<template>
    <div>
        <div
            id="main-wrapper"
            data-navbarbg="skin6"
            data-theme="light"
            data-layout="vertical"
            data-sidebartype="full"
            data-boxed-layout="full"
        >
            <Header-Partial></Header-Partial>
            <div v-if="access_slabs">
                <Aside-Partial
                    v-if="getHeader"
                    @activarLateral="showAside = $event"
                ></Aside-Partial>
                <div
                    v-if="getHeader"
                    class="page-wrapper"
                    v-bind:class="{ show: showAside }"
                >
                    <router-view></router-view>
                    <footer-partial></footer-partial>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import HeaderPartial from "@/core/views/Header";
import AsidePartial from "@/apps/slabs/views/Aside";
import FooterPartial from "@/core/views/Footer";
export default {
    name: "SlabsLayout",
    components: {
        HeaderPartial,
        AsidePartial,
        FooterPartial,
    },
    data() {
        return {
            showAside: false,
            isLoading: true,
        };
    },
    created() {
        console.log('created access_slabs', this.access_slabs);
        console.log('created getHeader', this.getHeader);
        if (this.loggedIn && this.getHeader) {
            //console.log('si hay login M');
            this.validAccess();
        }
    },
    methods: {
        validAccess() {
            this.$store.dispatch("core/apps/validAccess").then((response) => {
                let AppSlabs = response;
                if (AppSlabs) {
                    //si tiene Slabs asignado entonces permitir acceso
                    const accessSlabs = { login: true, token: true };
                    this.$store.dispatch("ModApps/slabs/access_slabs", accessSlabs);
                } else {
                    const accessSlabs = { login: false, token: false };
                    this.$store.dispatch("ModApps/slabs/access_slabs", accessSlabs);
                    //console.log('sino volver a home');
                    this.$router.push({ name: "Home" });
                } //sino volver a home
            });
        },
    },
    watch: {
        showAside: function () {
            if (
                this.$router.currentRoute.name == "slabs" ||
                this.$router.currentRoute.name == "Default"
            ) {
                this.$store.dispatch("ModApps/setShowAside", this.showAside);
            }
        },
        getHeader: function () {
            console.log('watch getHeader ', this.getHeader);
            this.validAccess();
        },
        access_slabs: function () {
            console.log('watch access_slabs ', this.access_slabs);
        },
    },
    computed: {
        ...mapGetters("ModApps/slabs", { access_slabs: "access_slabs" }),
        ...mapGetters("core", { getHeader: "getHeader" }),
        ...mapGetters("core/session", { loggedIn: "loggedIn" }),
        ...mapGetters("core/apps", { getAppIDactual: "getAppIDactual" }),
    },
};
</script>
<style scoped>
#main-wrapper[data-layout="vertical"][data-sidebartype="full"] .page-wrapper {
    z-index: 1;
    margin-left: 100px;
}
</style>
