let saveTags = function() {
    let v = this;
    let get_editTags=v.$store.getters['ModApps/jobs/get_editTags'];
    let idx = get_editTags.idx;
    v.$store.commit("core/setLoadingModal", true);
    let url = v.apis.editTags()
    window.master
        .post(url, {
            user_id: v.user_id,
            org_id: v.org_id,
            id_jobs: get_editTags.idJob,
            tags: v.tags,
        })
        .then((response) => {
            v.setTags({job: get_editTags.idJob, tags: v.tags});
            v.$store.commit("core/setLoadingModal", false);
            v.$store.commit("core/setNotiPush", {
                group: "notiPush",
                type: '1', // 1=success | 2=info | 3=error | 4=loading | 5=warning
                title: "Success",
                text: "Edit tags",
                show: true,
                duration: 2000
            }); 
            v.$store.commit("core/setCloseModal", {action: 'saved'});
            //reiniciar valores
            let e = { idx: 0, idJob: 0, active: false, option: [], values: [] };
            v.$store.commit("ModApps/jobs/set_editTags", e);
            v.$store.commit("ModApps/jobs/set_refresh_grid", true);
            v.$modal.hide("modal-lateral");
        })
        .catch((error) => {
            console.log(error);
            v.$store.commit("core/setLoadingModal", false);
        });
};
export default saveTags;
