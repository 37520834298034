<template>
    <BillingRestore>
        <slot>
            <div class="page-breadcrumb">
                <div class="row">
                    <div class="col-md-8 align-self-center">
                        <h4 class="page-title">Restore Estimate</h4>
                    </div>
                    <div class="col-md-4 align-self-center">
                        <div class="d-flex align-items-right justify-content-end">
                            <router-link :to="{ name: 'Setting' }" class="btn btn-info btn-sm mr-2 ctrlbtn" style="margin-bottom: 10px;width: -webkit-fill-available;">
                                <i class="fas fa-arrow-left"></i>
                                Back
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="card card-body estilosform">
                    <div class="row">
                        <div class="card card-body estilosform">
                            <Loader v-if="isLoading"></Loader>
                            <div class="row contenedor-table" v-else>
                                <table
                                    class="table table-striped table-bordered table-hover"
                                    id="restoreEstimate"
                                >
                                    <thead>
                                        <tr>
                                            <th scope="col">Est #</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Customer</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Total</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Date Deleted</th>
                                            <th>Restore?</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in EstimatesList" :key="item.id">
                                            <td scope="row">000{{ item.id }}</td>
                                            <td>{{ item.date_create }}</td>
                                            <td>
                                                {{ item.firstname }} {{ item.lastname }}
                                                <br /><span style="color: #a9a9a9">{{
                                                    item.bussinesname
                                                }}</span>
                                            </td>
                                            <td>{{ item.estimate_optional }}</td>
                                            <td>${{ item.total }}</td>
                                            <td>
                                                <span
                                                    v-if="item.status == 1"
                                                    class="badge badge-secondary"
                                                    style="background-color: #f89406"
                                                    >Pending</span
                                                >
                                                <span
                                                    v-if="item.status == 2"
                                                    class="badge badge-success"
                                                    >Accepted</span
                                                >
                                                <span
                                                    v-if="item.status == 3"
                                                    class="badge badge-primary"
                                                    >Rejected</span
                                                >
                                                <span
                                                    v-if="item.status == 4"
                                                    class="badge badge-danger"
                                                    style="background-color: #f70d0d"
                                                    >Void</span
                                                >
                                            </td>
                                            <td>{{ item.fecdelete }}</td>
                                            <td>
                                                <button
                                                    type="button"
                                                    class="btn btn-warning"
                                                    @click="setEstimatesRestore(item.id)"
                                                >
                                                    <i class="fas fa-share"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </slot>
    </BillingRestore>
</template>
<script>
import Loader from "@/core/components/shared/Loader";
import BillingRestore from "@/apps/billing/Layouts/BillingRestore";
export default {
    components: {
        BillingRestore,
        Loader,
    },
    data() {
        return {
            isLoading: false,
            EstimatesList: [],
        };
    },
    methods: {
        getEstimatesRestore() {
            this.isLoading = true;
            window.billing
                .get("estimate/getrestore/" + window.localuserdata)
                .then((response) => {
                    console.log("estimates list");
                    this.EstimatesList = response.data.result;
                    let e = this.EstimatesList;
                    for (var i = 0; i < e.length; i++) {
                        let date_create = new Date(e[i].date_create).toDateString();
                        let date_create_new = date_create.split(" ");
                        this.EstimatesList[i].date_create =
                            date_create_new[0] +
                            ", " +
                            date_create_new[1] +
                            " " +
                            date_create_new[2] +
                            ", " +
                            date_create_new[3];
                    }
                    console.log(this.EstimatesList);
                    this.isLoading = false;
                })
                .catch((error) => console.log(error));
        },
        setEstimatesRestore(id) {
            this.$swal({
                title: "Restore this Estimate?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes restore!",
            }).then((result) => {
                if (result.value) {
                    this.isLoading = true;
                    window.billing
                        .post("estimate/setrestore", {
                            user_id: window.localuserdata,
                            id: id,
                        })
                        .then((response) => {
                            console.log(response.data);
                            this.isLoading = false;
                            if (response.data.result) {
                                this.getEstimatesRestore();
                                this.$swal.fire({
                                    icon: "success",
                                    title: "Estimate restore successfully",
                                    showConfirmButton: false,
                                    timer: 2000,
                                });
                            } else {
                                this.$swal.fire({
                                    icon: "error",
                                    title: "Error",
                                    text: "",
                                    showConfirmButton: false,
                                    timer: 6000,
                                });
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            this.isLoading = false;
                        });
                }
            });
        },
    },
    created() {
        this.getEstimatesRestore();
    },
};
</script>

<style lang="scss" scoped>
#restoreEstimate {
    width: 100%;
}
#restoreEstimate thead tr th {
    font-weight: bold;
    font-size: 16px;
}
#restoreEstimate thead tr th:nth-child(1) {
    width: 8%;
    text-align: center;
}
#restoreEstimate thead tr th:nth-child(2) {
    width: 12%;
    text-align: center;
}
#restoreEstimate thead tr th:nth-child(3) {
    width: 40%;
    text-align: left;
}
#restoreEstimate thead tr th:nth-child(4) {
    width: 30%;
    text-align: left;
}
#restoreEstimate thead tr th:nth-child(5) {
    width: 10%;
    text-align: center;
}
#restoreEstimate thead tr th:nth-child(6) {
    width: 5%;
    text-align: center;
}
#restoreEstimate thead tr th:nth-child(7) {
    width: 5%;
    text-align: center;
}
#restoreEstimate tbody tr td {
    text-align: center;
}
#restoreEstimate tbody tr td:nth-child(3),
#restoreEstimate tbody tr td:nth-child(4) {
    text-align: left;
}
.contenedor-table table thead tr th {
    font-weight: bold;
}
@media (max-width: 1100px) {
    .contenedor-table {
        max-width: 924px;
        overflow-x: scroll;
    }
}
@media (min-width: 1280px) {
    .ctrlbtn {
        width:100px !important;
    }
}
</style>
