let editOptions = function(num) {
	let t = this;
	t.m.show= true;
	t.m.component= 'optiones';
	if(num == 0){t.m.data= { opts: 0, list: t.optionsMarkSource };}
	if(num == 1){t.m.data= { opts: 1, list: t.optionsCTAChannel };}
	if(num == 2){t.m.data= { opts: 2, list: t.optionsCampign };}
	if(num == 3){t.m.data= { opts: 3, list: t.optionsCampign };}
	this.$store.commit('core/setOpenModal', t.m);
};
export default editOptions;
