let updateNameTask = function(d) {
    for (var k in this.listTaks) {
        if (this.listTaks[k]) {
            if (this.listTaks[k].id_i == d.job) {
                for (var i in this.listTaks[k].taks) {
                    if (this.listTaks[k].taks[i]) {
                        if (this.listTaks[k].taks[i].id ==d.tak_id) {
                            this.listTaks[k].pusher++;
                            this.listTaks[k].taks[i].name = d.tak_name;
                            this.listTaks[k].taks[i].alto = d.alto;
                            // this.autoHeigth(d.alto, k, i);
                        }
                    }
                }
            }
        }
    }
    this.reEscribirLista();
    this.starGrid();
};
export default updateNameTask;
