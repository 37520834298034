import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './config/axiosApi'
window.version_app = localStorage.getItem('versionCache');
import VueMeta from 'vue-meta'
import LoadScript from 'vue-plugin-load-script';
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css';
import Popover from 'vue-js-popover'
import Datetime from 'vue-datetime'
import { Settings } from 'luxon'
import 'vue-datetime/dist/vue-datetime.css'
import 'vue-search-select/dist/VueSearchSelect.css'
//import { VTooltip, VPopover, VClosePopover } from 'v-tooltip'
import Notifications from 'vue-notification'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import vmodal from 'vue-js-modal'
import VuePostmessage from 'vue-postmessage'
import packLaguages from '@/core/lang'
import libGlobal from '@/libGlobal' // funciones globales
import {ColorPicker, ColorPanel} from 'one-colorpicker'
import vClickOutside from 'v-click-outside'
Settings.defaultLocale = 'en'
const options = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674'
}
// global register
Vue.use(vClickOutside)
Vue.use(ColorPanel)
Vue.use(ColorPicker)
Vue.use(libGlobal);
Vue.use(LoadScript);
Vue.use(VuePostmessage)
Vue.use(Popover, { tooltip: true })
Vue.use(vmodal, {dynamic: true, dialog: true})
Vue.use(VueSweetalert2, options)
Vue.use(Datetime)
Vue.use(require('vue-moment'));
Vue.use(Notifications)
Vue.component('multiselect', Multiselect)
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})
Vue.config.productionTip = false
Vue.config.devtools = true
Vue.prototype.$localtoken = window.localtoken_M;
// en | es => idioma por default del sistema
window.langLocal = (localStorage.getItem('langLocal') != null) ? localStorage.getItem('langLocal') : 'en'; //en | es 
window.showLang = (localStorage.getItem('showLang') != null) ? localStorage.getItem('showLang') : 'Off'; //On | Off
if(localStorage.getItem('langLocal') == null){localStorage.setItem('langLocal', window.langLocal);}
window.laguages = packLaguages;
// console.log('laguages', window.laguages);
window.messages = {
  'en': {lang: window.laguages.en},
  'es': {lang: window.laguages.es}
}
window.lang= window.messages[window.langLocal].lang // declaracion para usar lang en js
var detectarNavegador = window.navigator.userAgent;  
var chrome = /Chrome/;
var firefox = /Firefox/;
var opera = /OPR/;
var edge = /Edge/;
var safari = /Safari/;
window.SafariEnUso=false;
if(safari.test(detectarNavegador) && !(chrome.test(detectarNavegador))){
  window.SafariEnUso = true;
  console.log('Detectado tu navegador: Apple Safari');
}
else {
  console.log('No es safari');
}
Vue.filter('formatMoney', (value) => {
  return Number(value)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
});

Vue.filter('formatMileage', (value) => {
  return Math.trunc(value)
      .toFixed()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
