<template>
    <div :class="['popMiniSite', (getShowMiniSite) ? 'show' : '']">
            <div class="overlayPop" @click="$emit('closeMiniSite')"></div>
            <div class="contentPop">
                <div class="headerPop">
                    <div class="input-group col-sm-8 col-xs-11" style="padding-left: 0px;padding-right: 45px;">
                        <span class="input-group-text" style="padding:0px;background: transparent; color:#fff;font-size: 20px;border:0px;line-height: 1;">
                            <i class="fas fa-globe"></i>
                        </span>
                        <input type="text" class="form-control" maxlength="26" v-model="name" required placeholder="Name, maxlength 26"
                        style="border-top-right-radius: 0px; border-bottom-right-radius: 0px;background: transparent; color:#fff;font-size: 18px;border:0px;line-height: 1;"/>
                        <span class="input-group-text" style="padding:0px;background: transparent;border:0px;line-height: 1;">
                            <button type="button"
                            class="btn btn-success"
                            @click="saveMiniSite()"
                            style="border-top-left-radius: 0px; border-bottom-left-radius: 0px;"
                            >Save</button>
                        </span>
                    </div>
                    <button class="closeIcon" @click="$emit('closeMiniSite')"><span></span></button>
                </div>
                <div class="bodyPop">
                    <div class="row" v-if="isLoading"><Loader class="col-12"></Loader></div>
                    <iframe :src="iframe.src+itemPreview" frameborder="0" class="previewIframe" @load="iframeIsLoaded" v-show="iframe.loaded && !isLoading"></iframe>
                </div>
            </div>
        </div>
</template>
<script>
// @ts-ignore
import { mapGetters } from "vuex";
// @ts-ignore
import Loader from "@/core/components/shared/Loader.vue";
export default {
    components: {
        Loader,
    },
    props: {
        itemPreview: {
            type: [String, Number],
            default: '',
        },
        ShowMiniSite: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            validMiniSite: false,
            isLoading: true,
            name: null,
            iframe: {
                loaded: false,
                src: window.urldashboard+'bSite/mini-site/preview/',
            },
            apis: null,
        };
    },
    created(){
        let t = this;
        t.getShowMiniSite = t.ShowMiniSite;
        t.name = localStorage.getItem('siteName');
        let urlApis = this.$store.getters['ModApps/Marketea/urlApis'];
        this.apis = urlApis.bSite;
        localStorage.setItem('save_pending', 0);
        localStorage.setItem('iframeState', 'pending');
    },
    methods: {
        iframeIsLoaded (e) {
            console.log('iframe loaded')
            this.iframe.loaded=true;
            this.isLoading = false;
            localStorage.setItem('iframeState', 'ready');
        },
        saveMiniSite(){
            console.log('saveSite')
            let t = this;
            let url = t.apis.set(12); //setMiniSite
            t.isLoading = true;
            window.master.post(url, {
                user_id: window.master_client,
                org_id: window.localorgdata,
                id: t.itemPreview,
                name: t.name,
                sections: localStorage.getItem('siteContent'),
                pusher_id: pusher.connection.socket_id
            })
            .then((response) => {
                console.log(url, response.data);
                if(response.data.status){
                    localStorage.setItem('save_pending', 0);
                    t.$notify({
                        group: "noti",
                        type: 'success',
                        title: "Success",
                        text: "Changes saved!",
                    });
                }
                t.isLoading = false;
            }).catch((error) => {
                console.log(error);
                t.isLoading = false;
            });
        },
    },
    computed: {
        ...mapGetters("ModApps/Marketea", {
            getMiniSite: "get_bsite_minisite",
        }),
        siteContent: {
            get: function () {
                let t = this;
                return (localStorage.getItem('siteContent')!=null) ? JSON.parse(localStorage.getItem('siteContent')) : {};
            },
            set: function (v) {
                if(v!= null){
                    localStorage.setItem('siteContent', JSON.stringify(v));
                }
            }
        },
        getShowMiniSite: {
            get: function () {
                let t = this;
                return t.ShowMiniSite;
            },
            set: function (v) {
                return v;
            }
        },
    },
    watch: {
        ShowMiniSite: function(){
            let t = this;
            t.getShowMiniSite = t.ShowMiniSite;
            t.name = localStorage.getItem('siteName');
        },
    },
}
</script>
<style lang="scss" scoped>
.popMiniSite {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 0%;
    transition: 0.5s all ease;
    scale: 0.5;
    display: block;
    &.show {
        scale: 1;
        width: 100%;
        height: 100%;
        z-index: 9999;
        .overlayPop{
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background: rgba(0 0 0 /50%);
            z-index: 1;
        }
    }
    .contentPop{
        position: absolute;
        top: 0px;
        left: 0;
        right: 0;
        z-index: 2;
        margin: auto;
        display: block;
        background: #ffffff;
        width: 100%;
        max-width: 430px;
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
        box-shadow: -3px -4px 16px 0px rgb(0 0 0 / 72%);
        border-radius: 10px;
        .headerPop{
            position: relative;
            display: block;
            width: 100%;
            height: 50px;
            background: #031099;
            color: #fff;
            padding: 10px;
            box-sizing: border-box;
            .closeIcon span:before, .closeIcon span:after{background-color: #fff;}
            .closeIcon:hover span:before, .closeIcon:hover span:after{background-color: #000;}
            .closeIcon:focus{outline: none;}
        }
        .bodyPop{
            display: block;
            width: 100%;
            height: calc(100vh - 55px);
            position: relative;
            padding: 10px 10px 0px 10px;
            overflow: hidden;
            overflow-y: auto;
            background: #f2f4f5;
            iframe.previewIframe{
                width: 100%;
                max-width: 430px;
                margin: auto;
                height: calc(100vh - 72px);
                position: relative;
                overflow: hidden;
                border-radius: 0px;
            }
            .add_section{
                padding: 5px 10px;
                border: 1px dashed #adadad;
                &:hover{border: 1px dashed #fcd602;}
                .btn_content{
                    padding: 8px 15px;
                    font-size: 16px;
                    border-radius: 20px;
                    z-index: 4;
                    margin: 0px auto 0px auto;
                    display: block;
                    width: 100%;
                    max-width: 150px;
                    color: #ddd;
                    border: 1px solid #02ac1e;
                    background: #02ac1e;
                    &:hover{cursor: pointer;}
                }
            }

        }
    }
}
</style>
