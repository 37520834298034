let init = function() {
    this.user_id=window.master_client;
    this.org_id=window.localorgdata;
    this.typez=window.user_type;
    this.f.user_id=window.master_client;
    this.f.org_id=window.localorgdata;
    this.user_id_assign=window.localAccountID;
    this.tagsDefault.user_id= window.localAccountID;
    this.tagsDefault.org_id=window.localorgdata;
    this.heightPage = window.innerHeight;
    let modulos = this.$store.getters['core/apps/modulos'];
    let privApp = this.$store.getters['core/session/privilegiesSesion'].modulos;
    console.log('modulos', modulos)
    console.log('privApp', privApp)
    let AppIdActual = localStorage.getItem('appIDactual');
    let modApp = [];
    for (let i in modulos) {
        if(modulos[i].app_id == AppIdActual){
            //for (let e in privApp[AppIdActual]) {
            //    
            //}
        }
    }
};
export default init;
