let existCover = function(galleryList) {
    let cover = galleryList.filter(r => {
        if(r.cover > 0){
            return r;
        }

    });
    return (cover.length>0);
};
export default existCover;
