<template>
    <div id="note">
        <!-- <button
            type="button"
            :class="btn"
            style="padding: 0px 3px; font-size: 10px; height: 16px"
            title="Edit Notes"
            @click="openNote(type, id)"
        >
            <i class="far fa-sticky-note"></i>
            <span class="textIcon" :style="sTxt">&nbsp;Edit Note</span>
        </button> -->
        <!-- <modal :name="modal.name" height="auto"> -->
            <div class="card">
                <div class="card-header">
                    <h3 class="page-title">
                        <i class="far fa-sticky-note"></i> {{ modal.title }}
                    </h3>
                </div>
                <div class="card-body">
                    <form @submit.prevent="saveNote">
                        <div class="form-group">
                            <label for="inputCity">Notes Admin</label>
                            <textarea
                                class="form-control"
                                v-model="modal.note"
                                cols="30"
                                rows="10"
                            ></textarea>
                        </div>
                        <div class="row">
                            <div class="col-12 text-right">
                                <button type="submit" class="btn btn-success">
                                    <i class="fas fa-save"></i> SAVE
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-danger"
                                    @click="delNote"
                                    v-if="modal.edit"
                                    style="margin-left: 10px"
                                >
                                    <i class="fas fa-trash-alt"></i> DELETE
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        <!-- </modal> -->
    </div>
</template>
<script>
export default {
    name: "NotesAdmin",
    components: { },
    // props: {
    //     id: {
    //         type: Number,
    //         default: 0,
    //     },
    //     type: {
    //         type: String,
    //         default: "",
    //     },
    //     sTxt: {
    //         type: String,
    //         default: "",
    //     },
    //     btnNotes: {
    //         type: Number,
    //         default: 0,
    //     },
    // },
    data () {
        return {
            isLoadingModal: false,
            modal: {
                title: "Add Notes",
                edit: false,
                note: "",
                idNote: null,
                transac_id: this.id,
                transac_type: this.type,
                user_idd: window.master_client,
                user_id: window.localorgdata,
                org_id: window.localorgdata,
                oauth: window.oauth_m,
                name: "NotesModal" + this.id,
            },
            id:0,
            type:'',
            sico: "",
            btn: "btn btn-secundary",
        };
    },
    created () {
        if (this.btnNotes === 1) {
            this.btn = "btn btn-warning";
        }
        this.init()
    },
    methods: {
        init() {
            let g = this.$store.getters['core/getOpenModal'];
            let r = g.data; 
            this.modal.note = "";          
            this.modal.transac_id = r.id;
            this.modal.transac_type = r.type;     
            this.modal.edit = r.edit;  
            this.modal.idNote = r.id;
            this.modal.name = "NotesModal" + r.id; 
            this.id = r.id;
            this.type = r.type;
            // console.log(this.modal);
            if(this.modal.edit){
                this.getNotes();
            }
        },          
        // openNote(type, id) {
        //     this.isLoadingModal = true;
        //     this.$modal.show(this.modal.name);
        //     this.getNotes();
        // },
        hideModal() {
            this.modal.note = "";
            this.modal.idNote = null;
            this.modal.edit = false;
            this.modal.title = "Add Notes";
            this.$parent.close();
        },
        getNotes() {
            this.$store.commit("core/setLoadingModal", true);
            let url = "note/";
            if (this.type == "Payment") {
                url = "paymentnote/";
            }
            window.billing
                .get(url + window.localuserdata + "/" + this.modal.transac_id)
                .then((response) => {
                    //console.log(response.data.result);
                    if (response.data.result !== null) {
                        this.modal.edit = true;
                        this.modal.title = "Edit Notes";
                        if (this.type === "Payment") {
                            this.modal.note = response.data.result.memo;
                            this.modal.idNote = response.data.result.id;
                            if (this.modal.note === null || this.modal.note === "null") {
                                this.modal.note = "";
                            }
                        } else {
                            this.modal.note = response.data.result.note;
                            this.modal.idNote = response.data.result.id;
                        }
                    }
                    this.$store.commit("core/setLoadingModal", false);
                })
                .catch((error) => {
                    this.$store.commit("core/setLoadingModal", false);
                    console.log(error);
                });
        },
        delNote() {
            this.$swal({
                title: "Delete this Note?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                if (result.value) {
                    this.$store.commit("core/setLoadingModal", true);
                    let url = "note/del";
                    if (this.type == "Payment") {
                        url = "paymentnote/del";
                    }
                    window.billing
                        .post(url, { 
                            id: this.modal.idNote,
                            transac_id: this.modal.transac_id,
                            transac_type: this.modal.transac_type,
                            user_idd: window.master_client,
                            user_id: window.localorgdata,
                            org_id: window.localorgdata,
                            oauth: window.oauth_m,
                        })
                        .then((response) => {
                            this.$swal.fire({
                                icon: "success",
                                title: "Deleted!",
                                text: "Deleted Note Successful",
                                showConfirmButton: false,
                                timer: 2000,
                            });
                            this.isNotes();
                            this.hideModal();
                            let t = this
                            this.$store.commit("core/setLoadingModal", false);
                            this.$store.commit("core/setCloseModal", true);
                            this.$store.commit("core/setActionRefresh", {
                                action: 'saved',
                                data: {
                                    methods:'invoNote',
                                    id:t.id,
                                    value:0,
                                    memo: '',
                                }
                            });                                                      
                        })
                        .catch((error) => {
                            this.$store.commit("core/setLoadingModal", false);
                            console.log(error);
                        });
                }
            });
        },
        saveNote() {
            let t = this
            let url = "note/add";
            if (t.modal.edit === true) {
                url = "note/edit";
            }
            if (t.type === "Payment") {
                url = "paymentnote/edit";
            }
            if(t.modal.edit && t.modal.note.length == 0){
                t.delNote();
            }
            else if(t.modal.note.length > 0){
                t.$store.commit("core/setLoadingModal", true);
                window.billing
                .post(url, t.modal)                
                .then((response) => {
                    t.isLoadingModal = false;
                    t.$swal.fire({
                        icon: "success",
                        title: "Saved",
                        text: "Note saved successfully",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    let memo = t.modal.note;
                    t.isNotes();
                    t.hideModal();
                    t.$store.commit("core/setLoadingModal", false);
                    t.$store.commit("core/setCloseModal", true);
                    this.$store.commit("core/setActionRefresh", {
                        action: 'saved',
                        data: {
                            methods:'invoNote',
                            id:t.id,
                            value: 1,
                            memo: memo
                        }
                    });                     
                })
                .catch((error) => {
                    t.$store.commit("core/setLoadingModal", false);
                    console.log(error);
                });
            }
            else{
                t.$store.commit("core/setCloseModal", true);
                    this.$store.commit("core/setActionRefresh", {
                    action: 'saved',
                    data: {
                        methods:'invoNote',
                        id:t.id,
                        value: 0,
                        memo: ''
                    }
                }); 
                t.hideModal();
            }
        },
        isNotes() {
            let url = "note/";
            if (this.type == "Payment") {
                url = "paymentnote/";
            }
            window.billing
                .get(url + window.localuserdata + "/" + this.modal.transac_id)
                .then((response) => {
                    // console.log(this.modal.transac_id, this.type, response.data.result);
                    this.btn = "btn btn-secundary";
                    if (response.data.result !== null) {
                        if (this.type === "Payment") {
                            let memo = response.data.result.memo;
                            if (memo != null && memo !== "null") {
                                this.btn = "btn btn-warning";
                            }
                        } else {
                            let note = response.data.result.note;
                            if (note !== null && note !== "null") {
                                this.btn = "btn btn-warning";
                            }
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    computed: {},
};
</script>
<style lang="css" scoped>
#note {
    display: inline-block;
}
.textIcon {
    font-size: 10px;
}
div#note label {
    color: #888787;
}
textarea.form-control, 
textarea.form-control:focus {
    border: 1px solid #dddd;
    color: #000;
    font-size: 16px;   
}
</style>
