<template>
    <div>
        <nav
            class="navbar navbar-expand-md navbar-dark fixed-top bg-dark"
            id="headerJobs"
        >
            <button
                class="navbar-toggler"
                type="button"
                id="activeMenuLateral"
                @click="ShowHiddenAside"
            >
                <span class="navbar-toggler-icon"></span>
            </button>
            <router-link :to="{ name: 'Jobs' }" class="navbar-brand desktop">
                Jobs
            </router-link>
            <span
                v-bind:class="[
                    'badge badge-pill badge-light numID mobile',
                    open ? 'active' : '',
                ]"
                @click="dashForm()"
            >
                <span class="titulo-cont">{{ JobsPending }} Open</span>
            </span>
            <span
                v-bind:class="[
                    'badge badge-pill badge-light completed mobile',
                    completed ? 'active' : '',
                ]"
                @click="goCompleted"
            >
                <span class="titulo-cont">Completed</span>
            </span>
            <button
                class="navbar-toggler"
                type="button"
                id="activeMenuAvanzado"
                @click="ShowHiddenMA"
                v-if="typeU == 0"
            >
                <i class="fas fa-ellipsis-v"></i>
            </button>
            <div
                v-bind:class="['collapse navbar-collapse', activarMA ? 'in' : '']"
                class=""
                id="navbarCollapse"
            >
                <div class="row" style="width: 100%">
                    <div class="col-sm-2 col-xl-4"></div>
                    <div class="col-sm-7 col-xl-4 text-center" style="padding-top: 2px">
                        <!-- start filter tag url -->
                        <filterTagsUrl v-if="id_tags.length > 0 && typez == 0"></filterTagsUrl>
                        <!-- end filter tag url -->
                        <span
                            v-else
                            v-bind:class="[
                                'badge badge-pill badge-light numID desktop',
                                open ? 'active' : '',
                            ]"
                            @click="dashForm()"
                        >
                            <!-- <span class="badge badge-pill badge-danger num-cont"></span> -->
                            <span class="titulo-cont">{{ JobsPending }} Open</span>
                        </span>
                        <span
                            v-bind:class="[
                                'badge badge-pill badge-light completed desktop',
                                completed ? 'active' : '',
                            ]"
                            @click="goCompleted"
                        >
                            <span class="titulo-cont">Completed</span>
                        </span>
                    </div>
                    <div class="col-sm-3 col-xl-4 ">
                        <ul class="navbar-nav" style="justify-content: flex-end">
                            <li
                                class="nav-item"
                                v-if="
                                    url == 'Jobs' ||
                                    url == 'JobsTags' ||
                                    url == 'JobsComppleted'
                                "
                            >
                                <form
                                    @submit.prevent="sendSearchJobs"
                                    v-bind:class="[
                                        active == false ? 'open' : '',
                                        'searchbox',
                                    ]"
                                    role="search"
                                >
                                    <div class="form-group">
                                        <input
                                            class="searchbox-input"
                                            type="text"
                                            placeholder="Search..."
                                            aria-label="Search"
                                            v-model="searchbox"
                                            ref="searchbox"
                                        />
                                    </div>
                                    <a
                                        class="searchbox-submit"
                                        value="Go"
                                        v-if="activarMA"
                                    >
                                        <span class="searchbox-icon">
                                            <i class="fa fa-search"></i
                                        ></span>
                                        <span class="mobile"> Search</span>
                                    </a>
                                    <a
                                        class="searchbox-submit"
                                        value="Go"
                                        @click="buttonUp()"
                                        v-else
                                    >
                                        <span class="searchbox-icon">
                                            <i class="fa fa-search"></i
                                        ></span>
                                        <span class="mobile"> Search</span>
                                    </a>
                                </form>
                                <div v-if="isLoading">
                                    <div class="vertical-menu">
                                        <ul>
                                            <li><Loader></Loader></li>
                                        </ul>
                                    </div>
                                </div>
                                <div v-else-if="searchbox.length > 0 && !isLoading">
                                    <div class="vertical-menu" v-if="searchD == false">
                                        <ul v-if="searchboxData.length == 0">
                                            <li style="padding-right: 0px">
                                                No Record
                                                <a
                                                    @click="cancel()"
                                                    class="an"
                                                    style="
                                                        margin-left: 167px;
                                                        border-left: 1px solid;
                                                        border-right: 1px solid;
                                                        position: relative;
                                                    "
                                                    >Close</a
                                                >
                                            </li>
                                        </ul>
                                        <ul v-else>
                                            <li
                                                v-for="(i, index) in searchboxData"
                                                :key="index"
                                            >
                                                <a
                                                    @click="searchDat(i.id_i)"
                                                    v-if="index <= 6"
                                                >
                                                    {{ i.id }} -- {{ i.customer_name }} --
                                                    {{ i.businessname }} -- {{ i.name }}
                                                    <span
                                                        v-for="(y, indey) in i.taks"
                                                        :key="indey"
                                                        class="badge badge-pill badge-info check-done"
                                                    >
                                                        {{ y.name }}
                                                    </span>
                                                </a>
                                            </li>
                                            <li
                                                style="
                                                    background-color: #eee;
                                                    padding: 12px;
                                                "
                                            >
                                                <a
                                                    @click="moreOptions()"
                                                    class="an"
                                                    v-if="searchD == false && counter > 1"
                                                    >Results found ({{ counter }})</a
                                                >
                                                <a
                                                    @click="viewAll()"
                                                    class="an"
                                                    v-if="searchD == false"
                                                    >View All</a
                                                >
                                                <a
                                                    @click="cancel()"
                                                    class="an"
                                                    v-if="searchD == false"
                                                    >Cancel</a
                                                >
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="scroll_vertical" v-if="searchD == true">
                                        <ul v-if="searchboxData.length == 0">
                                            <li style="padding-right: 0px">
                                                No Record
                                                <a
                                                    @click="cancel()"
                                                    class="an"
                                                    style="
                                                        margin-left: 167px;
                                                        border-left: 1px solid;
                                                        border-right: 1px solid;
                                                        position: relative;
                                                    "
                                                    >Close</a
                                                >
                                            </li>
                                        </ul>
                                        <ul v-else>
                                            <li
                                                v-for="(i, index) in searchboxData"
                                                :key="index"
                                            >
                                                <a
                                                    @click="searchDat(i.id_i)"
                                                    v-if="searchboxData.length > 0"
                                                >
                                                    {{ i.id }} -- {{ i.customer_name }} --
                                                    {{ i.businessname }} -- {{ i.name }}
                                                    <span
                                                        v-for="(y, indey) in i.taks"
                                                        :key="indey"
                                                        class="badge badge-pill badge-info check-done"
                                                    >
                                                        {{ y.name }}
                                                    </span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li
                                class="nav-item"
                                v-if="
                                    url != 'Jobs' &&
                                    url != 'JobsTags' &&
                                    url != 'JobOrderList'
                                "
                            >
                                <router-link
                                    :to="{ name: 'Jobs' }"
                                    class="nav-link"
                                    v-if="visor == 1"
                                >
                                    <i class="fa fa-home fa-lg"></i>
                                    <span class="mobile"> Jobs List</span>
                                </router-link>
                                <router-link
                                    :to="{ name: 'JobOrderList' }"
                                    class="nav-link"
                                    v-else
                                >
                                    <i class="fa fa-home fa-lg"></i>
                                    <span class="mobile"> Jobs List</span>
                                </router-link>
                            </li>
                            <li
                                class="nav-item"
                                v-if="
                                    (typeU == 0 && url == 'Jobs') ||
                                    url == 'JobsTags' ||
                                    url == 'JobOrderList'
                                "
                            >
                                <router-link
                                    :to="{ name: 'Jobs' }"
                                    class="nav-link"
                                    v-if="visor == 2"
                                >
                                    <i class="fas fa-th fa-lg"></i>
                                    <span class="mobile"> View Box</span>
                                </router-link>
                            </li>
                            <li class="nav-item" v-if="typeU == 0">
                                <router-link :to="{ name: 'JobsSetup' }" class="nav-link">
                                    <i class="fa fa-cog fa-lg"></i>
                                    <span class="mobile"> Setup</span>
                                </router-link>
                            </li>
                            <li 
                                class="nav-item " 
                                v-if="url == 'Jobs' || url == 'JobsTags' "
                            >
                                <span class="cdropdown opAddHeader nav-link">
                                    <button><i class="fas fa-ellipsis-v"></i></button>
                                    <label>
                                        <input type="checkbox">
                                        <ul class="text-left">
                                            <li @click="orderJobsBy(0)">
                                                <i class="fas fa-arrow-up"></i> 
                                                Sort by id
                                            </li>
                                            <li @click="orderJobsBy(1)">
                                                <i class="fas fa-arrow-up"></i> 
                                                Sort by old records
                                            </li>
                                            <li @click="orderJobsBy(2)">
                                                <i class="fas fa-arrow-up"></i> 
                                                Sort by new records
                                            </li>
                                        </ul>
                                    </label>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import Loader from "@/core/components/shared/Loader.vue";
import filterTagsUrl from '@/apps/jobs/components/jobsFilter/filterTagsUrl'
export default {
    components: {
        Loader,
        filterTagsUrl,
    },
    name: "headerJobs",
    data() {
        return {
            isLoading: false,
            searchDdiv: false,
            searchD: false,
            activar: true,
            active: true,
            searchbox: "",
            url: "",
            visor: 1,
            typeU: window.user_type,
            activarMA: false,
            open: true,
            completed: false,
            typez: window.user_type,
            query: "",
            editorTag: false,
            editarTags: [],
            btnAddJobs: false,
            id_tags: [],
            result: [],
            searchboxData: [],
            empty: [],
            counter: 0,
            all: 0,
            buscardata: "",
            viewAlls: 0,
            JobsPending: 0,
        };
    },
    created() {
        this.init()
    },
    methods: {
        init(){
            let urlApis = this.$store.getters['ModApps/jobs/urlApis'];
            this.apis = urlApis.viewDash;

            this.JobsPending = this.$store.getters['ModApps/jobs/get_JOPending'];
            this.validAdminApp();
            this.url = this.$router.currentRoute.name;
            if (this.get_typeVisor) {
                this.visor = this.get_typeVisor;
            }
            this.id_tags = [];
            if (this.url == "JobsTags") {
                if (this.$route.params.id > 0) {
                    this.id_tags = [this.$route.params.id];
                }
            }
            else if (this.url == "JobsComppleted") {
                let getFilterTags=this.$store.getters['ModApps/jobs/getDataFilterTagsUrl'];
                if(getFilterTags.id>0){
                    this.id_tags = [getFilterTags.id];   
                }
            }
        },
        orderJobsBy(i){
            console.log('change sortBy', i)
            this.$store.commit('ModApps/jobs/setSortJobsBy', i);
        },
        cancel() {
            this.searchbox = "";
            this.searchboxData = [];
            this.buscardata = "";
            this.searchDdiv = true;
            this.hideShowSearch();
        },
        sendSearchJobs() {
            this.viewAll();
        },
        searchDat(data) {
            this.centralSearch(data, 1, 1);
            this.searchDdiv = true;
            this.hideShowSearch();
        },
        viewAll() {
            this.all = 1;
            this.result = [];
            this.getJobsDash('viewAll');
            this.viewAlls = 1;
            this.hideShowSearch();
        },
        moreOptions() {
            this.searchD = !this.searchD;
            this.all = 1;
            this.getJobsDash('moreOptions');
        },
        getJobsDash(text) {
            console.log('desde', text);
            let id_status = [1, 2];
            if (this.$router.currentRoute.name == "JobsComppleted") {
                id_status = [4];
            }
            if (this.$route.params.id != undefined) {
                this.id_tags = [];
                this.id_tags.push(parseInt(this.$route.params.id));
            }
            let url = this.apis.getJobsDash()
            this.isLoading = true;
            window.master
                .post(url, {
                    user_id_assign: window.localAccountID,
                    user_id: window.master_client,
                    org_id: window.localorgdata,
                    data: this.buscardata,
                    id_tags: this.id_tags,
                    all: this.all,
                    billing: 1,
                    type: window.user_type,
                    id_status: id_status,
                })
                .then((response) => {
                    let v = this;
                    v.counter = response.data.count;
                    let rs = response.data.limit;
                    v.result = v.dataJobDash(rs, v.editorTag, v.editarTags);
                    v.searchboxData = v.result;
                    v.all = 0;
                    this.hideShowSearch();
                    this.searchDdiv = false;
                    if (v.viewAlls == 1) {
                        v.viewAlls = 0;
                        let data = v.result;
                        let o = [];
                        o.push({ data: data, boolean: true, all: true });
                        v.$store.commit("ModApps/jobs/set_searchJobs", o);
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    this.isLoading = false;
                    console.log(error);
                });
        },
        dataJobDash(e, isAT, listTags) {
            let total = "";
            let list = [];
            for (const i in e) {
                //debugger;
                let nun = parseInt(e[i].id);
                if (nun <= 9) {
                    total = "0000" + nun;
                } else if (nun >= 10 && nun <= 99) {
                    total = "000" + nun;
                } else if (nun >= 100 && nun <= 999) {
                    total = "00" + nun;
                } else if (nun >= 1000) {
                    total = "0" + nun;
                } else {
                    total = nun;
                }
                let customer_name = "None";
                let customer_businessname = "None";
                let invoice_name = "";
                let showInvo = 0;
                if (
                    e[i].customer_name !== undefined &&
                    e[i].customer_name != "" &&
                    e[i].customer_name != null
                ) {
                    customer_name = e[i].customer_name;
                }
                if (
                    e[i].customer_businessname !== undefined &&
                    e[i].customer_businessname != "" &&
                    e[i].customer_businessname != null
                ) {
                    customer_businessname = e[i].customer_businessname;
                } else {
                    customer_businessname = customer_name;
                }
                if (e[i].invoice_name !== undefined) {
                    invoice_name = e[i].invoice_name;
                    showInvo = 1;
                }
                let taks = e[i].taks;
                for (let j in taks) {
                    taks[j].isLoading = false;
                }
                let edit = false;
                if (this.typez == 0) {
                    edit = true;
                } else if (isAT) {
                    let t = e[i].tags;
                    for (let k in t) {
                        for (let l in listTags) {
                            if (t[k].id_tags == listTags[l].tags_id) {
                                edit = true;
                            }
                        }
                    }
                }
                let newLine = {
                    date: e[i].date,
                    counter: e[i].counter,
                    id: total,
                    id_i: e[i].id,
                    ids: e[i].id,
                    id_assign: 1,
                    tags: e[i].tags,
                    taks: taks,
                    id_color: e[i].id_color,
                    id_customer: e[i].id_customer,
                    id_invoice: e[i].id_invoice,
                    id_status: e[i].id_status,
                    id_subcontractor: e[i].id_subcontractor,
                    jsstatus: e[i].jsstatus,
                    name: e[i].name,
                    notes: e[i].notes,
                    org_id: e[i].org_id,
                    user_id: e[i].user_id,
                    customer_name: customer_name,
                    businessname: customer_businessname,
                    invoice_name: invoice_name,
                    showInvo: showInvo,
                    selected: false,
                    colorList: this.colorList,
                    edicion: edit,
                    cus: e[i].cus,
                    gallery: e[i].gallery,
                    galleryList: e[i].galleryList,
                    link_drive: e[i].link_drive,
                    url: e[i].url,
                };
                list.push(newLine);
            }
            //console.log('dataJobDash', list)
            return list;
        },
        ShowHiddenMA() {
            this.activarMA = !this.activarMA;
            this.buttonUp();
        },
        validAdminApp() {
            let admin = false;
            if (window.user_type == 0) {
                admin = true;
            } else {
                for (let i in this.get_appRolUser) {
                    if (this.getAppIDactual == this.get_appRolUser[i].app_id) {
                        if (this.get_appRolUser[i].rol == 1) {
                            admin = true;
                        }
                    }
                }
            }
            if (admin) {
                //permisos de admin
                this.typeU = 0;
                console.log("menu admin");
            }
        },
        refresh() {
            console.log("click refresh");
            this.$store.commit("ModApps/jobs/set_actList");
        },
        buttonUp() {
            if (this.active == true) {
                this.active = false;
            } else {
                this.active = true;
            }
            this.searchDdiv = !this.searchDdiv;
            this.hideShowSearch();
            this.$refs.searchbox.focus();
        },
        hideShowSearch() {
            if (this.searchDdiv == false) {
                this.searchDdiv = true;
                document.styleSheets[0].addRule("div.vertical-menu", "display: block");
                document.styleSheets[0].addRule("div.vertical-menus", "display: block");
                document.styleSheets[0].addRule("div.scroll_vertical", "display: block");
            } else {
                this.searchDdiv = false;
                document.styleSheets[0].addRule("div.vertical-menu", "display: none");
                document.styleSheets[0].addRule("div.vertical-menus", "display: none");
                document.styleSheets[0].addRule("div.scroll_vertical", "display: none");
            }
        },
        ShowHiddenAside() {
            this.activar = !this.activar;
            this.$store.commit("ModApps/jobs/ActiveLateral", this.activar);
        },
        dashForm() {
            if (this.$router.currentRoute.name != "Jobs") {
                this.$router.push({ name: "Jobs" });
                this.open = true;
                this.completed = false;
            } else {
                this.$store.commit("ModApps/jobs/set_refresh_list", true);
            }
        },
        goCompleted() {
            if (this.$router.currentRoute.name != "JobsComppleted") {
                this.$router.push({ name: "JobsComppleted" });
                this.open = false;
                this.completed = true;
            } else {
                this.$store.commit("ModApps/jobs/set_refresh_list", true);
            }
        },
        centralSearch(data, m, p) {
            let list = [];
            let n = data.toString();
            if (this.result.length > 0) {
                list = this.result.filter(function (item) {
                    if (p == 1) {
                        if (item.id && item.id.indexOf(n.toLowerCase()) >= 0) {
                            if (item.id == parseInt(n)) {
                                return item;
                            }
                        }
                    } else {
                        if (item.id && item.id.indexOf(n.toLowerCase()) >= 0) {
                            return item;
                        } else if (
                            item.name &&
                            item.name.toLowerCase().indexOf(n.toLowerCase()) >= 0
                        ) {
                            return item;
                        } else if (
                            item.customer_name &&
                            item.customer_name.toLowerCase().indexOf(n.toLowerCase()) >= 0
                        ) {
                            return item;
                        } else if (
                            item.businessname &&
                            item.businessname.toLowerCase().indexOf(n.toLowerCase()) >= 0
                        ) {
                            return item;
                        } else {
                            for (let i in item.taks) {
                                if (
                                    item.taks[i].name
                                        .toLowerCase()
                                        .indexOf(n.toLowerCase()) >= 0
                                ) {
                                    return item;
                                }
                            }
                        }
                    }
                });
            }
            if (m == 1) {
                let o = [];
                o.push({ data: list, boolean: true, all: false });
                this.$store.commit("ModApps/jobs/set_searchJobs", o);
            } else {
                return list;
            }
        },
        searchboxFilter() {
            var t = this;            
            if (t.searchbox != "") {
                t.buscardata = t.searchbox.trim();
                t.isLoading = true;
                t.getJobsDash('searchboxFilter');
            } else {
                t.searchD = false;
                let o = [];
                let data = t.empty;
                o.push({ data: data, boolean: false, all: false });
                t.$store.commit("ModApps/jobs/set_searchJobs", o);
            }
        },
    },
    mounted() {
        var t = this;
        let searchbox = document.querySelector("input.searchbox-input")
        let timeout;
        if(searchbox){
            searchbox.addEventListener('keydown', () => {
                let list = [];
                if (t.searchbox != "") {
                    t.searchDdiv = false;
                    list = t.centralSearch(t.searchbox, 0, 0);
                    t.searchboxData = list;
                }
                clearTimeout(timeout)
                timeout = setTimeout(() => {
                    console.log('has dejado de escribir')
                    t.searchboxFilter();
                    clearTimeout(timeout)
                }, 400)
            })
        }
    },
    computed: {
        ...mapGetters("ModApps/jobs", ["lateral", "get_JOPending", "get_typeVisor"]),
        ...mapGetters("core/apps", ["get_appRolUser", "getAppIDactual"]),
    },
    watch: {
        get_JOPending: function () {
            this.JobsPending = this.get_JOPending;
        },
        $route(to, from) {
            this.url = to.name;
            this.id_tags = [];
            if (this.url == "JobsTags") {
                if (this.$route.params.id > 0) {
                    this.id_tags.push(this.$route.params.id);
                }
            }
            else if (this.url == "JobsComppleted") {
                let getFilterTags=this.$store.getters['ModApps/jobs/getDataFilterTagsUrl'];
                if(getFilterTags.id>0){
                    this.id_tags = [getFilterTags.id];   
                }
            }
        },
        lateral: function () {
            this.activar = this.lateral;
        },
        isLoading: function () {
            console.log("isLoading", this.isLoading);
        },
        get_typeVisor: function () {
            this.visor = this.get_typeVisor;
            // console.log('visor',this.visor)
        },
    },
};
</script>
<style lang="scss" scoped>
span.badge.badge-pill.badge-info.check-done {
    width: 120px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
div.scroll_vertical {
    right: 124px;
    position: fixed;
    margin-top: 31px;
    height: 300px;
    width: 350px;
    overflow: auto;
    border: 1px solid #666;
    background-color: #ccc;
    padding: 8px;
}
div.scroll_vertical ul {
    list-style: none;
}
div.scroll_vertical ul li {
    background-color: #eee;
    color: black;
    display: block;
    padding: 12px;
    text-decoration: none;
    cursor: pointer;
}
div.scroll_vertical ul li:hover {
    background-color: #ccc;
}
.vertical-menus {
    width: 350px;
    height: 300px;
    position: fixed;
    margin-top: 31px;
    right: 124px;
    overflow-x: hidden;
    overflow-y: scroll;
}
.vertical-menus ul li {
    background-color: #eee;
    color: black;
    display: block;
    padding: 12px;
    text-decoration: none;
    cursor: pointer;
}
.vertical-menus .an {
    background-color: #eee;
    color: black;
    display: initial;
    padding: 12px;
    text-decoration: none;
    cursor: pointer;
}
.vertical-menus ul li:hover {
    background-color: #ccc;
}
.vertical-menu {
    width: 350px;
    height: auto;
    position: fixed;
    margin-top: 31px;
    right: 124px;
}
.vertical-menu ul li {
    background-color: #eee;
    color: black;
    display: block;
    padding: 12px;
    text-decoration: none;
    cursor: pointer;
    list-style: none;
}
.vertical-menu .an {
    background-color: #eee;
    color: black;
    display: initial;
    padding: 12px;
    text-decoration: none;
    cursor: pointer;
}
.vertical-menu ul li:hover {
    background-color: #ccc;
}
.vertical-menu ul li.active {
    background-color: #4caf50;
    color: white;
}
#headerJobs {
    top: 40px;
    background-color: #343435 !important;
    padding: 2px 0px 2px 10px;
    width: 100%;
}
#activeMenuLateral {
    display: block;
    margin-right: 10px;
    border: 0px;
    padding: 0;
}
.titulo-cont {
    font-size: 16px;
}
.num-cont {
    font-size: 16px;
    padding: 0px 10px;
    margin-right: 10px;
    font-family: monospace;
}
span.numID {
    cursor: pointer;
}
.searchbox-open {
    width: 100%;
}
.searchbox {
    position: relative;
    min-width: 35px;
    margin-top: 0px;
    height: 31px;
    width: 0%;
    float: right;
    overflow: hidden;
    -webkit-transition: width 0.3s;
    -moz-transition: width 0.3s;
    -ms-transition: width 0.3s;
    -o-transition: width 0.3s;
    transition: width 0.3s;
}
.form-group {
    margin-bottom: 15px;
}
.searchbox-input {
    border-radius: 5px;
    top: 0;
    right: 0;
    border: 0;
    outline: 0;
    background: #fff;
    width: 100%;
    height: 30px;
    margin: 0;
    padding: 0px 55px 0px 20px;
    font-size: 13px;
    color: black;
    margin-top: 2px;
}
.searchbox-icon,
.searchbox-submit {
    width: 35px;
    height: 35px;
    display: block;
    position: absolute;
    top: 0;
    font-family: verdana;
    font-size: 22px;
    right: 0;
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
    line-height: 35px;
    text-align: center;
    cursor: pointer;
    color: #dcddd8;
    background: #343435;
}
.open.searchbox {
    width: 100% !important;
}
.desktop {
    display: inline-block;
}
.mobile {
    display: none;
}
span.badge.badge-pill.badge-light.completed {
    margin-left: 10px;
}
span.badge.badge-pill.badge-light {
    background-color: transparent;
    border: 1px solid #989898;
    color: #989898;
    padding: 0.5em 2em;
    border-radius: 10px;
    cursor: pointer;
}
span.badge.badge-pill.badge-light.active {
    border: 1px solid #ddd;
    color: #ddd;
}
@media (max-width: 800px) {
    .titulo-cont {
        font-size: 14px;
    }
    span.badge.badge-pill.badge-light {
        padding: 0.5em 1em;
    }
}
@media (max-width: 767px) {
    .desktop {
        display: none;
    }
    .mobile {
        display: initial;
    }
    li.nav-item .nav-link span.mobile {
        margin-left: 5px;
    }
    .searchbox {
        float: left;
        min-width: 84px;
    }
    .searchbox-submit {
        width: 90px;
        left: 0;
        right: initial;
        text-align: left;
    }
    .searchbox-icon {
        right: initial;
        left: -5px;
    }
    .searchbox-submit span.mobile {
        color: #d2d2d2;
        position: absolute;
        margin-left: 30px;
        font-size: 0.875rem;
    }
    .searchbox-input {
        border: 0px;
        box-shadow: none;
        margin-left: 80px;
        width: 74%;
        padding-right: 10px;
    }
}
.opAddHeader button {
    background: transparent;
    color: #fff;
    border: 0px;
    padding: 0px 5px;
}
.opAddHeader button:before {
    content: "";
    border-top: 0px;
}
.opAddHeader ul li,
.opAddHeader ul li:hover {
    color: #555;
}
@media (max-width: 767px){
    .opAddHeader ul {
        position: absolute;
        left: 0;
        right: initial;
    }
}
</style>
