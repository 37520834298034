<template>
    <div class="container-fluid" style="padding-top: 0px">
        <div v-if="isLoading" class="d-flex align-items-center justify-content-center">
            <Loader></Loader>
        </div>
        <div v-else class="row">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-5 align-self-center">
                                <h4 class="page-title">
                                    <i class="fas fa-user-edit"></i> Edit Form
                                </h4>
                            </div>
                            <div class="col-7 align-self-center">
                                <div class="d-flex align-items-right justify-content-end">
                                    <nav aria-label="breadcrumb">
                                        <ol
                                            class="breadcrumb"
                                            style="padding: 0; margin: 0"
                                        >
                                            <li class="breadcrumb-item">
                                                <router-link
                                                    :to="{ name: 'listForms' }"
                                                    class="btn btn-secondary btn-sm mr-2"
                                                >
                                                    <i class="fas fa-arrow-left"></i> Back
                                                </router-link>
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body cuerpo-form">
                        <div
                            v-if="formLoading"
                            class="d-flex align-items-center justify-content-center"
                        >
                            <Loader></Loader>
                        </div>
                        <form
                            v-else
                            autocomplete="off"
                            @submit.prevent="return false;"
                            :name="form.name"
                            :class="'builder ' + form.class"
                        >
                            <draggable
                                v-model="inputs"
                                tag="div"
                                class="editForm"
                                v-bind="dragOptions"
                                @change="moveInput"
                                @start="isDragging = true"
                                @end="isDragging = false"
                                handle=".handle"
                                v-if="inputs.length"
                            >
                                <div
                                    v-for="(i, index) in inputs"
                                    :key="index"
                                    v-bind:class="[
                                        { inputActive: inputActive == index },
                                        'form-group ' + i.label_width,
                                    ]"
                                    style=""
                                >
                                    <div class="handle" style="text-align: center">
                                        <i class="fas fa-ellipsis-v"></i>
                                    </div>
                                    <div @click="edit(i, index)" class="edit_input"></div>
                                    <label
                                        :for="i.label_for"
                                        :style="
                                            'display:' +
                                            (i.label_display == 1 ? 'inline' : 'none') +
                                            ';' +
                                            i.label_style
                                        "
                                    >
                                        {{ i.label_name }}
                                    </label>
                                    <input
                                        v-if="i.campo_type == 'text'"
                                        type="text"
                                        :class="
                                            'form-control ' +
                                            i.campo_class +
                                            ' ' +
                                            i.campo_error
                                        "
                                        :id="i.campo_id"
                                        :name="i.campo_name"
                                        v-bind:required="i.campo_required ? true : false"
                                        :placeholder="i.campo_placeholder"
                                        :style="''+i.campo_style"
                                        :value="i.campo_value"
                                        autocomplete="off"
                                        disabled
                                    />
                                    <textarea
                                        v-if="i.campo_type == 'textarea'"
                                        :class="['form-control ' + i.campo_class]"
                                        :id="i.campo_id"
                                        :name="i.campo_name"
                                        v-bind:required="i.campo_required ? true : false"
                                        :placeholder="i.campo_placeholder"
                                        :style="i.campo_style"
                                        v-model="i.campo_value"
                                        disabled
                                    ></textarea>
                                    <select
                                        v-if="i.campo_type == 'select'"
                                        :class="'form-control ' + i.campo_class"
                                        :id="i.campo_id"
                                        :name="i.campo_name"
                                        :style="i.campo_style"
                                        disabled
                                    >
                                        <option
                                            v-for="(o, ondex) in i.options"
                                            :key="ondex"
                                        >
                                            {{ o }}
                                        </option>
                                    </select>
                                    <div v-if="i.campo_type == 'radio'">
                                        <label
                                            class="c-input c-radio"
                                            v-for="(o, ondex) in i.options"
                                            :key="ondex"
                                        >
                                            <input
                                                type="radio"
                                                :id="i.campo_id"
                                                :value="o"
                                                :name="i.campo_name"
                                            />
                                            <span
                                                class="c-indicator"
                                                :style="i.campo_style"
                                            >
                                                {{ o }}</span
                                            >
                                        </label>
                                    </div>
                                    <label
                                        v-if="i.campo_type == 'checkbox'"
                                        style="display: block"
                                    >
                                        <label
                                            v-for="(o, ondex) in i.options"
                                            :key="ondex"
                                        >
                                            <input
                                                type="checkbox"
                                                :id="i.campo_id"
                                                :value="o"
                                                :name="i.campo_name"
                                            />
                                            <span :style="i.campo_style"> {{ o }}</span>
                                        </label>
                                    </label>
                                    <button
                                        type="button"
                                        v-if="i.campo_type == 'submit'"
                                        :id="i.campo_id"
                                        :class="i.campo_class + ' ' + i.campo_error"
                                        :style="i.campo_style"
                                    >
                                        {{ i.campo_value }}
                                    </button>
                                </div>
                            </draggable>
                        </form>
                    </div>
                    <!-- <div class="card-footer"></div> -->
                </div>
            </div>
            <div class="col-md-4">
                <div class="card">
                    <form @submit.prevent="setForm(1)">
                        <div
                            class="btn-group btn-group-lg"
                            role="group"
                            aria-label="Basic example"
                        >
                            <button
                                type="button"
                                @click="menuTab(1)"
                                v-bind:class="[{ active: editarForm }, 'btn btn-light']"
                            >
                                Setting
                            </button>
                            <!-- <button type="button" @click="menuTab(2)" v-bind:class="[{ active: editarDesigns }, 'btn btn-light']">Designs</button> -->
                            <button
                                type="button"
                                @click="menuTab(4)"
                                v-bind:class="[
                                    { active: editarNotis },
                                    'btn btn-light',
                                ]"
                            >
                                Noti
                            </button>
                            <button
                                type="button"
                                @click="menuTab(5)"
                                v-bind:class="[
                                    { active: editarAlert },
                                    'btn btn-light',
                                ]"
                            >
                                Alerts
                            </button>
                            <button
                                type="button"
                                @click="menuTab(3)"
                                v-bind:class="[
                                    { active: editaCustomCSS },
                                    'btn btn-light',
                                ]"
                            >
                                CSS
                            </button>
                        </div>
                        <div class="card-header">
                            <div class="row">
                                <div class="col-12 align-self-center">
                                    <div
                                        class="d-flex align-items-right justify-content-end"
                                    >
                                        <nav aria-label="breadcrumb">
                                            <ol
                                                class="breadcrumb"
                                                style="padding: 0; margin: 0"
                                                v-if="editarForm"
                                            >
                                                <li class="breadcrumb-item">
                                                    <span
                                                        class="cdropdown bg-info content_create_inputs"
                                                    >
                                                        <button class="btn btn-sm mr-2">
                                                            <i class="fas fa-plus"></i>
                                                            New
                                                        </button>
                                                        <label>
                                                            <input type="checkbox" />
                                                            <ul>
                                                                <li
                                                                    @click="
                                                                        createInput(1)
                                                                    "
                                                                >
                                                                    <i
                                                                        class="icon_input"
                                                                    ></i>
                                                                    Input Text
                                                                </li>
                                                                <li
                                                                    @click="
                                                                        createInput(2)
                                                                    "
                                                                >
                                                                    <i
                                                                        class="icon_textarea"
                                                                    ></i>
                                                                    Textarea
                                                                </li>
                                                                <li @click="createInput(6)">
                                                                    <i class="fas fa-map-marked-alt"></i>
                                                                    G-Address
                                                                </li>
                                                                <li
                                                                    @click="
                                                                        createInput(3)
                                                                    "
                                                                >
                                                                    <i
                                                                        class="fas fa-caret-square-down"
                                                                    ></i>
                                                                    Dropdown
                                                                </li>
                                                                <li
                                                                    @click="
                                                                        createInput(4)
                                                                    "
                                                                >
                                                                    <i
                                                                        class="icon_radio"
                                                                    ></i>
                                                                    Radio button
                                                                </li>
                                                                <li
                                                                    @click="
                                                                        createInput(5)
                                                                    "
                                                                >
                                                                    <i
                                                                        class="fas fa-check-square"
                                                                    ></i>
                                                                    Checkbox
                                                                </li>
                                                            </ul>
                                                        </label>
                                                    </span>
                                                </li>
                                                <li class="breadcrumb-item">
                                                    <button
                                                        type="submit"
                                                        class="btn btn-success btn-sm mr-2"
                                                        :disabled="btnsave"
                                                    >
                                                        <i class="fas fa-save"></i> Save
                                                    </button>
                                                </li>
                                                <li
                                                    v-if="editarCampo"
                                                    class="breadcrumb-item"
                                                >
                                                    <button
                                                        type="button"
                                                        class="btn btn-danger btn-sm mr-2"
                                                        :disabled="btnsave"
                                                        @click="delInput"
                                                    >
                                                        <i class="fas fa-trash"></i>
                                                        Delete
                                                    </button>
                                                </li>
                                                <li
                                                    v-if="editarCampo"
                                                    class="breadcrumb-item"
                                                >
                                                    <button
                                                        type="button"
                                                        class="btn btn-primary btn-sm mr-2"
                                                        :disabled="btnsave"
                                                        @click="cancelInput"
                                                    >
                                                        <i class="fas fa-times"></i>
                                                        cancel
                                                    </button>
                                                </li>
                                            </ol>
                                            <ol
                                                class="breadcrumb"
                                                style="padding: 0; margin: 0"
                                                v-if="editarDesigns"
                                            >
                                                <li class="breadcrumb-item">
                                                    <button
                                                        type="button"
                                                        class="btn btn-success btn-sm"
                                                        @click="refreshStyle"
                                                    >
                                                        <i class="fa fa-save"></i> Save
                                                        design
                                                    </button>
                                                </li>
                                                <li
                                                    v-if="styleOld != null"
                                                    class="breadcrumb-item"
                                                >
                                                    <button
                                                        type="button"
                                                        class="btn btn-primary btn-sm mr-2"
                                                        @click="cancelDesign"
                                                        style="margin-left: 10px"
                                                    >
                                                        <i class="fas fa-times"></i>
                                                        cancel
                                                    </button>
                                                </li>
                                            </ol>
                                            <ol
                                                class="breadcrumb"
                                                style="padding: 0; margin: 0"
                                                v-if="editaCustomCSS"
                                            >
                                                <li class="breadcrumb-item">
                                                    <button
                                                        type="button"
                                                        class="btn btn-success btn-sm"
                                                        @click="refreshStyle"
                                                    >
                                                        <i class="fa fa-save"></i> Save
                                                        custom css
                                                    </button>
                                                </li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body lateral-config">
                            <div class="cuerpo-lateral">
                                <div v-if="editarForm">
                                    <div class="form-general" v-if="editarForm">
                                        <div
                                            class="card-header label-header"
                                            @click="showform"
                                        >
                                            <i class="fa fa-minus" v-if="formConfig"></i>
                                            <i class="fa fa-plus" v-else></i>
                                            <span>FORM SETTING</span>
                                        </div>
                                        <div
                                            v-bind:class="[
                                                { activeLista: formConfig },
                                                'label-config',
                                            ]"
                                        >
                                            <div
                                                class="form-group"
                                                style="padding: 0px; padding-right: 20px"
                                            >
                                                <label for="">Form Name <small>(Internal Use)</small>:</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="form.name"
                                                />
                                            </div>
                                            <div
                                                class="form-group"
                                                style="padding: 0px; padding-right: 20px"
                                            >
                                                <label for="">Title <small>(Public Use)</small>:</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="form.email_name"
                                                />
                                            </div>
                                            <div
                                                class="form-group"
                                                style="padding: 0px; padding-right: 20px"
                                            >
                                                <label for="">From <small>(Sender)</small>:</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="form.email"
                                                />
                                            </div>
                                            <div
                                                class="form-group"
                                                style="padding: 0px; padding-right: 20px"
                                            >
                                                <label for="">Subject:</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="form.subject"
                                                />
                                            </div>
                                            <div
                                                class="form-group"
                                                style="padding: 0px; padding-right: 20px;display:none;"
                                            >
                                                <label for="">Class:</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="form.class"
                                                />
                                            </div>
                                            <div
                                                class="form-group"
                                                style="padding: 0px; padding-right: 20px;display:none;"
                                            >
                                                <label for="">Anti-spam validator: </label>
                                                <br>
                                                <label class="c-input c-radio">
                                                    <input
                                                        id="radio2"
                                                        type="radio"
                                                        value="0"
                                                        v-model="form.recaptcha"
                                                    />
                                                    <span class="c-indicator"></span>
                                                    None
                                                </label><br>
                                                <label class="c-input c-radio">
                                                    <input
                                                        id="radio1"
                                                        type="radio"
                                                        value="1"
                                                        v-model="form.recaptcha"
                                                    />
                                                    <span class="c-indicator"></span>
                                                    Google Recaptcha
                                                </label><br>
                                                <!--<label class="c-input c-radio">
                                                    <input
                                                        id="radio1"
                                                        type="radio"
                                                        value="2"
                                                        v-model="form.recaptcha"
                                                    />
                                                    <span class="c-indicator"></span>
                                                    Validation with Numbers
                                                </label>-->
                                            </div>
                                            <div
                                                class="form-group"
                                                style="padding: 0px; padding-right: 20px"
                                                v-if="form.recaptcha == 1"
                                            >
                                                <label for="">Key website:</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="form.key_public"
                                                />
                                            </div>
                                            <div
                                                class="form-group"
                                                style="padding: 0px; padding-right: 20px"
                                                v-if="form.recaptcha == 1"
                                            >
                                                <label for="">Key secret:</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="form.key_secert"
                                                />
                                            </div>
                                            <div
                                                class="form-group"
                                                style="padding: 0px; padding-right: 20px;display:none;"
                                            >
                                                <label for="">Style:</label>
                                                <textarea
                                                    class="form-control"
                                                    v-model="form.style"
                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        v-if="
                                            editarCampo &&
                                            editinput.campo_type != 'submit'
                                        "
                                        class="label-content"
                                    >
                                        <div
                                            class="card-header label-header"
                                            @click="showLabel"
                                        >
                                            <i class="fa fa-minus" v-if="labelConfig"></i>
                                            <i class="fa fa-plus" v-else></i>
                                            <span>LABEL</span>
                                        </div>
                                        <div
                                            v-bind:class="[
                                                { activeLista: labelConfig },
                                                'label-config',
                                            ]"
                                            v-if="editinput.campo_type != 'submit'"
                                        >
                                            <div
                                                class="form-group"
                                                style="padding: 0px; padding-right: 20px"
                                            >
                                                <label for="">Label name:</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="editinput.label_name"
                                                />
                                            </div>
                                            <div
                                                class="form-group"
                                                style="padding: 0px; padding-right: 20px"
                                            >
                                                <label for="">Show Label: </label>
                                                <label class="c-input c-radio">
                                                    <input
                                                        id="radio1"
                                                        type="radio"
                                                        value="1"
                                                        v-model="editinput.label_display"
                                                    />
                                                    <span class="c-indicator"></span>
                                                    Yes
                                                </label>
                                                <label class="c-input c-radio">
                                                    <input
                                                        id="radio2"
                                                        type="radio"
                                                        value="0"
                                                        v-model="editinput.label_display"
                                                    />
                                                    <span class="c-indicator"></span>
                                                    No
                                                </label>
                                            </div>
                                            <div
                                                class="form-group"
                                                style="padding: 0px; padding-right: 20px"
                                                v-if="editinput.campo_id!='address-google'"
                                            >
                                                <label for="">Label width: </label>
                                                <label class="c-input c-radio">
                                                    <input
                                                        id="radio1"
                                                        type="radio"
                                                        value="c1-1"
                                                        v-model="editinput.label_width"
                                                    />
                                                    <span class="c-indicator"></span>
                                                    1/1
                                                </label>
                                                <label class="c-input c-radio">
                                                    <input
                                                        id="radio2"
                                                        type="radio"
                                                        value="c1-2"
                                                        v-model="editinput.label_width"
                                                    />
                                                    <span class="c-indicator"></span>
                                                    1/2
                                                </label>
                                                <label class="c-input c-radio">
                                                    <input
                                                        id="radio2"
                                                        type="radio"
                                                        value="c1-3"
                                                        v-model="editinput.label_width"
                                                    />
                                                    <span class="c-indicator"></span>
                                                    1/3
                                                </label>
                                                <label class="c-input c-radio">
                                                    <input
                                                        id="radio2"
                                                        type="radio"
                                                        value="c1-4"
                                                        v-model="editinput.label_width"
                                                    />
                                                    <span class="c-indicator"></span>
                                                    3/4
                                                </label>
                                            </div>
                                            <div
                                                class="form-group"
                                                style="padding: 0px; padding-right: 20px;display:none;"
                                                v-if="editinput.campo_id!='address-google'"
                                            >
                                                <label for="">Label ID:</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="editinput.label_id"
                                                />
                                            </div>
                                            <div
                                                class="form-group"
                                                style="padding: 0px; padding-right: 20px;display:none;"
                                                v-if="editinput.campo_id!='address-google'"
                                            >
                                                <label for="">Label class:</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="editinput.label_class"
                                                />
                                            </div>

                                            <div
                                                class="form-group"
                                                style="padding: 0px; padding-right: 20px;display:none;"
                                                v-if="editinput.campo_id!='address-google'"
                                            >
                                                <label for="">Label style:</label>
                                                <textarea
                                                    class="form-control"
                                                    v-model="editinput.label_style"
                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="editarCampo" class="campo-content">
                                        <div
                                            class="card-header label-header"
                                            @click="showCampo"
                                        >
                                            <i class="fa fa-minus" v-if="campoConfig"></i>
                                            <i class="fa fa-plus" v-else></i>
                                            <span>INPUT</span>
                                        </div>
                                        <div
                                            v-bind:class="[
                                                { activeLista: campoConfig },
                                                'label-config',
                                            ]"
                                        >
                                            <div
                                                class="form-group"
                                                style="padding: 0px; padding-right: 20px;display:none;"
                                                v-if="editinput.campo_id!='address-google'"
                                            >
                                                <label for="">ID:</label>
                                                <input
                                                    type="text"
                                                    :class="
                                                        'form-control ' +
                                                        editinput.campo_error
                                                    "
                                                    v-model="editinput.campo_id"
                                                />
                                            </div>
                                            <div
                                                class="form-group"
                                                style="padding: 0px; padding-right: 20px;display:none;"
                                                v-if="editinput.campo_id!='address-google'"
                                            >
                                                <label for="">NAME:</label>
                                                <input
                                                    type="text"
                                                    :class="
                                                        'form-control ' +
                                                        editinput.campo_error
                                                    "
                                                    v-model="editinput.campo_name"
                                                />
                                            </div>
                                            <div
                                                class="form-group"
                                                style="padding: 0px; padding-right: 20px;display:none;"
                                                v-if="editinput.campo_id!='address-google'"
                                            >
                                                <label for="">Class:</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="editinput.campo_class"
                                                />
                                            </div>
                                            <div
                                                class="form-group"
                                                style="padding: 0px; padding-right: 20px"
                                                v-if="editinput.campo_type != 'submit' && editinput.campo_id!='address-google'"
                                            >
                                                <label for="">Required: </label>
                                                <label class="c-input c-radio">
                                                    <input
                                                        id="radio1"
                                                        type="radio"
                                                        value="1"
                                                        v-model="editinput.campo_required"
                                                    />
                                                    <span class="c-indicator"></span>
                                                    Yes
                                                </label>
                                                <label class="c-input c-radio">
                                                    <input
                                                        id="radio2"
                                                        type="radio"
                                                        value="0"
                                                        v-model="editinput.campo_required"
                                                    />
                                                    <span class="c-indicator"></span>
                                                    No
                                                </label>
                                            </div>
                                            <div
                                                class="form-group"
                                                style="padding: 0px; padding-right: 20px"
                                                v-if="editinput.campo_id=='address-google'"
                                            >
                                                <label for="">Show Map: </label>
                                                <label class="c-input c-radio">
                                                    <input
                                                        id="radio2"
                                                        type="radio"
                                                        value="0"
                                                        v-model="editinput.showMap"
                                                    />
                                                    <span class="c-indicator"></span>
                                                    No
                                                </label>
                                                <label class="c-input c-radio">
                                                    <input
                                                        id="radio1"
                                                        type="radio"
                                                        value="1"
                                                        v-model="editinput.showMap"
                                                    />
                                                    <span class="c-indicator"></span>
                                                    Yes
                                                </label>
                                            </div>
                                            <div
                                                class="form-group"
                                                style="padding: 0px; padding-right: 20px"
                                                v-if="editinput.campo_type == 'text' || editinput.campo_type == 'textarea'"
                                            >
                                                <label for="">Placeholder:</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="editinput.campo_placeholder"
                                                />
                                            </div>
                                            <div
                                                class="form-group"
                                                style="padding: 0px; padding-right: 20px"
                                                v-if="editinput.campo_type=='submit'"
                                            >
                                                <label for="">Text Button:</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="editinput.campo_value"
                                                />
                                            </div>
                                            <div
                                                v-if="
                                                    editinput.campo_type == 'select' ||
                                                    editinput.campo_type == 'radio' ||
                                                    editinput.campo_type == 'checkbox'
                                                "
                                            >
                                                <div
                                                    class="form-group"
                                                    style="
                                                        padding: 0px;
                                                        padding-right: 20px;
                                                    "
                                                    v-if="editinput.options.length > 0"
                                                >
                                                    <label for="">Options:</label>
                                                    <draggable
                                                        v-model="editinput.options"
                                                        tag="div"
                                                        class="editForm"
                                                        v-bind="dragOptions"
                                                        @start="isDragging = true"
                                                        @end="isDragging = false"
                                                        handle=".handle"
                                                        v-if="editinput.options.length"
                                                    >
                                                        <div
                                                            class="input-group"
                                                            v-for="(
                                                                o, x
                                                            ) in editinput.options"
                                                            :key="x"
                                                        >
                                                            <span
                                                                class="input-group-addon handle han-nohover"
                                                            >
                                                                <i
                                                                    class="fa fa-ellipsis-v"
                                                                ></i>
                                                            </span>
                                                            <input
                                                                type="text"
                                                                class="form-control opcionsInputs"
                                                                v-model="
                                                                    editinput.options[x]
                                                                "
                                                            />
                                                            <span
                                                                class="input-group-addon btn btn-danger delOption"
                                                                @click="delOption(o)"
                                                            >
                                                                <i
                                                                    class="fa fa-times"
                                                                ></i>
                                                            </span>
                                                        </div>
                                                    </draggable>
                                                    <button
                                                        type="button"
                                                        class="btn btn-info btn-sm"
                                                        @click="addOptions"
                                                    >
                                                        <i class="fa fa-plus"></i> Options
                                                    </button>
                                                </div>
                                            </div>
                                            <div
                                                class="form-group"
                                                style="padding: 0px; padding-right: 20px;display:none;"
                                                v-if="editinput.campo_id!='address-google'"
                                            >
                                                <label for="">Style:</label>
                                                <textarea
                                                    class="form-control"
                                                    v-model="editinput.campo_style"
                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="editarDesigns">
                                    <div v-for="(i, index) in designs" :key="index">
                                        <div
                                            class="card-header label-header"
                                            @click="activeDesign(index)"
                                        >
                                            <i
                                                class="fa fa-ban"
                                                v-if="designsOp != styleActive"
                                            ></i>
                                            <i class="fa fa-check-circle" v-else></i>
                                            <span>STYLE 1</span>
                                        </div>
                                        <div
                                            v-bind:class="[
                                                { activeLista: designsOp == styleActive },
                                                'label-config',
                                            ]"
                                            style="padding: 0px 0px 30px 15px"
                                        >
                                            <div
                                                class="card-header label-header"
                                                @click="designsLabels"
                                            >
                                                <i
                                                    class="fa fa-minus"
                                                    v-if="designLabel"
                                                ></i>
                                                <i class="fa fa-plus" v-else></i>
                                                <span>Design Label:</span>
                                            </div>
                                            <div
                                                v-bind:class="[
                                                    { activeLista: designLabel },
                                                    'label-config',
                                                ]"
                                            >
                                                <div
                                                    class="form-group"
                                                    style="
                                                        padding: 0px;
                                                        padding-right: 20px;
                                                    "
                                                >
                                                    <label for="">Label Text size:</label>
                                                    <select
                                                        v-model="i.label_size"
                                                        class="form-control"
                                                    >
                                                        <option value="10">10</option>
                                                        <option value="12">12</option>
                                                        <option value="14">14</option>
                                                        <option value="16">16</option>
                                                        <option value="18">18</option>
                                                        <option value="20">20</option>
                                                        <option value="22">22</option>
                                                        <option value="24">24</option>
                                                        <option value="26">26</option>
                                                        <option value="28">28</option>
                                                        <option value="30">30</option>
                                                        <option value="32">32</option>
                                                        <option value="34">34</option>
                                                        <option value="36">36</option>
                                                    </select>
                                                </div>
                                                <div
                                                    class="form-group"
                                                    style="
                                                        padding: 0px;
                                                        padding-right: 20px;
                                                    "
                                                >
                                                    <label for=""
                                                        >Label Text weigth:</label
                                                    >
                                                    <select
                                                        v-model="i.label_weight"
                                                        class="form-control"
                                                    >
                                                        <option value="400">
                                                            Normal
                                                        </option>
                                                        <option value="bold">Bold</option>
                                                    </select>
                                                </div>
                                                <div class="form-group campoColor">
                                                    <label class="labelColor"
                                                        >Label Text color:</label
                                                    >
                                                    <input
                                                        type="text"
                                                        class="form-control boxcolor"
                                                        v-model="i.label_color"
                                                        disabled
                                                        @click="
                                                            picker('label', i.label_color)
                                                        "
                                                    />
                                                    <button
                                                        type="button"
                                                        class="btn btn-light btn-xs btn-color muestra"
                                                        @click="
                                                            picker('label', i.label_color)
                                                        "
                                                        v-if="showPickerLavel == false"
                                                        :style="mLabelColor"
                                                    ></button>
                                                    <button
                                                        type="button"
                                                        class="btn btn-success btn-xs btn-color"
                                                        @click="
                                                            picker('label', i.label_color)
                                                        "
                                                        v-else
                                                    >
                                                        Ok
                                                    </button>
                                                    <button
                                                        type="button"
                                                        class="btn btn-danger btn-xs btn-color"
                                                        @click="
                                                            oldPicker(
                                                                i.label_color,
                                                                mLabelColor,
                                                                'label'
                                                            )
                                                        "
                                                        v-if="showPickerLavel"
                                                    >
                                                        X
                                                    </button>
                                                    <chrome-picker
                                                        v-model="getcolorLabel"
                                                        v-if="showPickerLavel"
                                                        class="getcolor"
                                                    ></chrome-picker>
                                                </div>
                                            </div>
                                            <div
                                                class="card-header label-header"
                                                @click="designsInputs"
                                            >
                                                <i
                                                    class="fa fa-minus"
                                                    v-if="designInput"
                                                ></i>
                                                <i class="fa fa-plus" v-else></i>
                                                <span>Design Input:</span>
                                            </div>
                                            <div
                                                v-bind:class="[
                                                    { activeLista: designInput },
                                                    'label-config',
                                                ]"
                                            >
                                                <div
                                                    class="form-group"
                                                    style="
                                                        padding: 0px;
                                                        padding-right: 20px;
                                                    "
                                                >
                                                    <label for="">Input Text size:</label>
                                                    <select
                                                        v-model="i.input_size"
                                                        class="form-control"
                                                    >
                                                        <option value="10">10</option>
                                                        <option value="12">12</option>
                                                        <option value="14">14</option>
                                                        <option value="16">16</option>
                                                        <option value="18">18</option>
                                                        <option value="20">20</option>
                                                        <option value="22">22</option>
                                                        <option value="24">24</option>
                                                        <option value="26">26</option>
                                                        <option value="28">28</option>
                                                        <option value="30">30</option>
                                                        <option value="32">32</option>
                                                        <option value="34">34</option>
                                                        <option value="36">36</option>
                                                    </select>
                                                </div>
                                                <div
                                                    class="form-group"
                                                    style="
                                                        padding: 0px;
                                                        padding-right: 20px;
                                                    "
                                                >
                                                    <label for=""
                                                        >Input Text weigth:</label
                                                    >
                                                    <select
                                                        v-model="i.input_weight"
                                                        class="form-control"
                                                    >
                                                        <option value="400">
                                                            Normal
                                                        </option>
                                                        <option value="bold">Bold</option>
                                                    </select>
                                                </div>
                                                <div class="form-group campoColor">
                                                    <label class="labelColor"
                                                        >Input Text color:</label
                                                    >
                                                    <input
                                                        type="text"
                                                        class="form-control boxcolor"
                                                        v-model="i.input_color"
                                                        disabled
                                                        @click="
                                                            picker(
                                                                'inputText',
                                                                i.input_color
                                                            )
                                                        "
                                                    />
                                                    <button
                                                        type="button"
                                                        class="btn btn-light btn-xs btn-color muestra"
                                                        @click="
                                                            picker(
                                                                'inputText',
                                                                i.input_color
                                                            )
                                                        "
                                                        v-if="
                                                            showPickerInputText == false
                                                        "
                                                        :style="mInputColor"
                                                    ></button>
                                                    <button
                                                        type="button"
                                                        class="btn btn-success btn-xs btn-color"
                                                        @click="
                                                            picker(
                                                                'inputText',
                                                                i.input_color
                                                            )
                                                        "
                                                        v-else
                                                    >
                                                        Ok
                                                    </button>
                                                    <button
                                                        type="button"
                                                        class="btn btn-danger btn-xs btn-color"
                                                        @click="
                                                            oldPicker(
                                                                i.input_color,
                                                                mInputColor,
                                                                'inputText'
                                                            )
                                                        "
                                                        v-if="showPickerInputText"
                                                    >
                                                        X
                                                    </button>
                                                    <chrome-picker
                                                        v-model="getcolorInputText"
                                                        v-if="showPickerInputText"
                                                        class="getcolor"
                                                    ></chrome-picker>
                                                </div>
                                                <div class="form-group campoColor">
                                                    <label class="labelColor"
                                                        >Input Border color:</label
                                                    >
                                                    <input
                                                        type="text"
                                                        class="form-control boxcolor"
                                                        v-model="i.input_border_color"
                                                        disabled
                                                        @click="
                                                            picker(
                                                                'inputBorder',
                                                                i.input_border_color
                                                            )
                                                        "
                                                    />
                                                    <button
                                                        type="button"
                                                        class="btn btn-light btn-xs btn-color muestra"
                                                        @click="
                                                            picker(
                                                                'inputBorder',
                                                                i.input_border_color
                                                            )
                                                        "
                                                        v-if="
                                                            showPickerInputBorder == false
                                                        "
                                                        :style="mInputBorder"
                                                    ></button>
                                                    <button
                                                        type="button"
                                                        class="btn btn-success btn-xs btn-color"
                                                        @click="
                                                            picker(
                                                                'inputBorder',
                                                                i.input_border_color
                                                            )
                                                        "
                                                        v-else
                                                    >
                                                        Ok
                                                    </button>
                                                    <button
                                                        type="button"
                                                        class="btn btn-danger btn-xs btn-color"
                                                        @click="
                                                            oldPicker(
                                                                i.input_border_color,
                                                                mInputBorder,
                                                                'inputBorder'
                                                            )
                                                        "
                                                        v-if="showPickerInputBorder"
                                                    >
                                                        X
                                                    </button>
                                                    <chrome-picker
                                                        v-model="getcolorInputBorder"
                                                        v-if="showPickerInputBorder"
                                                        class="getcolor"
                                                    ></chrome-picker>
                                                </div>
                                                <div
                                                    class="form-group"
                                                    style="
                                                        padding: 0px;
                                                        padding-right: 20px;
                                                    "
                                                >
                                                    <label for=""
                                                        >Placeholder Text size:</label
                                                    >
                                                    <select
                                                        v-model="i.input_place_size"
                                                        class="form-control"
                                                    >
                                                        <option value="10">10</option>
                                                        <option value="12">12</option>
                                                        <option value="14">14</option>
                                                        <option value="16">16</option>
                                                        <option value="18">18</option>
                                                        <option value="20">20</option>
                                                        <option value="22">22</option>
                                                        <option value="24">24</option>
                                                        <option value="26">26</option>
                                                        <option value="28">28</option>
                                                        <option value="30">30</option>
                                                        <option value="32">32</option>
                                                        <option value="34">34</option>
                                                        <option value="36">36</option>
                                                    </select>
                                                </div>
                                                <!-- NO funciona, el weight del input predomina
													<div class="form-group" style="padding:0px;padding-right:20px;">
													<label for="">Placeholder Text weigth:</label>
													<select v-model="i.input_place_weight" class="form-control">
														<option value="400">Normal</option>
														<option value="bold">Bold</option>
													</select>
													</div> 
												-->
                                                <div class="form-group campoColor">
                                                    <label class="labelColor"
                                                        >Placeholder Text color:</label
                                                    >
                                                    <input
                                                        type="text"
                                                        class="form-control boxcolor"
                                                        v-model="i.input_place_color"
                                                        disabled
                                                        @click="
                                                            picker(
                                                                'place',
                                                                i.input_place_color
                                                            )
                                                        "
                                                    />
                                                    <button
                                                        type="button"
                                                        class="btn btn-light btn-xs btn-color muestra"
                                                        @click="
                                                            picker(
                                                                'place',
                                                                i.input_place_color
                                                            )
                                                        "
                                                        v-if="showPickerPlace == false"
                                                        :style="mPlace"
                                                    ></button>
                                                    <button
                                                        type="button"
                                                        class="btn btn-success btn-xs btn-color"
                                                        @click="
                                                            picker(
                                                                'place',
                                                                i.input_place_color
                                                            )
                                                        "
                                                        v-else
                                                    >
                                                        Ok
                                                    </button>
                                                    <button
                                                        type="button"
                                                        class="btn btn-danger btn-xs btn-color"
                                                        @click="
                                                            oldPicker(
                                                                i.input_place_color,
                                                                mPlace,
                                                                'place'
                                                            )
                                                        "
                                                        v-if="showPickerPlace"
                                                    >
                                                        X
                                                    </button>
                                                    <chrome-picker
                                                        v-model="getcolorPlace"
                                                        v-if="showPickerPlace"
                                                        class="getcolor"
                                                    ></chrome-picker>
                                                </div>
                                            </div>
                                            <div
                                                class="card-header label-header"
                                                @click="designsBtns"
                                            >
                                                <i
                                                    class="fa fa-minus"
                                                    v-if="designBtn"
                                                ></i>
                                                <i class="fa fa-plus" v-else></i>
                                                <span>Design Btn:</span>
                                            </div>
                                            <div
                                                v-bind:class="[
                                                    { activeLista: designBtn },
                                                    'label-config',
                                                ]"
                                            >
                                                <div
                                                    class="form-group"
                                                    style="
                                                        padding: 0px;
                                                        padding-right: 20px;
                                                    "
                                                >
                                                    <label for="">Btn size:</label>
                                                    <select
                                                        v-model="i.btn_size"
                                                        class="form-control"
                                                    >
                                                        <option value="10">10</option>
                                                        <option value="12">12</option>
                                                        <option value="14">14</option>
                                                        <option value="16">16</option>
                                                        <option value="18">18</option>
                                                        <option value="20">20</option>
                                                        <option value="22">22</option>
                                                        <option value="24">24</option>
                                                        <option value="26">26</option>
                                                        <option value="28">28</option>
                                                        <option value="30">30</option>
                                                        <option value="32">32</option>
                                                        <option value="34">34</option>
                                                        <option value="36">36</option>
                                                    </select>
                                                </div>
                                                <div
                                                    class="form-group"
                                                    style="
                                                        padding: 0px;
                                                        padding-right: 20px;
                                                    "
                                                >
                                                    <label for="">Btn weigth:</label>
                                                    <select
                                                        v-model="i.btn_weight"
                                                        class="form-control"
                                                    >
                                                        <option value="400">
                                                            Normal
                                                        </option>
                                                        <option value="bold">Bold</option>
                                                    </select>
                                                </div>
                                                <div class="form-group campoColor">
                                                    <label class="labelColor"
                                                        >Btn Text color:</label
                                                    >
                                                    <input
                                                        type="text"
                                                        class="form-control boxcolor"
                                                        v-model="i.btn_color"
                                                        disabled
                                                        @click="
                                                            picker(
                                                                'btnColor',
                                                                i.btn_color
                                                            )
                                                        "
                                                    />
                                                    <button
                                                        type="button"
                                                        class="btn btn-light btn-xs btn-color muestra"
                                                        @click="
                                                            picker(
                                                                'btnColor',
                                                                i.btn_color
                                                            )
                                                        "
                                                        v-if="showPickerBtnColor == false"
                                                        :style="mBtnColor"
                                                    ></button>
                                                    <button
                                                        type="button"
                                                        class="btn btn-success btn-xs btn-color"
                                                        @click="
                                                            picker(
                                                                'btnColor',
                                                                i.btn_color
                                                            )
                                                        "
                                                        v-else
                                                    >
                                                        Ok
                                                    </button>
                                                    <button
                                                        type="button"
                                                        class="btn btn-danger btn-xs btn-color"
                                                        @click="
                                                            oldPicker(
                                                                i.btn_color,
                                                                mBtnColor,
                                                                'btnColor'
                                                            )
                                                        "
                                                        v-if="showPickerBtnColor"
                                                    >
                                                        X
                                                    </button>
                                                    <chrome-picker
                                                        v-model="getcolorBtnColor"
                                                        v-if="showPickerBtnColor"
                                                        class="getcolor"
                                                    ></chrome-picker>
                                                </div>
                                                <div class="form-group campoColor">
                                                    <label class="labelColor"
                                                        >Btn Background:</label
                                                    >
                                                    <input
                                                        type="text"
                                                        class="form-control boxcolor"
                                                        v-model="i.btn_bg_color"
                                                        disabled
                                                        @click="
                                                            picker(
                                                                'btnBorder',
                                                                i.btn_bg_color
                                                            )
                                                        "
                                                    />
                                                    <button
                                                        type="button"
                                                        class="btn btn-light btn-xs btn-color muestra"
                                                        @click="
                                                            picker(
                                                                'btnBorder',
                                                                i.btn_bg_color
                                                            )
                                                        "
                                                        v-if="showPickerBtnBG == false"
                                                        :style="mBtnBG"
                                                    ></button>
                                                    <button
                                                        type="button"
                                                        class="btn btn-success btn-xs btn-color"
                                                        @click="
                                                            picker(
                                                                'btnBorder',
                                                                i.btn_bg_color
                                                            )
                                                        "
                                                        v-else
                                                    >
                                                        Ok
                                                    </button>
                                                    <button
                                                        type="button"
                                                        class="btn btn-danger btn-xs btn-color"
                                                        @click="
                                                            oldPicker(
                                                                i.btn_bg_color,
                                                                mBtnBG,
                                                                'btnBorder'
                                                            )
                                                        "
                                                        v-if="showPickerBtnBG"
                                                    >
                                                        X
                                                    </button>
                                                    <chrome-picker
                                                        v-model="getcolorBtnBG"
                                                        v-if="showPickerBtnBG"
                                                        class="getcolor"
                                                    ></chrome-picker>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="editaCustomCSS">
                                    <div class="form-group">
                                        <textarea
                                            v-model="customCSS"
                                            required="required"
                                            class="form-control"
                                            placeholder="/*Add css code*/"
                                            style="height: 72vh"
                                        ></textarea>
                                    </div>
                                </div>
                                <div v-if="editarNotis">
                                    <form @submit.prevent="updateNotis()" id="form1" class="">
                                        <div class="card-body">
                                            <div  class="form-group">
                                                <label for="exampleFormControlInput1">Email </label>
                                                <input
                                                type="email"
                                                class="form-control"
                                                id="exampleFormControlInput1"
                                                v-model="email1"
                                                />
                                            </div>
                                            <div  class="form-group">
                                                <label for="exampleFormControlInput2">Email copy 2</label>
                                                <input
                                                type="email"
                                                class="form-control"
                                                id="exampleFormControlInput2"
                                                v-model="email2"
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label for="exampleFormControlInput3">Email copy 3</label>
                                                <input
                                                type="email"
                                                class="form-control"
                                                id="exampleFormControlInput3"
                                                v-model="email3"
                                                />
                                            </div>
                                            <div class="form-group d-flex justify-content-center">
                                                <button type="submit" class="btn btn-success">Update Notis</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div v-if="editarAlert">
                                    <form @submit.prevent="updateAlertResponse()">
                                        <div class="form-group">
                                            <label>Alert form success</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="f.aSuccess"
                                                required
                                            />
                                        </div>
                                        <div class="form-group">
                                            <label>Alert form rror</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="f.aError"
                                                required
                                            />
                                        </div>
                                        <div class="form-group">
                                            <label id="redirection">
                                                <span style="margin-right: 10px">Enable Redirection</span>
                                                <input
                                                    type="checkbox"
                                                    id="redirection"
                                                    value="1"
                                                    v-model="f.activeResponse"
                                                    style="margin-right: 10px"
                                                />
                                                <small>( Redirect after sending )</small>
                                            </label>
                                        </div>
                                        <div class="form-group">
                                            <label>Redirect on submit</label>
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">Url /</span>
                                                </div>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="page-uri"
                                                    v-model="f.urlResponse"
                                                    :disabled="f.activeResponse==0"
                                                    :style="(f.activeResponse==0) ? 'background-color: #e9ecef;' : ''"
                                                />
                                            </div>
                                        </div>
                                        <div class="form-group d-flex justify-content-center">
                                            <button
                                                type="submit"
                                                class="btn btn-success"
                                            >
                                                Update Alerts
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <link rel="stylesheet" :href="urlStyle" />
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader.vue";
import draggable from "vuedraggable";
import { Chrome } from "vue-color";
export default {
    name: "BuilderForms",
    components: {
        Loader,
        draggable,
        "chrome-picker": Chrome,
    },
    data() {
        return {
            isLoading: false,
            formLoading: false,
            form: [],
            inputs: [],
            editinput: [],
            editarCampo: false,
            editarForm: false,
            labelConfig: true,
            campoConfig: false,
            formConfig: true,
            btnsave: false,
            inputActive: null,
            restoreInput: false,
            addInput: [],
            editarDesigns: false,
            editarNotis: false,
            editarAlert: false,
            editaCustomCSS: false,
            designsOp: 0,
            customCSS: "",
            vdate: new Date(),
            vGet: "",
            urlStyle: "",
            oldColor: "",
            designs: [],
            designLabel: true,
            designInput: false,
            designBtn: false,
            styleActive: 0,
            styleOld: null,
            showPickerLavel: false,
            showPickerInputText: false,
            showPickerInputBorder: false,
            showPickerPlace: false,
            showPickerBtnColor: false,
            showPickerBtnBG: false,
            getcolorLabel: "rgba(170,170,170,1)",
            getcolorInputText: "rgba(0,0,0,1)",
            getcolorInputBorder: "rgba(170,170,170,1)",
            getcolorPlace: "rgba(170,170,170,1)",
            getcolorBtnColor: "rgba(0,0,0,1)",
            getcolorBtnBG: "rgba(1,151,32,1)",
            mLabelColor: "background-color: rgba(170,170,170,1);",
            mInputColor: "background-color: rgba(0,0,0,1);",
            mInputBorder: "background-color: rgba(170,170,170,1);",
            mPlace: "background-color: rgba(170,170,170,1);",
            mBtnColor: "background-color: rgba(0,0,0,1);",
            mBtnBG: "background-color: rgba(1,151,32,1);",
            resf: false,
            restar: false,
            email1: null,
            email2: null,
            email3: null,
            accept_notification: false,
            apis: null,
            f: {
                user_id: window.master_client,
                org_id: window.localorgdata,
                form_id: this.$route.params.id,
                aSuccess: "",
                aError: "",
                urlResponse: "",
                activeResponse: 0,
                open_top: 0,
            },
        };
    },
    created() {
        if(!this.$route.params.id) {
            this.$notify({
                group: "noti",
                type: "error",
                title: "Not Found!",
                text: "Form does not exist.",
                duration: 10000
            });
            this.$router.push({ name: 'listForms' });
        }
        else{
            let apisForm = this.$store.getters['ModApps/Marketea/urlApis'];
            this.apis = apisForm.Form;
            this.getForms();
        }
    },
    methods: {
        getForms() {
            if (this.resf == false) {
                this.isLoading = true;
            }
            window.master
                .get("getforms/" + window.localorgdata+"/"+this.$route.params.id)
                .then((response) => {
                    let e = response.data;
                    if (e.status) {
                        this.restar = false;
                        this.resf = false;
                        this.restore(e);
                        this.getNotis();
                        this.getAlert();
                    } else {
                        this.getIndex();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.$notify({
                        group: "noti",
                        title: "ERROR",
                        type: "error",
                        text: "An error has occurred",
                        duration: 10000
                    });
                });
        },
        getIndex() {
            window.master
                .get("getindex")
                .then((response) => {
                    this.form = response.data.result;
                    this.inputs = JSON.parse(this.form.inputs);
                    this.editarForm = true;
                    this.isLoading = false;
                    this.vGet = this.vdate.getTime();
                    this.urlStyle = response.data.url + this.form.id+ '/' + this.vGet;
                    localStorage.setItem("restore", JSON.stringify(this.inputs));
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.$notify({
                        group: "noti",
                        title: "ERROR",
                        type: "error",
                        text: "An error has occurred",
                        duration: 10000
                    });
                });
        },
        getNotis() {
            this.isLoading = true;
            window.master.get(this.apis.getSetting(this.$route.params.id))
            .then((response) => {
                console.log(this.apis.getSetting(this.$route.params.id), response.data)
                if (response.data.status) {
                    this.email1 = response.data.result.email1;
                    this.email2 = response.data.result.email2;
                    this.email3 = response.data.result.email3;
                }
                this.isLoading = false;
            })
            .catch((error) => {console.log(error); this.isLoading = false;});
        },
        updateNotis() {
            this.isLoading = true;
            window.master
            .post(this.apis.setNoti(), {
                org_id: window.localuserdata,
                user_id: window.master_client,
                email1: this.email1,
                email2: this.email2,
                email3: this.email3,
                accept_notification: 1,
                form_id: this.$route.params.id,
            })
            .then((response) => {
                this.$swal.fire({
                    icon: "success",
                    title: "Updated",
                    showConfirmButton: false,
                    timer: 2000,
                });
                this.isLoading = false;
            })
            .catch((error) => {
                console.log(error);
                this.isLoading = false;
            });
        },
        getAlert() {
            this.formLoading = false;
            let url = this.apis.getAlert();
            window.master
                .post(url, {
                    org_id: this.f.org_id,
                    user_id: this.f.user_id,
                    form_id: this.$route.params.id
                })
                .then((response) => {
                    console.log(url, response.data)
                    let e = response.data.result;
                    this.f.aSuccess = e.success_text;
                    this.f.aError = e.error_text;
                    this.f.urlResponse = e.uri_response;
                    if (e.active_uri==1) {
                        this.f.activeResponse = true;
                    } else {
                        this.f.activeResponse = false;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        updateAlertResponse(){
            let url = this.apis.setAlert();
            this.isLoading = true;
            if (this.f.activeResponse == true) {
                this.f.activeResponse = 1;
            } else {
                this.f.activeResponse = 0;
            }
            window.master
            .post(url, this.f)
            .then((response) => {
                console.log(url, response.data);
                this.$swal.fire({
                    icon: "success",
                    title: "Updated",
                    showConfirmButton: false,
                    timer: 2000,
                });
                this.isLoading = false;
            })
            .catch((error) => {
                console.log(error);
                this.isLoading = false;
            });
        },
        moveInput() {
            this.setForm(0);
        },
        refreshStyle() {
            this.formLoading = true;
            this.setForm(1);
        },
        slugUrl(str) {
            str = str.replace(/^\s+|\s+$/g, "-");
            str = str.toLowerCase();
            var buscar =
                // eslint-disable-next-line no-useless-escape
                "ÃÀÁÄÂẼÈÉËÊÌÍÏÎÕÒÓÖÔÙÚÜÛÑãàáäâẽèéëêìíïîõòóöôùúüûñç·/,:;*@#&%¡?!+.{}[]\_´~'<>";
            var re =
                "aaaaaeeeeeiiiiooooouuuunaaaaaeeeeeiiiiooooouuuunc                         ";
            for (var i = 0, l = buscar.length; i < l; i++) {
                str = str.replace(buscar.charAt(i), re.charAt(i));
            }
            str = str.replace(/^\s+|\s+$/g, "");
            return str
                .replace(/[^a-z0-9 -]/g, "")
                .replace(/\s+/g, "-")
                .replace(/-+/g, "-");
        },
        setForm(m) {
            let btn_size = this.designs[0].btn_size;
            let btn_weight = this.designs[0].btn_weight;
            let input_place_weight = this.designs[0].input_place_weight;
            let input_place_size = this.designs[0].input_place_size;
            let input_size = this.designs[0].input_size;
            let input_weight = this.designs[0].input_weight;
            let label_size = this.designs[0].label_size;
            let label_weight = this.designs[0].label_weight;
            let id = this.designs[0].id;
            this.designs = [];
            this.designs.push({
                btn_bg_color: this.getcolorBtnBG,
                btn_color: this.getcolorBtnColor,
                btn_size: btn_size,
                btn_weight: btn_weight,
                id: id,
                input_border_color: this.getcolorInputBorder,
                input_color: this.getcolorInputText,
                input_place_color: this.getcolorPlace,
                input_place_size: input_place_size,
                input_place_weight: input_place_weight,
                input_size: input_size,
                input_weight: input_weight,
                label_color: this.getcolorLabel,
                label_size: label_size,
                label_weight: label_weight,
                style:
                    "form.builder label{display:inline-block;margin-bottom:0.1rem;color:{{label_color}};font-size:{{label_size}}px;font-weight:{{label_weight}};}form.builder .form-control{display:block;width:100%;padding:0.375rem 0.75rem;font-size:{{input_size}}px;color:{{input_color}};font-weight:{{input_weight}};border:1px solid {{input_border_color}};line-height:1.5;background-color:#fff;background-clip:padding-box;border-radius:2px;margin-bottom:0.5rem;-webkit-transition:border-color 0.15 sease-in-out,-webkit-box-shadow 0.15 sease-in-out;transition:border-color 0.15 sease-in-out,-webkit-box-shadow 0.15 sease-in-out;transition:border-color 0.15 sease-in-out,box-shadow 0.15 sease-in-out;transition:border-color 0.15 sease-in-out,box-shadow 0.15 sease-in-out,-webkit-box-shadow 0.15 sease-in-out;}form.builder .form-control::placeholder{color:{{input_place_color}};font-size:{{input_place_size}}px;font-weigth:{{input_place_weight}};opacity:1;}form.builder .form-control:-ms-input-placeholder{color:{{input_place_color}};font-size:{{input_place_size}}px;font-weigth:{{input_place_weight}};}form.builder .form-control::-ms-input-placeholder{color:{{input_place_color}};font-size:{{input_place_size}}px;font-weigth:{{input_place_weight}};}form.builder button{padding:10px 20px;font-size:{{btn_size}}px;color:{{btn_color}};font-weight:{{btn_weight}};background-color:{{btn_bg_color}};border-width:1px;border-style:solid;border-color:{{btn_bg_color}};box-shadow:none;-webkit-box-shadow:none;}form.builder button:hover{padding:10px 20px;font-size:{{btn_size}}px;color:{{btn_color}};font-weight:{{btn_weight}};background-color:{{btn_bg_color}};border-width:1px;border-style:solid;border-color:{{btn_bg_color}};box-shadow:none;-webkit-box-shadow:none;}",
            });
            this.btnsave = true;
            let url = "setforms";
            if (this.form.org_id > 0) {
                url = "updateforms";
            }
            let error = 0;
            let l = this.inputs;
            for (let i in l) {
                if (l[i].campo_id.length == 0 || l[i].label_name.length == 0) {
                    if (l[i].campo_type !== "submit") {
                        error++;
                        this.inputs[i].campo_error = "error";
                    }
                } else {
                    this.inputs[i].campo_id = this.slugUrl(this.inputs[i].campo_id);
                    // this.inputs[i].campo_name = this.inputs[i].campo_name;
                }
            }
            if (error > 0) {
                this.$notify({
                    group: "noti",
                    type: "error",
                    title: "input Error",
                    text: "ID or Label Name empty!!",
                    duration: 10000
                });
                this.btnsave = false;
            } else {
                window.master
                    .post(url, {
                        id: this.form.id,
                        user_id: window.localuserdata,
                        org_id: window.localuserdata,
                        name: this.form.name,
                        email_name: this.form.email_name,
                        email: this.form.email,
                        subject: this.form.subject,
                        class: this.form.class,
                        recaptcha: this.form.recaptcha,
                        key_public: this.form.key_public,
                        key_secert: this.form.key_secert,
                        enctype: this.form.enctype,
                        style: this.form.style,
                        inputs: JSON.stringify(this.inputs),
                        designs: this.designs[0],
                        custom_code: this.customCSS,
                    })
                    .then((response) => {
                        //console.log(response.data);
                        if (this.form.org_id == 0) {
                            this.form.id = response.data.result.id;
                            this.form.user_id = window.localAccountID;
                            this.form.org_id = window.localorgdata;
                        }
                        if (m > 0) {
                            this.vdate = new Date();
                            this.vGet = this.vdate.getTime();
                            this.urlStyle = response.data.url + this.form.id+ '/' + this.vGet;
                            this.$notify({
                                group: "noti",
                                type: "success",
                                title: "Saved",
                                text: "Successful registration",
                            });
                        }
                        if (m == 2) {
                            this.editinput = [];
                            this.inputActive = null;
                            this.editarCampo = false;
                            this.restoreInput = false;
                            this.formConfig = true;
                            this.addInput = [];
                        }
                        localStorage.setItem("restore", JSON.stringify(this.inputs));
                        this.styleOld = response.data.s;
                        this.btnsave = false;
                        this.formLoading = false;
                        this.resf = true;
                        this.restar = true;
                        this.getForms();
                    })
                    .catch((error) => {
                        console.log(error);
                        this.btnsave = false;
                        this.formLoading = false;
                    });
            }
        },
        edit(i, index) {
            this.editinput = i;
            this.inputActive = index;
            this.labelConfig = true;
            this.editarCampo = true;
            this.campoConfig = true;
            this.formConfig = false;
            this.editarForm = true;
            this.editarDesigns = false;
            this.editarNotis = false;
            this.editarAlert = false;
            this.editaCustomCSS = false;
            if (!this.restoreInput) {
                this.restoreInput = true;
            }
        },
        showLabel() {
            this.labelConfig = !this.labelConfig;
            this.campoConfig = false;
            this.formConfig = false;
        },
        showCampo() {
            this.campoConfig = !this.campoConfig;
            this.labelConfig = false;
            this.formConfig = false;
        },
        showform() {
            this.formConfig = !this.formConfig;
            this.campoConfig = false;
            this.labelConfig = false;
        },
        cancelInput() {
            this.editinput = [];
            this.inputs = JSON.parse(localStorage.getItem("restore"));
            this.inputActive = null;
            this.editarCampo = false;
            this.restoreInput = false;
            this.formConfig = true;
            this.addInput = [];
        },
        delInput() {
            this.$swal({
                title: "Delete this Input?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                if (result.value) {
                    let d = JSON.stringify(this.inputs[this.inputActive]) + ",";
                    let i = JSON.stringify(this.inputs);
                    let r = i.replace(d, "");
                    this.inputs = JSON.parse(r);
                    this.setForm(2);
                }
            });
        },
        createInput(t) {
            let label = "";
            let type = "";
            let name = "";
            let options = [];
            let obj = JSON.parse(localStorage.getItem("restore"));
            let formatInput = {
                label_width: "c1-1",
                label_display: 1,
                label_id: "",
                label_class: "",
                label_style: "",
                label_name: "",
                label_for: "",
                campo_name: "",
                campo_id: "",
                campo_error: "",
                campo_type: "",
                options: "",
                campo_class: "",
                campo_required: 0,
                campo_height: "auto",
                campo_placeholder: "",
                campo_value: "",
                campo_style: "",
            };
            if(t!=6){
                switch (t) {
                    case 1:
                        type = "text";
                        label = "Input Text";
                        break;
                    case 2:
                        type = "textarea";
                        label = "Comments";
                        break;
                    case 3:
                        type = "select";
                        label = "Dropdown";
                        options = ["Item 1", "Item 2", "Item 3", "Item 4"];
                        break;
                    case 4:
                        type = "radio";
                        label = "Decision";
                        options = ["Yes", "No"];
                        break;
                    case 5:
                        type = "checkbox";
                        label = "Multiple choice";
                        options = ["Menu 1", "Menu 2"];
                        break;
                    default:
                        type = "text";
                        label = "Input Text";
                        break;
                }
                let nameInput = label.toLowerCase().replace(' ', '-')
                formatInput.label_name = label;
                formatInput.label_id = nameInput
                formatInput.campo_id = nameInput
                formatInput.campo_name = nameInput;
                formatInput.campo_type = type;
                formatInput.options = options;
            }else{
                formatInput.label_width='c1-1';
                formatInput.label_name = 'Address';
                formatInput.label_id = 'address-google';
                formatInput.campo_id = 'address-google';
                formatInput.campo_name = 'address-google';
                formatInput.campo_type = 'textarea';
                formatInput.campo_required=1;
                formatInput.label_class='address-google';
                formatInput.campo_placeholder = 'Type your address here';
                formatInput.options = [];
                formatInput.showMap = 0;
            }
            //console.log('New ',formatInput);
            let add = this.addInput;
            this.addInput = [];
            this.addInput.push(formatInput);
            for (let i in add) {
                this.addInput.push(add[i]);
            }
            //console.log(this.addInput);
            this.inputs = [];
            let lastItem = (obj.length - 1);
            let indexAdd=0;
            for (let k in obj) {
                if(k==lastItem){
                    console.log('lastItem', k)
                    for (let j in this.addInput) {
                        this.inputs.push(this.addInput[j]);
                        indexAdd=(this.inputs.length - 1)
                        //console.log('add', this.addInput[j]);
                    }
                }
                this.inputs.push(obj[k]);
                //console.log('antiguos', obj[k]);
            }
            this.editinput = this.inputs[indexAdd];
            this.inputActive = indexAdd;
            this.formConfig = false;
            this.restoreInput = true;
            this.edit(this.inputs[indexAdd], indexAdd)
        },
        delOption(o) {
            let list = [];
            let op = this.editinput.options;
            for (let i in op) {
                if (op[i] != o) {
                    list.push(op[i]);
                }
            }
            this.editinput.options = list;
        },
        addOptions() {
            let add = this.editinput.options.length + 1;
            this.editinput.options.push("Item " + add);
        },
        menuTab(t) {
            if (t == 1) {
                this.editarForm = true;
                this.editarDesigns = false;
                this.editarNotis = false;
                this.editarAlert = false;
                this.editaCustomCSS = false;
            }
            if (t == 2) {
                this.editarForm = false;
                this.editarDesigns = true;
                this.editarNotis = false;
                this.editarAlert = false;
                this.editaCustomCSS = false;
            }
            if (t == 3) {
                this.editarForm = false;
                this.editarDesigns = false;
                this.editarNotis = false;
                this.editarAlert = false;
                this.editaCustomCSS = true;
            }
            if (t == 4) {
                this.editarForm = false;
                this.editarDesigns = false;
                this.editarNotis = true;
                this.editarAlert = false;
                this.editaCustomCSS = false;
            }
            if (t == 5) {
                this.editarForm = false;
                this.editarDesigns = false;
                this.editarNotis = false;
                this.editarAlert = true;
                this.editaCustomCSS = false;
            }
        },
        activeDesign(i) {
            this.designsOp = i;
            this.styleActive = i;
        },
        designsLabels() {
            this.designLabel = !this.designLabel;
            this.designInput = false;
            this.designBtn = false;
        },
        designsInputs() {
            this.designInput = !this.designInput;
            this.designLabel = false;
            this.designBtn = false;
        },
        designsBtns() {
            this.designBtn = !this.designBtn;
            this.designInput = false;
            this.designLabel = false;
        },
        picker(t, c) {
            let s = false;
            if (t == "label") {
                this.showPickerLavel = !this.showPickerLavel;
                s = this.showPickerLavel;
            }
            if (t == "inputText") {
                this.showPickerInputText = !this.showPickerInputText;
                s = this.showPickerInputText;
            }
            if (t == "inputBorder") {
                this.showPickerInputBorder = !this.showPickerInputBorder;
                s = this.showPickerInputBorder;
            }
            if (t == "place") {
                this.showPickerPlace = !this.showPickerPlace;
                s = this.showPickerPlace;
            }
            if (t == "btnColor") {
                this.showPickerBtnColor = !this.showPickerBtnColor;
                s = this.showPickerBtnColor;
            }
            if (t == "btnBorder") {
                this.showPickerBtnBG = !this.showPickerBtnBG;
                s = this.showPickerBtnBG;
            }
            if (s) {
                this.oldColor = c;
            }
        },
        oldPicker(c, m, t) {
            c = this.oldColor;
            if (t == "label") {
                this.showPickerLavel = false;
                this.getcolorLabel = this.oldColor;
                this.mLabelColor = "background-color:" + c + ";";
                this.designs[this.styleActive].label_color = this.oldColor;
            }
            if (t == "inputText") {
                this.showPickerInputText = false;
                this.getcolorInputText = this.oldColor;
                this.mInputColor = "background-color:" + c + ";";
                this.designs[this.styleActive].input_color = this.oldColor;
            }
            if (t == "inputBorder") {
                this.showPickerInputBorder = false;
                this.getcolorInputBorder = this.oldColor;
                this.mInputBorder = "background-color:" + c + ";";
                this.designs[this.styleActive].input_border_color = this.oldColor;
            }
            if (t == "place") {
                this.showPickerPlace = false;
                this.getcolorPlace = this.oldColor;
                this.mPlace = "background-color:" + c + ";";
            }
            if (t == "btnColor") {
                this.showPickerBtnColor = false;
                this.getcolorBtnColor = this.oldColor;
                this.mBtnColor = "background-color:" + c + ";";
            }
            if (t == "btnBorder") {
                this.showPickerBtnBG = false;
                this.getcolorBtnBG = this.oldColor;
                this.mBtnBG = "background-color:" + c + ";";
            }
        },
        cancelDesign() {
            this.restar = true;
            this.resf = true;
            this.restore();
        },
        restore(e) {
            let styleForm;
            let designs;
            if (this.restar == false) {
                this.form = e.result;
                this.inputs = JSON.parse(this.form.inputs);
                this.vGet = this.vdate.getTime();
                this.urlStyle = e.url + this.form.id+ '/' + this.vGet;
                localStorage.setItem("restore", JSON.stringify(this.inputs));
                localStorage.setItem("restore_btn_styleForm", JSON.stringify(e.s));
                localStorage.setItem("restore_btn_designs", JSON.stringify(e.s));
                this.customCSS = e.s.custom_code;
                styleForm = e.s;
                designs = e.designs;
                this.styleOld = e.s;
            } else {
                let e = localStorage.getItem("restore_btn_styleForm");
                let ed = localStorage.getItem("restore_btn_designs");
                styleForm = JSON.parse(e);
                designs = JSON.parse(ed);
                this.styleOld = JSON.parse(e);
            }
            this.styleForm = styleForm;
            this.designs = designs;
            if (this.resf == false) {
                this.isLoading = false;
                this.editarForm = true;
                this.resf = false;
            }
            for (let i in designs) {
                if (designs[i].id == styleForm.designs_id) {
                    this.styleActive = i;
                }
            }
            this.menuTab(1);
            this.getcolorLabel = styleForm.label_color;
            this.getcolorInputText = styleForm.input_color;
            this.getcolorInputBorder = styleForm.input_border_color;
            this.getcolorPlace = styleForm.input_place_color;
            this.getcolorBtnColor = styleForm.btn_color;
            this.getcolorBtnBG = styleForm.btn_bg_color;
            this.mLabelColor = "background-color: " + styleForm.label_color + ";";
            this.mInputColor = "background-color: " + styleForm.input_color + ";";
            this.mInputBorder = "background-color: " + styleForm.input_border_color + ";";
            this.mPlace = "background-color: " + styleForm.input_place_color + ";";
            this.mBtnColor = "background-color: " + styleForm.btn_color + ";";
            this.mBtnBG = "background-color: " + styleForm.btn_bg_color + ";";
            this.setDesigns();
        },
        setDesigns() {
            let btn_size = this.styleForm.btn_size;
            let btn_weight = this.styleForm.btn_weight;
            let input_place_weight = this.styleForm.input_place_weight;
            let input_place_size = this.styleForm.input_place_size;
            let input_size = this.styleForm.input_size;
            let input_weight = this.styleForm.input_weight;
            let label_size = this.styleForm.label_size;
            let label_weight = this.styleForm.label_weight;
            let id = this.styleForm.id;
            this.designs = [];
            this.designs.push({
                btn_bg_color: this.getcolorBtnBG,
                btn_color: this.getcolorBtnColor,
                btn_size: btn_size,
                btn_weight: btn_weight,
                id: id,
                input_border_color: this.getcolorInputBorder,
                input_color: this.getcolorInputText,
                input_place_color: this.getcolorPlace,
                input_place_size: input_place_size,
                input_place_weight: input_place_weight,
                input_size: input_size,
                input_weight: input_weight,
                label_color: this.getcolorLabel,
                label_size: label_size,
                label_weight: label_weight,
                style:
                    "form.builder label{display:inline-block;margin-bottom:0.1rem;color:{{label_color}};font-size:{{label_size}}px;font-weight:{{label_weight}};}form.builder .form-control{display:block;width:100%;padding:0.375rem 0.75rem;font-size:{{input_size}}px;color:{{input_color}};font-weight:{{input_weight}};border:1px solid {{input_border_color}};line-height:1.5;background-color:#fff;background-clip:padding-box;border-radius:2px;margin-bottom:0.5rem;-webkit-transition:border-color 0.15 sease-in-out,-webkit-box-shadow 0.15 sease-in-out;transition:border-color 0.15 sease-in-out,-webkit-box-shadow 0.15 sease-in-out;transition:border-color 0.15 sease-in-out,box-shadow 0.15 sease-in-out;transition:border-color 0.15 sease-in-out,box-shadow 0.15 sease-in-out,-webkit-box-shadow 0.15 sease-in-out;}form.builder .form-control::placeholder{color:{{input_place_color}};font-size:{{input_place_size}}px;font-weigth:{{input_place_weight}};opacity:1;}form.builder .form-control:-ms-input-placeholder{color:{{input_place_color}};font-size:{{input_place_size}}px;font-weigth:{{input_place_weight}};}form.builder .form-control::-ms-input-placeholder{color:{{input_place_color}};font-size:{{input_place_size}}px;font-weigth:{{input_place_weight}};}form.builder button{padding:10px 20px;font-size:{{btn_size}}px;color:{{btn_color}};font-weight:{{btn_weight}};background-color:{{btn_bg_color}};border-width:1px;border-style:solid;border-color:{{btn_bg_color}};box-shadow:none;-webkit-box-shadow:none;}form.builder button:hover{padding:10px 20px;font-size:{{btn_size}}px;color:{{btn_color}};font-weight:{{btn_weight}};background-color:{{btn_bg_color}};border-width:1px;border-style:solid;border-color:{{btn_bg_color}};box-shadow:none;-webkit-box-shadow:none;}",
            });
        },
    },
    watch: {
        getcolorLabel: function () {
            if (typeof this.getcolorLabel == "object") {
                let c = this.getcolorLabel.rgba;
                this.getcolorLabel =
                    "rgba(" + c.r + "," + c.g + "," + c.b + "," + c.a + ")";
                this.mLabelColor = "background:" + this.getcolorLabel + ";";
            }
        },
        getcolorInputText: function () {
            if (typeof this.getcolorInputText == "object") {
                let c = this.getcolorInputText.rgba;
                this.getcolorInputText =
                    "rgba(" + c.r + "," + c.g + "," + c.b + "," + c.a + ")";
                this.mInputColor = "background:" + this.getcolorInputText + ";";
            }
        },
        getcolorInputBorder: function () {
            if (typeof this.getcolorInputBorder == "object") {
                let c = this.getcolorInputBorder.rgba;
                this.getcolorInputBorder =
                    "rgba(" + c.r + "," + c.g + "," + c.b + "," + c.a + ")";
                this.mInputBorder = "background:" + this.getcolorInputBorder + ";";
            }
        },
        getcolorPlace: function () {
            if (typeof this.getcolorPlace == "object") {
                let c = this.getcolorPlace.rgba;
                this.getcolorPlace =
                    "rgba(" + c.r + "," + c.g + "," + c.b + "," + c.a + ")";
                this.mPlace = "background:" + this.getcolorPlace + ";";
            }
        },
        getcolorBtnColor: function () {
            if (typeof this.getcolorBtnColor == "object") {
                let c = this.getcolorBtnColor.rgba;
                this.getcolorBtnColor =
                    "rgba(" + c.r + "," + c.g + "," + c.b + "," + c.a + ")";
                this.mBtnColor = "background:" + this.getcolorBtnColor + ";";
            }
        },
        getcolorBtnBG: function () {
            if (typeof this.getcolorBtnBG == "object") {
                let c = this.getcolorBtnBG.rgba;
                this.getcolorBtnBG =
                    "rgba(" + c.r + "," + c.g + "," + c.b + "," + c.a + ")";
                this.mBtnBG = "background:" + this.getcolorBtnBG + ";";
            }
        },
    },
    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
            };
        },
    },
};
</script>
<style lang="scss" scoped>
.error {
    border: 1px solid #ff0000 !important;
}
.content_create_inputs {
    position: relative;
    ul {
        flex-wrap: wrap;
        justify-content: space-between;
        list-style-type: none;
        margin: 0;
        padding: 0;
        li {
            position: relative;
            width: 49%;
            cursor: pointer;
            background-color: #f1f1f1;
            border: 1px solid #ccc;
            margin: 8px auto;
            padding: 5px 9px;
            &:hover {
                background-color: #eceaea;
                cursor: pointer;
            }
            .icon_radio {
                position: relative;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                border: 1px solid #7d7d7d;
                display: inline-block;
                vertical-align: middle;
                &:before {
                    content: "";
                    position: absolute;
                    top: 3px;
                    left: 3px;
                    display: block;
                    width: 8px;
                    height: 8px;
                    background: #a7a7a7;
                    border-radius: 50%;
                }
            }
            .icon_textarea {
                width: 20px;
                height: 15px;
                display: inline-block;
                vertical-align: middle;
                background-color: #fffbfb;
                border: 1px solid #a0a0a0;
            }
            .icon_input {
                width: 20px;
                height: 8px;
                display: inline-block;
                vertical-align: middle;
                background-color: #fffbfb;
                border: 1px solid #a0a0a0;
            }
        }
    }
}
.editForm .form-group {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    position: relative;
    border: 1px dashed #fff;
    border-left: 0px;
    margin-bottom: 5px;
}
.editForm .form-group:hover,
.editForm .form-group.inputActive {
    border-color: #8e8383;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: whitesmoke;
}
.editForm .form-group.inputActive .handle {
    display: block;
}
.editForm .form-group:hover .handle {
    display: block;
}
.handle {
    position: absolute;
    top: -3px;
    left: 0px;
    width: 15px;
    height: 108%;
    z-index: 1;
    padding: 20px 0px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: rgba(215, 239, 253, 0.7098039215686275);
    color: #8e8383;
    display: none;
    cursor: move;
}
.form-control {
    border-color: #a9a9a9;
    margin-bottom: 0.5rem;
}
.editForm button {
    box-shadow: none;
    border: 1px solid #222223;
    padding: 10px 20px;
    display:block;
    margin: auto;
}
button.btn {
    border: 0;
    padding: 5px 10px;
}
.cuerpo-form {
    overflow: hidden;
    overflow-y: auto;
    height: 82vh;
    padding-top: 5px;
}
.card-header{padding: 0px 15px;}
.card-header.label-header {
    padding: 0.3rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border: 1px solid #000;
    margin: 5px 0px;
    font-weight: bold;
    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
        cursor: pointer;
    }
}
.lateral-config {
    height: 75vh;
    overflow: hidden;
    .add_inputs {
        display: flex;
        justify-content: flex-end;
    }
}
.cuerpo-lateral {
    display: block;
    min-height: 300px;
    height: auto;
    overflow: hidden;
    overflow-y: auto;
    max-height: 75vh;
}
.card-header.label-header span {
    padding-left: 10px;
}
label.c-input.c-radio {
    padding: 0px 10px;
}
.label-config {
    height: 0px;
    transform: scaleY(0);
    overflow: hidden;
    transition: transform 0.3s ease-out;
}
.activeLista {
    overflow: initial !important;
    height: auto !important;
    transform: scaleY(1);
    transform-origin: top;
}
.breadcrumb-item + .breadcrumb-item {
    padding-left: 0rem;
}
.breadcrumb-item + .breadcrumb-item::before {
    content: "";
    padding-right: 0rem;
}
.cdropdown {
    margin-right: 10px;
    padding: 0px 5px;
    font-size: 0.76563rem;
    line-height: 1.5;
}
.cdropdown > a,
.cdropdown > button {
    background-color: #137eff;
    color: #fff;
}
.cdropdown input[type="checkbox"]:checked + ul {
    width: 150px;
}
.cdropdown > a:before,
.cdropdown > button:before {
    border-top: 5px solid #fff;
}
.delOption {
    padding: 8px 10px;
    line-height: 01.5;
    height: 35px;
    border: 0px;
}
.han-nohover {
    display: block !important;
    padding-top: 6px !important;
    text-align: center;
    height: 85% !important;
    border-radius: 0px !important;
    top: 0px !important;
    cursor: move !important;
}
.opcionsInputs {
    padding-left: 20px;
}
.editForm label.c-input.c-radio {
    display: block;
}
button.btn.btn-light {
    background-color: #fff;
    border: 1px solid #6c757d;
    margin-bottom: 0px;
    border-radius: 2px;
    display: inline-block !important;
    width: 25% !important;
}
button.active.btn.btn-light {
    background-color: #f7f7f7 !important;
    border-color: #222223 !important;
    border-bottom: 0px;
}
.btn-group.btn-group-lg {
    display: block;
}
.editarDesigns label.c-input.c-radio {
    display: block;
    font-size: 20px;
}
.labelColor {
    display: block;
    width: 100%;
}
.boxcolor {
    width: 60%;
    display: inline-block;
    border-radius: 0;
    height: 33px;
    float: left;
}
button.btn.btn-light.btn-xs.btn-color {
    padding: 5px 10px !important;
    border-radius: 0 !important;
    display: inline-block !important;
    height: 33px;
    float: left;
    border: 1px solid #ccc !important;
    width: 20px !important;
}
.campoColor {
    margin-bottom: 50px;
}
button.btn.btn-light.btn-xs.btn-color.muestra {
    padding: 5px 16px !important;
}
.btn-color {
    padding: 9px 15px !important;
    border-radius: 0px !important;
}
.btn-color:focus {
    box-shadow: none;
}
/*diseño de columnas*/
.c1-1 {
    width: 100%;
    float: left;
}
.c1-2 {
    width: 50%;
    float: left;
}
.c1-3 {
    width: 33.333%;
    float: left;
}
.c3-4 {
    width: 66.666%;
    float: left;
}
@media (max-width: 767px) {
    .c1-2 {
        width: 100%;
    }
    .c1-3 {
        width: 100%;
    }
    .c3-4 {
        width: 100%;
    }
}
input.form-control:disabled,
input.form-control[readonly],
input.form-control,
select.form-control:disabled,
select.form-control[readonly],
select.form-control,
textarea.form-control:disabled,
textarea.form-control[readonly],
textarea.form-control
{
    opacity: 1;
    background-color: #fff;
    margin-bottom:0px;
}
textarea.form-control:disabled,
textarea.form-control[readonly],
textarea.form-control
{
    height: 100px;
}

textarea#address-google{
    resize: none;
    height: 36px;
}
.edit_input {
    position: absolute;
    left: 15px;
    top: -3px;
    height: 100%;
    width: 100%;
    text-align: center;
}
.campo-content {
    padding-bottom: 20px;
}
</style>
