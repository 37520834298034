<template>
    <div class="edit_food_name" :data-indicador="indicador">
        <h4 class="food_item_name" :data-indicador="indicador" v-if="!editActive" @click.prevent="activeEdit()">{{ food.name_food }}</h4>
        <div class="content-editor" v-else>
            <textarea
                v-model="food.name_food"
                class="widget_edit_food_name"
                placeholder="Food Name"
                maxlength="50"
                ref="editName_food"
                @keypress="noEnter"
                @change="saveFoodName()"
                @focusout="inactiveEdit"
            ></textarea>
        </div>
    </div>
</template>
<script>
import { VueEditor } from "vue2-editor/dist/vue2-editor.core.js";
export default {
    components: {
        VueEditor,
    },
    props: ['indicador', 'food'],
    data(){
        return {
            loadSave: false,
            editActive: false,
            apis: null,
        };
    },
    created(){
        let t = this;
        let urlApis = this.$store.getters['ModApps/Marketea/urlApis'];
        this.apis = urlApis.bMenu;
    },
    methods: {
        activeEdit(){
            let t = this;
            t.editActive = true;
            setTimeout(() => {
                if(t.$refs.editName_food){
                    t.$refs.editName_food.focus();
                }
            }, 200);
        },
        inactiveEdit(){this.editActive = false;},
        noEnter(e){
            let t = this;
            var code = (e.which) ? e.which : e.keyCode;
            if(code == 13){
                e.preventDefault();
                t.saveFoodName()
            }
        },
        saveFoodName(){
            let t= this;
            t.inactiveEdit()
            let urlService = t.apis.set(3)// setFood
            if(!t.loadSave){
                t.loadSave = true;
                // @ts-ignore
                window.master.post(urlService, t.food)
                .then((response) => {
                    console.log(urlService, t.food, response.data);
                    t.loadSave = false;
                })
                .catch((error) => {
                    console.log(error);
                    t.loadSave = false;
                    t.$notify({
                        group: "noti",
                        type: "error",
                        title: "Error",
                        text: "Could not be save!",
                    });
                });
            }
        },
    }
}
</script>
<style lang="scss" scoped>
    .edit_food_name {
        .food_item_name{
            border: 1px dashed transparent;
            &:hover{
                border: 1px dashed #fcd602;
            }
        }
        textarea.widget_edit_food_name{
            width: 100%;
            height: 40px;
            font-size: 1.5rem;
            font-weight: bold;
            color: #000;
            font-family: Arial, Helvetica, sans-serif;
            text-align: left;
            padding: 0px;
            resize: none;
            overflow: hidden;
            box-shadow: none;
            outline: none;
        }
    }
</style>
