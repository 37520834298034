export default {
	urlApis(state){ return state.urlApis; },
	langSites(state){ return state.langRoofCal; },
	accessApp(state){ return state.access; },
	get_asideStorage(state){ return state.asideStorage;},
	get_loadSites(state){ return state.loadSites;},
	get_webs(state){ return state.websList;},
	get_addWeb(state){ return state.addWeb;},
	get_webChose(state){ return state.webChose;},
	getRefreshBoxContent(state){ return state.refreshBoxContent;},
	getBoxContentclone(state){ return state.boxContentclone;},
	getBoxContentCloneNewIndex(state){ return state.boxContentCloneNewIndex;},
	getcloseBarTools(state){ return state.closeBarTools;},
	getSideBarOpc(state){ return state.sideBarOpc;},
	getOpcFontPage(state){ return state.opcFontPage;},
	getFontPageTypeBg(state){ return state.opcFontPage.typeBg;},
	getFontPageBgImg(state){ return state.opcFontPage.images;},
	getFontPageColorOverlay(state){ return state.opcFontPage.colorOverlay;},
	getFontPageTypeHeight(state){ return state.opcFontPage.typeHeight;},
	getFontPageSlideHeight(state){ return state.opcFontPage.slideHeight;},
	getFontPageBoxSize(state){ return state.opcFontPage.boxSize;},
	getFontPageLayoutContent(state){ return state.opcFontPage.layoutContent;},
	getFontPageContentAling(state){ return state.opcFontPage.contentAling;},
	getFontPageThumbs(state){ return state.opcFontPage.thumbs;},
	getFontPageFormHeader(state){ return state.opcFontPage.formHeader;},
	getFontPageFormShow(state){ return state.opcFontPage.formShow;},
	getFontPageBoxForm(state){ return state.opcFontPage.boxForm;},
	getFontPageFormAling(state){ return state.opcFontPage.formAling;},
	getBeforeUnloadBiulder(state){ return state.beforeUnloadBiulder;},
}
