<template>
    <div>
        <div class="card-header d-flex" style="position: relative; align-items: center">
            <span style="font-size: 18px">Security pin</span>
        </div>
        <Loader v-if="isLoading"></Loader>
        <div>
            <form @submit.prevent="form" v-if="isLoading == false">
                <div class="form-general" style="margin: 30px">
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-12">
                                <select class="form-control" v-model="f.meta_value">
                                    <option value="1">Active</option>
                                    <option value="0">Inactive</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-primary" :disabled="btnSave">
                        Save
                    </button>
                </div>
            </form>
        </div>
        <notifications group="noti" position="top center"></notifications>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader.vue";
import { mapGetters } from "vuex";
export default {
    name: "DisplaySecurity",
    components: {
        Loader,
    },
    data() {
        return {
            isLoading: false,
            f: {
                user_id: window.master_client,
                org_id: window.localorgdata,
                type: window.user_type,
                meta_key: "PIN_SECURITY",
                meta_value: 1,
            },
            btnSave: false,
        };
    },
    created() {
        this.getData();
    },
    methods: {
        form() {
            this.isLoading = true;
            this.btnSave = true;
            let url = "/display/displayformsecurity";
            window.master
                .post(url, this.f)
                .then((response) => {
                    // console.log(response.data);
                    this.isLoading = false;
                    this.btnSave = false;
                    let t = [];
                    t.push({ status: 1 });
                    this.arrays("DisplaySettingPing", t);
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.btnSave = false;
                });
        },
        getData() {
            this.isLoading = true;
            let url = "/display/getdisplaysetup";
            window.master
                .post(url, {
                    org_id: this.f.org_id,
                    user_id: this.f.user_id,
                    meta_key: this.f.meta_key,
                })
                .then((response) => {
                    console.log(response.data);
                    this.f.meta_value = response.data.rs[0].meta_value;
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        arrays(name, data) {
            let k = [];
            k.push({ function: name, data: data });
            this.$store.commit("ModApps/display/set_displayEdit", k);
        },
    },
    computed: {
        ...mapGetters("ModApps/display", ["get_displayEdit"]),
    },
};
</script>
<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
.marguen {
    margin-top: 40px !important;
    display: block;
    text-align: center;
    margin: 0 auto;
    font-size: 34px;
    font-weight: bold;
}
.btnx {
    border: none;
    width: 25px;
}
</style>
