<template>
    <div class="card">
        <div class="card-header">
            <h5 class="card-title">Add users to Tags</h5>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-12">
                    <div class="content-table">
                        <form @submit.prevent="form">
                            <table style="width: 100%" border="0">
                                <tbody>
                                    <tr>
                                        <td colspan="2" width="99%">
                                            <div class="option__desc" style="margin: 0.5px; height: 50px">
                                                <div style="width: 10%; float: left; height: 50px" v-if="ownerImg">
                                                    <img class="option__image"
                                                        style="border-radius: 50px"
                                                        width="50px"
                                                        height="50px"
                                                    />
                                                </div>
                                                <div style="width: 10%; float: left; height: 50px" v-else>
                                                    <span class="avatarDefault">{{ ownerL }}</span>
                                                </div>
                                                <div style="width: 90%;
                                                        float: right;
                                                        height: 50px;
                                                        padding: 0px;
                                                    ">
                                                    <span class="option__title"
                                                        style="font-size: 18px;
                                                            color: #4f5467;
                                                            margin-left: 10px;
                                                            padding: 0px;
                                                        " >{{ ownerName }} (Owner hola)</span><br />
                                                    <span class="option__small"
                                                        style="font-size: 14px;
                                                            color: #6a7a8c;
                                                            margin-left: 10px;
                                                            padding: 0px;
                                                        ">{{ ownerEmail }}</span>
                                                </div>
                                            </div>
                                            <br />
                                        </td>
                                    </tr>
                                    <tr></tr>
                                    <tr v-if="show_privileges">
                                        <td colspan="2" class="rolTags">
                                            <h4>
                                                Privileges for:
                                                <b>{{ user_privilege.title }}</b>
                                                <small> ({{ user_privilege.desc }})</small>
                                            </h4>
                                            <label class="c-input c-radio">
                                                <input value="1" v-model="user_privilege.privilegio" type="radio" />
                                                <span class="c-indicator"></span>
                                                View
                                            </label>
                                            <label class="c-input c-radio">
                                                <input value="2" v-model="user_privilege.privilegio" type="radio" />
                                                <span class="c-indicator"></span>
                                                Editor Tag
                                            </label>
                                            <button type="button" class="btn btn-success btn-sm" @click="okPrivileges" >
                                                Ok
                                            </button>
                                            <!-- <button type="button" class="btn btn-danger btn-sm" @click="closeP">&times;</button> -->
                                        </td>
                                    </tr>
                                    <tr v-bind:class="{ noneRow: show_privileges }">
                                        <td>
                                            <multiselect
                                                class="form-control"
                                                @select="privilegiosUser"
                                                v-model="f.userIdAssign"
                                                placeholder="Search"
                                                label="title"
                                                track-by="id"
                                                :options="options"
                                                :custom-label="customLabel"
                                                :multiple="true"
                                                :taggable="true"
                                            >
                                                <template slot="option" slot-scope="props">
                                                    <div class="option__desc" style="margin: 0.5px; height: 50px">
                                                        <div style="width: 10%;
                                                                float: left;
                                                                height: 50px;
                                                            " v-if="props.option.img">
                                                            <img class="option__image"
                                                                :src="props.option.img"
                                                                style="border-radius: 50px"
                                                                width="50px"
                                                                height="50px"
                                                            />
                                                        </div>
                                                        <div style="width: 10%;
                                                                float: left;
                                                                height: 50px;
                                                            "
                                                            v-else
                                                        >
                                                            <span class="avatarDefault">{{ props.option.default }}</span>
                                                        </div>
                                                        <div style="width: 90%;
                                                                float: right;
                                                                height: 50px;
                                                                padding: 10px;
                                                            "
                                                        >
                                                            <span class="option__title" style="font-size: 18px; color: #4f5467;">{{ props.option.title }}</span><br />
                                                            <span class="option__small" style="font-size: 14px; color: #6a7a8c;">{{ props.option.desc }}</span>
                                                        </div>
                                                    </div>
                                                </template>
                                            </multiselect>
                                        </td>
                                        <td>
                                            <button type="submit" class="btn btn-success">
                                                Save
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>                
</template>
<script>
import Multiselect from "vue-multiselect";
import { mapGetters } from "vuex";
export default {
    name: "addUserToTags",
    components: {
        Multiselect,
    },
    data() {
        return {
            isLoading: false,
            user_id: window.master_client,
            user_id_sesion: window.localAccountID,
            org_id: window.localorgdata,
            userlist: [],
            f: {
                userIdAssign: [],
            },
            userIdAssign: [],
            asing: [],
            options: [],
            value: [],
            ownerL: "",
            ownerName: "",
            ownerEmail: "",
            ownerImg: "",
            show_privileges: false,
            user_privilege: [],
            idTag: 0,
        };
    },
    created() {
        let urlApis = this.$store.getters['ModApps/jobs/urlApis'];
        this.apis = urlApis.jobFilter;          
        console.log('hola desde addUsersTags', this.$store.getters['core/getOpenModal'])
        this.idTag = this.$store.getters['core/getOpenModal'].data.tagsModalId;
        this.iniJobsList();
    },
    methods: {
        okPrivileges() {
            let o = this.f.userIdAssign;
            for (let i in o) {
                if (o[i].id == this.user_privilege.id) {
                    this.f.userIdAssign[i].privilegio = this.user_privilege.privilegio;
                }
            }
            this.closeP();
        },
        closeP() {
            this.user_privilege = [];
            this.show_privileges = false;
        },
        privilegiosUser(e) {
            this.user_privilege = e;
            this.show_privileges = true;
            console.log("seleccionado", this.user_privilege);
        },
        customLabel({ title, desc, img }) {
            return `${title} – ${desc}`;
        },
        iniJobsList() {
            this.getForm().then((response) => {
                let r = this.asing;
                let o = r.length;
                if (o > 0) {
                    this.getUsersList();
                } else {
                    this.getUsersList();
                }
            });
        },
        getForm() {
            return new Promise((resolve, reject) => {
                this.$store.commit("core/setLoadingModal", true);
                let url = this.apis.getTags()
                window.master
                    .post(url, {
                        user_id: window.master_client,
                        org_id: this.org_id,
                        tags_id: this.idTag,
                    })
                    .then((response) => {
                        let r = response.data.res;
                        this.asing = r;
                        this.$store.commit("core/setLoadingModal", false);
                        resolve(true);
                    })
                    .catch((error) => {
                        this.$store.commit("core/setLoadingModal", false);
                        console.log(error);
                        reject(false);
                    });
            });
        },
        getUsersList() {
            this.$store.commit("core/setLoadingModal", true);
            let url = this.apis.getUsers()
            window.master
                .get(url)
                .then((response) => {
                    let e = response.data.rs; // lista de user
                    let q = response.data.r; //user master
                    let a = q[0].name;
                    this.ownerL = a.charAt().toUpperCase();
                    this.ownerName = q[0].name;
                    this.ownerEmail = q[0].email;
                    this.ownerImg = "";
                    this.userlist = [];
                    let userList = [];
                    if (e.length > 0) {
                        let f = this.asing;
                        for (let i in e) {
                            let first = e[i].firstname;
                            let last = e[i].lastname;
                            let w =
                                first.charAt().toUpperCase() +
                                last.charAt().toUpperCase();
                            let item = {
                                id: e[i].account_id,
                                title: e[i].name,
                                desc: e[i].email,
                                img: e[i].avatar,
                                default: w,
                                privilegio: e[i].privilegio,
                            };
                            userList.push(item);
                            for (const j in f) {
                                if (f[j].user_id_admin === e[i].account_id) {
                                    this.f.userIdAssign.push(item);
                                }
                            }
                        }
                    }
                    this.userIdAssign = userList;
                    this.userlist = userList;
                    this.value = this.f.userIdAssign;
                    this.options = this.userIdAssign;
                    this.$store.commit("core/setLoadingModal", false);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        form() {
            this.$store.commit("core/setLoadingModal", false);
            var users = [];
            let z = this.f.userIdAssign;
            for (const q in z) {
                users.push({
                    user_id_admin: z[q].id,
                    privilegio: z[q].privilegio,
                });
            }
            let url = this.apis.addUsers()
            let sendData = {
                user_id: window.master_client,
                org_id: this.org_id,
                tags_id: this.idTag,
                app_id: this.getAppIDactual,
                data: users,
            };
            console.log("sendData", sendData);
            window.master
                .post(url, sendData)
                .then((response) => {
                    let t = this
                    this.$store.commit("core/setLoadingModal", false);
                    this.$store.commit("core/setCloseModal", {
                        action: 'saved',                      
                    }); 
                })
                .catch((error) => {
                    this.btnSave = false;
                    this.$store.commit("core/setLoadingModal", false);
                    this.$notify({
                        group: "noti",
                        icon: "error",
                        title: "Error",
                        text: "failed to save, try later!",
                    });
                    console.log(error);
                });
        },
    },
    computed: {
        ...mapGetters("ModApps/jobs", ["get_changeAssignUser"]),
        ...mapGetters("core/apps", ["getAppIDactual"]),
    },
};
</script>
<style lang="scss" scoped>
span.avatarDefault {
    width: 50px;
    height: 50px;
    display: flex;
    margin: 0px auto;
    border: 1px solid #ccc;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 25px;
    font-weight: 900;
    background-color: #5ac146;
    color: #fff;
}
.noneRow {
    opacity: 0;
}
.privilegios {
    position: absolute;
    z-index: 1;
    width: 100%;
}
.rolTags label.c-input.c-radio {
    margin-right: 20px;
}
</style>
