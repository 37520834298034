export default {
	SetActiveJobs(context, val) {
      context.commit('ActiveJobs', val);  
    },
    update_JOPending(context, i) {
    	let tag_id = i.tag_id;
    	let typeUser = i.typeUser;
    	window.master.get("/jobs/getjobspending/"+window.localAccountID+'/'+window.localorgdata+'/'+typeUser+'/'+tag_id)
		.then((response) => {
			let total = response.data.result;
			context.commit('set_JOPending', total);  
		})
		.catch((error) => {
			console.log(error); 
		});
	},
    update_menuTags(context) {
    	return new Promise((resolve, reject) => {
	    	window.master.get("/jobs/getJobsTags/"+window.master_client +'/'+window.localorgdata)
			.then((response) => {
				let menutags = response.data.result;
				context.commit('set_menuTags', menutags);  
				resolve(true); // valor de retorno positivo 
			})
			.catch((error) => {
				console.log(error); 
				reject(false); // valor de retoro al fallar 
			});
		});
	}
}
