let changePage = function(page) {
	this.page = page;			
	if(typeof this.page != 'number') { 
		this.page= 1; 
		console.log('no es number');
	}
	this.pagination.current_page = page;
	this.query = "?page=" + page;
	this.searchFilter();
};
export default changePage;
