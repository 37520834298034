<template>
    <div class="row card-body" style="padding-top: 0px;padding-bottom: 5em;">
        <div class="card-header window-header" style="margin: 0px -10px 0px -10px; width: 105%;">
            <h4 style="margin-bottom: 0px;">
                Hours:
                <button type="button"
                    class="btn btn-success btn-sm"
                    :disabled="isDisabled"
                    style="margin-left: 10px;"
                    @click="saveForm()"
                >
                        Save
                </button>
            </h4>
		</div>
        <div class="window-body">
            <div class="row" v-if="hours.length>0">
                <template v-for="(h, hIdx24) in hours">
                    <div class="col-12" style="margin-top: 15px; border-bottom: 1px solid #ddd; padding-bottom:10px;" :key="hIdx24" v-if="h.day_id==24">
                        <label class="col-form-label" for="hours24Open" style="font-weight: bold;">Open {{ h.day_name }}</label><br>
                        <label class="col-form-label">
                            <input type="checkbox" v-model="h.active" value="1" :checked="h.active" @change="changeHours24Open(h)" id="hours24Open"> Active
                        </label>
                    </div>
                </template>
                <template v-for="(h, hIdx) in hours">
                    <div class="col-12" style="margin-top: 15px; border-bottom: 1px solid #ddd; padding-bottom:10px;" :key="hIdx" v-if="h.day_id!=24">
                        <div v-if="hours24Open" class="bg-disabled">
                            <!-- <span>Not available for your current plan</span> -->
                        </div>
                        <label class="col-form-label" style="font-weight: bold;">{{ h.day_name }}</label><br>
                        <label class="col-form-label">
                            <input type="checkbox" v-model="h.closed" value="1" :checked="h.closed"> Closed
                        </label>
                        <div class="open_closed ">
                            <div :class="['input_hours', h.closed==1 ? 'disabled' : '']">
                                <label :class="[h.closed==1 ? 'disabled' : '']">Open at </label>
                                <div class="input-group">
                                    <input type="time" :max="h.closes_at" min="07:00" step="600" :class="['form-control', h.closed==1 ? 'disabled' : '']" v-model="h.opens_at" :disabled="h.closed==1">
                                    <!-- <input type="text" :class="['form-control', h.closed==1 ? 'disabled' : '']" v-model="h.opens_at" :disabled="h.closed==1"> -->
                                    <!-- <span :class="['input-group-text', h.closed==1 ? 'disabled' : '']">
                                        <select v-model="h.opens_me" :class="['amOrpm', h.closed==1 ? 'disabled' : '']" :disabled="h.closed==1">
                                            <option value="1">am</option>
                                            <option value="2">pm</option>
                                        </select>
                                    </span> -->
                                </div>
                            </div>
                            <div :class="['input_hours', h.closed==1 ? 'disabled' : '']">
                                <label :class="[h.closed==1 ? 'disabled' : '']">Close at</label>
                                <div class="input-group">
                                    <input type="time" max="22:30" :min="h.opens_at" step="600" :class="['form-control', h.closed==1 ? 'disabled' : '']" v-model="h.closes_at" :disabled="h.closed==1">
                                    <!-- <input type="text" :class="['form-control', h.closed==1 ? 'disabled' : '']" v-model="h.closes_at" :disabled="h.closed==1"> -->
                                    <!-- <span :class="['input-group-text', h.closed==1 ? 'disabled' : '']">
                                        <select v-model="h.closes_me" :class="['amOrpm', h.closed==1 ? 'disabled' : '']" :disabled="h.closed==1">
                                            <option value="1">am</option>
                                            <option value="2">pm</option>
                                        </select>
                                    </span> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div class="row" style="width: 100%;margin-top: 40px;">
                <div class="col-12 text-center" style="">
                    <button type="button"
                        class="btn btn-success btn-lg"
                        @click="saveForm()"
                        :disabled="isDisabled"
                        style="width: 100px;border-radius: 20px;"
                    >
                    Save
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:"editSchedule",
    data(){
        return {
            cardItem: null,
            hours : null,
            apis: null,
            isDisabled: false,
            hora12: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            minutos: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55],
            hours24Open: false,
        };
    },
    created(){
        let t = this;
        let urlApis = this.$store.getters['ModApps/Marketea/urlApis'];
        this.apis = urlApis.bSite;
        t.cardItem = this.$store.getters['ModApps/Marketea/get_bsite_cardItem'];
        t.hours = t.ifExistSchedule(t.cardItem);
    },
    methods: {
        ifExistSchedule(item){
            let t = this;
            let hours = null;
            let hoursDefault= [
                {day_id:1, day_name:'Monday', closed:0, opens_at:'08:00', opens_me:1, closes_at:'17:00', closes_me:2},
                {day_id:2, day_name:'Tuesday', closed:0, opens_at:'08:00', opens_me:1, closes_at:'17:00', closes_me:2},
                {day_id:3, day_name:'Wednesday', closed:0, opens_at:'08:00', opens_me:1, closes_at:'17:00', closes_me:2},
                {day_id:4, day_name:'Thursday', closed:0, opens_at:'08:00', opens_me:1, closes_at:'17:00', closes_me:2},
                {day_id:5, day_name:'Friday', closed:0, opens_at:'08:00', opens_me:1, closes_at:'17:00', closes_me:2},
                {day_id:6, day_name:'Saturday', closed:1, opens_at:'08:00', opens_me:1, closes_at:'17:00', closes_me:2},
                {day_id:0, day_name:'Sunday', closed:1, opens_at:'08:00', opens_me:1, closes_at:'17:00', closes_me:2},
            ];
            if(item.temp_opts && item.temp_opts.length>0){
                for (const key in item.temp_opts) {
                    if (Object.hasOwnProperty.call(item.temp_opts, key)) {
                        if(item.template_id==item.temp_opts[key].template_id){
                            if(item.temp_opts[key].meta_key=='schedule' && item.temp_opts[key].meta_value!=null && item.temp_opts[key].meta_value.length>0){
                                hours = JSON.parse(item.temp_opts[key].meta_value);
                            }
                        }
                    }
                }
            }
            if(hours==null){hours = hoursDefault;}
            let validHours24 = true;
            for (const key in hours) {
                if (Object.hasOwnProperty.call(hours, key)) {
                    const element = hours[key];
                    // console.log('hours', key, element)
                    if(element.day_name=='24 hours'){
                        t.hours24Open = element.active;
                        validHours24 = false;
                        // console.log('is exist fields 24 hours', t.hours24Open)
                    }
                }
            }
            if(validHours24){
                hours.push({day_id:24, day_name:'24 hours', active: 0},)
                // console.log('new fields 24 hours');
            }
            console.log('isSchedule', hours)
            return hours;
        },
        changeHours24Open(h){
            let t = this;
            t.hours24Open = h.active;
            console.log('changeHours24Open', t.hours24Open)
        },
        saveForm(editUrl){
            let t = this;
            t.isDisabled = true;
            let formData = new FormData();
            // @ts-ignore
            formData.append("user_id", window.master_client);
            // @ts-ignore
            formData.append("org_id", window.localorgdata);
            formData.append("id", t.cardItem.id);
            formData.append("template_id", t.cardItem.template_id);
            formData.append("meta_key", 'schedule');
            for (const key in t.hours) {
                if (Object.hasOwnProperty.call(t.hours, key)) {
                    if(t.hours[key].day_name!='24 hours'){
                        let opens_hour = (t.hours[key].opens_at.indexOf(':')) ? parseInt(t.hours[key].opens_at.split(':')[0]) : parseInt(t.hours[key].opens_at);
                        if(opens_hour>11){t.hours[key].opens_me=2}else{t.hours[key].opens_me=1}
                        let closes_hour = (t.hours[key].closes_at.indexOf(':')) ? parseInt(t.hours[key].closes_at.split(':')[0]) : parseInt(t.hours[key].closes_at);
                        if(closes_hour>11){t.hours[key].closes_me=2}else{t.hours[key].closes_me=1}
                    }
                }
            }
            formData.append("meta_value", JSON.stringify(t.hours));
            formData.append("pusher_id", pusher.connection.socket_id);
            t.$store.dispatch('ModApps/Marketea/setTempOpts', formData)
            .then(r => {
                console.log('setOptionsTemplate', r);
                t.isDisabled = false;
                setTimeout(() =>{
                    t.$parent.close();
                }, 400);
            })
            .catch(error => {
                console.log(error);
                t.isDisabled = false;
                t.$notify({
                    group: "noti",
                    type: 'Error',
                    title: "Fail",
                    text: "Failed to save",
                    duration: 2000
                });
            });
        },
    },
    watch: {
    },
}
</script>
<style lang="scss" scoped>
.window-header{cursor: move;}
.card-header h4{font-size:24px;}
.col-form-label, .form-control, .input-group-text{
    font-size:18px;
}
.col-form-label{
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px;
    line-height: 1;
}
.window-body{
    height: calc(100vh - 45px);
    height: 600px;
    overflow: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    padding-bottom: 3em;
    width: 100%;
    padding-left: 15px;
    &::-webkit-scrollbar:vertical {
        width:3px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #797979;
        border-radius: 20px;
        border: 0px solid #f1f2f3;
    }
    .row{
        width: 100%;
    }
}
.open_closed{
    position: relative;
    width: 100%;
    display: flex;
}
.input_hours{
    margin-left:15px;
    position: relative;
    margin-top:5px;
    width: 100%;
    max-width: 160px;
    padding-top: 15px;
    border-radius: 4px;
    box-sizing: border-box;
    height: 85px;
    // border: 1px dashed red;
    padding-left: 2px;
    padding-right: 2px;
    label{
        padding: 5px 15px;
        position: absolute;
        z-index: 2;
        top:  0px;
        left: 10px;
        background: #fff;
        margin-bottom: 0px;
        height: 32px;
        // border: 1px dashed red;
    }
    .input-group{
        position: relative;
        z-index: 1;
        // border: 1px dashed #0700ff;
        height: 60px;
        padding: 4px 3px;
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        .form-control{
            line-height: 1;
            padding: 15px 10px 10px 15px;
            height: 50px;
            box-sizing: border-box;
            // border: 1px dashed #0ca706;
            width: 100%;
            max-width: 140px;
            min-width: 140px;
            display: flex;
            align-items: center;
            justify-content: center;

        }
        .input-group-text{
            padding: 0px;
            background: #fff;
            border-left:0px;
            select.amOrpm{
                padding: 10px;
                border:0px;
            }
        }
    }
}
@media (max-width:767px){
    .window-body{
        height: 100%;
        max-height: 800px;
        padding-bottom: 20em;
        padding-left: 5px;
    }
    .input_hours{
        margin-left: 10px;
    }
}
input[type="time"].form-control {
    font-size: 1rem;
}
.bg-disabled {
    position: absolute;
    top:0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 5;
    background: rgb(221 221 221 / 80%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
}
.bg-disabled span {
    color:#a11a1a;
    font-weight: bold;
    font-size: 20px;
    text-shadow: 0px 0px 0px #555;width: 90%;
    margin: auto;
    text-align: center;
}
</style>
