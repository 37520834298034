<template>
    <div>
        <div class="card-header">
            <h3>Widget Projects Slider</h3>
        </div>
        <div class="card-body" style="min-height: 200px">
            <div class="row">
                <div class="col-sm-12 text-center" style="margin-bottom: 20px;">
                    <dropdown-menu
                        v-model="dropdownStatus.show"
                        :right="dropdownStatus.right"
                        :hover="dropdownStatus.hover"
                        :interactive="dropdownStatus.interactive"
                        :closeOnClickOutside="dropdownStatus.closeOnClick"
                    >
                        <button class="btn btn-light">
                            <h4>Filter List: <small>{{dropdownStatusVal}}</small></h4>
                        </button>
                        <div slot="dropdown">
                            <a class="dropdown-item" href="#" @click.prevent="dropdownStatusVal=opts[0]">{{opts[0]}}</a>
                            <a class="dropdown-item" href="#" @click.prevent="dropdownStatusVal=opts[1]">{{opts[1]}}</a>
                            <a class="dropdown-item" href="#" @click.prevent="dropdownStatusVal=opts[2]">{{opts[2]}}</a>
                            <a class="dropdown-item" href="#" @click.prevent="dropdownStatusVal=opts[3]">{{opts[3]}}</a>
                        </div>
                    </dropdown-menu>
                </div>
                <div class="col-sm-12">
                    <textarea
                        id="shortCode"
                        class="form-control"
                        style="height: 50px; max-height: 50px; display: block; text-align: center; resize: none; overflow: hidden;"
                        readonly
                        onClick="this.select()"
                        v-model="shortCode"
                    ></textarea>
                </div>
                <div class="col-12 text-center" style="margin-top: 10px;">
                    <button class="btn btn-primary" @click="copyCodeInput('textarea#shortCode')">Copy Short code</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import DropdownMenu from '@innologica/vue-dropdown-menu';
import './menuHeader.scss';
export default {
    components: {
		'dropdown-menu': DropdownMenu,
    },
    props: {
    },
    data() {
        return {
            org_id: window.localuserdata,
            user_id: window.master_client,
            isLoading: false,
            isCode: true,
            codeHtml: "",
            shortCode: "",
            dropdownStatusVal: 'Any status',
            opts: [
                "Any status",
                "Current",
                "Completed",
                "To Be Built",
            ],
            dropdownStatus: {
                show : false,
				right: true,
				hover: false,
				interactive: false,
				closeOnClick: true,
            },
            filter: 'all',
        };
    },
    created() {
        this.changeTextarea();
    },
    methods: {
        changeTextarea() {
            // < = &#60; > = &#62;
            let e = "[";
            e+= "shortCode ";
            e+= "app=bSite ";
            e+= "oId="+this.org_id+" ";
            e+= "uId="+this.user_id+" ";
            e+= "widget=ProjectsSlider ";
            e+= "filter="+this.filter+" ";
            e+= "timer=10s ";
            e+= "/]";
            this.shortCode = e;
        },
        copyCodeInput(inputCode) {
            let t =this;
            var copyCode = document.querySelector(inputCode);
            if(copyCode){
                copyCode.classList.toggle('selected');
                setTimeout(() => {
                    var textToCopy = copyCode.value;
                    try {
                        t.copyToClipboard(textToCopy);
                        copyCode.classList.toggle('selected');
                        t.$emit('actionReturn', {action: 'onlyCloseModal'});
                    } catch(error) {
                        console.error('Error al copiar al portapapeles:', error)
                        copyCode.classList.toggle('selected');
                    }
                }, 500);
            }
        },
        copyToClipboard(textToCopy) {
            // Navigator clipboard api needs a secure context (https)
            if (navigator.clipboard && window.isSecureContext) {
                navigator.clipboard.writeText(textToCopy);
                console.log('Text copied to the clipboard!');
            } else {
                console.log('Navigator clipboard FALSE');
                // Use the 'out of viewport hidden text area' trick
                const textArea = document.createElement("textarea");
                textArea.value = textToCopy;
                // Move textarea out of the viewport so it's not visible
                textArea.style.position = "absolute";
                textArea.style.left = "-999999px";
                document.body.prepend(textArea);
                textArea.select();

                try {
                    document.execCommand('copy');
                    console.log('execCommand copy');
                } catch (error) {
                    console.error('error execCommand', error);
                } finally {
                    textArea.remove();
                }
            }
        },
    },
    watch: {
        dropdownStatusVal: function(){
            let t = this;
            t.dropdownStatus.show=false;
            if(t.dropdownStatusVal==t.opts[0]){t.filter='all';}
            if(t.dropdownStatusVal==t.opts[1]){t.filter='current';}
            if(t.dropdownStatusVal==t.opts[2]){t.filter='completed';}
            if(t.dropdownStatusVal==t.opts[3]){t.filter='toBeBuilt';}
            t.changeTextarea();
        }
    }
};
</script>
<style lang="scss" scoped>
.selected{
    background: #0d6efd!important;
    color:#fff!important;
}
</style>
