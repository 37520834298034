export default {
	SET_HEADER(state, val) { state.header = val; },
	SET_SHOWASIDE(state, showAside) {
		state.showAside = showAside
	},
	set_accitonPusher(state, val) { state.accitonPusher = val },
	set_chatActive(state, val) { state.chatActive = val; },
	setOpenModal(state, val) { state.openModal = val; },
	setModalActivity(state, val) { state.openModal.data = val; },
	setLoadingModal(state, val) { state.loadingModal = val; },
	setCloseModal(state, val) { 
		// console.log('state.openModal.show', state.openModal.show);
		let actionData = {}
		if (Object.keys(val).length > 0) {
			actionData = val;
		}
		state.openModal = {
			show: false,
			component: '',
			data: actionData
		}
		console.log('setCloseModal', state.openModal);
	},
	setNotiPush(state, val) { 
			state.notiPush = {
			group: val.group,
			type: val.type,
			title: val.title,
			text: val.text,
			show: val.show,
			duration: val.duration || 3000
		}; 
	},
	setNotiPushHide(state, val) { state.notiPush.show = false; },
	setNotiUpdate(state, val) { state.notiUpdate = val; },
	setNotiUpdateHide(state, val) { state.notiUpdate.show = false; },
	setNotiPushRefresh(state, val) { state.notiPushRefresh= val; },
	setAlertSimple(state, val) { 
		// console.log('setAlertSimple', state.alertSimple.show);
		state.alertSimple.icon = val.icon;
		state.alertSimple.title = val.title;
		state.alertSimple.text = val.text;
		state.alertSimple.showConfirmButton = val.showConfirmButton;
		state.alertSimple.timer = val.timer;
		state.alertSimple.show = val.show;
		// console.log('setAlertSimple', state.alertSimple.show);
	},
	setAlertSimpleHide(state, val) { 
		state.alertSimple.icon = ''; // success | error
		state.alertSimple.title = ''; // titulo del msjs
		state.alertSimple.text = ''; // descripcion del msj
		state.alertSimple.showConfirmButton = false; // button Ok
		state.alertSimple.timer = 3000; // tiempo en pantalla
		state.alertSimple.show = false;
		// console.log('setAlertSimpleHide', state.alertSimple);
	},
	setAlertConfirm(state, i) { 
		// console.log('setAlertConfirm', state.alertConfirm.show);
		// state.alertConfirm.icon = val.icon;
		// state.alertConfirm.title = val.title;
		// state.alertConfirm.text = val.text;
		// state.alertConfirm.show = val.show;
		// console.log('setAlertConfirm', state.alertConfirm.show);
		if (i.show) {
			debugger;
			return new Promise((resolve, reject) => {
				i.t.$swal({
					title: i.title,
					text: i.text,
					icon: i.icon,
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					confirmButtonText: "Yes, Delete it!",
				}).then((result) => {
					resolve(result);
				})
				.catch((result) => {
					resolve(result);
				});
			});
		}
	},
	setAlertConfirmHide(state, val) { 
		state.alertConfirm.icon = ''; // success | error
		state.alertConfirm.title = ''; // titulo del msjs
		state.alertConfirm.text = ''; // descripcion del msj
		state.alertConfirm.show = false;
		// console.log('setAlertConfirmHide', state.alertConfirm);
	},
	setActionRefresh(state, val){
		state.actionRefresh = {
			action:val.action, // action
			data: val.data // value
		};
		console.log('setActionRefresh', state.actionRefresh);
	},
	set_showSuscription(state, val) { state.showSuscription = val; },
	showSuscriptionEmpty(state, val) { state.showSuscription = null; },
}
