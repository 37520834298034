<template>
    <div class="row card-body" style="padding-top: 0px;padding-bottom: 0px;">
        <div class="card-header window-header" style="margin: 0px -10px 0px -10px; width: 105%;">
            <h4 style="margin-bottom: 0px;">
                <h4>Botons Action:</h4>
            </h4>
		</div>
        <div class="row window-body">
            <div class="col-12" v-if="item!=null">
                <div class="row">
                    <div class="col-md-5" style="padding-top:20px;">
                        <div class="chooseColor">
                            <label class="col-form-label">Background:</label>
                            <span class="sampleColor" :style="muestraBgColor()" @click="showEditBgColor()"></span>
                            <span class="btnOk" @click="changeBgColor()" v-if="editBgColor">Ok</span>
                            <span class="btnClose" @click="closeBgColor()" v-if="editBgColor">X</span>
                        </div>
                        <color-picker v-model="sampleBgColor" class="getcolor" v-if="editBgColor"></color-picker>
                    </div>
                    <div class="col-md-3" style="padding-top:20px;">
                        <div class="chooseColor">
                            <label class="col-form-label">Text:</label>
                            <span class="sampleColor" :style="muestraTextColor()" @click="showEditTextColor()"></span>
                            <span class="btnOk" @click="changeTextColor()" v-if="editTextColor">Ok</span>
                            <span class="btnClose" @click="closeTextColor()" v-if="editTextColor">X</span>
                        </div>
                            <color-picker v-model="sampleTextColor" class="getcolor" v-if="editTextColor" ></color-picker>
                    </div>
                    <div class="col-md-4" style="padding-top:20px; text-align: center;">
                        <button type="button" class="btn btn-success" @click="setColor()">Save Color</button>
                        <button type="button" class="btn btn-light" style="margin-left: 10px; border-color:#ccc;" @click="cancelColor()">Cancel</button>
                    </div>
                </div>
                <draggable
                    v-model="btns_action"
                    tag="div"
                    class="btns_action"
                    v-bind="dragOptions"
                    @start="isDragging(true)"
                    @end="isDragging(false)"
                    @change="checkMoveFindMeAt(btns_action)"
                    handle=".handle"
                >
                    <template v-for="(i, index) in btns_action">
                        <div class="row marginTop" :key="'contact'+index" style="position:relative;">
                            <div v-if="showDivDisabled(item.template_id, index)" class="bg-disabled">
                                <span>Not available for this template</span>
                            </div>
                            <div class="col-1">
                                <div class="btnmove handle" style="line-height: 50px;" v-if="validMove(item.template_id)">
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 512 512" xml:space="preserve" class="icon-drag">
                                        <circle cx="153.6" cy="-105" r="61"></circle>
                                        <circle cx="153.6" cy="61" r="61"></circle>
                                        <circle cx="153.6" cy="256" r="61"></circle>
                                        <circle cx="153.6" cy="451" r="61"></circle>
                                        <circle cx="153.6" cy="646" r="61"></circle>
                                        <circle cx="153.6" cy="851" r="61"></circle>
                                        <circle cx="358.4" cy="-105" r="61"></circle>
                                        <circle cx="358.4" cy="61" r="61"></circle>
                                        <circle cx="358.4" cy="256" r="61"></circle>
                                        <circle cx="358.4" cy="451" r="61"></circle>
                                        <circle cx="358.4" cy="646" r="61"></circle>
                                        <circle cx="358.4" cy="851" r="61"></circle>
                                    </svg>
                                </div>
                            </div>
                            <div class="col-11">
                                <div class="row">
                                    <div class="col-12">
                                        <button type="button" @click="openEditBtn(index, i)" class="btnOpenEdit" :style="customBgBtn()">
                                            {{ i.label }} <i class="fas fa-pen"></i>
                                        </button>
                                    </div>
                                    <div class="row"  v-if="showBtnIndex==index">
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-8 ">
                                                    <label class="col-form-label">Label:</label>
                                                    <div class="input-group">
                                                        <input type="text" class="form-control"
                                                            v-model="i.label"
                                                            maxlength="100"
                                                            @change="setBtnActions()"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-4 text-center">
                                                    <label class="col-form-label">Show:</label>
                                                    <div class="onoffswitch">
                                                        <div v-if="((index==0))"
                                                    class="switchDisabled"></div>
                                                        <input
                                                            type="checkbox"
                                                            class="onoffswitch-checkbox"
                                                            :id="'status-'+i.tipo+'-'+index"
                                                            v-model="i.status"
                                                            :value="1"
                                                            @change="setBtnActions()"
                                                            :disabled="(index==0)"
                                                        />
                                                        <label class="onoffswitch-label" :for="'status-'+i.tipo+'-'+index" >
                                                            <span class="onoffswitch-inner"></span>
                                                            <span class="onoffswitch-switch"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <label class="col-form-label">Type:</label>
                                                    <select class="form-control" v-model="i.icon" @change="changeType(i)">
                                                        <option v-for="(t, idxT) in btns_types" :key="idxT"
                                                        :value="t.value" :selected="(t.value==i.icon)">{{ t.text }}</option>
                                                    </select>
                                                </div>
                                                <div class="col-md-6">
                                                    <label class="col-form-label">Value: </label>
                                                    <div class="input-group">
                                                        <input type="text" class="form-control"
                                                            v-if="i.icon.indexOf('system_action')>-1"
                                                            readonly
                                                            :value="i.val"
                                                        />
                                                        <input type="text" class="form-control"
                                                            v-else
                                                            v-model="i.val"
                                                            maxlength="100"
                                                            @change="setBtnActions()"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </draggable>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import draggable from 'vuedraggable';
import { Sketch } from "vue-color";
export default {
    name:"editBtnsActions",
    components:{
        draggable,
        "color-picker": Sketch,
    },
    data(){
        return {
            default_btn: {
                user_id : window.master_client,
                org_id : window.localorgdata,
                id: 1,
                tipo: 3,
                icon: 'system_action_add_contact',
                label: 'ADD TO CONTACT',
                val: 'system_action_add_contact',
                style: {bgColor: "#53626a", txtColor: "#ffffff"},
                status: 1,
                orden: 0,
            },
            style_default: {bgColor: "#53626a", txtColor: "#ffffff"},
            urlweb : window.urlbSite,
            limit_bts: 3,
            btns_action: [],
            btns_types: [{
                value: 'system_action_add_contact',
                text: 'System Action Add to Contact'
            },{
                value: 'custom_action',
                text: 'Custom Action Link'
            }],
            PlanPremium: false,
            premiumProfile: false,
            planActual: 1,
            tlfOrg: null,
            webOrg: null,
            showBtnIndex: null,
            editTextColor: false,
            getTextColor: null,
            sampleTextColor: null,
            getBgColor: null,
            sampleBgColor: null,
            editBgColor: false,
            oldTextColor: null,
            oldBgColor: null,
        };
    },
    created(){
        // valid premium planID
        this.getOrg();
        this.PlanPremium = (this.$store.getters['core/apps/getPlanIdApp']==6 || this.$store.getters['core/apps/getPlanIdApp']==11);
        this.planActual = this.$store.getters['core/apps/getPlanIdApp'];
        this.premiumProfile = (this.$store.getters['core/apps/getPlanIdApp']!=8);
        if(this.planActual==7){
            this.PlanPremium = true;
            console.log('Plan sponsored, dando permiso de premium', this.PlanPremium);
        }
        console.log('PlanPremium', this.PlanPremium);
        if(this.premiumProfile){
            this.item = this.$store.getters['ModApps/Marketea/get_bsite_cardItem'];
            this.read_btns_action(this.item);
        }
        else{
            this.showPremium();
        }
    },
    methods:{
        showPremium(){
            let t = this;
            t.$store.commit('core/setNotiPush', {
                show: true,
                group: "notiPush",
                type: '5', // 1=success|2=info|3=error|4=loading|5=warning
                title: 'This feature is available for PREMIUM USERS.',
                text: 'Upgrade your App to Premium and advanced features, Please contact your administrator at support@marketea.com or Call us at 774-287-3353.',
                duration: 15000
            });
            let appData = {
                app_id: 8,
                show: true,
                infoApp: null,
                planes: [],
            }
            t.$store.commit("core/set_showSuscription", appData);
        },
        getOrg(){
            let t = this;
            let getOrg = this.$store.getters['core/Org/orginf'];
            if(getOrg != null){
                console.log('getters FormInfo', getOrg);
                t.tlfOrg = getOrg.com_telephone;
                t.webOrg = getOrg.com_website;
            }
        },
        isDragging(t){
            console.log('move')
            return t;
        },
        validMove(template_id){
            let valid = false;
            let idValid=[3, 4, 5, 6];
            if(idValid.indexOf(template_id)>-1){valid = true;}
            else{valid = false;}
            return valid;
        },
        showDivDisabled(template_id, index){
            let valid = false;
            let idValid=[3, 4, 5, 6];
            if(idValid.indexOf(template_id)==-1 && index>0){valid = true;}
            else{valid = false;}
            return valid;
        },
        checkMoveFindMeAt: function(list){
            list = list.filter((r, idx) => {
                r.orden = idx;
                return r;
            });
            console.log('checkMove', list);
            this.setBtnActions();
        },
        read_btns_action(r){
            let t = this;
            console.log('read_btns_action', r.btns_action);
            if(r.btns_action!=null && r.btns_action.length>0){
                t.btns_action = r.btns_action.filter(i => {
                    i.val = (i.val==null || i.val=='null') ? '' : i.val;
                    i.label = (i.label==null || i.label=='null') ? 'Name Btn' : i.label;
                    i.style = (i.style==null || i.style=='null' || i.style=='' || i.style==='[object Object]') ? t.style_default : ((typeof i.style === 'object') ? i.style : JSON.parse(i.style));
                    t.oldTextColor = i.style.txtColor;
                    t.getTextColor = i.style.txtColor;
                    t.oldBgColor = i.style.bgColor;
                    t.getBgColor = i.style.bgColor;
                    return i;
                });
            }
            else{
                t.btns_action.push(t.default_btn);
            }
            if(t.btns_action.length<3){
                if(t.btns_action.length==1){
                    t.btns_action.push({
                        user_id : window.master_client,
                        org_id : window.localorgdata,
                        id: 2,
                        tipo: 3,
                        icon: 'custom_action',
                        label: 'CALL',
                        val: 'tel:'+t.tlfOrg || 'https://www.mylink.com',
                        style: t.style_default,
                        status: 0,
                        orden: 1,
                    });
                    t.btns_action.push({
                        user_id : window.master_client,
                        org_id : window.localorgdata,
                        id: 3,
                        tipo: 3,
                        icon: 'custom_action',
                        label: 'WEBSITE',
                        val: t.webOrg || 'https://www.mylink.com',
                        style: t.style_default,
                        status: 0,
                        orden: 2,
                    });
                }
                else if(t.btns_action.length==2){
                    t.btns_action.push({
                        user_id : window.master_client,
                        org_id : window.localorgdata,
                        id: 3,
                        tipo: 3,
                        icon: 'custom_action',
                        label: 'WEBSITE',
                        val: t.webOrg || 'https://www.mylink.com',
                        style: t.style_default,
                        status: 0,
                        orden: 2,
                    });
                }
            }
            console.log('getTextColor', t.getTextColor, 'getBgColor', t.getBgColor)
        },
        changeType(i){
            if(i.icon.indexOf('system_action')>-1){
                i.val = i.icon;
            }else{
                i.val = 'https://www.mylink.com';
            }
            this.setBtnActions();
        },
        setBtnActions(){
            let t = this;
            if(t.btns_action[0]['status']==0){
                t.btns_action[0]['status']=1;
            }
            this.item.btns_action=t.btns_action;
            let payload = {
                key: 'bsite_cardItem',
                val: t.item,
            };
            console.log('change contact', t.item);
            t.$store.commit('ModApps/Marketea/set_bSiteValStorage', payload);
            t.$store.dispatch('ModApps/Marketea/setBtnActions')
            .then(r => {
                console.log('set_btns_action', r);
            })
            .catch(error => {
                console.log(error);
                t.$notify({
                    group: "noti",
                    type: 'Error',
                    title: "Fail",
                    text: "Failed to save",
                    duration: 2000
                });
            });
        },
        openEditBtn(idx, i){
            this.showBtnIndex = idx;
        },
        customBgBtn(){
            let t = this;
            let s = '';
            s+='background: '+t.getBgColor+';';
            s+='border-color: '+t.getBgColor+';';
            s+='color: '+t.getTextColor+';'
            return s;
        },
        muestraTextColor(){
            return 'background-color:'+this.getTextColor+';';
        },
        showEditTextColor(){
            this.sampleTextColor= this.getTextColor;
            this.editTextColor=true;
        },
        closeTextColor(){
            this.editTextColor=false;
        },
        changeTextColor(){
            this.editTextColor=false;
            if(this.sampleTextColor.hex8){
                this.getTextColor = this.sampleTextColor.hex8;
                this.customBgBtn();
            }
        },
        muestraBgColor(){
            return 'background-color:'+this.getBgColor+';';
        },
        showEditBgColor(){
            this.sampleBgColor = this.getBgColor;
            this.editBgColor=true;
        },
        closeBgColor(){
            this.editBgColor=false;
        },
        changeBgColor(){
            this.editBgColor=false;
            if(this.sampleBgColor.hex8){
                this.getBgColor = this.sampleBgColor.hex8;
                this.customBgBtn();
            }
        },
        setColor(){
            let t = this;
            let newList = t.btns_action.filter(i => {
                i.style.bgColor = t.getBgColor;
                i.style.txtColor = t.getTextColor;
                i.style = JSON.stringify(i.style);
                return i;
            });
            t.btns_action = newList;
            t.setBtnActions();
        },
        cancelColor(){
            let t = this;
            let newList = t.btns_action.filter(i => {
                i.style.bgColor = t.oldBgColor;
                i.style.txtColor = t.oldTextColor;
                return i;
            });
            t.getBgColor= t.oldBgColor;
            t.getTextColor= t.oldTextColor;
            t.btns_action = newList;
        }
    },
    watch: {
        getCardItem: function(){
            if(this.getCardItem!=null){
                this.item=this.getCardItem;
            }
        },
        item: function(){
            if(this.item!=null){
                let t = this;
                t.read_btns_action(t.item);
            }
        },
    },
    computed: {
        ...mapGetters("ModApps/Marketea", {
            getCardItem: "get_bsite_cardItem",
        }),
        item: {
            get: function () {
                let t = this;
                return t.getCardItem;
            },
            set: function (v) {
                return v;
            }
        },
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
            };
        },
    }
}
</script>
<style lang="scss" scoped>
.window-header{cursor: move;}
.marginTop{margin-top: 20px;}
.card-header h4{font-size:24px;margin-bottom: 0px;}
.onoffswitch{
    &.disabled{
        .onoffswitch-label{
            .onoffswitch-inner{
                &:before, &:after {
                    background-color: #c3c3c4 !important;
                    color: #555;
                }
            }
        }
    }
    .onoffswitch-label{
        .onoffswitch-inner{
            &:before{
                padding-left: 10px;
            }
        }
    }
}
.btnmove {
    position: relative;
    left: 0px;
    top: 0px;
    width: 18px;
    height: 100%;
    background-color: #fff;
    z-index: 10;
    font-size: 12px;
    line-height: 75px;
    text-align: center;
    vertical-align: middle;
    font-weight: normal;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    display: flex;
    .icon-drag{
        fill: #ddd;
    }
    &:hover {
        /*background-color: #e0e0e0;*/
        background-color: #e1f2fb;
        cursor: move;
        .icon-drag{
            fill: #000;
        }
    }
}
.btns_action{
    position: relative;
    width: 100%;
}
.window-body{
    position: relative;
    width: 100%;
    margin: auto;
    height: 500px;
    border: 0px solid #000;
    margin-bottom: 2em;
    overflow: hidden;
    overflow-y: auto;
}
.bg-disabled {
    position: absolute;
    top:0px;
    left: -1%;
    width: 100%;
    height: 108%;
    background: rgb(221 221 221 / 80%);
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
}
.bg-disabled span {
    color:#a11a1a;
    font-weight: bold;
    font-size: 25px;
    text-shadow: 0px 0px 0px #555;
}
.switchDisabled {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(221 221 221 / 50%);
    z-index: 2;
    border-radius: 20px;
}
@media (max-width:767px){
    .window-body{
        height: 100%;
        max-height: 800px;
        padding-bottom: 20em;
    }
}
.btnOpenEdit {
    background: linear-gradient(135deg, #53626a 0%, rgb(40 40 41) 100%);
    border-color: #2a2d2d;
    color: #ffffff;
    text-decoration: none;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    display: flex;
    padding: 0px;
    height: 50px !important;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    position:relative;
    cursor: pointer;
}
.btnOpenEdit i.fas {
    position: absolute;
    right: 15px;
}
.sampleColor{
    width: 40px;
    height: 20px;
    display: flex;
    justify-content: center;
    margin-left: 5px;
    margin-right: 5px;
    position: relative;    top: 5px;
    border:1px solid #ddd;
}
.btnOk{
    background: #5ac146;
    color: #fff;
    width: 30px;
    font-size: 12px;
    height: 20px;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-left: 5px;
    position: relative;
    line-height: 1;
    padding-top: 3px;
    text-align: center;
    top: 5px;
    &:hover{cursor: pointer;}
}
.btnClose{
    background: #be0606;
    color: #fff;
    width: 20px;
    font-size: 12px;
    height: 20px;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
    position: relative;
    line-height: 1;
    padding-top: 3px;
    text-align: center;
    top: 5px;
    &:hover{cursor: pointer;}
}
.getcolor {
    position: absolute;
    top: 60px;
    z-index: 5;
}
.chooseColor{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
</style>
