<template>
    <div>
        <div class="container-fluid">
            <form @submit.prevent="saveInvoice">
                <div class="card">
                    <div class="alert alert-danger" role="alert" v-if="errors.length">
                        <ul style="margin-bottom: 0">
                            <li v-for="error in errors" :key="error">{{ error }}</li>
                        </ul>
                    </div>
                    <div class="card-header">
                        <div class="row">
                            <div class="col-6 align-self-center">
                                <h3 class="page-title">{{ formInvoice.titlePage }}</h3>
                            </div>
                            <div class="col-6 text-right">
                                <button
                                    type="submit"
                                    class="btn btn-success"
                                    :disabled="isMsjDate"
                                >
                                    <i class="fas fa-save"></i> SAVE
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-secundary"
                                    style="margin-right: 5px; margin-left: 5px"
                                    @click="$router.push({ name: 'InvoicesRec' })"
                                >
                                    <i class="fas fa-times"></i> CANCEL
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-danger"
                                    @click="MetDeleteInvoiceRec(formInvoice.idInv)"
                                    v-if="formInvoice.actionNname == 'update'"
                                >
                                    <i class="fas fa-trash-alt"></i> DELETE
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="bgloading" v-show="isLoading"><Loader></Loader></div>

                        <div class="row">
                            <div class="col-12">
                                <div class="row">
                                    <div
                                        class="col-md-12 col-xl-10 offset-xl-1 col-lg-12"
                                    >
                                        <div class="row">
                                            <div class="col-5">
                                                <label
                                                    for=""
                                                    style="
                                                        position: relative;
                                                        width: 100%;
                                                        display: block;
                                                    "
                                                    >Customer
                                                    <span
                                                        style="
                                                            position: absolute;
                                                            right: 0;
                                                            top: 3px;
                                                        "
                                                    >
                                                        <button
                                                            type="button"
                                                            @click="NewCustomer"
                                                            class="btnlink"
                                                        >
                                                            <i class="fas fa-plus"></i>
                                                            New Customer
                                                        </button>
                                                    </span>
                                                </label>
                                                <div
                                                    class="form-group"
                                                    id="CustomersList"
                                                >
                                                    <model-select
                                                        :options="CustomersList"
                                                        v-model="ValuesCustomers"
                                                        required
                                                        placeholder="Choose a Customer"
                                                    >
                                                    </model-select>
                                                </div>
                                            </div>
                                            <div class="col-5">
                                                <label for=""
                                                    >Invoice Name (Optional)
                                                </label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="formInvoice.invoicename"
                                                />
                                            </div>

                                            <div class="col-5">
                                                <label for="">Bill To</label>
                                                <div class="form-group bill_to">
                                                    {{ ValuesCustomers.customer_name }}
                                                    {{ ValuesCustomers.customer_lastname
                                                    }}<br />
                                                    <div v-if="ValuesCustomers.billing_address !==''">
                                                        {{
                                                            ValuesCustomers.billing_address
                                                        }}
                                                    </div>
                                                    {{ ValuesCustomers.city }}
                                                    {{ ValuesCustomers.state }},
                                                    {{ ValuesCustomers.code }} <br />
                                                    {{ ValuesCustomers.customer_phone }}
                                                    <br />
                                                    {{ ValuesCustomers.email }}
                                                </div>
                                            </div>
                                            <div class="col-5">
                                                <label for="">Job Information</label>
                                                <textarea
                                                    name=""
                                                    id="job_information"
                                                    cols="30"
                                                    rows="5"
                                                    class="form-control"
                                                    v-model="formInvoice.jobinformation"
                                                ></textarea>
                                            </div>
                                            <div class="col-2" style="padding-left: 0px">
                                                <div>
                                                    <label for="">Terms</label>
                                                    <select
                                                        class="form-control select-terms"
                                                        v-model="formInvoice.term_id"
                                                        required
                                                    >
                                                        <option :value="0">
                                                            Due on Receipt
                                                        </option>
                                                        <option
                                                            :value="item.id"
                                                            v-for="item in TermsList"
                                                            :key="item.id"
                                                        >
                                                            {{ item.name }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-12" v-if="isMsjDate">
                                                <div class="alert alert-danger">
                                                    <strong>{{ msjDate }}</strong>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-3 controlInterval">
                                                        <label for="">Interval</label>
                                                        <div>
                                                            <select
                                                                class="form-control select-terms"
                                                                v-model="
                                                                    formInvoice.interval
                                                                "
                                                                required
                                                            >
                                                                <!-- 
                                                                <option value="1" label="Weekly">Weekly</option>
                                                                <option value="2" label="Biweekly">Biweekly</option> 
                                                                -->
                                                                <option
                                                                    value="3"
                                                                    label="Monthly"
                                                                >
                                                                    Monthly
                                                                </option>
                                                                <option
                                                                    value="4"
                                                                    label="Quarterly"
                                                                >
                                                                    Quarterly
                                                                </option>
                                                                <option
                                                                    value="5"
                                                                    label="Semiannual"
                                                                >
                                                                    Semiannual
                                                                </option>
                                                                <option
                                                                    value="6"
                                                                    label="Annual"
                                                                >
                                                                    Annual
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-2 controlInterval">
                                                        <label for="">Start Date</label>
                                                        <div>
                                                            <datetime
                                                                type="date"
                                                                v-model="startdate"
                                                                format="MMM dd, yyyy"
                                                                input-class="form-control pdate"
                                                                value-zone="America/New_York"
                                                            ></datetime>
                                                        </div>
                                                    </div>
                                                    <div class="col-2 controlInterval">
                                                        <label for="">End Date</label>
                                                        <div>
                                                            <datetime
                                                                type="date"
                                                                v-model="enddate"
                                                                format="MMM dd, yyyy"
                                                                input-class="form-control pdate"
                                                                placeholder="M d, yyyy"
                                                                value-zone="America/New_York"
                                                            ></datetime>
                                                            <span
                                                                class="deleteEndDate"
                                                                @click="limpiarEndDate"
                                                                >x</span
                                                            >
                                                        </div>
                                                    </div>
                                                    <div class="col-2 controlInterval">
                                                        <label for=""
                                                            >Auto Notification</label
                                                        >
                                                        <div class="sendMail">
                                                            <input
                                                                type="checkbox"
                                                                v-model="
                                                                    formInvoice.send_email
                                                                "
                                                            />Send Email
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="col-2 controlInterval status"
                                                    >
                                                        <label for="">Status</label>
                                                        <div>
                                                            <select
                                                                class="form-control select-terms"
                                                                v-model="
                                                                    formInvoice.status
                                                                "
                                                            >
                                                                <option value="1">
                                                                    Active
                                                                </option>
                                                                <option value="0">
                                                                    Inactive
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="table-productos">
                                                <table
                                                    class="table table-striped"
                                                    style="margin-top: 10px"
                                                >
                                                    <thead
                                                        style="background-color: #cfd8dc"
                                                    >
                                                        <tr>
                                                            <th scope="col">
                                                                PRODUCT/SERVICE
                                                            </th>
                                                            <th scope="col">
                                                                DESCRIPTION
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                style="text-align: right"
                                                            >
                                                                QTY
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                style="text-align: right"
                                                            >
                                                                UNIT COST
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                style="text-align: center"
                                                            >
                                                                TAX
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                style="text-align: center"
                                                            >
                                                                PRICE
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <draggable
                                                        v-model="formList.items"
                                                        tag="tbody"
                                                        class="tbody"
                                                        v-bind="dragOptions"
                                                        @start="isDragging = true"
                                                        @end="isDragging = false"
                                                        handle=".handle"
                                                    >
                                                        <tr
                                                            v-for="(
                                                                item, index
                                                            ) in formList.items"
                                                            :key="index"
                                                        >
                                                            <th
                                                                scope="row"
                                                                style="position: relative"
                                                            >
                                                                <div
                                                                    class="btnmove handle"
                                                                >
                                                                    <i
                                                                        class="fas fa-ellipsis-v"
                                                                    ></i>
                                                                </div>
                                                                <model-select
                                                                    :options="
                                                                        item.options
                                                                    "
                                                                    v-model="item.model"
                                                                    placeholder="Choose a Products"
                                                                    class="productos"
                                                                ></model-select>
                                                                <div
                                                                    class="btninv"
                                                                    @click="
                                                                        NewProducts(
                                                                            item,
                                                                            index
                                                                        )
                                                                    "
                                                                >
                                                                    +
                                                                </div>
                                                            </th>
                                                            <td>
                                                                <textarea
                                                                    name=""
                                                                    id=""
                                                                    cols="30"
                                                                    rows="2"
                                                                    class="form-control"
                                                                    v-model="
                                                                        item.model
                                                                            .descripcion
                                                                    "
                                                                ></textarea>
                                                            </td>
                                                            <td
                                                                style="
                                                                    width: 70px;
                                                                    text-align: right;
                                                                "
                                                            >
                                                                <input
                                                                    type="text"
                                                                    placeholder="0.00"
                                                                    v-model="
                                                                        item.model.qty
                                                                    "
                                                                    style="
                                                                        text-align: right;
                                                                    "
                                                                    v-bind:class="[
                                                                        {
                                                                            error:
                                                                                item.model
                                                                                    .qty ==
                                                                                null,
                                                                        },
                                                                        'form-control',
                                                                    ]"
                                                                    @focus="vEntero"
                                                                    @focusout="vDecimal"
                                                                />
                                                            </td>
                                                            <td style="width: 120px">
                                                                <input
                                                                    type="text"
                                                                    class="form-control"
                                                                    v-model="
                                                                        item.model
                                                                            .unitcost
                                                                    "
                                                                    placeholder="0.00"
                                                                    style="
                                                                        text-align: right;
                                                                    "
                                                                    @focus="vEntero"
                                                                    @focusout="vDecimal"
                                                                />
                                                            </td>
                                                            <td>
                                                                <select
                                                                    name=""
                                                                    id=""
                                                                    class="form-control"
                                                                    v-model="
                                                                        item.model.taxOpc
                                                                    "
                                                                >
                                                                    <option
                                                                        v-for="(
                                                                            t, index
                                                                        ) in item.taxlistOption"
                                                                        :key="index"
                                                                        :value="t"
                                                                    >
                                                                        {{
                                                                            t.initialstate
                                                                        }}
                                                                        {{ t.name }}%
                                                                    </option>
                                                                </select>
                                                            </td>
                                                            <td
                                                                style="
                                                                    position: relative;
                                                                    text-align: right;
                                                                "
                                                            >
                                                                {{
                                                                    (Number(
                                                                        item.model.qty
                                                                    ) *
                                                                        Number(
                                                                            item.model.unitcost
                                                                        ))
                                                                        | formatMoney
                                                                }}
                                                                <span
                                                                    class="deletebtn"
                                                                    @click="
                                                                        removeItem(index)
                                                                    "
                                                                    >x</span
                                                                >
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th
                                                                scope="row"
                                                                class="text-left"
                                                            >
                                                                <button
                                                                    type="button"
                                                                    class="btn btn-success btn-sm"
                                                                    @click="addNewLine"
                                                                >
                                                                    Add new line
                                                                </button>
                                                            </th>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    </draggable>
                                                </table>
                                            </div>
                                            <div
                                                class="blockMont"
                                                style="
                                                    display: flex;
                                                    justify-content: space-between;
                                                    width: 100%;
                                                "
                                            >
                                                <div style="width: 50%">
                                                    <textarea
                                                        name=""
                                                        id=""
                                                        cols="30"
                                                        rows="4"
                                                        class="form-control"
                                                        v-model="
                                                            formInvoice.customer_message
                                                        "
                                                        placeholder="Customer message"
                                                    ></textarea>
                                                </div>
                                                <div style="width: 50%">
                                                    <table style="float: right">
                                                        <tr>
                                                            <td align="right">
                                                                <div
                                                                    style="
                                                                        padding-right: 10px;
                                                                        font-size: 15px;
                                                                    "
                                                                >
                                                                    <b> SubTotal </b>
                                                                </div>
                                                            </td>
                                                            <td align="right">
                                                                <div
                                                                    style="
                                                                        font-size: 18px;
                                                                    "
                                                                >
                                                                    {{
                                                                        subTotal
                                                                            | formatMoney
                                                                    }}
                                                                    <input
                                                                        type="hidden"
                                                                        class="form-control"
                                                                        v-model="
                                                                            formInvoice.sub_Total
                                                                        "
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr
                                                            v-for="(
                                                                iva, index
                                                            ) in taxesResumen"
                                                            :key="index"
                                                        >
                                                            <td colspan="2">
                                                                <table width="100%">
                                                                    <tr
                                                                        v-if="
                                                                            iva.total > 0
                                                                        "
                                                                    >
                                                                        <td align="left">
                                                                            <div
                                                                                style="
                                                                                    padding-right: 10px;
                                                                                "
                                                                            >
                                                                                <b>
                                                                                    <span
                                                                                        >{{
                                                                                            iva.name
                                                                                        }}</span
                                                                                    >
                                                                                    ({{
                                                                                        iva.porcentaje
                                                                                    }}%)
                                                                                </b>
                                                                            </div>
                                                                        </td>
                                                                        <td align="right">
                                                                            {{
                                                                                Number(
                                                                                    iva.total
                                                                                )
                                                                                    | formatMoney
                                                                            }}
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td align="right">
                                                                <div
                                                                    style="
                                                                        font-size: 16px;
                                                                        padding-right: 10px;
                                                                    "
                                                                >
                                                                    <b> Total</b>
                                                                </div>
                                                            </td>
                                                            <td align="right">
                                                                <div
                                                                    style="
                                                                        font-size: 18px;
                                                                    "
                                                                >
                                                                    {{
                                                                        total
                                                                            | formatMoney
                                                                    }}
                                                                    <input
                                                                        type="hidden"
                                                                        class="form-control"
                                                                        v-model="
                                                                            formInvoice.total
                                                                        "
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div
                                                style="
                                                    width: 100%;
                                                    text-align: right;
                                                    margin-top: 20px;
                                                "
                                            >
                                                <button
                                                    type="submit"
                                                    class="btn btn-success"
                                                    :disabled="isMsjDate"
                                                >
                                                    <i class="fas fa-save"></i> SAVE
                                                </button>
                                                <button
                                                    type="button"
                                                    class="btn btn-secundary"
                                                    style="
                                                        margin-right: 10px;
                                                        margin-left: 10px;
                                                    "
                                                    @click="
                                                        $router.push({
                                                            name: 'InvoicesRec',
                                                        })
                                                    "
                                                >
                                                    <i class="fas fa-times"></i> CANCEL
                                                </button>
                                                <button
                                                    type="button"
                                                    class="btn btn-danger"
                                                    @click="
                                                        MetDeleteInvoiceRec(
                                                            formInvoice.idInv
                                                        )
                                                    "
                                                    v-if="
                                                        formInvoice.actionNname ==
                                                        'update'
                                                    "
                                                >
                                                    <i class="fas fa-trash-alt"></i>
                                                    DELETE
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <!-- modal -->
        <modalLateral>
            <component v-bind:is="m.component"></component>
        </modalLateral>          
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Loader from '@/core/components/shared/Loader'
import { ModelSelect } from 'vue-search-select'
// import Taxes from '@/apps/billing/components/setting/Taxes'
import ClickOutside from 'vue-click-outside'
import draggable from 'vuedraggable'
import modalLateral from '@/core/plugins/themeModalLateral/modalLateral'
import formCustomer from '@/apps//billing/components/formCustomer'
import formProducts from '@/apps//billing/components/formProducts'
export default {
    name: "InvoiceNew",
    display: "Table",
    order: 8,
    components: {
        Loader,
        // Taxes,
        ModelSelect,
        draggable,
        modalLateral,
        formCustomer,
        formProducts
    },
    data () {
        return {
            m: {
                show: false,
                component: '',
                data: []
            },              
            list: [],
            dragging: false,
            isLoading: true,
            isLoadingstart: false,
            errors: [],
            showModal: false,
            ProdServiceLists: [],
            CustomersList: [],
            TaxesLists: [],
            SettingBox: "",
            start_invoice: false,
            formList: {
                items: [],
            },
            TermsList: [],
            ValuesCustomers: {
                text: null,
                value: null,
                billing_address: null,
                bussinesname: null,
                customer_address: null,
                customer_name: null,
                customer_phone: null,
                email: null,
            },
            formcustomer: {
                CustomerId: null,
                firstname: null,
                email: null,
                address: null,
                state: null,
                lastname: null,
                phone: null,
                city: null,
                zip: null,
                error: 0,
            },
            formInvoice: {
                idInv: null,
                numdoc: null,
                titlePage: "Add Recurring Invoice",
                btnName: "SAVE",
                actionNname: "create", // update
                customer_id: null,
                term_id: 0,
                customer_name: null,
                customer_email: null,
                customer_address: null,
                billing_address: null,
                invoicename: null,
                jobinformation: null,
                subtotal: 0,
                total: 0,
                fecdoc: "",
                fecdue: "",
                totaltax: null,
                qty: null,
                dataItems: [],
                interval: 3,
                startdate: new Date().toISOString(),
                enddate: "",
                send_email: false,
                status: 1,
            },
            HideSuggestionProd: false,
            TypeofAccount: [],
            FormAddPS: {
                vatError: false,
                vatErrorMsg: null,
                index_modal: null,
                btnName: "SAVE",
                Action: "Add",
                id: null,
                productname: null,
                account_id: 0,
                quantity: 1,
                unitcost: 0,
                description: null,
                applytax: 0,
                status: 0,
            },
            taxesResumen: [],
            startdate: new Date(
                    (new Date(Date.now() - ((new Date()).getTimezoneOffset() * 60000))).toISOString().slice(0, 10) + "T23:31:04.000Z"
                ).toISOString(),
            enddate: "",
            isMsjDate: false,
            msjDate:
                "This date is not valid because of February. Please choose a another date.",
            ProdServiceisLoading: false,
        };
    },
    created () {
        this.getCustomer();
        this.getTaxes();
        this.getAccountTypes();
        this.getTermsList();
    },
    directives: {
        ClickOutside,
    },
    methods: {
        vDecimal(e) {
            //console.log('change',e.target.value);
            let val = e.target.value;
            e.target.value = this.validDecimal(val);
        },
        vEntero(e) {
            let val = e.target.value;
            val = val.replace(",", "");
            //console.log('valor de focus',val);
            e.target.value = val;
        },
        validDecimal(num) {
            //console.log('valor de num',num);
            //let vatIdRegex =  new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/)
            let vatIdRegex = new RegExp(
                /^(-{1}?(?:([0-9]{0,10}))|([0-9]{1})?(?:([0-9]{0,9})))?(?:.([0-9]{0,3}))?$/
            );
            let value = 0.0;
            if (vatIdRegex.test(num)) {
                //console.log('si es entero ', num);
                if (num.indexOf(".") > -1) {
                    value = num;
                } else {
                    value = num + ".00";
                }
            }
            return Number(value)
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        },
        edicionDuplicadosOtros() {
            if (this.$route.params.idinv && this.$route.params.edit) {
                this.formInvoice.titlePage = "Edit Recurring Invoice";
                this.formInvoice.actionNname = "update";
                this.InvoiceById();
            }
            if (this.$route.params.idinv && this.$route.params.convert === "convert") {
                this.formInvoice.titlePage = "Convert to Recurring Invoices";
                this.formInvoice.actionNname = "create";
                this.getInvoiceById();
            }
            if (this.$route.params.idinv && this.$route.params.duplicate === "duplicate") {
                this.formInvoice.titlePage = "Duplicate Recurring Invoice";
                this.formInvoice.actionNname = "duplicate";
                this.InvoiceById();
            }
            if (this.$route.params.idc && this.$route.params.transac === "transac") {
                this.getCustomer(this.$route.params.idc);
                this.addNewLinePrimary();
            }
        },
        valueOptionstax(obj) {
            //reiniciar totales
            if (this.taxesResumen.length > 0) {
                for (let e in this.taxesResumen) {
                    this.taxesResumen[e].total = 0;
                }
            }
            for (let i in obj) {
                if (obj[i].model.taxOpc !== 0) {
                    if (this.taxesResumen.length > 0) {
                        for (let e in this.taxesResumen) {
                            if (this.taxesResumen[e].id === obj[i].model.taxOpc.id) {
                                let unitcost = obj[i].model.unitcost.replace(",", "");
                                let total =
                                    ((Number(unitcost) *
                                        Number(obj[i].model.taxOpc.name)) /
                                        100) *
                                    Number(obj[i].model.qty);
                                this.taxesResumen[e].total =
                                    Number(this.taxesResumen[e].total) + Number(total);
                            }
                        }
                    }
                }
            }
        },
        formatearFecha() {
            this.startdate = new Date(
                (new Date(this.startdate).toISOString() + "").slice(0, 10) +
                    "T23:31:04.000Z"
            ).toISOString();
            let dia_start = new Date(this.startdate);
            let d = dia_start.getDate();
            if (d === 29 || d === 30 || d === 31) {
                this.isMsjDate = true;
            } else {
                this.isMsjDate = false;
            }
            if (this.enddate !== "" && this.enddate !== null) {
                this.enddate = new Date(
                    (new Date(this.enddate).toISOString() + "").slice(0, 10) +
                        "T23:31:04.000Z"
                ).toISOString();
                let dia_end = new Date(this.enddate);
                let d = dia_end.getDate();
                if (d === 29 || d === 30 || d === 31) {
                    this.isMsjDate = true;
                } else {
                    this.isMsjDate = false;
                }
            }
        },
        ClosedModal() {
            this.SettingBox = "";
        },
        InvoiceById() {
            this.isLoading = true;
            window.billing
                .get("invoicerec/" + this.$route.params.idinv)
                .then((response) => {
                    console.log("detail invoice");
                    console.log(response.data.result);
                    this.formInvoice.idInv = response.data.result.id;
                    this.formInvoice.jobinformation = response.data.result.jobinformation;
                    this.formInvoice.invoicename = response.data.result.invoicename;
                    this.formInvoice.term_id = response.data.result.terms_id;
                    this.formInvoice.numdoc = response.data.result.numdoc;
                    //this.formInvoice.startdate = (this.formInvoice.actionNname == 'duplicate') ? new Date().toISOString().slice(0,10) : response.data.result.startdate
                    this.startdate =
                        this.formInvoice.actionNname == "duplicate"
                            ? new Date().toISOString().slice(0, 10)
                            : response.data.result.startdate;
                    //this.formInvoice.enddate = (this.formInvoice.actionNname == 'duplicate') ? new Date().toISOString().slice(0,10) : response.data.result.enddate
                    // this.enddate =
                    //     this.formInvoice.actionNname == "duplicate"
                    //         ? new Date().toISOString().slice(0, 10)
                    //         : response.data.result.enddate;
                    this.enddate = null;
                    this.formInvoice.subtotal = response.data.result.subtotal;
                    this.formInvoice.total = response.data.result.total;
                    this.formInvoice.interval = response.data.result.interval;
                    this.formInvoice.status = response.data.result.activorec;
                    this.formInvoice.send_email = response.data.result.send_email;
                    this.selectBillTo(response.data.customer.id);
                    for (let item in response.data.itemsprod) {
                        let taxfil = this.TaxesLists.filter(
                            (taxfil) => taxfil.id === response.data.itemsprod[item].tax_id
                        );
                        let newObj = taxfil.reduce((a, b) => Object.assign(a, b), {});
                        let isEmptyObj = !Object.keys(newObj).length;
                        let unitcost = this.validDecimal(
                            response.data.itemsprod[item].price + ""
                        );
                        this.formList.items.push({
                            options: this.ProdServiceLists,
                            taxlistOption: this.TaxesLists,
                            model: {
                                value: response.data.itemsprod[item].product_id,
                                unitcost: unitcost,
                                text: response.data.itemsprod[item].product_name,
                                descripcion: response.data.itemsprod[item].description,
                                qty: this.validDecimal(
                                    response.data.itemsprod[item].quantity + ""
                                ),
                                taxOpc: isEmptyObj ? 0 : newObj,
                            },
                        });
                    }
                    this.isLoading = false;
                })
                .catch((error) => console.log(error));
        },
        getInvoiceById() {
            this.isLoading = true;
            window.billing
                .get("invoicerec/convert/" + this.$route.params.idinv)
                .then((response) => {
                    console.log("detail invoice");
                    console.log(response.data.result);
                    this.formInvoice.idInv = response.data.result.id;
                    this.formInvoice.jobinformation = response.data.result.jobinformation;
                    this.formInvoice.invoicename = response.data.result.invoicename;
                    this.formInvoice.term_id = response.data.result.terms_id;
                    this.formInvoice.numdoc = response.data.result.numdoc;
                    this.formInvoice.subtotal = response.data.result.subtotal;
                    this.formInvoice.total = response.data.result.total;
                    var city = response.data.customer.city;
                    city = city !== null ? city + "," : "";
                    this.ValuesCustomers = {
                        text:
                            response.data.customer.firstname +
                            " " +
                            response.data.customer.lastname,
                        value: response.data.customer.id,
                        billing_address: response.data.customer.billingaddress,
                        bussinesname: response.data.customer.bussinesname,
                        customer_address: response.data.customer.address,
                        customer_name: response.data.customer.firstname,
                        customer_phone: response.data.customer.mobilephone,
                        email: response.data.customer.email,
                        city: city,
                        state: response.data.customer.bstate,
                        code: response.data.customer.bpostcode,
                    };
                    this.formList.items = [];
                    for (let item in response.data.itemsprod) {
                        let taxfil = this.TaxesLists.filter(
                            (taxfil) => taxfil.id === response.data.itemsprod[item].tax_id
                        );
                        let newObj = taxfil.reduce((a, b) => Object.assign(a, b), {});
                        let isEmptyObj = !Object.keys(newObj).length;
                        this.formList.items.push({
                            options: this.ProdServiceLists,
                            taxlistOption: this.TaxesLists,
                            model: {
                                value: response.data.itemsprod[item].product_id,
                                unitcost: this.validDecimal(
                                    response.data.itemsprod[item].price + ""
                                ),
                                text: response.data.itemsprod[item].product_name,
                                descripcion: response.data.itemsprod[item].description,
                                qty: this.validDecimal(
                                    response.data.itemsprod[item].quantity + ""
                                ),
                                taxOpc: isEmptyObj ? 0 : newObj,
                            },
                        });
                    }
                    this.isLoading = false;
                })
                .catch((error) => console.log(error));
        },
        getAccountTypes() {
            window.billing
                .get("chart-account-list/" + window.localuserdata)
                .then((response) => {
                    console.log("Account type form modal es");
                    console.log(response.data.result);
                    this.TypeofAccount = response.data.result;
                })
                .catch((error) => console.log(error));
        },
        getCustomer(idc = 0) {
            let self = this;
            window.billing
                .get("customers/" + window.localuserdata)
                .then((response) => {
                    console.log("customer list Invoice");
                    console.log(response.data.result);
                    const Customers = [];
                    if (response.data.start_invoice === false) {
                        this.start_invoice = true;
                    }
                    if (response.data.result) {
                        for (let item in response.data.result) {
                            var id = response.data.result[item].id;
                            var text = response.data.result[item].firstname;
                            var billing_address =
                                response.data.result[item].billingaddress;
                            var customer_address = response.data.result[item].address;
                            var bussinesname = response.data.result[item].bussinesname;
                            var email = response.data.result[item].email;
                            var lastname = response.data.result[item].lastname;
                            var customer_phone = response.data.result[item].officephone;
                            var city = response.data.result[item].bcity;
                            var state = response.data.result[item].bstate;
                            var code = response.data.result[item].bpostcode;
                            city = city !== null ? city + "," : "";
                            state = state !== null ? state : "";
                            code = code !== null ? code : "";
                            var guion = " - ";
                            if (bussinesname === null) {
                                bussinesname = "";
                                guion = " ";
                            }
                            if (lastname === null) {
                                lastname = "";
                            }
                            Customers.push({
                                value: id,
                                text: text + " " + lastname + guion + bussinesname,
                                customer_name: text,
                                customer_address: customer_address,
                                customer_phone: customer_phone,
                                customer_lastname: lastname,
                                billing_address: billing_address,
                                bussinesname: bussinesname,
                                email: email,
                                city: city,
                                state: state,
                                code: code,
                            });
                            if (idc > 0 && idc === id) {
                                this.ValuesCustomers = {
                                    text: text + " " + lastname + guion + bussinesname,
                                    value: id,
                                    billing_address: billing_address,
                                    bussinesname: bussinesname,
                                    customer_address: customer_address,
                                    customer_name: text,
                                    customer_lastname: lastname,
                                    customer_phone: customer_phone,
                                    email: email,
                                    city: city,
                                    state: state,
                                    code: code,
                                };
                            }
                        }
                    }
                    this.CustomersList = Customers;
                    if (idc > 0) {
                        this.isLoading = false;
                    }
                })
                .catch((error) => console.log(error));
        },
        selectBillTo(idc) {
            for (let item in this.CustomersList) {
                let id = this.CustomersList[item].value;
                if (idc > 0 && idc === id) {
                    this.ValuesCustomers = {
                        text: this.CustomersList[item].text,
                        value: this.CustomersList[item].value,
                        billing_address: this.CustomersList[item].billing_address,
                        bussinesname: this.CustomersList[item].bussinesname,
                        customer_address: this.CustomersList[item].customer_address,
                        customer_name: this.CustomersList[item].customer_name,
                        customer_lastname: this.CustomersList[item].customer_lastname,
                        customer_phone: this.CustomersList[item].customer_phone,
                        email: this.CustomersList[item].email,
                        city: this.CustomersList[item].city,
                        state: this.CustomersList[item].state,
                        code: this.CustomersList[item].code,
                    };
                }
            }
        },
        getTermsList() {
            window.billing
                .get("terms/" + window.localuserdata)
                .then((response) => {
                    console.log("terms");
                    console.log(response.data);
                    this.TermsList = response.data;
                })
                .catch((error) => console.log(error));
        },
        NewCustomer() {  
            let t = this;
            this.m = {
                show: true,
                component: 'formCustomer',
                data: { }
            }             
            this.$store.commit('core/setOpenModal', this.m); 
        },
        NewProducts(item, index) {
            this.FormAddPS.index_modal = index;
            let t = this;
            this.m = {
                show: true,
                component: 'formProducts',
                data: { 
                    FormAddPS:t.FormAddPS,
                    TypeofAccount:t.TypeofAccount,
                    TaxesLists:t.TaxesLists,
                    formList:t.formList              
                }
            }             
            this.$store.commit('core/setOpenModal', this.m);             
        },
        MetAddProd(ip, index) {
            console.log(index);
            this.HideSuggestionProd = false;
        },
        ItemsClick(index) {},
        saveInvoice() {
            this.isLoading = true;
            this.errors = [];
            if (this.formInvoice.enddate === "") {
                this.formInvoice.enddate = null;
            }
            if (!this.ValuesCustomers.value) {
                this.errors.push('Must select "Customer"');
                this.isLoading = false;
            }
            if (this.formInvoice.total == 0) {
                this.errors.push("Total must not be greater than zero");
                this.isLoading = false;
                return false;
            }
            // if (!this.formList.items[0].model.value) {
            //     this.errors.push("Must add at least 1 Product or service");
            //     this.isLoading = false;
            // }
            for (let i in this.formList.items) {
                if (
                    this.formList.items[i].model.qty === "" ||
                    this.formList.items[i].model.qty === null
                ) {
                    this.formList.items[i].model.qty = 0;
                    //this.errors.push('You must specify quantity for all products');
                    //this.isLoading = false
                }
            }
            //console.log(this.formInvoice);
            if (
                this.ValuesCustomers.value &&
                this.errors.length == 0 &&
                !this.isMsjDate
            ) {
                if (
                    this.formInvoice.actionNname === "create" ||
                    this.formInvoice.actionNname === "duplicate"
                ) {
                    window.billing
                        .post("invoicesrec/add", {
                            user_id: window.localuserdata,
                            customer_id: this.ValuesCustomers.value,
                            invoicename: this.formInvoice.invoicename,
                            jobinformation: this.formInvoice.jobinformation,
                            sub_total: this.formInvoice.subtotal,
                            total: this.formInvoice.total,
                            interval: this.formInvoice.interval,
                            startdate: this.formInvoice.startdate,
                            enddate: this.formInvoice.enddate,
                            send_email: this.formInvoice.send_email,
                            activorec: this.formInvoice.status,
                            term_id: this.formInvoice.term_id,
                            dataItems: this.formList.items,
                        })
                        .then((response) => {
                            this.isLoading = false;
                            console.log(response.data);
                            this.$swal.fire({
                                icon: "success",
                                title: "Created",
                                text: "Successful registration",
                                showConfirmButton: false,
                                timer: 2000,
                            });
                            this.$router.push({ name: "InvoicesRec" });
                        })
                        .catch((error) => console.log(error, (this.isLoading = false)));
                }
                if (this.formInvoice.actionNname === "update") {
                    window.billing
                        .put("invoicesrec/edit/" + this.formInvoice.idInv, {
                            user_id: window.localuserdata,
                            customer_id: this.ValuesCustomers.value,
                            invoicename: this.formInvoice.invoicename,
                            numdoc: this.formInvoice.numdoc,
                            jobinformation: this.formInvoice.jobinformation,
                            sub_total: this.formInvoice.subtotal,
                            total: this.formInvoice.total,
                            interval: this.formInvoice.interval,
                            startdate: this.formInvoice.startdate,
                            enddate: this.formInvoice.enddate,
                            send_email: this.formInvoice.send_email,
                            activorec: this.formInvoice.status,
                            term_id: this.formInvoice.term_id,
                            dataItems: this.formList.items,
                        })
                        .then((response) => {
                            this.isLoading = false;
                            console.log(response.data);
                            this.$swal.fire({
                                icon: "success",
                                title: "Updated",
                                text: "Successful Updated",
                                showConfirmButton: false,
                                timer: 2000,
                            });
                            this.$router.push({ name: "InvoicesRec" });
                        })
                        .catch((error) => console.log(error, (this.isLoading = false)));
                }
            }
        },
        getTaxes() {
            window.billing
                .get("taxes/" + window.localuserdata)
                .then((response) => {
                    console.log("taxes request all");
                    //console.log(response.data)
                    //this.TaxesLists = response.data;
                    const ObjTaxes = [{
                            id: 0,
                            name: '--None--',
                            porcentaje: '',
                            initialstate: '',
                            statename: '',
                            count: 0,
                            total: 0,
                        }];
                    if (response.data) {
                        for (let item in response.data) {
                            var id = response.data[item].id;
                            var initialstate = response.data[item].initialstate;
                            var name = response.data[item].name;
                            var state_id = response.data[item].state_id;
                            var statename = response.data[item].statename;
                            var status = response.data[item].status;
                            var user_id = response.data[item].user_id;
                            ObjTaxes.push({
                                id: id,
                                initialstate: initialstate,
                                name: name,
                                state_id: state_id,
                                statename: statename,
                                status: status,
                                user_id: user_id,
                                default: "none",
                            });

                            this.taxesResumen.push({
                                id: id,
                                name: initialstate,
                                porcentaje: name,
                                initialstate: initialstate,
                                statename: statename,
                                count: 0,
                                total: 0,
                            });
                        }
                    }
                    this.TaxesLists = ObjTaxes;
                    this.getProdservices();
                })
                .catch((error) => console.log(error));
        },
        getProdservices() {
            window.billing
                .get("product-services/" + window.localuserdata)
                .then((response) => {
                    console.log("product sevice list table");
                    console.log(response.data.result);
                    //this.ProdServiceLists = response.data.result
                    const ProdList = [{
                        value: 0,
                        text: "Uncategorized",
                        description: "",
                        unitcost: "1.00",
                        qty: "1.00",
                        taxOpc: {
                            id: 0,
                            name: '--None--',
                            porcentaje: '',
                            initialstate: '',
                            statename: '',
                            count: 0,
                            total: 0,
                        },
                        applytax: 0,
                        prices: 0
                    }];
                    if (response.data.result) {
                        for (let item in response.data.result) {
                            var id = response.data.result[item].id;
                            var prodname = response.data.result[item].productname;
                            var quantity = this.validDecimal(
                                response.data.result[item].quantity + ""
                            );
                            var unitcost = this.validDecimal(
                                response.data.result[item].unitcost + ""
                            );
                            var descripcion = response.data.result[item].description;
                            var applytax = response.data.result[item].applytax;
                            let taxOpcID = 0;
                            for (let i in this.TaxesLists) {
                                if (
                                    this.TaxesLists[i].id ===
                                    response.data.result[item].applytax
                                ) {
                                    taxOpcID = this.TaxesLists[i];
                                }
                            }
                            ProdList.push({
                                value: id,
                                text: prodname,
                                unitcost: unitcost,
                                descripcion: descripcion,
                                qty: quantity,
                                taxOpc: taxOpcID,
                                applytax: applytax,
                            });
                        }
                    }
                    this.ProdServiceLists = ProdList;
                    /// item default 1
                    console.log(
                        "params=" + this.$route.params.length,
                        this.$route.params,
                        this.$route.name
                    );
                    if (
                        this.$route.hasOwnProperty("params") &&
                        (this.$route.params.idinv ||
                            this.$route.params.duplicate ||
                            this.$route.params.convert ||
                            this.$route.params.transac)
                    ) {
                        this.edicionDuplicadosOtros();
                    } else {
                        this.addNewLinePrimary();
                        this.isLoading = false;
                    }
                })
                .catch((error) => {
                    this.isLoading = false;
                    console.log(error);
                });
        },
        addNewLinePrimary() {
            const ProdList = [];
            for (let item in this.ProdServiceLists) {
                var id = this.ProdServiceLists[item].value;
                var prodname = this.ProdServiceLists[item].text;
                var quantity = this.validDecimal(this.ProdServiceLists[item].qty + "");
                var unitcost = this.validDecimal(
                    this.ProdServiceLists[item].unitcost + ""
                );
                var descripcion = this.ProdServiceLists[item].descripcion;
                var applytax = this.ProdServiceLists[item].applytax;
                let taxOpcID = 0;
                for (let i in this.TaxesLists) {
                    if (this.TaxesLists[i].id === this.ProdServiceLists[item].applytax) {
                        taxOpcID = this.TaxesLists[i];
                    }
                }
                ProdList.push({
                    value: id,
                    text: prodname,
                    unitcost: unitcost,
                    descripcion: descripcion,
                    qty: quantity,
                    taxOpc: taxOpcID,
                });
            }
            if (this.formInvoice.actionNname === "create") {
                this.formList.items.push({
                    options: ProdList,
                    taxlistOption: this.TaxesLists,
                    model: {
                        value: 0,
                        text: "Uncategorized",
                        description: "",
                        unitcost: "1.00",
                        qty: "1.00",
                        taxOpc: {
                            id: 0,
                            name: '--None--',
                            porcentaje: '',
                            initialstate: '',
                            statename: '',
                            count: 0,
                            total: 0,
                        },
                        applytax: 0,
                    },
                });
            }
        },
        addNewLine() {
            console.log("add item");
            const ProdList = [];
            for (let item in this.ProdServiceLists) {
                var id = this.ProdServiceLists[item].value;
                var prodname = this.ProdServiceLists[item].text;
                var quantity = this.validDecimal(this.ProdServiceLists[item].qty + "");
                var unitcost = this.validDecimal(
                    this.ProdServiceLists[item].unitcost + ""
                );
                var descripcion = this.ProdServiceLists[item].descripcion;
                var applytax = this.ProdServiceLists[item].applytax;
                let taxOpcID = {
                    id: 0,
                    name: '--None--',
                    porcentaje: '',
                    initialstate: '',
                    statename: '',
                    count: 0,
                    total: 0,
                };
                for (let i in this.TaxesLists) {
                    if (this.TaxesLists[i].id === this.ProdServiceLists[item].applytax) {
                        taxOpcID = this.TaxesLists[i];
                    }
                }
                ProdList.push({
                    value: id,
                    text: prodname,
                    unitcost: unitcost,
                    descripcion: descripcion,
                    qty: quantity,
                    taxOpc: taxOpcID,
                });
            }
            this.formList.items.push({
                options: ProdList,
                taxlistOption: this.TaxesLists,
                model: {
                    value: "",
                    text: "",
                    description: "",
                    unitcost: "1.00",
                    qty: "1.00",
                    taxOpc: 0,
                    applytax: applytax,
                },
            });
        },
        prices(index){
            let qty = this.quitarComas(this.formList.items[index].model.qty)
            let unitcost = this.quitarComas(this.formList.items[index].model.unitcost)             
            let prices = parseFloat((qty * unitcost).toFixed(2))
            if(isNaN(prices)){
                this.formList.items[index].model= {
                    value: "",
                    text: "",
                    description: "",
                    unitcost: "1.00",
                    qty: "1.00",
                    taxOpc: {
                        id: 0,
                        name: '--None--',
                        porcentaje: '',
                        initialstate: '',
                        statename: '',
                        count: 0,
                        total: 0,
                    },
                    applytax: 0,
                    prices: 0
                }
            }else{
                this.formList.items[index].model.prices = prices;
            }
        },
        quitarComas(special){
            let vatIdComas = new RegExp(/,/);       
            if (vatIdComas.test(special)) {
                special = special.replace(",", "");
                special = this.quitarComas(special)
            }
            return special           
        },
        quitarNegativosX(special){
            if (special == 0) {
                special = '0.00'
            }
            let countNegativeUnitcost = (special.match(/-/g) || []).length;            
            if (countNegativeUnitcost == 2) {
                if (special.slice(-1) == '-') {
                    special = special.slice(0, -1)
                }                    
                special = special.replaceAll("-", "");
                special = '-'+special           
                special = this.quitarNegativosX(special)
                this.msjAlert("Incorrect amount, you may be adding disallowed symbology.")
            }
            return special
        },
        removeItem(index) {
            this.formList.items.splice(index, 1);
        },
        hide() {
            this.HideSuggestionProd = false;
        },
        MetDeleteInvoiceRec(idinv) {
            this.isLoading = true;
            this.$swal({
                title: "Delete this Recurring Invoice?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                if (result.value) {
                    window.billing
                        .put("invoicerec/delete/" + idinv, {
                            user_id: window.localuserdata,
                        })
                        .then((response) => {
                            console.log(response.data);
                            this.isLoading = false;
                            if (response.data.status) {
                                this.$swal.fire({
                                    icon: "success",
                                    title: "Deleted!",
                                    text: "Deleted Recurring Invoice Successful",
                                    showConfirmButton: false,
                                    timer: 2000,
                                });
                                this.$router.push({ name: "InvoicesRec" });
                            }
                        })
                        .catch((error) => console.log(error, (this.isLoading = false)));
                }
            });
            this.isLoading = false;
        },
        asignarDueDate() {
            let sumar;
            if (this.formInvoice.term_id > 0) {
                for (let prop in this.TermsList) {
                    if (this.TermsList[prop].id === this.formInvoice.term_id) {
                        let dias = this.TermsList[prop].name.split("NET ");
                        console.log("dias ", dias[1]);
                        sumar = dias[1];
                    }
                }
                let hoy = new Date(this.formInvoice.fecdoc);
                console.log(hoy); // .toISOString().slice(0,10)
                let d = hoy.getDate();
                let m = hoy.getMonth() + 1;
                let a = hoy.getFullYear();
                if (d < 10) {
                    d = "0" + d;
                }
                if (m < 10) {
                    m = "0" + m;
                }

                console.log("fecha entante = ", hoy);
                let r = this.sumaFecha(sumar, hoy);
                console.log("nueva fecha = ", r);
                let nueva = new Date(r).toISOString();
                console.log("cambiando formato ", nueva);
                this.formInvoice.fecdue = nueva;
            } else {
                this.formInvoice.fecdue = this.formInvoice.fecdoc;
            }
        },
        sumaFecha(d, fecha) {
            var a = (fecha + "").split(" "); // convertir el objeto en array
            var f = a[3] + "-" + a[1] + "-" + a[2]; // a-m-d
            var fn = new Date(f);
            fn.setDate(fn.getDate() + parseInt(d));
            var anno = fn.getFullYear();
            var mes = fn.getMonth() + 1;
            var dia = fn.getDate();

            mes = mes < 10 ? "0" + mes : mes;
            dia = dia < 10 ? "0" + dia : dia;
            var fechaFinal = anno + "-" + mes + "-" + dia + " " + a[4] + " " + a[5]; // horas y GTM
            return fechaFinal;
        },
        limpiarEndDate() {
            this.enddate = "";
        },
    },
    watch: {
        'formList.items': {
            handler: function (after, before) {
                let m = after
                //debugger;
                for (let i in m) {
                    let a
                    if(m[i].model.unitcost != undefined){
                        if(m[i].model.unitcost.length>0){
                            a = this.quitarNegativosX(m[i].model.unitcost)  
                            this.formList.items[i].model.unitcost = a
                        }
                    }
                    let b
                    if(m[i].model.qty != undefined){
                        if(m[i].model.qty.length>0){
                            b = this.quitarNegativosX(m[i].model.qty)  
                            console.log('quitarNegativosX model.qty', b)
                            this.formList.items[i].model.qty = b   
                        }
                    }

                    this.prices(i)                                      
                }                         
            },
            deep: true
        },
        'getActionRefresh': function() {
            console.log('getActionRefresh', this.getActionRefresh)
            if (Object.keys(this.getActionRefresh.data).length > 0) {
                let data = this.getActionRefresh.data; 
                let methods = this.getActionRefresh.action;
                if (methods === 'newCustomer') {
                    this.ValuesCustomers = data;
                    this.getCustomer();
                }
                if (methods === 'products') {
                    this.ProdServiceLists.push(data.model);
                    this.formList.items[
                        this.FormAddPS.index_modal
                    ].options = this.ProdServiceLists;
                    this.formList.items[
                        this.FormAddPS.index_modal
                    ].model = data.model;                        
                }                                        
                this.m = {
                    show: false,
                    component: '',
                    data: {}
                }
            }
        },        
        startdate: function () {
            this.formatearFecha();
            this.formInvoice.startdate = this.startdate;
        },
        enddate: function () {
            this.formatearFecha();
            this.formInvoice.enddate = this.enddate;
        },
        formcustomer: {
            deep: true,
            handler: (e) => {
                //debugger;
                for (var i in e) {
                    if (i !== "notes" && i !== "error") {
                        if (e[i] !== null && e[i].length > 0) {
                            if (e[i].indexOf(",") > -1) {
                                //console.log(i, e[i]);
                                e.error = parseInt(e.error) + 1;
                                e[i] = e[i].replace(",", "");
                                console.log(e);
                            }
                        }
                    }
                }
            },
        },
        "formcustomer.error": function (e) {
            if (e > 0) {
                this.$notify({
                    group: "notiCustomer",
                    title: "Error",
                    type: "error",
                    text: "The symbol coma “,” is not allowed.",
                });
                return (e = 0);
            }
        },
    },
    computed: {
        ...mapGetters("core", { getActionRefresh: "getActionRefresh" }),
        subTotal() {
            return this.formList.items.reduce((carry, item) => {
                let unitcost 
                if (item.model.unitcost == '' || item.model.unitcost == 'null' || item.model.unitcost == undefined) {
                    unitcost = '0.00'
                } else {
                    unitcost = item.model.unitcost
                    if (unitcost.indexOf(",") > -1) {
                        unitcost = this.quitarComas(unitcost)
                    }                     
                }
                let countPunto = unitcost.split('.').length - 1;           
                if (countPunto == 2) {
                    unitcost = unitcost.replace(".", "");
                }

                let qty 
                if (item.model.qty == '' || item.model.qty == 'null' || item.model.qty== undefined) {
                    qty = '0.00'
                } else {
                    qty = item.model.qty
                    if (qty.indexOf(",") > -1) {
                        qty = this.quitarComas(qty)
                    }                      
                }
                let countPuntoQty = qty.split('.').length - 1;           
                if (countPuntoQty == 2) {
                    qty = qty.replace(".", "");
                }  

                let stt = carry + Number(unitcost) * Number(qty)
                if (isNaN(stt)) {
                    stt = '0.00'
                }
                this.formInvoice.subtotal = stt
                console.log('subtotal', this.formInvoice.subtotal)
                return stt                
            }, 0);
        },
        TotalTax() {            
            let totaltaxes = 0;
            if (this.taxesResumen.length > 0) {
                for (let e in this.taxesResumen) {
                    totaltaxes+=this.taxesResumen[e].total;
                }
            }
            return totaltaxes;
        },
        total() {
            let t = this;
            t.valueOptionstax(t.formList.items);
            console.log("ment total ", t.subTotal);
            let stt= 0;
            if (Number(t.subTotal) > 0) {
                stt= Number(t.subTotal) + t.TotalTax;
            console.log("stt total ", stt);
            }
            if (isNaN(stt)) {stt = '0.00';}
            t.formInvoice.total = stt;
            return stt;
        },
        filtroTypeofAccount() {
            return this.TypeofAccount.filter(function (u) {
                return u.account_type === 3 || u.account_type === 5;
            });
        },
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
            };
        },
    },
};
</script>
<style lang="scss" scoped>
.page-breadcrumb {
    position: relative;
    z-index: 9;
}
.messageBox {
    display: none;
    background-color: rgba(255, 255, 255, 0.74);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    .box {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        .conte {
            max-width: 350px;
            text-align: center;
            i {
                font-size: 40px;
                margin-bottom: 20px;
            }
            p {
                display: block;
                margin-bottom: 10px;
                font-size: 15px;
            }
        }
    }
    &.show {
        display: block;
    }
}
.flip-list-move {
    transition: transform 0.5s;
}
.no-move {
    transition: transform 0s;
}
.ghost {
    opacity: 0.5;
    background: #a0a0a0;
}
.iva_block {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding-top: 5px;
    padding-bottom: 5px;
    .itemsb {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
}
.deletebtn {
    position: absolute;
    right: -24px;
    top: 22px;
    width: 24px;
    height: 20px;
    background-color: #f12e2e;
    cursor: pointer;
    font-size: 20px;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    padding: 7px;
    line-height: 0px;
    &:hover {
        background-color: #c12727;
    }
}
.ui.fluid.search.selection.dropdown.productos {
    width: 90%;
    float: left;
    display: inline-block;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding: 0.78571429em 2.1em 0.78571429em 1em;
}
.btninv {
    position: relative;
    left: initial;
    top: 0;
    width: 20px;
    height: 38px;
    cursor: pointer;
    background-color: #fff;
    z-index: 10;
    color: #333;
    font-size: 18px;
    text-align: center;
    vertical-align: middle;
    line-height: 34px;
    font-weight: 400;
    border: 1px solid #dedede;
    float: right;
    display: inline-block;
    margin-left: -6px;
    &:hover {
        background-color: #e0e0e0;
    }
}
.btnmove {
    position: absolute;
    left: -13px;
    top: -3px;
    width: 13px;
    height: 100%;
    cursor: pointer;
    background-color: #e1f2fb;
    z-index: 10;
    color: #afafaf;
    font-size: 12px;
    line-height: 75px;
    text-align: center;
    vertical-align: middle;
    font-weight: normal;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    &:hover {
        background-color: #e0e0e0;
        cursor: move;
    }
}
.pageSignature {
    display: block;
    margin-top: 20px;
    width: 100%;
}
.table-productos {
    width: 100%;
}
.bill_to {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 0.8rem;
    line-height: 1.5;
    color: #4f5467;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dedede;
    border-radius: 2px;
    height: 130px;
}
#job_information {
    height: 130px;
}
@media (max-width: 1100px) {
    .table-productos {
        position: relative;
        display: block;
        padding-left: 15px;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-productos table {
        width: 860px;
    }
}

@media (max-width: 800px) {
    .table-productos {
        max-width: 800px;
    }
}
.form-control {
    border: 1px solid #dedede;
}
.pdate {
    padding: 0.255rem 0.75em;
}
.select-terms {
    height: calc(2rem + 2px) !important;
    font-size: 0.8rem;
}
.controlInterval {
    position: relative;
}
.controlInterval > label {
    display: block;
    padding: 5px 10px;
    background: #5ac146;
    color: #fff;
    border-bottom: 1px solid #000;
}

.controlInterval.status > label {
    background: #307ecc;
}
.controlInterval {
    border: 1px solid #000;
    padding: 0px 0px 10px;
    margin: 0px 7px;
}
.controlInterval > div {
    padding: 4px 6px 0px 6px;
}
.controlInterval > div.sendMail {
    padding-top: 10px;
}
.deleteEndDate {
    position: absolute;
    right: 6px;
    top: 39px;
    width: 29px;
    height: 29px;
    background-color: #f12e2e;
    cursor: pointer;
    font-size: 20px;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    padding: 12px 7px 7px 7px;
    line-height: 0px;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
}
.error {
    border-color: red !important;
}
</style>
