<template>
	<div class="text-center">
		<div class="bg-login">
			<img  src="@/core/assets/img/marketea_logo_2023_blue.svg" alt="logo" class="logo-black" @click="backWebsite"/>
		</div>
		<!-- <div class="cont text-left" >
			<h2 class="titulo">Marketea; Your Marketing, Web Presence & Software Solution</h2>
		</div> -->
	</div>
</template>
<script>
	export default {
		name: 'infoApp',
		data () {
			return {
				urlPage: window.urlweb
			}
		},
		created(){

		},
		methods: {			
			backWebsite(){
				window.open(window.urlweb, "_blank");
			}
		}
	};
</script>
<style lang="css" scoped>
.bg-login{
	margin-top: 0px;
}
.logo-black{
	margin-top: 20px;
	width: 300px;
	/*filter: drop-shadow(0px 0px 3px rgb(255 255 255 / 52%));*/
}
.cont h2.titulo {
	font-size: 20px;
	line-height: 32px;
	font-weight: normal;
	max-width: 480px;
	margin-top: 20px;
	color: #000;
	display: block;
	/*text-shadow: 0px 0px 1px #000, 1px 1px 2px rgb(223 203 203 / 82%);*/
}
@media (max-width: 1190px) {
	.bg-login{
		margin-top: 100px;
	}
}
@media (max-width: 999px) {
	.bg-login{
		margin-top: 10px;
		text-align: center;
	}
	.logo-black{
	}
	.cont h2.titulo {
		text-align: center;
		margin: 10px auto;
		max-width: 400px;
		margin-top: 20px;
	}
}
@media (max-width: 767px) {
	.bg-login{
		margin-top: 10px;
		text-align: center;
	}
	.logo-black{
		width: 200px;
	}
	.cont h2.titulo {
		font-size: 20px;
		line-height: 1.5;
		text-align: center;
		max-width: 360px;
		margin: 10px auto;
	}
}
</style>
