export default {
    Active(state, val){state.access = val},    
    set_asideStorage(state, val){ state.asideStorage = val; },
    set_loadSites(state, val){ state.loadSites = val; },
    set_webs(state, val){ 
        let newList = val; 
        for (let i in newList) {
            if(newList[i]){
                if(i==0){
                    newList[i].chose=1;
                    state.webChose = newList[i];
                }
            }else{newList[i].chose=0;}
        }
        state.websList=[];
        for (let i in newList) {
            if(newList[i]){
                state.websList.push(newList[i]);
            }
        }
    },
    set_addWeb(state, val){ state.addWeb = val; },
    set_webChose(state, val){ 
        state.webChose = val; 
        let newList = [];
        for (let i in state.websList) {
            if(state.websList[i]){
                newList.push(state.websList[i]);
            }
        }
        for (let i in newList) {
            if(newList[i]){
                if(val.id==newList[i].id){
                    state.webChose = newList[i];
                    newList[i].chose=1;
                }
                else{newList[i].chose=0;}
            }
        }
        state.websList=[];
        for (let i in newList) {
            if(newList[i]){
                state.websList.push(newList[i]);
            }
        }
    },
    set_markerWeb(state, val){
        let newList = [];
        for (let i in state.websList) {
            if(state.websList[i]){
                newList.push(state.websList[i]);
            }
        }
        for (let i in newList) {
            if(newList[i]){
                if(val.id==newList[i].id){
                    newList[i].marker=1;
                    //console.log('selected marker', val.id)
                }else{
                    newList[i].marker=0;
                }
            }
        }
        state.websList=[];
        for (let i in newList) {
            if(newList[i]){
                state.websList.push(newList[i]);
            }
        }
    },
    setRefreshBoxContent(state, val){ state.refreshBoxContent+=val; },
    setBoxContentCloneNewIndex(state, val){ state.boxContentCloneNewIndex=val; },
    setCloneBoxContent(state, val){ 
        if(val==null){console.log('reinicio null de setCloneBoxContent', val);}
        else{console.log('add setRefreshBoxContent', val);}
        state.boxContentclone=val; 
    },
    setcloseBarTools(state, val){ state.closeBarTools+=val; },
    setSideBarOpc(state, val){ 
        state.sideBarOpc={
            active: val.active || false,
            component: val.component || null,
            data: val.data || []
        }; 
    },
    setUpdateFontPage(state, val){ 
        if(val.opc!='heightHeader'){
            if(state.opcFontPage[val.opc]!= undefined){
                state.opcFontPage[val.opc]= val.value; 
                if(val.opc=='boxSize'){
                    if(val.value=='col-12'){
                        state.opcFontPage.formShow=false;
                    }else{
                        state.opcFontPage.formShow=true;
                    }
                }
            }
        }else{
            state.opcFontPage.typeHeight= val.value.typeHeight; 
            state.opcFontPage.slideHeight= val.value.slideHeight; 
        }
        console.log('setUpdateFontPage', val)
    },
}
