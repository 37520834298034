<template>
	<div class="card">
		<div class="card-header">
			<h3 class="page-title">
				<i class="far fa-sticky-note"></i> Open Report
			</h3>
		</div>
		<div class="card-body">
			<form @submit.prevent="saveReport">
				<div class="form-group">
					<vue-editor v-model="f.report" :editor-toolbar="customToolbar"></vue-editor>
				</div>
				<div class="row">
					<div class="col-12 text-right">
						<button type="submit" class="btn btn-success">
							<i class="fas fa-save"></i> SAVE
						</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>
<script>
import { VueEditor } from 'vue2-editor'
export default {
	name: 'openReport',
	components: {
		VueEditor
	},
	data () {
		return {
			f: {
				user_id: window.master_client,
                org_id: window.localorgdata,
				idSale: 0,
				id: 0,
				points: 0,
				sales_type: 'retail',
				report: '',
			},
            customToolbar: [
                ["bold", "italic", "underline", "strike"], // toggled buttons
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" }
                ],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                // ["link", "image", "video"],
                ["link"],
                ["clean"] // remove formatting button
            ],
		}
	},
	created(){
		this.init();
	},
	methods:{
		init(){
			let t = this;
			let d = t.$store.getters['core/getOpenModal'].data;
			t.f.idSale= d.id;
			t.f.id= d.quality_id;
			t.f.sales_type= d.sales_type;
			t.f.points= d.quality_points;
			t.f.action= 'report';
			t.f.report='';
			t.getReport();
		},
		getReport(){
			let t = this;
			t.$store.commit("core/setLoadingModal", true);
			window.master
            .post('salesGetQualifi', t.f)
            .then((response) => {
                console.log('salesGetQualifi', response.data)
                if(response.data.status){
                	t.f.report=response.data.get.report;
                }
                t.$store.commit("core/setLoadingModal", false);
            })
            .catch((error) => {
                console.log(error);
                t.$store.commit("core/setLoadingModal", false);
            });
		},
		saveReport(){
			let t = this;
			t.$store.commit("core/setLoadingModal", true);
			window.master.post('salesSetQualifi', t.f)
            .then((response) => {
                console.log('salesSetQualifi', response.data);
                t.$notify({
                    group: "noti",
                    type: "success",
                    title: "Successfully saved",
                    text: " report registered",
                });
				t.$store.commit("core/setLoadingModal", false);
                t.$parent.close();
            })
            .catch((error) => {
            	console.log(error);
            	t.$store.commit("core/setLoadingModal", false);
            });
		}
	}
}
</script>
<style lang="css" scoped>
.ql-editor {
    max-height: 400px;
    overflow-y: auto;
}
</style>
