var jobs = "/jobs/";
export default {  
	getTags(){
		return jobs+"getUserTagsAdmin";
	},
	getUsers(){
		return jobs+"users/" + window.master_client;
	},
	addUsers(){
		return jobs+"addUsersTags";
	},   
	addJobs(){
		return jobs+"addJobsTags";
	}, 
	editJobs(){
		return jobs+"editJobsTags";
	},   
    del(id){
		return jobs+"getJobsDel/" + window.master_client + "/" + window.localorgdata + "/" + id        
	},    
};
