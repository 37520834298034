export default {
	langbilling(state){ return state.langbilling; },
    access_billing(state){ return state.billing; },
	vendorOP(state){ return state.vendorOP; },
	vendorSelect(state){ return state.vendorSelect; },
	get_setupBilling(state){ return state.setupBilling; },
    get_dashboard(state){ return state.dashboard;},
    getResponseMail(state){ return state.responseMail;},
    getSelectBank(state){ 
    	if(localStorage.getItem('selectBank')) {
    		state.selectBank = JSON.parse(localStorage.getItem('selectBank'));
    	}
    	return state.selectBank;
    },
    getFilterBank(state){ return state.filterBank;},
    getTextfilter(state){ return state.textfilter;},
    getDelItemSelected(state){ return state.delItemSelected;},
    getTemplateBanking(state){ return state.templateBanking;},
    getAssignExpense(state){ return state.assignExpense;},
    getCustomerRefresh(state){ return state.customerRefresh;},
    getCustomerSearch(state){ return state.customerSearch;},
    getCustomerSearchActive(state){ return state.customerSearchActive;},
    getCustomerID(state){ return state.customerID;},
    getFormCustomer(state){ return state.formCustomer;},
    getCustomerByID(state){ return state.customerByID;},
    getCustomerData(state){ return state.customerData;},
    getShowSetting(state){ return state.showSetting;},  
    getCustomerCountTotal(state){ return state.customerCountTotal;},  
    getAccountListBanking(state){ return state.AccountList;},  
    getShowSMS(state){ return state.showSMS;},  
    gettemplateContract(state){ return state.templateContract;},  
    getVisibleAside(state){ return state.asideMenu;},  
};
