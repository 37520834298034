let changePage = function(page) {
	this.page = page;			
	if(typeof this.page != 'number') { 
		this.page= 1; 
		console.log('no es number');
	}
	this.pagination.current_page = this.page;
	this.query = "?page=" + this.page;
	this.getList();
};
export default changePage;
