let getList = function() {
	let t = this;
	t.isLoading = true;
	window.master.post(t.apis.get(), {
		org_id: window.localorgdata,
		pag: t.paginador,
		rowNum: t.rowNum,
		search: t.searchText,
		from: '', //t.dateFrom,
		to: '', //t.dateTo,
		searchValid: t.searchValid,
	}, 
	{
		onUploadProgress: (progressEvent) => {
			t.getProgress = parseInt((progressEvent.loaded / progressEvent.total) * 100);
		}
	})
	.then((response) => {
		// console.log(response.data);
		if(response.data.list.data.length > 0) {
			t.dataT = t.fillInData(response.data.list.data);
			t.dataTOLD = t.fillInData(response.data.list.data);
			t.pagination = response.data.list;
		}
		t.isLoading = false;  
		t.numPagList();               
	})
	.catch((error) => {
		console.log(error);
		t.isLoading = false;
	});
};
export default getList;
