export default { 
	SET_ORGINFO (state, orginfo) {
		state.infoorg = orginfo;
		localStorage.setItem('nomCompany', orginfo.com_company_name);
	},
	SET_ORG_All (state, orglist){ state.orgAll = orglist; },
	SELECT_ORG_ACTIVE(state, ido) {
		let data = state.orgAll.filter(x => x.id === ido).map(x => x);
		state.infoorg = data;
	},
	set_formInfo(state, val) { state.formInfo = val;},
	reset_formInfo(state) { 
		state.formInfo = {
			user_id : window.master_client,
			id : 0,
			title : 'ADD',
			action : 'add',
			companyname : '',
			firstname : '',
			lastname : '',
			address : '',
			city : '',
			state : '',
			zipcode : '',
			telephone : '',
			email : '',
			website : '',
			timezone_id : 1
		};
	},
	set_varChangeOrg(state, val) { state.varChangeOrg = val; },
	set_varChangeOrgID(state, val) { state.varChangeOrg.org_id = val; },
	setPlanId(state, val){ state.plan_id = val; },
	setStatusBilling(state, val){ 
		//console.log('setStatusBilling', val)
		state.statusBilling = val; 
	},
}
