<template>
	<div>
		<div class="card-header text-center" style="font-size: 20px;">
            ASSIGN OR INSTALL
        </div>
		<form @submit.prevent="InstallApps" class="card-body text-center">
			<div class="media">
				<div class="media-body">
					<img class="iconapp" alt="billing" v-if="app_id==1" src="@/core/assets/img/icon_billing.svg">
					<img class="iconapp" alt="Website" v-else-if="app_id==2" src="@/core/assets/img/icon_sites.svg">
					<img class="iconapp" alt="project" v-else-if="app_id==3" src="@/core/assets/img/icon_project.svg">
					<img class="iconapp" alt="jobs" v-else-if="app_id==4" src="@/core/assets/img/icon_jobs.svg">
					<img class="iconapp" alt="calculator" v-else-if="app_id==5"  src="@/core/assets/img/icon_calculator.svg">
					<img class="iconapp" alt="slabs" v-else-if="app_id==7"  src="@/core/assets/img/icon_slabs.svg">
					<img class="iconapp" alt="marketea" v-else-if="app_id==7"  src="@/core/assets/img/icon_auxiliar.svg">
					<img class="iconapp" alt="display" v-else-if="app_id==10"  src="@/core/assets/img/icon_display.svg">
					<img class="iconapp" alt="dimenu" v-else-if="app_id==11"  src="@/core/assets/img/icon_dimenu.svg">
					<img class="iconapp" alt="Marketea" v-else src="@/core/assets/img/icon_auxiliar.svg">
					<h5 class="titulo_install" v-if="app_id==8">CRM</h5>
					<h5 class="titulo_install" v-else>{{name}}</h5>
					<p>{{description}} <a target="_blank" href="https://marketea.com/Terms-of-Use">Terms of Use.</a></p>
				</div>
			</div>
			<div class="form-row">
				<div class="col-4"></div>
				<div class="col-4">
					<label>Select an application</label>
					<select class="form-control" v-model="app_id" @click="getDataApp()">
						<option 
							v-for="(i, index) in listApps" 
							:key="index"
							:value="i.id" 
						>{{i.name}}</option>
					</select><br><br>
					<button type="submit" class="btn btn-success" style="width: 100%;" :disabled="submit">
						INSTALL APP
					</button>
				</div>
			</div>
		</form>
	</div>
</template>
<script>
export default {
	name: 'AssignAppsR',
	props: {
		dataApp: {
			type: [Object],
			default: () => {}
		}
	},
	data () {
		return {
			user_id: 0,
			org_id: 0,
			app_id: 1,
			name: '',
			description: '',
			listApps: [],
			submit: false,
		}
	},
	created() {
		let dataOrg = this.$store.getters['core/getOpenModal'].data;
		this.user_id = dataOrg.i.user_id;
		this.org_id = dataOrg.i.id;
		this.app_id = (dataOrg.app_id>0) ? dataOrg.app_id : 1;
		this.getListApps();
	},
	methods: {
		getListApps(){
			let t = this;
			t.$store.commit("core/setLoadingModal", true);
			let url = '/getAppslist/'+t.org_id;
			window.reselleR2.get(url)
            .then((response) => {
                t.listApps = response.data.r.map(function(item){
                	if(item.id==8){item.name='CRM';}
                	return item;
                });
                t.getDataApp();
                t.$store.commit("core/setLoadingModal", false);
            })
            .catch((error) => {
                console.log(error);
				t.$store.commit("core/setLoadingModal", false);
            });
		},
		getDataApp(){
			let t = this;
			t.name = null;
			t.description = null;
            t.listApps.map(function(item){
            	if(item.id == t.app_id){
					t.name = item.name;
					t.description = item.description;
            	}
        	});
		},
		InstallApps() {
			let t = this;
			t.submit=true;
			t.$store.commit("core/setLoadingModal", true);
			window.reselleR2.post('AssignAppFront/' + t.user_id, {
				user_id : t.user_id,
				org_id: t.org_id,
				app_id: t.app_id,
			}).then((response) => {
				t.submit=false;
				t.$parent.$parent.$parent.getOrg();
				t.$store.commit("core/setCloseModal", false);
				t.$store.commit("core/setLoadingModal", false);
			}).catch((error) => {
				console.log(error);
				t.submit=false;
				t.$store.commit("core/setLoadingModal", false);
			});
		},
	}
};
</script>
<style lang="css" scoped>
h5.titulo_install{
	margin-top: 10px;
	font-weight:bold;
	font-size: 24px;
}
.media-body p {
	font-size: 19px;
	padding: 10px 60px;
}
.iconapp{
	width: 150px;
	height:auto;
}
</style>
