let autoHeigth = function(alto, idxJobs, idxTask){
    let t = this;
    let item = t.listTaks[idxJobs].taks[idxTask];
    let elem = document.querySelector(".heigth" + idxTask + "-" + item.id);
    if(elem){
        elem.style.height = "auto";
        let h = elem.scrollHeight;
        if(item.name.length<=30 || h<26){
            h = 26; // solo una linea
        }
        if (h>=84){h = 84;}
        elem.setAttribute("data-heigth", h);
        elem.style.height = h + "px";
        let previewTask = elem.nextElementSibling;
        if(previewTask){
            previewTask.style.height = h + "px";
        }
        console.log('autoHeigth item', idxTask, 'taks', item.id, 'alto='+item.alto)   
    } else {
        console.log('elem no existe', idxTask, 'taks', item.id, 'alto='+item)   
    }
    t.starGrid();
};
export default autoHeigth;
