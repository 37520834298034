let editRow = function(idx, i) {
	let edit =false;
	let editIDcampaing =false;
	let t =this;
	// console.log('edit', idx, i)
	for (let j in t.dataTOLD) {
		if(t.dataTOLD[j] && t.dataTOLD[j].id == i.id) {
			// console.log('item', i.id, 'dates', t.dataTOLD[j].date + ' != ' + i.date)
			if(t.dataTOLD[j].date != i.date) { edit =true;}
			if(t.dataTOLD[j].id_marksource != i.id_marksource) { edit =true;}
			if(t.dataTOLD[j].id_ctachannel != i.id_ctachannel) { edit =true;}
			if(t.dataTOLD[j].id_campaign != i.id_campaign) { edit =true; editIDcampaing =true;}
			if(t.dataTOLD[j].id_groupname != i.id_groupname) { edit =true;}
			if(t.dataTOLD[j].leadstatus != i.leadstatus) { edit =true;}
			if(t.dataTOLD[j].leadscore != i.leadscore) { edit =true;}
			// if(edit) {
			// 	console.log('edit', idx, i.date)
			// }
		}
	}
	if(edit) {
		console.log('editRow', idx, i.date)
		// t.isLoading = true;
		window.master.post(t.apis.edit(), i, {
			onUploadProgress: (progressEvent) => {
				this.getProgress = parseInt((progressEvent.loaded / progressEvent.total) * 100);
				this.getLoading = false;
			}
		})
		.then((response) => {
			if(response.data.status) {
				t.dataTOLD[idx] = i;
			}
			// t.isLoading = false;
			if(editIDcampaing){
				t.constructorGroupName(i)
			}
		})
		.catch((error) => {
			console.log(error);
			// t.isLoading = false;
		});
	}
};
export default editRow;
