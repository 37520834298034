<template>
    <div>
        <div class="card-header">{{title}}</div>
        <div class="card-body">
            <form @submit.prevent="saveSetting">
                <div class="form-row">
                    <div class="col-12">
                        <label class="radio-inline col-2">
                            <span id="email" class="form-text"> Show</span>
                            <input
                                type="radio"
                                :value="1"
                                v-model="showMenu"
                            />&nbsp;&nbsp;
                        </label>
                        <label class="radio-inline col-2">
                            <span id="email" class="form-text"> Hide</span>
                            <input type="radio" :value="0" v-model="showMenu" />
                        </label>
                    </div>
                    <div class="col-12">
                        <br />
                        <button type="submit" class="btn btn-success">SAVE</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name: 'ShowOrHidenMenu',
    components: {
    },
    data () {
        return {
            isLoading: false,
            showMenu: 0,
            title: '',
            meta_key: '',
        };
    },
    created () {
        let t = this;
        let getModal = t.$store.getters['core/getOpenModal'];
        t.title = getModal.data.title;
        t.meta_key = getModal.data.meta_key;
        t.getStart();
    },
    methods: {
        getStart () {
            this.isLoading = true;
            this.$store.commit("core/setLoadingModal", true);
            window.billing
                .post('getKeyValSetting', {
                    user_id: window.master_client,
                    org_id: window.localorgdata,
                    meta_key: this.meta_key,
                })
                .then((response) => {
                    console.log(response.data);
                    if(response.data.status){
                        if(response.data.r.meta_value ==0){
                            this.showMenu = 0;
                        }else{this.showMenu = 1;}                        
                        if(this.meta_key == 'showSMS'){
                            this.$store.commit('ModApps/billing/setShowSMS', this.showMenu); // ocultar
                        }
                    }else{this.showMenu = 0;} 
                    this.isLoading = false;
                    this.$store.commit("core/setLoadingModal", false);
                })
                .catch((error) => {
                    console.log(error);
                    this.$store.commit("core/setLoadingModal", false);
                });
        },
        saveSetting () {
            this.$store.commit("core/setLoadingModal", true);
            window.billing
                .post('setKeyValSetting', {
                    user_id: window.master_client,
                    org_id: window.localorgdata,
                    meta_key: this.meta_key,
                    meta_value: this.showMenu,
                })
                .then((response) => {
                    this.$swal.fire({
                        icon: 'success',
                        title: 'Updated',
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    this.$store.commit("core/setLoadingModal", false);
                    this.$store.commit("core/setCloseModal", {action: 'saved'}); 
                })
                .catch((error) => {
                    this.$store.commit("core/setLoadingModal", false);
                    console.log(error, (this.isLoading = false))
                });
        },
    },
    computed: { },
};
</script>
