let dashDelImg = function() {
    this.JobsGalleryList = [];
    this.JobsGalleryList = this.accitonPusher.item.img;
    for (let i in this.listTaks) {
        if (this.listTaks[i].id_i == this.accitonPusher.item.job) {
            this.listTaks[i].galleryList = [];
            let img = this.accitonPusher.item.img;
            let count = img.length;
            if (count > 0) {
                this.listTaks[i].galleryList = this.accitonPusher.item.img;
            }
        }
    }
    this.reEscribirLista();
};
export default dashDelImg;
