<template>
    <div>
        <div class="page-breadcrumb" style="padding-top:0px;">
            <div class="row">
                <div class="col-5 align-self-center">
                    <h4 class="page-title">Setting</h4>
                </div>
                <div class="col-7 align-self-center">
                    <div class="d-flex align-items-center justify-content-end">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <div class="alert alert-info" v-if="showinfoAlert" style="padding:5px 10px; margin-bottom:5px;">
                                  Options for [ <i class="far fa-gem"></i> ] premium plan only.
                                  <button type="button" class="close" @click="showinfoAlert=!showinfoAlert" style="margin-left: 10px;">
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="row">
                            <div class="col-md-12" >
                                
                            </div>
                            <div class="col-md-3">
                                <div class="card card-body">
                                    <div class="list-group list-group-flush">
                                        <h4>GENERAL SETTING</h4>
                                        <div class="list-group-item" v-for="(i, index) in listOptGeneral" :key="index">
                                            <!--<span class="badge btn-infoPop" @click="popInfo(i)">
                                                <i class="fa fa-info"></i>
                                            </span>-->
                                            <a href="#" @click.prevent="llamarPopPremium"
                                                class=" list-group-item-action"
                                                v-if="i.premium && !PlanPremium"
                                            >
                                                {{i.label}}
                                                <span class="premium pre">
                                                    <i class="far fa-gem"></i>
                                                </span>
                                            </a> 
                                            <a href="#" @click.prevent="$router.push({ name: i.ruta })" 
                                                class=" list-group-item-action"
                                                v-else-if="i.type==1"
                                            >
                                                {{i.label}}
                                                <span class="premium pre" v-if="i.premium==true">
                                                    <i class="far fa-gem"></i>
                                                </span>
                                            </a> 
                                            <div
                                                v-else-if="i.type==2"
                                                class="list-group-item-action"
                                                style="cursor: pointer"
                                                @click="modal(i.ruta)"
                                            >
                                                {{i.label}}
                                                <span class="premium pre" v-if="i.premium==true">
                                                    <i class="far fa-gem"></i>
                                                </span>
                                            </div> 
                                            <div
                                                v-else-if="i.type==3"
                                                class="list-group-item-action"
                                                style="cursor: pointer"
                                                @click="modal2(i.ruta, i.title, i.id)"
                                            >
                                                {{i.label}}
                                                <span class="premium pre" v-if="i.premium==true">
                                                    <i class="far fa-gem"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card card-body">
                                    <div class="list-group list-group-flush">
                                        <h4>CUSTOMER SETTING</h4>
                                        <div class="list-group-item" v-for="(i, index) in listOptCustomer" :key="index">
                                            <!--<span class="badge btn-infoPop" @click="popInfo(i)">
                                                <i class="fa fa-info"></i>
                                            </span>-->
                                            <a href="#" @click.prevent="$router.push({ name: i.ruta })" 
                                                class=" list-group-item-action"
                                                v-if="i.type==1"
                                            >
                                                {{i.label}}
                                            </a> 
                                            <div
                                                v-else-if="i.type==2"
                                                class="list-group-item-action"
                                                style="cursor: pointer"
                                                @click="modal(i.ruta)"
                                            >
                                                {{i.label}}
                                            </div> 
                                            <div
                                                v-else-if="i.type==3"
                                                class="list-group-item-action"
                                                style="cursor: pointer"
                                                @click="modal2(i.ruta, i.title, i.id)"
                                            >
                                                {{i.label}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="card card-body">
                                    <div class="list-group list-group-flush">
                                        <h4>INVOICE SETTING</h4>
                                        <div @click.prevent="llamarPopPremium"
                                            class="list-group-item list-group-item-action"
                                            v-if="!PlanPremium"
                                        >
                                            Start Number
                                            <span class="premium pre">
                                                <i class="far fa-gem"></i>
                                            </span>
                                        </div> 
                                        <div
                                            v-else
                                            class="list-group-item list-group-item-action"
                                            @click="modal('StartInvoice')"
                                        >
                                            Start Number
                                            <span class="premium pre">
                                                <i class="far fa-gem"></i>
                                            </span>
                                        </div>
                                        <div class="list-group-item list-group-item-action"
                                            @click="modal('invociceOptions')"
                                        >
                                            Invoice Options
                                        </div>
                                        <router-link
                                            :to="{ name: 'EmailMessageInvoice' }"
                                            class="list-group-item list-group-item-action"
                                            >
                                            Email Messages
                                        </router-link>
                                        <router-link
                                            :to="{ name: 'EmailMessageInvVoid' }"
                                            class="list-group-item list-group-item-action"
                                            >
                                            Email Messages Void
                                        </router-link>
                                        <div
                                            class="list-group-item list-group-item-action"
                                            @click="modal('ShowPDFOptionsInvo')"
                                        >
                                            Show PDF Options
                                        </div>
                                        <div @click.prevent="llamarPopPremium"
                                            class="list-group-item list-group-item-action"
                                            v-if="!PlanPremium"
                                        >
                                            Show/Hide Recurring Inv.
                                            <span class="premium pre">
                                                <i class="far fa-gem"></i>
                                            </span>
                                        </div> 
                                        <div
                                            v-else
                                            class="list-group-item list-group-item-action"
                                            @click="modal2('ShowOrHidenMenu', 'Show/Hide - Recurring Invoices', 'menuRecInvo')"
                                        >
                                            Show/Hide Recurring Inv.
                                            <span class="premium pre">
                                                <i class="far fa-gem"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="card card-body">
                                    <div class="list-group list-group-flush">
                                        <h4>ACCEPT CREDIT CARDS</h4>
                                        <!-- <a href="#" class="list-group-item list-group-item-action">Expenses</a> -->
                                        <router-link
                                            v-if="PlanPremium"
                                            :to="{ name: 'squareAccount' }"
                                            class="list-group-item list-group-item-action"
                                        >
                                            Square Up 
                                            <span class="premium pre">
                                                <i class="far fa-gem"></i>
                                            </span> 
                                        </router-link>
                                        <div
                                        v-else
                                            class="list-group-item list-group-item-action disabled"
                                            @click="llamarPopPremium"
                                        >
                                            Square Up 
                                            <span class="premium pre">
                                                <i class="far fa-gem"></i>
                                            </span> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">                                
                                <div class="card card-body">
                                    <div class="list-group list-group-flush">
                                        <h4>ESTIMATE SETTING</h4>
                                        <div @click.prevent="llamarPopPremium"
                                            class="list-group-item list-group-item-action"
                                            v-if="!PlanPremium"
                                        >
                                            Start Number
                                            <span class="premium pre">
                                                <i class="far fa-gem"></i>
                                            </span>
                                        </div> 
                                        <div
                                            v-else
                                            class="list-group-item list-group-item-action"
                                            @click="modal('StartEstimate')"
                                        >
                                            Start Number
                                            <span class="premium pre">
                                                <i class="far fa-gem"></i>
                                            </span>
                                        </div>
                                        <router-link
                                            v-if="PlanPremium"
                                            :to="'setting/contract-templates'"
                                            class="list-group-item list-group-item-action"
                                            >
                                            Contract Templates
                                            <span class="premium pre">
                                                <i class="far fa-gem"></i>
                                            </span>
                                            </router-link
                                        >
                                        <div
                                            v-else
                                            class="list-group-item list-group-item-action disabled"
                                            @click="llamarPopPremium"
                                        >
                                        Contract Templates
                                            <span class="premium pre">
                                                <i class="far fa-gem"></i>
                                            </span>
                                        </div>
                                        <div
                                            class="list-group-item list-group-item-action"
                                            v-if="PlanPremium"
                                            @click="modal('signature')"
                                        >
                                            Digital Signature
                                            <span class="premium pre">
                                                <i class="far fa-gem"></i>
                                            </span>
                                        </div>
                                        <div
                                            v-else
                                            class="list-group-item list-group-item-action disabled"
                                            @click="llamarPopPremium"
                                        >
                                            Digital Signature
                                            <span class="premium pre">
                                                <i class="far fa-gem"></i>
                                            </span>
                                        </div>
                                        <router-link
                                            :to="{ name: 'EmailMessageEstimate' }"
                                            class="list-group-item list-group-item-action"
                                            >Email Messages
                                        </router-link>
                                        <div
                                            class="list-group-item list-group-item-action"
                                            @click="modal('ShowPDFOptionsEsti')"
                                        >
                                            Show PDF Options
                                        </div>
                                    </div>
                                </div>
                                <div class="card card-body">
                                    <div class="list-group list-group-flush">
                                        <h4>APP INTEGRATIONS</h4>
                                        <div @click.prevent="llamarPopPremium"
                                            class="list-group-item list-group-item-action"
                                            v-if="!PlanPremium"
                                        >
                                            Show/Hide Job Integration
                                            <span class="premium pre">
                                                <i class="far fa-gem"></i>
                                            </span>
                                        </div>
                                        <div
                                            v-else
                                            class="list-group-item list-group-item-action"
                                            @click="modal2('ShowOrHidenMenu', 'Show/Hide Job Integration', 'JobsInt')"
                                        >
                                            Show/Hide Job Integration
                                            <span class="premium pre">
                                                <i class="far fa-gem"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">                                
                                <div class="card card-body">
                                    <div class="list-group list-group-flush">
                                        <h4>MONEY IN</h4>
                                        <router-link
                                            :to="'setting/taxes'"
                                            class="list-group-item list-group-item-action"
                                            >
                                            Tax
                                        </router-link>
                                        <router-link
                                            :to="'setting/terms'"
                                            class="list-group-item list-group-item-action"
                                            >
                                            Terms
                                        </router-link>
                                        <router-link
                                            :to="'setting/product-services'"
                                            class="list-group-item list-group-item-action"
                                            >
                                            Product & Services
                                        </router-link>
                                        <router-link
                                            :to="'setting/chart-accounts'"
                                            class="list-group-item list-group-item-action"
                                            >
                                            Chart of Accounts
                                        </router-link>
                                        <router-link
                                            :to="{ name: 'EmailMessageStatement' }"
                                            class="list-group-item list-group-item-action"
                                            >
                                            Payment Received Message
                                        </router-link>
                                    </div>
                                </div>
                                <div class="card card-body">
                                    <div class="list-group list-group-flush">
                                        <h4>MONEY OUT</h4>   

                                        <div @click.prevent="llamarPopPremium"
                                            class="list-group-item list-group-item-action"
                                            v-if="!PlanPremium"
                                        >
                                            Show/Hide Write a Check
                                            <span class="premium pre">
                                                <i class="far fa-gem"></i>
                                            </span>
                                        </div>                                      
                                        <div
                                            v-else
                                            class="list-group-item list-group-item-action"
                                            @click="modal2('ShowOrHidenMenu', 'Show/Hide - Write a Check', 'menuWriteCheck')"
                                        >
                                            Show/Hide Write a Check
                                            <span class="premium pre">
                                                <i class="far fa-gem"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- modal -->
        <modalLateral>
            <component v-bind:is="m.component"></component>
        </modalLateral>        
    </div>
</template>
<script>
import Loader from '@/core/components/shared/Loader'
import Taxes from '@/apps/billing/components/setting/Taxes'
import Terms from '@/apps/billing/components/setting/Terms'
import signature from '@/apps/billing/components/setting/signature'
import StartInvoice from '@/apps/billing/components/setting/StartInvoice'
import StartEstimate from '@/apps/billing/components/setting/StartEstimate'
import invociceOptions from '@/apps/billing/components/setting/invociceOptions'
import ShowOrHidenMenu from '@/apps/billing/components/setting/ShowOrHidenMenu'
import mailsendOptions from '@/apps/billing/components/setting/mailsendOptions'
import ShowPDFOptionsInvo from '@/apps/billing/components/setting/ShowPDFOptionsInvo'
import ShowPDFOptionsEsti from '@/apps/billing/components/setting/ShowPDFOptionsEsti'
import customerAddress2 from '@/apps/billing/components/setting/ShowSecondAddressCustomerProfile'
import { mapGetters } from 'vuex'
import modalLateral from '@/core/plugins/themeModalLateral/modalLateral'
export default {
    name: "Setting",
    components: {
        Loader,
        Taxes,
        Terms,
        signature,
        StartInvoice,
        StartEstimate,
        invociceOptions,
        ShowOrHidenMenu,
        mailsendOptions,
        ShowPDFOptionsInvo,
        ShowPDFOptionsEsti,
        customerAddress2,
        modalLateral,
    },
    data() {
        return {
            showinfoAlert: true,
            freePlan: false,
            showModal: false,
            SettingBox: "",
            m: {
                show: false,
                component: '',
                data: []
            },
            PlanPremium : (window.OrgPlan > 1),
            listOptGeneral: [
                {
                    title: 'Organization Information',
                    desc: '',
                    label: 'Organization Information',
                    ruta: 'SettingCompanyInfo',
                    type: 1,
                    id: 'org',
                    class: '',
                    premium: false,
                },
                {
                    title: 'ePrint Setup',
                    desc: '',
                    label: 'ePrint Setup',
                    ruta: 'Settingeprint',
                    type: 1,
                    id: 'ePrint',
                    class: '',
                    premium: false,
                },
                {
                    title: 'Restore',
                    desc: '',
                    label: 'Restore',
                    ruta: 'RestoreCustomer',
                    type: '1',
                    id: 'restore',
                    class: '',
                    premium: false,
                },
                {
                    title: 'Backgrounds',
                    desc: '',
                    label: 'Backgrounds',
                    ruta: 'Backgrounds',
                    type: 1,
                    id: 'bg',
                    class: '',
                    premium: false,
                },
                {
                    title: 'Show/hide - Send Text Message',
                    desc: '',
                    label: 'Show/hide SMS Noti.',
                    ruta: 'ShowOrHidenMenu',
                    type: 3,
                    id: 'showSMS',
                    class: '',
                    premium: true,
                },
                {
                    title: 'Mailsend Option',
                    desc: '',
                    label: ' E-mail Notification Setup.',
                    ruta: 'mailsendOptions',
                    type: 3,
                    id: 'mailsend',
                    class: '',
                    premium: true,
                },
            ],
            listOptCustomer: [
                {
                    title: 'Show Second Address on Profile',
                    desc: '',
                    label: 'Show Second Address on Profile',
                    ruta: 'customerAddress2',
                    type: 2,
                    id: 'showSMS',
                    class: '',
                },
            ], 
        };
    },
    created(){
        this.PlanPremium = (this.$store.getters['core/Org/getPlanId'] > 1);
        console.log('created PlanPremium', this.PlanPremium);
        this.freePlan = (this.$store.getters['core/Org/getPlanId'] == 4);
        console.log('created freePlan', this.freePlan);
        if(this.freePlan==true){
            // for (let i in this.listOptGeneral) {
            //     if(this.listOptGeneral[i]){
            //         this.listOptGeneral[i].premium=true;
            //         if(this.listOptGeneral[i].id=='org' || this.listOptGeneral[i].id=='bg'){
            //             this.listOptGeneral[i].premium=false;
            //         }
            //     }
            // }
        }
    },
    methods: {
        llamarPopPremium() {
            this.m = {
                show: true,
                component: 'availablePremium',
                data: {
                    title: 'Orgs',
                    type: 'Orgs',
                    name: 'Orgs',
                    app_id: 0,
                    description: 'Orgs'
                }
            };
            this.$store.commit('core/session/setShowPopPremium', this.m);
        },
        getStates() {
            this.isLoadingCustomers = true;
            window.billing
                .get("states/")
                .then((response) => {
                    console.log(response.data.resultStates);
                    this.CustomersList = response.data.resultStates;
                    this.isLoadingCustomers = false;
                })
                .catch((error) => console.log(error));
        },
        modal(component) {
            this.m = {
                show: true,
                component: component,
                data: {}
            }  
            this.$store.commit('core/setOpenModal', this.m);             
        },
        modal2(component, title, meta_key) {
            this.m = {
                show: true,
                component: component,
                data: {
                    title: title,
                    meta_key: meta_key
                }
            }  
            this.$store.commit('core/setOpenModal', this.m);             
        },
        popInfo(t){
            console.log('pop', t);
        },
    },
    watch: { },
    computed: { },
};
</script>
<style lang="scss" scoped>
i.fas.fa-arrow-left {
    font-size: 13px;
}
.disabled{

}
.btn-infoPop:hover{
    cursor: pointer;
}
.btn-infoPop{
    position: absolute;
    width: 15px;
    height: 15px;
    left: 0px;
    border-radius: 50%;
    padding:3px 3px 0px 3px;
    font-size: 6px;
    background-color: #cfcfcf;
}
.list-group-item {
    position: relative;
    padding-left: 30px;
}
</style>
