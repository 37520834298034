let sendMailInvoice = function(item) {
	if (item.DataItem.send == 1) {
		item.DataItem.send = 0;
		this.$store.commit("core/setCloseModal", {
			action: 'sendMailInvoice',                     
		});
		if(item.email1eprintcheck==false && item.email2eprintcheck==false){
			this.searchFilter();
		}		
		this.$notify({
			group: "noti",
			title: "Success",
			type: "success",
			text: "Send Mail!",
		});
		this.$store.commit("ModApps/billing/SetResponseMail", null);
	}
};
export default sendMailInvoice;
