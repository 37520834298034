let getinvoice = function() {
    if (this.dataBilling.length > 0) {
        let r = [];
        for (let i in this.dataBilling) {
            if (this.dataBilling[i].id == this.customer_id) {
                r = this.dataBilling[i].invo;
            }
        }
        let f = [{ value: 0, text: "None" }];
        if (r.length > 0) {
            for (const i in r) {
                f.push({
                    value: r[i].id,
                    text: r[i].fecdoc + " - No: " + r[i].numdoc,
                    numdoc: r[i].numdoc,
                });
            }
        }
        this.invoice = f;
    }
};
export default getinvoice;
