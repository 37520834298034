let footerData = function() {
    let t = this
    t.footer.privilegies_dash = t.privilegies_dash
    t.footer.colorList = t.colorList
    t.footer.typez = t.typez
    t.footer.listTaks = t.listTaks
    t.footer.id_tags = t.id_tags
    t.footer.tagsModalId = t.tagsModalId
};
export default footerData;
