<template>
    <div>
        <div class="card-header window-header">
            <div class="col-12 d-flex justify-content-start">
                <h3>Project #{{ editItem.id }}</h3>
            </div>
        </div>
        <div class="window-body">
            <div v-show="isLoading" class="bgLoaging">
                <Loader :getProgress="apiProgess"></Loader>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-5">
                        <h2 class="card-title">
                            <div class="widget_input_text edit_text" style="padding-left: 0;padding-right: 0;max-width: 100%;">
                                <textarea v-model="project_name" style="" maxlength="50" ref="titlePro" @input="auto_height($refs.titlePro)" @change="editProject()"></textarea>
                            </div>
                        </h2>
                        <h5 class="card-title text-success">
                            Price $
                            <div class="widget_input_text edit_text" style="padding-left: 0;">
                                <input type="text" v-model="price" style="color: #5ac146 !important;" @change="editProject()">
                            </div>
                        </h5>
                        <p class="card-text">
                            <span>
                                <strong>Location:</strong>
                                <div class="widget_input_text edit_text">
                                    <input type="text" v-model="location" @change="editProject()">
                                </div>
                            </span>
                            <span>
                                <strong>Space:</strong>
                                <div class="widget_input_text edit_text ">
                                    <input type="text" v-model="space" @change="editProject()"  ref="spaceInput" @input="auto_width($refs.spaceInput)">
                                </div>
                            </span>
                            <span>
                                <strong>Bedrooms:</strong>
                                <div class="widget_input_text edit_text ">
                                    <input type="text" v-model="bedrooms" @change="editProject()" ref="bedroomsInput" @input="auto_width($refs.bedroomsInput)">
                                </div>
                            </span>
                            <span>
                                <strong>Baths:</strong>
                                <div class="widget_input_text edit_text ">
                                    <input type="text" v-model="baths" @change="editProject()" ref="bathsInput" @input="auto_width($refs.bathsInput)">
                                </div>
                            </span>
                            <span>
                                <strong>Lot Size:</strong>
                                <div class="widget_input_text edit_text ">
                                    <input type="text" v-model="lot_size" @change="editProject()" ref="lot_sizeInput" @input="auto_width($refs.lot_sizeInput)">
                                </div>
                            </span>
                            <!-- <span>
                                <VueEditor
                                    v-model="editItem.description"
                                    :editorOptions='editorOptions'
                                    :editor-toolbar="customToolbar"
                                    class="widget_text_inline small edit_text"
                                />
                            </span> -->
                        </p>
                    </div>
                    <div class="col-md-7">
                        <div class="previewImg">
                            <img class="card-img"
                            v-if="coverImage!=null && coverImage!='null' && coverImage!=''"
                                :src="pathRootUrl+coverImage+'/600/100'"
                                alt="Card image cap"
                                id="previewImg"
                            >
                            <img class="card-img" src="@/core/assets/img/no-image.png" alt="Card image cap" v-else id="previewImg">
                            <div class="btn-upload-img" @click="$refs.uploadImg.click()">
                                <i class="fas fa-cloud-upload-alt"  v-if="!loadImg"></i>
                                <loader v-if="loadImg && !isLoading"></loader>
                            </div>
                        </div>
                    </div>
                    <input type="file" ref="uploadImg" accept="image/jpeg, image/jpg, image/png, image/webp" @change="changeImage($event)" style="display: none;">
                </div>
                <div class="row">
                    <div class="col-12">
                        <VueEditor
                            v-model="features"
                            :editorOptions='editorOptions'
                            :editor-toolbar="customToolbar"
                            class="widget_text_multiline edit_text"
                            placeholder="Add a description or characteristics of the project"
                            @text-change="editFeatures()"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="edit_pop">
                            <!-- <button class="delItemPop" v-show="editVideoActive==null"><i class="fas fa-trash"></i></button> -->
                            <button class="editItemPop" v-show="editVideoActive==null" @click="editVideoActive=1">
                                <i class="fas fa-edit" v-if="urlvideo!=null"></i>
                                <i class="fas fa-plus" v-else></i>
                            </button>
                            <form @submit.prevent="saveVideo()" v-show="editVideoActive!=null" class="formEditPop">
                                <div class="input-group" style="padding:0px;">
                                    <span class="input-group-text backspace"
                                        style="cursor:pointer;"
                                        @click="urlvideo=null"
                                    >
                                    <i class="fas fa-backspace"></i>
                                    </span>
                                    <input type="text" class="form-control" v-model="urlvideo"
                                    required placeholder="https://www.youtube.com/watch?v="
                                    style="font-size: 14px;height: 35px;"/>
                                    <span class="input-group-text times"
                                        style="cursor:pointer;"
                                        @click="closeVideo()"
                                    >
                                    <i class="fas fa-times"></i>
                                    </span>
                                    <span class="input-group-text check"
                                        style="cursor:pointer;"
                                        @click="saveVideo()"
                                    >
                                    <i class="fas fa-check"></i>
                                    </span>
                                </div>
                                <loader v-if="loadVideo && !isLoading"></loader>
                            </form>
                        </div>
                        <iframe class="widget_elemt_iframe_youtube"
                            :src="'https://www.youtube.com/embed/'+previewUrlVideo+'?si=hAJI53Ootkl2qBuH'"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen
                        ></iframe>
                    </div>
                </div>
                <div class="row">
                    <draggable :list="gallery"
                        v-bind="dragGallery"
                        @start="isDraggingGallery(true)"
                        @end="isDraggingGallery(false)"
                        @change="checkMoveGallery(gallery)"
                        handle=".gallery_move"
                        tag="div"
                        group="gallery_projects"
                        class="gallery_projects"
                    >
                        <div v-for="(i, idx) in gallery" :key="idx"
                            :class="['gallery_images',
                                ((idx==0 || (idx)%4==0)) ? 'large' :
                                    (idx==1 || ((idx-1)%4==0)) ?  'prelarga' : 'normal'
                            ]"
                        >
                        <div class="gallery_overlay">
                                <button class="gallery_move"><i class="fas fa-arrows-alt"></i></button>
                                <button class="gallery_open" @click="openPopGallery(idx, gallery)"><i class="fas fa-search-plus"></i></button>
                                <button class="gallery_del" @click="deleteGallery(i.filename, idx)"><i class="fas fa-trash"></i></button>
                            </div>
                            <div class="gallery_preview" :style="'background-image: url('+i.src+')'">
                                <!-- <img :src="i" class="gallery_img"> -->
                                <loader v-if="i.isLoading && !isLoading" :getProgress="i.progress" class="gallery_load"></loader>
                                <div :class="['gallery_success', (i.progress==100) ? 'show' : '']">
                                    <i class="fas fa-check-double animate__animated animate__slideInUp"></i>
                                </div>
                            </div>
                        </div>
                        <div :class="['gallery_images', 'gallery_add']" @click="$refs.uploadGallery.click()" v-if="!filesLoading">
                            <div class="gallery_overlay add_image">
                                <i class="fas fa-plus"></i>
                            </div>
                            <div class="gallery_preview" :style="'background-image: url('+require('@/core/assets/img/no-image.png')+')'">
                            </div>
                        </div>
                        <input type="file" ref="uploadGallery" accept="image/jpeg, image/jpg, image/png, image/webp" @change="saveGallery($event)" multiple style="display: none;">
                    </draggable>
                    <popGallery
                        :items="viewGalleryPop"
                        :itemIndex="viewImgIdx"
                        :dataPop="editItem"
                        @closePop="resetPop($event)"
                    ></popGallery>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="edit_pop">
                            <!-- <button class="delItemPop" v-show="editMapActive==null"><i class="fas fa-trash"></i></button> -->
                            <button class="editItemPop" v-show="editMapActive==null" @click="editMapActive=1">
                                <i class="fas fa-edit" v-if="urlMap!=null"></i>
                                <i class="fas fa-plus" v-else></i>
                            </button>
                            <form @submit.prevent="saveMap()" v-show="editMapActive!=null" class="formEditPop">
                                <label class="col-form-label bg-light" style="padding-left: 10px;">
                                    Paste the code HTML of your map here,
                                    <a href="https://maps.google.com/" target="_blank">or get one</a>
                                </label>
                                <div class="input-group" style="padding:0px;">
                                    <span class="input-group-text backspace"
                                        style="cursor:pointer;"
                                        @click="urlMap=null"
                                    >
                                    <i class="fas fa-backspace"></i>
                                    </span>
                                    <input type="text" class="form-control" v-model="urlMap"
                                    required placeholder="https://www.google.com/maps/embed?pb="
                                    style="font-size: 14px;height: 35px;"/>
                                    <span class="input-group-text times"
                                        style="cursor:pointer;"
                                        @click="closeMap()"
                                    >
                                    <i class="fas fa-times"></i>
                                    </span>
                                    <span class="input-group-text check"
                                        style="cursor:pointer;"
                                        @click="saveMap()"
                                    >
                                    <i class="fas fa-check"></i>
                                    </span>
                                </div>
                                <loader v-if="loadMap && !isLoading"></loader>
                            </form>
                        </div>
                        <iframe class="widget_elemt_iframe_map"
                            :src="previewMap"
                            frameborder="0"
                            allowfullscreen
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader.vue";
import "./scss_details.scss";
import { VueEditor } from "vue2-editor/dist/vue2-editor.core.js";
import draggable from 'vuedraggable'
import popGallery from "./popGallery.vue";
export default {
    components: {
        Loader,
        VueEditor,
        draggable,
        popGallery,
    },
    props: {
        dataModal: {
            type: [Object, String, Number, Array],
            require: false,
            default: null,
        }
    },
    data() {
        return {
            isLoading: false,
            isExistSlug: true,
            apis: null,
            premiumProfile: false,
            planActual: 1,
            editItem: null,
            loadImg: false,
            loadVideo: false,
            loadMap: false,
            urlImg: null,
            coverImage: null,
            imgOld: null,
            fileBlob: null,
            apiProgess: 0,
            errorUrl: null,
            features: null,
            editorOptions: { theme: 'bubble' },
            customToolbar: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ["bold", "underline"], // toggled buttons
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                ],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ color: [] }],
                ["link", "clean"], // remove formatting button
            ],
            urlDefault: '',
            previewUrlVideo: null,
            urlvideo: null,
            // mapDefault: null,
            mapDefault: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1517337.2149921788!2d-73.00297119894064!3d42.03140120496237!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e3656de973bffd%3A0x45c6d03655830d1c!2sMassachusetts%2C%20EE.%20UU.!5e0!3m2!1ses-419!2sco!4v1702055332819!5m2!1sen-419!2sus",
            previewMap: null,
            urlMap: null,
            project_name: null,
            project_description: null,
            location: 'Milford, MA',
            space: '2000',
            bedrooms: '0',
            baths: '0',
            lot_size: null,
            price: '1000',
            sold: '1000',
            status: '1',
            slug_url: null,
            slug_url_old: null,
            editVideoActive: null,
            editMapActive: null,
            gallery: [],
            filesInput: [],
            filesUploading: [],
            filesLoaded: [],
            fileSelectedIdx: null,
            countUpload: 0,
            pathRootUrl: null,
            pathRootBase64: null,
            filesLoading: false,
            viewImgIdx: -1,
            viewGalleryPop: [],
        };
    },
    created() {
        let urlApis = this.$store.getters['ModApps/Marketea/urlApis'];
        this.apis = urlApis.Projects;
        // valid premium planID
        this.premiumProfile = (this.$store.getters['core/apps/getPlanIdApp']!=8);
        this.planActual = this.$store.getters['core/apps/getPlanIdApp'];
        if(this.planActual==7){
            this.premiumProfile = true;
            console.log('Plan sponsored, dando permiso de premium', this.premiumProfile);
        }
        console.log('premiumProfile', this.premiumProfile);
        if(this.dataModal!=null){
            this.editItem = this.dataModal;
            // console.log('edit item', this.editItem);
            this.pathRootUrl= window.urlmaster+'api/v2/projects/previewImgProject/'+this.editItem.user_id+'/'+ this.editItem.org_id+'/';
            this.pathRootBase64= window.urlmaster+'api/v2/projects/base64ImgProject/'+this.editItem.user_id+'/'+ this.editItem.org_id+'/';
            this.getProjectOpts();
        }
    },
    methods: {
        getProjectOpts(){
            let t = this;
            t.isLoading = true;
            let urlService = t.apis.get(3); // getBySlugUrl
            window.master.post(urlService, {
                user_id: window.master_client,
                org_id: window.localorgdata,
                slug_url: t.editItem.slug_url,
                // eslint-disable-next-line no-undef
                pusher_id: pusher.connection.socket_id,
            }, {
                onUploadProgress: (progressEvent) => {
                    // console.log('onUploadProgress', progressEvent)
                    let scanPer = Math.floor(
                        (progressEvent.loaded*100 / progressEvent.total)
                    );
                    // console.log('scanPer', scanPer);
                    if(scanPer < 99){
                        t.apiProgess = scanPer;
                    }
                }
            })
            .then((response) => {
                t.apiProgess =100;
                console.log(urlService, response.data);
                t.editItem= response.data.r;
                t.getItemProject();
                t.urlImg = response.data.urlImg;
                if(t.editItem.features){
                    t.features = t.editItem.features.meta_value;
                }
                if(t.editItem.video){
                    t.urlvideo = t.editItem.video.meta_value;
                }
                if(t.editItem.map){
                    t.urlMap = t.editItem.map.meta_value;
                }
                if(t.editItem.gallery){
                    t.getGallery();
                }
                t.validUrlVideo();
                t.validUrlMap();
                t.isLoading = false;
            }).catch((error) => {
                console.log(error);
                t.apiProgess =0;
                t.isLoading = false;
            });
        },
        editProject(){
            let t = this;
            if(!t.isExistSlug){
                // t.isLoading = true;
                let payLoad = new FormData();
                payLoad.append("user_id", window.master_client);
                payLoad.append("org_id", window.localorgdata);
                // eslint-disable-next-line no-undef
                payLoad.append("pusher_id", pusher.connection.socket_id);
                payLoad.append("id", t.editItem.id);
                payLoad.append("name", t.project_name);
                payLoad.append("description", t.project_description);
                payLoad.append('location', t.location);
                payLoad.append('space', t.space);
                payLoad.append('bedrooms', t.bedrooms);
                payLoad.append('baths', t.baths);
                payLoad.append('lot_size', t.lot_size);
                payLoad.append('price', t.price);
                payLoad.append('sold', t.sold);
                payLoad.append('status', t.status);
                payLoad.append('coverImage', t.coverImage);
                payLoad.append('slug_url', t.slug_url);
                let urlService = t.apis.set(1); //editProject
                console.log(urlService)
                window.master.post(urlService, payLoad, {
                    headers: {
                        common: {
                            Authorization: 'Bearer ' + window.localtoken_M
                        },
                        post: { 'Content-Type': 'multipart/form-data' }
                    },
                    onUploadProgress: (progressEvent) => {
                        // console.log('onUploadProgress', progressEvent)
                        let scanPer = Math.floor(
                            (progressEvent.loaded*100 / progressEvent.total)
                        );
                        // console.log('scanPer', scanPer);
                        if(scanPer < 99){
                            t.apiProgess = scanPer;
                        }
                    }
                })
                .then((response) => {
                    t.apiProgess =100;
                    console.log(urlService, response.data);
                    if(response.data.status){
                        t.$emit('actionReturn', {action: 'onlyRefreshList'});
                    }else{
                        t.$notify({
                            group: "noti",
                            type: 'error',
                            title: "Fail!",
                            text: "Could not be saved",
                        });
                    }
                    t.isLoading = false;
                }).catch((error) => {
                    console.log(error);
                    t.apiProgess =0;
                    t.isLoading = false;
                })
            }
        },
        getItemProject(){
            let t = this;
            t.project_name= this.editItem.name;
            t.project_description= this.editItem.description;
            t.location= this.editItem.location;
            t.space= this.editItem.space;
            t.bedrooms= this.editItem.bedrooms;
            t.baths= this.editItem.baths;
            t.lot_size= this.editItem.lot_size;
            t.price= this.editItem.price;
            t.status= this.editItem.status;
            t.coverImage= this.editItem.coverImage;
            t.imgOld= this.editItem.coverImage;
            this.slug_url_old = this.editItem.slug_url;
            this.auto_height(this.$refs.titlePro);
            this.auto_width(this.$refs.spaceInput);
            this.auto_width(this.$refs.bedroomsInput);
            this.auto_width(this.$refs.bathsInput);
            this.auto_width(this.$refs.lot_sizeInput);
        },
        auto_height(element) {
            element.style.height = "32px";
            element.style.height = (element.scrollHeight) + "px";
        },
        auto_width(element) {
            element.style.width = "32px";
            element.style.width = (element.scrollWidth) + "px";
            console.log('auto_width', element.style.width)
        },
        crearLink(noValid) {
            let t = this;
            t.slug_url = t.formatURL(t.project_name);
            if (noValid == undefined) {
                t.errorUrl=null;
                if(t.slug_url_old!=t.slug_url){
                    t.validateIfurlExists();
                }else{
                    t.isExistSlug = false;
                }
            }
        },
        formatURL(slug) {
            let str = slug;
            str = str.replace(/^\s+|\s+$/g, "");
            str = str.toLowerCase();
            var from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç ·/_,:;";
            var to = "aaaaaeeeeeiiiiooooouuuunc-------";
            for (var i = 0, l = from.length; i < l; i++) {
                str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
            }
            str = str
                .replace(/[^a-z0-9 -]/g, "")
                .replace(/\s+/g, "-")
                .replace(/-+/g, "-");
            return str;
        },
        validateIfurlExists() {
            let t = this;
            t.isExistSlug = true;
            let url = t.apis.get(8); // ifExistsSlug
            window.master.post(url, {
                user_id: window.master_client,
                org_id: window.localorgdata,
                slug_url: t.slug_url,
                pusher_id: pusher.connection.socket_id
            })
            .then((response) => {
                if (response.data.r > 0) {
                    t.isExistSlug = true;
                    t.errorUrl=1;
                } else {
                    t.isExistSlug = false;
                }
            }).catch((error) => {
                console.log(error);
                t.isExistSlug = false;
            });
        },
        changeImage(event){
            let t = this;
            let file = event.target.files[0];
            if (file) {
                t.loadImg=true;
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function (e) {
                    t.noImg = e.target.result;
                    t.$refs.uploadImg.value = null;
                    t.fileBlob= file;
                    t.editImg();
                };
            } else {
                                t.$refs.uploadImg.value = null;
                t.loadImg=false;
            }
        },
        editImg(){
            let t = this;
            if(t.editItem!=null){
                let payLoad = new FormData();
                payLoad.append("user_id", window.master_client);
                payLoad.append("org_id", window.localorgdata);
                payLoad.append("fileBlob", t.fileBlob);
                payLoad.append("imgOld", t.imgOld);
                payLoad.append('coverImage', t.coverImage);
                // eslint-disable-next-line no-undef
                payLoad.append("pusher_id", pusher.connection.socket_id);
                payLoad.append("id", t.editItem.id);
                let urlService = t.apis.set(2); //editCover
                window.master.post(urlService, payLoad, {
                headers: {"Content-Type": "multipart/form-data"}
                })
                .then((response) => {
                    console.log(urlService, response.data);
                    if(!response.data.status){
                        t.$notify({
                            group: "noti",
                            type: 'error',
                            title: "Fail!",
                            text: "Uploading image ",
                        });
                        t.f.coverImage = null;
                    }else{
                        t.coverImage = response.data.filename;
                        t.fileBlob=null;
                        t.$emit('actionReturn', {action: 'onlyRefreshList'});
                    }
                    t.$refs.uploadImg.value = null;
                    t.loadImg = false;
                }).catch((error) => {
                    console.log(error);
                    t.loadImg = false;
                    t.$notify({
                        group: "noti",
                        type: 'error',
                        title: "Error",
                        text: error,
                    });
                });
            }
        },
        editFeatures(){
            let t = this;
            if(t.features!=null){
                console.log("features saved")
                // t.isLoading = true;
                let payLoad = new FormData();
                payLoad.append("user_id", window.master_client);
                payLoad.append("org_id", window.localorgdata);
                // eslint-disable-next-line no-undef
                payLoad.append("pusher_id", pusher.connection.socket_id);
                payLoad.append("project_id", t.editItem.id);
                payLoad.append("meta_name", 'features');
                payLoad.append("meta_value", t.features);
                let urlService = t.apis.set(3); // setFeatures
                console.log(urlService)
                window.master.post(urlService, payLoad, {
                    headers: {
                        common: {
                            Authorization: 'Bearer ' + window.localtoken_M
                        },
                        post: { 'Content-Type': 'multipart/form-data' }
                    },
                    onUploadProgress: (progressEvent) => {
                        // console.log('onUploadProgress', progressEvent)
                        let scanPer = Math.floor(
                            (progressEvent.loaded*100 / progressEvent.total)
                        );
                        // console.log('scanPer', scanPer);
                        if(scanPer < 99){
                            t.apiProgess = scanPer;
                        }
                    }
                })
                .then((response) => {
                    t.apiProgess =100;
                    console.log(urlService, response.data);
                    if(response.data.status){
                        // t.$emit('actionReturn', {action: 'onlyRefreshList'});
                    }else{
                        t.$notify({
                            group: "noti",
                            type: 'error',
                            title: "Fail!",
                            text: "Could not be saved",
                        });
                    }
                    t.isLoading = false;
                }).catch((error) => {
                    console.log(error);
                    t.apiProgess =0;
                    t.isLoading = false;
                });
            }
        },
        validUrlVideo(){
            let url = this.urlDefault;
            if(this.urlvideo!=null && this.urlvideo!='null'){
                url = this.urlvideo;
                this.isExistSlug=false;
            }else{
                this.isExistSlug=true;
            }
            let urlIframe = url;
            if(urlIframe.indexOf('&')>-1){
                let u = urlIframe.split('&');
                if(u.length>=0){
                    let watchID = u[0].split('watch?v=');
                    this.previewUrlVideo = watchID[1];
                }
            }else{
                let watchID = urlIframe.split('watch?v=');
                this.previewUrlVideo = watchID[1];
            }
            console.log('url video', this.previewUrlVideo)
        },
        saveVideo(){
            let t = this;
            if(!t.isExistSlug){
                t.loadVideo = true;
                let payLoad = new FormData();
                payLoad.append("user_id", window.master_client);
                payLoad.append("org_id", window.localorgdata);
                // eslint-disable-next-line no-undef
                payLoad.append("pusher_id", pusher.connection.socket_id);
                payLoad.append("project_id", t.editItem.id);
                payLoad.append("meta_name", 'video');
                payLoad.append("meta_value", t.urlvideo);
                let urlService = t.apis.set(3); // setFeatures
                console.log(urlService)
                window.master.post(urlService, payLoad, {
                    headers: {
                        common: {
                            Authorization: 'Bearer ' + window.localtoken_M
                        },
                        post: { 'Content-Type': 'multipart/form-data' }
                    },
                    onUploadProgress: (progressEvent) => {
                        // console.log('onUploadProgress', progressEvent)
                        let scanPer = Math.floor(
                            (progressEvent.loaded*100 / progressEvent.total)
                        );
                        // console.log('scanPer', scanPer);
                        if(scanPer < 99){
                            t.apiProgess = scanPer;
                        }
                    }
                })
                .then((response) => {
                    t.apiProgess =100;
                    console.log(urlService, response.data);
                    if(response.data.status){
                        // t.$emit('actionReturn', {action: 'refreshList'});
                    }else{
                        t.$notify({
                            group: "noti",
                            type: 'error',
                            title: "Fail!",
                            text: "Could not be saved",
                        });
                    }
                    t.editVideoActive=null;
                    t.loadVideo = false;
                }).catch((error) => {
                    console.log(error);
                    t.apiProgess =0;
                    t.loadVideo = false;
                });
            }
        },
        closeVideo(){
            let t = this;
            t.editVideoActive=null
            if(t.editItem.video){
                t.urlvideo = t.editItem.video.meta_value;
            }
        },
        validUrlMap(){
            let t = this;
            let url = this.mapDefault;
            if(this.urlMap!=null && this.urlMap.length){
                url = this.urlMap;
                this.isExistSlug=false;
                let iframe = url.split(' ');
                for (const i in iframe) {
                    if (iframe[i].indexOf('src="')>-1){
                        this.previewMap= iframe[i].slice(5, iframe[i].length-1);
                    }
                }
            }else{
                this.isExistSlug=true;
                this.previewMap= url;
            }
            console.log('previewMap', this.previewMap)
        },
        saveMap(){
            let t = this;
            if(!t.isExistSlug){
                t.loadMap = true;
                let payLoad = new FormData();
                payLoad.append("user_id", window.master_client);
                payLoad.append("org_id", window.localorgdata);
                // eslint-disable-next-line no-undef
                payLoad.append("pusher_id", pusher.connection.socket_id);
                payLoad.append("project_id", t.editItem.id);
                payLoad.append("meta_name", 'map');
                payLoad.append("meta_value", t.urlMap);
                let urlService = t.apis.set(3); // setOpts
                console.log(urlService);
                window.master.post(urlService, payLoad, {
                    headers: {
                        common: {
                            Authorization: 'Bearer ' + window.localtoken_M
                        },
                        post: { 'Content-Type': 'multipart/form-data' }
                    },
                    onUploadProgress: (progressEvent) => {
                        // console.log('onUploadProgress', progressEvent)
                        let scanPer = Math.floor(
                            (progressEvent.loaded*100 / progressEvent.total)
                        );
                        // console.log('scanPer', scanPer);
                        if(scanPer < 99){
                            t.apiProgess = scanPer;
                        }
                    }
                })
                .then((response) => {
                    t.apiProgess =100;
                    console.log(urlService, response.data);
                    if(response.data.status){
                        // t.$emit('actionReturn', {action: 'refreshList'});
                    }else{
                        t.$notify({
                            group: "noti",
                            type: 'error',
                            title: "Fail!",
                            text: "Could not be saved",
                        });
                    }
                    t.loadMap = false;
                    t.editMapActive=null;
                }).catch((error) => {
                    console.log(error);
                    t.apiProgess =0;
                    t.loadMap = false;
                    t.editMapActive=null;
                });
            }
        },
        closeMap(){
            let t = this;
            t.editMapActive=null;
            if(t.editItem.map){
                t.urlMap = t.editItem.map.meta_value;
            }
        },
        getGallery(){
            let t = this;
            let g =(t.editItem.gallery.meta_value!=null) ? JSON.parse(t.editItem.gallery.meta_value) : [];
            t.gallery = [];
            if(g.length>0){
                g.filter(i => {
                    t.gallery.push({
                        src: t.pathRootUrl+i+'/600/100',
                        filename: i,
                        isLoading: false,
                        progress: 0,
                    });
                });
            }
            console.log('getGallery', t.gallery)
        },
        saveGallery(event) {
            let t = this;
            let files = event.target.files;
            if(files.length>0){
                t.filesInput=[];
                t.filesUploading=[];
                t.filesLoaded=[];
                files.forEach(function(e, i){
                    t.addPreview(e).then(r => {
                        console.log('addPreview', r);
                        t.addFile(files, e, r);
                    });
                });
            }else {
                t.$refs.uploadGallery.value = null;
                // t.loadImg=false;
            }
        },
        addFile(files, file_object, file_base64){
            let t = this;
            setTimeout(() => {
                console.log('addFile');
                t.gallery.push(file_base64);
                t.filesInput.push(file_object);
                console.log('gallery addItem', t.gallery[t.gallery.length-1]);
                if(files.length==t.filesInput.length){
                    console.log('send to validUpload');
                    t.countUpload=0;
                    t.validUpload();
                }
            }, 200);
        },
        addPreview(file){
            let t = this;
            return new Promise((resolve, reject) => {
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function (e) {
                    resolve({
                        src: e.target.result,
                        filename: file.name,
                        load: true,
                        progress: 0,
                        lastModified: file.lastModified
                    });
                };
            });
        },
        validUpload(){
            let t = this;
            if(t.filesInput.length>0 && t.filesInput.length > t.countUpload){
                console.log('filesInput length', t.filesInput);
                console.log('validUpload', t.filesInput[t.countUpload]);
                t.findGalleryIdx(t.filesInput[t.countUpload]);
                t.filesLoading = true;
                if(t.fileSelectedIdx!=null){
                    t.filesUploading = t.filesInput[t.countUpload];
                    let payLoad = new FormData();
                    payLoad.append("user_id", window.master_client);
                    payLoad.append("org_id", window.localorgdata);
                    // eslint-disable-next-line no-undef
                    payLoad.append("pusher_id", pusher.connection.socket_id);
                    payLoad.append("project_id", t.editItem.id);
                    payLoad.append("meta_name", 'gallery');
                    payLoad.append("countUpload", 1);
                    payLoad.append("fileBlob_1", t.filesUploading);
                    let urlService = t.apis.set(4); //setGallery
                    console.log('to replace ', t.gallery[t.fileSelectedIdx]);
                    t.gallery[t.fileSelectedIdx].isLoading=true;
                    window.master.post(urlService, payLoad, {
                        headers: {
                            common: {
                                Authorization: 'Bearer ' + window.localtoken_M
                            },
                            post: { 'Content-Type': 'multipart/form-data' }
                        },
                        onUploadProgress: (progressEvent) => {
                            let scanPer = Math.floor(
                                (progressEvent.loaded*100 / progressEvent.total)
                            );
                            if(scanPer < 99){
                                t.gallery[t.fileSelectedIdx].progress=scanPer;
                                // console.log('progress', t.gallery[t.fileSelectedIdx].progress);
                            }
                        }
                    })
                    .then((response) => {
                        console.log(urlService, response.data);
                        if(response.data.status){
                            t.gallery[t.fileSelectedIdx].progress=100;
                            console.log('progress', t.gallery[t.fileSelectedIdx].progress);
                            t.gallery[t.fileSelectedIdx].src=t.pathRootUrl+response.data.filename[0]+'/550/100';
                            t.gallery[t.fileSelectedIdx].filename=response.data.filename[0];
                            t.gallery[t.fileSelectedIdx].isLoading=false;
                        }else{
                            t.gallery[t.fileSelectedIdx].isLoading=false;
                            t.gallery.splice(t.fileSelectedIdx, 1);
                            t.$notify({
                                group: "noti",
                                type: 'error',
                                title: "Fail!",
                                text: "Uploading image [ "+t.filesUploading.name+' ]',
                            });
                        }
                        t.filesLoaded.push(t.filesInput[t.countUpload]);
                        t.countUpload++;
                        setTimeout(() => {
                            t.gallery[t.fileSelectedIdx].progress=0;
                            t.validUpload();
                        }, 1000);
                    })
                    .catch((error) => {
                        console.log(error);
                        t.$notify({
                            group: "noti",
                            type: 'error',
                            title: "Error",
                            text: error+', image [ '+t.filesUploading.name+' ]',
                        });
                        // t.filesInput.splice(0, 1);
                        t.gallery[t.fileSelectedIdx].isLoading=false;
                        t.gallery.splice(t.fileSelectedIdx, 1);
                        t.countUpload++;
                        setTimeout(() => {
                            t.gallery[t.fileSelectedIdx].progress=0;
                            t.validUpload();
                        }, 200);
                    });
                }else{
                    console.log('fileSelectedIdx no valido', t.fileSelectedIdx);
                }
            }else{
                t.filesLoading = false;
                console.log('filesInput vacio', t.filesInput);
                t.$refs.uploadGallery.value = null;
            }
        },
        findGalleryIdx(item){
            let t = this;
            t.fileSelectedIdx=null;
            for (let idx in t.gallery) {
                if (Object.hasOwnProperty.call(t.gallery, idx)) {
                    if(t.gallery[idx].lastModified==item.lastModified){
                        t.fileSelectedIdx=idx;
                    }
                }
            }
            console.log('findGalleryIdx', t.fileSelectedIdx);
        },
        isDraggingGallery(t) {
            console.log('move', t) // t = true or false
            return t;
        },
        galleryMetaValue(object){
            let t = this;
            let gallery = [];
            for (const key in object) {
                if (Object.hasOwnProperty.call(object, key)) {
                    gallery.push(object[key].filename);
                }
            }
            return JSON.stringify(gallery);
        },
        checkMoveGallery(galleryList){
            let t = this;
            console.log('checkMove', galleryList);
            let payLoad = new FormData();
            payLoad.append("user_id", window.master_client);
            payLoad.append("org_id", window.localorgdata);
            // eslint-disable-next-line no-undef
            payLoad.append("pusher_id", pusher.connection.socket_id);
            payLoad.append("project_id", t.editItem.id);
            payLoad.append("meta_name", 'gallery');
            payLoad.append("meta_value", t.galleryMetaValue(t.gallery));
            payLoad.append("move", 1);
            let urlService = t.apis.set(4); //setGallery
            window.master.post(urlService, payLoad)
            .then((response) => {
                console.log(urlService, response.data);
            })
            .catch((error) => {
                console.log(error);
                t.$notify({
                    group: "noti",
                    type: 'error',
                    title: "Error",
                    text: error,
                });
            });
        },
        deleteGallery(nomDel, idx){
            let t = this;
            let payLoad = new FormData();
            payLoad.append("user_id", window.master_client);
            payLoad.append("org_id", window.localorgdata);
            // eslint-disable-next-line no-undef
            payLoad.append("pusher_id", pusher.connection.socket_id);
            payLoad.append("project_id", t.editItem.id);
            payLoad.append("meta_name", 'gallery');
            payLoad.append("meta_value", t.galleryMetaValue(t.gallery));
            payLoad.append("del_img", nomDel);
            let urlService = t.apis.set(4); //setGallery
            console.log('deleteGallery ', nomDel);
            t.gallery[idx].isLoading=true;
            window.master.post(urlService, payLoad)
            .then((response) => {
                console.log(urlService, response.data);
                t.gallery[idx].isLoading=false;
                if(response.data.status){
                    t.gallery.splice(idx, 1);
                }else{
                    t.$notify({
                        group: "noti",
                        type: 'error',
                        title: "Fail!",
                        text: "Delete image [ "+nomDel+' ]',
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                t.$notify({
                    group: "noti",
                    type: 'error',
                    title: "Error",
                    text: error+', Delete image [ '+nomDel+' ]',
                });
                t.gallery[idx].isLoading=false;
            });
        },
        openPopGallery(idx, galleryList){
            this.viewImgIdx = idx;
            this.viewGalleryPop = galleryList;
            console.log('openPopGallery', idx, galleryList);
        },
        resetPop(val){
            console.log('resetPop', val);
            this.viewImgIdx = -1;
            this.viewGalleryPop = [];
        }
    },
    watch: {
        'project_name': function () {
            if(this.project_name!=null){
                if(this.project_name.length > 2){
                    this.crearLink();
                }else{this.isExistSlug=true;}
            }else{
                this.isExistSlug=true;
            }
        },
        "urlvideo": function(){
            this.validUrlVideo();
        },
        "urlMap": function(){
            this.validUrlMap();
        }
    },
    computed: {
        dragGallery() {
            return {
                animation: 200,
                disabled: false,
                group: "gallery_projects",
                ghostClass: "gallery_projects",
            };
        },
    }
};
</script>

<style lang="scss" scoped>
.window-header{cursor: move;padding: 2px;}
.marginTop{margin-top: 20px;}
.window-body{
    height: calc(100vh - 50px);
    // height: 600px;
    overflow: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    padding-bottom: 0em;
    width: 100%;
    .card-body{padding-top: 0px;}
    &::-webkit-scrollbar:vertical {
        width:3px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #797979;
        border-radius: 20px;
        border: 0px solid #f1f2f3;
    }
    .bgLoaging {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        margin: auto;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9;
        background-color: rgba(255, 255, 255, 0.7);
    }

    .bgLoaging>div {
        margin-left: -100px;
    }
    .widget_elemt_iframe_map{
        width: 100%;
        height: 500px;
        margin-top: 20px;
        margin-left: 0px;
        margin-right: 0px;
    }
    h2.card-title{
        font-size: 30px;
        color: #000;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #ddd;
    }
    h5.card-title{
        margin-bottom: 0px;
        color: #000;
        font-size: 20px;
    }
    p.card-text {
        margin-top: 10px;
        margin-bottom: 10px;
        color: #000;
        font-size: 16px;
        span{
            display: block;
        }
    }
    .previewImg{
        position: relative;
        width: 100%;
        max-width: 400px;
        margin: 4px auto;
        overflow: hidden;
        border:1px solid #000;
        img{
            width: 100%;
            height: auto;
            &.noImg{
                margin: auto;
                display: block;
            }
        }
        .btn-upload-img{
            position: absolute;
            z-index: 1;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-content: center;
            justify-content: center;
            align-items: center;
            text-shadow: 1px 0px 10px rgb(255 255 255 / 50%);
            color: #4c32e9;
            font-size: 50px;
            opacity: 0.5;
            &:hover{
            background-color: rgb(0 0 0 / 0.2);
            opacity: 1;
            cursor: pointer;
            }
        }
    }
    .widget_elemt_iframe_youtube{
        width: 100%;
        height: 500px;
        margin-top: 20px;
        margin-left: 0px;
        margin-right: 0px;
    }
    .widget_elemt_iframe_map{
        width: 100%;
        height: 500px;
        margin-top: 20px;
        margin-left: 0px;
        margin-right: 0px;
    }
    .edit_pop{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: transparent;
        border: 1px solid #fff;
        z-index: 1;
        .delItemPop {
            position: absolute;
            z-index: 3;
            top: 20px;
            right: 12px;
            color: #fff;
            background: red;
            padding: 5px 12px;
            border-radius: 4px;
            opacity: 0.3;
            border:1px solid red;
            box-shadow: none;
            font-size: 1.5em;
        }
        .editItemPop {
            position: absolute;
            height: 90%;
            width: 90%;
            z-index: 2;
            top: 5%;
            right: 0;
            left: 0;
            margin: auto;
            color: #fff;
            background: transparent;
            padding: 5px 8px;
            opacity: 0.3;
            border: 0px;
            box-shadow: none;
            font-size: 5em;
        }
        .formEditPop{
            position: absolute;
            height: 90%;
            width: 90%;
            z-index: 2;
            top: 5%;
            right: 0;
            left: 0;
            margin: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .input-group{
                .form-control{
                    border-radius: 0px;
                }
                .input-group-text{
                    &.backspace{
                        border-right: 0px;
                        border-top-right-radius: 0px;
                        border-bottom-right-radius: 0px;
                        color: blue;
                    }
                    &.times{
                        border-left: 0px;
                        border-radius: 0px;
                        color: red;
                    }
                    &.check{
                        border-left: 0px;
                        border-radius: 0px;
                        color: green;
                    }
                }
            }
        }
        &:hover{
            cursor: pointer;
            background: rgba(0, 0, 0, 0.26);
            border-color: #eed919;
            .delItemPop {
                opacity: 0.8;
                &:hover{
                    opacity: 1;
                    cursor: pointer;
                }
            }
            .editItemPop {
                opacity: 0.8;
                &:hover{
                    opacity: 1;
                    cursor: pointer;
                }
            }
        }
    }
    .edit_text{
        background: transparent;
        border: 1px solid #fff;
        &:hover{
            cursor: alias;
            border-color: #eed919;
        }
    }
    .gallery_projects{
        position: relative;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        .gallery_images{
            position: relative;
            margin: 1%;
            height: 250px;
            &.normal{
                flex: 0 0 48%;
                max-width: 48%;
            }
            &.large{
                flex: 0 0 58%;
                max-width: 58%;
            }
            &.prelarga{
                flex: 0 0 38%;
                max-width: 38%;
            }
            &.gallery_add{
                flex: 0 0 38%;
                max-width: 38%;
            }
            .gallery_overlay {
                position: absolute;
                z-index: 2;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background: rgba(0 0 0 /40%);
                color: #fff;
                font-size: 2em;
                opacity: 0;
                &.add_image{
                    opacity: 1;
                    background: rgba(0 0 0 /20%);
                    font-size: 4em;
                    text-shadow: 5px -5px 1px #807070;
                }
                .gallery_move{
                    box-shadow: none;
                    border: 0px;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 15%;
                    max-width: 50px;
                    margin-right: 5%;
                    color: #bdbdbd;
                    background: #dee6f5;
                    &:hover{
                        cursor: move;
                        color: #555;
                    }
                }
                .gallery_open{
                    box-shadow: none;
                    border: 0px;
                    position: absolute;
                    top: 20px;
                    margin: auto;
                    width: 200px;
                    height: 200px;
                    color: #c0bcbc;
                    background: transparent;
                    &:hover{
                        color: #fff;
                        cursor: zoom-in;
                        box-shadow: 1px 1px 20px 2px rgb(0 0 0 / 40%), inset 1px 1px 20px 2px rgba(218, 218, 218, 0.4);
                        border-radius: 50%;
                    }
                }
                .gallery_del{
                    box-shadow: none;
                    border: 0px;
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    width: 15%;
                    max-width: 50px;
                    color: #fff;
                    background: #ac1d13;
                    &:hover{
                        cursor: pointer;
                    }
                }
            }
            .gallery_preview{
                position: relative;
                z-index: 1;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-clip: border-box;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                background-color: rgb(241 241 241 / 50%);
                .gallery_load{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    margin: auto;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background-color: rgba(0 0 0 / 25%);
                }
                .gallery_success{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    margin: auto;
                    display: none;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background-color: rgba(255, 255, 255, 0.25);
                    font-size: 4em;
                    text-shadow: 5px -5px 1px #f5eeee;
                    color: #17c417;
                    overflow: hidden;
                    &.show{display: flex;}
                }
                .gallery_img{
                    width: 100%;
                    height: auto;
                }
            }
            &:hover{
                .gallery_overlay{opacity:1; }
            }
        }
    }
}
@media (max-width:767px){
    .window-body{
        height: 100%;
        max-height: 750px;
        padding-bottom: 20em;
    }
}
</style>
