let desactivateActions = function() {
	let allCheck = document.querySelectorAll(".checkAction");
	for (let i in allCheck) {
		if (allCheck.hasOwnProperty(i)) {
			allCheck[i].parentElement.parentElement.nextSibling.style.display =
			"none";
			allCheck[i].parentElement.parentElement.nextSibling.style.left =
			"0px";
			allCheck[i].checked = false;
		}
	}
};
export default desactivateActions;
