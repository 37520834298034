<template>
    <div>
        <div class="card-header">
            <h3>Redirect after finish estimate</h3>
        </div>
        <form @submit.prevent="setRedirect" id="form1" class="" >
            <div class="card-body">
                <div class="bgloading" v-show="isLoading" style="padding-top: 0;"><Loader></Loader></div>
                <div class="row">
                  <div class="form-group col-sm-2">
                      <label class="radio-inline">
                        <input type="radio" v-model="f.redirect" :value="1"> Yes
                      </label>
                  </div>
                  <div class="form-group col-sm-2">
                      <label class="radio-inline">
                        <input type="radio" v-model="f.redirect" :value="0"> no
                      </label>
                  </div>
                </div>
                <div class="row" v-if="f.redirect==1">
                  <div class="form-group col-sm-2">
                      <label class="radio-inline">
                        <input type="radio" v-model="f.opt" :value="1"> dominio.com/
                      </label>
                  </div>
                  <div class="form-group col-sm-2">
                      <label class="radio-inline">
                        <input type="radio" v-model="f.opt" :value="2"> Other Url
                      </label>
                  </div>
                  <div class="form-group col-sm-12" v-if="f.opt==2">
                      <div class="input-group ">
                        <div class="input-group-append">
                          <span class="input-group-text" id="basic-addon2">Url Site:</span>
                        </div>
                        <input type="text" class="form-control" v-model="f.uri" placeholder="https://dominio.com/page" >
                      </div>
                  </div>
                </div>
            </div>
            <div class="card-footer d-flex justify-content-end">
                <button type="submit" class="btn btn-success btn-sm">SAVE</button>
            </div>
        </form>
    </div>
</template>

<script>
import Loader from "@/core/components/shared/Loader.vue";
export default {
  name: 'RoofCalRedirect',
  components: {
    Loader,
  },
  data() {
    return {
      user_id: window.master_client,
      org_id: window.localorgdata,
      isLoading: true,
      f: { 
        user_id: window.master_client,
        org_id: window.localorgdata,
        redirect: 0,
        opt: 1,
        uri: '',
      },
      apis: {},
    };
  },
  created() {
    let urlApis = this.$store.getters['ModApps/RoofCal/urlApis'];
    this.apis = urlApis.setup;
    this.getRedirect();
  },
  methods: {
    getRedirect(){
      let t = this;
      t.isLoading = true;
      window.master.post(this.apis.getRedirect(), {
        user_id: window.master_client,
        org_id: window.localorgdata,
      })
      .then((response) => {
        if(response.data.status){
          t.f = response.data.r;
        }
        t.isLoading = false;
      })
      .catch((error) => {
        console.log(error);
        t.isLoading = false;
      });
    },
    setRedirect(){
      let t = this;
      t.isLoading = true;
      window.master.post(this.apis.setRedirect(), t.f)
      .then((response) => {
        t.getRedirect();
      })
      .catch((error) => {
        console.log(error);
        t.isLoading = false;
      });
    },
  }

};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
