<template>
	<div class="card">
		<div class="card-header">{{formInfo.title}} ORGANIZATION</div>
		<div class="card-body" style="padding-top:0px;">
			<form @submit.prevent="saveOrg"  enctype="multipart/form-data" id="form1">
				<div class="row">
					<div class="col-md-12 ">
						<label class="col-form-label ">Name Organization</label>
						<input type="text" class="form-control" id=""  v-model="formInfo.companyname" required>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6 marginTop">
						<label class="col-form-label">Name</label>
						<input type="text" class="form-control" v-model="formInfo.firstname">
					</div>
					<div class="col-md-6 marginTop">
						<label class="col-form-label">Last Name</label>
						<input type="text" class="form-control" v-model="formInfo.lastname">
					</div>
					<div class="col-md-12 marginTop">
						<label class="col-form-label">Address</label>
						<input type="text" class="form-control" v-model="formInfo.address">
					</div>
					<div class="col-md-4 marginTop">
						<label class="col-form-label">City</label>
						<input type="text" class="form-control" v-model="formInfo.city">
					</div>
					<div class="col-md-4 marginTop">
						<label class="col-form-label">State</label>
						<input type="text" class="form-control" v-model="formInfo.state">
					</div>
					<div class="col-md-4 marginTop">
						<label class="col-form-label">Zip Code</label>
						<input type="text" class="form-control" v-model="formInfo.zipcode">
					</div>
					<div class="col-md-4 marginTop">
						<label class="col-form-label">Telephone</label>
						<input type="text" class="form-control" v-model="formInfo.telephone">
					</div>
					<div class="col-md-4 marginTop">
						<label class="col-form-label">E-mail</label>
						<input type="text" class="form-control" v-model="formInfo.email">
						<span v-if="emailInvalid" class="alert alert-danger"  style="padding: 5px">
							Please enter a valid email format
						</span>
					</div>
					<div class="col-md-4 marginTop">
						<label class="col-form-label">Time zone</label>
						<select class="form-control" v-model="formInfo.timezone_id"  :disabled="editZone">
							<option :value="i.id" v-for="(i,index) in timezonesList" v-bind:key="index">{{i.name}}</option>
						</select>
					</div>
					<div class="col-md-12 marginTop">
						<label class="col-form-label">Website</label>
						<input type="text" class="form-control" v-model="formInfo.website">
					</div>
					<div class="col-md-12">
						<div style="margin-top:15px;display: flex;justify-content: flex-end;">
							<button type="submit" class="btn btn-success " style="margin-right:10px;">SAVE</button>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'addEditOrg',
		data () {
			return {
				formInfo : {
					user_id : window.master_client,
					id : 0,
					title : 'ADD',
					action : 'add',
					companyname : '',
					firstname : '',
					lastname : '',
					address : '',
					city : '',
					state : '',
					zipcode : '',
					telephone : '',
					email : '',
					website : '',
					timezone_id : 1,
				},
				emailInvalid: false,
				editZone : false,
				timezonesList: []
			}
		},
		created() {
			this.timezones();
			let getFormInfo = this.$store.getters['core/Org/getFormInfo'];
			if(getFormInfo != null && getFormInfo.action=="update"){
				this.formInfo = getFormInfo
			}
			console.log('formInfo', this.formInfo);
		},
		methods: {
			timezones(){
				window.master.get('timezones')
				.then((response) => {
					this.timezonesList = response.data.result;
				}).catch((error) => console.log(error));
			},
			saveOrg(){
				let t = this;
				let re = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
				if (!re.test(t.formInfo.email)) {
					t.emailInvalid=true;
					console.log('formato de email invalid');
				}
				else
				{
					t.emailInvalid=false;
					t.$store.commit('core/setLoadingModal', true);
					let url = 'organization/' + window.master_client;
					if(t.formInfo.action === 'add') {
						url = 'organization/' + window.master_client;
					}else if(t.formInfo.action === 'update') {
						url = 'updateOrganization';
					}
					console.log('url', url);
					window.master.post(url, t.formInfo)
					.then((response) => {
						t.$store.commit('core/Org/reset_formInfo');
						t.$store.commit('core/setLoadingModal', false);
						t.$store.commit("core/setCloseModal", false);
						if(response.data.status) {
							if(localStorage.getItem("mark_client_data") == t.formInfo.id){
								localStorage.setItem("nomCompany", t.formInfo.companyname)
							}
							t.$store.dispatch('core/Org/OrgAccountAll');
							if(t.$router.currentRoute.name !== 'Organizations'){
								t.$router.push({name:'Organizations'});
							}
						}
					}).catch((error) => {
						console.log(error);
						t.$store.commit('core/setLoadingModal', false);
					});
				}
			}
		}
	};
</script>
<style lang="css" scoped>
	.form-control {
		border: 1px solid #aaa;
	}
</style>
