<template>
	<div class="navTop">
    <nav class="navbar navbar-dark bg-dark">
      <div class="container-fluid" style="padding-left: 0px;">
        <div class="d-flex">
          <ul class="navbar-nav ">
            <li class="nav-item">
              <router-link v-bind:class="['nav-link', 'iconAppSites']" :to="{name:'Sites'}" >
                <img src="@/apps/Sites/assets/icon_sites.png" class="img-icon" />
                Sites
              </router-link>
            </li>
            <li class="nav-item">
              <router-link v-bind:class="['nav-link home', 'mprincipal', !navEditor ? 'active' : '']" :to="{name:'Sites'}" >Home</router-link>
            </li>
            <li class="nav-item">
              <router-link v-bind:class="['nav-link', 'mprincipal', navEditor ? 'active' : '']" :to="{name:'biulder'}" >Builder</router-link>
            </li>
          </ul>          
        </div>
        <div class="d-flex" v-if="website>0">
          <ul class="navbar-nav ">
            <li class="nav-item">
              <a class="nav-link add" href="#" @click.prevent="addWeb">Add</a>
            </li>
            <li class="nav-item">
              <span class="selectDomain">Select Website:</span>
              <dropdown-menu
                v-model="show"
                :right="right"
                :hover="hover"
                class="selectWebsites"
              >
                <button class="btn dropdown-toggle btn-selected" type="button">
                  {{webSelect.name}}
                </button>
                <div slot="dropdown">
                  <div  v-for="(i, index) in listWebs" :key="index">
                    <a href="#" 
                      v-bind:class="['dropdown-item', (webSelect.id==i.id) ? 'active' : '']" 
                      @click.prevent="changeWeb(i)"
                    >{{i.name}}</a>
                  </div>
                </div>
              </dropdown-menu>
              <!-- <div class="dropdown" >
                <button class="btn dropdown-toggle btn-selected" type="button" @click="show=!show" >
                  {{webSelect.name}}
                </button>
                <ul v-bind:class="['dropdown-menu', show ? 'show' : '']" >
                  <li  v-for="(i, index) in listWebs" :key="index">
                    <a href="#" 
                      v-bind:class="['dropdown-item', (webSelect.id==i.id) ? 'active' : '']" 
                      @click.prevent="changeWeb(i)"
                    >{{i.name}}</a>
                  </li>
                </ul>
              </div> -->
            </li>
          </ul>  
        </div>
        <!-- <div class="d-flex" v-else>
          <ul class="navbar-nav ">
            <li class="nav-item">
              <a class="nav-link add" href="#" @click.prevent="count+1">Add WebSite</a>
            </li>
          </ul>  
        </div> -->
        <div class="d-flex" v-if="website>0">
          <ul class="navbar-nav ">
            <li class="nav-item">
              <a class="nav-link" href="#" @click.prevent="count+1"><i class="fas fa-mobile-alt"></i></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" @click.prevent="count+1"><i class="fas fa-tablet-alt"></i></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" @click.prevent="count+1"><i class="fas fa-desktop"></i></a>
            </li>
          </ul>  
        </div>
        <div class="d-flex" v-if="website>0">
          <ul class="navbar-nav ">
            <li class="nav-item">
              <a class="nav-link visit" href="#" @click.prevent="count+1">Visit</a>
            </li>
          </ul>  
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { ClickOutside } from 'vue-click-outside'
import DropdownMenu from "@innologica/vue-dropdown-menu";
export default {
  name: 'navTop',
  props: {
    msg: String
  },
  components: {
    DropdownMenu,
  },
  data() {
    return {
      navEditor: false,
      show: false,
      right: true,
      hover: false,
      website:0,
      listWebs: [],
      webSelect: '', 
    }
  },
  directives: {
    ClickOutside,
  },
  created(){
    this.navEditor = this.$parent.navEditor;
    this.listWebs = this.$store.getters['ModApps/Sites/get_webs'];
    this.website=this.listWebs.length;
    this.webSelect = this.listWebs[0];
  },
  methods:{
    addWeb(){
      let t = this;
      t.$store.commit('ModApps/Sites/set_addWeb', true);
    },
    changeWeb(i){
      let t = this;
      console.log('changeWeb', i)
      t.$store.commit('ModApps/Sites/set_webChose', i);
      t.show=false;
    },
  },
  watch: {
    "$parent.navEditor": function () {
      this.navEditor = this.$parent.navEditor;
    },
    "get_webs": function(){
      if(this.get_webs.length>0){
        this.listWebs = this.get_webs;
        this.website=this.listWebs.length;
      }
    },
    "webChose": function(){
      this.webSelect = this.webChose;
    },
  },
  computed: {
    ...mapGetters("ModApps/Sites", { 
      get_webs: "get_webs", 
      webChose: "get_webChose",
    }),
  },
};
</script>
<style scoped lang="scss">
.navTop{
  margin-top: 10px;
}
.navbar-nav{
  flex-direction: row;
}
nav.navbar.navbar-dark.bg-dark {
    padding: 0rem 0rem;
    height: 40px;
}
li.nav-item a{
  padding-left: 10px;
  padding-right: 10px;
  &:hover{
    cursor: pointer;
    background-color: green;
  }
}
li.nav-item a.mprincipal{
  padding-left: 30px;
  padding-right: 30px;
  margin-right: 1px;
  border-left: 1px solid #484545;
  border-right: 1px solid #484545;
  &.active{
    border-left: 0px solid;
    border-right: 0px solid;
  }
}
span.selectDomain{
  display: inline-flex;
  height: 40px;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
}
a.dropdown-item{
  background: none !important;
  &:hover{
    color: #212529 !important; 
    background: #efefef !important; 
  }
  &.active{
    color: #212529 !important; 
    background: #ccc !important; 
  }
}
.dropdown-menu.show {
    display: block;
    position: absolute;
}
button.btn.dropdown-toggle.btn-selected {
    background: none;
    color: #fff;
    padding-top: 7px;
}
a.nav-link.add{
  color: #24c946;
  text-decoration: underline;
  &:hover{
    color: #fff;
    text-decoration: none;
  }
}
a.nav-link.visit{
  color: #24c946;
  text-decoration: underline;
  &:hover{
    text-decoration: none;
  }
}
img.img-icon {
  width: 20px;
  margin-top: -4px;
}
a.nav-link.iconAppSites {
  font-size: 16px;
  padding: 0.3rem 10px 0.1rem 10px;
  font-weight: 500;
  margin-right: 20px;
  &:hover{
    background: transparent !important; 
  }
}

</style>
