let formToDo = function(id) {
    this.$store.commit("core/setLoadingModal", true);
    this.btnToDoList = true;
    let d = this.get_todolist[0].data;
    // console.log(d);
    this.addTaks.id = id;
    this.addTaks.id_jobs = id
    this.addTaks.org_id = window.localorgdata;
    this.addTaks.user_id = window.master_client;
    this.addTaks.taks = this.f.taks;
    let url = this.apis.formToDo()
    window.master
        .post(url, this.addTaks)
        .then((response) => {
            this.f.taks = [];
            let r = this.listTaks;
            let id = response.data.rs[1];
            let taks = response.data.rs[0];
            let b = [];
            for (let i in taks) {
                b.push({
                    created_at: taks[i].created_at,
                    id: taks[i].id,
                    id_jobs: taks[i].id_jobs,
                    id_status: taks[i].id_status,
                    isLoading: false,
                    name: taks[i].name,
                    order: taks[i].order,
                    org_id: taks[i].org_id,
                    updated_at: taks[i].updated_at,
                    user_id: taks[i].user_id,
                });
            }
            for (let i in r) {
                if (r[i].id == id) {
                    this.listTaks[i].taks = [];
                    this.listTaks[i].taks = b;
                }
            }
            // console.log(this.listTaks);
            this.btnToDoList = false;
            this.$store.commit("core/setLoadingModal", false);
            this.$store.commit("core/setCloseModal", {});  
            this.$store.commit("ModApps/jobs/set_refresh_grid", true);   
        })
        .catch((error) => {
            console.log(error);
            this.btnToDoList = false;
            this.$store.commit("core/setLoadingModal", false);
            this.$store.commit("core/setCloseModal", {});     
        });
};
export default formToDo;
