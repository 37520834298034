<template>
    <div>
        <div class="card-header">Edit Email/Phone</div>
        <div class="card-body">
            <div class="card">
                <div class="card-body">
                    <form @submit.prevent="form">
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label for="">Email</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="p.email"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label for="">Phone</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="p.phone"
                                />
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary float-right">
                            Update
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'editEmailPhone',
    components: { },
    data () {
        return {
            p: {
                user_id: window.localAccountID,
                email: '',
                phone: '',
                id:0,
            },
            user_id: 0,
        };
    },
    created () {
        let data = this.$store.getters['core/getOpenModal'].data;
        console.log('data modal', data);
        this.getEdit(data);
    },
    methods: {
        getEdit(d) {
            this.isLoading = true;
            this.user_id = d.userID;
            this.p.id = d.userID;
            this.p.phone = d.phoneLogin;
            this.p.email = d.email;
            this.isLoading = false;
        },
        msj(type, title, text) {
            this.$store.commit("core/setNotiPush", {
                group: "notiPush",
                type: type, // 1=success|2=info|3=error|4=loading|5=warning
                title: title,
                text: text,
                show: true,
                duration: 5000
            });            
        },
        form() {
            this.$store.commit("core/setLoadingModal", true);
            let type = '3' // 1=success|2=info|3=error|4=loading|5=warning
            let title = "Error"            
            if (this.p.phone.length == 0) {
                let text = "the phone number cannot be empty." 
                this.msj(type, title, text)
                this.$store.commit("core/setLoadingModal", false);
                return false
            }
            if (this.p.email.length == 0) {
                let text = "the email cannot be empty." 
                this.msj(type, title, text) 
                this.$store.commit("core/setLoadingModal", false);               
                return false
            }            
            
            let url = '/editEmailPhone';
            window.reselleR2
                .post(url, this.p)
                .then((response) => {
                    console.log(response.data)
                    if (response.data.status) {
                        let type = '1' // 1=success|2=info|3=error|4=loading|5=warning
                        let title = "Update"
                        let text = "Successful."
                        this.msj(type, title, text)                         
                    } else {
                        let msjp = response.data.msj.phone
                        let type = '3' // 1=success|2=info|3=error|4=loading|5=warning
                        let title = "Error"                        
                        if (msjp !== undefined) {
                            let text = msjp
                            this.msj(type, title, text)                            
                        } else {
                            let msje = response.data.msj.email  
                            let text = msje
                            this.msj(type, title, text)                                                         
                        }
                    }
                    this.$store.commit("core/setLoadingModal", false);
                    this.$store.commit("core/setCloseModal", {action: 'saved', method: 'refresh-edit'});                         
                })
                .catch((error) => {
                    console.log(error)
                    let type = '3' // 1=success|2=info|3=error|4=loading|5=warning
                    let title = "Error"
                    let text = "updating data."
                    this.msj(type, title, text)                         
                    this.$store.commit("core/setLoadingModal", false);
                })
        },
    },
};
</script>
