import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/';
import validRouters from './validRouters'
import core from '@/core/router/'
import appsRouter from '@/apps/routerIndex'
import PathNotFound from '@/core/views/error404'
// console.log('core', core)
// console.log('appsRouter', appsRouter)
// rutas de core
let rutas_core = core;
// rutas de las apps 
let rutas_apps = appsRouter;
// unir las rutas
let rutas_new = [];
let ruta_error = [{path: '/:pathMatch(.*)*',meta: { requiresVisitor: true }, name: 'error404',folder: PathNotFound}];
rutas_new = rutas_new.concat(rutas_core);
rutas_new = rutas_new.concat(rutas_apps);
rutas_new = rutas_new.concat(ruta_error);
// convertir a objeto router
let rutas = rutas_new.map(r => validRouters.route(r.path, r.folder, r.name, r.meta, r.children));
// console.log('rutas', rutas);

Vue.use(VueRouter)
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: rutas
})
router.beforeEach((to, from, next) => {
    // debugger;
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if(localStorage.getItem('ui') == 'Login'){
            if (!store.getters['core/session/loggedIn']) {
                // console.log('requiresAuth');
                next({ name: 'Login' })
            } else {
                if(localStorage.getItem('updateCache') == 1){
                    store.commit('core/setNotiPushRefresh', true);
                }
                next()
            }
        }
        else if(localStorage.getItem('ui') == 'Reseller'){
            //if (!store.getters['core/session/loggedInResller']) {
            if (!localStorage.getItem('access_token_r')) {
                // console.log('requiresAuth');
                next({ name: 'Reseller' })
            } else {
                if(localStorage.getItem('updateCache') == 1){
                    store.commit('core/setNotiPushRefresh', true);
                }
                next()
            }
        }else {
            next()
        }
    } else {
            if(localStorage.getItem('updateCache') == 1){
                store.commit('core/setNotiPushRefresh', true);
            }
            next()
    }
})
export default router
