let validSquare = function() {
	let t = this;
	window.masterGetBilling.get("validSquare/"+window.localuserdata,)
	.then((response) => {
		console.log('validSquare', response.data)
		t.validAccountSquare=response.data.showInvo;		
	})
	.catch((error) => {
		console.log(error);
	});
};
export default validSquare;
