<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h2 class="page-title"><i class="fas fa-home"></i> Dashboard Marketea</h2>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Dashboard",
    components: {},
    data() {
        return {
            isLoading: false,
        };
    },
};
</script>
