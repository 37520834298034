<template>
    <div>
        <div class="page-breadcrumb">
            <div class="row">
                <div class="col-5 align-self-center">
                    <h4 class="page-title">Subcontractor</h4>
                </div>
                <div class="col-7 align-self-center">
                    <div class="d-flex align-items-center justify-content-end">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link
                                        :to="{ name: 'EmployeesNew' }"
                                        class="btn btn-info btn-sm mr-2"
                                        ><i class="fas fa-plus"></i>Add
                                        Subcontractor</router-link
                                    >
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-body">
                            <div
                                class="d-flex justify-content-end"
                                style="justify-content: space-between"
                            >
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="TextSearch"
                                    placeholder="Filter Subcontractors"
                                    style="width: 200px; border: 1px solid #c9c9ca"
                                />
                            </div>
                            <table class="table table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th style="width: 30%">Full Name</th>
                                        <th style="width: 40%">Business Name</th>
                                        <th style="width: 15%">Type</th>
                                        <th style="width: 15%">Rate</th>
                                        <th style="width: 5%">W-9</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(i, index) in filteredCustomer"
                                        :key="index"
                                    >
                                        <td>{{ i.firstname }} {{ i.lastname }}</td>
                                        <td>
                                            <span v-if="i.type == 2">{{
                                                i.business_name
                                            }}</span>
                                        </td>
                                        <td>
                                            <span
                                                v-if="i.type == 1"
                                                class="badge badge-info"
                                                >Individual</span
                                            ><span
                                                v-if="i.type == 2"
                                                class="badge badge-secondary"
                                                >Corporation</span
                                            >
                                        </td>
                                        <td>
                                            <span v-if="i.type == 1"
                                                >${{ i.default_rate | formatMoney }}</span
                                            >
                                        </td>
                                        <td>
                                            <button
                                                class="btn_image_preview"
                                                v-if="i.image_url != null"
                                                @click="newtabBrowser(i.image_url)"
                                            >
                                                <i class="far fa-image"></i>
                                            </button>
                                        </td>

                                        <td style="width: 85px">
                                            <span class="cdropdown">
                                                <button class="">
                                                    <span class="">Actions</span>
                                                </button>
                                                <label>
                                                    <input type="checkbox" />
                                                    <ul>
                                                        <li
                                                            @click="
                                                                MetEditEmployees(i.id)
                                                            "
                                                        >
                                                            <button>
                                                                <i
                                                                    class="fas fa-edit"
                                                                ></i>
                                                                Edit
                                                            </button>
                                                        </li>
                                                        <!--  <li @click="voidE(i.id)"  v-if="i.status==1"><button><i class="fas fa-trash-alt"></i> Void</button></li> -->
                                                        <li
                                                            @click="
                                                                MetDeleteEmployees(i.id)
                                                            "
                                                        >
                                                            <button>
                                                                <i
                                                                    class="fas fa-times"
                                                                ></i>
                                                                Delete
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </label>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ClickOutside from "vue-click-outside";
export default {
    name: "Employees",
    components: {},
    data() {
        return {
            isLoading: false,
            isLoadingCustomers: false,
            CustomersList: [],
            TextSearch: "",
        };
    },
    created() {
        this.getEmployees();
    },
    mounted() {},
    directives: {
        ClickOutside,
    },
    methods: {
        getEmployees() {
            var self = this;
            this.isLoadingCustomers = true;
            window.billing
                .get("employees/" + window.localuserdata)
                .then((response) => {
                    if (response.data.success) {
                        self.CustomersList = response.data.result;
                    }
                    self.isLoadingCustomers = false;
                })
                .catch((error) => console.log(error));
        },
        newtabBrowser(image) {
            window.open(
                window.urlmaster +
                    "media/" +
                    window.localAccountID +
                    "/" +
                    window.localuserdata +
                    "/w-9/" +
                    image,
                "_blank"
            );
        },
        MetEditEmployees(id) {
            this.$router.push({
                name: "EmployeesNewEdit",
                params: { subcontractor_id: id },
            });
        },
        MetDeleteEmployees(id) {
            window.billing
                .delete("employees/delete/" + id + "/" + window.localuserdata)
                .then((response) => {
                    if (response.data.status) {
                        this.getEmployees();
                        this.$notify({
                            group: "noti",
                            title: "Deleted",
                            type: "success",
                            text: "Deleted successfull",
                        });
                    } else {
                        this.$notify({
                            group: "noti",
                            title: "Error",
                            type: "error",
                            text:
                                "This customer have attached documents and can not be deleted",
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
    },
    computed: {
        filteredCustomer: function () {
            var self = this;
            return this.CustomersList.filter(function (item) {
                return (
                    (item.firstname &&
                        item.firstname
                            .toLowerCase()
                            .indexOf(self.TextSearch.toLowerCase()) >= 0) ||
                    (item.business_name &&
                        item.business_name
                            .toLowerCase()
                            .indexOf(self.TextSearch.toLowerCase()) >= 0) ||
                    (item.lastname &&
                        item.lastname
                            .toLowerCase()
                            .indexOf(self.TextSearch.toLowerCase()) >= 0)
                );
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.btn_image_preview {
    border: 0;
    background-color: transparent;
    cursor: pointer;
    i {
        font-size: 22px;
    }
}
.eboton i {
    font-size: 12px;
}
.eboton {
    padding: 5px 10px;
    font-size: 14px;
    margin-left: 10px;
    color: #fff;
    background-color: #137eff !important;
    border-color: #137eff !important;
}
#btnGroupDrop1 {
    height: 26px;
    padding-top: 0px;
}
button.btn.btn-success.btn-new {
    padding: 0px;
    width: 26px;
    height: 26px;
}
.Btncancel {
    background-color: #fff;
    border-color: #000;
}
.Btncancel,
.BtnSave,
.BtnDel {
    border-radius: 2px;
}
.Btncancel,
.BtnSave,
.BtnDel {
    margin-left: 5px;
    padding: 5px 10px;
    font-size: 14px;
}
.contenedor-total {
    padding: 0rem;
}
.alert_totales {
    display: inline-block;
    padding: 0.2rem 0.5rem;
    width: 50%;
    text-align: center;
}
.alert_totales h1 span {
    font-size: 20px;
}
.tabla-transaction {
    width: 100%;
}
@media (max-width: 1190px) {
    .tabla-transaction {
        max-width: 800px;
        overflow-x: scroll;
    }
    .tabla-transaction table {
        min-width: 800px;
    }
    .alert_totales {
        display: block;
        width: 100%;
        text-align: right;
    }
}
@media (max-width: 767px) {
    .list-group.list-group-flush {
        height: 240px;
    }
}
.balance {
    color: #ff0000;
}
.btn-transaction {
    color: #fff !important;
    background-color: #137eff !important;
    border-color: #137eff !important;
    border-radius: 2px !important;
}
table tbody tr td .badge {
    width: 70px;
}
</style>
