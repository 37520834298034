<template>
    <div class="card">
        <div class="card-header"><h3>Assign Inspector</h3> </div>
        <div class="card-body">
            <div class="popover-assign-seller">
                <h4>Choose a Representative:</h4>
                <div class="content-table">
                    <multiselect v-model="userIdAssign" placeholder="Search:" select-label="Add" track-by="account_id"
                        :options="options" :multiple="false" style="border: 1px solid #343a402e;" ref="vselectSeller"
                        @input="saveAssign">
                        <template slot="selection" slot-scope="{ values, search, isOpen }">
                            <span class="multiselect__single" v-if="values.length" v-show="!isOpen">
                                Search:
                                <!-- {{ values.length }} assigned users -->
                            </span>
                        </template>
                        <template #singleLabel="props">
                            <span class="badge badge-light">
                                <span :class="['avatarUser', 'bg-avatar']" :style="'background-color:'+props.option.classColor+';'">
                                    {{ props.option.pref_name }}
                                </span>
                                <span class="option__title" style="">
                                        {{ props.option.firstname }} {{ props.option.lastname }}<br />
                                        <span class="option__small" style="">
                                            {{ props.option.email }}
                                        </span>
                                </span>
                            </span>
                        </template>
                        <template slot="option" slot-scope="props">
                            <div class="option__desc" style="margin: 0.5px; ">
                                <div>
                                    <strong style="color:#555; margin-bottom: 5px;" v-if="props.option.account_id == owner">{{
                                        props.option.owner }} <small>(Owner)</small><br /></strong>
                                    <span class="option__title" style="font-size: 12px;color: #4f5467;">
                                        {{ props.option.firstname }} {{ props.option.lastname }}
                                    </span>
                                    <br />
                                    <span class="option__small" style="font-size: 11px;color: #aaa;">
                                        {{ props.option.email }}
                                    </span>
                                </div>
                            </div>
                        </template>
                    </multiselect>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import './sellerAssign.scss'
export default {
    components: {
        Multiselect,
    },
    data() {
        return {
            org_id: window.localorgdata,
            owner: window.master_client,
            userIdAssign: null,
            userlist: [],
            options: [],
            item: null,
            isOpen: false,
        };
    },
    created() {
        let t = this;
        let g = t.$store.getters['core/getOpenModal'];
        t.userIdAssign = g.data.userlist.find(r => { return r.account_id == g.data.item.insp_users});
        t.item = g.data.item;
        t.options = g.data.userlist;
        if (t.userlist.length > 0) {
            t.userlist.filter(r => {
                t.options.push(r);
            })
        }
        console.log('userIdAssign', this.userIdAssign)
    },
    methods: {
        removeElement: function (user) {
            this.$refs.vselectSeller.removeElement(user)
            //console.log('removeElement', user)
        },
        saveAssign(val) {
            let t = this;
            console.log('saveAssign', val)
            t.userIdAssign = val
            window.master.post("assignInspSales", {
                id: t.item.id,
                insp_users: val.account_id,
                user_id: window.master_client,
                org_id: window.localorgdata,
            })
            .then((response) => {
                console.log('assignInspSales', response.data);
                t.$parent.close();
            })
            .catch((error) => {
                console.log(error);
            });
        },
    },
};
</script>
