export default {
    retrieveTokenSlabs(context, credentials) {
        //login Slabs
        return new Promise((resolve, reject) => {
            const token = 'slabs';
            localStorage.setItem('access_token_slabs', token);
            localStorage.setItem('oauth_slabs', 'slabs');
            window.localtoken_slabs = token;
            window.Slabs.defaults.headers.common.Authorization = 'Bearer ' + token;
            console.log('login de Slabs ');
            const accessSlabs = {
            token: token,
            login: true,
            };
            context.commit('ActivarSlabs', accessSlabs);
            resolve(true);
            // resolve(response);
        });
    },
    access_slabs(context, accessSlabs) {
        context.commit('ActivarSlabs', accessSlabs);
    },
}
