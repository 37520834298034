<template>
    <div>
        <div class="card-header">E-mail Notification Setup</div>
        <div class="card-body">
            <form @submit.prevent="saveSetting">
                <div class="form-row">
                    <div class="col-12">
                        <label class="radio-inline row">
                            <span id="email" class="form-text col-10"> Use system header and reply to my mail <small>(Recommended)</small></span>
                            <input type="radio" :value="0" v-model="showMenu" class="form-text col-2" />
                        </label>
                        <label class="radio-inline row">
                            <span id="email" class="form-text col-10"> Use organization mail as header</span>
                            <input type="radio" :value="1" v-model="showMenu" class="form-text col-2"/>
                        </label>
                    </div>
                    <div class="col-12">
                        <br />
                        <button type="submit" class="btn btn-success">SAVE</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name: 'mailsendOptions',
    components: {
    },
    data () {
        return {
            isLoading: false,
            showMenu: 0,
            title: '',
            meta_key: '',
        };
    },
    created () {
        let t = this;
        let getModal = t.$store.getters['core/getOpenModal'];
        t.title = getModal.data.title;
        t.meta_key = getModal.data.meta_key;
        t.getStart();
    },
    methods: {
        getStart () {
            this.isLoading = true;
            this.$store.commit("core/setLoadingModal", true);
            window.billing
                .post('getKeyValSetting', {
                    user_id: window.master_client,
                    org_id: window.localorgdata,
                    meta_key: this.meta_key,
                })
                .then((response) => {
                    console.log(response.data);
                    if(response.data.status){
                        if(response.data.r && response.data.r.meta_value){
                            this.showMenu = response.data.r.meta_value;
                        }
                    }
                    this.isLoading = false;
                    this.$store.commit("core/setLoadingModal", false);
                })
                .catch((error) => {
                    console.log(error);
                    this.$store.commit("core/setLoadingModal", false);
                });
        },
        saveSetting () {
            this.$store.commit("core/setLoadingModal", true);
            window.billing
                .post('setKeyValSetting', {
                    user_id: window.master_client,
                    org_id: window.localorgdata,
                    meta_key: this.meta_key,
                    meta_value: this.showMenu,
                })
                .then((response) => {
                    this.$swal.fire({
                        icon: 'success',
                        title: 'Updated',
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    this.$store.commit("core/setLoadingModal", false);
                    this.$store.commit("core/setCloseModal", {action: 'saved'}); 
                })
                .catch((error) => {
                    this.$store.commit("core/setLoadingModal", false);
                    console.log(error, (this.isLoading = false))
                });
        },
    },
    computed: { },
};
</script>
<style lang="scss" scoped>
    .radio-inline.row{
        border: 1px solid #ccc;
        padding: 10px 2px;
        margin: 0px 3px;
        position: relative;
        cursor: pointer;
        margin-bottom: 10px !important;
    }
    .radio-inline.row input,.radio-inline.row label{margin:0px}
    input.form-text.col-2 {
        position: absolute;
        right: 0;
        top: 12px;
        height: 20px;
    }

</style>
