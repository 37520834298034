<template>
    <div class="container-fluid">
        <div class="card">
            <div class="card-header" style="padding: 0px">
                <sheader :name="titulo"></sheader>
            </div>
            <div class="card-body">
                <div class="row" style="padding-bottom: 20px">
                    <div class="col-md-2"></div>
                    <div class="col-md-2">
                        <button
                            class="btn btn-success"
                            style="margin-left: 10px"
                            @click="tag()"
                        >
                            <i class="fas fa-plus"></i> Add Tags
                        </button>
                    </div>
                    <div class="col-md-6">
                        <input
                            type="text"
                            class="form-control search"
                            v-model="search"
                            placeholder="Search"
                        />
                    </div>
                    <div class="col-md-2"></div>
                </div>
                <div class="row">
                    <div class="col-2"></div>
                    <div class="col-8">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th width="10px">Edit</th>
                                    <th width="10px">Delete</th>
                                </tr>
                            </thead>
                            <tbody v-if="isLoading">
                                <tr>
                                    <td colspan="10"><Loader></Loader></td>
                                </tr>
                            </tbody>
                            <tbody v-else-if="filterItems.length == 0">
                                <tr>
                                    <td colspan="3" class="text-center">No Record</td>
                                </tr>
                            </tbody>
                            <draggable
                                v-else
                                class="tbody"
                                v-bind="dragOptions"
                                tag="tbody"
                                @start="isDragging = true"
                                @end="isDragging = false"
                                handle=".handle"
                                @change="checkMove"
                                v-model="tags"
                            >
                                <tr v-for="(i, index) in filterItems" :key="index">
                                    <td>
                                        <div class="handle">
                                            <i class="fas fa-ellipsis-v"></i>
                                        </div>
                                        {{ i.name }}
                                    </td>
                                    <td>
                                        <button class="btn btn-info" @click="edit(i.id)">
                                            <i class="fas fa-edit"></i>
                                        </button>
                                    </td>
                                    <td>
                                        <button class="btn btn-danger" @click="del(i.id)">
                                            <i class="fas fa-trash"></i>
                                        </button>
                                    </td>
                                </tr>
                            </draggable>
                        </table>
                    </div>
                </div>
            </div>
        </div>
		<!-- modal -->
		<modalLateral>
			<component v-bind:is="m.component"></component>
		</modalLateral>           
    </div>
</template>
<script>
import modalLateral from '@/core/plugins/themeModalLateral/modalLateral'
import sheader from "@/apps/jobs/components/setup/setupHeader";
import formTagsModal from "@/apps/jobs/components/setup/formTagsModal.vue";
import Loader from "@/core/components/shared/Loader";
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
export default {
    name: "Tags",
    components: {
        Loader,
        sheader,
        draggable,
        modalLateral,
        formTagsModal,
    },
    data() {
        return {
            titulo: "Tags",
            btnSave: false,
            isLoading: false,
            tags: [],
            search: "",
            user_id: window.master_client,
            org_id: window.localorgdata,
            f: {
                id: "",
                name: "",
                user_id: window.master_client,
                org_id: window.localorgdata,
            },
            m: {
                show: false,
                component: '',
                data: []
            },                
        };
    },
    created() {      
        this.init();
    },
    methods: {
        init(){
            let urlApis = this.$store.getters['ModApps/jobs/urlApis'];
            this.apis = urlApis.setup;         
            this.getJobsTags();            
        },
        checkMove() {
            this.tags.map((item, index) => {
                item.order = index + 1;
            });
            console.log("nove ", this.tags);
            let url = this.apis.checkMove()
            window.master
                .put(url, {
                    orderTags: this.tags,
                })
                .then((response) => {
                    console.log(response.data);
                    this.$store.dispatch("jobs/update_menuTags");
                })
                .catch((error) => console.log(error, (this.isLoading = false)));
        },
        tag() {
            this.m = {
                show: true,
                component: 'formTagsModal',
                data: []
            }
            this.$store.commit('core/setOpenModal', this.m);            
        },
        edit(id) {
            let t = this.tags;
            for (let i = 0; i < t.length; i++) {
                if (id == t[i].id) {
                    this.f.id = t[i].id;
                    this.f.name = t[i].name;
                }
            }
            this.m = {
                show: true,
                component: 'formTagsModal',
                data: {id:this.f.id, name:this.f.name}
            }
            this.$store.commit('core/setOpenModal', this.m); 
        },
        getJobsTags() {
            this.isLoading = true;
            let url = this.apis.getJobsTags()
            window.master
                .get(url)
                .then((response) => {
                    let r = response.data.result;
                    this.tags = r;
                    console.log("getJobsTags", response.data);
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        del(id) {
            this.$swal({
                title: "Delete Tags?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                if (result.value) {
                    let url = this.apis.del()
                    window.master
                        .delete(url, {
                                user_id: this.user_id,
                                org_id: this.org_id,
                                id: id,
                            }
                        )
                        .then((response) => {
                            let r = response.data.status;
                            if (r == false) {
                                this.$swal.fire({
                                    icon: "error",
                                    title: "cannot be deleted because it is in use",
                                    showConfirmButton: false,
                                    timer: 4000,
                                });
                            } else {
                                this.$store.dispatch("jobs/update_menuTags");
                                this.getJobsTags();
                            }
                        })
                        .catch((error) => console.log(error));
                }
            });
        },
    },
    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: "tags",
                disabled: false,
                ghostClass: "ghost",
            };
        },
        filterItems: function () {
            var self = this;
            return this.tags.filter(function (item) {
                return (
                    item.name &&
                    item.name.toLowerCase().indexOf(self.search.toLowerCase()) >= 0
                );
            });
        },
        ...mapGetters("core", { getOpenModal: "getOpenModal" }),
    },
    watch: {
        'getOpenModal': function() {
            if (this.getOpenModal.data.metodo === "returnTags") {               
                this.$notify({
                    group: "noti",
                    title: "Saved",
                    type: "success",
                    text: "Successful registration",
                }); 
                this.getJobsTags();                               
                this.m = {
                    show: false,
                    component: '',
                    data: {}
                }                
            }
        },         
    },    
};
</script>
<style lang="scss" scoped>
.search {
    border-color: #555;
}
.handle {
    position: absolute;
    border: 0px solid #000;
    left: 0px;
    padding: 15px 2px 3px 4px;
    height: 42px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: rgba(17, 177, 245, 0.38);
    cursor: move;
    margin-top: -9px;
}
.tbody t {
    position: relative;
}
.tbody tr td {
    position: relative;
    padding-left: 16px;
}
</style>
