<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h2 class="page-title"><i class="fas fa-cog"></i> Setup</h2>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-3">
                        <div class="list-group list-group-flush">
                            <h4
                                style="
                                    color: #9c9c9c;
                                    font-weight: normal;
                                    font-size: 20px;
                                "
                            >
                                General Setting
                            </h4>
                            <div
                                class="list-group-item list-group-item-action"
                                @click="ModalBox('CounterOptions')"
                                v-if="usertype == 0"
                            >
                                Counter Options
                            </div>
                            <router-link
                                :to="{ name: 'Tags' }"
                                class="list-group-item list-group-item-action"
                                >Tags</router-link
                            >
                            <router-link
                                :to="{ name: 'To_do_lists' }"
                                class="list-group-item list-group-item-action"
                                >To do lists</router-link
                            >
                            <div
                                class="list-group-item list-group-item-action"
                                @click="ModalBox('tagsDefault')"
                                v-if="usertype == 0"
                            >
                                Tags Default
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-md-3"  v-if="usertype == 0">
                        <div class="list-group list-group-flush">
                            <h4 style="color: #9c9c9c;font-weight: normal;font-size: 20px;">Users </h4>
                            <div class="list-group-item list-group-item-action" >
                                <router-link :to="{name: 'User'}" class="list-group-item list-group-item-action">Users List</router-link>
                            </div>
                        </div>
                    </div> -->
                    <div class="col-md-3" v-if="usertype == 0">
                        <div class="list-group list-group-flush">
                            <h4
                                style="
                                    color: #9c9c9c;
                                    font-weight: normal;
                                    font-size: 20px;
                                "
                            >
                                Customer
                            </h4>
                            <div
                                class="list-group-item list-group-item-action"
                                @click="ModalBox('ShowCustomer')"
                                v-if="usertype == 0"
                            >
                                Show Customer Name
                            </div>
                            <div
                                class="list-group-item list-group-item-action"
                                @click="ModalBox('ShowCustomerNotes')"
                                v-if="usertype == 0"
                            >
                                Show Notes
                            </div>
                            <div
                                class="list-group-item list-group-item-action"
                                @click="ModalBox('ShowInvoice')"
                                v-if="usertype == 0"
                            >
                                Show invoice
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal name="modal-setting" height="auto" v-bind:click-to-close="false">
            <div class="card">
                <button @click="ClosedModal" class="closeIcon"><span></span></button>
                <CounterOptions v-if="SettingBox == 'CounterOptions'"></CounterOptions>
                <ShowCustomer v-if="SettingBox == 'ShowCustomer'"></ShowCustomer>
                <ShowCustomerNotes
                    v-if="SettingBox == 'ShowCustomerNotes'"
                ></ShowCustomerNotes>
                <ShowInvoice v-if="SettingBox == 'ShowInvoice'"></ShowInvoice>
            </div>
        </modal>
        <!-- modal -->
        <modalLateral>
            <component v-bind:is="m.component"></component>
        </modalLateral>         
    </div>
</template>
<script>
import modalLateral from '@/core/plugins/themeModalLateral/modalLateral'
import CounterOptions from '@/apps/jobs/components/setup/CounterOptions'
import ShowCustomer from '@/apps/jobs/components/setup/ShowCustomer'
import ShowCustomerNotes from '@/apps/jobs/components/setup/ShowCustomerNotes'
import ShowInvoice from '@/apps/jobs/components/setup/ShowInvoice'
import tagsDefault from '@/apps/jobs/components/setup/tagsDefault'
import { mapGetters } from 'vuex'
export default {
    name: "JobsSetup",
    components: {
        modalLateral,
        CounterOptions,
        ShowCustomer,
        ShowCustomerNotes,
        ShowInvoice,
        tagsDefault,
    },
    data() {
        return {
            m: {
                show: false,
                component: '',
                data: []
            },             
            SettingBox: "",
            usertype: window.user_type,
        };
    },
    created() {
        this.validAdminApp();
    },
    methods: {
        validAdminApp() {
            let admin = false;
            if (window.user_type == 0) {
                admin = true;
            } else {
                for (let i in this.appRolUser) {
                    if (this.appIDactual == this.appRolUser[i].app_id) {
                        if (this.appRolUser[i].rol == 1) {
                            admin = true;
                        }
                    }
                }
            }
            if (admin) {
                //permisos de admin
                this.usertype = 0;
                console.log("menu admin");
            }
        },       
        ModalBox(i) {
            let t = this;
            this.m = {
                show: true,
                component: i,
                data: {}
            }             
            this.$store.commit('core/setOpenModal', this.m);

            // this.SettingBox = i;
            // this.$modal.show("modal-setting");
        },
        ClosedModal() {
            this.$modal.hide("modal-setting");
            this.SettingBox = "";
        },
    },
        watch: {
        'getOpenModal': function() {
            if (!this.getOpenModal.show) {
                if (Object.keys(this.getOpenModal.data).length > 0) {
                    let t = this.getOpenModal.data;
                }
                this.m = {
                    show: false,
                    component: '',
                    data: {}
                }
            }
        },
    },
    computed: {
        ...mapGetters("core", { getOpenModal: "getOpenModal" }),
        ...mapGetters("core/apps", ["appRolUser", "appIDactual"]),
    },
};
</script>
