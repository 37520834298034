<template>
    <div>
        <div class="page-breadcrumb">
            <div class="row">
                <div class="col-md-8">
                    <div class="row">
                        <div class="col-md-6">
                            <h4 class="page-title" style="width: 260px">
                                <i class="fas fa-chart-line"></i> Report Taxes
                            </h4>
                        </div>
                        <div class="col-md-6">
                            <select class="form-control" v-model="TaxCurrent" style="margin-bottom: 5px;">
                                <option
                                    :value="i.id"
                                    v-for="(i, index) in TaxesLists"
                                    :key="index"
                                >
                                    {{ i.statename }} {{ i.name }}%
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-md-4" style="text-align: end;">
                    <div class="d-flex align-items-center justify-content-end">
                        <router-link :to="{ name: 'BillingReport' }" class="btn btn-info btn-sm mr-2 ctrlbtn" style="margin-bottom: 10px;width: -webkit-fill-available;">
                            <i class="fas fa-arrow-left"></i>
                            Back
                        </router-link>  
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card card-body">
                        <div class="row">
                            <!-- start botoneras ajustados a responsive -->
                            <div class="col-md-12">
                                <div class="row">
                                    <div id="divA" class="col-md-3">
                                        <div class="form-group row">
                                            <div class="col-md-3">
                                                <button
                                                    type="button"
                                                    class="btn btn-success mb-2"
                                                    style="width: inherit;min-width: 80px;"
                                                    @click="FilterTaxReport()"
                                                >
                                                    <i class="fas fa-search"></i> Filter
                                                </button>
                                            </div>                               
                                            <div class="col-md-9">
                                                <select
                                                    class="form-control"
                                                    v-model="SelectOne"
                                                    @change="ChangeSelectPrimary"
                                                >
                                                    <option value="1">This Year</option>
                                                    <option value="2">Last Year</option>
                                                    <option value="3">Calendar</option>
                                                </select>                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div id="divS" class="col-md-4" v-if="SelectOne == 3">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group row">
                                                    <label class="col-sm-3 col-form-label"><b>From:</b></label>
                                                    <div class="col-sm-9">
                                                        <datetime
                                                            type="date"
                                                            v-model="dateFrom"
                                                            format="MMM dd, yyyy"
                                                            input-class="form-control"
                                                            value-zone="America/New_York"
                                                        ></datetime>            
                                                    </div>
                                                </div>                               
                                            </div>
                                            <div class="col-md-6">   
                                                <div class="form-group row">
                                                    <label class="col-sm-3 col-form-label"><b>To:</b></label>
                                                    <div class="col-sm-9">
                                                        <datetime
                                                            type="date"
                                                            v-model="dateTo"
                                                            format="MMM dd, yyyy"
                                                            input-class="form-control"
                                                            value-zone="America/New_York"
                                                        ></datetime>           
                                                    </div>                                             
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="divB" class="col-md-5" v-if="SelectOne == 1 || SelectOne == 2">
                                        <div class="form-group row">
                                            <div class="col-md-12 trim_filter">
                                                <button
                                                    style="margin-bottom: 2px;"
                                                    type="button"
                                                    class="btn btn-primary"
                                                    @click="trimester(1)"
                                                >
                                                    Q1: ${{ trim1 | formatMoney }}
                                                </button>
                                                <button
                                                    style="margin-bottom: 2px;"
                                                    type="button"
                                                    class="btn btn-primary"
                                                    @click="trimester(2)"
                                                >
                                                    Q2: ${{ trim2 | formatMoney }}
                                                </button>
                                                <button
                                                    style="margin-bottom: 2px;"
                                                    type="button"
                                                    class="btn btn-primary"
                                                    @click="trimester(3)"
                                                >
                                                    Q3: ${{ trim3 | formatMoney }}
                                                </button>
                                                <button
                                                    style="margin-bottom: 2px;"
                                                    type="button"
                                                    class="btn btn-primary"
                                                    @click="trimester(4)"
                                                >
                                                    Q4: ${{ trim4 | formatMoney }}
                                                </button>
                                            </div>   
                                        </div>
                                    </div>
                                    <div id="divC" v-if="SelectOne != 3" class="col-md-4">
                                        <div style="display: flex; justify-content: flex-end">
                                            <span class="cdropdown" style="margin-bottom: 10px;">
                                                <button class="btn btn-transaction">
                                                    Export As
                                                </button>
                                                <label>
                                                    <input type="checkbox" />
                                                    <ul class="text-left">
                                                        <li @click="generarFile('pdf')">
                                                            <button><strong>PDF</strong></button>
                                                        </li>
                                                        <li @click="generarFile('csv')">
                                                            <button>
                                                                CSV (Comma Separated Value)
                                                            </button>
                                                        </li>
                                                        <!-- <li @click="generarFiles('xls')"><button>XLS (Microsoft Excel 1997 - 2004 Compatible)</button></li> -->
                                                        <li @click="generarFile('xlsx')">
                                                            <button>
                                                                XLSX (Microsoft Excel)
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </label>
                                            </span>
                                        </div>
                                    </div>   
                                    <div id="divC" v-else class="col-md-5">
                                        <div style="display: flex; justify-content: flex-end">
                                            <span class="cdropdown" style="margin-bottom: 10px;">
                                                <button class="btn btn-transaction">
                                                    Export As
                                                </button>
                                                <label>
                                                    <input type="checkbox" />
                                                    <ul class="text-left">
                                                        <li @click="generarFile('pdf')">
                                                            <button><strong>PDF</strong></button>
                                                        </li>
                                                        <li @click="generarFile('csv')">
                                                            <button>
                                                                CSV (Comma Separated Value)
                                                            </button>
                                                        </li>
                                                        <!-- <li @click="generarFiles('xls')"><button>XLS (Microsoft Excel 1997 - 2004 Compatible)</button></li> -->
                                                        <li @click="generarFile('xlsx')">
                                                            <button>
                                                                XLSX (Microsoft Excel)
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </label>
                                            </span>
                                        </div>
                                    </div>                                                                        
                                </div>
                            </div>
                            <!-- end botoneras ajustados a responsive -->                        

                            <div class="col-md-12">
                                <div class="contenedor-table">
                                    <div class="bgloading" v-if="isLoading">
                                        <Loader></Loader>
                                    </div>
                                    <table
                                        class="table table-striped table-bordered table-hover"
                                        id="invList"
                                        style="margin-top: 15px"
                                    >
                                        <thead>
                                            <tr>
                                                <th scope="col">Inv #</th>
                                                <th scope="col">Date</th>
                                                <th scope="col" style="width: 200px">
                                                    Customer
                                                </th>
                                                <th scope="col" style="width: 100px">
                                                    Tax Name
                                                </th>
                                                <th scope="col" style="text-align: right">
                                                    Tax Amount
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td style="text-align: center"></td>
                                                <td style="text-align: right">
                                                    $<b style="font-size: 16px">{{
                                                        total | formatMoney
                                                    }}</b>
                                                </td>
                                            </tr>
                                            <tr
                                                v-for="item in InvoicesListR"
                                                :key="item.id"
                                                v-if="item.taxe !== 0"
                                            >
                                                <td scope="row">{{ item.nro }}</td>
                                                <td>{{ item.fecha }}</td>
                                                <td>
                                                    {{ item.customername }}
                                                    {{ item.customelastname }}<br /><span
                                                        style="color: #a9a9a9"
                                                        >{{ item.customecompany }}</span
                                                    >
                                                </td>
                                                <td style="text-align: left">
                                                    <span
                                                        >{{ item.taxename }}
                                                        {{ item.taxenum }}%</span
                                                    >
                                                </td>
                                                <th scope="col" style="text-align: right">
                                                    ${{ item.taxe | formatMoney }}
                                                </th>
                                            </tr>
                                            <tr v-if="noresult">
                                                <td colspan="9">
                                                    <div
                                                        style="
                                                            text-align: center;
                                                            color: red;
                                                        "
                                                    >
                                                        No result
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td style="text-align: center"></td>
                                                <td style="text-align: right">
                                                    $<b style="font-size: 16px">{{
                                                        total | formatMoney
                                                    }}</b>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader";
export default {
    name: "Report",
    components: {
        Loader,
    },
    data() {
        return {
            isLoading: false,
            noresult: false,
            statusdropd: false,
            taxeCheck: false,
            isCheckAll: true,
            statusdata: [
                { value: "1", name: "Pending" },
                { value: "2", name: "Partially Paid" },
                { value: "3", name: "Paid" },
                { value: "4", name: "Overdue" },
                { value: "5", name: "Void" },
            ],
            statuslist: [1, 2, 3, 4, 5],
            showModal: false,
            InvoicesListR: [],
            SelectOne: 1,
            SelectStatus: 0,
            //dateFrom : new Date().toISOString(),
            dateFrom: new Date(
                (new Date().toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
            ).toISOString(),
            dateTo: new Date(
                (new Date().toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
            ).toISOString(),
            thisYear: null,
            thisMonth: null,
            type: null,
            TaxCurrent: null,
            TaxesLists: [],
            q01: [],
            q02: [],
            q03: [],
            q04: [],
        };
    },
    created() {
        this.getTaxes();
        //this.FilterInvoice();
        //this.taxFilter();
    },
    mounted() {
        this.setTimeoutResponsive()
    },
    methods: {
        modoResponsive() {
            let divA = document.getElementById("divA");
            let divB = document.getElementById("divB");
            let divC = document.getElementById("divC");
            let divS = document.getElementById("divS");
            if (screen.width === 1024 && screen.height === 1366) {
                if (divA !== null) {
                    divA.classList.remove("col-md-3");	
                    divA.classList.add("col-md-12");
                }
                if (divB !== null) {
                    divB.classList.remove("col-md-5");	
                    divB.classList.add("col-md-8");
                }
                if (divC !== null) {
                    divC.classList.remove("col-md-5");	
                    divC.classList.add("col-md-4"); 
                }
                if (divS !== null) {
                    divS.classList.remove("col-md-4");	
                    divS.classList.add("col-md-8"); 
                }                                               	
            }
            if (screen.width === 768 && screen.height === 1024) {
                if (divA !== null) {
                    divA.classList.remove("col-md-3");	
                    divA.classList.add("col-md-12");
                }
                if (divB !== null) {
                    divB.classList.remove("col-md-5");	
                    divB.classList.add("col-md-8");
                }
                if (divC !== null) {
                    divC.classList.remove("col-md-5");	
                    divC.classList.add("col-md-4"); 
                }
                if (divS !== null) {
                    divS.classList.remove("col-md-4");	
                    divS.classList.add("col-md-8"); 
                }  
            } 
            if (screen.width === 1024 && screen.height === 600) {
                if (divA !== null) {
                    divA.classList.remove("col-md-3");	
                    divA.classList.add("col-md-12");
                }
                if (divB !== null) {
                    divB.classList.remove("col-md-5");	
                    divB.classList.add("col-md-8");
                }
                if (divC !== null) {
                    divC.classList.remove("col-md-5");	
                    divC.classList.add("col-md-4"); 
                }
                if (divS !== null) {
                    divS.classList.remove("col-md-4");	
                    divS.classList.add("col-md-8"); 
                }                                              	
            }
            if (screen.width === 1280 && screen.height === 800) {
                if (divA !== null) {
                    divA.classList.remove("col-md-3");	
                    divA.classList.add("col-md-12");
                }
                if (divB !== null) {
                    divB.classList.remove("col-md-5");	
                    divB.classList.add("col-md-8");
                }
                if (divC !== null) {
                    divC.classList.remove("col-md-5");	
                    divC.classList.add("col-md-4"); 
                }
                if (divS !== null) {
                    divS.classList.remove("col-md-4");	
                    divS.classList.add("col-md-8"); 
                }                                               	
            }
        },
        ChangeSelectPrimary() {
            this.setTimeoutResponsive()
        },
        setTimeoutResponsive() {
			let t = this
			setTimeout(function () {
				t.modoResponsive()
			}, 1000); 
        },         
        getTaxes() {
            window.billing
                .get("taxes/" + window.localuserdata)
                .then((response) => {
                    console.log(response.data);
                    this.TaxesLists = response.data;
                    this.TaxCurrent = 0;
                    if (this.TaxesLists.length > 0) {
                        this.TaxCurrent = this.TaxesLists[0].id;
                        this.taxFilter();
                        this.FilterTaxReport();
                    }
                })
                .catch((error) => console.log(error));
        },
        taxFilter() {
            console.log("tax id " + this.TaxCurrent);
            window.billing
                .get("taxes/filter/" + window.localuserdata + "/" + this.TaxCurrent)
                .then((response) => {
                    console.log(response.data);
                    this.InvoicesListR = response.data.result;
                })
                .catch((error) => console.log(error));
        },
        trimester(trim) {
            console.log("trim= " + trim);
            window.billing
                .put("taxes/filtertrim/" + window.localuserdata + "/" + this.TaxCurrent, {
                    user_id: window.localuserdata,
                    selecttype: this.SelectOne,
                    trim: trim,
                })
                .then((response) => {
                    console.log(response.data);
                    this.InvoicesListR = response.data.result;
                })
                .catch((error) => console.log(error));
        },
        FilterTaxReport() {
            this.isLoading = true;
            console.log("Filtrar");
            window.billing
                .put("report/taxes/" + window.localuserdata, {
                    user_id: window.localuserdata,
                    selecttype: this.SelectOne,
                    status: this.statuslist,
                    taxeCheck: this.taxeCheck,
                    dateFrom: this.dateFrom,
                    dateTo: this.dateTo,
                    filetype: this.type,
                    tax_id: this.TaxCurrent,
                })
                .then((response) => {
                    console.log("reponse data filter general");
                    console.log(response.data);
                    this.statusdropd = false;
                    this.isLoading = false;

                    this.InvoicesListR = response.data.result;
                    console.log("reponse pdf= " + response.data.pdf);

                    this.trimall();
                    if (this.InvoicesListR.length == 0) {
                        this.noresult = true;
                    } else {
                        this.noresult = false;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        trimall() {
            window.billing
                .put("taxes/trimall/" + window.localuserdata + "/" + this.TaxCurrent, {
                    user_id: window.localuserdata,
                    selecttype: this.SelectOne,
                })
                .then((response) => {
                    console.log("result taxes btn total");
                    console.log(response.data);
                    this.q01 = response.data.result1;
                    this.q02 = response.data.result2;
                    this.q03 = response.data.result3;
                    this.q04 = response.data.result4;
                })
                .catch((error) => console.log(error));
        },
        generarFile(type) {
            this.pdf = 0;
            let uri =
                "report/export/taxes/pdf/" +
                window.localuserdata +
                "/" +
                this.TaxCurrent +
                "/" +
                this.SelectOne +
                "/" +
                this.dateFrom +
                "/" +
                this.dateTo +
                "/" +
                type;
            window.open(window.urlbase + "api/v2/" + uri, "_blank");

            //let resultlist = this.InvoicesListR.filter(r => r.taxe > 0);
        },
        formatearFecha() {
            this.dateFrom = new Date(
                (new Date(this.dateFrom).toISOString() + "").slice(0, 10) +
                    "T23:31:04.000Z"
            ).toISOString();
        },
        formatearFecha2() {
            this.dateTo = new Date(
                (new Date(this.dateTo).toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
            ).toISOString();
        },
        /* generarFiles(type){
          console.log('type file ' + type)
          this.$swal({
            title: "Export",
            text: "Export Report as "+type+" (Microsoft Excel)",
            icon: "info",
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonText: 'Cancel',
            confirmButtonText: "Export",
            showLoaderOnConfirm: true,
            preConfirm: () => {
              window.billing.put('taxes/export/files/'+ window.localuserdata,{
                            user_id: window.localuserdata,
                            selecttype: this.SelectOne,
                            dateFrom: this.dateFrom,
                            dateTo: this.dateTo,
                            filetype : type,
                            tax_id : this.TaxCurrent,
                          })
                .then(response => {
                    console.log(response.data);
                    this.statusdropd = false
                    this.isLoading = false
                    window.open(window.urlbase+'files/'+ response.data.result);
                    
                })
                .catch(error => {
                  this.$swal.showValidationMessage(
                    `Request failed: ${error}`
                  )
                })
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
          .then((result) => {
          if (result.value) {
             this.$swal.fire({
              allowOutsideClick: false,
              text: `Successfully downloaded`,
              confirmButtonText: "Close",
              showLoaderOnConfirm: true,
                preConfirm: () => {
                 this.DeleteFile();
                },
            })
          }
         }) 
        }, */
        DeleteFile() {
            let name = "taxes_report";
            window.billing
                .get("report/taxes/deletefile/" + window.localuserdata + "/" + name)
                .then((response) => {})
                .catch((error) => console.log(error));
        },
        btnstatus() {
            this.statusdropd = !this.statusdropd;
        },
        checkAll: function () {
            this.isCheckAll = !this.isCheckAll;
            this.statuslist = [];
            if (this.isCheckAll) {
                for (var key in this.statusdata) {
                    this.statuslist.push(this.statusdata[key].value);
                }
            }
        },
        updateCheckall: function () {
            if (this.statuslist.length == this.statusdata.length) {
                this.isCheckAll = true;
            } else {
                this.isCheckAll = false;
            }
        },
    },
    watch: {
        dateFrom: function () {
            this.formatearFecha();
        },
        dateTo: function () {
            this.formatearFecha2();
        },
    },
    computed: {
        total: function () {
            if (!this.InvoicesListR) {
                return 0;
            }
            return this.InvoicesListR.reduce(function (total, value) {
                return total + Number(value.total);
            }, 0);
        },
        trim1: function () {
            if (!this.q01) {
                return 0;
            }
            return this.q01.reduce(function (total1, value) {
                return total1 + Number(value.total1);
            }, 0);
        },
        trim2: function () {
            if (!this.q02) {
                return 0;
            }
            return this.q02.reduce(function (total2, value) {
                return total2 + Number(value.total2);
            }, 0);
        },
        trim3: function () {
            if (!this.q03) {
                return 0;
            }
            return this.q03.reduce(function (total3, value) {
                return total3 + Number(value.total3);
            }, 0);
        },
        trim4: function () {
            if (!this.q04) {
                return 0;
            }
            return this.q04.reduce(function (total4, value) {
                return total4 + Number(value.total4);
            }, 0);
        },
        totalbalance: function () {
            if (!this.InvoicesListR) {
                return 0;
            }
            return this.InvoicesListR.reduce(function (total, value) {
                return total + Number(value.balance);
            }, 0);
        },
    },
};
</script>
<style lang="scss" scoped>
.trim_filter {
    button {
        margin-right: 5px;
    }
}
.dropdStatus {
    position: absolute;
    width: 130px;
    height: auto;
    background-color: #fff;
    z-index: 9;
    margin: 0;
    border: 1px solid #ccc;
    padding: 10px;
}
.dropdStatus li {
    text-align: left;
}
.dropdStatus li label {
    text-align: left;
    display: block;
}
.btn-status {
    font-size: 13px;
    letter-spacing: 0.5px;
    color: #5d5b5b !important;
    border-radius: 0;
    border: 1px solid #dcdcdc;
    background-color: #fff;
    padding: 7px;
}
#invList thead tr th:nth-child(2),
#invList tbody tr td:nth-child(2) {
    min-width: 100px;
    width: 100px;
}
#invList thead tr th:nth-child(3),
#invList tbody tr td:nth-child(3) {
    min-width: 100px;
    width: 100px;
}
#invList thead tr th:nth-child(4),
#invList tbody tr td:nth-child(4) {
    min-width: 200px;
}
#invList thead tr th:nth-child(5),
#invList tbody tr td:nth-child(5) {
    min-width: 180px;
}
.contenedor-table table thead tr th {
    font-weight: bold;
}
.paginacion {
    width: 70px;
}
#invList thead tr th:nth-child(11),
#invList tbody tr td:nth-child(11) {
    min-width: 85px;
    width: 85px;
}
@media (max-width: 1100px) {
    #invList thead tr th:nth-child(1),
    #invList tbody tr td:nth-child(1) {
        min-width: 50px;
    }
    #invList thead tr th:nth-child(2),
    #invList tbody tr td:nth-child(2) {
        min-width: 80px;
        width: 80px;
    }
    #invList thead tr th:nth-child(3),
    #invList tbody tr td:nth-child(3) {
        min-width: 80px;
        width: 80px;
    }
    #invList thead tr th:nth-child(4),
    #invList tbody tr td:nth-child(4) {
        min-width: 150px;
        width: 150px;
    }
    #invList thead tr th:nth-child(5),
    #invList tbody tr td:nth-child(5) {
        min-width: 130px;
        width: 130px;
    }
    #invList thead tr th:nth-child(6),
    #invList tbody tr td:nth-child(6) {
        min-width: 90px;
        width: 90px;
    }
    #invList thead tr th:nth-child(7),
    #invList tbody tr td:nth-child(7) {
        min-width: 70px;
        width: 70px;
    }
    #invList thead tr th:nth-child(8),
    #invList tbody tr td:nth-child(8) {
        min-width: 90px;
        width: 90px;
    }
    #invList thead tr th:nth-child(9),
    #invList tbody tr td:nth-child(9) {
        min-width: 80px;
        width: 80px;
    }
    #invList thead tr th:nth-child(10),
    #invList tbody tr td:nth-child(10) {
        min-width: 50px;
        width: 50px;
    }
    #invList thead tr th:nth-child(11),
    #invList tbody tr td:nth-child(11) {
        min-width: 50px;
        width: 50px;
    }
    #invList tbody tr td:nth-child(11) > .cdropdown > button {
        padding: 15px;
    }
    #invList tbody tr td:nth-child(11) > .cdropdown > button:before {
        right: 11px;
    }
    #invList tbody tr td:nth-child(11) > .cdropdown > button > span {
        display: none;
    }
    .contenedor-table {
        max-width: 924px;
        overflow-x: scroll;
    }
}
@media (min-width: 1280px) {
    .ctrlbtn {
        width:100px !important;
    }
}
</style>
