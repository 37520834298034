<template>
    <div>
        <div v-if="formLoading" class="d-flex align-items-center justify-content-center">
            <Loader></Loader>
        </div>
        <form
            v-else
            autocomplete="off"
            @submit.prevent="return false;"
            :name="form.name"
            :class="'builder ' + form.class"
        >
            <draggable
                v-model="inputs"
                tag="div"
                class="editForm"
                group="description"
                @change="moveInput"
                @start="isDragging = true"
                @end="isDragging = false"
                handle=".handle"
                v-if="inputs.length"
            >
                <div
                    v-for="(i, index) in inputs"
                    :key="index"
                    v-bind:class="[
                        { inputActive: inputActive == index },
                        'form-group ' + i.label_width,
                    ]"
                    style=""
                    @click="edit(i, index)"
                >
                    <div class="handle" style="text-align: center">
                        <i class="fas fa-ellipsis-v"></i>
                    </div>
                    <label
                        :for="i.label_for"
                        :style="
                            'opacity:' +
                            (i.label_display == 1 ? '1' : '0') +
                            ';' +
                            i.label_style
                        "
                    >
                        {{ i.label_name }}
                    </label>

                    <input
                        v-if="i.campo_type == 'text'"
                        type="text"
                        :class="'form-control ' + i.campo_class + ' ' + i.campo_error"
                        :id="i.campo_id"
                        :name="i.campo_name"
                        v-bind:required="i.campo_required ? true : false"
                        :placeholder="i.campo_placeholder"
                        :style="+i.campo_style"
                        :value="i.campo_value"
                        autocomplete="off"
                    />
                    <textarea
                        v-if="i.campo_type == 'textarea'"
                        :class="'form-control ' + i.campo_class"
                        :id="i.campo_id"
                        :name="i.campo_name"
                        v-bind:required="i.campo_required ? true : false"
                        :placeholder="i.campo_placeholder"
                        :style="i.campo_style"
                        v-model="i.campo_value"
                    ></textarea>
                    <select
                        v-if="i.campo_type == 'select'"
                        :class="'form-control ' + i.campo_class"
                        :id="i.campo_id"
                        :name="i.campo_name"
                        :style="i.campo_style"
                    >
                        <option v-for="(o, ondex) in i.options" :key="ondex">
                            {{ o }}
                        </option>
                    </select>
                    <div v-if="i.campo_type == 'radio'">
                        <label
                            class="c-input c-radio"
                            v-for="(o, ondex) in i.options"
                            :key="ondex"
                        >
                            <input
                                type="radio"
                                :id="i.campo_id"
                                :value="o"
                                :name="i.campo_name"
                            />
                            <span class="c-indicator" :style="i.campo_style">
                                {{ o }}</span
                            >
                        </label>
                    </div>
                    <label v-if="i.campo_type == 'checkbox'" style="display: block">
                        <label v-for="(o, ondex) in i.options" :key="ondex">
                            <input
                                type="checkbox"
                                :id="i.campo_id"
                                :value="o"
                                :name="i.campo_name"
                            />
                            <span :style="i.campo_style"> {{ o }}</span>
                        </label>
                    </label>
                    <button
                        type="button"
                        v-if="i.campo_type == 'submit'"
                        :id="i.campo_id"
                        :class="i.campo_class + ' ' + i.campo_error"
                        :style="i.campo_style"
                    >
                        {{ i.campo_value }}
                    </button>
                </div>
            </draggable>
        </form>
        <link rel="stylesheet" :href="urlStyle" />
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import Loader from "@/core/components/shared/Loader.vue";
import draggable from "vuedraggable";
export default {
    name: "asideForm",
    components: {
        Loader,
        draggable,
    },
    data() {
        return {
            formLoading: false,
            inputs: [],
            form: [],
            inputActive: null,
            resf: false,
            styleForm: [],
            designs: [],
            vdate: new Date(),
            vGet: "",
            urlStyle: "",
            customCSS: "",
            styleActive: 0,
            isDragging: false,
        };
    },
    created() {
        this.formLoading = true;
        this.$store.dispatch("ModApps/Marketea/update_formBuilt", this.$route.params.id)
        .then((response) => {
            if(response) {
                this.getForms();   
            } else {
                this.getIndex();
            }
        });
    },
    methods: {
        getForms() {
            if (this.$store.getters["ModApps/Marketea/get_formBuilt"]) {
                let e = this.$store.getters["ModApps/Marketea/get_formBuilt"];
                if (e.status) {
                    this.restore(e);
                } else {
                    // this.getIndex();
                }
            } else {
                this.$notify({
                    group: "noti",
                    title: "ERROR",
                    type: "error",
                    text: "An error has occurred",
                });
            }
        },
        getIndex() {
            this.formLoading = true;
            window.master
                .get("getindex")
                .then((response) => {
                    this.form = response.data.result;
                    this.inputs = JSON.parse(this.form.inputs);
                    this.vGet = this.vdate.getTime();
                    this.urlStyle = response.data.url + this.vGet;
                    localStorage.setItem("restore", JSON.stringify(this.inputs));
                    this.formLoading = false;
                })
                .catch((error) => {
                    this.formLoading = false;
                    console.log(error);
                    this.$notify({
                        group: "noti",
                        title: "ERROR",
                        type: "error",
                        text: "An error has occurred",
                    });
                });
        },
        restore(e) {
            this.form = e.result;
            if (this.form.inputs != null) {
                this.inputs = JSON.parse(this.form.inputs);
            } else {
                this.inputs = [];
            }
            this.vGet = this.vdate.getTime();
            this.urlStyle = e.url + this.vGet;
            localStorage.setItem("restore", JSON.stringify(this.inputs));
            if (e.s.custom_code != null) {
                this.customCSS = e.s.custom_code;
            } else {
                this.customCSS = "";
            }
            this.styleForm = e.s;
            this.formLoading = false;
            this.setDesigns();
        },
        setDesigns() {
            let btn_bg_color = this.styleForm.btn_bg_color;
            let btn_color = this.styleForm.btn_color;
            let btn_size = this.styleForm.btn_size;
            let btn_weight = this.styleForm.btn_weight;
            let input_place_weight = this.styleForm.input_place_weight;
            let input_place_size = this.styleForm.input_place_size;
            let input_size = this.styleForm.input_size;
            let input_weight = this.styleForm.input_weight;
            let label_size = this.styleForm.label_size;
            let label_weight = this.styleForm.label_weight;
            let id = this.styleForm.id;
            let input_border_color = this.styleForm.input_border_color;
            let input_color = this.styleForm.input_color;
            let input_place_color = this.styleForm.input_place_color;
            let label_color = this.styleForm.label_color;
            this.designs = [];
            this.designs.push({
                btn_bg_color: btn_bg_color,
                btn_color: btn_color,
                btn_size: btn_size,
                btn_weight: btn_weight,
                id: id,
                input_border_color: input_border_color,
                input_color: input_color,
                input_place_color: input_place_color,
                input_place_size: input_place_size,
                input_place_weight: input_place_weight,
                input_size: input_size,
                input_weight: input_weight,
                label_color: label_color,
                label_size: label_size,
                label_weight: label_weight,
                style:
                    "form.builder label{display:inline-block;margin-bottom:0.1rem;color:{{label_color}};font-size:{{label_size}}px;font-weight:{{label_weight}};}form.builder .form-control{display:block;width:100%;padding:0.375rem 0.75rem;font-size:{{input_size}}px;color:{{input_color}};font-weight:{{input_weight}};border:1px solid {{input_border_color}};line-height:1.5;background-color:#fff;background-clip:padding-box;border-radius:2px;margin-bottom:0.5rem;-webkit-transition:border-color 0.15 sease-in-out,-webkit-box-shadow 0.15 sease-in-out;transition:border-color 0.15 sease-in-out,-webkit-box-shadow 0.15 sease-in-out;transition:border-color 0.15 sease-in-out,box-shadow 0.15 sease-in-out;transition:border-color 0.15 sease-in-out,box-shadow 0.15 sease-in-out,-webkit-box-shadow 0.15 sease-in-out;}form.builder .form-control::placeholder{color:{{input_place_color}};font-size:{{input_place_size}}px;font-weigth:{{input_place_weight}};opacity:1;}form.builder .form-control:-ms-input-placeholder{color:{{input_place_color}};font-size:{{input_place_size}}px;font-weigth:{{input_place_weight}};}form.builder .form-control::-ms-input-placeholder{color:{{input_place_color}};font-size:{{input_place_size}}px;font-weigth:{{input_place_weight}};}form.builder button{padding:10px 20px;font-size:{{btn_size}}px;color:{{btn_color}};font-weight:{{btn_weight}};background-color:{{btn_bg_color}};border-width:1px;border-style:solid;border-color:{{btn_bg_color}};box-shadow:none;-webkit-box-shadow:none;}form.builder button:hover{padding:10px 20px;font-size:{{btn_size}}px;color:{{btn_color}};font-weight:{{btn_weight}};background-color:{{btn_bg_color}};border-width:1px;border-style:solid;border-color:{{btn_bg_color}};box-shadow:none;-webkit-box-shadow:none;}",
            });
            localStorage.setItem("restore_btn_styleForm", JSON.stringify(this.designs));
        },
        moveInput() {
            let g = this.$store.getters["ModApps/Marketea/get_editorStorage"];
            let c = g.length;
            if (c > 0) {
                this.form = g[0].form;
            }
            let k = this.$store.getters["ModApps/Marketea/get_desingStorage"];
            let co = k.length;
            if (co > 0) {
                this.designs = k[0].designs[0];
            }
            let o = this.$store.getters["ModApps/Marketea/get_customCssStorage"];
            let cot = o.length;
            if (cot > 0) {
                this.customCSS = o[0].customCSS;
            }
            let a = this.$store.getters["ModApps/Marketea/get_asideStorage"];
            let cota = a.length;
            if (cota > 0) {
                this.inputs = a[0].inputs;
            }
            let data = [];
            data.push({
                inputs: this.inputs,
                designs: this.designs,
                custom_code: this.customCSS,
                form: this.form,
            });
            this.arrays("moveInput", data);
            this.inputs = [];
            this.getForms();
        },
        edit(i, index) {
            this.inputActive = index;
            let data = [];
            data.push({
                i: i,
                index: index,
                inputs: this.inputs,
                form: this.form,
                designs: this.designs,
                custom_code: this.customCSS,
            });
            this.arrays("edit", data);
        },
        createInput(t) {
            let label = "";
            let type = "";
            let name = "";
            let options = [];
            let obj = JSON.parse(localStorage.getItem("restore"));
            let formatInput = {
                label_width: "c1-2",
                label_display: 1,
                label_id: "",
                label_class: "",
                label_style: "",
                label_name: "",
                label_for: "",
                campo_name: "",
                campo_id: "",
                campo_error: "",
                campo_type: "",
                options: "",
                campo_class: "",
                campo_required: 0,
                campo_height: "auto",
                campo_placeholder: "",
                campo_value: "",
                campo_style: "",
            };
            switch (t) {
                case 1:
                    type = "text";
                    label = "Input Text";
                    break;
                case 2:
                    type = "textarea";
                    label = "Comments";
                    break;
                case 3:
                    type = "select";
                    label = "Dropdown";
                    options = ["Item 1", "Item 2", "Item 3", "Item 4"];
                    break;
                case 4:
                    type = "radio";
                    label = "Decision";
                    options = ["Yes", "No"];
                    break;
                case 5:
                    type = "checkbox";
                    label = "Multiple choice";
                    options = ["Menu 1", "Menu 2"];
                    break;
                default:
                    type = "text";
                    label = "Input Text";
                    break;
            }
            formatInput.label_name = label;
            formatInput.campo_name = type;
            formatInput.campo_type = type;
            formatInput.options = options;
            // console.log('New ',formatInput);
            let add = this.addInput;
            this.addInput = [];
            this.addInput.push(formatInput);
            for (let i in add) {
                this.addInput.push(add[i]);
            }
            // console.log(this.addInput);
            this.inputs = [];
            for (let j in this.addInput) {
                this.inputs.push(this.addInput[j]);
                // console.log('add', this.addInput[j]);
            }
            for (let k in obj) {
                this.inputs.push(obj[k]);
                // console.log('antiguos', obj[k]);
            }
            this.editinput = this.inputs[0];
            this.inputActive = 0;
            this.editarCampo = true;
            this.formConfig = false;
            this.restoreInput = true;
            let data = [];
            data.push({
                i: this.inputs[0],
                index: 0,
                inputs: this.inputs,
                form: this.form,
                designs: this.designs,
                custom_code: this.customCSS,
            });
            this.arrays("RcreateInput", data);
        },
        arrays(name, data) {
            let k = [];
            k.push({ function: name, data: data });
            this.$store.commit("ModApps/Marketea/set_builtForm", k);
        },
    },
    watch: {
        get_builtForm: function () {
            if (this.get_builtForm) {
                let g = this.$store.getters["ModApps/Marketea/get_builtForm"];
                let fun = g[0].function;
                if (fun == "getForms") {
                    let r = g[0].data[0];
                    this.formLoading = r.formLoading;
                    this.resf = r.resf;
                    this.getForms();
                }
                if (fun == "createInput") {
                    this.createInput(g[0].data);
                }
                if (fun == "delInput") {
                    this.inputs = g[0].data;
                }
            }
        },
    },
    computed: {
        ...mapGetters("ModApps/Marketea", ["get_builtForm", "get_formBuilt"]),
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
            };
        },
    },
};
</script>
<style lang="scss" scoped>
.editForm label.c-input.c-radio {
    display: block;
}
label.c-input.c-radio {
    padding: 0px 10px;
}
.editForm .form-group {
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    border: 1px dashed #fff;
    border-left: 0px;
    min-height: 68px;
}
.editForm .form-group:hover,
.editForm .form-group.inputActive {
    border-color: #8e8383;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: whitesmoke;
}
.editForm .form-group.inputActive .handle {
    display: block;
}
.editForm .form-group:hover .handle {
    display: block;
}
.handle {
    position: absolute;
    top: -3px;
    left: 0px;
    width: 15px;
    height: 108%;
    z-index: 1;
    padding: 20px 0px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: rgba(215, 239, 253, 0.7098039215686275);
    color: #8e8383;
    display: none;
    cursor: move;
}
.c1-1 {
    width: 100%;
    float: left;
}
.c1-2 {
    width: 50%;
    float: left;
}
.c1-3 {
    width: 33.333%;
    float: left;
}
.c1-4 {
    width: 25%;
    float: left;
}
@media (max-width: 767px) {
    .c1-2 {
        width: 100%;
    }
    .c1-3 {
        width: 100%;
    }
    .c3-4 {
        width: 100%;
    }
}
</style>
