<template>
    <div class="card ">
        <div class="card-header">
            <h3>Categories</h3>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <form @submit.prevent="saveCurrency">
                    <table class="table table-striped mb-0">
                        <thead>
                            <tr>
                                <td style="width:120px;">Edit Currency:</td>
                                <td>
                                    <div class="input-group">
                                        <select v-model="currency" class="form-control">
                                            <option value="$">$ USD</option>
                                            <option value="S./">S./ Suns PEN</option>
                                        </select>
                                    </div>
                                </td>
                                <td style="width: 90px;">
                                    <button class="btn btn-success btn-md">
                                        Save
                                    </button>
                                </td>
                            </tr>
                        </thead>
                    </table>
                </form>
                <form @submit.prevent="addItem">
                    <table class="table table-striped mb-0">
                        <thead>
                            <tr>
                                <td style="width:120px;">Add new:</td>
                                <td>
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="Category name" v-model="nomItem" required/>
                                    </div>
                                </td>
                                <td style="width: 90px;">
                                    <button class="btn btn-success btn-md">
                                        Save
                                    </button>
                                </td>
                            </tr>
                        </thead>
                    </table>
                </form>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th valign="middle">Name</th>
                            <th valign="middle" class="text-center">Products</th>
                            <th valign="middle" class="text-center">Status</th>
                            <th valign="middle" class="text-right">
                            </th>
                        </tr>
                    </thead>
                    <tbody v-if="NoRecord">
                        <tr>
                            <td colspan="5" class="text-center">No record</td>
                        </tr>
                    </tbody>
                    <draggable v-else v-model="listMain" tag="tbody" class="tbody" v-bind="dragOptions"
                        @change="checkMove" @start="isDragging = true" @end="isDragging = false" handle=".handle">
                        <tr v-for="(i, index) in listMain" :key="index">
                            <td scope="row" style="position: relative">
                                <div class="btnmove handle">
                                    <i class="fas fa-ellipsis-v"></i>
                                </div>
                                <span style="margin-left: 15px">
                                    <input type="text" class="item-edit" placeholder="Name" v-model="i.name_cat"
                                        @change="editItem(i)">
                                </span>
                            </td>
                            <td width="50px" class="text-center">
                                <span class="badge badge-primary" style="padding:5px 8px;">{{ i.foods.length }}</span>
                            </td>
                            <td>
                                <div class="onoffswitch">
                                    <input type="checkbox" class="onoffswitch-checkbox" :id="i.id" :checked="i.checked"
                                        @change="changeStatus(i)" />
                                    <label class="onoffswitch-label" :for="i.id">
                                        <span class="onoffswitch-inner"></span>
                                        <span class="onoffswitch-switch"></span>
                                    </label>
                                </div>
                            </td>
                            <td class="text-right">
                                <button class="btn btn-danger btn-sm" @click="deleteRow(i, index)" v-bind:style="(i.foods.length>0) ? 'opacity: 0.65' : ''">
                                    <i class="fas fa-times"></i>
                                </button>
                            </td>
                        </tr>
                    </draggable>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'
export default {
    components: {
        draggable,
    },
    data() {
        return {
            listMain: [],
            NoRecord: false,
            nomItem: '',
            apis: null,
            currency: null,
        }
    },
    created() {
        let urlApis = this.$store.getters['ModApps/Marketea/urlApis'];
        this.apis = urlApis.bMenu;
        this.currency = this.$store.getters['ModApps/Marketea/get_bmenu_currency']
        this.modelarStatus(this.$store.getters['ModApps/Marketea/get_bmenu_listCat']);
    },
    methods: {
        init() {
            let t = this;
            // let d = t.$store.getters['core/getOpenModal'].data;
            t.$store.commit("core/setLoadingModal", true);
            window.master.post(t.apis.getItemComun(), {
                org_id: window.localorgdata,
            })
            .then((response) => {
                // console.log(response.data);
                let list = [];
                list = response.data.r;
                t.modelarStatus(list);
                t.$store.commit("core/setLoadingModal", false);
            })
            .catch((error) => {
                console.log(error);
                t.$store.commit("core/setLoadingModal", false);
            });
        },
        modelarStatus(list) {
            let t = this;
            for (let j in list) {
                if (list[j]) {
                    list[j].checked = (list[j].status > 0);
                }
            }
            t.listMain = list;
        },
        saveCurrency() {
            let t = this;
            let d = t.$store.getters['core/getOpenModal'].data;
            let urlService = t.apis.set(9)// setCurrency
            if (t.currency.length > 0) {
                t.$store.commit("core/setLoadingModal", true);
                window.master.post(urlService, {
                    user_id: window.master_client,
                    org_id: window.localorgdata,
                    currency: t.currency,
                    pusher_id: pusher.connection.socket_id,
                })
                .then((response) => {
                    t.$store.commit("core/setLoadingModal", false);
                    if (response.data.status && response.data.get.meta_value) {
                        t.$store.commit('ModApps/Marketea/set_bmenu_currency', response.data.get.meta_value);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    t.$store.commit("core/setLoadingModal", false);
                    t.$notify({
                        group: "noti",
                        type: "error",
                        title: "Error",
                        text: "Could not be saved!",
                    });
                });
            }
        },
        addItem() {
            let t = this;
            let d = t.$store.getters['core/getOpenModal'].data;
            let urlService = t.apis.set(0)// setCat
            if (t.nomItem.length > 0) {
                // t.$store.commit("core/setLoadingModal", true);
                window.master.post(urlService, {
                    user_id: window.master_client,
                    org_id: window.localorgdata,
                    name_cat: t.nomItem,
                    pusher_id: pusher.connection.socket_id,
                })
                .then((response) => {
                    if (response.data.status) {}
                })
                .catch((error) => {
                    console.log(error);
                    // t.$store.commit("core/setLoadingModal", false);
                    t.$notify({
                        group: "noti",
                        type: "error",
                        title: "Error",
                        text: "Could not be saved!",
                    });
                });
            }
        },
        deleteRow(i, indexVal) {
            let t = this;
            if(i.foods.length>0){
                t.$notify({
                    group: "noti",
                    type: "error",
                    title: "Error",
                    text: "To remove it must be without products",
                });
            }else{
                t.$swal({
                    title: "Delete Item?",
                    text: "Are you sure?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Yes, Delete it!",
                }).then((result) => {
                    if (result.value) {
                        console.log('delete item', i.name_cat)
                        if(t.getCatActive.id==t.listMain[indexVal].id){
                            let newActive = ((indexVal>0) ? (indexVal-1) : (indexVal+1));
                            t.$store.commit('ModApps/Marketea/set_bMenuValStorage', {key: 'bmenu_catActive', val: t.listMain[newActive]});
                        }
                        t.$store.commit('ModApps/Marketea/set_bMenuDelCatItem', indexVal);
                        t.deleteItem(i, indexVal);
                    }
                });
            }
        },
        deleteItem(i, indexVal) {
            let t = this;
            if (i.id > 0) {
                let urlService = t.apis.set(2)// deleteCat
                window.master.post(urlService, i)
                .then((response) => {
                    console.log(urlService, response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
            }
        },
        editItem(i) {
            let t = this;
            if (i.name_cat.length > 0) {
                let urlService = t.apis.set(0)// setCat
                window.master.post(urlService, i)
                .then((response) => {
                    console.log(response.data);
                    for (let j in t.listMain) {
                        if (t.listMain[j].id === i.id) {
                            t.listMain[j] = i;
                            console.log('item', t.listMain[j])
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    t.$notify({
                        group: "noti",
                        type: "error",
                        title: "Error",
                        text: "Could not be saved!",
                    });
                });
            }
        },
        changeStatus(i) {
            let t = this;
            if (i.checked) {
                i.status = 0;
                i.checked = false;
            } else {
                i.status = 1;
                i.checked = true;
            }
            let urlService = t.apis.set(0)// setCat
            window.master.post(urlService, i)
            .then((response) => {
                console.log(response.data);
                for (let j in t.listMain) {
                    if (t.listMain[j].id === i.id) {
                        t.listMain[j] = i;
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                t.$notify({
                    group: "noti",
                    type: "error",
                    title: "Error",
                    text: "Could not be saved!",
                });
            });
        },
        checkMove() {
            let t = this;
            let d = t.$store.getters['core/getOpenModal'].data;
            t.listMain.map((item, index) => {
                item.orden = index;
            });
            t.$store.commit('ModApps/Marketea/set_bMenuValStorage', {key: 'bmenu_listCat', val: t.listMain});
            let urlService = t.apis.set(1)// setCatOrden
            window.master.post(urlService, {
                ordenCats: t.listMain,
            })
            .then((response) => {
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
                t.$notify({
                    group: "noti",
                    type: "error",
                    title: "Error",
                    text: "Could not be saved!",
                });
            });
        },
    },
    computed: {
        ...mapGetters("ModApps/Marketea/", {
                getListCat: "get_bmenu_listCat",
                getCatActive: "get_bmenu_catActive",
            }),
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
            };
        },
    },
    watch:{
        getListCat: function(){
            console.log('watchers', this.getListCat)
            this.modelarStatus(this.getListCat);
        }
    }
}
</script>
<style lang="scss" scoped>
    .btnmove {
        position: absolute;
        left: 0px !important;
        top: 0px !important;
        width: 13px;
        height: 100%;
        cursor: pointer;
        background-color: #e1f2fb;
        z-index: 10;
        color: #afafaf;
        font-size: 12px;
        line-height: 75px;
        text-align: center;
        vertical-align: middle;
        font-weight: normal;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            background-color: #e0e0e0;
            cursor: move;
        }
    }
    .onoffswitch {
        position: relative;
        width: 60px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
    }
    .onoffswitch-checkbox {
        display: none;
    }
    .onoffswitch-label {
        display: block;
        overflow: hidden;
        cursor: pointer;
        border: 1px solid #057121;
        border-radius: 20px;
    }
    .onoffswitch-inner {
        display: block;
        width: 200%;
        margin-left: -100%;
        transition: margin 0.1s ease-in 0s;
    }
    .onoffswitch-inner:before,
    .onoffswitch-inner:after {
        display: block;
        float: left;
        width: 50%;
        height: 20px;
        padding: 0;
        line-height: 20px;
        font-size: 12px;
        color: white;
        font-family: Trebuchet, Arial, sans-serif;
        font-weight: bold;
        box-sizing: border-box;
    }
    .onoffswitch-inner:before {
        content: "On";
        padding-right: 10px;
        padding-left: 5px;
        background-color: #04b653;
        color: #ffffff;
    }
    .onoffswitch-inner:after {
        content: "Off";
        padding-right: 10px;
        background-color: #eeeeee;
        color: #222;
        text-align: right;
    }
    .onoffswitch-switch {
        display: block;
        width: 15px;
        height: 15px;
        margin: 6px;
        background: #ffffff;
        position: absolute;
        top: -3px;
        bottom: 0;
        right: 33px;
        border: 1px solid #057121;
        border-radius: 20px;
        transition: all 0.1s ease-in 0s;
    }
    .onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner {
        margin-left: 0;
    }
    .onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch {
        right: 0px;
    }
    .item-edit {
        width: calc(100% - 15px);
        border: 0px solid transparent;
        box-shadow: none;
        background-color: transparent;
    }
</style>
