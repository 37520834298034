<template>
    <div>
        <slot>
            <div class="page-breadcrumb container">
                <div class="row">
                    <div class="col-md-8 align-self-center">
                        <h4 class="page-title">Setting Backgrounds</h4>
                    </div>
                    <div class="col-md-4 align-self-center">
                        <div class="d-flex align-items-right justify-content-end">
                            <router-link :to="{ name: 'Setting' }" class="btn btn-info btn-sm mr-2  ctrlbtn" style="margin-bottom: 10px;width: -webkit-fill-available;">
                                <i class="fas fa-arrow-left"></i>
                                Back
                            </router-link>  
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row" style="margin-top: 20px; margin-bottom: 80px">
                    <div class="col-md-12">
                        <div class="card">
                            <form
                                @submit.prevent="saveBgPDF"
                                enctype="multipart/form-data"
                            >
                                <div class="card-header alert alert-info text-dark">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <h3>Backgrounds for Invoice & Estimates</h3>
                                        </div>
                                        <div class="col-md-6 text-right">
                                            <div class="row">
                                                <div class="col-md-4 text-right">
                                                    <button
                                                        v-if="!freePlan"
                                                        type="submit"
                                                        class="btn btn-success ctrlbtn"
                                                        style="margin-bottom: 10px;width: -webkit-fill-available;"
                                                        :disabled="btnSave"
                                                    >
                                                        <i class="fas fa-save"></i> SAVE
                                                    </button>
                                                    <button
                                                        v-else
                                                        type="button"
                                                        class="btn btn-success ctrlbtn"
                                                        style="margin-bottom: 10px;width: -webkit-fill-available;"
                                                        @click="llamarPopPremium()"
                                                    >
                                                        <i class="fas fa-save"></i> SAVE
                                                    </button>
                                                </div>
                                                <div class="col-md-4 text-right">
                                                    <button
                                                        type="button"
                                                        class="btn btn-secundary ctrlbtn"
                                                        style="margin-bottom: 10px;width: -webkit-fill-available;"
                                                        @click="backStandard"
                                                    >
                                                        LAST SAVED
                                                    </button>
                                                </div>  
                                                <div class="col-md-4 text-right">
                                                    <button
                                                        type="button"
                                                        class="btn btn-secundary ctrlbtn"
                                                        style="margin-bottom: 10px;width: -webkit-fill-available; min-width: 150px !important;"
                                                        @click="resetDefault"
                                                    >
                                                        RESET BY DEFAULT
                                                    </button>
                                                </div>                                                                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <Loader v-if="isLoading"></Loader>
                                    <div class="row" v-else>
                                        <div
                                            class="col-md-12"
                                            style="margin-bottom: 20px"
                                        >
                                            <label
                                                class="c-input c-radio"
                                                style="margin-right: 20px"
                                            >
                                                <input
                                                    name="background"
                                                    v-model="tipo_fondo"
                                                    value="1"
                                                    type="radio"
                                                />
                                                <span
                                                    class="c-indicator btn btn-secondary btn-lg"
                                                    v-bind:class="{ disabled: custom }"
                                                >
                                                    Standard
                                                    <i
                                                        class="fa fa-check"
                                                        v-if="standard"
                                                    ></i>
                                                    <i class="fas fa-ban" v-else></i>
                                                </span>
                                            </label>
                                            <label class="c-input c-radio">
                                                <input
                                                    name="background"
                                                    v-model="tipo_fondo"
                                                    value="2"
                                                    type="radio"
                                                />
                                                <span
                                                    class="c-indicator btn btn-secondary btn-lg"
                                                    v-bind:class="{ disabled: standard }"
                                                >
                                                    Custom
                                                    <i
                                                        class="fa fa-check"
                                                        v-if="custom"
                                                    ></i>
                                                    <i class="fas fa-ban" v-else></i>
                                                </span>
                                            </label>
                                        </div>
                                        <div
                                            class="col-md-8 columns-bg-1"
                                            v-if="standard"
                                        >
                                            <div class="row">
                                                <h4>Solid colored backgrounds</h4>
                                            </div>
                                            <div class="row">
                                                <div class="bgpdf bgcolorSolid">
                                                    <input
                                                        type="radio"
                                                        v-model="formIvo.type"
                                                        value="0"
                                                        id="bg0"
                                                        @change="waterMark(0)"
                                                    />
                                                    <label
                                                        for="bg0"
                                                        @click="bgNull"
                                                    ></label>
                                                    <span>None</span>
                                                </div>
                                                <div
                                                    class="bgpdf bg-d1 bgcolorSolid"
                                                    :style="cssDegraded"
                                                >
                                                    <input
                                                        type="radio"
                                                        v-model="formIvo.type"
                                                        value="1"
                                                        id="bg1"
                                                        @change="waterMark(0)"
                                                    />
                                                    <label
                                                        for="bg1"
                                                        @click="bgNull"
                                                    ></label>
                                                    <span>Degraded</span>
                                                </div>
                                                <div
                                                    class="bgpdf bgcolorSolid"
                                                    v-if="Showlogo"
                                                >
                                                    <input
                                                        type="radio"
                                                        v-model="formIvo.type"
                                                        value="2"
                                                        id="bg2"
                                                        @change="waterMark(1)"
                                                    />
                                                    <label
                                                        for="bg2"
                                                        @click="bgNull"
                                                    ></label>
                                                    <span>Watermark</span>
                                                    <span
                                                        class="marca-agua"
                                                        :style="cssMagua"
                                                    ></span>
                                                </div>
                                                <div
                                                    class="bgpdf bg-d1 bgcolorSolid"
                                                    v-if="Showlogo"
                                                    :style="cssDegraded"
                                                >
                                                    <input
                                                        type="radio"
                                                        v-model="formIvo.type"
                                                        value="3"
                                                        id="bg3"
                                                        @change="waterMark(1)"
                                                    />
                                                    <label
                                                        for="bg3"
                                                        @click="bgNull"
                                                    ></label>
                                                    <span
                                                        >Degraded<br />&<br />Watermark</span
                                                    >
                                                    <span
                                                        class="marca-agua"
                                                        :style="cssMagua"
                                                    ></span>
                                                </div>
                                            </div>
                                            <div v-if="listFondos.length > 0" class="row">
                                                <h4>Image backgrounds</h4>
                                            </div>
                                            <div
                                                v-for="i in listFondos"
                                                :key="i.id"
                                                class="row"
                                            >
                                                <div v-if="listFondos.length > 0">
                                                    <div class="bgpdf" :style="i.style">
                                                        <input
                                                            type="radio"
                                                            v-model="bg"
                                                            :value="i.val + '-b'"
                                                            :id="i.val + '-b'"
                                                            @change="waterMark(0)"
                                                        />
                                                        <label
                                                            :for="i.val + '-b'"
                                                        ></label>
                                                        <span>Image {{ i.name }}</span>
                                                    </div>
                                                    <div
                                                        class="bgpdf"
                                                        v-if="Showlogo"
                                                        :style="i.style"
                                                    >
                                                        <input
                                                            type="radio"
                                                            v-model="bg"
                                                            :value="i.val + '-w'"
                                                            :id="i.val + '-w'"
                                                            @change="waterMark(1)"
                                                        />
                                                        <label
                                                            :for="i.val + '-w'"
                                                        ></label>
                                                        <span
                                                            >Image {{ i.name
                                                            }}<br />&<br />Watermark</span
                                                        >
                                                        <span
                                                            class="marca-agua"
                                                            :style="cssMagua"
                                                        ></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="col-md-4 columns-bg-2"
                                            v-if="standard"
                                        >
                                            <img
                                                :src="logo"
                                                alt="logo"
                                                v-if="Showlogo"
                                                class="previewLogo"
                                            />
                                            <div class="alert alert-warning" v-else>
                                                <strong>Empty</strong> Do not load a logo
                                                yet,
                                                <router-link
                                                    :to="{ name: 'SettingCompanyInfo' }"
                                                >
                                                    click here to load</router-link
                                                >
                                            </div>
                                            <h3 style="font-size: 18px">Controls</h3>
                                            <div class="row">
                                                <div class="form-group col-md-12">
                                                    <label class="labelColor1"
                                                        >Top color</label
                                                    >
                                                    <input
                                                        type="text"
                                                        class="form-control color1"
                                                        v-model="color1"
                                                        disabled
                                                        @click="picker1"
                                                    />
                                                    <button
                                                        type="button"
                                                        class="btn btn-light btn-xs btn-color1 muestra1"
                                                        @click="picker1"
                                                        v-if="showPicker1 == false"
                                                        :style="muestra1"
                                                    ></button>
                                                    <button
                                                        type="button"
                                                        class="btn btn-success btn-xs btn-color1"
                                                        @click="picker1"
                                                        v-else
                                                    >
                                                        Ok
                                                    </button>
                                                    <button
                                                        type="button"
                                                        class="btn btn-danger btn-xs btn-color1"
                                                        @click="oldPicker1"
                                                        v-if="showPicker1"
                                                    >
                                                        X
                                                    </button>
                                                    <chrome-picker
                                                        v-model="getcolor1"
                                                        v-if="showPicker1"
                                                        class="getcolor"
                                                    ></chrome-picker>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label class="labelColor2"
                                                        >Bottom color</label
                                                    >
                                                    <input
                                                        type="text"
                                                        class="form-control color2"
                                                        v-model="color2"
                                                        disabled
                                                        @click="picker2"
                                                    />
                                                    <button
                                                        type="button"
                                                        class="btn btn-light btn-xs btn-color2 muestra2"
                                                        @click="picker2"
                                                        v-if="showPicker2 == false"
                                                        :style="muestra2"
                                                    ></button>
                                                    <button
                                                        type="button"
                                                        class="btn btn-success btn-xs btn-color2"
                                                        @click="picker2"
                                                        v-else
                                                    >
                                                        Ok
                                                    </button>
                                                    <button
                                                        type="button"
                                                        class="btn btn-danger btn-xs btn-color2"
                                                        @click="oldPicker2"
                                                        v-if="showPicker2"
                                                    >
                                                        X
                                                    </button>
                                                    <chrome-picker
                                                        v-model="getcolor2"
                                                        v-if="showPicker2"
                                                        class="getcolor"
                                                    ></chrome-picker>
                                                </div>
                                                <div class="form-group col-md-4">
                                                    <label for="">Angle</label>
                                                    <input
                                                        type="range"
                                                        v-model="angulo"
                                                        min="45"
                                                        max="360"
                                                        step="45"
                                                        class="form-control"
                                                    />
                                                    <div class="step-ops">
                                                        {{ angulo }}
                                                    </div>
                                                </div>
                                            </div>
                                            <h3 style="font-size: 18px">Watermark</h3>
                                            <div class="row">
                                                <div
                                                    class="form-group col-md-6"
                                                    v-if="Showlogo"
                                                >
                                                    <label for="">Opacity</label>
                                                    <input
                                                        type="range"
                                                        v-model="opacity"
                                                        min="0.05"
                                                        max="1"
                                                        step="0.05"
                                                        class="form-control"
                                                    />
                                                    <div class="step-ops">
                                                        {{ opacity }}
                                                    </div>
                                                </div>
                                                <div
                                                    class="form-group col-md-6"
                                                    v-if="Showlogo"
                                                >
                                                    <label for="">Position</label>
                                                    <input
                                                        type="range"
                                                        v-model="position"
                                                        min="10"
                                                        max="90"
                                                        step="10"
                                                        class="form-control"
                                                    />
                                                    <div class="step-ops">
                                                        {{ position }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12" v-if="custom">
                                            <input
                                                type="file"
                                                style="display: none"
                                                ref="subiBg"
                                                accept="image/jpeg"
                                                @change="obtfileBg"
                                            />
                                            <img
                                                v-if="ShowBg"
                                                v-bind:src="preview"
                                                id="bg-inv"
                                                ref="previewBg"
                                            />
                                            <button
                                                type="button"
                                                class="btn btn-danger btn-del-bg"
                                                v-if="ShowBg"
                                                @click="delBg1"
                                            >
                                                <i class="fa fa-trash"></i> Delete
                                                Background
                                            </button>
                                            <button
                                                type="button"
                                                class="btn btn-dark btn-up-bg btn-lg"
                                                @click="$refs.subiBg.click()"
                                                v-else
                                            >
                                                <i class="fas fa-upload"></i> File browser
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </slot>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader";
import { Chrome } from "vue-color";
export default {
    components: {
        Loader,
        "chrome-picker": Chrome,
    },
    name: "Backgrounds",
    data() {
        return {
            isLoading: false,
            bg: null,
            bg_custom: null,
            fileBg: null,
            Showlogo: false,
            logo: "",
            validBg: false,
            ShowBg: false,
            validBgOld: false,
            bgOld: false,
            preview: "",
            color1: "rgba(189,223,239,0.20)",
            oldColor1: "",
            getcolor1: "rgba(189,223,239,0.20)",
            color2: "rgba(252,19,19,0.07)",
            oldColor2: "rgba(252,19,19,0.07)",
            getcolor2: "rgba(252,19,19,0.07)",
            bgAgua: "none",
            opacity: "0.05",
            position: "60",
            angulo: 45,
            watermark: 0,
            cssDegraded: "",
            cssMagua: "",
            showPicker1: false,
            showPicker2: false,
            muestra1: "",
            standard: true,
            custom: false,
            customNone: "display:none;",
            tipo_fondo: 1,
            valDefault: {
                ty: 0,
                c1: "rgba(189,223,239,0.20)",
                c2: "rgba(252,19,19,0.07)",
                op: "0.05",
                po: "60",
                an: 45,
                wa: 0,
            },
            lastSaved: {
                ty: "",
                c1: "",
                c2: "",
                op: "",
                po: "",
                an: "",
                wa: "",
                bg: "",
                tf: "",
            },
            formIvo: {
                action: "createinvo",
                back_id: null,
                type: 0,
                modulo: "invoice",
                bg: null,
            },
            rutaCustom: "",
            rutaLocal: "",
            listFondos: [],
            btnSave: false,
            freePlan: false,
        };
    },
    created() {
        this.freePlan = (this.$store.getters['core/Org/getPlanId'] == 4);
        console.log('created freePlan', this.freePlan);
        this.getLogo();
        this.cssMagua =
            `
					opacity:` +
            this.opacity +
            `;
					background-position: center ` +
            this.position +
            `%;
					background-image:` +
            this.bgAgua +
            `;`;
        this.muestra1 = "background:" + this.color1 + ";";
        this.muestra2 = "background:" + this.color2 + ";";
        this.getBgs();
    },
    mounted() {},
    methods: {
        llamarPopPremium() {
            this.m = {
                show: true,
                component: 'availablePremium',
                data: {
                    title: 'Orgs',
                    type: 'Orgs',
                    name: 'Orgs',
                    app_id: 0,
                    description: 'Orgs'
                }
            };
            this.$store.commit('core/session/setShowPopPremium', this.m);
        },
        waterMark(val) {
            this.watermark = val;
        },
        bgNull() {
            this.bg = null;
        },
        cssDegradedMain() {
            this.cssDegraded =
                `background: ` +
                this.color2 +
                `; 
					background: -moz-linear-gradient(` +
                this.angulo +
                `deg,  ` +
                this.color2 +
                ` 20%, #ffffff 10%, #ffffff 78.5%, ` +
                this.color1 +
                ` 0%);
					background: -webkit-linear-gradient(` +
                this.angulo +
                `deg,  ` +
                this.color2 +
                ` 20%,#ffffff 10%,#ffffff 78.5%,` +
                this.color1 +
                ` 0%); 
					background: linear-gradient(` +
                this.angulo +
                `deg,  ` +
                this.color2 +
                ` 20%,#ffffff 10%,#ffffff 78.5%,` +
                this.color1 +
                ` 0%); 
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='` +
                this.color2 +
                `', endColorstr='` +
                this.color1 +
                `',GradientType=1 ); `;
        },
        resetDefault() {
            this.formIvo.type = this.valDefault.ty;
            this.color1 = this.valDefault.c1;
            this.color2 = this.valDefault.c2;
            this.opacity = this.valDefault.op;
            this.position = this.valDefault.po;
            this.angulo = this.valDefault.an;
            this.watermark = this.valDefault.wa;
            this.bg = null;
            this.tipo_fondo = 1;
            this.cssDegradedMain();
            this.muestra1 = "background:" + this.color1 + ";";
            this.muestra2 = "background:" + this.color2 + ";";
        },
        getBgs() {
            this.isLoading = true;
            window.billing
                .get(
                    "bg/gebgs/" +
                        window.localuserdata +
                        "/" +
                        localStorage.getItem("folder_user")
                )
                .then((response) => {
                    let r = response.data;
                    if (r.status) {
                        for (let i in r.result) {
                            if (this.formIvo.modulo == r.result[i].modulo) {
                                this.formIvo.action = "updateinvo";

                                this.formIvo.back_id = r.result[i].id;
                                this.formIvo.type = r.result[i].type;
                                this.formIvo.bg = r.result[i].bg;
                                this.color1 = r.result[i].color1;
                                this.color2 = r.result[i].color2;
                                this.opacity = r.result[i].opacity;
                                this.position = r.result[i].position;
                                this.angulo = r.result[i].angle;
                                this.watermark = r.result[i].watermark;
                                this.lastSaved.ty = r.result[i].type;
                                this.lastSaved.c1 = r.result[i].color1;
                                this.lastSaved.c2 = r.result[i].color2;
                                this.lastSaved.op = r.result[i].opacity;
                                this.lastSaved.po = r.result[i].position;
                                this.lastSaved.an = r.result[i].angle;
                                this.lastSaved.wa = r.result[i].watermark;
                                this.lastSaved.bg = this.bg;
                                if (r.result[i].type < 6) {
                                    this.lastSaved.tf = 1;
                                    this.tipo_fondo = 1;
                                } else {
                                    this.lastSaved.tf = 2;
                                    this.tipo_fondo = 2;
                                    this.bgOld = r.result[i].bg_custom;
                                    this.bg_custom = r.result[i].bg_custom;
                                }
                                console.log("valor de BG = ", r.result[i].bg);
                                if (r.result[i].bg != null) {
                                    console.log("watermark =", this.watermark);
                                    if (this.watermark == 1) {
                                        this.bg = r.result[i].bg + "-w";
                                    } else {
                                        this.bg = r.result[i].bg + "-b";
                                    }
                                    console.log("bg =", this.bg);
                                }
                                if (r.result[i].bg_custom != null) {
                                    this.preview = r.dirBack + r.result[i].bg_custom;
                                    this.ShowBg = true;
                                }
                            }
                        }
                    }
                    this.rutaLocal = r.rutaM;
                    if (r.fondos.length > 0) {
                        this.listFondos = [];
                        for (let i in r.fondos) {
                            this.listFondos.push({
                                val: r.fondos[i].fondo,
                                name:
                                    r.fondos[i].name != null && r.fondos[i].name != ""
                                        ? r.fondos[i].name
                                        : "",
                                style:
                                    "background-image: url(" +
                                    this.rutaLocal +
                                    r.fondos[i].fondo +
                                    ");",
                            });
                        }
                    }

                    this.isLoading = false;
                })
                .catch((error) => {
                    this.isLoading = false;
                    console.log(error);
                });
        },
        backStandard() {
            this.formIvo.type = this.lastSaved.ty;
            this.color1 = this.lastSaved.c1;
            this.color2 = this.lastSaved.c2;
            this.opacity = this.lastSaved.op;
            this.position = this.lastSaved.po;
            this.angulo = this.lastSaved.an;
            this.bg = this.lastSaved.bg;
            this.watermark = this.lastSaved.wa;
            this.tipo_fondo = this.lastSaved.tf;
            this.cssDegradedMain();
            this.muestra1 = "background:" + this.color1 + ";";
            this.muestra2 = "background:" + this.color2 + ";";
        },
        picker1() {
            this.showPicker1 = !this.showPicker1;
            if (this.showPicker1) {
                this.oldColor1 = this.color1;
            }
        },
        picker2() {
            this.showPicker2 = !this.showPicker2;
            if (this.showPicker2) {
                this.oldColor2 = this.color2;
            }
        },
        oldPicker1() {
            this.showPicker1 = false;
            this.color1 = this.oldColor1;
            this.getcolor1 = this.oldColor1;
            this.cssDegradedMain();
            this.muestra1 = "background:" + this.color1 + ";";
        },
        oldPicker2() {
            this.showPicker2 = false;
            this.getcolor2 = this.oldColor2;
            this.color2 = this.oldColor2;
            this.cssDegradedMain();
            this.muestra1 = "background:" + this.color1 + ";";
        },
        getLogo() {
            let t= this;
            window.master
                .get("bg/logo/" + window.localuserdata + "/" + +window.master_client)
                .then((response) => {
                    console.log(response.data);
                    t.Showlogo = response.data.Showlogo;
                    t.logo = response.data.logo;
                    t.bgAgua = "url";
                    t.bgAgua += "(";
                    t.bgAgua += response.data.logo;
                    t.bgAgua += ")";
                    console.log("bg/logo/", t.bgAgua);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        obtfileBg(event) {
            let file = "";
            if (this.fileBg != null) {
                file = this.fileBg;
            } else if (event != undefined) {
                file = event.target.files[0];
            }
            if (file != "") {
                this.validBg = true;
                this.ShowBg = true;
                this.fileBg = file;
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function (e) {
                    document.querySelector("#bg-inv").src = e.target.result;
                };
            }
        },
        delBg1() {
            this.fileBg = null;
            this.validBg = false;
            this.validBgOld = true;
            this.ShowBg = false;
            this.preview = "";
            this.$refs.subiBg.value = "";
        },
        saveBgPDF() {
            this.btnSave = true;
            this.isLoading = true;
            if (this.formIvo.type < 5) {
                this.formIvo.bg = null;
            }
            if (this.formIvo.type == 6) {
                this.bg = null;
            }
            this.lastSaved.c1 = this.color1;
            this.lastSaved.c2 = this.color2;
            this.lastSaved.op = this.opacity;
            this.lastSaved.po = this.position;
            this.lastSaved.an = this.angulo;
            this.lastSaved.wa = this.watermark;
            this.lastSaved.bg = this.bg;
            this.lastSaved.tf = this.tipo_fondo;

            this.saveBgCustom();
        },
        saveBgCustom() {
            let f = new FormData();
            f.append("folder_user", localStorage.getItem("folder_user"));
            f.append("org_id", window.localuserdata);
            f.append("type", this.formIvo.type);
            f.append("modulo", this.formIvo.modulo);
            f.append("validBg", this.validBg);
            f.append("bgOld", this.bgOld);
            f.append("validBgOld", this.validBgOld);
            if (this.formIvo.type == 6 && this.validBg) {
                f.append("bg_custom", this.fileBg, this.fileBg.name);

                window.master
                    .post("savebgimg", f)
                    .then((response) => {
                        if (response.data.status) {
                            this.bg_custom = response.data.bg_custom;
                            this.saveDataBg();
                        } else {
                            this.$notify({
                                group: "noti",
                                title: "Background",
                                type: "error",
                                text: "Error loading custom",
                            });
                            this.isLoading = false;
                        }
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        console.log(error);
                        this.$notify({
                            group: "noti",
                            title: "Background",
                            type: "error",
                            text: "Error loading custom",
                        });
                    });
            } else if (this.validBgOld) {
                console.log("eliminando logo");
                window.master
                    .post("deletebgimg", f)
                    .then((response) => {
                        if (response.data.status) {
                            this.bg_custom = null;
                            this.formIvo.type = 0;
                            this.saveDataBg();
                        } else {
                            this.$notify({
                                group: "noti",
                                title: "Background",
                                type: "error",
                                text: "Error delete custom",
                            });
                            this.isLoading = false;
                        }
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        console.log(error);
                        this.$notify({
                            group: "noti",
                            title: "Background",
                            type: "error",
                            text: "Error loading custom",
                        });
                    });
            } else {
                console.log("solo guardar data");
                this.saveDataBg();
            }
        },
        saveDataBg() {
            let f = new FormData();
            f.append("folder_user", localStorage.getItem("folder_user"));
            f.append("user_id", window.localuserdata);
            f.append("type", this.formIvo.type);
            f.append("modulo", this.formIvo.modulo);
            f.append("validBg", this.validBg);
            if (this.formIvo.type == 6) {
                f.append("bg", null);
            } else {
                f.append("bg", this.formIvo.bg);
            }
            f.append("bg_custom", this.bg_custom);
            f.append("color1", this.color1);
            f.append("color2", this.color2);
            f.append("opacity", this.opacity);
            f.append("position", this.position);
            f.append("angle", this.angulo);
            f.append("watermark", this.watermark);

            let url = "createinvo";
            if (this.formIvo.action != "createinvo") {
                url = "updateinvo";
                f.append("back_id", this.formIvo.back_id);
            }
            window.billing
                .post("bg/" + url, f)
                .then((response) => {
                    this.$notify({
                        group: "noti",
                        title: "Background",
                        type: "success",
                        text: "Saved successfully",
                    });

                    this.isLoading = false;
                    this.btnSave = false;
                    this.getBgs();
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.btnSave = false;
                    console.log(error);
                    this.$notify({
                        group: "noti",
                        title: "Background",
                        type: "error",
                        text: "Error save",
                    });
                });
        },
    },
    watch: {
        tipo_fondo: function () {
            if (this.tipo_fondo == 1) {
                this.standard = true;
                this.custom = false;
                this.customNone = "display:none;";
                if (this.bg != null && this.lastSaved.ty != 6) {
                    this.backStandard();
                } else {
                    this.formIvo.type = this.lastSaved.ty == 6 ? 0 : this.lastSaved.ty;
                }
            } else {
                this.standard = false;
                this.custom = true;
                this.customNone = "display:block;";
                this.formIvo.type = 6;
                this.obtfileBg();
            }
        },
        getcolor1: function () {
            if (typeof this.getcolor1 == "object") {
                let c = this.getcolor1.rgba;
                this.color1 = "rgba(" + c.r + "," + c.g + "," + c.b + "," + c.a + ")";
                this.cssDegradedMain();
                this.muestra1 = "background:" + this.color1 + ";";
            }
        },
        getcolor2: function () {
            if (typeof this.getcolor2 == "object") {
                let c = this.getcolor2.rgba;
                this.color2 = "rgba(" + c.r + "," + c.g + "," + c.b + "," + c.a + ")";
                this.cssDegradedMain();
                this.muestra2 = "background:" + this.color2 + ";";
            }
        },
        angulo: function () {
            this.cssDegradedMain();
        },
        opacity: function () {
            this.cssMagua =
                `
					opacity:` +
                this.opacity +
                `;
					background-position: center ` +
                this.position +
                `%;
					background-image:` +
                this.bgAgua +
                `;`;
        },
        position: function () {
            this.cssMagua =
                `
					opacity:` +
                this.opacity +
                `;
					background-position: center ` +
                this.position +
                `%;
					background-image:` +
                this.bgAgua +
                `;`;
        },
        bgAgua: function () {
            this.cssMagua =
                `
					opacity:` +
                this.opacity +
                `;
					background-position: center ` +
                this.position +
                `%;
					background-image:` +
                this.bgAgua +
                `;`;
        },
        bg: function () {
            if (this.bg != null) {
                this.formIvo.type = 5;
                this.formIvo.bg = this.bg.slice(0, -2);
            }
            console.log("type", this.formIvo.type);
        },
    },
};
</script>
<style lang="css" scoped>
label.c-input.c-radio input {
    display: none;
}
span.c-indicator.btn.btn-secondary.btn-lg.disabled {
    cursor: pointer;
}
.columns-bg-1 {
}
.previewLogo {
    width: 210px;
    border: 1px solid #ccc;
    padding: 10px;
    display: block;
    margin-bottom: 20px;
}
.labelColor1 {
    display: block;
    width: 100%;
}
.color1 {
    width: 60%;
    display: inline-block;
    border-radius: 0;
    height: 33px;
    float: left;
}
.btn-color1 {
    padding: 5px 10px;
    border-radius: 0;
    display: inline-block;
    height: 33px;
    float: left;
    border: 1px solid #ccc;
}
.muestra1 {
    padding: 5px 16px;
}
.labelColor2 {
    display: block;
    width: 200%;
}
.color2 {
    width: 60%;
    display: inline-block;
    border-radius: 0;
    height: 33px;
    float: left;
}
.btn-color2 {
    padding: 5px 10px;
    border-radius: 0;
    display: inline-block;
    height: 33px;
    float: left;
    border: 1px solid #ccc;
}
.muestra2 {
    padding: 5px 16px;
}
.getcolor {
    position: absolute;
    top: 60px;
    z-index: 5;
}
.step-ops {
    text-align: center;
}
.bgpdf label {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #999999;
    position: relative;
    z-index: 3;
}
.bgpdf {
    width: 128px;
    height: 160px;
    text-align: center;
    display: inline-block;
    float: left;
    margin: 5px;
    margin-bottom: 70px;
    background-size: cover;
    position: relative;
    z-index: 3;
}
.bgcolorSolid {
    margin-bottom: 30px;
}
.bgpdf span {
    position: absolute;
    top: 104%;
    left: 0;
    right: 0;
    z-index: 2;
    font-size: 12px;
    line-height: 1;
}
.bg-d1 {
    background: rgba(252, 19, 19, 0.07);
    background: -moz-linear-gradient(
        45deg,
        rgba(252, 19, 19, 0.07) 20%,
        #ffffff 10%,
        #ffffff 78.5%,
        rgba(189, 223, 239, 0.2) 0%
    );
    background: -webkit-linear-gradient(
        45deg,
        rgba(252, 19, 19, 0.07) 20%,
        #ffffff 10%,
        #ffffff 78.5%,
        rgba(189, 223, 239, 0.2) 0%
    );
    background: linear-gradient(
        45deg,
        rgba(252, 19, 19, 0.07) 20%,
        #ffffff 10%,
        #ffffff 78.5%,
        rgba(189, 223, 239, 0.2) 0%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='rgba(252,19,19,0.07)', endColorstr='rgba(189,223,239,0.20)',GradientType=1 );
}

.bgpdf span.marca-agua {
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: center 60%;
    opacity: 0.3;
    width: 100%;
    height: 100%;
    z-index: 2;
    position: absolute;
    top: 0;
}
.columns-bg-1 input[type="radio"] {
    display: none;
}
.columns-bg-1 input[type="radio"]:checked + label {
    border: 2px solid;
}
.columns-bg-1 input[type="radio"]:checked + label:before {
    content: "\f14a";
    font-family: "Font Awesome 5 Free";
    color: #469f35;
    font-size: 2rem;
    position: absolute;
    right: 5px;
    bottom: 1px;
}
#bg-inv,
.bg-drag-drop-invo {
    width: 250px;
    margin: auto;
    height: 320px;
    border: 1px dashed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 28px;
    padding: 10px 15px;
}
#bg-inv {
    border: 1px solid;
}
.btn-del-bg,
.btn-up-bg {
    width: 250px;
    margin: auto;
    display: block;
}
@media (max-width: 1100px) {
    .bgpdf {
        width: 110px;
        height: 140px;
    }
}

@media (min-width: 768px) {
    .columns-bg-1 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    .columns-bg-2 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
}

@media (min-width: 1101px) {
    .columns-bg-1 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    .columns-bg-2 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
}
@media (max-width: 767px) {
    .bgpdf {
        width: 55px;
        height: 70px;
    }
}
@media (min-width: 1280px) {
    .ctrlbtn {
        width:100px !important;
    }
}
</style>
