<template>
    <div class="row card-body" style="padding-top: 0px;padding-bottom: 5em;">
        <div class="row card-header window-header" style="margin: 0px -10px 0px -10px; width: 105%;">
            <div class="col-12 col-sm-12 d-flex justify-content-center">
                <h3 style="margin-bottom: 0px;">
                    Choose Template
                </h3>
            </div>
		</div>
        <div class="window-body">
            <div class="row">
                <Loader class="col-12 text-center" v-if="isLoading"></Loader>
                <div class="col-xs-12 col-sm-6 marginBottom">
                    <label class="style-template">
                        <input type="radio" :value="1" v-model="form.template_id" :checked="form.template_id==1" @change="saveCard()" class="inputHide"/>
                        <h4>
                            Classic grid
                            <span :class="['status_template', (form.template_id==1) ? 'active' : '']"></span>
                        </h4>
                        <img src="@/apps/Marketea/assets/template1.png" alt="template1" class="templatePreview"/>
                    </label>
                </div>
                <div class="col-xs-12 col-sm-6 marginBottom">
                    <label class="style-template">
                        <input type="radio" :value="2" v-model="form.template_id" :checked="form.template_id==2" @change="saveCard()" class="inputHide"/>
                        <h4>
                            Nightly
                            <span :class="['status_template', (form.template_id==2) ? 'active' : '']"></span>
                        </h4>
                        <img src="@/apps/Marketea/assets/template2.png" alt="template2" class="templatePreview"/>
                    </label>
                </div>
                <div class="col-xs-12 col-sm-6 marginBottom">
                    <div v-if="!premiumProfile" class="bg-disabled">
                        <span>Not available for your current plan</span>
                    </div>
                    <label class="style-template">
                        <input type="radio" :value="3" v-model="form.template_id" :checked="form.template_id==3" @change="saveCard()" class="inputHide"/>
                        <h4>
                            Jaglow
                            <span :class="['status_template', (form.template_id==3) ? 'active' : '']"></span>
                        </h4>
                        <img src="@/apps/Marketea/assets/template3.png" alt="template3" class="templatePreview"/>
                    </label>
                </div>
                <div class="col-xs-12 col-sm-6 marginBottom">
                    <div v-if="!premiumProfile" class="bg-disabled">
                        <span>Not available for your current plan</span>
                    </div>
                    <label class="style-template">
                        <input type="radio" :value="4" v-model="form.template_id" :checked="form.template_id==4" @change="saveCard()" class="inputHide"/>
                        <h4>
                            Postorg
                            <span :class="['status_template', (form.template_id==4) ? 'active' : '']"></span>
                        </h4>
                        <img src="@/apps/Marketea/assets/template4.png" alt="template4" class="templatePreview"/>
                    </label>
                </div>
                <div class="col-xs-12 col-sm-6 marginBottom">
                    <div v-if="!premiumProfile" class="bg-disabled">
                        <span>Not available for your current plan</span>
                    </div>
                    <label class="style-template">
                        <input type="radio" :value="5" v-model="form.template_id" :checked="form.template_id==5" @change="saveCard()" class="inputHide"/>
                        <h4>
                            bMenu
                            <span :class="['status_template', (form.template_id==5) ? 'active' : '']"></span>
                        </h4>
                        <img src="@/apps/Marketea/assets/template5.png" alt="template5" class="templatePreview"/>
                    </label>
                </div>
                <div class="col-xs-12 col-sm-6 marginBottom">
                    <div v-if="!premiumProfile" class="bg-disabled">
                        <span>Not available for your current plan</span>
                    </div>
                    <label class="style-template">
                        <input type="radio" :value="6" v-model="form.template_id" :checked="form.template_id==6" @change="saveCard()" class="inputHide"/>
                        <h4>
                            Events
                            <span :class="['status_template', (form.template_id==6) ? 'active' : '']"></span>
                        </h4>
                        <img src="@/apps/Marketea/assets/template4.png" alt="template6" class="templatePreview"/>
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>
<script>// @ts-ignore
import Loader from "@/core/components/shared/Loader.vue";
export default {
    name:"editTemplate",
    components: {
        Loader,
    },
    data(){
        return {
            isLoading: false,
            card_id: 0,
            form : {
                name : '',
                lastname : '',
                rol: 'Employee',
                slug_url : '',
                slug_name: '',
                status : '1',
                template_id : 1,
            },
            premiumProfile: false,
            planesPro: [6, 7, 10, 11, 12],
        };
    },
    created(){
        let t = this;
        let data = this.$store.getters['ModApps/Marketea/get_bsite_cardItem'];
        // t.premiumProfile = (t.planesPro.indexOf(t.$store.getters['core/apps/getPlanIdApp'])>-1);
        t.premiumProfile = (t.$store.getters['core/apps/getPlanIdApp']!=8);
        console.log('premiumProfile', t.$store.getters['core/apps/getPlanIdApp'])
        t.fillForm(data)
    },
    methods: {
        fillForm(data){
            let t = this;
            let r = data;
            t.loadWrite=1;
            // t.org.company_name = r.company_name;
            t.form.name = r.name;
            t.form.lastname = r.lastname;
            t.form.rol = r.rol;
            t.form.status = r.status;
            t.card_primary = r.card_primary;
            t.form.template_id = r.template_id;
            t.form.slug_url = r.slug_url;
            t.form.slug_name = r.slug_url.replace(t.suf_url+'-', '');
            t.slug_name = r.slug_url.replace(t.suf_url+'-', '');
        },
        saveCard(){
            let t = this;
            t.isLoading=true;
            t.$store.commit('ModApps/Marketea/setAbout', t.form);
            t.$store.commit('ModApps/Marketea/set_reloadPreview', true);
            t.$store.dispatch('ModApps/Marketea/setCard')
            .then(r => {
                console.log('saveCard', r);
                t.isLoading=false;
                t.$modal.hide("modal-editProfile");
                t.$store.commit('ModApps/Marketea/set_reloadPreview', false);
            })
            .catch(error => {
                console.log(error);
                t.isLoading=false;
                t.$store.commit('ModApps/Marketea/set_reloadPreview', false);
                t.$notify({
                    group: "noti",
                    type: 'Error',
                    title: "Fail",
                    text: "Failed to save",
                    duration: 2000
                });
            });
        },
    },
}
</script>
<style lang="scss" scoped>
.window-header{cursor: move;}
.card-header h3{font-size:24px;}
.window-body{
    height: calc(100vh - 45px);
    height: 600px;
    overflow: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    padding-bottom: 3em;
    width: 100%;
    &::-webkit-scrollbar:vertical {
        width:3px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #797979;
        border-radius: 20px;
        border: 0px solid #f1f2f3;
    }
    .row{
        width: 100%;
    }
}
.col-form-label, .form-control, .input-group-text{
    font-size:18px;
}
.onoffswitch{
    display: inline-flex;
    width: 80px;
    .onoffswitch-label{
        .onoffswitch-inner{
            &:before{
                padding-left: 25px;
                content: "Active";
            }
            &:after{
                content: "Inactive";
            }
        }
        .onoffswitch-switch{right: 55px;}
    }
}
label.style-template{
    display: block;
    margin: auto;
    width: 100%;
    height: 250px;
    max-width: 150px;
    &:hover{
        cursor: pointer;
    }
}
.templatePreview{
    display: block;
    margin: auto;
    width: 100%;
    border-radius: 4px;
    border: 3px solid #000;
}
.inputHide{display: none;}
label.style-template .inputHide:checked ~ img.templatePreview{
    border: 3px solid rgb(79, 164, 255);
}
.status_template{
    display: inline-block;
    margin-left: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px solid #ddd;
    background: #ddd;
    &.active{background: #5ac146;}
}
.marginBottom{margin-bottom: 60px;min-height: 300px;}

@media (max-width:767px){
    .window-body{
        height: 100%;
        max-height: 800px;
        padding-bottom: 20em;
    }
}
.bg-disabled {
    position: absolute;
    top:0px;
    width: 95%;
    height: 150%;
    background: rgb(221 221 221 / 80%);
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
}
.bg-disabled span {
    color:#a11a1a;
    font-weight: bold;
    font-size: 20px;
    text-shadow: 0px 0px 0px #555;width: 90%;
    margin: auto;
    text-align: center;
}
</style>
