/*rutas app de Setting*/
import RestoreEstimate from '@/apps/billing/views/setting/RestoreEstimate';
import RestoreCustomer from '@/apps/billing/views/setting/RestoreCustomer';
import RestoreInvoice from '@/apps/billing/views/setting/RestoreInvoice';
import RestorePaymentsReceived from '@/apps/billing/views/setting/RestorePaymentsReceived';
import RestoreExpenses from '@/apps/billing/views/setting/RestoreExpenses';
export default [
  {
    path: '/billing/setting/restore-estimate',
    name: 'RestoreEstimate',
    folder: RestoreEstimate,
  },
  {
    path: '/billing/setting/restore-customer',
    name: 'RestoreCustomer',
    folder: RestoreCustomer,
  },
  {
    path: '/billing/setting/restore-invoices',
    name: 'RestoreInvoice',
    folder: RestoreInvoice,
  },
  {
    path: '/billing/setting/restore-payments-received',
    name: 'RestorePaymentsReceived',
    folder: RestorePaymentsReceived,
  },
  {
    path: '/billing/setting/restore-expenses',
    name: 'RestoreExpenses',
    folder: RestoreExpenses,
  },
];
