// creo constante q guarda la funcion de requerir archivos
const requireModule = require.context('.', true, /\.js$/);
// nombre a exportar
const watch = {};
// recorro el arbol de archivos de mi carpeta actual donde estoy ubicado
requireModule.keys().forEach(fileName => {
	// si es igual al mismo index omitir
	if (fileName === './index.js') return;
	if (fileName === './watch.js') return;
	// quito la extencion .js y declaro el nombre de la funcion o metodo segun el nombre del archivo
	const functionName = fileName.replace(/(\.\/|\.js)/g, '');
	// si aun no he creado el objeto lo inicializo
	if (!watch[functionName]) { 
		watch[functionName] = {};
	}
	// almacenar en la memoria la importacion
	//watch[functionName]= fileName;
	// console.log('file watch', fileName)
	watch[functionName] = requireModule(fileName).default
	// console.log('import watch', watch[functionName])
});
// console.log('print', watch)
export default watch
