<template>
    <div class="list_add_widgets">
        <ul>
            <!-- <li class="disabled">
                <svg viewBox="0 0 24 24" focusable="false" class="iconImg"><path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zm-5-7l-3 3.72L9 13l-3 4h12l-4-5z"></path></svg>
            </li> -->
            <li @click="addEmbedCode()">
                <i class="fas fa-code"></i>
            </li>
            <li @click="addvideoYB()">
                <!-- <i class="fas fa-video"></i> -->
                <i class="fab fa-youtube"></i>
            </li>
            <li style="margin-top: 5px;" @click="addText()">
                <svg viewBox="0 0 24 24" focusable="false" class="iconTexto"><path d="M2 4v3h5v13h3V7h5V4H2zm20 5h-9v3h3v8h3v-8h3V9z"></path></svg>
            </li>
            <li @click="$emit('exitAddWidget')" style="margin-bottom: 5px;">
                <i class="fas fa-times"></i>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    data(){
        return {
            listWidgets: [],
            wText: null,
            wLink: null,
            wImage: null,
            wIframe: null,
        };
    },
    methods: {
        addText(){
            this.$emit('setAddWidget', {
                widget: 'text',
                text: "<h2>Lorem ipsum dolor sit amet.</h2><p>Laudantium esse voluptate possimus libero placeat. Quae facilis ex totam doloremque eligendi qui, quaerat sequi. Accusantium quisquam hic iusto quo id quaerat.</p>",
                url:'',
            });
        },
        addvideoYB(){
            this.$emit('setAddWidget', {
                widget: 'videoYB',
                url: ""
            });
        },
        addEmbedCode(){
            this.$emit('setAddWidget', {
                widget: 'iframe',
                url: null,
            });
        },
    }
}
</script>
<style lang="scss" scoped>
.list_add_widgets{
    position: absolute;
    right: -5px;
    bottom: -10px;
    z-index: 2;
    background: #333333;
    padding: 0px 5px;
    border-radius: 20px;
    box-shadow: -1px 0px 2px 1px rgba(255, 255, 255, 0.226);
    ul{
        list-style: none;
        padding: 0px;
        margin: 0px;
        li{
            font-size: 10px;
            line-height: 1;
            margin: 10px 0px;
            padding: 1px;
            width: 25px;
            height: 25px;
            border:1px solid #fff;
            border-radius: 50%;
            color:#fff;
            display: flex;
            justify-content: center;
            align-items: center;
            svg{
                fill:#fff;
                width: 14px;
            }
            &:hover{
                background: #031099;
                cursor: pointer;
            }
            &.disabled{
                svg{fill:#ccc !important;}
            }
        }
    }
}
</style>
