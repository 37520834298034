let getGroupName = function(i) {
	let t =this;
	return new Promise((resolve, reject) => {
		window.master.get(t.apis.groupName() + window.localorgdata + '/' + i.id_campaign)
		.then((response) => {
			let prop = ['id', 'name', 'id_campaign'];
			resolve(this.contruirSelect(response.data.group, prop));//valor de retorno positivo 
		})
		.catch((error) => {
			console.log(error);
			resolve([]);//valor de retorno vacio
		});
	});
};
export default getGroupName;
