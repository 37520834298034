let delTags = function(i, idx, tag) {
    let v = this;
    let url = this.apis.delTags()
    window.master
    .post(url, {
        user_id: this.user_id,
        org_id: this.org_id,
        id_jobs: i.id_i,
        id_tags: tag.id_tags,
    })
    .then((response) => {
        if (response.data.status == 1) {
            this.$store.commit("ModApps/jobs/set_delTags", {job: i.id_i, id_tags: tag.id_tags});
            this.$store.commit("ModApps/jobs/set_refresh_grid", true);
        }
    })
    .catch((error) => {
        console.log(error);
    });
};
export default delTags;
