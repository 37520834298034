let reEscribirLista = function(idx) {
    let listOut = this.listTaks;
    if (!this.searchActivate) {
        this.listTaksResult = [];
        for (let prop in listOut) {
            if (listOut.hasOwnProperty(prop)) {
                //if(idx==prop){debugger;}
                listOut[prop].pusher += 1;
                this.listTaksResult.push(listOut[prop]);
            }
        }
    } else {
        //buscador activado, seleccionar item actuales para actualizarlos
        let itemFiltrados = [];
        for (let prop in this.listTaksResult) {
            if (this.listTaksResult.hasOwnProperty(prop)) {
                itemFiltrados.push(this.listTaksResult[prop]);
            }
        }
        this.listTaksResult = [];
        for (let prop in listOut) {
            if (listOut.hasOwnProperty(prop)) {
                for (let i in itemFiltrados) {
                    if (itemFiltrados.hasOwnProperty(i)) {
                        if (itemFiltrados[i].id_i == listOut[prop].id_i) {
                            listOut[prop].pusher += 1;
                            this.listTaksResult.push(listOut[prop]);
                        }
                    }
                }
            }
        }
    }
};
export default reEscribirLista;
