let autoHeigthAllTask = function(){
	let t = this;
	// metodo local
	//console.log('autoHeigthAllTask', new Date());
	function heightToData(task, j, item, i) {
	  this.style.height = "auto";
	  this.style.height = (this.scrollHeight) + "px";
	  console.log('heightToData', new Date());
	  // console.log('heightToData', this.getAttribute("data-heigth"));
	  this.addEventListener("change", t.editCamp(task, j, item, i), false); 
	}
	for (let i in t.listTaks) {
		if (t.listTaks[i]) {
			let item =t.listTaks[i];
			for (let j in t.listTaks[i].taks) {
				if (t.listTaks[i].taks[j]) {
					let task = t.listTaks[i].taks[j];
					let idTask = task.id;
                    let elem = document.querySelector(".heigth" + j + "-" + idTask);
                    if(elem){
                    	let overflow = false;
                    	let h = elem.scrollHeight; // alto igual q scroll
                    	if(t.listTaks[i].taks[j].name.length<=30 || h<26){
                    		h = 26; // solo una linea
                    	}
                    	if (h>=84){ 
                    		h = 84;
                    		overflow = true;
                    	}
                    	t.listTaks[i].taks[j].alto = h; // alto igual q scroll
                    	//console.log('scrollHeight', h, new Date());
                    	// evento para detectar cambios en la data del input
                    	elem.style.height = "auto";
                    	elem.style.height = h + "px";
						elem.classList.add('hide');
						let previewTask = elem.nextElementSibling;
						if(previewTask){							
                    		previewTask.style.height = h + "px";
						}
						if(overflow){
							elem.style.overflow = "hidden";
							previewTask.style.overflow = "hidden";
							previewTask.classList.add('ellipsis');
							//console.log('activando overflowY', t.listTaks[i].taks[j]);
						}
                    	elem.addEventListener("input", function(e){
							this.style.height = "auto";
							this.style.height = (this.scrollHeight) + "px";
							let previewTask = this.nextElementSibling;
							if(previewTask){							
	                    		previewTask.style.height = (this.scrollHeight) + "px";
							}
                    		this.setAttribute("data-heigth", (this.scrollHeight));
							console.log('heightToData for in', new Date());
							console.log('heightToData', this.getAttribute("data-heigth"));
                    	}, false); 
                    	//console.log('item', i, 'task', j, 'alto='+taks.alto)	
                    } else {
                    	console.log('elem no existe', i, 'task', j, 'alto='+task)   
                    }
				} 
			} 
		} 
	}
	t.reEscribirLista(); 
};
export default autoHeigthAllTask;
