<template>
	<div class="headerSite">
        <div v-if="render" class="renderHeader">
            <div v-if="topbar.length>0" class="navTopBar">
                <div v-for="(i, idx) in topbar" :key="idx" :class="['itemTopBar', 'section-custom', i.divClass]">
                    <div class="btnsOpcNav row">
                        <div class="col-md-4 text-left" style="padding:0px;">
                            <div class="optEdicion left">
                                <ul>
                                    <li><i class="fas fa-ellipsis-v"></i></li>
                                    <li><i class="fas fa-edit"></i></li>
                                    <li  @click="delTopBar(i, idx)"><i class="fas fa-trash-alt"></i></li>
                                    <li>TopBar #{{idx+1}}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-4 text-center" style="padding:0px;display: flex;justify-content: center;">
                            <div class="optEdicion" v-if="idx==0 && topbar.length<2">
                                <ul>
                                    <li @click="addTopBar()"><i class="fas fa-plus-circle"></i>Add TopBar</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-4 text-right" style="padding:0px;"></div>
                    </div>
                    <div :class="i.container">
                        <div class="row">
                            <div v-for="(w, widgetIdx) in i.columns" :key="widgetIdx" :class="['widgetItem', w.grids]">
                                <div v-html="w.htmlCode" class="htmlCode "></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="header" :class="['header-section', 'hd'+headerMain.type]">
                <div class="btnsOpcNav row" >
                    <div class="col-md-4 text-left" style="padding:0px;">
                        <div class="optEdicion">
                            <ul>
                                <li><i class="fas fa-edit"></i></li>
                                <li @click="changeNavbar(1)" :class="['changeNav', (headerMain.type==1) ? 'active' : '']">NavBar 1</li>
                                <li @click="changeNavbar(2)" :class="['changeNav', (headerMain.type==2) ? 'active' : '']">NavBar 2</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-4 text-center" style="padding:0px;display: flex;justify-content: center;">
                        <div class="optEdicion" v-if="topbar.length==0">
                            <ul>
                                <li @click="addTopBar()"><i class="fas fa-plus-circle"></i>Add TopBar</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-4 text-right" style="padding:0px;"></div>
                </div>
                <nav class="navbar navbar-expand-lg navbar-light " > <!-- nav-fixed-top builder -->
                	<div class="container">
                		<a class="navbar-brand" href="#" @click.prevent="$parent.clickVoid()">
                            <div class="optEdicion">
                                <ul>
                                    <li><i class="fas fa-edit"></i></li>
                                    <li><i class="fas fa-trash-alt"></i></li>
                                </ul>
                            </div>
                            <img :src="logo.imgUrl" :alt="logo.imgAlt"/>
                        </a>
                		<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
                			<span class="navbar-toggler-icon"></span>
                		</button>
                		<div id="navbarScroll" class="collapse navbar-collapse">
                            <div class="optEdicion">
                                <ul>
                                    <li><i class="fas fa-edit"></i></li>
                                    <li><i class="fas fa-trash-alt"></i></li>
                                </ul>
                            </div>
                			<ul class="navbar-nav me-auto my-lg-0 navbar-nav-scroll">
                				<li v-for="(i, index) in menu" :key="index" :class="['nav-item', (i.child.length>0) ? 'dropdown' : '']">
                                   <a  v-if="i.child.length==0" :class="'nav-link '+ i.cssclass" href="#" @click.prevent="$parent.changePage(i.link)">{{i.title}}</a>
                					<a v-else :class="'nav-link dropdown-toggle '+ i.cssclass" href="#" @click.prevent="$parent.navbarDropdown(i)">
                						{{i.title}}
                					</a>
                					<ul class="dropdown-menu" v-if="i.child.length>0" :style="(i.show) ? 'display:block;' : ''">
                						<li v-for="(sub, idxSub) in i.child" :key="idxSub">
                                            <a :class="'dropdown-item '+ sub.cssclass" href="#" @click.prevent="$parent.changePage(sub.link)">{{sub.title}}</a>
                                        </li>
                					</ul>
                				</li>
                			</ul>
                		</div>
                		<div class="d-flex btn-rigth" v-if="btn.title">
                            <div class="optEdicion">
                                <ul>
                                    <li><i class="fas fa-edit"></i></li>
                                    <li><i class="fas fa-trash-alt"></i></li>
                                </ul>
                            </div>
                			<a :href="btn.link" :class="'btn btn-outline-success '+btn.cssclass" @click.prevent="$parent.clickVoid()">
                                <span class="icon linearicons-telephone"></span> {{btn.title}}
                            </a>
                		</div>
                	</div>
                </nav>
            </div>
            <section 
                v-if="coverPage!=null"
                :class="['header-page', 'navType'+headerMain.type]" 
                :style="'height:'+coverPage.slideHeight+coverPage.typeHeight"
            >
                <div class="btnsOpcSect before row typeHeader">
                    <div class="col-md-12 text-center" style="padding:0px;display: flex;justify-content: left;">
                        <div class="optEdicion">
                            <ul>
                                <li @click="editSlider(pageCurrent.coverPage)"><i class="fas fa-edit"></i> Slider Show</li>
                               <!--  <li @click="changeSlider(1, pageCurrent.coverPage)" :class="[(pageCurrent.coverPage.classHeight==1) ? 'active' : '']">Header</li>
                                <li @click="changeSlider(2, pageCurrent.coverPage)" :class="[(pageCurrent.coverPage.classHeight==2) ? 'active' : '']">Big Header</li>
                                <li @click="changeSlider(3, pageCurrent.coverPage)" :class="[(pageCurrent.coverPage.classHeight==3) ? 'active' : '']">Front Page</li>
                                <li @click="changeSlider(4, pageCurrent.coverPage)" :class="[(pageCurrent.coverPage.classHeight==4) ? 'active' : '']">Title Only</li> -->
                            </ul>
                        </div>
                    </div>
                </div>
                <div 
                    id="mainSlider"
                    :class="['slider_show', 'header'+headerMain.type, coverPage.divClass]" 
                    :data-autoplay="coverPage.autoplay" 
                    :data-time="coverPage.time" 
                    :data-nav="coverPage.typeNav"
                    :style="'height:'+coverPage.slideHeight+coverPage.typeHeight+';'"
                >
                    <draggable 
                        tag="div"
                        v-if="coverPage.images.length>0" 
                        :class="['container--slider_main']"
                        :style="'height:100%;margin-left:'+((coverPage.type==2)?'-100%':'0px')+';'"
                        v-model="coverPage.images"
                        v-bind="dragGrid"
                        @start="isDragging(true)"
                        @end="isDragging(false)"
                        handle=".moveColumn"
                    >
                        <!-- slide -->
                        <div v-if="coverPage.typeBg == 1" :class="['slider_show--item', 'item0']">
                            <picture v-if="coverPage.images[0].desktop!=''">
                                  <source v-if="coverPage.images[0].mobile!=''" media="(max-width: 450px)" :srcset="coverPage.images[0].mobile" />
                                  <source v-if="coverPage.images[0].tablet!=''" media="(max-width: 1100px)" :srcset="coverPage.images[0].tablet" />
                                  <img :src="coverPage.images[0].desktop" alt="slider_show--img" class="slider_show--img" loading="lazy" width="1920" height="1080"/>
                            </picture>   
                        </div>
                        <div v-else-if="coverPage.typeBg == 2" v-for="(img, imgIdx) in coverPage.images" :key="imgIdx" :class="['slider_show--item', 'item'+imgIdx]" >
                            <picture v-if="img.desktop!=''">
                                  <source v-if="img.mobile!=''" media="(max-width: 450px)" :srcset="img.mobile" />
                                  <source v-if="img.tablet!=''" media="(max-width: 1100px)" :srcset="img.tablet" />
                                  <img :src="img.desktop" alt="slider_show--img" class="slider_show--img" loading="lazy" width="1920" height="1080"/>
                            </picture>                            
                        </div>
                        <div v-else class="video"></div>
                    </draggable>
                    <div class="colorOverlay" :style="'background-color: rgba('+coverPage.colorOverlay.r+','+coverPage.colorOverlay.g+','+coverPage.colorOverlay.b+','+coverPage.colorOverlay.a+');'"></div>
                    <div class="slider_show--nav"></div>
                    <div v-if="coverPage.single_content==1" class="container--slider_section-content row" >
                        <div v-for="(c, columnIdx) in coverPage.content[0].columns" :key="columnIdx" :class="['columnItem',c.grids, 'align-self-center', '']">
                            <draggable
                              tag="div"
                              v-if="c.widgets.length>0" 
                              :class="['section-content-column', c.moreClass]"
                              v-model="c.widgets"
                              v-bind="dragWidgets"
                              @start="isDragging(true)"
                              @end="isDragging(false)"
                              handle=".moveWidget"
                            >
                              <div v-for="(w, widgetIdx) in c.widgets" :key="widgetIdx" :class="['widgetItem']">
                                <div v-if="w.htmlCode!='partner'" v-html="w.htmlCode" :class="['htmlCode', w.moreClass]" :contenteditable="(w.type!='code' && w.type!='img')"></div>
                                <div v-else :class="w.partner.divClass">
                                    <img v-for="(p, partnerIdx) in w.partner.imgs" :key="partnerIdx" :src="p.urlImg" />
                                </div>
                              </div>
                            </draggable>  
                        </div>
                    </div>
                </div>
            </section>
        </div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import draggable from 'vuedraggable'
export default {
    components: {
        draggable
    },
	name: 'navMain',
	data () {
		return {
            render: false,
            headerMain: {},
            logo2: {},
            logo: {},
            menu: [],
            btn: {},
            pages: [],
            topbar: [],
            pageCurrent: null,
            coverPage: null,
		}
	},
    created(){
        this.validNav();
        this.validPages();
        this.validTopBar();
        this.pageCurrent = this.$parent.pageCurrent;
        console.log('create');
    },
    mounted(){
        console.log('mounted', document.querySelector("#mainSlider"));
        //debugger;
        this.runSlider();
    },
    methods: {
        runSlider(){
            let t = this;
            if(t.coverPage!=null && t.coverPage.typeBg==2){
                console.log('runSlider');
                t.mainSlider("#mainSlider");
            }
        },
        validNav(){
            let t = this;
            if(t.$parent.theme.main.nav){
                t.headerMain=t.$parent.theme.main.nav;
                t.logo=t.headerMain.logo;
                t.logo2=t.headerMain.logo2;
                t.menu=t.headerMain.menu;
                t.btn=t.headerMain.btn;
                t.coverPage=null;
                t.stopInterval();
                setTimeout(function(){
                    t.coverPage=t.$parent.pageCurrent.coverPage;
                    t.render=true;
                    if(t.coverPage.typeBg==2){
                       setTimeout(function(){ t.runSlider();}, 500);
                    }
                }, 500);
            } 
            else{
                t.render=false;
            }
        },
        validPages(){
            if(this.$parent.theme.main.pages){            
                this.pages=this.$parent.theme.main.pages;
            }
        },
        validTopBar(){
            if(this.$parent.theme.main.topbar){            
                this.topbar=this.$parent.theme.main.topbar;
            }
        },
        addTopBar(){
            let id = this.$parent.theme.main.topbar.length;
            let newTopbar = {
                container: 'container',
                divClass: 'topbar'+id,
                columns: [
                    {
                        type: 'text', // texto, img
                        htmlCode: '<p class="">top section 1</p>',
                        grids: 'col-4 text-center',
                    },
                    {
                        type: 'text', // texto, img
                        htmlCode: '<p class="">top section 2</p>',
                        grids: 'col-4 text-center',
                    },
                    {
                        type: 'text', // texto, img
                        htmlCode: '<p class="">top section 3</p>',
                        grids: 'col-4 text-center',
                    },
                ]
            };
            this.$parent.theme.main.topbar.push(newTopbar);
        },
        delTopBar(i, idx){
            this.$parent.theme.main.topbar.splice(idx, 1);
        },
        changeNavbar(navChange){
            this.$parent.theme.main.nav.type=navChange;
        },
        isDragging(t){
            //console.log('move', t) // t = true or false
            return t;
        },
        log: function(evt) {},
        changeSlider(type, slider){
            this.$parent.pageCurrent.coverPage.classHeight = type;
        },
        changeGrid(col, columnIdx){
            let t = this;
            t.coverPage.content[0].columns[columnIdx].grids='col-'+col;
            t.$parent.pageCurrent.coverPage = t.coverPage;
        },
        alignGrid(align, columnIdx){
            let t = this;
            t.coverPage.content[0].columns[columnIdx].moreClass='text-'+align;
            t.$parent.pageCurrent.coverPage = t.coverPage;
        },
        editSlider(slider){
            let t = this;
            let s = {
                active: true,
                component: 'opcHeader',
                data: [slider]
            }
            t.$store.commit("ModApps/Sites/setSideBarOpc", s);
        },
        saveSlider(slider){
            let t = this;
            t.$parent.pageCurrent.coverPage = slider;
        },
        // slider
        mainSlider(elem){
            let t = this;
            const slider_show = document.querySelector(elem);
            //debugger;
            if(slider_show!=null && t.coverPage.typeBg==2){
                console.log('continue con SliderShow', slider_show);
                slider_show.style.maxWidth = document.querySelector(elem).offsetWidth+'px';
                let containerSlide = slider_show.children[0];
                let maxContainer = 100 * containerSlide.children.length;
                containerSlide.style.width = maxContainer+'%';
                let itemSlide = containerSlide.children;
                let lastItemSlide = itemSlide[itemSlide.length-1];
                containerSlide.insertAdjacentElement('afterbegin', lastItemSlide);
                //console.log('items', itemSlide);
                let iniSlide;
                t.autoplay(iniSlide, slider_show, containerSlide);
            }
        },
        autoplay(iniSlide, slider_show, containerSlide){
            let t = this;
            if(slider_show){
                let validAutoPlay = (slider_show.getAttribute('data-autoplay')!=null) ? ((slider_show.getAttribute('data-autoplay') == 'yes') ? 'yes' : 'no') : 'no';
                let timeSpeed = (slider_show.getAttribute('data-time')!=null) ? slider_show.getAttribute('data-time') : '5000';
                iniSlide = setInterval(function(){
                    let iniSlide=null;
                    let timeSpeed=null;
                    let inVal = localStorage.getItem('Interval');
                    console.log('setInterval', inVal);
                    if(inVal!=null && inVal!="null" && inVal!=""){
                        t.next(slider_show, containerSlide, iniSlide, timeSpeed);
                    }
                }, timeSpeed);
                localStorage.setItem('Interval', iniSlide);
                let dataNav = (slider_show.getAttribute('data-nav')!=null) ? slider_show.getAttribute('data-nav') : '1';
                if(dataNav){
                    let btnNav = slider_show.children[2];
                    if(dataNav =='1' || dataNav=='2'){
                        let btnLeft = document.querySelector('.slider_show--btn_left');
                        let btnRigth = document.querySelector('.slider_show--btn_right');
                        if(btnLeft ===null && btnRigth===null){
                            let bLeft = document.createElement('div');
                            let bRight = document.createElement('div');
                            bLeft.classList.add('slider_show--btn_left');
                            bRight.classList.add('slider_show--btn_right');
                            let typeBtn = (dataNav=='2') ? dataNav : '';
                            let iconleft = (dataNav=='2') ? '&#60;' : '';
                            let iconright = (dataNav=='2') ? '&#62;' : '';
                            bLeft.innerHTML = '<span class="btn_left--icon'+typeBtn+'">'+iconleft+'</span>';
                            bRight.innerHTML = '<span class="btn_right--icon'+typeBtn+'">'+iconright+'</span>';
                            btnNav.append(bLeft);
                            btnNav.append(bRight);
                            let btnLeft = document.querySelector('.slider_show--btn_left');
                            let btnRigth = document.querySelector('.slider_show--btn_right');
                            btnRigth.addEventListener('click', function(){
                                t.next(slider_show, containerSlide, iniSlide, timeSpeed);
                            });
                            btnLeft.addEventListener('click', function(){
                                t.prev(slider_show, containerSlide, iniSlide, timeSpeed);
                            });
                        }
                    }
                    else if(dataNav =='3'){
                        let btnIndicators = document.querySelectorAll('.btn_indicadores');
                        if(btnIndicators==null){
                            let indicators = document.createElement('div');
                            indicators.classList.add('slider_show--btn_indicators');
                            let countBtns = containerSlide.children.length;
                            let ul = document.createElement('ul');
                            ul.classList.add('slider_show--list_indicadores');
                            for (var i = 0; i < countBtns; i++) {
                                let li = document.createElement('li');
                                li.classList.add('list_indicadores--item');
                                li.innerHTML='<button type="buttom" class="btn_indicadores" data-slide-to="'+i+'"></button>';
                                ul.append(li);                      
                            }
                            indicators.append(ul);
                            btnNav.append(indicators);
                            let btnIndicators = document.querySelectorAll('.btn_indicadores');
                            if(btnIndicators.length>0){
                                
                            }
                            //debugger
                        }
                    }
                }
                let isDown = false;
                let startX;
                let scrollLeft;
                let mover;
                let slider = slider_show;
                const end = (e) => {
                    //console.log('terminando movimiento', mover)
                    isDown = false;
                    slider.classList.remove('active');
                    if(mover <= -10){
                        t.next(slider_show, containerSlide, iniSlide, timeSpeed);
                    }
                    else if(mover >= 10){
                        t.prev(slider_show, containerSlide, iniSlide, timeSpeed);
                    }
                }
                const start = (e) => {
                    startX = e.pageX || e.touches[0].pageX - slider.offsetLeft;
                    //console.log('iniciando movimiento', startX);
                    isDown = true;
                    slider.classList.add('active');
                    scrollLeft = slider.scrollLeft;
                }
                const move = (e) => {
                    if(!isDown) return;
                    e.preventDefault();
                    const x = e.pageX || e.touches[0].pageX - slider.offsetLeft;
                    mover = (x - startX);
                }
                slider.addEventListener('mousedown', start);// click down
                slider.addEventListener('touchstart', start);// pulsando touch
                slider.addEventListener('mousemove', move);// moviendo el cursor
                slider.addEventListener('touchmove', move);// moviendo touch
                slider.addEventListener('mouseup', end);// click up
                slider.addEventListener('mouseleave', end);// cursor fuera
                slider.addEventListener('touchend', end);// soltar touch
            }
        },
        stopAutoPlay(interval, slider_show, containerSlide){
            let t = this;
            if(interval!=undefined){
                clearInterval(interval);
                localStorage.removeItem('Interval');
            }
            //console.log('click next');
            setTimeout(() => {
                //console.log('ejecutando de nuevo autoplay');
                let iniSlide;
                t.autoplay(iniSlide, slider_show, containerSlide);
            }, 1000);
        },
        stopInterval(){
            let interval = localStorage.getItem('Interval');
            if(interval){
                clearInterval(interval);
                localStorage.removeItem('Interval');
                //console.log('stopInterval');
            }
        },
        next(slider_show, containerSlide, iniSlide=null, timeSpeed=null){
            let t = this;
            let firstItemSlide = slider_show.children[0].children[0];
            if(firstItemSlide){
                containerSlide.style.marginLeft = "-100%";
                containerSlide.style.transition = "all 0.5s";
                setTimeout(() => {
                    containerSlide.style.transition = "none";
                    containerSlide.insertAdjacentElement('beforeend', firstItemSlide);
                    containerSlide.style.marginLeft = "-0%";
                    if(iniSlide!=null && timeSpeed!=null){
                        //console.log('kill autoplay');
                        //t.stopAutoPlay(iniSlide, slider_show, containerSlide)
                    }
                }, 500);
            }
        },
        prev(slider_show, containerSlide, iniSlide=null, timeSpeed=null){
            let t = this;
            let lastItemSlide = slider_show.children[0].children[slider_show.children[0].children.length-1];
            if(lastItemSlide){
                containerSlide.style.marginLeft = "0%";
                containerSlide.style.transition = "all 0.5s";
                setTimeout(() => {
                    containerSlide.style.transition = "none";
                    containerSlide.insertAdjacentElement('afterbegin', lastItemSlide);
                    containerSlide.style.marginLeft = "-100%";
                    if(iniSlide!=null && timeSpeed!=null){
                        //console.log('kill autoplay');
                        //t.stopAutoPlay(iniSlide, slider_show, containerSlide)
                    }
                }, 500);
            }
        },
    },
    watch:{
        '$parent.theme.main.nav': function() {
            this.validNav();
        },
        '$parent.theme.main.pages': function() {
            this.validPages();
        },
        '$parent.theme.main.topbar': function() {
            this.validTopBar();
        },
        "$parent.pageCurrent": function () {
            this.pageCurrent = this.$parent.pageCurrent;
            this.validNav();
            this.validPages();
            this.validTopBar();
        },
        "getFontPageTypeBg": function(){
            //debugger;
            this.validNav();
        },
        "getFontPageBgImg": function(){
            //debugger;
            this.validNav();
        },
    },
    computed: {
        ...mapGetters("ModApps/Sites", { 
            getSideBarOpc: "getSideBarOpc",
            getFontPageTypeBg: "getFontPageTypeBg",
            getFontPageBgImg: "getFontPageBgImg",
        }),
        dragGrid() {
            return {
            animation: 200,
            group: "columnsSlider",
            disabled: false,
            ghostClass: "ghost",
            };
        },
        dragWidgets() {
            return {
                animation: 200,
                group: "widgetsSlider",
                disabled: false,
                ghostClass: "ghost",
            };
        },
    }
};
</script>
<style lang="scss" scoped>
.header-section{
    position: relative;
    font-family: "Roboto",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
    z-index: 3;
}
/*header diseño1*/
.hd1 {
    background: linear-gradient(to bottom,rgb(0 0 0 / 80%) 30%,rgb(0 0 0 / 3%) 100%);
    font-weight: 500;border-bottom: 1px solid;position: absolute;width: 100%;}
.navTopBar .itemTopBar{background-color: #fff; height: 50px; display: flex;justify-content: center; align-items: center;}
.navTopBar .itemTopBar .widgetItem{display: flex; justify-content: center; align-items: center;}
.hd1 nav{margin:0px auto;height: 60px;padding: 0px;}
.hd1 nav.navbar {position: relative;display: flex;flex-wrap: wrap;align-items: center;justify-content: space-between;padding-top: 0.5rem;padding-bottom: 0.5rem;}
.hd1 nav.navbar-light .navbar-brand:focus, .hd1 nav.navbar-light .navbar-brand:hover {color: rgba(0,0,0,.9);}
.hd1 nav.navbar-light .navbar-brand {color: rgba(0,0,0,.9);}
.hd1 nav.navbar-light .navbar-toggler {color: rgba(0,0,0,.55);border-color: rgba(0,0,0,.1);margin-right: 30px;background-color: #ececec;padding: 5px;box-shadow: none;}
.hd1 nav.navbar-light .navbar-nav .nav-link.active,
.hd1 nav.navbar-light .navbar-nav .nav-link{color: #fff;text-decoration: none;}
.hd1 nav.navbar-light .navbar-nav .nav-link.active:hover,
.hd1 nav.navbar-light .navbar-nav .nav-link:hover{color:#ce2e01;}
.hd1 nav.navbar-light .navbar-brand img{width: 220px;}

.hd1 nav ul{list-style: none;padding: 0px;}
.hd1 nav ul li a{font-size: 16px;position: relative;display: inline-block;color: #151515;line-height: 1.2;font-weight: 500;letter-spacing: .075em;text-transform: uppercase;transition: .25s;}
.hd1 nav .navbar-collapse{flex-grow: unset;}
.hd1 nav .btn-outline-success:hover,.hd1 .btn-outline-success, .hd1 .btn-check:active+.btn-outline-success,.hd1 .btn-check:checked+.btn-outline-success,.hd1 .btn-outline-success.active,.hd1 .btn-outline-success.dropdown-toggle.show,.hd1 .btn-outline-success:active {padding: 7.5px 20px;background: #cc1c1f;color: #fff;font-weight: 500;border: none;border-radius: 0px;text-decoration: none;}

.hd1 nav .btn-check:active+.btn-outline-success:focus, 
.hd1 nav .btn-check:checked+.btn-outline-success:focus, 
.hd1 nav .btn-outline-success.active:focus, 
.hd1 nav .btn-outline-success.dropdown-toggle.show:focus, 
.hd1 nav .btn-outline-success:active:focus {box-shadow: none;}

@media (min-width: 992px){
    .hd1 nav.navbar-expand-lg .navbar-toggler {display: none;}
    .hd1 nav.navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .hd1 nav.navbar-expand-lg .navbar-collapse {
        display: flex;
        flex-basis: auto;
    }
    .hd1 nav.navbar-expand-lg .navbar-nav-scroll {
        overflow: visible;
    }
    .hd1 nav.navbar-expand-lg .navbar-nav {
        flex-direction: row;
    }
    .hd1 nav .my-lg-0 {
        margin-top: 0;
        margin-bottom: 0;
    }
}
@media (max-width: 992px){
    .hd1{background: #ececec;position: relative;}
    .hd1 nav{height: 60px;}
    .hd1 #navbarScroll.show{display:block;left: 0px;transition: left .5s;}    
    .hd1 #navbarScroll.show .navbar-nav-scroll{padding: 0px 0px 28px 0px;overflow: initial;margin: 0px;margin-left: -15px;}
    .hd1 #navbarScroll.show .navbar-nav-scroll .nav-item{background-color: #ececec;padding: 15px;width: 50%;}
    .hd1 nav.navbar-light .navbar-nav .nav-link.active,
    .hd1 nav.navbar-light .navbar-nav .nav-link{color: #6a6a6a;text-decoration: none;}
    .hd1 nav .container{flex-direction: row-reverse;justify-content: start !important;}
    .hd1 nav .d-flex{display: none !important;}
}
/*header diseño2*/
.hd2{background: #363636;font-weight: 500;border-bottom: 1px solid;width: 100%;}
.hd2 nav{margin:0px auto;height: 60px;padding: 0px;}
.hd2 nav.navbar {position: relative;display: flex;flex-wrap: wrap;align-items: center;justify-content: space-between;padding-top: 0.5rem;padding-bottom: 0.5rem;}
.hd2 nav.navbar-light .navbar-brand:focus, .hd2 nav.navbar-light .navbar-brand:hover {color: rgba(0,0,0,.9);}
.hd2 nav.navbar-light .navbar-brand {color: rgba(0,0,0,.9);}
.hd2 nav.navbar-light .navbar-toggler {color: rgba(0,0,0,.55);border-color: rgba(0,0,0,.1);margin-right: 30px;background-color: #ececec;padding: 5px;box-shadow: none;}
.hd2 nav.navbar-light .navbar-nav .nav-link.active,
.hd2 nav.navbar-light .navbar-nav .nav-link{color: #fff;text-decoration: none;}
.hd2 nav.navbar-light .navbar-nav .nav-link.active:hover,
.hd2 nav.navbar-light .navbar-nav .nav-link:hover{color:#ce2e01;}
.hd2 nav.navbar-light .navbar-brand img{width: 220px;}
.hd2 nav ul{list-style: none;padding: 0px;}
.hd2 nav ul li a{font-size: 16px;position: relative;display: inline-block;color: #151515;line-height: 1.2;font-weight: 500;letter-spacing: .075em;text-transform: uppercase;transition: .25s;}
.hd2 nav .navbar-collapse{flex-grow: unset;}
.hd2 nav .btn-outline-success:hover,.hd2 .btn-outline-success, .hd2 .btn-check:active+.btn-outline-success,.hd2 .btn-check:checked+.btn-outline-success,.hd2 .btn-outline-success.active,.hd2 .btn-outline-success.dropdown-toggle.show,.hd2 .btn-outline-success:active {padding: 7.5px 20px;background: #cc1c1f;color: #fff;font-weight: 500;border: none;border-radius: 0px;text-decoration: none;}
.hd2 nav .btn-check:active+.btn-outline-success:focus, 
.hd2 nav .btn-check:checked+.btn-outline-success:focus, 
.hd2 nav .btn-outline-success.active:focus, 
.hd2 nav .btn-outline-success.dropdown-toggle.show:focus, 
.hd2 nav .btn-outline-success:active:focus {box-shadow: none;}
@media (min-width: 992px){
    .hd2 nav.navbar-expand-lg .navbar-toggler {display: none;}
    .hd2 nav.navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .hd2 nav.navbar-expand-lg .navbar-collapse {
        display: flex;
        flex-basis: auto;
    }
    .hd2 nav.navbar-expand-lg .navbar-nav-scroll {
        overflow: visible;
    }
    .hd2 nav.navbar-expand-lg .navbar-nav {
        flex-direction: row;
    }
    .hd2 nav .my-lg-0 {
        margin-top: 0;
        margin-bottom: 0;
    }
}
@media (max-width: 992px){
    .hd2{background: #363636;position: relative;}
    .hd2 nav{height: 60px;}
    .hd2 #navbarScroll.show{display:block;left: 0px;transition: left .5s;}    
    .hd2 #navbarScroll.show .navbar-nav-scroll{padding: 0px 0px 28px 0px;overflow: initial;margin: 0px;margin-left: -15px;}
    .hd2 #navbarScroll.show .navbar-nav-scroll .nav-item{background-color: #ececec;padding: 15px;width: 50%;}
    .hd2 nav.navbar-light .navbar-nav .nav-link.active,
    .hd2 nav.navbar-light .navbar-nav .nav-link{color: #6a6a6a;text-decoration: none;}
    .hd2 nav .container{flex-direction: row-reverse;justify-content: start !important;}
    .hd2 nav .d-flex{display: none !important;}
}
/*header diseño3*/

/*final header*/
/*seleccionar elementos del nav*/
.header-section, nav.navbar-light .navbar-brand, div#navbarScroll, .hd1 nav.navbar-light .btn-rigth, .header-page {border: 0px dashed transparent;}
.header-section:hover, nav.navbar-light .navbar-brand:hover, div#navbarScroll:hover, .btn-rigth:hover, .header-page:hover {border: 1px dashed #19c716;}
.header-section nav.navbar-light{height: 90px;}
/*fin de seleccionar elementos del nav*/
@media (max-width: 992px){
    #navbarScroll{margin-top: 23px;position: relative;left: -120%;transition: left .5s;height: auto;}
    .slider img{
        height: 100%;
        width: auto;
    }

}
.optEdicion.left{left: -15px;}
li.nav-item.dropdown:hover + ul.dropdown-menu{ display:block; }
.header-section:hover .btnsOpcNav.row .optEdicion, 
.header-section nav.navbar-light .navbar-brand:hover .optEdicion, 
div#navbarScroll:hover .optEdicion, 
.btn-rigth:hover .optEdicion{ display: block; cursor: pointer; }
.renderHeader, .section-custom{
    position: relative;
}
.btnsOpcNav{
    position: absolute;
    top: -28px;
    height: 20px;
    width: 100%;
    z-index: 1;
    padding-left: 10px;
    padding-right: 10px;
}
.section-custom:hover > .btnsOpcNav .optEdicion{display: block; cursor: pointer;}
.section-custom:hover{
    border: 3px dashed #19c716;
    position: relative;
}
.section-content{
  position: relative;
  background-color: rgba(0,0,0,.5);
  background-clip: padding-box;
  border: solid transparent;
  border-width: 0px;
  min-height: 28px;
  -webkit-box-shadow: inset 1px 1px 0 rgb(0 0 0 / 10%), inset 0 -1px 0 rgb(0 0 0 / 7%);
  box-shadow: inset 1px 1px 0 rgb(0 0 0 / 10%), inset 0 -1px 0 rgb(0 0 0 / 7%);
}
.section-content.bg-nocolor{
  background-color: transparent;
}
.bg-img{
    background-size: cover;
    background-position: center;
    position: relative;
    width: 100%;
    height: 100%;
}
.slider-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.colorOverlay{
    position: absolute;
    top: 0;
    left: 0vw;
    width: 100%;
    height: 100%;
}
.moveSect{
  position: absolute;
  top: 0px;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: 5px;
  color: #aaa;
    &:hover{
      border-top: 1px dashed #aaa;
      border-bottom: 1px dashed #aaa;
      border-right: 1px dashed #aaa;
      cursor: move;
    }

}
.grip {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.align-center{
  justify-content: center;
  align-items: center;
  align-content: center;
}
h1{
  color: #fff;
  margin-bottom: 0px;
  padding: 5px;
  border: 1px dashed transparent;
  &:hover{
      border: 1px dashed #aaa;
  }
}.widgetItem{
  border: 0px dashed transparent;
  &:hover{
      border: 1px dashed #bfa5ff;
  }
}
.container.header-nav-top .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.optEdicion ul li.active {
    background: rgb(64 23 165 / 45%);
    color: #fff;
}
.btnsOpcSect.after{
    position: absolute;
    bottom: 5px;
    height: 20px;
    width: 100%;
    z-index: 1;
    padding-left: 10px;
    padding-right: 10px;
}
.header-page:hover > .btnsOpcSect .optEdicion{display: block; cursor: pointer; opacity: 1;}
.columnItem:hover{border: 2px dashed #19c716;}
.columnItem:hover > .btnsOpcColumn .optEdicion{top: -20px;}
.header-page:hover {position: relative;}
.header-page.navType1:hover > .btnsOpcSect.before.typeHeader {top: 91px;z-index: 3;border-top: 1px dashed #19c716;}
.header-page.navType2:hover > .btnsOpcSect.before.typeHeader{top: 5px;z-index: 3;}

</style> 
