<template>
    <div>
        <div v-for="(i, index) in designs" :key="index">
            <div class="card-header label-header" @click="activeDesign(index)">
                <i class="fa fa-ban" v-if="designsOp != styleActive"></i>
                <i class="fa fa-check-circle" v-else></i>
                <span> STYLE 1</span>
            </div>
            <div
                v-bind:class="[{ activeLista: designsOp == styleActive }, 'label-config']"
                style="padding: 0px 0px 0px 15px"
            >
                <div class="card-header label-header" @click="designsLabels">
                    <i class="fa fa-minus" v-if="designLabel"></i>
                    <i class="fa fa-plus" v-else></i>
                    <span> Design Label:</span>
                </div>
                <div v-bind:class="[{ activeLista: designLabel }, 'label-config']">
                    <div class="form-group" style="padding: 0px; padding-right: 20px">
                        <label for="">Label Text size:</label>
                        <select
                            v-model="i.label_size"
                            class="form-control"
                            @change="LabelTextsize($event)"
                        >
                            <option value="10">10</option>
                            <option value="12">12</option>
                            <option value="14">14</option>
                            <option value="16">16</option>
                            <option value="18">18</option>
                            <option value="20">20</option>
                            <option value="22">22</option>
                            <option value="24">24</option>
                            <option value="26">26</option>
                            <option value="28">28</option>
                            <option value="30">30</option>
                            <option value="32">32</option>
                            <option value="34">34</option>
                            <option value="36">36</option>
                        </select>
                    </div>
                    <div class="form-group" style="padding: 0px; padding-right: 20px">
                        <label for="">Label Text weigth:</label>
                        <select
                            v-model="i.label_weight"
                            class="form-control"
                            @change="LabelTextweigth($event)"
                        >
                            <option value="400">Normal</option>
                            <option value="bold">Bold</option>
                        </select>
                    </div>
                    <div class="form-group campoColor">
                        <label class="labelColor">Label Text color:</label>
                        <input
                            type="text"
                            class="form-control boxcolor"
                            v-model="i.label_color"
                            disabled
                            @click="picker('label', i.label_color)"
                        />
                        <button
                            type="button"
                            class="btn btn-light btn-xs btn-color muestra"
                            @click="picker('label', i.label_color)"
                            v-if="showPickerLavel == false"
                            :style="mLabelColor"
                        ></button>
                        <button
                            type="button"
                            class="btn btn-success btn-xs btn-color"
                            @click="picker('label', i.label_color)"
                            v-else
                        >
                            Ok
                        </button>
                        <button
                            type="button"
                            class="btn btn-danger btn-xs btn-color"
                            @click="oldPicker(i.label_color, mLabelColor, 'label')"
                            v-if="showPickerLavel"
                        >
                            X
                        </button>
                        <chrome-picker
                            v-model="getcolorLabel"
                            v-if="showPickerLavel"
                            class="getcolor"
                        ></chrome-picker>
                    </div>
                </div>

                <div class="card-header label-header" @click="designsInputs">
                    <i class="fa fa-minus" v-if="designInput"></i>
                    <i class="fa fa-plus" v-else></i>
                    <span> Design Input:</span>
                </div>
                <div v-bind:class="[{ activeLista: designInput }, 'label-config']">
                    <div class="form-group" style="padding: 0px; padding-right: 20px">
                        <label for="">Input Text size:</label>
                        <select
                            v-model="i.input_size"
                            class="form-control"
                            @change="InputTextsize($event)"
                        >
                            <option value="10">10</option>
                            <option value="12">12</option>
                            <option value="14">14</option>
                            <option value="16">16</option>
                            <option value="18">18</option>
                            <option value="20">20</option>
                            <option value="22">22</option>
                            <option value="24">24</option>
                            <option value="26">26</option>
                            <option value="28">28</option>
                            <option value="30">30</option>
                            <option value="32">32</option>
                            <option value="34">34</option>
                            <option value="36">36</option>
                        </select>
                    </div>
                    <div class="form-group" style="padding: 0px; padding-right: 20px">
                        <label for="">Input Text weigth:</label>
                        <select
                            v-model="i.input_weight"
                            class="form-control"
                            @change="InputTextweigth($event)"
                        >
                            <option value="400">Normal</option>
                            <option value="bold">Bold</option>
                        </select>
                    </div>
                    <div class="form-group campoColor">
                        <label class="labelColor">Input Text color:</label>
                        <input
                            type="text"
                            class="form-control boxcolor"
                            v-model="i.input_color"
                            disabled
                            @click="picker('inputText', i.input_color)"
                        />
                        <button
                            type="button"
                            class="btn btn-light btn-xs btn-color muestra"
                            @click="picker('inputText', i.input_color)"
                            v-if="showPickerInputText == false"
                            :style="mInputColor"
                        ></button>
                        <button
                            type="button"
                            class="btn btn-success btn-xs btn-color"
                            @click="picker('inputText', i.input_color)"
                            v-else
                        >
                            Ok
                        </button>
                        <button
                            type="button"
                            class="btn btn-danger btn-xs btn-color"
                            @click="oldPicker(i.input_color, mInputColor, 'inputText')"
                            v-if="showPickerInputText"
                        >
                            X
                        </button>
                        <chrome-picker
                            v-model="getcolorInputText"
                            v-if="showPickerInputText"
                            class="getcolor"
                        ></chrome-picker>
                    </div>

                    <div class="form-group campoColor">
                        <label class="labelColor">Input Border color:</label>
                        <input
                            type="text"
                            class="form-control boxcolor"
                            v-model="i.input_border_color"
                            disabled
                            @click="picker('inputBorder', i.input_border_color)"
                        />
                        <button
                            type="button"
                            class="btn btn-light btn-xs btn-color muestra"
                            @click="picker('inputBorder', i.input_border_color)"
                            v-if="showPickerInputBorder == false"
                            :style="mInputBorder"
                        ></button>
                        <button
                            type="button"
                            class="btn btn-success btn-xs btn-color"
                            @click="picker('inputBorder', i.input_border_color)"
                            v-else
                        >
                            Ok
                        </button>
                        <button
                            type="button"
                            class="btn btn-danger btn-xs btn-color"
                            @click="
                                oldPicker(
                                    i.input_border_color,
                                    mInputBorder,
                                    'inputBorder'
                                )
                            "
                            v-if="showPickerInputBorder"
                        >
                            X
                        </button>
                        <chrome-picker
                            v-model="getcolorInputBorder"
                            v-if="showPickerInputBorder"
                            class="getcolor"
                        ></chrome-picker>
                    </div>
                    <div class="form-group" style="padding: 0px; padding-right: 20px">
                        <label for="">Placeholder Text size:</label>
                        <select
                            v-model="i.input_place_size"
                            class="form-control"
                            @change="PlaceholderTextsize($event)"
                        >
                            <option value="10">10</option>
                            <option value="12">12</option>
                            <option value="14">14</option>
                            <option value="16">16</option>
                            <option value="18">18</option>
                            <option value="20">20</option>
                            <option value="22">22</option>
                            <option value="24">24</option>
                            <option value="26">26</option>
                            <option value="28">28</option>
                            <option value="30">30</option>
                            <option value="32">32</option>
                            <option value="34">34</option>
                            <option value="36">36</option>
                        </select>
                    </div>
                    <div class="form-group campoColor">
                        <label class="labelColor">Placeholder Text color:</label>
                        <input
                            type="text"
                            class="form-control boxcolor"
                            v-model="i.input_place_color"
                            disabled
                            @click="picker('place', i.input_place_color)"
                        />
                        <button
                            type="button"
                            class="btn btn-light btn-xs btn-color muestra"
                            @click="picker('place', i.input_place_color)"
                            v-if="showPickerPlace == false"
                            :style="mPlace"
                        ></button>
                        <button
                            type="button"
                            class="btn btn-success btn-xs btn-color"
                            @click="picker('place', i.input_place_color)"
                            v-else
                        >
                            Ok
                        </button>
                        <button
                            type="button"
                            class="btn btn-danger btn-xs btn-color"
                            @click="oldPicker(i.input_place_color, mPlace, 'place')"
                            v-if="showPickerPlace"
                        >
                            X
                        </button>
                        <chrome-picker
                            v-model="getcolorPlace"
                            v-if="showPickerPlace"
                            class="getcolor"
                        ></chrome-picker>
                    </div>
                </div>

                <div class="card-header label-header" @click="designsBtns">
                    <i class="fa fa-minus" v-if="designBtn"></i>
                    <i class="fa fa-plus" v-else></i>
                    <span> Design Btn:</span>
                </div>
                <div v-bind:class="[{ activeLista: designBtn }, 'label-config']">
                    <div class="form-group" style="padding: 0px; padding-right: 20px">
                        <label for="">Btn size:</label>
                        <select
                            v-model="i.btn_size"
                            class="form-control"
                            @change="Btnsize($event)"
                        >
                            <option value="10">10</option>
                            <option value="12">12</option>
                            <option value="14">14</option>
                            <option value="16">16</option>
                            <option value="18">18</option>
                            <option value="20">20</option>
                            <option value="22">22</option>
                            <option value="24">24</option>
                            <option value="26">26</option>
                            <option value="28">28</option>
                            <option value="30">30</option>
                            <option value="32">32</option>
                            <option value="34">34</option>
                            <option value="36">36</option>
                        </select>
                    </div>
                    <div class="form-group" style="padding: 0px; padding-right: 20px">
                        <label for="">Btn weigth:</label>
                        <select
                            v-model="i.btn_weight"
                            class="form-control"
                            @change="Btnweigth($event)"
                        >
                            <option value="400">Normal</option>
                            <option value="bold">Bold</option>
                        </select>
                    </div>
                    <div class="form-group campoColor">
                        <label class="labelColor">Btn Text color:</label>
                        <input
                            type="text"
                            class="form-control boxcolor"
                            v-model="i.btn_color"
                            disabled
                            @click="picker('btnColor', i.btn_color)"
                        />
                        <button
                            type="button"
                            class="btn btn-light btn-xs btn-color muestra"
                            @click="picker('btnColor', i.btn_color)"
                            v-if="showPickerBtnColor == false"
                            :style="mBtnColor"
                        ></button>
                        <button
                            type="button"
                            class="btn btn-success btn-xs btn-color"
                            @click="picker('btnColor', i.btn_color)"
                            v-else
                        >
                            Ok
                        </button>
                        <button
                            type="button"
                            class="btn btn-danger btn-xs btn-color"
                            @click="oldPicker(i.btn_color, mBtnColor, 'btnColor')"
                            v-if="showPickerBtnColor"
                        >
                            X
                        </button>
                        <chrome-picker
                            v-model="getcolorBtnColor"
                            v-if="showPickerBtnColor"
                            class="getcolor"
                        ></chrome-picker>
                    </div>
                    <div class="form-group campoColor">
                        <label class="labelColor">Btn Background:</label>
                        <input
                            type="text"
                            class="form-control boxcolor"
                            v-model="i.btn_bg_color"
                            disabled
                            @click="picker('btnBorder', i.btn_bg_color)"
                        />
                        <button
                            type="button"
                            class="btn btn-light btn-xs btn-color muestra"
                            @click="picker('btnBorder', i.btn_bg_color)"
                            v-if="showPickerBtnBG == false"
                            :style="mBtnBG"
                        ></button>
                        <button
                            type="button"
                            class="btn btn-success btn-xs btn-color"
                            @click="picker('btnBorder', i.btn_bg_color)"
                            v-else
                        >
                            Ok
                        </button>
                        <button
                            type="button"
                            class="btn btn-danger btn-xs btn-color"
                            @click="oldPicker(i.btn_bg_color, mBtnBG, 'btnBorder')"
                            v-if="showPickerBtnBG"
                        >
                            X
                        </button>
                        <chrome-picker
                            v-model="getcolorBtnBG"
                            v-if="showPickerBtnBG"
                            class="getcolor"
                        ></chrome-picker>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { Chrome } from "vue-color";
export default {
    name: "designForm",
    components: {
        "chrome-picker": Chrome,
    },
    data() {
        return {
            customCSS: "",
            form: [],
            styleForm: [],
            designs: [],
            designsOp: false,
            styleActive: 0,
            designLabel: false,
            designInput: false,
            designBtn: false,
            showPickerLavel: false,
            showPickerInputText: false,
            showPickerInputBorder: false,
            showPickerPlace: false,
            showPickerBtnColor: false,
            showPickerBtnBG: false,
            mLabelColor: "background-color: rgba(170,170,170,1);",
            mInputColor: "background-color: rgba(0,0,0,1);",
            mInputBorder: "background-color: rgba(170,170,170,1);",
            mPlace: "background-color: rgba(170,170,170,1);",
            mBtnColor: "background-color: rgba(0,0,0,1);",
            mBtnBG: "background-color: rgba(1,151,32,1);",
            getcolorLabel: "rgba(170,170,170,1)",
            getcolorInputText: "rgba(0,0,0,1)",
            getcolorInputBorder: "rgba(170,170,170,1)",
            getcolorPlace: "rgba(170,170,170,1)",
            getcolorBtnColor: "rgba(0,0,0,1)",
            getcolorBtnBG: "rgba(1,151,32,1)",
        };
    },
    created() {
        this.getForms();
    },
    methods: {
        LabelTextsize(event) {
            this.designs[0].label_size = event.target.value;
        },
        LabelTextweigth(event) {
            this.designs[0].label_weight = event.target.value;
        },
        InputTextsize(event) {
            this.designs[0].input_size = event.target.value;
        },
        InputTextweigth(event) {
            this.designs[0].input_weight = event.target.value;
        },
        PlaceholderTextsize(event) {
            this.designs[0].input_place_size = event.target.value;
        },
        Btnsize(event) {
            this.designs[0].btn_size = event.target.value;
        },
        Btnweigth(event) {
            this.designs[0].btn_weight = event.target.value;
        },
        getForms() {
            if (this.$store.getters["ModApps/Marketea/get_formBuilt"]) {
                this.restore(this.$store.getters["ModApps/Marketea/get_formBuilt"]);
            } else {
                this.$notify({
                    group: "noti",
                    title: "ERROR",
                    type: "error",
                    text: "An error has occurred",
                });
            }
        },
        restore(e) {
            this.form = e.result;
            if (e.s.custom_code != null) {
                this.customCSS = e.s.custom_code;
            } else {
                this.customCSS = "";
            }
            this.styleForm = e.s;
            this.inputs = JSON.parse(this.form.inputs);
            let styleForm = this.styleForm;
            this.getcolorLabel = styleForm.label_color;
            this.getcolorInputText = styleForm.input_color;
            this.getcolorInputBorder = styleForm.input_border_color;
            this.getcolorPlace = styleForm.input_place_color;
            this.getcolorBtnColor = styleForm.btn_color;
            this.getcolorBtnBG = styleForm.btn_bg_color;
            this.mLabelColor = "background-color: " + styleForm.label_color + ";";
            this.mInputColor = "background-color: " + styleForm.input_color + ";";
            this.mInputBorder = "background-color: " + styleForm.input_border_color + ";";
            this.mPlace = "background-color: " + styleForm.input_place_color + ";";
            this.mBtnColor = "background-color: " + styleForm.btn_color + ";";
            this.mBtnBG = "background-color: " + styleForm.btn_bg_color + ";";
            let btn_bg_color = this.styleForm.btn_bg_color;
            let btn_color = this.styleForm.btn_color;
            let btn_size = this.styleForm.btn_size;
            let btn_weight = this.styleForm.btn_weight;
            let input_place_weight = this.styleForm.input_place_weight;
            let input_place_size = this.styleForm.input_place_size;
            let input_size = this.styleForm.input_size;
            let input_weight = this.styleForm.input_weight;
            let label_size = this.styleForm.label_size;
            let label_weight = this.styleForm.label_weight;
            let id = this.styleForm.id;
            let input_border_color = this.styleForm.input_border_color;
            let input_color = this.styleForm.input_color;
            let input_place_color = this.styleForm.input_place_color;
            let label_color = this.styleForm.label_color;
            let designs_id = this.styleForm.designs_id;
            this.designs = [];
            this.designs.push({
                btn_bg_color: btn_bg_color,
                btn_color: btn_color,
                btn_size: btn_size,
                btn_weight: btn_weight,
                id: id,
                designs_id: designs_id,
                input_border_color: input_border_color,
                input_color: input_color,
                input_place_color: input_place_color,
                input_place_size: input_place_size,
                input_place_weight: input_place_weight,
                input_size: input_size,
                input_weight: input_weight,
                label_color: label_color,
                label_size: label_size,
                label_weight: label_weight,
                style:
                    "form.builder label{display:inline-block;margin-bottom:0.1rem;color:{{label_color}};font-size:{{label_size}}px;font-weight:{{label_weight}};}form.builder .form-control{display:block;width:100%;padding:0.375rem 0.75rem;font-size:{{input_size}}px;color:{{input_color}};font-weight:{{input_weight}};border:1px solid {{input_border_color}};line-height:1.5;background-color:#fff;background-clip:padding-box;border-radius:2px;margin-bottom:0.5rem;-webkit-transition:border-color 0.15 sease-in-out,-webkit-box-shadow 0.15 sease-in-out;transition:border-color 0.15 sease-in-out,-webkit-box-shadow 0.15 sease-in-out;transition:border-color 0.15 sease-in-out,box-shadow 0.15 sease-in-out;transition:border-color 0.15 sease-in-out,box-shadow 0.15 sease-in-out,-webkit-box-shadow 0.15 sease-in-out;}form.builder .form-control::placeholder{color:{{input_place_color}};font-size:{{input_place_size}}px;font-weigth:{{input_place_weight}};opacity:1;}form.builder .form-control:-ms-input-placeholder{color:{{input_place_color}};font-size:{{input_place_size}}px;font-weigth:{{input_place_weight}};}form.builder .form-control::-ms-input-placeholder{color:{{input_place_color}};font-size:{{input_place_size}}px;font-weigth:{{input_place_weight}};}form.builder button{padding:10px 20px;font-size:{{btn_size}}px;color:{{btn_color}};font-weight:{{btn_weight}};background-color:{{btn_bg_color}};border-width:1px;border-style:solid;border-color:{{btn_bg_color}};box-shadow:none;-webkit-box-shadow:none;}form.builder button:hover{padding:10px 20px;font-size:{{btn_size}}px;color:{{btn_color}};font-weight:{{btn_weight}};background-color:{{btn_bg_color}};border-width:1px;border-style:solid;border-color:{{btn_bg_color}};box-shadow:none;-webkit-box-shadow:none;}",
            });
        },
        activeDesign(i) {
            this.designsOp = i;
            this.styleActive = i;
        },
        designsLabels() {
            this.designLabel = !this.designLabel;
            this.designInput = false;
            this.designBtn = false;
        },
        designsInputs() {
            this.designInput = !this.designInput;
            this.designLabel = false;
            this.designBtn = false;
        },
        designsBtns() {
            this.designBtn = !this.designBtn;
            this.designInput = false;
            this.designLabel = false;
        },
        oldPicker(c, m, t) {
            c = this.oldColor;
            if (t == "label") {
                this.getcolorLabel = [];
                this.designs[this.styleActive].label_color = [];
                this.showPickerLavel = false;
                this.getcolorLabel = this.oldColor;
                this.mLabelColor = "background-color:" + c + ";";
                this.designs[this.styleActive].label_color = this.oldColor;
            }
            if (t == "inputText") {
                this.showPickerInputText = false;
                this.getcolorInputText = this.oldColor;
                this.mInputColor = "background-color:" + c + ";";
                this.designs[this.styleActive].input_color = this.oldColor;
            }
            if (t == "inputBorder") {
                this.showPickerInputBorder = false;
                this.getcolorInputBorder = this.oldColor;
                this.mInputBorder = "background-color:" + c + ";";
                this.designs[this.styleActive].input_border_color = this.oldColor;
            }
            if (t == "place") {
                this.showPickerPlace = false;
                this.getcolorPlace = this.oldColor;
                this.mPlace = "background-color:" + c + ";";
            }
            if (t == "btnColor") {
                this.showPickerBtnColor = false;
                this.getcolorBtnColor = this.oldColor;
                this.mBtnColor = "background-color:" + c + ";";
            }
            if (t == "btnBorder") {
                this.showPickerBtnBG = false;
                this.getcolorBtnBG = this.oldColor;
                this.mBtnBG = "background-color:" + c + ";";
            }
        },
        picker(t, c) {
            let s = false;
            if (t == "label") {
                this.showPickerLavel = !this.showPickerLavel;
                s = this.showPickerLavel;
                this.designs[0].label_color = this.getcolorLabel;
            }
            if (t == "inputText") {
                this.showPickerInputText = !this.showPickerInputText;
                s = this.showPickerInputText;
                this.designs[0].input_color = this.getcolorInputText;
            }
            if (t == "inputBorder") {
                this.showPickerInputBorder = !this.showPickerInputBorder;
                s = this.showPickerInputBorder;
                this.designs[0].input_border_color = this.getcolorInputBorder;
            }
            if (t == "place") {
                this.showPickerPlace = !this.showPickerPlace;
                s = this.showPickerPlace;
                this.designs[0].input_place_color = this.getcolorPlace;
            }
            if (t == "btnColor") {
                this.showPickerBtnColor = !this.showPickerBtnColor;
                s = this.showPickerBtnColor;
                this.designs[0].btn_color = this.getcolorBtnColor;
            }
            if (t == "btnBorder") {
                this.showPickerBtnBG = !this.showPickerBtnBG;
                s = this.showPickerBtnBG;
                this.designs[0].btn_bg_color = this.getcolorBtnBG;
            }
            if (s) {
                this.oldColor = c;
            }
        },
        arrays(name, data) {
            let k = [];
            k.push({ function: name, data: data });
            this.$store.commit("ModApps/Marketea/set_builtForm", k);
        },
    },
    watch: {
        getcolorLabel: function () {
            if (typeof this.getcolorLabel == "object") {
                let c = this.getcolorLabel.rgba;
                this.getcolorLabel =
                    "rgba(" + c.r + "," + c.g + "," + c.b + "," + c.a + ")";
                this.mLabelColor = "background:" + this.getcolorLabel + ";";
            }
        },
        getcolorInputText: function () {
            if (typeof this.getcolorInputText == "object") {
                let c = this.getcolorInputText.rgba;
                this.getcolorInputText =
                    "rgba(" + c.r + "," + c.g + "," + c.b + "," + c.a + ")";
                this.mInputColor = "background:" + this.getcolorInputText + ";";
            }
        },
        getcolorInputBorder: function () {
            if (typeof this.getcolorInputBorder == "object") {
                let c = this.getcolorInputBorder.rgba;
                this.getcolorInputBorder =
                    "rgba(" + c.r + "," + c.g + "," + c.b + "," + c.a + ")";
                this.mInputBorder = "background:" + this.getcolorInputBorder + ";";
            }
        },
        getcolorPlace: function () {
            if (typeof this.getcolorPlace == "object") {
                let c = this.getcolorPlace.rgba;
                this.getcolorPlace =
                    "rgba(" + c.r + "," + c.g + "," + c.b + "," + c.a + ")";
                this.mPlace = "background:" + this.getcolorPlace + ";";
            }
        },
        getcolorBtnColor: function () {
            if (typeof this.getcolorBtnColor == "object") {
                let c = this.getcolorBtnColor.rgba;
                this.getcolorBtnColor =
                    "rgba(" + c.r + "," + c.g + "," + c.b + "," + c.a + ")";
                this.mBtnColor = "background:" + this.getcolorBtnColor + ";";
            }
        },
        getcolorBtnBG: function () {
            if (typeof this.getcolorBtnBG == "object") {
                let c = this.getcolorBtnBG.rgba;
                this.getcolorBtnBG =
                    "rgba(" + c.r + "," + c.g + "," + c.b + "," + c.a + ")";
                this.mBtnBG = "background:" + this.getcolorBtnBG + ";";
            }
        },
        get_builtForm: function () {
            if (this.get_builtForm) {
                let g = this.$store.getters["ModApps/Marketea/get_builtForm"];
                let fun = g[0].function;
                if (fun == "cancelDesign") {
                    this.getForms();
                }
                if (fun == "SaveDesign") {
                    let data = [];
                    data.push({
                        inputs: this.inputs,
                        form: this.form,
                        designs: this.designs,
                        custom_code: this.customCSS,
                    });
                    this.arrays("RSaveDesign", data);
                }
            }
        },
        designs: {
            handler: function (after, before) {
                if (before != "") {
                    let r = [];
                    r.push({
                        designs: after,
                        designsOp: this.designsOp,
                        styleActive: this.styleActive,
                        designLabel: this.designLabel,
                        designBtn: this.designBtn,
                        designInput: this.designInput,
                    });
                    this.$store.commit("ModApps/Marketea/set_desingStorage", r);
                } else {
                    let g = this.$store.getters["ModApps/Marketea/get_desingStorage"];
                    let count = g.length;
                    if (count > 0) {
                        this.designs = g[0].designs;
                        this.designsOp = g[0].designsOp;
                        this.styleActive = g[0].styleActive;
                        this.designLabel = g[0].designLabel;
                        this.designBtn = g[0].designBtn;
                        this.designInput = g[0].designInput;
                        this.designLabel = g[0].designLabel;
                    }
                }
            },
            deep: true,
        },
    },
    computed: {
        ...mapGetters("forms", ["get_builtForm"]),
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
            };
        },
    },
};
</script>
<style lang="scss" scoped>
.label-config {
    height: 0px;
    transform: scaleY(0);
    overflow: hidden;
    transition: transform 0.3s ease-out;
}
.activeLista {
    overflow: initial !important;
    height: auto !important;
    transform: scaleY(1);
    transform-origin: top;
}
.card-header.label-header {
    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border: 1px solid #000;
    margin: 5px 00px;
    font-weight: bold;
    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
        cursor: pointer;
    }
}
.boxcolor {
    width: 60%;
    display: inline-block;
    border-radius: 0;
    height: 33px;
    float: left;
}
.editarDesigns label.c-input.c-radio {
    display: block;
    font-size: 20px;
}
.labelColor {
    display: block;
    width: 100%;
}
button.btn.btn-light.btn-xs.btn-color {
    padding: 5px 10px !important;
    border-radius: 0 !important;
    display: inline-block !important;
    height: 33px;
    float: left;
    border: 1px solid #ccc !important;
    width: 20px !important;
}
.campoColor {
    margin-bottom: 50px;
}
button.btn.btn-light.btn-xs.btn-color.muestra {
    padding: 5px 16px !important;
}
.btn-color {
    padding: 9px 15px !important;
    border-radius: 0px !important;
}
.btn-color:focus {
    box-shadow: none;
}
</style>
