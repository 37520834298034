<template>
	<div class="container-fluid">
		<div class="card" style="padding-left: 40px;">
			<div class="card-body">
				<div class="row content">
					<div class="colAllVisitors">
						<h4
                            style="
                                color: #9c9c9c;
                                font-weight: normal;
                                font-size: 20px;
                            "
                        >
                            All visitors
                        </h4>
                        <div class="list-group list-group-flush" v-if="isLoading">
                        	<div class="list-group-item list-group-item-action itemSession"
                        	>
                                <div class="idsession">
                                	<span class="loadH1"><span class="loadingBar"></span></span>
                                	<i class="fas fa-angle-double-right"></i>
                            	</div>
                                <span class="pais">
                                	<i class="fas fa-globe"></i>
                                	<span class="loadH"><span class="loadingBar"></span></span>
                                </span>
                                <span class="device">
                                	<i class="fas fa-desktop"></i>
                                	<span class="loadH2"><span class="loadingBar"></span></span>
                                </span>
                                <span class="lastpage">
                                	<span class="loadH"><span class="loadingBar"></span></span>
                                </span>
                                <span class="lastActivity">
                                	<i class="fas fa-clock"></i>
                                	<span class="loadH2"><span class="loadingBar"></span></span>
                            	</span>
                            </div>
                        </div>
                        <div class="list-group list-group-flush" v-else>
                        	<div v-for="(i, index) in listLog"
                        		:key="index"
                        		v-bind:class="[
                        		'list-group-item list-group-item-action',
                        		'itemSession',
                        		i.idsesion==visitorDetails.idsesion ? 'active' : ''+visitorDetails.idsesion+'']"
                        		@click="openVisit(i)"
                        	>
                                <div class="idsession">
                                	{{i.idsesion}}
                                	<i class="fas fa-angle-double-right"></i>
                            	</div>
                                <span class="pais">
                                	<i class="fas fa-globe"></i>
                                	{{i.ip_info.country}}
                                </span>
                                <span class="device">
                                	<i class="fas fa-desktop" 
                                	v-if="i.device=='Desktop'"></i>
                                	<i class="fas fa-tablet-alt" 
                                	v-else-if="i.device=='Tablet' || i.device=='iPad'"></i>
                                	<i class="fas fa-mobile-alt" 
                                	v-else-if="i.device=='Mobile'"></i>
                                	{{i.device}}
                                </span>
                                <span class="lastpage">
                                	<span v-if="i.pages[0].title!=null">
                                		{{i.pages[0].title}}
                                	</span>
                                	<span v-else>
                                		{{i.pages[0].url}}
                                	</span>
                                </span>
                                <span class="lastActivity">
                                	<i class="fas fa-clock"></i>
                                	{{i.lastActivity}}
                            	</span>
                            </div>
                        </div>
						
					</div>
					<div class="visitDeails">
						<h4
                            style="
                                color: #9c9c9c;
                                font-weight: normal;
                                font-size: 20px;
                            "
                        >
							Visitor details 
                        </h4>
                        <div class="content-details">
                        	<div v-if="isLoading2">
                        		<div>
                        			<span class="loadH1"><span class="loadingBar"></span></span>
                        		</div>
                        		<div>
                        			<span class="loadH"><span class="loadingBar"></span></span>
                        		</div>
                        		<div>
                        			<span class="loadH2"><span class="loadingBar"></span></span>
                        		</div>
                        	</div>
                        	<div v-else-if="visitorDetails.idsesion>0">
	                        	<div class="headSession" >
	                        		<div class="icon"><i class="fas fa-globe"></i></div>
	                        		<div class="infoHead">
	                        			<div class="idUser"><h3>{{visitorDetails.idsesion}}</h3></div>
	                        			<div class="location">
	                        				{{ visitorDetails.ip_info.country}}. {{ visitorDetails.ip_info.regionName}}, {{ visitorDetails.ip_info.city}} {{ visitorDetails.ip_info.zip }}
	                        			</div>
	                        		</div>
	                        	</div>
	                        	<div class="bodySession">
	                        		<div class="row">
	                        			<div class="col-md-4 mt20">
	                        				<h4>Visitor Since:</h4>
	                        				<i class="fas fa-clock"></i>
	                        				{{visitorDetails.date_create}} 
	                        				{{visitorDetails.hora}}
	                        			</div>
	                        			<div class="col-md-4 mt20">
	                        				<h4>Last visited:</h4>
	                        				<i class="fas fa-clock"></i>
	                        				{{visitorDetails.lastVisited}}
	                        			</div>
	                        			<div class="col-md-4 mt20">
	                        				<h4>Total Visits:</h4>
	                        				<i class="fas fa-globe-americas"></i>
	                        				{{visitorDetails.visits}}
	                        			</div>
	                        			<div class="col-md-4 mt20">
	                        				<h4>Referral:</h4>
	                        				{{visitorDetails.referer}}
	                        			</div>
	                        			<div class="col-md-4 mt20">
	                        				<h4>Device:</h4>
	                        				<i class="fas fa-desktop" 
		                                	v-if="visitorDetails.device=='Desktop'"></i>
		                                	<i class="fas fa-tablet-alt" 
		                                	v-else-if="visitorDetails.device=='Tablet' || visitorDetails.device=='iPad'"></i>
		                                	<i class="fas fa-mobile-alt" 
		                                	v-else-if="visitorDetails.device=='Mobile'"></i>
		                                	{{visitorDetails.device}}
	                        			</div>
	                        			<div class="col-md-4 mt20">
	                        				<h4>OS:</h4>
	                        				<i class="fab fa-apple" v-if="visitorDetails.os=='Mac Os'"></i>
	                        				<i class="fab fa-windows" v-else-if="visitorDetails.os=='Windows'"></i>
	                        				<i class="fab fa-linux" v-else-if="visitorDetails.os=='Linux'"></i>
	                        				<i class="fab fa-android" v-else-if="visitorDetails.os=='Android'"></i>
	                        				{{visitorDetails.os}}
	                        			</div>
	                        			<div class="col-md-4 mt20">
	                        				<h4>Browser:</h4>
	                        				<i class="fab fa-chrome" 
	                        				v-if="visitorDetails.browser.indexOf('Chrome')>-1"></i>
	                        				<i class="fab fa-firefox-browser" 
	                        				v-else-if="visitorDetails.browser.indexOf('Firefox')>-1"></i>
	                        				<i class="fab fa-safari" 
	                        				v-else-if="visitorDetails.browser.indexOf('Safari')>-1"></i>
	                        				<i class="fab fa-edge" 
	                        				v-else-if="visitorDetails.browser.indexOf('Edge')>-1"></i>
	                        				<i class="fab fa-internet-explorer" 
	                        				v-else-if="visitorDetails.browser.indexOf('msie')>-1"></i>
	                        				<i class="fab fa-opera" 
	                        				v-else-if="visitorDetails.browser.indexOf('Opera')>-1"></i>
	                        				<i class="far fa-window-maximize" v-else></i>
	                        				{{visitorDetails.browser}}
	                        			</div>
	                        			<div class="col-md-4 mt20">
	                        				<h4>Ip:</h4>
	                        				<i class="fas fa-network-wired"></i>
	                        				{{visitorDetails.ip}}
	                        			</div>
	                        			<div class="col-md-4 mt20">
	                        				<h4>User Agent:</h4>
	                        				{{visitorDetails.userAgent}}
	                        			</div>
	                        		</div>
	                        		<div class="row">
	                        			<div class="col-md-12 ">
	                        				<h3>Top pages:</h3>
	                        				<div class="list-group list-group-flush">
												<div class="list-group-item list-group-item-action "
												v-for="(i, index) in visitorDetails.pages"
												:key="index"
												>
												<h4><b>Title:</b> {{i.title}}</h4>
												<h5><b>Url:</b> {{i.url}}</h5>
												<h6><b>Referral:</b> {{i.referer}}</h6>
												<div class="pageVisites">
													<h6><b>Total Visits:</b><br> {{i.visits}}</h6>
													<h6><b>Visitor Since:</b><br> {{i.visitSince}}</h6>
													<h6><b>Last visited:</b><br> {{i.lastVisited}}</h6>
												</div>
												</div>
											</div>
	                        			</div>
	                        		</div>
	                        	</div>
                        	</div>
                        	<div v-else>
                        		no hay datos seleccionados
                        	</div>
                        	
                        </div>
					</div>
				</div>
			</div>		
		</div>		
	</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
	name: 'RoofCalLog',
	components: {
	},
	data() {
		return {
			isLoading: true,
			isLoading2: true,
			user_id: window.master_client,
			org_id: window.localorgdata,
			filter: '',
			listLog: [],
			apis: {},
			visitorDetails: {idsesion:0},
		};
	},
	created() {
		let t = this;
		let urlApis = t.$store.getters['ModApps/RoofCal/urlApis'];
		t.apis = urlApis.setup;
		window.localStorage.removeItem('activeLogAct');
		t.getLogActivity(1);
	},
	methods: {
		getLogActivity(load){
			let t = this;
			if(load==1){t.isLoading = true;}
			window.master.post(t.apis.getLog(), {
				user_id: window.master_client,
				org_id: window.localorgdata,
				filter: t.filter,
			})
			.then((response) => {
				t.listLog = response.data.r;
				console.log('listLog', t.listLog);
				if(t.listLog.length>0 && t.visitorDetails.idsesion==0){
					t.visitorDetails = t.listLog[0];
					t.isLoading2=false;
				}else{
					for (let i in t.listLog) {
						if(t.listLog[i].idsesion == t.visitorDetails.idsesion){
							t.visitorDetails = t.listLog[i];
							console.log('actualizar item', t.visitorDetails);
						}
					}
				}
				t.isLoading = false;
				if(!window.localStorage.getItem('activeLogAct')){
					setInterval(function(){
						if(window.localStorage.getItem('appIDactual')==5 && t.$router.currentRoute.name == "RoofCalLog"){
							let d = new Date();
							let time = d.getHours() +''+ d.getMinutes()
							if(!window.localStorage.getItem('activeLogAct')){
								window.localStorage.setItem('activeLogAct', 1);
							}
							let logAct = window.localStorage.getItem('activeLogAct');
							logAct = parseInt(logAct);
							if(time> logAct){
								window.localStorage.setItem('activeLogAct', time);
								console.log('activeLogAct');
								t.getLogActivity(0);
							}else{
								console.log('no cumple con el min para activeLogAct');
							}
						}
					}, (60*1000));
				}
			})
			.catch((error) => {
				console.log(error);
				t.isLoading = false;
				t.isLoading2=false;
			});
		},
		openVisit(i){
			let t = this;
			t.isLoading2 = true;
			window.master.post(t.apis.getLog(), {
				user_id: window.master_client,
				org_id: window.localorgdata,
				filter: i.idsesion,
			})
			.then((response) => {
				console.log('openVisit', response.data.r);
				t.visitorDetails = response.data.r[0];
				t.isLoading2 = false;
			})
			.catch((error) => {
				console.log(error);
				t.isLoading2 = false;
			});
		}
	},
	watch: {
		accitonPusher: function () {
			let t = this;
			if (t.accitonPusher != undefined) {
				let data = t.accitonPusher;
				if(window.localorgdata == data.org_id && window.master_client == data.user_id){
					if(data.action === "RoofCalLeads"){
						this.getLogActivity(0);
					}
					else if(data.action === "RoofCalLeads validPhone"){
						this.getLogActivity(0);
					}
					else if(data.action === "RoofCalVisit"){
						this.getLogActivity(0);
					}
				}
			}
		},
	},
	computed: {
		...mapGetters("core", { 
			accitonPusher: "get_accitonPusher"
		}),
	},
}
</script>
<style lang="css" scoped>
.page-wrapper > .container-fluid{
    padding-top: 0px;
    padding-bottom: 0px;
    min-height: calc(100vh - 50px);
}
.card-body {
	padding-top: 0px;
	padding-bottom: 0px;
}
.card{
	background-color: transparent;
}
.content{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: center;
    align-items: flex-start;
}
.colAllVisitors{
	width: 320px;
    height: calc(100vh - 55px);
    overflow-x: hidden;
    overflow-y: auto;
    margin-right: 10px;
}
.visitDeails{
	width: calc(100% - 350px);
}
.content-details{	
    background-color: #fff;
    height: calc(100vh - 90px);
    overflow-x: hidden;
    overflow-y: auto;
	border-radius: 10px;
    box-shadow: 1px 2px 6px 0px rgb(0 0 0 / 19%);
    padding: 10px 15px;
}
.itemSession{
	width: 300px;
	border-radius: 10px;
    margin-bottom: 10px;
    border: 1px solid #fafafa;
    box-shadow: 1px 2px 6px 0px rgb(0 0 0 / 19%);
    padding: 10px;
}
.idsession {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}
.pais {
	display: inline-block;
	width: 160px;
    float: left;
    font-size: 14px;
    margin-bottom: 10px;
    border: 0px solid #ccc;
}
.device {
	display: inline-block;
	width: 90px;
    float: right;
    text-align: left;
    font-size: 12px;
    margin-bottom: 10px;
    border: 0px solid #ccc;
}
.lastpage {
	display: inline-block;
	width: 180px;
    float: left;
    font-size: 14px;
    border: 0px solid #ccc;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.lastActivity {
	display: inline-block;
	width: 90px;
    float: right;
    text-align: left;
    font-size: 12px;
    color: #8a8888;
    border: 0px solid #ccc;
}
.loadH{
	display: inline-block;
	width: 100%;
	max-width: 140px;
	height: 15px;
	padding-left: 5px;
	padding-right: 5px;
}
.loadH1{
	display: inline-block;
	width: 100%;
	max-width: 100px;
	height: 15px;
	padding-left: 5px;
	padding-right: 5px;
}
.loadH2{
	display: inline-block;
	width: 100%;
	max-width: 60px;
	height: 15px;
	padding-left: 5px;
	padding-right: 5px;
}
.loadingBar{	
	display: block;
	width: 100%;
	opacity: 1;
	height: 100%;
	background-color: #ccc;
	transition: all;
	-webkit-animation: loadBar 2s infinite step-start both;
	animation: loadBar 2s infinite step-start both;
}
@-webkit-keyframes loadBar {
	0% {width: 100%;opacity: 1;}
	2% {width: 2%;opacity: 0.02;}
	4% {width: 4%;opacity: 0.04;}
	6% {width: 6%;opacity: 0.06;}
	8% {width: 8%;opacity: 0.08;}
	10% {width: 10%;opacity: 0.10;}
	12% {width: 12%;opacity: 0.12;}
	14% {width: 14%;opacity: 0.14;}
	16% {width: 16%;opacity: 0.16;}
	18% {width: 18%;opacity: 0.18;}
	20% {width: 20%;opacity: 0.20;}
	22% {width: 22%;opacity: 0.22;}
	24% {width: 24%;opacity: 0.24;}
	26% {width: 26%;opacity: 0.26;}
	28% {width: 28%;opacity: 0.28;}
	30% {width: 30%;opacity: 0.30;}
	32% {width: 32%;opacity: 0.32;}
	34% {width: 34%;opacity: 0.34;}
	36% {width: 36%;opacity: 0.36;}
	38% {width: 38%;opacity: 0.38;}
	40% {width: 40%;opacity: 0.40;}
	42% {width: 42%;opacity: 0.42;}
	44% {width: 44%;opacity: 0.44;}
	46% {width: 46%;opacity: 0.46;}
	48% {width: 48%;opacity: 0.48;}
	50% {width: 50%;opacity: 0.50;}
	52% {width: 52%;opacity: 0.52;}
	54% {width: 54%;opacity: 0.54;}
	56% {width: 56%;opacity: 0.56;}
	58% {width: 58%;opacity: 0.58;}
	60% {width: 60%;opacity: 0.60;}
	62% {width: 62%;opacity: 0.62;}
	64% {width: 64%;opacity: 0.64;}
	66% {width: 66%;opacity: 0.66;}
	68% {width: 68%;opacity: 0.68;}
	70% {width: 70%;opacity: 0.70;}
	72% {width: 72%;opacity: 0.72;}
	74% {width: 74%;opacity: 0.74;}
	76% {width: 76%;opacity: 0.76;}
	78% {width: 78%;opacity: 0.78;}
	80% {width: 80%;opacity: 0.80;}
	82% {width: 82%;opacity: 0.82;}
	84% {width: 84%;opacity: 0.84;}
	86% {width: 86%;opacity: 0.86;}
	88% {width: 88%;opacity: 0.88;}
	90% {width: 90%;opacity: 0.90;}
	92% {width: 92%;opacity: 0.92;}
	94% {width: 94%;opacity: 0.94;}
	96% {width: 96%;opacity: 0.96;}
	98% {width: 98%;opacity: 0.98;}
	100% {width: 100%;opacity: 1;}
}
@keyframes loadBar {
	0% {width: 100%;opacity: 1;}
	2% {width: 2%;opacity: 0.02;}
	4% {width: 4%;opacity: 0.04;}
	6% {width: 6%;opacity: 0.06;}
	8% {width: 8%;opacity: 0.08;}
	10% {width: 10%;opacity: 0.10;}
	12% {width: 12%;opacity: 0.12;}
	14% {width: 14%;opacity: 0.14;}
	16% {width: 16%;opacity: 0.16;}
	18% {width: 18%;opacity: 0.18;}
	20% {width: 20%;opacity: 0.20;}
	22% {width: 22%;opacity: 0.22;}
	24% {width: 24%;opacity: 0.24;}
	26% {width: 26%;opacity: 0.26;}
	28% {width: 28%;opacity: 0.28;}
	30% {width: 30%;opacity: 0.30;}
	32% {width: 32%;opacity: 0.32;}
	34% {width: 34%;opacity: 0.34;}
	36% {width: 36%;opacity: 0.36;}
	38% {width: 38%;opacity: 0.38;}
	40% {width: 40%;opacity: 0.40;}
	42% {width: 42%;opacity: 0.42;}
	44% {width: 44%;opacity: 0.44;}
	46% {width: 46%;opacity: 0.46;}
	48% {width: 48%;opacity: 0.48;}
	50% {width: 50%;opacity: 0.50;}
	52% {width: 52%;opacity: 0.52;}
	54% {width: 54%;opacity: 0.54;}
	56% {width: 56%;opacity: 0.56;}
	58% {width: 58%;opacity: 0.58;}
	60% {width: 60%;opacity: 0.60;}
	62% {width: 62%;opacity: 0.62;}
	64% {width: 64%;opacity: 0.64;}
	66% {width: 66%;opacity: 0.66;}
	68% {width: 68%;opacity: 0.68;}
	70% {width: 70%;opacity: 0.70;}
	72% {width: 72%;opacity: 0.72;}
	74% {width: 74%;opacity: 0.74;}
	76% {width: 76%;opacity: 0.76;}
	78% {width: 78%;opacity: 0.78;}
	80% {width: 80%;opacity: 0.80;}
	82% {width: 82%;opacity: 0.82;}
	84% {width: 84%;opacity: 0.84;}
	86% {width: 86%;opacity: 0.86;}
	88% {width: 88%;opacity: 0.88;}
	90% {width: 90%;opacity: 0.90;}
	92% {width: 92%;opacity: 0.92;}
	94% {width: 94%;opacity: 0.94;}
	96% {width: 96%;opacity: 0.96;}
	98% {width: 98%;opacity: 0.98;}
	100% {width: 100%;opacity: 1;}
}
.list-group-item.list-group-item-action.itemSession:hover, .list-group-item.list-group-item-action.itemSession:focus,
.list-group-item.list-group-item-action.itemSession.active {
    color: #4F5467;
    text-decoration: none;
    background-color: #f6fafe;
}
.headSession {
    display: flex;
}
.icon {
    font-size: 50px;
    margin-right: 20px;
}
.infoHead {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
}
.bodySession{
}
.bodySession div.mt20{
	margin-top: 20px;
}
.pageVisites{
	display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
}
</style>
