<template>
    <div>
        <div class="card">
            <div class="card-header">
                Add Account
            </div>
            <div class="card-body">
                <form @submit.prevent="MetSAddChartAccount">
                    <div class="form-row">
                        <div class="col-12">
                            <span id="emailHelp" class="form-text text-muted">
                                Account Type:</span
                            >
                            <select
                                class="form-control"
                                v-model="FormReg.account_type_id"
                                required
                            >
                                <option value="">--Select--</option>
                                <option
                                    v-for="item in ResultListTypes"
                                    :key="item.id"
                                    v-bind:value="item.id"
                                >
                                    {{ item.name_account }}
                                </option>
                            </select>
                        </div>
                        <div class="col-12">
                            <span id="emailHelp" class="form-text text-muted">
                                Account Name</span
                            >
                            <input
                                type="text"
                                class="form-control"
                                v-model="FormReg.name"
                                required
                            />
                        </div>
                        <div class="col-12">
                            <span id="emailHelp" class="form-text text-muted">
                                Description:</span
                            >
                            <textarea
                                name=""
                                id=""
                                cols="10"
                                rows="2"
                                class="form-control form-text w100"
                                style="width: 100%"
                                v-model="FormReg.description"
                            ></textarea>
                        </div>
                        <div class="col-12">
                            <span id="emailHelp" class="form-text text-muted">.</span>
                            <button type="submit" class="btn btn-success float-right">
                                {{ FormReg.btnName }}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: { },
    name: "formChartAccounts",
    data () {
        return {
            m: {
                show: false,
                component: '',
                data: []
            },
            ResultListTypes: [],
            FormReg: {
                btnName: "SAVE",
                Action: "Add",
                id: null,
                name: null,
                description: null,
                account_type_id: "",
            },                                    
        }
    },
    created () {
        this.getAccountTypes();
        this.init();
    },
    methods: {
        init() {
            let g = this.$store.getters['core/getOpenModal'];
            let r = g.data;            
            this.FormReg.btnName = r.FormReg.btnName;
            this.FormReg.Action = r.FormReg.Action;
            this.FormReg.id = r.FormReg.id;
            this.FormReg.name = r.FormReg.name;
            this.FormReg.description = r.FormReg.description;
            this.FormReg.account_type_id = r.FormReg.account_type_id;
        },  
        getAccountTypes() {
            this.isLoading = true;
            window.billing
                .get("account-types/" + window.localuserdata)
                .then((response) => {
                    this.ResultListTypes = response.data;
                    this.isLoading = false;
                })
                .catch((error) => console.log(error));
        },        
        MetSAddChartAccount() {
            this.$store.commit("core/setLoadingModal", true);
            if (this.FormReg.Action == "Add") {
                window.billing
                    .post("chart-account/add", {
                        user_id: window.localuserdata,
                        name_account: this.FormReg.name,
                        account_type_id: this.FormReg.account_type_id,
                        description: this.FormReg.description,
                    })
                    .then((response) => {
                        this.FormReg.name = null;
                        this.FormReg.state_id = 0;
                        this.NoRecord = false;

                        this.$notify({
                            group: "noti",
                            title: "Add",
                            type: "success",
                            text: "Add Chart Successful",
                        });
                        this.$store.commit("core/setLoadingModal", false);
                        this.$store.commit("core/setCloseModal", {action: 'saved'});                       
                    })
                    .catch((error) => {
                        console.log(error, (this.isLoading = false))
                        this.$store.commit("core/setLoadingModal", false);
                    });
            }
            if (this.FormReg.Action == "Update") {
                this.$store.commit("core/setLoadingModal", true);
                window.billing
                    .put("chart-account/edit/" + this.FormReg.id, {
                        user_id: window.localuserdata,
                        name_account: this.FormReg.name,
                        account_type_id: this.FormReg.account_type_id,
                        description: this.FormReg.description,
                    })
                    .then((response) => {
                        this.FormReg.id = null;
                        this.FormReg.name = null;
                        this.FormReg.account_type_id = "";
                        this.FormReg.description = null;
                        this.FormReg.btnName = "SAVE";
                        this.FormReg.Action = "Add";
                        this.NoRecord = null;

                        this.$notify({
                            group: "noti",
                            title: "Updated",
                            type: "success",
                            text: "Updated Chart Successful",
                        });
                        this.$store.commit("core/setLoadingModal", false);
                        this.$store.commit("core/setCloseModal", {action: 'saved'});                         
                    })
                    .catch((error) => {
                        console.log(error, (this.isLoading = false))
                        this.$store.commit("core/setLoadingModal", false);
                    });
            }
        },
    },
    watch: { },
    computed: {},
};
</script>

<style lang="css" scoped></style>
