let newAddRefresh = function(job, positionItem) {
    let t = this;
    let url = this.apis.newAddRefresh()
    window.master
        .get(url +'/'+ job.id)
        .then((response) => {
            let r = response.data.result;
            let list = t.dataJobDash(r, t.editorTag, t.editarTags); //filter
            if (
                t.typez == 0 ||
                (list[0].user_id_assign.length > 0 &&
                    list[0].user_id_assign.indexOf(
                        parseInt(window.localAccountID)
                    ) > -1)
            ) {
                let valid = true;
                for (let j in list) {
                    for (var k in t.listTaksResult) {
                        if (t.listTaksResult.hasOwnProperty(k)) {
                            if (t.listTaksResult[k].id_i == list[j].id_i) {
                                valid = false;
                            }
                        }
                    }
                }
                //debugger;
                let newList = [];
                let addItem = 0;
                if (positionItem == 0) {
                    newList = list;
                }
                if (valid) {
                    for (var l in t.listTaks) {
                        if (t.listTaks.hasOwnProperty(l)) {
                            if (positionItem == 0 || addItem == 1) {
                                newList.push(t.listTaks[l]);
                            } else {
                                if (
                                    t.listTaks[l].id_i > list[0].id_i &&
                                    addItem == 0
                                ) {
                                    newList.push(t.listTaks[l]);
                                } else {
                                    newList.push(list[0]);
                                    newList.push(t.listTaks[l]);
                                    addItem = 1;
                                }
                            }
                        }
                    }
                    t.listTaks = newList;
                    t.reEscribirLista();
                    let tag_id = t.id_tags.length > 0 ? t.id_tags[0] : 0;
                    t.update_JOPending(tag_id);
                    t.starGrid();
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
};
export default newAddRefresh;
