var jobs = "/jobs/";
export default {  
	getJobsDash(){
		return jobs+"getsearchjobsdash";
	},
	getDataForm(){
		return jobs+"getDataForm";
	},
	getDataBilling(){
		return jobs+"getDataBilling";
	},
	del(id){
		return jobs+"delJobs/" + window.master_client + "/" + window.localorgdata + "/" + id;
	},	
	getJobsInfoByID(id){
		return jobs+"jobinfobyid/" + id;
	},
    addJobs(){
		return jobs+"addJobs";
	},
    editJobs(){
		return jobs+"editJobs";
	},

    addJobsTags(){
		return jobs+"addJobsTags";
	},
    getTemplateByID(id){
		return jobs+"getTemplateByID/" + id;
	},    
	addToDoList(id){
		return jobs+"getTemplateByID/" + id;
	},
};
