export default {
    ActiveJobs(state, val) {state.jobs = val},
    ActiveLateral(state, val) { state.lateralShow = val; },
    ActiveModalTo(state, val) { state.modalAddTo = val; },
    set_modalEditToID(state, val) { state.formTo.id = val; },
    set_alertTosaved(state, val) { state.alertTosaved = val; },
    set_JOPending(state, val) { state.jobsOrderPending = val; },
    set_menuTags(state, val) { state.menu_tags = val; },
    set_jobsListAssignUser(state, val) { 
        state.jobsListAssignUser.user_id_assign = val;
        // state.jobsListAssignUser.user_id_assign = val.id;  
        // state.jobsListAssignUser.name = val.name;  
        // state.jobsListAssignUser.email = val.email;  
        state.changeAssignUser= true;  
    },
    changeAssignUserOff(state) { 
        state.jobsListAssignUser.user_id_assign = [];
        // state.jobsListAssignUser.name = null;
        // state.jobsListAssignUser.email = null;
        state.changeAssignUser = false;  
    },
    set_searchJobs(state, val) { state.searchJobs=val;},
    set_typeVisor(state, val) { state.typeVisor=val;},
    set_actList(state) { state.act_list++; },
    set_itemJob(state, val) { state.itemJob=val;},
    set_editTask(state, val) { state.editTask=val;},
    set_delTags(state, val) { state.delTags=val;},
    set_editTags(state, val) { state.editTags=val;},
    set_editCusInv(state, val) { state.editCusInv=val;},
    set_showCus(state, val) { state.showCus=val;},
    set_ActiveMA(state, val) { state.ActiveMA=val;},
    set_refresh_grid(state, val) { state.refresh_grid=val;},
	get_todolist(state, val) { state.todolist=val;},    
    set_refresh_list(state, val) { state.refresh_list=val;},
    set_jobsDash(state, val) { state.jobsDash=val;},
    setDataFilterTagsUrl(state, val) { state.datafilterTagsUrl=val;},
    setSortJobsBy(state, val) { state.sortJobsBy=val;},
    setSeeTagsDefault(state, val) { state.seeTagsDefault=val;},
}
