<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-md-12">
                                    <h2 class="page-title" style="font-size: 18px">
                                        Slabs Gallery
                                    </h2>
                                </div>
                                <div class="col-md-6">
                                    <button
                                        @click="addIMG"
                                        class="btn btn-success float-left"
                                    >
                                        <i class="fas fa-plus"></i> Add New
                                    </button>
                                </div>
                                <div class="col-md-6">
                                    <div style="display: flex; justify-content: flex-end">
                                        <button
                                            type="button"
                                            class="btn btn-primary btn-ms"
                                            style="
                                                margin-right: 5px;
                                                display: flex;
                                                align-items: center;
                                            "
                                        >
                                            <input
                                                type="checkbox"
                                                v-model="selectAll"
                                                id="selAll"
                                            /><label
                                                for="selAll"
                                                style="padding-left: 3px"
                                                >Select All</label
                                            >
                                        </button>
                                        <button
                                            type="button"
                                            class="btn btn-danger btn-ms"
                                            style="margin-right: 5px"
                                            v-if="selectedItems.length"
                                            @click="DeleteAllItems"
                                        >
                                            Delete Items
                                            <b>({{ selectedItems.length }})</b>
                                        </button>
                                        <router-link
                                            :to="{ name: 'SlabsSlabs' }"
                                            class="btn btn-secondary float-right"
                                        >
                                            <i class="fas fa-chevron-left"></i> Back
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div
                                class="col-12"
                                v-if="addImage"
                                style="position: relative"
                            >
                                <span
                                    style="
                                        position: absolute;
                                        top: 2px;
                                        top: 10px;
                                        right: 23px;
                                        z-index: 100;
                                    "
                                    @click="CloseDrop"
                                >
                                    <i class="fas fa-times"></i
                                ></span>
                                <vue-dropzone
                                    ref="uploadGallerys"
                                    id="dropzone"
                                    :options="dropzoneOptions"
                                    :useCustomSlot="true"
                                    @vdropzone-sending="sendingEvent"
                                    @vdropzone-success="vsuccess"
                                    @vdropzone-queue-complete="vqueueComplete"
                                    @vdropzone-sending-multiple="vsendingMuliple"
                                    @vdropzone-removed-file="vremoved"
                                >
                                    <div class="dropzone-custom-content">
                                        <h3 class="dropzone-custom-title">
                                            Drag and drop to upload image!
                                        </h3>
                                        <div class="subtitle">
                                            ...or click to select a image from your
                                            computer
                                        </div>
                                    </div>
                                </vue-dropzone>
                            </div>
                            <div class="col-md-12">
                                <div class="contenedor-gallery">
                                    <div class="bgloading" v-show="isLoading">
                                        <Loader></Loader>
                                    </div>
                                    <div class="card-body" v-if="listGallery.length">
                                        <draggable
                                            v-model="listGallery"
                                            tag="ul"
                                            class="ul-reset listItems"
                                            v-bind="dragOptions"
                                            @change="checkMove"
                                            @start="isDragging = true"
                                            @end="isDragging = false"
                                            handle=".handle"
                                        >
                                            <li v-for="i in listGallery" :key="i.id">
                                                <div class="iHeader handle">
                                                    <div class="btnmove">
                                                        <i class="fas fa-ellipsis-v"></i>
                                                    </div>
                                                    {{ i.name }}
                                                </div>
                                                <div class="iContent">
                                                    <img
                                                        :src="i.img"
                                                        @click="EditTitle(i, i.id, i.img)"
                                                    />
                                                </div>
                                                <div
                                                    class="delete"
                                                    @click="deleteItem(i.id)"
                                                >
                                                    <i class="fas fa-times"></i>
                                                </div>
                                                <div class="checkItems">
                                                    <label class="container">
                                                        <input
                                                            type="checkbox"
                                                            v-model="selectedItems"
                                                            :value="i.id"
                                                        />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                            </li>
                                        </draggable>
                                    </div>
                                    <div class="card-body" v-else>
                                        <div style="text-align: center">No record</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal
            name="modal-gallery"
            width="800px"
            height="auto"
            :resizable="true"
            :adaptive="true"
            :scrollable="true"
            class="modalgallery"
        >
            <div class="card-header" style="position: relative">
                <span style="font-size: 18px">{{ title }}</span>
                <i
                    class="fas fa-times"
                    style="
                        position: absolute;
                        top: 9px;
                        right: 11px;
                        font-size: 24px;
                        cursor: pointer;
                    "
                    @click="closeModal"
                ></i>
            </div>
            <form
                @submit.prevent="UpdateInfo"
                enctype="multipart/form-data"
                id="form1"
                class="custominputs"
            >
                <div class="card-body">
                    <div class="imgmodalContent2">
                        <div class="img">
                            <img :src="imgLarge" style="margin: 0 auto" />
                        </div>
                        <div class="imgInfo">
                            <div class="row" style="padding-bottom: 10px">
                                <div class="col-12">
                                    <label>Name</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="name"
                                        id=""
                                    />
                                </div>
                            </div>
                            <span
                                >Date: <b>{{ date }}</b></span
                            >
                            <span
                                >Dimensions: <b>{{ width }} x {{ height }}px</b></span
                            >
                            <span
                                >Type: <b>{{ type }}</b></span
                            >
                            <span
                                >Size: <b>{{ peso }} KB</b></span
                            >
                            <span style="margin-top: 15px"><b>Rotate:</b></span>
                            <div style="" class="btnsRotaes">
                                <span
                                    >-90°
                                    <button type="button" @click="rotateAction('90')">
                                        <i class="fas fa-undo"></i>
                                    </button>
                                </span>
                                <span style="margin-left: 7px">
                                    +90°
                                    <button type="button" @click="rotateAction('-90')">
                                        <i class="fas fa-undo rotate_custom"></i></button
                                ></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer d-flex justify-content-end">
                    <button
                        type="button"
                        class="btn btn-danger btn-sm"
                        style="margin-right: 5px"
                    >
                        DELETE
                    </button>
                    <button type="submit" class="btn btn-success btn-sm">UPDATE</button>
                </div>
            </form>
        </modal>
        <notifications group="noti" position="top center"></notifications>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader.vue";
import draggable from "vuedraggable";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
export default {
    name: "SlabsGallery",
    components: {
        Loader,
        draggable,
        vueDropzone: vue2Dropzone,
    },
    data() {
        return {
            isLoading: false,
            title: null,
            listGallery: [],
            id: null,
            name: "",
            img: "",
            date: "",
            type: "",
            width: "",
            height: "",
            peso: "",
            url: "",
            urlimg: null,
            dropzoneOptions: {
                url: window.urlSlabs + "api/v2/creategallery",
                thumbnailWidth: 200,
                addRemoveLinks: true,
                acceptedFiles: "image/jpeg, image/jpg",
            },
            queueComplete: false,
            sendingMultiple: false,
            removedFile: false,
            success: false,
            addImage: false,
            material_id: false,
            imgLarge: "",
            selectedItems: [],
        };
    },
    created() {
        //console.log(this.$route.params.material_id)
        this.material_id = this.$route.params.material_id;
        this.galleryList(this.$route.params.material_id);
    },
    methods: {
        CloseDrop() {
            this.addImage = false;
        },
        rotateAction(angle) {
            window.SlabsV2.post("rotateimg", {
                org_id: window.localuserdata,
                user_id: window.localAccountID,
                prefijo: "gl",
                angle: angle,
                type: 1,
                img: this.img,
                id: this.id,
            })
                .then((response) => {
                    this.galleryList(this.$route.params.material_id);
                    this.$modal.hide("modal-gallery");
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        addIMG() {
            this.addImage = true;
        },
        vsendingMuliple(file, xhr, formData) {
            this.sendingMultiple = true;
        },
        vremoved(file, xhr, error) {
            this.removedFile = true;
        },
        sendingEvent(file, xhr, formData) {
            formData.append("material_id", this.material_id);
            formData.append("fileName", file.name);
        },
        vsuccess(file, response) {
            this.success = true;
        },
        vqueueComplete(file, xhr, formData) {
            this.queueComplete = true;
            this.$refs.uploadGallerys.removeAllFiles(true);
            this.addImage = false;
            this.galleryList(this.$route.params.material_id);
        },
        DeleteAllItems() {
            this.$swal({
                title: "Delete this Items?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                if (result.value) {
                    window.SlabsV2.put(
                        "gallery/delete/all/" + this.$route.params.material_id,
                        {
                            items: this.selectedItems,
                        }
                    )
                        .then((response) => {
                            this.selectedItems = [];
                            this.galleryList(this.$route.params.material_id);
                        })
                        .catch((error) => {
                            console.log(error);
                            this.isLoading = false;
                        });
                }
            });
        },
        galleryList(material_id) {
            this.isLoading = true;
            window.SlabsV2.get("gallery/" + material_id)
                .then((response) => {
                    this.isLoading = false;
                    let list = response.data.result;
                    this.urlimg = response.data.url;
                    for (let i in list) {
                        list[i].img_original = list[i].img;
                        list[i].img = this.urlimg + list[i].img;
                    }
                    this.listGallery = list;
                    console.log(this.listGallery);
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        deleteItem(id) {
            this.$swal({
                title: "Delete this Image?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                if (result.value) {
                    this.isLoading = true;
                    window.SlabsV2.delete("gallery/delete/" + id)
                        .then((response) => {
                            this.galleryList(this.$route.params.material_id);
                            this.isLoading = false;
                            if (response.data.status) {
                                this.$notify({
                                    group: "noti",
                                    title: "Deleted!",
                                    type: "success",
                                    text: "Deleted Item Successful",
                                });
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            this.isLoading = false;
                        });
                }
            });
        },
        EditTitle(i, id, img) {
            this.id = i.id;
            this.img = i.img_original;
            this.title = i.name;
            this.name = i.name;
            this.type = i.type;
            this.peso = i.peso;
            this.width = i.width;
            this.height = i.height;
            this.date = new Date(i.created_at).toISOString().slice(0, 10);
            this.imgLarge = img.replace("thumbnail", "large");
            this.$modal.show("modal-gallery");
        },
        UpdateInfo() {
            window.SlabsV2.put("gallery/info", {
                id: this.id,
                name: this.name,
            })
                .then((response) => {
                    this.$modal.hide("modal-gallery");
                    this.galleryList(this.$route.params.material_id);
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        closeModal() {
            this.$modal.hide("modal-gallery");
        },
        checkMove() {
            this.listGallery.map((item, index) => {
                item.item = index + 1;
            });
            window.SlabsV2.put("gallery/order/" + window.localuserdata, {
                user_id: window.localuserdata,
                lists: this.listGallery,
            })
                .then((response) => {
                    console.log(response.data);
                })
                .catch((error) => console.log(error, (this.isLoading = false)));
        },
    },
    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
            };
        },
        selectAll: {
            get: function () {
                return this.listGallery
                    ? this.selectedItems.length == this.listGallery.length
                    : false;
            },
            set: function (value) {
                var selected = [];
                if (value) {
                    this.listGallery.forEach(function (transac) {
                        selected.push(transac.id);
                    });
                }
                this.selectedItems = selected;
            },
        },
    },
};
</script>
<style lang="scss" scoped>
.btnsRotaes {
    display: flex;
    justify-content: center;
    button {
        background-color: #5ac146;
        color: #fff;
        border: 0;
        border-radius: 3px;
        cursor: pointer;
    }
}
.rotate_custom {
    transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
}
.contenedor-gallery {
    min-height: 200px;
}
.btnmove {
    position: absolute;
    left: 2px !important;
    top: 0px !important;
    width: 13px;
    height: 100%;
    z-index: 100;
    color: #afafaf;
    font-size: 12px;
    line-height: 75px;
    text-align: center;
    vertical-align: middle;
    font-weight: normal;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: move;
}
.custominputs {
    input {
        border: 1px solid #ccc;
    }
}
.imgmodalContent2 {
    display: flex;
    .img {
        width: 65%;
        text-align: center;
        margin-right: 10px;
        img {
            width: 100%;
            height: 300px;
            object-fit: contain;
        }
    }
    .imgInfo {
        padding-left: 10px;
        width: 35%;

        span {
            display: block;
        }
    }
}
.imgmodalContent {
    display: block;
    margin-top: 20px;
    .imgInfo {
        display: flex;
        width: 100%;
        padding-right: 10px;
        padding-top: 4px;
        span {
            display: block;
            margin-right: 8px;
            border-right: 1px solid #ccc;
            padding-right: 7px;
        }
    }
    .img {
        width: 100%;
        height: auto;
        display: block;
        margin: 0 auto;
        overflow: hidden;
        img {
            width: 100%;
        }
    }
    @media (max-width: 1023px) {
        display: block;
        .imgInfo {
            width: 100%;
            padding-right: 0px;
        }
        .img {
            width: 100%;
        }
    }
}
@media (min-width: 1366px) {
    .modalgallery {
    }
}

@media (max-width: 1023px) {
    .modalgallery {
        margin-top: 50px;
    }
}
.listItems {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
    li {
        position: relative;
        width: 18%;
        border: 1px solid #ccc;
        height: 150px;
        margin: 5px 5px;
        .iHeader {
            display: block;
            position: relative;
            background-color: #ececec;
            padding: 5px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-left: 15px;
            > i {
                position: absolute;
                top: 5px;
                right: 5px;
                font-size: 20px;
                cursor: pointer;
                &:hover {
                    color: #000;
                }
            }
        }
        .iContent {
            display: block;
            padding: 8px;
            position: relative;
            height: 117px;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                cursor: pointer;
            }
        }
        .delete {
            background-color: #ec5252;
            color: #fff;
            position: absolute;
            bottom: 7px;
            right: 8px;
            width: 20px;
            text-align: center;
            padding: 2px;
            border-radius: 3px;
            cursor: pointer;
            display: none;
        }

        &:hover {
            .delete {
                display: block;
            }
        }
    }
}
.checkItems {
    position: absolute;
    left: 0;
    bottom: 0;

    .container {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 0px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    .checkmark {
        position: absolute;
        left: 8px;
        bottom: 8px;
        height: 18px;
        width: 18px;
        background-color: #f7f4f4;
        border: 1px solid #959595;
    }
    .container:hover input ~ .checkmark {
        background-color: #ccc;
    }
    .container input:checked ~ .checkmark {
        background-color: #2196f3;
    }
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }
    .container input:checked ~ .checkmark:after {
        display: block;
    }
    .container .checkmark:after {
        left: 6px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}
</style>
