const variables = {
	mark_socurce: [],
	cta: [],
	campaign: [],
	group: [],
	isCloseMenuAction: null,
	valueMarkSource: 0,
	filterMarkSource: [],
	optionsMarkSource: [],
	valueCTAChannel: 0,
	filterCTAChannel: [],
	optionsCTAChannel: [],
	valueCampign: 0,
	filterCampign: 0,
	optionsCampign: [],
	valueGroupName: 0,
	filterGroupName: 0,
	filtersOtpsGroupName: [],
	optionsGroupName: [],
	m: {
		show: false,
		component: '',
		data: []
	},	
	isLoading: false,
	dataT: [],
	dataTOLD: [],
	selectAll: false,
	selected: [],
	getProgress: 0,
	getLoading: true,
	savedAdd: false,
	paginador: 0,
	offset: 4,
	page: 1,
	pagination: {
		current_page: 1,
		total: 1,
	},
	query: "",
	rowNum: 50,
	rowList: [5, 10, 20, 50, 100],
	numPag: [1],
	searchValid: false,
	searchText: '',
	dateFrom: (new Date().toISOString() + "").slice(0, 11) + new Date().toTimeString().slice(0, 8)+'.000-05:00',
	dateTo: (new Date().toISOString() + "").slice(0, 11) + new Date().toTimeString().slice(0, 8)+'.000-05:00',
	apis: {},
};
export default variables;
