<template>
	<div class="main-pattern-login fixed top-0 left-0 right-0 bottom-0" style="z-index:101;    overflow: hidden;" :class="{ 'fade-out': success }"  ref="touchArea">
		<div class="loading" style="height:0.25em">
			<div v-if="isLoading" style="height:0.25em;width:20%;" class="animation-loading"></div>
		</div>
		<main draggable="false" v-if="!apiError">
			<h1 class="title">Show that you are not a robot, match<br> the following numbers:</h1>
			<h3 class="passCode">
				<span v-for="i, idx in showCodePass" :key="idx">
					{{i}}
					<span v-if="idx < (showCodePass.length-1)"> - </span>
				</span>
			</h3>
			<div id="num">
				<div class="num" v-for="coord, i in matrix" :key="i">
					{{i}}
				</div>				
			</div>
			<svg id="pattern-login" ref="svg" viewBox="0 0 30 30" draggable="false" ondragstart="return false;" :class="{ 'success': success, 'error': error }">
				<defs>
					<filter id="glow" width="1.5" height="1.5" x="-.25" y="-.25">
						<feGaussianBlur stdDeviation="0.25" result="coloredBlur"/>
						<feMerge>
							<feMergeNode in="coloredBlur"/>
							<feMergeNode in="SourceGraphic"/>
						</feMerge>
					</filter>
				</defs>
				<path style="-moz-user-select: none;-webkit-user-select: none;-ms-user-select: none;user-select: none;-webkit-user-drag: none;user-drag: none;" draggable="false" ondragstart="return false;" ref="indicator" id="indicator" :d="pathToSvg" stroke-linecap="round" stroke-linejoin="round"></path>
				<circle style="-moz-user-select: none;-webkit-user-select: none;-ms-user-select: none;user-select: none;-webkit-user-drag: none;user-drag: none;" draggable="false" ondragstart="return false;" v-for="coord, i in matrix" :key="i" ref="circle" :cx="coord[0]" :cy="coord[1]" r="4" :class="{ 'glow': (glowMatrix[i]) }" :data-key="i"></circle>
			</svg>
		</main>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
	name: 'index',
	data () {
		return {
			matrix: [
				//0: 
				[5, 5],
				//1: 
				[15, 5],
				//2: 
				[25, 5],
				//3: 
				[5, 15],
				//4: 
				[15, 15],
				//5: 
				[25, 15],
				//6: 
				[5, 25],
				//7: 
				[15, 25],
				//8: 
				[25, 25]
			],
			path: [],
			trackMouseMove: false,
			screenX: 0,
			screenY: 0,
			success: false,
			error: false,
			password: '',
			isLoading: false,
			pass: '01436',
			showCodePass: '01436',
			name_app: '',
			idRecord: '',
			ip: '',
			cache: '',
			apiError: true,
		};
	},
	created () {
		this.isLoading =true;
		this.getQueryVariables();
		if(
			this.name_app.length>0 &&
			this.idRecord.length>0 &&
			this.ip.length>0 &&
			this.cache.length>0 
		)
		{
			this.getDataCode();
		}
	},
	destroyed(){
		if (this.$refs && this.$refs.touchArea){
			window.removeEventListener('mousedown', this.touchstartANDmousedown);
			window.removeEventListener('touchdown', this.touchstartANDmousedown);
			window.removeEventListener('mouseup', this.touchendANDmouseup);
			window.removeEventListener('touchend', this.touchendANDmouseup);
			window.removeEventListener('touchmove', this.mousemoveANDtouchmove)
			window.removeEventListener('mousemove', this.mousemoveANDtouchmove)
		}
	},
	watch: {
		pushRefresh: function () {
			if (this.pushRefresh) {
				localStorage.setItem('updateCache', 0);
				window.location.reload(true);
			}
		},
	},
	computed: {
		...mapGetters("core", { pushRefresh: "getNotiPushRefresh"}),
		pathToString(){
			return this.path.join('')
		},
		glowMatrix(){
			let t = this;
			return t.matrix.map(function(n, i){
				return t.path.includes(i)
			})
		},
		pathToSvg(){
			let t = this;
			if (!t.path.length) {return '';}
				if (t.screenX && t.screenY && t.trackMouseMove){
					var svgB = t.$refs.svg.getBoundingClientRect()
				}
				return t.path.map(function(n, i){
					return (i ? ' L ' : 'M ') + t.matrix[n][0] + ' ' + t.matrix[n][1];
				}).join('') + (t.screenX && t.screenY && t.trackMouseMove ? ' L ' + ((t.screenX - svgB.left)*30/svgB.width) + ' ' + ((t.screenY - svgB.top)*30/svgB.width) : '')
		}
	},
	methods: {
		init() {
			let t = this;
			t.$nextTick(function(){
				window.addEventListener('touchmove', t.mousemoveANDtouchmove)
				window.addEventListener('mousemove', t.mousemoveANDtouchmove)
				window.addEventListener('mouseup', t.touchendANDmouseup);
				window.addEventListener('touchend', t.touchendANDmouseup);
				window.addEventListener('mousedown', t.touchstartANDmousedown);
				window.addEventListener('touchstart', t.touchstartANDmousedown);
			});
		},
		getDataCode(){
			let t = this;
			window.masterApiv2.post('/addLockScreen', 
			{
				name_app: t.name_app,
				idRecord: t.idRecord,
				ip: t.ip,
				cache: t.cache,
			}
			).then(response => {
				t.pass = response.data.r.codePass
				t.showCodePass = response.data.r.codePass.split('');
				t.apiError = false;
				t.isLoading = false;
				t.init();
			})
			.catch(error => {
				console.log(error);
			});
		},
		getQueryVariables() {
			let query = window.location.search.substring(1);
			let vars = query.split("&");
			for (let i=0; i < vars.length; i++) {
				let pair = vars[i].split("=");
				if(pair[0] == 'n') { this.name_app = pair[1];}
				if(pair[0] == 'id') { this.idRecord = pair[1];}
				if(pair[0] == 'ip') { this.ip = pair[1];}
				if(pair[0] == 'c') { this.cache = pair[1];}
			}
		},
		touchstartANDmousedown(ev){
			this.error = false;
			this.success = false;
			this.trackMouseMove = true;
			this.path = [];
			// avoid scrolling when using touch
			document.body.style.overflow = 'hidden';
		},
		touchendANDmouseup(ev){
			this.trackMouseMove = false;
			document.body.style.overflow = 'auto';
			this.login();
		},
		mousemoveANDtouchmove(ev){
			if (!this.trackMouseMove) return false;
			var target;
			if (ev.type == 'mousemove'){
				target = document.elementFromPoint(ev.clientX, ev.clientY);
				this.screenX = ev.clientX;
				this.screenY = ev.clientY;
			} else {
				var myLocation = ev.changedTouches[0];
				target = document.elementFromPoint(myLocation.clientX, myLocation.clientY);
				this.screenX = myLocation.clientX;
				this.screenY = myLocation.clientY;
			}
			let keyAsString = target.getAttribute('data-key');
			let key;
			if (keyAsString) key = Number(keyAsString);
			else return false;
			/*
				This part of the code is a little bit nasty.
				For performance reasons, when moving the finger or mouse fast enough,
				there was a chance that one would "skip" one of the touchable dots.
				So this code looks for the currently touched dot and the previus one
				and fill in the blank with the dot that may have left inbetween
			*/
			let last_key = this.path[this.path.length-1];
			if (last_key == 0 && key == 2){
				this.path.push(1);
			}
			if (last_key == 0 && key == 6){
				this.path.push(3);
			}
			if (last_key == 0 && key == 8){
				this.path.push(4);
			}
			if (last_key == 1 && key == 7){
				this.path.push(4);
			}
			if (last_key == 2 && key == 1){
				this.path.push(1);
			}
			if (last_key == 2 && key == 6){
				this.path.push(4);
			}
			if (last_key == 2 && key == 8){
				this.path.push(5);
			}
			if (last_key == 3 && key == 5){
				this.path.push(4);
			}
			//4
			if (last_key == 5 && key == 3){
				this.path.push(4);
			}
			if (last_key == 6 && key == 0){
				this.path.push(3);
			}
			if (last_key == 6 && key == 8){
				this.path.push(7);
			}
			if (last_key == 6 && key == 4){
				this.path.push(4);
			}
			if (last_key == 7 && key == 1){
				this.path.push(4);
			}
			if (last_key == 8 && key == 0){
				this.path.push(4);
			}
			if (last_key == 8 && key == 2){
				this.path.push(5);
			}
			if (last_key == 8 && key == 6){
				this.path.push(7);
			}
			if (!this.path.includes(key)){
				this.path.push(key);
			}
		},
		login(){
			var t = this;
			if (!this.path.length){
				return false;
			}
			console.log('times_tried', window.times_tried)
			t.success = false;
			t.error = false;
			var data = { pattern: t.pathToString };
			console.log('send data', data)
			t.isLoading = true;
			if (t.pass == t.pathToString){
				// success
				t.success = true;
				console.log('success Ok!');
			}else{
				//failure
				console.log('failure error!');
				t.error = true;
				t.isLoading = false;
			}
			this.$postMessage({success: t.success}, '*', window.parent);
			// RESET
			window.setTimeout(function(){
				t.success = false;
				t.isLoading = false;
				t.error = false;
			}, 2000);	
		},
	},
}
</script>
<style lang="css" scoped>
.vue-notification-group.refreshApp {
	display: none !important;
}
.overflow-y-auto{ overflow-y: auto }
.fixed{ position: fixed}
.top-0{ top: 0}
.bottom-0{ bottom: 0}
.left-0{ left: 0}
.right-0{ right: 0}
/*Custom CSS*/
.fade-out{
	animation: fade-out .4s;
	animation-delay: .1s;
	animation-fill-mode: forwards;
	opacity: 1;
}
@keyframes fade-out{
	to{ opacity: 0; }
}
main{
	padding: 10px 10vmin;
	box-sizing: border-box;
	text-align: center;
	user-select: none;
	overflow: hidden;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
}
svg#pattern-login{
	cursor: pointer;
	width: 400px;
	height: 400px;
	max-width: 80vmin;
	max-height: 80vmin;
	fill: transparent;
	stroke: #777;
	stroke-width: 1px;
	display: inline-block;
}
svg#pattern-login circle.glow, svg#pattern-login path#indicator{
	stroke: #37F;
}
.glow{
	filter: url(#glow);
}
svg#pattern-login.success circle.glow, svg#pattern-login.success path#indicator{
	stroke: #3F7;
}
svg#pattern-login.error circle.glow, svg#pattern-login.error path#indicator{
	stroke: #F33;
}
.animation-loading{
	background-color: #37F;
	animation: loading 1s linear infinite;
	transform: translateX(-100%);
}
@keyframes loading{
	to{ transform: translateX(500%)}
}
.title{
	margin-bottom: 0px;
	font-size: 1.3rem;
}
.passCode{
	margin-bottom: 20px;
	font-size: 1.5rem;
	color: red;
	font-weight: bold;
}
div#num {
    position: absolute;
    top: 110px;
    width: 400px;
    max-width: 80vmin;
    max-height: 80vmin;
    margin: auto;
    z-index: -1;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

.num {
    width: 8rem;
    height: 8rem;
    font-size: 3.5rem;
    box-sizing: content-box;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex: 0 0 33.33333%;
}
@media(max-width: 450px){
	.num {
		height: 7.5rem;
	}
}
@media(max-width: 419px){
	div#num {
    	top: 130px;
	}.num {
		height: 7.2rem;
	}
}
@media(max-width: 404px){
	div#num {
    	top: 140px;
	}.num {
		height: 6.2rem;
	}
}
@media(max-width: 360px){
	div#num {
    	top: 130px;
	}.num {
	}
}
@media(max-width: 348px){
	div#num {
	}
	.num {
		height: 5.9rem;
	}
}
@media(max-width: 333px){
	div#num {
	}
	.num {
		height: 5.6rem;
	}
}
@media(max-width: 318px){
	div#num {
	}
	.num {
    	height: 5.5rem;
    	font-size: 3rem;
	}
}
@media(max-width: 310px){
	div#num {
	}
	.num {
    	height: 5.2rem;
    	font-size: 2.5rem;
	}
}
@media(max-width: 295px){
	div#num {
	}
	.num {
    	height: 5rem;
	}
}
@media(max-width: 270px){
	div#num {
		display: none;
	}
}
</style>
