<template>
	<div class="card">
		<div class="card-header">
		</div>		
		<div class="card-body">
			<div v-if="isLoading"><Loader></Loader></div>
			<div v-else>
				<div class="row">
					<div class="col-12">
						<label for="inputEmail4">Organization List: </label>
						<select id="inputOrgs" class="form-control" v-model="modal.org_id" @change="AppsAvailablesByOrg">
							<option :value="i.org_id" v-for="i in appDisponibles" :key="i.id">{{i.bill_company_name}}</option>
						</select>
					</div>
				</div>

				<div class="row">
					<div class="col-md-12">
						<div class="row">
							<div style="padding: 5px;width: 50%;">App Name</div>
							<div style="padding: 5px;width: 20%;text-align: center;">Active</div>
							<div style="padding: 5px;width: 15%;text-align: center;">Admin</div>
							<div style="padding: 5px;width: 15%;text-align: center;">Privileges</div>
						</div>
						<div class="row" v-for="(i,index) in listOrgsbyUser" :key="index" style="border-top: 1px solid #dee2e6;padding: 0.4rem;vertical-align: top;vertical-align: middle;">
							<div style="display: contents; width: 100%;">
								<div style="padding: 5px;width: 50%;">{{i.name}}</div>
								<div style="padding: 5px;width: 20%;display: grid;">
									<div class="btn-switch text-center" v-if="freePlan && i.id==1">
										Inactive
									</div>
									<div class="btn-switch" v-else>
										<div class="onoffswitch" >
											<input type="checkbox" :id="'activeApp'+i.id" :checked="i.swich" class="onoffswitch-checkbox" @click="activeApp(i,index)">
											<label class="onoffswitch-label" :for="'activeApp'+i.id">
												<span class="onoffswitch-inner"></span>
												<span class="onoffswitch-switch"></span>
											</label>
										</div>
									</div>
								</div>
								<div style="padding: 5px;width: 15%;text-align: center;">
									<div v-if="i.swich > 0 ">
										<div v-if="i.id == 8 || i.id == 10 || i.id == 7 || i.id == 2 || i.id == 11">
											<input type="checkbox" disabled v-model="i.admin" :value="1" @change="convertToAdmin(i,index, 0)" style="position: relative;top: 2px;">
										</div>
										<div v-else>
											<div v-if="showContent!= 1 && showContent!= 4">
												<input type="checkbox" v-model="i.admin" :value="1" @change="convertToAdmin(i,index, 0)" style="position: relative;top: 2px;">
											</div>
											<div v-else>
												<input type="checkbox" v-model="i.admin" :value="1" disabled style="position: relative;top: 2px;box-shadow: inset -13px -9px 2px 0px #ccc;">
											</div>
										</div>
									</div>
								</div>
								<div style="padding: 5px;width: 15%;text-align: center;" v-if="i.swich > 0 ">
									<div v-if="(i.id == 4 && !i.admin) || (i.id == 1 && !i.admin)">
										<button v-if="showContent == false" @click="activeModulules(i.id, index)" class="btn btn-warning" style="padding: 2px 6px;font-size: 12px;color: #efefef;"><i class="fas fa-edit"></i></button>					
									</div>
									<div v-else>
										All
									</div>
								</div>
								<div class="container" v-if="showContent == i.id">
									<Privilegies :account_id="accountID" :org_id="modal.org_id" :app_id="i.id" :index="index" :item="i"></Privilegies>
								</div>
							</div>
						</div>
					</div> 
					<!-- <div class="col-12">
						<table class="table table-striped">
							<thead>
								<tr>
									<th scope="col" width="90%">App Name</th>
									<th scope="col" width="10%">Active</th>
									<th scope="col" width="10%">Admin</th>
									<th scope="col" width="10%">Privilegies</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(i,index) in appslistbyorg" :key="index" >
									<td>{{i.name}}</td>
									<td>
										<div class="btn-switch" >
											<div class="onoffswitch" >
												<input type="checkbox" :id="'activeApp'+i.id" :checked="i.swich" class="onoffswitch-checkbox" @click="activeApp(i,index)">
												<label class="onoffswitch-label" :for="'activeApp'+i.id">
													<span class="onoffswitch-inner"></span>
													<span class="onoffswitch-switch"></span>
												</label>
											</div>
										</div>
									</td>
									<td class="text-center" >
										<div v-if="i.swich > 0 ">							
											<div v-if="i.id == 8 || i.id == 10 || i.id == 7 || i.id == 2">

												<input type="checkbox" disabled :checked="i.admin" @click="convertToAdmin(i,index)" style="position: relative;top: 2px;">
											</div>
											<div v-else>
												<input type="checkbox" :value="i.admin" :checked="i.admin" @click="convertToAdmin(i,index)" style="position: relative;top: 2px;">
											</div>											
										</div>
									</td>
									<td class="text-center" >
										<div v-if="i.swich > 0 && !i.admin">
											<div v-if="i.id != 8 || i.id != 10 || i.id != 7 || i.id != 2">
												<button class="btn btn-primary btn-sm" @click="privilegies(i.id)">Privilegies</button>
											</div>																			
										</div>										
									</td>
								</tr>
								<tr v-if="!appslistbyorg.length">
									<td colspan="10"><div>This organization does not have apps installed</div></td>
								</tr>															
							</tbody>
						</table>
					</div> -->
				</div>	
			</div>		
		</div>
	</div>
</template>
<script>
import Loader from '@/core/components/shared/Loader'
import Privilegies from '@/core/components/Account/Users/Privilegies'
import { mapGetters } from 'vuex'
export default {
	name: 'AssignApps',
	components: {
		Loader,
		Privilegies,
	},
	// props:{
	// 	accountID:{type: Number, required:true},
	// 	orgID:{type: Number, required:true}
	// },
	data () {
		return {
			isLoading: false,
			user_id: window.master_client,
			appDisponibles:[],
			listApp:[],
			appslistbyorg: [],
			modal :{
				org_id :0,
			},
			idApps:[2, 7, 8, 10, 11, 1, 4],
			showContent:false,
			freePlan: false,
		}
	},
	created(){		
		this.freePlan = (this.$store.getters['core/Org/getPlanId'] == 4);
		// console.log('created get_appRolUser', this.get_appRolUser);
		this.init();
	},
	methods: {
		desactiveadmin(app_id, val){
			let t = this;
			let editAdmin = {};
			let newList = [];
			let index = 0;
			for (let j in t.appslistbyorg) {
				if(t.appslistbyorg[j]){
					if(t.appslistbyorg[j].id == app_id){
						let index = j;
						let campos = Object.keys(t.appslistbyorg[j]);
						for (let k in campos) {
							if(campos[k]){
								editAdmin[campos[k]]= t.appslistbyorg[j][campos[k]];
								if(campos[k] == 'admin'){
									editAdmin[campos[k]]=val;
								}
							}
						}
						newList.push(editAdmin);
					}else {
						newList.push(t.appslistbyorg[j]);
					}
				}
			}
			this.appslistbyorg = newList;
		},
		activeModulules(id, index) {
			let idApps = [1, 4]
			if (idApps.indexOf(id) > -1) {
				if (this.showContent > 0) {
					this.showContent = false	
				} else {
					this.showContent = id
				}
			}
		},
		activeApp(i, index){
			let t = this;
			t.isLoading= true;
			if(t.appslistbyorg[index].swich==1) {
				t.appslistbyorg[index].swich = 0;
				this.showContent = false
			} else {
				t.appslistbyorg[index].swich = 1;
				//this.activeModulules(i.id, index)
			}
			window.master.post('/user/activeorg', {
				user_id: window.master_client,
				org_id : t.modal.org_id,
				account_id : t.accountID,
				app_id: t.appslistbyorg[index].id,
				swich: t.appslistbyorg[index].swich,
				rol: 0, // activacion o desactivacion siempre sera rol = 0
				idApps:t.idApps,
			}).then((response) => {
				// console.log('activeorg', response.data)
				// solo si se asigno el app
				if (i.admin) {
					this.appslistbyorg[index].admin = false
				} else {
					this.appslistbyorg[index].admin = true
					this.activeModulules(i.id, index)
				}
				// if(t.appslistbyorg[index].swich==1 && this.appslistbyorg[index].admin){
				// 	this.convertToAdmin(i, index, 1);
				// }
				this.$store.commit('core/setNotiPush', {
					show: true,
					group: "notiPush",
					type: '1', // 1=success|2=info|3=error|4=loading|5=warning
					title: 'Success',
					text: 'Successful Update.',
					duration: 3000
				});
				t.isLoading= false;
			}).catch((error) => {
				console.log(error)
				t.isLoading= false;
			});
		},		
		init() {
			let g = this.$store.getters['core/getOpenModal'];
			this.accountID = g.data.account_id;
			this.orgID = g.data.orgid;
			this.getUsersById();
		},
		rules(i, index){
			let id = i.id;
			if (this.idApps.indexOf(id) > -1) {
				this.convertToAdmin(i, index);
			} else{ 				
				this.isLoading= false; 
			}
		},		
		convertToAdmin(i, index, p){
			this.isLoading= true;
			let rol = 0;
			if(p==0 && (i.id == 1 || i.id == 4)){
				this.isLoading= false;
				// this.appslistbyorg[index].admin=true;
				// this.appslistbyorg[index].rol=1;
				if(i.admin){
					i.rol=1;
				}
				this.activeModulules(i.id, index);
			}else{
				if (p == 0) {
					// desde el form
					// if (i.admin) {
						this.appslistbyorg[index].admin = false
						this.showContent = false
						rol = 0; 
					// } else {
					// 	this.appslistbyorg[index].admin = true
					// 	// this.activeModulules(i.id, index)				
					// 	rol = 1; 
					// }
				} else {	
					// desde metodo activeorg
					// if (this.appslistbyorg[index].swich == true) {
						rol = 1; 
						this.appslistbyorg[index].admin = true
					// } else {
					// 	rol = 0; 
					// 	this.appslistbyorg[index].admin = false					
					// }			
				}
				window.master.post('/user/rolApp', {
					user_id: this.accountID,
					org_id : this.modal.org_id,
					app_id: i.id,
					rol: rol,
				}).then((response) => {
					let r = response.data;
					if(r.rol == 1){
						this.showContent = false
						this.$store.commit('core/setNotiPush', {
							show: true,
							group: "notiPush",
							type: '1', // 1=success|2=info|3=error|4=loading|5=warning
							title: 'Success',
							text: 'Successful Update.',
							duration: 3000
						});
						// si desctivan admin guardar solo modulo de dash
						if(!this.appslistbyorg[index].admin){
							this.activeModulules(i.id, index);
						}
						this.$parent.close()
					}
					else{
						this.appslistbyorg[index].admin = false;
						this.appslistbyorg[index].swich = false;
						this.$store.commit('core/setNotiPush', {
							show: true,
							group: "notiPush",
							type: '3', // 1=success|2=info|3=error|4=loading|5=warning
							title: 'Error',
							text: 'failed assignment.',
							duration: 50000
						});
					}
					this.isLoading= false;
				}).catch((error) => {
					console.log(error);
					this.appslistbyorg[index].admin = false;
					this.appslistbyorg[index].swich = false;
					this.$store.commit('core/setNotiPush', {
						show: true,
						group: "notiPush",
						type: '3', // 1=success|2=info|3=error|4=loading|5=warning
						title: 'Error',
						text: 'could not be set as administrator.',
						duration: 50000
					});
					this.isLoading= false;
				});
			}
			
		},
		privilegies(app_id){
			this.$router.push({
				name:'Privilegies', 
				params:{
					account_id:this.accountID, 
					org_id: this.modal.org_id, 
					app_id:app_id
				}
			});
		},
		getAppsActivas() {
			let t = this;
			t.appslistbyorg = []
			let user_id= window.master_client;
			let org_id = t.modal.org_id;
			let account_id = t.accountID;
			window.master.get('/user/getactiveorg/'+org_id+'/'+user_id+'/'+account_id)
			.then((response) => {
				console.log('apps activas ', response.data.rs);
				let apps = response.data.rs;
				for (let i in apps) {
					for (let e in t.appslistbyorg) {
						if (t.appslistbyorg[e].id== apps[i].app_id) {
							t.appslistbyorg[e].swich = 1;
							t.appslistbyorg[e].admin = (apps[i].rol==1);
							t.appslistbyorg[e].rol = apps[i].rol;
							//t.appslistbyorg[e].admin = (apps[i].active==1);
							if(t.appslistbyorg[e].admin){
								console.log('app admins', t.appslistbyorg[e])
							}
						}
					}
				}
				// console.log('appslistbyorg', t.appslistbyorg);
				t.isLoading = false;
			}).catch((error) => {
				console.log(error)				
				t.isLoading = false;
			});
		},
		AppsAvailablesByOrg(){
			this.isLoading= true;
			if(this.modal.org_id > 0)
			{
				this.$store.dispatch('core/apps/ListAppsForOrgOfSecondary', this.modal.org_id)
				.then((response) => {
					console.log('antes', response);
					let apps = this.orderApps(response);
					for (let i in apps) {
						apps[i].swich=0;
					}
					console.log('despues', apps);
					// console.log('apps', apps);
					this.getAppsActivas();
					this.appslistbyorg = []
					this.listApp = []
					this.appslistbyorg = apps;
					this.listApp = apps;
					// this.isLoading= false;
				})
				.catch(error => {
					console.log(error);
					this.$store.commit('core/setNotiPush', {
						show: true,
						group: "notiPush",
						type: '3', // 1=success|2=info|3=error|4=loading|5=warning
						title: 'Error',
						text: 'Organization not found.',
						duration: 10000
					});
					this.isLoading= false;
					// this.closeModalBox();
				});
			}else{
				this.$store.commit('core/setNotiPush', {
					show: true,
					group: "notiPush",
					type: '3', // 1=success|2=info|3=error|4=loading|5=warning
					title: 'Error',
					text: 'There is no organization.',
					duration: 10000
				});
				this.isLoading= false;
				// this.closeModalBox();
			}
		},		
		orderApps(listApps){
			let orderApp= [
		        {name: "Billing", id:"1"},
		        {name: "Marketea", id:"8"},
		        {name: "Jobs", id:"4"},
		        {name: "RoofCal", id:"5"},
		        {name: "Slabs", id:"7"},
		        {name: "Display", id:"10"},
		        {name: "Projects", id:"3"},
		    ];
		    let newApp = [];
			for (let e in orderApp) {
				if(orderApp[e]){
					for (let i in listApps) {
						if(orderApp[e].id == listApps[i].id){
							newApp.push(listApps[i]);
							//console.log('agg app', listApps[i]);
						}
					}
				}
			}
			if(newApp.length < listApps.length){
				for (let i in listApps) {
					if(!orderApp.indexOf(listApps[i])== -1){
						newApp.push(listApps[i]);
						//console.log('agg app fuera del order', listApps[i]);
					}
				}
			}
			return newApp;
		},
		getUsersById(){
			this.isLoading= true;
			window.master.get('user/'+this.user_id+'/'+this.accountID)
			.then(response => {
				this.appDisponibles = []
				this.appDisponibles = response.data.result;			
				if(this.orgID > 0){
					this.modal.org_id=this.orgID;
				}
				else if(response.data.result.length > 0){
					this.modal.org_id=response.data.result[0].org_id;
				}
				this.AppsAvailablesByOrg();
				// this.isLoading= false;
			})
			.catch(error => {
				console.log(error);
				this.isLoading= false;
			});
		},		
	},
	wacth: {
	    "get_appRolUser": function(){
	    //   console.log('wacth get_appRolUser', this.get_appRolUser);
	    },
	},
	computed: {
		...mapGetters('core/apps', ['get_appRolUser']),	
		listOrgsbyUser: function () {
            return this.appslistbyorg;
        }	
	}
};
</script>
<style lang="scss" scoped>
	#inputOrgs{border:1px solid #555;}
	/*INICIO DEL SWICHT*/
	.btn-switch{
		display: inline-block;
    	float: right;
	}
	.onoffswitch {
    position: relative; 
    width: 60px;
    -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
	}
	.onoffswitch-checkbox {
	    display: none;
	}
	.onoffswitch-label {
	    display: block; 
	    overflow: hidden; 
	    cursor: pointer;
	    border: 1px solid #585858; 
	    border-radius: 20px;
	}
	.onoffswitch-inner {
	    display: block; 
	    width: 200%; 
	    margin-left: -100%;
	    transition: margin 0.1s ease-in 0s;
	}
	.onoffswitch-inner:before, 
	.onoffswitch-inner:after {
	    display: block; 
	    float: left; 
	    width: 50%; 
	    height: 20px; 
	    padding: 0; 
	    line-height: 20px;
	    font-size: 12px; 
	    color: white; 
	    font-family: Trebuchet, Arial, sans-serif; 
	    font-weight: bold;
	    box-sizing: border-box;
	}
	.onoffswitch-inner:before {
	    content: "On";
    	padding-left: 10px;
    	background-color: #489a38;
	    color: #FFFFFF;
	}
	.onoffswitch-inner:after {
	    content: "Off";
	    padding-right: 10px;
	    background-color: #d65353; 
	    color: #fff;
	    text-align: right;
	}
	.onoffswitch-switch {
	    display: block; 
	    width: 15px; 
	    height: 15px;
	    margin: 6px;
	    background: #fff;
	    position: absolute; 
	    top: -3px; 
	    bottom: 0;
	    right: 33px;
    	border: 1px solid #585858; 
	    border-radius: 20px;
	    transition: all 0.1s ease-in 0s; 
	}
	.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
	    margin-left: 0;
	}
	.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
	    right: 0px; 
	}
	/*FIN DEL SWICHT*/
</style>
