<template>
    <div class="card">
        <div class="card-header">
            <h3>Select one Estimate
                <span class="badge badge-dark" v-if="selectOne!=null && selectOne.id>0">#{{ selectOne.numdoc }}</span>
            </h3>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-12">
                    <label for="">Search: <small class="badge badge-light" v-if="customerEst_id!=null">( Filter by customer Id: {{ customerEst_id }} )</small></label>
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" placeholder="Search by: name, job info, customer name, id, date" v-model="filterEst"/>
                        <span class="input-group-text" id="basic-addon1">
                            <button type="submit" class="btn"><i class="fas fa-search"></i></button>
                        </span>
                    </div>
                </div>
                <div class="col-12" v-if="searchEstimate.length==0 && loaded">
                    <div class="alert alert-primary">
                        <strong>No estimates were found for the last 6 months!</strong>
                    </div>
                </div>
                <div class="col-12" v-else>
                    <div class="list-group" v-for="(i, idx) in searchEstimate" :key="idx">
                        <!-- <a href="#" class="list-group-item list-group-item-action active" aria-current="true">The current link item</a> -->
                        <label :class="['list-group-item list-group-item-action', (selectOne.id==i.id) ? 'active' : '']" :for="'selectOne'+idx" v-if="i.id>0">
                            <div class="row">
                                <div class="col-2">
                                    <input type="radio" name="selectOne" :id="'selectOne'+idx" :value="i.id" :checked="selectOne.id==i.id" @click="chosseEst(i)"/>
                                </div>
                                <div class="col-10 text-right"><i>{{ i.dateCreate }}</i></div>
                                <div class="col-4"><strong>Numdoc: </strong> <span class="badge badge-dark">#{{ i.numdoc }}</span></div>
                                <div class="col-4">
                                    <strong>Status: </strong>
                                    <span
                                        :class="['badge',
                                            (i.status==1) ? 'badge-secondary': '',
                                            (i.status==2) ? 'badge-success': '',
                                            (i.status==5) ? 'badge-info': '',
                                        ]"
                                        :style="(i.status==1) ? 'background-color: #f89406': ''"
                                    >
                                        <span v-if="i.status==1">Pending</span>
                                        <span v-if="i.status==2">Accepted</span>
                                        <span v-if="i.status==5">Signed</span>
                                    </span>
                                </div>
                                <div class="col-4 text-right">
                                    <strong>Total: </strong>
                                    <span> $ {{ i.total | formatMoney }}</span>
                                </div>
                                <div :class="[(i.job_information!=null && i.job_information.length>0) ? 'col-6' : 'col-12']">
                                    <strong>Bill To:</strong><br>
                                    <div v-if="i.bussinesname">
                                        {{ i.bussinesname }}
                                    </div>
                                    <div>
                                        <span v-if="i.firstname">{{ i.firstname }}</span>
                                        <span v-if="i.lastname"> {{ i.lastname }}</span>
                                        <span v-if="i.email"><br>{{ i.email }}</span>
                                    </div>
                                </div>
                                <div class="col-6" v-if="i.job_information">
                                    <strong>Job info:</strong><br>
                                    <span >{{ i.job_information.slice(150) }}</span>
                                </div>
                                <div class="col-12" v-if="i.estimate_optional">
                                    <strong>Name:</strong>
                                    <span v-if="i.estimate_optional"> {{ i.estimate_optional.slice(0, 150) }}</span>
                                </div>
                            </div>
                        </label>
                        <div v-else class="list-group-item list-group-item-action">
                            <div class="alert alert-warning text-center">
                                <strong>{{i.message}}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {
    },
    data() {
        return {
            org_id: window.localorgdata,
            user_id: window.master_client,
            filterEst: null,
            itemSales: null,
            selectOne: {id: 0},
            loaded: false,
            listEst: [],
            estIds: [],
            customerEst_id: null,
        };
    },
    created() {
        let t = this;
        let g = t.$store.getters['core/getOpenModal'];
        t.$store.commit("core/setLoadingModal", false);
        t.itemSales = g.data.item;
        t.estIds = g.data.estIds;
        console.log('estIds', t.estIds)
        console.log('itemSales', t.itemSales)
        if(t.itemSales.est_id>0){
        t.selectOne.id = t.itemSales.est_id;
        t.selectOne.numdoc = t.itemSales.numdoc;
            t.customerEst_id = t.itemSales.estimate.customer_id;
        }
        console.log('customer_id', t.customer_id)
        t.getEstimateList();
    },
    methods : {
        getEstimateList(){
            let t = this;
            t.$store.commit("core/setLoadingModal", true);
            window.billingSales.post("estimate/selectOne", {
                org_id: window.localorgdata,
            })
            .then((response) => {
                console.log('estimate/selectOne', response.data.result)
                if(response.data.result.length>0){
                    let list = response.data.result.filter(r => {
                        let isExist = t.estIds.filter(e => {
                            if(e.est_id==r.id){ return e;}
                        });
                        if(isExist.length==0){
                            if(t.customerEst_id!=null){
                                if(t.customerEst_id==r.customer_id){return r;}
                            }else{return r;}
                        }
                    });
                    console.log('list', list);
                    t.listEst = t.modelFields(list)
                };
                t.$store.commit("core/setLoadingModal", false);
                t.loaded=true;
            })
            .catch((error) => {
                console.log(error);
                t.$store.commit("core/setLoadingModal", false);
                t.loaded=true;
            });
        },
        modelFields(l){
            let t = this;
            return l.filter(r => {
                // r.firstname
                // r.lastname
                // r.email
                // r.bussinesname
                // r.id
                // r.numdoc
                // r.customer_id
                // r.numdoc
                // r.accepted_date
                // r.estimate_optional
                // r.job_information
                // r.customer_message
                // r.total
                // r.date_create
                // r.created_at
                // r.status
                r.customer_name= r.firstname+' '+r.lastname;
                r.customer_name_reverso= r.lastname+' '+r.firstname;
                r.customer_name_bussines= r.firstname+' '+r.lastname + ' '+ r.bussinesname
                r.customer_firstname_bussines= r.firstname+ ' '+ r.bussinesname
                r.customer_lastname_bussines= r.lastname + ' '+ r.bussinesname
                r.statusName= (r.status==1) ? 'Pending' : ((r.status==2) ? 'Accepted' : 'Signed');
                r.dateCreate = (new Date(r.date_create)+'').slice(4, 15);
                r.dateCreate2 = (new Date(r.date_create)+'').slice(4, 7) +' '+(new Date(r.date_create)+'').slice(11, 15);
                return r;
            })
        },
        chosseEst(i){
            this.selectOne= i;
            this.salesSetEstID(this.selectOne);
        },
        salesSetEstID(est) {
            let t = this;
            t.$store.commit("core/setLoadingModal", true);
            window.master
                .post("salesSetEstID", {
                org_id: window.localorgdata,
                user_id: window.master_client,
                idSale: t.itemSales.id,
                status: t.itemSales.status,
                est_id: est.id,
                numdoc: est.numdoc,
                statusEstimate: est.status,
                })
                .then((response) => {
                    console.log("salesSetEstID", response.data);
                    t.$notify({
                        group: "noti",
                        type: "success",
                        title: "Estimate saved",
                        text: "Successful registration",
                    });
                    t.$parent.close();
                })
                .catch((error) => {
                    console.log(error);
                    this.$notify({
                        group: "noti",
                        type: "error",
                        title: "Error!",
                        text: "Save estimate",
                    });
                    this.selectOne= {id : 0};
                });
        },
    },
    computed: {
        searchEstimate() {
            let t = this;
            if(t.filterEst && t.filterEst.length>0){
                let result = t.listEst.filter(i => {
                    if(i.firstname && i.firstname.toLowerCase().indexOf(t.filterEst.toLowerCase()) >= 0){ return i;}
                    else if(i.lastname && i.lastname.toLowerCase().indexOf(t.filterEst.toLowerCase()) >= 0){ return i;}
                    else if(i.bussinesname && i.bussinesname.toLowerCase().indexOf(t.filterEst.toLowerCase()) >= 0){ return i;}
                    else if(i.customer_name && i.customer_name.toLowerCase().indexOf(t.filterEst.toLowerCase()) >= 0){ return i;}
                    else if(i.customer_name_reverso && i.customer_name_reverso.toLowerCase().indexOf(t.filterEst.toLowerCase()) >= 0){ return i;}
                    else if(i.customer_name_bussines && i.customer_name_bussines.toLowerCase().indexOf(t.filterEst.toLowerCase()) >= 0){ return i;}
                    else if(i.customer_firstname_bussines && i.customer_firstname_bussines.toLowerCase().indexOf(t.filterEst.toLowerCase()) >= 0){ return i;}
                    else if(i.customer_lastname_bussines && i.customer_lastname_bussines.toLowerCase().indexOf(t.filterEst.toLowerCase()) >= 0){ return i;}
                    else if(i.numdoc && i.numdoc.toLowerCase().indexOf(t.filterEst.toLowerCase()) >= 0){ return i;}
                    else if(i.estimate_optional && i.estimate_optional.toLowerCase().indexOf(t.filterEst.toLowerCase()) >= 0){ return i;}
                    else if(i.job_information && i.job_information.toLowerCase().indexOf(t.filterEst.toLowerCase()) >= 0){ return i;}
                    else if(i.total && i.total.toString().indexOf(t.filterEst.toLowerCase()) >= 0){ return i;}
                    else if(i.dateCreate && i.dateCreate.toLowerCase().indexOf(t.filterEst.toLowerCase()) >= 0){ return i;}
                    else if(i.dateCreate2 && i.dateCreate2.toLowerCase().indexOf(t.filterEst.toLowerCase()) >= 0){ return i;}
                    else if(i.statusName && i.statusName.toLowerCase().indexOf(t.filterEst.toLowerCase()) >= 0){ return i;}
                });
                if(result.length>0){
                    console.log('result', result)
                    return result;
                }
                else{
                    let r = [{id: 0, message: 'No record'}];
                console.log('result', r)
                    return r;
                }
            }
            else{
                return t.listEst;
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.list-group-item:hover {cursor: pointer;}
.list-group-item.active {
    z-index: 2;
    color: #000;
    background-color: #daddfb;
    border-color: #2962FF;
}
</style>
