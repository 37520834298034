/*rutas de Expenses*/
import Expenses from '@/apps/billing/views/Expenses';
import ExpensesNew from '@/apps/billing/views/ExpensesNew';
export default [
	{
    path: 'expenses/',
    name: 'Expenses',    
    folder: Expenses,
  },
  {
    path: 'expenses/new',
    name: 'ExpensesNew',    
    folder: ExpensesNew,
  },
  {
    path: 'expenses/new/:ide',
    name: 'ExpensesEdit',    
    folder: ExpensesNew,
  }
];
