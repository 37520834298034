<template>
    <div>
        <div class="card">
            <div class="card-header">
                <h5 class="card-title"><i class="fas fa-tasks"></i> Manage Tasks</h5>
            </div>
            <div
                class="card-body"
                style="height: 300px; overflow-x: hidden; overflow-y: scroll"
            >
                <div
                    class="row"
                    style="
                        border-top: 1px solid #ddd;
                        width: 100%;
                        margin-top: 5px;
                        padding-top: 5px;
                    "
                >
                    <div class="col-12 content-table">
                        <form @submit.prevent="$parent.$parent.$parent.formToDo(id)">
                            <div
                                class="row"
                                style="vertical-align: middle; padding-bottom: 10px"
                            >
                                <div
                                    class="form-group col-md-6"
                                    style="margin: 0px; padding-right: 0"
                                >
                                    <div class="input-group">
                                        <model-select
                                            :options="listTemplate"
                                            v-model="$parent.$parent.$parent.templateValue"
                                            placeholder="Choose a to do list"
                                            class="form-control to_do_list"
                                        ></model-select>
                                    </div>
                                </div>
                                <div
                                    class="col-md-1"
                                    style="margin: 0px; padding-left: 0"
                                >
                                    <span class="input-group-addon">
                                        <button
                                            type="button"
                                            class="btn btn-default"
                                            @click="$parent.$parent.$parent.addToDoList"
                                            style="
                                                height: 37px;
                                                border-radius: 2px;
                                                padding-top: 5px;
                                            "
                                            :disabled="$parent.$parent.$parent.btnToDoList"
                                        >
                                            <i class="fa fa-plus"></i>
                                        </button>
                                    </span>
                                </div>
                            </div>
                            <table class="table table-hover">
                                <thead>
                                    <tr class="bg-secondary">
                                        <th
                                            style="vertical-align: middle"
                                        >
                                            <div
                                                style="
                                                    color: #fff;
                                                    font-size: 14px;
                                                    padding-top: 0px;
                                                    margin-bottom: 5px;
                                                "
                                            >
                                                Things to do
                                                <span
                                                    class="badge badge-pill badge-light"
                                                    v-if="h.length > 0"
                                                    >{{ h.length }}</span
                                                >
                                            </div>
                                        </th>
                                        <th
                                            class="text-center"
                                            style="
                                                vertical-align: middle;
                                                color: #fff;
                                                font-size: 16px;
                                                width: 90px;
                                            "
                                        >
                                            Status
                                        </th>
                                        <th
                                            class="text-center"
                                            style="
                                                vertical-align: middle;
                                                padding: 1px 4px;
                                                color: #fff;
                                                width: 43px;
                                            "
                                        >
                                            <i class="fa fa-times fa-lg"></i>
                                        </th>
                                    </tr>
                                </thead>
                                <draggable
                                    v-if="h.length > 0"
                                    v-model="h.taks"
                                    tag="tbody"
                                    class="tbody"
                                    v-bind="dragOptions"
                                    @start="isDragging = true"
                                    @end="isDragging = false"
                                    handle=".handle"
                                >
                                    <tr v-for="(i, index) in h" :key="index">
                                        <td>
                                            <div class="handle">
                                                <i class="fas fa-ellipsis-v"></i>
                                            </div>
                                            <textarea
                                                class="form-control"
                                                v-model="i.name"
                                                placeholder="text box"
                                                required
                                                :ref="'taks' + index"
                                                :id="'taks' + index"
                                                style="height: 44px"
                                            >
                                            </textarea>
                                        </td>
                                        <td>
                                            <model-select
                                                :options="i.statusList"
                                                v-model="i.status"
                                                placeholder="Status"
                                                class="form-control status"
                                            ></model-select>
                                        </td>
                                        <td class="text-center">
                                            <button
                                                type="button"
                                                class="btn btn-danger"
                                                :disabled="h.length == 1"
                                                @click="delTaks(i.id, index)"
                                                style="
                                                    border-radius: 2px;
                                                    padding: 2px 4px;
                                                    font-size: 12px;
                                                "
                                            >
                                                <i class="fa fa-times"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </draggable>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="3" class="text-center">
                                            No record
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colspan="3" class="text-left">
                                            <button
                                                type="button"
                                                class="btn btn-info"
                                                @click="addLine"
                                            >
                                                <i class="fa fa-plus"></i> Add task
                                            </button>
                                            <button
                                                type="submit"
                                                class="btn btn-success"
                                                :disabled="btnSavetoDo"
                                                style="margin-left: 10px"
                                            >
                                                <i class="fas fa-save"></i> SAVE
                                            </button>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import draggable from 'vuedraggable'
import { ModelSelect } from 'vue-search-select'
export default {
    name: "manageTasks",  
    components: { 
        ModelSelect,
        draggable,
    },    
    data () {
        return {  
            user_id: window.master_client,
			org_id: window.localorgdata,
            f:{
                taks:[],
            },
            templateValue:[],  
            listTemplate: [],
            btnToDoList:false,     
            btnSavetoDo:false,
            g:[],
            h:'',
            id:0,
        };
    },
    created () { 
        this.init()
    },
    methods: { 
        init(){
            let urlApis = this.$store.getters['ModApps/jobs/urlApis'];
            this.apis = urlApis.jobBox;               
            this.g = this.$store.getters['core/getOpenModal']
            this.id = this.g.data[0].id_i
            this.getToDoList()            
        },
        getToDoList() {
            let t = this;
            t.$store.commit("core/setLoadingModal", true);
            t.$parent.$parent.$parent.f.taks = []
            t.h = t.$parent.$parent.$parent.f.taks
            let url = this.apis.getToDoList()
            window.master
                .post(url, {
                    user_id: t.user_id,
                    org_id: t.org_id,
                    user_id_sesion: window.localAccountID,
                    app_id: t.appIDactual,
                })
                .then((response) => {
                    // console.log(response.data);
                    //color
                    // t.color = response.data.color;
                    //status
                    let status = response.data.status;
                    let y = [];
                    for (let i in status) {
                        if (status[i].id == 1 || status[i].id == 3) {
                            y.push({ id: status[i].id, text: status[i].name });
                        }
                    }
                    // form new task
                    let statusList = [];
                    let list = y;
                    for (let i in list) {
                        statusList.push({
                            value: list[i].id,
                            text: list[i].text,
                        });
                    }
                    let lineNew = {
                        id: 0,
                        name: "",
                        status: 1,
                        statusList: statusList,
                    };
                    // this.f.taks.push(lineNew);
                    // tags
                    let tags = response.data.tags;
                    let tagsList = [];
                    for (let i in tags) {
                        tagsList.push({ id: tags[i].id, name: tags[i].name });
                    }
                    //to do list template
                    let template = response.data.template;
                    this.listTemplate = [];
                    for (let i in template) {
                        if (template[i].taks.length > 0) {
                            this.listTemplate.push({
                                value: template[i].id,
                                text: template[i].name,
                            });
                        }
                    }
                    // console.log(this.listTemplate)
                    let t = [];              
                    t.push({
                        listTemplate: this.listTemplate,
                        lineNew: lineNew,
                        taks: this.g.data[0].taks,
                    });
                    this.arrays("task", t);
                    this.$store.commit("core/setLoadingModal", false);
                })
                .catch((error) => {
                    console.log(error);
                    this.$store.commit("core/setLoadingModal", false);
                });
        }, 
        arrays(name, data) {
            let k = [];
            k.push({ function: name, data: data });
            this.$store.commit("ModApps/jobs/get_todolist", k);
        },               
        addLine(){
            let g = this.$store.getters['ModApps/jobs/get_todolist']
            let d = g[0].data;           
            let r = d[0].lineNew;
            let id = this.h.length - 1;
            let lineNew = {
                id: id,
                name: null,
                status: 1,
                statusList: r.statusList,
            };
            this.h.push(lineNew);
            let idx = this.h.length - 1;
            setTimeout(function () {
                document.getElementById("taks" + idx).focus();
            }, 200);
        },
        delTaks(id, index) {
            this.h.splice(index, 1);
        },        
    }, 
    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
            };
        },
    }, 
};
</script>
<style lang="scss" scoped>
.tbody t {
    position: relative;
}
.tbody tr td {
    position: relative;
    padding-left: 16px;
}
.handle {
    position: absolute;
    border: 0px solid #000;
    left: 5px;
    padding: 12px 2px 3px 4px;
    height: 83%;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: rgb(17 177 245 / 38%);
    cursor: move;
}
@media (max-width: 600px) {
    .handle {
        left: -8px;
        height: 95%;
    }
}
</style>
