<template>
    <div class="container-fluid">
        <div class="page-breadcrumb">
            <div class="row">
                <div class="col-6">
                    <h2 class="page-title"><i class="fas fa-cog"></i> Setting</h2>
                </div>
                <div class="col-6 align-self-center">
                    <div class="d-flex align-items-right justify-content-end">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link
                                        :to="{ name: 'Display' }"
                                        class="btn btn-info btn-sm mr-2"
                                        ><i class="fas fa-arrow-left"></i>
                                        Back</router-link
                                    >
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-3">
                        <div class="list-group list-group-flush">
                            <h4
                                style="
                                    color: #9c9c9c;
                                    font-weight: normal;
                                    font-size: 20px;
                                "
                            >
                                General Setting
                            </h4>
                            <!-- <div class="list-group-item list-group-item-action" >Type Template</div> -->
                            <!-- <router-link :to="{name: 'Tags'}" class="list-group-item list-group-item-action">Tags</router-link> -->
                            <div
                                class="list-group-item list-group-item-action"
                                style="cursor: pointer"
                                @click="openModal('DisplaySecurity')"
                            >
                                Security pin
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal name="modal-setting" height="auto" v-bind:click-to-close="false">
            <div class="card">
                <button @click="closeModal" class="closeIcon"><span></span></button>
                <DisplaySecurity v-if="SettingBox == 'DisplaySecurity'"></DisplaySecurity>
            </div>
        </modal>
    </div>
</template>
<script>
import DisplaySecurity from "@/apps/display/components/setting/DisplaySecurity.vue";
import { mapGetters } from "vuex";
export default {
    name: "DisplaySetting",
    components: {
        DisplaySecurity,
    },
    data() {
        return {
            isLoading: false,
            user_id: window.master_client,
            org_id: window.localorgdata,
            typez: window.user_type,
            accountID: window.localAccountID,
            SettingBox: "",
        };
    },
    created() {
        //prueba preload
        this.isLoading = true;
        let t = this;
        setTimeout(function () {
            t.isLoading = false;
        }, 1000);
    },
    methods: {
        closeModal() {
            this.SettingBox = "";
            this.$modal.hide("modal-setting");
        },
        openModal() {
            this.SettingBox = "DisplaySecurity";
            this.$modal.show("modal-setting");
        },
    },
    watch: {
        get_displayEdit: function () {
            if (this.get_displayEdit) {
                let g = this.$store.getters["ModApps/display/get_displayEdit"];
                let fun = g[0].function;
                let r = g[0].data[0];
                if (fun == "DisplaySettingPing") {
                    this.closeModal();
                }
            }
        },
    },
    computed: {
        ...mapGetters("ModApps/display", ["get_displayEdit"]),
    },
};
</script>

<style lang="css" scoped></style>
