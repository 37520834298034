<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-10 offset-sm-1">
                    <div class="card">
                        <div class="card-body">
                            <form
                                class="form-horizontal form-material custom_form"
                                @submit.prevent="savedata"
                                enctype="multipart/form-data"
                                id="form1"
                            >
                                <div class="card-header">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <h2 class="page-title">
                                                Remanents {{ title }}
                                            </h2>
                                        </div>
                                        <div class="col-md-6">
                                            <div
                                                class="d-block text-right"
                                                style="width: 100%"
                                            >
                                                <button
                                                    type="submit"
                                                    class="btn btn-success b-block"
                                                    style="margin-right: 5px"
                                                >
                                                    <i class="fas fa-save"></i> Save
                                                </button>
                                                <router-link
                                                    :to="{ name: 'SlabsRemanents' }"
                                                    class="btn btn-secondary b-block Btncancel"
                                                    ><i class="fas fa-window-close"></i>
                                                    Cancel</router-link
                                                >
                                                <button
                                                    type="button"
                                                    class="btn btn-danger"
                                                    @click="elimItem()"
                                                    style="margin-left: 5px"
                                                    v-if="form.action == 'update'"
                                                >
                                                    <i class="fas fa-trash-alt"></i>
                                                    DELETE
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="bgloading" v-if="isLoading">
                                        <Loader></Loader>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-lg-4">
                                            <label class="">Select Slabs:</label>
                                            <model-select
                                                :options="slabs_list"
                                                v-model="form.slabs_id"
                                                placeholder="Choose a Slabs"
                                                @input="handleOnInput($event)"
                                            >
                                            </model-select>
                                        </div>
                                        <div class="col-sm-12 col-lg-5">
                                            <div class="form-group">
                                                <label class="">Cover:</label>
                                                <div style="display: flex">
                                                    <div
                                                        data-v-61d79379=""
                                                        style="
                                                            border: 1px solid
                                                                rgb(204, 204, 204);
                                                            padding: 4px;
                                                            width: 50px;
                                                            border-radius: 5px;
                                                        "
                                                    >
                                                        <img
                                                            :src="form.cover"
                                                            v-if="
                                                                form.cover_old !== null &&
                                                                form.cover_old !== ''
                                                            "
                                                            style="
                                                                width: 40px;
                                                                height: 40px;
                                                                object-fit: cover;
                                                            "
                                                        />
                                                    </div>
                                                    <div class="custom-file">
                                                        <input
                                                            type="file"
                                                            class="custom-file-input"
                                                            id="customFile"
                                                            accept="image/jpg,image/jpeg"
                                                            @change="filelogo"
                                                        />
                                                        <label
                                                            class="custom-file-label"
                                                            for="customFile"
                                                            id="imgurl"
                                                            >Choose file</label
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-lg-3">
                                            <div class="form-group">
                                                <label class="">Status:</label>
                                                <select
                                                    class="form-control"
                                                    name="referal"
                                                    v-model="form.status"
                                                >
                                                    <option value="1">Online</option>
                                                    <option value="0">Offline</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <nav>
                                        <div
                                            class="nav nav-tabs"
                                            id="nav-tab1"
                                            role="tablist"
                                        >
                                            <a
                                                :class="[
                                                    'nav-item nav-link',
                                                    tabActive == 1 ? 'active' : '',
                                                ]"
                                                @click="tabActiveMethod(1)"
                                                id="nav-s-tab"
                                                data-toggle="tab"
                                                href="javascript:"
                                                role="tab"
                                                aria-controls="nav-profile"
                                                aria-selected="false"
                                                >Small Description</a
                                            >
                                            <a
                                                :class="[
                                                    'nav-item nav-link',
                                                    tabActive == 2 ? 'active' : '',
                                                ]"
                                                @click="tabActiveMethod(2)"
                                                id="nav-d-tab"
                                                data-toggle="tab"
                                                href="javascript:"
                                                role="tab"
                                                aria-controls="nav-contact"
                                                aria-selected="false"
                                                >Remnant Description
                                            </a>
                                        </div>
                                    </nav>
                                    <div class="tab-content" id="nav-tabContent">
                                        <div
                                            :class="[
                                                'tab-pane fade',
                                                tabActive == 1 ? 'show active' : '',
                                            ]"
                                            id="nav-small"
                                            role="tabpanel"
                                            aria-labelledby="nav-profile-tab"
                                        >
                                            <div class="row">
                                                <div
                                                    class="col-sm-12 col-lg-12 col-xl-12"
                                                >
                                                    <div class="form-group">
                                                        <textarea
                                                            class="form-control"
                                                            id="exampleFormControlTextarea1"
                                                            rows="3"
                                                            :maxlength="maxText"
                                                            v-model="
                                                                form.small_description
                                                            "
                                                        ></textarea>
                                                        <small
                                                            style="
                                                                text-align: right;
                                                                display: block;
                                                            "
                                                            v-if="
                                                                form.small_description ==
                                                                null
                                                            "
                                                            >Limit
                                                            <b>{{ maxText }}</b>
                                                            characters</small
                                                        >
                                                        <small
                                                            style="
                                                                text-align: right;
                                                                display: block;
                                                            "
                                                            v-else
                                                            >Limit
                                                            <b
                                                                v-text="
                                                                    maxText -
                                                                    form.small_description
                                                                        .length
                                                                "
                                                            ></b>
                                                            characters</small
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            :class="[
                                                'tab-pane fade',
                                                tabActive == 2 ? 'show active' : '',
                                            ]"
                                            id="nav-desc"
                                            role="tabpanel"
                                            aria-labelledby="nav-profile-tab"
                                        >
                                            <div class="row">
                                                <div
                                                    class="col-sm-12 col-lg-12 col-xl-12"
                                                >
                                                    <div class="form-group">
                                                        <vue-editor
                                                            id="editor"
                                                            v-model="
                                                                form.full_description
                                                            "
                                                            style="width: 100%"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- bloke 2 tabs -->
                                    <nav style="padding-bottom: 20px">
                                        <div
                                            class="nav nav-tabs"
                                            id="nav-tab_seo"
                                            role="tablist"
                                        >
                                            <a
                                                :class="[
                                                    'nav-item nav-link',
                                                    tabActive2 == 1 ? 'active' : '',
                                                ]"
                                                @click="tabActiveMethod2(1)"
                                                id="nav-se1-tab"
                                                data-toggle="tab"
                                                href="javascript:"
                                                role="tab"
                                                aria-controls="nav-seo"
                                                aria-selected="false"
                                                >Seo title</a
                                            >
                                            <a
                                                :class="[
                                                    'nav-item nav-link',
                                                    tabActive2 == 2 ? 'active' : '',
                                                ]"
                                                @click="tabActiveMethod2(2)"
                                                id="nav-se3-tab"
                                                data-toggle="tab"
                                                href="javascript:"
                                                role="tab"
                                                aria-controls="nav-seo"
                                                aria-selected="false"
                                                >Seo description
                                            </a>
                                            <a
                                                :class="[
                                                    'nav-item nav-link',
                                                    tabActive2 == 3 ? 'active' : '',
                                                ]"
                                                @click="tabActiveMethod2(3)"
                                                id="nav-se2-tab"
                                                data-toggle="tab"
                                                href="javascript:"
                                                role="tab"
                                                aria-controls="nav-seo"
                                                aria-selected="false"
                                                >Keywords (Optional)
                                            </a>
                                        </div>
                                    </nav>
                                    <div class="tab-content" id="nav-tabContent2">
                                        <div
                                            :class="[
                                                'tab-pane fade',
                                                tabActive2 == 1 ? 'show active' : '',
                                            ]"
                                            id="nav-contact"
                                            role="tabpanel"
                                            aria-labelledby="nav-contact-tab"
                                        >
                                            <div class="row">
                                                <div
                                                    class="col-sm-12 col-lg-12 col-xl-12"
                                                >
                                                    <div class="form-group">
                                                        <label class="">Title:</label>
                                                        <input
                                                            type="text"
                                                            name="seo_title"
                                                            v-model="form.seo_title"
                                                            class="form-control"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            :class="[
                                                'tab-pane fade',
                                                tabActive2 == 3 ? 'show active' : '',
                                            ]"
                                            id="nav-seo2"
                                            role="tabpanel"
                                            aria-labelledby="nav-contact-tab"
                                        >
                                            <div class="row">
                                                <div
                                                    class="col-sm-12 col-lg-12 col-xl-12"
                                                >
                                                    <div class="form-group">
                                                        <label class="">Keywords:</label>
                                                        <textarea
                                                            class="form-control"
                                                            id="exampleFormControlTextarea2"
                                                            rows="3"
                                                            v-model="form.seo_keywords"
                                                        ></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            :class="[
                                                'tab-pane fade',
                                                tabActive2 == 2 ? 'show active' : '',
                                            ]"
                                            id="nav-seo3"
                                            role="tabpanel"
                                            aria-labelledby="nav-contact-tab"
                                        >
                                            <div class="row">
                                                <div
                                                    class="col-sm-12 col-lg-12 col-xl-12"
                                                >
                                                    <div class="form-group">
                                                        <label class=""
                                                            >Seo description:</label
                                                        >
                                                        <textarea
                                                            class="form-control"
                                                            id="exampleFormControlTextarea3"
                                                            rows="3"
                                                            v-model="form.seo_description"
                                                        ></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div
                                                class="d-block text-right"
                                                style="width: 100%"
                                            >
                                                <input
                                                    type="hidden"
                                                    name="imgOld"
                                                    v-model="form.cover_old"
                                                />
                                                <button
                                                    type="submit"
                                                    class="btn btn-success b-block BtnSave"
                                                    style="margin-right: 5px"
                                                >
                                                    <i class="fas fa-save"></i> Save
                                                </button>
                                                <router-link
                                                    :to="{ name: 'SlabsRemanents' }"
                                                    class="btn btn-secondary b-block Btncancel"
                                                    ><i class="fas fa-window-close"></i>
                                                    Cancel</router-link
                                                >
                                                <button
                                                    type="button"
                                                    class="btn btn-danger"
                                                    @click="elimItem()"
                                                    style="margin-left: 5px"
                                                    v-if="form.action == 'update'"
                                                >
                                                    <i class="fas fa-trash-alt"></i>
                                                    DELETE
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader.vue";
import { ModelSelect } from "vue-search-select";
import { VueEditor } from "vue2-editor";
export default {
    name: "SlabsRemanentsNew",
    components: {
        Loader,
        VueEditor,
        ModelSelect,
    },
    data() {
        return {
            isLoading: false,
            tabActive: 1,
            tabActive2: 1,
            maxText: 500,
            categoriesList: [],
            patternList: [],
            ManufacturerList: [],
            form: {
                id: null,
                action: "create",
                status: 1,
                original_name: null,
                small_description: "",
                country_origin: 65,
                material_id: 0,
                pattern_id: 0,
                colors: "",
                type: 2,
                full_description: "",
                available_finish: "",
                applications: "",
                slabs_id: 0,
                dimensions: "",
                sq_ft: "",
                seo_description: "",
                seo_keywords: "",
                seo_title: "",
                cover: "",
                cover_old: null,
                manufacturer: 1,
                manufacturer_name: "",
            },
            slabs_list: [],
            countriesList: [],
            title: "New",
        };
    },
    created() {
        this.getManufacturers();
        this.getCategories();
        this.getpatternList();
        this.getCountries();
        this.getSlabs();
        if (this.$route.params.edit && this.$route.params.id) {
            this.form.action = "update";
            this.getById("edit", this.$route.params.id);
            this.title = "Edit";
        }
    },
    methods: {
        handleOnInput($event) {
            this.getById("change", $event);
        },
        getSlabs() {
            this.isLoading = true;
            window.SlabsV2.get("getmaterials/" + window.localuserdata)
                .then((response) => {
                    let m = response.data.result;
                    let data = [];
                    for (let i in m) {
                        data.push({ value: m[i].id, text: m[i].name });
                    }
                    this.slabs_list = data;
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        filelogo(event) {
            this.form.cover = event.target.files[0];
            if (event.target.files && event.target.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = function (e) {
                    document.querySelector("#imgurl").innerHTML =
                        event.target.files[0].name;
                };
            }
        },
        elimItem() {
            this.$swal({
                title: "Delete this Item?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                if (result.value) {
                    this.isLoading = true;
                    window.SlabsV2.put("deletematerial/" + this.form.id)
                        .then((response) => {
                            //console.log(response.data);
                            this.isLoading = false;
                            if (response.data.status) {
                                this.$router.push({ name: "SlabsRemanents" });
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            this.isLoading = false;
                        });
                }
            });
        },
        getManufacturers() {
            this.isLoading = true;
            window.SlabsV2.get("manufacturers")
                .then((response) => {
                    this.isLoading = false;

                    let l = response.data.result;
                    let data = [];
                    for (let i in l) {
                        data.push({ value: l[i].id, text: l[i].name });
                    }
                    this.ManufacturerList = data;
                })
                .catch((error) => console.log(error));
        },
        getCategories() {
            this.isLoading = true;
            window.SlabsV2.get("getcategory")
                .then((response) => {
                    this.isLoading = false;
                    let m = response.data.result;
                    let data = [];
                    for (let i in m) {
                        data.push({ value: m[i].id, text: m[i].name });
                    }
                    this.categoriesList = data;
                })
                .catch((error) => console.log(error));
        },
        getpatternList() {
            this.isLoading = true;
            window.SlabsV2.get("patterns")
                .then((response) => {
                    this.isLoading = false;
                    let l = response.data.result;
                    let data = [];
                    for (let i in l) {
                        data.push({ value: l[i].id, text: l[i].name });
                    }
                    this.patternList = data;
                })
                .catch((error) => console.log(error));
        },
        getCountries() {
            this.isLoading = true;
            window.master
                .get("paises")
                .then((response) => {
                    this.isLoading = false;
                    let p = response.data.paises;
                    let data = [];
                    for (let i in p) {
                        data.push({ value: p[i].id, text: p[i].nom_en });
                    }
                    this.countriesList = data;
                })
                .catch((error) => console.log(error));
        },
        getById(c, id) {
            this.isLoading = true;
            window.SlabsV2.get("getmaterial/" + id)
                .then((response) => {
                    this.isLoading = false;
                    console.log("detalle ");
                    console.log(response.data.result);
                    this.form.material_id = response.data.result.material_id;
                    this.form.pattern_id = response.data.result.pattern_id;
                    this.form.original_name = response.data.result.name;
                    //this.form.slabs_id = response.data.result.slabs_id
                    this.form.manufacturer = response.data.result.manufacturer;
                    this.form.manufacturer_name = response.data.result.manufacturer_name;
                    this.form.small_description =
                        response.data.result.small_descrip == "null"
                            ? ""
                            : response.data.result.small_descrip;
                    this.form.full_description =
                        response.data.result.full_descrip == "null"
                            ? ""
                            : response.data.result.full_descrip;
                    this.form.available_finish =
                        response.data.result.available_finish == "null"
                            ? ""
                            : response.data.result.available_finish;
                    this.form.applications = response.data.result.application;
                    this.form.colors =
                        response.data.result.colors == "null"
                            ? ""
                            : response.data.result.colors;
                    this.form.country_origin =
                        response.data.result.country_id == "null"
                            ? ""
                            : response.data.result.country_id;
                    this.form.seo_description =
                        response.data.result.descrip_seo == "null"
                            ? ""
                            : response.data.result.descrip_seo;
                    this.form.seo_keywords =
                        response.data.result.key_seo == "null"
                            ? ""
                            : response.data.result.key_seo;
                    this.form.seo_title =
                        response.data.result.title_seo == "null"
                            ? ""
                            : response.data.result.title_seo;
                    if (c == "edit") {
                        this.form.id = response.data.result.id;
                        this.form.slabs_id = response.data.result.slabs_id;
                        this.form.dimensions = response.data.result.dimensions;
                        this.form.sq_ft = response.data.result.sq_ft;
                        this.form.cover =
                            response.data.result.cover !== null &&
                            response.data.result.cover !== ""
                                ? response.data.url + response.data.result.cover
                                : "";
                        this.form.cover_old = response.data.result.cover;
                    }
                })
                .catch((error) => console.log(error));
        },
        tabActiveMethod(index) {
            this.tabActive = index;
        },
        tabActiveMethod2(index) {
            this.tabActive2 = index;
        },
        savedata() {
            this.isLoading = true;
            var f = new FormData();
            f.append("id", this.form.id);
            f.append("user_id", window.localAccountID);
            f.append("org_id", window.localuserdata);
            f.append("manufacturer", this.form.manufacturer);
            f.append("manufacturer_name", this.form.manufacturer_name);
            f.append("name", this.form.original_name);
            f.append("small_descrip", this.form.small_description);
            f.append("country_id", this.form.country_origin);
            let cty = false;
            for (let item in this.countriesList) {
                if (this.countriesList[item].value == this.form.country_origin) {
                    f.append("country", this.countriesList[item].text);
                    cty = true;
                }
            }
            if (!cty) {
                f.append("country", "");
            }
            f.append("material_id", this.form.material_id);
            f.append("availability_option", 0);
            f.append("pattern_id", this.form.pattern_id);
            f.append("colors", this.form.colors);
            f.append("full_descrip", this.form.full_description);
            f.append("available_finish", this.form.available_finish);
            f.append("type", this.form.type);
            f.append("cover", this.form.cover);
            f.append("cover_old", this.form.cover_old);
            f.append("application", this.form.applications);
            f.append("slabs_id", this.form.slabs_id);
            f.append("dimensions", this.form.dimensions);
            f.append("sq_ft", this.form.sq_ft);
            f.append("descrip_seo", this.form.seo_description);
            f.append("key_seo", this.form.seo_keywords);
            f.append("title_seo", this.form.seo_title);
            f.append("status", this.form.status);
            console.log("slabs_id=" + this.form.slabs_id);
            if (this.form.action == "create") {
                window.SlabsV2.post("creatematerial", f)
                    .then((response) => {
                        this.isLoading = false;
                        console.log(response.data);
                        this.$router.push({ name: "SlabsRemanents" });
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else if (this.form.action == "update") {
                window.SlabsV2.post("updatematerial", f)
                    .then((response) => {
                        this.isLoading = false;
                        console.log(response.data);
                        this.$router.push({ name: "SlabsRemanents" });
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
    },
    watch: {
        "form.original_name": function (val) {
            this.form.seo_title = val;
        },
        "form.small_description": function (val) {
            this.form.seo_description = val;
        },
    },
};
</script>
<style lang="scss" scoped>
#editor_small {
    height: 100px !important;
    min-height: 100px !important;
    .ql-editor {
        min-height: 100px;
    }
}
.custom_form {
    .form-control {
        border: 1px solid #c1c1c1;
    }
}
</style>
