<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-md-4">
                                <router-link
                                    :to="{ name: 'estproForm' }"
                                    class="btn btn-success"
                                >
                                    <i class="fas fa-plus"></i>
                                    Add Estimate
                                </router-link>
                                <div class="showColumns">
                                    <div class="basic_dropdown" v-click-outside="hideBtncolumns">
                                        <button type="button" class="btn-columns" @click="btncolumns()">
                                            <i class="fas fa-columns"></i>
                                        </button>
                                        <ul class="dropdColumns" v-if="columnsdropd">
                                            <li v-for="(item, index) in colsAll" :key="index">
                                                <div :class="['custom-control custom-checkbox']">
                                                    <input
                                                        type="checkbox"
                                                        class="custom-control-input"
                                                        :id="'cols-'+index"
                                                        v-bind:value="item.id"
                                                        v-model="cols"
                                                        @change="editCols"
                                                    />
                                                    <label
                                                        :class="['custom-control-label']"
                                                        :for="'cols-'+index"
                                                    >
                                                        {{ item.name }}
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="row">
                                    <div class="col-md-8">
                                        <div class="input-group">
                                            <input
                                            type="text"
                                            class="form-control searchbox-input"
                                            placeholder="Search"
                                            v-model="inputFilter"
                                            v-on:keypress.enter="searchFilter"
                                            />
                                            <div class="input-group-prepend btn-filter-search">
                                                <span
                                                    class="btn btn-info"
                                                    @click="searchFilter"
                                                    style="border: 0px"
                                                >Search</span>
                                            </div>
                                            <div
                                                class="input-group-prepend btn-filter-search"
                                                @click="searchAll"
                                            >
                                                <span class="input-group-text"><i class="fas fa-backspace"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 fillStatus">
                                        <div
                                            class="basic_dropdown"
                                            v-click-outside="hideBtnstatus"
                                        >
                                            <button
                                                class="btn-status"
                                                @click="btnstatus()"
                                            >
                                                Status
                                                <i class="fas fa-chevron-down"></i>
                                            </button>
                                            <ul
                                                class="dropdStatus"
                                                v-if="statusdropd"
                                            >
                                                <!-- <li>
                                                    <div class="custom-control custom-checkbox" >
                                                        <input
                                                            type="checkbox"
                                                            class="custom-control-input"
                                                            id="check12"
                                                            @click="checkAll()"
                                                            v-model="isCheckAll"
                                                        />
                                                        <label
                                                            class="custom-control-label"
                                                            for="check12"
                                                            ><b>All</b></label
                                                        >
                                                    </div>
                                                </li> -->
                                                <li v-for="(item, index) in statusdata" :key="index">
                                                    <div :class="['custom-control custom-checkbox']">
                                                        <input
                                                            type="checkbox"
                                                            class="custom-control-input"
                                                            :id="index"
                                                            v-bind:value="item.value"
                                                            v-model="statuslist"
                                                            @change="searchFilter"
                                                        />
                                                        <label
                                                            :class="['custom-control-label']"
                                                            :for="index"
                                                        >
                                                            {{ item.name }} 
                                                            <span :class="['badge badge-light', 
                                                        (item.value==1) ? 'pending' : 
                                                            (item.value==2) ? 'accepted' : 
                                                                (item.value==5) ? 'sing' : 
                                                                    (item.value==3) ? 'rejected' : 
                                                                        (item.value==4) ? 'void' : ''
                                                        ]">
                                                                <b v-if="item.value==1">{{Pending.length}}</b>
                                                                <b v-if="item.value==2">{{Accepted.length}}</b>
                                                                <b v-if="item.value==5">{{Sing.length}}</b>
                                                                <b v-if="item.value==3">{{Rejected.length}}</b>
                                                                <b v-if="item.value==4">{{Void.length}}</b>
                                                            </span>
                                                        </label>
                                                        </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <select v-model="paginador" class="form-control" @change="searchFilterPaginador">
                                        <option value="50">Display</option>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                        <option value="300">300</option>
                                        <option value="500">500</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <Loader v-if="isLoading"></Loader>
                            <div class="contenedor-estimate" v-else>
                                <table
                                    class="estpro"
                                    v-if="listData.length > 0"
                                >
                                    <thead>
                                        <tr>
                                            <th class="note">Note</th>
                                            <th class="numdoc">Est #</th>
                                            <th v-if="cols.includes('1')">CRM</th>
                                            <th >Customer</th>
                                            <th style="text-align: left">Estimate Name (Optional)</th>
                                            <th style="text-align: right">Total</th>
                                            <th style="text-align: center">Status</th>
                                            <th style="text-align: center">Send</th>
                                            <th class="contract">Contract</th>
                                            <th style="text-align: center" v-if="cols.includes('2')">Calc</th>
                                            <th ><i class="fas fa-ellipsis-h"></i></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(item, idxTR) in listData"
                                            :key="idxTR" :id="'tr-'+idxTR"
                                        >
                                            <td class="note">
                                                <span :class="'newEst newEst-'+item.id" v-if="item.pusher==1">New!</span>
                                                <button
                                                    @click="openNotesEstPro(item)"
                                                    type="button" 
                                                    :title="(item.notesEstpro > 0) ? 'Edit Notes' : 'Add Note'"
                                                    :class="[(item.notesEstpro > 0) ? 'active' : '']"
                                                >
                                                    <sup :class="(item.notesEstpro>0) ? '' : 'none'">{{ item.notesEstpro }}</sup>
                                                    <i :class="['far fa-sticky-note']"></i>
                                                    <span class="textIcon" style="display: none;"> Edit Note</span>
                                                </button>
                                            </td>
                                            <td class="numdoc">
                                                <span class="num" @click="actionPdf(item.id)">#{{ item.numdoc }}</span>
                                                <span class="btn-edit" v-if="item.status != 4  && item.status != 5" @click="actionEdit(item.id)">Edit</span>
                                                <span class="btn-edit no-edit" v-else>edit</span>
                                                <span class="btn-pdf" @click="actionPdf(item.id)">PDF</span>
                                                <span class="date_create">{{ item.date_create | moment("MMM DD, YYYY")}}</span>
                                                <!-- <div class="rowOptions" >
                                                    <div :class="'btnOptions btnOptions-'+idxTR" @click="activeMenuRow(item, idxTR)">
                                                        <i class="fas fa-ellipsis-h" ></i>
                                                    </div>
                                                    <div :class="'contentRowOptions contentRowOptions-'+idxTR">
                                                        <ul class="menuRowOptions">
                                                            <li>
                                                                Open Task
                                                            </li>
                                                            <li>
                                                                Duplicate
                                                            </li>
                                                            <li>
                                                                Delete
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div> -->
                                            </td>
                                            <td class="crm" v-if="cols.includes('1')"></td>
                                            <td class="customer">
                                                {{ item.firstname }}
                                                {{ item.lastname }} <br /><span
                                                    style="color: #a9a9a9"
                                                    >{{ item.bussinesname }}</span
                                                >
                                            </td>
                                            <td class="est_name">
                                                <input type="text" class="form-control edit-name" v-model="item.estimate_optional" @change="editNameEst(item)"/>
                                            </td>
                                            <td class="total">
                                                <span>
                                                    ${{ item.total | formatMoney }}
                                                </span>
                                            </td>
                                            <td class="status" style="text-align: center">
                                                <span class="cdropdown">
                                                    <button type="button" 
                                                        :class="['btn-status',
                                                        (item.status==1) ? 'pending' :
                                                            (item.status==2) ? 'accepted' :
                                                                (item.status==3) ? 'rejected' :
                                                                    (item.status==4) ? 'void' :
                                                                        (item.status==5) ? 'signed' : 'inactivo'
                                                        ]"
                                                    >
                                                        <span v-for="(i, idx) in statusdata" :key="idx" v-if="item.status==i.value">
                                                            {{ i.name }}
                                                        </span>
                                                    </button>
                                                    <label>
                                                        <input type="checkbox" />
                                                        <ul class="actions-status">
                                                            <li v-for="(i, idx) in statusdata" 
                                                                :key="idx" 
                                                                v-if="item.status!=i.value && i.value!=5 && i.value!=4"
                                                                @click="changeStatus(item, i.value)"
                                                            >
                                                                {{ i.name }}
                                                            </li>
                                                        </ul>
                                                    </label>
                                                </span>
                                            </td>
                                            <td class="send" style="text-align: center; padding: 0px;">
                                                <button type="button"  class="btn-mail" @click="actionEmail(item.id, item.email, item.numdoc)" title="Send by e-mail">
                                                    <sup :class="(item.email_send>0) ? '' : 'none'">{{ item.email_send }}</sup>
                                                    <i :class="['fas fa-envelope', (item.email_send==0) ? 'inactivo' : '']"></i>
                                                </button>
                                                <button type="button" v-if="showSMS==1" class="btn-sms" @click="sendSMSaction(item, 'estimate')" title="Send by SMS">
                                                    <sup :class="(item.countSmsEst>0) ? '' : 'none'">{{item.countSmsEst}}</sup>
                                                    <i :class="['fas fa-sms', (item.countSmsEst==0) ? 'inactivo' : '']"></i>
                                                </button>
                                            </td>
                                            <td class="contract" style="text-align: center; padding: 0px;" v-if="accountSMS == 2">
                                                <button
                                                    v-if="item.signe==1"
                                                    @click="actionContract(item, 1)"
                                                    :title=" (item.status==5) ? 'Contract already signed' : 'Request Digital Signature'"
                                                >
                                                    <sup :class="(item.countSmsEst>0) ? '' : 'none'">{{item.countSmsEst}}</sup>
                                                    <i :class="['fas fa-file-contract',
                                                        (item.countSmsEst==0) ? 'inactivo' :
                                                            (item.status==1) ? 'pending' :
                                                                (item.status==2) ? 'accepted' :
                                                                    (item.status==5) ? 'signed' : 'inactivo'
                                                        ]"></i>
                                                </button>
                                            </td>
                                            <td class="calculator" v-if="cols.includes('2')">
                                                <button type="button" v-if="idxTR==1 || idxTR==3" @click="OpenCalcEstimator(item, idxTR)">
                                                    <i :class="['fas fa-calculator', (idxTR==1) ? 'inactivo' : '']"></i>
                                                </button>
                                            </td>
                                            <td class="actions">
                                                <span class="cdropdown">
                                                    <button><i class="fas fa-ellipsis-v"></i></button>
                                                    <label>
                                                        <input type="checkbox" />
                                                        <ul class="actions-pro">
                                                            <li @click="actionPdf(item.id)">
                                                                <button>
                                                                    <i class="fas fa-file-pdf"></i>
                                                                    Open PDF
                                                                </button>
                                                            </li>
                                                            <li @click="actionDuplicate(item.id)" v-if="item.status != 4">
                                                                <button>
                                                                    <i class="fas fa-copy"></i>
                                                                    Duplicate
                                                                </button>
                                                            </li>
                                                            <li @click="convertToInvoice(item.id)" v-if="item.status == 2 || item.status == 5">
                                                                <button>
                                                                    <i class="far fa-file-alt"></i>
                                                                    Convert To Invoice
                                                                </button>
                                                            </li>
                                                            <li @click="actionEdit(item.id)" v-if="item.status != 4  && item.status != 5">
                                                                <button>
                                                                    <i class="fas fa-edit"></i> Edit
                                                                </button>
                                                            </li>
                                                            <li @click="MetDeleteEstimate(item.id)" v-if="item.status !== 2 && item.status != 5">
                                                                <button>
                                                                    <i class="fas fa-times"></i>
                                                                    Delete
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </label>
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div
                                    class="text-center d-block w-100 p20"
                                    v-else-if="
                                        EstimatesList.length == 0 && isFilter == true
                                    "
                                >
                                    <div class="text-center" style="padding: 80px">
                                        No record
                                    </div>
                                </div>
                                <div class="text-center d-block w-100 p20" v-else>
                                    <div class="text-center" style="padding: 80px">
                                        <span style="padding-bottom: 30px"
                                            >Its time to create your first Estimate! </span
                                        ><br />
                                        <router-link
                                            :to="{ name: 'EstimatesNew' }"
                                            class="btn btn-success"
                                            style="margin-top: 7px"
                                            >Create New Estimate</router-link
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- modal -->
        <modalLateral>
            <component v-bind:is="m.component"  :items="DataMailEstimate"></component>
        </modalLateral>
    </div>
</template>
<script>
import moment from 'moment';
import Loader from '@/core/components/shared/Loader';
import Taxes from '@/apps/billing/components/setting/Taxes';
import Terms from '@/apps/billing/components/setting/Terms';
import modalEmail from '@/apps/billing/components/modalEmail';
import noteEstPro from '@/apps/billing/components/estpro/notes';
import estimator from '@/apps/billing/components/estpro/estimator';
import ClickOutside from 'vue-click-outside';
import modalLateral from '@/core/plugins/themeModalLateral/modalLateral';
import sendSMSCustomer from '@/apps/billing/components/sendSMSCustomer';
import { mapGetters } from 'vuex';
export default {
    name: "Estimates",
    components: {
        Loader,
        Terms,
        Taxes,
        modalEmail,
        noteEstPro,
        estimator,
        sendSMSCustomer,
        modalLateral,
    },
    data () {
        return {
            m: {
                show: false,
                component: '',
                data: []
            },
            isLoading: false,
            showSMS: 1,
            showModal: false,
            SettingBox: "",
            EstimatesList: [],
            DataMailEstimate: {
                isLoading: false,
                idinv: null,
                IDitem: null, // numdoc
                email: null,
                EmailCheck: false,
                view: "estimate",
            },
            emailsE: [],
            inputFilter: "",
            OldEstimatesList: [],
            invStatus: 0,
            isFilter: false,
            isCheckAll: true,
            statusdata: [
                { value: "1", name: "Pending" },
                { value: "2", name: "Accepted" },
                { value: "3", name: "Rejected" },
                { value: "4", name: "Void" },
                { value: "5", name: "Signed" },
            ],
            statuslist: [1, 2, 3, 4, 5],
            statusdropd: false,
            paginador: 50,
            accountSMS: 0,
            PlanPremium : (window.OrgPlan > 1),
            validEprint: false,
            colsAll: [
                {id: '1', name: 'crm'},
                {id: '2', name: 'calc'},
            ],
            cols: ['2'],
            columnsdropd: false,
        };
    },
    created () {
        this.accountSMS = (!isNaN(localStorage.getItem('accountSMS'))) ? parseInt(localStorage.getItem('accountSMS')) : false;
        console.log('accountSMS', this.accountSMS)
        this.PlanPremium = (this.$store.getters['core/Org/getPlanId'] > 1);
        if(!this.PlanPremium){
            this.$router.push({ name: "Estimates" });
        }
        this.getShowSMS();
        this.getEstimates();
    },
    mounted () {},
    directives: {
        ClickOutside,
    },
    methods: {    	
        activeMenuRow(item, idxTR){
            let t = this;
            let tr= document.querySelector("#tr-"+idxTR);
            if(tr != null){
                t.outFocusTr(idxTR);
                if(!tr.classList.contains('activeTr')){
                    tr.classList.add('activeTr');
                    let btnOptions = document.querySelector(".btnOptions-"+idxTR);
                    let contentRowOptions = document.querySelector(".contentRowOptions-"+idxTR);
                    if(btnOptions!=null && contentRowOptions!=null){
                    	btnOptions.classList.add('active');
                    	contentRowOptions.classList.add('active');
                    }
                }else{
                	t.outFocusTr(idxTR);
                }
            }
        },        
        outFocusTr(idxTR){
            let t = this;
            let tr = document.querySelectorAll('.estpro tbody tr');
            tr.forEach(function(elem){
                if(elem.classList.contains('activeTr')){
                    elem.classList.remove('activeTr');
                    console.log('outFocusTr tr', elem)
                    if(idxTR!=null){
	                    let btnOptions = document.querySelector(".btnOptions-"+idxTR);
	                    let contentRowOptions = document.querySelector(".contentRowOptions-"+idxTR);
	                    if(btnOptions!=null && contentRowOptions!=null){
	                    	btnOptions.classList.remove('active');
	                    	contentRowOptions.classList.remove('active');
	                    }
                    }
                }
            });
            for (let i in t.EstimatesList) {
            	if(t.EstimatesList[i]){
            		t.EstimatesList[i].menuRow=false;
            	}
            }
        },
        actionContract(item, opt){
            if(item.signe==1 && item.status == 2 && item.status != 5){
                this.OpenSMS(item);
            }
            else if(item.signe==1 && item.status != 2 && item.status != 5){
                this.InfoSMS(item, opt);
            }else{
                this.InfoSMS(item, 3); // Contract already signed
            }
        },
        InfoSMS(item, opt){
            let typeAlert = 5; // 1=success | 2=info | 3=error | 4=loading | 5=warning
            let title = "To use SMS";
            let msg ="You must change the STATUS of the item to ACCEPTED to use this function.";
            if(opt==2){msg ="You must enable the contract signing option.";}
            if(opt==3){msg ="Contract already signed."; title="Info"; typeAlert=2;}
            if(opt==4){msg ="Contract already signed."; title="Info"; typeAlert=2;}
            this.$store.commit("core/setNotiPush", {
                group: "notiPush",
                type: typeAlert, // 1=success | 2=info | 3=error | 4=loading | 5=warning
                title: title,
                text: msg,
                show: true,
                duration: 5000
            }); 
        },
        OpenSMS(item){
            let t = this;
            if(!t.PlanPremium){
                t.$store.commit('core/session/setShowPopPremium', {show: true, data: []});
            }
            else {
                t.m = {
                    show: true,
                    component: 'sendSMSCustomer',
                    data: {
                        item: item, 
                        typeDocumentID: 0, 
                    }
                }
                t.$store.commit('core/setOpenModal', this.m);  
            }
        }, 
        searchAll(){
            this.inputFilter='';
            this.getEstimates();
        },
        sendSMSaction(item, type){
            let t = this;
            let data = {};
            let keys = Object.keys(item);
            for (let i in keys) {
                if (item[keys[i]]) {
                    data[keys[i]]=item[keys[i]];
                    if(keys[i] == 'need_contract' || keys[i] == 'signe'){
                        data[keys[i]]=0;
                    }
                }
            }
            //console.log('sendSMSaction', item, data)
            t.detailCustomerSMS(data, type);
        },
        detailCustomerSMS(item, type){
            if(!this.PlanPremium){
                this.$store.commit('core/session/setShowPopPremium', {show: true, data: []});
            }
            else {
                this.$store.commit("core/setNotiPush", {
                    group: "notiPush",
                    type: '4', // 1=success | 2=info | 3=error | 4=loading | 5=warning
                    title: "Sending sms",
                    text: "Loading...",
                    show: true,
                    duration: 500
                }); 
                let obj = {
                    id: item.id,
                    idNumdoc: item.numdoc,
                    icCustomer: item.customer_id,
                    customerName: item.firstname+' '+item.lastname,
                    need_contract: item.need_contract,
                    signe: item.signe
                };
                window.billing
                .post("getShellpdf", {      
                    user_id: window.localuserdata, 
                    org_id: window.localorgdata,           
                    oauth_m: window.localStorage.getItem("oauth_m"),
                    AccountID: window.localAccountID,
                    app_name: window.localStorage.getItem('nameApp'),
                    app_id: window.localStorage.getItem('appIDactual'),
                    id:item.id,
                    idcustomer: item.customer_id,
                    type:type,
                    parameters:JSON.stringify(obj),            
                })
                .then((response) => {
                    let r = response.data.status; 
                    if(r == false){
                        this.$notify({
                            group: "noti",
                            title: "Error",
                            type: "error",
                            text: "You must add mobile phone",
                        });
                    } else {                
                        this.$notify({
                            group: "noti",
                            title: "Send PDF by SMS",
                            type: "success",
                            text: "Send PDF by SMS Successful",
                        });    
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
            }
        },
        btnstatus() {
            this.statusdropd = !this.statusdropd;
        },
        hideBtnstatus() {
            this.statusdropd = false;
        },
        btncolumns() {
            this.columnsdropd = !this.columnsdropd;
        },
        hideBtncolumns() {
            this.columnsdropd = false;
        },
        checkAll: function () {
            this.isCheckAll = !this.isCheckAll;
            this.statuslist = [];
            if (this.isCheckAll) {
                for (var key in this.statusdata) {
                    this.statuslist.push(this.statusdata[key].value);
                }
                this.searchFilter();
            }
        },
        updateCheckall: function () {
            if (this.statuslist.length == this.statusdata.length) {
                this.isCheckAll = true;
            } else {
                this.isCheckAll = false;
            }
        },
        searchFilter() {
            if (this.statuslist.length > 0) {}
            this.isLoading = true;
            window.billing
            .post("filterestimate", {
                user_id: window.localuserdata,
                filter: this.inputFilter,
                status: this.statuslist,
                pag: this.paginador,
            })
            .then((response) => {
                //console.log('filter ',response.data.result);
                let listFilter = response.data.result;
                let e = listFilter;
                for (var i = 0; i < e.length; i++) {
                    let date_create = (new Date(e[i].date_create) + "").slice(4, 15);
                    let date_create_new = date_create.split(" ");
                    // listFilter[i].date_create=date_create_new[0] +" ";
                    // listFilter[i].date_create+=date_create_new[1] +", ";
                    // listFilter[i].date_create+=date_create_new[2];
                    listFilter[i].menuRow = false;
                }
                this.EstimatesList = listFilter;
                this.isFilter = true;
                this.isLoading = false;
                if(response.data.columnEstPro!=null){
                    let cols = response.data.columnEstPro.meta_value;
                    let columnsShow = (cols==0) ? [] : cols.split(',');
                    this.cols = columnsShow;
                }
            })
            .catch((error) => {
                this.isLoading = false;
                console.log(error);
            });
        },
        searchFilterPaginador() {
            if (this.paginador > 0) {
                this.searchFilter();
            } else {
                this.EstimatesList = this.OldEstimatesList;
                this.isLoading = false;
                this.isFilter = false;
            }
        },
        actionPdf(id) {
            window.open(window.urlbase + "pdf/estimate-pro/" + id, "_blank");
        },
        actionPDFforEnvelope(id) {
            window.open(window.urlbase + "api/v2/estimateenvelopes/pdf/" + id, "_blank");
        },
        actionEdit(id) {
            this.$router.push({ name: "estproFormEdit", params: { idest: id } });
        },
        actionDuplicate(id) {
            this.$router.push({
                name: "estproFormDuplicate",
                params: { idest: id, duplicate: "duplicate" },
            });
        },
        convertToInvoice(id) {
            this.$router.push({
                name: "EstimatesConvertInvoice",
                params: { idest: id, convert: "convert" },
            });
        },
        ClosedModal() {
            this.SettingBox = "";
        },
        getEstimates() {
            this.searchFilter();
            /*
            this.isLoading = true;
            window.billing
                .get("estimates/" + window.localuserdata)
                .then((response) => {
                    //console.log('estimates list');
                    this.validEprint = (response.data.ePrint && response.data.ePrint.meta_value);
                    this.EstimatesList = response.data.result;
                    let e = this.EstimatesList;
                    for (var i = 0; i < e.length; i++) {
                        let date_create = (new Date(e[i].date_create) + "").slice(4, 15);
                        let date_create_new = date_create.split(" ");
                        this.EstimatesList[i].menuRow = false;
                        this.EstimatesList[i].date_create =
                            date_create_new[0] +
                            " " +
                            date_create_new[1] +
                            ", " +
                            date_create_new[2];
                            this.EstimatesList[i].accountSMS = window.accountSMS
                    }
                    this.OldEstimatesList = this.EstimatesList;
                    //console.log(this.EstimatesList);
                    this.isLoading = false;
                    if(response.data.columnEstPro!=null){
                        let cols = response.data.columnEstPro.meta_value;
                        let columnsShow = (cols==0) ? [] : cols.split(',');
                        this.cols = columnsShow;
                    }
                })
                .catch((error) => console.log(error));
                */
        },
        actionEmail(id, email, numdoc) {
            this.DataMailEstimate.idinv = id;
            this.DataMailEstimate.IDitem = numdoc;
            this.DataMailEstimate.email = email;
            this.DataMailEstimate.validEprint = this.validEprint;
            this.DataMailEstimate.estpro = 1;
            let t = this;
            this.m = {
                show: true,
                component: 'modalEmail',
                data: {DataMailEstimate:t.DataMailEstimate}
            }
            this.$store.commit('core/setOpenModal', this.m);
        },
        openNotesEstPro(item){
            let t = this;
            t.m = {
                show: true,
                component: 'noteEstPro',
                data: item
            }
            t.$store.commit('core/setOpenModal', this.m);
        },
        sendMailEstimate(item) {
            if (item.DataItem.send == 1) {
                item.DataItem.send = 0;
                this.$store.commit("core/setCloseModal", {
                    action: 'sendMailEstimate',
                });
                this.getEstimates();
                this.$notify({
                    group: "noti",
                    title: "Success",
                    type: "success",
                    text: "Send Mail!",
                });
                this.$store.commit("ModApps/billing/SetResponseMail", null);
            }
        },
        actionPrint(idest) {
            this.isLoading = true;
            window.billing
                .post("eprintest/" + idest, {
                    user_id: window.localuserdata,
                    eprint: 1,
                })
                .then((response) => {
                    console.log(response.data);
                    this.isLoading = false;
                    if (response.data.status) {
                        this.$notify({
                            group: "noti",
                            title: "Send Mail!",
                            type: "success",
                            text: "Send Successful",
                        });
                    } else {
                        this.$notify({
                            group: "noti",
                            title: "Send Mail!",
                            type: "error",
                            text: "Send error",
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.$notify({
                        group: "noti",
                        title: "Send Mail!",
                        type: "error",
                        text: "Send error",
                    });
                });
        },
        MetDeleteEstimate(idest) {
            this.isLoading = true;
            this.$swal({
                title: "Delete this Estimate?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                if (result.value) {
                    window.billing
                        .put("estimate/delete/" + idest, {
                            user_id: window.localuserdata,
                        })
                        .then((response) => {
                            console.log(response.data);
                            this.isLoading = false;
                            if (response.data.status) {
                                this.getEstimates();
                                this.$notify({
                                    group: "noti",
                                    title: "Deleted!",
                                    type: "success",
                                    text: "Deleted Estimate Successful",
                                });
                            }
                        })
                        .catch((error) => console.log(error, (this.isLoading = false)));
                }
            });
            this.isLoading = false;
        },
        actionVoid(idest) {
            this.isLoading = true;
            this.$swal({
                title: "Void this Estimate?",
                text: "You will not be able to recover it",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Void it!",
            }).then((result) => {
                if (result.value) {
                    window.billing
                        .put("estimate/void/" + idest, {
                            user_id: window.localuserdata,
                        })
                        .then((response) => {
                            console.log(response.data);
                            this.isLoading = false;
                            if (response.data.status) {
                                this.getEstimates();
                                this.$notify({
                                    group: "noti",
                                    title: "Void!",
                                    type: "success",
                                    text: "Void Estimate Successful",
                                });
                            }
                        })
                        .catch((error) => console.log(error, (this.isLoading = false)));
                }
            });
            this.isLoading = false;
        },
        pusherEst(p){
            let t = this;
            console.log('pusherEst', p.action)
            let old = this.EstimatesList;
            let list =[];
            for (const i in old) { list.push(old[i]);}
            // actualizar status
            if(p.action == "estimate-status"){
                for (const i in list) {
                    if(list[i].id == p.data.id_est){
                        list[i].status = p.data.status;
                        if(p.data.updateNotes){
                            if(p.data.updateNotes.original.result){
                                list[i].notes = 1;
                            }
                        }
                    }
                }
            }
            // actualizar name
            if(p.action == "estimate-name"){
                for (const i in list) {
                    if(list[i].id == p.data.id_est){
                        list[i].estimate_optional = p.data.campo;
                    }
                }
            }
            // actualizar name
            if(p.action == "note-estimate"){
                for (const i in list) {
                    if(list[i].id == p.data.id_est){
                        list[i].notesEstpro = p.data.count;
                    }
                }
            }
            if(p.action == "estimate-add"){
                if(p.data.socket_id!=pusher.connection.socket_id){
                    if(p.data.getItem.length>0){
                        let newItem = p.data.getItem[0];
                        console.log(p.action, newItem);
                        if(t.inputFilter.length==0 && t.statuslist.length==5){
                            list.unshift(newItem);
                            t.delTagNew(newItem.id);
                        }
                    }
                }
            }
            if(p.action == "estimate-update"){
                if(p.data.socket_id!=pusher.connection.socket_id){
                    if(p.data.getItem.length>0){
                        let itemUpdate = p.data.getItem[0];
                        console.log(p.action, itemUpdate);
                        for (const i in list) {
                            if(list[i].id == itemUpdate.id){
                                // si hay coincidencia, revisar si hay datos actualizados
                                let keys = Object.keys(itemUpdate);
                                for (let index = 0; index < keys.length; index++) {
                                    if(list[i][keys[index]]){
                                        if(list[i][keys[index]]!= itemUpdate[keys[index]]){
                                            list[i][keys[index]] = itemUpdate[keys[index]];
                                            console.log('update id', itemUpdate.id, 'campo actualizado', keys[index], 'val='+itemUpdate[keys[index]]);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            this.EstimatesList = list;
        },
        delTagNew(id){
            setTimeout(() => {
                let t = this;
                let old = t.EstimatesList;
                let list =[];
                for (const i in old) { list.push(old[i]);}
                for (const i in list) {
                    if(list[i].id == id){
                        list[i].pusher = 0;
                        console.log('delTagNew', id);
                    }
                }
                t.EstimatesList = list;
            }, 6E4);
        },
        getShowSMS(){
            window.billing
            .post('getKeyValSetting', {
                user_id: window.master_client,
                org_id: window.localorgdata,
                meta_key: 'showSMS',
            })
            .then((response) => {
                console.log(response.data);
                if(response.data.status){
                    this.$store.commit('ModApps/billing/setShowSMS', response.data.r.meta_value);
                    this.showSMS = this.$store.getters['ModApps/billing/getShowSMS'];
                }else{this.showSMS = 1;} 
                console.log('showSMS', this.showSMS);
            })
            .catch((error) => {
                console.log(error);
            });
        },
        pusherCountSmsEst(p){
            console.log('updateCountSMS', p.action)
            let old = this.EstimatesList;
            let idEst = 0;
            let countSMs = 0;
            let d = p.accountSMS.split('-');
            idEst = d[0];
            countSMs = d[1];
            let list =[];
            for (const i in old) { list.push(old[i]);}
            // actualizar status
            if(p.action == "updateCountSMS"){
                for (const i in list) { 
                   if(list[i].id == idEst){
                        list[i].countSmsEst = countSMs;
                        console.log('pusherCountSmsEst', list[i])
                   }
                }
            }
            this.EstimatesList = list;
        },
        changeStatus(item, status_id){
            window.billing.post("changeStatusEst", {
                user_idd:  window.master_client,
                org_id: window.localorgdata,
                user_id: window.localuserdata,
                id: item.id,
                status_old: item.status,
                status_new: status_id,
                socket_id: pusher.connection.socket_id,
            })
            .then((response) => {
                if(response.data.status){
                    item.status=status_id;
                }
            }).catch((error) => {
                console.log(error);
            });
        },
        editNameEst(item){
            window.billing.post("changeNameEst", {
                user_idd:  window.master_client,
                org_id: window.localorgdata,
                user_id: window.localuserdata,
                id: item.id,
                estimate_optional: item.estimate_optional,
                socket_id: pusher.connection.socket_id,
            })
            .then((response) => {
                console.log('changeNameEst', response.data.status);
            }).catch((error) => {
                console.log(error);
            });
        },
        editCols() {
            let t = this;
            window.billing.post("/columnasEstpro", {
                user_idd: window.master_client,
                user_id: window.localorgdata,
                org_id: window.localorgdata,
                typeUser: window.user_type,
                oauth: window.oauth_m,
                columnas: t.cols.toString(),
                socket_id: pusher.connection.socket_id,
            })
            .then((response) => {
                console.log('columnasEstpro', response.data.r);
                //this.searchFilter(1);
                if(response.data.r){
                    let cols = response.data.r.meta_value;
                    console.log('setting_columnas', cols)
                    // this.showCols(cols);
                }
            })
            .catch((error) => {
                console.log(error);
                t.isLoading = false;
            });
        },
        OpenCalcEstimator(item, idxTR){
            let t = this;
            if(idxTR==3){
                t.m = {
                    show: true,
                    component: 'estimator',
                    data: item
                }
                t.$store.commit('core/setOpenModal', t.m);
            }else{
                // t.InfoSMS(item, 4);
            }
        },
    },
    watch: {
        SettingBox: function () {
            if (!this.SettingBox == "") {
                this.$modal.show("hello-world");
            }
        },
        getResponseMail: function () {
            console.log("getResponseMail", this.getResponseMail);
            if (this.getResponseMail && this.getResponseMail.fEventBus === "estimate-email") {
                this.sendMailEstimate(this.getResponseMail);
            }
        },
        'getOpenModal': function() {
            if (!this.getOpenModal.show) {
                if (Object.keys(this.getOpenModal.data).length > 0) {
                    let t = this.getOpenModal.data;
                    if (t.methods === 'invoNote') {
                        let id = t.id
                        let value = t.value
                        let g = this.EstimatesList
                        let a = []
                        for (let i in g) {
                            if (id === g[i].id) {
                                g[i].notes = value;
                            } 
                            a.push(g[i]);
                        }
                        this.EstimatesList = a;
                    }
                }
                this.m = {
                    show: false,
                    component: '',
                    data: {}
                }
            }
        },
        'getActionRefresh': function() {
            if (Object.keys(this.getActionRefresh.data).length > 0) {
                    let t = this.getActionRefresh.data;
                    if (t.methods === 'invoNote') {
                        let id = t.id
                        let value = t.value
                        let g = this.EstimatesList
                        let a = []
                        for (let i in g) {
                            if (id === g[i].id) {
                                g[i].notes = value;
                            } 
                            a.push(g[i]);
                        }
                        this.EstimatesList = a;
                    }
                }
        },
		accitonPusher: function () {
			if (this.accitonPusher != undefined) {
				let p = this.accitonPusher;
                if (p.action == "serviceSMS") {
                    this.isLoading = true;
                    let y = this.EstimatesList
                    for (const i in y) {
                        this.EstimatesList[i].accountSMS = p.accountSMS
                    }
                    localStorage.setItem('accountSMS', p.accountSMS);
                    this.accountSMS = (!isNaN(localStorage.getItem('accountSMS'))) ? parseInt(localStorage.getItem('accountSMS')) : false;
                    this.isLoading = false;
				}
                if(window.master_client == p.user_id && window.localorgdata == p.org_id){
                    this.pusherEst(p);
                }
                if(window.master_client == p.user_id && window.localorgdata == p.org_id && p.action == "showSMS"){
                    this.getShowSMS();
                }
                if(window.master_client == p.user_id && window.localorgdata == p.org_id && p.action == "updateCountSMS"){
                    this.pusherCountSmsEst(p);
                }
			}
		},
        getPlanId(){
            this.PlanPremium = (this.$store.getters['core/Org/getPlanId'] > 1);
            console.log('watch PlanPremium', this.PlanPremium);
        }, 
        getStoreShowSMS(){
            this.showSMS = this.getStoreShowSMS;
            console.log('showSMS', this.showSMS);
        }, 
    },
    computed: {
        ...mapGetters("core", { 
            getOpenModal: "getOpenModal", 
            getActionRefresh: "getActionRefresh", 
            accitonPusher: "get_accitonPusher", 
        }),
        ...mapGetters("ModApps/billing", { getResponseMail: "getResponseMail" }),
        ...mapGetters("ModApps/billing", { getStoreShowSMS: "getShowSMS" }),
        ...mapGetters("core/Org", { getPlanId: "getPlanId"}),
        Pending: function () {
            return this.EstimatesList.filter((r) => r.status === 1);
            //return this.requests.filter(r => r.status === "APPROVED");
        },
        Accepted: function () {
            return this.EstimatesList.filter((r) => r.status === 2);
        },
        Rejected: function () {
            return this.EstimatesList.filter((r) => r.status === 3);
        },
        Void: function () {
            return this.EstimatesList.filter((r) => r.status === 4);
        },
        Sing: function () {
            return this.EstimatesList.filter((r) => r.status === 5);
        },
        datecreate: function (date) {
            return "hola";
        },
        listData: function(){
        	  return this.EstimatesList.filter((r) => {return r});
        },
    },
};
</script>
<style lang="scss" scoped>
@import "./styles.scss";
</style>
