<template>
	<div class="container-principal">
		<navtop></navtop>
		<barmenu></barmenu>
		<div class="page-home">
			<div class="container ">
				<div class="row" v-if="website==0">
					<div class="col-3"></div>
					<div class="col-6">
						<formsite></formsite>
					</div>
					<div class="col-3"></div>
				</div>
				<div class="row" v-else>
					<div class="col-12" v-if="selectWeb">
						<ul v-if="listWebs.length>0">
							<li v-for="(i, index) in listWebs" :key="index">
								<a href="#" @click.prevent="markerWeb(i)">
									<i class="fas fa-star" v-if="i.marker==1"></i>
									<i class="far fa-star" v-else></i>
								</a>
								<a href="#" @click.prevent="changeWeb(i)">
									<i class="fas fa-bookmark" v-if="i.chose==1"></i>
									<i class="far fa-bookmark" v-else></i>
									[{{i.id}}]
								</a> {{i.name}}
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import navtop from "../components/sitebuilder/navTop.vue";
import barmenu from "../components/sitebuilder/barMenu.vue";
import formsite from "../components/home/formCreateSite.vue";
export default {
  components: {
		navtop,
		barmenu,
		formsite,
  },
  name: 'sitesHome',
  data() {
    return {
      navEditor: false,
      listWebs: [],
      website: 0,
      selectWeb: true,
    }
  },
  created(){
  	let t = this;
    t.listWebs = t.$store.getters['ModApps/Sites/get_webs'];
  	t.selectWeb=true;
    t.website=t.listWebs.length;
    if(t.website>0){
    	t.$store.commit('ModApps/Sites/set_addWeb', false);
    }
    else{
    	t.$store.commit('ModApps/Sites/set_addWeb', true);
    }
  },
  methods:{
    changeWeb(i){
      let t = this;
      console.log('changeWeb', i)
      t.$store.commit('ModApps/Sites/set_webChose', i);
      t.show=false;
    },
  	markerWeb(i){
      let t = this;
  		t.$store.commit('ModApps/Sites/set_loadSites', true);     
      window.master
      .post('sites/markerWeb', {
          user_id: window.master_client,
          org_id: window.localorgdata,
          token: window.localtoken_M,
          web_id: i.id,
      })
      .then((response) => {
        console.log('markerWeb', response.data)
        if(response.data.status){
        }
        t.$store.commit('ModApps/Sites/set_loadSites', false);
      })
      .catch((error) => {
        console.log(error);
        t.$store.commit('ModApps/Sites/set_loadSites', false);
      });
  	},
  },
  watch: {
    "get_webs": function(){
      if(this.get_webs.length>0){
        this.listWebs = this.get_webs;
        this.website=this.listWebs.length;
    		this.$store.commit('ModApps/Sites/set_addWeb', false);
      }
    },
    addWeb: function(){
    	console.log('addWeb', this.addWeb)
    	this.website=(this.addWeb) ? 0 : 1;
    },
    getPusher: function(){
    	let t = this;
    	if (t.getPusher != undefined) {
    		let token = localStorage.getItem('access_token_m');
    		let oauth_m = localStorage.getItem('oauth_m');
    		if(t.getPusher.data.org_id == window.localorgdata){
    			// solo acciones de sesion
    			if(t.getPusher.token == token || t.getPusher.oauth != oauth_m){
    			}
    			// en generar para la org
    			if(t.getPusher.action == "act_listWebs"){
    				let listWebs = t.getPusher.data.webs;
    				console.log('getPusher act_listWebs', listWebs)
    				if(listWebs.length>0){
    					t.$store.commit('ModApps/Sites/set_webs', listWebs);
    				}
    			}
  				if(t.getPusher.action == "act_markerWebs"){
  					console.log('act markerWeb', t.getPusher.data.marker)
  					t.$store.commit('ModApps/Sites/set_markerWeb', t.getPusher.data.marker);
  				}
    		}
    	}
    },
  },
  computed: {
        ...mapGetters("core", { 
            getPusher: "get_accitonPusher",
            //getOpenModal: 'getOpenModal',
        }),
    ...mapGetters("ModApps/Sites", { get_webs: "get_webs", addWeb: "get_addWeb" }),
  },
};
</script>
<style scoped lang="scss">
.container-principal{
	margin-top: 0px;
}
.page-home {
   margin-top: 10px;
   margin-left: 120px;
}
</style>
