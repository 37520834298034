<template>
    <div class="card bot-Estimator">
        <div class="card">
            <div class="card-header">
                    <div class="robot">
                        <i class="fas fa-robot"></i>
                        <div class="namebot">
                            <h3>Bot Estimator</h3>
                            <div :class="['loader', (wirting==true) ? 'in' : '']" >
                                <div class="loading"></div>
                            </div>
                        </div>
                    </div>
            </div>
            <div class="card-body">
                <div class="alert alert-primary" v-if="alertInfo" id="alertInfo">
                    <button type="button" class="close" @click="closeAlertinfo">
                        <span aria-hidden="true">&times;</span>
                        <span class="sr-only">Close</span>
                    </button>
                    <strong>Welcome to your estimator assistant</strong>, answer the questions and wait for your result.
                </div>
                <div class="chat-body">
                    <ul>
                        <li class="message" v-for="(i, index) in showOnScreen" :key="index">
                            <div class="message-body">
                                <h4 class="questionAndAnswer">
                                    <span v-if="index==0">Hi
                                        <span class="name"> {{profile.name}} </span>,
                                    </span>
                                    {{i.question}}
                                </h4>
                                <ul v-if="i.options.length>0">
                                    <li v-for="(o, oIdx) in i.options" :key="oIdx"
                                        :class="[
                                            (o.title!=null) ? 'selectOptions' : 'inputText',
                                            (index==3 || index==5) ? 'btn-number' : '',
                                            (selectedAnswer[index] && o.value==selectedAnswer[index].AnswerId) ? 'active' : ''
                                        ]"
                                    >
                                        <div v-if="o.title!=null" class="option-name" @click="setAnswer(index, i.idQest, o.value)">
                                            <i class="fas fa-check" v-show="selectedAnswer[index] && o.value==selectedAnswer[index].AnswerId"></i>
                                            <!-- <span v-if="step.roofSQ==false">{{oIdx+1}} - </span> -->
                                            <span>{{ o.title }}</span>
                                            <div v-if="o.img!=null" class="option-img">
                                                <img :src="o.img" :alt="'img '+o.title" />
                                            </div>
                                        </div>
                                        <div v-else class="option-input">
                                            <input type="number"
                                                v-model="o.value"
                                                class="form-control"
                                                step="0.01"
                                                min="0"
                                                max="999999.99"
                                                maxlength="9"
                                                placeholder="E.g. 1.00"
                                                @keypress="quantityIsInteger"
                                                @change="isInterger"
                                                :disabled="(step.roofSQ)"
                                            />
                                            <button class="btn-save" @click="setAnswer(index, i.idQest, o.value)">
                                                <i class="fas fa-save"></i>
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="message-body me" v-if="selectedAnswer[index] && showAnswer!=null">
                                <span v-if="showAnswer(i, index).text!=null">{{ showAnswer(i, index).text }}</span>
                                <span v-if="showAnswer(i, index).val!=null && index==2">{{ showAnswer(i, index).val }} Sq. </span>
                                <div v-if="showAnswer(i, index).img!=null" class="option-img">
                                    <img :src="showAnswer(i, index).img" :alt="'img-Answer-'+showAnswer(i, index).text" />
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="result" v-if="showOnScreen.length == selectedAnswer.length && finish">
                        <i class="far fa-check-circle"></i>
                        <h3>Analysis Finished</h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.<br>
                            <strong class="total">Total  $0.00</strong>
                            <strong class="profit">Profit $0.00</strong>
                        </p>
                    </div>
                    <div class="loader" v-show="wirting">
                        <div class="loading"></div>
                    </div>
                </div>
                <!-- <div class="chat-footer">
                    <textarea name="" id="message" placeholder="Escribir mensaje..."></textarea>
                    <button type="button" id="sendMessage"><i class="fas fa-paper-plane"></i></button>
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "estimator",
    data(){
        return {
            alertInfo: true,
            listQuest:[
            ],
            selectedAnswer:[],
            showOnScreen: [],
            indexQuest: 0,
            profile:null,
            finish: false,
            wirting: true,
            step:{
                serviceType: false,
                materialType: false,
                roofSQ: false,
                roofStories: false,
                typeRoof: false,
                layersStripping: false,
                roofComplexity: false,
            },
        };
    },
    created(){
		let t = this;
		t.$store.dispatch('core/session/validTypeUser').then((admin) => {
            t.getProfile(admin).then((r) => {
				t.init();
            });
        });
    },
    methods: {
        init(){
		let t = this;
        t.getServiceTypes().then(r => {
            if(r!==false){
                t.listQuest.push({
                    idQest: 1,
                    question: 'I understand that you want to create an Estimate. What type of Service?',
                    options: r,
                });
                /*for (let index = 2; index <= 5; index++) {
                    let newItem = {
                        idQest: index,
                        question: 'Name Question '+index+'?',
                        options:[{
                                title: 'Answer One',
                                value:'1',
                                text: null,
                                img: null,
                            }, {
                                title: 'Answer Two',
                                value:'2',
                                text: null,
                                img: null,
                            }, {
                                title: 'Answer Three',
                                value:'3',
                                text: null,
                                img: null,
                            }, {
                                title: 'Answer Four',
                                value:'4',
                                text: null,
                                img: null,
                            },
                        ]
                    };
                    t.listQuest.push(newItem);
                }*/
                t.showQuestion(t.indexQuest);
            }
        })
        },
        getProfile(admin) {
			let t = this;
            return new Promise((resolve, reject) => {
                window.master
                .post('userProfile', {
                    user_id: window.localAccountID,
                    admin: admin
                })
                .then((response) => {
                    t.profile = response.data.profile;
                    resolve(true);
                })
                .catch((error) => {
                    console.log(error)
                    resolve(false);
                })
            });
        },
        showAnswer(i, index){
            let t = this;
            let res =i.options;
            let text = null;
            let img = null;
            let val = 0;
            for (const key in res) {
                if (res[key].value==t.selectedAnswer[index].AnswerId) {
                    text = res[key].title;
                    if(index==3){
                        text = (res[key].value==1) ? res[key].title+' Story' : res[key].title+' Stories';
                    }
                    if(index==5){
                        text = (res[key].value==1) ? res[key].title+' Layer' : res[key].title+' Layers';
                    }
                    img = res[key].img;
                    val = res[key].value;
                }
            }
            return {
                text,
                img,
                val,
            };
        },
        showQuestion(i){
            let t = this;
            if(Object.keys(t.listQuest).includes(i.toString())){
                if(Object.keys(t.showOnScreen).includes(i.toString())){
                    t.showOnScreen[i]=t.listQuest[i];
                    t.wirting=false;
                }else{
                    t.scrollDown()
                    setTimeout(function(){
                        t.wirting=false;
                        t.showOnScreen.push(t.listQuest[i]);
                        t.scrollDown()
                    }, 1000);
                }
                console.log(t.showOnScreen);
            }
        },
        setAnswer(index, question, answer){
            let t = this;
            if(Object.keys(t.selectedAnswer).includes(index.toString())){
                t.selectedAnswer[index]={qestId: question, AnswerId: answer};
                t.nextQuest(index);
            }
            else{
                t.wirting=true;
                t.selectedAnswer.push({qestId: question, AnswerId: answer});
                t.scrollDown()
                t.indexQuest++;
                t.nextQuest(t.indexQuest);
            }
            console.log(t.selectedAnswer);
        },
        nextQuest(indexNext){
            let t = this;
            if(t.selectedAnswer.length==1){
                t.step.serviceType=true;
                t.getMaterialTypes(t.selectedAnswer[0].AnswerId).then(r => {
                    if(r!==false){
                        t.listQuest.push({
                            idQest: 2,
                            question: 'Select the type of material you want:',
                            options: r,
                        });
                        t.showQuestion(indexNext);
                    }
                });
            }
            else if(t.selectedAnswer.length==2){
                t.step.materialType=true;
                t.listQuest.push({
                    idQest: 2,
                    question: 'How big is the Roof (sq)?',
                    options: [{
                        title: null,
                        value: '20.00',
                        text: null,
                        img: null,
                    }],
                });
                t.showQuestion(indexNext);
            }
            else if(t.selectedAnswer.length==3){
                t.step.roofSQ=true;
                let pisos = [];
                for (let index = 1; index <= 5; index++) {
                    let nom = (index < 5) ? index : '+4';
                    pisos.push({
                        title: nom,
                        value: index,
                        text: null,
                        img: null,
                    });
                };
                t.listQuest.push({
                    idQest: 2,
                    question: 'Roof Stories?',
                    options: pisos,
                });
                t.showQuestion(indexNext);
            }
            else if(t.selectedAnswer.length==4){
                t.step.roofStories=true;
                t.listQuest.push({
                    idQest: 2,
                    question: 'Type of Existing Roof:',
                    options: [{
                        title: 'Asphalt',
                        value: '1',
                        text: null,
                        img: null,
                    }, {
                        title: 'Slate',
                        value: '2',
                        text: null,
                        img: null,
                    }, {
                        title: 'Rubber',
                        value: '3',
                        text: null,
                        img: null,
                    }, {
                        title: 'Metal',
                        value: '4',
                        text: null,
                        img: null,
                    }],
                });
                t.showQuestion(indexNext);
            }else if(t.selectedAnswer.length==5){
                t.step.typeRoof=true;
                let stripping= [];
                for (let index = 1; index <= 5; index++) {
                    stripping.push({
                        title: index,
                        value: index,
                        text: null,
                        img: null,
                    });
                };
                t.listQuest.push({
                    idQest: 2,
                    question: 'Layers of Stripping:',
                    options: stripping,
                });
                t.showQuestion(indexNext);
            }else if(t.selectedAnswer.length==6){
                t.step.layersStripping=true;
                t.listQuest.push({
                    idQest: 2,
                    question: 'Roof Complexity:',
                    options: [{
                        title: 'Flat Roof',
                        value: '1',
                        text: null,
                        img: window.urlmaster+'images/no-image.png',
                    }, {
                        title: 'Standard',
                        value: '2',
                        text: null,
                        img: window.urlmaster+'images/no-image.png',
                    }, {
                        title: 'Intermedium',
                        value: '3',
                        text: null,
                        img: window.urlmaster+'images/no-image.png',
                    }, {
                        title: 'Complex',
                        value: '4',
                        text: null,
                        img: window.urlmaster+'images/no-image.png',
                    }],
                });
                t.showQuestion(indexNext);
            }else if(t.selectedAnswer.length==7){
                t.step.roofComplexity=true;
                t.wirting=false;
            }
            else{
                if(t.showOnScreen.length == t.listQuest.length){
                    t.finish=false;
                    t.wirting=false;
                }
            }
        },
        closeAlertinfo(){
            this.alertInfo = false;
        },
        scrollDown(){
            setTimeout(function(){
                let scroll=document.querySelector(".chat-body");
                if(scroll){
                    scroll.scrollTop=scroll.scrollHeight;
                }
            }, 200);
        },
        getServiceTypes(){
            let t = this;
            return new Promise((resolve, reject) => {
                window.master.post('getServiceTypesUsers', {
                    user_id: window.master_client,
                    org_id: window.localorgdata,
                })
                .then((response) => {
                    let s = response.data;
                    if(s.status){
                        let services = [];
                        for (const i in s.services) {
                            if (s.services[i]) {
                                let img = s.rutaSamll+s.services[i].img;
                                services.push({
                                    value: s.services[i].id,
                                    title: s.services[i].name,
                                    img: (s.services[i].img!=null) ? img : null,
                                    text: null,
                                });
                            }
                        }
                        resolve(services);
                    }else{
                        resolve(false);
                    }
                })
                .catch((error) => {
                    console.log(error)
                    resolve(false);
                })
            });
        },
        getMaterialTypes(service_id){
            let t = this;
            return new Promise((resolve, reject) => {
                window.master.post('getMaterialTypesUsers', {
                    user_id: window.master_client,
                    org_id: window.localorgdata,
                    service_id: service_id,
                })
                .then((response) => {
                    let s = response.data;
                    if(s.status){
                        let materials = [];
                        for (const i in s.list) {
                            if (s.list[i]) {
                                let img = s.rutaSamll+s.list[i].img;
                                materials.push({
                                    value: s.list[i].id,
                                    title: s.list[i].name,
                                    img: (s.list[i].img!=null) ? img : null,
                                    text: null,
                                });
                            }
                        }
                        resolve(materials);
                    }else{
                        resolve(false);
                    }
                })
                .catch((error) => {
                    console.log(error)
                    resolve(false);
                })
            });
        },
        quantityIsInteger(e) {
            var code = (e.which) ? e.which : e.keyCode;
            if((code>=48 && code<=57) || code==46) { // is a number.
                return true;
            } else{ // other keys.
                // console.log('other keys', code);
                return e.preventDefault();
            }
        },
        isEmptyNaN(val){
            if (isNaN(val)){ return true;}
            else if (val==null){ return true;}
            else if (val==''){ return true;}
            else{ return false;}
        },
        isInterger(e){
            let t=this;
            console.log(e.target.value)
            if (t.isEmptyNaN(e.target.value)) {e.target.value = '0.00';}
            e.target.value = parseFloat(e.target.value.replace(/,/g, "."))
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, "");
        },
    },
    watch: {
        selectedAnswer: function(){
            let t = this;
        }
    }
}
</script>
<style scoped lang="scss">
.card.bot-Estimator{
    padding: 0px;
    .loader{
        position: relative;
        transition: all 1.5s;
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        align-content: space-around;
        justify-content: center;
        align-items: center;
        .loading{
            border-radius: 50%;
            width:1em;
            height: 1em;
            position: relative;
            color:#031099;
            animation: writing 1.5s infinite ease-in-out;
            animation-delay: -0.16s;
            &:before, &:after{
                border-radius: 50%;
                width:1em;
                height: 1em;
                position: absolute;
                top:0px;
                content:"";
                color:#031099;
                animation: writing 1.5s infinite ease-in-out;
            }
            &:before{left: -1.5em;animation-delay: -0.32s;}
            &:after{right: -1.5em;}
        }
    }
    .card-header{
        padding: 0px;
        padding-top: 10px;
        background: #fff;
        .robot{
            display: flex;
            align-items: flex-end;
            align-content: center;
            margin-left: 15px;
            margin-bottom: 10px;
            i{
                font-size: 30px;
                color:#031099;
                border:1px solid #ccc;
                border-radius: 50%;
                padding: 8px 5px;
                margin-right: 10px;
            }
            .namebot{
                display: block;
                h3{display: block;margin: 0px;}
                .loader{
                    z-index: 1;
                    top: -30px;
                    opacity: 0;
                    &.in{opacity: 1;}
                    .loading, .loading:before, .loading:after{
                        width: 0.5em;
                        height: 0.5em;
                    }
                    .loading:before{left: -1em;}
                    .loading:after{right: -1em;}
                }
            }
        }
    }
    .card-body{
        padding: 0px;
        background: #fff;
        #alertInfo{margin-bottom: 0rem;}
        .chat-body {
            position: relative;
            height: calc(80vh - 50px);
            background: #fff;
            display: block;
            overflow-y: scroll;
            overflow-x: hidden;
            scroll-behavior: smooth;
            padding: 10px;
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            border: 1px solid #fff;
            border-top: none;
            .robot{
                text-align: center;
                i{ font-size: 50px;}
            }
            ul {
                margin: 0;
                padding: 0;
                li.message {
                    &:first-child {margin-top: 10px;}
                    display: block;
                    position: relative;
                    margin: 20px 20px 40px 20px;
                    transition-property: width;
                    transition-duration: 1s;
                    transition-delay: 0s;
                    animation: aparecer 0.2s ease;
                    .message-body {
                        display: block;
                        width: 100%;
                        margin-left: 0px;
                        margin-top: 10px;
                        padding: 10px 10px;
                        background: #f0f0f0;
                        border-radius: 20px;
                        border-top-left-radius: 0px;
                        width:100%;
                        max-width: 380px;
                        &.me{
                            padding: 3px 10px;
                            border: 1px solid #ddd;
                            border-radius: 20px;
                            border-bottom-right-radius: 0px;
                            margin-left: calc(100% - 175px);
                            margin-bottom: 10px;
                            max-width: 172px;
                            vertical-align: top;
                            display: flex;
                            flex-direction: column;
                            background: #031099;
                            span{
                                padding: 5px 10px 5px 10px;
                                font-size: 20px;
                                color: #fff;
                            }
                            .option-img {
                                width: 150px;
                                position: relative;
                                display: flex;
                                justify-content: center;
                                overflow: hidden;
                                border-radius: 4px;
                                img{
                                    height: 120px;
                                    margin-bottom: 10px;
                                    border-radius: 4px;
                                }
                            }
                        }
                        .questionAndAnswer {
                            display: block;
                            font-weight: normal;
                            color: #555;
                            line-height: 1.5;
                            font-size: 20px;
                            text-align:left;
                            margin-bottom: 20px;
                            span.name{font-weight: 700;}
                        }
                        ul {
                            margin: 0;
                            padding: 0;
                            li{
                                margin: 0;
                                display: inline-block;
                                padding: 3px 10px;
                                border: 1px solid #ddd;
                                border-radius: 10px;
                                margin-right: 5px;
                                margin-bottom: 10px;
                                font-size: 18px;
                                max-width: 172px;
                                vertical-align: top;
                                &.selectOptions{
                                    width: 172px;
                                    max-width: 172px;
                                    &.btn-number {
                                        width: 50px;
                                        max-width: 50px;
                                        margin-right: 15px;
                                        height: 50px;
                                        padding: 0px;
                                        .option-name{
                                            position: relative;
                                            text-align: center;
                                            padding-top: 8px;
                                            height: 50px;
                                            span {
                                                font-size: 22px;
                                            }
                                            i.fas.fa-check{
                                                position: absolute;
                                                font-size: 12px;
                                                top: -3px;
                                                right: -5px;
                                            }
                                        }
                                    }
                                }
                                &.inputText{width: 215px;max-width: 215px;}
                                &:hover{
                                    cursor: pointer;
                                    background-color: #ccc;
                                }
                                &.active:hover{background-color: #ccc;}
                                &.active{background-color: #ddd;}
                                .option-img {
                                    width: 150px;
                                    position: relative;
                                    display: flex;
                                    justify-content: center;
                                    overflow: hidden;
                                    border-radius: 4px;
                                    img{
                                        height: 120px;
                                        margin-bottom: 10px;
                                        border-radius: 4px;
                                    }
                                }
                                .option-input {
                                    input.form-control{
                                        font-size: 18px;
                                        border-radius: 10px;
                                        border-top-right-radius: 0px;
                                        border-bottom-right-radius: 0px;
                                        border: 1px solid #e9ecef;
                                        border-right: 0px;
                                        width: 150px;
                                        display: inline-block;
                                        outline: none;
                                        &:focus{outline: none;}
                                        &[type=number]::-webkit-inner-spin-button,
                                        &[type=number]::-webkit-outer-spin-button{
                                            -webkit-appearance: none;
                                            margin: 0;
                                        }
                                        &[type=number]{
                                            -moz-appearance: textfield;
                                        }
                                    }
                                    .btn-save{
                                        box-shadow: none;
                                        border:1px solid;
                                        color: #fff;
                                        font-size: 20px;
                                        background-color: #031099;
                                        width: 40px;
                                        height: 42px;
                                        border: 1px solid #e9ecef;
                                        border-left: 0px;
                                        border-top-right-radius: 10px;
                                        border-bottom-right-radius: 10px;
                                    }
                                }
                            }
                        }
                    }
                    .message-body time {
                        position: relative;
                        display: block;
                        font-size: 10px;
                        line-height: 10px;
                        color: #fff;
                        margin-top:5px;
                        margin-bottom:10px;
                        text-align: right;
                    }
                    span.tail-in {
                        position: absolute;
                        top: 14px;
                        left: 5px;
                        color: #031099;
                    }
                }
            }
            .result{
                display: block;
                margin: auto;
                margin-top: 20px;
                margin-bottom: 100px;
                line-height: 1.3;
                text-align:center;
                font-size: 14px;
                width: 100%;
                max-width: 300px;
                color:#555;
                h3{font-size: 34px;}
                i{display: block;font-size: 50px;color:#489f38;}
                p{
                    font-size: 15px;
                    .total{
                        display: block;
                        text-align: left;
                        font-size: 16px;
                        font-weight: 700;
                        margin-top:10px;
                        max-width: 200px;
                        margin-left: 100px;
                    }
                    .profit{
                        display: block;
                        text-align: left;
                        font-size: 16px;
                        font-weight: 700;
                        margin-top:10px;
                        color: #031099;
                        max-width: 200px;
                        margin-left: 100px;
                    }
                }
            }
        }
        .chat-footer {
        position: relative;
        height: 10vh;
        border-top: 1px solid #ddd;
        textarea#message {
            width: 90%;
            height: 90%;
            border: 0px;
            outline: none;
            border-right: 1px solid;
            font-size: 16px;
            color: #555;
            padding: 5px 5px 5px 10px;
            resize: none;
            border-color: #ddd;
        }
        button#sendMessage {
            position: absolute;
            top: 0px;
            height: 100%;
            width: 10%;
            border: 1px solid #ddd;
            background: #0d6bdc;
            color: #fff;
            font-size: 25px;
            cursor: pointer;
        }
    }
    }
}
@keyframes writing {
    0%,
    80%,
    100%{
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40%{
        box-shadow: 0 2.5em 0 0;
    }
}
@keyframes aparecer {
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}
</style>
