let showGallery = function(i) {
    let g = {
        jobsNoteNum: i.id,
        jobsNoteID: i.id_i,
        jobsNote: i.notes,
        jobsEdicion: i.edicion,
        jobsLinkDrive: i.link_drive,
        JobsIFGallery: i.gallery,
        JobsGalleryList: i.galleryList,
        JobsGalleryURL: i.url,
        JobsGallerySHOW: true,
        typez: this.typez,
        listTaks: this.listTaks,
        methods:"galleryBox",
        action:"modal",
    }
    this.$store.commit("ModApps/jobs/set_jobsDash", g);  
};
export default showGallery;
