<template>
	<div>
		<header-principal></header-principal>
		<div v-if="accessApp">
            <component 
                v-if="getHeader" 
                v-bind:is="component"
                @activarLateral="showAside = $event"
            ></component>
			<div 
                v-if="getHeader" 
                v-bind:class="[
                    'page-wrapper', 
                    {show: showAside}, 
                    {'asidemak-active':maquetador}
                ]" 
            >
				<router-view></router-view>
			</div>
		</div>
		<footer-principal></footer-principal>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import HeaderPrincipal from "@/core/views/Header";
import maside from "@/apps/Marketea/views/Aside";
import asidemak from "@/apps/Marketea/components/LandingPage/maquetador/lateral";
import FooterPrincipal from "@/core/views/Footer";
export default {
    name: "Layout",
    components: {
        HeaderPrincipal,
        maside,
        asidemak,
        FooterPrincipal,
    },
    data() {
        return {
            showAside: false,
            maquetador: false,
            component: 'maside',
            bsite_idApp: 8,
        };
    },
    created() {
        if (this.loggedIn && this.getHeader) {
            //console.log('si hay login M');
            this.validAccess();
        }
    },
    methods: {
        validAccess() {
            let t = this;
            t.$store.commit("set_isLoading", true);
            t.$store.dispatch("core/apps/validAccess").then((response) => {
                let App = response;
                if (App) {
                    //si tiene Marketea asignado entonces permitir acceso
                    t.$store.commit("set_isLoading", true);
                    // t.$store.dispatch("core/apps/getPlanApp", localStorage.getItem('appIDactual'))
                    t.$store.dispatch("core/apps/getPlanApp", t.bsite_idApp)
                    .then((response) => {
                        t.$store.dispatch("ModApps/Marketea/getStatusAppCRM").then((statusApp) =>{
                            let plan_id = t.$store.getters['core/apps/getPlanIdApp'];
                            let continuarAccessApp = false;
                            if(plan_id==3 && statusApp.statusAppCRM==1){continuarAccessApp = true;}
                            // statusApp.statusPay==1 && 
                            else if(statusApp.statusAppCRM==1){continuarAccessApp = true;}
                            else{continuarAccessApp = false;}
                            if(continuarAccessApp){
                                t.$store.commit("set_isLoading", false);
                                console.log("Accesso permitido");
                                t.getAppStatus();
                                t.$store.dispatch("ModApps/Marketea/getPlugins").then(p => {
                                    t.$store.dispatch("ModApps/Marketea/SetActive", App);
                                    let url = t.$router.currentRoute.name;
                                    t.asideMaquetador(url);
                                });
                            }else{
                                console.log("Accesso negado");
                                let name ='bSite';
                                let msj = this.$store.getters["core/apps/getMsgAccessSuspended"];
								let msg = msj.msg.replace('[nameApp]', name);
								this.$store.commit('core/setNotiPush', {
									title: msj.title,
									text: msg,
									show: true,
									group: "notiPush",
									type: '5', // 1=success|2=info|3=error|4=loading|5=warning
									duration: 15000
								});
                                setTimeout(function(){
                                    t.$store.commit("set_isLoading", false);
                                    t.$router.push({ name: "Home" });
                                }, 5000);
                            }
                        }).catch(error => {
                            console.log(error);
                            t.errorAccess()
                        });
                    }).catch(error => {
                        console.log(error);
                        t.errorAccess()
                    });
                } else {
                    t.$store.commit("set_isLoading", false);
                    t.$store.dispatch("ModApps/Marketea/SetActive", App);
                    console.log("Accesso Negado");
                    t.$router.push({ name: "Home" });
                } //sino volver a home
            });
        },
        asideMaquetador(url){
            // console.log('url layout', url);
            if (url == 'maquetador' || url == 'editMaquetador') {
                this.maquetador= true;
                this.component = 'asidemak';
            }
            else{
                this.maquetador= false;
                this.component = 'maside';
            }
        },
        errorAccess(){
            let t = this;
            let msj = this.$store.getters["core/apps/getMsgAccessDeneid"];
            this.$store.commit("core/setAlertSimple", {
                icon: 'error',
                title: msj.title,
                text: msj.msg,
                showConfirmButton: false,
                timer: 4000,
                show: true
            });
            setTimeout(function(){
                t.$store.commit("set_isLoading", false);
                t.$router.push({ name: "Home" });
            }, 4000);
        },
        getAppStatus(){
            let s = this.$store.getters["core/apps/getAppStatus"];
            let planActual = this.$store.getters["core/apps/getPlanIdApp"];
            console.log('getAppStatus', s)
            // planActual < 3 exluir sponsore y plan free
            if(s.paymentStatus != 1 && planActual != 7 && planActual != 8){
                let msj = this.$store.getters["core/apps/getMsgPaymentAlert"];
                this.$store.commit('core/setNotiPush', {
                    title: msj.title,
                    text: msj.msg,
                    show: true,
                    group: "notiPush",
                    type: '5', // 1=success|2=info|3=error|4=loading|5=warning
                    duration: 15000
                });
            }
        }
    },
    watch: {
        getHeader: function () {
            //console.log('getHeader ', this.getHeader);
            this.validAccess();
        },
        $route(to, from) {
            // this.$router.currentRoute.name
            let url = to.name;
            this.asideMaquetador(url);
        },
        getPusher: function(){
            let t = this;
            if (t.getPusher != undefined) {
                if(t.getPusher.action == "actAppPlan"){
                    if(t.getPusher.data.org_id == window.localorgdata || t.getPusher.data.plan_id == t.$store.getters['core/apps/getPlanIdApp']){
                        console.log('getPlanApp', t.getPusher);
                        // t.$store.dispatch("core/apps/getPlanApp", localStorage.getItem('appIDactual'))
                        t.$store.dispatch("core/apps/getPlanApp", t.bsite_idApp)
                        .then((response) => {
                            setTimeout(function(){
                                location.reload(true);
                            }, 1000);
                        });
                    }
                }
                if(t.getPusher.action == "statusPay-crm" || t.getPusher.action == "statusAppCRM"){
                    if(t.getPusher.data.org_id == window.localorgdata && t.getPusher.data.app_id==localStorage.getItem('appIDactual')){
                        t.$store.dispatch("ModApps/Marketea/getStatusAppCRM").then((statusApp) =>{
                            let plan_id = t.$store.getters['core/apps/getPlanIdApp'];
                            let continuarAccessApp = false;
                            t.getAppStatus();
                            if(plan_id==3 && statusApp.statusAppCRM==1){continuarAccessApp = true;}
                            else if(statusApp.statusPay==1 && statusApp.statusAppCRM==1){continuarAccessApp = true;}
                            else{continuarAccessApp = false;}
                            if(continuarAccessApp){
                                console.log("Accesso negado");
                                let name ='bSite';
                                let msj = this.$store.getters["core/apps/getMsgAccessSuspended"];
								let msg = msj.msg.replace('[nameApp]', name);
								this.$store.commit('core/setNotiPush', {
									title: msj.title,
									text: msg,
									show: true,
									group: "notiPush",
									type: '5', // 1=success|2=info|3=error|4=loading|5=warning
									duration: 15000
								});
                                setTimeout(function(){
                                    t.$store.commit("set_isLoading", false);
                                    t.$router.push({ name: "Home" });
                                }, 5000);
                            }
                        }).catch(error => {
                            console.log(error);
                            t.errorAccess()
                        });
                    }
                }
            }
        },
    },
    computed: {
        ...mapGetters("core", { getPusher: "get_accitonPusher"}),
        ...mapGetters("ModApps/Marketea", { accessApp: "accessApp" }),
        ...mapGetters("core", { getHeader: "getHeader" }),
        ...mapGetters("core/session", { loggedIn: "loggedIn" })
    },
};
</script>
<style scoped lang="scss">
.header {
    top: 0;
}
.page-wrapper {
    margin-left: 170px;
}
.asidemak-active{
  margin-left: 0px !important; 
}
</style>
