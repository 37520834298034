<template>
	<div class="container">
		<div class="row">
			<div class="col-md-2"></div>
			<div class="col-md-8">
				<div class="row">
					<div class="col-md-10 align-self-center">
						<h3 class="page-title">Zip Code List</h3>
					</div>
					<div class="col-md-2 align-self-center">
						<div class="d-flex align-items-right justify-content-end">
							<router-link :to="{ name: 'RoofCalSetup' }" class="btn btn-info btn-sm mr-2 ctrlbtn" style="margin-bottom: 10px;width: -webkit-fill-available;">
								<i class="fas fa-arrow-left"></i>
								Back
							</router-link>   
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-2"></div>
			<div class="col-md-2"></div>
			<div class="col-md-8">
				<zipcode></zipcode>
			</div>
		</div>
	</div>
</template>
<script>
import zipcode from "@/apps/RoofCal/components/RoofCalZipCode.vue";
export default {
	name: 'RoofCalZipSetup',
	components: {
		zipcode,
	},
	data () {
		return {

		}
	}
}
</script>
<style lang="css" scoped>
</style>
