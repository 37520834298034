<template>
    <div>
        <aside
            class="left-sidebar"
            data-sidebarbg="skin5"
            v-bind:class="{ min: lateral }"
        >
            <div class="scroll-sidebar">
                <div class="btnAsideAction" @click="ShowHiddenAside">
                    <i class="fas fa-chevron-left" v-bind:class="{ min: lateral }"></i>
                    Close Menu
                </div>
                <nav :class="'sidebar-nav ' + heightMenu">
                    <!-- <div class="input-group">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Search"
                            v-model="search"
                        />
                        <span class="input-group-addon">
                            <button type="button" class="btn btn-primary">
                                <i class="fa fa-search"></i>
                            </button>
                        </span>
                    </div> -->
                    <ul id="sidebarnav" @click="ShowHiddenAside">
                        <li
                            class="sidebar-item"
                            v-for="(i, index) in filterMenu"
                            :key="index"
                            v-bind:class="{ sub: i.type === 2 }"
                        >
                            <router-link
                                :to="i.ruta"
                                exact-active-class="active"
                                class="sidebar-link waves-effect waves-dark"
                                v-if="i.type == 1"
                            >
                                <i
                                    :class="i.icon"
                                    style="color: #ccc; font-size: 12px"
                                ></i>
                                <span class="hide-menu">{{ i.name }}</span>
                            </router-link>
                        </li>
                    </ul>
                </nav>
                <!-- <div :class="'menu-foot '+heightMenu" @click="ShowHiddenAside">
                    <router-link :to="{name: 'Jobs'}" class="list-group-item list-group-item-action" v-if="showDash">
                        <i class="fas fa-home" style="color:#ccc;font-size: 12px"></i> Dashboard
                    </router-link>
                    <router-link :to="{name: 'JobOrderList'}" class="list-group-item list-group-item-action " v-if="showJobList && typez == 0">
                        <i class="fas fa-list" style="color:#ccc;font-size: 12px"></i> Job List
                    </router-link>
                    <router-link :to="{name: 'JobsComppleted'}" class="list-group-item list-group-item-action" v-if="showCompleted">
                        <i class="fas fa-check-circle" style="color:#ccc;font-size: 12px"></i> Completed Jobs 
                    </router-link>
                    <router-link :to="{name: 'JobsSetup'}" class="list-group-item list-group-item-action" v-if="showSetup">
                        <i class="fas fa-cog" style="color:#ccc;font-size: 12px"></i> Setup
                    </router-link>
                </div> -->
            </div>
        </aside>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    name: "AsideJobs",
    components: {},
    data() {
        return {
            activar: true,
            menuDefaul: [
                {
                    name: "All",
                    ruta: { name: "Jobs" },
                    icon: "fas fa-tags",
                    m_id: 0,
                    type: 1,
                },
            ],
            menuLateral: [],
            search: "",
            showDash: false,
            showJobList: false,
            showCompleted: false,
            showSetup: false,
            countMenu: 1,
            heightMenu: "full",
            typez: window.user_type,
        };
    },
    created() {
        if (window.user_type > 0) {
            this.menuDefaul[0].ruta = { name: "Jobs" };
        }
        this.validarMenu();
        this.estructuraMenu(this.menuDefaul);
        this.$store.dispatch("ModApps/jobs/update_menuTags").then((response) => {});
    },
    methods: {
        ShowHiddenAside() {
            this.activar = !this.activar;
            this.$store.commit("ModApps/jobs/ActiveLateral", this.activar);
        },
        estructuraMenu(tags) {
            for (let i in tags) {
                let newTags = {
                    name: tags[i].name,
                    ruta: tags[i].ruta,
                    icon: tags[i].icon,
                    m_id: tags[i].id,
                    type: 1,
                };
                this.menuLateral.push(newTags);
            }
            // console.log('menu lateral ', this.menuLateral)
        },
        validarMenu() {
            if (window.user_type > 0) {
                let privilegios = this.$store.getters['core/session/privilegiesSesion'];
                let appRolUser = this.$store.getters['core/apps/get_appRolUser'];
                let appIDactual = this.$store.getters['core/apps/getAppIDactual'];
                let modulos = privilegios.modulos.jobs;
                // console.log('modulos a seleccionar =', modulos);
                let validAdminUser = false;
                this.countMenu = 0;
                for (let i in appRolUser) {
                    if(appRolUser[i].app_id == appIDactual){
                        validAdminUser = true;
                        this.showDash = true;
                        this.showCompleted = true;
                        this.showSetup = true;
                        this.countMenu = 3;
                    }   
                }
                if(validAdminUser) {
                    for (let i in modulos) {
                        if (modulos[i] == 14) {
                            this.countMenu += 1;
                            this.showDash = true;
                        } // dash
                        if (modulos[i] == 16) {
                            this.countMenu += 1;
                            this.showJobList = true;
                        } // job list
                        if (modulos[i] == 17) {
                            this.countMenu += 1;
                            this.showCompleted = true;
                        } // completed
                        if (modulos[i] == 18) {
                            this.countMenu += 1;
                            this.showSetup = true;
                        } // setup
                    }
                }
                if (this.countMenu == 1) {
                    this.heightMenu = "uno";
                } else if (this.countMenu == 2) {
                    this.heightMenu = "dos";
                } else if (this.countMenu == 3) {
                    this.heightMenu = "tres";
                } else {
                    this.heightMenu = "full";
                }
            } else {
                this.showDash = true;
                this.showJobList = true;
                this.showCompleted = true;
                this.showSetup = true;
                this.heightMenu = "full";
            }
        },
    },
    mounted: function () {},
    computed: {
        ...mapGetters("ModApps/jobs", ["lateral", "get_menuTags"]),
        filterMenu: function () {
            var self = this;
            return this.menuLateral.filter(function (item) {
                return (
                    item.name &&
                    item.name.toLowerCase().indexOf(self.search.toLowerCase()) >= 0
                );
            });
        },
    },
    watch: {
        lateral: function () {
            this.activar = this.lateral;
        },
        get_menuTags: function () {
            this.menuLateral = [];
            this.estructuraMenu(this.menuDefaul);
            //console.log('menutags',this.get_menuTags)
            let tagsSalida = [];
            let tags = this.get_menuTags;
            for (let i in tags) {
                let ruta = "JobOrderListTags";
                //if(window.user_type > 0){ruta = 'JobsTags'; }
                ruta = "JobsTags";
                let newTags = {
                    name: tags[i].name,
                    ruta: { name: ruta, params: { id: tags[i].id, tags: "tags" } },
                    icon: "fas fa-tags",
                    m_id: tags[i].id,
                    type: 1,
                };
                tagsSalida.push(newTags);
            }
            this.estructuraMenu(tagsSalida);
        },
    },
};
</script>
<style lang="scss" scoped>
.scroll-sidebar {
    height: calc(100% - 5px);
    position: relative;
}
.left-sidebar {
    position: fixed;
    z-index: 1031;
    width: 250px;
    background: #fff !important;
    padding-top: 54px;
}
.btnAsideAction {
    position: absolute;
    bottom: initial;
    top: -14px;
    left: 0;
    width: 100%;
    height: 40px;
    background-color: #343435;
    cursor: pointer;
    padding: 10px 4px 4px 4px;
    line-height: 21px;
    text-align: center;
    color: #fff;
}
aside.left-sidebar.min {
    width: 0px;
}
aside.left-sidebar.min .scroll-sidebar {
    display: none;
}
.sidebar-nav ul .sidebar-item {
    width: 100%;
    border-top: 1px solid#dee2e6;
}
nav.sidebar-nav {
    overflow-y: hidden;
    padding-top: 25px;
}
nav.sidebar-nav.uno {
    height: 91.5%;
}
nav.sidebar-nav.dos {
    height: 85%;
}
nav.sidebar-nav.tres {
    height: 77.5%;
}
nav.sidebar-nav.full {
    height: 70%;
}
nav.sidebar-nav.uno,
nav.sidebar-nav.dos,
nav.sidebar-nav.tres,
nav.sidebar-nav.full {
    height: 99% !important;
}
.menu-foot.uno {
    height: 7.5%;
}
.menu-foot.dos {
    height: 15%;
}
.menu-foot.tres {
    height: 21.5%;
}
.menu-foot.full {
    height: 30%;
}
.menu-foot {
    display: none;
}
ul#sidebarnav {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 30px;
}
.menu-foot a {
    color: #343434;
    font-weight: bold;
}
.menu-foot a i {
    color: #000;
}
.sidebar-nav > #sidebarnav > .sidebar-item > .sidebar-link:hover {
    background: #e2e1e1;
    color: #555;
}
.sidebar-nav > #sidebarnav > .sidebar-item > .sidebar-link:hover i,
.sidebar-nav ul .sidebar-item .sidebar-link i {
    color: #000;
}
.sidebar-nav ul .sidebar-item .sidebar-link {
    color: #555;
    opacity: 1;
}
@media (max-width: 1024px) {
    .sidebar-nav ul .sidebar-item .sidebar-link {
        padding: 8px 8px;
    }
}
</style>
