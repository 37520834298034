<template>
    <div>
        <div class="card-header window-header">
            <div class="col-12 d-flex justify-content-start">
                <h3>Create Project</h3>
                <button type="button"
                    class="btn btn-success btn-sm"
                    style="height: 30px;margin-left: 10px; padding: 2px 5px;line-height: 1;"
                    @click="createProject()"
                    :disabled="isExistSlug"
                >
                    Save
                </button>
            </div>
        </div>
        <form @submit.prevent="createProject()" id="form1" class="window-body">
            <div v-show="isLoading" class="bgLoaging">
                <Loader :getProgress="apiProgess"></Loader>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <label class="col-form-label ">Project name:</label>
                        <input type="text" class="form-control" id="" v-model="project_name" required maxlength="50"/>
                        <small v-if="errorUrl!=null" class="form-text text-danger">Error, url already exists or is not valid</small>
                    </div>
                    <!-- <div class="col-md-12">
                        <label class="col-form-label ">Small Description:</label>
                        <textarea class="form-control" v-model="project_description" maxlength="160" placeholder="Max characters 160"></textarea>
                    </div> -->
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="col-form-label">Location</label>
                                <input type="text" class="form-control" v-model="location" maxlength="50"/>
                            </div>
                            <div class="col-md-12">
                                <label class="col-form-label">Space</label>
                                <input type="text" class="form-control" v-model="space" maxlength="50"/>
                            </div>
                            <div class="col-md-12">
                                <label class="col-form-label">Bedrooms</label>
                                <input type="text" class="form-control" v-model="bedrooms" maxlength="50"/>
                            </div>
                            <div class="col-md-12">
                                <label class="col-form-label">Baths</label>
                                <input type="text" class="form-control" v-model="baths" maxlength="50"/>
                            </div>
                            <div class="col-md-12">
                                <label class="col-form-label">Lot Size</label>
                                <input type="text" class="form-control" v-model="lot_size" maxlength="50"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="col-form-label">Cover Image</label>
                                <div class="previewImg">
                                    <img :src="noImg" class="img-thumbnail noImg" v-if="coverImage==null" id="previewImg"/>
                                    <img :src="urlImg+coverImage" class="img-thumbnail" v-else  id="previewImg"/>
                                    <div class="btn-upload-img" @click="$refs.uploadImg.click()">
                                        <i class="fas fa-cloud-upload-alt"  v-if="!loadImg"></i>
                                        <loader v-else></loader>
                                    </div>
                                    <div class="delImg" @click="deleteImg" v-if="coverImage!=null"><i class="fas fa-trash"></i></div>
                                </div>
                                <input type="file" ref="uploadImg" accept="image/*" @change="changeImage($event)" style="display: none;">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="col-form-label">Price</label>
                        <input type="text" class="form-control" v-model="price" maxlength="50"/>
                    </div>
                    <div class="col-md-6">
                        <label class="col-form-label">Status</label>
                        <select class="form-control" v-model="status">
                            <option value="1">Current</option>
                            <option value="2">Completed</option>
                            <option value="3">To Be Built</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="card-footer d-flex justify-content-center">
                <button type="submit"
                    class="btn btn-success"
                    style="width: 100%;"
                    :disabled="isExistSlug">Save Project</button>
            </div>
        </form>
    </div>
</template>
<script>
// @ts-ignore
import Loader from "@/core/components/shared/Loader.vue";
export default {
    components: {
        Loader,
    },
    props: {
        dataModal: {
            type: [Object, String, Number, Array],
            require: false,
            default: null,
        }
    },
    data() {
        return {
            isLoading: false,
            isExistSlug: true,
            apis: null,
            project_name: null,
            project_description: null,
            location: '',
            space: '',
            bedrooms: '',
            baths: '',
            lot_size: '',
            price: '$0.00',
            sold: '0',
            status: '1',
            coverImage: null,
            imgOld: null,
            fileBlob: null,
            // @ts-ignore
            noImg: require('@/core/assets/img/no-image.png'),
            // @ts-ignore
            urlImg: (localStorage.getItem('bsite_cardUrlPic')+'projects/').replaceAll('"', ''),
            loadImg: false,
            slug_url: null,
            slug_url_old: null,
            // @ts-ignore
            urlweb : window.urlbSite,
            premiumProfile: false,
            planActual: 1,
            editItem: null,
            apiProgess: 0,
            errorUrl: null,
        };
    },
    created() {
        let urlApis = this.$store.getters['ModApps/Marketea/urlApis'];
        this.apis = urlApis.Projects;
        // valid premium planID
        this.premiumProfile = (this.$store.getters['core/apps/getPlanIdApp']!=8);
        this.planActual = this.$store.getters['core/apps/getPlanIdApp'];
        if(this.planActual==7){
            this.premiumProfile = true;
            console.log('Plan sponsored, dando permiso de premium', this.premiumProfile);
        }
        this.reset();
        console.log('premiumProfile', this.premiumProfile);
        if(this.dataModal!=null){
            this.editItem = this.dataModal;
            console.log('edit item', this.editItem);
            // this.getItemProject();
        }
    },
    watch: {
        'project_name': function () {
            if(this.project_name!=null){
                if(this.project_name.length > 2){
                    this.crearLink();
                }else{this.isExistSlug=true;}
            }else{
                this.isExistSlug=true;
            }
        },
    },
    methods: {
        reset(){
            let t = this;
            t.isLoading= false;
            t.project_name= null;
            t.project_description= null;
            t.location= '';
            t.space= '';
            t.bedrooms= '';
            t.baths= '';
            t.lot_size= '';
            t.price= '$0.00';
            t.sold= '$0.00';
            t.status= '1';
            t.coverImage= null;
            t.imgOld= null;
            t.fileBlob= null;
        },
        crearLink(noValid) {
            let t = this;
            t.slug_url = t.formatURL(t.project_name);
            if (noValid == undefined) {
                t.errorUrl=null;
                t.validateIfurlExists();
            }
        },
        formatURL(slug) {
            let str = slug;
            str = str.replace(/^\s+|\s+$/g, "");
            str = str.toLowerCase();
            var from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç ·/_,:;";
            var to = "aaaaaeeeeeiiiiooooouuuunc-------";
            for (var i = 0, l = from.length; i < l; i++) {
                str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
            }
            str = str
                .replace(/[^a-z0-9 -]/g, "")
                .replace(/\s+/g, "-")
                .replace(/-+/g, "-");
            return str;
        },
        validateIfurlExists() {
            let t = this;
            t.isExistSlug = true;
            let url = t.apis.get(8); // ifExistsSlug
            window.master.post(url, {
                user_id: window.master_client,
                org_id: window.localorgdata,
                slug_url: t.slug_url,
                pusher_id: pusher.connection.socket_id
            })
            .then((response) => {
                if (response.data.r > 0) {
                    t.isExistSlug = true;
                    t.errorUrl=1;
                } else {
                    t.isExistSlug = false;
                }
            }).catch((error) => {
                console.log(error);
                t.isExistSlug = false;
            });
        },
        createProject(){
            let t = this;
            if(!t.isExistSlug){
                t.isLoading = true;
                let payLoad = new FormData();
                // @ts-ignore
                payLoad.append("user_id", window.master_client);
                // @ts-ignore
                payLoad.append("org_id", window.localorgdata);
                payLoad.append("fileBlob", t.fileBlob);
                payLoad.append("imgOld", t.imgOld);
                // @ts-ignore
                // eslint-disable-next-line no-undef
                payLoad.append("pusher_id", pusher.connection.socket_id);
                if(t.editItem!=null){
                    payLoad.append("id", t.editItem.id);
                }
                payLoad.append("name", t.project_name);
                payLoad.append("description", t.project_description);
                payLoad.append('location', t.location);
                payLoad.append('space', t.space);
                payLoad.append('bedrooms', t.bedrooms);
                payLoad.append('baths', t.baths);
                payLoad.append('lot_size', t.lot_size);
                payLoad.append('price', t.price);
                payLoad.append('sold', t.sold);
                payLoad.append('status', t.status);
                payLoad.append('coverImage', t.coverImage);
                payLoad.append('slug_url', t.slug_url);
                let urlService = t.apis.set(0); //createProject
                // @ts-ignore
                window.master.post(urlService, payLoad, {
                    headers: {
                        common: {
                        // @ts-ignore
                        Authorization: 'Bearer ' + window.localtoken_M
                        },
                        post: { 'Content-Type': 'multipart/form-data' }
                    },
                    onUploadProgress: (progressEvent) => {
                        // console.log('onUploadProgress', progressEvent)
                        let scanPer = Math.floor(
                            (progressEvent.loaded*100 / progressEvent.total)
                        );
                        // console.log('scanPer', scanPer);
                        if(scanPer < 99){
                            t.apiProgess = scanPer;
                        }
                    }
                })
                .then((response) => {
                    t.apiProgess =100;
                    // @ts-ignore
                    console.log(urlService, response.data);
                    if(response.data.status){
                        t.$emit('actionReturn', {action: 'refreshList'});
                    }
                    else{t.$emit('actionReturn', {action: 'onlyCloseModal'});}
                    t.isLoading = false;
                }).catch((error) => {
                    console.log(error);
                    t.apiProgess =0;
                    t.isLoading = false;
                });
            }
        },
        changeImage(event){
            let t = this;
            let file = event.target.files[0];
            if (file) {
                t.loadImg=true;
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function (e) {
                    t.noImg = e.target.result;
                    // @ts-ignore
                    t.$refs.uploadImg.value = null;
                    t.fileBlob= file;
                    t.loadImg=false;
                };
            } else {
                // @ts-ignore
                t.$refs.uploadImg.value = null;
                t.loadImg=false;
            }
        },
    },
    computed: {
    },
};
</script>

<style lang="scss" scoped>
.window-header{cursor: move;padding: 2px;}
.marginTop{margin-top: 20px;}
.window-body{
    height: calc(100vh - 50px);
    // height: 600px;
    overflow: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    padding-bottom: 0em;
    width: 100%;
    .card-body{padding-top: 0px;}
    &::-webkit-scrollbar:vertical {
        width:3px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #797979;
        border-radius: 20px;
        border: 0px solid #f1f2f3;
    }
    .bgLoaging {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        margin: auto;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9;
        background-color: rgba(255, 255, 255, 0.342);
    }

    .bgLoaging>div {
        margin-left: -100px;
    }
    .row{
        width: 100%;
        .previewImg{
            position: relative;
            width: 100%;
            max-width: 300px;
            overflow: hidden;
            img{
                width: 100%;
                height: auto;
                &.noImg{
                    margin: auto;
                    display: block;
                }
            }
            .btn-upload-img{
                position: absolute;
                z-index: 1;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                align-content: center;
                justify-content: center;
                align-items: center;
                text-shadow: 1px 0px 10px rgb(255 255 255 / 50%);
                color: #4c32e9;
                font-size: 50px;
                opacity: 0.5;
                &:hover{
                background-color: rgb(0 0 0 / 0.2);
                opacity: 1;
                cursor: pointer;
                }
            }
            .delBgImg {
                position: absolute;
                z-index: 2;
                top: 5px;
                right: 5px;
                color: #fff;
                background: red;
                padding: 8px;
                border-radius: 4px;
                opacity: 0.3;
                &:hover{
                    opacity: 1;
                    cursor: pointer;
                }
            }
        }
    }
    .card-footer.d-flex.justify-content-center {
        position: relative;
        width: 100%;
        height: 73px;
        bottom: 0px;
        left: 0px;
    }
}
@media (max-width:767px){
    .window-body{
        height: 100%;
        max-height: 750px;
        padding-bottom: 20em;
    }
}
</style>
