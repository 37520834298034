<template>
	<div class="opc-secciones">
		<div class="widgets-content">
			<div v-bind:class="['title-content', (menuType.indexOf(1)>-1) ? 'open' : '']" @click="openMenu(1)">
				<h4>
					Choose Slider Layout:
					<i class="fas fa-chevron-down" v-if="(menuType.indexOf(1)>-1)"></i>
					<i class="fas fa-chevron-right" v-else></i>
				</h4>
			</div> 
			<div class="box-contents list-widgets item1" v-if="(menuType.indexOf(1)>-1)">
				<div :class="['box-section', (layoutContent==1) ? 'active' : '']" @click="changeBoxSize(1)">
					<h5>Main Title</h5>
					<p>Description info</p>
					<button type="button" class="btn">Button</button>
				</div>
				<div :class="['box-section', (layoutContent==2) ? 'active' : '']" @click="changeBoxSize(2)">
					<div class="row">
						<div class="col-7">
							<h5>Main Title</h5>
							<p>Description info</p>
							<button type="button" class="btn">Button</button>
						</div>
						<div class="col-5">
							<h6>Form Title</h6>
							<p>Form info</p>
							<div class="formBox">Form</div>
						</div>
					</div>
				</div>
				<div :class="['box-section', (layoutContent==3) ? 'active' : '']" @click="changeBoxSize(3)">
					<div class="row">
						<div class="col-5">
							<h6>Form Title</h6>
							<p>Form info</p>
							<div class="formBox">Form</div>
						</div>
						<div class="col-7">
							<h5>Main Title</h5>
							<p>Description info</p>
							<button type="button" class="btn">Button</button>
						</div>
					</div>
				</div>				
				<div :class="['box-section', (layoutContent==4) ? 'active' : '']" @click="changeBoxSize(4)">
					<div class="row">
						<div class="col-7">
							<h5>Main Title</h5>
							<p>Description info</p>
							<div class="box-imgs">
								<div class="boxImg">IMG</div>
								<div class="boxImg">IMG</div>
								<div class="boxImg">IMG</div>
								<div class="boxImg">IMG</div>
							</div>
						</div>
						<div class="col-5">
							<h6>Form Title</h6>
							<p>Form info</p>
							<div class="formBox">Form</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
	name: 'slideLayout',
	data () {
		return {
			menuType: [1, 2, 3, 4],
			boxSize: '',
			formShow: false,
			boxForm: '',
			formHeader: 0,
			formAling: '',
			thumbs: [],
			layoutContent: '',
		}
	},
	created(){
		this.init();
	},
	methods: {
		init(){
			let d = this.$store.getters['ModApps/Sites/getSideBarOpc'].data;
			if(d.length>0){
				let coverPage = d[0];
				this.layoutContent = coverPage.layoutContent;
				this.boxSize = coverPage.content[0].columns[0].grids;
				this.contentAling = coverPage.content[0].columns[0].moreClass;
				//this.formShow=o.formShow;
				//this.thumbs=o.thumbs;
				//this.formHeader=o.formHeader;
				//this.boxForm=o.boxForm;
				//this.formAling=o.formAling;
			}
		},
		openMenu(type){
			let t = this;
			if(t.menuType.indexOf(type)>-1){
				let elem = document.querySelector(".item"+type);
				if(elem){  
					let heightResta = elem.offsetHeight/10;
					elem.style.overflow = 'hidden';
					let bg1 = '#d7d7d7';
					let bg2 = '#f2f4f5';
					let bgCurrent = '#d7d7d7';
					elem.style.height = elem.offsetHeight - heightResta + 'px';
					let efectoKeyframes = [
						{ height: elem.style.height },
						{ background: bgCurrent },
						{ height: '0px' },
						];
					let optionsTiming = {
						duration: 200,
						iterations: 1,
					};
					elem.animate(efectoKeyframes, optionsTiming);
				}
				setTimeout(function(){
					if(elem){ elem.removeAttribute('style'); }
					t.menuType.splice(t.menuType.indexOf(type), 1);
				}, 180);
			}
			else{
				t.menuType.push(type);
			}
		},
		changeBoxSize(type){
			let t = this;
			t.layoutContent=type;
			t.boxSize=(type==1) ? 'col-12' : 'col-8';
			t.$store.commit("ModApps/Sites/setUpdateFontPage", {opc: 'boxSize', value: t.boxSize});
			t.$store.commit("ModApps/Sites/setUpdateFontPage", {opc: 'layoutContent', value: t.layoutContent});
			t.changeContentAling(type);
		},
		changeContentAling(type){
			let t = this;
			t.contentAling=(type==1) ? 'text-center' : 'text-left';
			t.$store.commit("ModApps/Sites/setUpdateFontPage", {opc: 'contentAling', value: t.contentAling});
		},
	},
	watch: {
		getFontPageLayoutContent: function(){
			let t = this;
			t.layoutContent = t.getFontPageLayoutContent
		},
		getFontPageBoxSize: function(){
			let t = this;
			t.boxSize = t.getFontPageBoxSize
		},
		getFontPageContentAling: function(){
			let t = this;
			t.contentAling = t.getFontPageContentAling
		},
		getFontPageThumbs: function(){
			let t = this;
			t.thumbs = t.getFontPageThumbs
		},
		getFontPageFormHeader: function(){
			let t = this;
			t.formHeader = t.getFontPageFormHeader
		},
		getFontPageFormShow: function(){
			let t = this;
			t.formShow = t.getFontPageFormShow
		},
		getFontPageBoxForm: function(){
			let t = this;
			t.boxForm = t.getFontPageBoxForm
		},
		getFontPageFormAling: function(){
			let t = this;
			t.formAling = t.getFontPageFormAling
		},
	},
	computed: {
		...mapGetters("ModApps/Sites", { 
			getFontPageLayoutContent: "getFontPageLayoutContent",
			getFontPageBoxSize: "getFontPageBoxSize",
			getFontPageContentAling: "getFontPageContentAling",
			getFontPageThumbs: "getFontPageThumbs",
			getFontPageFormHeader: "getFontPageFormHeader",
			getFontPageFormShow: "getFontPageFormShow",
			getFontPageBoxForm: "getFontPageBoxForm",
			getFontPageFormAling: "getFontPageFormAling",
		}),
	}	
}
</script>
<style lang="scss" scoped>
	.opc-secciones {
		height: calc(100vh - 124px);
		overflow: hidden;
		overflow-y: auto;
		.widgets-content {
			.box-contents {
				.box-section.active{
					background-color: #f1f2f3
				}
				.box-section {
					display: flex;
					flex-direction: column;
					flex-wrap: wrap;
					align-content: center;
					justify-content: center;
					align-items: center;
					width: 100%;
					max-width: 226px;
					height: 140px;
					border-radius: 2px;
					border: 1px solid #dadce0;
					margin-bottom: 10px;
					padding: 5px;
					&:hover {
						background-color: #dadce0;
						cursor: pointer;
					}
					.row{
						width: 100%;
						.col-7{
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: flex-start;
						}
						.col-5{
							text-align: center;
							h6{
								font-size: 11px;
								font-weight: bold;
								margin-bottom: 0px;
								letter-spacing: 1px;
							}
							p{
								margin-bottom: 0px;
								letter-spacing: 1px;
								font-size: 10px;
							}
						}
						.formBox{
							height: 70px;
							border: 1px solid #ccc;
							background-color: #fff;
							font-size: 14px;
							letter-spacing: 1px;
							display: flex;
							flex-direction: column;
							flex-wrap: wrap;
							align-content: center;
							justify-content: center;
							align-items: center;
						}
					}
					h5{
						font-weight: bold;
						margin-bottom: 0px;
						letter-spacing: 1px;
					}
					p{
						margin-bottom: 0px;
						letter-spacing: 1px;
						font-size: 12px;
					}
					button.btn{
						border: 1px solid #ccc;
						background-color: #fff;
						font-size: 10px;
						padding: 1px 3px;
						letter-spacing: 1px;
					}
					.box-imgs{
						display: flex;
						flex-direction: row;
						flex-wrap: wrap;
						align-content: center;
						justify-content: space-between;
						align-items: center;
						width: 100%;
						.boxImg{
							border: 1px solid #ccc;
							background-color: #fff;
							font-size: 8px;
							padding: 1px 2px;
							letter-spacing: 1px;	
							display: flex;
							flex-direction: column;
							align-content: center;
							justify-content: center;
							align-items: center;
							font-weight: bold;
							height: 25px;
						}
					}
				}/*.box-section*/
			}/*.box-contents*/
		}/*widgets-content*/
	}/*.opc-secciones*/
</style>
