let getOpenModal = function() {	
	if (this.getOpenModal.modalTag) {
		let d = this.getOpenModal.data;
		this.m = {
			show: true,
			component: this.getOpenModal.component,
			data: d
		};
		this.$store.commit('core/setOpenModal', this.m);
	}
	else if (!this.getOpenModal.show) {
		if (Object.keys(this.getOpenModal.data).length > 0) {
			let t = this.getOpenModal.data;
			if (t.methods === 'editTags') {
				this.update_menuTags()
				this.getJobsDash()
			} 
			if (t.methods === 'delTags') {
				this.update_menuTags()
			} 
			if (t.methods === 'editNoteBox') {
				this.getJobsDash()
			}                                                           
		}
		this.m = {
			show: false,
			component: '',
			data: {}
		};
	}
};
export default getOpenModal;
