<template>
  <div class="task-filter" @click="$parent.$refs.tableTask.outFocusTr()">
    <div class="campos_filters">
      <div class="btn-group btn-sync" role="group">
        <button
            type="button"
            :class="['btn btn-light btn-sm']"
            @click="$parent.init()"
            :disabled="$parent.isLoading"
            style="border: 1px solid #dcdcdc;background-color: #fff;padding: 7px 10px;color: #5d5b5b;"
          >
          <i :class="['fas fa-sync', ($parent.isLoading) ? 'aReFresh' : '']"></i>
        </button>
      </div>
        <div class="btn-group" role="group">
          <button type="button" class="btn btn-primary btnClick" @click="$parent.addTaksPop('add', null)" :disabled="$parent.btnAdd">Add task</button>        
          <div class="btn-group" role="group" v-click-outside="hideDropAdd">
            <button type="button" class="btn btn-primary dropdown-toggle" @click="showDropAdd=!showDropAdd"></button>
            <div :class="['dropdown-menu', (showDropAdd) ? 'show' : '']" >
              <!-- <button type="button" class="dropdown-item" href="#" @click="addTask()" :disabled="$parent.btnAdd">New quick task</button> -->
              <button type="button" class="dropdown-item" href="#">To do list</button>
            </div>
          </div>
        </div>
        <div :class="['input-group inputFillSearch', (showSearch) ? 'showSearch' : 'hideSearch']" >
          <div class="inputSearch" v-click-outside="hideSearch">
            <div class="input-group-btn">
              <button class="btn btn-search" type="button" @click="activeSearch()">
                <i class="fas fa-search"></i> <span class="label-search">Search</span>
              </button>
            </div>
            <input type="text" 
              :class="['form-control']" 
              v-model="searchForm" 
              @keypress.enter="searchTaskName" 
              ref="searchForm" 
              placeholder="Search task name, press enter to filter"
            />
          </div>
        </div>
        <div class="btn-group" role="group" v-click-outside="hideDropFill">
          <button type="button" class="btn btn-light btnClick" @click="activeFill()">
            <i class="fas fa-filter"></i> Due Date
          </button>
          <div class="btn-group" role="group">
            <button type="button" class="btn btn-light dropdown-toggle" @click="showDropFill=!showDropFill"></button>
            <div :class="['dropdown-menu', (showDropFill) ? 'show' : '']" >
              <button type="button" class="dropdown-item" href="#">
                <label>
                  <input type="radio" v-model="$parent.filterTimer" :value="1" id="dueDateAll" /> All
                </label>
              </button>
              <button type="button" class="dropdown-item" href="#">
                <label>
                  <input type="radio" v-model="$parent.filterTimer" :value="2" id="dueDateToday" /> Today
                </label>
              </button>
              <button type="button" class="dropdown-item" href="#">
                <label>
                  <input type="radio" v-model="$parent.filterTimer" :value="3" id="dueDateWeek" /> This Week
                </label>
              </button>
              <button type="button" class="dropdown-item" href="#">
                <label>
                  <input type="radio" v-model="$parent.filterTimer" :value="4" id="dueDateMonth" /> This Month
                </label>
              </button>
            </div>
          </div>
        </div>
        <div class="btn-group" role="group" v-click-outside="hideFillState">
          <button type="button" class="btn btn-light btnClick" @click="showFillState=!showFillState">
            <i class="fas fa-filter"></i> Status
          </button>
          <div class="btn-group" role="group">
            <button type="button" class="btn btn-light dropdown-toggle" @click="showFillState=!showFillState"></button>
            <div :class="['dropdown-menu', (showFillState) ? 'show' : '']" >
              <!-- <button type="button" class="dropdown-item" href="#">
                <label>
                  <input type="checkbox" id="statusAll" @click="allStatus" :checked="($parent.statuslist.length>=3)"/> All
                </label>
              </button> -->
              <button type="button" class="dropdown-item" href="#">
                <label>
                  <input type="checkbox" v-model="$parent.statuslist" :value="0" id="statusPending" :disabled="($parent.statuslist.length==1 && $parent.statuslist.includes(0))"/> Pending
                </label>
              </button>
              <button type="button" class="dropdown-item" href="#">
                <label>
                  <input type="checkbox" v-model="$parent.statuslist" :value="1" id="statusInProgress" :disabled="($parent.statuslist.length==1 && $parent.statuslist.includes(1))"/> In Progress
                </label>
              </button>
              <button type="button" class="dropdown-item" href="#">
                <label>
                  <input type="checkbox" v-model="$parent.statuslist" :value="2" id="statusDone" :disabled="($parent.statuslist.length==1 && $parent.statuslist.includes(2))"/> Done
                </label>
              </button>
              <!-- <button type="button" class="dropdown-item" href="#">
                <label>
                  <input type="checkbox" v-model="$parent.statuslist" :value="4" id="statusUncompleted" /> Uncompleted
                </label>
              </button>
              <button type="button" class="dropdown-item" href="#">
                <label>
                  <input type="checkbox" v-model="$parent.statuslist" :value="3" id="statusDeleted" :disabled="($parent.statuslist.length==1 && $parent.statuslist.includes(3))"/> Deleted
                </label>
              </button> -->
            </div>
          </div>
        </div>
        <div class="btn-group" >
          <button type="button" 
            :class="[
              'btn btn-info btnCompleted', 
              ($parent.statuslist.includes(2)) ? 'active' : 'disabled'
            ]" 
            @click="showCompleted" 
            style="cursor:pointer;"
          >
            <i class="fas fa-filter"></i>
            Completed 
            ( <span>{{ $parent.countCompleted }}</span> )
          </button>
        </div>
        <div class="btn-group" role="group">
          <dropdown-menu
              v-model="colsDrop"
              :right="right"
              :hover="hover"
          >
              <button type="button" class="btn btn-light btn-sm" style="border: 1px solid rgb(220, 220, 220);background-color: rgb(255, 255, 255);padding: 7px 10px; color: rgb(93, 91, 91);">
                  <i class="fas fa-columns"></i>
              </button>
              <div slot="dropdown">
                <template v-for="(i, idx) in $parent.desingTableTask">
                    <div class="dropdown-item" :key="idx" v-if="i.optional">
                      <div class="custom-control custom-checkbox" >
                        <input
                            type="checkbox"
                            class="custom-control-input"
                            :id="idx + '-' + i.label"
                            v-bind:value="i.id"
                            v-model="cols"
                            :checked="(i.visible!=!1)"
                            @change="editCols(idx, i)"
                        />
                        <label class="custom-control-label" :for="idx + '-' + i.label">
                          <span v-if="i.id!='name_vendor'">
                            {{ i.label }}
                          </span>
                          <span v-else>Vendor</span>
                        </label>
                      </div>
                    </div>
                  </template>
              </div>
          </dropdown-menu>
        </div>
    </div>
  </div>
</template>
<script>
import ClickOutside from 'vue-click-outside'
import DropdownMenu from '@innologica/vue-dropdown-menu'
export default {
  components: {
    DropdownMenu,
  },
  name: "task_filter",
  data() {
    return {
      showDropAdd: false,
      showSearch: false,
      searchForm: null,
      showDropFill: false,
      showFillState: false,
      cols: ['rec', 'assign', 'category', 'expense'],
      colsDrop: false, 
      right: true,
      hover: false, 
      m: {
          show: false,
          component: '',
          data: []
      },
    };
  },
  created(){
  },
  directives: {
    ClickOutside
  },
  methods:{
    hideDropAdd() {this.showDropAdd= false;},
    hideDropFill() {this.showDropFill= false;},
    hideFillState() {this.showFillState= false;},
    addTask() {
      this.$parent.autoAdd();
    },
    activeSearch(){
      this.showSearch=!this.showSearch;
      let t = this;
      setTimeout(function(){
        if(t.showSearch){t.$refs.searchForm.focus();}
      }, 400);
    },
    hideSearch(){this.showSearch= false;},
    activeFill(){this.showDropFill= !this.showDropFill;},
    editCols(idx, i){   
      let t = this;
      let rees = [];
      t.$parent.desingTableTask.filter((r) => {rees.push(r)});
      rees[idx].visible = !i.visible;
      rees[idx].customClick.show3punto=false;
      t.$parent.desingTableTask = [];
      rees.filter((r) => {t.$parent.desingTableTask.push(r)});
      /*window.billing.post("/columnasInvoice", {
        user_idd: window.master_client,
        org_id: window.localorgdata,
        typeUser: window.user_type,
        user_id: window.localorgdata,
        oauth: window.oauth_m,
        columnas: this.cols.toString()
      })
      .then((response) => {
        console.log('columnasInvoice', response.data.r);
        this.searchFilter();
        if(response.data.r){
          let cols = response.data.r.meta_value;
          console.log('setting_columnas', cols)
          // this.showCols(cols);
        }
      })
      .catch((error) => {
        console.log(error);
        t.isLoading = false;
      });*/
    },
    allStatus(){
      this.$parent.statuslist=[0, 1, 2];
    },
    showCompleted(){
      if(!this.$parent.statuslist.includes(2) || this.$parent.statuslist.length>1){
        this.$parent.statuslist=[2];
      }else{
        this.$parent.statuslist=[0, 1];
      }
    },
    searchTaskName(){
      if(this.searchForm==null || this.searchForm.length==0){
        this.activeSearch();
      this.$parent.inputFilter = '';
      }else{
        this.$parent.inputFilter = this.searchForm;
      }
      this.$parent.getListTask(1);
    },
  },
  watch:{
    "$parent.statuslist": function(){
      if(this.$parent.statuslist.length>0){
        this.$parent.getListTask(1);
      }
    },
    "$parent.filterTimer": function(){
      this.$parent.getListTask(1);
    },
  },
};
</script>
<style lang="scss" scoped>
  .task-filter {
    padding: 15px;
    border-top: 1px solid #dee2e6;
  }
  .campos_filters {
    display: flex;
  }
  .btn-primary{background: #031099;}
  .inputSearch{
    border: 1px solid #999;
    border-radius: 4px;
      button.btn.btn-search {
      font-size: 12px;
      padding: 6px 10px;
      background: #fff;
      color: #999;
    }
  }
  .inputFillSearch{
    transition: all 0.5s ease-in-out;
  }
  .showSearch {
      margin-right: 10px;
      width: 300px;
    .inputSearch{
      position: relative;
      width: 300px;
      .label-search{display: none;}
      input.form-control{
        display: block; 
        position: absolute;
        top: 0px;
        left: 0px;
        background: transparent;
        padding: 6px 10px 2px 25px;
        border-radius: 4px;
        border:0px;
        &:focus{outline: none;}
        &:focus-within{outline: none;}
        &:focus-visible{outline: none;}
      }  
    }
  }
  .hideSearch {
    width: 74px;
    margin-right: 10px;
    transition: all 0.5s ease-in-out;
    .inputSearch{
      input.form-control{display: none;}
    }
  }
  .btn-group{
    margin-right: 10px;
    .btn-group{margin-right: 0px;}
    .btnClick{
      font-size: 12px;
      padding: 2px 10px;
    }
    .dropdown-toggle{
      padding: 2px 5px;   
      border-left: 1px solid #4c37d1;
    }
    .dropdown-menu.show {
      padding: 2px 0px;
      .dropdown-item {
        font-size: 12px;
        padding: 5px 10px;
      }
    }
  }
  button.btn.btn-light {
      background: #fff;
      border: 1px solid #999;
  }
  .dropdown-item {
      padding: 0 10px;
      &.active, &:active {
        color: #fff;
        text-decoration: none;
        background-color: #2962FF;
    }
    &:hover, &:focus {
      color: #16181b;
      text-decoration: none;
      background-color: #f6fafe;
      &.active{
        color: #fff;
        text-decoration: none;
        background-color: #2962FF;
      }
    }
    label.custom-control-label {
      font-size: 12px;
      display: block;
        &::before, &::after {
          top: 0rem;
      }
      &:hover{
        cursor: pointer;
      }
    }
  }
  @keyframes rotate {from {transform: rotate(0deg);}
      to {transform: rotate(360deg);}}
  @-webkit-keyframes rotate {from {-webkit-transform: rotate(0deg);}
    to {-webkit-transform: rotate(360deg);}}
  .aReFresh{
      -webkit-animation: 3s rotate linear infinite;
      animation: 3s rotate linear infinite;
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
  }
  .btnCompleted{
    font-size: 12px;
    span{
      font-size: 14px;
      font-weight: bold;
      color:#fff;
    }
  }
</style>
