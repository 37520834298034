export default {
    loginReseller(context, credentials) {
      let c = context;
      return new Promise((resolve, reject) => {
        window.reselleR1.post('loginReseller', {
          email: credentials.email,
          password: credentials.password,
        })
        .then(response => {
            if(response.data.status){
              console.log('login Reseller');
              if(response.data.smsStatus==1){
                c.commit('setSessionTemp', response.data);
                resolve(1);
              }
              else{
                localStorage.setItem('access_token_r', response.data.accessToken);
                localStorage.setItem('resellerId', response.data.user_id);
                localStorage.setItem('oauth_r', response.data.oauth);
                localStorage.setItem('ui', 'Reseller');
                resolve(2);
              }
            }else{
              console.log(response.data.error);
              resolve(false);
            }
        })
        .catch(error => { 
          console.log(error);
          reject(false); 
        });
      });
    }, 
    getSessionReseller(context) {
      let c = context;
      return new Promise((resolve, reject) => {
        window.reselleR1.post('sessionResellerLogin', {
          oauth_id: localStorage.getItem('oauth_r'),
          user_id: localStorage.getItem('resellerId'),
        })
        .then(response => {
          console.log('getsession', response.data)
            if(response.data.tokenValid){
              console.log('login Reseller');
              c.commit('setAuthResller', true);
              // c.commit('setLoggedInResller', true);
              localStorage.setItem('ui', 'Reseller');
              resolve(response);
            }else{
              resolve(false);
            }
        })
        .catch(error => { 
          console.log(error);
          resolve(false); 
        });
      });
    },
    validSessionReseller(context) {
      let c = context;
      return new Promise((resolve, reject) => {
        window.reselleR2.post('sessionResellerAuth', {
          oauth_id: localStorage.getItem('oauth_r'),
          user_id: localStorage.getItem('resellerId'),
        })
        .then(response => {
          console.log('getsession', response.data)
            if(response.data.tokenValid){
              localStorage.setItem('ui', 'Reseller');
              c.commit('setLoggedInResller', true);
              c.commit('setAuthResller', true);
              console.log('login Reseller');
              resolve(response);
            }else{
              resolve(false);
            }
        })
        .catch(error => { 
          console.log(error);
          resolve(false); 
        });
      });
    },    
    destroyTokenReseller(context) {
      console.log('logout');
      let c = context;
      return new Promise((resolve, reject) => {
        //debugger;
        window.reselleR2.post('logout', {
          user_id: localStorage.getItem('resellerId'),
          oauth: localStorage.getItem('oauth_r'),
        })
          .then(response => {
            console.log('salir');
            c.commit('destroyTokenResller')
            resolve(response);
          })
          .catch(error => {
            console.log(error);
            c.commit('destroyTokenResller');
            reject(error);
          })
      });
    },
}
