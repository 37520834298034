<template>
	<div>
		<div class="card">
			<div class="card-header">
				Add Subcontractor/Hours                    
			</div>
			<form @submit.prevent="actionSubTimePdfAllCheck">
				<div class="card-body">
					<div class="row">
						<div class="col-md-12">
							<table border="0" width="85%">
								<tr>
									<td valign="top">Subcontractor</td>
									<td valign="top">:</td>
									<td valign="top">
										<strong>
											{{ timecheck.subcontractor_name }}
										</strong>
									</td>
								</tr>
								<tr>
									<td valign="top">Select Project (Optional)</td>
									<td valign="top">:</td>
									<td valign="top">
										- Choose a Project -
										<!-- <model-select :options="horas" v-model="fs.proyect" placeholder="- Choose a Project -"></model-select> -->
									</td>
								</tr>
								<tr>
									<td valign="top">Check Date</td>
									<td valign="top">:</td>
									<td valign="top">
										<datetime
											required
											type="datetime"
											v-model="fs.fecini"
											:format="{
												year: 'numeric',
												month: 'numeric',
												day: 'numeric',
											}"
											use12-hour
											value-zone="America/New_York"
											input-class="form-control jobdate"
										></datetime>
									</td>
								</tr>
								<tr>
									<td valign="top">Pay to the order of</td>
									<td valign="top">:</td>
									<td valign="top">
										{{ timecheck.subcontractor_name }}
									</td>
								</tr>
								<tr>
									<td valign="top">Amount $</td>
									<td valign="top">:</td>
									<td valign="top">{{ timecheck.payment }}</td>
								</tr>
								<tr>
									<td valign="top">Memo</td>
									<td valign="top">:</td>
									<td valign="top">
										<textarea
											name="observ_1"
											id="observ_1"
											autocomplete="off"
											rows="5"
											class="input-block-level"
											cols="60"
											v-model="fs.fromTo"
										></textarea>
									</td>
								</tr>
							</table>
						</div>
					</div>
					<div style="width: 100%; text-align: right">
						<button
							type="submit"
							class="btn btn-success"
							:disabled="isDisabled"
						>
							Print Check
						</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>
<script>
export default {
	components: {
	},
	name: "SubFormTimesheetPrint",
	data() {
		return {
			isDisabled: false,
            fs: {
                user_id: window.localorgdata,
                times_id: 0,
                id: 0,
                fecini:
                    (new Date().toISOString() + "").slice(0, 11) +
                    new Date().toTimeString().slice(0, 8),
                fromTo: "",
                subcontractor_name: "",
                payment: 0,
                proyect: "",
            },
			timecheck: [],          
		};
	},
    created () {
        this.init()
    },
	methods: {
        init() {
            let g = this.$store.getters['core/getOpenModal'];
            let r = g.data;
            this.timecheck = r.timecheck;          
        },		
        actionSubTimePdfAllCheck() {
            this.isLoading = true;
            let url = "/timesheet/ePrinttimesheetPDFAllCheck";
            let data = {
                user_id: this.fs.user_id,
                id: this.fs.times_id,
                eprint: 1,
                sub_id: this.fs.id,
            };
            window.billing
                .post(url, data)
                .then((response) => {
                    this.isLoading = false;
                    let status = response.data.status;
                    let error = response.data.error;
                    let message = response.data.message;
                    if (status === false && error === true) {
                        this.$notify({
                            group: "noti",
                            title: "Send!",
                            type: "error",
                            text: message,
                        });
                    }
                    if (status === true && error === false) {
                        this.$notify({
                            group: "noti",
                            title: "Send!",
                            type: "success",
                            text: message,
                        });
                    } else {
                        this.$notify({
                            group: "noti",
                            title: "Send!",
                            type: "error",
                            text: message,
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.$notify({
                        group: "noti",
                        title: "Send!",
                        type: "error",
                        text: "Send Error",
                    });
                });
        }                          
    },
    watch: {},
}
</script>
