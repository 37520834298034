<template>
	<div class="popover-assign">
		<div class="content-owner" v-if="item.assing!=undefined">
            <div class="isExistAdmin">
                Choose User:
            </div>
            <template v-for="(a, aIdx) in item.assing">
                <span class="owner" :key="aIdx" v-if="a.id==org_id">
                    <span class="avatarUser">{{a.pref_name}}</span>
                    <span class="owner-default">
                        {{a.firstname}} {{a.lastname}}<br>
                        <span class="owner-email" >{{a.email}}</span>
                    </span>
                </span>
            </template>
        </div>
        <div class="listUser" v-if="item.assing!=undefined">
            <template v-for="(a, aIdx) in item.assing">
                <span :key="aIdx" class="badge badge-light">
                    <span :class="['avatarUser','bg-avatar'+aIdx]">
                        {{a.pref_name}}
                    </span>
                    {{a.firstname}} {{a.lastname}}
                    <i class="fas fa-times" @click="removeElement(a)" ></i>
                </span>
            </template>
        </div>
        <div class="content-table">
            <multiselect
                v-model="userIdAssign"
                placeholder="Search:"
				select-label="Add"
                track-by="id"
                :options="options"
                :multiple="true"
                style="border: 1px solid #343a402e;"
                ref="vselectAssing"
                @input="saveAssign"
            >
            	<template slot="selection" slot-scope="{ values, search, isOpen }">
            		<span 
            			class="multiselect__single"
            			v-if="values.length" 
            			v-show="!isOpen"
            		>
            		Search:
            			<!-- {{ values.length }} assigned users -->
            		</span>
            	</template>
                <template slot="option" slot-scope="props" >
                    <div class="option__desc" style="margin: 0.5px; ">
                        <div>
                            <strong style="color:#555; margin-bottom: 5px;" v-if="props.option.id==org_id">{{ props.option.owner }}</strong><br />
                            <span class="option__title" style="font-size: 12px;color: #4f5467;">
                            	{{ props.option.firstname }} {{ props.option.lastname }}
                            </span>
                            <br />
                            <span class="option__small" style="font-size: 11px;color: #aaa;">
                            	{{ props.option.email }}
                            </span
                            >
                        </div>
                    </div>
                </template>
            </multiselect>
        </div>
	</div>
</template>
<script>
//import Loader from "@/core/components/shared/Loader.vue";
import Multiselect from "vue-multiselect";
import { mapGetters } from "vuex";
export default {
    name: "assign_user",
    components: {
        //Loader,
        Multiselect,
    },
    data() {
        return {
            org_id: window.localorgdata,
			userIdAssign: [],
			userlist: [],
			options: [],
			item: null,
        };
    },
    created() {
		let t = this;		
        let g = t.$store.getters['core/getOpenModal'];
        t.userIdAssign = g.data.item.assing;
        t.item = g.data.item;
        t.options =g.data.userlist;
		if(t.userlist.length>0){
			t.userlist.filter(r => {
				// if(r.id != t.org_id){}
                t.options.push(r);
			})
		}
        console.log('userIdAssign', this.userIdAssign)
    },
    methods: {
        removeElement: function(user) {
			this.$refs.vselectAssing.removeElement(user)
            //console.log('removeElement', user)
		},
		saveAssign(values){
			console.log('saveAssign', values)
			let t = this;
			let campo = 'assing_users';
			let arr = [];
            t.userIdAssign = [];
            values.filter(r => {
				arr.push(r.id)
                t.userIdAssign.push(r);
			});
            t.item.assing = t.userIdAssign;
			let valor=arr.toString();
			console.log('change', campo, valor)
			window.billing
			.post("updateTask", {
				id: t.item.id,
				user_idd: window.master_client,
				user_id: window.localorgdata,
				org_id: window.localorgdata,
				oauth_m: window.oauth_m,
				campo: campo,
				valor: valor,
			})
			.then((response) => {
				console.log('updateTask');
                t.isExistAdmin();
			})
			.catch((error) => {
				console.log(error);
			});
		},
        isExistAdmin(){
            let t = this;
            return t.userIdAssign.filter(r => {
                if(r.id == t.org_id){
                    return r;
                }
            });
        },
    },
    watch:{
    },
    computed: {        
    },
};
</script>
<style lang="scss" scoped>
    .popover-assign{
            position: relative;
            height: auto;
            /*overflow: hidden;*/
            background: #fff;
            color: #000;
            border-radius: 4px;
            padding: 10px 0px;
            display: block;
            margin-top: 20px;

        .avatarUser{
            width: 40px;
            height: 40px;
            display: inline-block;
            text-align: center;
            margin: 0px;
            padding-top: 5px;
            border: 1px solid #ccc;
            position: relative;
            z-index: 1;
            border-radius: 50%;
            font-size: 18px;
            letter-spacing: -1px; 
            font-weight: 900;
            background-color: #c4c4c4;
            color: #fff;
            text-transform: uppercase;
            
            &.bg-avatar0{background-color: #2b6af1;}
            &.bg-avatar1{background-color: #fdab3d;}
            &.bg-avatar2{background-color: #00c875;}
            &.bg-avatar3{background-color: #e2445c;}
            &.bg-avatar4{background-color: #b00af1;}
            &.bg-avatar5{background-color: #2ab8df;}
            &.bg-avatar6{background-color: #df752a;}
        }
        .content-owner{
            .owner{
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-content: center;
                justify-content: center;
                align-items: center;
                margin: auto;
                margin-bottom: 10px;
                .owner-default{
                    color: #555; 
                    font-size: 18px;
                    text-align: left;
                    line-height: 1em;
                    .owner-email{color: #6a7a8c; font-size: 12px;}

                }
                .avatarUser{
                    width: 48px;
                    height: 48px;
                    background-color: #031099;
                    font-size: 24px;
                    margin-right: 10px;
                }
            }
        }
        .listUser{
            display: block;
            width: 100%;
            margin: auto;
            text-align: center;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            padding: 0px 5px;
            margin-bottom: 10px;
            span.badge{
                position: relative;
                width: 100%;
                max-width: 200px;
                font-size: 16px;
                text-align: left;
                border: 1px solid #ddd;
                border-top-left-radius: 15px;
                border-bottom-left-radius: 15px;
                border-top-right-radius: 15px;
                border-bottom-right-radius: 15px;
                margin-bottom: 10px;
                margin-right: 10px;
                padding: 0px 5px 0px 0px;
                overflow-x: hidden;
                overflow-y: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow-x: clip;
                .avatarUser{
                    width: 40px;
                    height: 40px;
                    border-radius: 10%;
                }
                i.fas{
                    display: none;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    z-index: 2;
                    top: 5px;
                    right: 5px;
                    font-size: 80%;
                    border: 1px solid #ddd;
                    color: #555;
                    background-color: #ddd;
                    border-radius: 50%;
                    width: 20px;
                    height: 20px;
                    padding: 5px;
                }
                &:hover{
                    z-index: 2;
                    i.fas{
                        display: flex;
                        cursor: pointer;
                        &:hover{
                            background-color: #e2445c;
                            color: #fff;
                        }
                    }
                }
            }
        }
        .content-table{
            padding: 0px 10px;
        }
    }
    .isExistAdmin{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: bold;
        text-shadow: none;
        margin-bottom: 20px;
    }
</style>
