let activateActions = function(index) {
	let allCheck = document.querySelectorAll(".checkAction");
	for (let i in allCheck) {
		if (allCheck.hasOwnProperty(i)) {
			allCheck[i].parentElement.parentElement.nextSibling.style.display =
			"none";
			allCheck[i].parentElement.parentElement.nextSibling.style.left =
			"0px";
		}
	}
	let check = document.querySelector("#check" + index);
	if (check.checked) {
		check.parentElement.parentElement.nextSibling.style.display = "block";
		check.parentElement.parentElement.nextSibling.style.left = "-180px";
	} else {
		check.parentElement.parentElement.nextSibling.style.display = "none";
		check.parentElement.parentElement.nextSibling.style.left = "0px";
	}
};
export default activateActions;
