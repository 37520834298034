<template>
    <div class="container-fluid container_dimenu">
        <article class="container-menu">
            <nav-category v-if="listCat.length>0"
                @clickItem="showSection($event)"
                @clickAction="openLateral($event)"
                @liOffsetWidth="menuLiOffsetWidth($event)"
            ></nav-category>
            <food-list
                v-if="listCat.length>0"
                @followItem="indicadorMenu($event)"
                @clickAction="openLateral($event)"
                @sectionActive="sectBg($event)"
                @delFood="delFoodConfirm($event)"
                :urlMedia="urlMedia+path_base"
            ></food-list>
        </article>
        <!-- modal lateral-->
        <modalLateral>
            <component v-bind:is="m.component" ></component>
        </modalLateral>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import "./stylesGlobal.scss"
import nav_bMenu from "./components/nav_bMenu.vue"
import foodList from "./components/foodList.vue"
import categories from "./components/categories_bMenu.vue"
import foodUpload from "./components/foodUpload.vue"
import modalLateral from '@/core/plugins/themeModalLateral/modalLateral'
export default {
    components:{
        'nav-category': nav_bMenu,
        'food-list': foodList,
        categories,
        foodUpload,
        modalLateral,
    },
    data(){
        return {
            isLoading: false,
            apiProgess: 0,
            listCat2: [
                {
                    id: 1,
                    name: "Margaritas & Cocktails",
                    foods : [
                        { name: "Triple Dipper™"},
                        { name: "Bone-In Wings"},
                    ],
                },
                {
                    id: 2,
                    name: "Appetizers",
                    foods : [
                        { name: "White Skillet Queso"},
                        { name: "Fresh Guacamole"},
                        { name: "Chips & Salsa"},
                        { name: "Fried Mozzarella - Shareable"},
                        { name: "Fried Mozzarella - Small"},
                        { name: "Texas Cheese Fries"},
                    ],
                },
                {
                    id: 5,
                    name: "Big Mouth Burgers®",
                    foods : [
                        { name: "Triple Dipper™"},
                        { name: "Bone-In Wings"},
                        { name: "Boneless Wings"},
                        { name: "Dip Trio"},
                        { name: "Southwestern Eggrolls"},
                        { name: "Skillet Queso"},
                        { name: "White Skillet Queso"},
                        { name: "Fresh Guacamole"},
                        { name: "Chips & Salsa"},
                        { name: "Fried Mozzarella - Shareable"},
                        { name: "Fried Mozzarella - Small"},
                        { name: "Texas Cheese Fries"},
                    ],
                },
                {
                    id: 6,
                    name: "Chicken Crisper® Combos",
                    foods : [],
                },
                {
                    id: 7,
                    name: "Steaks & Ribs",
                    foods : [],
                },
            ],
            listCat: [],
            categoryActive: null,
            apis: null,
            pagRows: 0,
            searchCategory: null,
            filter_status: [0, 1],
            ordenKey: 'orden',
            ordenVal: 'ASC',
            filter_group: [],
            urlMedia: null,
            path_base: null,
            m: {
                show: false,
                component: '',
                data: []
            },
        };
    },
    created(){
        let t = this;
        let urlApis = t.$store.getters['ModApps/Marketea/urlApis'];
        t.apis = urlApis.bMenu;
        t.getList();
    },
    mounted(){
        let t = this;
    },
    computed: {
        ...mapGetters("core", { getPusher: "get_accitonPusher"}),
    },
    methods: {
        getList(noLoad){
            let t = this;
            let urlService = t.apis.get(0); //setCat
            if(noLoad==undefined){t.isLoading = true;}
            // @ts-ignore
            window.master.post(urlService, {
                user_id: window.master_client,
                org_id: window.localorgdata,
                pusher_id: pusher.connection.socket_id,
                rows: t.pagRows,
                filter_group: t.filter_group,
                filter_name: t.searchCategory,
                filter_status: t.filter_status,
                ordenKey: t.ordenKey,
                ordenVal: t.ordenVal,
            }, {
                headers: {
                    common: {
                    // @ts-ignore
                    Authorization: 'Bearer ' + window.localtoken_M
                    },
                    // post: { 'Content-Type': 'multipart/form-data' }
                },
                onUploadProgress: (progressEvent) => {
                    // console.log('onUploadProgress', progressEvent)
                    let scanPer = Math.floor(
                        (progressEvent.loaded*100 / progressEvent.total)
                    );
                    // console.log('scanPer', scanPer);
                    if(scanPer < 99){
                        t.apiProgess = scanPer;
                    }
                }
            })
            .then((response) => {
                t.apiProgess =100;
                // @ts-ignore
                // console.log(urlService, response.data);
                t.urlMedia = response.data.path.url;
                t.path_base = response.data.path.path_base;
                if(response.data.list.total>0){
                    t.listCat = response.data.list.data;
                    console.log(urlService, t.listCat)
                    t.categoryActive = t.listCat[0];
                    t.setStore('bmenu_listCat', t.listCat);
                    t.setStore('bmenu_catActive', t.categoryActive);
                    if(response.data.currency){
                        t.$store.commit('ModApps/Marketea/set_bmenu_currency', response.data.currency.meta_value);
                    }
                }
                // else{
                //     t.dataList =[];
                //     t.listCat = t.listCat2;
                //     t.categoryActive = t.listCat[0];
                //     t.setStore('bmenu_listCat', t.listCat);
                //     t.setStore('bmenu_catActive', t.categoryActive);
                // }
                t.isLoading = false;
            }).catch((error) => {
                console.log(error);
                t.apiProgess =0;
                t.isLoading = false;
            });
        },
        setStore(key, val){
            let t = this;
            t.$store.commit('ModApps/Marketea/set_bMenuValStorage', {key, val});
        },
        showSection($event){
            // console.log('showSection', $event)
            let t = this;
            t.indicadorMenu();
            t.foodScrolling($event);
            t.sectBg("#section-"+$event)
        },
        indicadorMenu(elemtId){
            const menu = document.querySelector('.nav-category');
            const indicador = document.getElementById('indicador');
            let listNav = menu.querySelectorAll('ul.menu-categores li');
            if(listNav.length > 2){
                let itemActivo = menu.querySelector('ul.menu-categores li a.active');
                const food_list = document.querySelector('.container_food_list');
                const secciones = document.querySelectorAll('.food_seccion');
                if(itemActivo){
                    let tamañoIndicador = itemActivo.offsetWidth;
                    indicador.style.width = tamañoIndicador + 'px';
                    // Volvemos a posicionar el indicador.
                    let indexSeccionActiva = itemActivo.offsetLeft
                    indicador.style.transform = `translateX(${indexSeccionActiva}px)`;
                    // console.log('indicador width', indicador.style.width);
                    // console.log('indicador translateX', indexSeccionActiva);
                    // console.log('menu.offsetWidth', menu.offsetWidth);
                    let leftScroll = menu.offsetWidth - (indexSeccionActiva+tamañoIndicador);
                    // console.log('leftScroll', leftScroll)
                    if(leftScroll < 0){
                        menu.scrollLeft = (leftScroll * -1) + 40;
                        // console.log('scrollLeft --', menu.scrollLeft)
                    }else if(leftScroll > 0){
                        menu.scrollLeft-= leftScroll;
                        // console.log('scrollLeft ++', menu.scrollLeft)
                    }
                }else{
                    indicador.style.width = '0px';
                    indicador.style.transform = `translateX(0px)`;
                }
            }
        },
        foodScrolling(elemtId){
            const food_list = document.querySelector('.container_food_list');
            const food_seccions = document.querySelectorAll('.food_seccion').length;
            if(food_seccions>1){
                if(document.querySelector("#section-"+elemtId)){
                    food_list.scrollTop = document.querySelector("#section-"+elemtId).offsetTop - 80;
                }
            }
        },
        sectBg(idS){
            // console.log('sectBg', idS)
            if(document.querySelectorAll('.food_seccion').length>2 && document.getElementById(idS)){
                document.querySelectorAll('.food_seccion').forEach(s => {document.getElementById(s.id).style.opacity='0.5';});
                document.getElementById(idS).style.opacity='1';
            }
        },
        menuLiOffsetWidth(event){
            const mainNav = document.querySelector(".main-nav-category");
            if(mainNav){
                const ulMenuCat = document.querySelector("ul.menu-categores");
                if(ulMenuCat){
                    let navCat = 0;
                    ulMenuCat.querySelectorAll('li').forEach(li => {navCat += li.offsetWidth});
                    if(navCat < mainNav.offsetWidth){
                        ulMenuCat.offsetWidth
                        let liWidth = mainNav.offsetWidth / ulMenuCat.querySelectorAll('li').length;
                        if(liWidth>0 && ((liWidth * ulMenuCat.querySelectorAll('li').length)>=mainNav.offsetWidth)){
                            console.log('ok', liWidth)
                            ulMenuCat.querySelectorAll('li').forEach(li => {
                                li.style.flex='auto';
                                li.style.display='flex';
                                li.style.justifyContent='center';
                            });
                            ulMenuCat.style.width='100%';
                            ulMenuCat.style.justifyContent='space-around';
                        }else{console.log('error', liWidth)}
                    }
                    this.indicadorMenu();
                }
            }
        },
        openLateral(event) {
            let t = this;
            t.m.show= true;
            t.m.component= event.name;
            t.m.data=event.data;
            t.$store.commit('core/setOpenModal', t.m);
        },
        delFoodConfirm(food){
            let t = this;
            // console.log('delFoodConfirm', food)
            if(!food || food.id==0){
                t.$notify({
                    group: "noti",
                    type: "error",
                    title: "Error",
                    text: "To remove it must be without products",
                });
            }else{
                t.$swal({
                    title: "Delete "+food.name_food+"?",
                    text: "Are you sure?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Yes, Delete it!",
                }).then((result) => {
                    if (result.value) {
                        console.log('delete food', food.name_food)
                        t.$store.commit('ModApps/Marketea/set_bMenuDelFoodItem', food);
                        t.deleteFood(food);
                    }
                });
            }
        },
        deleteFood(i){
            let t = this;
            if (i.id > 0) {
                let urlService = t.apis.set(8)// deleteFood
                window.master.post(urlService, {
                    user_id: window.master_client,
                    org_id: window.localorgdata,
                    id: i.id,
                    pusher_id: pusher.connection.socket_id,
                })
                .then((response) => {
                    console.log(urlService, response.data);
                })
                .catch((error) => {
                    console.log(error);
                    t.$notify({
                        group: "noti",
                        type: "error",
                        title: "Error",
                        text: "Could not be saved!",
                    });
                });
            }
        },
    },
    watch: {
        getPusher: function(){
            let t = this;
            let g = t.getPusher;
            // pusher.connection.socket_id
            if(g.data.org_id==window.localorgdata){
                if(g.data.action=='CategoryItem' && g.data.ope=="create"){
                    let payload = {key: 'bmenu_listCat', val: g.data.getItem};
                    t.$store.commit('ModApps/Marketea/set_bMenuNewItem', payload);
                }
            }
        }
    }
}
</script>
<style lang="scss" scoped>
</style>
