let selectedTr = function() {
	let list = document.querySelectorAll('.table-body table tbody tr');	
	for (let j in list) {
		if (list.hasOwnProperty(j)) {
			let tr = list[j];
			let dataID = parseInt(tr.getAttribute('data-id'));
			if(!tr.classList.contains('active') && this.selected.indexOf(dataID) > -1) {
				tr.classList.add('active');
			} else if(tr.classList.contains('active') && this.selected.indexOf(dataID) == -1) {
				tr.classList.remove('active');
			}
			// console.log('data-id', tr.getAttribute('data-id'), this.selected.indexOf(dataID));
		}
	}
	if(this.selected.length != this.dataT.length) {
		this.selectAll = false;
	} else { this.selectAll = true;}
	//console.log('selected', this.selected)
};
export default selectedTr;
