import draggable from 'vuedraggable'
import { ModelSelect } from 'vue-search-select'
import Multiselect from 'vue-multiselect'
import { VueEditor } from 'vue2-editor'
import { mapGetters } from 'vuex'
import Loader from '@/core/components/shared/Loader'
import assignUser from '@/apps/jobs/components/jobslist/assignUser'
import mCustomer from '@/apps/jobs/components/jobsBox/mCustomer'
import modalLateral from '@/core/plugins/themeModalLateral/modalLateral'
import galleryBox from '@/apps/jobs/components/jobsBox/galleryBox'
import editNoteBox from '@/apps/jobs/components/jobsBox/editNoteBox'
import addUserToTags from '@/apps/jobs/components/jobsFilter/addUserToTags'
import assignTags from '@/apps/jobs/components/tags/assignTags'
import manageTasks from '@/apps/jobs/components/jobsBox/manageTasks'
export default {
	draggable,
	ModelSelect,
	Multiselect,
	VueEditor,
	Loader,
	assignUser,
	mCustomer,
	modalLateral,
	galleryBox,
	editNoteBox,
	addUserToTags,
	assignTags,
	manageTasks,
};
