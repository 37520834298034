<template>
    <div>
        <div class="card-header">
            <div>
                <h4>Resend Activation</h4>
            </div>
        </div>
        <form @submit.prevent="sendformRT">
            <div class=" card-body">
                <div class="row">
                    <div class="col-md-12 col-sm-12">
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <div class="alert alert-danger" role="alert" v-if="messageError != null">
                                    <b>Error: </b>{{messageError}}
                                </div>
                                <div class="alert alert-success" role="alert" v-if="messageSuccess">
                                    <b>Greath, </b>a recovery token has been sent to your email.
                                </div>
                                <div class="password-input" v-if="messageSuccess === false">
                                    <span class="showpassord " action="hide" >
                                        <i class="fas fa-user"></i>
                                    </span>
                                    <input id="email" type="email" class="form-control" name="email" v-model="userCredenctial.email" placeholder="Email" autocomplete="email" required>
                                </div>
                            </div>
                        </div>
                    </div>                 
                </div> 
            </div>
            <div class="card-footer">
                <div v-if="!isLoading">
                    <button v-if="messageSuccess === false" type="submit" class="btn btn-success btn-login">Send token</button>
                    <!-- <button type="button" class="btn btn-sing creat-account" @click="backLoginRT">Go to login </button> -->
                </div>
                <div v-else>
                    <Loader></Loader>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import Loader from '@/core/components/shared/Loader'
export default {
    name: 'formRtoken',
    components: {
        Loader
    },
    data () {
        return {
            messageError: null,
            userCredenctial: {
                email: '',
                password: ''
            },
            isLoading: false,
            urlPage: window.urlweb,
            formLogin: true,
            messageSuccess: false,
            Rtoken:false,
        }
    },
    methods: {
        RtokenActivation(){
            this.Rtoken = true
        },
        backLoginRT() { 
           console.log(this.urlPage);
           this.redirectLogin();
        },
        redirectLogin () {
            // window.location.href=this.urlPage 
            this.$router.push({ name: 'Login' });
        },        
        sendformRT() {
            this.loginInterno()
        },
        loginInterno() {
            this.isLoading = true;
            this.messageError=null;
            this.$store.dispatch('core/session/pushtetokenemail', {
              email: this.userCredenctial.email,
            })
            .then(response => {
                this.isLoading = false;
                let status = response.status
                let msj = response.msj
                if(!status){
                    this.messageError= msj
                    this.messageSuccess = false 
                }else{
                    this.messageSuccess = true   
                }
            }).catch((error) => {
                console.log(error);
                this.isLoading = false;
                this.messageError= 'Error de conexion';
            })
        },
    }
};
</script>

<style lang="css" scoped>
.card {
    background-color: #fbfbfb;
    border: 1px solid #ddd;
    box-shadow: 0 1px 1px #ececec;
    -webkit-box-shadow: 0 1px 1px #ececec;
    -moz-box-shadow: 0 1px 1px #ececec;
    position: relative;
    border-radius: 2px;
    margin-top:40px;
}
.card-header {
    padding: 15px 13px;
    margin: 0;
    border-bottom: 1px solid #ddd;
    background: rgba(248,248,248,.9);
}
.card-header h4 {
    margin-bottom: 0px;
    color: #555;
}
.card-footer {
    display: block;
    padding: 7px 14px 15px;
    border-top: 1px solid #ddd;
    background: rgba(248,248,248,.9);
}
button.btn.btn-success.btn-login {
    padding: 2px 20px;
    border-color: #0d83dd;
    background-color: #0d83dd;
    box-shadow:none;
}
.btn-sing {
    padding: 2px 10px;
    color: #1f1f1f ;
    background-color: #01972000 ;
    border-color: #1f1f1f ;
    border-radius: 2px;
    float: right;
}
.creat-account{
    border-color: #aaa ;
}
form label {
    display: block;
    margin-bottom: 6px;
    line-height: 19px;
    font-weight: 400;
    font-size: 13px;
    color: #333;
    text-align: left;
    white-space: normal;
}
.form-control {
    border: 1px solid #bdbdbd;
    background-color: #fbfbfb !important;
    height: 50px;
}
.form-control:focus {
    background-color: #fbfbfb !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus 
input:-internal-autofill-selected
{
    -webkit-box-shadow: 0 0 0px 1000px #fbfbfb inset;
    background-color: #fbfbfb !important;
}
.showpassord{
    height: 50px;
    border-left: 1px solid;
}
.note {
    margin-top: 6px;
    padding: 0 15px;
    font-size: 11px;
    line-height: 15px;
    color: #999;
}
.note a {
    font-size: 13px;
}
@media (max-width: 1190px){
    .btn.btn-success.btn-login,
    .btn.btn-sing.creat-account {
        display: block;
        margin: 10px auto;
        width: 80%;
        height: 40px;
        font-size: 20px;
        float: initial;
    }
}
@media (max-width: 767px){
    .card {
        max-width: 330px;
        margin: auto;
    }
}
@media (max-width: 350px){
    .card {
        max-width: 300px;
        margin: auto;
    }
}
</style>
