export default {
	header : false,
	showAside: false, 
	accitonPusher: [],
	chatActive: false,
	openModal: {
		show: false,
		component: '',
		data: [],
	},
	loadingModal: false,
	notiPush: {
		group: '',
		type: '',
		title: '',
		text: '',
		show: false,
		duration: 3000
	},
	notiUpdate: {
		group: 'update-item',
		type: '',
		title: '',
		text: '',
		duration: 100,
		show: false
	},
	notiPushRefresh: false,
	alertSimple: {
		icon: '', // success | error
		title: '', // titulo del msjs
		text: '', // descripcion del msj
		showConfirmButton: false, // button Ok
		timer: 3000, // tiempo en pantalla
		show: false
	},
	alertConfirm: {
		icon: '', // success | error
		title: '', // titulo del msjs
		text: '', // descripcion del msj
		show: false
	},
	actionRefresh: {
		action: '',
		data: {}
	},
	showSuscription : {
		app_id: 0,
		show: false,
		infoApp: null,
		planes: [],
	},
	paletaDeColores: [
		'#2b6af1',
		'#fdab3d',
		'#00c875',
		'#e2445c',
		'#b00af1',
		'#2ab8df',
		'#df752a',
		'#001c40',
		'#ff7b0a',
		'#024a86',
		'#02ac66',
		'#ef280f',
		'#e7da0a',
		'#ff689d',
		'#23bac4',
		'#8c4966',
		'#222222',
		'#ececec',
		'#c82a54',
		'#031099',
		'#137eff',
	],
}
