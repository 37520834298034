/*rutas de recurring*/
import InvoicesRec from '@/apps/billing/views/InvoicesRec';
import InvoicesRecNew from '@/apps/billing/views/InvoicesRecNew';
export default [
  {
    path: 'recurring-invoice',
    name: 'InvoicesRec',
    folder: InvoicesRec,
  },
  {
    path: 'recurring-invoice/new',
    name: 'InvoicesRecNew',
    folder: InvoicesRecNew,
  },
  {
    path: 'recurring-invoice/edit/:idinv',
    name: 'InvoiceRecEdit',
    folder: InvoicesRecNew,
  },
  {
    path: 'recurring-invoice/:idinv/:duplicate',
    name: 'InvoiceRecDuplicate',
    folder: InvoicesRecNew,
  },
  {
    path: 'recurring-invoice/:idc/:transac',
    name: 'InvoiceRecTransac',
    folder: InvoicesRecNew,
  },
  {
    path: 'recurring-invoice/:idinv/:convert',
    name: 'InvoiceRecConvert',
    folder: InvoicesRecNew,
  }
];
