let updateDeleteJob = function() {
    for (var k in this.listTaks) {
        if (this.listTaks.hasOwnProperty(k)) {
            if (this.listTaks[k].id_i == this.accitonPusher.item) {
                this.listTaks.splice(k, 1);
            }
        }
    }
    this.reEscribirLista();
    let tag_id = this.id_tags.length > 0 ? this.id_tags[0] : 0;
    this.update_JOPending(tag_id);
    this.starGrid();
};
export default updateDeleteJob;
