export default {	
    access_account(state){return state.auth; },
    loggedIn(state) { return state.token; },
    getEmailVerify(state){return state.emailVerify; },
	getUserType(state){return state.user_type; },
	getReturnUsers(state){return state.return_user; },
	getDataUser(state){return state.dataUser; },	
    privilegiesSesion(state) {return state.privilegies; },
    getShowPopPremium(state) {return state.popPremium; },
}
