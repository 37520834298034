<template>
    <div class="row card-body" style="">
        <div class="row card-header window-header" style="margin: 0px -10px 0px -10px; width: 105%;">
            <div class="col-12 d-flex justify-content-center">
                <h3 style="margin-bottom: 0px;">
                    Add Card
                </h3>
                <!-- <button type="button"
                    class="btn btn-success btn-sm"
                    style="margin-left: 10px;"
                    @click="saveCard()"
                    :disabled="isloading"
                >
                        Save
                </button> -->
            </div>
		</div>
        <div class="row loading" style="width: 100%" v-if="isloading">
            <Loader class="col-12"></Loader>
        </div>
        <div class="row" style="width: 100%;">
            <div class="col-12 text-center">
                <h4>Choose a Template</h4>
            </div>
            <div class="col-xs-12 col-sm-6 marginBottom">
                <label class="style-template">
                    <input type="radio" :value="1" v-model="form.template_id" class="inputHide" @change="saveCard()"/>
                    <h4>
                        Classic grid
                        <span :class="['status_template', (form.template_id==1) ? 'active' : '']"></span>
                    </h4>
                    <img src="@/apps/Marketea/assets/template1.png" alt="template1" class="templatePreview"/>
                </label>
            </div>
            <div class="col-xs-12 col-sm-6 marginBottom">
                <label class="style-template">
                    <input type="radio" :value="2" v-model="form.template_id" class="inputHide" @change="saveCard()"/>
                    <h4>
                        Nightly
                        <span :class="['status_template', (form.template_id==2) ? 'active' : '']"></span>
                    </h4>
                    <img src="@/apps/Marketea/assets/template2.png" alt="template2" class="templatePreview"/>
                </label>
            </div>
            <div class="col-xs-12 col-sm-6 marginBottom" style="height: 350px;">
                <div v-if="!premiumProfile" class="bg-disabled">
                    <span>Not available for your current plan</span>
                </div>
                <label class="style-template">
                    <input type="radio" :value="3" v-model="form.template_id" :checked="form.template_id==3" @change="saveCard()" class="inputHide"/>
                    <h4>
                        Jaglow
                        <span :class="['status_template', (form.template_id==3) ? 'active' : '']"></span>
                    </h4>
                    <img src="@/apps/Marketea/assets/template3.png" alt="template3" class="templatePreview"/>
                </label>
            </div>
            <div class="col-xs-12 col-sm-6 marginBottom" style="height: 350px;">
                <div v-if="!premiumProfile" class="bg-disabled">
                    <span>Not available for your current plan</span>
                </div>
                <label class="style-template">
                    <input type="radio" :value="4" v-model="form.template_id" :checked="form.template_id==4" @change="saveCard()" class="inputHide"/>
                    <h4>
                        Postorg
                        <span :class="['status_template', (form.template_id==4) ? 'active' : '']"></span>
                    </h4>
                    <img src="@/apps/Marketea/assets/template4.png" alt="template4" class="templatePreview" style="height: 320px;width: auto;"/>
                </label>
            </div>
            <div class="col-xs-12 col-sm-6 marginBottom" style="height: 350px;">
                <div v-if="!premiumProfile" class="bg-disabled">
                    <span>Not available for your current plan</span>
                </div>
                <label class="style-template">
                    <input type="radio" :value="5" v-model="form.template_id" :checked="form.template_id==5" @change="saveCard()" class="inputHide"/>
                    <h4>
                        bMenu
                        <span :class="['status_template', (form.template_id==5) ? 'active' : '']"></span>
                    </h4>
                    <img src="@/apps/Marketea/assets/template5.png" alt="template5" class="templatePreview" style="height: 320px;width: auto;"/>
                </label>
            </div>
            <div class="col-xs-12 col-sm-6 marginBottom" style="height: 350px;">
                <div v-if="!premiumProfile" class="bg-disabled">
                    <span>Not available for your current plan</span>
                </div>
                <label class="style-template">
                    <input type="radio" :value="6" v-model="form.template_id" :checked="form.template_id==6" @change="saveCard()" class="inputHide"/>
                    <h4>
                        Events
                        <span :class="['status_template', (form.template_id==6) ? 'active' : '']"></span>
                    </h4>
                    <img src="@/apps/Marketea/assets/template4.png" alt="template6" class="templatePreview" style="height: 320px;width: auto;"/>
                </label>
            </div>
		</div>
        <!-- <div class="row" style="width: 100%;margin-top: 80px;">
            <div class="col-12 text-center" style="">
                <button type="button"
                    class="btn btn-success"
                    @click="saveCard()"
                    :disabled="isloading"
                    style="width: 100px;border-radius: 20px;"
                >
                Save
                </button>
            </div>
        </div> -->
    </div>
</template>
<script>// @ts-ignore
import Loader from "@/core/components/shared/Loader.vue";
export default {
    name:"createCard",
    components:{
        Loader
    },
    data(){
        return {
            isloading: false,
            form : {
                user_id: window.master_client,
                org_id: window.localorgdata,
                name : '',
                lastname : '',
                rol: 'Employee',
                template_id: 0,
                pusher_id: pusher.connection.socket_id,
            },
            premiumProfile: false,
            planesPro: [6, 7, 10, 11, 12],
        };
    },
    created(){
        let t= this;
        // t.premiumProfile = (t.planesPro.indexOf(t.$store.getters['core/apps/getPlanIdApp'])>-1);
        t.premiumProfile = (t.$store.getters['core/apps/getPlanIdApp']!=8);
        console.log('premiumProfile', t.$store.getters['core/apps/getPlanIdApp'])
    },
    methods: {
        saveCard(){
            let t = this;
            t.isloading=true;
            t.$store.dispatch('ModApps/Marketea/createProfile', t.form)
            .then(r => {
                console.log('createProfile', r);
                if(r.status){
                    t.$modal.hide("modal-editProfile");
                }
                t.isloading=false;
            })
            .catch(error => {
                console.log(error);
                t.isloading=false;
                t.$notify({
                    group: "noti",
                    type: 'Error',
                    title: "Fail",
                    text: "Failed to save",
                    duration: 2000
                });
            });
        },
    },
}
</script>
<style lang="scss" scoped>
.window-header{cursor: move;}
.card-header h3{font-size:24px;}
.col-form-label, .form-control, .input-group-text{
    font-size:18px;
}
.onoffswitch{
    display: inline-flex;
    width: 80px;
    .onoffswitch-label{
        .onoffswitch-inner{
            &:before{
                padding-left: 25px;
                content: "Active";
            }
            &:after{
                content: "Inactive";
            }
        }
        .onoffswitch-switch{right: 55px;}
    }
}
label.style-template{
    display: block;
    margin: auto;
    width: 100%;
    height: 250px;
    max-width: 150px;
    &:hover{
        cursor: pointer;
    }
}
.templatePreview{
    display: block;
    margin: auto;
    width: 100%;
    border-radius: 4px;
    border: 3px solid #000;
}
.inputHide{display: none;}
label.style-template .inputHide:checked ~ img.templatePreview{
    border: 3px solid rgb(79, 164, 255);
}
.status_template{
    display: inline-block;
    margin-left: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px solid #ddd;
    background: #ddd;
    &.active{background: #5ac146;}
}
.card-body{
    height: 500px;
    align-content: flex-start;
    border: 0px solid #000;
    padding-top: 0px;
    padding-bottom: 5em;
    overflow: hidden;
    overflow-y: auto;
}
.loading{
    position: absolute;
    top:60px;
    height: calc(100% - 60px);
    width: 100%;
    display: flex;
    align-content: center;
    z-index: 1;
    background: rgba(0 0 0 / 40%);
}
.marginBottom{margin-bottom: 60px;}

.bg-disabled {
    position: absolute;
    top:0px;
    width: 95%;
    height: 108%;
    background: rgb(221 221 221 / 80%);
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
}
.bg-disabled span {
    color:#a11a1a;
    font-weight: bold;
    font-size: 20px;
    text-shadow: 0px 0px 0px #555;width: 90%;
    margin: auto;
    text-align: center;
}
</style>
