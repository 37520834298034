<template>
    <div>
        <div class="page-breadcrumb">
            <div class="row">
                <div class="col-7 align-self-left"></div>
                <div class="col-5 align-self-center">
                    <div class="d-flex align-items-right justify-content-end">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link
                                        :to="{ name: 'Invoices' }"
                                        class="btn btn-info"
                                    >
                                        <i class="fas fa-arrow-left"></i> Back to Invoice
                                        List
                                    </router-link>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="container">
                    <div class="card-header text-center">
                        <h2 class="card-title">Invoice Statement</h2>
                    </div>
                    <div class="card-body bg-light">
                        <Loader v-if="isLoading"></Loader>
                        <div class="row" v-else>
                            <div class="col-12 text-center">
                                <button
                                    type="button"
                                    class="btn btn-secundary"
                                    style="margin-right: 5px; margin-left: 5px"
                                    @click="generarPdf(invo.idv)"
                                >
                                    <i class="fas fa-file-pdf"></i> Open PDF
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-secundary"
                                    style="margin-right: 5px; margin-left: 5px"
                                    @click="actionEmail(invo.idv, invo.numdoc)"
                                >
                                    <i class="far fa-envelope"></i> Send Email
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-secundary"
                                    style="margin-right: 5px; margin-left: 5px"
                                    @click="detailCustomerSMS('statement')"
                                    v-bind:class="[
                                        'btn btn-secundary sms',
                                        (PlanPremium) ? 'active' : 'inactive',
                                    ]"
                                >
                                    <i class="far fa-envelope"></i> Send SMS
                                </button>                                
                            </div>
                            <div class="col-2"></div>
                            <div class="col-8">
                                <div class="row">
                                    <div class="col-6">
                                        <table>
                                            <tr>
                                                <td>Invoice #</td>
                                                <td>:</td>
                                                <td
                                                    style="
                                                        color: #036;
                                                        font-size: 18px;
                                                        font-weight: bold;
                                                    "
                                                >
                                                    {{
                                                        invo.numdoc
                                                            .toString()
                                                            .padStart(4, "000")
                                                    }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Date</td>
                                                <td>:</td>
                                                <td>{{ invo.date }}</td>
                                            </tr>
                                            <tr>
                                                <td>Terms</td>
                                                <td>:</td>
                                                <td>{{ invo.terms }}</td>
                                            </tr>
                                            <tr>
                                                <td>Due Date</td>
                                                <td>:</td>
                                                <td>{{ invo.duedate }}</td>
                                            </tr>
                                            <tr>
                                                <td>Name/project</td>
                                                <td>:</td>
                                                <td>{{ invo.nameProject }}</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="col-6">
                                        <table align="right">
                                            <tr>
                                                <td>{{ invo.customer_name }}</td>
                                            </tr>
                                            <tr>
                                                <td>{{ invo.billing_address }}</td>
                                            </tr>
                                            <tr>
                                                <td>{{ invo.customer_address }}</td>
                                            </tr>
                                            <tr>
                                                <td>{{ invo.customer_phone }}</td>
                                            </tr>
                                            <tr>
                                                <td>{{ invo.email }}</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="col-12"><br /></div>
                                    <div class="col-4 contenedor-total">
                                        <div
                                            role="alert"
                                            class="alert alert-info alert_totales Amount"
                                        >
                                            <label>Amount:</label>
                                            <h1>
                                                <span class="badge badge-info"
                                                    >$
                                                    {{ invo.monto | formatMoney }}</span
                                                >
                                            </h1>
                                        </div>
                                    </div>
                                    <div class="col-4 contenedor-total">
                                        <div
                                            role="alert"
                                            class="alert alert-success alert_totales payment"
                                        >
                                            <label>Payment Received:</label>
                                            <h1>
                                                <span class="badge badge-success"
                                                    >$
                                                    {{
                                                        invo.payments | formatMoney
                                                    }}</span
                                                >
                                            </h1>
                                        </div>
                                    </div>
                                    <div class="col-4 contenedor-total">
                                        <div
                                            role="alert"
                                            class="alert alert-danger alert_totales balance"
                                        >
                                            <label>Balance due:</label>
                                            <h1>
                                                <span class="badge badge-danger"
                                                    >$
                                                    {{ invo.balance | formatMoney }}</span
                                                >
                                            </h1>
                                        </div>
                                    </div>
                                    <div class="col-12"><br /></div>
                                </div>
                                <div class="row">
                                    <h3 style="font-weight: bold">Payment History</h3>
                                    <table
                                        class="table table-striped table-bordered table-hover"
                                        id="payment_history"
                                    >
                                        <thead>
                                            <tr>
                                                <th width="60" style="text-align: center;"># Record</th>
                                                <th width="80" align="left">Date</th>
                                                <th width="123" align="left">Method</th>
                                                <th width="70" align="center">Ref #</th>
                                                <th width="70" style="text-align: center;">Status</th>
                                                <th width="80" style="text-align: right;">Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="(i, index) in payment_history"
                                                v-bind:key="index"
                                            >
                                                <td align="center">
                                                    {{
                                                        i.idx
                                                            .toString()
                                                            .padStart(4, "000")
                                                    }}
                                                </td>
                                                <td>{{ i.fecha }}</td>
                                                <td>{{ i.method }}</td>
                                                <td align="center">{{ (i.ref!=null && i.ref!='null') ? i.ref : '#'}}</td>
                                                <td align="center">
                                                    <span
                                                        v-if="i.status == 1"
                                                        class="badge badge-success"
                                                        >Accepted</span
                                                    >
                                                    <span
                                                        v-if="i.status == 0"
                                                        class="badge badge-danger"
                                                        style="background-color: #f70d0d"
                                                        >Void</span
                                                    >
                                                </td>
                                                <td align="right">
                                                    ${{ i.price | formatMoney }}
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td
                                                    height="24"
                                                    colspan="6"
                                                    style="
                                                        text-align: right;
                                                        font-weight: bold;
                                                        font-size: 16px;
                                                        margin-top: 4px;
                                                    "
                                                >
                                                    Payment Received: ${{
                                                        invo.payments | formatMoney
                                                    }}
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modalLateral>
            <component v-bind:is="m.component" :items="DataMailStatement"></component>
        </modalLateral> 
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader";
import modalEmail from "@/apps/billing/components/modalEmail";
import modalLateral from '@/core/plugins/themeModalLateral/modalLateral'
import { mapGetters } from "vuex";
export default {
    name: "Statement",
    components: {
        Loader,
        modalEmail,
        modalLateral,
    },
    data() {
        return {
            accountSMS: localStorage.getItem('accountSMS'),
            isLoading: false,
            payment_history: [],
            invo: {
                idv: "",
                numdoc: "",
                date: "",
                terms: "",
                duedate: "",
                nameProject: "",
                customer_name: "",
                billing_address: "",
                customer_address: "",
                customer_phone: "",
                email: "",
                monto: 0,
                payments: 0,
                balance: 0,
            },
            DataMailStatement: {
                isLoading: false,
                idinv: null,
                IDitem: null, // numdoc
                email: null,
                EmailCheck: false,
                view: "statement",
            },
            SettingBox: "",
            emailsS: [],
            m: {
                show: false,
                component: '',
                data: []
            },
            PlanPremium : (window.OrgPlan > 1),
        };
    },
    mounted() {},
    created() {
        this.PlanPremium = (this.$store.getters['core/Org/getPlanId'] > 1);
        if (this.$route.params.idinv) {
            this.Statement();
        }
    },
    methods: {
        detailCustomerSMS(type) {
            let id = this.$route.params.idinv
            let customer_id = this.$route.params.idcus
            let idNumdoc = this.invo.numdoc
            if(!this.PlanPremium){
                this.$store.commit('core/session/setShowPopPremium', {show: true, data: []});
            }
            else {
                this.$store.commit("core/setNotiPush", {
                    group: "notiPush",
                    type: '4', // 1=success | 2=info | 3=error | 4=loading | 5=warning
                    title: "Sending sms",
                    text: "Loading...",
                    show: true,
                    duration: 500
                });                  
                let obj = {id: id, idNumdoc: idNumdoc, icCustomer: customer_id}
                window.billing
                    .post("shellpdf/" + customer_id, {
                        trans: 0,
                        pag: this.paginador,            
                        user_id: window.localuserdata, 
                        org_id: window.localorgdata,           
                        oauth_m: window.localStorage.getItem("oauth_m"),
                        AccountID: window.localAccountID,
                        app_name: window.localStorage.getItem('nameApp'),
                        app_id: window.localStorage.getItem('appIDactual'),
                        id:id,
                        type:type,
                        parameters:JSON.stringify(obj),            
                    })
                    .then((response) => {
                        let r = response.data.status; 
                        if(r == false){
                            this.$notify({
                                group: "noti",
                                title: "Error",
                                type: "error",
                                text: "You must add mobile phone",
                            });
                        } else {                
                            this.$notify({
                                group: "noti",
                                title: "Send PDF by SMS",
                                type: "success",
                                text: "Send PDF by SMS Successful",
                            });    
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    });
            }
        },        
        sendMailStatement(item) {
            if (item.DataItem.send == 1) {
                item.DataItem.send = 0;
                this.$store.commit("core/setCloseModal", {
                    action: 'sendMailStatement',                     
                });
                this.SettingBox = "";
                this.$notify({
                    group: "noti",
                    title: "Success",
                    type: "success",
                    text: "Send Mail!",
                });
                this.$store.commit("ModApps/billing/SetResponseMail", null);
            }
        },
        Statement() {
            this.isLoading = true;
            window.billing
                .get("statement/" + this.$route.params.idinv)
                .then((response) => {
                    console.log(response.data.result);
                    this.invo.idv = response.data.result.idv;
                    this.invo.numdoc = response.data.result.numdoc;
                    this.invo.terms = response.data.result.terms;
                    this.invo.nameProject = response.data.result.nameProject;
                    this.invo.customer_name = response.data.result.customer_name;
                    this.invo.billing_address = response.data.result.billing_address;
                    this.invo.customer_address = response.data.result.customer_address;
                    this.invo.customer_phone = response.data.result.customer_phone;
                    this.invo.email = response.data.result.email;
                    this.invo.monto = response.data.result.monto;
                    this.invo.payments = response.data.result.payments;
                    this.invo.balance = response.data.result.balance;
                    let f = response.data.result.date;
                    let fecha = new Date(f).toDateString();
                    let fecha_new = fecha.split(" ");
                    this.invo.date =
                        fecha_new[1] + " " + fecha_new[2] + ", " + fecha_new[3];
                    let f2 = response.data.result.duedate;
                    let fecha2 = new Date(f2).toDateString();
                    let fecha_new2 = fecha2.split(" ");
                    this.invo.duedate =
                        fecha_new2[1] + " " + fecha_new2[2] + ", " + fecha_new2[3];
                    this.payment_history = response.data.paymentsList;
                    let e = this.payment_history;
                    for (var i = 0; i < e.length; i++) {
                        let fecha = new Date(e[i].fecha).toDateString();
                        let fecha_new = fecha.split(" ");
                        this.payment_history[i].fecha =
                            fecha_new[1] + " " + fecha_new[2] + ", " + fecha_new[3];
                        if (e[i].memo == "null") {
                            this.payment_history[i].memo = "";
                        }
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    this.isLoading = false;
                    console.log(error);
                });
        },
        generarPdf(id) { 
            window.open(window.urlbase + "api/v2/statement/pdf/" + id, "_blank");
        },
        actionEmail(id, numdoc) {
            this.DataMailStatement.idinv = id;
            this.DataMailStatement.IDitem = numdoc;
            this.DataMailStatement.email = this.invo.email;
            let t = this;
            this.m = {
                show: true,
                component: 'modalEmail',
                data: {DataMail:t.DataMailStatement}
            }             
            this.$store.commit('core/setOpenModal', this.m);   
        },
    },
    watch: {
        getResponseMail: function () {
            console.log("getResponseMail", this.getResponseMail);
            if (this.getResponseMail && this.getResponseMail.fEventBus === "statement-email") {
                this.sendMailStatement(this.getResponseMail);
            }
        },
		accitonPusher: function () {
			if (this.accitonPusher != undefined) {
				let p = this.accitonPusher;
                if (p.action == "serviceSMS") {
                    this.accountSMS = p.accountSMS
                    localStorage.setItem('accountSMS', p.accountSMS);
                    this.accountSMS = localStorage.getItem('accountSMS');
				}
			}
		},
        getPlanId(){
            this.PlanPremium = (this.$store.getters['core/Org/getPlanId'] > 1);
            console.log('watch PlanPremium', this.PlanPremium);
        },    
    },
    computed: {
        ...mapGetters("ModApps/billing", { getResponseMail: "getResponseMail" }),
        ...mapGetters("core/Org", { getPlanId: "getPlanId"}),
        ...mapGetters("core", {
            accitonPusher: "get_accitonPusher",
        }),        
    },
};
</script>
<style lang="css" scoped>
.alert.alert-info.alert_totales.Amount,
.alert.alert-success.alert_totales.payment,
.alert.alert-danger.alert_totales.balance {
    width: 100%;
}
#payment_history thead tr th {
    font-weight: bold;
}
.sms.inactive i {
    color: #000;
}
.sms.inactive {
    color: #c9caca;
}
</style>
