//billing
import Billing from '@/apps/billing/Layouts/BillingLayout';
/*rutas de billing*/
import dash from '@/apps/billing/router/paths_billing_dash.js' 
import customer from '@/apps/billing/router/paths_billing_customer.js' 
import invoice from '@/apps/billing/router/paths_billing_invoice.js' 
import statement from '@/apps/billing/router/paths_billing_statement.js' 
import estimate from '@/apps/billing/router/paths_billing_estimate.js' 
import payments from '@/apps/billing/router/paths_billing_payments.js' 
import recurring from '@/apps/billing/router/paths_billing_recurring.js' 
import banking from '@/apps/billing/router/paths_billing_banking.js' 
import employees from '@/apps/billing/router/paths_billing_employees.js' 
import expenses from '@/apps/billing/router/paths_billing_expenses.js' 
import vendors from '@/apps/billing/router/paths_billing_vendors.js' 
import writeraCheck from '@/apps/billing/router/paths_billing_writeraCheck.js' 
import reports from '@/apps/billing/router/paths_billing_reports.js' 
import setting from '@/apps/billing/router/paths_billing_setting.js' 
import restore from '@/apps/billing/router/paths_billing_restore.js' 
import timesheet from '@/apps/billing/router/paths_billing_timesheet.js' 
import squarePay from '@/apps/billing/router/paths_billing_squarePay.js' 
import smart_office from '@/apps/billing/router/paths_billing_smart_office.js' 

let rutas_billing=dash;
rutas_billing=rutas_billing.concat(customer);
rutas_billing=rutas_billing.concat(invoice);
rutas_billing=rutas_billing.concat(statement);
rutas_billing=rutas_billing.concat(estimate);
rutas_billing=rutas_billing.concat(payments);
rutas_billing=rutas_billing.concat(recurring);
rutas_billing=rutas_billing.concat(banking);
rutas_billing=rutas_billing.concat(employees);
rutas_billing=rutas_billing.concat(expenses);
rutas_billing=rutas_billing.concat(vendors);
rutas_billing=rutas_billing.concat(writeraCheck);
rutas_billing=rutas_billing.concat(reports);
rutas_billing=rutas_billing.concat(setting);
rutas_billing=rutas_billing.concat(timesheet);
rutas_billing=rutas_billing.concat(smart_office);

let returnBilling = [
	{
    	path: '/billing',
    	folder: Billing,
    	meta: { requiresAuth: true },
    	children: rutas_billing
    }
]
returnBilling=returnBilling.concat(restore);
returnBilling=returnBilling.concat(squarePay);
export default returnBilling;
