<template>
	<div class="content-squareUp">
		<div class="card-header">
			<svg width="14" height="14" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path d="M7.526 7.866c-.026.08-.026.179-.026.376v3.516c0 .197 0 .296.026.376a.53.53 0 0 0 .34.34c.08.026.179.026.376.026h3.516c.197 0 .296 0 .376-.026a.53.53 0 0 0 .34-.34c.026-.08.026-.179.026-.376V8.242c0-.197 0-.296-.026-.376a.53.53 0 0 0-.34-.34c-.08-.026-.179-.026-.376-.026H8.242c-.197 0-.296 0-.376.026a.53.53 0 0 0-.34.34z" fill="#373F4A"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M0 4c0-.62 0-.929.041-1.188A3.333 3.333 0 0 1 2.811.04C3.072 0 3.382 0 4 0h12c.62 0 .929 0 1.188.041a3.333 3.333 0 0 1 2.771 2.77C20 3.072 20 3.382 20 4v12c0 .62 0 .929-.041 1.188a3.333 3.333 0 0 1-2.77 2.771C16.928 20 16.618 20 16 20H4c-.62 0-.929 0-1.188-.041a3.333 3.333 0 0 1-2.771-2.77C0 16.928 0 16.618 0 16V4zm4 .947C4 4.424 4.424 4 4.947 4h10.106c.523 0 .947.424.947.947v10.106a.947.947 0 0 1-.947.947H4.947A.947.947 0 0 1 4 15.053V4.947z" fill="#373F4A"></path></svg> 
			SquareUp Payment
			<span
				style="
				color: #fff;
				background-color: #4caf50;
				padding: 3px;
				border-radius: 3px;
				"
			>
				INV #{{ data.numdoc }}
			</span>
		</div>
		<div class="card-body" style="padding-top:10px; padding-left: 0px; padding-right: 0px;">
			<div>
			<h3>
				<span style="padding-left: 5px; font-size: 20px">
				<b>Customer: </b>
					{{ data.cname }} {{ data.clname }} 
					<span v-if="data.cbname!=null">- {{ data.cbname }}</span>
				</span>
			</h3>
			</div>
			<div style="display: flex; color: #fff; margin-bottom: 10px">
				<div
					style="
					background-color: #87c37b;
					width: 50%;
					text-align: right;
					padding: 10px;
					"
				>
					Total:
					<span style="font-size: 19px">
						${{ data.total | formatMoney }}
					</span>
				</div>
				<div
					style="
					background-color: #737373;
					width: 50%;
					padding: 10px;
					"
				>
					Balance:
					<span style="font-size: 19px">
						${{ data.balanceText | formatMoney }}
					</span>
				</div>
			</div>
			<form id="form1" @submit.prevent="savePayment" class="justify-content-center"  style="padding-left: 10px; padding-right: 10px;">
				<div v-if="isAccountEmpty">
					<div class="alert alert-warning">
						There is no registered account. <strong style="cursor:pointer;" @click="goSetSquareUp">Click here</strong> to register.
					</div>
				</div>
				<div class="form-group" v-if="isAmount">
					<label class="amount">AMOUNT</label>
					<div class="input-group">
						<div class="input-group-prepend">
							<span class="input-group-text">$</span>
						</div>
						<input
							type="text"
							class="form-control"
							placeholder="0.00"
							required
							v-model="amount"
							@keyup="validP"
						/>
						<span
							class="error"
							style="color: red"
							v-if="vatError"
						>{{ vatErrorMsg }}</span
						>
					</div>
				</div>
				<iframe :src="rutaSquare" frameborder="0" class="squareUp" v-if="isShowform"></iframe>
			</form>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
	name: 'formSquarepay',
	data () {
		return {
			rutaSquare: '',
			amount: 0,
			isShowform: false,
			data: {},
			vatError: false,
			vatErrorMsg: null,
			isAmount: true,
			isAccountEmpty: false,
		}
	},
	created() {
		let g = this.$store.getters['core/getOpenModal'];
		this.data = g.data;
		this.amount=this.data.balance;
		this.getSquareAccount();
	},
	methods: {
		goSetSquareUp() {
			this.$router.push({ name: 'squareAccount'})
		},
		getSquareAccount() {
			this.$store.commit('core/setLoadingModal', true);
			let t = this;
			window.masterApiv2.post('getSquareUpAccount', {
				user_id: t.data.user_id,
				org_id: t.data.org_id,
			}).then((response) => {
				if(response.data.status) {
					this.isAccountEmpty= false;
					this.isAmount= true;
					this.validP();
					this.init();
				} else {
					this.isAccountEmpty= true;
					this.isAmount= false;
					this.isShowform = false;
					console.log('no hay datos de square Up');
				}
				this.$store.commit('core/setLoadingModal', false);
			}).catch((error) => {
				this.$store.commit('core/setLoadingModal', false);
				console.log(error);
			});
		},
		init() {
			// obteniendo datos
			let g = this.$store.getters['core/getOpenModal'];
			let d = '';
			d+=this.amount + '/';
			d+=localStorage.getItem('oauth_m') + '/';
			d+=g.data.user_id + '/';
			d+=g.data.org_id + '/';
			d+='0/';
			d+=g.data.customer_id + '/';
			d+=g.data.invoice_id;
			this.rutaSquare= window.urlmaster+ "api/v2/createSquarePay/" + d;
			// console.log('iframe', this.rutaSquare);
		},
		savePayment() {
			console.log('send form false');
		},
		showForm() {
			this.isShowform = !this.isShowform;
		},                   
        validP() {
            let vatIdRegex = new RegExp(/^\d+(\.\d{0,2})?$/);
            //let vatIdRegex =  new RegExp(/^\d*(\.\d{1})?\d{0,1}$/)
            if (
                !vatIdRegex.test(
                    parseFloat(
                        parseFloat(parseFloat(this.amount).toFixed(2))
                    ).toFixed(2)
                ) &&
                event.key != "."
            ) {
                this.vatError = true;
                this.vatErrorMsg =
                    "Enter the valid number, with a maximum of two decimal places.";
                this.isShowform = false;
            } else if (parseFloat(parseFloat(this.amount).toFixed(2)) == 0) {
                this.vatError = true;
                this.vatErrorMsg = "Must be greater than zero.";
                this.isShowform = false;
            } else if (
                this.data.total != undefined &&
                parseFloat(parseFloat(this.amount).toFixed(2)) >
                    parseFloat(parseFloat(this.data.total).toFixed(2))
            ) {
                this.vatError = true;
                this.vatErrorMsg = "Amount cannot be greater than the balance.";
                this.isShowform = false;
            } else {
                this.vatError = false;
                this.vatErrorMsg = "";
                this.isShowform = true;
                this.init();
            }
        },
    },
    watch: {
		getOpenModal: function () {
			if (this.getOpenModal.savedFormExterno) {
				this.isAmount = false;
				this.data.balanceText -= this.amount;
			}
		},
		accitonPusher: function () {
			if (this.accitonPusher != undefined) {
				let p = this.accitonPusher;
				console.log('accitonPusher', p);
				if (p.action && p.action == 'close-squarepay' && p.oauth === window.oauth_m) {
					this.isAmount = false;
					this.data.balanceText -= this.amount;
				}
			}
		}
	},
	computed: {
		...mapGetters("core", {
			getOpenModal: "getOpenModal",
			accitonPusher: "get_accitonPusher",
		}),
	}
};
</script>
<style lang="css" scoped>
.content-squareUp{

}
.content-squareUp #form1{	
	margin: auto;
	display: block;
	max-width: 430px;
}
.form-group{ margin-bottom: 0px; }

.squareUp {
	width: 100%;
	height: 360px;
}
.amount {
	color: #000000;
	font-size: 14px;
	font-family: "Helvetica Neue", "Helvetica", sans-serif;
	font-weight: 500;
	letter-spacing: 0.5px;
}
.form-control {
	padding: 16px;
    font-size: 16px;
}
@media(max-width: 767px) {
	.squareUp {
		max-width: 100%;
	}
}
</style>
