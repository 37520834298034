<template>
    <div>
        <div
            class="messageBox"
            v-show="start_estimate"
            v-bind:class="{ show: start_estimate }"
        >
            <div class="box">
                <div class="conte">
                    <i class="fas fa-exclamation-triangle"></i>
                    <p>
                        You want to change the start of the numeration of your Estimates?.
                        Do it now or click Cancel.
                    </p>
                    <button
                        class="btn btn-success"
                        style="margin-right: 5px"
                        @click="activateStartUpdate"
                    >
                        Update Start Number
                    </button>
                    <button class="btn btn-primary" @click="activateStartEstimate">
                        Cancel
                    </button>
                    <Loader v-show="isLoadingstart"></Loader>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <form @submit.prevent="saveEstimates">
                <div class="card">
                    <div class="alert alert-danger" role="alert" v-if="errors.length">
                        <ul style="margin-bottom: 0">
                            <li v-for="error in errors" :key="error">{{ error }}</li>
                        </ul>
                    </div>
                    <div class="card-header">
                        <div class="row">
                            <div class="col-6 align-self-center">
                                <h3 class="page-title">
                                    {{ formEstimates.titlePage }}
                                    <span v-if="formEstimates.numdoc != null"
                                        ># {{ formEstimates.numdoc }}
                                        <button 
                                            @click="actionNotes(formEstimates.idEst)"                                                    
                                            type="button" 
                                            title="Edit Notes"                                                        
                                            class="btn btn-secundary"
                                            style="padding: 0px 3px; font-size: 10px; height: 16px;">
                                            <i class="far fa-sticky-note"></i>
                                            Edit Note
                                        </button>        
                                    </span>
                                </h3>
                            </div>
                            <div class="col-6 text-right">
                                <button type="submit" class="btn btn-success" :disabled="saveDisabled">
                                    <i class="fas fa-save"></i> SAVE
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-secundary"
                                    style="margin-right: 5px; margin-left: 5px"
                                    @click="$router.push({ name: 'Estimates' })"
                                >
                                    <i class="fas fa-times"></i> CANCEL
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-danger"
                                    @click="MetDeleteEstimate(formEstimates.idEst)"
                                    v-if="formEstimates.actionNname == 'update'"
                                >
                                    <i class="fas fa-trash-alt"></i> DELETE
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="bgloading" v-show="isLoading"><Loader></Loader></div>
                        <div class="row">
                            <div class="col-12">
                                <div class="row">
                                    <div
                                        class="col-md-12 col-xl-10 offset-xl-1 col-lg-12"
                                    >
                                        <div class="row">
                                            <div
                                                style="
                                                    background-color: #f1f1f1;
                                                    padding: 8px;
                                                    display: flex;
                                                    margin-bottom: 12px;
                                                "
                                                class="col-12"
                                            >
                                                <div class="col-2">
                                                    <label for="inputEmail4"
                                                        >Status</label
                                                    >
                                                    <select
                                                        name=""
                                                        id=""
                                                        class="form-control"
                                                        v-model="formEstimates.status"
                                                    >
                                                        <option :value="1">
                                                            Pending
                                                        </option>
                                                        <option :value="2">
                                                            Accepted
                                                        </option>
                                                        <option :value="3">
                                                            Rejected
                                                        </option>
                                                        <option :value="4">Void</option>
                                                    </select>
                                                </div>
                                                <div class="col-6">
                                                    <label for="inputEmail4"
                                                        >Accepted By</label
                                                    >
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        v-model="
                                                            formEstimates.accepted_by
                                                        "
                                                    />
                                                </div>
                                                <div class="col-4">
                                                    <label for="inputEmail4"
                                                        >Accepted Date</label
                                                    >
                                                    <datetime
                                                        v-model="
                                                            formEstimates.accepted_date
                                                        "
                                                        input-class="form-control"
                                                        value-zone="America/New_York"
                                                    ></datetime>
                                                </div>
                                            </div>
                                            <div class="col-5">
                                                <label
                                                    for="inputEmail4"
                                                    style="
                                                        position: relative;
                                                        width: 100%;
                                                        display: block;
                                                    "
                                                    >Customer
                                                    <span
                                                        style="
                                                            position: absolute;
                                                            right: 0;
                                                            top: 3px;
                                                        "
                                                    >
                                                        <button
                                                            type="button"
                                                            @click="NewCustomer"
                                                            class="btnlink"
                                                        >
                                                            <i class="fas fa-plus"></i>
                                                            New Customer
                                                        </button>
                                                    </span>
                                                </label>
                                                <div
                                                    class="form-group"
                                                    id="CustomersList"
                                                >
                                                    <model-select
                                                        :options="CustomersList"
                                                        v-model="ValuesCustomers"
                                                        placeholder="Choose a Customer"
                                                    >
                                                    </model-select>
                                                </div>
                                            </div>
                                            <div class="col-5">
                                                <label for="inputEmail4"
                                                    >Estimate Name (Optional)
                                                </label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    maxlength="50"
                                                    v-model="
                                                        formEstimates.estimates_optional
                                                    "
                                                />
                                            </div>
                                            <div class="col-5">
                                                <label for="inputEmail4">Bill To</label>
                                                <div class="form-group bill_to">
                                                    <div>
                                                        <span>
                                                            {{ ValuesCustomers.customer_name }}
                                                        </span>
                                                        <span>
                                                            {{ ValuesCustomers.customer_lastname
                                                    }}
                                                        </span>
                                                    </div>
                                                    <div v-if="ValuesCustomers.bussinesname!=null && ValuesCustomers.bussinesname!='' && ValuesCustomers.bussinesname!='null'">
                                                        {{ ValuesCustomers.bussinesname }}
                                                    </div>
                                                    <div 
                                                        v-if="ValuesCustomers.billing_address!='' && ValuesCustomers.billing_address!=null"
                                                    >
                                                        {{ValuesCustomers.billing_address}}
                                                    </div>
                                                    <div
                                                        v-if="ValuesCustomers.city!=null ||
                                                        ValuesCustomers.state!=null ||
                                                        ValuesCustomers.code!=null 
                                                        "
                                                    >
                                                        <span v-if="ValuesCustomers.city!=null" style="margin-right:5px;">{{ValuesCustomers.city}}</span>
                                                        <span v-if="ValuesCustomers.state!=null" style="margin-right:5px;">{{ ValuesCustomers.state }}</span>
                                                        <span v-if="ValuesCustomers.code!=null" style="margin-right:5px;">{{ ValuesCustomers.code }}</span>
                                                    </div>
                                                    <div>
                                                        <span v-if="ValuesCustomers.customer_phone!=null">
                                                            {{ ValuesCustomers.customer_phone }}
                                                        </span>
                                                        <span v-if="ValuesCustomers.customer_officephone!=null">
                                                            <span v-if="ValuesCustomers.customer_phone!=null"> / </span>
                                                            {{ ValuesCustomers.customer_officephone }}
                                                        </span>                  
                                                    </div>
                                                    <div v-if="ValuesCustomers.email!=null">
                                                        {{ ValuesCustomers.email }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-5">
                                                <label
                                                    for="inputEmail4"
                                                    v-if="jobinfomationcheck"
                                                    >{{ jobinfomationtitle }}</label
                                                >
                                                <textarea
                                                    name=""
                                                    id="job_information"
                                                    cols="30"
                                                    rows="2"
                                                    class="form-control"
                                                    v-model="
                                                        formEstimates.job_information
                                                    "
                                                    v-if="jobinfomationcheck"
                                                ></textarea>
                                            </div>
                                            <div class="col-2">
                                                <label for="inputEmail4">Date</label>
                                                <datetime
                                                    type="date"
                                                    v-model="inputFecha"
                                                    input-class="form-control"
                                                    value-zone="America/New_York"
                                                ></datetime>
                                            </div>
                                            <tableProductos></tableProductos>
                                            <div
                                                class="blockMont"
                                                style="
                                                    display: flex;
                                                    justify-content: space-between;
                                                    width: 100%;
                                                "
                                            >
                                                <div style="width: 50%">
                                                    <textarea
                                                        name=""
                                                        id=""
                                                        cols="30"
                                                        rows="4"
                                                        class="form-control"
                                                        v-model="
                                                            formEstimates.customer_message
                                                        "
                                                        placeholder="Customer message"
                                                    ></textarea>
                                                </div>
                                                <div style="width: 50%">
                                                    <tableResumenTotales :form="formEstimates"></tableResumenTotales>
                                                </div>
                                            </div>
                                            <div class="pageSignature">
                                                <div
                                                    class="form-inline"
                                                    style="border-bottom: 1px solid #ccc"
                                                >
                                                    <div class="my-1 mr-2">
                                                        Need a Contract?
                                                    </div>
                                                    <div
                                                        class="custom-control custom-checkbox my-1 mr-sm-2"
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            class="custom-control-input"
                                                            id="customControlInline"
                                                            v-model="
                                                                formEstimates.checked
                                                            "
                                                        />
                                                        <label
                                                            class="custom-control-label"
                                                            for="customControlInline"
                                                            >Yes</label
                                                        >
                                                    </div>
                                                    <div
                                                        class="form-group"
                                                        v-if="formEstimates.checked"
                                                        style="
                                                            margin-right: 5px;
                                                            margin-left: 15px;
                                                        "
                                                    >
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            style="margin-bottom:0px; "
                                                            v-model="
                                                                selectCTemplate.title
                                                            "
                                                            placeholder="Terms & Conditions"
                                                        />
                                                    </div>
                                                    <div
                                                        class="form-group"
                                                        v-if="formEstimates.checked"
                                                    >
                                                        <select
                                                            class="form-control my-1 mr-2"
                                                            id="exampleFormControlSelect1"
                                                            style="
                                                                width: 300px;
                                                                margin-top: 10px;
                                                            "
                                                            v-model="selectCTemplate"
                                                        >
                                                            <option :value="{title:'', description:''}" selected>
                                                                Select template
                                                            </option>
                                                            <option
                                                                v-for="i in ContractTemLists"
                                                                :key="i.id"
                                                                :value="i"
                                                            >
                                                                {{ i.title }}
                                                                <span
                                                                    v-if="
                                                                        i.alias !== null
                                                                    "
                                                                    >- {{ i.alias }}</span
                                                                >
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div class="my-1 mr-2" v-if="formEstimates.checked && PlanPremium">
                                                        Request Digital Signature?
                                                    </div>
                                                    <div
                                                        class="custom-control custom-checkbox my-1 mr-sm-2"
                                                        v-if="formEstimates.checked && PlanPremium"
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            class="custom-control-input"
                                                            id="signe"
                                                            v-model="
                                                                formEstimates.signe
                                                            "
                                                        />
                                                        <label
                                                            class="custom-control-label"
                                                            for="signe"
                                                            >Yes</label
                                                        >
                                                    </div>
                                                </div>
                                                <vue-editor
                                                    v-model="selectCTemplate.description"
                                                    v-if="formEstimates.checked"
                                                />
                                                <h5
                                                    style="padding-top: 20px"
                                                    v-if="formEstimates.checked"
                                                >
                                                    Signature:
                                                </h5>
                                                <div
                                                    class="row"
                                                    v-if="formEstimates.checked"
                                                >
                                                    <div class="col">
                                                        <div class="form-group row">
                                                            <label
                                                                for=""
                                                                class="col-sm-2 col-form-label"
                                                                >Customer</label
                                                            >
                                                            <div class="col-sm-10">
                                                                <input
                                                                    type="text"
                                                                    class="form-control"
                                                                    id=""
                                                                    v-model="
                                                                        ValuesCustomers.text
                                                                    "
                                                                />
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label
                                                                for=""
                                                                class="col-sm-2 col-form-label"
                                                                >Date</label
                                                            >
                                                            <div class="col-sm-10">
                                                                <datetime
                                                                    type="date"
                                                                    v-model="
                                                                        FormSignature.dateCustomer
                                                                    "
                                                                    value-zone="America/New_York"
                                                                    input-class="form-control"
                                                                ></datetime>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="form-group row">
                                                            <label
                                                                for=""
                                                                class="col-sm-2 col-form-label"
                                                                >Company</label
                                                            >
                                                            <div class="col-sm-10">
                                                                <input
                                                                    type="text"
                                                                    class="form-control"
                                                                    v-model="
                                                                        FormSignature.nameCompany
                                                                    "
                                                                />
                                                            </div>
                                                        </div>
                                                        <div class="form-group row">
                                                            <label
                                                                for=""
                                                                class="col-sm-2 col-form-label"
                                                                >Date</label
                                                            >
                                                            <div class="col-sm-10">
                                                                <datetime
                                                                    type="date"
                                                                    v-model="
                                                                        FormSignature.dateCompany
                                                                    "
                                                                    value-zone="America/New_York"
                                                                    input-class="form-control"
                                                                ></datetime>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                style="
                                                    width: 100%;
                                                    text-align: right;
                                                    margin-top: 20px;
                                                "
                                            >
                                                <button
                                                    type="submit"
                                                    class="btn btn-success"
                                                    :disabled="saveDisabled"
                                                >
                                                    <i class="fas fa-save"></i> SAVE
                                                </button>
                                                <button
                                                    type="button"
                                                    class="btn btn-secundary"
                                                    style="
                                                        margin-right: 10px;
                                                        margin-left: 10px;
                                                    "
                                                    @click="
                                                        $router.push({
                                                            name: 'Estimates',
                                                        })
                                                    "
                                                >
                                                    <i class="fas fa-times"></i> CANCEL
                                                </button>
                                                <button
                                                    type="button"
                                                    class="btn btn-danger"
                                                    @click="
                                                        MetDeleteEstimate(
                                                            formEstimates.idEst
                                                        )
                                                    "
                                                    v-if="
                                                        formEstimates.actionNname ==
                                                        'update'
                                                    "
                                                >
                                                    <i class="fas fa-trash-alt"></i>
                                                    DELETE
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <!-- modal -->
        <modalLateral v-if="modalBill.indexOf(m.component)> -1">
            <component v-bind:is="m.component"></component>
        </modalLateral>         
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Loader from '@/core/components/shared/Loader'
import ClickOutside from 'vue-click-outside'
import draggable from 'vuedraggable'
import { VueEditor } from 'vue2-editor'
import { ModelSelect } from 'vue-search-select'
import StartEstimate from '@/apps/billing/components/setting/StartEstimate'
// import Taxes from '@/apps/billing/components/setting/Taxes'
import modalLateral from '@/core/plugins/themeModalLateral/modalLateral'
import NotesAdmin from '@/apps/billing/components/NotesAdmin'
import formCustomer from '@/apps/billing/components/formCustomer'
import formProducts from '@/apps/billing/components/formProducts'
import tableProductos from '@/apps/billing/components/tableProductos'
import tableResumenTotales from '@/apps/billing/components/tableResumenTotales'
export default {
    name: "EstiatesNew",
    display: "Table",
    order: 8,
    components: {
        Loader,
        StartEstimate,
        ModelSelect,
        // Taxes,
        draggable,
        VueEditor,
        modalLateral,
        NotesAdmin,
        formCustomer,
        formProducts,
        tableProductos,
        tableResumenTotales,
    },
    data () {
        return {
           m: {
                show: false,
                component: '',
                data: []
            },             
            isLoading: true,
            isLoadingstart: false,
            errors: [],
            showModal: false,
            start_estimate: false,
            ProdServiceLists: [],
            CustomersList: [],
            TaxesLists: [],
            SettingBox: "",
            formList: {
                items: []
            },
            ValuesCustomers: {
                text: null,
                value: null,
                billing_address: null,
                bussinesname: null,
                customer_address: null,
                customer_name: null,
                customer_phone: null,
                customer_officephone: null,
                email: null,
            },
            formcustomer: {
                CustomerId: null,
                firstname: null,
                email: null,
                address: null,
                state: null,
                lastname: null,
                phone: null,
                city: null,
                zip: null,
                error: 0,
            },
            formEstimates: {
                idEst: null,
                titlePage: "Add Estimate",
                btnName: "SAVE",
                actionNname: "create", // update
                customer_id: null,
                numdoc: null,
                customer_name: null,
                customer_email: null,
                customer_address: null,
                billing_address: null,
                estimates_optional: null,
                status: 1,
                accepted_by: null,
                accepted_date: null,
                job_information: null,
                customer_message: null,
                date_created: new Date(
                    (new Date(Date.now() - ((new Date()).getTimezoneOffset() * 60000))).toISOString().slice(0, 10) + "T23:31:04.000Z"
                ).toISOString(),
                subtotal: 0,
                discount_type: 1,
                discount_mount: 0.00,
                discount_total: 0.00,
                discount_name: '',
                total: 0,
                totaltax: null,
                qty: null,
                checked: false,
                signe: false,
                dataItems: [],
            },
            HideSuggestionProd: false,
            TypeofAccount: [],
            FormAddPS: {
                vatError: false,
                vatErrorMsg: null,
                index_modal: null,
                btnName: "SAVE",
                Action: "Add",
                id: null,
                productname: null,
                account_id: 0,
                quantity: 1,
                unitcost: 0,
                description: null,
                applytax: 0,
                status: 0,
            },
            ContractTemLists: [],
            selectCTemplate: {
                title: "",
                description: "",
            },
            FormSignature: {
                //nameCustomer: null,
                dateCustomer: new Date(
                    (new Date(Date.now() - ((new Date()).getTimezoneOffset() * 60000))).toISOString().slice(0, 10) + "T23:31:04.000Z"
                ).toISOString(),
                dateCompany: new Date(
                    (new Date(Date.now() - ((new Date()).getTimezoneOffset() * 60000))).toISOString().slice(0, 10) + "T23:31:04.000Z"
                ).toISOString(),
                nameCompany: localStorage.getItem("nomCompany"),
            },
            taxesResumen: [],
            inputFecha: new Date(
                    (new Date(Date.now() - ((new Date()).getTimezoneOffset() * 60000))).toISOString().slice(0, 10) + "T23:31:04.000Z"
                ).toISOString(),
            jobinfomationcheck: true,
            jobinfomationtitle: "Job information",
            jobinfomationtext: null,
            dateCustomer: new Date().toISOString().slice(0, 10),
            dateCompany: new Date().toISOString().slice(0, 10),
            accepted_date: new Date().toISOString().slice(0, 10),
            ProdServiceisLoading: false,
            DiscountDropd: false,
            modalBill: [
                'NotesAdmin',
                'formCustomer',
                'formProducts',
                'StartEstimate',
            ],
            PlanPremium : (window.OrgPlan > 1),
            saveDisabled: false,
            statusLoadName:[
                ['has not loaded'],
                ['loading'],
                ['success'],
                ['faild'],
                ['error'],
                ['is already configured'],
            ],
            stateLoadDOM: [
                {
                    name: 'getEstimateSetting',
                    status: 0,
                },
                {
                    name: 'metstartEstimate',
                    status: 0,
                },
                {
                    name: 'getCustomer',
                    status: 0,
                },
                {
                    name: 'getTaxes',
                    status: 0,
                },
                {
                    name: 'getProdservices',
                    status: 0,
                },
                {
                    name: 'EstimateById',
                    status: 0,
                },
                {
                    name: 'getAccountTypes',
                    status: 0,
                },
                {
                    name: 'getCTemplates',
                    status: 0,
                },
            ],
            freePlan: false,
        };
    },
    beforeMount () {},
    created () {
        this.PlanPremium = (this.$store.getters['core/Org/getPlanId'] > 1);
        this.freePlan = (this.$store.getters['core/Org/getPlanId'] == 4);
        if(this.PlanPremium){
            //this.$router.push({ name: "estpro" });
        }
        this.getEstimateSetting();
        this.metstartEstimate();
        this.getCustomer();
        this.getTaxes();
        this.getAccountTypes();
        this.getCTemplates();
    },
    directives: {
        ClickOutside,
    },
    methods: { 
        statusLoad(id, status){
            this.stateLoadDOM[id].status = status;
            console.log('stateLoadDOM', this.stateLoadDOM[id].name, 'Status='+this.statusLoadName[status][0], new Date().getTime());
            if(status == 3 || status == 4){
                let msj = 'Property '+this.stateLoadDOM[id].name+' '+this.statusLoadName[status][0];
                /*this.$notify({
                    group: "noti",
                    type: "error",
                    title: "Error load",
                    text: msj
                });
                setTimeout(function(){
                    window.location.reload(true);
                }, 5000);*/
            }
        },             
        msjAlert(msj){
            this.$notify({
                group: "noti",
                title: "Error",
                type: "error",
                text: msj
            });
        },    
        formatUSD(amount) {    
            let vatIdRegex = new RegExp(/^[0-9.,-]+$/); 
            let value = ''     
            let val = 0.0;
            if (vatIdRegex.test(amount)) {
                let ops = {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                };
                let numberFormat2 = new Intl.NumberFormat('en-US', ops);  
                value = numberFormat2.format(amount)                
                // console.log('numberFormat2', numberFormat2.format(amount)); // amount
                if (value.indexOf(".") > -1) {
                    val = value;
                } else {
                    val = value + ".00";
                }
            }              
            return val
        },
        actionNotes(id){
            let t = this;
            this.m = {
                show: true,
                component: 'NotesAdmin',
                data: {
                    id:id, 
                    type:'Estimate',
                    // edit: edit,
                    transac_type:'Estimate',
                    idNote: id
                }
            }             
            this.$store.commit('core/setOpenModal', this.m);  
        },      
        NewCustomer() {
            let t = this;
            this.m = {
                show: true,
                component: 'formCustomer',
                data: { }
            }             
            this.$store.commit('core/setOpenModal', this.m);             
        },
        NewProducts(item, index) {
            this.FormAddPS.index_modal = index;
            let t = this;
            this.m = {
                show: true,
                component: 'formProducts',
                data: { 
                    FormAddPS:t.FormAddPS,
                    TypeofAccount:t.TypeofAccount,
                    TaxesLists:t.TaxesLists,
                    formList:t.formList              
                }
            }             
            this.$store.commit('core/setOpenModal', this.m);             
        },   
        activateStartUpdate() {
            let t = this;
            this.m = {
                show: true,
                component: 'StartEstimate',
                data: { }
            }             
            this.$store.commit('core/setOpenModal', this.m);
        },
        edicionDuplicadosOtros() {
            if (this.$route.params.idest && !this.$route.params.duplicate) {
                this.formEstimates.titlePage = "Edit Estimate";
                this.formEstimates.actionNname = "update";
                this.EstimateById(1);
            }
            if (this.$route.params.idest && this.$route.params.duplicate) {
                this.formEstimates.titlePage = "Duplicate Estimate";
                this.formEstimates.actionNname = "duplicate";
                this.EstimateById(2);
            }
            if (this.$route.params.idc && this.$route.params.transac) {
                this.getCustomer(this.$route.params.idc);
                this.addNewLinePrimary();
            }
        },
        formatearFechaContrato2() {
            this.FormSignature.dateCustomer = new Date(
                (new Date(this.FormSignature.dateCustomer).toISOString() + "").slice(
                    0,
                    10
                ) + "T23:31:04.000Z"
            ).toISOString();
        },
        formatearFechaContrato() {
            this.FormSignature.dateCompany = new Date(
                (new Date(this.FormSignature.dateCompany).toISOString() + "").slice(
                    0,
                    10
                ) + "T23:31:04.000Z"
            ).toISOString();
        },
        formatearFecha() {
            this.inputFecha = new Date(
                (new Date(this.inputFecha).toISOString() + "").slice(0, 10) +
                    "T23:31:04.000Z"
            ).toISOString();
        },
        formatearFecha2() {
            this.formEstimates.accepted_date =
                this.formEstimates.accepted_date == null ||
                this.formEstimates.accepted_date == ""
                    ? null
                    : new Date(
                          (
                              new Date(this.formEstimates.accepted_date).toISOString() +
                              ""
                          ).slice(0, 10) + "T23:31:04.000Z"
                      ).toISOString();
        },
        metstartEstimate() {
            this.statusLoad(1, 1);
            window.billing
                .get("startestimate/" + window.localuserdata)
                .then((response) => {
                    // console.log(response.data);
                    if (response.data.start_estimate == false) {
                        this.start_estimate = true;
                        this.statusLoad(1, 2);
                    }
                    else{
                        this.statusLoad(1, 5);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.statusLoad(1, 4);
                });
        },
        activateStartEstimate() {
            this.isLoadingstart = true;
            window.billing
                .post("startestimate/add", {
                    user_id: window.localuserdata,
                    startnumber: "001",
                })
                .then((response) => {
                    this.isLoadingstart = false;
                    this.start_estimate = false;
                })
                .catch((error) => console.log(error, (this.isLoadingstart = false)));
        },
        ClosedModal() {
            this.SettingBox = "";
        },
        EstimateById(state) {
            this.isLoading = true;
            this.statusLoad(5, 1);
            window.billing
                .get("estimate/" + this.$route.params.idest)
                .then((response) => {
                    this.formEstimates.idEst = response.data.result.id;
                    this.formEstimates.numdoc = response.data.result.numdoc;
                    this.formEstimates.job_information =
                        response.data.result.job_information;
                    this.formEstimates.estimates_optional =
                        response.data.result.estimate_optional;
                    this.formEstimates.customer_message =
                        response.data.result.customer_message;
                    this.formEstimates.accepted_by = response.data.result.accepted_by;
                    this.formEstimates.accepted_date =
                        response.data.result.accepted_date == null ||
                        response.data.result.accepted_date == ""
                            ? null
                            : new Date(
                                  (
                                      new Date(
                                          response.data.result.accepted_date
                                      ).toISOString() + ""
                                  ).slice(0, 10) + "T23:31:04.000Z"
                              ).toISOString();
                    this.formEstimates.status = response.data.result.status;
                    //this.formEstimates.date_created = (this.formEstimates.actionNname == 'duplicate') ? new Date().toISOString().slice(0, 10) : response.data.result.date_create
                    this.inputFecha = (this.formEstimates.actionNname == 'duplicate') ? new Date().toISOString().slice(0, 10) : response.data.result.date_create
                    this.formEstimates.actionNname = "update"
                    this.formEstimates.checked =
                        response.data.result.need_contract == 1;
                    this.formEstimates.signe =
                        response.data.result.signe == 1;
                    this.formEstimates.subtotal = response.data.result.sub_total;                    
                    this.formEstimates.discount_type = response.data.result.discount_type;
                    this.formEstimates.discount_mount = response.data.result.discount_mount;
                    this.formEstimates.discount_total = response.data.result.discount_total;
                    this.formEstimates.discount_name = response.data.result.discount_name;
                    this.formEstimates.total = response.data.result.total;

                    this.selectCTemplate.description =
                        response.data.result.terms_conditions_desc;
                    this.selectCTemplate.title =
                        response.data.result.terms_conditions_title;
                    this.FormSignature.dateCustomer = new Date(
                        (
                            new Date(
                                response.data.result.signaturecustomerdate
                            ).toISOString() + ""
                        ).slice(0, 10) + "T23:31:04.000Z"
                    ).toISOString();
                    this.FormSignature.dateCompany = new Date(
                        (
                            new Date(
                                response.data.result.signaturecompanydate
                            ).toISOString() + ""
                        ).slice(0, 10) + "T23:31:04.000Z"
                    ).toISOString();
                    this.selectBillTo(response.data.customer.id);
                    for (let item in response.data.itemsprod) {
                        let taxfil = this.TaxesLists.filter(
                            (taxfil) => taxfil.id == response.data.itemsprod[item].tax_id
                        );
                        let newObj = taxfil.reduce((a, b) => Object.assign(a, b), {});
                        let taxOpcDefault ={
                            id: 0,
                            name: 'None',
                            porcentaje: '',
                            initialstate: '',
                            statename: '',
                            count: 0,
                            total: 0,
                        };
                        let isEmptyObj = !Object.keys(newObj).length;
                        let unitcost = this.formatUSD(response.data.itemsprod[item].unitcost);  
                        let qty = this.formatUSD(response.data.itemsprod[item].qty);
                        this.formList.items.push({
                            options: this.ProdServiceLists,
                            taxlistOption: this.TaxesLists,
                            model: {
                                value: response.data.itemsprod[item].product_id,
                                unitcost: unitcost,
                                text: response.data.itemsprod[item].product_name,
                                descripcion: response.data.itemsprod[item].description,
                                qty: qty,
                                taxOpc: isEmptyObj ? taxOpcDefault : newObj,
                            },
                        });
                    }
                    if (state == 2) {
                        this.formEstimates.actionNname = "duplicate"
                        this.formEstimates.accepted_by = "";
                        this.formEstimates.accepted_date = "";
                        this.formEstimates.status = 1;
                        this.FormSignature.dateCustomer = new Date(
                            (new Date().toISOString() + "").slice(0, 10) +
                                "T23:31:04.000Z"
                        ).toISOString();
                        this.FormSignature.dateCompany = new Date(
                            (new Date().toISOString() + "").slice(0, 10) +
                                "T23:31:04.000Z"
                        ).toISOString();
                        this.FormSignature.nameCompany = localStorage.getItem(
                            "nomCompany"
                        );
                    }
                    this.isLoading = false;
                    this.statusLoad(5, 2);
                })
                .catch((error) => {
                    console.log(error);
                    this.statusLoad(5, 4);
                });
        },
        getAccountTypes() {
            this.statusLoad(6, 1);
            window.billing
                .get("chart-account-list/" + window.localuserdata)
                .then((response) => {
                    // console.log("Account type form modal es");
                    // console.log(response.data.result);
                    this.TypeofAccount = response.data.result;
                    this.statusLoad(6, 2);
                })
                .catch((error) => {
                    console.log(error);
                    this.statusLoad(6, 4);
                });
        },
        MetSAddProdService() {
            let x = this.formList.items[this.FormAddPS.index_modal];
            if (this.FormAddPS.unitcost == 0) {
                this.FormAddPS.vatErrorMsg = "Please enter Valid Number.";
                return false;
            }
            if (this.FormAddPS.Action == "Add") {
                this.formList.items[
                    this.FormAddPS.index_modal
                ].taxlistOption = this.TaxesLists;
                if (this.FormAddPS.account_id == 0) {
                    this.$notify({
                        group: "notiMetSAddProdService",
                        title: "Error",
                        type: "error",
                        text: "You must select an Account.",
                    });
                } else {
                    this.ProdServiceisLoading = true;
                    window.billing
                        .post("product-service/add", {
                            user_id: window.localuserdata,
                            account_id: this.FormAddPS.account_id,
                            productname: this.FormAddPS.productname,
                            quantity: this.FormAddPS.quantity,
                            unitcost: this.FormAddPS.unitcost,
                            description: this.FormAddPS.description,
                            applytax: this.FormAddPS.applytax,
                            status: 1,
                        })
                        .then((response) => {
                            //this.getProdservices();
                            if (response.data.status) {
                                let idTaxOpc = {
                                    id: 0,
                                    name: 'None',
                                    porcentaje: '',
                                    initialstate: '',
                                    statename: '',
                                    count: 0,
                                    total: 0,
                                };
                                for (let i in this.TaxesLists) {
                                    if (
                                        this.TaxesLists[i].id == this.FormAddPS.applytax
                                    ) {
                                        idTaxOpc = this.TaxesLists[i];
                                    }
                                }
                                let unitcost = this.formatUSD(response.data.unitcost);
                                let quantity = this.formatUSD(response.data.quantity);
                                let model = {
                                    value: response.data.ProductId,
                                    text: response.data.productname,
                                    prodname: response.data.productname,
                                    unitcost: unitcost,
                                    descripcion: response.data.description,
                                    qty: quantity,
                                    taxOpc: idTaxOpc,
                                    applytax: this.FormAddPS.applytax,
                                };
                                this.ProdServiceLists.push(model);
                                this.formList.items[
                                    this.FormAddPS.index_modal
                                ].options = this.ProdServiceLists;
                                this.formList.items[
                                    this.FormAddPS.index_modal
                                ].model = model;
                            }
                            this.FormAddPS.account_id = null;
                            this.FormAddPS.productname = null;
                            this.FormAddPS.quantity = 1;
                            this.FormAddPS.unitcost = 0;
                            this.FormAddPS.description = null;
                            this.FormAddPS.applytax = 0;
                            this.FormAddPS.status = 1;
                            this.FormAddPS.taxOpc = {
                                id: 0,
                                name: 'None',
                                porcentaje: '',
                                initialstate: '',
                                statename: '',
                                count: 0,
                                total: 0,
                            };
                            this.ProdServiceisLoading = false;
                            this.$notify({
                                group: "noti",
                                title: "Created!",
                                type: "success",
                                text: "Add Product Successful",
                            });
                        })
                        .catch((error) => {
                            console.log(error);
                            this.ProdServiceisLoading = false;
                        });
                }
            }
        },
        getCustomer(idc = 0) {
            let self = this;
            this.statusLoad(2, 1);
            window.billing
                .get("customers/" + window.localuserdata)
                .then((response) => {
                    const Customers = [];
                    console.log(response.data.result);
                    if (response.data.result) {
                        for (let item in response.data.result) {
                            var id = response.data.result[item].id;
                            var text = response.data.result[item].firstname;
                            var billing_address =
                                response.data.result[item].billingaddress;
                            var customer_address = response.data.result[item].address;
                            var bussinesname = response.data.result[item].bussinesname;
                            var email = response.data.result[item].email;
                            var lastname = response.data.result[item].lastname;
                            var customer_phone = response.data.result[item].mobilephone;
                            var customer_officephone = response.data.result[item].officephone;
                            var city = response.data.result[item].bcity;
                            var state = response.data.result[item].bstate;
                            var code = response.data.result[item].bpostcode;
                            city = city != null ? city + "," : "";
                            state = state != null ? state : "";
                            code = code != null ? code : "";
                            var guion = " - ";
                            this.formEstimates.customer_email = email;
                            if (bussinesname == null) {
                                bussinesname = "";
                                guion = " ";
                            }
                            if (lastname == null) {
                                lastname = "";
                            }
                            Customers.push({
                                value: id,
                                text: text + " " + lastname + guion + bussinesname,
                                customer_name: text,
                                customer_address: customer_address,
                                customer_phone: customer_phone,
                                customer_officephone: customer_officephone,
                                customer_lastname: lastname,
                                billing_address: billing_address,
                                bussinesname: bussinesname,
                                email: email,
                                city: city,
                                state: state,
                                code: code,
                            });
                            if (idc > 0 && idc == id) {
                                this.ValuesCustomers = {
                                    text: text + " " + lastname + guion + bussinesname,
                                    value: id,
                                    billing_address: billing_address,
                                    bussinesname: bussinesname,
                                    customer_address: customer_address,
                                    customer_name: text,
                                    customer_lastname: lastname,
                                    customer_phone: customer_phone,
                                    customer_officephone: customer_officephone,
                                    email: email,
                                    city: city,
                                    state: state,
                                    code: code,
                                };
                            }
                        }
                        this.statusLoad(2, 2);
                    }else{
                        this.statusLoad(2, 5);
                    }
                    this.CustomersList = Customers;
                    if (idc > 0) {
                        this.isLoading = false;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.statusLoad(2, 4);
                });
        },
        selectBillTo(idc) {
            for (let item in this.CustomersList) {
                let id = this.CustomersList[item].value;
                if (idc > 0 && idc == id) {
                    this.ValuesCustomers = {
                        text: this.CustomersList[item].text,
                        value: this.CustomersList[item].value,
                        billing_address: this.CustomersList[item].billing_address,
                        bussinesname: this.CustomersList[item].bussinesname,
                        customer_address: this.CustomersList[item].customer_address,
                        customer_name: this.CustomersList[item].customer_name,
                        customer_lastname: this.CustomersList[item].customer_lastname,
                        customer_phone: this.CustomersList[item].customer_phone,
                        customer_officephone: this.CustomersList[item].customer_officephone,
                        email: this.CustomersList[item].email,
                        city: this.CustomersList[item].city,
                        state: this.CustomersList[item].state,
                        code: this.CustomersList[item].code,
                    };
                }
            }
            // console.log("customer seleccionado", this.ValuesCustomers);
        },
        MetAddProd(ip, index) {
            // console.log(index);
            this.HideSuggestionProd = false;
        },
        ItemsClick(index) {},
        saveEstimates() {
            this.isLoading = true;
            this.saveDisabled = true;
            this.errors = [];
            if (!this.ValuesCustomers.value) {
                //this.errors.push('Must select "Customer"');
                this.msjAlert('Must select "Customer')
                this.isLoading = false;
                this.saveDisabled = false;
                return false;
            }
            if (this.formEstimates.total == 0) {
                // this.errors.push("Total must not be greater than zero");
                this.msjAlert("Total must not be greater than zero")
                this.isLoading = false;
                this.saveDisabled = false;
                return false;
            }
            // if (!this.formList.items[0].model.value && this.formList.items[0].model.descripcion.length==0) {
            //     //this.errors.push("Must add at least 1 Product or service");
            //     this.msjAlert("Must add at least 1 Product or service")
            //     this.isLoading = false;
            // }
            for (let i in this.formList.items) {
                if (
                    this.formList.items[i].model.qty == "" ||
                    this.formList.items[i].model.qty == null
                ) {
                    this.formList.items[i].model.qty = 0;
                    //this.errors.push('You must specify quantity for all products');
                    //this.isLoading = false
                }
                if (
                    this.formList.items[i].model.unitcost == "" ||
                    this.formList.items[i].model.unitcost == null
                ) {
                    this.formList.items[i].model.unitcost = 0;
                    //this.errors.push('You must specify quantity for all products');
                    //this.isLoading = false
                }                
            }
            if (this.ValuesCustomers.value && this.errors.length == 0) {
                if (
                    this.formEstimates.actionNname == "create" ||
                    this.formEstimates.actionNname == "duplicate"
                ) {
                    window.billing
                        .post("estimates/add", {
                            user_id: window.localuserdata,
                            customer_id: this.ValuesCustomers.value,
                            estimate_optional: this.formEstimates.estimates_optional,
                            status: this.formEstimates.status,
                            accepted_by: this.formEstimates.accepted_by,
                            accepted_date: this.formEstimates.accepted_date,
                            job_information: this.formEstimates.job_information,
                            customer_message: this.formEstimates.customer_message,
                            need_contract: this.formEstimates.checked,
                            signe: this.formEstimates.signe,
                            terms_conditions_title: this.selectCTemplate.title,
                            terms_conditions_desc: this.selectCTemplate.description,
                            signaturecustomer: this.ValuesCustomers.text,
                            signaturecustomerdate: this.FormSignature.dateCustomer,
                            signaturecompany: this.FormSignature.nameCompany,
                            signaturecompanydate: this.FormSignature.dateCompany,
                            date_create: this.formEstimates.date_created,
                            sub_total: this.formEstimates.subtotal,
                            discount_type: this.formEstimates.discount_type,
                            discount_mount: this.formEstimates.discount_mount,
                            discount_total: this.formEstimates.discount_total,
                            discount_name: this.formEstimates.discount_name,
                            total: this.formEstimates.total,
                            dataItems: this.formList.items,
                        })
                        .then((response) => {
                            this.isLoading = false;
                            this.saveDisabled = false;
                            this.$notify({
                                group: "noti",
                                title: "Created!",
                                type: "success",
                                text: "Successful registration",
                            });
                            this.$router.push({ name: "Estimates" });
                        })
                        .catch((error) => {
                            console.log(error);
                            this.isLoading = false;
                            this.saveDisabled = false;
                        });
                }
                if (this.formEstimates.actionNname == "update") {
                    window.billing
                        .put("estimate/edit/" + this.formEstimates.idEst, {
                            user_id: window.localuserdata,
                            numdoc: this.formEstimates.numdoc,
                            customer_id: this.ValuesCustomers.value,
                            estimate_optional: this.formEstimates.estimates_optional,
                            status: this.formEstimates.status,
                            accepted_by: this.formEstimates.accepted_by,
                            accepted_date: this.formEstimates.accepted_date,
                            job_information: this.formEstimates.job_information,
                            customer_message: this.formEstimates.customer_message,
                            need_contract: this.formEstimates.checked,
                            signe: this.formEstimates.signe,
                            terms_conditions_title: this.selectCTemplate.title,
                            terms_conditions_desc: this.selectCTemplate.description,
                            signaturecustomer: this.ValuesCustomers.text,
                            signaturecustomerdate: this.FormSignature.dateCustomer,
                            signaturecompany: this.FormSignature.nameCompany,
                            signaturecompanydate: this.FormSignature.dateCompany,
                            date_create: this.formEstimates.date_created,
                            sub_total: this.formEstimates.subtotal,
                            discount_type: this.formEstimates.discount_type,
                            discount_mount: this.formEstimates.discount_mount,
                            discount_total: this.formEstimates.discount_total,
                            discount_name: this.formEstimates.discount_name,
                            total: this.formEstimates.total,
                            dataItems: this.formList.items,
                        })
                        .then((response) => {
                            this.isLoading = false;
                            this.saveDisabled = false;
                            console.log(response.data);
                            this.$notify({
                                group: "noti",
                                title: "Updated",
                                type: "success",
                                text: "Successful Updated",
                            });
                            this.$router.push({ name: "Estimates" });
                        })
                        .catch((error) => {
                            console.log(error);
                            this.isLoading = false;
                            this.saveDisabled = false;
                        });
                }
            }
        },
        getTaxes() {
            this.statusLoad(3, 1);
            window.billing
                .get("taxes/" + window.localuserdata)
                .then((response) => {
                    console.log("taxes request all");
                    //console.log(response.data)
                    //this.TaxesLists = response.data;
                    const ObjTaxes = [{
                            id: 0,
                            name: '--None--',
                            porcentaje: '',
                            initialstate: '',
                            statename: '',
                            count: 0,
                            total: 0,
                        }];
                    if (response.data) {
                        for (let item in response.data) {
                            var id = response.data[item].id;
                            var initialstate = response.data[item].initialstate;
                            var name = response.data[item].name;
                            var state_id = response.data[item].state_id;
                            var statename = response.data[item].statename;
                            var status = response.data[item].status;
                            var user_id = response.data[item].user_id;
                            ObjTaxes.push({
                                id: id,
                                initialstate: initialstate,
                                name: name,
                                state_id: state_id,
                                statename: statename,
                                status: status,
                                user_id: user_id,
                                default: "none",
                            });

                            this.taxesResumen.push({
                                id: id,
                                name: initialstate,
                                porcentaje: name,
                                initialstate: initialstate,
                                statename: statename,
                                count: 0,
                                total: 0,
                            });
                        }
                        this.statusLoad(3, 2);
                    }else{
                        this.statusLoad(3, 3);
                    }
                    this.TaxesLists = ObjTaxes;
                    this.getProdservices();
                })
                .catch((error) => {
                    console.log(error);
                    this.statusLoad(3, 4);
                });
        },
        getProdservices() {
            this.statusLoad(4, 1);
            window.billing
                .get("product-services/" + window.localuserdata)
                .then((response) => {
                    console.log("product sevice list table");
                    //this.ProdServiceLists = response.data.result
                    const ProdList = [{
                        value: 0,
                        text: "Uncategorized",
                        description: "",
                        unitcost: "1.00",
                        qty: "1.00",
                        taxOpc: {
                            id: 0,
                            name: '--None--',
                            porcentaje: '',
                            initialstate: '',
                            statename: '',
                            count: 0,
                            total: 0,
                        },
                        applytax: 0,
                        prices: 0
                    }];
                    if (response.data.result) {
                        for (let item in response.data.result) {
                            var id = response.data.result[item].id;
                            var prodname = response.data.result[item].productname;
                            var quantity = this.formatUSD(response.data.result[item].quantity);
                            var unitcost = this.formatUSD(response.data.result[item].unitcost);
                            var descripcion = response.data.result[item].description;
                            var applytax = response.data.result[item].applytax;
                            let taxOpcID = {
                                id: 0,
                                name: 'None',
                                porcentaje: '',
                                initialstate: '',
                                statename: '',
                                count: 0,
                                total: 0,
                            };
                            for (let i in this.TaxesLists) {
                                if (
                                    this.TaxesLists[i].id ==
                                    response.data.result[item].applytax
                                ) {
                                    taxOpcID = this.TaxesLists[i];
                                }
                            }
                            ProdList.push({
                                value: id,
                                text: prodname,
                                unitcost: unitcost,
                                descripcion: descripcion,
                                qty: quantity,
                                taxOpc: taxOpcID,
                                applytax: applytax,
                            });
                        }
                        this.statusLoad(4, 2);
                    }
                    this.ProdServiceLists = ProdList;
                    console.log("ProdList ", this.ProdServiceLists);
                    /// item default 1
                    // console.log(
                    //     "params=" + this.$route.params.length,
                    //     this.$route.params,
                    //     this.$route.name
                    // );
                    if (this.$route.name != "EstimatesNew") {
                        this.edicionDuplicadosOtros();
                    } else {
                        this.addNewLinePrimary();
                        this.isLoading = false;
                    }
                })
                .catch((error) => {
                    this.isLoading = false;
                    console.log(error);
                    this.statusLoad(4, 4);
                });
        },
        addNewLinePrimary() {
            const ProdList = [];
            for (let item in this.ProdServiceLists) {
                var id = this.ProdServiceLists[item].value;
                var prodname = this.ProdServiceLists[item].text;
                var quantity = this.ProdServiceLists[item].qty;
                var unitcost = this.ProdServiceLists[item].unitcost;
                var descripcion = this.ProdServiceLists[item].descripcion;
                var applytax = this.ProdServiceLists[item].applytax;
                let taxOpcID = {
                    id: 0,
                    name: '--None--',
                    porcentaje: '',
                    initialstate: '',
                    statename: '',
                    count: 0,
                    total: 0,
                };
                for (let i in this.TaxesLists) {
                    if (this.TaxesLists[i].id == this.ProdServiceLists[item].applytax) {
                        taxOpcID = this.TaxesLists[i];
                    }
                }
                ProdList.push({
                    value: id,
                    text: prodname,
                    unitcost: unitcost,
                    descripcion: descripcion,
                    qty: quantity,
                    taxOpc: taxOpcID,
                });
            }
            if (this.formEstimates.actionNname == "create") {
                this.formList.items.push({
                    options: ProdList,
                    taxlistOption: this.TaxesLists,
                    model: {
                        value: 0,
                        text: "Uncategorized",
                        description: "",
                        unitcost: "1.00",
                        qty: "1.00",
                        taxOpc: {
                            id: 0,
                            name: '--None--',
                            porcentaje: '',
                            initialstate: '',
                            statename: '',
                            count: 0,
                            total: 0,
                        },
                        applytax: 0,
                        prices: 0
                    },
                });
            }
        },
        hide() {
            this.HideSuggestionProd = false;
        },
        getCTemplates() {
            this.statusLoad(7, 1);
            window.billing
                .get("contracttemplate/" + window.localuserdata)
                .then((response) => {
                    console.log("template estimates list");
                    console.log(response.data);
                    this.ContractTemLists = response.data.result;
                    this.statusLoad(7, 2);
                })
                .catch((error) => {
                    console.log(error);
                    this.statusLoad(7, 4);
                });
        },
        MetDeleteEstimate(idest) {
            this.isLoading = true;
            this.$swal({
                title: "Delete this Estimate?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                if (result.value) {
                    window.billing
                        .put("estimate/delete/" + idest, {
                            user_id: window.localuserdata,
                        })
                        .then((response) => {
                            this.isLoading = false;
                            if (response.data.status) {
                                this.$notify({
                                    group: "noti",
                                    title: "Deleted!",
                                    type: "success",
                                    text: "Deleted Estimate Successful",
                                });

                                this.$router.push({ name: "Estimates" });
                            }
                        })
                        .catch((error) => console.log(error, (this.isLoading = false)));
                }
            });
            this.isLoading = false;
        },
        checkVatId() {
            //RegExp(/^[1-9]+/) ?|0+\.[1-9][0-9]
            let vatIdRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/);
            if (this.FormAddPS.unitcost == "") {
                this.FormAddPS.vatError = true;
                this.FormAddPS.vatErrorMsg = "Enter positive number.";
            } else if (!vatIdRegex.test(this.FormAddPS.unitcost)) {
                this.FormAddPS.vatError = true;

                this.FormAddPS.vatErrorMsg = "Please enter Valid Number.";
                //this.FormAddPS.unitcost = 0;
            } else {
                this.FormAddPS.vatError = false;
            }
        },
        getEstimateSetting() {
            //this.FormestimateSetting.isLoading = true
            this.statusLoad(0, 1);
            window.billing
                .get("estimates/setting/" + window.localuserdata)
                .then((response) => {
                    // console.log(response.data.result);
                    if (response.data.result.length) {
                        this.jobinfomationcheck =
                            response.data.result[0].jobinfomationcheck;
                        this.jobinfomationtitle =
                            response.data.result[0].jobinfomationtitle;
                        this.jobinfomationtext =
                            response.data.result[0].jobinfomationtext;
                        this.formEstimates.job_information =
                            response.data.result[0].jobinfomationtext;
                            this.statusLoad(0, 2);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.statusLoad(0, 4);
                });
        },
    },
    watch: {
        'getActionRefresh': function() {
            console.log('getActionRefresh', this.getActionRefresh)
            if (Object.keys(this.getActionRefresh.data).length > 0) {
                let data = this.getActionRefresh.data; 
                let methods = this.getActionRefresh.action;
                if (methods === 'newCustomer') {
                    this.ValuesCustomers = data;
                    this.getCustomer();
                }
                if (methods === 'products') {
                    this.ProdServiceLists.push(data.model);
                    this.formList.items[
                        this.FormAddPS.index_modal
                    ].options = this.ProdServiceLists;
                    this.formList.items[
                        this.FormAddPS.index_modal
                    ].model = data.model;                        
                }                                        
                this.m = {
                    show: false,
                    component: '',
                    data: {}
                }
            }
        },
        inputFecha: function () {
            this.formatearFecha();
            this.formEstimates.date_created = this.inputFecha;
        },
        "FormSignature.dateCustomer": function () {
            this.formatearFechaContrato2();
            this.dateCustomer = this.FormSignature.dateCustome;
        },
        "FormSignature.dateCompany": function () {
            this.formatearFechaContrato();
            this.dateCompany = this.FormSignature.dateCompany;
        },
        "formEstimates.accepted_date": function () {
            if (
                this.formEstimates.accepted_date !== null ||
                this.formEstimates.accepted_date !== ""
            ) {
                this.formatearFecha2();
                this.accepted_date =
                    this.formEstimates.accepted_date == null ||
                    this.formEstimates.accepted_date == ""
                        ? null
                        : this.formEstimates.accepted_date;
            }
        },
        formcustomer: {
            deep: true,
            handler: (e) => {
                //debugger;
                for (var i in e) {
                    if (i != "notes" && i != "error") {
                        if (e[i] != null && e[i].length > 0) {
                            if (e[i].indexOf(",") > -1) {
                                //console.log(i, e[i]);
                                e.error = parseInt(e.error) + 1;
                                e[i] = e[i].replace(",", "");
                                console.log(e);
                            }
                        }
                    }
                }
            },
        },
        "formcustomer.error": function (e) {
            if (e > 0) {
                this.$notify({
                    group: "notiCustomer",
                    title: "Error",
                    type: "error",
                    text: "The symbol coma “,” is not allowed.",
                });
                return (e = 0);
            }
        },
        getPlanId() {    
            this.PlanPremium = (this.$store.getters['core/Org/getPlanId'] > 1);
            console.log('watch PlanPremium', this.PlanPremium);
        },
    },
    computed: {
        ...mapGetters("core", { getOpenModal: "getOpenModal", getActionRefresh: "getActionRefresh" }),
        ...mapGetters("core/Org", { getPlanId: "getPlanId"}),
        filtroTypeofAccount() {
            return this.TypeofAccount.filter(function (u) {
                return u.account_type === 3 || u.account_type === 5;
            });
        },
        isDisabled: function () {
            if (this.FormAddPS.unitcost == 0) {
                return this.FormAddPS.unitcost;
            } else {
                return false;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.iva_block {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding-top: 5px;
    padding-bottom: 5px;
    .itemsb {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
}
.deletebtn {
    position: absolute;
    right: -24px;
    top: 22px;
    width: 24px;
    height: 20px;
    background-color: #f12e2e;
    cursor: pointer;
    font-size: 20px;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    padding: 7px;
    line-height: 0px;
    &:hover {
        background-color: #c12727;
    }
}
.pageSignature {
    display: block;
    margin-top: 20px;
    width: 100%;
}
.flip-list-move {
    transition: transform 0.5s;
}
.no-move {
    transition: transform 0s;
}
.ghost {
    opacity: 0.5;
    background: #a0a0a0;
}
.ui.fluid.search.selection.dropdown.productos {
    width: 90%;
    float: left;
    display: inline-block;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
.btninv {
    position: relative;
    left: initial;
    top: 0;
    width: 20px;
    height: 38px;
    cursor: pointer;
    background-color: #fff;
    z-index: 10;
    color: #333;
    font-size: 18px;
    text-align: center;
    vertical-align: middle;
    line-height: 34px;
    font-weight: 400;
    border: 1px solid #dedede;
    float: right;
    display: inline-block;
    margin-left: -6px;
    &:hover {
        background-color: #e0e0e0;
    }
}
.btnmove {
    position: absolute;
    left: -13px;
    top: -3px;
    width: 13px;
    height: 100%;
    cursor: pointer;
    background-color: #e1f2fb;
    z-index: 10;
    color: #afafaf;
    font-size: 12px;
    line-height: 75px;
    text-align: center;
    vertical-align: middle;
    font-weight: normal;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    &:hover {
        background-color: #e0e0e0;
        cursor: move;
    }
}
.table-productos {
    width: 100%;
}
.bill_to {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 0.8rem;
    line-height: 1.5;
    color: #4f5467;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dedede;
    border-radius: 2px;
    height: 130px;
}
#job_information {
    height: 130px;
}
#CustomersList .ui.selection.dropdown .menu {
    border-top: 1px solid #d4d0c8 !important;
}
@media (max-width: 1100px) {
    .table-productos {
        position: relative;
        display: block;
        padding-left: 15px;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-productos table {
        width: 860px;
    }
}
@media (max-width: 800px) {
    .table-productos {
        max-width: 800px;
    }
}
.error {
    border-color: red !important;
}

.messageBox {
    display: none;
    background-color: rgba(255, 255, 255, 0.74);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    .box {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        .conte {
            max-width: 350px;
            text-align: center;
            i {
                font-size: 40px;
                margin-bottom: 20px;
            }
            p {
                display: block;
                margin-bottom: 10px;
                font-size: 15px;
            }
        }
    }
    &.show {
        display: block;
    }
}
</style>
