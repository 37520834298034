<template>
    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col-8" style="padding-top: 10px">
                    <h5 class="card-title" v-if="opt==1">
                        Sold Project
                        <!-- Lead ID <span class="badge badge-primary">#{{leadID}}</span> / 
                        Sale ID <span class="badge badge-primary">#{{ salesID }}</span> / 
                        Estimate ID <span class="badge badge-primary">#{{ numdoc }}</span> -->
                    </h5>
                    <h5 class="card-title" v-else>
                        Rejected project
                    </h5>
                </div>
            </div>
        </div>
        <div class="card-body" style="padding: 1em !important;max-height: 400px;min-height: 200px;overflow: hidden;overflow-y: auto;">
            <div class="row" v-if="opt==1 && closingSave==false">
                <div class="col-md-12">
                    <div class="alert alert-info" role="alert">
                      <p class="mb-0">Upload a pdf or jpg of the signed contract, (this step is optional)</p>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-check form-check-inline" style="margin-bottom: 20px;">
                        <label class="form-check-label">
                            <h5 style="margin-bottom: 0px;">Type File:</h5>
                        </label>
                        <label class="form-check-label" style="margin-left: 10px;">
                            <input class="form-check-input" type="radio" v-model="type_file" value="img"> Upload images
                        </label>
                        <label class="form-check-label" style="margin-left: 10px;">
                            <input class="form-check-input" type="radio" v-model="type_file" value="pdf"> Upload PDF
                        </label>
                    </div>
                </div>
                <div class="col-md-12" v-if="type_file=='img'">
                    <input type="file"
                        id="imgNotes"
                        accept=".jpg,.jpeg,.png"
                        multiple
                        style="display: none"
                        @change="subirIMG($event)"
                        ref="addGalley"
                        v-if="editGallery"
                    />
                    <div class="btn-group" v-if="ifGallery == 0">
                        <button type="button" class="btn btn-secondary" @click="displayRef">
                            <i class="fas fa-paperclip"></i> Upload Images
                        </button>
                        <!-- <button type="button" class="btn btn-danger" @click="deleteAllIMG" v-if="galleryList.length > 0" style="margin-left: 10px">
                            <i class="fas fa-trash"></i> Delete All
                        </button> -->
                    </div>
                </div>
                <div class="col-md-12" v-else>
                    <!-- <button type="button" class="btn btn-secondary" @click="displayRef">
                        <i class="fas fa-paperclip"></i> Upload PDF
                    </button> -->
                </div>
            </div>
            <div class="row" v-else-if="opt==2" style="margin-top:20px;">
                <div class="col-md-12">
                    <textarea v-if="closingSave==false" v-model="note" class="form-control" style="height: 80px;" placeholder="Notes:" maxlength="300"></textarea>
                    <div v-else class="container-comments">
                        <ul class="listComments">
                            <li v-for="(i, idx) in closingList" :key="idx">
                                <div class="comments-content">
                                    <div class="date">
								        <b>Date/Time: </b>
								        <span>{{i.date}} {{i.hour}}</span>
                                    </div>
                                    <div class="comment">
                                        <b>Note: </b>
								        <pre>{{i.note}}</pre>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-12" style="margin-top:20px;" v-if="closingSave==false">
                    <button
                    type="button"
                    class="btn btn-success d-flex"
                    @click="saveRejected()"
                >
                    save as rejected
                </button>
                </div>
            </div>
            <div class="row" v-else-if="opt==1 && closingSave==true">
                <div class="col-md-12" v-if="type_file=='img'">
                    <gallerysales css="h-300"
                        :items="galleryList"
                        :url="galleryURL"
                        :typez="typez"
                        :cells="5"
                        :salesID="salesID"
                        :cover="cover"
                        v-if="ifGallery == 1"
                    ></gallerysales>
                    <div class="alert alert-warning" role="alert" v-else>
                      <p class="mb-0">This Project was “approved” using Digital Signature from Marketea Billing. There are no attachments.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import gallerysales from '@/apps/Marketea/plugins/gallery/gallerySalesLimit'
export default {
    name: "closing",
    components: {
        gallerysales,
    },
    data () {
        return {
            typez: 0,
            numdoc: 0,
            leadID: 0,
            salesID: 0,
            jobsNote: "",
            editGallery: true,
            ifGallery: 0,
            galleryList: [],
            galleryURL: "",
            cover: 0,
            opt: 1,
            type_file: 'img',
            note: null,
            closingSave: false,
            date: null,
            hour: null,
            est_id: 0,
            closingList: [],
        }
    },
    created () {
        this.init();
    },
    methods: {
        init() {
            let t = this;
            t.$store.commit("core/setLoadingModal", false);
            let urlApis = t.$store.getters['ModApps/jobs/urlApis'];
            t.apis = urlApis.jobBox;
            let g = t.$store.getters['core/getOpenModal'];
            let r = g.data;
            t.opt = r.opt;
            t.salesID = r.item.id;
            t.est_id = r.item.est_id;
            t.leadID = r.item.perfil.lead_id;
            t.numdoc = r.item.est_id;
            if(r.item.status==6 || r.item.status==7){
                t.$store.commit("core/setLoadingModal", true);
                window.master.post('getClosingSales', {
                    user_id: window.master_client,
                    org_id: window.localorgdata,
                    sales_id: t.salesID,
                })
                .then((response) => {
                    console.log('getClosingSales', response.data)
                    if(response.data.status){
                        if(response.data.r.length>0){
                            let r =response.data.r;
                            for (const i in r) {
                                if (Object.prototype.hasOwnProperty.call(r, i)) {
                                    t.closingList.push({
                                        note: r[i].note,
                                        date: r[i].date,
                                        hour: r[i].hour
                                    });
                                }
                            }
                            // t.note=response.data.r.note;
                            // t.date=response.data.r.date;
                            // t.hour=response.data.r.hour;
                            t.closingSave=true;
                            if(t.opt==1){
                                t.galleryURL = response.data.url;
                                if(response.data.r.files){
                                    t.ifGallery = 1;
                                    t.galleryList = response.data.r.files;
                                    if(t.galleryList.length){
                                        t.cover = t.galleryList[0];
                                    }
                                }
                            }
                        }
                    }
                    t.$store.commit("core/setLoadingModal", false);
                })
                .catch((error) => {
                    console.log(error);
                    t.$store.commit("core/setLoadingModal", false);
                });
            }
        },
        subirIMG(event) {
            let t = this;
            if(event.target.files.length){
                t.$store.commit("core/setLoadingModal", true);
                let fileSendHeader = { headers: { "Content-Type": "multipart/form-data" } };
                let formData = new FormData();
                let cont = [];
                for (let i in event.target.files) {
                    if (event.target.files.hasOwnProperty(i)) {
                        cont++;
                        formData.append("image" + cont, event.target.files[i]);
                    }
                }
                formData.append("nombreIMG", "image");
                formData.append("contIMG", cont);
                formData.append("sales_id", this.salesID);
                formData.append("org_id", window.localuserdata);
                formData.append("user_id", window.master_client);
                formData.append("folder", 'sales');
                formData.append("delIMG", "image");
                formData.append("contDel", 0);
                formData.append("opt", t.opt);
                formData.append("note", t.note);
                formData.append("type_file", t.type_file);
                formData.append("files", null);
                formData.append("status", 6); // id status = sold
                formData.append("action", 'actListSalesTabs');
                formData.append("tab", 5); // index de sold
                window.master
                .post('setClosingSales', formData, fileSendHeader)
                .then((response) => {
                    console.log('setClosingSales', response.data)
                    t.$refs.addGalley.value=null;
                    t.$store.commit("core/setLoadingModal", false);
                    t.$store.commit("core/setCloseModal", {action: 'sold', 'idSale': t.salesID});
                    t.$parent.close();
                })
                .catch((error) => {
                    console.log(error);
                    t.$refs.addGalley.value=null;
                    t.$store.commit("core/setLoadingModal", false);
                });
            }
        }, 
        displayRef() {
            this.$refs.addGalley.click();
        },
        saveRejected() {
            let t = this;
            // this.$swal({
            //     title: "Are you sure you want to reject the project?",
            //     text: "Ance you reject you can not return this action, do you want to continue?",
            //     icon: "warning",
            //     showCancelButton: true,
            //     confirmButtonColor: "#3085d6",
            //     confirmButtonText: "Yes, Reject it!",
            // }).then((result) => {
            //     if (result.value) {
                    t.$store.commit("core/setLoadingModal", true);
                    window.master
                    .post('setClosingSales', {
                        user_id: window.master_client,
                        org_id: window.localorgdata,
                        sales_id: t.salesID,
                        opt: t.opt,
                        note: t.note,
                        status: 7,
                        est_id: t.est_id,
					    est_status: 3, //rejected
                        "action": 'actListSalesTabs',
                        "tab": 6, // index de rejected
                    })
                    .then((response) => {
                        t.$store.commit("core/setLoadingModal", false);
                        if (response.data.status) {
                            t.$store.commit("core/setCloseModal", {action: 'rejected', 'idSale': t.salesID});
                            t.$parent.close();
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        t.$store.commit("core/setLoadingModal", false);
                    });
            //     }
            // });
        },
    },
    watch: {
    },
    computed: {
        ...mapGetters("core", {getOpenModal: "getOpenModal"})
    }
};
</script>
<style lang="scss" scoped>
ul.listComments {
list-style: none;
margin-bottom: 0px;
padding-inline-start: 0px;
margin-block-start: 0px;
}

.comments-content {
    padding: 10px;
    margin-right: 20px;
    border: 1px solid #e4daff;
    border-radius: 10px;
    background: rgb(228 218 255 / 49%);
    margin-bottom: 20px;
}
.date, .by {
    font-size: 10px;
    color: #bebebe;
}
.comment {
    font-size: 14px;
}	
.comment span{
    font-size: 12px;
}	
.comment {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}
.comment span {margin-left: 10px;}
.comment pre {
    font-size: 14px;
    color: #6a7a8c;
    display: flex;
    font-family: "ProximaNova", sans-serif;
    margin-left: 10px;
    margin-bottom: 0px;;
    font-weight: 400;
    line-height: 1.5;
}
</style>
