let CustomerModal = function(i, op) {
    this.itemNameCustomers = i;
    this.editCusInv=op;
    if (i.id_customer > 0) {
        // this.$modal.show("nameCustomer");
        this.m = {
            show: true,
            component: 'mCustomer',
            data: i
        };
        this.$store.commit('core/setOpenModal', this.m);
    }
};
export default CustomerModal;
