import sites from '@/apps/Sites/lang/';
import urlApis from '@/apps/Sites/apis/';
export default {
	sites: sites,
	urlApis: urlApis,
	access: false,
	asideStorage:[],
	loadSites: false,
	addWeb: false,
	refreshBoxContent: 0,
	webChose: 0,
	websList: [],
	boxContentclone: null,
	boxContentCloneNewIndex: null,
	closeBarTools: 0,
	sideBarOpc:{
		active: false,
		component: null,
		data: []
	},
	opcFontPage:{
		typeBg: 0,
		images: [],
    	colorOverlay:'',
    	typeHeight: '',
		slideHeight: '',
    	layoutContent: '',
    	boxSize: '',
    	contentAling: '',
    	thumbs: [],
    	formShow: false,
    	formHeader: 0,
    	boxForm: '',
    	formAling: '',
	},
	beforeUnloadBiulder: false,
}
