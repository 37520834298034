<template>
	<div>
		<div class="card">
			<div class="card-header">
				Add Timesheet
			</div>
			<form @submit.prevent="saveTimesheet">
				<div class="card-body">
					<div class="row">
						<div class="form-row">
							<div class="form-group col-md-4">
								<label>From:</label>
								<datetime
								type="date"
								v-model="f.fecini"
								:format="{
								year: 'numeric',
								month: 'short',
								day: 'numeric',
							}"
							use12-hour
							value-zone="America/New_York"
							input-class="form-control jobdate"
							></datetime>
						</div>
						<div class="form-group col-md-5">
							<label for="inputCity">To:</label>
							<datetime
							type="date"
							disabled
							v-model="f.fecfin"
							:format="{
							year: 'numeric',
							month: 'short',
							day: 'numeric',
						}"
						use12-hour
						value-zone="America/New_York"
						input-class="form-control jobdate"
						readonly="true"
						></datetime>
					</div>
				</div>
				<div class="form-group">
					<label for="">Project Information:</label>
					<textarea
					v-model="f.descrip"
					class="form-control"
					autocomplete="off"
					style="height: 100px"
					size="40"
					rows="24"
					cols="80"
					></textarea>
				</div>
			</div>
			<div style="width: 100%; text-align: right; margin-top: 20px">
				<button
				type="submit"
				class="btn btn-success"
				:disabled="isDisabled"
				>
				<i class="fas fa-save"></i> SAVE
			</button>
		</div>
	</div>
</form>
</div>
</div>
</template>
<script>
export default {
	components: {
	},
	name: "formTimesheet",
	data () {
		return {
			isDisabled: false,
			f: {
				times_id: 0,
				id: 0,
				user_id: window.localorgdata,
				billing: 0,
				fecini:
				(new Date().toISOString() + "").slice(0, 11) + "13:00:00.000-04:00",
				fecfin: "",
				descrip: "",
			},            
		};
	},
	created () {},
	methods: {
		saveTimesheet() {
			this.$store.commit('core/setLoadingModal', true);
			let r = this.convertDate(this.f.fecini);
			this.f.fecini = r;
			let j = this.convertDate(this.f.fecfin);
			this.f.fecfin = j;
			window.billing
			.post("timesheet/form", this.f)
			.then((response) => {
				console.log(response.data);
				this.$store.commit('core/setLoadingModal', false);
				this.$store.commit("core/setCloseModal", {action: 'saved'});
				this.refresTimesheet();
			})
			.catch((error) => {
				console.log(error);
				this.$store.commit('core/setLoadingModal', false);
			});
		},      
		refresTimesheet() {
			this.f.id = 0;
			this.f.fecini =
			(new Date().toISOString() + "").slice(0, 11) +
			new Date().toTimeString().slice(0, 8);
			this.f.fecfin =
			(new Date().toISOString() + "").slice(0, 11) +
			new Date().toTimeString().slice(0, 8);
			this.f.descrip = "";
		},   
		sumarConstante(cam) {
			let t;
			if (cam === 0) {
				t = (new Date().toISOString() + "").slice(0, 11) + "13:00:00.000-04:00";
			} else {
				t = this.f.fecini;
			}
			let hoy = new Date(t);
			let d = hoy.getDate();
			let m = hoy.getMonth() + 1;
			let a = hoy.getFullYear();
			if (d < 10) {
				d = "0" + d;
			}
			if (m < 10) {
				m = "0" + m;
			}
			let r = this.sumaFecha(6, hoy);
			var nueva = new Date(r).toISOString();
			this.f.fecfin = nueva;
		}, 
		sumaFecha(d, fecha) {
            var a = (fecha + "").split(" "); // convertir el objeto en array
            var meses = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
            ];
            var nro_mes = parseInt(meses.indexOf(a[1])) + 1;
            nro_mes = nro_mes < 10 ? "0" + nro_mes : nro_mes;
            var f = a[3] + "-" + nro_mes + "-" + a[2] + "T23:31:04.000Z"; // a-m-d+'T23:31:04.000Z'
            console.log("fecha  string = " + f);
            var fn = new Date(f);
            fn.setDate(fn.getDate() + parseInt(d));
            var anno = fn.getFullYear();
            var mes = fn.getMonth() + 1;
            var dia = fn.getDate();
            mes = mes < 10 ? "0" + mes : mes;
            dia = dia < 10 ? "0" + dia : dia;
            var fechaFinal = anno + "/" + mes + "/" + dia + " " + a[4] + " " + a[5]; // horas y GTM
            return fechaFinal;
        }, 
        convertDate(f) {
            // let f = '2021-04-09T00:00:00.000-04:00';
            let fn = new Date(f);
            let times = new Date();
            let anno = fn.getFullYear();
            let mes = fn.getMonth() + 1;
            let dia = fn.getDate();
            mes = mes < 10 ? "0" + mes : mes;
            dia = dia < 10 ? "0" + dia : dia;
            let fechaResultante =
            anno + "-" + mes + "-" + dia + "T" + times.toString().slice(16, 24);
            return fechaResultante;
        },                           
    },
    watch: {
    	"f.fecini": function () {
    		if (this.f.fecini.slice(10) === "T00:00:00.000-04:00") {
    			this.f.fecini = this.f.fecini.replace(
    				"T00:00:00.000-04:00",
    				"T13:00:00.000-04:00"
    				);
    			this.sumarConstante(this.f.fecini);
    		}
    	},        
    },
};
</script>
<style lang="css" scoped>
.avisoCompany {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	padding: 0px 80px;
	background: rgba(255, 255, 255, 0.8);
	display: flex;
	flex-direction: column;
	justify-content: center;
}
</style>
