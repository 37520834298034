<template>
    <div>
        <div class="card-header">TERMS</div>
        <div class="card-body">
            <div class="alert alert-info" role="alert">
                <form @submit.prevent="MetSAddTerm">
                    <div class="form-row">
                        <div class="col-md-10">
                            <small id="emailHelp" class="form-text text-muted">
                                Add Terms:</small
                            >
                            <select class="form-control" v-model="term_id" required>
                                <option value="">--Terms--</option>
                                <option
                                    v-for="iterm in TermsLists"
                                    :key="iterm.id"
                                    v-bind:value="iterm.id"
                                >
                                    {{ iterm.name }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-2">
                            <small id="emailHelp" class="form-text text-muted">.</small>
                            <button type="submit" class="btn btn-success" style="width: inherit !important;">ADD</button>
                        </div>
                    </div>
                </form>
            </div>
            <div>
                <Loader v-if="isLoadingTerms"></Loader>
                <ul class="list-group list-group-flush">
                    <li
                        class="list-group-item d-flex justify-content-between align-items-cente"
                        v-for="termconf in configtermsList"
                        :key="termconf.id"
                    >
                        <span>{{ termconf.name }}</span>
                        <span>
                            <button
                                class="btn btn-danger btn-sm mr-2"                                
                                @click="MetDeleteBtn(termconf.id)"
                            >
                                <i class="fas fa-times"></i>
                            </button>
                        </span>
                    </li>
                </ul>
                <div v-if="NoRecordTerms == true" class="text-center">No Record</div>
            </div>
        </div>
    </div>
</template>
<script>
import Loader from '@/core/components/shared/Loader';
export default {
    components: {
        Loader,
    },
    data () {
        return {
            isLoadingTerms: false,
            configtermsList: [],
            TermsLists: [],
            term_id: '',
            NoRecordTerms: false,
        };
    },
    mounted () {
        this.getTerms();
        this.getTermsList();
    },
    methods: {
        getTerms () {
            this.isLoadingTerms = true;
            window.billing
                .get('termslist/' + window.localuserdata)
                .then((response) => {
                    // console.log(response.data.resultTerms);
                    this.TermsLists = response.data.resultTerms;
                    this.isLoadingTerms = false;
                })
                .catch((error) => console.log(error));
        },
        getTermsList () {
            this.isLoadingTerms = true;
            window.billing
                .get('terms/' + window.localuserdata)
                .then((response) => {
                    // console.log(response.data);
                    this.configtermsList = response.data;
                    if (this.configtermsList.length == 0) {
                        this.NoRecordTerms = true;
                    }
                    this.isLoadingTerms = false;
                })
                .catch((error) => console.log(error));
        },
        MetSAddTerm () {
            this.isLoadingTerms = true;
            window.billing
                .post('term/add', {
                    user_id: window.localuserdata,
                    term_id: this.term_id,
                })
                .then((response) => {
                    // console.log(response.data);
                    this.isLoadingTerms = false;
                    this.term_id = '';
                    this.NoRecordTerms = false;
                    this.getTermsList();
                    if (response.data.status) {
                        this.$swal.fire({
                            icon: 'success',
                            title: 'Add Term Successful',
                            showConfirmButton: false,
                            timer: 2000,
                        });
                    } else {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Term already exists',
                            showConfirmButton: false,
                            timer: 2000,
                        });
                    }
                })
                .catch((error) => console.log(error, (this.isLoadingTerms = false)));
        },
        MetDeleteBtn (idterm) {
            console.log(idterm);
            // debugger;
            this.$swal({
                title: 'Delete this Term?',
                text: 'Are you sure?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes, Delete it!',
            }).then((result) => {
                if (result.value) {
                    window.billing
                        .post('term/delete', {
                            user_id: window.localuserdata,
                            term_id: idterm,
                        })
                        .then((response) => {
                            this.isLoadingTerms = false;
                            if (response.data.status) {
                                this.getTermsList();
                                this.$swal.fire({
                                    icon: 'success',
                                    title: 'Deleted Term Successful',
                                    showConfirmButton: false,
                                    timer: 2000,
                                });
                            } else {
                                this.$swal.fire({
                                    icon: 'error',
                                    title:
                                        'Terms is in use, it is not possible to delete',
                                    showConfirmButton: false,
                                    timer: 5000,
                                });
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            this.isLoadingTerms = false;
                        });
                }
            });
        },
    },
};
</script>
