<template>
    <div>
        <slot>
            <div class="page-breadcrumb">
                <div class="row">
                    <div class="col-md-8 align-self-center">
                        <h4 class="page-title">Setting / Product & Services</h4>
                    </div>
                    <div class="col-md-4 align-self-center">
                        <div class="d-flex align-items-right justify-content-end">
                            <router-link :to="{ name: 'Setting' }" class="btn btn-info btn-sm mr-2 ctrlbtn" style="margin-bottom: 10px;width: -webkit-fill-available;">
                                <i class="fas fa-arrow-left"></i>
                                Back
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card card-body">
                            <div class="row">
                                <div class="col-md-6"> 
                                    <button
                                        style="margin-bottom: 10px;width: -webkit-fill-available;"
                                        class="btn btn-success btn-sm mr-2 float-left ctrlbtn"
                                        @click="MetAddNewBtn"
                                    >
                                        <i class="fas fa-plus"></i> Add Product & Services
                                    </button>                                
                                </div>
                                <div class="col-md-6"> 
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="TextSearch"
                                        placeholder="Filter Products"
                                        style="border: 1px solid #c9c9ca;float: right;margin-bottom: 10px;"
                                    />                              
                                </div>                                
                            </div>                       
                            <div class="contenedor-table">
                                <Loader v-if="isLoading"></Loader>
                                <table v-else class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Account</th>
                                            <th scope="col">Name & Description</th>
                                            <th scope="col">Unit Cost</th>
                                            <th scope="col">Tax</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">
                                                <i class="fas fa-chart-bar"></i>
                                            </th>
                                            <th scope="col" style="padding-right: 75px;"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in filterItems" :key="item.id">
                                            <td scope="row">{{ item.name_account }}</td>
                                            <td>
                                                <span
                                                    style="
                                                        padding: 2px;
                                                        background-color: #eae8e8;
                                                        height: 23px;
                                                        width: 19px;
                                                        display: inline-block;
                                                        text-align: center;
                                                        margin-right: 10px;
                                                        position: relative;
                                                    "
                                                    @click="
                                                        MetDetailcontentBtn(
                                                            item.description
                                                        )
                                                    "
                                                    v-if="item.description !== null"
                                                    ><b>+</b></span
                                                >{{ item.productname }}
                                            </td>
                                            <td>${{ item.unitcost | formatMoney }}</td>
                                            <td>
                                                <span
                                                    v-for="sta in TaxesLists"
                                                    :key="sta.id"
                                                    ><span
                                                        v-if="
                                                            sta.state_id == item.state_id
                                                        "
                                                        >{{ sta.initialstate }}
                                                    </span>
                                                </span>
                                                <span v-if="item.taxname !== null"
                                                    >{{ item.taxname }}%</span
                                                >
                                            </td>
                                            <td>
                                                <span
                                                    class="badge badge-success"
                                                    v-if="item.status == 1"
                                                    >Active</span
                                                >
                                                <span class="badge badge-secondary" v-else
                                                    >Inactive</span
                                                >
                                            </td>
                                            <td>
                                                <button
                                                    type="button"
                                                    class="btn btn-primary btn-sm"
                                                >
                                                    <i class="fas fa-chart-line"></i> Open
                                                </button>
                                            </td>
                                            <td>
                                                <button
                                                    class="btn btn-secondary btn-sm float-right"
                                                    @click="
                                                        MetEditBtn(
                                                            item.id,
                                                            item.account_id,
                                                            item.productname,
                                                            item.quantity,
                                                            item.unitcost,
                                                            item.description,
                                                            item.applytax,
                                                            item.status
                                                        )
                                                    "
                                                >
                                                    <i class="fas fa-edit"></i></button
                                                ><button
                                                    class="btn btn-danger btn-sm mr-2 float-right"
                                                    @click="MetDelBtn(item.id)"
                                                >
                                                    <i class="fas fa-times"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>                                
                                <div v-if="NoRecord == true" class="text-center">
                                    no record
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <modal name="modal-detail" height="auto">
                <div class="card-body">{{ description_product }}</div>
            </modal>
        </slot>
        <!-- modal -->
        <modalLateral>
            <component v-bind:is="m.component"></component>
        </modalLateral>          
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Loader from '@/core/components/shared/Loader'
import modalLateral from '@/core/plugins/themeModalLateral/modalLateral'
import formProductServices from '@/apps/billing/components/setting/formProductServices'
export default {
    components: {
        Loader,
        modalLateral,
        formProductServices
    },
    data () {
        return {
            isLoading: false,
            description_product: null,
            TypeofAccount: [],
            ProdServiceLists: [],
            TextSearch: "",
            TaxesLists: [],
            FormAddPS: {
                btnName: "SAVE",
                Action: "Add",
                id: null,
                productname: null,
                account_id: 0,
                quantity: 1,
                unitcost: 1,
                description: null,
                applytax: 0,
                status: 0,
            },
            errorCosto: false,
            errorQuality: false,
            NoRecord: false,
            m: {
                show: false,
                component: '',
                data: []
            }            
        };
    },
    mounted () {
        this.getProdservices();
        this.getTaxes();
    },
    methods: {
        closeModal() {
            this.$modal.hide("setting-ps");
        },
        MetDetailcontentBtn(desc) {
            this.$modal.show("modal-detail");
            this.description_product = desc;
        },
        getProdservices(load) {
            if(load==undefined){this.isLoading = true;}
            window.billing
                .get("product-services/" + window.localuserdata)
                .then((response) => {
                    console.log("product sevice list table ");
                    console.log(response.data.result);
                    this.ProdServiceLists = response.data.result;
                    if (this.ProdServiceLists.length == 0) {
                        this.NoRecord = true;
                    }
                    this.isLoading = false;
                })
                .catch((error) => console.log(error));
        },
        MetAddNewBtn() {
            this.FormAddPS = {
                btnName: "SAVE",
                Action: "Add",
                id: null,
                productname: null,
                account_id: "",                
                quantity: 1,
                unitcost: null,
                description: null,
                applytax: 0,
                status: 1,
            }
            let t = this
            this.m = {
                show: true,
                component: 'formProductServices',
                data: {FormAddPS:t.FormAddPS}
            }  
            this.$store.commit('core/setOpenModal', this.m);             
        },        
        MetEditBtn(
            id,
            account_id,
            productname,
            quantity,
            unitcost,
            description,
            applytax,
            status
        ) {
            this.FormAddPS = {
                btnName: "UPDATE",
                Action: "Update",
                id: id,
                productname: productname,
                account_id: account_id,
                quantity: quantity,
                unitcost: unitcost,
                description: description,
                applytax: applytax,
                status: status,
            };
            let t = this
            this.m = {
                show: true,
                component: 'formProductServices',
                data: {FormAddPS:t.FormAddPS}
            }   
            this.$store.commit('core/setOpenModal', this.m);             
        },
        MetDelBtn(id) {
            this.isLoading = true;
            this.$swal({
                title: "Delete this Product?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                if (result.value) {
                    window.billing
                        .put("product-services/" + id, {
                            user_id: window.localuserdata,
                        })
                        .then((response) => {
                            console.log(response.data);
                            this.isLoading = false;
                            if (response.data.deleted) {
                                this.getProdservices();
                                this.$swal.fire({
                                    icon: "success",
                                    title: "Deleted Product Successful",
                                    showConfirmButton: false,
                                    timer: 2000,
                                });
                            } else {
                                this.$swal.fire({
                                    icon: "error",
                                    title: "Error",
                                    text:
                                        "It has invoice or estimate assigned to this product.",
                                    showConfirmButton: false,
                                    timer: 6000,
                                });
                            }
                        })
                        .catch((error) => console.log(error, (this.isLoading = false)));
                }
            });
            this.isLoading = false;
        },
        getTaxes() {
            window.billing
                .get("taxes/" + window.localuserdata)
                .then((response) => {
                    console.log(response.data);
                    this.TaxesLists = response.data;
                    if (this.TaxesLists.length == 0) {
                        this.NoRecord = true;
                    }
                    this.isLoading = false;
                })
                .catch((error) => console.log(error));
        },
    },
    computed: {
        filterItems: function () {
            var self = this;
            return this.ProdServiceLists.filter(function (item) {
                return (
                    (item.name_account &&
                        item.name_account
                            .toLowerCase()
                            .indexOf(self.TextSearch.toLowerCase()) >= 0) ||
                    (item.productname &&
                        item.productname
                            .toLowerCase()
                            .indexOf(self.TextSearch.toLowerCase()) >= 0)
                );
            });
        },
        ...mapGetters("core", { 
            getOpenModal: "getOpenModal",
            getActionRefresh: "getActionRefresh", 
        }),
    },
    watch: {
        'getOpenModal': function() {
            // console.log('timesheet getOpenModal', this.getOpenModal.show);
            if (!this.getOpenModal.show) {
                if (Object.keys(this.getOpenModal.data).length > 0) {
                    this.getProdservices();
                }
                this.m = {
                    show: false,
                    component: '',
                    data: {}
                }
            }
        },
        'getActionRefresh': function() {
            if (Object.keys(this.getActionRefresh.data).length > 0) {
                let t = this.getActionRefresh.data;
                if (t.methods === 'refreshList') {
                    this.getProdservices(1);
                }                     
            }
        },
    }
};
</script>
<style lang="scss" scoped>
div[data-popover="myname"] {
    background: #444;
    color: #f9f9f9;
    position: absolute;
    top: -8px !important;
    left: 21px !important;

    font-size: 12px;
    line-height: 1.5;
    margin: 5px;
}
@media (min-width: 1279px) {
    .ctrlbtn {
        width:160px !important;
    }
    .ctrlbtnCustom {
        width:100px !important;
    }    
    .ctrlbtnShow {
        display: none !important;
    }    
}
@media (max-width: 1281px) {
    .ctrlbtnHiden {
        display: none !important;
    }    
}

.contenedor-table table thead tr th {
    font-weight: bold;
}
@media (max-width: 1100px) {
    .contenedor-table {
        max-width: 924px;
        overflow-x: scroll;
    }
}
</style>
