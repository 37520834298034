var formatMoney = require('./formatMoney');
var dateSumarDias = require('./dateSumarDias');
var getQueryVarsUrl = require('./getQueryVarsUrl');
exports.install = function (Vue, options) {
	Vue.prototype.libGlobal = {
		formatMoney: formatMoney.default,
		dateSumarDias: dateSumarDias.default,
		getQueryVarsUrl: getQueryVarsUrl.default,
	};
};
