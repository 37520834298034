<template>
    <div>
        <dropdown-menu
            v-model="show"
            :right="right"
            :hover="hover"
            v-if="orgAll.length > 0"
        >
            <button class="btnDrop" @click="reloj()">
                <span class="nomOrgSelected">{{ orgSelect }} </span>
                <i class="fas fa-briefcase iconMaletin"></i>
                <i
                    class="fas fa-chevron-down chevron"
                    style="font-size: 12px; margin-left: 5px"
                ></i>
            </button>
            <div slot="dropdown" v-if="orgdata">
                <div class="dropdown-item" style="width: 250px">
                    <a class="text-left active">
                        <span style="font-size: 16px; text-transform: initial; color: #ccc;">
                            {{ hourSystem }}
                        </span>
                    </a>
                </div>
                <div
                    class="dropdown-item"
                    v-for="i in orgAll"
                    :key="i.id"
                    @click="ChangeOrg(i.id)"
                    style="width: 250px"
                >
                    <a class="text-left active" v-if="orgIDSelect == i.id">
                        {{ i.bill_company_name }} ( Id: {{i.id}} ) 
                        <b
                            class="badge badge-success"
                            v-if="i.principal == 1"
                            style="margin: 5px; font-size: 9px; float: right"
                            >Primary</b
                        >
                    </a>
                    <a class="text-left noactive" v-else>
                        {{ i.bill_company_name }} ( Id: {{i.id}} ) 
                        <b
                            class="badge badge-success"
                            v-if="i.principal == 1"
                            style="margin: 5px; font-size: 9px; float: right"
                            >Primary</b
                        >
                    </a>
                </div>

                <div class="dropdown-item" 
                v-if="orgdata && user_type==0" 
                style="width: 250px; border-top:1px solid #ccc;">
                    <button type="button" class="btn btn-light btn-sm" @click="AddOrg" style="border: 1px solid #b2b2b2;background-color: #ffffff;">
                        Add new Org
                    </button>
                </div>
            </div>
        </dropdown-menu>
    </div>
</template>

<script>
import DropdownMenu from "@innologica/vue-dropdown-menu";
import { mapGetters } from "vuex";
export default {
    name: "menuOrg",
    components: {
        DropdownMenu,
    },
    data() {
        return {
            isLoading: false,
            show: false,
            right: true,
            hover: false,
            hourSystem: "Day, Hours ...",
            organizationslist: [],
            org_id: 0,
            org_id_old: 0,
            orgSelect: "My Organization",
            orgIDSelect: 0,
            premium : (window.OrgPlan > 1),
            user_type : window.user_type,
        };
    },
    created(){
        this.getOrganizations();
    },
    methods: {
        reloj() {
            window.master
                .get("date/" + window.localorgdata)
                .then((response) => {
                    var d = response.data.day;
                    var hora_back = response.data.hora;
                    var fechaHoraServidor = new Date(response.data.f);
                    var fechaHoraLocal = new Date();
                    var horas = fechaHoraServidor.getHours();
                    var minutos = fechaHoraServidor.getMinutes();
                    var m = "am";
                    if (horas > 0 && horas > 12) {
                        horas = horas - 12;
                        m = "pm";
                    } else if (horas == 0) {
                        horas = 12;
                        m = "am";
                    }
                    if (horas < 10) {
                        horas = "0" + horas;
                    }
                    if (minutos < 10) {
                        minutos = "0" + minutos;
                    }
                    if (hora_back) {
                        this.hourSystem = hora_back;
                    } else {
                        this.hourSystem = d + " " + horas + ":" + minutos + " " + m;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        ChangeOrg(id) {
            var continuarInApp = false;
            this.isLoading = true;
            this.org_id = id;
            let e = this.organizationslist;
            for (let i in e) {
                if (e[i].id == id) {
                    this.orgSelect = e[i].bill_company_name;
                    this.orgIDSelect = id;
                }
            }
            let userfolder = window.localAccountID + "/" + this.org_id;
            localStorage.setItem("mark_client_data", this.org_id);
            localStorage.setItem("folder_user", userfolder);
            localStorage.setItem("nomCompany", this.orgSelect);
            console.log("cambiado org " + this.org_id_old + " por " + this.org_id);
            console.log(
                "obteniendo nueva valiable org_id= " +
                    localStorage.getItem("mark_client_data")
            );
            window.localuserdata = localStorage.getItem("mark_client_data");
            window.localorgdata = localStorage.getItem("mark_client_data");
            console.log(
                "lista de apps de org antes del cambio ",
                this.$store.getters["core/apps/applistorgs"]
            );
            this.$store.commit("set_isLoading", true);
            this.$store
                .dispatch("core/Org/ActiveOrgdataByUser", this.org_id)
                .then((response) => {
                    if(response)
                    {
                        if (
                            this.$router.currentRoute.name === "Home" ||
                            this.$router.currentRoute.name === "Profile" ||
                            this.$router.currentRoute.name === "Organizations" ||
                            this.$router.currentRoute.name === "Security" ||
                            this.$router.currentRoute.name === "Users" ||
                            this.$router.currentRoute.name === "Privilegies" ||
                            this.$router.currentRoute.name === "PrivilegiesList" ||
                            this.$router.currentRoute.name === "AddOrganization"
                        ) {
                            continuarInApp = true;
                            this.redireccionChange(continuarInApp);
                        } else if (window.user_type == 0) {
                            this.$store
                                .dispatch("core/apps/ListAppsbyOrg", id)
                                .then((response) => {
                                    let apps = response;
                                    console.log("apps de org ", apps);
                                    this.validContinuarInApp(apps, continuarInApp);
                                });
                        } else {
                            this.$store
                                .dispatch("core/apps/ListAppsForOrgOfSecondary", id)
                                .then((response) => {
                                    let apps = response;
                                    console.log("userSecundary apps de org ", apps);
                                    this.validContinuarInApp(apps);
                                });
                        }
                    }else{
                        this.$store.commit("set_isLoading", false);
                    }
                });
        },
        validContinuarInApp(apps, continuarInApp) {
            for (let i in apps) {
                if (apps[i].id == localStorage.getItem("applive")) {
                    continuarInApp = true;
                }
            }
            this.redireccionChange(continuarInApp);
        },
        redireccionChange(continuarInApp) {
            if (continuarInApp) {
                window.location.reload(true);
            } else {
                this.$store.dispatch("core/apps/exit_Apps");
                this.$swal.fire({
                    icon: "error",
                    title:
                        "The selected organization does not have this app available, try another one.",
                    showConfirmButton: false,
                    timer: 4000,
                });
                this.$router.push({ name: "Home" });
            }
        },
        getOrganizations() {
            this.organizationslist = this.$store.getters["core/apps/orgAll"];
            this.org_id = localStorage.getItem("mark_client_data");
            this.org_id_old = localStorage.getItem("mark_client_data");
            this.orgSelect = localStorage.getItem("nomCompany");
            this.orgIDSelect = localStorage.getItem("mark_client_data");
            this.$store.dispatch("core/session/IngresoAccount", true);
            if(!localStorage.getItem('appStart')){
                this.$store.commit("set_isLoading", false);
            }
        },
        AddOrg() {
            // this.$parent.$children[2].AddOrganization();
            this.$emit('setTriggerAddOrg', true);
        }
    },
    watch: {
        orgAll: function () {
            console.log('orgAll actualizando')
            this.getOrganizations();
        },
        getHeader: function () {
            if (this.getHeader) {
                this.getOrganizations();
            }
        },
        getPlanId: function () {
            this.premium = (this.getPlanId > 1);
        },
    },
    computed: {
        ...mapGetters("core", { getHeader: "getHeader" }),
        ...mapGetters("core/Org", { 
            orgdata: "orginf",
            orgAll: "orgAll",
            getPlanId: "getPlanId",
        }),
    },
};
</script>
<style lang="css" scoped>
.btnDrop {
    background-color: #222223;
}
@media (max-width: 1024px) {
    i.chevron {
        display: none !important;
    }
    span.pre {
        display: none !important;
    }  
}
</style>
