<template>
    <div>
        <div class="card-header window-header">
            <div class="col-12 d-flex justify-content-start">
                <h3>Project #{{ editItem.id }}</h3>
                <button type="button"
                    class="btn btn-success btn-sm"
                    style="height: 30px;margin-left: 10px; padding: 2px 5px;line-height: 1;"
                    @click="editProjectVideo()"
                    :disabled="isExistSlug"
                >
                    Save
                </button>
            </div>
        </div>
        <form @submit.prevent="editProjectVideo()" id="form1" class="window-body">
            <div v-show="isLoading" class="bgLoaging">
                <Loader :getProgress="apiProgess"></Loader>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="input-group" style="padding:0px;">
                            <input type="text" class="form-control" v-model="urlvideo"
                            required placeholder="https://www.youtube.com/watch?v="
                            style="font-size: 14px;height: 35px;"/>
                        </div>
                        <div class="col-md-12">
                            <iframe class="widget_elemt_iframe_youtube"
                                :src="'https://www.youtube.com/embed/'+previewUrl+'?si=hAJI53Ootkl2qBuH'"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer d-flex justify-content-center">
                <button type="submit"
                    class="btn btn-success"
                    style="width: 100%;"
                    :disabled="isExistSlug">Save Project</button>
            </div>
        </form>
    </div>
</template>
<script>
// @ts-ignore
import Loader from "@/core/components/shared/Loader.vue";
export default {
    components: {
        Loader,
    },
    props: {
        dataModal: {
            type: [Object, String, Number, Array],
            require: false,
            default: null,
        }
    },
    data() {
        return {
            isLoading: false,
            isExistSlug: true,
            apis: null,
            urlDefault: "https://www.youtube.com/watch?v=9xIk_l1UG6I",
            previewUrl: null,
            urlvideo: null,
            premiumProfile: false,
            planActual: 1,
            editItem: null,
            apiProgess: 0,
            errorUrl: null,
        };
    },
    created() {
        let urlApis = this.$store.getters['ModApps/Marketea/urlApis'];
        this.apis = urlApis.Projects;
        // valid premium planID
        this.premiumProfile = (this.$store.getters['core/apps/getPlanIdApp']!=8);
        this.planActual = this.$store.getters['core/apps/getPlanIdApp'];
        if(this.planActual==7){
            this.premiumProfile = true;
            console.log('Plan sponsored, dando permiso de premium', this.premiumProfile);
        }
        console.log('premiumProfile', this.premiumProfile);
        if(this.dataModal!=null){
            this.editItem = this.dataModal;
            // console.log('edit item', this.editItem);
            this.getProjectOpts();
        }
    },
    methods: {
        getProjectOpts(){
            let t = this;
            t.isLoading = true;
            t.urlvideo= null;
            let urlService = t.apis.get(5); //getWithVideo
            // @ts-ignore
            window.master.post(urlService, {
                user_id: window.master_client,
                org_id: window.localorgdata,
                id: t.editItem.id,
                pusher_id: pusher.connection.socket_id,
            }, {
                onUploadProgress: (progressEvent) => {
                    // console.log('onUploadProgress', progressEvent)
                    let scanPer = Math.floor(
                        (progressEvent.loaded*100 / progressEvent.total)
                    );
                    // console.log('scanPer', scanPer);
                    if(scanPer < 99){
                        t.apiProgess = scanPer;
                    }
                }
            })
            .then((response) => {
                t.apiProgess =100;
                // @ts-ignore
                console.log(urlService, response.data);
                if(response.data.video){
                    t.urlvideo = response.data.video.meta_value;
                }else{
                    t.validUrlVideo();
                }
                t.isLoading = false;
            }).catch((error) => {
                console.log(error);
                t.apiProgess =0;
                t.isLoading = false;
                t.validUrlVideo();
            });
        },
        editProjectVideo(){
            let t = this;
            if(!t.isExistSlug){
                t.isLoading = true;
                let payLoad = new FormData();
                // @ts-ignore
                payLoad.append("user_id", window.master_client);
                // @ts-ignore
                payLoad.append("org_id", window.localorgdata);
                // @ts-ignore
                // eslint-disable-next-line no-undef
                payLoad.append("pusher_id", pusher.connection.socket_id);
                payLoad.append("project_id", t.editItem.id);
                payLoad.append("meta_name", 'video');
                payLoad.append("meta_value", t.urlvideo);
                let urlService = t.apis.set(3); // setFeatures
                console.log(urlService)
                // @ts-ignore
                window.master.post(urlService, payLoad, {
                    headers: {
                        common: {
                        // @ts-ignore
                        Authorization: 'Bearer ' + window.localtoken_M
                        },
                        post: { 'Content-Type': 'multipart/form-data' }
                    },
                    onUploadProgress: (progressEvent) => {
                        // console.log('onUploadProgress', progressEvent)
                        let scanPer = Math.floor(
                            (progressEvent.loaded*100 / progressEvent.total)
                        );
                        // console.log('scanPer', scanPer);
                        if(scanPer < 99){
                            t.apiProgess = scanPer;
                        }
                    }
                })
                .then((response) => {
                    t.apiProgess =100;
                    // @ts-ignore
                    console.log(urlService, response.data);
                    if(response.data.status){
                        t.$emit('actionReturn', {action: 'refreshList'});
                    }else{
                        t.$notify({
                            group: "noti",
                            type: 'error',
                            title: "Fail!",
                            text: "Could not be saved",
                        });
                    }
                    t.isLoading = false;
                }).catch((error) => {
                    console.log(error);
                    t.apiProgess =0;
                    t.isLoading = false;
                });
            }
        },
        validUrlVideo(){
            let url = this.urlDefault;
            if(this.urlvideo!=null){
                url = this.urlvideo;
                this.isExistSlug=false;
            }else{
                this.isExistSlug=true;
            }
            let urlIframe = url;
            if(urlIframe.indexOf('&')>-1){
                let u = urlIframe.split('&');
                if(u.length>=0){
                    let watchID = u[0].split('watch?v=');
                    this.previewUrl = watchID[1];
                }
            }else{
                let watchID = urlIframe.split('watch?v=');
                this.previewUrl = watchID[1];
            }
        },
    },
    watch: {
        "urlvideo": function(){
            this.validUrlVideo();
        }
    },
};
</script>

<style lang="scss" scoped>
.window-header{cursor: move;padding: 2px;}
.marginTop{margin-top: 20px;}
.window-body{
    height: calc(100vh - 50px);
    // height: 600px;
    overflow: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    padding-bottom: 0em;
    width: 100%;
    .card-body{padding-top: 0px;}
    &::-webkit-scrollbar:vertical {
        width:3px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #797979;
        border-radius: 20px;
        border: 0px solid #f1f2f3;
    }
    .bgLoaging {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        margin: auto;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9;
        background-color: rgba(255, 255, 255, 0.342);
    }

    .bgLoaging>div {
        margin-left: -100px;
    }
    .widget_elemt_iframe_youtube{
        width: 100%;
        height: 500px;
        margin-top: 20px;
        margin-left: 0px;
        margin-right: 0px;
    }
    .card-footer.d-flex.justify-content-center {
        position: relative;
        width: 100%;
        height: 73px;
        bottom: 0px;
        left: 0px;
    }
}
@media (max-width:767px){
    .window-body{
        height: 100%;
        max-height: 750px;
        padding-bottom: 20em;
    }
}
</style>
