<template>
    <div>
        <div class="card-header window-header" style="padding: 10px 2px;">
            <div class="col-12 d-flex justify-content-start">
                <h2 class="card-title" style="text-transform: capitalize;">{{project_name}}</h2>
            </div>
        </div>
        <div class="window-body">
            <div v-show="isLoading" class="bgLoaging">
                <Loader :getProgress="apiProgess"></Loader>
            </div>
            <div class="card-body">
                <div class="row" style="margin-top: 20px;">
                    <div class="col-5">
                        <h5 class="card-title text-success">
                            Price ${{ price }}
                        </h5>
                        <p class="card-text">
                            <span>
                                <strong>Location:</strong>
                                {{location}}
                            </span>
                            <span>
                                <strong>Space:</strong>
                                {{ space }}
                            </span>
                            <span>
                                <strong>Bedrooms:</strong>
                                {{ bedrooms }}
                            </span>
                            <span>
                                <strong>Baths:</strong>
                                {{baths}}
                            </span>
                            <span>
                                <strong>Lot Size:</strong>
                                {{lot_size}}
                            </span>
                        </p>
                    </div>
                    <div class="col-7">
                        <div class="previewImg" >
                            <img class="card-img"
                            v-if="coverImage!=null && coverImage!='null' && coverImage!=''"
                                :src="pathRootUrl+coverImage+'/600/100'"
                                alt="Card image cap"
                                id="previewImg"
                            >
                            <img class="card-img" src="@/core/assets/img/no-image.png" alt="Card image cap" v-else id="previewImg">
                        </div>
                    </div>
                </div>
                <div class="row" v-if="features!=null">
                    <div class="col-12">
                        <div v-html="features" class="features"></div>
                    </div>
                </div>
                <div class="row" v-if="urlvideo!='' && urlvideo!=null">
                    <div class="col-md-12">
                        <iframe class="widget_elemt_iframe_youtube"
                            :src="'https://www.youtube.com/embed/'+previewUrlVideo+'?si=hAJI53Ootkl2qBuH'"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen
                        ></iframe>
                    </div>
                </div>
                <div class="row" v-if="gallery.length">
                    <div class="gallery_projects">
                        <div v-for="(i, idx) in gallery" :key="idx"
                            :class="['gallery_images',
                                ((idx==0 || (idx)%4==0)) ? 'large' :
                                    (idx==1 || ((idx-1)%4==0)) ?  'prelarga' : 'normal'
                            ]"
                        >
                        <div class="gallery_overlay">
                                <button class="gallery_open" @click="openPopGallery(idx, gallery)"><i class="fas fa-search-plus"></i></button>
                            </div>
                            <div class="gallery_preview" :style="'background-image: url('+i.src+')'">
                            </div>
                        </div>
                    </div>
                    <popGallery
                        :items="viewGalleryPop"
                        :itemIndex="viewImgIdx"
                        :dataPop="editItem"
                        @closePop="resetPop($event)"
                    ></popGallery>
                </div>
                <div class="row" v-if="previewMap!='' && previewMap!=null">
                    <div class="col-md-12">
                        <iframe class="widget_elemt_iframe_map"
                            :src="previewMap"
                            frameborder="0"
                            allowfullscreen
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                            style="height: 350px;"
                        ></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader.vue";
import "@/apps/Marketea/views/plugins/projects/scss_details.scss";
import popGallery from "@/apps/Marketea/views/plugins/projects/popGallery.vue";
export default {
    components: {
        Loader,
        popGallery,
    },
    props: {
        data: {
            type: [Object, String, Number, Array],
            require: false,
            default: null,
        }
    },
    data() {
        return {
            isLoading: false,
            isExistSlug: true,
            apis: null,
            premiumProfile: false,
            planActual: 1,
            editItem: null,
            loadImg: false,
            loadVideo: false,
            loadMap: false,
            urlImg: null,
            coverImage: null,
            imgOld: null,
            fileBlob: null,
            apiProgess: 0,
            errorUrl: null,
            features: null,
            urlDefault: '',
            previewUrlVideo: null,
            urlvideo: null,
            // mapDefault: null,
            mapDefault: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1517337.2149921788!2d-73.00297119894064!3d42.03140120496237!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e3656de973bffd%3A0x45c6d03655830d1c!2sMassachusetts%2C%20EE.%20UU.!5e0!3m2!1ses-419!2sco!4v1702055332819!5m2!1sen-419!2sus",
            previewMap: null,
            urlMap: null,
            project_name: null,
            project_description: null,
            location: 'Milford, MA',
            space: '2000',
            bedrooms: '0',
            baths: '0',
            lot_size: null,
            price: '1000',
            sold: '1000',
            status: '1',
            slug_url: null,
            slug_url_old: null,
            editVideoActive: null,
            editMapActive: null,
            gallery: [],
            filesInput: [],
            filesUploading: [],
            filesLoaded: [],
            fileSelectedIdx: null,
            countUpload: 0,
            pathRootUrl: null,
            pathRootBase64: null,
            filesLoading: false,
            viewImgIdx: -1,
            viewGalleryPop: [],
        };
    },
    created() {
        let urlApis = this.$store.getters['ModApps/Marketea/urlApis'];
        this.apis = urlApis.Projects;
        // valid premium planID
        this.premiumProfile = (this.$store.getters['core/apps/getPlanIdApp']!=8);
        this.planActual = this.$store.getters['core/apps/getPlanIdApp'];
        if(this.planActual==7){
            this.premiumProfile = true;
            console.log('Plan sponsored, dando permiso de premium', this.premiumProfile);
        }
        console.log('premiumProfile', this.premiumProfile);
        if(this.data!=null){
            this.editItem = this.data;
            // console.log('edit item', this.editItem);
            this.pathRootUrl= window.urlmaster+'api/v2/projects/previewImgProject/'+this.editItem.user_id+'/'+ this.editItem.org_id+'/';
            this.pathRootBase64= window.urlmaster+'api/v2/projects/base64ImgProject/'+this.editItem.user_id+'/'+ this.editItem.org_id+'/';
            this.getProjectOpts();
        }
    },
    methods: {
        getProjectOpts(){
            let t = this;
            t.isLoading = true;
            let urlService = t.apis.get(3); // getBySlugUrl
            window.master.post(urlService, {
                user_id: window.master_client,
                org_id: window.localorgdata,
                slug_url: t.editItem.slug_url,
                // eslint-disable-next-line no-undef
                pusher_id: pusher.connection.socket_id,
            }, {
                onUploadProgress: (progressEvent) => {
                    // console.log('onUploadProgress', progressEvent)
                    let scanPer = Math.floor(
                        (progressEvent.loaded*100 / progressEvent.total)
                    );
                    // console.log('scanPer', scanPer);
                    if(scanPer < 99){
                        t.apiProgess = scanPer;
                    }
                }
            })
            .then((response) => {
                t.apiProgess =100;
                console.log(urlService, response.data);
                t.editItem= response.data.r;
                t.getItemProject();
                t.urlImg = response.data.urlImg;
                if(t.editItem.features){
                    t.features = t.editItem.features.meta_value;
                }
                if(t.editItem.video){
                    t.urlvideo = t.editItem.video.meta_value;
                }
                if(t.editItem.map){
                    t.urlMap = t.editItem.map.meta_value;
                }
                if(t.editItem.gallery){
                    t.getGallery();
                }
                t.validUrlVideo();
                t.validUrlMap();
                t.isLoading = false;
            }).catch((error) => {
                console.log(error);
                t.apiProgess =0;
                t.isLoading = false;
            });
        },
        getItemProject(){
            let t = this;
            t.project_name= this.editItem.name;
            t.project_description= this.editItem.description;
            t.location= this.editItem.location;
            t.space= this.editItem.space;
            t.bedrooms= this.editItem.bedrooms;
            t.baths= this.editItem.baths;
            t.lot_size= this.editItem.lot_size;
            t.price= this.editItem.price;
            t.status= this.editItem.status;
            t.coverImage= this.editItem.coverImage;
            t.imgOld= this.editItem.coverImage;
            this.slug_url_old = this.editItem.slug_url;
        },
        auto_height(element) {
            element.style.height = "32px";
            element.style.height = (element.scrollHeight) + "px";
        },
        auto_width(element) {
            element.style.width = "32px";
            element.style.width = (element.scrollWidth) + "px";
            console.log('auto_width', element.style.width)
        },
        validUrlVideo(){
            let url = this.urlDefault;
            if(this.urlvideo!=null && this.urlvideo!='null'){
                url = this.urlvideo;
                this.isExistSlug=false;
            }else{
                this.isExistSlug=true;
            }
            let urlIframe = url;
            if(urlIframe.indexOf('&')>-1){
                let u = urlIframe.split('&');
                if(u.length>=0){
                    let watchID = u[0].split('watch?v=');
                    this.previewUrlVideo = watchID[1];
                }
            }else{
                let watchID = urlIframe.split('watch?v=');
                this.previewUrlVideo = watchID[1];
            }
            console.log('url video', this.previewUrlVideo)
        },
        validUrlMap(){
            let t = this;
            let url = this.mapDefault;
            if(this.urlMap!=null && this.urlMap.length){
                url = this.urlMap;
                this.isExistSlug=false;
                let iframe = url.split(' ');
                for (const i in iframe) {
                    if (iframe[i].indexOf('src="')>-1){
                        this.previewMap= iframe[i].slice(5, iframe[i].length-1);
                    }
                }
            }else{
                this.isExistSlug=true;
                this.previewMap= url;
            }
            console.log('previewMap', this.previewMap)
        },
        getGallery(){
            let t = this;
            let g =(t.editItem.gallery.meta_value!=null) ? JSON.parse(t.editItem.gallery.meta_value) : [];
            t.gallery = [];
            if(g.length>0){
                g.filter(i => {
                    t.gallery.push({
                        src: t.pathRootUrl+i+'/600/100',
                        filename: i,
                        isLoading: false,
                        progress: 0,
                    });
                });
            }
            console.log('getGallery', t.gallery)
        },
        galleryMetaValue(object){
            let t = this;
            let gallery = [];
            for (const key in object) {
                if (Object.hasOwnProperty.call(object, key)) {
                    gallery.push(object[key].filename);
                }
            }
            return JSON.stringify(gallery);
        },
        openPopGallery(idx, galleryList){
            this.viewImgIdx = idx;
            this.viewGalleryPop = galleryList;
            console.log('openPopGallery', idx, galleryList);
        },
        resetPop(val){
            console.log('resetPop', val);
            this.viewImgIdx = -1;
            this.viewGalleryPop = [];
        }
    },
    watch: {
        "urlvideo": function(){
            this.validUrlVideo();
        },
        "urlMap": function(){
            this.validUrlMap();
        }
    },
    computed: {
    }
};
</script>

<style lang="scss" scoped>
.window-header{cursor: move;padding: 2px;}
.marginTop{margin-top: 20px;}
.window-body{
    height: calc(100vh - 50px);
    // height: 600px;
    overflow: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    padding-bottom: 0em;
    width: 100%;
    .card-body{padding-top: 0px;}
    &::-webkit-scrollbar:vertical {
        width:3px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #797979;
        border-radius: 20px;
        border: 0px solid #f1f2f3;
    }
    .bgLoaging {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        margin: auto;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9;
        background-color: rgba(255, 255, 255, 0.7);
    }

    .bgLoaging>div {
        margin-left: -100px;
    }
    .widget_elemt_iframe_map{
        width: 100%;
        height: 500px;
        margin-top: 20px;
        margin-left: 0px;
        margin-right: 0px;
    }
    h2.card-title{
        font-size: 30px;
        color: #000;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #ddd;
    }
    h5.card-title{
        margin-bottom: 0px;
        color: #000;
        font-size: 20px;
    }
    p.card-text {
        margin-top: 10px;
        margin-bottom: 10px;
        color: #000;
        font-size: 16px;
        line-height: 1.3;
        span{
            display: block;
        }
    }
    .previewImg{
        position: relative;
        width: 100%;
        max-width: 400px;
        margin: 4px auto;
        overflow: hidden;
        border:1px solid #000;
        img{
            width: 100%;
            height: auto;
            &.noImg{
                margin: auto;
                display: block;
            }
        }
        .btn-upload-img{
            position: absolute;
            z-index: 1;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-content: center;
            justify-content: center;
            align-items: center;
            text-shadow: 1px 0px 10px rgb(255 255 255 / 50%);
            color: #4c32e9;
            font-size: 50px;
            opacity: 0.5;
            &:hover{
            background-color: rgb(0 0 0 / 0.2);
            opacity: 1;
            cursor: pointer;
            }
        }
    }
    .widget_elemt_iframe_youtube{
        width: 100%;
        height: 500px;
        margin-top: 20px;
        margin-left: 0px;
        margin-right: 0px;
    }
    .widget_elemt_iframe_map{
        width: 100%;
        height: 500px;
        margin-top: 20px;
        margin-left: 0px;
        margin-right: 0px;
    }
    .edit_pop{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: transparent;
        border: 1px solid #fff;
        z-index: 1;
        .delItemPop {
            position: absolute;
            z-index: 3;
            top: 20px;
            right: 12px;
            color: #fff;
            background: red;
            padding: 5px 12px;
            border-radius: 4px;
            opacity: 0.3;
            border:1px solid red;
            box-shadow: none;
            font-size: 1.5em;
        }
        .editItemPop {
            position: absolute;
            height: 90%;
            width: 90%;
            z-index: 2;
            top: 5%;
            right: 0;
            left: 0;
            margin: auto;
            color: #fff;
            background: transparent;
            padding: 5px 8px;
            opacity: 0.3;
            border: 0px;
            box-shadow: none;
            font-size: 5em;
        }
        .formEditPop{
            position: absolute;
            height: 90%;
            width: 90%;
            z-index: 2;
            top: 5%;
            right: 0;
            left: 0;
            margin: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .input-group{
                .form-control{
                    border-radius: 0px;
                }
                .input-group-text{
                    &.backspace{
                        border-right: 0px;
                        border-top-right-radius: 0px;
                        border-bottom-right-radius: 0px;
                        color: blue;
                    }
                    &.times{
                        border-left: 0px;
                        border-radius: 0px;
                        color: red;
                    }
                    &.check{
                        border-left: 0px;
                        border-radius: 0px;
                        color: green;
                    }
                }
            }
        }
        &:hover{
            cursor: pointer;
            background: rgba(0, 0, 0, 0.26);
            border-color: #eed919;
            .delItemPop {
                opacity: 0.8;
                &:hover{
                    opacity: 1;
                    cursor: pointer;
                }
            }
            .editItemPop {
                opacity: 0.8;
                &:hover{
                    opacity: 1;
                    cursor: pointer;
                }
            }
        }
    }
    .edit_text{
        background: transparent;
        border: 1px solid #fff;
        &:hover{
            cursor: alias;
            border-color: #eed919;
        }
    }
    .gallery_projects{
        position: relative;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        .gallery_images{
            position: relative;
            margin: 1%;
            height: 250px;
            &.normal{
                flex: 0 0 48%;
                max-width: 48%;
            }
            &.large{
                flex: 0 0 58%;
                max-width: 58%;
            }
            &.prelarga{
                flex: 0 0 38%;
                max-width: 38%;
            }
            &.gallery_add{
                flex: 0 0 38%;
                max-width: 38%;
            }
            .gallery_overlay {
                position: absolute;
                z-index: 2;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background: rgba(0 0 0 /40%);
                color: #fff;
                font-size: 2em;
                opacity: 0;
                &.add_image{
                    opacity: 1;
                    background: rgba(0 0 0 /20%);
                    font-size: 4em;
                    text-shadow: 5px -5px 1px #807070;
                }
                .gallery_move{
                    box-shadow: none;
                    border: 0px;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 15%;
                    max-width: 50px;
                    margin-right: 5%;
                    color: #bdbdbd;
                    background: #dee6f5;
                    &:hover{
                        cursor: move;
                        color: #555;
                    }
                }
                .gallery_open{
                    box-shadow: none;
                    border: 0px;
                    position: absolute;
                    top: 20px;
                    margin: auto;
                    width: 200px;
                    height: 200px;
                    color: #c0bcbc;
                    background: transparent;
                    &:hover{
                        color: #fff;
                        cursor: zoom-in;
                        box-shadow: 1px 1px 20px 2px rgb(0 0 0 / 40%), inset 1px 1px 20px 2px rgba(218, 218, 218, 0.4);
                        border-radius: 50%;
                    }
                }
                .gallery_del{
                    box-shadow: none;
                    border: 0px;
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    width: 15%;
                    max-width: 50px;
                    color: #fff;
                    background: #ac1d13;
                    &:hover{
                        cursor: pointer;
                    }
                }
            }
            .gallery_preview{
                position: relative;
                z-index: 1;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-clip: border-box;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                background-color: rgb(241 241 241 / 50%);
                .gallery_load{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    margin: auto;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background-color: rgba(0 0 0 / 25%);
                }
                .gallery_success{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    margin: auto;
                    display: none;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background-color: rgba(255, 255, 255, 0.25);
                    font-size: 4em;
                    text-shadow: 5px -5px 1px #f5eeee;
                    color: #17c417;
                    overflow: hidden;
                    &.show{display: flex;}
                }
                .gallery_img{
                    width: 100%;
                    height: auto;
                }
            }
            &:hover{
                .gallery_overlay{opacity:1; }
            }
        }
    }
}
@media (max-width:767px){
    .window-body{
        height: 100%;
        max-height: 750px;
        padding-bottom: 20em;
    }
}
.features {
    line-height: 1.3;
    margin-top: 40px;
}
</style>
