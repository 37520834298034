let getMethods = function() {
	var self = this;
	window.billing
	.get("paymentsmetodos")
	.then((response) => {
		let Metodos = [];
		for (let item in response.data.result) {
			Metodos.push({
				value: response.data.result[item].id,
				text: response.data.result[item].name,
			});
		}
		self.PaymentMethodsList = Metodos;
	})
	.catch((error) => {
		console.log(error);
	});
};
export default getMethods;
