<template>
    <div class="main_sales">
        <div class="container">
            <div class="row" v-if="isLoading">
                <div class="col-12" >
                    <Loader></Loader>
                </div>
            </div>
            <div  class="row" v-else>
                <div class="col-12">
                    <!-- {{salesData}} -->
                </div>
                <div id="Appointment">
                    <div class="containerAppointment">
                        <div id="title" class="title">
                            <h1>Appointment </h1>
                        </div>
                        <div id="info" class="info">
                            <div class="row">
                                <div class="col-12">
                                    <p><strong>Lead Name:</strong> {{salesData.title}}</p>
                                </div>
                                <div class="col-12">
                                    <p><strong>Datetime:</strong> {{ meeting_date | moment("MMM DD, YYYY") }} at {{ convertTo12HourFormat(meeting_hour) }}</p>
                                </div>
                                <div class="col-12">
                                    <p>
                                        <strong>You have a meeting with:</strong> {{custom_name}} <br>
                                        <span v-if="custom_contact">
                                            <strong>Phone:</strong> <a :href="'tel:'+custom_contact" title="Call now">{{formatPhoneNumberWithCountryCode(custom_contact)}}</a>
                                        </span>
                                        <br>
                                        <span v-if="custom_email">
                                            <strong>Email: </strong> <a :href="'mailto:'+custom_email" title="Contact now">{{ custom_email }}</a>
                                        </span>
                                    </p>
                                </div>
                                <div class="col-12">
                                    <p>
                                        <strong>REPORT:</strong>
                                        <span v-if="report"> {{ report }}</span>
                                        <span v-else> Scheduled meeting.</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import Loader from "@/core/components/shared/Loader.vue";
export default {
    components: {
        Loader,
    },
    data(){
        return {
            isLoading: false,
            saledId: null,
            appttId: null,
            salesData: null,
            custom_name: null,
            custom_contact: null,
            custom_email: null,
            report: null,
            meeting_date: null,
            meeting_hour: null,
        };
    },
    created() {
        let t = this; // objecto Vue
        t.isLoading = true;
        if(t.$route.params.salesId && t.$route.params.appttId){
            //solo si existen las variables de url
            // consultar sales
            t.saledId = t.$route.params.salesId
            t.appttId = t.$route.params.appttId
            t.getSales();
        }
    },
    watch: {
    },
    computed: {
        ...mapGetters("core", { getPusher: "get_accitonPusher"}),
    },
    methods: {
        getSales() {
            let t = this;
            window.masterApiv2.post('sales/getById', {
                id : t.saledId,
                apt_Id: t.appttId
            })
            .then((response) => {
                console.log(response);
                if(response.data.status){
                    t.salesData = response.data.sales
                    if(response.data.perfil_lead){
                        t.custom_name = response.data.perfil_lead.name +' ' +response.data.perfil_lead.lastname;
                        t.custom_contact = response.data.perfil_lead.phone;
                        t.custom_email = response.data.perfil_lead.email;
                    }
                    if(response.data.apptt){
                        t.meeting_date = new Date(response.data.apptt.dateM); // sacar solo date
                        t.meeting_hour = response.data.apptt.hourM;
                        t.report = response.data.apptt.report;
                    }
                }
                t.isLoading = false;
            })
            .catch((error) => {
				console.log(error);
                t.isLoading = false;
			});
        },
        convertTo12HourFormat(time24) {
			// Extraer la hora y los minutos del tiempo en formato de 24 horas
			let [hour, minute] = time24.split(':').map(Number);
			// Determinar si es AM o PM
			let period = hour >= 12 ? 'PM' : 'AM';
			// Convertir la hora al formato de 12 horas
			hour = hour % 12 || 12; // La hora '0' debería convertirse en '12'
			// Asegurarse de que los minutos tengan dos dígitos
			minute = minute < 10 ? `0${minute}` : minute;
			// Devolver la hora formateada
			return `${hour}:${minute} ${period}`;
		},
        formatPhoneNumberWithCountryCode(phoneNumber) {
            // Eliminar todos los caracteres no numéricos
            const cleaned = phoneNumber.replace(/\D/g, '');

            // Variables para almacenar el código de país y el número
            let countryCode = '';
            let mainNumber = cleaned;

            // Detectar y extraer el código de país si está presente
            if (cleaned.length > 10) {
            // Extraer el código de país asumiendo que es el prefijo antes de los 10 dígitos del número
            countryCode = '+' + cleaned.slice(0, cleaned.length - 10);
            mainNumber = cleaned.slice(-10);
            }

            // Verificar que el número principal tenga exactamente 10 dígitos
            if (mainNumber.length !== 10) {
            throw new Error("El número de teléfono debe contener exactamente 10 dígitos.");
            }

            // Formatear el número de teléfono
            const formattedMainNumber = mainNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3');

            // Devolver el número formateado con el código de país si existe
            return countryCode ? `${countryCode} ${formattedMainNumber}` : formattedMainNumber;
        }
    }
}
</script>

<style lang="scss" scoped>
.main_sales{
    #Appointment{
        box-shadow: 0px 0px 4px #ccc;
        padding: 15px;
        margin: 50px auto;
        background: #fff
    }
    .containerAppointment{
        max-width: 515px;
        margin: 0px auto;
        border: 1px solid #ccc;
        padding: 10px;
    }
    .title::after{
        content: "";
        width: 75px;
        height: 2px;
        display: block;
        background-color: #ccc;
        margin: 0px auto;
    }
    .title h1{
        text-transform: uppercase;
        text-align: center;
        font-size: 2.5rem;
        margin-bottom: 0px;
    }
    #info.info  strong{
        font-size: 1rem;
    }
    #info.info {
        font-size: 1.1rem;
    }
    #info.info p{ margin-bottom: 0px; line-height: 2;}
}
</style>
