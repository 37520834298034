<template>
    <div class="row tabla-transaction">
        <table class="table table-striped table-bordered table-hover" style="margin-bottom: 320px">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Type</th>
                    <th scope="col">Name</th>
                    <th scope="col">Date</th>
                    <th scope="col" style="text-align: right">
                        Total
                    </th>
                    <th scope="col" style="text-align: right">
                        Balance
                    </th>
                    <th
                        scope="col"
                        style="text-align: center"
                    >
                        Status
                    </th>
                    <th
                        scope="col"
                        style="text-align: center"
                    >
                        Email
                    </th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody v-if="tablaValid">
                <tr v-for="(i, index) in transactionsList" v-bind:key="index">
                    <td>{{i.nro.toString().padStart(4, "000")}}</td>
                    <td>{{ i.type }}</td>
                    <td>{{ i.name }}</td>
                    <td>{{ i.fecha }}</td>
                    <td style="text-align: right">
                        $ {{ i.total | formatMoney }}
                    </td>
                    <td style="text-align: right">
                        <div v-if="i.type != 'Recurring Invoice'">
                            $ {{ i.balance | formatMoney }}
                        </div>
                    </td>
                    <td style="text-align: center">
                        <div v-if="i.type == 'Payment'">
                            <span v-if="i.status == 1" class="badge badge-success">Accepted</span>
                            <span v-if="i.status == 0" class="badge badge-danger" style="background-color: #b94a48;">Void</span>
                        </div>
                        <div v-else-if="i.type == 'Invoice'">
                            <span v-if="i.status == 1" class="badge badge-secondary" style="background-color: #f89406;">Pending</span>
                            <span v-else-if="i.status == 2" class="badge badge-primary">Partially Paid</span>
                            <span v-else-if="i.status == 3" class="badge badge-success">Paid</span>
                            <span v-else-if="i.status == 4" class="badge badge-primary" style="background-color: #e90404;">Overdue</span>
                            <span v-else-if="i.status == 5" class="badge badge-danger" style="background-color: #b94a48;">Void</span>
                        </div>
                        <div v-else-if="i.type == 'Recurring Invoice'">
                            <span v-if="i.status == 1" class="badge badge-success">Active</span>
                            <span v-else class="badge badge-primary" style="background-color: #e90404;">Inactive</span>
                        </div>
                        <div v-else-if="i.type == 'Estimate'">
                            <span v-if="i.status == 1" class="badge badge-secondary" style="background-color: #f89406;">Pending</span>
                            <span v-if="i.status == 2" class="badge badge-success">Accepted</span>
                            <span v-if="i.status == 3" class="badge badge-primary">Rejected</span >
                            <span v-if="i.status == 4" class="badge badge-danger" style="background-color: #b94a48;">Void</span>
                        </div>
                    </td>
                    <td style="text-align: center">
                        <div v-if="i.type != 'Recurring Invoice'">
                            <button style="border: 0;background: transparent;cursor: pointer;font-size: 13px;"
                                @click="actionEmail(i.id, i.nro, i.type, i.email)">
                                <span class="count-email">{{ i.nro_email }}</span>
                                <i class="fas fa-envelope" style="color: #f89406;font-size: 18px;"></i>
                            </button>
                        </div>
                    </td>
                    <td style="">
                        <div v-if="i.type == 'Invoice'">
                            <span class="cdropdown">
                                <button>
                                    <span><i class="fas fa-cog"></i></span>
                                </button>
                                <label>
                                    <input type="checkbox" />
                                    <ul>
                                        <li @click="actionPdfInvo(i.id)">
                                            <button>
                                                <i class="fas fa-file-pdf"></i>
                                                Open PDF
                                            </button>
                                        </li>
                                        <li @click="actionPdfInvoEnv(i.id)" v-if="i.idxstatus != 4">
                                            <button>
                                                <i class="fas fa-file-pdf"></i>
                                                Open PDF for
                                                Envelope
                                            </button>
                                        </li>
                                        <li @click="actionPrintInvo(i.id)">
                                            <button>
                                                <i class="fas fa-print"></i>
                                                ePrint
                                            </button>
                                        </li>
                                        <li @click="statement(i.id, i.customer_id)">
                                            <button>
                                                <i class="fas fa-file-invoice-dollar"></i>
                                                Statement
                                            </button>
                                        </li>
                                        <li @click="actionEmail(i.id, i.nro, i.type, i.email)">
                                            <button>
                                                <i class="far fa-envelope"></i>
                                                Email
                                            </button>
                                        </li>
                                        <li v-if="i.status != 4" @click="actionDuplicateInvo(i.id)">
                                            <button>
                                                <i class="fas fa-copy"></i>
                                                Duplicate
                                            </button>
                                        </li>
                                        <li @click="convertRecurringT(i.id)">
                                            <button>
                                                <i class="fas fa-file-alt"></i>
                                                Convert to Recurring Invoices
                                            </button>
                                        </li>
                                        <li v-if="i.idxstatus != 2 && i.idxstatus != 3 && i.idxstatus != 5" @click="actionEditInvo(i.id)">
                                            <button>
                                                <i class="fas fa-edit"></i>
                                                Edit
                                            </button>
                                        </li>
                                        <li v-if="i.idxstatus != 4" @click="actionVoidInvo(i.id)">
                                            <button>
                                                <i class="fas fa-trash-alt"></i>
                                                Void
                                            </button>
                                        </li>
                                        <li @click="MetDeleteInvoice(i.id)">
                                            <button>
                                                <i class="fas fa-times"></i>
                                                Delete
                                            </button>
                                        </li>
                                    </ul>
                                </label>
                            </span>
                        </div>
                        <div v-else-if="i.type == 'Estimate'">
                            <span class="cdropdown">
                                <button><i class="fas fa-cog"></i></button>
                                <label>
                                    <input type="checkbox" />
                                    <ul>
                                        <li @click="actionPdfEst(i.id)">
                                            <button>
                                                <i class="fas fa-file-pdf"></i>
                                                Open PDF
                                            </button>
                                        </li>
                                        <li @click="actionPrintEst(i.id)">
                                            <button>
                                                <i class="fas fa-print"></i>
                                                Print
                                            </button>
                                        </li>
                                        <li @click="actionEmail(i.id, i.nro, i.type, i.email )">
                                            <button>
                                                <i class="far fa-envelope"></i>
                                                Email
                                            </button>
                                        </li>
                                        <li v-if="i.status != 4" @click="actionDuplicateEst(i.id)">
                                            <button>
                                                <i class="fas fa-copy"></i>
                                                Duplicate
                                            </button>
                                        </li>
                                        <li v-if="i.status == 2 " @click="convertToInvoice(i.id)">
                                            <button>
                                                <i class="far fa-file-alt"></i>
                                                Convert To Invoice
                                            </button>
                                        </li>
                                        <li v-if="i.status != 4  && i.status != 5" @click=" actionEditEst(i.id)">
                                            <button>
                                                <i class="fas fa-edit"></i>
                                                Edit
                                            </button>
                                        </li>
                                        <li v-if="i.status != 4  && i.status != 5" @click="actionVoidEst(i.id)">
                                            <button>
                                                <i class="fas fa-trash-alt"></i>
                                                Void
                                            </button>
                                        </li>
                                        <li v-if="i.status != 4  && i.status != 5" @click="MetDeleteEstimate(i.id)">
                                            <button>
                                                <i class="fas fa-times"></i>
                                                Delete
                                            </button>
                                        </li>
                                    </ul>
                                </label>
                            </span>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr>
                    <td colspan="10" class="text-center">
                        <Loader v-if="transacValid"></Loader><span v-else>No record</span>
                    </td>
                </tr>
            </tbody>
        </table>
        <!-- modal -->
        <modalLateral>
            <component v-bind:is="m.component" :items="DataMailTransac"></component>
        </modalLateral>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import modalLateral from '@/core/plugins/themeModalLateral/modalLateral'
import modalEmail from '@/apps/billing/components/modalEmail'
export default {
    name: "counterCustomer",
    components: { 
        modalLateral,
        modalEmail
    },    
    data () {
        return { 
            m: {
                show: false,
                component: '',
                data: []
            },            
            isLoading:false,
            tablaValid: false,
            transacValid:false,
            transactionsList: [],
            paginador: 100,
            DataMailTransac: {
                isLoading: false,
                id: null,
                IDitem: null, // numdoc
                email: null,
                type: null,
                EmailCheck: false,
                view: "transac",
            },            
        }
    },
    created () {
        let getCustomerData = this.$store.getters['ModApps/billing/getCustomerData'];
        if (getCustomerData.length > 0) {
            this.detailCustomer(getCustomerData)
        }     
    },
    methods: { 
        detailCustomer(transac) {
            this.transactionsList = transac;
            if (this.transactionsList.length > 0) {
                this.tablaValid = true;
                this.transacValid = true;
                for (let i in transac) {
                    if (transac[i].type == "Invoice") {
                        let f = new Date(transac[i].fv);
                        let fa = new Date();
                        if (
                            fa > f &&
                            transac[i].status != 3 &&
                            transac[i].status != 5 &&
                            transac[i].status != 2
                        ) {
                            transac[i].status = 4;
                        }
                    }
                }
            } else {
                this.tablaValid = false;
                this.transacValid = false;
            }
        },          
        actionEmail(id, nro, type, email) {
            type = type.toLowerCase();
            this.DataMailTransac.idinv = id;
            this.DataMailTransac.IDitem = nro;
            this.DataMailTransac.email = email;
            this.DataMailTransac.type = type;
            this.DataMailTransac.view = type;            
            let t = this;
            this.m = {
                show: true,
                component: 'modalEmail',
                data: {DataMailTransac:t.DataMailTransac}
            }  
            this.$store.commit('core/setOpenModal', this.m);
        },  
        actionPdfInvo(id) {
            window.open(
                window.urlbase +
                    "api/v2/invoice/pdf/" +
                    id +
                    "/" +
                    window.localAccountID +
                    "/" +
                    localStorage.getItem("oauth_m"),
                "_blank"
            );
        },  
        actionPdfInvoEnv(id) {
            window.open(
                window.urlbase +
                    "api/v2/invoiceenvelopes/pdf/" +
                    id +
                    "/" +
                    window.localAccountID +
                    "/" +
                    localStorage.getItem("oauth_m"),
                "_blank"
            );
        }, 
        actionPrintInvo(idinv) {
            this.isLoading = true;
            window.billing
                .post("eprintinvo/" + idinv, {
                    user_id: window.localuserdata,
                    eprint: 1,
                })
                .then((response) => {
                    console.log(response.data);
                    this.isLoading = false;
                    if (response.data.status) {
                        this.$notify({
                            group: "noti",
                            title: "Send!",
                            type: "success",
                            text: "Send Successful",
                        });
                    } else {
                        this.$notify({
                            group: "noti",
                            title: "Send Mail!",
                            type: "error",
                            text: "Send error",
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.$notify({
                        group: "noti",
                        title: "Send!",
                        type: "error",
                        text: "Send Error",
                    });
                });
        },  
        statement(id, idcus) {
            this.$router.push("statement/" + id + "/" + idcus);
        }, 
        actionDuplicateInvo(id) {
            this.$router.push("invoice/" + id + "/duplicate");
        },   
        convertRecurringT(id) {
            this.$router.push({
                name: "InvoiceRecConvert",
                params: { idinv: id, convert: "convert" },
            });
        }, 
        actionEditInvo(id) {
            this.$router.push({
                name: "InvoiceDet",
                params: { idinv: id, edit: "edit" },
            });
        }, 
        actionVoidInvo(idinv) {
            this.isLoading = true;
            this.$swal({
                title: "Void this Invoice?",
                text: "You will not be able to recover it",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Void it!",
            }).then((result) => {
                if (result.value) {
                    window.billing
                        .put("invoice/void/" + idinv, {
                            user_id: window.localuserdata,
                        })
                        .then((response) => {
                            this.isLoading = false;
                            if (response.data.status) {
                                this.getTransactions();
                                this.$notify({
                                    group: "noti",
                                    title: "Void!",
                                    type: "success",
                                    text: "Void Invoice Successful.",
                                });
                            }
                        })
                        .catch((error) => console.log(error, (this.isLoading = false)));
                }
            });
            this.isLoading = false;
        }, 
        getTransactions() {
            this.tablaValid = false;
            window.billing
                .post("customer/" + this.customerID, {
                    user_id: window.localuserdata,
                    trans: this.transactionsID,
                    pag: this.paginador,
                })
                .then((response) => {
                    let transac = response.data.trasaction;
                    this.transactionsList = transac;
                    if (this.transactionsList.length > 0) {
                        this.tablaValid = true;
                        this.transacValid = true;
                        for (let i in transac) {
                            if (transac[i].type == "Invoice") {
                                let f = new Date(transac[i].fv);
                                let fa = new Date();
                                if (
                                    fa > f &&
                                    transac[i].status != 3 &&
                                    transac[i].status != 5 &&
                                    transac[i].status != 2
                                ) {
                                    transac[i].status = 4;
                                }
                            }
                        }
                    } else {
                        this.tablaValid = false;
                        this.transacValid = false;
                    }
                })
                .catch((error) => console.log(error));
        },
        MetDeleteInvoice(idinv) {
            this.isLoading = true;
            this.$swal({
                title: "Delete this Invoice?",
                text: "Are you sure?",
                icon: "warn ",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                if (result.value) {
                    window.billing
                        .put("invoice/delete/" + idinv, {
                            user_id: window.localuserdata,
                        })
                        .then((response) => {
                            console.log(response.data);
                            this.isLoading = false;
                            if (response.data.status) {
                                this.getTransactions();
                                this.$notify({
                                    group: "noti",
                                    title: "Success",
                                    type: "success",
                                    text: "Deleted Invoice Successful.",
                                });
                            }
                        })
                        .catch((error) => console.log(error, (this.isLoading = false)));
                }
            });
            this.isLoading = false;
        },
        actionPdfEst(id) {
            window.open(window.urlbase + "api/v2/estimate/pdf/" + id, "_blank");
        },
        actionPrintEst(idest) {
            this.isLoading = true;
            window.billing
                .post("eprintest/" + idest, {
                    user_id: window.localuserdata,
                    eprint: 1,
                })
                .then((response) => {
                    console.log(response.data);
                    this.isLoading = false;
                    if (response.data.status) {
                        this.$notify({
                            group: "noti",
                            title: "Send Mail!",
                            type: "success",
                            text: "Send Successful",
                        });
                    } else {
                        this.$notify({
                            group: "noti",
                            title: "Send Mail!",
                            type: "error",
                            text: "Send error",
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.$notify({
                        group: "noti",
                        title: "Send Mail!",
                        type: "error",
                        text: "Send error",
                    });
                });
        }, 
        actionDuplicateEst(id) {
            let t =this;
            let planPremium = (t.$store.getters['core/Org/getPlanId'] > 1);
            if(!planPremium){
                this.$router.push("estimate/" + id + "/duplicate");
            }else{
                this.$router.push("estimate/" + id + "/duplicate");
                // t.$router.push({
                //     name: "estproFormDuplicate",
                //     params: { idest: id, duplicate: "duplicate" },
                // });
            }
        },
        convertToInvoice(id) {
            this.$router.push({
                name: "EstimatesConvertInvoice",
                params: { idest: id, convert: "convert" },
            });
        }, 
        actionEditEst(id) {
            let t =this;
            let planPremium = (t.$store.getters['core/Org/getPlanId'] > 1);
            if(!planPremium){
                t.$router.push({
                    name: "EstimatesDet",
                    params: { idest: id },
                });
            }else{
                t.$router.push({
                    name: "EstimatesDet",
                    params: { idest: id },
                });
                // t.$router.push({
                //     name: "estproFormEdit",
                //     params: { idest: id },
                // });
            }
        },
        actionVoidEst(idest) {
            this.isLoading = true;
            this.$swal({
                title: "Void this Estimate?",
                text: "You will not be able to recover it",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Void it!",
            }).then((result) => {
                if (result.value) {
                    window.billing
                        .put("estimate/void/" + idest, {
                            user_id: window.localuserdata,
                        })
                        .then((response) => {
                            this.isLoading = false;
                            if (response.data.status) {
                                this.getTransactions();
                                this.$notify({
                                    group: "noti",
                                    title: "Void!",
                                    type: "success",
                                    text: "Void Estimate Successful.",
                                });
                            }
                        })
                        .catch((error) => console.log(error, (this.isLoading = false)));
                }
            });
            this.isLoading = false;
        }, 
        MetDeleteEstimate(idest) {
            this.isLoading = true;
            this.$swal({
                title: "Delete this Estimate?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                if (result.value) {
                    window.billing
                        .put("estimate/delete/" + idest, {
                            user_id: window.localuserdata,
                        })
                        .then((response) => {
                            this.isLoading = false;
                            if (response.data.status) {
                                this.getTransactions();
                                this.$notify({
                                    group: "noti",
                                    title: "Deleted!",
                                    type: "success",
                                    text: "Deleted Estimate Successful.",
                                });
                            }
                        })
                        .catch((error) => console.log(error, (this.isLoading = false)));
                }
            });
            this.isLoading = false;
        },                                                                                                                                                   
    },
	computed: {
        ...mapGetters("ModApps/billing", { 
            getCustomerData: "getCustomerData",
        }), 
	},
	watch: {
        getCustomerData: function () {
            if (this.getCustomerData.length > 0) {
            this.detailCustomer(this.getCustomerData)
        }
        }
    },
};
</script>
<style lang="scss" scoped>
    .tabla-transaction {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: auto;
        table { height: fit-content;}
        table thead th,
        table tbody td{
            height: 35px;
        }
    }
    @media (min-width: 1366px){
        .tabla-transaction {
            height: calc(100vh - 320px);
        }    
    }
    .tabla-transaction table thead tr th:nth-child(2),
    .tabla-transaction table tbody tr td:nth-child(2){
        width: 85px;
    }
    .tabla-transaction table thead tr th:nth-child(4),
    .tabla-transaction table tbody tr td:nth-child(4){
        width: 100px;
    }
    .tabla-transaction table thead tr th:nth-child(5),
    .tabla-transaction table tbody tr td:nth-child(5){
        width: 130px;
        min-width: 130px;
        max-width: 130px;
    }
    .tabla-transaction table thead tr th:nth-child(6),
    .tabla-transaction table tbody tr td:nth-child(6){
        width: 130px;
        min-width: 130px;
        max-width: 130px;
    }
    .tabla-transaction table thead tr th:nth-child(8),
    .tabla-transaction table tbody tr td:nth-child(8){
        width: 60px;
    }
    .tabla-transaction table thead tr th:nth-child(9),
    .tabla-transaction table tbody tr td:nth-child(9){
        width: 58px;
        min-width: 58px;
        max-width: 58px;
    }
    .count-email{ margin-right: 5px;}
    .tabla-transaction span.badge {
        width: 100%;
    }
</style>
