<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <Profile></Profile>
            </div>
        </div>
    </div>
</template>
<script>
import Profile from '@/apps/Marketea/components/google/Profiles'
export default {
    name: "Profiles",
    components: {
        Profile
    },
    data () {
        return {
            isLoading: false,
        }
    },
}
</script>
