<template>
	<div>
		<header-principal></header-principal>
		<div v-if="accessApp">
            <component 
                v-if="getHeader" 
                v-bind:is="component"
                @activarLateral="showAside = $event"
            ></component>
			<div 
                v-if="getHeader" 
                v-bind:class="[
                    'page-wrapper', 
                    {show: showAside}, 
                ]" 
            >
				<router-view></router-view>
			</div>
		</div>
		<footer-principal></footer-principal>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import HeaderPrincipal from "@/core/views/Header";
import menulateral from "@/apps/RoofCal/views/RoofCalAside";
import FooterPrincipal from "@/core/views/Footer";
export default {
    name: "RoofCalLayout",
    components: {
        HeaderPrincipal,
        menulateral,
        FooterPrincipal,
    },
    data() {
        return {
            showAside: false,
            component: 'menulateral'
        };
    },
    created() {
        if (this.loggedIn && this.getHeader) {
            //console.log('si hay login M');
            this.validAccess();
        }
    },
    methods: {
        validAccess() {
            let t = this;
            t.$store.dispatch("core/apps/validAccess").then((response) => {
                let App = response;
                if (App) {
                    //si tiene RoofCal asignado entonces permitir acceso
                    console.log("Accesso permitido");
                    t.$store.dispatch("ModApps/RoofCal/SetActive", App);
                } else {
                    t.$store.dispatch("ModApps/RoofCal/SetActive", App);
                    console.log("Accesso Negado");
                    t.$router.push({ name: "Home" });
                } //sino volver a home
            });
        },
    },
    watch: {
        getHeader: function () {
            //console.log('getHeader ', this.getHeader);
            this.validAccess();
        },
    },
    computed: {
        ...mapGetters("ModApps/RoofCal", { accessApp: "accessApp" }),
        ...mapGetters("core", { getHeader: "getHeader" }),
        ...mapGetters("core/session", { loggedIn: "loggedIn" })
    },
};
</script>
<style scoped lang="scss">
.header {
    top: 0;
}
.page-wrapper {
    margin-left: 40px;
}
</style>
