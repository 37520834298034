<template>
    <div>
        you must fill the OAuth client form
        <button class="btn btn-success" @click="form()">
            go to OAuth client
        </button>
    </div>  
</template>
<script>
export default {
    name: "noData",
    components: {},
    data () {
        return { }
    },
    created () { },
    methods: {
        form() {
            this.$router.push({name: "OauthClient"});     
        }     
    }    
}
</script>
