import { mapGetters } from 'vuex'
// creo constante q guarda la funcion de requerir archivos
const requireModule = require.context('.', true, /\.js$/);
// nombre  a exportar
const computed = {
	// llamando metodos de getters, tambien podrian incluirse los de mutation y state de ser necesario
	...mapGetters("core", { getModalActivity: "getModalActivity" }),
};
// recorro el arbol de archivos de mi carpeta actual donde estoy ubicado
requireModule.keys().forEach(fileName => {
	// si es igual al mismo index omitir
	if (fileName === './index.js') return;
	if (fileName === './computed.js') return;
	// quito la extencion .js y declaro el nombre de la funcion o metodo segun el nombre del archivo
	const functionName = fileName.replace(/(\.\/|\.js)/g, '');
	// si aun no he creado el objeto lo inicializo
	if (!computed[functionName]) { 
		computed[functionName] = {};
	}
	// almacenandolo en la memoria la importacion
	//computed[functionName]= fileName;
	// console.log('file computed', fileName)
	computed[functionName] = requireModule(fileName).default
	// console.log('import computed', computed[functionName])
});
// console.log('print', computed)
export default computed
