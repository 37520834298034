import smartO_task from '@/apps/billing/views/smart_office/smartO_task';
import smartO_visualizer from '@/apps/billing/views/smart_office/smartO_visualizer';
import smartO_knowledge from '@/apps/billing/views/smart_office/smartO_knowledge';
export default [
	{
		path: 'smart-office/task',
		name: 'smartO_task',
		folder: smartO_task,
	}, 
	{
		path: 'smart-office/visualizer',
		name: 'smartO_visualizer',
		folder: smartO_visualizer,
	}, 
	{
		path: 'smart-office/knowledge',
		name: 'smartO_knowledge',
		folder: smartO_knowledge,
	}, 
];
