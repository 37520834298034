<template>
    <div>
        <div class="page-breadcrumb">
            <div class="row">
                <div class="col-5 align-self-center"></div>
                <div class="col-7 align-self-center">
                    <div class="d-flex align-items-center justify-content-end">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link
                                        :to="{ name: 'WriteaCheck' }"
                                        class="btn btn-info btn-sm mr-2"
                                        ><i class="fas fa-arrow-left"></i>
                                        Back</router-link
                                    >
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12 col-xl-8 col-lg-7">
                    <div class="bgloading" v-show="isLoading"><Loader></Loader></div>
                    <form @submit.prevent="saveCheck" id="form1">
                        <div class="card">
                            <div class="card-header" style="margin-bottom: 0rem">
                                <div class="row">
                                    <div class="col-sm-3">
                                        <h4>
                                            <b>{{ title }}</b> a Check
                                            <strong v-if="editar == true"
                                                ># {{ form.idx }}
                                            </strong>
                                        </h4>
                                    </div>
                                    <div class="col-sm-4">
                                        <div
                                            class="custom-control custom-radio custom-control-inline"
                                            v-if="editar == false"
                                        >
                                            <input
                                                type="radio"
                                                id="customRadioInline2"
                                                checked
                                                name=""
                                                class="custom-control-input"
                                                :value="2"
                                                v-model="form.type"
                                            />
                                            <label
                                                class="custom-control-label"
                                                for="customRadioInline2"
                                                >Subcontrator</label
                                            >
                                        </div>
                                        <div
                                            class="custom-control custom-radio custom-control-inline"
                                            v-if="editar == false"
                                        >
                                            <input
                                                type="radio"
                                                id="customRadioInline1"
                                                name=""
                                                class="custom-control-input"
                                                :value="1"
                                                v-model="form.type"
                                            />
                                            <label
                                                class="custom-control-label"
                                                for="customRadioInline1"
                                            >
                                                Vendor</label
                                            >
                                        </div>
                                    </div>
                                    <div class="col-sm-5">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div
                                                    class="form-check form-check-inline"
                                                    style="display: flex"
                                                >
                                                    <label
                                                        class="form-check-label"
                                                        style="padding-right: 10px"
                                                        >Date</label
                                                    >
                                                    <datetime
                                                        type="date"
                                                        v-model="inputFecha"
                                                        format="MMM dd, yyyy"
                                                        input-class="form-control"
                                                        value-zone="America/New_York"
                                                    ></datetime>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div
                                        class="card-body"
                                        style="border: 1px solid #d6d8d9"
                                    >
                                        <div class="form-row alert alert-info">
                                            <div class="form-group col-md-5">
                                                <div v-if="form.type == 1">
                                                    <!-- <span style="position: absolute; right: 5px; top: -21px;">
                                                        <button type="button" class="btnlink" @click="addVendorNew">
                                                            <i class="fas fa-plus"></i> New Vendor
                                                        </button>
                                                    </span> -->
                                                    <label for="" class="flexArea">
                                                        <span>Vendor:</span>
                                                        <span
                                                            style="
                                                                color: #1f8ab3;
                                                                cursor: pointer;
                                                            "
                                                            @click="addVendorNew"
                                                            v-if="editar == false"
                                                        >
                                                            <i class="fas fa-plus"></i>
                                                            New Vendor
                                                        </span>
                                                    </label>
                                                    <model-select
                                                        required
                                                        class="VendorList"
                                                        :options="VendorsList"
                                                        v-model="form.vendor_id"
                                                        v-if="editar == false"
                                                        placeholder="Choose a Customer"
                                                    >
                                                    </model-select>
                                                    <div v-else>
                                                        <strong>{{ form.pay_to }}</strong
                                                        ><br />
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <label for="">Subcontractor:</label>
                                                    <model-select
                                                        required
                                                        :options="EmployeesList"
                                                        v-model="form.subcontractor"
                                                        v-if="editar == false"
                                                        placeholder="Choose a Subcontractor"
                                                    >
                                                    </model-select>
                                                    <div v-else>
                                                        <strong>{{ form.pay_to }}</strong
                                                        ><br />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group col-md-7">
                                                <label for="">Select Account: </label>
                                                <div v-if="editar == false">
                                                    <model-select
                                                        required
                                                        :options="AccountList"
                                                        v-model="form.account_id"
                                                        placeholder="Choose a Account"
                                                    >
                                                    </model-select>
                                                </div>
                                                <div v-else>
                                                    <strong>{{ form.AccountSeleted }}</strong>
                                                </div>
                                            </div>
                                            <div class="form-group col-md-5">
                                                <label for=""
                                                    >Select Project (Optional):</label
                                                >
                                                <model-select
                                                    required
                                                    :options="ProjectList"
                                                    v-model="Customer"
                                                    v-if="editar == false"
                                                    placeholder="Choose a Project"
                                                >
                                                </model-select>
                                                <div v-else>
                                                    <strong></strong><br />
                                                    <small></small>
                                                </div>
                                            </div>
                                            <div class="form-group col-md-7">
                                                <label for="" class="flexArea">
                                                    <span>Check From:</span>
                                                    <span
                                                        style="
                                                            color: #1f8ab3;
                                                            cursor: pointer;
                                                        "
                                                        @click="addBankNew"
                                                        v-if="editar == false"
                                                    >
                                                        <i class="fas fa-plus"></i> Add
                                                        Bank
                                                    </span>
                                                </label>
                                                <model-select
                                                    required
                                                    :options="BankingList"
                                                    v-model="form.bank_id"
                                                    v-if="editar == false"
                                                    placeholder="Choose a Banking"
                                                >
                                                </model-select>                                                       
                                                <div v-else>
                                                    <strong>{{ form.bankingSelected }}</strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col-md-9">
                                                <label for="inputCity"
                                                    >Check Number (Optional)</label
                                                >
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="form.check_number"
                                                />
                                            </div>
                                            <div class="form-group col-md-3">
                                                <label for="inputZip">Amount</label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"
                                                            >$</span
                                                        >
                                                    </div>
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        placeholder="0.00"
                                                        required
                                                        v-model="form.amount"
                                                        @keyup="validP"
                                                        v-if="editar == false"
                                                    />
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        disabled
                                                        v-model="form.amount"
                                                        v-else
                                                    />
                                                    <span
                                                        class="error"
                                                        style="color: red"
                                                        v-if="form.vatError"
                                                        >{{ form.vatErrorMsg }}</span
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col-md-9">
                                                <label for="inputCity">Pay to</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="form.pay_to"
                                                    v-if="editar == false"
                                                />
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="form.pay_to"
                                                    v-else
                                                    disabled
                                                />
                                            </div>
                                            <div class="form-group col-md-3">
                                                <label for="inputZip">Check Date</label>
                                                <div class="input-group">
                                                    <datetime
                                                        type="date"
                                                        v-model="inputFechacheckdate"
                                                        format="MMM dd, yyyy"
                                                        input-class="form-control"
                                                        value-zone="America/New_York"
                                                    ></datetime>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="" style="padding-right: 10px"
                                                >Memo
                                            </label>
                                            <div
                                                class="form-check form-check-inline"
                                                v-if="editar == false"
                                            >
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    id="inlineCheckbox1"
                                                    v-model="form.print_memo"
                                                    checked
                                                />
                                                <label
                                                    class="form-check-label"
                                                    for="inlineCheckbox1"
                                                    >Print on check?</label
                                                >
                                            </div>
                                            <textarea
                                                class="form-control"
                                                rows="2"
                                                v-model="form.memo"
                                            ></textarea>                                            
                                            <!-- <textarea
                                                class="form-control"
                                                rows="2"
                                                v-model="form.memo"
                                                v-if="editar == false"
                                            ></textarea>
                                            <textarea
                                                class="form-control"
                                                rows="2"
                                                v-model="form.memo"
                                                v-else
                                                disabled
                                            ></textarea> -->
                                        </div>
                                        <div
                                            style="
                                                width: 100%;
                                                text-align: right;
                                                margin-top: 20px;
                                            "
                                        >
                                            <button
                                                type="button"
                                                class="btn btn-secundary btn-lg"
                                                style="margin-right: 10px"
                                                @click="
                                                    $router.push({ name: 'WriteaCheck' })
                                                "
                                            >
                                                <i class="fas fa-times"></i> CANCEL
                                            </button>
                                            <button
                                                type="submit"
                                                class="btn btn-success btn-lg"
                                                :disabled="isDisabled"
                                                style="margin-right: 10px"
                                            >
                                                <i class="fas fa-save"></i> SAVE
                                            </button>
                                            <button
                                                type="button"
                                                class="btn btn-success btn-lg"
                                                @click="validPrinted"
                                                :disabled="isDisabled"
                                            >
                                                <i class="fas fa-save"></i> SAVE & PRINT
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-md-4 col-xl-4 col-lg-4 d-md-none d-lg-block">
                    <div class="card">
                        <div class="card-header">Recent Payments</div>
                        <div class="card-body">
                            <ul class="list-group list-group-flush">
                                <li
                                    class="list-group-item d-flex justify-content-between align-items-left"
                                    v-for="i in CheckList"
                                    :key="i.id"
                                >
                                    <label v-if="i.check_number"
                                        >#{{
                                            i.check_number.toString().padStart(4, "000")
                                        }}
                                        {{ i.pay_to }}</label
                                    >
                                    <label v-else
                                        >#0 {{ i.pay_to }}{{ i.check_number }}</label
                                    >
                                    <span
                                        ><b>$ {{ i.amount }}</b></span
                                    >
                                </li>
                            </ul>
                            <router-link
                                :to="{ name: 'WriteaCheck' }"
                                class="btn btn-primary float-right"
                                >All Check</router-link
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- modal -->
        <modalLateral>
            <component v-bind:is="m.component"></component>
        </modalLateral>              
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Loader from '@/core/components/shared/Loader'
import { ModelSelect } from 'vue-search-select'
import ClickOutside from 'vue-click-outside'
import modalLateral from '@/core/plugins/themeModalLateral/modalLateral'
import formAddVendor from '@/apps//billing/components/writecheck/formAddVendor'
import formAddBaking from '@/apps//billing/components/writecheck/formAddBaking'
export default {
    name: "WriteaCheck",
    components: {
        Loader,
        ModelSelect,
        modalLateral,
        formAddVendor,
        formAddBaking
    },
    data() {
        return {
            m: {
                show: false,
                component: '',
                data: []
            },             
            title: "",
            vendorname: null,
            subcontractorname: null,
            editar: false,
            isLoading: false,
            VendorsList: [],
            EmployeesList: [],
            AccountList: [],
            BankingList: [],
            ProjectList: [],
            CheckList: [],
            form: {
                id: null,
                user_id: window.localuserdata,
                type: 2,
                account_id: 0,
                accountId:0,
                bank_id: 0,
                vendor_id: 0,
                adddate: new Date(
                    (new Date(Date.now() - ((new Date()).getTimezoneOffset() * 60000))).toISOString().slice(0, 10) + "T23:31:04.000Z"
                ).toISOString(),
                checkdate: new Date(
                    (new Date(Date.now() - ((new Date()).getTimezoneOffset() * 60000))).toISOString().slice(0, 10) + "T23:31:04.000Z"
                ).toISOString(),
                check_number: null,
                subcontractor: 0,
                payto: null,
                pay_to: null,
                amount: null,
                memo: null,
                status: 1,
                print_memo: true,
                ope: "create",
                vatError: false,
                vatErrorMsg: null,
                AccountSeleted: "",
                bankingSelected: "",                
            },
            Customer: {
                text: null,
                value: null,
                billing_address: null,
                bussinesname: null,
                customer_address: null,
                customer_name: null,
                customer_phone: null,
                email: null,
            },
            validimg1: false,
            botton: true,
            isDisabledPrice: true,
            numdoc: "",
            inputFecha: new Date(
                    (new Date(Date.now() - ((new Date()).getTimezoneOffset() * 60000))).toISOString().slice(0, 10) + "T23:31:04.000Z"
                ).toISOString(),
            inputFechacheckdate: new Date(
                    (new Date(Date.now() - ((new Date()).getTimezoneOffset() * 60000))).toISOString().slice(0, 10) + "T23:31:04.000Z"
                ).toISOString(),
            addVendor: {
                company_name: null,
            },
            formB: {
                user_id: window.localuserdata,
                title: "Add Bank or Credit Card",
                action: "create",
                account_type: true,
                account_name: "",
                account_code: "",
                account_number: "",
                bank_name: "",
                routing_number: "",
                description: "",
                activo: 1,
                primary: false,
                AccountSeleted: "",
                bankingSelected: "",
                send_print: 0,
                account_type_transac:1
            },
        };
    },
    created() {
        if (this.$route.params.idw) {
            this.title = "Edit";
            this.form.id = this.$route.params.idw;
            this.getCheckID();
            this.form.ope = "update";
            this.editar = true;
        } else {
            this.title = "Add";
        }
        if (this.$route.params.idc && this.$route.params.transac) {
            this.getVendors(this.$route.params.idc);
        }
        this.getVendors();
        this.getEmployees();
        this.getchartaccount();
        this.getBanking();
        this.getlist();
        this.getEprint();        
    },
    directives: {
        ClickOutside,
    },
    methods: {
        addVendorNew() {
			let t = this;
			this.m = {
				show: true,
				component: 'formAddVendor',
				data: {
				}
			}  
			this.$store.commit('core/setOpenModal', this.m);            
        },
        addBankNew() {
			let t = this;
			this.m = {
				show: true,
				component: 'formAddBaking',
				data: {
                    formB:t.formB
				}
			}  
			this.$store.commit('core/setOpenModal', this.m); 
        },
        formatearFecha() {
            this.inputFecha = new Date(
                (new Date(this.inputFecha).toISOString() + "").slice(0, 10) +
                    "T23:31:04.000Z"
            ).toISOString();
        },
        formatearFechacdate() {
            this.inputFechacheckdate = new Date(
                (new Date(this.inputFechacheckdate).toISOString() + "").slice(0, 10) +
                    "T23:31:04.000Z"
            ).toISOString();
        },
        getEmployees() {
            var self = this;
            this.isLoading = true;
            window.billing
                .get("employees/" + window.localuserdata)
                .then((response) => {
                    if (response.data.success) {
                        let EmployeesList = [];
                        for (let item in response.data.result) {
                            let t = response.data.result[item].printoncheck;
                            EmployeesList.push({
                                value: response.data.result[item].id,
                                text: t,
                            });
                        }
                        self.EmployeesList = EmployeesList;
                    }
                    self.isLoading = false;
                })
                .catch((error) => console.log(error));
        },
        getVendors(idc = 0) {
            var self = this;
            this.isLoading = true;
            window.billing
                .get("vendors/" + window.localuserdata)
                .then((response) => {
                    // console.log(response.data.result);
                    const Vendors = [];
                    if (response.data.result) {
                        for (let item in response.data.result) {
                            var id = response.data.result[item].id;
                            var text = response.data.result[item].firstname;
                            var company_name = response.data.result[item].company_name;
                            var title = response.data.result[item].title;
                            var guion = " - ";
                            if (text == null) {
                                guion = "";
                                text = "";
                            }

                            Vendors.push({
                                value: id,
                                text: company_name + guion + text,
                            });
                        }
                    }
                    this.VendorsList = Vendors;
                    self.isLoading = false;
                })
                .catch((error) => {
                    self.isLoading = false;
                    console.log(error);
                });
        },
        getCheckID() {
            this.isLoading = true;
            if (this.form.id != null) {
                window.billing
                    .post("checkbyid", {
                        id: this.form.id,
                        user_id: window.localuserdata,
                    })
                    .then((response) => {
                        // console.log(response.data);
                        var result = response.data.result;
                        for (let prop in result) {
                            if (prop == "idx") {
                                this.form[prop] = result[prop]
                                    .toString()
                                    .padStart(4, "000");
                            } else if (prop == "add_date") {
                                this.inputFecha = result[prop];
                            } else if (prop == "check_date") {
                                this.inputFechacheckdate = result[prop];
                            } else if (prop == "memo" && result[prop] == "null") {
                                this.form[prop] = "";
                            } else if (prop == "check_number" && result[prop] == "null") {
                                this.form[prop] = "";
                            } else if (prop == "pay_to" && result[prop] != "null") {
                                this.form.pay_to = result[prop];
                            } else {
                                this.form[prop] = result[prop];
                            }
                        }

                        this.botton = false;
                        this.numdoc = result.numdoc;
                        // this.isLoading = false;
                    })
                    .catch((error) => {
                        self.isLoading = false;
                        console.log(error);
                    });
            }
        },
        getchartaccount() {
            this.isLoading = true;
            window.billing
                .get("expenses/account/" + window.localuserdata)
                .then((response) => {
                    if (response.data.result.length > 0) {
                        let AccountList = [];
                        for (let item in response.data.result) {
                            let t = response.data.result[item].name_account;
                            AccountList.push({
                                value: response.data.result[item].id,
                                text: t,
                            });
                        }
                        this.AccountList = AccountList;
                        if (this.editar) {
                            for (const y in AccountList) {
                                if (this.form.account_id === AccountList[y].value) {
                                    console.log(AccountList[y]);
                                    this.form.AccountSeleted = AccountList[y].text;
                                }
                            }
                        }                   
                    }
                    this.isLoading = false;
                })
                .catch((error) => console.log(error));
        },
        getBanking() {
            this.isLoading = true;
            window.billing
                .get("bankings/" + window.localuserdata)
                .then((response) => {
                    this.isLoading = false;
                    if (response.data.result.length > 0) {
                        let BankingList = [];
                        for (let item in response.data.result) {
                            let t = response.data.result[item].bank_name + " ";
                            t +=
                                "xxxx" +
                                response.data.result[item].account_number.slice(-4);
                            BankingList.push({
                                value: response.data.result[item].id,
                                text: t,
                            });
                        }
                        this.BankingList = BankingList;
                        if (this.editar) {
                            for (const i in BankingList) {
                                if (this.form.bank_id === BankingList[i].value) {
                                    this.form.bankingSelected = BankingList[i].text;
                                }
                            }
                        }
                    }
                })
                .catch((error) => {
                    this.isLoading = false;
                    console.log(error);
                });
        },        
        getlist() {
            this.isLoading = true;
            window.billing
                .get("writecheck/" + window.localuserdata)
                .then((response) => {
                    // console.log("aqui");
                    // console.log(response.data);
                    this.CheckList = response.data.result;
                    this.isLoading = false;
                })
                .catch((error) => {
                    this.isLoading = false;
                    console.log(error);
                });
        },
        validP() {
            let vatIdRegex = new RegExp(/^\d+(\.\d{0,2})?$/);
            //let vatIdRegex =  new RegExp(/^\d*(\.\d{1})?\d{0,1}$/)
            let a = this.form.amount.split("");
            let chaters = ["$", ","];
            this.form.amount = "";
            for (let e in a) {
                let v = true;
                for (let i in chaters) {
                    if (a[e] == chaters[i]) {
                        v = false;
                    }
                }
                if (v == true) {
                    this.form.amount += "" + a[e];
                }
            }
            if (!vatIdRegex.test(this.form.amount) && event.key != ".") {
                this.form.vatError = true;
                this.form.vatErrorMsg =
                    "Enter the valid number, with a maximum of two decimal places.";
                this.botton = true;
            } else if (this.form.price == 0) {
                this.form.vatError = true;
                this.form.vatErrorMsg = "Must be greater than zero.";
                this.botton = true;
            } else {
                this.form.vatError = false;
                this.botton = false;
                this.form.vatErrorMsg = "";
            }
        },
        saveCheck() {
            var self = this;
            self.isLoading = true;
            // console.log(self.form);
            if (self.form.ope == "create") {
                window.billing
                    .post("writecheck/create", {
                        user_id: window.localuserdata,
                        type: this.form.type,
                        account_id: this.form.account_id,
                        bank_id: this.form.bank_id,
                        vendor_id: this.form.vendor_id,
                        adddate: this.form.adddate,
                        checkdate: this.form.checkdate,
                        checknumber: this.form.check_number,
                        subcontractor: this.form.subcontractor,
                        payto: this.form.pay_to,
                        amount: this.form.amount,
                        memo: this.form.memo,
                        status: 1,
                        print_memo: this.form.print_memo,
                    })
                    .then((response) => {
                        // console.log(response.data);
                        self.$swal.fire({
                            icon: "success",
                            title: "Successful registration",
                            showConfirmButton: false,
                            timer: 2000,
                        });
                        this.$router.push({ name: "WriteaCheck" });
                    })
                    .catch((error) => {
                        self.isLoading = false;
                        console.log(error);
                    });
            } else {
                window.billing
                    .post("writecheck/update", {
                        id: this.form.id,
                        adddate: this.form.adddate,
                        checkdate: this.form.checkdate,
                        checknumber: this.form.check_number,
                        memo: this.form.memo,
                    })
                    .then((response) => {
                        // console.log(response.data);
                        self.$swal.fire({
                            icon: "success",
                            title: "Successful Update",
                            showConfirmButton: false,
                            timer: 2000,
                        });
                        this.$router.push({ name: "WriteaCheck" });
                    })
                    .catch((error) => {
                        self.isLoading = false;
                        console.log(error);
                    });
            }
        },
        getEprint() {
            var self = this;
            this.isLoading = true;
            window.billing
                .get("writecheck/valideprint/" + window.localuserdata)
                .then((response) => {
                    this.isLoading = false
                    this.send_print = response.data.result;
                })
                .catch((error) => {
                    this.isLoading = false
                    console.log(error)
                });
        },
        validPrinted() {
            if (this.send_print == 1) {
                this.saveCheckandPrinted();
            } else {
                this.$swal({
                    title:
                        "You don't have Eprint configured, you want to convert check to printed anyway?",
                    text: "Are you sure?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Yes, change to printed!",
                }).then((result) => {
                    if (result.value) {
                        this.send_print = 0;
                        this.saveCheckandPrinted();
                    }
                });
            }
        },
        saveCheckandPrinted() {
            var self = this;
            self.isLoading = true;
            if (self.form.ope == "create") {
                window.billing
                    .post("writecheck/create", {
                        user_id: window.localuserdata,
                        type: this.form.type,
                        account_id: this.form.account_id,
                        bank_id: this.form.bank_id,
                        vendor_id: this.form.vendor_id,
                        adddate: this.form.adddate,
                        checkdate: this.form.checkdate,
                        checknumber: this.form.check_number,
                        subcontractor: this.form.subcontractor,
                        payto: this.form.pay_to,
                        amount: this.form.amount,
                        memo: this.form.memo,
                        status: 1,
                        print_memo: this.form.print_memo,
                    })
                    .then((response) => {
                        // console.log(response.data);
                        self.$swal.fire({
                            icon: "success",
                            title: "Successful registration",
                            showConfirmButton: false,
                            timer: 2000,
                        });
                        this.actionPrint(response.data.id, response.data.idx);
                    })
                    .catch((error) => {
                        self.isLoading = false;
                        console.log(error);
                    });
            } else {
                window.billing
                    .post("writecheck/update", {
                        id: this.form.id,
                        adddate: this.form.adddate,
                        checkdate: this.form.checkdate,
                        checknumber: this.form.check_number,
                    })
                    .then((response) => {
                        // console.log(response.data);
                        self.$swal.fire({
                            icon: "success",
                            title: "Successful Update",
                            showConfirmButton: false,
                            timer: 2000,
                        });
                        this.actionPrint(response.data.id, response.data.idx);
                    })
                    .catch((error) => {
                        self.isLoading = false;
                        console.log(error);
                    });
            }
        },
        actionPrint(id, idx) {
            let numdoc = idx.toString().padStart(4, "000");
            this.Loading = true;
            let t = this;
            this.$swal({
                title: "Convert to printed check?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes",
                html: `<label>
              <input type="checkbox" value="1" id="sendCheck">
              <span class="swal2-label">send to printer check</span>
              </label>`,
            }).then((result) => {
                if (result.value) {
                    let sendCheck = document.querySelector("#sendCheck").checked ? 1 : 0;
                    window.billing
                        .post("printcheck", {
                            user_id: window.localorgdata,
                            id: id,
                            numdoc: numdoc,
                            send_print: t.send_print,
                            sendCheck: sendCheck,
                        })
                        .then((response) => {
                            t.Loading = false;
                            // console.log(response.data);
                            if (response.data.status) {
                                t.$router.push({ name: "WriteaCheck" });
                            } else {
                                console.log("error printed");
                                t.$notify({
                                    group: "noti",
                                    title: "Check",
                                    type: "error",
                                    text: "Error printed",
                                });
                                setTimeout(function () {
                                    t.$router.push({ name: "WriteaCheck" });
                                }, 1000);
                            }
                        })
                        .catch((error) => {
                            t.Loading = false;
                            console.log(error);
                            t.$notify({
                                group: "noti",
                                title: "Check",
                                type: "error",
                                text: "Error printed",
                            });

                            setTimeout(function () {
                                t.$router.push({ name: "WriteaCheck" });
                            }, 1000);
                        });
                } else {
                    t.Loading = false;
                }
            });
        },
    },
    watch: {
        'getOpenModal': function() {
            if (!this.getOpenModal.show) {
                if (Object.keys(this.getOpenModal.data).length > 0) {
                    let t = this.getOpenModal.data; 
                    if (t.methods === 'vendor') {
                        this.VendorsList.push({
                            value: t.value,
                            text: t.text
                        });  
                        this.form.vendor_id = t.value
                        this.form.pay_to = t.text
                    }
                    if (t.methods === 'bankin') {
                        this.form.bank_id = t.bank_id
                        this.getBanking();
                    }
                }
                this.m = {
                    show: false,
                    component: '',
                    data: {}
                }
            }
        },        
        Customer: function (i) {
            this.form.customer_id = i.value;
        },
        inputFecha: function () {
            this.formatearFecha();
            this.form.adddate = this.inputFecha;
        },
        inputFechacheckdate: function () {
            this.formatearFechacdate();
            this.form.checkdate = this.inputFechacheckdate;
        },
        "form.vendor_id": function (i) {
            this.form.payto = this.VendorsList.filter((x) => x.value === i).map(
                (x) => x.text
            );
            if (!this.editar) {
                // console.log("asignando payto", this.form.payto[0]);
                this.form.pay_to = this.form.payto[0];
            }
        },
        "form.subcontractor": function (i) {
            this.form.payto = this.EmployeesList.filter((x) => x.value === i).map(
                (x) => x.text
            );
            if (!this.editar) {
                this.form.pay_to = this.form.payto[0];
            }
        },
        "form.type": function () {
            // console.log("cambio de type = ", this.form.type);
            if (this.form.type == 1) {
                this.form.subcontractor = 0;
            }
            if (this.form.type == 2) {
                this.form.vendor_id = 0;
            }
        },       
    },
    computed: {
        ...mapGetters("core", { getOpenModal: "getOpenModal" }),
        isDisabled: function () {
            if (this.form.type == 1 && this.form.Vendor == 0) {
                return true;
            } else if (this.form.type == 2 && this.form.Employees == 0) {
                return true;
            } else if (this.form.account_id == null || this.form.account_id == 0) {
                return true;
            } else if (this.form.bank_id == null || this.form.bank_id == 0) {
                return true;
            } else if (
                this.form.amount == 0 ||
                this.form.amount == "" ||
                this.form.amount == null
            ) {
                return true;
            } else if (this.botton) {
                return true;
            } else {
                return false;
            }
        },
    },
};
</script>
<style scope lang="scss">
.ui.fluid.search.selection.dropdown {
    border-radius: 2px;
    padding: 0.68571429em 2.1em;
    min-height: 2.142em;
}
.flexArea {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
}
</style>
