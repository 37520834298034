<template>
    <div class="card">
        <div class="card-header window-header">
            <h4 style="margin-bottom: 0px;">YouTube Url</h4>
            <button @click="$parent.close()" class="closeIcon"><span></span></button>
        </div>
        <form class="card-body" @submit.prevent="insertIframe()">
            <div class="input-group" style="padding:0px;">
                <input type="text" class="form-control" v-model="url"
                required placeholder="https://www.youtube.com/watch?v="
                style="border-top-right-radius: 0px; border-bottom-right-radius: 0px;font-size: 10px;height: 35px;"/>
                <span class="input-group-text" style="padding:0px;background: transparent;border:0px;line-height: 1;">
                    <button type="submit"
                    class="btn btn-success"
                    style="border-top-left-radius: 0px; border-bottom-left-radius: 0px;"
                    >Save</button>
                </span>
            </div>
        </form>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            type: [Number, String, Object, Array],
            default: null,
            require: false,
        },
    },
    data(){
        return {
            url: null,
        };
    },
    created(){
        let t = this;
        if(t.data!=null){
            t.url=t.data;
        }
    },
    methods: {
        insertIframe(){
            let urlIframe = this.url;
            if(urlIframe.indexOf('&')>-1){
                let u = urlIframe.split('&');
                if(u.length>=0){
                    let watchID = u[0].split('watch?v=');
                    urlIframe = watchID[1];
                }
            }else{
                let watchID = urlIframe.split('watch?v=');
                urlIframe = watchID[1];
            }
            this.$emit('actionModal', {
                action: 'videoYB',
                url: urlIframe,
            });
        }
    }
}
</script>
<style lang="scss" scoped>
small.form-text.text-muted{
    display: block;
    font-size: 10px;
}
</style>
