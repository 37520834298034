<template>
    <div>
        <div class="page-breadcrumb">
            <div class="row">
                <div class="col-md-8 align-self-center">
                    <h4 class="page-title">
                        <i class="fas fa-chart-line"></i> Report Profit & Loss
                    </h4>
                </div>
                <div class="col-md-4 align-self-center">
                    <div class="d-flex align-items-right justify-content-end">
                        <router-link :to="{ name: 'BillingReport' }" class="btn btn-info btn-sm mr-2 ctrlbtn" style="margin-bottom: 10px;width: -webkit-fill-available;">
                            <i class="fas fa-arrow-left"></i>
                            Back
                        </router-link>  
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card card-body">
                        <div class="row">
                            <!-- start botoneras ajustados a responsive -->
                            <div class="col-md-12">
                                <div class="row">
                                    <div id="divA" class="col-md-4">
                                        <div class="form-group row">
                                            <div class="col-md-5">
                                                <button
                                                    type="button"
                                                    class="btn btn-success mb-2"
                                                    style="width: inherit;min-width: 80px;"
                                                    @click="filterProfitLoss"
                                                >
                                                    <i class="fas fa-search"></i> Filter
                                                </button>  
                                            </div>                               
                                            <div class="col-md-7">
                                                <select class="form-control" v-model="filter">
                                                    <option value="1">This Year</option>
                                                    <option value="2">Previous Year</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="divS" class="col-md-3">
                                    </div>
                                    <div id="divB" class="col-md-3">
                                    </div> 
                                    <div id="divC" class="col-md-2">
                                        <div style="display: flex; justify-content: flex-end">
                                            <button
                                                type="button"
                                                class="btn btn-secondary"
                                                style="margin-bottom: 10px;"
                                                @click="pdfProfitLoss"
                                            >
                                                <i class="fas fa-file-pdf"></i> Download PDF
                                            </button>                                  
                                        </div>
                                    </div>                                                                        
                                </div>
                            </div>
                            <!-- end botoneras ajustados a responsive -->                        
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <Loader class="bgloading" v-if="isLoading"></Loader>
                                <div class="contenedor-table" id="profitLoss" v-else>
                                    <table
                                        class="table table-striped table-bordered table-hover"
                                    >
                                        <thead>
                                            <tr>
                                                <th>Month</th>
                                                <th>Paid</th>
                                                <th>Balance Due</th>
                                                <th>Income</th>
                                                <th>Expenses</th>
                                                <th>Profit - Loss</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="(i, index) in Profit_list"
                                                v-bind:key="index"
                                            >
                                                <td>{{ i.month }}</td>
                                                <td>${{ i.paid | formatMoney }}</td>
                                                <td>${{ i.balance | formatMoney }}</td>
                                                <td>${{ i.income | formatMoney }}</td>
                                                <td>${{ i.expenses | formatMoney }}</td>
                                                <td>
                                                    <span
                                                        v-if="i.profitLoss < 0"
                                                        class="text-danger"
                                                    >
                                                        ${{ i.profitLoss | formatMoney }}
                                                    </span>
                                                    <span v-else>
                                                        ${{ i.profitLoss | formatMoney }}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th>Year {{ anioFilter }}</th>
                                                <th>${{ totalPaid | formatMoney }}</th>
                                                <th>${{ totalBalance | formatMoney }}</th>
                                                <th>${{ totalIncome | formatMoney }}</th>
                                                <th>
                                                    ${{ totalExpenses | formatMoney }}
                                                </th>
                                                <th>
                                                    ${{ totalProfitLoss | formatMoney }}
                                                </th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="row">
                                                <label class="text-success md-text-left" style="padding: 10px;">
                                                    Paid Invoices:
                                                </label>
                                                <h3 class="text-left">
                                                    <span class="badge badge-light" style="padding: 10px;">
                                                        ${{ totalPaid | formatMoney }}
                                                    </span>
                                                </h3>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="row">
                                                <label class="text-danger md-text-left" style="padding: 10px;">
                                                    Outstanding:
                                                </label>
                                                <h3 class="text-left">
                                                    <span class="badge badge-light text-danger" style="padding: 10px;">
                                                        ${{ totalBalance | formatMoney }}
                                                    </span>
                                                </h3>
                                            </div> 
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader";
export default {
    components: {
        Loader,
    },
    name: "ProfitandLoss",
    data() {
        return {
            isLoading: false,
            Profit_list: [
                {
                    month: "January",
                    paid: 0,
                    balance: 0,
                    income: 0,
                    expenses: 0,
                    profitLoss: 0,
                },
                {
                    month: "February",
                    paid: 0,
                    balance: 0,
                    income: 0,
                    expenses: 0,
                    profitLoss: 0,
                },
                {
                    month: "March",
                    paid: 0,
                    balance: 0,
                    income: 0,
                    expenses: 0,
                    profitLoss: 0,
                },
                {
                    month: "April",
                    paid: 0,
                    balance: 0,
                    income: 0,
                    expenses: 0,
                    profitLoss: 0,
                },
                {
                    month: "May",
                    paid: 0,
                    balance: 0,
                    income: 0,
                    expenses: 0,
                    profitLoss: 0,
                },
                {
                    month: "June",
                    paid: 0,
                    balance: 0,
                    income: 0,
                    expenses: 0,
                    profitLoss: 0,
                },
                {
                    month: "July",
                    paid: 0,
                    balance: 0,
                    income: 0,
                    expenses: 0,
                    profitLoss: 0,
                },
                {
                    month: "August",
                    paid: 0,
                    balance: 0,
                    income: 0,
                    expenses: 0,
                    profitLoss: 0,
                },
                {
                    month: "September",
                    paid: 0,
                    balance: 0,
                    income: 0,
                    expenses: 0,
                    profitLoss: 0,
                },
                {
                    month: "October",
                    paid: 0,
                    balance: 0,
                    income: 0,
                    expenses: 0,
                    profitLoss: 0,
                },
                {
                    month: "November",
                    paid: 0,
                    balance: 0,
                    income: 0,
                    expenses: 0,
                    profitLoss: 0,
                },
                {
                    month: "December",
                    paid: 0,
                    balance: 0,
                    income: 0,
                    expenses: 0,
                    profitLoss: 0,
                },
            ],
            anioFilter: "",
            totalPaid: 0,
            totalBalance: 0,
            totalIncome: 0,
            totalExpenses: 0,
            totalProfitLoss: 0,
            filter: 1,
            thisYear: [],
            prevYear: [],
        };
    },
    created() {
        this.getProfitLoss();
    },
    methods: {
        getProfitLoss() {
            this.isLoading = true;
            window.billing
                .post("report/profitloss", { user_id: window.localuserdata })
                .then((response) => {
                    this.thisYear = response.data.thisYear;
                    this.prevYear = response.data.prevYear;
                    this.aNoActual = response.data.year;
                    this.aNoAnterior = response.data.prevY;
                    this.filterProfitLoss();
                })
                .catch((error) => {
                    this.isLoading = false;
                    console.log(error);
                });
        },
        filterProfitLoss() {
            this.isLoading = true;
            console.log(this.filter);
            if (this.filter == 1) {
                this.anioFilter = this.aNoActual;
                this.recorrerLista(this.thisYear);
            } else {
                this.anioFilter = this.aNoAnterior;
                this.recorrerLista(this.prevYear);
            }
            this.isLoading = false;
        },
        recorrerLista(list) {
            this.totalPaid = 0;
            this.totalBalance = 0;
            this.totalIncome = 0;
            this.totalExpenses = 0;
            this.totalProfitLoss = 0;
            for (var i = 0; i < Object.keys(list).length; i++) {
                this.Profit_list[i].paid = list[1 + i].paid;
                this.Profit_list[i].balance = list[1 + i].balance_due;
                this.Profit_list[i].income = list[1 + i].income;
                this.Profit_list[i].expenses = list[1 + i].expenses;
                this.Profit_list[i].profitLoss = list[1 + i].ProfitLoss;
                this.totalPaid += parseFloat(list[1 + i].paid);
                this.totalBalance += parseFloat(list[1 + i].balance_due);
                this.totalIncome += parseFloat(list[1 + i].income);
                this.totalExpenses += parseFloat(list[1 + i].expenses);
                this.totalProfitLoss += parseFloat(list[1 + i].ProfitLoss);
            }
        },
        pdfProfitLoss() {
            let uri = "report/profitloss/pdf/" + window.localuserdata + "/" + this.filter;
            window.open(window.urlbase + "api/v2/" + uri, "_blank");
        },
    },
};
</script>

<style lang="css" scoped>
#profitLoss table th {
    font-weight: bold;
}
#profitLoss table thead tr th,
#profitLoss table tbody tr td,
#profitLoss table tfoot tr th {
    text-align: right;
    width: 18%;
}
#profitLoss table thead tr th:nth-child(1),
#profitLoss table tbody tr td:nth-child(1),
#profitLoss table tfoot tr th:nth-child(1) {
    text-align: left;
    width: 10%;
    max-width: 120px;
}
.contenedor-table table thead tr th {
    font-weight: bold;
}
@media (max-width: 1100px) {
    .contenedor-table {
        max-width: 924px;
        overflow-x: scroll;
    }
}
@media (min-width: 1280px) {
    .ctrlbtn {
        width:100px !important;
    }
}
</style>
