<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-6">
				<h3 class="card-title">
					Accounts
				</h3>
			</div>
			<div class="col-md-6">
				<div class="row">
					<div class="col-md-6">			
					</div>
					<div class="col-md-6">			
						<input
							type="text"
							class="form-control"
							v-model="TextSearch"
							placeholder="Search"
							style="border: 1px solid #c9c9ca;"
						/>	
					</div>	
				</div>				
			</div>
		</div>		
        <div class="row">
			<div class="card-body tdynamic">
				<div class="content-table-tdynamic" >							
					<table class="table-tdynamic" id="tableID">
						<thead>
							<tr>
								<th scope="col" class="colId">ID</th>
								<th scope="col">Billing Info</th>
								<th scope="col">Email/Phone</th>							
								<th scope="col">Start Date</th>
								<th scope="col">Login Activity</th>
								<th scope="col" class="colStatus">Status</th>
								<th scope="col" class="colOrgs">Orgs</th>
								<th scope="col" class="colActions">Actions</th>
							</tr>
						</thead>
						<tbody v-if="isLoading === true">
							<tr>
								<td colspan="8" style="text-align: center">
									<loader></loader>
								</td>
							</tr>
						</tbody>
						<tbody v-else-if="result.length === 0">
							<tr>
								<td colspan="8" style="text-align: center">
									No record
								</td>
							</tr>
						</tbody>
						<tbody v-else>
							<tr v-for="(i, index) in filtered" :key="index">
								<td class="colId">{{i.userID}}</td>
								<td>
									<b>{{i.businnes}}</b><br>
									{{i.name}} {{i.lastname}}
								</td>
								<td>
									{{i.email}} <br>
									{{i.phoneLogin}}
								</td>
								<td>
									{{i.created_date}}<br>
									{{i.created_time}}
								</td>
								<td>
									{{i.loginActivity_date}}<br>
									{{i.loginActivity_time}}
								</td>
								<td class="colStatus">
									<div class="action_btn_status">
                                        <span @click="activeStatus(index)"
											v-bind:class="[
												'statusSelected badge',
												(i.status==1) ? 'badge-success active' : 
												(i.status==2) ? 'badge-dark inactive' : 
												(i.status==3) ? 'badge-danger spam' : 
												(i.status==4) ? 'badge-danger suspended' : '',
											]"
                                            style="cursor: pointer"
										>
											{{i.nameStatus}}
											<i class="fas fa-angle-down"></i>
										</span>
                                        <span
                                            class="status_list"
                                            v-if="statusActive == index"
                                        >
                                            <ul class="reset" style="padding-left: 0;">
                                                <li 
                                                	v-for="(s, sindex) in statuslist" 
                                                	:key="sindex"
                                                	@click="changeStatus(s.statusID, i.userID)"
                                                	>
                                                    <span
                                                        v-bind:class="[
															'badge',
															(s.statusID==1) ? 'badge-success active' : 
															(s.statusID==2) ? 'badge-dark inactive' : 
															(s.statusID==3) ? 'badge-danger spam' : 
															(s.statusID==4) ? 'badge-danger suspended' : '',
														]"
                                                        >{{s.nameStatus}}</span
                                                    >
                                                </li>
                                            </ul>
                                        </span>
                                    </div>						
								</td>
								<td class="colOrgs" @click="modalOrgs('orgAccount', i)">
									<span class="badge badge-pill badge-dark"> {{i.orgs}}</span>
								</td>
								<td class="colActions">
									<span class="cdropdown">
										<button><i class="fas fa-cog"></i></button>
										<label>
											<input 
												type="checkbox" 
												class="submenu" 
												@click="checkSubmenu($event)" 
											/>
												<ul>
													<li  @click="modalOrgs('editAccount', i)">
														<i class="fa fa-lg fa-edit"></i> Edit Billing Info
													</li>
													<li  @click="modalOrgs('editEmailPhone', i)">
														<i class="fa fa-lg fa-edit"></i> Edit Email/Phone
													</li>
													<li  @click="modalOrgs('passAccount', i)">
														<i class="fas fa-lock"></i> Change Password
													</li>																																							
													<li @click="modalOrgs('orgAccount', i)">
														<i class="fa fa-lg fa-check"></i> Organizations
													</li>
													<li @click="verifyEmail(i.userID)">
														<i class="fa fa-lg fa-paper-plane"></i> Verify Email
													</li>
													<li  @click="ResendTokenEmail(i.userID)">
														<i class="fas fa-sms"></i> Send Verification Email
													</li>	
													<!-- <li  @click="Suspended(i)">
														<i class="fa fa-lg fa-trash"></i> Suspend
													</li> -->
												</ul>								
										</label>
									</span>
								</td>												
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
        <!-- modal -->
        <modalLateral v-show="activeModalOrgs">
            <component v-bind:is="m.component"></component>
        </modalLateral> 		
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import Loader from '@/core/components/shared/Loader'
import editAccount from '@/core/components/Reseller/Account/editAccount'
import orgAccount from '@/core/components/Reseller/Account/orgAccount'
import passAccount from '@/core/components/Reseller/Account/passAccount'
import editEmailPhone from '@/core/components/Reseller/Account/editEmailPhone'
import modalLateral from '@/core/plugins/themeModalLateral/modalLateral'
export default {
	name: 'UsersbyReseller',
	components: {
		orgAccount,
		editAccount,
		passAccount,
		editEmailPhone,
		modalLateral,
		Loader
	},	
	data () {
		return {
			isLoading: false,
			result:[],
            m: {
                show: false,
                component: '',
                data: []
            },
			f:{
				resellerId:window.resellerId,
			},
			statusActive: null, 					
			statuslist: [], 
			activeModalOrgs: false,	
			TextSearch: "",		
		}
	},
	created(){
		console.log('UsersbyReseller')
		this.getAcount();
	},
    methods: {
        activeStatus(index) {
            if (this.statusActive == index) {
                this.statusActive = null;
            } else {
                this.statusActive = index;
            }
        },
    	changeStatus(status, user_id){
    		this.statusActive = null;
    		this.isLoading = true;
    		let url = "/changeStatus";
			window.reselleR2.post(url, {
				user_id: user_id,
				idStatus: status,
			})
			.then((response) => {
				this.getAcount();
			})
			.catch((error) => {
				this.isLoading = false;
				console.log(error)
			});
    	},
		checkSubmenu(e) {		
			let submenus = document.querySelectorAll('.' + e.target.classList[0]);
			submenus.forEach(function(i){
				if(i != e.target){
					i.checked = false;
				}
			});
		},		
        modalOrgs(component, i) {
            this.m = {
                show: true,
                component: component,
                data: i
            }  
            this.activeModalOrgs= true;
            this.$store.commit('core/setOpenModal', this.m);          
        },		
        getAcount() {
        	let t = this;
			t.isLoading = true
            let url = "/getAccounts";
            window.reselleR2
                .post(url, t.f)
                .then((response) => {
					let u = response.data.r;
					let statuslist = response.data.statusList;
					console.log(u);
					let statusName=['Active', 'Inactive', 'Spam', 'Suspended'];
					for (let i in u) {
						if(u[i].status==1){u[i].statusName = statusName[0];}
						if(u[i].status==2){u[i].statusName = statusName[1];}
						if(u[i].status==3){u[i].statusName = statusName[2];}
						if(u[i].status==4){u[i].statusName = statusName[3];}
					}
                    t.result = u;
					console.log(u);
                    t.statuslist = statuslist;
					t.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
					t.isLoading = false
                });
        },	
		verifyEmail(user_id){
            let url = "/verifyemail";
            window.reselleR2
			.post(url, {user_id: user_id})
			.then((response) => {
				console.log("success", response);	
			})
            .catch((error) => {
				console.log(error);
			});
		},
		ResendTokenEmail(user_id){
            let url = "/pushtetokenemail";
            window.reselleR2
			.post(url, {user_id: user_id})
			.then((response) => {			
				console.log("success", response);
			})
            .catch((error) => {
				console.log(error);
			});		
		},
		Suspended(i){
			console.log(i);
		}
    },
    computed: {
		filtered: function () {
			var t = this;
            if(t.TextSearch.length == 0) {
                return t.result;    
            } else{				
				return this.result.filter(function (item) {
					let fullname = item.name+' '+item.lastname
					let fullnameA = item.name+' '+item.businnes
					let fullnameB = item.lastname+' '+item.name
					let fullnameC = item.lastname+' '+item.businnes
					let fullnameD = item.businnes+' '+item.lastname
					let fullnameE = item.businnes+' '+item.name
					let fullnameF = item.name+' '+item.lastname+' '+item.businnes
					let fullnameG = item.lastname+' '+item.name+' '+item.businnes
					let fullnameH = item.businnes+' '+item.lastname+' '+item.name
					let fullnameI = item.businnes+' '+item.name+' '+item.lastname
					return (
						(item.name &&
							item.name
								.trim()
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||	
						(item.lastname &&
							item.lastname
								.trim()
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||													
						(item.businnes &&
							item.businnes
								.trim()
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
						(fullname &&
							fullname
								.trim()
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
						(fullnameA &&
							fullnameA
								.trim()
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
						(fullnameB &&
							fullnameB
								.trim()
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
						(fullnameC &&
							fullnameC
								.trim()
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
						(fullnameD &&
							fullnameD
								.trim()
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
						(fullnameE &&
							fullnameE
								.trim()
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
						(fullnameF &&
							fullnameF
								.trim()
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
						(fullnameG &&
							fullnameG
								.trim()
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||							
						(fullnameH &&
							fullnameH
								.trim()
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
						(fullnameI &&
							fullnameI
								.trim()
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||	
						(item.phoneLogin &&
							item.phoneLogin
								.trim()
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||							
						(item.email &&
							item.email
								.trim()
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0)							
					);
				});
			}
		},		
        ...mapGetters("core", { getOpenModal: "getOpenModal" }),
    },
    watch: {
        'getOpenModal': function() {            
			let t = this.getOpenModal.data; 
			// console.log(t);
			if (t.method === 'refresh-edit') {
				this.getAcount()
			}
			if(!this.getOpenModal.show){
				this.activeModalOrgs= false;
			}
        },          
    },	  	
}
</script>
<style lang="css" scoped>
	@import '../../plugins/tdynamic/css/tdynamic.css';
	.tdynamic {
	    position: relative;
	    height: calc(100vh - 140px);
	    color: #555;
	}
	.tdynamic .content-table-tdynamic{
		top: 0px;
	}
	.table-tdynamic thead tr th{
		font-weight: bold;
	}
	.table-tdynamic thead tr th,
	.table-tdynamic tbody tr td{
		padding: 1px 8px;
	}
	.table-tdynamic thead tr th.colId,
	.table-tdynamic tbody tr td.colId{
		width: 80px;
	}
	.table-tdynamic thead tr th.colOrgs,
	.table-tdynamic tbody tr td.colOrgs{
		width: 80px;
	}
	.table-tdynamic thead tr th.colStatus,
	.table-tdynamic tbody tr td.colStatus{
		width: 80px;
	}
	.table-tdynamic thead tr th.colActions,
	.table-tdynamic tbody tr td.colActions{
		width: 80px;
	}
	.table-tdynamic tbody tr td.colOrgs{
		cursor: pointer;
	}
	.status_list {
	    padding: 0px;
	    position: absolute;
	    left: 0;
	    top: 25px;
	    background-color: #fff;
	    /* width: 120px; */
	    font-size: 14px;
	    z-index: 9;
	}
	.status_list > ul {
	    margin-bottom: 0px;
	    border: 1px solid #ccc;
	}
    .status_list > ul > li {
	    display: block;
	    position: relative;
	    margin: 1px 0;
	    padding: 5px;
    }
	.status_list > ul > li:hover {
		background-color: #efefef;
		cursor: pointer;
	}
	.action_btn_status {
    	position: relative;
    	display: flex;
    	justify-content: flex-end;
    	align-items: center;
    	justify-content: center;
	}
	span.statusSelected {
	    font-size: 12px;
	}

	span.statusSelected i {
	    color: #000;
	}
</style>
