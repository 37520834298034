export default {
	auth : false,
    token: localStorage.getItem('access_token_m') || null,
    emailVerify : false,
    user_type : localStorage.getItem('user_type') || 0,
    return_user: false,
    dataUser: {name:'UserName', account_id:0},
    privilegies: [],
    token_r: localStorage.getItem('access_token_r') || null,
    popPremium: {
        show: false,
        data: [],
    },
    statusBilling: 1,
}
