var jobs = "/jobs/";
export default {  
	uploadImg(){
		return jobs+"uploadIMG";
	},
	elImg(){
		return jobs+"delImgAllGallery";
	},
	editNotes(){
		return jobs+"editNotes";
	},   
	toComplete(){
		return jobs+"toComplete";
	}, 
	getToDoList(){
		return jobs+"getDataForm";
	},
	jobsPdf(id_invoice){
		return window.urlbase + "api/v2/invoice/pdf/" + id_invoice + "/" +window.localAccountID + "/" + localStorage.getItem("oauth_m");
	},    
	changeColor(){
		return jobs+"setJobsColor";
	}, 
    del(id){
		return jobs+"delJobs/" + window.master_client + "/" + window.localorgdata + "/" + id        
	},    
};
