<template>
	<div>
		<listInfo></listInfo>
	</div>
</template>
<script>
import listInfo from "@/apps/Marketea/components/LandingPage/listInfo";
export default {
	name: 'listPages',
    components: {
        listInfo,
    },   	
	data () {
		return {
		}
	},
}
</script>
<style lang="css" scoped>
</style>
