let refreshListByPayment = function(d) {	
	for (let i in this.InvoicesList) {
		if(this.InvoicesList[i] && this.InvoicesList[i].id == d.invoice_id) {
			this.InvoicesList[i].idxstatus = d.status;
			this.InvoicesList[i].balance = (this.InvoicesList[i].total - d.abonado);
			console.log('item refresh', this.InvoicesList[i]);
		}
	}
};
export default refreshListByPayment;
