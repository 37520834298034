<template>
	<div class="card">
		<div class="card-body">
			<div class="container-comments">
				<ul v-if="notesList.length>0" class="listComments">
					<li v-for="(i, idx) in notesList" :key="idx">
						<div class="comments-content">
							<div class="date">Date: {{i.date}}</div>
							<div class="comment">{{i.comments}}</div>
							<div class="by">By: {{i.name}}</div>
						</div>
					</li>
				</ul>
				<div v-else style="padding-top: 20px;">
					<div class="alert alert-info" role="alert">
					  Post notes or comments about this project
					</div>
				</div>
			</div>
			<form @submit.prevent="saveNotes">
				<table class="table table-bordered" style="border:0px; margin-bottom: 0px;">
					<tbody>
						<tr>
							<td rowspan="3" style="width: 70%;">
								<textarea v-model="f.comments" class="form-control" style="height: 80px;" placeholder="Comments" required></textarea>
							</td>
						</tr>
						<tr>
							<td>
								<input type="text" class="form-control" v-model="f.name" placeholder="Author" disabled="true">
							</td>
						</tr>
						<tr>
							<td class="text-left">
								<button type="submit" class="btn btn-success" style="width: 100%;">Save</button>
							</td>
						</tr>
						<tr>
							<td class="text-left">
								<!--<span style="margin-right: 20px;"><i class="fa fa-image"></i></span>
								<span><i class="fas fa-paperclip"></i></span>-->
							</td>
						</tr>
					</tbody>
				</table>			
			</form>
		</div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
name: 'notes',
	data () {
		return {
			f: {
				user_id: window.master_client,
                org_id: window.localorgdata,
				sales_id: 0,
				name: '',
				comments: '',
			},
			notesList: [],
			profile: {},
		}
	},
	created(){
		let t = this;
		t.$store.dispatch('core/session/validTypeUser').then((admin) => {
            t.getProfile(admin).then((r) => {
				t.init();
            })
        })
	},
	methods:{
		init(){
			let t = this;
			let d = t.$store.getters['core/getOpenModal'].data;
			t.f= {
				user_id: window.master_client,
                org_id: window.localorgdata,
				sales_id: d.id,
				name: '',
				comments: '',
			};
			document.querySelector('body').classList.add('overflowYhide');
			t.getNotes();
		},
		getNotes(){
			let t = this;
			t.$store.commit("core/setLoadingModal", true);
			window.master
            .post('getNotesSales', t.f)
            .then((response) => {
                console.log('getNotesSales', response.data)
                t.notesList=response.data.notes;
                t.$store.commit("core/setLoadingModal", false);
				t.f.comments= '';
				setTimeout(function(){
					let scroll=document.querySelector(".container-comments");
					// scroll.scrollTop=scroll.scrollHeight;
					t.f.name = t.profile.name +" "+ t.profile.last;
				}, 200);
            })
            .catch((error) => {
                console.log(error);
                t.$store.commit("core/setLoadingModal", false);
            });
		},
		saveNotes(){
			let t = this;

			t.$store.commit("core/setLoadingModal", true);
			window.master
            .post('setNotesSales', t.f)
            .then((response) => {
                console.log('setNotesSales', response.data)
            })
            .catch((error) => {
                console.log(error);
                //t.$refs.addGalley.value=null;
                t.$store.commit("core/setLoadingModal", false);
            });
		},
		close(){
			this.f.name= '';
			this.f.comments= '';
			this.$store.commit("core/setCloseModal", true);
		},
        getProfile (admin) {
			let t = this;
        	return new Promise((resolve, reject) => {
                window.master
                .post('userProfile', {
                	user_id: window.localAccountID,
                	admin: admin
                })
                .then((response) => {
                    t.profile = response.data.profile
                    resolve(true);
                })
                .catch((error) => {
                    console.log(error)
                    reject(false);
                })
            });
        },
	},
	watch: {
        getPusher: function(){
            let t = this;
            if (t.getPusher != undefined) {
                if(t.getPusher.action == "actNote" && t.getPusher.data.org_id == window.localorgdata && t.getPusher.data.sales_id==t.f.sales_id){
                    console.log('actNote', t.getPusher);
                    t.getNotes();
                }
            }
        },
    },
    computed: {
        ...mapGetters("core", { 
            getPusher: "get_accitonPusher"
        }),
    }
}
</script>
<style lang="css" scoped>
	.table-bordered th, .table-bordered td {
	    border: 0px solid #dee2e6;
	}
	.container-comments {
	    display: block;
	    width: 100%;
	    height: calc(100vh - 210px);
	    overflow: hidden;
    	overflow-y: auto;
	}
	ul.listComments {
    list-style: none;
    margin-bottom: 0px;
    padding-inline-start: 0px;
    margin-block-start: 0px;
}

.comments-content {
    padding: 10px;
    margin-right: 20px;
    border: 1px solid #e4daff;
    border-radius: 10px;
    background: rgb(228 218 255 / 49%);
    margin-bottom: 20px;
}

.date, .by {
    font-size: 10px;
    color: #bebebe;
}
.comment {
    font-size: 16px;
}

</style>
