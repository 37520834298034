<template>
    <div>
        <div
            class="messageBox"
            v-show="start_invoice"
            v-bind:class="{ show: start_invoice }"
        >
            <div class="box">
                <div class="conte">
                    <i class="fas fa-exclamation-triangle"></i>
                    <p>
                        You want to change the start of the numeration of your Invoices?.
                        Do it now or click Cancel.
                    </p>
                    <button
                        class="btn btn-success"
                        style="margin-right: 5px"
                        @click="activateStartUpdate"
                    >
                        Update Start Number
                    </button>
                    <button class="btn btn-primary" @click="activateStartInvoice">
                        Cancel
                    </button>
                    <Loader v-show="isLoadingstart"></Loader>
                </div>
            </div>
        </div>
        <!--  <div class="page-breadcrumb">
            <div class="row">
                <div class="col-12 align-self-center">
                     <h4 class="page-title">{{formInvoice.titlePage}}</h4>
                </div>
            </div>
        </div> -->
        <div class="container-fluid">
            <form @submit.prevent="saveInvoice">
                <div class="card">
                    <div class="alert alert-danger" role="alert" v-if="errors.length">
                        <ul style="margin-bottom: 0">
                            <li v-for="error in errors" :key="error">{{ error }}</li>
                        </ul>
                    </div>
                    <div class="card-header">
                        <div class="row">
                            <div class="col-6 align-self-center">
                                <h3 class="page-title">
                                    {{ formInvoice.titlePage }}
                                    <span v-if="formInvoice.numdoc != null"
                                        ># {{ formInvoice.numdoc }}
                                        <button 
                                            @click="actionNotes(formInvoice.idInv)"                                                    
                                            type="button" 
                                            title="Edit Notes"                                                        
                                            class="btn btn-secundary"
                                            style="padding: 0px 3px; font-size: 10px; height: 16px;">
                                            <i class="far fa-sticky-note"></i>
                                            Edit Note
                                        </button>                                         
                                    </span>
                                </h3>
                            </div>
                            <div class="col-6 text-right">
                                <button
                                    type="submit"
                                    class="btn btn-success"
                                    :disabled="saveDisabled"
                                >
                                    <i class="fas fa-save"></i> SAVE
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-secundary"
                                    style="margin-right: 5px; margin-left: 5px"
                                    @click="$router.push({ name: 'Invoices' })"
                                >
                                    <i class="fas fa-times"></i> CANCEL
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-danger"
                                    @click="MetDeleteEstimate(formInvoice.idInv)"
                                    v-if="formInvoice.actionNname == 'update'"
                                >
                                    <i class="fas fa-trash-alt"></i> DELETE
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="bgloading" v-show="isLoading"><Loader></Loader></div>
                        <div class="row">
                            <div class="col-12">
                                <div class="row">
                                    <div
                                        class="col-md-12 col-xl-10 offset-xl-1 col-lg-12"
                                    >
                                        <div class="row">
                                            <div class="col-5">
                                                <label
                                                    for="inputEmail4"
                                                    style="
                                                        position: relative;
                                                        width: 100%;
                                                        display: block;
                                                    "
                                                    >Customer:
                                                    <span
                                                        style="
                                                            position: absolute;
                                                            right: 0;
                                                            top: 3px;
                                                        "
                                                    >
                                                        <button
                                                            type="button"
                                                            @click="NewCustomer"
                                                            class="btnlink"
                                                        >
                                                            <i class="fas fa-plus"></i>
                                                            New Customer
                                                        </button>
                                                    </span>
                                                </label>
                                                <div
                                                    class="form-group"
                                                    id="CustomersList"
                                                >
                                                    <model-select
                                                        :options="CustomersList"
                                                        v-model="ValuesCustomers"
                                                        placeholder="Choose a Customer"
                                                    >
                                                    </model-select>
                                                </div>
                                            </div>
                                            <div class="col-5">
                                                <label for="inputEmail4"
                                                    >Invoice Name (Optional)
                                                </label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    maxlength="50"
                                                    v-model="formInvoice.invoicename"
                                                />
                                            </div>

                                            <div class="col-5">
                                                <label for="inputEmail4">Bill To:</label>
                                                <div class="form-group bill_to">
                                                    
                                                    <div>
                                                        <span>
                                                            {{ ValuesCustomers.customer_name }}
                                                        </span>
                                                        <span>
                                                            {{ ValuesCustomers.customer_lastname
                                                    }}
                                                        </span>
                                                    </div>
                                                    <div v-if="ValuesCustomers.bussinesname!=null && ValuesCustomers.bussinesname!='' && ValuesCustomers.bussinesname!='null'">
                                                        {{ ValuesCustomers.bussinesname }}
                                                    </div>
                                                    <div 
                                                        v-if="ValuesCustomers.billing_address!='' && ValuesCustomers.billing_address!=null"
                                                    >
                                                        {{ValuesCustomers.billing_address}}
                                                    </div>
                                                    <div
                                                        v-if="ValuesCustomers.city!=null ||
                                                        ValuesCustomers.state!=null ||
                                                        ValuesCustomers.code!=null 
                                                        "
                                                    >
                                                        <span v-if="ValuesCustomers.city!=null" style="margin-right:5px;">{{ValuesCustomers.city}}</span>
                                                        <span v-if="ValuesCustomers.state!=null" style="margin-right:5px;">{{ ValuesCustomers.state }}</span>
                                                        <span v-if="ValuesCustomers.code!=null" style="margin-right:5px;">{{ ValuesCustomers.code }}</span>
                                                    </div>          
                                                    <div>
                                                        <span v-if="ValuesCustomers.customer_phone!=null">
                                                            {{ ValuesCustomers.customer_phone }}
                                                        </span>
                                                        <span v-if="ValuesCustomers.customer_officephone!=null">
                                                            <span v-if="ValuesCustomers.customer_phone!=null"> / </span>
                                                            {{ ValuesCustomers.customer_officephone }}
                                                        </span>                  
                                                    </div>
                                                    <div v-if="ValuesCustomers.email!=null">
                                                        {{ ValuesCustomers.email }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-5">
                                                <label
                                                    for="inputEmail4"
                                                    v-if="formInvoice.jobinfomationcheck"
                                                    >{{
                                                        formInvoice.jobinformationTitle
                                                    }}:</label
                                                >
                                                <textarea
                                                    name=""
                                                    id="job_information"
                                                    cols="30"
                                                    rows="5"
                                                    class="form-control"
                                                    v-model="
                                                        formInvoice.jobinformation_text
                                                    "
                                                    v-if="formInvoice.jobinfomationcheck"
                                                ></textarea>
                                            </div>
                                            <div class="col-2" style="padding-right: 0px">
                                                <div>
                                                    <div>
                                                        <label for="inputEmail4"
                                                            >Date: </label
                                                        >
                                                        <datetime
                                                            type="date"
                                                            v-model="inputFecdoc"
                                                            format="MMM dd, yyyy"
                                                            value-zone="America/New_York"
                                                            input-class="form-control"
                                                        ></datetime>
                                                    </div>
                                                    <label for="">Due Date:</label>
                                                    <span
                                                        class="cdropdown selec_DueDate"
                                                        v-click-outside="hideBtnDueDate"
                                                    >
                                                        <button
                                                            type="button"
                                                            @click="btnDueDate()"
                                                            style="border: 0px"
                                                        >
                                                            <span>{{ Due_Date }}</span>
                                                        </button>
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                v-model="DueDatedropd"
                                                            />
                                                            <ul>
                                                                <li>
                                                                    <button
                                                                        type="button"
                                                                        @click="
                                                                            dueDate(0)
                                                                        "
                                                                    >
                                                                        Due on Receipt
                                                                    </button>
                                                                </li>
                                                                <li
                                                                    v-for="item in TermsList"
                                                                    :key="item.id"
                                                                >
                                                                    <button
                                                                        type="button"
                                                                        @click="
                                                                            dueDate(
                                                                                item.id
                                                                            )
                                                                        "
                                                                    >
                                                                        {{ item.name }}
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                        </label>
                                                    </span>
                                                </div>
                                                <div>
                                                    <datetime
                                                        type="date"
                                                        disabled
                                                        v-model="formInvoice.fecdue"
                                                        format="MMM dd, yyyy"
                                                        value-zone="America/New_York"
                                                        input-class="form-control pdate"
                                                    ></datetime>
                                                </div>
                                            </div>
                                            <tableProductos></tableProductos>
                                            <div
                                                class="blockMont"
                                                style="
                                                    display: flex;
                                                    justify-content: space-between;
                                                    width: 100%;
                                                "
                                            >
                                                <div style="width: 50%">
                                                    <textarea name="" id="" cols="30" rows="4" class="form-control" v-model="formInvoice.customer_message" placeholder="Customer message"></textarea>
                                                </div>
                                                <div style="width: 50%">
                                                    <tableResumenTotales :form="formInvoice"></tableResumenTotales>
                                                </div>
                                            </div>
                                            <div
                                                style="
                                                    width: 100%;
                                                    text-align: right;
                                                    margin-top: 20px;
                                                "
                                            >
                                                <button
                                                    type="submit"
                                                    class="btn btn-success"
                                                    :disabled="saveDisabled"
                                                >
                                                    <i class="fas fa-save"></i> SAVE
                                                </button>
                                                <button
                                                    type="button"
                                                    class="btn btn-secundary"
                                                    style="
                                                        margin-right: 10px;
                                                        margin-left: 10px;
                                                    "
                                                    @click="
                                                        $router.push({ name: 'Invoices' })
                                                    "
                                                >
                                                    <i class="fas fa-times"></i> CANCEL
                                                </button>
                                                <button
                                                    type="button"
                                                    class="btn btn-danger"
                                                    @click="
                                                        MetDeleteEstimate(
                                                            formInvoice.idInv
                                                        )
                                                    "
                                                    v-if="
                                                        formInvoice.actionNname ==
                                                        'update'
                                                    "
                                                >
                                                    <i class="fas fa-trash-alt"></i>
                                                    DELETE
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <!-- modal -->
        <modalLateral v-if="modalBill.indexOf(m.component)> -1">
            <component v-bind:is="m.component"></component>
        </modalLateral>          
    </div>
</template>
<script>
import Loader from '@/core/components/shared/Loader'
// import { BasicSelect  } from 'vue-search-select'
// import Taxes from '@/apps/billing/components/setting/Taxes'
import ClickOutside from 'vue-click-outside'
import draggable from 'vuedraggable'
import { mapGetters } from 'vuex'
import { ModelSelect } from 'vue-search-select'
import modalLateral from '@/core/plugins/themeModalLateral/modalLateral'
import NotesAdmin from '@/apps/billing/components/NotesAdmin'
import StartInvoice from '@/apps/billing/components/setting/StartInvoice'
import formCustomer from '@/apps/billing/components/formCustomer'
import formProducts from '@/apps/billing/components/formProducts'
import tableProductos from '@/apps/billing/components/tableProductos'
import tableResumenTotales from '@/apps/billing/components/tableResumenTotales'
export default {
    name: "InvoiceNew",
    display: "Table",
    order: 8,
    components: {
        Loader,
        // Taxes,
        // BasicSelect,
        draggable,
        ModelSelect,
        modalLateral,
        StartInvoice,
        NotesAdmin,
        formCustomer,
        formProducts,
        tableProductos,
        tableResumenTotales,
    },
    data () {
        return {
            m: {
                show: false,
                component: '',
                data: []
            },             
            list: [],
            dragging: false,
            isLoading: true,
            isLoadingstart: false,
            errors: [],
            showModal: false,
            ProdServiceLists: [],
            CustomersList: [],
            TaxesLists: [],
            SettingBox: "",
            start_invoice: false,
            //dataSearchProducts : [],
            //searchFilter : null,
            //TextSearchDescripcion : '',
            formList: {
                items: [],
            },
            TermsList: [],
            ValuesCustomers: {
                text: null,
                value: null,
                billing_address: null,
                bussinesname: null,
                customer_address: null,
                customer_name: null,
                customer_phone: null,
                customer_officephone: null,
                email: null,
            },
            formcustomer: {
                CustomerId: null,
                firstname: null,
                email: null,
                address: null,
                state: null,
                lastname: null,
                phone: null,
                city: null,
                zip: null,
                error: 0,
            },
            formInvoice: {
                idInv: null,
                numdoc: null,
                titlePage: "Add Invoice",
                btnName: "SAVE",
                actionNname: "create", // update
                customer_id: null,
                term_id: 0,
                customer_name: null,
                customer_email: null,
                customer_address: null,
                billing_address: null,
                invoicename: null,
                customer_message: null,
                jobinfomationcheck: true,
                jobinformationTitle: "Job information",
                jobinformation: null,
                jobinformation_text: null,
                subtotal: 0,
                discount_type: 1,
                discount_mount: 0.00,
                discount_total: 0.00,
                discount_name: '',
                total: 0,
                fecdoc: new Date().toISOString(),
                fecdue: new Date().toISOString(),
                totaltax: null,
                qty: null,
                dataItems: [],
            },
            HideSuggestionProd: false,
            TypeofAccount: [],
            FormAddPS: {
                vatError: false,
                vatErrorMsg: null,
                index_modal: null,
                btnName: "SAVE",
                Action: "Add",
                id: null,
                productname: null,
                account_id: 0,
                quantity: 1,
                unitcost: 1.00,
                description: null,
                applytax: 0,
                status: 0,
            },
            taxesResumen: [],
            inputFecdoc: new Date(
                (new Date(Date.now() - ((new Date()).getTimezoneOffset() * 60000))).toISOString().slice(0, 10) + "T23:31:04.000Z"
            ).toISOString(),
            Due_Date: "",
            DueDatedropd: false,
            ProdServiceisLoading: false,
            saveDisabled: false,
            DiscountDropd: false,
            modalBill: [
                'NotesAdmin',
                'formCustomer',
                'formProducts',
                'StartInvoice',
            ],
            PlanPremium : (window.OrgPlan > 1),
            statusLoadName:[
                ['has not loaded'],
                ['loading'],
                ['success'],
                ['faild'],
                ['error'],
                ['is already configured'],
            ],
            stateLoadDOM: [
                {
                    name: 'metstartInvoice',
                    status: 0,
                },
                {
                    name: 'getCustomer',
                    status: 0,
                },
                {
                    name: 'getTaxes',
                    status: 0,
                },
                {
                    name: 'getProdservices',
                    status: 0,
                },
                {
                    name: 'InvoiceById',
                    status: 0,
                },
                {
                    name: 'EstimateById',
                    status: 0,
                },
                {
                    name: 'getAccountTypes',
                    status: 0,
                },
                {
                    name: 'getTermsList',
                    status: 0,
                },
                {
                    name: 'getInvoiceSetting',
                    status: 0,
                },
            ],
        };
    },
    beforeMount () {},
    created () {
        console.log('status', this.statusLoadName)
        this.init()
    },
    mounted () {},
    directives: {
        ClickOutside,
    },
    methods: {
        statusLoad(id, status){
            this.stateLoadDOM[id].status = status;
            console.log('stateLoadDOM', this.stateLoadDOM[id].name, 'Status='+this.statusLoadName[status][0], new Date().getTime());
            if(status == 3 || status == 4){
                let msj = 'Property '+this.stateLoadDOM[id].name+' '+this.statusLoadName[status][0];
                /*this.$notify({
                    group: "noti",
                    type: "error",
                    title: "Error load",
                    text: msj
                });
                setTimeout(function(){
                    window.location.reload(true);
                }, 5000);*/
            }
        },
        init() {
            this.PlanPremium = (this.$store.getters['core/Org/getPlanId'] > 1);
            this.metstartInvoice();
            this.getCustomer();
            this.getTaxes();
            this.getAccountTypes();
            this.getTermsList();
            this.getInvoiceSetting();
        },      
        msjAlert(msj){
            this.$notify({
                group: "noti",
                title: "Error",
                type: "error",
                text: msj
            });
        },    
        formatUSD(amount) {    
            let vatIdRegex = new RegExp(/^[0-9.,-]+$/); 
            let value = ''     
            let val = 0.0;
            if (vatIdRegex.test(amount)) {
                let ops = {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                };
                let numberFormat2 = new Intl.NumberFormat('en-US', ops);  
                value = numberFormat2.format(amount)                
                // console.log('numberFormat2', numberFormat2.format(amount)); // amount
                if (value.indexOf(".") > -1) {
                    val = value;
                } else {
                    val = value + ".00";
                }
            }              
            return val
        },       
        actionNotes(id){
            let t = this;
            this.m = {
                show: true,
                component: 'NotesAdmin',
                data: {
                    id:id, 
                    type:'Invoice',
                    transac_type:'Invoice',
                    idNote: id
                }
            }             
            this.$store.commit('core/setOpenModal', this.m);  
        },        
        NewCustomer() {  
            let t = this;
            this.m = {
                show: true,
                component: 'formCustomer',
                data: { }
            }             
            this.$store.commit('core/setOpenModal', this.m); 
        },        
        NewProducts(item, index) {
            this.FormAddPS.index_modal = index;
            let t = this;
            this.m = {
                show: true,
                component: 'formProducts',
                data: { 
                    FormAddPS:t.FormAddPS,
                    TypeofAccount:t.TypeofAccount,
                    TaxesLists:t.TaxesLists,
                    formList:t.formList              
                }
            }             
            this.$store.commit('core/setOpenModal', this.m);             
        }, 
        activateStartUpdate() {
            let t = this;
            this.m = {
                show: true,
                component: 'StartInvoice',
                data: { }
            }             
            this.$store.commit('core/setOpenModal', this.m);             
        },                      
        btnDueDate() {
            this.DueDatedropd = !this.DueDatedropd;
        },
        hideBtnDueDate() {
            this.DueDatedropd = false;
        },                      
        edicionDuplicadosOtros() {
            if (this.$route.params.idinv && this.$route.params.edit) {
                this.formInvoice.titlePage = "Edit Invoice";
                this.formInvoice.actionNname = "update";
                this.InvoiceById(1);
            }
            if (this.$route.params.idinv && this.$route.params.duplicate) {
                this.formInvoice.titlePage = "Duplicate Invoice";
                this.formInvoice.actionNname = "duplicate";
                this.InvoiceById(2);
            }
            if (this.$route.params.idest && this.$route.params.convert) {
                this.formInvoice.titlePage = "Convert Estimate to Invoice";
                this.formInvoice.actionNname = "create";
                this.EstimateById();
            }
            if (this.$route.params.idc && this.$route.params.transac) {
                this.getCustomer(this.$route.params.idc);
                this.addNewLinePrimary();
            }
        },
        formatearFecha() {
            this.inputFecdoc = new Date(
                (new Date(this.inputFecdoc).toISOString() + "").slice(0, 10) +
                    "T23:31:04.000Z"
            ).toISOString();
        },
        getInvoiceSetting() {
            this.statusLoad(8, 1);
            window.billing
                .get("invoice/setting/" + window.localuserdata)
                .then((response) => {
                    // console.log(response.data.result);
                    if (response.data.result.length) {
                        this.formInvoice.jobinfomationcheck =
                            response.data.result[0].jobinfomationcheck;
                        this.formInvoice.jobinformationTitle =
                            response.data.result[0].jobinfomationtitle;
                        this.formInvoice.jobinformation_text =
                            response.data.result[0].jobinfomationtext;
                        this.formInvoice.term_id = response.data.result[0].term_id;
                        this.statusLoad(8, 2);
                    }else{
                        this.statusLoad(8, 3);
                    }
                    // console.log(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    this.statusLoad(8, 4);
                });
        },
        EstimateById() {
            this.isLoading = true;
            this.statusLoad(5, 1);
            window.billing
                .get("estimate/" + this.$route.params.idest)
                .then((response) => {
                    this.formInvoice.invoicename = response.data.result.estimate_optional;
                    this.formInvoice.jobinformation =response.data.result.job_information;
                    this.formInvoice.jobinformation_text =response.data.result.job_information;
                    this.formInvoice.customer_message =
                        response.data.result.customer_message;
                    this.formInvoice.sub_total = response.data.result.sub_total;
                    this.formInvoice.total = response.data.result.total;
                    this.formInvoice.discount_type = response.data.result.discount_type;
                    this.formInvoice.discount_mount = response.data.result.discount_mount;
                    this.formInvoice.discount_total = response.data.result.discount_total;
                    this.formInvoice.discount_name = response.data.result.discount_name;
                    this.selectCTemplate = {
                        description: response.data.result.terms_conditions_desc,
                        title: response.data.result.terms_conditions_title,
                    };
                    this.selectBillTo(response.data.customer.id);
                    this.formList.items = [];
                    for (let item in response.data.itemsprod) {
                        let taxfil = this.TaxesLists.filter(
                            (taxfil) => taxfil.id == response.data.itemsprod[item].tax_id
                        );
                        let newObj = taxfil.reduce((a, b) => Object.assign(a, b), {});
                        let isEmptyObj = !Object.keys(newObj).length;
                        let unitcost = this.formatUSD(response.data.itemsprod[item].unitcost); 
                        let qty = this.formatUSD(response.data.itemsprod[item].qty)  
                        let unitcost_n = response.data.itemsprod[item].unitcost  
                        let qty_n = response.data.itemsprod[item].qty                         
                        let price = parseFloat((qty_n * unitcost_n).toFixed(2))                 
                        this.formList.items.push({
                            options: this.ProdServiceLists,
                            taxlistOption: this.TaxesLists,
                            model: {
                                value: response.data.itemsprod[item].product_id,
                                unitcost: unitcost,
                                text: response.data.itemsprod[item].product_name,
                                descripcion: response.data.itemsprod[item].description,
                                qty: qty,                                                                
                                taxOpc: isEmptyObj ? 0 : newObj,
                                prices: price,
                            },
                        });
                    }
                    console.log(this.formList.items);
                    this.isLoading = false;
                    this.statusLoad(5, 2);
                })
                .catch((error) => {
                    console.log(error);
                    this.statusLoad(5, 4);
                });
        },
        metstartInvoice() {
            this.statusLoad(0, 1);
            window.billing
                .get("startinvoice/" + window.localuserdata)
                .then((response) => {
                    // console.log(response.data);
                    if (response.data.start_invoice == false) {
                        this.start_invoice = true;
                        this.statusLoad(0, 2);
                    }else{
                        this.statusLoad(0, 5);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.statusLoad(0, 4);
                });
        },
        activateStartInvoice() {
            this.isLoadingstart = true;
            window.billing
                .post("startinvoice/add", {
                    user_id: window.localuserdata,
                    startnumber: "001",
                })
                .then((response) => {
                    this.isLoadingstart = false;
                    this.start_invoice = false;
                })
                .catch((error) => console.log(error, (this.isLoadingstart = false)));
        },
        InvoiceById(status) {
            this.isLoading = true;
            this.statusLoad(4, 1);
            window.billing
                .get("invoice/" + this.$route.params.idinv)
                .then((response) => {
                    this.formInvoice.idInv = response.data.result.id;
                    this.formInvoice.jobinformation = response.data.result.jobinformation;
                    this.formInvoice.invoicename = response.data.result.invoicename;
                    this.formInvoice.jobinformation_text =
                        response.data.result.jobinformation_text;
                    this.formInvoice.customer_message =
                        response.data.result.customer_message;
                    this.formInvoice.term_id = response.data.result.terms_id;
                    this.dueDate(this.formInvoice.term_id);
                    this.formInvoice.numdoc = response.data.result.numdoc;
                    this.inputFecdoc = (status==2) ? new Date().toISOString().slice(0, 10) : response.data.result.fecdoc;
                    this.formInvoice.fecdue = (status==2) ? new Date().toISOString().slice(0, 10) : response.data.result.fecdue;
                    this.formInvoice.subtotal = response.data.result.subtotal;
                    this.formInvoice.discount_type = response.data.result.discount_type;
                    this.formInvoice.discount_mount = response.data.result.discount_mount;
                    this.formInvoice.discount_total = response.data.result.discount_total;
                    this.formInvoice.discount_name = response.data.result.discount_name;
                    this.formInvoice.total = response.data.result.total;
                    this.ValuesCustomers = {
                        text:
                            response.data.customer.firstname +
                            " " +
                            response.data.customer.lastname,
                        value: response.data.customer.id,
                        billing_address: response.data.customer.billingaddress,
                        bussinesname: response.data.customer.bussinesname,
                        customer_address: response.data.customer.billingaddress,
                        customer_name: response.data.customer.firstname,
                        customer_lastname: response.data.customer.lastname,
                        customer_phone: response.data.customer.mobilephone,
                        customer_officephone: response.data.customer.officephone,
                        city: response.data.customer.bcity,
                        state: response.data.customer.bstate,
                        code: response.data.customer.bpostcode,
                        email: response.data.customer.email,
                    };
                    let data_search = [];
                    for (let item in response.data.itemsprod) {
                        let taxfil = this.TaxesLists.filter(
                            (taxfil) => taxfil.id == response.data.itemsprod[item].tax_id
                        );
                        let newObj = taxfil.reduce((a, b) => Object.assign(a, b), {});
                        let isEmptyObj = !Object.keys(newObj).length;
                        let unitcost = this.formatUSD(response.data.itemsprod[item].price);  
                        let qty = this.formatUSD(response.data.itemsprod[item].quantity);  
                        let unitcost_n = response.data.itemsprod[item].price  
                        let qty_n = response.data.itemsprod[item].quantity                         
                        let price = parseFloat((qty_n * unitcost_n).toFixed(2))                                                                    
                        this.formList.items.push({
                            options: this.ProdServiceLists,
                            taxlistOption: this.TaxesLists,
                            model: {
                                value: response.data.itemsprod[item].product_id,
                                unitcost: unitcost,
                                text: response.data.itemsprod[item].product_name,
                                descripcion: response.data.itemsprod[item].description,
                                qty: qty,                                
                                taxOpc: isEmptyObj ? 0 : newObj,
                                prices:price,
                            },
                        });
                        /* var fil = this.ProdServiceLists.find(f => f.value === response.data.itemsprod[item].product_id);
                        data_search.push({'value':item,'text': fil.text})
                        let d = this.formList.items */
                    }
                    //this.dataSearchProducts = data_search
                    this.isLoading = false;
                    this.statusLoad(4, 2);
                })
                .catch((error) => {
                    console.log(error);
                    this.statusLoad(4, 4);
                });
        },
        getAccountTypes() {
            this.statusLoad(6, 1);
            window.billing
                .get("chart-account-list/" + window.localuserdata)
                .then((response) => {
                    // console.log("Account type form modal es");
                    // console.log(response.data.result);
                    this.TypeofAccount = response.data.result;
                    this.statusLoad(6, 2);
                })
                .catch((error) => {
                    console.log(error);
                    this.statusLoad(6, 4);
                });
        },
        MetSAddProdService() {
            let x = this.formList.items[this.FormAddPS.index_modal];
            if (this.FormAddPS.unitcost == 0) {
                this.FormAddPS.vatErrorMsg = "Please enter Valid Number.";
                return false;
            }
            if (this.FormAddPS.Action == "Add") {
                this.formList.items[
                    this.FormAddPS.index_modal
                ].taxlistOption = this.TaxesLists;
                if (this.FormAddPS.account_id == 0) {
                    this.$notify({
                        group: "notiMetSAddProdService",
                        title: "Error",
                        type: "error",
                        text: "You must select an Account.",
                    });
                } else {
                    this.ProdServiceisLoading = true;
                    window.billing
                        .post("product-service/add", {
                            user_id: window.localuserdata,
                            account_id: this.FormAddPS.account_id,
                            productname: this.FormAddPS.productname,
                            quantity: this.FormAddPS.quantity,
                            unitcost: this.FormAddPS.unitcost,
                            description: this.FormAddPS.description,
                            applytax: this.FormAddPS.applytax,
                            status: 1,
                        })
                        .then((response) => {
                            console.log(response.data);
                            //this.getProdservices();
                            if (response.data.status) {
                                let idTaxOpc = 0;
                                for (let i in this.TaxesLists) {
                                    if (
                                        this.TaxesLists[i].id == this.FormAddPS.applytax
                                    ) {
                                        idTaxOpc = this.TaxesLists[i];
                                    }
                                }
                                let unitcost = this.formatUSD(response.data.unitcost);
                                let quantity = this.formatUSD(response.data.quantity);                                                                
                                let model = {
                                    value: response.data.ProductId,
                                    text: response.data.productname,
                                    prodname: response.data.productname,
                                    unitcost: unitcost,
                                    descripcion: response.data.description,
                                    qty: quantity,
                                    taxOpc: idTaxOpc,
                                    applytax: this.FormAddPS.applytax,
                                };
                                this.ProdServiceLists.push(model);
                                this.formList.items[
                                    this.FormAddPS.index_modal
                                ].options = this.ProdServiceLists;
                                this.formList.items[
                                    this.FormAddPS.index_modal
                                ].model = model;
                                console.log(
                                    "nueva linea",
                                    this.formList.items[this.FormAddPS.index_modal]
                                );
                            }
                            this.FormAddPS.account_id = 0;
                            this.FormAddPS.productname = null;
                            this.FormAddPS.quantity = 1;
                            this.FormAddPS.unitcost = 1.00;
                            this.FormAddPS.description = null;
                            this.FormAddPS.applytax = 0;
                            this.FormAddPS.status = 1;
                            this.ProdServiceisLoading = false;
                            this.$notify({
                                group: "noti",
                                title: "Created",
                                type: "success",
                                text: "Add Product Successful",
                            });
                        })
                        .catch((error) => {
                            console.log(error);
                            this.ProdServiceisLoading = false;
                        });
                }
            }
        },
        getCustomer(idc = 0) {
            let self = this;
            this.statusLoad(1, 1);
            window.billing
                .get("customers/" + window.localuserdata)
                .then((response) => {
                    // console.log("customer list Invoice");
                    // console.log(response.data.result);
                    const Customers = [];

                    if (response.data.result) {
                        for (let item in response.data.result) {
                            var id = response.data.result[item].id;
                            var text = response.data.result[item].firstname;
                            var billing_address =
                                response.data.result[item].billingaddress;
                            var customer_address = response.data.result[item].address;
                            var bussinesname = response.data.result[item].bussinesname;
                            var email = response.data.result[item].email;
                            var lastname = response.data.result[item].lastname;
                            var customer_phone = response.data.result[item].mobilephone;
                            var customer_officephone = response.data.result[item].officephone;
                            var customer_lastname = response.data.result[item].lastname;
                            var city = response.data.result[item].bcity;
                            var state = response.data.result[item].bstate;
                            var code = response.data.result[item].bpostcode;
                            city = city != null ? city : "";
                            state = state != null ? state : "";
                            code = code != null ? code : "";
                            var guion = " - ";
                            if (bussinesname == null) {
                                bussinesname = "";
                                guion = " ";
                            }
                            if (lastname == null) {
                                lastname = "";
                            }
                            Customers.push({
                                value: id,
                                text: text + " " + lastname + guion + bussinesname,
                                customer_name: text,
                                customer_address: billing_address,
                                customer_phone: customer_phone,
                                customer_officephone: customer_officephone,
                                customer_lastname: lastname,
                                billing_address: billing_address,
                                bussinesname: bussinesname,
                                email: email,
                                city: city,
                                state: state,
                                code: code,
                            });
                            if (idc > 0 && idc == id) {
                                this.ValuesCustomers = {
                                    text: text + " " + lastname + guion + bussinesname,
                                    value: id,
                                    billing_address: billing_address,
                                    bussinesname: bussinesname,
                                    customer_address: customer_address,
                                    customer_name: text,
                                    customer_lastname: lastname,
                                    customer_phone: customer_phone,
                                    customer_officephone: customer_officephone,
                                    email: email,
                                    city: city,
                                    state: state,
                                    code: code,
                                };
                            }
                        }
                        this.statusLoad(1, 2);
                    }else{this.statusLoad(1, 3);}
                    this.CustomersList = Customers;
                    if (idc > 0) {
                        this.isLoading = false;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.statusLoad(1, 4);
                });
        },
        selectBillTo(idc) {
            for (let item in this.CustomersList) {
                let id = this.CustomersList[item].value;
                if (idc > 0 && idc == id) {
                    console.log("billTO", this.CustomersList[item]);
                    this.ValuesCustomers = {
                        text: this.CustomersList[item].text,
                        value: this.CustomersList[item].value,
                        billing_address: this.CustomersList[item].billing_address,
                        bussinesname: this.CustomersList[item].bussinesname,
                        customer_address: this.CustomersList[item].customer_address,
                        customer_name: this.CustomersList[item].customer_name,
                        customer_lastname: this.CustomersList[item].customer_lastname,
                        customer_phone: this.CustomersList[item].customer_phone,
                        customer_officephone: this.CustomersList[item].customer_officephone,
                        email: this.CustomersList[item].email,
                        city: this.CustomersList[item].city,
                        state: this.CustomersList[item].state,
                        code: this.CustomersList[item].code,
                    };
                }
            }
        },
        getTermsList() {
            this.statusLoad(7, 1);
            window.billing
                .get("terms/" + window.localuserdata)
                .then((response) => {
                    // console.log("terms");
                    // console.log(response.data);
                    this.TermsList = response.data;
                    this.statusLoad(7, 2);
                })
                .catch((error) => {
                    console.log(error);
                    this.statusLoad(7, 4);
                });
        },
        MetAddProd(ip, index) {
            console.log(index);
            this.HideSuggestionProd = false;
        },
        saveCustomer() {
            window.billing
                .post("estimate/customer/add", {
                    user_id: window.localuserdata,
                    firstname: this.formcustomer.firstname,
                    lastname: this.formcustomer.lastname,
                    mobilephone: this.formcustomer.phone,
                    officephone: this.formcustomer.officephone,
                    astate: this.formcustomer.state,
                    acity: this.formcustomer.city,
                    address: this.formcustomer.address,
                    email: this.formcustomer.email,
                    apostcode: this.formcustomer.zip,
                })
                .then((response) => {
                    if (response.data.status) {
                        let lastname = this.formcustomer.lastname;
                        lastname = lastname != null ? lastname : "";
                        var city = this.formcustomer.city;
                        city = city != null ? city + "," : "";
                        this.ValuesCustomers = {
                            text: this.formcustomer.firstname + " " + lastname,
                            value: response.data.customerId,
                            bussinesname: "",
                            billing_address: this.formcustomer.address,
                            customer_address: this.formcustomer.address,
                            customer_name: this.formcustomer.firstname,
                            customer_lastname: lastname,
                            customer_phone: this.formcustomer.phone,
                            customer_officephone: this.formcustomer.officephone,
                            email: this.formcustomer.email,
                            city: city,
                            state: this.formcustomer.state,
                            code: this.formcustomer.zip,
                        };
                        this.FormSignature = {
                            nameCustomer: this.formcustomer.firstname + " " + lastname,
                        };
                    }
                    this.getCustomer();
                    this.$notify({
                        group: "noti",
                        title: "Created",
                        type: "success",
                        text: "Successful registration",
                    });
                    this.formcustomer = {
                        firstname: null,
                        lastname: null,
                        phone: null,
                        state: null,
                        city: null,
                        address: null,
                        email: null,
                        zip: null,
                        error: 0,
                    };
                })
                .catch((error) => console.log(error, (this.isLoading = false)));
        },
        saveInvoice() {
            this.isLoading = true;
            this.saveDisabled = true;
            this.errors = [];
            if (!this.ValuesCustomers.value) {
                // this.errors.push('Must select "Customer"');
                this.msjAlert('Must select "Customer')
                this.isLoading = false;
                this.saveDisabled = false;
                return false;
            }
            if (this.formInvoice.total == 0) {
                // this.errors.push("Total must not be greater than zero");
                this.msjAlert("Total must not be greater than zero")
                this.isLoading = false;
                this.saveDisabled = false;
                return false;
            }
            // if (!this.formList.items[0].model.value && this.formList.items[0].model.descripcion.length==0) {
            //     // this.errors.push("Must add at least 1 Product or service");
            //     this.msjAlert("Must add at least 1 Product or service")
            //     this.isLoading = false;
            //     this.saveDisabled = false;
            //     return false;
            // }
            for (let i in this.formList.items) {
                if (
                    this.formList.items[i].model.qty == "" ||
                    this.formList.items[i].model.qty == null
                ) {
                    this.formList.items[i].model.qty = 0;
                }
                if (
                    this.formList.items[i].model.unitcost == "" ||
                    this.formList.items[i].model.unitcost == null
                ) {
                    this.formList.items[i].model.unitcost = 0;
                }                
            }
            if (this.ValuesCustomers.value && this.errors.length == 0) {
                if (
                    this.formInvoice.actionNname == "create" ||
                    this.formInvoice.actionNname == "duplicate"
                ) {
                    console.log(this.formInvoice);
                    window.billing
                        .post("invoice/add", {
                            typeUser: window.user_type,
                            oauth: localStorage.getItem("oauth_m"),
                            org_id: window.localuserdata,
                            user_idd: window.master_client,
                            user_id: window.localuserdata,
                            customer_id: this.ValuesCustomers.value,
                            invoicename: this.formInvoice.invoicename,
                            jobinformation: this.formInvoice.jobinformation,
                            jobinformation_text: this.formInvoice.jobinformation_text,
                            customer_message: this.formInvoice.customer_message,
                            sub_total: this.formInvoice.subtotal,
                            discount_type: this.formInvoice.discount_type,
                            discount_mount: this.formInvoice.discount_mount,
                            discount_total: this.formInvoice.discount_total,
                            discount_name: this.formInvoice.discount_name,
                            total: this.formInvoice.total,
                            fecdoc: this.formInvoice.fecdoc,
                            fecdue: this.formInvoice.fecdue,
                            term_id: this.formInvoice.term_id,
                            dataItems: this.formList.items,
                        })
                        .then((response) => {
                            this.isLoading = false;
                            console.log(response.data);
                            this.$notify({
                                group: "noti",
                                title: "Created",
                                type: "success",
                                text: "Successful registration",
                            });
                            this.saveDisabled = false;
                            this.$router.push({ name: "Invoices" });
                        })                        
                        .catch((error) => {
                            console.log(error);
                            this.isLoading = false;
                            this.saveDisabled = false;
                        });
                }
                if (this.formInvoice.actionNname == "update") {
                    window.billing
                        .put("invoice/edit/" + this.formInvoice.idInv, {
                            typeUser: window.user_type,
                            oauth: localStorage.getItem("oauth_m"),
                            org_id: window.localuserdata,
                            user_idd: window.master_client,
                            user_id: window.localuserdata,
                            customer_id: this.ValuesCustomers.value,
                            invoicename: this.formInvoice.invoicename,
                            numdoc: this.formInvoice.numdoc,
                            jobinformation: this.formInvoice.jobinformation,
                            customer_message: this.formInvoice.customer_message,
                            jobinformation_text: this.formInvoice.jobinformation_text,
                            sub_total: this.formInvoice.subtotal,
                            discount_type: this.formInvoice.discount_type,
                            discount_mount: this.formInvoice.discount_mount,
                            discount_total: this.formInvoice.discount_total,
                            discount_name: this.formInvoice.discount_name,
                            total: this.formInvoice.total,
                            fecdoc: this.formInvoice.fecdoc,
                            fecdue: this.formInvoice.fecdue,
                            term_id: this.formInvoice.term_id,
                            dataItems: this.formList.items,
                        })
                        .then((response) => {
                            this.isLoading = false;
                            this.saveDisabled = false;
                            console.log(response.data);
                            this.$notify({
                                group: "noti",
                                title: "Updated",
                                type: "success",
                                text: "Successful Updated",
                            });
                            this.$router.push({ name: "Invoices" });
                        })
                        .catch((error) => {
                            console.log(error);
                            this.isLoading = false;
                            this.saveDisabled = false;
                        });
                }
            }else {
                this.saveDisabled = false;
            }
        },
        getTaxes() {
            this.statusLoad(2, 1);
            window.billing
                .get("taxes/" + window.localuserdata)
                .then((response) => {
                    console.log("taxes request all");
                    //console.log(response.data)
                    //this.TaxesLists = response.data;
                    const ObjTaxes = [{
                            id: 0,
                            name: '--None--',
                            porcentaje: '',
                            initialstate: '',
                            statename: '',
                            count: 0,
                            total: 0,
                        }];
                    if (response.data) {
                        for (let item in response.data) {
                            var id = response.data[item].id;
                            var initialstate = response.data[item].initialstate;
                            var name = response.data[item].name;
                            var state_id = response.data[item].state_id;
                            var statename = response.data[item].statename;
                            var status = response.data[item].status;
                            var user_id = response.data[item].user_id;
                            ObjTaxes.push({
                                id: id,
                                initialstate: initialstate,
                                name: name,
                                state_id: state_id,
                                statename: statename,
                                status: status,
                                user_id: user_id,
                                default: "none",
                            });
                            this.taxesResumen.push({
                                id: id,
                                name: initialstate,
                                porcentaje: name,
                                initialstate: initialstate,
                                statename: statename,
                                count: 0,
                                total: 0,
                            });
                        }
                        this.statusLoad(2, 2);
                    }else{
                        this.statusLoad(2, 3);
                    }
                    this.TaxesLists = ObjTaxes;
                    this.getProdservices();
                })
                .catch((error) => {
                    console.log(error);
                    this.statusLoad(2, 4);
                });
        },
        getProdservices() {
            this.statusLoad(3, 1);
            window.billing
                .get("product-services/" + window.localuserdata)
                .then((response) => {
                    // console.log("product sevice list table");
                    // console.log(response.data.result);
                    //this.ProdServiceLists = response.data.result
                    const ProdList = [{
                        value: 0,
                        text: "Uncategorized",
                        description: "",
                        unitcost: "1.00",
                        qty: "1.00",
                        taxOpc: {
                            id: 0,
                            name: '--None--',
                            porcentaje: '',
                            initialstate: '',
                            statename: '',
                            count: 0,
                            total: 0,
                        },
                        applytax: 0,
                        prices: 0
                    }];
                    if (response.data.result) {
                        for (let item in response.data.result) {
                            var id = response.data.result[item].id;
                            var prodname = response.data.result[item].productname;
                            var quantity = this.formatUSD(response.data.result[item].quantity);
                            //console.log(prodname, response.data.result[item].unitcost);
                            var unitcost = this.formatUSD(response.data.result[item].unitcost);
                            //console.log(prodname, unitcost);
                            var descripcion = response.data.result[item].description;
                            var applytax = response.data.result[item].applytax;
                            let taxOpcID = 0;
                            for (let i in this.TaxesLists) {
                                if (
                                    this.TaxesLists[i].id ==
                                    response.data.result[item].applytax
                                ) {
                                    taxOpcID = this.TaxesLists[i];
                                }
                            }
                            ProdList.push({
                                value: id,
                                text: prodname,
                                unitcost: unitcost,
                                descripcion: descripcion,
                                qty: quantity,
                                taxOpc: taxOpcID,
                                applytax: applytax,
                            });
                        }
                    }else{
                        this.statusLoad(3, 3);
                    }
                    this.ProdServiceLists = ProdList;
                    /// item default 1
                    console.log(
                        "params=" + this.$route.params.length,
                        "params=" + this.$route.params,
                        this.$route.name,
                        "params=" + this.$route.hasOwnProperty("params")
                    );
                    // console.log(this.$route.params);
                    if (
                        this.$route.hasOwnProperty("params") &&
                        (this.$route.params.edit ||
                            this.$route.params.duplicate ||
                            this.$route.params.convert ||
                            this.$route.params.transac)
                    ) {
                        this.edicionDuplicadosOtros();
                    } else {
                        this.addNewLinePrimary();
                        this.isLoading = false;
                    }
                })
                .catch((error) => {
                    this.isLoading = false;
                    console.log(error);
                    this.statusLoad(3, 4);
                });
        },
        addNewLinePrimary() {
            const ProdList = [];
            for (let item in this.ProdServiceLists) {
                var id = this.ProdServiceLists[item].value;
                var prodname = this.ProdServiceLists[item].text;
                var quantity = this.ProdServiceLists[item].qty;
                // var quantity = this.formatUSD(this.ProdServiceLists[item].qty);
                // console.log(prodname, this.ProdServiceLists[item].unitcost)
                // var unitcost = this.formatUSD(this.ProdServiceLists[item].unitcost);
                // console.log(prodname, unitcost)
                var unitcost = this.ProdServiceLists[item].unitcost;
                var descripcion = this.ProdServiceLists[item].descripcion;
                var applytax = this.ProdServiceLists[item].applytax;
                let taxOpcID = {
                    id: 0,
                    name: '--None--',
                    porcentaje: '',
                    initialstate: '',
                    statename: '',
                    count: 0,
                    total: 0,
                };
                for (let i in this.TaxesLists) {
                    if (this.TaxesLists[i].id == this.ProdServiceLists[item].applytax) {
                        taxOpcID = this.TaxesLists[i];
                    }
                }
                let price = parseFloat((quantity * unitcost).toFixed(2))
                ProdList.push({
                    value: id,
                    text: prodname,
                    unitcost: unitcost,
                    descripcion: descripcion,
                    qty: quantity,
                    taxOpc: taxOpcID,
                    prices: price
                });
            }
            if (this.formInvoice.actionNname == "create") {
                let price = parseFloat((1 * 1).toFixed(2))
                this.formList.items.push({
                    options: ProdList,
                    taxlistOption: this.TaxesLists,
                    model: {
                        value: 0,
                        text: "Uncategorized",
                        description: "",
                        unitcost: "1.00",
                        qty: "1.00",
                        taxOpc: {
                            id: 0,
                            name: '--None--',
                            porcentaje: '',
                            initialstate: '',
                            statename: '',
                            count: 0,
                            total: 0,
                        },
                        applytax: 0,
                    },
                });
                console.log("primary item", this.formList.items);
            }
        },
        hide() {
            this.HideSuggestionProd = false;
        },
        MetDeleteEstimate(idinv) {
            this.isLoading = true;
            this.$swal({
                title: "Delete this Invoice?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                if (result.value) {
                    window.billing
                        .put("invoice/delete/" + idinv, {
                            user_id: window.localuserdata,
                        })
                        .then((response) => {
                            console.log(response.data);
                            this.isLoading = false;
                            if (response.data.status) {
                                this.$notify({
                                    group: "noti",
                                    title: "Deleted!",
                                    type: "success",
                                    text: "Deleted Invoice Successful",
                                });
                                this.$router.push({ name: "Invoices" });
                            }
                        })
                        .catch((error) => console.log(error, (this.isLoading = false)));
                }
            });
            this.isLoading = false;
        },
        checkVatId() {
            //RegExp(/^[1-9]+/) ?|0+\.[1-9][0-9]
            let vatIdRegex = new RegExp(/^(0|[1-9]\d*)(\.\d+)?$/);
            if (this.FormAddPS.unitcost == "") {
                this.FormAddPS.vatError = true;
                this.FormAddPS.vatErrorMsg = "Enter positive number.";
            } else if (!vatIdRegex.test(this.FormAddPS.unitcost)) {
                this.FormAddPS.vatError = true;
                this.FormAddPS.vatErrorMsg = "Please enter Valid Number.";
                //this.FormAddPS.unitcost = 0;
            } else {
                this.FormAddPS.vatError = false;
            }
        },
        dueDate(due) {
            this.formInvoice.term_id = due;
            this.asignarDueDate();
        },
        asignarDueDate() {
            var sumar = 0;
            if (this.formInvoice.term_id > 0) {
                for (let prop in this.TermsList) {
                    if (this.TermsList[prop].id == this.formInvoice.term_id) {
                        this.Due_Date = this.TermsList[prop].name;
                        let dias = this.TermsList[prop].name.split("NET ");
                        //console.log('dias ', dias[1]);
                        sumar = dias[1];
                    }
                }
                let hoy = new Date(this.formInvoice.fecdoc);
                //console.log(hoy); // .toISOString().slice(0,10)
                let d = hoy.getDate();
                let m = hoy.getMonth() + 1;
                let a = hoy.getFullYear();
                if (d < 10) {
                    d = "0" + d;
                }
                if (m < 10) {
                    m = "0" + m;
                }
                let r = this.sumaFecha(sumar, hoy);
                var nueva = new Date(r).toISOString();
                this.formInvoice.fecdue = nueva;
            } else {
                this.formInvoice.fecdue = this.formInvoice.fecdoc;
                this.Due_Date = "Due on Receipt";
            }
            this.hideBtnDueDate();
        },
        sumaFecha(d, fecha) {
            var a = (fecha + "").split(" "); // convertir el objeto en array
            var meses = [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ];
            var nro_mes = parseInt(meses.indexOf(a[1])) + 1;
            nro_mes = nro_mes < 10 ? "0" + nro_mes : nro_mes;
            var f = a[3] + "-" + nro_mes + "-" + a[2] + "T23:31:04.000Z"; // a-m-d+'T23:31:04.000Z'
            console.log("fecha  string = " + f);
            var fn = new Date(f);
            fn.setDate(fn.getDate() + parseInt(d));
            var anno = fn.getFullYear();
            var mes = fn.getMonth() + 1;
            var dia = fn.getDate();
            mes = mes < 10 ? "0" + mes : mes;
            dia = dia < 10 ? "0" + dia : dia;
            var fechaFinal = anno + "/" + mes + "/" + dia + " " + a[4] + " " + a[5]; // horas y GTM
            return fechaFinal;
        },
        ClosedModal() {
            this.SettingBox = "";
        },
        resfresh(res) {
            if (res.open == 1) {
                this.ClosedModal();
                this.closeModal();
                this.start_invoice = false;
            }
        },
    },
    watch: {    
        'getActionRefresh': function() {
            console.log('getActionRefresh', this.getActionRefresh)
            if (Object.keys(this.getActionRefresh.data).length > 0) {
                let data = this.getActionRefresh.data; 
                let methods = this.getActionRefresh.action;
                if (methods === 'newCustomer') {
                    this.ValuesCustomers = data;
                    this.getCustomer();
                }
                if (methods === 'products') {
                    this.ProdServiceLists.push(data.model);
                    this.formList.items[
                        this.FormAddPS.index_modal
                    ].options = this.ProdServiceLists;
                    this.formList.items[
                        this.FormAddPS.index_modal
                    ].model = data.model;                        
                }                                        
                this.m = {
                    show: false,
                    component: '',
                    data: {}
                }
            }
        },    
        // 'getOpenModal': function() {
        //     if (!this.getOpenModal.show) {
        //         if (Object.keys(this.getOpenModal.data).length > 0) {
        //             let t = this.getOpenModal.data; 
        //             debugger;
        //             if (t.methods === 'recurring') {
        //                 this.ValuesCustomers = t.ValuesCustomers
        //                 this.getCustomer();
        //             }
        //             if (t.methods === 'products') {
        //                 this.ProdServiceLists.push(t.model);
        //                 this.formList.items[
        //                     this.FormAddPS.index_modal
        //                 ].options = this.ProdServiceLists;
        //                 this.formList.items[
        //                     this.FormAddPS.index_modal
        //                 ].model = t.model;                        
        //             }                                        
        //         }
        //         this.m = {
        //             show: false,
        //             component: '',
        //             data: {}
        //         }
        //     }
        // },        
        get_setupBilling: function () {
            if (this.get_setupBilling) {
                let g = this.$store.getters["billing/get_setupBilling"];
                let fun = g[0].function;
                let r = g[0].data[0];
                if (fun == "UpdateStartInvoice") {
                    this.resfresh(r);
                }
            }
        },
        inputFecdoc: function () {
            this.formatearFecha();
            this.formInvoice.fecdoc = this.inputFecdoc;
            this.asignarDueDate();
        },
        formcustomer: {
            deep: true,
            handler: (e) => {
                //debugger;
                for (var i in e) {
                    if (i != "notes" && i != "error") {
                        if (e[i] != null && e[i].length > 0) {
                            if (e[i].indexOf(",") > -1) {
                                //console.log(i, e[i]);
                                e.error = parseInt(e.error) + 1;
                                e[i] = e[i].replace(",", "");
                                console.log(e);
                            }
                        }
                    }
                }
            },
        },
        "formcustomer.error": function (e) {
            if (e > 0) {
                this.$notify({
                    group: "notiCustomer",
                    title: "Error",
                    type: "error",
                    text: "The symbol coma “,” is not allowed.",
                });
                return (e = 0);
            }
        },
        getPlanId() {    
            this.PlanPremium = (this.$store.getters['core/Org/getPlanId'] > 1);
            console.log('watch PlanPremium', this.PlanPremium);
        },
    },
    computed: {     
        ...mapGetters("core", { getOpenModal: "getOpenModal", getActionRefresh: "getActionRefresh" }),
        ...mapGetters("core/Org", { getPlanId: "getPlanId"}),
        ...mapGetters("ModApps/billing", ["get_setupBilling"]),
        filtroTypeofAccount() {
            return this.TypeofAccount.filter(function (u) {
                return u.account_type === 3 || u.account_type === 5;
            });
        },
        isDisabled: function () {
            if (this.FormAddPS.unitcost == 0) {
                return this.FormAddPS.unitcost;
            } else {
                return false;
            }
        },
        /* 
            filterItemsDescription: function () {
            var self = this;
            return this.formList.items.filter(function (item) {
                return item.model.descripcion && item.model.descripcion.toLowerCase().indexOf(self.TextSearchDescripcion.toLowerCase()) >= 0
            });
            } 
        */
    },
};
</script>
<style lang="scss" scoped>
.cdropdown > button.discount:before {
    border-left: 0px;
    border-top: 0px;
}
.item_selected {
    background-color: #c6ddf5 !important;
}
.page-breadcrumb {
    position: relative;
    z-index: 9;
}
.messageBox {
    display: none;
    background-color: rgba(255, 255, 255, 0.74);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    .box {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        .conte {
            max-width: 350px;
            text-align: center;
            i {
                font-size: 40px;
                margin-bottom: 20px;
            }
            p {
                display: block;
                margin-bottom: 10px;
                font-size: 15px;
            }
        }
    }
    &.show {
        display: block;
    }
}
.flip-list-move {
    transition: transform 0.5s;
}
.no-move {
    transition: transform 0s;
}
.ghost {
    opacity: 0.5;
    background: #a0a0a0;
}
.iva_block {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding-top: 5px;
    padding-bottom: 5px;
    .itemsb {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
}
.pageSignature {
    display: block;
    margin-top: 20px;
    width: 100%;
}

.bill_to {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    padding-left: 2rem;
    font-size: 0.8rem;
    line-height: 1.5;
    color: #4f5467;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dedede;
    border-radius: 2px;
    height: 130px;
}
#job_information {
    height: 130px;
}

.form-control {
    border: 1px solid #dedede;
}
.pdate {
    padding: 0.255rem 0.75em;
}
.select-terms {
    height: calc(2rem + 2px) !important;
}
span.cdropdown.selec_DueDate {
    width: 100%;
    font-size: 0.8rem;
    line-height: 1.5;
    color: #6a7a8c;
    border: 1px solid #dedede;
    border-radius: 2px;
}
.cdropdown ul li {
    padding: 0px;
}
span.cdropdown.selec_DueDate button {
    color: #6a7a8c;
    width: 100%;
    text-align: left;
    padding: 6px 10px;
}
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
</style>
