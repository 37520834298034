<template>
    <div class="card">
        <div class="card-header"><i class="fa fa-star"></i> Review</div>
        <div class="card-body">
            <div v-if="isLoading"><Loader></Loader></div>
            <div v-else>
                <div v-if="stat == 1">
                </div>
                <div v-else>
                    <noData></noData>
                </div>
            </div>
        </div>
    </div>  
</template>
<script>
import Loader from '@/core/components/shared/Loader'
import noData from "@/apps/Marketea/components/google/noData.vue";
export default {
    name: "Reviews",
    components: {
        Loader,
        noData
    },
    data() {
        return {
            isLoading: false,
            f:{
                id:0,
                email:'',
                ids:0,
                locationId:0,
                user_id: window.master_client,
                org_id: window.localorgdata,                
            },
            stat:0
        }
    },
    created () {
        let apisGoogle = this.$store.getters['ModApps/Marketea/urlApis'];
        this.apis = apisGoogle.google;         
        this.get()         
    },
    methods: {
        get() {
            this.isLoading = true
            let url = this.apis.get()
            window.master
                .post(url, this.f)
                .then((response) => {
                    let r = response.data.rs
                    if (response.data.rs !== null) {
                        this.f.ids = r.ids
                        this.f.email = r.email
                        this.f.id = r.id
                        this.f.locationId = r.locationId  
                        this.stat = 1
                    } else {
                        this.stat = 0
                    }              
                    this.isLoading = false                 
                })
                .catch((error) => {
                    console.log(error, (this.isLoading = false))
                });
        },        
    }    
}
</script>
