let editRow = function(idx, i) {
	// debugger;
	let edit =false;
	let t =this;
	for (let j in t.dataTOLD) {
		if(t.dataTOLD[j] && t.dataTOLD[j].id == i.id) {
			if(t.dataTOLD[j].name != i.name) { edit =true;}
			if(t.dataTOLD[j].tlf != i.tlf) { edit =true;}
			if(t.dataTOLD[j].link != i.link) { edit =true;}
		}
	}
	if(edit) {
		console.log('editRow', idx, i.name)
		// t.isLoading = true;
		window.master.post(t.apis.edit(), i, {
			onUploadProgress: (progressEvent) => {
				t.getProgress = parseInt((progressEvent.loaded / progressEvent.total) * 100);
				t.getLoading = false;
			}
		})
		.then((response) => {
			if(response.data.status) {
				t.dataTOLD[idx] = i;
			}
			// t.isLoading = false;			
		})
		.catch((error) => {
			console.log(error);
			// t.isLoading = false;
		});
	}
};
export default editRow;
