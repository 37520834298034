/*rutas de timesheet*/
import Timesheet from '@/apps/billing/views/Timesheet';
import TimesheetSub from '@/apps/billing/views/TimesheetSub';
import TimesheetTask from '@/apps/billing/views/TimesheetTask';
import TimesheetAllChecks from '@/apps/billing/views/TimesheetAllChecks';
export default [
  {
  	path: 'timesheet',
  	name: 'Timesheet',
    folder: Timesheet,    
  },
  {
  	path: 'TimesheetTask',
  	name: 'TimesheetTask',
    folder: TimesheetTask,    
  },  
  {
    path: 'timesheet/open/numdoc/:id',
    name: 'TimesheetSub',
    folder: TimesheetSub,
  }, 
  {
    path: 'timesheet/check/numdoc/:id',
    name: 'TimesheetAllChecks',
    folder: TimesheetAllChecks,
  },    
];
