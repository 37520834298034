import Layout from '@/apps/projects/Layouts/Layout.vue'
import projectsDash from '@/apps/projects/views/projectsDash.vue'
import projectsView from '@/apps/projects/views/projectsView.vue'
import projectsVisualizer from '@/apps/projects/views/projectsVisualizer.vue'
import projectsContracts from '@/apps/projects/views/projectsContracts.vue'
import projectsSubcontracts from '@/apps/projects/views/projectsSubcontracts.vue'
import projectsWarranty from '@/apps/projects/views/projectsWarranty.vue'
import projectsReputation from '@/apps/projects/views/projectsReputation.vue'
import projectsTimesheet from '@/apps/projects/views/projectsTimesheet.vue'
import projectsSetting from '@/apps/projects/views/projectsSetting.vue'
export default [
	{
	  path: '/Projects',
		folder: Layout,
		meta: {requiresAuth: true},
		children: [
			{
				path: '/',
				name:'Projects',
				folder: projectsDash
			},
			{
				path: 'projects',
				name:'projectsView',
				folder: projectsView
			},
			{
				path: 'visualizer',
				name:'projectsVisualizer',
				folder: projectsVisualizer
			},
			{
				path: 'contracts',
				name:'projectsContracts',
				folder: projectsContracts
			},
			{
				path: 'Subcontracts',
				name:'projectsSubcontracts',
				folder: projectsSubcontracts
			},
			{
				path: 'Warranty',
				name:'projectsWarranty',
				folder: projectsWarranty
			},
			{
				path: 'Reputation',
				name:'projectsReputation',
				folder: projectsReputation
			},
			{
				path: 'Timesheet',
				name:'projectsTimesheet',
				folder: projectsTimesheet
			},
			{
				path: 'Setting',
				name:'projectsSetting',
				folder: projectsSetting
			},
		]
	}
]
