// incluir todo los archivos js del directorio actual
const requireModules = require.context('.', true, /\.js$/)
// variable para almacenar los path
const modules = {}
const excluir = ['./routerIndex.js', './storeIndex.js', './_app_example', '_app_example']
// correr arreglo
requireModules.keys().forEach(fileName => {
  // excluir archivos
  if (excluir.indexOf(fileName) > -1) return
  // remplazar ./ and .js
  const path = fileName.replace(/(\.\/|\.js)/g, '')
  // asignando valor del grupo app y su folder router
  // debugger;
  const [appName, imported] = path.split('/')
  // validacion de crear objeto sino existe
  if (!modules[appName] && excluir.indexOf(appName) == -1) {
    // console.log('appName', appName)
    modules[appName] = {
      namespaced: true
    }
  }
  // solo si es storeModules incluir
  if (appName !== '_app_example' && imported === 'storeModules') {
    let path = fileName.replace(/(\.\/|\.js)/g, '')
    let [appName, imported, nameImport] = path.split('/')
    // console.log('nameImport', nameImport)
    modules[appName][nameImport] = requireModules(fileName).default
  }
});
// console.log('modules', modules)
const ModApps = {
  namespaced: true,
  state:{},
  getters:{},
  mutations:{},
  actions:{},
  modules
};
export default ModApps; 
