let asignItemDataT = function(item) {
	let t =this;
	for (let j in t.dataT) {
		if(t.dataT[j] && t.dataT[j].id == item.id) {
			t.dataT[j] = item;
			t.dataTOLD[j] = item;		
		}
	}
};
export default asignItemDataT;
