// access y home
import Login from '@/core/Layouts/LoginLayout.vue';
import LayaoutActivation from '@/core/Layouts/ActivationLayout.vue';
import Activation from '@/core/components/Login/formActivation.vue';
import Logout from '@/core/components/Logout.vue';
import Home from '@/core/views/Home.vue'
// options of account
import DefaultLayout from '@/core/Layouts/Account/DefaultLayout.vue';
import Profile from '@/core/views/Account/Profile.vue';
import requestSMSnoti from '@/core/views/Account/requestSMSnoti.vue';
import Organizations from '@/core/views/Account/Organizations.vue';
import Security from '@/core/views/Account/Security.vue';
import SecurityInvite from '@/core/views/Account/SecurityInvite.vue';
import UsersList from '@/core/views/Account/Users/List.vue';
// import Privilegies from '@/core/views/Account/Users/Privilegies.vue';
import UsersForm from '@/core/views/Account/Users/Form.vue';
import lockScreen from '@/core/plugins/LockScreen/index.vue';
import Forgot from '@/core/views/Forgot.vue';
//reseller
//import Reseller from '@/core/Layouts/reseller/LoginLayout.vue';
import ResellerLogin from '@/core/Layouts/reseller/LoginResellerLayout.vue';
import DefaultLayoutR from '@/core/Layouts/reseller/DefaultLayoutR.vue';
import HomeR from '@/core/views/Reseller/homeR.vue';
import OrgbyReseller from '@/core/views/Reseller/OrgbyReseller.vue';
import UsersbyReseller from '@/core/views/Reseller/UsersbyReseller.vue';
export default [
	{
		path: '/',
		name: 'Login',
		meta: { requiresVisitor: true },
		folder: Login
	},
	{
		path: '/lockScreen',
		name: 'lockScreen',
		meta: { requiresVisitor: true },
		folder: lockScreen
	},
	{
		path: '/forgot',
		name: 'Forgot',
		meta: { requiresVisitor: true },
		folder: Forgot
	},
	{
		path: '/logout',
		name: 'Logout',
		folder: Logout,
		meta: { requiresAuth: false }
	},
	{
		path: '/',
		meta: { requiresAuth: true },
		folder: DefaultLayout,
		children: [
			{
				path: 'home',
				name: 'Home',
				folder: Home
			},		
		]
	},
	{
		path: '/activation',
		name: 'Activation',
		folder: LayaoutActivation,
		meta: { requiresAuth: false }
	},	
	{
		path: '/account',
		meta: { requiresAuth: true },
		folder: DefaultLayout,
		children: [
			{
				path: '' || '/profile',
				name: 'Profile',
				folder: Profile
			},
			{
				path: 'organizations',
				name: 'Organizations',
				folder: Organizations
			},
			{
				path: 'requestSMSnoti',
				name: 'requestSMSnoti',
				folder: requestSMSnoti
			},
			{
				path: 'security',
				name: 'Security',
				folder: Security
			},
			{
				path: 'SecurityInvite',
				name: 'SecurityInvite',
				folder: SecurityInvite
			},
			{
				path: 'users',
				name: 'Users',
				folder: UsersList
			},
			{
				path: 'users/:user_id',
				name: 'Usersave',
				folder: UsersList
			},
			// {
			// 	path: 'privilegies/:account_id/:org_id/:app_id',
			// 	name: 'Privilegies',
			// 	folder: Privilegies
			// },
			{
				path: 'users/add',
				name: 'UsersForm',
				folder: UsersForm
			},
			{
				path: 'users/:id/:edit',
				name: 'UsersFormEdit',
				folder: UsersForm
			},
			{
				path: 'users/:id/:edit/:profile',
				name: 'UsersEditPerfil',
				folder: UsersForm
			}
		]
	},
	// {
	// 	path: '/reseller',
	// 	name: 'Reseller',
	// 	meta: { requiresVisitor: true },
	// 	folder: ResellerLogin
	// },
	// {
	// 	path: '/account-reseller',
	// 	meta: { requiresAuth: true },
	// 	folder: DefaultLayoutR,
	// 	children: [
	// 		{
	// 			path: '/',
	// 			name: 'HomeReseller',
	// 			folder: HomeR
	// 		},	
	// 		{
	// 			path: 'organizations',
	// 			name: 'OrgbyReseller',
	// 			folder: OrgbyReseller
	// 		},
	// 		{
	// 			path: 'accounts',
	// 			name: 'UsersbyReseller',
	// 			folder: UsersbyReseller
	// 		},		
	// 	]
	// },
]
