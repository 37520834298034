<template>
	<div class="opc-secciones">
    <div class="widgets-content">
      <div v-bind:class="['title-content', (menuType.indexOf(1)>-1) ? 'open' : '']" @click="openMenu(1)">
        <h4>
          Choose Background:
          <i class="fas fa-chevron-down" v-if="(menuType.indexOf(1)>-1)"></i>
          <i class="fas fa-chevron-right" v-else></i>
        </h4>
      </div> 
      <div class="box-contents list-widgets item1" v-if="(menuType.indexOf(1)>-1)">
        <div class="box-section" style="padding: 0px;">
          <div class="typeBg" @click="changeTypeBg(1)">
            <div class="icon-check">
              <i :class="['fas fa-check-circle', (typeBg==1) ? 'active' : '']"></i>
              <i :class="['far fa-circle', (typeBg!=1) ? 'active' : '']"></i>
            </div>
            <div :class="['text-label', (typeBg==1) ? 'active' : '']">
              Static Image
            </div>
          </div>
        </div>
        <div class="box-section" style="padding: 0px;">
          <div class="typeBg" @click="changeTypeBg(2)">
            <div class="icon-check">
              <i :class="['fas fa-check-circle', (typeBg==2) ? 'active' : '']"></i>
              <i :class="['far fa-circle', (typeBg!=2) ? 'active' : '']"></i>
            </div>
            <div :class="['text-label', (typeBg==2) ? 'active' : '']">
              Slider (5 images)
            </div>
          </div>
        </div>
        <div class="box-section" style="padding: 0px;">
          <div class="typeBg" @click="changeTypeBg(3)">
            <div class="icon-check">
              <i :class="['fas fa-check-circle', (typeBg==3) ? 'active' : '']"></i>
              <i :class="['far fa-circle', (typeBg!=3) ? 'active' : '']"></i>
            </div>
            <div :class="['text-label', (typeBg==3) ? 'active' : '']">
              Video
            </div>
          </div>
        </div>
      </div> 
    </div>
		<div class="widgets-content">
      <div v-bind:class="['title-content', (menuType.indexOf(2)>-1) ? 'open' : '']" @click="openMenu(2)">
        <h4>
          Background Image:
          <i class="fas fa-chevron-down" v-if="(menuType.indexOf(2)>-1)"></i>
          <i class="fas fa-chevron-right" v-else></i>
        </h4>
      </div> 
      <div class="box-contents list-widgets item2" v-if="(menuType.indexOf(2)>-1)">
        <div class="box-section" @click="$refs.imgHeader.click()">
          <span class="title">Upload Image for Slider:</span>
          <div v-if="typeBg==1 && images[0]" class="previewImg">
            <img :src="images[0].desktop" class="img-thumbnail" />
            <div class="btn-upload-img" ><i class="fas fa-cloud-upload-alt"></i></div>
          </div>
          <div v-else-if="typeBg==2 && images.length>0" v-for="(slide, sliderIdx) in images" :key="sliderIdx" class="previewImg">
            <img :src="slide.desktop" :class="['img-thumbnail',  'sliderIdx'+sliderIdx]" />
            <div class="btn-upload-img" ><i class="fas fa-cloud-upload-alt"></i></div>
          </div>
          <img :src="noImg" class="img-thumbnail" v-else/>
        </div>
        <input type="file" ref="imgHeader" accept="image/*" :multiple="(typeBg==2)" @change="changeStaticImage($event)" style="display: none;">
      </div> 
    </div>
    <div class="widgets-content">
      <div v-bind:class="['title-content', (menuType.indexOf(3)>-1) ? 'open' : '']" @click="openMenu(3)">
        <h4>
          Slider Height:
          <i class="fas fa-chevron-down" v-if="(menuType.indexOf(3)>-1)"></i>
          <i class="fas fa-chevron-right" v-else></i>
        </h4>
      </div> 
      <div class="box-contents list-widgets item3" v-if="(menuType.indexOf(3)>-1)">
        <div class="box-section" style="padding: 0px;flex-direction: row;">
          <span class="title" >Type:</span>
          <div class="typeBg" @click="changeTypeHeight('px')" style="width: 90px; padding-left: 20px;">
            <div class="icon-check">
              <i :class="['fas fa-check-circle', (typeHeight=='px') ? 'active' : '']"></i>
              <i :class="['far fa-circle', (typeHeight!='px') ? 'active' : '']"></i>
            </div>
            <div :class="['text-label', (typeHeight=='px') ? 'active' : '']" style="font-weight:bold;">
              px
            </div>
          </div>
          <div class="typeBg" @click="changeTypeHeight('vh')" style="width: 90px; padding-left: 20px;">
            <div class="icon-check">
              <i :class="['fas fa-check-circle', (typeHeight=='vh') ? 'active' : '']"></i>
              <i :class="['far fa-circle', (typeHeight!='vh') ? 'active' : '']"></i>
            </div>
            <div :class="['text-label', (typeHeight=='vh') ? 'active' : '']" style="font-weight:bold;">
              %
            </div>
          </div>
        </div>
        <div class="box-section">
          <span class="title" >
            Height: {{slideHeight}}
            <span v-if="typeHeight=='px'">px</span>
            <span v-else>%</span>
          </span>
          <input type="range" v-model="slideHeight" min="400" max="800" step="50" @input="changeSlideHeight($event)" style="margin-bottom:10px;" v-if="typeHeight=='px'">
          <input type="range" v-model="slideHeight" min="50" max="100" step="5" @input="changeSlideHeight($event)" style="margin-bottom:10px;" v-else>
        </div>
      </div> 
    </div>
    <div class="widgets-content">
      <div v-bind:class="['title-content', (menuType.indexOf(4)>-1) ? 'open' : '']" @click="openMenu(4)">
        <h4>
          Color Layer Overlay:
          <i class="fas fa-chevron-down" v-if="(menuType.indexOf(4)>-1)"></i>
          <i class="fas fa-chevron-right" v-else></i>
        </h4>
      </div> 
      <div class="box-contents list-widgets item4" v-if="(menuType.indexOf(4)>-1)">
        <div class="box-section">
          <span class="title" >Opacity: {{(opacity*100).toFixed(0)}}%</span>
            <input type="range" v-model="opacity" min="0" max="1" step="0.05" 
              @input="changeOpacity($event)" style="margin-bottom:10px;">
            <color-picker
              v-model="colorOverlay"
              class="getcolor"
              @input="changeColorOverlay($event)"
              :palette="['#FFFFFF','#CCCCCC','#000000','#00FF00','#10C33F','#CCEEFF','#0519FF','#FFEB00','#FF0105']"
            ></color-picker>
            <color-material 
              v-model="colorOverlay"
              class="getcolor"
              @input="changeColorOverlay($event)"
              style="height: 130px;margin-bottom:10px;"
            ></color-material>
        </div>
      </div> 
    </div>
    <!-- <div class="widgets-content">
      <div v-bind:class="['title-content', (menuType.indexOf(2)>-1) ? 'open' : '']" @click="openMenu(2)">
        <h4>
          Content
          <i class="fas fa-chevron-down" v-if="(menuType.indexOf(2)>-1)"></i>
          <i class="fas fa-chevron-right" v-else></i>
        </h4>
      </div> 
      <div class="box-contents list-widgets item2" v-if="(menuType.indexOf(2)>-1)">
        <div class="box-section">
          <span class="title">Box Size:</span>
          <div class="btn-group btn-group-toggle" >
            <label :class="['btn btn-primary btn-sm', (boxSize=='col-12') ? 'active': '']" @change="changeBoxSize(1)">
              <input type="radio" :checked="(boxSize=='col-12')"> Full width
            </label>
            <label :class="['btn btn-primary btn-sm', (boxSize=='col-8') ? 'active': '']" @change="changeBoxSize(2)">
              <input type="radio" :checked="(boxSize=='col-8')"> 3/4 width
            </label>
            <label :class="['btn btn-primary btn-sm', (boxSize=='col-6') ? 'active': '']" @change="changeBoxSize(3)">
              <input type="radio" :checked="(boxSize=='col-6')"> 1/2 width
            </label>
          </div>
        </div>
        <div class="box-section">
          <span class="title">Content alignment:</span>
          <div class="btn-group btn-group-toggle" >
            <label :class="['btn btn-primary btn-sm', (contentAling=='text-left') ? 'active': '']" @change="changeContentAling(1)">
              <input type="radio" :checked="(contentAling=='text-left')"> <i class="fas fa-align-left"></i>
            </label>
            <label :class="['btn btn-primary btn-sm', (contentAling=='text-center') ? 'active': '']" @change="changeContentAling(2)">
              <input type="radio" :checked="(contentAling=='text-center')"> <i class="fas fa-align-center"></i>
            </label>
            <label :class="['btn btn-primary btn-sm', (contentAling=='text-right') ? 'active': '']" @change="changeContentAling(3)">
              <input type="radio" :checked="(contentAling=='text-right')"> <i class="fas fa-align-right"></i>
            </label>
          </div>
        </div>
        <div class="box-section">
          Thumbnails
        </div>
      </div> 
    </div> -->
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import { Compact, Material } from "vue-color";
export default {
  name: 'opcHeader',
    components: {
        "color-picker": Compact,
        "color-material": Material,
    },
  props: {
    noImg: {
      type: String, // String, Number, Boolean, Function, Object, Array
      required: false,
      default: require('@/core/assets/img/no-image.png'),
    },
  },
  data() {
    return {
      menuType: [1, 2, 3, 4],
      typeBg: 1, 
      images: [],
      colorOverlay: {a:'0.5', b:'0', g:'0', r:'0'},
      heightHeader: '',
      contentAling: 'text-left',
      boxSize: 'col-8',
      thumbs: [],
      formShow: false,
      boxForm: '',
      formHeader: 0,
      formAling: '',
      opacity: '0.5',
      typeHeight: 'px',
      slideHeight: 600,
    };
  },
  created(){
    this.init();
  },
  methods: {
    init(){
      let d = this.$store.getters['ModApps/Sites/getSideBarOpc'].data;
      if(d.length>0){
        let coverPage = d[0];
        this.typeBg = coverPage.typeBg;
        this.images = coverPage.images;
        this.colorOverlay = coverPage.colorOverlay;
        this.typeHeight = coverPage.typeHeight;
        this.slideHeight = coverPage.slideHeight;
        this.opacity = this.colorOverlay.a;
        this.contentAling = coverPage.content[0].columns[0].moreClass;
        this.boxSize = coverPage.content[0].columns[0].grids;
        //this.heightHeader=o.heightHeader;
        //this.thumbs=o.thumbs;
        //this.formHeader=o.formHeader;
        //this.formShow=o.formShow;
        //this.boxForm=o.boxForm;
        //this.formAling=o.formAling;
      }
    },
    openMenu(type){
      let t = this;
      if(t.menuType.indexOf(type)>-1){
        let elem = document.querySelector(".item"+type);
        if(elem){  
          let heightResta = elem.offsetHeight/10;
          elem.style.overflow = 'hidden';
          let bg1 = '#d7d7d7';
          let bg2 = '#f2f4f5';
          let bgCurrent = '#d7d7d7';
          elem.style.height = elem.offsetHeight - heightResta + 'px';
          let efectoKeyframes = [
            { height: elem.style.height },
            { background: bgCurrent },
            { height: '0px' },
          ];
          let optionsTiming = {
            duration: 200,
            iterations: 1,
          };
          elem.animate(efectoKeyframes, optionsTiming);
        }
        setTimeout(function(){
          if(elem){ elem.removeAttribute('style'); }
          t.menuType.splice(t.menuType.indexOf(type), 1);
        }, 180);
      }
      else{
        t.menuType.push(type);
      }
    },
    changeTypeBg(typeBg){
      let t = this;
      t.typeBg = typeBg;
      t.$store.commit("ModApps/Sites/setUpdateFontPage", {opc: 'typeBg', value: t.typeBg});
    },
    changeStaticImage(event){
      let t = this;
      let files = [];
      files = event.target.files;
      if (files !=null && files.length>0) {
        t.images = [];
        t.$store.commit("ModApps/Sites/setUpdateFontPage", {opc: 'images', value: []});
        for (let i in files) {
          if(files[i]){
            var reader = new FileReader();
            reader.onload = (e) => {
              let myImageData = e.target.result;
              t.images.push({desktop: myImageData});
              if(t.images.length == files.length){
                t.$store.commit("ModApps/Sites/setUpdateFontPage", {opc: 'images', value: t.images});
              }
            };
            if (files[i] && files[i].type.match('image.*')) {
              reader.readAsDataURL(files[i]);
            }
          }
        }
      }
    },
    changeColorOverlay(e){
      let t = this;
      //debugger;
      let c = e.rgba;
      let rgba = {r:c.r, g:c.g, b:c.b, a:t.opacity};
      t.colorOverlay = rgba;
      t.$store.commit("ModApps/Sites/setUpdateFontPage", {opc: 'colorOverlay', value: t.colorOverlay});
    },
    changeOpacity(){
      let t = this;
      //debugger;
      let c = t.colorOverlay;
      let rgba = {r:c.r, g:c.g, b:c.b, a:t.opacity};
      t.colorOverlay = rgba;
      t.$store.commit("ModApps/Sites/setUpdateFontPage", {opc: 'colorOverlay', value: t.colorOverlay});
    },
    changeTypeHeight(val){
      let t = this;
      //debugger;
      t.typeHeight=val;
      if(val=='px' && t.slideHeight<=100){
        t.slideHeight= (t.slideHeight*800)/100;
      }
      else if(val=='vh' && t.slideHeight>100){
        t.slideHeight= (t.slideHeight*100)/800;
      }
      t.$store.commit("ModApps/Sites/setUpdateFontPage", {
        opc: 'heightHeader', 
        value: {
          typeHeight: t.typeHeight,
          slideHeight: t.slideHeight,
        }
      });
    },
    changeSlideHeight(val){
      let t = this;
      //debugger;
      t.slideHeight=val.target.value;
      t.$store.commit("ModApps/Sites/setUpdateFontPage", {
        opc: 'heightHeader', 
        value: {
          typeHeight: t.typeHeight,
          slideHeight: t.slideHeight,
        }
      });
    },
  },
  watch: {
      getFontPageTypeBg: function(){
        let t = this;
        t.typeBg = t.getFontPageTypeBg;
      },
      getFontPageBgImg: function(){
        let t = this;
        t.btImg = t.getFontPageBgImg
      },
      getFontPageColorOverlay: function(){
        let t = this;
        t.colorOverlay = t.getFontPageColorOverlay
      },
      getFontPageTypeHeight: function(){
        let t = this;
        t.typeHeight = t.getFontPageTypeHeight
      },
      getFontPageSlideHeight: function(){
        let t = this;
        t.slideHeight = t.getFontPageSlideHeight
      },
      getFontPageBoxSize: function(){
        let t = this;
        t.boxSize = t.getFontPageBoxSize
      },
      getFontPageContentAling: function(){
        let t = this;
        t.contentAling = t.getFontPageContentAling
      },
      getFontPageThumbs: function(){
        let t = this;
        t.thumbs = t.getFontPageThumbs
      },
      getFontPageFormHeader: function(){
        let t = this;
        t.formHeader = t.getFontPageFormHeader
      },
      getFontPageFormShow: function(){
        let t = this;
        t.formShow = t.getFontPageFormShow
      },
      getFontPageBoxForm: function(){
        let t = this;
        t.boxForm = t.getFontPageBoxForm
      },
      getFontPageFormAling: function(){
        let t = this;
        t.formAling = t.getFontPageFormAling
      },

  },
  computed: {
    ...mapGetters("ModApps/Sites", { 
      getFontPageTypeBg: "getFontPageTypeBg",
      getFontPageBgImg: "getFontPageBgImg",
      getFontPageColorOverlay: "getFontPageColorOverlay",
      getFontPageTypeHeight: "getFontPageTypeHeight",
      getFontPageSlideHeight: "getFontPageSlideHeight",
      getFontPageBoxSize: "getFontPageBoxSize",
      getFontPageContentAling: "getFontPageContentAling",
      getFontPageThumbs: "getFontPageThumbs",
      getFontPageFormHeader: "getFontPageFormHeader",
      getFontPageFormShow: "getFontPageFormShow",
      getFontPageBoxForm: "getFontPageBoxForm",
      getFontPageFormAling: "getFontPageFormAling",
    }),
  }
};
</script>
<style lang="scss" scoped>
.opc-secciones {
  height: calc(100vh - 124px);
  overflow: hidden;
  overflow-y: auto;
  .widgets-content{}
  .widgets-content {
    .box-contents {
      .box-section {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        border-radius: 2px;
        border: 1px solid #dadce0;
        margin-bottom: 10px;
        padding: 5px;
        &:hover {
          background-color: #fff;
          cursor: default;
        }
        .title{
          font-weight: bold;
        }
        .previewImg{
          position: relative;
          img{
            width: 200px;
            height: auto;
          }
          .btn-upload-img{
            position: absolute;
            z-index: 1;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-content: center;
            justify-content: center;
            align-items: center;
            text-shadow: 1px 0px 10px rgb(255 255 255 / 50%);
            color: #4c32e9;
            font-size: 50px;
            opacity: 0.5;
            &:hover{
              background-color: rgb(0 0 0 / 0.2);
              opacity: 1;
              cursor: pointer;
            }
          }
        }
        .typeBg{
          display: block;
          width: 100%;
          &:hover {
            background-color: #f2f4f5;
            cursor: pointer;
          }
          .icon-check {
            display: inline-block;
            padding-left: 5px;
            font-size: 16px;
            i{ display: none;}
            i.active{ display: inline-block;}
          }
          .text-label {
            display: inline-block;
            padding-left: 10px;
          }
          .text-label.active{ font-weight: bold;}
        }
      }
    }
  }
}
.getcolor {
  width: 200px;
}
</style>
