let actionPrint = function(idinv) {
	this.closeSubmenu(idinv);
	this.isLoading = true;
	window.billing
	.post("eprintinvo/" + idinv + "/" + window.oauth_m, {
		user_id: window.localuserdata,
		eprint: 1,
	})
	.then((response) => {
		console.log(response.data);
		this.isLoading = false;
		if (response.data.status) {
			this.$notify({
				group: "noti",
				title: "Send!",
				type: "success",
				text: "Send Successful",
			});
		} else {
			this.$notify({
				group: "noti",
				title: "Send Mail!",
				type: "error",
				text: "Send error",
			});
		}
	})
	.catch((error) => {
		console.log(error);
		this.isLoading = false;
		this.$notify({
			group: "noti",
			title: "Send!",
			type: "error",
			text: "Send Error",
		});
	});
};
export default actionPrint;
