import variables from './data';
import components from './components';
import methods from './methods';
import watch from './watch';
import computed from './computed';
export {
  variables,
  components,
  methods,
  watch,
  computed
}
