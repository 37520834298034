let getAds = function() {
	let t = this;
	t.isLoading = true;
	window.master.post(t.apis.get() + t.query, {
		org_id: window.localorgdata,
		pag: t.paginador,
		rowNum: t.rowNum,
	}, 
	{
		onUploadProgress: (progressEvent) => {
			t.getProgress = parseInt((progressEvent.loaded / progressEvent.total) * 100);
		}
	})
	.then((response) => {
		if(response.data.ads.data.length > 0) {
			t.dataT = t.fillInData(response.data.ads.data);
			t.dataTOLD = t.fillInData(response.data.ads.data);
			t.pagination = response.data.ads;
		}
		let prop = ['id', 'name', 'id_campaign'];
		t.optionsMarkSource = t.contruirSelect(response.data.markSource, prop);
		t.optionsCTAChannel = t.contruirSelect(response.data.cta, prop);
		t.optionsCampign = t.contruirSelect(response.data.campaign, prop);
		t.isLoading = false;  
		t.numPagList();               
	})
	.catch((error) => {
		console.log(error);
		t.isLoading = false;
	});
};
export default getAds;
