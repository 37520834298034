<template>
    <div>
        <div class="card-header">{{ card_title }}</div>
        <form @submit.prevent="prepararSendMail" v-if="showCompanyinfo == false">
            <div class="card-body">
                <div class="bgloading" v-if="items.isLoading">
                    <Loader></Loader>
                </div>
                <div
                    class="alert alert-warning"
                    role="alert"
                    v-if="formsend.bill_email_verify == 0"
                    style="padding: 5px"
                >
                    Your Organization’s EMAIL wasn’t verifed. Go to Setting to Verify it
                    now.
                    <b
                        ><router-link :to="{ name: 'SettingCompanyInfo' }">
                            Click Here</router-link
                        ></b
                    >
                </div>
                <h4 class="card-title">
                    Send 
                    <span style="margin-right:10px;">{{items.view}}</span>
                    <b><span class="badge badge-info">#{{ items.IDitem }} </span></b>
                </h4>
                <div class="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        class="custom-control-input"
                        id="customCheck1"
                        v-model="formsend.emailcustomercheck"
                        :disabled="items.email == null ? true : false"
                    />
                    <label class="custom-control-label" for="customCheck1"
                        >Will be sent to the Client:
                        <b>
                            <span v-if="items.email">{{ items.email }}</span
                            ><span style="color: red" v-else
                                >This client DOESN'T have email</span
                            >
                        </b></label
                    >
                </div>
                <div class="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        class="custom-control-input"
                        id="customCheck2"
                        v-model="formsend.emailcompanycheck"
                    />
                    <label class="custom-control-label" for="customCheck2"
                        >Send me a copy: <b> {{ formsend.emailcompany }}</b></label
                    >
                </div>
                <div class="form-inline" style="padding-top: 15px; padding-bottom: 15px">
                    <div class="form-group mb-2 col-sm-2" style="padding-left: 0px;">
                        <div class="custom-control custom-checkbox">
                            <input
                                type="checkbox"
                                class="custom-control-input"
                                id="othermail"
                                v-model="formsend.othercheck"
                            />
                            <label for="othermail" class="custom-control-label"
                                >Other :</label
                            >
                        </div>
                    </div>
                    <div class="form-group col-sm-3 mb-2" style="padding-left: 0px;">
                        <label for="inputPassword2" class="sr-only">Name</label>
                        <input
                            type="text"
                            class="form-control"
                            style="display: block;width: 100%;"
                            placeholder="Name"
                            v-model="formsend.nameother"
                        />
                    </div>
                    <div class="form-group col-sm-6 mb-2" style="padding-left: 0px;">
                        <label for="inputPassword2" class="sr-only">Email</label>
                        <input
                            type="text"
                            class="form-control"
                            style="display: block;width: 100%;"
                            placeholder="Email"
                            v-model="formsend.emailother"
                        />
                    </div>
                </div>
                <div class="custom-control custom-checkbox" v-if="items.validEprint">
                    <input
                        type="checkbox"
                        class="custom-control-input"
                        id="customCheck3"
                        v-model="formsend.email1eprintcheck"
                    />
                    <label class="custom-control-label" for="customCheck3"
                        >Send to my printer: <b> {{ formsend.email1eprint }}</b></label
                    >
                </div>
                <div class="custom-control custom-checkbox"  v-if="items.validEprint">
                    <input
                        type="checkbox"
                        class="custom-control-input"
                        id="customCheck4"
                        v-model="formsend.email2eprintcheck"
                    />
                    <label class="custom-control-label" for="customCheck4"
                        >Send to my printer 2: <b> {{ formsend.email2eprint }}</b></label
                    >
                </div>
            </div>
            <div class="card-footer">
                <div
                    class="d-flex justify-content-end"
                    v-if="formsend.bill_email_verify == 1"
                >
                    <button
                        type="submit"
                        class="btn btn-success d-flex"
                        :disabled="isDisabled"
                    >
                        <div class="ldsnew" v-if="miniloader">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        <i
                            class="fas fa-paper-plane"
                            style="margin-right: 5px; margin-top: 3px"
                        ></i>
                        SEND
                    </button>
                </div>
                <div class="d-flex justify-content-end" v-else>
                    <button type="button" class="btn btn-success d-flex" disabled>
                        <i
                            class="fas fa-paper-plane"
                            style="margin-right: 5px; margin-top: 3px"
                        ></i>
                        SEND
                    </button>
                </div>
            </div>
            <div v-if="companyinfo" class="avisoCompany">
                <div class="alert alert-warning">
                    <span class="badge badge-warning">
                        <i class="fas fa-exclamation-triangle"></i>
                    </span>
                    Company Info is required to send messages. Please fill out your
                    business name. <br />
                    <button @click="addNameCompany">Click Here.</button>
                </div>
            </div>
        </form>
        <div v-else>
            <form @submit.prevent="saveBusinessName">
                <div class="card-body">
                    <Loader class="bgloading" v-if="items.isLoading"></Loader>
                    <div class="form-group">
                        <label class="sr-only mx-sm-3 mb-2">BUSINESS NAME</label>
                        <input
                            type="text"
                            class="form-control mx-sm-9"
                            id=""
                            placeholder="Name Company"
                            v-model="company_name"
                        />
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-flex justify-content-end">
                        <button
                            type="submit"
                            class="btn btn-success d-flex"
                            :disabled="isDisabled"
                        >
                            <div class="ldsnew" v-if="miniloader">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <i
                                class="fas fa-save"
                                style="margin-right: 5px; margin-top: 3px"
                            ></i>
                            SAVE
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader";
export default {
    components: {
        Loader,
    },
    name: "modalEmail",
    props: {
        items: { type: Object, required: true },
    },
    data() {
        return {
            isLoading: false,
            miniloader: false,
            formsend: {
                DataItem: this.items,
                emailcompany: null,
                emailcompanycheck: false,
                email1eprintcheck: false,
                email2eprintcheck: false,
                nameother: null,
                emailother: null,
                emailcustomercheck: false,
                othercheck: false,
                email1eprint: null,
                email2eprint: null,
                bill_email_verify: 1,
            },
            emails: [],
            companyinfo: false,
            showCompanyinfo: false,
            company_name: "",
            card_title: "SEND EMAIL",
            isDisabled: false,
            isEstPro: 0,
        };
    },
    created() {
        if (this.items.email != null) {
            this.formsend.emailcustomercheck = true;
            this.isDisabled = true;
        }
        if(this.items.estpro!=undefined && this.items.estpro!=null){
            this.isEstPro = this.items.estpro;
        }
        this.getCompanyInfo();
        this.getEprintemail();
    },
    methods: {
        addNameCompany() {
            this.card_title = "ADD NAME COMPANY";
            this.showCompanyinfo = true;
        },
        saveBusinessName() {
            if (this.company_name.length > 3) {
                this.isLoading = true;
                window.billing
                    .post("addnamecompany", {
                        user_id: window.localuserdata,
                        company_name: this.company_name,
                    })
                    .then((response) => {
                        this.card_title = "SEND EMAIL";
                        this.showCompanyinfo = false;
                        this.companyinfo = false;
                        this.isLoading = false;
                    })
                    .catch((error) => console.log(error));
            }
        },
        prepararSendMail() {
            this.miniloader = true;
            this.isDisabled = false;
            let fEventBus = "";
            let url = "";
            console.log("viewEmail = ", this.items.view);
            if (this.items.view == "invoice") {
                fEventBus = "invoice-email";
                url = "invoicemailsend";
            }
            if (this.items.view == "statement") {
                fEventBus = "statement-email";
                url = "statementmailsend";
            } else if (this.items.view == "estimate") {
                fEventBus = "estimate-email";
                url = "estimatemailsend";
            } else if (this.items.view == "payment") {
                fEventBus = "payment-email";
                url = "paymentmailsend";
            }
            if (this.items.view != "") {
                this.sendMail(fEventBus, url);
            }
        },
        sendMail(fEventBus, url) {
            let t = this;
            console.log(t.formsend);
            console.log(t.formsend.DataItem.idinv);
            let valido = true;
            if(t.formsend.othercheck && t.formsend.emailother != null && t.formsend.emailother.length > 0)
            {
                let emailother = t.formsend.emailother;
                let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
                //Se muestra un texto a modo de ejemplo, luego va a ser un icono
                if (!emailRegex.test(emailother)) 
                {
                    t.$store.commit("core/setNotiPush", {
                        group: "notiPush",
                        type: '5', // 1=success | 2=info | 3=error | 4=loading | 5=warning
                        title: "Email Other: ",
                        text: "Incorrect email",
                        show: true,
                        duration: 10000
                    });
                    valido = false;
                    t.isDisabled = false;
                    t.miniloader = false;
                }
            }
            if(valido)
            {
                t.isLoading = true;
                t.isDisabled = true;
                window.billing
                .post(url + "/" + t.formsend.DataItem.idinv + "/" + window.oauth_m, {
                    user_id: window.localuserdata,
                    emailcustomercheck: t.formsend.emailcustomercheck,
                    emailcompany: t.formsend.emailcompany,
                    nameother: t.formsend.nameother,
                    emailother: t.formsend.emailother,
                    email1eprint: t.formsend.email1eprint,
                    email2eprint: t.formsend.email2eprint,
                    emailcompanycheck: t.formsend.emailcompanycheck,
                    othercheck: t.formsend.othercheck,
                    email1eprintcheck: t.formsend.email1eprintcheck,
                    email2eprintcheck: t.formsend.email2eprintcheck,
                    isEstPro: t.isEstPro,
                })
                .then((response) => {
                    t.isLoading = false;
                    t.miniloader = false;
                    t.isDisabled = false;
                    if(response.data.status){
                        t.formsend.DataItem.send = 1;
                        t.formsend.fEventBus = fEventBus;
                        t.$store.commit("ModApps/billing/SetResponseMail", t.formsend);
                        t.$parent.close();
                    }else{
                        if(response.data.email.length>0){
                            let sentEmail = response.data.email
                            for (let i in sentEmail) {
                                if(sentEmail[i]){
                                    t.$notify({
                                        group: "noti",
                                        type: 'success', 
                                        title: "Success",
                                        text: "Mail sent to [ "+sentEmail[i]+" ] successfully",
                                        duration: 5000
                                    });
                                }
                            }
                        }
                        if(response.data.invalidEmail.length>0){
                            let listinvalid = response.data.invalidEmail
                            for (let i in listinvalid) {
                                if(listinvalid[i]){
                                    t.$notify({
                                        group: "noti",
                                        type: 'error',
                                        title: "Failed to send: ",
                                        text: "email [ "+listinvalid[i]+" ] does not exist or is incorrect",
                                        duration: 20000
                                    });
                                }
                            }
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    t.isLoading = false;
                    t.isDisabled = false;
                    t.miniloader = false;
                    this.$notify({
                        group: "noti",
                        type: "error",
                        title: "Error",
                        text: "Send Mail!",
                    });
                });
            }
        },
        getEprintemail() {
            this.isLoading = true;
            if(this.items.validEprint){
                window.billing
                .get("setting/" + window.localuserdata)
                .then((response) => {
                    this.formsend.email1eprint = response.data.email1.meta_value;
                    this.formsend.email2eprint = response.data.email2.meta_value;
                })
                .catch((error) => console.log(error));
            }
        },
        getCompanyInfo() {
            this.isLoading = true;
            window.billing
                .get("companyinfo/" + window.localuserdata)
                .then((response) => {
                    console.log("info company info");
                    console.log(response.data[0]);
                    let e = response.data[0];
                    this.formsend.emailcompany = e.bill_email;
                    this.formsend.bill_email_verify = e.bill_email_verify;
                    if (e.com_company_name == null || e.com_company_name == "") {
                        this.companyinfo = true;
                    } else {
                        this.companyinfo = false;
                    }
                })
                .catch((error) => console.log(error));
        },
    },
    watch: {
        "formsend.emailcompanycheck": function () {
            if (
                this.formsend.emailcompanycheck == true ||
                this.formsend.emailcustomercheck == true ||
                this.formsend.othercheck == true ||
                this.formsend.email1eprintcheck == true ||
                this.formsend.email2eprintcheck == true ||
                this.companyinfo == true
            ) {
                this.isDisabled = false;
            } else {
                this.isDisabled = true;
            }
        },
        "formsend.emailcustomercheck": function () {
            if (
                this.formsend.emailcompanycheck == true ||
                this.formsend.emailcustomercheck == true ||
                this.formsend.othercheck == true ||
                this.formsend.email1eprintcheck == true ||
                this.formsend.email2eprintcheck == true ||
                this.companyinfo == true
            ) {
                this.isDisabled = false;
            } else {
                this.isDisabled = true;
            }
        },
        "formsend.othercheck": function () {
            if (
                this.formsend.emailcompanycheck == true ||
                this.formsend.emailcustomercheck == true ||
                this.formsend.othercheck == true ||
                this.formsend.email1eprintcheck == true ||
                this.formsend.email2eprintcheck == true ||
                this.companyinfo == true
            ) {
                this.isDisabled = false;
            } else {
                this.isDisabled = true;
            }
        },
        "formsend.email1eprintcheck": function () {
            if (
                this.formsend.emailcompanycheck == true ||
                this.formsend.emailcustomercheck == true ||
                this.formsend.othercheck == true ||
                this.formsend.email1eprintcheck == true ||
                this.formsend.email2eprintcheck == true ||
                this.companyinfo == true
            ) {
                this.isDisabled = false;
            } else {
                this.isDisabled = true;
            }
        },
        "formsend.email2eprintcheck": function () {
            if (
                this.formsend.emailcompanycheck == true ||
                this.formsend.emailcustomercheck == true ||
                this.formsend.othercheck == true ||
                this.formsend.email1eprintcheck == true ||
                this.formsend.email2eprintcheck == true ||
                this.companyinfo == true
            ) {
                this.isDisabled = false;
            } else {
                this.isDisabled = true;
            }
        },
        companyinfo: function () {
            if (
                this.formsend.emailcompanycheck == true ||
                this.formsend.emailcustomercheck == true ||
                this.formsend.othercheck == true ||
                this.formsend.email1eprintcheck == true ||
                this.formsend.email2eprintcheck == true ||
                this.companyinfo == true
            ) {
                this.isDisabled = false;
            } else {
                this.isDisabled = true;
            }
        },
    },
    computed: {},
};
</script>

<style lang="css" scoped>
.avisoCompany {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 0px 80px;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
}
</style>
