<template>
    <div>
        <div class="card">
                <div class="card-header">
                    Add Product & Service
                </div>
                <div class="card-body">
                    <form @submit.prevent="MetSAddProdService">
                        <div class="form-row">
                            <div class="col-12">
                                <span id="emailHelp" class="form-text text-muted">
                                    Account:</span
                                >
                                <select
                                    class="form-control"
                                    v-model="FormAddPS.account_id"
                                    required
                                >
                                    <option value="" selected>--Select--</option>
                                    <option
                                        v-for="item in filtroTypeofAccount"
                                        :key="item.id"
                                        v-bind:value="item.id"
                                    >
                                        {{ item.name_account }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-12">
                                <span id="" class="form-text text-muted">Name:</span>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="FormAddPS.productname"
                                    required
                                />
                            </div>
                            <div class="col-12">
                                <span id="" class="form-text text-muted">Quantity:</span>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="FormAddPS.quantity"
                                    placeholder="1"
                                />
                                <span v-if="errorQuality" style="color: red">{{
                                    errorQuality
                                }}</span>
                            </div>
                            <div class="col-12">
                                <span id="" class="form-text text-muted">Unit Cost:</span>
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="0.10"
                                    v-model="FormAddPS.unitcost"
                                />
                                <span v-if="errorCosto" style="color: red">
                                    {{ errorCosto }}</span
                                >
                            </div>
                            <div class="col-12">
                                <span id="" class="form-text text-muted">
                                    Description:</span
                                >
                                <textarea
                                    name=""
                                    id=""
                                    cols="10"
                                    rows="2"
                                    class="form-control form-text w100"
                                    style="width: 100%"
                                    v-model="FormAddPS.description"
                                ></textarea>
                            </div>
                            <div class="col-12">
                                <span id="" class="form-text text-muted">
                                    Apply Tax:</span
                                >
                                <select class="form-control" v-model="FormAddPS.applytax">
                                    <option value="0">0.00% -None- (NN)</option>
                                    <option
                                        v-for="item in TaxesLists"
                                        :key="item.id"
                                        v-bind:value="item.id"
                                    >
                                        {{ item.name }}% {{ item.statename }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-12">
                                <span id="" class="form-text text-muted"> Status:</span>
                                <select class="form-control" v-model="FormAddPS.status">
                                    <option v-bind:value="1">Active</option>
                                    <option v-bind:value="0">Inactive</option>
                                </select>
                            </div>
                            <div class="col-12">
                                <span id="emailHelp" class="form-text text-muted">.</span>
                                <button type="submit" class="btn btn-success float-right">
                                    {{ FormAddPS.btnName }}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
        </div>
    </div>
</template>
<script>
export default {
    components: { },
    name: "formProductServices",
    data () {
        return {
            FormAddPS: {
                btnName: "SAVE",
                Action: "Add",
                id: null,
                productname: null,
                account_id: 0,
                quantity: 1,
                unitcost: 1,
                description: null,
                applytax: 0,
                status: 0,
            },
            errorCosto: false,
            errorQuality: false,            
            m: {
                show: false,
                component: '',
                data: []
            },
            TypeofAccount:[], 
            TaxesLists: [],                       
        }
    },
    created () {
        this.init()
        this.getAccountTypes();
        this.getTaxes()
    },
    methods: {   
        init() {
            let g = this.$store.getters['core/getOpenModal'];
            let r = g.data;            
            this.FormAddPS.btnName = r.FormAddPS.btnName;
            this.FormAddPS.Action = r.FormAddPS.Action;
            this.FormAddPS.id = r.FormAddPS.id;
            this.FormAddPS.productname = r.FormAddPS.productname;
            this.FormAddPS.account_id = r.FormAddPS.account_id;
            this.FormAddPS.quantity = r.FormAddPS.quantity;
            this.FormAddPS.unitcost = r.FormAddPS.unitcost;
            this.FormAddPS.description = r.FormAddPS.description;
            this.FormAddPS.applytax = r.FormAddPS.applytax;
            this.FormAddPS.status = r.FormAddPS.status;
        },    
        MetSAddProdService() {
            if (this.FormAddPS.quantity < 1) {
                this.errorQuality = "Quantity must be greater than or equal to 1";
            } else if (this.FormAddPS.unitcost >= 0.1) {
                this.errorQuality = false;
                this.errorCosto = false;
                if (this.FormAddPS.Action == "Add") {
                    this.$store.commit("core/setLoadingModal", true);
                    window.billing
                        .post("product-service/add", {
                            user_id: window.localuserdata,
                            account_id: this.FormAddPS.account_id,
                            productname: this.FormAddPS.productname,
                            quantity: this.FormAddPS.quantity,
                            unitcost: this.FormAddPS.unitcost,
                            description: this.FormAddPS.description,
                            applytax: this.FormAddPS.applytax,
                            status: this.FormAddPS.status,
                        })
                        .then((response) => {
                            this.FormAddPS.account_id = null;
                            this.FormAddPS.productname = null;
                            this.FormAddPS.quantity = 1;
                            this.FormAddPS.unitcost = 1;
                            this.FormAddPS.description = null;
                            this.FormAddPS.applytax = 0;
                            this.FormAddPS.status = 1;
                            this.NoRecord = false;

                            this.$notify({
                                group: "noti",
                                title: "ADD",
                                type: "success",
                                text: "Add Product Successful",
                            });
                            this.$store.commit("core/setLoadingModal", false);
                            this.$store.commit("core/setCloseModal", {action: 'saved'});
                            this.$store.commit("core/setActionRefresh", {action: 'saved', data: {methods: 'refreshList'}});
                            this.$parent.close()
                        })
                        .catch((error) => {
                            console.log(error, (this.isLoading = false))
                            this.$store.commit("core/setLoadingModal", false);
                        });
                }
                if (this.FormAddPS.Action == "Update") {
                    this.$store.commit("core/setLoadingModal", true);
                    window.billing
                        .put("product-services/edit/" + this.FormAddPS.id, {
                            user_id: window.localuserdata,
                            account_id: this.FormAddPS.account_id,
                            productname: this.FormAddPS.productname,
                            quantity: this.FormAddPS.quantity,
                            unitcost: this.FormAddPS.unitcost,
                            description: this.FormAddPS.description,
                            applytax: this.FormAddPS.applytax,
                            status: this.FormAddPS.status,
                        })
                        .then((response) => {
                            console.log(response.data);
                            this.FormAddPS.id = null;
                            this.FormAddPS.account_id = "";
                            this.FormAddPS.productname = null;
                            this.FormAddPS.quantity = 1;
                            this.FormAddPS.unitcost = 1;
                            this.FormAddPS.description = null;
                            this.FormAddPS.applytax = 0;
                            this.FormAddPS.status = 1;
                            this.FormAddPS.btnName = "SAVE";
                            this.FormAddPS.Action = "Add";
                            this.NoRecord = null;
                            
                            this.$swal.fire({
                                icon: "success",
                                title: "Updated Product Successful",
                                showConfirmButton: false,
                                timer: 2000,
                            });
                            this.$store.commit("core/setLoadingModal", false);
                            this.$store.commit("core/setCloseModal", {action: 'saved'});   
                            this.$store.commit("core/setActionRefresh", {action: 'saved', data: {methods: 'refreshList'}});     
                            this.$parent.close()                     
                        })
                        .catch((error) => {
                            console.log(error, (this.isLoading = false))
                            this.$store.commit("core/setLoadingModal", false);
                        });
                }
            } else {
                this.errorQuality = false;
                this.errorCosto = "The minimum cost cannot be less than 0.10";
            }
        },        
        getAccountTypes() {
            this.isLoading = true;
            window.billing
                .get("chart-account-list/" + window.localuserdata)
                .then((response) => {
                    console.log("Account type form modal es");
                    this.TypeofAccount = response.data.result;
                    let e = response.data.result;
                    if (e.length > 0) {
                        for (let i in e) {
                            this.TypeofAccount[i].value = this.TypeofAccount[i].id;
                            this.TypeofAccount[i].text = this.TypeofAccount[
                                i
                            ].name_account;
                        }
                    }
                    console.log(this.TypeofAccount);
                    this.isLoading = false;
                })
                .catch((error) => console.log(error));
        },
        getTaxes() {
            window.billing
                .get("taxes/" + window.localuserdata)
                .then((response) => {
                    console.log(response.data);
                    this.TaxesLists = response.data;
                    if (this.TaxesLists.length == 0) {
                        this.NoRecord = true;
                    }
                    this.isLoading = false;
                })
                .catch((error) => console.log(error));
        },        
    },
    watch: {},
    computed: {
        filtroTypeofAccount() {
            return this.TypeofAccount.filter(function (u) {
                return u.account_type === 3 || u.account_type === 5;
            });
        },        
    },
};
</script>

<style lang="css" scoped></style>
