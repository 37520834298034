let getJobsColor = function() {
    let t = this;
    let url = this.apis.getJobsColor()
    return new Promise((resolve, reject) => {
        window.master
            .get("/jobs/getJobsColor/" + t.user_id + "/" + t.org_id)
            .then((response) => {
                let r = response.data.result;
                t.colorList = r;
                resolve(true);
            })
            .catch((error) => {
                console.log(error);
                reject(false);
            });
    });
};
export default getJobsColor;
