<template>
	<div class="contenedor_view content_knowledge">
		<div class="row">
			<div class="col-12">
				<div class="filtros_actiones">
					<div class="btn-group btn-sync" role="group">
						<button
							type="button"
							:class="['btn btn-light btn-sm']"
							@click="getKnowledge()"
							:disabled="isloadingSync"
							style="border: 1px solid #dcdcdc;background-color: #fff;padding: 7px 10px;color: #5d5b5b;"
						>
						<i :class="['fas fa-sync', (isloadingSync) ? 'aReFresh' : '']"></i>
						</button>
					</div>
					<button class="btn btn-info" @click="openAdd()">Add</button>
					<form @submit.prevent="getKnowledge(1)">
						<div class="input-group">
							<input
								type="text"
								class="form-control search"
								placeholder="Task name"
								v-model="searchName"
							/>
							<div class="input-group-prepend btn-filter-search" @click="getKnowledge()">
								<span class="btn btn-info" style="border: 0px">
									<i class="fas fa-search"></i>
								</span>
							</div>
							<div class="input-group-prepend btn-search-all" @click="searchAll">
								<span class="input-group-text">
									<i class="fas fa-backspace"></i>
								</span>
							</div>
						</div>
					</form>
				</div>
			</div>
			<div class="col-12">
				<div class="contenedor_table">
					<table id="table_knowledge">
						<thead>
							<tr>
								<th class="num">#</th>
								<th class="name">Knowledge Name</th>
								<th class="open">Open</th>
								<th class="tags">Tags</th>
								<th class="sendto">Convert to Task</th>
							</tr>
						</thead>
						<tbody v-if="isloadingTable===true">
							<tr>
								<td colspan="5" class="text-center">
									<Loader></Loader>
								</td>
							</tr>
						</tbody>
						<tbody v-else-if="listKno.length==0">
							<tr>
								<td colspan="5" class="text-center">
									<br><br>
									<h3 class="alert-heading">Create your first knowledge guide</h3>
									<button type="button" class="btn btn-success btn-lg"  @click="openAdd()">Create now</button>
									<br><br>
								</td>
							</tr>
						</tbody>
						<tbody v-else>
							<template v-for="(i, idx) in listKno" >
								<tr :key="idx" :class="['tr-'+idx]">
									<td class="num">{{ 1+idx}}</td>
									<td class="name">
										<input type="text" v-model="i.task_name" class="edit-name" @change="saveChangeInput('task_name', i)"/>
									</td>
									<td class="open" @click="openItem(i)">
										<i class="fas fa-expand-alt"></i>
                                        <!-- <span v-if="i.open==0">Display</span> -->
									</td>
									<td class="tags">
										<div class="selecttags">
											<multiselect
												v-model="i.category"
												:class="['form-control', 'vselect' + (i.id)]"
												tag-placeholder="Add tag"
												placeholder="Add tag"
												label="category_name"
												track-by="id"
												:options="CategoryList"
												:multiple="true"
												:taggable="true"
												:clear-on-select="false"
												:ref="'vselect' + i.id"
												select-label="Choose"
												deselect-label="Remove"
												@tag="addTag"
												@input="changeTags"
												@open="openTagSelect(i)"
												@search-change="SearchTag"
											>
											<template slot="tag" slot-scope="props">
											<span :class="[
											'multiselect__tag',
											(i.category.length <= 4) ? 'wAuto' :
											(i.category.length < 6) ? 'w60' :
											(i.category.length < 7) ? 'w50' :
											(i.category.length < 8) ? 'w40' :
											(i.category.length < 13) ? 'w30' :
											(i.category.length < 16) ? 'w20' : ''
											]"
											:title="getOptionDisplay(props.option)"
											>
											<span v-text="getOptionDisplay(props.option)"></span>
											<i tabindex="1" @keydown.enter.prevent="removeElement(props.option, item)"  @mousedown.prevent="removeElement(props.option, i)" class="multiselect__tag-icon"></i>
											</span>
											</template>
											</multiselect>
										</div>
									</td>
									<td class="sendto">
										<button type="button" class="btn btn-success btn-sm" title="Build a task">
											<i class="fas fa-share"></i>
										</button>
									</td>
								</tr>
							</template>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<modal name="modalKnowledge" :resizable="true" :adaptive="true" :height="500" width="100%" @before-close="resetModal">
			<component v-bind:is="modalComponent" @setEmitForm="getEmitForm($event)" :itemDisplay="itemDisplay"></component>
        </modal>
	</div>
</template>
<script>
import moment from 'moment';
import { mapGetters } from 'vuex'
// @ts-ignore
import Loader from '@/core/components/shared/Loader';
// @ts-ignore
import formknowledge from '@/apps/billing/components/smartOffice/form_knowledge';
export default {
	name: 'smartO_knowledge',
	components: {
		Loader,
		formknowledge,
	},
	data() {
		return {
			modalComponent: null,
			searchName: null,
			listKno: [],
			isloadingTable: false,
			isloadingSync: false,
			itemDisplay: {},
			categorySelected: {},
			CategoryList: [],
		};
	},
	created(){
		let t = this;
		t.getcategory().then(r =>{
			t.getKnowledge();
		});
	},
	methods: {
		openAdd(){
			this.modalComponent="formknowledge";
			this.$modal.show('modalKnowledge');
		},
		getEmitForm(data){
			let t =this;
			if(data.action=='add'){
				console.log('new item', data.item);
				t.listKno.unshift(data.item);
			}else{
				console.log('update item', data.item);
				t.getKnowledge(1);
			}
		},
		resetModal(){
			this.modalComponent=null;
			this.itemDisplay = {};
		},
		closeModal(){
			this.$modal.hide('modalKnowledge');
		},
		getKnowledge(load){
			let t = this;
			t.isloadingSync=true;
			if(load==undefined){t.isloadingTable=true;}
			window.billing
            .post('getListTaskKnow', {
                user_idd: window.master_client,
                user_id: window.localorgdata,
                org_id: window.localorgdata,
				filter: t.searchName,
                socket_id: pusher.connection.socket_id,
            })
            .then((response) => {
                console.log('getListTaskKnow', response.data);
				let list = response.data.list;
				for (const key in list) {
					if (Object.hasOwnProperty.call(list, key)) {
						list[key].open=0;
						let category_ids = [];
						let tagsIds = [];
						if (list[key].category_ids != null && list[key].category_ids.length > 0) {
							category_ids = list[key].category_ids.split(',');
							tagsIds = t.CategoryList.filter(r => {
								for (let c in category_ids) {
									if (category_ids[c] == r.id) {
										return r;
									}
								}
							});
						}
						list[key].category= tagsIds;
					}
				}
				t.listKno=list;
				t.isloadingTable=false;
				t.isloadingSync=false;
            })
            .catch((error) => {
                console.log(error);
				t.isloadingTable=false;
				t.isloadingSync=false;
            });
		},
		searchAll(){
			let t = this;
			t.searchName='';
			t.getKnowledge();
		},
		openItem(i){
			this.itemDisplay = i;
			this.modalComponent="formknowledge";
			this.$modal.show('modalKnowledge');
		},
        saveChangeInput(campo, i){
            let t = this;
            console.log('change', campo, i[campo])
            window.billing
            .post("updateTaskKno", {
                id: i.id,
                user_idd: window.master_client,
                user_id: window.localorgdata,
                org_id: window.localorgdata,
                oauth_m: window.oauth_m,
                campo: campo,
                valor: i[campo],
                socket_id: pusher.connection.socket_id,
            })
            .then((response) => {
                console.log('updateTaskKno');
            })
            .catch((error) => {
                console.log(error)
            });
        },
		getcategory() {
			let t = this;
			t.isloadingTable=true;
			return new Promise((resolve, reject) => {
				window.billing
					.post("getTagsAll", {
						org_id: window.localorgdata,
						socket_id: pusher.connection.socket_id,
					})
					.then((response) => {
						//console.log('getTagsAll', response.data);
						let r = response.data;
						t.CategoryList = [];
						if (r.list.length > 0) {
							t.CategoryList = r.list;
						}
						resolve(true);
					})
					.catch((error) => {
						console.log(error);
						resolve(false);
					});
			});
		},
		openTagSelect(item){this.categorySelected=item; },
        changeTags(values){
            let t = this;
            let item = t.categorySelected;
            console.log('changeTags', values, item)
            let category_ids = [];
            let tags = [];
            let itemNone = null;
            tags = values.filter(r => {
                if(r.id==1){itemNone=r;}
                if(r.id>1){
                    category_ids.push(r.id);
                    return r;
                }
            });
            item.category_ids = (category_ids.length>0) ? category_ids.toString().replace('1,', '') : '';
            t.saveChangeInput('category_ids', item);
        },
        addTag (newTag) {
            let t = this;
            let id = 'new999999';
            const tag = {
                id: id,
                category_name: newTag,
            }
            console.log('new tag', tag);
            t.CategoryList.push(tag);
            let list = t.dataList;
            for (var i in list) {
                if(list[i]){
                    if(list[i].id==t.categorySelected.id){
                        t.dataList[i].category.push(tag);
                    }
                }
            }
            window.billing
            .post("addTagByUser", {
                id: t.categorySelected.id,
                user_idd: window.master_client,
                user_id: window.localorgdata,
                org_id: window.localorgdata,
                oauth_m: window.oauth_m,
                campo: 'category_ids',
                category_name: newTag,
				socket_id: pusher.connection.socket_id,
            })
            .then((response) => {
                console.log('addTagByUser', response.data.tag);
                for (let r in t.CategoryList) {
                    if(t.CategoryList[r].id==id){
						t.CategoryList[r].id = response.data.tag.id;
                        console.log('update tag ID', t.CategoryList[r]);
                    }
                };
                t.getcategory();
            })
            .catch((error) => {
                console.log(error)
            });
        },
        SearchTag(searchQuery, id){
        },
        getOptionDisplay(text){ return text.category_name; },
        removeElement: function(element, item) {
            this.categorySelected=item;
            this.$refs['vselect'+item.id][0].removeElement(element);
            console.log('removeElement', item.category)
        },
	},
};
</script>
<style lang="scss" scoped>  
.contenedor_view{
    position: relative;
    display: block;
    overflow-y: hidden;
    overflow-x: hidden;
    padding: 20px;
    border-top: 0px solid #ccc;
    height: calc(100vh - 55px);
    min-height: calc(100vh - 55px);
    padding-left: 30px;
    padding-right: 20px;
    margin-top: -10px;
    background-color: #fff;
    .page-title{
		padding-left: 15px;
    }
    .contenedor_table{
		overflow-y: auto;
		overflow-x: auto;
		border-top: 0px solid #ccc;
		position: relative;
		margin-top: 10px;
		height: calc(100vh - 180px);
		min-height: 300px;
		padding-left: 0px;
		padding-right: 15px;
		padding-bottom: 50px;
		width: 100%;
		#table_knowledge{
			width: auto;
			min-width: 100%;
			border-top: 0px;
			position: relative;
			border-collapse: separate;
			border-spacing: 0;
			border-radius: 4px;
			border-left: 5px solid #7460ee;
			thead tr {
				position: relative;
				&:first-child{
					th:first-child {border-top-left-radius: 2px;border-left: 0px solid #7460ee;}
					th:last-child {border-top-right-radius: 4px;}
				}
				th{
					color: #555;
					font-size: 14px;
					font-weight: normal;
					height: 30px;
					padding: 0.4rem;
					text-align: center;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					-webkit-font-smoothing: antialiased;
					position: -webkit-sticky;
					position: sticky;
					top: 0px;
					z-index: 1;
					background-color: #fff;
					background-clip: padding-box;
					border-top: 1px solid #dee2e6;
					border-bottom: 1px solid #dee2e6;
					border-right: 1px solid #dee2e6;
					border-left: 0px solid #dee2e6;
					&.num{
						min-width: 50px;
						width:50px;
					}
					&.name{text-align: left;}
					&.tags{
						min-width: 220px;
						width:500px;
					}
					&.sendto{
						min-width: 80px;
						width:80px;
					}
					&.open{
						min-width: 50px;
						width:50px;
					}
				}
			}
			tbody tr {
				&:last-child{
					td:first-child {border-bottom-left-radius: 4px;}
					td:last-child {border-top-right-radius: 4px;}
				}
				td:first-child {border-left: 0px solid #7460ee;}
				&:nth-of-type(odd) {background-color: #fff;}
				&:nth-of-type(even) {background-color: #fff;}
				&:hover {
					background-color: #eee;
					cursor: pointer;
					td {
						border-top: 1px solid #0073ea;
						border-bottom: 1px solid #0073ea;
						border-right: 1px solid #0073ea;
						&.name_vendor{
							.label-name{ i{color:#000;} }
						}
						&.check{
							.rowOptions{.btnOptions{display: block;}}
						}
					}
				}
				td{
					font-size: 0.775rem;
					padding: 0rem;
					height: 30px;
					vertical-align: middle;
					text-align: center;
					min-width: 88px;
					border-top: 0px solid #dee2e6;
					border-bottom: 1px solid #dee2e6;
					border-right: 1px solid #dee2e6;
					border-left: 0px solid #dee2e6;
					&.num{
						min-width: 50px;
						width:50px;
					}
					&.name{
						text-align: left; 
						padding-left: 10px;
						padding-right: 10px;
						.edit-name{
							border:0px;
							background-color: transparent;
							border-radius: 4px;
							width: 100%;
							font-weight: bold;
							color:#555;
							&:hover{
								border:1px solid #ccc;
								background-color: #fafafa;
								font-weight: normal;
							}
							&:focus{
								border:1px solid #ddd;
								background-color: #fff;
								font-weight: normal;
							}
						}
					}
					&.tags{
						min-width: 220px;
						width:500px;
					}
					&.sendto{
						min-width: 80px;
						width:80px;
					}
					&.open{
						min-width: 50px;
						width:50px;
						span{
							margin-left: 5px;
						}
					}
				}
			}
		}
	}
	@keyframes rotate {
		from {transform: rotate(0deg);}
		to {transform: rotate(360deg);}
	}
	@-webkit-keyframes rotate {
		from {-webkit-transform: rotate(0deg);}
		to {-webkit-transform: rotate(360deg);}
	}
	.filtros_actiones{
		.btn-sync{
			margin-right: 10px;
			i.aReFresh{
				-webkit-animation: 3s rotate linear infinite;
				animation: 3s rotate linear infinite;
				-webkit-transform-origin: 50% 50%;
				transform-origin: 50% 50%;
			}
		}
		form{
			display: inline-flex;
			margin-left: 10px;
			input.search{
				width: 220px;
			}
			.btn-filter-search{ cursor:pointer;}
			.btn-search-all{ cursor:pointer;}
		}
	}
}
</style>
