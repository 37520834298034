export default {
	langjobs(state) { return state.langjobs; },
	urlApis(state) { return state.urlApis; },
	access_Jobs(state) { return state.jobs; },
	lateral(state) { return state.lateralShow; },
	get_modalAddTo(state) { return state.modalAddTo; },
	get_alertTosaved(state) { return state.alertTosaved;},
	get_JOPending(state) { return state.jobsOrderPending;},
	get_menuTags(state) { return state.menu_tags;},
	get_jobsListAssignUser(state) { return state.jobsListAssignUser;},
	get_changeAssignUser(state) { return state.changeAssignUser;},
	get_searchJobs(state) { return state.searchJobs;},
	get_typeVisor(state) { return state.typeVisor;},
	get_actList(state) { return state.act_list;},
	get_itemJob(state) { return state.itemJob;},
	get_editTask(state) { return state.editTask;},
	get_editTags(state) { return state.editTags;},
	get_delTags(state) { return state.delTags;},
	get_editCusInv(state) { return state.editCusInv;},
	get_showCus(state) { return state.showCus;},
	get_ActiveMA(state) { return state.ActiveMA;},
	get_refresh_grid(state) { return state.refresh_grid;},
	get_todolist(state) { return state.todolist;},
	get_refresh_list(state) { return state.refresh_list;},
	get_jobsDash(state) { return state.jobsDash;},
	getDataFilterTagsUrl(state) { return state.datafilterTagsUrl;},
	getSortJobsBy(state) { return state.sortJobsBy;},
	getSeeTagsDefault(state) { return state.seeTagsDefault;},
}
