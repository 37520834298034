let deleteRow = function(i) {
	let t =this;
	// if(t.selected.length > 0) { 
		t.$swal({
			title: "Delete Item?",
			text: "You are sure to delete the selected records?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			confirmButtonText: "Yes, Delete it!",
		}).then((result) => {
			if (result.value) {
				// efecto desvanecer 
				t.desvanecerTR();
				// window.master.post("/crm/delBntCall", {ids: t.selected})
				window.master.post(t.apis.del(), {ids: i})
				.then((response) => {
					// eliminar de la lista 
					// t.deleteItems();
					t.getList();
				})
				.catch((error) => {
					console.log(error);
				});
			}
		});
    // }
};
export default deleteRow;
