<template>
    <div>
        <slot>
            <div class="page-breadcrumb">
                <div class="row">
                    <div class="col-md-8 align-self-center">
                        <h4 class="page-title">Contract Templates</h4>
                    </div>
                    <div class="col-md-4 align-self-center">
                        <div class="d-flex align-items-right justify-content-end">
                            <router-link :to="{ name: 'Setting' }" class="btn btn-info btn-sm mr-2 ctrlbtn" style="margin-bottom: 10px;width: -webkit-fill-available;">
                                <i class="fas fa-arrow-left"></i>
                                Back
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card card-body">
                            <div class="table-responsive">
                                <Loader v-if="isLoading"></Loader>                            
                                <table v-else class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">TITLE</th>
                                            <th scope="col">Alias</th>
                                            <th scope="col">
                                                <button
                                                    class="btn btn-success btn-sm mr-2 float-right"
                                                    @click="MetAddNewcTemplate"
                                                >
                                                    <i class="fas fa-plus"></i> New
                                                    Template
                                                </button>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="i in ContractTemLists" :key="i.id">
                                            <td scope="row">{{ i.title }}</td>
                                            <td scope="row">
                                                <span v-if="i.alias !== null">
                                                    {{ i.alias }}</span
                                                >
                                            </td>
                                            <td>
                                                <button
                                                    class="btn btn-secondary btn-sm float-right"
                                                    @click="
                                                        MetEditBtn(
                                                            i.id,
                                                            i.title,
                                                            i.alias,
                                                            i.description
                                                        )
                                                    "
                                                >
                                                    <i class="fas fa-edit"></i></button
                                                ><button
                                                    class="btn btn-danger btn-sm mr-2 float-right"
                                                    @click="MetDeleteBtn(i.id)"
                                                >
                                                    <i class="fas fa-times"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div v-if="NoRecord == true" class="text-center">
                                    no record
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </slot>
        <!-- modal 
        <modalLateral>
            <component v-bind:is="m.component"></component>
        </modalLateral>  
        -->
        <modal
            name="modal-form"
            width="1000"
            height="520"
        >
            <div class="card-header" style="position: relative">
                <span style="font-size: 18px">Template</span>
                <i
                    class="fas fa-times"
                    style="
                        position: absolute;
                        top: 9px;
                        right: 11px;
                        font-size: 24px;
                        cursor: pointer;
                    "
                    @click="CloseModal"
                ></i>
            </div>
            <div class="card-body">
                <formTemplates></formTemplates>
            </div>
        </modal>        
    </div>
</template>
<script>
import Loader from '@/core/components/shared/Loader'
import { mapGetters } from 'vuex'
//import { VueEditor } from 'vue2-editor'
//import modalLateral from '@/core/plugins/themeModalLateral/modalLateral'
import formTemplates from '@/apps/billing/components/setting/formTemplates'
export default {
    components: {
        Loader,
        //VueEditor,
        //modalLateral,
        formTemplates
    },
    data () {
        return {
            m: {
                show: false,
                component: '',
                data: []
            },            
            isLoading: false,
            ContractTemLists: [],
            DataForm: {
                btnName: "ADD",
                action: "Add",
                id: null,
                title: null,
                alias: null,
                description: null,
            },

            NoRecord: false,
        };
    },
    mounted () {
        this.getCTemplates();
    },
    methods: {
        getCTemplates() {
            this.isLoading = true;
            window.billing
                .get("contracttemplate/" + window.localuserdata)
                .then((response) => {
                    this.isLoading = false;
                    this.ContractTemLists = response.data.result;
                    if (this.ContractTemLists.length == 0) {
                        this.NoRecord = true;
                    }
                    this.isLoading = false;
                })
                .catch((error) => console.log(error));
        },
        MetEditBtn(id, title, alias, description) {
            let t = this
            t.DataForm = {
                btnName: "UPDATE",
                action: "Update",
                title: title,
                alias: alias,
                description: description,
                id: id,
            };
            t.$store.commit('ModApps/billing/settemplateContract', t.DataForm);     
            t.$modal.show("modal-form");
        },
        CloseModal() {
            let t = this
            t.DataForm = {
                btnName: "ADD",
                action: "Add",
                id: 0,
                title: '',
                alias: '',
                description: '',
            }; 
            t.$modal.hide("modal-form");
        },
        MetAddNewcTemplate() {
            let t = this
            t.DataForm = {
                btnName: "ADD",
                action: "Add",
                id: 0,
                title: '',
                alias: '',
                description: '',
            }; 
            t.$modal.show("modal-form"); 
            t.$store.commit('ModApps/billing/settemplateContract', t.DataForm);           
        },        
        MetDeleteBtn(id) {
            this.isLoading = true;
            this.$swal({
                title: "Delete this Template?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                if (result.value) {
                    window.billing
                        .put("contracttemplate/" + id, {
                            user_id: window.localuserdata,
                        })
                        .then((response) => {
                            this.isLoading = false;
                            this.getCTemplates();
                            this.$notify({
                                group: "noti",
                                title: "Deleted",
                                type: "success",
                                text: "Deleted Template Successful",
                            });
                        })
                        .catch((error) => console.log(error, (this.isLoading = false)));
                }
            });
            this.isLoading = false;
        },
    },
    watch: {
        'gettemplateContract': function() {
            if (this.gettemplateContract.action=='refresh') {
                    this.getCTemplates();
            }
        }
    }, 
    computed: {
        ...mapGetters("ModApps/billing", { gettemplateContract: "gettemplateContract" }),
    }        
};
</script>
<style lang="scss" scoped>

#editor {
    height: 300px;
}

@media (min-width: 1279px) {
    .ctrlbtn {
        width:160px !important;
    }
    .ctrlbtnCustom {
        width:100px !important;
    }    
    .ctrlbtnShow {
        display: none !important;
    }    
}
@media (max-width: 1281px) {
    .ctrlbtnHiden {
        display: none !important;
    }    
}

.contenedor-table table thead tr th {
    font-weight: bold;
}
@media (max-width: 1100px) {
    .contenedor-table {
        max-width: 924px;
        overflow-x: scroll;
    }
}

</style>
