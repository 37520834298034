<template>
	<div class="container-principal">
		<navtop></navtop>
		<bartools></bartools>
		<!-- <domhtml></domhtml> -->
		<div v-bind:class="['domHtml', !hideNav ? 'pleft' : '']">
			<article class="siteHtml">
      	<navhead></navhead>
      	<viewPage></viewPage>
    	</article>
		</div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import navtop from "../components/sitebuilder/navTop.vue";
import bartools from "../components/sitebuilder/barTools.vue";
//import domhtml from "../components/sitebuilder/domHtml";
import navhead from "../components/sitebuilder/sections/navMain.vue";
import viewPage from "../components/sitebuilder/sections/viewPage.vue";
export default {
  components: {
		navtop,
		bartools,
		//domhtml,
		navhead,
		viewPage,
  },
	props: {
		logo_blue: {
	    type: String, // String, Number, Boolean, Function, Object, Array
	    required: false,
	    default: require('@/core/assets/img/marketea_logo_2023_blue.svg'),
	  },
		logo_black: {
	    type: String, // String, Number, Boolean, Function, Object, Array
	    required: false,
	    default: require('@/core/assets/img/marketea_logo_2023_blue.svg'),
	  },
		logo_white: {
	    type: String, // String, Number, Boolean, Function, Object, Array
	    required: false,
	    default: require('@/core/assets/img/logo-white.png'),
	  },
    noImg: {
      type: String, // String, Number, Boolean, Function, Object, Array
      required: false,
      default: require('@/core/assets/img/no-image.png'),
    },
	},
  name: 'sitesHome',
  data() {
    return {
      navEditor: true,
      theme: {
        main: {
          cssclass:"opc-secciones",
          opc: {
            cssclass:"opcSect",
            tag: "span",
          },
          topbar: [],
          nav:{
          	type: 1,
            logo:{
              link: '',
              cssclass: [],
              imgAlt: 'logo-white',
              imgUrl: this.logo_white,
              opc:[
                {
                  title: 'Edit Logo',
                  action: ''
                }
              ]
            },
            logo2:{
              link: '',
              cssclass: [],
              imgAlt: 'logo-black',
              imgUrl: 'https://maximusbuilders.com/imgcms/editor/images/Logo%20MaximusBuilders.png',
              opc:[
                {
                  title: 'Edit Logo',
                  action: ''
                }
              ]
            },
            menu:[
              {
                title: 'Home',
                link: 'home',
                cssclass: '',
                child: []
              },
              {
                title: 'About',
                link: 'about',
                cssclass: '',
                child: []
              },
              {
                title: 'Services',
                link: 'services',
                show: false,
                cssclass: '',
                child: [
                  {
                    title: 'Services 1',
                    link: 'services-1',
                    cssclass: '',
                  },
                  {
                    title: 'Services 2',
                    link: 'services-2',
                    cssclass: '',
                  },
                ]
              },
              {
                title: 'Gallery',
                link: 'gallery',
                cssclass: '',
                child: []
              },
              {
                title: 'Contact',
                link: 'contact',
                cssclass: '',
                child: []
              },
            ],
            btn: {
              title: '(508) 825-4169',
              link: '#',
              cssclass: ''
            },
          },
          footer:{},
          pages: [
	          {
	          	title: 'Home',
	          	slug: 'home',
	            cssclass:"section-content",
	            opc: {tag: "div", cssclass:"menuSettingSect", html:"<i class='fas fa-chevron-down'></i>"},
	            move: { tag:"div", cssclass:"moveSect", html:"<i class='fas fa-ellipsis-v'></i>"},
	            coverPage: {
	            	typeBg: '1',
	            	typeNav: '1',
	            	autoplay: 'yes', // yes | no
								time: 7000,
	            	colorOverlay: {a:'0.5', b:'0', g:'0', r:'0'},
	            	typeHeight: 'px',
	            	slideHeight: '650',
	            	classHeight: '2', // 1=Header, 2=Big Header, 3=Front Page, 4= title Only
	            	single_content: 1,
	            	layoutContent: 1,
	            	divClass: '',
	            	images: [{
		            		desktop: 'https://marketea.com/userfiles/img/header.webp',
		            		tablet: 'https://marketea.com/userfiles/img/header.webp',
		            		mobile: 'https://marketea.com/userfiles/img/header.webp',
		            },
		            {
		            		desktop: 'https://maximusbuilders.com/imgcms/headers/slider/img_725_627_351_891.jpg',
		            		tablet: 'https://maximusbuilders.com/imgcms/headers/slider/img_725_627_351_891.jpg',
		            		mobile: 'https://maximusbuilders.com/imgcms/headers/slider/img_725_627_351_891.jpg',
		            },
		            {
		            		desktop: 'https://maximusbuilders.com/imgcms/headers/slider/img_874_727_427_922.jpg',
		            		tablet: 'https://maximusbuilders.com/imgcms/headers/slider/img_874_727_427_922.jpg',
		            		mobile: 'https://maximusbuilders.com/imgcms/headers/slider/img_874_727_427_922.jpg',
		            }],
	            	content: [{
	            		container: 'container',
	            		divClass: '',
	            		columns: [
					          {
				              widgets: [{
				                type: 'text', // texto, img
				                htmlCode: '<h1>Lorem ipsum dolor sit amet</h1><h4>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</h4>',
				                moreClass: '',
				              },
				              {
				              	type: 'btn', //text, img, btn, code
				              	htmlCode: '<a href="#" class="btn-builder">Request a Quote</a>',
				              	moreClass: ''
				              }],
				              grids: 'col-12',
				              moreClass: 'text-center',
					          },
	            		]
	              }]
	            },
	            content: [{
	            		container: 'container',
	            		divClass: 'section-custom section-custom0',
	            		columns: [{widgets: [{type: 'text', htmlCode: null, moreClass: ''}], grids: 'col-12', moreClass: ''}]
	            	}
	            ],
	          },
	          {
	          	title: 'About',
	          	slug: 'about',
	            cssclass:"section-content",
	            opc: {tag: "div", cssclass:"menuSettingSect", html:"<i class='fas fa-chevron-down'></i>"},
	            move: { tag:"div", cssclass:"moveSect", html:"<i class='fas fa-ellipsis-v'></i>"},
	            coverPage: {
	            	type: '1', // img, color, slider
	            	urlImg: '',
	            	bgColor: '',
	            	alto: '',
	            	content: {
	            		container: 'container',
	            		divClass: 'text-center',
	            		columns: [
					          {
				              widgets: [{
				                type: 'text', // texto, img
				                htmlCode: '<h1>About Us</h1>',
				                moreClass: '',
				              }],
				              grids: 'col-12',
				              moreClass: '',
					          },
	            		]
	              }
	            },
	            content: [{
	            		container: 'container',
	            		divClass: 'section-custom section-custom0',
	            		columns: [{widgets: [{type: 'text', htmlCode: null, moreClass: ''}], grids: 'col-12', moreClass: ''}]
	            	}
	            ],
	          },
	          {
	          	title: 'Services',
	          	slug: 'services-1',
	            cssclass:"section-content",
	            opc: {tag: "div", cssclass:"menuSettingSect", html:"<i class='fas fa-chevron-down'></i>"},
	            move: { tag:"div", cssclass:"moveSect", html:"<i class='fas fa-ellipsis-v'></i>"},
	            coverPage: {
	            	type: '1', // img, color, slider
	            	urlImg: '',
	            	bgColor: '',
	            	alto: '',
	            	content: {
	            		container: 'container',
	            		divClass: 'text-center',
	            		columns: [
					          {
				              widgets: [{
				                type: 'text', // texto, img
				                htmlCode: '<h1>Service 1</h1>',
				                moreClass: '',
				              }],
				              grids: 'col-12',
				              moreClass: '',
					          },
	            		]
	              }
	            },
	            content: [{
	            		container: 'container',
	            		divClass: 'section-custom section-custom0',
	            		columns: [{widgets: [{type: 'text', htmlCode: null, moreClass: ''}], grids: 'col-12', moreClass: ''}]
	            	}
	            ],
	          },
	          {
	          	title: 'Services 2',
	          	slug: 'services-2',
	            cssclass:"section-content",
	            opc: {tag: "div", cssclass:"menuSettingSect", html:"<i class='fas fa-chevron-down'></i>"},
	            move: { tag:"div", cssclass:"moveSect", html:"<i class='fas fa-ellipsis-v'></i>"},
	            coverPage: {
	            	type: '1', // img, color, slider
	            	urlImg: '',
	            	bgColor: '',
	            	alto: '',
	            	content: {
	            		container: 'container',
	            		divClass: 'text-center',
	            		columns: [
					          {
				              widgets: [{
				                type: 'text', // texto, img
				                htmlCode: '<h1>Service 2</h1>',
				                moreClass: '',
				              }],
				              grids: 'col-12',
				              moreClass: '',
					          },
	            		]
	              }
	            },
	            content: [{
	            		container: 'container',
	            		divClass: 'section-custom section-custom0',
	            		columns: [{widgets: [{type: 'text', htmlCode: null, moreClass: ''}], grids: 'col-12', moreClass: ''}]
	            	}
	            ],
	          },
	          {
	          	title: 'gallery',
	          	slug: 'gallery',
	            cssclass:"section-content",
	            opc: {tag: "div", cssclass:"menuSettingSect", html:"<i class='fas fa-chevron-down'></i>"},
	            move: { tag:"div", cssclass:"moveSect", html:"<i class='fas fa-ellipsis-v'></i>"},
	            coverPage: {
	            	type: '1', // img, color, slider
	            	urlImg: '',
	            	bgColor: '',
	            	alto: '',
	            	content: {
	            		container: 'container',
	            		divClass: 'text-center',
	            		columns: [
					          {
				              widgets: [{
				                type: 'text', // texto, img
				                htmlCode: '<h1>Gallery</h1>',
				                moreClass: '',
				              }],
				              grids: 'col-12',
				              moreClass: '',
					          },
	            		]
	              }
	            },
	            content: [{
	            		container: 'container',
	            		divClass: 'section-custom section-custom0',
	            		columns: [{widgets: [{type: 'text', htmlCode: null, moreClass: ''}], grids: 'col-12', moreClass: ''}]
	            	}
	            ],
	          },
	          {
	          	title: 'contact',
	          	slug: 'contact',
	            cssclass:"section-content",
	            opc: {tag: "div", cssclass:"menuSettingSect", html:"<i class='fas fa-chevron-down'></i>"},
	            move: { tag:"div", cssclass:"moveSect", html:"<i class='fas fa-ellipsis-v'></i>"},
	            coverPage: {
	            	type: '1', // img, color, slider
	            	urlImg: '',
	            	bgColor: '',
	            	alto: '',
	            	content: {
	            		container: 'container',
	            		divClass: 'text-center',
	            		columns: [
					          {
				              widgets: [{
				                type: 'text', // texto, img
				                htmlCode: '<h1>Contact Us</h1>',
				                moreClass: '',
				              }],
				              grids: 'col-12',
				              moreClass: '',
					          },
	            		]
	              }
	            },
	            content: [{
	            		container: 'container',
	            		divClass: 'section-custom section-custom0',
	            		columns: [{widgets: [{type: 'text', htmlCode: null, moreClass: ''}], grids: 'col-12', moreClass: ''}]
	            	}
	            ],
	          },
	        ]
        }
      },
      hideNav: true,
      pageCurrent: null,
      pageCurrentIdx: 0,
    }
  },
  created(){
  	this.initPage()
  },
  mounted(){
  	let t=this;
  	window.addEventListener("beforeunload", (evento) => {
  		debugger;
  		if (t.$store.getters['ModApps/Sites/getBeforeUnloadBiulder']) {
      	evento.preventDefault();
        evento.returnValue = "";
        return "";
      }
  	});
  },
  methods : {
  	clickVoid(){
  		console.log('clickVoid')
  	},
  	initPage(){
  		this.pageCurrent = this.theme.main.pages[0];
  	},
  	changePage(slugCurent){
  		for (let i in this.theme.main.pages) {
  			if(this.theme.main.pages[i]){
  				if(this.theme.main.pages[i].slug == slugCurent){
  					console.log('changePage', this.theme.main.pages[i].title)
  					this.pageCurrent = this.theme.main.pages[i];
  					this.pageCurrentIdx=i;
  				}
  			}
  		}
  	},
  	navbarDropdown(i){
  		i.show = !i.show;
  	},
  	showAsideEditor(){
  		this.hideNav = !this.hideNav;
  		this.$store.commit("ModApps/Sites/setcloseBarTools", 0);
  	},
  },
  watch: {
  	"theme.main.pages": function(){
  		this.pageCurrent = this.theme.main.pages[this.pageCurrentIdx];
  	},
    getSideBarOpc: function(){
    	//debugger;
      if(this.getSideBarOpc.active && this.hideNav){
      	this.showAsideEditor();
      }
    },
    getFontPageTypeBg: function(){
    	let coverPage = this.theme.main.pages[this.pageCurrentIdx].coverPage
      coverPage.typeBg = this.getFontPageTypeBg;
      this.theme.main.pages[this.pageCurrentIdx].coverPage = coverPage;
    },
    getFontPageBgImg: function(){
    	//debugger;    	
    	let coverPage = this.theme.main.pages[this.pageCurrentIdx].coverPage
      coverPage.images = this.getFontPageBgImg;
      this.theme.main.pages[this.pageCurrentIdx].coverPage = coverPage;
    },
    getFontPageColorOverlay: function(){
    	//debugger;    	
    	let coverPage = this.theme.main.pages[this.pageCurrentIdx].coverPage
      coverPage.colorOverlay = this.getFontPageColorOverlay;
      this.theme.main.pages[this.pageCurrentIdx].coverPage = coverPage;
    },
    getFontPageTypeHeight: function(){
    	//debugger;    	
    	let coverPage = this.theme.main.pages[this.pageCurrentIdx].coverPage
      coverPage.typeHeight = this.getFontPageTypeHeight;
      this.theme.main.pages[this.pageCurrentIdx].coverPage = coverPage;
    },
    getFontPageSlideHeight: function(){
    	//debugger;    	
    	let coverPage = this.theme.main.pages[this.pageCurrentIdx].coverPage
      coverPage.slideHeight = this.getFontPageSlideHeight;
      this.theme.main.pages[this.pageCurrentIdx].coverPage = coverPage;
    },
    getFontPageBoxSize: function(){
    	//debugger;    	
    	let coverPage = this.theme.main.pages[this.pageCurrentIdx].coverPage
      coverPage.content[0].columns[0].grids = this.getFontPageBoxSize;
      console.log('slider col 1 = ', this.getFontPageBoxSize)
      this.theme.main.pages[this.pageCurrentIdx].coverPage = coverPage;
    },
    getFontPageLayoutContent: function(){
    	//debugger;    	
    	let coverPage = this.theme.main.pages[this.pageCurrentIdx].coverPage
      coverPage.layoutContent = this.getFontPageLayoutContent;
      let widgetBtn = {
      	type: 'btn', //text, img, btn, code
      	htmlCode: '<a href="#" class="btn-builder">Request a Quote</a>',
      	moreClass: ''
      };
      let widgetPartner = {
      	type: 'img', //text, img, btn, code
      	htmlCode: 'partner',
      	moreClass: '',
      	partner: {
      		divClass: 'partner-slide',
	      	imgs:[
	  				{urlImg: this.noImg},
	  				{urlImg: this.noImg},
	  				{urlImg: this.noImg},
	  				{urlImg: this.noImg},
	  			],
      	}
      }
      if(coverPage.layoutContent>1){
      	if(coverPage.layoutContent==4){
      		coverPage.content[0].columns[0].widgets.splice(1, 1);
      		coverPage.content[0].columns[0].widgets.push(widgetPartner);
      	}else{
      		coverPage.content[0].columns[0].widgets.splice(1, 1);
      		coverPage.content[0].columns[0].widgets.push(widgetBtn);
      	}
      	if(coverPage.content[0].columns.length>1){
      		coverPage.content[0].columns.splice(1, 1);
      	}
      	let gridClass = 'col-4';
      	if(coverPage.layoutContent==3){
      		gridClass = 'col-4 order-first';
      	}
      	coverPage.content[0].columns.push({
      		widgets: [
	      		{
	            type: 'text', // texto, img
	            htmlCode: '<h4>Request a Quote</h4><p>Lorem ipsum dolor sit amet.</p>',
	            moreClass: '',
	          },
	          {
	          	type: 'code', //text, img, btn, code
	          	htmlCode: '<div class="formView">&#60; Form code &#62;</div>',
	          	moreClass: ''
	          }
          ],
          grids: gridClass,
          moreClass: 'text-center content-form ',
        });
      }else{
      		coverPage.content[0].columns[0].widgets.splice(1, 1);
      		coverPage.content[0].columns[0].widgets.push(widgetBtn);
      	if(coverPage.content[0].columns.length>1){
      		coverPage.content[0].columns.splice(1, 1);
      	}
      }
      this.theme.main.pages[this.pageCurrentIdx].coverPage = coverPage;
    },
    getFontPageContentAling: function(){
    	//debugger;    	
    	let coverPage = this.theme.main.pages[this.pageCurrentIdx].coverPage
      coverPage.content[0].columns[0].moreClass = this.getFontPageContentAling;
      this.theme.main.pages[this.pageCurrentIdx].coverPage = coverPage;
    },
  },
	computed: {
    ...mapGetters("ModApps/Sites", { 
      getSideBarOpc: "getSideBarOpc",
      getFontPageBgImg: "getFontPageBgImg",
      getFontPageTypeBg: "getFontPageTypeBg",
			getFontPageColorOverlay: "getFontPageColorOverlay",
			getFontPageTypeHeight: "getFontPageTypeHeight",
			getFontPageSlideHeight: "getFontPageSlideHeight",
			getFontPageLayoutContent: "getFontPageLayoutContent",
			getFontPageBoxSize: "getFontPageBoxSize",
			getFontPageContentAling: "getFontPageContentAling",
			getFontPageThumbs: "getFontPageThumbs",
			getFontPageFormHeader: "getFontPageFormHeader",
			getFontPageFormShow: "getFontPageFormShow",
			getFontPageBoxForm: "getFontPageBoxForm",
			getFontPageFormAling: "getFontPageFormAling",
    }),
	}
};
</script>
<style scoped lang="css">
	.container-principal{
		margin-top: 0px;
	}
	.domHtml{
		position: relative;
	  width: 100%;
	  height: 100vh;
	  margin-top: 0px;
	  transition: 0.3s ease-in-out;
		-webkit-transition: 0.3s ease-in-out;
	}
	.domHtml.pleft{
		/*margin-right: 256px;
	  width: calc(100% - 256px);*/
	}
	.siteHtml{
	  display: block;
	  min-height: calc(100vh - 6rem);
	  padding-bottom: 11.5rem;
		/*  max-width: 1200px;*/
	  width: calc(100% - 5px);
	  margin: 0px 1px 20px 1px;
	  background-color: transparent;
	  box-shadow: inset 1px 1px 0 rgb(0 0 0 / 10%), inset 0 -1px 0 rgb(0 0 0 / 7%);
	  overflow-x: hidden;
	  overflow-y: auto;
	  height: calc(100vh - 83px);
	  position: relative;
	  padding-top: 30px;
	}
	.siteHtml:before{
		content: "This section is NOT part of your web design";
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 50px;
		font-size: 20px;
		text-align: center;
	  color: #555;
	  opacity: 0.3;
	}
</style>
