let addTask = function(i) {
    this.btnAddTask = false;
        let newTask = {
            id_jobs: i.id_i,
            id: 0,
            name: "new task",
            id_status: 1,
        };
        i.taks.push(newTask);
        this.$store.commit("ModApps/jobs/set_refresh_grid", true);
        let btn = '.btnAddTask-'+i.id_i;
        let button = '.buttonAddTask-'+i.id_i;
        if(document.querySelector(btn)){
            document.querySelector(btn).classList.remove("showBtnAdd");
        }
        if(document.querySelector(button)){
            document.querySelector(button).setAttribute('disabled', true);
        }
        let idx = i.taks.length - 1;
        let t = this;
        let url = this.apis.addItemTask()
        window.master
            .post(url, {
                user_id: window.master_client,
                org_id: window.localorgdata,
                id_jobs: i.id_i,
                name: i.taks[idx].name,
                id_status: i.taks[idx].id_status,
            })
            .then((response) => {
                let r = response.data;
                if (!r.status) {
                    console.log("error al agregar item, remover", i.taks[idx]);
                    i.taks.splice(idx, 1);
                } else {
                    i.taks[idx].id = r.r.id;
                    //console.log('editItemTask',response.data);
                    let n = i.taks[idx].name;
                    let elem = document.querySelector(".heigth" + idx + "-0");
                    elem.classList.add(".heigth" + idx + "-" + i.taks[idx].id);
                    elem.classList.remove(".heigth" + idx + "-0");
                }
                this.btnAddTask = true;
                t.$store.commit("ModApps/jobs/set_refresh_grid", true);
                if(document.querySelector(button)){
                    document.querySelector(button).removeAttribute('disabled');
                }
            })
            .catch((error) => {
                console.log(error);
                this.btnAddTask = true;
                if(document.querySelector(button)){
                    document.querySelector(button).removeAttribute('disabled');
                }
            });    
};
export default addTask;
