<template>
    <div>
        <div class="card-header window-header">
            <div class="col-12 d-flex justify-content-start">
                <h3>Project #{{ editItem.id }}</h3>
                <button type="button"
                    class="btn btn-success btn-sm"
                    style="height: 30px;margin-left: 10px; padding: 2px 5px;line-height: 1;"
                    @click="editProjectFeatures()"
                    :disabled="isExistSlug"
                >
                    Save
                </button>
            </div>
        </div>
        <form @submit.prevent="editProjectFeatures()" id="form1" class="window-body">
            <div v-show="isLoading" class="bgLoaging">
                <Loader :getProgress="apiProgess"></Loader>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <label class="col-form-label ">Full Description:</label>
                        <VueEditor v-model="features" :editor-toolbar="customToolbar" class="project_features" />
                    </div>
                </div>
            </div>
            <div class="card-footer d-flex justify-content-center">
                <button type="submit"
                    class="btn btn-success"
                    style="width: 100%;"
                    :disabled="isExistSlug">Save Project</button>
            </div>
        </form>
    </div>
</template>
<script>
// @ts-ignore
import Loader from "@/core/components/shared/Loader.vue";
import { VueEditor } from "vue2-editor";
export default {
    components: {
        Loader,
        VueEditor
    },
    props: {
        dataModal: {
            type: [Object, String, Number, Array],
            require: false,
            default: null,
        }
    },
    data() {
        return {
            isLoading: false,
            isExistSlug: false,
            apis: null,
            features: null,
            premiumProfile: false,
            planActual: 1,
            editItem: null,
            apiProgess: 0,
            errorUrl: null,
            customToolbar: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ["bold", "underline"], // toggled buttons
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                ],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ color: [] }],
                ["link", "clean"], // remove formatting button
            ],
        };
    },
    created() {
        let urlApis = this.$store.getters['ModApps/Marketea/urlApis'];
        this.apis = urlApis.Projects;
        // valid premium planID
        this.premiumProfile = (this.$store.getters['core/apps/getPlanIdApp']!=8);
        this.planActual = this.$store.getters['core/apps/getPlanIdApp'];
        if(this.planActual==7){
            this.premiumProfile = true;
            console.log('Plan sponsored, dando permiso de premium', this.premiumProfile);
        }
        console.log('premiumProfile', this.premiumProfile);
        if(this.dataModal!=null){
            this.editItem = this.dataModal;
            // console.log('edit item', this.editItem);
            this.getProjectFeatures();
        }
    },
    methods: {
        getProjectFeatures(){
            let t = this;
            t.isLoading = true;
            t.features= null;
            let urlService = t.apis.get(4); //setOptions
            // @ts-ignore
            window.master.post(urlService, {
                user_id: window.master_client,
                org_id: window.localorgdata,
                id: t.editItem.id,
                pusher_id: pusher.connection.socket_id,
            }, {
                headers: {
                    common: {
                    // @ts-ignore
                    Authorization: 'Bearer ' + window.localtoken_M
                    },
                    post: { 'Content-Type': 'multipart/form-data' }
                },
                onUploadProgress: (progressEvent) => {
                    // console.log('onUploadProgress', progressEvent)
                    let scanPer = Math.floor(
                        (progressEvent.loaded*100 / progressEvent.total)
                    );
                    // console.log('scanPer', scanPer);
                    if(scanPer < 99){
                        t.apiProgess = scanPer;
                    }
                }
            })
            .then((response) => {
                t.apiProgess =100;
                // @ts-ignore
                console.log(urlService, response.data);
                if(response.data.features){
                    t.features = response.data.features.meta_value;
                }
                t.isLoading = false;
            }).catch((error) => {
                console.log(error);
                t.apiProgess =0;
                t.isLoading = false;
            });
        },
        editProjectFeatures(){
            let t = this;
            if(!t.isExistSlug){
                t.isLoading = true;
                let payLoad = new FormData();
                // @ts-ignore
                payLoad.append("user_id", window.master_client);
                // @ts-ignore
                payLoad.append("org_id", window.localorgdata);
                // @ts-ignore
                // eslint-disable-next-line no-undef
                payLoad.append("pusher_id", pusher.connection.socket_id);
                payLoad.append("project_id", t.editItem.id);
                payLoad.append("meta_name", 'features');
                payLoad.append("meta_value", t.features);
                let urlService = t.apis.set(3); // setFeatures
                console.log(urlService)
                // @ts-ignore
                window.master.post(urlService, payLoad, {
                    headers: {
                        common: {
                        // @ts-ignore
                        Authorization: 'Bearer ' + window.localtoken_M
                        },
                        post: { 'Content-Type': 'multipart/form-data' }
                    },
                    onUploadProgress: (progressEvent) => {
                        // console.log('onUploadProgress', progressEvent)
                        let scanPer = Math.floor(
                            (progressEvent.loaded*100 / progressEvent.total)
                        );
                        // console.log('scanPer', scanPer);
                        if(scanPer < 99){
                            t.apiProgess = scanPer;
                        }
                    }
                })
                .then((response) => {
                    t.apiProgess =100;
                    // @ts-ignore
                    console.log(urlService, response.data);
                    if(response.data.status){
                        t.$emit('actionReturn', {action: 'refreshList'});
                    }else{
                        t.$notify({
                            group: "noti",
                            type: 'error',
                            title: "Fail!",
                            text: "Could not be saved",
                        });
                    }
                    t.isLoading = false;
                }).catch((error) => {
                    console.log(error);
                    t.apiProgess =0;
                    t.isLoading = false;
                });
            }
        },
    },
    computed: {
    },
};
</script>

<style lang="scss" scoped>
.window-header{cursor: move;padding: 2px;}
.marginTop{margin-top: 20px;}
.window-body{
    height: calc(100vh - 50px);
    // height: 600px;
    overflow: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    padding-bottom: 0em;
    width: 100%;
    .card-body{padding-top: 0px;}
    &::-webkit-scrollbar:vertical {
        width:3px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #797979;
        border-radius: 20px;
        border: 0px solid #f1f2f3;
    }
    .bgLoaging {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        margin: auto;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9;
        background-color: rgba(255, 255, 255, 0.342);
    }

    .bgLoaging>div {
        margin-left: -100px;
    }
    .row{
        width: 100%;
        .previewImg{
            position: relative;
            width: 100%;
            max-width: 300px;
            overflow: hidden;
            img{
                width: 100%;
                height: auto;
                &.noImg{
                    margin: auto;
                    display: block;
                }
            }
            .btn-upload-img{
                position: absolute;
                z-index: 1;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                align-content: center;
                justify-content: center;
                align-items: center;
                text-shadow: 1px 0px 10px rgb(255 255 255 / 50%);
                color: #4c32e9;
                font-size: 50px;
                opacity: 0.5;
                &:hover{
                background-color: rgb(0 0 0 / 0.2);
                opacity: 1;
                cursor: pointer;
                }
            }
            .delBgImg {
                position: absolute;
                z-index: 2;
                top: 5px;
                right: 5px;
                color: #fff;
                background: red;
                padding: 8px;
                border-radius: 4px;
                opacity: 0.3;
                &:hover{
                    opacity: 1;
                    cursor: pointer;
                }
            }
        }
    }
    .card-footer.d-flex.justify-content-center {
        position: relative;
        width: 100%;
        height: 73px;
        bottom: 0px;
        left: 0px;
    }
}
@media (max-width:767px){
    .window-body{
        height: 100%;
        max-height: 750px;
        padding-bottom: 20em;
    }
}
</style>
