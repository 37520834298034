<template>
    <div class="">
        <form @submit.prevent="MetSavesettingInvoice">
            <div class="card">
                <div class="card-header">Invoice Options</div>
                <div class="card-body">
                    <div class="form-group row">
                        <label
                            for="inputPassword"
                            class="col-sm-1 col-form-label"
                        ></label>
                        <div class="col-sm-12">
                            <div style="margin-top: 15px">
                                <div class="form-group row">
                                    <label
                                        for="jobi"
                                        class="col-sm-3 col-form-label text-right"
                                        >Default Invoice Terms:</label
                                    >
                                    <div class="col-sm-9">
                                        <select
                                            class="form-control"
                                            id=""
                                            v-model="
                                                FormInvoiceSetting.term_id
                                            "
                                        >
                                            <option :value="0">
                                                Due on Receipt
                                            </option>
                                            <option
                                                :value="termconf.id"
                                                v-for="termconf in configtermsList"
                                                :key="termconf.id"
                                            >
                                                {{ termconf.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-9">
                                        <div
                                            class="custom-checkbox text-right"
                                            style="
                                                display: flex;
                                                align-items: center;
                                            "
                                        >
                                            <label
                                                for="jobi"
                                                class="col-sm-4 col-form-label text-right"
                                                >Job information:</label
                                            >
                                            <input
                                                type="checkbox"
                                                class=""
                                                id="jobi"
                                                v-model="
                                                    FormInvoiceSetting.jobinfomationcheck
                                                "
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label
                                        for=""
                                        class="col-sm-3 col-form-label text-right"
                                        >Job information:</label
                                    >
                                    <div class="col-sm-9">
                                        <input
                                            type="text"
                                            class="form-control"
                                            v-model="
                                                FormInvoiceSetting.jobinfomationtitle
                                            "
                                            :disabled="
                                                FormInvoiceSetting.jobinfomationcheck
                                                    ? disabled
                                                    : ''
                                            "
                                        />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label
                                        for=""
                                        class="col-sm-3 col-form-label text-right"
                                        >Default job information:</label
                                    >
                                    <div class="col-sm-9">
                                        <textarea
                                            class="form-control"
                                            id=""
                                            rows="10"
                                            v-model="
                                                FormInvoiceSetting.jobinfomationtext
                                            "
                                            :disabled="
                                                FormInvoiceSetting.jobinfomationcheck
                                                    ? disabled
                                                    : ''
                                            "
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 ctrlbtnShow">
                            <div class="row">
                                <div class="col-md-6">
                                    <button
                                        type="button"
                                        class="btn btn-primary btn-sm mr-2 float-right"
                                        style="margin-bottom: 10px;width: -webkit-fill-available;"
                                        @click="closeInvoOpts"
                                    >
                                        CANCEL
                                    </button>
                                </div>
                                <div class="col-md-6">
                                    <button
                                        type="submit"
                                        class="btn btn-success btn-sm mr-2 float-right"
                                        style="margin-bottom: 10px;width: -webkit-fill-available;"
                                    >
                                        <i class="fas fa-save"></i> SAVE
                                    </button>
                                </div>
                            </div>
                        </div>                                       
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
export default {
    components: { },
    name: "invociceOptions",
    data () {
        return {
            configtermsList: [],
            disabled: false,
            FormInvoiceSetting: {
                isLoading: false,
                user_id: null,
                action: "Add",
                greeting: false,
                greetingFormat1: 1,
                greetingFormat2: 1,
                messagesubjet: null,
                messagetext: null,
                jobinfomationcheck: true,
                jobinfomationtitle: "Job information",
                jobinfomationtext: null,
                term_id: 0,
            },
        }
    },
    created () {
        this.getInvoiceSetting();
    },
    methods: {
        getInvoiceSetting() {
            this.$store.commit("core/setLoadingModal", true);
            //this.FormestimateSetting.isLoading = true
            window.billing
            .get("invoice/setting/" + window.localuserdata)
            .then((response) => {
                console.log(response.data);
                if (response.data.result.length) {
                    this.FormInvoiceSetting.user_id = response.data.result[0].user_id;
                    this.FormInvoiceSetting.greeting =
                        response.data.result[0].greeting;
                    this.FormInvoiceSetting.greetingFormat1 =
                        response.data.result[0].greetingFormat1;
                    this.FormInvoiceSetting.greetingFormat2 =
                        response.data.result[0].greetingFormat2;
                    this.FormInvoiceSetting.messagesubjet =
                        response.data.result[0].messagesubjet;
                    this.FormInvoiceSetting.messagetext =
                        response.data.result[0].messagetext;
                    this.FormInvoiceSetting.jobinfomationcheck =
                        response.data.result[0].jobinfomationcheck;
                    this.FormInvoiceSetting.jobinfomationtitle =
                        response.data.result[0].jobinfomationtitle;
                    this.FormInvoiceSetting.jobinfomationtext =
                        response.data.result[0].jobinfomationtext;
                    this.FormInvoiceSetting.term_id = response.data.result[0].term_id;
                    if (this.FormInvoiceSetting.user_id !== null) {
                        this.FormInvoiceSetting.action = "Update";
                    }
                    this.$store.commit("core/setLoadingModal", false);
                }
            })
            .catch((error) => {
                console.log(error)
                this.$store.commit("core/setLoadingModal", false);
            });
        },        
        MetSavesettingInvoice() {
            this.$store.commit("core/setLoadingModal", true);
            if (this.FormInvoiceSetting.action == "Add") {
                window.billing
                .post("invoice/setting", {
                    user_id: window.localuserdata,
                    greeting: this.FormInvoiceSetting.greeting,
                    greetingFormat1: this.FormInvoiceSetting.greetingFormat1,
                    greetingFormat2: this.FormInvoiceSetting.greetingFormat2,
                    messagesubjet: this.FormInvoiceSetting.messagesubjet,
                    messagetext: this.FormInvoiceSetting.messagetext,
                    term_id: this.FormInvoiceSetting.term_id,
                    jobinfomationcheck: this.FormInvoiceSetting.jobinfomationcheck,
                    jobinfomationtitle: this.FormInvoiceSetting.jobinfomationtitle,
                    jobinfomationtext: this.FormInvoiceSetting.jobinfomationtext,
                })
                .then((response) => {
                    this.FormInvoiceSetting.action = "Update";
                    this.$store.commit("core/setLoadingModal", false);
                    this.$store.commit("core/setCloseModal", true);
                    this.$notify({
                        group: "noti",
                        title: "Created",
                        type: "success",
                        text: "Created Message Successful",
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.$store.commit("core/setLoadingModal", false);
                });
            }
            if (this.FormInvoiceSetting.action == "Update") {
                this.$store.commit("core/setLoadingModal", true);
                window.billing
                .put("invoice/setting/" + window.localuserdata, {
                    user_id: window.localuserdata,
                    greeting: this.FormInvoiceSetting.greeting,
                    greetingFormat1: this.FormInvoiceSetting.greetingFormat1,
                    greetingFormat2: this.FormInvoiceSetting.greetingFormat2,
                    messagesubjet: this.FormInvoiceSetting.messagesubjet,
                    messagetext: this.FormInvoiceSetting.messagetext,
                    term_id: this.FormInvoiceSetting.term_id,
                    jobinfomationcheck: this.FormInvoiceSetting.jobinfomationcheck,
                    jobinfomationtitle: this.FormInvoiceSetting.jobinfomationtitle,
                    jobinfomationtext: this.FormInvoiceSetting.jobinfomationtext,
                })
                .then((response) => {
                    this.FormInvoiceSetting.action = "Update";
                    this.$store.commit("core/setLoadingModal", false);
                    this.$store.commit("core/setCloseModal", true);
                    this.$notify({
                        group: "noti",
                        title: "Updated",
                        type: "success",
                        text: "Updated Setting Successful",
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.$store.commit("core/setLoadingModal", false);
                });
            }
        },
        closeInvoOpts(){this.$store.commit("core/setCloseModal", true);},
    },
    watch: { },
    computed: {},
};
</script>

<style lang="css" scoped></style>
