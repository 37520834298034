<template>
    <BillingRestore>
        <slot>
            <div class="page-breadcrumb">
                <div class="row">
                    <div class="col-md-8 align-self-center">
                        <h4 class="page-title">Restore Customer</h4>
                    </div>
                    <div class="col-md-4 align-self-center">
                        <div class="d-flex align-items-right justify-content-end">
                            <router-link :to="{ name: 'Setting' }" class="btn btn-info btn-sm mr-2 ctrlbtn" style="margin-bottom: 10px;width: -webkit-fill-available;">
                                <i class="fas fa-arrow-left"></i>
                                Back
                            </router-link>   
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="card card-body estilosform">
                    <Loader v-if="isLoadingCustomers"></Loader>
                    <div class="row contenedor-table" v-else>
                        <table
                            class="table table-striped table-bordered table-hover"
                            id="restoreCustomer"
                        >
                            <thead>
                                <tr>
                                    <th>Name/Company</th>
                                    <th>Date</th>
                                    <th>Restore?</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in CustomersList" :key="item.id">
                                    <td>
                                        <span
                                            >{{ item.firstname }}
                                            {{ item.lastname }}</span
                                        >
                                        <small class="d-block" style="color: #aaa">
                                            {{ item.bussinesname }}
                                        </small>
                                    </td>
                                    <td>{{ item.fecdelete }}</td>
                                    <td>
                                        <button
                                            type="button"
                                            class="btn btn-warning"
                                            @click="setCustomersRestore(item.id)"
                                        >
                                            <i class="fas fa-share"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </slot>
    </BillingRestore>
</template>
<script>
import Loader from "@/core/components/shared/Loader";
import BillingRestore from "@/apps/billing/Layouts/BillingRestore";
export default {
    components: {
        BillingRestore,
        Loader,
    },
    data() {
        return {
            isLoading: false,
            CustomersList: [],
            isLoadingCustomers: true,
        };
    },
    methods: {
        getCustomersRestore() {
            var self = this;
            this.isLoadingCustomers = true;
            console.log("localuserdata=" + window.localuserdata);
            window.billing
                .get("customer/getrestore/" + window.localuserdata)
                .then((response) => {
                    console.log(response.data.result);
                    self.CustomersList = response.data.result;
                    self.isLoadingCustomers = false;
                })
                .catch((error) => console.log(error));
        },
        setCustomersRestore(id) {
            console.log("restaurar customer" + id);
            this.$swal({
                title: "Restore this Customer?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes restore!",
            }).then((result) => {
                if (result.value) {
                    this.isLoadingCustomers = true;
                    window.billing
                        .put("customer/setrestore", {
                            user_id: window.localuserdata,
                            id: id,
                        })
                        .then((response) => {
                            console.log(response.data);
                            this.isLoadingCustomers = false;
                            if (response.data.result) {
                                this.getCustomersRestore();
                                this.$swal.fire({
                                    icon: "success",
                                    title: "Customer restore successfully",
                                    showConfirmButton: false,
                                    timer: 2000,
                                });
                            } else {
                                this.$swal.fire({
                                    icon: "error",
                                    title: "Error",
                                    text: "",
                                    showConfirmButton: false,
                                    timer: 6000,
                                });
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            this.isLoadingCustomers = false;
                        });
                }
            });
        },
    },
    mounted() {
        this.getCustomersRestore();
    },
};
</script>

<style lang="scss" scoped>
#restoreCustomer {
    width: 100%;
}
#restoreCustomer thead tr th {
    font-weight: bold;
    font-size: 16px;
}
#restoreCustomer thead tr th:nth-child(1) {
    width: 80%;
}
#restoreCustomer thead tr th:nth-child(2) {
    width: 15%;
    text-align: center;
}
#restoreCustomer thead tr th:nth-child(3) {
    width: 5%;
    text-align: center;
}
#restoreCustomer tbody tr td:nth-child(2) {
    text-align: center;
}
#restoreCustomer tbody tr td:nth-child(3) {
    text-align: center;
}
.contenedor-table table thead tr th {
    font-weight: bold;
}
@media (max-width: 1100px) {
    .contenedor-table {
        max-width: 924px;
        overflow-x: scroll;
    }
}
@media (min-width: 1280px) {
    .ctrlbtn {
        width:100px !important;
    }
}
</style>
