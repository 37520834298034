<template>
    <div>
        <form @submit.prevent="UpdateSetting" id="form1" class="custominputs">
            <div class="card-body">
                <div class="bgloading" v-show="isLoading"><Loader></Loader></div>
                <div class="form-check" style="margin-left: 11px; padding: 12px 10px">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck1"
                        v-model="accept_notification"
                    />
                    <label class="form-check-label" for="defaultCheck1">
                        Receive Email Notifications
                    </label>
                </div>
                <div class="form-group">
                    <label for="exampleFormControlInput1">Email </label>
                    <input
                        type="email"
                        class="form-control"
                        id="exampleFormControlInput1"
                        v-model="email1"
                        :disabled="!accept_notification"
                    />
                </div>
                <div class="form-group">
                    <label for="exampleFormControlInput1">Email copy 2</label>
                    <input
                        type="email"
                        class="form-control"
                        id="exampleFormControlInput1"
                        v-model="email2"
                        :disabled="!accept_notification"
                    />
                </div>
                <div class="form-group">
                    <label for="exampleFormControlInput1">Email copy 3</label>
                    <input
                        type="email"
                        class="form-control"
                        id="exampleFormControlInput1"
                        v-model="email3"
                        :disabled="!accept_notification"
                    />
                </div>
            </div>
            <div class="card-footer d-flex justify-content-end">
                <button type="submit" class="btn btn-success btn-sm">UPDATE</button>
            </div>
        </form>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader.vue";
import { mapGetters } from "vuex";
export default {
    name: "EmailSetting",
    components: {
        Loader,
    },
    data() {
        return {
            isLoading: false,
            email1: null,
            email2: null,
            email3: null,
            accept_notification: false,
            org_email: null,
        };
    },
    created() {
        this.getSetting();
    },
    mounted() {
        this.email1 = this.org_email;
    },
    methods: {
        UpdateSetting() {
            var accept_notification = 0;
            if (this.accept_notification == true) {
                accept_notification = 1;
            }
            window.SlabsV2.post("email/notification", {
                org_id: window.localuserdata,
                user_id: window.localAccountID,
                email1: accept_notification == 1 ? this.email1 : null,
                email2: accept_notification == 1 ? this.email2 : null,
                email3: accept_notification == 1 ? this.email3 : null,
                accept_notification: accept_notification,
            })
                .then((response) => {
                    this.$swal.fire({
                        icon: "success",
                        title: "Updated",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    this.getSetting();
                    let t = [];
                    t.push({ open: 1 });
                    this.arrays("UpdateEmailSetting", t);
                })
                .catch((error) => {
                    this.isLoading = false;
                    console.log(error);
                });
        },
        getSetting() {
            this.isLoading = true;
            window.SlabsV2.get("email/setting/" + window.localuserdata)
                .then((response) => {
                    this.isLoading = false;
                    if (response.data.status) {
                        if (response.data.result.email1 == null) {
                            this.email1 = this.org_email;
                        } else {
                            this.email1 = response.data.result.email1;
                        }
                        this.email2 = response.data.result.email2;
                        this.email3 = response.data.result.email3;
                        this.accept_notification =
                            response.data.result.accept_notification == 1;
                    }
                })
                .catch((error) => console.log(error));
        },
        arrays(name, data) {
            let k = [];
            k.push({ function: name, data: data });
            this.$store.commit("ModApps/slabs/set_slabsStorage", k);
        },
    },
    computed: {
        ...mapGetters("ModApps/slabs", ["get_slabsStorage"]),
    },
};
</script>
<style lang="scss" scoped></style>
