let jobsPdf = function(id, id_invoice) {
    let billing = 0;
    let b = this.$store.getters.applistorgs;
    for (const i in b) {
        if (b[i].name === "Billing" && b[i].privilegies == true) {
            billing = 1;
        }
    }
    let url = this.apis.jobsPdf(id_invoice)
    //window.open(urlmaster + "api/v2/jobs/pdf/"+this.user_id+'/'+this.org_id+'/'+id+'/'+ billing, "_blank");
    window.open(url, "_blank");
};
export default jobsPdf;
