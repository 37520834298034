<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card" style="background: transparent">
                        <div class="card-header" style="padding-left: 0">
                            <div class="row">
                                <div class="col-md-6">
                                    <router-link
                                        :to="{ name: 'InvoicesRecNew' }"
                                        class="btn btn-success"
                                        ><i class="fas fa-plus"></i> Add Recurring
                                        Invoice</router-link
                                    >
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Search"
                                                v-model="inputFilter"
                                                v-on:keyup="searchFilter"
                                            />
                                        </div>
                                        <div class="col-md-2">
                                            <div
                                                class="basic_dropdown"
                                                v-click-outside="hideBtnstatus"
                                            >
                                                <button
                                                    class="btn-status"
                                                    @click="btnstatus()"
                                                >
                                                    Status
                                                    <i class="fas fa-caret-down"></i>
                                                </button>
                                                <ul
                                                    class="reset dropdStatus"
                                                    v-if="statusdropd"
                                                >
                                                    <li>
                                                        <div
                                                            class="custom-control custom-checkbox"
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                class="custom-control-input"
                                                                id="check12"
                                                                @click="checkAll()"
                                                                v-model="isCheckAll"
                                                            />
                                                            <label
                                                                class="custom-control-label"
                                                                for="check12"
                                                                ><b>All</b></label
                                                            >
                                                        </div>
                                                    </li>
                                                    <li
                                                        v-for="(
                                                            item, index
                                                        ) in statusdata"
                                                        :key="index"
                                                    >
                                                        <div
                                                            class="custom-control custom-checkbox"
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                class="custom-control-input"
                                                                :id="index"
                                                                v-bind:value="item.value"
                                                                v-model="statuslist"
                                                                @change="searchFilter"
                                                            />
                                                            <label
                                                                class="custom-control-label"
                                                                :for="index"
                                                                >{{ item.name }}</label
                                                            >
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <select
                                                v-model="paginador"
                                                class="form-control paginacion"
                                                @change="searchFilterPaginador"
                                            >
                                                <option value="0">Display</option>
                                                <option value="200">200</option>
                                                <option value="300">300</option>
                                                <option value="500">500</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <Loader v-if="isLoading"></Loader>
                                <div
                                    class="contenedor-estimate"
                                    style="min-height: calc(100vh - 230px)"
                                    v-else
                                >
                                    <table
                                        class="table table-striped table-bordered table-hover"
                                        v-if="InvoicesList.length > 0"
                                        id="invListRec"
                                    >
                                        <thead>
                                            <tr>
                                                <th scope="col">Rec #</th>
                                                <th scope="col">Customer</th>
                                                <th scope="col">Interval</th>
                                                <th scope="col">Start Date</th>
                                                <th scope="col">End Date</th>
                                                <th scope="col">Terms</th>
                                                <th scope="col">Name</th>
                                                <th scope="col" style="text-align: right">
                                                    Total
                                                </th>
                                                <th
                                                    scope="col"
                                                    style="text-align: center"
                                                >
                                                    Status
                                                </th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="item in InvoicesList"
                                                :key="item.id"
                                            >
                                                <td scope="row">
                                                    {{
                                                        item.numdoc
                                                            .toString()
                                                            .padStart(5, "0000")
                                                    }}
                                                </td>
                                                <td>
                                                    {{ item.firstname }} {{ item.lastname
                                                    }}<br /><span
                                                        style="color: #a9a9a9"
                                                        >{{ item.bussinesname }}</span
                                                    >
                                                </td>
                                                <td scope="row">
                                                    {{ intervalText[item.interval] }}
                                                </td>
                                                <td>{{ item.startdate }}</td>
                                                <td>{{ item.enddate }}</td>
                                                <td style="text-align: left">
                                                    {{ item.termText }}
                                                </td>
                                                <td>{{ item.invoicename }}</td>
                                                <td style="text-align: right">
                                                    ${{ item.total }}
                                                </td>
                                                <td style="text-align: center">
                                                    <span
                                                        v-if="item.activorec == 1"
                                                        class="badge badge-success"
                                                        >Active</span
                                                    >
                                                    <span
                                                        v-else-if="item.activorec == 0"
                                                        class="badge badge-danger"
                                                        >Inactive</span
                                                    >
                                                </td>
                                                <td>
                                                    <span class="cdropdown">
                                                        <button>
                                                            <span>Actions</span>
                                                        </button>
                                                        <label>
                                                            <input type="checkbox" />
                                                            <ul>
                                                                <li
                                                                    @click="
                                                                        actionEdit(
                                                                            item.id
                                                                        )
                                                                    "
                                                                >
                                                                    <button>
                                                                        <i
                                                                            class="fas fa-edit"
                                                                        ></i>
                                                                        Edit
                                                                    </button>
                                                                </li>
                                                                <li
                                                                    @click="
                                                                        actionDuplicate(
                                                                            item.id
                                                                        )
                                                                    "
                                                                >
                                                                    <button>
                                                                        <i
                                                                            class="fas fa-copy"
                                                                        ></i>
                                                                        Duplicate
                                                                    </button>
                                                                </li>
                                                                <li
                                                                    @click="
                                                                        MetDeleteInvoiceRec(
                                                                            item.id
                                                                        )
                                                                    "
                                                                >
                                                                    <button>
                                                                        <i
                                                                            class="fas fa-times"
                                                                        ></i>
                                                                        Delete
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                        </label>
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div
                                        class="text-center d-block w-100 p20"
                                        v-else-if="
                                            InvoicesList.length == 0 && isFilter == true
                                        "
                                    >
                                        <div
                                            class="text-center"
                                            style="padding: 80px; background: #fff"
                                        >
                                            No record
                                        </div>
                                    </div>
                                    <div class="text-center d-block w-100 p20" v-else>
                                        <div
                                            class="text-center"
                                            style="padding: 80px; background: #fff"
                                        >
                                            <span
                                                >Its time to create your first Recurring
                                                Invoice!</span
                                            ><br />
                                            <router-link
                                                :to="{ name: 'InvoicesRecNew' }"
                                                class="btn btn-success"
                                                style="margin-top: 7px"
                                                >Create New Recurring Invoice</router-link
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader";
import ClickOutside from "vue-click-outside";
export default {
    name: "Estimates",
    components: {
        Loader,
    },
    data() {
        return {
            isLoading: false,
            showModal: false,
            SettingBox: "",
            InvoicesList: [],
            intervalText: [],
            TermsList: [],
            FormSendMail: {
                isLoading: false,
                IDEstimate: null,
                EmailCustomer: null,
                EmailCheck: false,
            },
            inputFilter: "",
            OldInvoicesList: [],
            invStatus: 0,
            isFilter: false,
            isCheckAll: true,
            statusdata: [
                { value: "1", name: "Active" },
                { value: "0", name: "Inactive" },
            ],
            statuslist: [1, 0],
            statusdropd: false,
            paginador: 0,
        };
    },
    created() {
        this.getTermsList();
        this.getInvoices();
    },
    directives: {
        ClickOutside,
    },
    methods: {
        btnstatus() {
            this.statusdropd = !this.statusdropd;
        },
        hideBtnstatus() {
            this.statusdropd = false;
        },
        checkAll: function () {
            this.isCheckAll = !this.isCheckAll;
            this.statuslist = [];
            if (this.isCheckAll) {
                for (var key in this.statusdata) {
                    this.statuslist.push(this.statusdata[key].value);
                }
                this.searchFilter();
            }
        },
        updateCheckall: function () {
            if (this.statuslist.length == this.statusdata.length) {
                this.isCheckAll = true;
            } else {
                this.isCheckAll = false;
            }
        },
        searchFilter() {
            if (this.inputFilter.length > 1 || this.statuslist.length > 0) {
                this.isLoading = true;
                window.billing
                    .post("filterinvoicerec", {
                        user_id: window.localuserdata,
                        filter: this.inputFilter,
                        status: this.statuslist,
                        pag: this.paginador,
                    })
                    .then((response) => {
                        //console.log('filter ',response.data.result);
                        let listFilter = response.data.result;
                        let e = listFilter;
                        for (var i = 0; i < e.length; i++) {
                            let d = new Date(e[i].startdate);
                            let sd = (d + "").split(" ");
                            listFilter[i].startdate = sd[1] + " " + sd[2] + ", " + sd[3];
                            if (e[i].enddate != "" && e[i].enddate != null) {
                                d = new Date(e[i].enddate);
                                sd = (d + "").split(" ");
                                listFilter[i].enddate =
                                    sd[1] + " " + sd[2] + ", " + sd[3];
                            } else {
                                listFilter[i].enddate = "";
                            }
                            for (let prop in this.TermsList) {
                                if (e[i].terms_id == this.TermsList[prop].id) {
                                    listFilter[i].termText = this.TermsList[prop].name;
                                }
                            }
                            if (e[i].terms_id == 0) {
                                listFilter[i].termText = "Due on Receipt";
                            }
                        }
                        this.InvoicesList = listFilter;
                        this.isFilter = true;
                        this.isLoading = false;
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        console.log(error);
                    });
            } else {
                this.InvoicesList = this.OldInvoicesList;
                this.isLoading = false;
                this.isFilter = false;
            }
        },
        searchFilterPaginador() {
            if (this.paginador > 0) {
                this.searchFilter();
            } else {
                this.InvoicesList = this.OldInvoicesList;
                this.isLoading = false;
                this.isFilter = false;
            }
        },
        getInvoices() {
            this.isLoading = true;
            window.billing
                .get("invoicesrec/" + window.localuserdata)
                .then((response) => {
                    console.log("invoices list");
                    console.log(response.data.result);
                    this.InvoicesList = response.data.result;
                    let e = this.InvoicesList;
                    for (var i = 0; i < e.length; i++) {
                        let d = new Date(e[i].startdate);
                        let sd = (d + "").split(" ");
                        this.InvoicesList[i].startdate =
                            sd[1] + " " + sd[2] + ", " + sd[3];
                        if (e[i].enddate != "" && e[i].enddate != null) {
                            d = new Date(e[i].enddate);
                            sd = (d + "").split(" ");
                            this.InvoicesList[i].enddate =
                                sd[1] + " " + sd[2] + ", " + sd[3];
                        } else {
                            this.InvoicesList[i].enddate = "";
                        }
                        this.intervalText[1] = "Weekly";
                        this.intervalText[2] = "Biweekly";
                        this.intervalText[3] = "Monthly";
                        this.intervalText[4] = "Quarterly";
                        this.intervalText[5] = "Semiannual";
                        this.intervalText[6] = "Annual";
                        for (let prop in this.TermsList) {
                            if (e[i].terms_id == this.TermsList[prop].id) {
                                this.InvoicesList[i].termText = this.TermsList[prop].name;
                            }
                        }
                        if (e[i].terms_id == 0) {
                            this.InvoicesList[i].termText = "Due on Receipt";
                        }
                    }
                    this.OldInvoicesList = this.InvoicesList;
                    this.isLoading = false;
                })
                .catch((error) => console.log(error));
        },
        getTermsList() {
            window.billing
                .get("terms/" + window.localuserdata)
                .then((response) => {
                    console.log("terms");
                    console.log(response.data);
                    this.TermsList = response.data;
                })
                .catch((error) => console.log(error));
        },
        actionEdit(id) {
            this.$router.push({
                name: "InvoiceRecEdit",
                params: { idinv: id, edit: "edit" },
            });
        },
        actionDuplicate(id) {
            this.$router.push({
                name: "InvoiceRecEdit",
                params: { idinv: id, duplicate: "duplicate" },
            });
        },
        MetDeleteInvoiceRec(idinv) {
            this.isLoading = true;
            this.$swal({
                title: "Delete this Recurring Invoice?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                if (result.value) {
                    window.billing
                        .put("invoicerec/delete/" + idinv, {
                            user_id: window.localuserdata,
                        })
                        .then((response) => {
                            console.log(response.data);
                            this.isLoading = false;
                            if (response.data.status) {
                                this.$swal.fire({
                                    icon: "success",
                                    title: "Deleted!",
                                    text: "Deleted Recurring Invoice Successful",
                                    showConfirmButton: false,
                                    timer: 2000,
                                });
                                this.getInvoices();
                            }
                        })
                        .catch((error) => console.log(error, (this.isLoading = false)));
                }
            });
            this.isLoading = false;
        },
    },
};
</script>
<style lang="scss" scoped>
#invListRec {
    margin-bottom: 150px;
}
@media (max-width: 991px) {
    .contenedor-estimate {
        width: 100%;
        overflow-x: scroll;
    }
}
.contenedor-estimate table {
    width: 100%;
    min-width: 767px;
}
.contenedor-estimate table thead tr th,
.contenedor-estimate table tbody tr td {
    min-width: 30px;
}
#invListRec thead tr th:nth-child(2),
#invListRec tbody tr td:nth-child(2) {
    min-width: 200px;
    width: 200px;
}
#invListRec thead tr th:nth-child(3),
#invListRec tbody tr td:nth-child(3) {
    min-width: 100px;
    width: 100px;
}
#invListRec thead tr th:nth-child(4),
#invListRec tbody tr td:nth-child(4) {
    min-width: 100px;
}
#invListRec thead tr th:nth-child(5),
#invListRec tbody tr td:nth-child(5) {
    min-width: 100px;
}
#invListRec thead tr th:nth-child(7),
#invListRec tbody tr td:nth-child(7) {
    min-width: 180px;
}
.contenedor-estimate table thead tr th {
    font-weight: bold;
}
.paginacion {
    width: 70px;
}
#invListRec thead tr th:nth-child(10),
#invListRec tbody tr td:nth-child(10) {
    min-width: 85px;
    width: 85px;
}
@media (max-width: 1100px) {
    #invListRec thead tr th:nth-child(1),
    #invListRec tbody tr td:nth-child(1) {
        min-width: 50px;
    }
    #invListRec thead tr th:nth-child(2),
    #invListRec tbody tr td:nth-child(2) {
        min-width: 150px;
        width: 150px;
    }
    #invListRec thead tr th:nth-child(3),
    #invListRec tbody tr td:nth-child(3) {
        min-width: 80px;
        width: 80px;
    }
    #invListRec thead tr th:nth-child(4),
    #invListRec tbody tr td:nth-child(4) {
        min-width: 80px;
        width: 80px;
    }
    #invListRec thead tr th:nth-child(5),
    #invListRec tbody tr td:nth-child(5) {
        min-width: 70px;
        width: 70px;
    }
    #invListRec thead tr th:nth-child(6),
    #invListRec tbody tr td:nth-child(6) {
        min-width: 90px;
        width: 90px;
    }
    #invListRec thead tr th:nth-child(7),
    #invListRec tbody tr td:nth-child(7) {
        min-width: 130px;
        width: 130px;
    }
    #invListRec thead tr th:nth-child(8),
    #invListRec tbody tr td:nth-child(8) {
        min-width: 90px;
        width: 90px;
    }
    #invListRec thead tr th:nth-child(9),
    #invListRec tbody tr td:nth-child(9) {
        min-width: 80px;
        width: 80px;
    }
    #invListRec thead tr th:nth-child(10),
    #invListRec tbody tr td:nth-child(10) {
        min-width: 50px;
        width: 50px;
    }
    #invListRec tbody tr td:nth-child(10) > .cdropdown > button {
        padding: 15px;
    }
    #invListRec tbody tr td:nth-child(10) > .cdropdown > button:before {
        right: 11px;
    }
    #invListRec tbody tr td:nth-child(10) > .cdropdown > button > span {
        display: none;
    }
    .contenedor-estimate {
        max-width: 924px;
        overflow-x: scroll;
    }
}
.dropdStatus {
    position: absolute;
    width: 130px;
    height: auto;
    background-color: #fff;
    z-index: 9;
    margin: 0;
    border: 1px solid #ccc;
    padding: 10px;
    list-style: none;
}
.dropdStatus li {
    text-align: left;
}
.dropdStatus li label {
    text-align: left;
    display: block;
}
.btn-status {
    font-size: 13px;
    letter-spacing: 0.5px;
    color: #5d5b5b !important;
    border-radius: 0;
    border: 1px solid #dcdcdc;
    background-color: #fff;
    padding: 7px;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: #e9ecef;
}
.table-striped tbody tr:nth-of-type(even) {
    background-color: #fff;
}
.contenedor-estimate tbody tr td .badge {
    width: 90%;
}
</style>
