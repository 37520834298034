<template>
    <div>
        <div class="card-header">
            <h3>Notificacions</h3>
        </div>
        <form @submit.prevent="setNoti" id="form1" class="" v-if="formNoti">
            <div class="card-body">
                <div class="bgloading" v-show="isLoading"><Loader></Loader></div>
                <div class="form-group" style="display: none;">
                    <label for="exampleFormControlInput1">Name: </label>
                    <input
                        type="text"
                        class="form-control"
                        v-model="f.name"
                    />
                </div>
                <div class="form-group">
                    <label for="exampleFormControlInput1">Email: </label>
                    <input
                        type="email"
                        class="form-control"
                        v-model="f.email"
                        required="true"
                    />
                </div>
                <div class="form-group">
                    <label for="exampleFormControlInput1">Phone: </label>
                    <input
                        type="text"
                        class="form-control"
                        v-model="f.phone"
                    />
                </div>
                <div class="form-check" style="margin-left: 11px; padding: 12px 10px">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="f.noti_email"
                        id="noti_email"
                    />
                    <label class="form-check-label" for="noti_email">
                        Receive Email Notifications
                    </label>
                </div>
                <div class="form-check" style="margin-left: 11px; padding: 12px 10px">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="f.noti_phone"
                        id="noti_phone"
                    />
                    <label class="form-check-label" for="noti_phone">
                        Receive SMS Notifications
                    </label>
                </div>
            </div>
            <div class="card-footer d-flex justify-content-end">
                <button type="button" class="btn btn-secondary btn-sm mr-2"  @click="cancel()">Cancel</button>
                <button type="submit" class="btn btn-success btn-sm">SAVE</button>
            </div>
        </form>
        <div class="card-body" v-else>
          <button type="button" class="btn btn-primary" @click="add()">Add</button>
            <table class="table table-bordered table-inverse table-hover">
              <thead>
                <tr>
                  <th width="120px">Receive Email</th>
                  <th>Email</th>
                  <th width="120px">Receive Phone</th>
                  <th>Phone</th>
                  <th width="90px">Actions</th>
                </tr>
              </thead>
              <tbody v-if="isLoading">
                <tr>
                  <td colspan="35" align="center"><Loader></Loader></td>
                </tr>
              </tbody>
              <tbody v-else-if="listNoti.length == 0">
                <tr>
                  <td colspan="3" align="center">No record</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr v-for="(i, index) in listNoti" :key="index">
                  <td>
                    <div class="onoffswitch">
                      <input
                        :id="'receiveEmail' + i.id"
                        type="checkbox"
                        v-model="i.noti_email"
                        :checked="(i.noti_email)"
                        class="onoffswitch-checkbox"
                        @input="editReceiveEmail($event, i)"
                      />
                      <label class="onoffswitch-label" :for="'receiveEmail' + i.id">
                        <span class="onoffswitch-inner"></span>
                        <span class="onoffswitch-switch"></span>
                      </label>
                    </div>
                  </td>
                  <td>{{i.email}}</td>
                  <td>
                    
                    <div class="onoffswitch">
                      <input
                        :id="'receivePhone' + i.id"
                        type="checkbox"
                        v-model="i.noti_phone"
                        :checked="(i.noti_phone)"
                        class="onoffswitch-checkbox"
                        @input="editReceivePhone($event, i)"
                      />
                      <label class="onoffswitch-label" :for="'receivePhone' + i.id">
                        <span class="onoffswitch-inner"></span>
                        <span class="onoffswitch-switch"></span>
                      </label>
                    </div>
                  </td>
                  <td>{{i.phone}}</td>
                  <td width="90px">
                    <button
                      class="btn btn-info btn-sm mr-2"
                      @click="editNoti(i)"
                    >
                      <i class="fas fa-edit"></i>
                    </button>
                    <button
                      class="btn btn-danger btn-sm mr-2"
                      @click="DelNoti(i)"
                    >
                      <i class="fas fa-times"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import Loader from "@/core/components/shared/Loader.vue";
export default {
  name: 'RoofCalNoti',
  components: {
    Loader,
  },
  data() {
    return {
      user_id: window.master_client,
      org_id: window.localorgdata,
      formNoti: false,
      isLoading: true,
      f: { 
        user_id: window.master_client,
        org_id: window.localorgdata,
        noti_email: '',
        noti_phone: '',
        name: '',
        email: '',
        phone: ''
      },
      oldEdit: {
        user_id: window.master_client,
        org_id: window.localorgdata,
        noti_email: '',
        noti_phone: '',
        name: '',
        email: '',
        phone: ''
      },
      listNoti: [],
      apis: {},
    };
  },
  created() {
    let urlApis = this.$store.getters['ModApps/RoofCal/urlApis'];
    this.apis = urlApis.setup;
    this.getNoti();
  },
  methods: {
    editReceiveEmail($event, i) {
      if ($event.target.checked == true) {
        i.noti_email = 1;
      } else {
        i.noti_email = 0;
      }
      this.f=i;
      this.setNoti();
    },
    editReceivePhone($event, i) {
      if ($event.target.checked == true) {
          i.noti_phone = 1;
      } else {
          i.noti_phone = 0;
      }
      this.f=i;
      this.setNoti();
    },
    getNoti(){
      let t = this;
      t.isLoading = true;
      window.master.post(this.apis.getNoti(), {
        user_id: window.master_client,
        org_id: window.localorgdata,
      })
      .then((response) => {
        t.listNoti = response.data.r;
        t.isLoading = false;
        t.formNoti = false;
        if(t.listNoti.length == 0){
          t.formNoti = true;
        }
      })
      .catch((error) => {
        console.log(error);
        t.isLoading = false;
      });
    },
    editNoti(i){
      this.formNoti =true;
      this.f.id = i.id;
      this.f.name = i.name;
      this.f.email = i.email;
      this.f.phone = i.phone;
      this.f.noti_email = i.noti_email;
      this.f.noti_phone = i.noti_phone;

      this.oldEdit.id = i.id;
      this.oldEdit.name = i.name;
      this.oldEdit.email = i.email;
      this.oldEdit.phone = i.phone;
      this.oldEdit.noti_email = i.noti_email;
      this.oldEdit.noti_phone = i.noti_phone;
    },
    setNoti(){
      let t = this;
      t.isLoading = true;
      this.f.noti_email = (this.f.noti_email) ? 1 : 0;
      this.f.noti_phone = (this.f.noti_phone) ? 1 : 0;
      window.master.post(this.apis.setNoti(), t.f)
      .then((response) => {
        t.getNoti();
      })
      .catch((error) => {
        console.log(error);
        t.isLoading = false;
      });
    },
    DelNoti(i){      
      let t = this;      
      t.$swal({
        title: "Delete Item?",
        text: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      }).then((result) => {
        if (result.value) {     
          t.isLoading = true;
          window.master.post(this.apis.delNoti(), i)
          .then((response) => {
            t.getNoti();
          })
          .catch((error) => {
            console.log(error);
            t.isLoading = false;
          });
        }
      });            
    },
    add(){
      this.formNoti = true;
      this.resetForm();
    },
    resetForm(){
      console.log('list', this.listNoti)
      this.f.id = 0;
      this.f.noti_email = '';
      this.f.noti_phone = '';
      this.f.name = '';
      this.f.email = '';
      this.f.phone = '';

      this.oldEdit.id = 0;
      this.oldEdit.noti_email = '';
      this.oldEdit.noti_phone = '';
      this.oldEdit.name = '';
      this.oldEdit.email = '';
      this.oldEdit.phone = '';
      console.log('list', this.listNoti)
    },
    cancel(){      
      this.formNoti = false;
    },
  }

};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.onoffswitch {
    position: relative;
    width: 60px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
.onoffswitch-checkbox {
    display: none;
}
.onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid #585858;
    border-radius: 20px;
}
.onoffswitch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.1s ease-in 0s;
}
.onoffswitch-inner:before,
.onoffswitch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 20px;
    padding: 0;
    line-height: 20px;
    font-size: 12px;
    color: white;
    font-family: Trebuchet, Arial, sans-serif;
    font-weight: bold;
    box-sizing: border-box;
}
.onoffswitch-inner:before {
    content: "On";
    padding-right: 30px;
    padding-left: 10px;
    background-color: #5ac146;
    color: #ffffff;
}
.onoffswitch-inner:after {
    content: "Off";
    padding-right: 10px;
    background-color: #d65353;
    color: #fff;
    text-align: right;
}
.onoffswitch-switch {
    display: block;
    width: 15px;
    height: 15px;
    margin: 6px;
    background: #fff;
    position: absolute;
    top: -3px;
    bottom: 0;
    right: 33px;
    border: 1px solid #585858;
    border-radius: 20px;
    transition: all 0.1s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px;
}

</style>
