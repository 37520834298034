<template>
	<div>
		<div class="card-header text-center" style="font-size: 20px;">
                INSTALL
        </div>
		<form @submit.prevent="InstallApps" class="card-body text-center">
			<div class="media">
				<div class="media-body">
					<img class="iconapp" alt="billing" v-if="app_id==1" src="@/core/assets/img/icon_billing.svg">
					<img class="iconapp" alt="sites" v-else-if="app_id==2" src="@/core/assets/img/icon_sites.svg">
					<img class="iconapp" alt="project" v-else-if="app_id==3" src="@/core/assets/img/icon_project.svg">
					<img class="iconapp" alt="jobs" v-else-if="app_id==4" src="@/core/assets/img/icon_jobs.svg">
					<img class="iconapp" alt="calculator" v-else-if="app_id==5"  src="@/core/assets/img/icon_calculator.svg">
					<img class="iconapp" alt="slabs" v-else-if="app_id==7"  src="@/core/assets/img/icon_slabs.svg">
					<img class="iconapp" alt="marketea" v-else-if="app_id==8"  src="@/core/assets/img/icon_bsite_white.svg">
					<img class="iconapp" alt="display" v-else-if="app_id==10"  src="@/core/assets/img/icon_display.svg">
					<img class="iconapp" alt="dimenu" v-else-if="app_id==11"  src="@/core/assets/img/icon_dimenu.svg">
					<img class="iconapp" alt="Marketea" v-else src="@/core/assets/img/icon_auxiliar.svg">
					<h5 class="titulo_install" v-if="app_id==1">Marketea {{name}}</h5>
					<h5 class="titulo_install" v-else>{{name}}</h5>
					<p>{{description}} 
						<a target="_blank" href="https://marketea.com/terms-of-use">Terms of Use.</a>
					</p><br><br>
				</div>
			</div>
			<div class="form-row">
				<div class="col-4"></div>
				<div class="col-4">
					<select class="form-control" id="exampleFormControlSelect1" v-model="org_id" style="display:none;" v-if="emailVerify">
						<option :value="i.id" v-for="(i, index) in organizationslist" :key="index">{{i.bill_company_name}}</option>
					</select>
					<button type="submit" v-if="emailVerify" class="btn btn-success" style="width: 100%;">
						AGREE & INSTALL
					</button>
				</div>
				<div class="col" style="display:none;">
					<button type="button" class="btn btn-secondary" @click="closeModal" style="width: 100%;">
						<i class="fas fa-times"></i> CANCEL
					</button>
				</div>
			</div>
		</form>
	</div>
</template>
<script>
export default {
	name: 'InstallApps',
	props: {
		dataApp: {
			type: [Object],
			default: () => {}
		}
	},
	data () {
		return {
			org_id: window.localorgdata,
			app_id: 0,
			name: '',
			description: '',
			emailVerify: false
		}
	},
	created() {
		this.organizationslist = this.$store.getters['core/apps/applistorgs'];
		this.emailVerify = this.$store.getters['core/session/getEmailVerify'];
		this.app_id = this.dataApp.app_id;
		this.name = this.dataApp.name;
		this.description = this.dataApp.description;
	},
	methods: {
		closeModal() {
			this.$store.commit("core/setCloseModal", false);
		},
		InstallApps() {
			this.$store.commit("core/setLoadingModal", true);
			window.master.put('assignapp/add/' + window.master_client, {
				user_id : window.master_client,
				org_id: this.org_id,
				app_id: this.app_id,
			}).then((response) => {
				this.$store.commit("core/setCloseModal", false);
				this.$store.dispatch('core/apps/ListAppsbyOrg', window.localorgdata)
				.then((response) => {
					localStorage.removeItem('getDataSession');
					this.$store.dispatch("core/session/getDataSessionLogin")
					.then((response) => {
						window.location.reload(true);
					});					
				});
			}).catch((error) => {
				console.log(error);
				this.$store.commit("core/setLoadingModal", false);
			});
		},
	}
};
</script>
<style lang="css" scoped>
h5.titulo_install{
	margin-top: 10px;
	font-weight:bold;
	font-size: 24px;
}
.media-body p {
	font-size: 19px;
	padding: 10px 60px;
}
.iconapp{
	width: 150px;
	height:auto;
}
</style>
