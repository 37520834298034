let addAds = function() {
	let n = [{
		id: 0,
		user_id: window.master_client,
		org_id :window.localorgdata,
		date: (new Date().toISOString() + "").slice(0, 11) + new Date().toTimeString().slice(0, 8)+'.000-05:00',
		dateFormat: '',
		id_marksource: 0,
		id_ctachannel: 0,
		id_campaign: 0,
		id_groupname: 0,
		leadstatus: 0,
		leadscore: 0,
		optionsGroupName: [],
		new: true,
	}];
	let add = n.concat(this.dataT);
	this.dataT = add;
	let t =this;
	t.savedAdd = true;
	// console.log('savedAdd', t.savedAdd)
	window.master.post(t.apis.add(), n[0])
	.then((response) => {
		if(response.data.status) {
			for (let i in t.dataT) {
				if(t.dataT[i] && t.dataT[i].id == 0) {
					t.dataT[i].id = response.data.r.id;
					t.dataT[i].new = false;
				}
			}
			t.dataTOLD = t.fillInData(t.dataT);
			t.savedAdd = false;
			t.selectAll=false;
			t.getAds();
			// console.log('savedAdd', t.savedAdd)
		}
	})
	.catch((error) => {
		console.log(error);
		t.savedAdd = false;
	});
};
export default addAds;
