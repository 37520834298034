<template>
    <div class="container-fluid">
        <div class="bgloading" v-if="isLoading"><Loader :getProgress="progress"></Loader></div>
        <div class="row" v-else>
            <div class="col-md-12">
                <div class="container">
                    <form @submit.prevent="form">
                        <div class="card" style="margin-top: 10px">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-5">
                                        <h3 class="tituloJobs">Form Display</h3>
                                    </div>
                                    <div class="col-7 text-right">
                                        <button
                                            type="submit"
                                            class="btn btn-success"
                                            :disabled="btnSave"
                                        >
                                            <i class="fas fa-save"></i> SAVE
                                        </button>
                                        <button
                                            type="button"
                                            @click="cancel"
                                            class="btn btn-light"
                                            style="margin-left: 10px"
                                        >
                                            <i class="fas fa-times"></i> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <label>Template:</label>
                                                    <model-select
                                                        :options="TypeTemplates"
                                                        v-model="f.TypeTemplates"
                                                        @input="handleOnInput"
                                                        v-if="(!$route.params.edit)"
                                                    ></model-select>
                                                    <div v-else class="select-inactivo">{{f.TypeTemplates.text}}</div>
                                                </div>
                                                <div class="col-md-6">
                                                    <label>Name:</label>
                                                    <textarea
                                                        v-model="f.name"
                                                        class="form-control"
                                                        rows="2"
                                                        style="
                                                            overflow: hidden;
                                                            max-height: 51px;
                                                        "
                                                        :maxlength="50"
                                                        v-on:keypress.enter="validarEnter"
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <label>Start Date:</label>
                                                    <datetime
                                                        required
                                                        type="datetime"
                                                        v-model="f.dpublis"
                                                        :format="{
                                                            year: 'numeric',
                                                            month: 'short',
                                                            day: 'numeric',
                                                            hour: 'numeric',
                                                            minute: '2-digit',
                                                        }"
                                                        use12-hour
                                                        value-zone="America/New_York"
                                                        input-class="form-control jobdate"
                                                    ></datetime>
                                                </div>
                                                <div class="col-md-6">
                                                    <label>End Date:</label>
                                                    <datetime
                                                        type="datetime"
                                                        v-model="f.dfinish"
                                                        :format="{
                                                            year: 'numeric',
                                                            month: 'short',
                                                            day: 'numeric',
                                                            hour: 'numeric',
                                                            minute: '2-digit',
                                                        }"
                                                        use12-hour
                                                        value-zone="America/New_York"
                                                        input-class="form-control jobdate"
                                                    ></datetime>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div
                                                                class="input-group-text url-text"
                                                            >
                                                                {{
                                                                    urlDisplay +
                                                                    org_id +
                                                                    "/"
                                                                }}
                                                            </div>
                                                        </div>
                                                        <input
                                                            type="text"
                                                            class="form-control url-input"
                                                            v-model="f.url"
                                                            placeholder="url-name"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <label>Security pin:</label>
                                                    <input
                                                        type="number"
                                                        class="form-control"
                                                        v-model="f.pin"
                                                        min="0"
                                                        max="999999"
                                                        maxlength="6"
                                                    />
                                                </div>
                                                <div class="col-md-6">
                                                    <label>Status:</label>
                                                    <select
                                                        class="form-control"
                                                        v-model="f.status"
                                                    >
                                                        <option value="1">Active</option>
                                                        <option value="0">
                                                            Inactive
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="form-group"
                                            v-if="orientation === false && f.TypeTemplates.value==1"
                                        >
                                            <label>Orientation:</label>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="flex-container">
                                                        <div>
                                                            <label
                                                                ><input
                                                                    type="radio"
                                                                    class="horisontal"
                                                                    v-model="
                                                                        f.orientation
                                                                    "
                                                                    :value="1"
                                                                    :checked="
                                                                        f.orientation === 1
                                                                    "
                                                                    style="
                                                                        margin-left: 8px;
                                                                        cursor: pointer;
                                                                    "
                                                            /></label>
                                                        </div>
                                                        <div>
                                                            <label
                                                                ><input
                                                                    type="radio"
                                                                    class="vertical"
                                                                    v-model="
                                                                        f.orientation
                                                                    "
                                                                    :value="2"
                                                                    :checked="
                                                                        f.orientation === 2
                                                                    "
                                                                    style="
                                                                        margin-left: 8px;
                                                                        cursor: pointer;
                                                                    "
                                                            /></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group" v-if="f.TypeTemplates.value==3">
                                            <div class="row">
                                                <!-- <div class="col-md-6">
                                                    <label>Direction of transition:</label>
                                                    <div class="input-group">
                                                        <select
                                                            class="form-control"
                                                            v-model="f.direction"
                                                        >
                                                            <option value="horizontal">Left & Right</option>
                                                            <option value="vertical">Up & Down</option>
                                                        </select>

                                                        <div class="input-group-prepend prevDir">
                                                            <i class="fas fa-arrow-left" v-if="f.direction == 'horizontal'"></i>
                                                            <i class="fas fa-arrow-right" v-if="f.direction == 'horizontal'"></i>
                                                            <i class="fas fa-arrow-up" v-if="f.direction == 'vertical'"></i>
                                                            <i class="fas fa-arrow-down" v-if="f.direction == 'vertical'"></i>
                                                        </div>
                                                    </div>                
                                                </div> -->
                                                <div class="col-md-6">
                                                    <label>Duration of transition (Speed):</label>
                                                    <select
                                                        class="form-control"
                                                        v-model="f.speed"
                                                    >
                                                        <option value="5">5 Seconds</option>
                                                        <option value="6">6 Seconds</option>
                                                        <option value="7">7 Seconds</option>
                                                        <option value="8">8 Seconds</option>
                                                        <option value="9">9 Seconds</option>
                                                        <option value="10">10 Seconds</option>
                                                        <option value="11">11 Seconds</option>
                                                        <option value="12">12 Seconds</option>
                                                        <option value="13">13 Seconds</option>
                                                        <option value="14">14 Seconds</option>
                                                        <option value="15">15 Seconds</option>
                                                        <option value="16">16 Seconds</option>
                                                        <option value="17">17 Seconds</option>
                                                        <option value="18">18 Seconds</option>
                                                        <option value="19">19 Seconds</option>
                                                        <option value="20">20 Seconds</option>
                                                    </select>
                                                </div>
                                                <div class="col-md-6">
                                                    <label>Transition effect:</label>
                                                    <select
                                                        class="form-control"
                                                        v-model="f.transition"
                                                    >
                                                        <option value="slide">Slide</option>
                                                        <option value="fade">Fade</option>
                                                        <option value="cube">Cube</option>
                                                        <option value="coverflow">Coverflow</option>
                                                        <option value="flip">Flip</option>
                                                    </select>
                                                </div>
                                                <!-- <div class="prevSlider">
                                                    <swiper
                                                        style="width: 100%; max-width: 480px; height: 270px;"
                                                        class="swiper" 
                                                        :options="swiperOption"
                                                        v-if="showPreviewSlider"
                                                    >
                                                        <swiper-slide 
                                                            v-for="(i, index) in previewSliderLoad" 
                                                            :key="index"
                                                        >
                                                            <img
                                                                :src="(previewSlider[index]) ? previewSlider[index].src : '#' "
                                                                class="img-responsive"
                                                            />
                                                        </swiper-slide>
                                                    </swiper>
                                                    <div
                                                        v-else
                                                        style="width: 100%; max-width: 480px; height: 270px;"
                                                    >
                                                        <img
                                                            v-if="showPreviewSlider==false" 
                                                            src="@/core/assets/img/no-image480x270.png" 
                                                            alt="no-img" 
                                                            class="img-responsive"
                                                        >
                                                    </div>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group" v-if="f.TypeTemplates.value == 1 || f.TypeTemplates.value == 2">
                                            <div
                                                v-if="Showimg1"
                                                style="height: auto; text-align: center"
                                            >
                                                <img
                                                    v-if="orientation == false"
                                                    v-bind:src="preview1"
                                                    id="img1"
                                                    v-bind:class="[
                                                        'img-responsive',
                                                        f.orientation === 1
                                                            ? 'imgH'
                                                            : 'imgV',
                                                    ]"
                                                />
                                                <img
                                                    v-else
                                                    v-bind:class="[
                                                        'img-responsive',
                                                        f.orientation === 1
                                                            ? 'imgH'
                                                            : 'imgV',
                                                    ]"                                                      
                                                    src="@/core/assets/img/poster1.jpg"
                                                /> 
                                            </div>
                                            <div
                                                v-show="!Showimg1"
                                                class="file-drag-drop"
                                                ref="Drop1"
                                                @click="$refs.img1.click()"
                                            >
                                                <span class="drop-files"
                                                    >Drag and drop the image here!</span
                                                >
                                            </div>
                                            <div v-if="Showimg1">
                                                <button
                                                    type="button"
                                                    class="btn btn-danger bntFileV"
                                                    @click="delImg1"
                                                    v-if="f.orientation === 2"
                                                >
                                                    <i class="fa fa-trash"></i> Delete
                                                    upload
                                                </button>
                                                <button
                                                    type="button"
                                                    class="btn btn-danger bntFileH"
                                                    @click="delImg1"
                                                    v-else
                                                >
                                                    <i class="fa fa-trash"></i> Delete
                                                    upload
                                                </button>
                                            </div>
                                            <div v-else>
                                                <button
                                                    type="button"
                                                    class="btn btn-info bntFile"
                                                    @click="$refs.img1.click()"
                                                >
                                                    <i class="fas fa-upload"></i> Upload
                                                </button>
                                            </div>
                                            <input
                                                type="file"
                                                style="display: none"
                                                ref="img1"
                                                accept="image/*,video/mp4"
                                                @change="fileImg1"
                                            />
                                        </div>
                                        <div v-if="f.TypeTemplates.value == 3" class="form-group">
                                            <div class="listfiles row" v-if="previewSlider.length>0">
                                                <div v-for="(i, index) in previewSliderLoad" :key="index" class="col-sm-6" :data-item="index" >
                                                    <div class="img-thumbnail itemImg" :data-name="previewSliderLoad[index].file">
                                                        <img 
                                                            :src="(previewSlider[index]) ? previewSlider[index].src : '#' " 
                                                            :alt="previewSliderLoad[index].file" 
                                                            v-bind:class="[
                                                                'img-responsive  img-files',
                                                            ]"
                                                            v-if="previewSliderLoad[index].load"
                                                        >
                                                        <div v-else class="img-responsive img-files" style="height: 135px; width: 100%;"><Loader :getProgress="progress"></Loader></div>
                                                    </div>
                                                    <div class="btnsImgDel">
                                                        <button
                                                            type="button"
                                                            class="btn btn-danger bntFileH"
                                                            @click="delImgslider(index)"
                                                            :disabled="!previewSliderLoad[index].load"
                                                        >
                                                            <span class="badge badge-pill badge-light">{{previewSliderLoad[index].file}}</span>
                                                            <i class="fa fa-trash"></i> 
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-show="uploadLoading" class="row" ><Loader class="col-sm-12 text-center" :getProgress="progress"></Loader></div>
                                            <div class="btnsImg" style="margin-top: 20px;">
                                                <button type="button" class="btn btn-info bntFile" @click="$refs.imgslider.click()">
                                                    <i class="fas fa-plus"></i> Add Image
                                                </button>
                                                <button v-if="previewSlider.length>1" type="button" class="btn btn-danger bntFile" @click="delAllslider()">
                                                    <i class="fas fa-trash"></i> Delete All
                                                </button>
                                            </div>
                                            <input
                                                type="file"
                                                style="display: none"
                                                ref="imgslider"
                                                accept="image/*"
                                                @change="addSlider"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <input type="hidden" v-model="f.id" />
                    </form>
                </div>
            </div>
        </div>
        <notifications group="noti" position="top center"></notifications>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader.vue";
import { mapGetters } from "vuex";
import { ModelSelect } from "vue-search-select";
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
// import 'swiper/css/swiper.css'
export default {
    name: "DashDisplayForm",
    components: {
        Loader,
        ModelSelect,
        // Swiper,
        // SwiperSlide,
    },
    data() {
        return {
            preview1: "",
            Showimg1: false,
            isLoading: false,
            btnSave: false,
            urlDisplay: window.urlDisplay,
            user_id: window.master_client,
            org_id: window.localorgdata,
            type: window.user_type,
            user_id_assign: window.localAccountID,
            TypeTemplates: [{value: 1, text:'Imagen'}],
            f: {
                id: 0,
                TypeTemplates: {value: 1, text:'Imagen'},
                name: "",
                dpublis: new Date().toISOString().slice(0, 10),
                dfinish: "",
                status: 1,
                pin: "",
                url: "",
                orientation: 1,
                archivo: null,
                archivoOld: null,
                img1: null,
                type: window.user_type,
                transition: 'slide',
                speed: 5,
                direction: 'horizontal',
            },
            name_old: "",
            urls_videoPoster: "",
            orientation: false,
            progress: 0,
            thumbs: '',
            filesSlider: [],
            filesSliderDel: [],
            filesSliderOld: [],
            previewSlider: [],
            previewSliderLoad: [],
            uploadSlider: true,
            uploadCount: 0,
            uploadimgen: 0,
            deleteIMG: [],
            uploadLoading: false,
            swiperOption: {
                slidesPerView: 1,
                spaceBetween: 30,
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                direction: 'horizontal', // horizontal | vertical
                effect: 'slide', // slide | fade | cube | flip | coverflow
                speed: 1000, // tiempo de transicion
                autoplay: {delay: 5000, disableOnInteraction: false}, // auto arranque
                coverflowEffect: {
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows : true,
                },
                cubeEffect: {shadow: true, slideShadows: true, shadowOffset: 20, shadowScale: 0.94},
            },
            showPreviewSlider: null,

        };
    },
    created() {
        //prueba preload
        // this.isLoading=true;
        // let t = this;
        // setTimeout(function(){ t.isLoading=false;}, 1000);
        // this.pinSecurity();
        this.getPoster();
        this.getTypeTemplates().then((r) => {
            if (this.$route.params.id != undefined && this.$route.params.edit != undefined) {
                this.getDisplayInfoByID();
            }else{
                this.isLoading = false;
            }
        });
    },
    mounted(){
        this.dragAndDropCapable = this.determineDragAndDropCapable();
        if (this.dragAndDropCapable) {
            let eventosDragDrop = [
                "drag",
                "dragstart",
                "dragend",
                "dragover",
                "dragenter",
                "dragleave",
                "drop",
            ];
            let t = this;
            if(t.$refs.Drop1){
                eventosDragDrop.forEach(function (evt) {
                    t.$refs.Drop1.addEventListener(evt, function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                    });
                });
                t.$refs.Drop1.addEventListener("drop", function (e) {
                    t.$refs.img1 = e.dataTransfer.files[0];
                    t.fileImg1();
                });
            };
        }
    },
    methods: {   
        sliderOption(){
            let t = this;
            t.swiperOption.direction = t.f.direction;
            t.swiperOption.effect = t.f.transition;
            t.swiperOption.autoplay.delay = (t.f.speed*1000);
            t.showPreviewSlider = false;
            if(t.previewSliderLoad.length>0){
                setTimeout(function(){
                    t.showPreviewSlider = (t.previewSliderLoad.length>0) ? true : null;
                }, 500);
            }
            else{t.showPreviewSlider =null;}
        },
        delAllslider(){
            let t = this;
            t.$swal({
                title: "Delete all images?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                if (result.value) {
                    t.filesSlider=[];
                    t.previewSlider=[];
                    t.previewSliderLoad=[];
                    t.$refs.imgslider.value =null;
                    t.uploadLoading=true;
                    let url = "/display/delSliderFiles";
                    window.master.post(url, {
                        org_id: t.org_id,
                        user_id: t.user_id,
                        id: t.f.id,
                    })
                    .then((response) => {
                        console.log(response.data);
                        t.uploadLoading=false;
                    })
                    .catch((error) => {
                        console.log(error);
                        t.uploadLoading=false;
                    });
                }
            });
        },   
        delImgslider(i){
            let t = this;
            t.$swal({
                title: "Delete image?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                if (result.value) {                    
                    t.filesSliderDel = [];
                    if(t.filesSliderOld.indexOf(t.filesSlider[i].name) > -1){
                        t.filesSliderDel.push({name: t.filesSlider[i].name});
                    }
                    t.filesSlider.splice(i, 1);
                    t.previewSlider.splice(i, 1);
                    t.previewSliderLoad.splice(i, 1);
                    t.$refs.imgslider.value =null;
                    if(t.filesSliderDel.length>0){
                        t.uploadLoading=true;
                        let url = "/display/delSliderFiles";
                        window.master.post(url, {
                            org_id: t.org_id,
                            user_id: t.user_id,
                            id: t.f.id,
                            deletes: t.filesSliderDel,
                        })
                        .then((response) => {
                            console.log(response.data);
                            t.uploadLoading=false;
                        })
                        .catch((error) => {
                            console.log(error);
                            t.uploadLoading=false;
                        });
                    }
                }
            });
        },    
        addSlider(event) {
            // maximo 100 megas
            let t = this;
            console.log(event.target.files)
            if(event.target.files.length > 0){
                let listFiles = event.target.files;
                t.uploadCount=0;
                if(t.previewSliderLoad.length == 0){
                    t.uploadLoading=true;
                }
                for (let i in listFiles) {
                    if(listFiles.hasOwnProperty(i)){
                        if (listFiles[i].size > 104857600) {
                            t.$store.commit("core/setNotiPush", {
                                group: "notiPush",
                                type: '5', // 1=success | 2=info | 3=error | 4=loading | 5=warning
                                title: "Error",
                                text: "The file "+listFiles[i].name+" exceeds 100 megabytes",
                                show: true,
                                duration: 10000
                            });
                            event.target.files.splice(i, 1);
                        }
                        else if (typeof listFiles[i] == 'object') {
                            // debugger;
                            t.filesSlider.push(listFiles[i]);
                            t.uploadCount++;
                        }
                    }
                }
                if(t.uploadCount > 0){
                    t.uploadSlider=true;                
                    t.uploadimgen = 0;
                    t.loadImgen();
                }
            }
        },
        loadImgen(){
            let t =this;
            let fileItem = t.filesSlider[t.uploadimgen];
            if(t.uploadSlider===true && t.filesSlider.hasOwnProperty(t.uploadimgen))
            {
                let isFileExist = false;
                for (let i in t.previewSlider) {
                    if(t.previewSlider.hasOwnProperty(i)){
                        if(t.previewSlider[i].name == fileItem.name){
                            isFileExist = true;
                        }
                    }
                }
                if(isFileExist) {
                    // console.log('item ya existe', fileItem.name, 'pasar al siguiente');
                    t.uploadimgen++;
                    if(t.uploadimgen < t.uploadCount){
                        // console.log('aunq ya existe, seguir corriendo loadImgen');
                        t.uploadSlider = true;
                    }
                    t.loadImgen();
                }
                else
                {
                    // console.log('img iteracion', t.uploadimgen)
                    t.uploadSlider = false;
                    let reader = new FileReader();
                    reader.readAsDataURL(fileItem);
                    t.previewSliderLoad.push({file: fileItem.name, load: false, src: null});
                    // console.log('add previewSliderLoad', t.previewSliderLoad);
                    // console.log('add previewSlider', t.previewSlider.length-1);
                    reader.onload = function(e){
                        for (let l in t.previewSliderLoad) {
                            if(!t.previewSliderLoad[l].load){ 
                                t.previewSliderLoad[l].load = true; 
                                t.previewSliderLoad[l].src = e.target.result; 
                                // console.log('edit previewSliderLoad', t.previewSliderLoad);
                                // console.log('edit previewSlider', l);
                                // console.log('add previewSliderLoad ', t.previewSliderLoad[l].src.substr(0, 100));
                                let img = document.createElement('img');
                                img.setAttribute('alt', t.filesSlider[l].name+'-oculto'); 
                                img.setAttribute('ref', t.filesSlider[l].name); 
                                img.setAttribute('position', l); 
                                img.style.display = 'none'; 
                                img.src = e.target.result;
                                document.body.appendChild(img);
                                img.onload = function(e){
                                    let nameOculto = img.getAttribute('alt');
                                    let nameoriginal = img.getAttribute('ref');
                                    let p = img.getAttribute('position');
                                    console.log(nameOculto, p, img.width + "x" + img.height);
                                    if(img.width < 1920 || img.height < 1080){
                                        t.$store.commit("core/setNotiPush", {
                                            group: "notiPush",
                                            type: '5', // 1=success | 2=info | 3=error | 4=loading | 5=warning
                                            title: "Error",
                                            text: "The file "+nameoriginal+" must have a minimum dimension of 1920 X 1080 pixels.",
                                            show: true,
                                            duration: 10000
                                        });
                                        t.deleteIMG.push({p: p, name: nameoriginal});
                                    }
                                    document.querySelector("[alt='"+nameOculto+"']").remove();
                                    img = undefined;
                                    t.uploadimgen++;
                                    if(t.uploadimgen < t.uploadCount){
                                        // console.log('seguir corriendo loadImgen');
                                        t.uploadSlider = true;
                                    }
                                    t.loadImgen();
                                }
                                break;
                            }
                        }
                    };
                    reader.onloadstart = function(e) {
                        // console.log('onloadstart', e.target);
                        t.progress =0;
                    };
                    reader.onprogress = function(e) {
                        if (e.lengthComputable) {
                            let progress= Number((e.loaded / e.total) * 100);
                            // console.log('onprogress', progress);
                            t.progress = progress;
                        }
                    };
                    reader.onloadend = function(e) {
                        // console.log('onloadend', e.target);
                        t.progress =100;
                    };
                    reader.onerror = function () {
                        throw new Error("There was an issue reading the file." + reader.error);
                    };
                }
            }
            else{
                // console.log('load img', t.previewSliderLoad[0].src.substr(0, 100));
                // console.log('validar imagenes q no cumplan validaciones de dimensiones 1920x1080');
                t.rectificarListSlider();
            }
        },
        rectificarListSlider(){
            let t = this;
            // console.log('imgs a eliminar', t.deleteIMG);
            // let orderMayorMenor = t.deleteIMG.sort(function(a, b){return b - a});
            let newFiles = [];
            let newPreviews = [];
            if(t.deleteIMG.length>0){
                for (let j in t.filesSlider) {
                    if(t.filesSlider.hasOwnProperty(j)){
                        let del = false;
                        for (let i in t.deleteIMG) {
                            if(t.deleteIMG.hasOwnProperty(i)){
                                if(t.filesSlider[j].name == t.deleteIMG[i].name){
                                    // console.log('item a eliminar', t.deleteIMG[i].name);
                                    del = true;
                                }
                            }
                        }
                        if(!del && t.filesSlider[j].name){
                            newFiles.push(t.filesSlider[j]);
                            // console.log('add previewSlider', t.previewSliderLoad[j].src.substr(0, 100));
                            newPreviews.push({name: t.filesSlider[j].name, src: t.previewSliderLoad[j].src});
                            // debugger;
                        }
                    }
                }
                t.filesSlider = newFiles;
                t.previewSlider = newPreviews;
            }
            else{
                // si todo esta bien entonces solo agregar previews
                for (let k in t.filesSlider) {
                    if(t.filesSlider.hasOwnProperty(k)){
                        let isFileExist = false;
                        for (let i in t.previewSlider) {
                            if(t.previewSlider.hasOwnProperty(i)){
                                if(t.previewSlider[i].name == t.filesSlider[k].name){
                                    isFileExist = true;
                                }
                            }
                        }
                        if(!isFileExist){
                            // console.log('add previewSlider', t.filesSlider[k]);
                            t.previewSlider.push({name: t.filesSlider[k].name, src: t.previewSliderLoad[k].src});
                        }
                    }
                }
            }
            t.deleteIMG = []; // reset
            // console.log('termino de correr');
            // console.log('rectificando lista de previewSliderLoad', t.previewSlider)
            t.previewSliderLoad=[];
            for (let l in t.previewSlider) {
                if(t.previewSlider.hasOwnProperty(l)){
                    // console.log('rectificando previewSliderLoad', t.previewSlider[l]);
                    let lineNew = {file: t.previewSlider[l].name, load: true, src: t.previewSlider[l].src};
                    t.previewSliderLoad.push(lineNew);
                }
            }
            t.uploadLoading=false;
            t.$refs.imgslider.value =null;
            // console.log('filesSlider sin eliminados', t.filesSlider);
            // console.log('previewSlider sin eliminados', t.previewSlider);
            // console.log('previewSliderLoad sin eliminados', t.previewSliderLoad);
        },
        determineDragAndDropCapable() {
            var div = document.createElement("div");
            return (
                ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) &&
                "FormData" in window &&
                "FileReader" in window
            );
        },
        getPoster() {
            let url = "/display/poster/" + this.org_id + "/" + this.user_id + "/0";
            window.master
                .get(url)
                .then((response) => {
                    let urls = response.data;
                    this.urls_videoPoster = urls.poster;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        handleOnInput($event) {
            this.delImg1()
            if ($event.value == 1 || $event.value == 3) {
                this.orientation = false;
            } else {
                this.orientation = true;
            }
        },
        validarEnter(event) {
            event.preventDefault();
            let tecla = document.all ? event.keyCode : event.which;
            console.log("anulando enter " + tecla);
        },
        getDisplayInfoByID() {
            let t = this;
            t.isLoading = true;
            t.f.id = t.$route.params.id;
            let url = "/display/getdisplaydash";
            window.master
                .post(url, {
                    user_id_assign: t.user_id_assign,
                    org_id: t.org_id,
                    user_id: t.user_id,
                    type: t.type,
                    id: t.f.id,
                })
                .then((response) => {
                    // console.log(response.data.list[0]);
                    let r = response.data.list[0];
                    let urls = response.data.urls;
                    let opS = response.data.optSlider;
                    t.f.id = r.id;
                    t.f.name = r.name;
                    t.name_old = r.name;
                    t.f.pin = r.pin;
                    t.f.dpublis = r.f_p;
                    t.f.dfinish = r.f_t;
                    t.f.status = r.status;
                    t.f.url = r.url;
                    t.f.orientation = r.orientacion;
                    if(r.name_archivos.length >0){
                        t.f.archivo = r.name_archivos[0].name_archivos;
                        t.f.archivoOld = r.name_archivos[0].name_archivos;
                    }
                    if(opS.toString().length > 0){
                        t.f.transition = opS.t;
                        t.f.speed = opS.s;
                        t.f.direction = opS.d;
                    }
                    if (r.prefijo == "imagen") {
                        t.preview1 = urls.thumbs + r.name_archivos[0].name_archivos;
                        t.orientation = false;
                    } 
                    else if (r.prefijo == "slider") {
                        t.thumbs = urls.thumbsSlider;
                        t.filesSliderOld = [];
                        for (let l in r.name_archivos) {
                            if(r.name_archivos[l]){
                                let file = r.name_archivos[l];
                                t.filesSliderOld.push(file.name_archivos);
                                t.previewSlider.push({
                                    src: t.thumbs + file.name_archivos,
                                    name: file.name_archivos
                                });
                                t.filesSlider.push({
                                    file: t.thumbs + file.name_archivos, 
                                    name: file.name_archivos
                                });
                                t.previewSliderLoad.push({
                                    file: file.name_archivos, 
                                    load: true
                                });
                            }
                        }
                    }
                    else {
                        t.preview1 = t.urls_videoPoster;
                        t.orientation = true;
                    }
                    let f = [];
                    f.push({
                        value: r.template_id,
                        text: r.name_templates,
                    });
                    t.f.TypeTemplates = f[0];
                    console.log('TypeTemplates', t.f.TypeTemplates)
                    t.Showimg1 = true;
                    t.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    t.isLoading = false;
                });
        },
        delImg1() {
            this.f.img1 = null;
            this.Showimg1 = false;
            this.preview1 = "";
            this.f.archivo = null;
            if(this.$refs.img1){this.$refs.img1.value=null;}
        },
        fileImg1(event) {
            // maximo 100 megas
            if (this.$refs.img1.size <= 104857600) {
                let file = "";
                if (this.f.img1 != null) {
                    file = this.f.img1;
                } else {
                    file = event.target.files[0];
                }
                this.f.archivo = file;
                this.Showimg1 = true;
                if (file) {
                    var reader = new FileReader();
                    reader.readAsDataURL(file);
                    var ti = this;
                    reader.onload = function (e) {
                        if (ti.orientation == false) {
                            document.querySelector("#img1").src = e.target.result;
                        } 
                        // else {
                        //     document.querySelector("#img1").src = ti.urls_videoPoster;
                        // }
                    };
                }
            } else {
                this.$refs.img1.value=null;
                this.$store.commit("core/setNotiPush", {
                    group: "notiPush",
                    type: '5', // 1=success | 2=info | 3=error | 4=loading | 5=warning
                    title: "Error",
                    text: "The file exceeds 100 megabytes",
                    show: true,
                    duration: 20000
                });
            }
        },
        cancel() {
            this.$router.push({ name: "Display" });
        },
        refres() {
            this.f.id = 0;
            this.f.name = "";
            this.f.dfinish = "";
            this.f.url = "";
            this.f.status = 1;
            this.f.orientation = 1;
            let f = [];
            f.push({ value: 1, text: "Imagen" });
            this.f.TypeTemplates = f[0];
            this.delImg1();
        },
        slugUrl() {
            let str = this.f.name;
            str = str.replace(/^\s+|\s+$/g, "");
            str = str.toLowerCase();

            var from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
            var to = "aaaaaeeeeeiiiiooooouuuunc------";
            for (var i = 0, l = from.length; i < l; i++) {
                str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
            }
            str = str
                .replace(/[^a-z0-9 -]/g, "")
                .replace(/\s+/g, "-")
                .replace(/-+/g, "-");
            this.f.url = str;
        },
        form() {
            if (this.f.name == null || this.f.name.length == 0) {
                this.$store.commit("core/setNotiPush", {
                    group: "notiPush",
                    type: '5', // 1=success | 2=info | 3=error | 4=loading | 5=warning
                    title: "Warning",
                    text: "Empty Name",
                    show: true,
                    duration: 10000
                });
            } 
            else if (this.f.status == 1 && this.f.archivo == undefined && this.f.TypeTemplates.value < 3) {
                this.$store.commit("core/setNotiPush", {
                    group: "notiPush",
                    type: '5', // 1=success | 2=info | 3=error | 4=loading | 5=warning
                    title: "Warning",
                    text: "Empty File",
                    show: true,
                    duration: 10000
                });
            } 
            else if (this.f.status == 1 && this.filesSlider.length == 0 && this.f.TypeTemplates.value == 3) {
                this.$store.commit("core/setNotiPush", {
                    group: "notiPush",
                    type: '5', // 1=success | 2=info | 3=error | 4=loading | 5=warning
                    title: "Warning",
                    text: "Empty File",
                    show: true,
                    duration: 10000
                });
            } 
            else {
                // if(this.f.pin.length == 0){
                // 	this.validated(7);
                // }else{
                this.isLoading = true;
                this.btnSave = true;
                let fileSendHeader = {};
                let pin = "";
                if (this.f.pin != "null" || this.f.pin != null || this.f.pin != 0) {
                    pin = this.f.pin;
                }
                let formData = new FormData();
                formData.append("id", this.f.id);
                formData.append("name", this.f.name);
                formData.append("pin", pin);
                formData.append("url", this.f.url);
                formData.append("user_id", this.user_id);
                formData.append("org_id", this.org_id);
                formData.append("archivo", this.f.archivo);
                formData.append("archivoOld", this.f.archivoOld);
                formData.append("TypeTemplates", this.f.TypeTemplates.value);
                formData.append("dpublis", this.f.dpublis);
                formData.append("dfinish", this.f.dfinish);
                formData.append("status", this.f.status);
                formData.append("orientation", this.f.orientation);
                formData.append("transition", this.f.transition);
                formData.append("speed", this.f.speed);
                formData.append("direction", this.f.direction);
                if(this.filesSlider.length > 0){
                    formData.append("cantSlider", this.filesSlider.length);
                    let cont = 0;
                    for (let i in this.filesSlider) {
                        if(this.filesSlider.hasOwnProperty(i)){
                            formData.append("slider"+cont, this.filesSlider[i]);
                            cont++;
                        }
                    }
                }
                let url = "/display/form";
                let t = this;
                window.master
                    .post(url, formData, {
                        headers: { "Content-Type": "multipart/form-data" },
                        onUploadProgress: (e) => {
                            if (e.lengthComputable) {
                                let progress= Number((e.loaded / e.total) * 100);
                                // console.log('progressEvent', progress);
                                t.progress = progress;
                            }
                        },
                    })
                    .then((response) => {
                        // console.log(response.data);
                        if(response.data.result){
                            t.refres();
                            t.$store.commit("core/setNotiPush", {
                                group: "notiPush",
                                type: '1', // 1=success | 2=info | 3=error | 4=loading | 5=warning
                                title: "Send Data",
                                text: "Successful",
                                show: true,
                                duration: 3000
                            });
                            t.$router.push({ name: "Display" });
                        }
                        t.isLoading = false;
                        t.btnSave = false;
                    })
                    .catch((error) => {
                        console.log(error);
                        t.isLoading = false;
                        t.btnSave = false;
                    });
                // }
            }            
        },
        getTypeTemplates() {
            return new Promise((resolve, reject) => {
                this.isLoading = true;
                let url = "/display/gettypetemplates";
                window.master
                .get(url)
                .then((response) => {
                    let r = response.data.list;
                    let f = [];
                    for (const i in r) {
                        f.push({
                            value: r[i].id,
                            text: r[i].name,
                        });
                    }
                    this.TypeTemplates = f;
                    this.f.TypeTemplates = f[0];
                    resolve(true);
                })
                .catch((error) => {
                    console.log(error);
                    resolve(false);
                });
            });
        },
        validated(y) {
            if (y == 1) {
                this.$store.commit("core/setNotiPush", {
                    group: "notiPush",
                    type: '5', // 1=success | 2=info | 3=error | 4=loading | 5=warning
                    title: "Warning",
                    text: "it can only be greater than 6 digits.",
                    show: true,
                    duration: 10000
                });
            } else {
                this.$store.commit("core/setNotiPush", {
                    group: "notiPush",
                    type: '5', // 1=success | 2=info | 3=error | 4=loading | 5=warning
                    title: "Warning",
                    text: "the security pin field cannot be empty, it must have at least 6 digits.",
                    show: true,
                    duration: 20000
                });
            }
        },
        pinSecurity() {
            if (this.f.pin.length == 0) {
                let max = 0;
                let min = 999999;
                let y = Math.floor(Math.random() * (max - min + 1) + min);
                if (y.length < 7) {
                    this.pinSecurity();
                } else {
                    this.f.pin = y;
                }
            }
        },
    },
    computed: {
        ...mapGetters("ModApps/display", ["get_displayEdit"]),
    },
    watch: {
        "previewSliderLoad": function(){
            this.sliderOption();
        },
        "f.direction": function(){
            this.sliderOption();
        },
        "f.transition": function(){
            this.sliderOption();
        },
        "f.speed": function(){
            this.sliderOption();
        },
        "f.pin": {
            handler: function (val, oldVal) {
                // console.log(val);
                if (val != null) {
                    if (val.length > 6) {
                        this.f.pin = oldVal;
                        this.validated(1);
                    }
                }
            },
            deep: true,
        },
        "f.name": function () {
            if (
                this.f.url == "" ||
                this.f.id == 0 ||
                (this.f.id > 0 && this.name_old != this.f.name)
            ) {
                this.slugUrl();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.flex-container {
    display: flex;
    flex-wrap: wrap;
}

.flex-container > div {
    width: 92px;
    margin: 5px;
}

label {
    display: block;
}

.horisontal {
    -webkit-appearance: none;
    outline: 0.1em solid black;
    outline-offset: 0.1em;
    width: 92px;
    height: 54px;
}
.vertical {
    -webkit-appearance: none;
    outline: 0.1em solid black;
    outline-offset: 0.1em;
    width: 54px;
    height: 92px;
}

input[type="radio"]:checked {
    display: inline-block;
    background-color: #000;
}
.file-drag-drop {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    width: 100%;
    background: #fff;
    margin: auto;
    margin-top: 0px;
    text-align: center;
    border-radius: 0px;
    border: 1px dashed #ccc;
}
.drop-files {
    font-size: 24px;
    color: #ccc;
}
.bntFileV {
    border-radius: 0px;
    margin: 0 auto;
    display: block;
    width: 100%;
    max-width: 216px;
}
.bntFileH {
    border-radius: 0px;
    margin: 0 auto;
    display: block;
    width: 100%;
    max-width: 384px;
}
.bntFile {
    border-radius: 0px;
    margin: 0 auto;
    display: block;
    width: 100%;
}

.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
    text-align: center;
    margin: 0 auto;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
.url-text {
    background: transparent;
    border: 0;
    box-shadow: none;
    padding: 0;
    color: #005aff;
}

input.form-control.url-input {
    border: 0px;
    border-bottom: 1px solid;
    padding: 0px;
    margin-left: 3px;
}
.imgH {
    width: 100%;
    max-width: 384px;
    height: 216px;
}
.imgV {
    width: 100%;
    max-width: 216px;
    height: 384px;
}
.btnsImg{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    margin-bottom: 10px;
}
.btnsImgDel{
    position: relative;
    width: 100%;
    max-width: 240px;
    margin-bottom: 10px;
}
.btnsImgDel button{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
}
.btnsImgDel button span.badge {
    max-width: 190px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.itemImg{
    width: 100%;
    max-width: 240px;
    height: 135px; 
    overflow: hidden;
}
.img-files {
    position: relative;
    width: 100%;
    max-width: 240px;
    height: auto; 
}
.itemImg:before {
}
.select-inactivo {
    background-color: #e9ecef;
    opacity: 1;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #4F5467;
    border-radius: 4px;
    border: 1px solid #e9ecef;
}
.prevDir{
    padding: 0.7rem 0.5rem;
    border: 1px solid #e9ecef;
    height: 35px;
    border-left: 0px;
    background: #e9ecef;
}
.listfiles{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-around;
}
.prevSlider{
    padding: 0px;
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 480px;
    max-height: 270px;
    height: 270px;
    overflow: hidden;
}
.swiper-slide{
    width: 480px;
    height: 270px !important;
}
</style>
