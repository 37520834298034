<template>
    <div>
        <div class="header">
            <div class="block block1" style="height: 50px">
                <menuAppsR></menuAppsR>
            </div>
            <div class="block block2">
                <ul class="menuTop">
                    <li>
                        <button class="btn-refresh" @click="refreshApp">
                            <i class="fas fa-sync-alt"></i>
                            <span class="tooltip">Refresh</span>
                        </button>
                    </li>
                    <li><menuAccountR></menuAccountR></li>
                </ul>
            </div>
        </div>
        <div id="loading" v-if="isLoading"><div class="op-loading"></div></div>
        <notiPusher></notiPusher>
        <alertsConfirm></alertsConfirm>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import menuAppsR from "@/core/components/Reseller/Header/menuAppsR";
import menuAccountR from "@/core/components/Reseller/Header/menuAccountR";
import notiPusher from "@/core/plugins/notiAlerts/notiPusher";
import alertsConfirm from "@/core/plugins/notiAlerts/alertsConfirm";
export default {
    name: "Header",
    components: {
        menuAppsR,
        menuAccountR,
        notiPusher,
        alertsConfirm,
    },
    data() {
        return {
            isLoading: false,
            app: null,
            activeIndex: null,
            isDimenu: false,
            validViews: [
                "Home",
                "Profile",
                "Organizations",
                "Security",
                "Users",
                "Privilegies",
                "PrivilegiesList",
                "AddOrganization",
            ],
            AppIDactual: 0
        };
    },
    created() {
        // console.log('store appsorg = ', this.$store.getters['core/apps/appsorg'])
        this.validSessionReseller();
    },
    mounted() {
        this.getMessagesExt();
        this.$postMessage('webExterna', '*', window.parent);
    },
    methods: {
        getMessagesExt() {
            this.$receiveMessage((e) => {
                if (e.data.ext) {
                    console.log('getMessagesExt', e);
                    window.top.location.replace(window.self.location.href);
                }
            }, false);
        },
        validSessionReseller() {
            let t = this;
            t.isLoading= true;
            t.$store.dispatch('core/reseller/validSessionReseller')
            .then(response => {
                console.log(response);
                if(response){
                    let r = response.data;
                    console.log('validToken login ', r);
                    if(r.tokenValid)
                    {
                        console.log('redireccionando HomeReseller');
                        localStorage.setItem('ui', 'Reseller');
                        this.$store.commit("core/SET_HEADER", true);
                    }
                    else
                    {
                        console.log('no hay entrada al sistema ', r.tokenValid);
                        // cerramos session
                        t.logout();
                    }
                } else {t.logout();}
                t.isLoading= false;
            })
            .catch((error) => {
                //console.log(error);
                console.log('error al iniciar sesion', error);
                t.isLoading= false;
                t.logout();
            });  
        },
        logout(){
            let t = this;
            t.$store.dispatch('core/reseller/destroyTokenReseller')
            .then(response => {
                console.log('destroyTokenReseller', response);
                this.isLoading= false;
                t.$router.push({ name: 'Reseller' });
            })
            .catch(error => {
                console.log('destroyToken', error);
                this.isLoading= false;
                t.$router.push({ name: 'Reseller' });
            });
        },
        refreshApp() {
            let d = new Date();
            let t = d.getTime();
            window.location = window.location.href + "?cache=false&" + t;
            // window.location.reload(true);
        },
    },
    watch: {
        get_isLoading: function () {
            console.log('get_isLoading', this.get_isLoading);
            this.isLoading = this.get_isLoading;
        },
        accitonPusher: function () {
            if (this.accitonPusher != undefined) {
                console.log("accitonPusher", this.accitonPusher);
                let token = "Bearer " + localStorage.getItem('access_token_r');
                let oauth_m = localStorage.getItem('oauth_r');
                if (this.accitonPusher.token === token || this.accitonPusher.oauth === oauth_m) {
                    if (this.accitonPusher.action === "disconnected") { 
                        console.log("desconectado");
                        if(localStorage.getItem('disconnected') == null) {
                            this.$notify({
                                group: 'noti',
                                type: 'error',
                                title: 'Disconnected',
                                text: 'session expired!',
                            });
                            localStorage.setItem('disconnected', 1);
                            this.$store.dispatch("core/reseller/destroyTokenReseller").then((response) => {
                                this.$router.push({ name: "Reseller" });
                            });
                        }
                    }
                }
            }
        },
        getProfile: function () {
            console.log('getProfile', this.getProfile);
        },
    },
    computed: {
        ...mapGetters({ get_isLoading: "get_isLoading" }),
        ...mapGetters("core", { 
            notiPush: "getNotiPush",
            accitonPusher: "get_accitonPusher"
        }),
        ...mapGetters("core/session", {
            access_account: "access_account",
            loggedIn: "loggedInResller"
        }),
        ...mapGetters("core/reseller", {
            getProfile: "getProfile",
        }),
    },
};
</script>
<style lang="css" scoped>
    @import '../../assets/css/headerStyles.scss';
</style>
