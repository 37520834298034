<template>
    <div>
        <div class="card-header">TAXES</div>
        <div class="card-body">
            <div class="alert alert-info" role="alert">
                <form @submit.prevent="MetSAddTaxes">
                    <div class="form-row">
                        <div class="col-5">
                            <small id="emailHelp" class="form-text text-muted">
                                State:</small
                            >
                            <select class="form-control" v-model="FormTax.state_id">
                                <option value="0">--State--</option>
                                <option
                                    v-for="state in statesList"
                                    :key="state.id"
                                    v-bind:value="state.id"
                                >
                                    {{ state.name }}
                                </option>
                            </select>
                        </div>
                        <div class="col-5">
                            <small id="emailHelp" class="form-text text-muted">
                                Tax (%)</small
                            >
                            <input
                                type="text"
                                class="form-control"
                                placeholder="0.01"
                                v-model="FormTax.nametaxes"
                            />
                            <input type="hidden" v-model="FormTax.idtax" />
                        </div>
                        <div class="col-2">
                            <small id="emailHelp" class="form-text text-muted">.</small>
                            <button type="submit" class="btn btn-success">
                                {{ FormTax.btnNameTax }}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div>
                <Loader v-if="isLoadingStates"></Loader>
                <ul class="list-group list-group-flush">
                    <li
                        class="list-group-item d-flex justify-content-between align-items-cente"
                        v-for="taxeitem in TaxesLists"
                        :key="taxeitem.id"
                    >
                        <span>{{ taxeitem.statename }} - {{ taxeitem.name }} %</span
                        ><span
                            ><button
                                class="btn btn-danger btn-sm mr-2"
                                @click="deleteTaxBtn(taxeitem.id)"
                            >
                                <i class="fas fa-times"></i></button
                            ><button
                                class="btn btn-secondary btn-sm"
                                @click="
                                    MetEditStateBtn(
                                        taxeitem.id,
                                        taxeitem.name,
                                        taxeitem.state_id
                                    )
                                "
                            >
                                <i class="fas fa-edit"></i></button
                        ></span>
                    </li>
                </ul>
                <div v-if="NoRecordTaxe == true" class="text-center">no record</div>
            </div>
        </div>
    </div>
</template>
<script>
import Loader from '@/core/components/shared/Loader';
export default {
    components: {
        Loader,
    },
    data () {
        return {
            isLoadingStates: false,
            statesList: [],
            FormTax: {
                btnNameTax: 'ADD',
                ActionTaxe: 'AddTaxe',
                idtax: null,
                nametaxes: null,
                state_id: 0,
            },
            TaxesLists: [],
            NoRecordTaxe: false,
        };
    },
    created () {
        this.getStates();
        this.getTaxes();
    },
    methods: {
        getStates () {
            this.isLoadingStates = true;
            window.billing
                .get('states/' + window.localuserdata)
                .then((response) => {
                    console.log(response.data.resultStates);
                    this.statesList = response.data.resultStates;
                    this.isLoadingStates = false;
                })
                .catch((error) => console.log(error));
        },
        MetSAddTaxes () {
            this.isLoadingStates = true;
            if (this.FormTax.ActionTaxe == 'AddTaxe') {
                window.billing
                    .post('taxes/add', {
                        user_id: window.localuserdata,
                        name: this.FormTax.nametaxes,
                        state_id: this.FormTax.state_id,
                    })
                    .then((response) => {
                        console.log(response.data);
                        this.isLoadingStates = false;
                        this.FormTax.nametaxes = null;
                        this.FormTax.state_id = 0;
                        this.NoRecordTaxe = false;
                        this.getTaxes();
                        this.$swal.fire({
                            icon: 'success',
                            title: 'Add Taxes Successful',
                            showConfirmButton: false,
                            timer: 2000,
                        });
                    })
                    .catch((error) => console.log(error, (this.isLoading = false)));
            }
            if (this.FormTax.ActionTaxe == 'UpdateTaxe') {
                window.billing
                    .post(
                        'taxes/edit/' + this.FormTax.idtax + '/' + window.localuserdata,
                        {
                            user_id: window.localuserdata,
                            name: this.FormTax.nametaxes,
                            state_id: this.FormTax.state_id,
                        }
                    )
                    .then((response) => {
                        console.log(response.data);
                        this.FormTax.nametaxes = null;
                        this.FormTax.state_id = 0;
                        this.FormTax.btnNameTax = 'ADD';
                        this.FormTax.ActionTaxe = 'AddTaxe';
                        this.NoRecordTaxe = null;
                        this.getTaxes();
                        this.$swal.fire({
                            icon: 'success',
                            title: 'Updated Taxes Successful',
                            showConfirmButton: false,
                            timer: 2000,
                        });
                    })
                    .catch((error) => console.log(error, (this.isLoading = false)));
            }
        },
        getTaxes () {
            window.billing
                .get('taxes/' + window.localuserdata)
                .then((response) => {
                    console.log(response.data);
                    this.TaxesLists = response.data;
                    if (this.TaxesLists.length == 0) {
                        this.NoRecordTaxe = true;
                    }
                    this.isLoadingStates = false;
                })
                .catch((error) => console.log(error));
        },
        MetEditStateBtn (idtax, name, idstate) {
            this.FormTax = {
                btnNameTax: 'UPDATE',
                ActionTaxe: 'UpdateTaxe',
                nametaxes: name,
                state_id: idstate,
                idtax: idtax,
            };
        },
        deleteTaxBtn (idtax) {
            console.log('eliminar tax');
            this.isLoadingStates = true;
            this.$swal({
                title: 'Delete this Tax?',
                text: 'Are you sure?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes, Delete it!',
            }).then((result) => {
                console.log('eliminar tax ', idtax);
                if (result.value) {
                    window.billing
                        .put('deletetax', {
                            user_id: window.localuserdata,
                            idtax: idtax,
                        })
                        .then((response) => {
                            console.log(response.data);
                            this.isLoadingStates = false;
                            this.getTaxes();
                            this.$swal.fire({
                                icon: 'success',
                                title: 'Deleted Taxes Successful',
                                showConfirmButton: false,
                                timer: 2000,
                            });
                        })
                        .catch((error) =>
                            console.log(error, (this.isLoadingStates = false))
                        );
                }
            });
            this.isLoadingStates = false;
        },
    },
};
</script>
<style></style>
