<template>
    <div>
        <TimesheetHeader></TimesheetHeader>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card" id="card">
                        <div class="card-header" style="padding-left: 0">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="botonera-filtros">
                                        <div class="btn-add-invo">
                                            <button
                                                class="btn btn-success"
                                                @click="addTimesheet"
                                                style="color: white"
                                            >
                                                <i class="fas fa-plus"></i>
                                                Add Timesheet
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div
                                class="row"
                                v-if="isLoading"
                                style="display: block; margin: 0 auto"
                            >
                                <Loader></Loader>
                            </div>
                            <div class="row" v-else style="padding-bottom: 130px">
                                <div class="col-md-12">
                                    <table
                                        width="90%"
                                        class="table table-bordered table-striped table-hover"
                                    >
                                        <thead>
                                            <tr>
                                                <th scope="col" style="text-align: end">
                                                    Tim #
                                                </th>
                                                <th scope="col">Project</th>
                                                <th scope="col">From</th>
                                                <th scope="col">To</th>
                                                <th scope="col" style="text-align: end">
                                                    Amount
                                                </th>
                                                <th scope="col" style="text-align: end">
                                                    # Subcontractors
                                                </th>
                                                <th scope="col">Open</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="TimesheetList.length > 0">
                                            <tr
                                                v-for="(item, index) in TimesheetList"
                                                :key="index"
                                            >
                                                <td align="right">{{ item.ids }}</td>
                                                <td>{{ item.project }}</td>
                                                <td>{{ item.wfrom }}</td>
                                                <td>{{ item.wto }}</td>
                                                <td align="right">${{ item.amounts }}</td>
                                                <td align="right">
                                                    {{ item.subcontractors }}
                                                </td>
                                                <td>
                                                    <button
                                                        class="btn btn-default"
                                                        @click="openFolder(item)"
                                                    >
                                                        <i
                                                            class="fa fa-folder"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </button>
                                                </td>
                                                <td>
                                                    <dropdown-menu>
                                                        <button
                                                            class="btn btn-light btnDropActions"
                                                        >
                                                            <label class="">
                                                                Actions
                                                                <input
                                                                    type="checkbox"
                                                                    v-model="item.actions"
                                                                    :id="'check' + index"
                                                                    class="checkAction"
                                                                    @click="
                                                                        activateActions(
                                                                            index
                                                                        )
                                                                    "
                                                                />
                                                                <i
                                                                    class="fas fa-chevron-down"
                                                                    style="
                                                                        font-size: 12px;
                                                                    "
                                                                ></i>
                                                            </label>
                                                        </button>
                                                        <div
                                                            slot="dropdown"
                                                            :id="'drop' + index"
                                                            @click="desactivateActions"
                                                        >
                                                            <div
                                                                class="dropdown-item action-item"
                                                                style="width: 190px"
                                                                @click="actionPdf(item)"
                                                            >
                                                                <a
                                                                    class="text-left textAction"
                                                                    ><i
                                                                        class="fas fa-file-pdf"
                                                                    ></i>
                                                                    Open PDF</a
                                                                >
                                                            </div>
                                                            <div
                                                                class="dropdown-item action-item"
                                                                style="width: 190px"
                                                                @click="actionPrint(item)"
                                                            >
                                                                <a
                                                                    class="text-left textAction"
                                                                    ><i
                                                                        class="fas fa-print"
                                                                    ></i>
                                                                    ePrint</a
                                                                >
                                                            </div>
                                                            <div
                                                                class="dropdown-item action-item"
                                                                style="width: 190px"
                                                                @click="
                                                                    actionAllChecks(item)
                                                                "
                                                            >
                                                                <a
                                                                    class="text-left textAction"
                                                                    ><i
                                                                        class="fas fa-envelope"
                                                                    ></i>
                                                                    Print All Checks</a
                                                                >
                                                            </div>
                                                            <div
                                                                class="dropdown-item action-item"
                                                                style="width: 190px"
                                                                @click="
                                                                    editTimesheet(item)
                                                                "
                                                            >
                                                                <a
                                                                    class="text-left textAction"
                                                                    ><i
                                                                        class="fas fa-edit"
                                                                    ></i>
                                                                    Edit</a
                                                                >
                                                            </div>
                                                            <div
                                                                class="dropdown-item action-item"
                                                                style="width: 190px"
                                                                @click="
                                                                    deleteTimesheet(item)
                                                                "
                                                            >
                                                                <a
                                                                    class="text-left textAction"
                                                                    ><i
                                                                        class="fas fa-times"
                                                                    ></i>
                                                                    Delete</a
                                                                >
                                                            </div>
                                                        </div>
                                                    </dropdown-menu>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th colspan="4"></th>
                                                <th style="text-align: end">
                                                    <strong>${{ amount }}</strong>
                                                </th>
                                                <th colspan="3"></th>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr>
                                                <td colspan="9" align="center">
                                                    NO RECORD
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- modal -->
        <modalLateral>
            <component v-bind:is="m.component"></component>
        </modalLateral>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import TimesheetHeader from '@/apps/billing/views/Timesheetheader'
import Loader from '@/core/components/shared/Loader'
import DropdownMenu from '@innologica/vue-dropdown-menu'
import modalLateral from '@/core/plugins/themeModalLateral/modalLateral'
import formTimesheet from '@/apps/billing/components/timesheet/formTimesheet'
export default {
    name: "Timesheet",
    components: {
        TimesheetHeader,
        Loader,
        DropdownMenu,
        modalLateral,
        formTimesheet
    },
    data () {
        return {
            isLoading: false,
            isDisabled: false,
            f: {
                times_id: 0,
                id: 0,
                user_id: window.localorgdata,
                billing: 0,
                fecini:
                    (new Date().toISOString() + "").slice(0, 11) + "13:00:00.000-04:00",
                fecfin: "",
                descrip: "",
            },
            TimesheetList: [],
            amount: 0,
            m: {
                show: false,
                component: '',
                data: []
            }            
        }
    },
    created () {
        this.getTimesheet();
    },
    methods: {
        actionPdf(item) {
            window.open(
                window.urlbase +
                    "api/v2/timesheet/pdf/numdoc/" +
                    item.user_id +
                    "/" +
                    item.id,
                "_blank"
            );
        },
        actionPrint(item) {
            this.isLoading = true;
            let url = "/timesheet/ePrintTimesheetPDF";
            let data = {
                user_id: item.user_id,
                id: item.id,
                eprint: 1,
            };
            window.billing
                .post(url, data)
                .then((response) => {
                    this.isLoading = false;
                    let status = response.data.status;
                    let error = response.data.error;
                    let message = response.data.message;
                    if (status === false && error === true) {
                        this.$notify({
                            group: "noti",
                            title: "Send!",
                            type: "error",
                            text: message,
                        });
                    }
                    if (status === true && error === false) {
                        this.$notify({
                            group: "noti",
                            title: "Send!",
                            type: "success",
                            text: message,
                        });
                    } else {
                        this.$notify({
                            group: "noti",
                            title: "Send!",
                            type: "error",
                            text: message,
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.$notify({
                        group: "noti",
                        title: "Send!",
                        type: "error",
                        text: "Send Error",
                    });
                });
        },
        actionAllChecks(item) {
            this.$router.push({ name: "TimesheetAllChecks", params: { id: item.id } });
        },
        openFolder(item) {
            this.$router.push({ name: "TimesheetSub", params: { id: item.id } });
        },
        editTimesheet(item) {
            this.f.id = item.id;
            this.f.fecini =
                (new Date(item.nfrom).toISOString() + "").slice(0, 11) +
                "13:00:00.000-04:00";
            this.f.fecfin =
                (new Date(item.nto).toISOString() + "").slice(0, 11) +
                "13:00:00.000-04:00";
            this.f.descrip = item.project;
            this.addTimesheet();
        },
        deleteTimesheet(item) {
            this.$swal({
                title: "Delete this Timesheet?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                this.isLoading = true;
                if (result.value) {
                    window.billing
                        .post("timesheet/del", item)
                        .then((response) => {
                            console.log(response.data);
                            this.isLoading = false;
                            this.getTimesheet();
                            // this.$swal.fire({
                            // 	icon: 'success',
                            // 	title: 'successfully removed!',
                            // 	showConfirmButton: false,
                            // 	timer: 4000
                            // });
                        })
                        .catch((error) => {
                            console.log(error);
                            this.isLoading = false;
                        });
                }
            });
        },
        getTimesheet() {
            this.isLoading = true;
            window.billing
                .post("timesheet/get", this.f)
                .then((response) => {
                    console.log(response.data);
                    this.TimesheetList = response.data.result;
                    this.amount = response.data.totalamount;
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        addTimesheet() {
            this.m = {
                show: true,
                component: 'formTimesheet',
                data: {}
            }  
            this.$store.commit('core/setOpenModal', this.m);            
        },
        desactivateActions() {
            let allCheck = document.querySelectorAll(".checkAction");
            for (let i in allCheck) {
                if (allCheck.hasOwnProperty(i)) {
                    allCheck[i].parentElement.parentElement.nextSibling.style.display =
                        "none";
                    allCheck[i].parentElement.parentElement.nextSibling.style.left =
                        "0px";
                    allCheck[i].checked = false;
                }
            }
        },
        activateActions(index) {
            let allCheck = document.querySelectorAll(".checkAction");
            for (let i in allCheck) {
                if (allCheck.hasOwnProperty(i)) {
                    allCheck[i].parentElement.parentElement.nextSibling.style.display =
                        "none";
                    allCheck[i].parentElement.parentElement.nextSibling.style.left =
                        "0px";
                }
            }
            let check = document.querySelector("#check" + index);
            if (check.checked) {
                check.parentElement.parentElement.nextSibling.style.display = "block";
                check.parentElement.parentElement.nextSibling.style.left = "-180px";
            } else {
                check.parentElement.parentElement.nextSibling.style.display = "none";
                check.parentElement.parentElement.nextSibling.style.left = "0px";
            }
        },
    },
    watch: {
        'getOpenModal': function() {
            // console.log('timesheet getOpenModal', this.getOpenModal.show);
            if (!this.getOpenModal.show) {
                if (Object.keys(this.getOpenModal.data).length > 0) {
                    this.getTimesheet();
                }
                this.m = {
                    show: false,
                    component: '',
                    data: {}
                }
            }
        }
    },
    computed: {
        ...mapGetters("core", { getOpenModal: "getOpenModal" }),
    }
};
</script>
<style lang="scss" scoped>
.botonera-filtros {
    padding: 0px 15px;
    display: flex;
    width: 100%;
}
.btn-add-invo,
.btn-sync {
    margin-right: 10px;
}
.btn-add-invo {
    width: 10%;
    max-width: 115px;
    min-width: 115px;
}
.btn-sync {
    width: 4px;
    max-width: 35px;
    min-width: 35px;
}
.filtros {
    width: 70%;
    margin-left: 16%;
}
@media (min-width: 1320px) {
    .mobile_display {
        justify-content: flex-end;
    }
}
.uno {
    width: 550px;
    margin-right: 10px;
}
.dos {
    width: 70px;
    margin-right: 10px;
}
.tres {
    width: 102px;
}
@media (max-width: 1320px) {
    .filtros {
        margin-left: 10%;
        width: 76%;
    }
}
@media (max-width: 1320px) {
    .filtros {
        margin-left: 5%;
        width: 81%;
    }
}
@media (max-width: 1100px) {
    .filtros {
        max-width: 924px;
    }
    .uno {
        width: 400px;
        margin-right: 10px;
    }
}
@media (max-width: 1000px) {
    .filtros {
        margin-left: 2%;
        width: 84%;
    }
    .uno {
        width: 300px;
    }
}
@media (max-width: 875px) {
    .filtros {
        margin-left: 2%;
        width: 84%;
    }
    .uno {
        width: 100%;
        margin-bottom: 10px;
    }
}
@media (max-width: 500px) {
    .botonera-filtros {
        display: block;
    }
    .btn-add-invo,
    .btn-sync {
        display: inline-block;
        margin-bottom: 10px;
    }
    .filtros {
        margin-left: 0%;
        margin-bottom: 10px;
        width: 100%;
        padding-left: 15px;
    }
}
.btn-filter-search:hover {
    cursor: pointer;
}

.tres button.btn,
.cuatro button.btn {
    padding: 7px 10px;
}

.cdropdown ul li {
    padding: 3px 10px;
}
.cdropdown ul li button {
    font-size: 0.76rem;
}
#card-invoice {
    background: transparent;
}
.dropdStatus {
    list-style: none;
}
.dropdStatus {
    position: absolute;
    width: 130px;
    height: auto;
    background-color: #fff;
    z-index: 9;
    margin: 0;
    border: 1px solid #ccc;
    padding: 10px;
    list-style: none;
    @media (max-width: 767px) {
        right: 0;
    }
}
.dropdStatus li {
    text-align: left;
}
.dropdStatus li label {
    text-align: left;
    display: block;
}
.btn-status {
    font-size: 13px;
    letter-spacing: 0.5px;
    color: #5d5b5b !important;
    border-radius: 0;
    border: 1px solid #dcdcdc;
    background-color: #fff;
    padding: 7px;
}
.pagination {
    .page-car {
        &.active {
            .page-link {
                background-color: #8b8b8c;
                color: #fff;
            }
        }
    }
}
.scroll-right {
    display: none;
}

.btnDropActions {
    border-color: #ccc;
    padding: 0;
}
.btnDropActions label input {
    display: none;
}
.btnDropActions label:hover {
    cursor: pointer;
}
.btnDropActions label {
    padding: 0.375rem 0.5rem;
    margin-bottom: 0.1rem;
    font-size: 12px;
}
.textAction {
    font-size: 12px;
}
.dropdown-item.action-item {
    padding: 3px 5px 3px 10px;
}

/* ************************************* */

.invoList tbody tr td {
    font-size: 0.775rem;
}

.invoList thead tr th:nth-child(1),
.invoList tbody tr td:nth-child(1) {
    min-width: 66px;
    width: 5.694564279551337%;
}
.invoList thead tr th:nth-child(2),
.invoList tbody tr td:nth-child(2) {
    min-width: 92px;
    width: 7.937877480586713%;
}
.invoList thead tr th:nth-child(3),
.invoList tbody tr td:nth-child(3) {
    min-width: 103px;
    width: 8.886971527178602%;
}
.invoList thead tr th:nth-child(4),
.invoList tbody tr td:nth-child(4) {
    min-width: 200px;
    width: 17.25625539257981%;
}
.invoList thead tr th:nth-child(5),
.invoList tbody tr td:nth-child(5) {
    min-width: 178px;
    width: 15.35806729939603%;
}
.invoList thead tr th:nth-child(6),
.invoList tbody tr td:nth-child(6) {
    min-width: 80px;
    width: 6.902502157031924%;
}
.invoList thead tr th:nth-child(7),
.invoList tbody tr td:nth-child(7) {
    min-width: 66px;
    width: 5.694564279551337%;
}
.invoList thead tr th:nth-child(8),
.invoList tbody tr td:nth-child(8) {
    min-width: 80px;
    width: 6.902502157031924%;
}
.invoList thead tr th:nth-child(9),
.invoList tbody tr td:nth-child(9) {
    min-width: 73px;
    width: 6.298533218291631%;
}
.invoList thead tr th:nth-child(10),
.invoList tbody tr td:nth-child(10) {
    min-width: 40px;
    width: 3.451251078515962%;
}
.invoList thead tr th:nth-child(11),
.invoList tbody tr td:nth-child(11) {
    min-width: 71px;
    width: 6.125970664365833%;
}
.invoList thead tr th:nth-child(12),
.invoList tbody tr td:nth-child(12) {
    min-width: 110px;
    width: 9.490940465918896%;
}

.invoList thead {
    width: 100%;
    display: block;
    background: #fff;
}
.invoList tbody {
    width: 100%;
    display: block;
    height: calc(100vh - 230px);
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 290px;
}
.invoList thead tr,
.invoList tbody tr {
    display: block;
}
.invoList thead tr th {
    display: inline-block;
    height: 34px;
}
.invoList tbody tr td {
    display: inline-block;
    height: 50px;
}
.table-bordered th,
.table-bordered td {
    border: 0px solid transparent !important;
}

.contenedor-invoice table thead tr th {
    font-weight: bold;
}
.paginacion {
    width: 70px;
}
.invoList thead tr th:nth-child(11),
.invoList tbody tr td:nth-child(11) {
    min-width: 70px;
    width: 70px;
}
table.table.table-striped.table-bordered.table-hover.invoList {
    border: 0px;
}
.contenedor-invoice table {
    width: 100%;
    min-width: 1180px;
}
.contenedor-invoice {
    padding: 0px 10px 0px 10px;
    overflow-x: auto;
    width: calc(100vw - 170px);
}
@media (max-width: 1230px) {
    .scroll-right {
        display: block;
    }
}
@media (max-width: 1100px) {
    .invoList thead tr th:nth-child(1),
    .invoList tbody tr td:nth-child(1) {
        min-width: 50px;
    }
    .invoList thead tr th:nth-child(2),
    .invoList tbody tr td:nth-child(2) {
        min-width: 80px;
        width: 80px;
    }
    .invoList thead tr th:nth-child(3),
    .invoList tbody tr td:nth-child(3) {
        min-width: 80px;
        width: 80px;
    }
    .invoList thead tr th:nth-child(4),
    .invoList tbody tr td:nth-child(4) {
        min-width: 150px;
        width: 150px;
    }
    .invoList thead tr th:nth-child(5),
    .invoList tbody tr td:nth-child(5) {
        min-width: 130px;
        width: 130px;
    }
    .invoList thead tr th:nth-child(6),
    .invoList tbody tr td:nth-child(6) {
        min-width: 90px;
        width: 90px;
    }
    .invoList thead tr th:nth-child(7),
    .invoList tbody tr td:nth-child(7) {
        min-width: 70px;
        width: 70px;
    }
    .invoList thead tr th:nth-child(8),
    .invoList tbody tr td:nth-child(8) {
        min-width: 90px;
        width: 90px;
    }
    .invoList thead tr th:nth-child(9),
    .invoList tbody tr td:nth-child(9) {
        min-width: 80px;
        width: 80px;
    }
    .invoList thead tr th:nth-child(10),
    .invoList tbody tr td:nth-child(10) {
        min-width: 50px;
        width: 50px;
    }
    .invoList thead tr th:nth-child(11),
    .invoList tbody tr td:nth-child(11) {
        min-width: 50px;
        width: 50px;
    }
    .invoList tbody tr td:nth-child(11) > .cdropdown > button {
        padding: 15px;
    }
    .invoList tbody tr td:nth-child(11) > .cdropdown > button:before {
        right: 11px;
    }
    .invoList tbody tr td:nth-child(11) > .cdropdown > button > span {
        display: none;
    }
    .contenedor-invoice {
        max-width: 924px;
    }
}
.invoList tbody tr:nth-of-type(odd) {
    background-color: #e9ecef;
}
.invoList tbody tr:nth-of-type(even) {
    background-color: #fff;
}
a:not([href]):not([tabindex]) {
    color: #212529;
}
</style>
