<template>
    <div class="card">
        <div class="card-header"><h4 class="card-title">Add To do List</h4></div>
        <div class="card-body">
            <Loader v-if="isLoading"></Loader>
            <form v-else @submit.prevent="save_To_do_List">
                <div class="form-group">
                    <label>Name list: </label>
                    <div class="input-group">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Name list:"
                            v-model="list.name"
                            required
                        />
                    </div>
                </div>
                <div class="form-group container-table">
                    <table class="table table-hover">
                        <thead>
                            <tr class="bg-secondary">
                                <th style="color: #fff">Things to do</th>
                                <th width="55px"></th>
                            </tr>
                        </thead>
                        <draggable
                            v-model="list.taks"
                            tag="tbody"
                            class="tbody"
                            v-bind="dragOptions"
                            @start="isDragging = true"
                            @end="isDragging = false"
                            handle=".handle"
                        >
                            <tr v-for="(i, index) in list.taks" v-bind:key="index">
                                <td>
                                    <div class="handle">
                                        <i class="fas fa-ellipsis-v"></i>
                                    </div>
                                    <textarea
                                        class="form-control"
                                        v-model="i.name"
                                        placeholder="text box"
                                        required
                                        :ref="'taks' + index"
                                        :id="'taks' + index"
                                    ></textarea>
                                </td>
                                <td>
                                    <button
                                        type="button"
                                        class="btn btn-danger"
                                        @click="delTaks(i.id, index)"
                                    >
                                        <i class="fa fa-times"></i>
                                    </button>
                                </td>
                            </tr>
                        </draggable>
                    </table>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <button type="button" class="btn btn-info" @click="addLine">
                            <i class="fa fa-plus"></i> Add taks
                        </button>
                    </div>
                    <div class="col-sm-6 text-right">
                        <button type="button" class="btn btn-light" @click="cancelar">
                            <i class="fa fa-times"></i> Cancel
                        </button>
                        <button type="submit" class="btn btn-success" :disabled="btnSave">
                            <i class="fa fa-save"></i> Save
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import draggable from "vuedraggable";
import Loader from "@/core/components/shared/Loader.vue";
import { mapGetters } from "vuex";
export default {
    name: "add_to_do_list",
    components: {
        Loader,
        draggable,
    },
    data() {
        return {
            f: {
                name: null,
                taks: [{ id: 0, name: null, status: 1 }],
            },
            btnSave: false,
            isLoading: false,
            editar: false,
            form_to_do: [],
            list:{
                id:0,
                taks:[],
                name:''
            }
        };
    },
    created() {
        this.init()
    },
    methods: {
        init(){
            let urlApis = this.$store.getters['ModApps/jobs/urlApis'];
            this.apis = urlApis.setup;       
            let g = this.$store.getters['core/getOpenModal']
            if (g.data === 0) {
                this.addLine();
            } else {
                this.list.id = g.data
                this.editar = true;
                this.getListByID();
            }
        },
        getListByID() {
            this.isLoading = true;
            this.list.taks = [];
            // console.log(this.list.id);
            let url = this.apis.getListByID(this.list.id)
            window.master
                .get(url)
                .then((response) => {
                    let template = response.data.template;
                    let taks = response.data.taks;
                    this.list.name = template.name;
                    this.list.taks = taks;
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        addLine() {
            let lineNew = {
                id: 0,
                name: null,
                status: 1,
            };
            this.list.taks.push(lineNew);
            let idx = this.list.taks.length - 1;
            setTimeout(function () {
                document.getElementById("taks" + idx).focus();
            }, 200);
        },
        delTaks(id, index) {
            this.list.taks.splice(index, 1);
        },
        save_To_do_List() {
            this.btnSave = true;
            this.isLoading = true;
            let url = this.apis.addTemplate()
            if (this.editar) {
                url = this.apis.editTemplate()
            }
            // console.log("save", this.list);
            window.master
                .post(url, {
                    user_id: window.master_client,
                    org_id: window.localorgdata,
                    list: this.list,
                })
                .then((response) => {
                    // console.log(response.data);
                    this.isLoading = false;
                    this.btnSave = false;
                    this.$store.commit("core/setCloseModal", {
                        action: 'saved',
                        metodo:'return'
                    });
                    this.$parent.close();
                })
                .catch((error) => {
                    console.log(error);
                    this.btnSave = false;
                    this.isLoading = false;
                    this.cancelar();
                });
        },
        cancelar() {
            this.$store.commit("core/setCloseModal", {});
        },
    },
    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: "list.taks",
                disabled: false,
                ghostClass: "ghost",
            };
        },
    },
    watch: {
        list: function () {
            console.log("nueva linea", this.list);
        },
    },
};
</script>
<style lang="scss" scoped>
.form-control {
    border-color: #555;
}
.btn-light {
    border-color: #555;
    background-color: #fff;
    margin-right: 10px;
}
.container-table {
    height: 40vh;
    overflow-y: auto;
}
.handle {
    position: absolute;
    border: 0px solid #000;
    left: 5px;
    padding: 15px 2px 3px 4px;
    height: 55px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: rgb(17 177 245 / 38%);
    cursor: move;
}
.tbody t {
    position: relative;
}
.tbody tr td {
    position: relative;
    padding-left: 16px;
}
</style>
