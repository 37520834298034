<template>
        <div class="optionsRow" style="padding-top: 0px;">
        <div class="card-header window-header">
            <div class="col-12" style="padding-left: 0px;">
                <h3 style="margin-bottom: 0px;">Section Options</h3>
            </div>
            <button @click="$emit('close')" class="closeIcon"><span></span></button>
		</div>
        <div class="window-body">
            <div class="row">
                <div class="col-12">
                    <label style="margin-right: 5px;width: 80px;">Text Color: </label>
                    <span class="sampleColor" :style="muestraTextColor()" @click="showEditTextColor()"></span>
                    <span class="btnOk" @click="changeTextColor()" v-if="editTextColor">Ok</span>
                    <span class="btnClose" @click="closeTextColor()" v-if="editTextColor">X</span>
                    <span @click="showEditTextColor()">{{ f.color }}</span>
                    <color-picker
                        v-model="sampleTextColor"
                        class="getcolor"
                        v-if="editTextColor"
                    ></color-picker>
                </div>
                <div class="col-12">
                    <label style="margin-right: 5px;width: 120px;">Background Color: </label>
                    <span class="sampleColor" :style="muestraBgColor()" @click="showEditBgColor()"></span>
                    <span class="btnOk" @click="changeBgColor()" v-if="editBgColor">Ok</span>
                    <span class="btnClose" @click="closeBgColor()" v-if="editBgColor">X</span>
                    <span @click="showEditBgColor()" v-if="!editBgColor">{{f.bgColor}}</span>
                    <color-picker
                        v-model="sampleBgColor"
                        class="getcolor"
                        v-if="editBgColor"
                    ></color-picker>
                    <div class="box-contents list-widgets">
                        <div class="box-section">
                            <span class="title">Background Image:</span>
                            <div class="previewImg">
                                <img :src="noImg" class="img-thumbnail" v-if="f.bgImage==null" id="previewImg"/>
                                <img :src="urlImg+f.bgImage" class="img-thumbnail" v-else  id="previewImg"/>
                                <div class="btn-upload-img" @click="$refs.bgImg.click()">
                                    <i class="fas fa-cloud-upload-alt"  v-if="!loadImg"></i>
                                    <loader v-else></loader>
                                </div>
                                <div class="delBgImg" @click="deleteBgImg" v-if="f.bgImage!=null"><i class="fas fa-trash"></i></div>
                            </div>
                            <input type="range" v-model="f.opacity" min="0" max="1" step="0.05" @change="changeStyles()">
                            <span>Opacity</span>
                        </div>
                        <input type="file" ref="bgImg" accept="image/*" @change="changeBgImage($event)" style="display: none;">
                    </div>
                </div>
                <div class="col-12">
                    <!-- <div class="form-group">
                        <label style="margin-right: 5px;width: 60px;">Padding: </label>
                        <input type="text" class="input_mp" v-model="f.p.t" @change="changeStyles()"/>
                        <input type="text" class="input_mp" v-model="f.p.r" @change="changeStyles()"/>
                        <input type="text" class="input_mp" v-model="f.p.b" @change="changeStyles()"/>
                        <input type="text" class="input_mp" v-model="f.p.l" @change="changeStyles()"/>
                        <label style="">px </label>
                    </div> -->
                    <div class="form-group">
                        <label style="margin-right: 5px;width: 60px;">Height: </label>
                        <input type="text" class="input_mp" v-model="f.height" @change="changeStyles()" placeholder="auto"/>
                        <label style="">px </label>
                    </div>
                    <!-- <div class="form-group">
                        <label style="margin-right: 5px;width: 100px;margin-bottom:10px;">Text Align: </label>
                        <div class="row">
                            <div class="col-6">
                                <div class="form-check" style="margin-bottom:10px;">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input" v-model="f.columnAlignH" name="alignH" value="normal" />
                                        Normal
                                    </label>
                                </div>
                                <div class="form-check" style="margin-bottom:10px;">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input" v-model="f.columnAlignH" name="alignH" value="center" />
                                        Center
                                    </label>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-check" style="margin-bottom:10px;">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input" v-model="f.columnAlignH" name="alignH" value="flex-start" />
                                        Left
                                    </label>
                                </div>
                                <div class="form-check" style="margin-bottom:10px;">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input" v-model="f.columnAlignH" name="alignH" value="flex-end" />
                                        Right
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="form-group">
                        <label style="margin-right: 5px;width: 100px;margin-bottom:10px;">Align Content: </label>
                        <div class="row">
                            <div class="col-6">
                                <div class="form-check" style="margin-bottom:10px;">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input" v-model="f.columnAlignV" name="alignV" value="normal" />
                                        Normal
                                    </label>
                                </div>
                                <div class="form-check" style="margin-bottom:10px;">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input" v-model="f.columnAlignV" name="alignV" value="center" />
                                        Center
                                    </label>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-check" style="margin-bottom:10px;">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input" v-model="f.columnAlignV" name="alignV" value="flex-start" />
                                        Up
                                    </label>
                                </div>
                                <div class="form-check" style="margin-bottom:10px;">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input" v-model="f.columnAlignV" name="alignV" value="flex-end" />
                                        Donw
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// @ts-ignore
import { Sketch } from "vue-color";
// @ts-ignore
import Loader from "@/core/components/shared/Loader.vue";
import { watch } from 'vue';
export default {
    components: {
        "color-picker": Sketch,
        Loader,
    },
    props: {
        data: {
            type: [Number, String, Object],
            default: 0,
            require: false,
        },
    },
    data(){
        return {
            noImg: require('@/core/assets/img/no-image.png'),
            editTextColor: false,
            getTextColor: { "rgba": { "r": 255, "g": 255, "b": 255, "a": 0 }},
            getBgColor: { "rgba": { "r": 255, "g": 255, "b": 255, "a": 0 }},
            sampleTextColor: null,
            editBgColor: false,
            sampleBgColor: "#00000000",
            urlImg: (localStorage.getItem('bsite_cardUrlPic')+'minisite/').replaceAll('"', ''),
            f: {
                color: "#6A7A8C",
                bgColor: "#FFFFFF",
                bgImage: null,
                opacity: 0.7,
                // p: {
                //     t: 30,
                //     r: 15,
                //     b: 15,
                //     l: 15,
                // },
                height: '',
                columnAlignV: 'normal',
                columnAlignH: 'center',
            },
            transparent: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAADBJREFUOE9jfPbs2X8GPEBSUhKfNAPjqAHDIgz+//+PNx08f/4cfzoYNYCBceiHAQC5flV5JzgrxQAAAABJRU5ErkJggg==",
            section: null,
            imgOld: null,
            loadImg: false,
            apis: null,
        };
    },
    created(){
        let t = this;
        let urlApis = t.$store.getters['ModApps/Marketea/urlApis'];
        t.apis = urlApis.bSite;
        t.init();
    },
    methods: {
        init(){
            let t = this;
            if(t.$store.getters['ModApps/Marketea/get_bsite_minisite'][t.data]){
                t.section = t.$store.getters['ModApps/Marketea/get_bsite_minisite'][t.data];
                console.log(t.data, t.section)
                if(t.section.opts==undefined && t.section.opts==null){
                    t.section.opts = t.f;
                }else{
                    //verficar los campos, si algun campo no existe por ser nuevo se agrega
                    let fieldsO = Object.keys(t.section.opts);
                    let fieldsF = Object.keys(t.f);
                    for (const key in fieldsF) {
                        let addField= false;
                        if(!fieldsO.includes(fieldsF[key])){
                            t.section.opts[fieldsF[key]]=t.f[fieldsF[key]];
                            console.log('add field', fieldsF[key], t.f[fieldsF[key]])
                        }
                    }
                    console.log('opts', t.section.opts)
                    t.f = t.section.opts;
                }
                if(t.f.bgImage!=null && t.f.bgImage!='null'){
                    t.imgOld=t.f.bgImage;
                }
                t.sampleTextColor = t.f.color;
                t.sampleBgColor = t.f.bgColor;
            }
        },
        muestraTextColor(){
            return 'background-color:'+this.f.color+';';
        },
        showEditTextColor(){
            this.sampleTextColor = this.f.color;
            this.editTextColor=true;
        },
        closeTextColor(){
            this.editTextColor=false;
        },
        changeTextColor(){
            let c = this.sampleTextColor.hex;
            this.f.color =c;
            this.editTextColor=false;
            this.changeStyles();
        },
        muestraBgColor(){
            return 'background-color:'+this.f.bgColor+';';
        },
        showEditBgColor(){
            this.sampleBgColor = this.f.bgColor;
            this.editBgColor=true;
        },
        closeBgColor(){
            this.editBgColor=false;
        },
        changeBgColor(){
            let c = this.sampleBgColor.hex8;
            this.f.bgColor =c;
            this.editBgColor=false;
            this.changeStyles();
        },
        changeStyles(){
            console.log('changeStyles', this.f)
            this.section.opts = this.f;
            this.setOpts();
        },
        setOpts(){
            this.$emit('saveOpts', this.section.opts);
        },
        changeBgImage(event){
            let t = this;
            let file = event.target.files[0];
            if (file) {
                t.loadImg=true;
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function (e) {
                    t.noImg = e.target.result;
                    t.$refs.bgImg.value = null;
                    t.setBgImg(file);
                };
            } else {
                t.$refs.bgImg.value = null;
                t.loadImg=false;
            }
        },
        setBgImg(file){
            let t = this;
            let payLoad = new FormData();
            payLoad.append("user_id", window.master_client);
            payLoad.append("org_id", window.localorgdata);
            payLoad.append("image", file);
            payLoad.append("imgOld", t.imgOld);
            payLoad.append("pusher_id", pusher.connection.socket_id);
            let url = t.apis.set(13); //uploadImgMiniSite
            window.master.post(url, payLoad, {
                headers: {"Content-Type": "multipart/form-data"}
            })
            .then((response) => {
                console.log(url, response.data);
                if(!response.data.status){
                    t.$notify({
                        group: "noti",
                        type: 'error',
                        title: "Fail!",
                        text: "Uploading image ",
                    });
                    t.f.bgImage = null;
                    t.$refs.bgImg.value = null;
                }else{
                    t.f.bgImage = response.data.filename;
                    t.changeStyles();
                }
                t.loadImg = false;
            }).catch((error) => {
                console.log(error);
                t.loadImg = false;
                t.$notify({
                        group: "noti",
                        type: 'error',
                        title: "Error",
                        text: error,
                    });
            });
        },
        deleteBgImg(){
            let t = this;
            t.loadImg = true;
            let payLoad = new FormData();
            payLoad.append("user_id", window.master_client);
            payLoad.append("org_id", window.localorgdata);
            payLoad.append("imgDel", t.f.bgImage);
            payLoad.append("pusher_id", pusher.connection.socket_id);
            let url = t.apis.set(14); //delImgMiniSite
            window.master.post(url, payLoad)
            .then((response) => {
                console.log(url, response.data);
                if(!response.data.status){
                    t.$notify({
                        group: "noti",
                        type: 'error',
                        title: "Fail!",
                        text: "delete image ",
                    });
                }else{
                    t.f.bgImage = null;
                    t.changeStyles();
                }
                t.loadImg = false;
            }).catch((error) => {
                console.log(error);
                t.loadImg = false;
                t.$notify({
                        group: "noti",
                        type: 'error',
                        title: "Error",
                        text: error,
                    });
            });
        },
    },
    watch: {
        "data": function(){
            this.init();
        }
    }
}
</script>
<style lang="scss" scoped>
.optionsRow{
    position: fixed;
    z-index: 9999;
    background: #fff;
    top: 30px;
    left: 2px;
    width: auto;
    min-width: 300px;
    max-width: 300px;
    box-shadow: -1px 0px 3px 2px rgba(0, 0, 0, 0.15);
    font-size: 12px;
    .card-header.window-header{
        width: 100%;
        padding: 0.55rem 1.25rem;
        h3{font-size:14px; text-align: left; margin: 0px;}
    }
    .window-body{
        height: auto;
        min-height: 350px;
        max-height: 420px;
        overflow: hidden;
        overflow-y: auto;
        box-sizing: border-box;
        padding-bottom: 3em;
        width: 100%;
        &::-webkit-scrollbar:vertical {
            width:3px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #797979;
            border-radius: 20px;
            border: 0px solid #f1f2f3;
        }
        .row{
            width: 100%;
            margin-left: 0px;
            margin-right: 0px;
        }
        .input_mp{
            width: 40px;
            display: inline-block;
            margin-right: 5px;
        }
        .sampleColor{
            width: 40px;
            height: 22px;
            display: inline-block;
            margin-left: 5px;
            margin-right: 5px;
            position: relative;
            top: 5px;
            border:1px solid #ddd;
        }
        .btnOk{
            background: #5ac146;
            color: #fff;
            width: 30px;
            font-size: 12px;
            height: 20px;
            display: inline-block;
            margin-left: 5px;
            position: relative;
            top: -2px;
            line-height: 1;
            padding-top: 3px;
            text-align: center;
            &:hover{cursor: pointer;}
        }
        .btnClose{
            background: #be0606;
            color: #fff;
            width: 20px;
            font-size: 12px;
            height: 20px;
            display: inline-block;
            margin-left: 5px;
            margin-right: 5px;
            position: relative;
            top: -2px;
            line-height: 1;
            padding-top: 3px;
            text-align: center;
            &:hover{cursor: pointer;}
        }
        .getcolor {
            position: absolute;
            top: 30px;
            z-index: 5;
        }
        .box-section {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-content: center;
            justify-content: center;
            align-items: center;
            width: 100%;
            max-width: 300px;
            height: auto;
            border-radius: 2px;
            border: 1px solid #dadce0;
            margin-top: 10px;
            margin-bottom: 10px;
            padding: 5px;
            &:hover {
                background-color: #fff;
                cursor: default;
            }
            .title{font-weight: bold;}
            .previewImg{
                position: relative;
                img{
                    width: auto;
                    height: 150px;
                }
                .btn-upload-img{
                    position: absolute;
                    z-index: 1;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    align-content: center;
                    justify-content: center;
                    align-items: center;
                    text-shadow: 1px 0px 10px rgb(255 255 255 / 50%);
                    color: #4c32e9;
                    font-size: 50px;
                    opacity: 0.5;
                    &:hover{
                    background-color: rgb(0 0 0 / 0.2);
                    opacity: 1;
                    cursor: pointer;
                    }
                }
                .delBgImg {
                    position: absolute;
                    z-index: 2;
                    top: 5px;
                    right: 5px;
                    color: #fff;
                    background: red;
                    padding: 8px;
                    border-radius: 4px;
                    opacity: 0.3;
                    &:hover{
                        opacity: 1;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
@media (max-width:767px) {
    .optionsRow{
        .closeIcon{
            top: 5px;
            width: 30px;
            height: 30px;
        }
        .closeIcon span:before, .closeIcon span:after {
            width: 20px;
        }
    }
}
</style>
