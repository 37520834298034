<template>
    <div>
        <div class="onoffswitch">
            <input
                type="checkbox"
                :id="'cat' + itemDisplay.id"
                v-model="check"
                :checked="check"
                class="onoffswitch-checkbox"
                @input="handleOnInput"
            />
            <label class="onoffswitch-label" :for="'cat' + itemDisplay.id">
                <span class="onoffswitch-inner"></span>
                <span class="onoffswitch-switch"></span>
            </label>
        </div>
        <ul class="list-options">
            <!-- <li>
				<span class="status" v-if="displayBox.status == 1">
					<span class="badge badge-pill badge-success">Active</span>
				</span>
				<span class="status" v-if="displayBox.status == 0">
					<span class="badge badge-pill badge-danger">Inactive</span>
				</span>	
			</li> -->
            <li class="edit" @click="$refs.file.click()" v-if="itemDisplay.prefijo != 'slider'">
                <i class="fas fa-upload"></i>
                <span class="tooltip">Upload</span>
            </li>
            <li class="edit" @click="viewDisplay(displayBox.url)">
                <i class="fas fa-link"></i>
                <span class="tooltip">View</span>
            </li>
            <li class="edit" @click="openForm(displayBox.id)">
                <i class="fas fa-edit"></i>
                <span class="tooltip">Edit</span>
            </li>
            <li @click="del(displayBox.id)">
                <i class="fas fa-trash"></i>
                <span class="tooltip">Delete</span>
            </li>
            <!-- <li @click="selectedItem(index)">
				<span class="cdropdown">
					<button class="paletaColor"><i class="fas fa-ellipsis-v"></i></button>
					<label>
						<input type="checkbox" :name="'op-'+displayBox.id" v-model="displayBox.selected">
						<ul class="list-optiones-extras text-left">
							<li @click="openForm(displayBox.id)">
								<button><i class="fas fa-edit"></i> Open</button>
							</li>
							<li @click="del(displayBox.id)">
								<button><i class="fas fa-times"></i> Delete</button>
							</li>
						</ul>
					</label>
				</span> 
			</li> -->
            <input
                type="file"
                ref="file"
                style="display: none"
                accept="image/*,video/mp4"
                @change="fileImg1"
            />
        </ul>
        <notifications group="noti" position="top center"></notifications>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    name: "footerBox",
    components: {},
props: {
 itemDisplay: { type: Object, required: true },
 inde: { type: Number, required: true },
},
    data() {
        return {
            displayBox: [],
            index: 0,
            user_id: window.master_client,
            org_id: window.localorgdata,
            f: {
                id: 0,
                TypeTemplates: "",
                name: "",
                dpublis: "",
                dfinish: "",
                status: 1,
                url: null,
                orientation: 1,
                archivo: null,
                archivoOld: null,
                img1: null,
                type: window.user_type,
                user_id: window.master_client,
                org_id: window.localorgdata,
            },
            check: false,
        };
    },
    created() {
        if (Object.keys(this.itemDisplay).length > 0) {
            this.displayBox = this.itemDisplay;
            if (this.displayBox.status == 1) {
                this.check = true;
            } else {
                this.check = false;
            }
            this.index = this.inde;
        }
    },
    methods: {
        handleOnInput($event) {
            if ($event.target.checked == true) {
                this.f.status = 1;
            } else {
                this.f.status = 0;
            }
            this.checkedDisplay();
        },
        passDataArray() {
            this.f.id = this.itemDisplay.id;
            this.f.name = this.itemDisplay.name;
            this.f.pin = this.itemDisplay.pin;
            this.f.url = this.itemDisplay.url;
            this.f.archivoOld = this.itemDisplay.name_archivos;
            this.f.TypeTemplates = this.itemDisplay.template_id;
            this.f.dpublis = this.itemDisplay.f_p;
            this.f.dfinish = this.itemDisplay.f_t;
            this.f.orientation = this.itemDisplay.orientacion;
        },
        checkedDisplay() {
            this.passDataArray();
            let url = "/display/check";
            window.master
                .post(url, this.f)
                .then((response) => {
                    console.log(response);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        fileImg1(event) {
            this.f.archivo = event.target.files[0];
            this.f.img1 = event.target.result;
            if (event.target.files[0].size <= 20000000) {
                this.passDataArray();
                this.uploadDisplay();
            } else {
                this.$notify({
                    group: "noti",
                    title: "Error",
                    type: "error",
                    text: "the file exceeds 20 megabytes",
                });
            }
        },
        uploadDisplay() {
            let t = [];
            t.push({ refres: true });
            this.arrays("DisplayUploadRefresh", t);

            let fileSendHeader = {
                headers: { "Content-Type": "multipart/form-data" },
            };
            let pin = null;
            if (this.f.pin != "null" || this.f.pin != null || this.f.pin != 0) {
                pin = this.f.pin;
            }
            let form_Data = new FormData();
            form_Data.append("user_id", this.user_id);
            form_Data.append("org_id", this.org_id);
            form_Data.append("id", this.f.id);
            form_Data.append("name", this.f.name);
            form_Data.append("pin", pin);
            form_Data.append("url", this.f.url);
            form_Data.append("archivo", this.f.archivo);
            form_Data.append("archivoOld", this.f.archivoOld);
            form_Data.append("TypeTemplates", this.f.TypeTemplates);
            form_Data.append("dpublis", this.f.dpublis);
            form_Data.append("dfinish", this.f.dfinish);
            form_Data.append("status", this.itemDisplay.status);
            form_Data.append("orientation", this.f.orientation);
            let url = "/display/form";
            window.master
                .post(url, form_Data, fileSendHeader)
                .then((response) => {
                    let t = [];
                    t.push({ refres: this.f });
                    this.arrays("DisplayUpload", t);
                    this.$swal.fire({
                        icon: "success",
                        title: "successfully update Display!",
                        showConfirmButton: false,
                        timer: 4000,
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        viewDisplay(url) {
            window.open(window.urlDisplay + this.org_id + "/" + url, "_blank");
        },
        openForm(id) {
            this.$router.push({
                name: "DisplayEditdash",
                params: { id: id, edit: "edit", dash: "dash" },
            });
        },
        del(id) {
            this.$swal({
                title: "Delete Display?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                if (result.value) {
                    let url = "/display/deldisplay";
                    if(this.itemDisplay.prefijo == 'slider'){
                        url = '/display/delSlider';
                    }
                    window.master
                        .post(url, {
                            user_id: this.user_id,
                            org_id: this.org_id,
                            id: id,
                        })
                        .then((response) => {
                            let r = response.data.status;
                            let t = [];
                            t.push({ id: id });
                            this.arrays("DisplayDelete", t);
                            // console.log(r)
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            });
        },
        selectedItem() {
            this.displayBox.selected = true;
        },
        arrays(name, data) {
            let k = [];
            k.push({ function: name, data: data });
            this.$store.commit("ModApps/display/set_displayEdit", k);
        },
    },
    computed: {
        ...mapGetters("ModApps/display", ["get_displayEdit"]),
    },
};
</script>
<style lang="scss" scoped>
.btn-switch {
    display: block;
    margin-top: 3%;
    margin-right: 30%;
    margin-left: 30%;
}
.onoffswitch {
    position: absolute;
    width: 60px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
.onoffswitch-checkbox {
    display: none;
}
.onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid #585858;
    border-radius: 20px;
}
.onoffswitch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.1s ease-in 0s;
}
.onoffswitch-inner:before,
.onoffswitch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 20px;
    padding: 0;
    line-height: 20px;
    font-size: 12px;
    color: white;
    font-family: Trebuchet, Arial, sans-serif;
    font-weight: bold;
    box-sizing: border-box;
}
.onoffswitch-inner:before {
    content: "On";
    padding-right: 30px;
    padding-left: 10px;
    background-color: #5ac146;
    color: #ffffff;
}
.onoffswitch-inner:after {
    content: "Off";
    padding-right: 10px;
    background-color: #d65353;
    color: #fff;
    text-align: right;
}
.onoffswitch-switch {
    display: block;
    width: 15px;
    height: 15px;
    margin: 6px;
    background: #fff;
    position: absolute;
    top: -3px;
    bottom: 0;
    right: 33px;
    border: 1px solid #585858;
    border-radius: 20px;
    transition: all 0.1s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px;
}

span.status {
    justify-content: flex-end;
}
span.status span.badge {
    border-radius: 0px;
    font-size: 14px;
}

.card-footer {
    text-align: right;
    padding: 3px 10px;
}
ul.list-options {
    list-style: none;
    width: auto;
    display: inline-block;
    margin: 0px;
    padding: 0px;
    opacity: 1;
}
ul.list-options li {
    display: inline-block;
    padding: 0px 10px;
    color: #555;
}
ul.list-options li:hover {
    cursor: pointer;
}
.item-taks:hover ul.list-options,
.item-taks.active ul.list-options {
    opacity: 1;
}
ul.list-options li .cdropdown > button {
    padding: 0;
    background-color: transparent;
    border: 0;
    color: #555;
}
ul.list-options li .cdropdown > button::before {
    border-top: 0px solid transparent;
}
ul.list-options li button {
    background: transparent;
    border: 0;
    color: #555;
    box-shadow: none;
}

ul.list-options li button:hover,
ul.list-options li button:focus,
ul.list-options li button:active {
    border: 0px solid transparent;
    outline: none;
}
.paletaColor:hover {
    cursor: pointer;
}

button,
select {
    text-transform: none;
}
button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
ul.list-options li {
    position: relative;
    &:hover {
        .tooltip {
            opacity: 1;
            visibility: visible;
        }
    }
}
.tooltip {
    background-color: #333;
    font-size: 12px;
    padding: 5px 10px;
    color: #fff;
    position: absolute;
    left: 50%;
    top: 100%;
    margin-top: 5px;
    transform: translateX(-50%);
    width: auto;
    z-index: 15;
    border-radius: 2px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    &:before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 7.5px 6px 7.5px;
        border-color: transparent transparent #333 transparent;
        position: absolute;
        left: 50%;
        margin-left: -7.5px;
        top: -6px;
    }
}
ul.list-optiones-extras li button {
    padding: 5px 10px;
}
.tooltip {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
span.cdropdown.btnRinvoExp {
    position: relative;
}
span.cdropdown.btnRinvoExp button {
    border: 0px;
    color: #000;
    padding-right: 20px;
    padding-left: 5px;
    font-size: 14px;
}
span.cdropdown.btnRinvoExp button:before {
    border-top: 5px solid transparent;
    content: initial;
}
</style>
