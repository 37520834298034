<template>
    <div class="card">
        <div class="card-header" v-if="!isLoading">
            <h3 v-if="fileID==null || editFiles">Upload Contract</h3>
            <button v-else-if="type_contract=='pdf' && !editFiles" type="button" class="btn btn-success btn-lg" @click.prevent="openPdf()">
                <i class="fas fa-expand-arrows-alt fa-lg" style="margin-right: 10px;"></i> Open Full Screen
            </button>
            <button v-else-if="type_contract=='img' && !editFiles" type="button" class="btn btn-success btn-lg" @click.prevent="openImages()">
                <i class="fas fa-expand-arrows-alt fa-lg" style="margin-right: 10px;"></i> Open Full Screen
            </button>
            <button v-if="(type_contract=='img' || type_contract=='pdf') && !editFiles && !validStatus" type="button" class="btn btn-info btn-lg" @click.prevent="replaceFiles()" style="margin-left: 10px;">
                Replace files
            </button>
        </div>
        <div class="card-body" style="padding-top: 0;">
            <div class="row">
                <Loader class="loading_pdf" v-if="isLoading" :getProgress="loadProgress"></Loader>
                <div class="col-12" v-else>
                    <button class="btn btn-info btnUploadPdf" v-if="fileID==null || editFiles" @click="$refs.filepdf.click()">Upload PDF <i class="fas fa-file-pdf" style="margin-left: 10px;"></i></button>
                    <br>
                    <button class="btn btn-info btnUploadPdf" v-if="fileID==null || editFiles" @click="$refs.fileImage.click()">Upload Images <i class="fas fa-image" style="margin-left: 10px;"></i></button>
                    <!-- <button class="btn btn-danger btnUploadPdf" v-else @click="delFilePdf()">Delete Document <i class="fas fa-file-pdf" style="margin-left: 10px;"></i></button> -->
                    <input type="file"
                        id="filepdf"
                        ref="filepdf"
                        accept=".pdf"
                        style="display: none;"
                        @change="loadPdf"
                    />
                    <input type="file"
                        id="fileImage"
                        ref="fileImage"
                        accept=".jpg,.jpeg,.png"
                        multiple
                        style="display: none;"
                        @change="subirIMG($event)"
                    />
                </div>
                <div class="col-12" v-if="pdfsrc && type_contract=='pdf'">
                    <iframe :src="pdfsrc" style="width: 100%;height: 80vh; border: none;">
                        Oops! an error has occurred.
                    </iframe>
                </div>
                <div class="col-12" v-else-if="type_contract=='img'">
                    <draggable
                    v-model="images"
                    tag="div"
                    class="contact_images"
                    @change="checkMove(images)"
                    handle=".handle"
                    >
                        <div v-for="(img, idx) in images" :key="idx" class="image_item">
                            <div class="btnmove handle"><i class="fas fa-ellipsis-v"></i></div>
                            <img  :alt="img" :src="urlFile+'small/'+img" />
                        </div>
                    </draggable>
                    <transition
                        enter-active-class="animated fadeIn"
                        leave-active-class="animated fadeOut"
                    >
                        <div class="lb-modal" v-if="indexImagen >= 0">
                            <button
                                class="btn btn-outline-danger btn-sm lb-modal-close"
                                @click="close"
                            >
                                <i class="fas far fa-times fa-2x"></i>
                            </button>
                            <button class="btn btn-outline-primary lb-modal-prev" @click="prev">
                                <i class="fas far fa-angle-left fa-3x"></i>
                            </button>
                            <button class="btn btn-outline-primary lb-modal-next" @click="next">
                                <i class="fas far fa-angle-right fa-3x"></i>
                            </button>
                            <div class="btn-group op-avanzadas">
                                <button
                                    type="button"
                                    class="btn btn-link lb-modal-rotate-left"
                                    @click="rotate(1)"
                                >
                                    <i class="fas fa-undo"></i>
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-link lb-modal-rotate-right"
                                    @click="rotate(2)"
                                >
                                    <i class="fas fa-undo rotate_custom"></i>
                                </button>
                            </div>
                            <div class="lb-modal-img" @click="close">
                                <img :src="srcImage" :style="girarImg" id="ImgGallery" :alt="srcImage"/>
                                <div class="spinner spinner-dots-wave" v-if="loading">
                                    <span class="badge badge-primary rounded-circle w-10 h-10">
                                        <i class="sr-only">&nbsp;</i>
                                    </span>
                                    <span class="badge badge-primary rounded-circle w-10 h-10">
                                        <i class="sr-only">&nbsp;</i>
                                    </span>
                                    <span class="badge badge-primary rounded-circle w-10 h-10">
                                        <i class="sr-only">&nbsp;</i>
                                    </span>
                                    <span class="badge badge-primary rounded-circle w-10 h-10">
                                        <i class="sr-only">&nbsp;</i>
                                    </span>
                                    <span class="badge badge-primary rounded-circle w-10 h-10">
                                        <i class="sr-only">&nbsp;</i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
// @ts-ignore
import draggable from 'vuedraggable';
// @ts-ignore
import Loader from "@/core/components/shared/Loader.vue";
export default {
    components: {
        Loader,
        draggable
    },
    data() {
        return {
            item: null,
            type_contract: null, // contract pdf
            name: '',
            file_name: null,
            fileUpload: null,
            loadProgress: 0,
            isLoading: false,
            pdfsrc: null,
            fileID: null,
            urlFile: null,
            images: [],
            idsrc: 0,
            indexImagen: -1,
            srcImage: null,
            loading: null,
            rotar: 0,
            girarImg: "",
            idx: 0,
            tam: 1000,
            tamMin: 1000,
            tamMax: 1000,
            events: [],
            editFiles: false,
            statusNoEditables: [7, 11],
        };
    },
    created() {
			let t = this;
			t.item = t.$store.getters['core/getOpenModal'].data.item;
            console.log('contract_id', t.item.contract_id)
            t.$store.commit("core/setLoadingModal", false);
            t.getFileContract();
    },
    methods : {
        getFileContract() {
            let t = this;
            t.isLoading= true;
            window.master.post("salesGetFileContract", {
                user_id: window.master_client,
                org_id: window.localorgdata,
                sales_id: t.item.id,
                id: t.item.contract_id,
            })
            .then((response) => {
                console.log('salesGetFileContract', response.data);
                t.isLoading= false;
                if(response.data.get){
                    t.type_contract = response.data.get.type_contract;
                    t.urlFile = response.data.url;
                    if(t.type_contract=='pdf'){
                        t.fileID = response.data.get.id;
                        t.pdfsrc = response.data.url+response.data.get.file_name;
                    }
                    else if(t.type_contract=='img'){
                        t.fileID = response.data.get.id;
                        let images = JSON.parse(response.data.get.file_name)
                        t.images = Object.values(images);
                    }else{
                        t.editFiles = true;
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                t.isLoading= false;
            });
        },
        loadPdf(event){
            let t = this;
            t.isLoading= true;
            let target = event.target;
            console.log('change', event);
            t.inputFileStatus=1;
            let pdf = target.files[0];
            if(pdf){
                t.fileUpload=pdf;
                console.log('loadPdf', pdf);
                t.saveFilePdf();
            }
        },
        saveFilePdf() {
            let t = this;
            t.isLoading= true;
            let formData = new FormData();
            formData.append("user_id", window.master_client);
            formData.append("org_id", window.localorgdata);
            formData.append("sales_id", t.item.id);
            formData.append("type", 'pdf');
            formData.append("name", t.name);
            formData.append("file_name", t.file_name);
            formData.append("file", t.fileUpload);
            formData.append("contract_id", t.item.contract_id);
            let url = 'salesGenerateContract';
            if(t.editFiles){
                url = 'salesContractFilesEdit';
            }
            t.loadProgress=0;
            window.master.post(url, formData, {
                headers: {
                common: {Authorization: 'Bearer ' + window.localtoken_M},
                post: {'Content-Type': 'multipart/form-data'}
            },
            onUploadProgress: (progressEvent) => {
                let scanPer = Math.floor(
                    (progressEvent.loaded*100 / progressEvent.total)
                );
                t.loadProgress=scanPer;
            }
            })
            .then((response) => {
                console.log('salesGenerateContract', response.data);
                if(response.data.status){
                    // t.getFileContract();
                    t.$notify({
                        group: "noti",
                        type: "success",
                        title: "Successfully saved",
                        // text: "Scope of Work",
                    });
                    t.$parent.close();
                }
                t.isLoading= false;
                // t.$refs.filepdf.value = null;
            })
            .catch((error) => {
                console.log(error);
                t.isLoading= false;
                t.$refs.filepdf.value = null;
            });
        },
        subirIMG(event) {
            let t = this;
            if(event.target.files.length){
                t.isLoading= true;
                t.loadProgress=0;
                let fileSendHeader = { headers: { "Content-Type": "multipart/form-data" } };
                let formData = new FormData();
                let cont = 0;
                let total = t.images.length;
                for (let i in event.target.files) {
                    if (event.target.files.hasOwnProperty(i)) {
                        total++;
                        if(total<=20){
                            cont++;
                            formData.append("image" + cont, event.target.files[i]);
                        }
                    }
                }
                formData.append("user_id", window.master_client);
                formData.append("org_id", window.localorgdata);
                formData.append("sales_id", t.item.id);
                formData.append("type", 'img');
                formData.append("name", t.name);
                formData.append("nombreIMG", "image");
                formData.append("contIMG", cont);
                formData.append("folder", 'sales');
                formData.append("delIMG", "image");
                formData.append("contDel", 0);
                formData.append("contract_id", t.item.contract_id);
                let url = 'salesGenerateContract';
                if(t.editFiles){
                    url = 'salesContractFilesEdit';
                }
                window.master.post(url, formData, {
                    headers: {
                    common: {Authorization: 'Bearer ' + window.localtoken_M},
                    post: {'Content-Type': 'multipart/form-data'}
                },
                onUploadProgress: (progressEvent) => {
                    let scanPer = Math.floor(
                        (progressEvent.loaded*100 / progressEvent.total)
                    );
                    t.loadProgress=scanPer;
                }
                })
                .then((response) => {
                    console.log('salesGenerateContract', response.data)
                    t.$store.commit("core/setLoadingModal", false);
                    if(total>20){
                        t.$notify({
                            group: 'noti',
                            type: 'error',
                            title: 'ERROR',
                            text: 'Maximum limit 20 images',
                        });
                    }
                    if(response.data.status){
                        t.$notify({
                            group: "noti",
                            type: "success",
                            title: "Successfully saved",
                            // text: "Scope of Work",
                        });
                        t.$parent.close();
                    }
                    t.isLoading= false;
                })
                .catch((error) => {
                    console.log(error);
                    t.isLoading= false;
                    t.$store.commit("core/setLoadingModal", false);
                    t.$refs.fileImage.value = null;
                });
            }
        },
        delFilePdf(){
            let t = this;
            t.$swal({
				title: "Delete document PDF?",
				text: "Are you sure?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				confirmButtonText: "Yes, Delete!",
			}).then((result) => {
				if (result.value) {
                    t.isLoading= true;
                    window.master.post("delSaleFilesLinks", {
                        user_id: window.master_client,
                        org_id: window.localorgdata,
                        sales_id: t.item.id,
                        type: t.type,
                        id: t.fileID,
                    })
                    .then((response) => {
                        console.log('delSaleFilesLinks', response.data);
                        t.getFileContract()
                    })
                    .catch((error) => {
                        console.log(error);
                        t.isLoading= false;
                    });
                }
            });
        },
        openPdf() {
			let t = this;
            console.log('open', t.pdfsrc);
            window.open(t.pdfsrc, "_blank");
        },
        checkMove(list){
            let t = this;
            console.log('checkMove', list)
            window.master.post("salesContractMoveImg", {
                user_id: window.master_client,
                org_id: window.localorgdata,
                sales_id: t.item.id,
                id: t.item.contract_id,
                images: list,
            })
            .then((response) => {
                console.log('salesContractMoveImg', response.data);
            })
            .catch((error) => {
                console.log(error);
            });
        },
        openImages() {this.show(0);},
        bind() {
            if (this.events.length > 0) return;
            this.events.push([
                "keydown",
                (e) => {
                    if (this.indexImagen < 0) return;
                    if (e.keyCode === 37) {
                        this.prev();
                    } else if (e.keyCode === 39) {
                        this.next();
                    } else if (e.keyCode === 27) {
                        this.close();
                    }
                },
            ]);
            this.events.forEach((e) => {
                window.addEventListener(e[0], e[1]);
            });
        },
        show(i) {
            document.body.style.overflowY = "hidden";
            if (i >= this.images.length) {
                i = 0;
            }
            if (i < 0) {
                i = this.images.length - 1;
            }
            this.loading = true;
            this.bind();
            this.indexImagen = i;
            console.log('indexImagen', i);
            this.tam = this.tamMin; // reiniciar tamaño
            if(this.images.length>0){
                var src = this.urlFile +'large/'+ this.images[i];
                var idsrc = i;
                let t = this;
                var img = new Image();
                img.src = src;
                img.onload = () => {
                    t.rotar = 0;
                    t.girarImg = "";
                    t.loading = false;
                    t.srcImage = src;
                    t.idsrc = idsrc;
                    console.log('img load', src)
                    let imgItem = document.getElementById("ImgGallery");
                    if (imgItem) {
                        imgItem.style.width = t.tam + "px";
                    }
                };
            }
        },
        next() {
            this.show(this.indexImagen + 1);
        },
        prev() {
            this.show(this.indexImagen - 1);
        },
        rotate(r) {
            if (r == 1) {
                r = -90;
            }
            if (r == 2) {
                r = 90;
            }
            this.rotar += r;
            this.girarImg =
                `-moz-transform: rotate(` +
                this.rotar +
                `deg);` +
                `-o-transform: rotate(` +
                this.rotar +
                `deg);` +
                `-webkit-transform: rotate(` +
                this.rotar +
                `deg);` +
                `transform: rotate(` +
                this.rotar +
                `deg);`;
            //console.log('rotate', this.rotar, 'girarImg='+this.girarImg);
        },
        close() {
            this.indexImagen = -1;
            this.events.forEach((e) => {
                window.removeEventListener(e[0], e[1]);
            });
            this.events = [];
            document.body.style.overflowY = "initial";
        },
        replaceFiles(){
            let t = this;
            t.editFiles= true;
            t.pdfsrc= null;
            t.images= [];
        },
    },
    computed : {
        validStatus : function(){
            console.log('status', (this.statusNoEditables.indexOf(this.item.status)>-1))
            return (this.statusNoEditables.indexOf(this.item.status)>-1);
        },
    }
}
</script>
<style lang="scss" scoped>
.loading_pdf{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0 0 0 / 25%);
    .container-progress{
        top: 0px;
        width: 80%;
        .progress{
            height: 10px;
        }
    }
}
.btnUploadPdf{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 1.5rem;
}

.contact_images {
    display: flex;
    flex-wrap: wrap;
}

.image_item {
    position: relative;
    margin: 10px;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.4);
}
.contact_images img {width: 100%;}
.btnmove {
    position: absolute;
    left: 0px !important;
    top: 0px !important;
    width: 13px;
    height: 100%;
    cursor: move;
    background-color: #e1f2fb;
    z-index: 10;
    color: #afafaf;
    font-size: 12px;
    line-height: 75px;
    text-align: center;
    vertical-align: middle;
    font-weight: normal;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lb-modal {
    transition: opacity .2s ease;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    min-height: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, .8);
    display: block;
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.lb-modal-close {
    position: absolute;
    right: 20px;
    top: 10px;
    color: #fff;
    z-index: 999;
    text-shadow: 2px 2px 2px #000;
    padding: 0.3rem 0.5rem;
}
.lb-modal-next, .lb-modal-prev {
    position: absolute;
    top: 50%;
    left: 20px;
    margin-top: -25px;
    /* width: 50px;
    height: 50px; */
    z-index: 999;
    cursor: pointer;
    color: #fff;
    background-color: transparent;
    border: none;
    text-shadow: 2px 2px 2px #000;
}
.lb-modal-next {
    left: auto;
    right: 20px;
}
.lb-modal-img {
    position: absolute;
    top: 10px;
    left: 70px;
    right: 70px;
    bottom: 10px;
    text-align: center;
}
.lb-modal-img:before {
    display: inline-block;
    content: "";
    vertical-align: middle;
    height: 100%;
}
.lb-modal-img img {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
    object-fit: contain;
}
.lb-modal-img .spinner {
    width: 150px;
    position: absolute;
    margin-left: -75px;
    top: 50%;
    left: 50%;
    z-index: 1;
}
</style>
