<template>
    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col-6" style="padding-top: 10px">
                    <h3 class="card-title">Edit Tags <span class="badge badge-success" v-if="item!=null">#{{item.idx}}</span></h3>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="selecttags">
                <multiselect
                    v-model="item.category"
                    :class="['form-control', 'vselect'+item.id]"
                    tag-placeholder="Add new"
                    placeholder="Add"
                    label="category_name"
                    track-by="id"
                    :options="CategoryList"
                    :multiple="true"
                    :taggable="true"
                    :clear-on-select="false"
                    :ref="'vselect'+item.id"
                    select-label="Choose"
                    deselect-label="Remove"
                    @tag="addTag"
                    @input="changeTags"
                >
                    <template slot="tag" slot-scope="props">
                        <span :class="[
                            'multiselect__tag', 
                            (item.category.length<=4) ? 'wAuto' : 
                                (item.category.length<6) ? 'w60' : 
                                    (item.category.length<7) ? 'w50' : 
                                        (item.category.length<8) ? 'w40' : 
                                            (item.category.length<13) ? 'w30' : 
                                                (item.category.length<16) ? 'w20' : ''
                            ]"
                            :title="getOptionDisplay(props.option)"
                        >
                            <span v-text="getOptionDisplay(props.option)"></span>
                            <i tabindex="1" @keydown.enter.prevent="removeElement(props.option, item)"  @mousedown.prevent="removeElement(props.option, item)" class="multiselect__tag-icon"></i>
                        </span>
                    </template>
                </multiselect>
            </div>
        </div>
    </div>
</template>
<script>
    import Multiselect from 'vue-multiselect';
    import "./selectTags.scss";
    export default {
        name: "selectTags",
        components: {
            Multiselect,
        },
        data(){
            return {
                item: null,
                isLoading: false,
                CategoryList:[],
            }
        },
        created(){
            let g = this.$store.getters['core/getOpenModal'];
            this.item = g.data;
            this.getTaskId();
        },
        methods: {
            getTaskId(){
                let t = this;
                return new Promise((resolve, reject) => { 
                    window.billing
                    .post("getTagsAll", {
                        org_id: window.localorgdata,
                    })
                    .then((response) => {
                        //console.log('getTagsAll', response.data);
                        let r = response.data;
                        t.CategoryList=[];
                        if(r.list.length>0){
                            t.CategoryList=r.list;
                            t.modelarTags();
                        }
                        resolve(true); 
                    })
                    .catch((error) => {
                        console.log(error);
                        resolve(false);
                    });
                });
            },
            modelarTags(){
                let t = this;
                let category_ids = [];
                let tagsIds = [];
                if(t.item.category_ids!=null && t.item.category_ids.length>0){
                    category_ids = t.item.category_ids.split(',');
                    tagsIds = t.CategoryList.filter(r => {
                        for (let c in category_ids) {
                            if(category_ids[c] == r.id){
                                return r;
                            }
                        }
                    });
                }
                t.item.category= tagsIds;
            },
            addTag (newTag) {
                let t = this;
                let id = 'new999999';
                const tag = {
                    id: id,
                    category_name: newTag,
                }
                console.log('new tag', tag);
                t.CategoryList.push(tag);
                window.billing
                .post("addTagByUser", {
                    id: t.item.id,
                    user_idd: window.master_client,
                    user_id: window.localorgdata,
                    org_id: window.localorgdata,
                    oauth_m: window.oauth_m,
                    campo: 'category_ids',
                    category_name: newTag,
                })
                .then((response) => {
                    console.log('addTagByUser', response.data.tag);
                    for (let r in t.CategoryList) {
                        if(t.CategoryList[r].id==id){
                            t.CategoryList[r].id = response.data.tag.id;
                            t.item.category.push(t.CategoryList[r]);
                            t.item.category_ids = t.item.category.toString();
                            console.log('update tag ID', t.CategoryList[r]);
                        }
                    };
                    t.$parent.$parent.$parent.getListTask(1);
                })
                .catch((error) => {
                    console.log(error)
                });
            },
            changeTags(values){
                let t = this;
                let item = t.item;
                console.log('changeTags', values, item)
                let category_ids = [];
                let tags = [];
                let itemNone = null;
                tags = values.filter(r => {
                    if(r.id==1){itemNone=r;}
                    if(r.id>1){
                        category_ids.push(r.id);
                        return r;
                    }
                });
                item.category_ids = (category_ids.length>0) ? category_ids.toString().replace('1,', '') : '';
                t.$parent.$parent.$parent.saveChangeInput('category_ids', item);
            },            
            getOptionDisplay(text){ return text.category_name; },
            removeElement: function(element, item) {
                this.item=item;
                this.$refs['vselect'+item.id].removeElement(element);
                console.log('removeElement', item.category)
            },
        },
    }
</script>
<style lang="scss" scoped>
</style>
