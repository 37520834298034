<template>
    <div>
        <aside
            class="left-sidebar"
            data-sidebarbg="skin5"
            v-bind:class="{ min: activarLateral }"
        >
            <div class="scroll-sidebar">
                <nav class="sidebar-nav">
                    <ul id="sidebarnav">
                        <li
                            class="sidebar-item"
                            v-for="(i, index) in menuLateral"
                            :key="index"
                            v-bind:class="{ sub: i.type === 2 }"
                        >
                            <router-link
                                :to="i.ruta"
                                exact-active-class="active"
                                class="sidebar-link waves-effect waves-dark"
                                v-if="i.type == 1"
                            >
                                <i :class="i.icon"></i>
                                <span class="hide-menu">{{ i.name }}</span>
                            </router-link>
                            <div
                                v-else-if="i.type == 2"
                                class="sidebar-link has-arrow waves-effect waves-dark"
                                v-on:click="activeSubMenu(i)"
                                v-bind:class="{ active: i.mActive }"
                                aria-expanded="false"
                            >
                                <i :class="i.icon"></i>
                                <span class="hide-menu">{{ i.name }} </span>
                            </div>

                            <ul
                                aria-expanded="false"
                                class="collapse first-level"
                                v-bind:class="{ in: i.mActive }"
                                v-if="i.items.length>0"
                            >
                                <li
                                    class="sidebar-item"
                                    v-for="(item, index2) in i.items"
                                    :key="index2"
                                >
                                    <router-link
                                        :to="item.ruta"
                                        exact-active-class="active"
                                        class="sidebar-link"
                                    >
                                        <span class="hide-menu">{{ item.name }}</span>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <li class="sidebar-item" v-if="planActual==planStandardId || planActual==planFreeId">
                            <div class="sidebar-link waves-effect waves-dark changePlan"
                                @click="showChangePlan()"
                                style=""
                            >
                                <i class="fas fa-gem"></i>
                                <span class="hide-menu">Update Now</span>
                            </div>
                        </li>
                    </ul>
                </nav>
                <div class="btnAsideAction" @click="ShowHiddenAside">
                    <i
                        class="fas fa-chevron-left"
                        v-bind:class="{ min: activarLateral }"
                    ></i>
                </div>
            </div>
        </aside>
        <modal
            name="modal-form"
            height="auto"
            :resizable="true"
            :adaptive="true"
            :scrollable="true"
        >
            <i
                class="fas fa-times"
                style="
                    position: absolute;
                    top: 9px;
                    right: 11px;
                    font-size: 24px;
                    cursor: pointer;
                    z-index: 99;
                "
                @click="CloseModal"
            ></i>
            <component :is="renderComponent"></component>
        </modal>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    name: "Aside",
    components: {
    },
    data() {
        return {
            isMoneyIn: true,
            isMoneyOut: false,
            activarLateral: false,
            winnerWidth: window.innerWidth,
            menuLateral: {},
            renderComponent: "",
            inputActive: null,
            indiceIn: 0,
            indiceOut: 0,
            PlanPremium: false,
            premiumProfile: false,
            planesPro: [],
            planFreeId: 8,
            planStandardId: 5,
        };
    },
    created() {
        let t=this;
        // valid premium planID
        // console.log('get_isPremiumProfile', t.$store.getters['ModApps/Marketea/get_isPremiumProfile']);
        t.planesPro = t.$store.getters['ModApps/Marketea/get_planesPro'];
        t.PlanPremium = (t.$store.getters['core/apps/getPlanIdApp']==6 || t.$store.getters['core/apps/getPlanIdApp']==11);
        t.premiumProfile = t.$store.getters['ModApps/Marketea/get_isPremiumProfile'];
        // console.log('PlanPremium', t.PlanPremium);
        t.planActual = t.$store.getters['core/apps/getPlanIdApp'];
        if(t.planActual==7){
            t.PlanPremium = true;
            // console.log('Plan sponsored, dando permiso de premium', t.PlanPremium);
        }
        // console.log('created getModulesMenu', t.getModulesMenu)
    },
    methods: {
        showChangePlan(){
            let t = this;
            let appData = {
                app_id: 8,
                show: true,
                infoApp: null,
                planes: [],
            }
            t.$store.commit("core/set_showSuscription", appData);
        },
        ShowHiddenAside() {
            this.activarLateral = !this.activarLateral;
            this.$emit("activarLateral", this.activarLateral);
        },
        estructuraMenu() {
            let t= this;
            this.menuLateral = []
            this.menuRutas = {
                1: {
                    name: "Smart Card",
                    ruta: { name: "smartCard" },
                    icon: "fas fa-id-card",
                    type: 1,
                    m_id: 13,
                    items: [],
                },
                3: {
                    name: "Leads",
                    ruta: { name: "LeadsMark" },
                    icon: "fas fa-user",
                    type: 1,
                    m_id: 1,
                    items: [],
                },
                4: {
                    name: "Sales",
                    ruta: { name: "Sales" },
                    icon: "fas fa-chart-line",
                    type: 2,
                    m_id: 2,
                    items: [
                        {
                            name: "Retail",
                            ruta: { name: "Sales" },
                            m_id: 15,
                        },
                        {
                            name: "Insurance",
                            ruta: { name: "sales_insurance" },
                            m_id: 15,
                        },
                    ],
                },
                9: {
                    name: "Forms",
                    icon: "fas fa-table",
                    ruta: { name: "forms" },
                    type: 1,
                    mActive: false,
                    items: [
                        {
                            name: "List",
                            ruta: { name: "listForms" },
                            m_id: 7,
                        },
                        {
                            name: "Alert & Response",
                            ruta: { name: "AlertForm" },
                            m_id: 8,
                        },
                        {
                            name: "Notificacions",
                            ruta: { name: "notiForms" },
                            m_id: 9,
                        },
                    ],
                },
                10: {
                    name: "Plugins",
                    ruta: { name: "plugins" },
                    icon: "fas fa-puzzle-piece",
                    type: 2,
                    mActive: false,
                    m_id: 8,
                    items: [
                        {
                            name: "Projects",
                            ruta: { name: "ProjectsAll" },
                            m_id: 9,
                        },
                        {
                            name: "Gallery",
                            ruta: { name: "bsite_gallery" },
                            m_id: 11,
                        },
                        {
                            name: "bMenu",
                            ruta: { name: "bsite_bmenu" },
                            m_id: 12,
                        },
                    ],
                },
                11: {
                    name: "Gutters",
                    ruta: { name: "cal_gutters" },
                    icon: "fas fa-puzzle-piece",
                    type: 2,
                    mActive: false,
                    m_id: 13,
                    items: [
                        {
                            name: "Estimate",
                            ruta: { name: "cal_gutter_est" },
                            m_id: 14,
                        },
                        {
                            name: "Setting",
                            ruta: { name: "cal_gutter_setting" },
                            m_id: 15,
                        },
                    ],
                },
                12: {
                    name: "Setup",
                    ruta: { name: "MSetup" },
                    icon: "fas fa-cog",
                    type: 1,
                    m_id: 12,
                    items: [],
                },
            };
            for (const e in t.getModulesMenu) {
                if (Object.hasOwnProperty.call(t.getModulesMenu, e)) {
                    let menuPlan = t.getModulesMenu[e];
                    // console.log('buscar module_key', menuPlan.module_key)
                    for (const r in t.menuRutas) {
                        if(t.menuRutas[r].ruta.name == menuPlan.module_key){
                            // console.log('encontrado', t.menuRutas[r].ruta.name)
                            let setItem = {
                                name: menuPlan.module_name,
                                ruta: { "name": menuPlan.module_key },
                                icon: t.menuRutas[r].icon,
                                type: menuPlan.type_mod,
                                m_id: menuPlan.id,
                                mActive: false,
                                items: []
                            };
                            if(menuPlan.type_mod==2){
                                let subRutas = t.menuRutas[r].items;
                                // if(Object.keys(subRutas).length>0){
                                if(subRutas.length>0){
                                    // console.log('validando submenu', subRutas, menuPlan.subMenu
                                    for (const i in menuPlan.subMenu) {
                                        if (Object.hasOwnProperty.call(menuPlan.subMenu, i)) {
                                            const subMenu = menuPlan.subMenu[i];
                                            for (const s in subRutas) {
                                                if (Object.hasOwnProperty.call(subRutas, s)) {
                                                    if(subRutas[s].ruta.name==subMenu.module_key){
                                                        if(menuPlan.plugins==1) {
                                                            let payload = {id: subMenu.id, slug: subMenu.module_key};
                                                            t.$store.dispatch("ModApps/Marketea/search_plugins", payload).then(p => {
                                                                if(p){
                                                                    console.log('submenu encontrado en plugins', p)
                                                                    let setSubMenu={
                                                                        name: subMenu.module_name,
                                                                        ruta: { name: subMenu.module_key },
                                                                        m_id: subMenu.id,
                                                                    }
                                                                    setItem.items.push(setSubMenu);
                                                                }
                                                            });
                                                        }else{
                                                            let setSubMenu={
                                                                name: subMenu.module_name,
                                                                ruta: { name: subMenu.module_key },
                                                                m_id: subMenu.id,
                                                            }
                                                            setItem.items.push(setSubMenu);
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            t.menuLateral.push(setItem);
                        }
                    }
                }
            }
            // console.log('menuPlan', t.menuLateral)
        },
        ShowForm() {
            this.$modal.show("modal-form");
            this.renderComponent = "Formtest";
        },
        CloseModal() {
            this.$modal.hide("modal-form");
            this.renderComponent = "";
        },
        validarMenu() {
            let menu = [];
            if (window.user_type == 0) {
                menu = this.menuLateral;
                this.indiceIn = 3;
                this.indiceOut = 4;
            } else {
            }
        },
        activeSubMenu(i) {
            let menuLateral = this.menuLateral   
            for (const j in menuLateral) {
                if (menuLateral[j].mActive !== undefined) {
                    if (menuLateral[j].mActive && menuLateral[j] != i) { 
                        this.menuLateral[j].mActive = false;
                    } 
                    if(menuLateral[j] == i) {
                        this.menuLateral[j].mActive = !this.menuLateral[j].mActive;
                    }
                }
            }
        },
    },
    mounted: function () {
        let t = this;
        t.estructuraMenu();
        t.validarMenu();
        if (t.winnerWidth < 1050) {
            t.ShowHiddenAside();
        }
    },
    watch: {
        getModulesMenu: function(){
            let t = this;
            // console.log('watch getModulesMenu', t.getModulesMenu)
            t.estructuraMenu();
        }
    },
    computed: {
        ...mapGetters("core", { getPusher: "get_accitonPusher"}),
        ...mapGetters("core/apps/", { getModulesMenu: "getModulesMenu" })
    },
};
</script>
<style lang="scss" scoped>
    .left-sidebar {
        position: fixed;
    }
    .btnAsideAction {
        position: absolute;
        bottom: -18px;
        top: initial;
        left: 0;
        width: 100%;
        height: 40px;
        background-color: #343435;
        cursor: pointer;
        padding: 10px 4px 4px 4px;
        line-height: 21px;
        text-align: center;
        color: #fff;
    }
    aside.left-sidebar.min {
        width: 60px;
        z-index: 100;
    }
    aside.left-sidebar.min .scroll-sidebar {
        height: 100%;
    }
    aside.left-sidebar.min .scroll-sidebar .btnAsideAction {
        bottom: 0%;
    }
    aside.left-sidebar.min .scroll-sidebar .btnAsideAction i:before {
        content: "\f054";
    }
    aside.left-sidebar.min .scroll-sidebar nav.sidebar-nav ul#sidebarnav li.sidebar-item,
    aside.left-sidebar.min
        .scroll-sidebar
        nav.sidebar-nav
        ul#sidebarnav
        li.sidebar-item
        ul.collapse.first-level
        li.sidebar-item {
        width: 60px;
        transition: all 0.5s ease-out;
        position: relative;
    }

    aside.left-sidebar.min
        .scroll-sidebar
        nav.sidebar-nav
        ul#sidebarnav
        li.sidebar-item:hover
        ul.collapse.first-level,
    aside.left-sidebar.min
        .scroll-sidebar
        nav.sidebar-nav
        ul#sidebarnav
        li.sidebar-item:hover
        ul.collapse.first-level.in {
        position: absolute;
        top: 0px;
        left: 40px;
        width: 170px;
    }

    aside.left-sidebar.min
        .scroll-sidebar
        nav.sidebar-nav
        ul#sidebarnav
        li.sidebar-item
        a.sidebar-link {
    }
    aside.left-sidebar.min
        .scroll-sidebar
        nav.sidebar-nav
        ul#sidebarnav
        li.sidebar-item
        a.sidebar-link
        span.hide-menu,
    aside.left-sidebar.min
        .scroll-sidebar
        nav.sidebar-nav
        ul#sidebarnav
        li.sidebar-item
        div.sidebar-link
        span.hide-menu,
    aside.left-sidebar.min
        .scroll-sidebar
        nav.sidebar-nav
        ul#sidebarnav
        li.sidebar-item
        ul.collapse.first-level,
    aside.left-sidebar.min
        .scroll-sidebar
        nav.sidebar-nav
        ul#sidebarnav
        li.sidebar-item
        ul.collapse.first-level.in {
        display: none;
    }
    aside.left-sidebar.min
        .scroll-sidebar
        nav.sidebar-nav
        ul#sidebarnav
        li.sidebar-item:hover
        ul.collapse.first-level,
    aside.left-sidebar.min
        .scroll-sidebar
        nav.sidebar-nav
        ul#sidebarnav
        li.sidebar-item:hover
        ul.collapse.first-level.in,
    aside.left-sidebar.min
        .scroll-sidebar
        nav.sidebar-nav
        ul#sidebarnav
        li.sidebar-item:hover
        ul.collapse.first-level
        li.sidebar-item
        a.sidebar-link
        span.hide-menu {
        display: block;
    }
    aside.left-sidebar.min
        .scroll-sidebar
        nav.sidebar-nav
        ul#sidebarnav
        li.sidebar-item:hover
        ul.collapse.first-level
        li.sidebar-item
        a.sidebar-link {
        border-left: 2px solid transparent;
    }
    aside.left-sidebar.min
        .scroll-sidebar
        nav.sidebar-nav
        ul#sidebarnav
        li.sidebar-item:hover
        a.sidebar-link,
    aside.left-sidebar.min
        .scroll-sidebar
        nav.sidebar-nav
        ul#sidebarnav
        li.sidebar-item:hover
        div.sidebar-link.has-arrow.waves-effect.waves-dark,
    aside.left-sidebar.min
        .scroll-sidebar
        nav.sidebar-nav
        ul#sidebarnav
        li.sidebar-item:hover
        div.sidebar-link.has-arrow.waves-effect.waves-dark.active {
        border-left: 2px solid #137eff;
        opacity: 1;
    }
    aside.left-sidebar.min
        .scroll-sidebar
        nav.sidebar-nav
        ul#sidebarnav
        li.sidebar-item:hover
        div.sidebar-link,
    aside.left-sidebar.min
        .scroll-sidebar
        nav.sidebar-nav
        ul#sidebarnav
        li.sidebar-item:hover
        ul.collapse.first-level {
        background: #191919;
    }
    aside.left-sidebar.min
        .scroll-sidebar
        nav.sidebar-nav
        ul#sidebarnav
        li.sidebar-item
        > .has-arrow::after {
        transition: all 0.5s ease-out;
    }
    aside.left-sidebar.min
        .scroll-sidebar
        nav.sidebar-nav
        ul#sidebarnav
        li.sidebar-item
        > .has-arrow::after {
        -webkit-transform: rotate(135deg) translate(0, -50%) !important;
        transform: rotate(135deg) translate(0, -50%) !important;
    }
    .changePlan{
        border: 1px solid;
        border-left: 1px solid !important;
        width: 100%;
        max-width: 150px;
        border-radius: 30px;
        margin: 20px auto;
    }
    @media (max-width: 1024px) {
        .sidebar-nav ul .sidebar-item .sidebar-link {
            padding: 8px 8px;
        }
        .changePlan{
            border: 0px solid;
            border-left: 0px solid !important;
            max-width: 100%;
        }
    }

</style>
