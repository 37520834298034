<template>
	<div>
		<div class="card">
			<div class="card-header">Add Transactions</div>
			<div class="card-body" style="padding-top: 30px; padding-bottom: 100px">
				<Loader v-if="Transac.isLoading"></Loader>
				<div v-else>
					<form @submit.prevent="saveTransac">
						<div class="form-group">
							<label for="inputCity">Date</label>
							<datetime
								input-class="form-control"
								required
								v-model="inputFecha"
							></datetime>
						</div>
						<div class="form-group">
							<label class="control-label">
								<span class="text-dashed-underline">Type</span>
							</label>
							<select
								class="form-control"
								required
								v-model="Transac.type"
							>
								<option value="1">Income</option>
								<option value="2">Unaccepted Expense</option>
							</select>
						</div>
						<div class="form-group">
							<label for="inputCity">Amount</label>
							<input
								type="text"
								class="form-control"
								required
								v-model="Transac.amount"
							/>
						</div>
						<div class="form-group-12" style="margin: 40px 0px 50px 0px">
							<label class="control-label">
								<span class="text-dashed-underline"
									>Description
								</span>
							</label>
							<textarea
								class="form-control"
								required
								v-model="Transac.desc"
							>
							</textarea>
						</div>
						<div class="col-12 text-right">
							<!-- <button
								type="button"
								class="btn btn-secundary"
								@click="hideModal"
								style="margin-right: 10px"
							>
								<i class="fas fa-times"></i> Cancel
							</button> -->
							<button type="submit" class="btn btn-success">
								<i class="fas fa-save"></i> Save
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	components: {
	},
	name: "formBaking",
	data () {
		return {
            Transac: {
                isLoading: false,
                banking_id: 0,
                date: new Date().toISOString().slice(0, 10),
                type: 1,
                amount: 0.01,
                desc: "",
            },
            inputFecha: new Date(
                (new Date().toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
            ).toISOString(),	  
		}
	},
	created() {
		this.init()
	},
	methods: {
        init() {
            let g = this.$store.getters['core/getOpenModal'];
            let r = g.data;
            this.Transac = r.Transac;
        },
        saveTransac() {
            if (!Number(this.Transac.amount)) {
                this.$notify({
                    group: "noti",
                    title: "Error",
                    type: "error",
                    text: "Amount must be greater than ZERO (0).",
                });
            } else if (this.Transac.amount < 0) {
                this.$notify({
                    group: "noti",
                    title: "Error",
                    type: "error",
                    text: "only numbers, no signs.",
                });
            } else {
                this.$store.commit("core/setLoadingModal", true);
                window.billing
                    .post("banking/createtransac", this.Transac)
                    .then((response) => {
                        this.$notify({
                            group: "noti",
                            title: "Processing",
                            type: "success",
                            text: "File processed successfully",
                        });
						this.$store.commit("core/setLoadingModal", false);
						this.$store.commit("core/setCloseModal", {action: 'saved'});
						this.$store.commit("core/setActionRefresh", {action: 'saved', data:{baking: true}});
						this.clear()
                    })
                    .catch((error) => {
                        this.$notify({
                            group: "noti",
                            title: "Save Error",
                            type: "error",
                        });
                        this.$store.commit("core/setLoadingModal", false);
                        console.log(error);
                    });
            }
        },
		clear() {
			this.Transac.isLoading = false
			this.Transac.banking_id = 0
			this.Transac.date = new Date().toISOString().slice(0, 10)
			this.Transac.type = 1
			this.Transac.amount = 0.01
			this.Transac.desc = ""
            this.inputFecha = new Date(
                (new Date().toISOString() + "").slice(0, 10) + "T23:31:04.000Z"
            ).toISOString()
		}					                      
    }
}
</script>
<style lang="css" scoped></style>
