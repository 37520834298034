let ordenate = function(response) {
    if (response.data.arxx.length > 0) {
        let cam = [];
        let r = response.data.result.cus;
        let ar = response.data.arxx;
        for (const i in ar) {
            let yu = (r[i].invo = []);
            let k = ar[i];
            for (const y in k) {
                yu.push({
                    created_at: k[y].created_at,
                    date: k[y].date,
                    fecdoc: k[y].fecdoc,
                    id: k[y].id,
                    numdoc: k[y].numdoc,
                    ordenDate: k[y].ordenDate,
                });
            }
            cam.push({
                id: r[i].id,
                invo: yu,
                name: r[i].name,
            });
        }
        this.dataBilling = cam;
    }
};
export default ordenate;
