<template>
	<div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col-6" style="padding-top: 10px">
                    <h5 class="card-title">
                        Notes & Reports <span class="badge badge-info"> Est #{{ est_numdoc }}</span>
                    </h5>
                </div>
            </div>
        </div>
		<div class="card-body">
			<div v-if="editId>0" class="popEditNote">
				<form class="formNote" @submit.prevent="editNoteEstPro">
					<div class="campo_note">
						<textarea v-model="comments" class="form-control" :placeholder="(comments==null) ? 'Edit note' : ''"></textarea>
					</div>
					<div class="btns_note">
						<button type="button" class="btn btn-ligth" @click="closeEditNote">Cancel</button>
						<button type="submit" class="btn btn-info">Save</button>
					</div>
				</form>
			</div>
			<div class="container-comments">
				<ul v-if="list.length>0" class="listComments">
					<li v-for="(i, idx) in list" :key="idx">
						<div class="comments-content">
							<button type="button" class="btn btn-sm btn-edit-meet" title="Edit note" @click="openEditNote(i)">
								<i class="fas fa-edit"></i>
							</button>
							<div class="comment">
								<b>Date: {{i.created_at | moment("MMM DD, hh:mm A")}}</b>
								<span>{{i.date}} {{i.hour}}</span>
							</div>
							<div class="comment note" @dblclick="openEditNote(i)">
								<pre v-if="i.comments!=null"><b>Comment: </b> {{i.comments}}</pre>
							</div>
							<div class="by">User name: {{i.seller_name}}</div>
						</div>
					</li>
				</ul>
				<div v-else style="padding-top: 20px;">
					<div class="alert alert-info" role="alert">
                        No records
					</div>
				</div>
			</div>
			<form @submit.prevent="saveNoteEstPro">
				<table class="table table-bordered" style="margin-bottom: 0px;">
					<thead>
						<tr>
							<th colspan="3" class="text-center thheader">
								Add Comments:
							</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td style="width: 30px;">User name:</td>
							<td>
								<input type="text" class="form-control" v-model="f.seller_name" placeholder="Seller name" disabled="true">
							</td>
						</tr>
						<tr>
							<td colspan="2" rowspan="2" style="width: 100%;">
								<textarea v-model="f.comments" required class="form-control" style="height: 100px;" placeholder="Comments:" maxlength="300"></textarea>
							</td>
						</tr>
						<tr>
							<td class="text-left" >
								<button type="submit" class="btn btn-success" style="width: 100%;" >
                                    <i class="far fa-paper-plane"></i>
                                </button>
							</td>
						</tr>
					</tbody>
				</table>
			</form>
		</div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
	name: 'notes',
	data () {
		return {
			f: {
				user_idd: window.master_client,
                user_id: window.localorgdata,
                org_id: window.localorgdata,
				seller_id: window.localAccountID,
				est_id: 0,
				seller_name: '',
				comments: '',
                socket_id: pusher.connection.socket_id,
			},
			profile: {},
			list: [],
			est_id: 0,
			est_numdoc: 0,
			editId: 0,
			note: null,
            typeSellerUser: 0,
		}
	},
	created(){
		let t = this;
		t.$store.commit("core/setLoadingModal", true);
		t.$store.dispatch('core/session/validTypeUser').then((admin) => {
            t.typeSellerUser = (!admin) ? 2 : 0;
            t.getProfile(admin).then((r) => {
				t.init();
            });
        });
	},
	methods:{
        getProfile (admin) {
			let t = this;
            return new Promise((resolve, reject) => {
                window.master
                .post('userProfile', {
                    user_id: window.localAccountID,
                    admin: admin
                })
                .then((response) => {
                    t.profile = response.data.profile
                    resolve(true);
                })
                .catch((error) => {
                    console.log(error)
                    resolve(false);
                })
            });
        },
		init(){
			let t = this;
			let d = t.$store.getters['core/getOpenModal'].data;
			t.f.user_id= window.localorgdata;
			t.f.org_id= window.localorgdata;
			t.f.est_id= d.id;
			t.est_numdoc= d.numdoc;
			console.log('est_id', t.f.est_id)
			document.querySelector('body').classList.add('overflowYhide');
			t.getEstProNotes();
		},
		getEstProNotes(load){
			let t = this;
			if(load==undefined){t.$store.commit("core/setLoadingModal", true);}
			window.billing
            .post('getEstProNotes', t.f)
            .then((response) => {
                console.log('getEstProNotes', response.data)
                t.list=response.data.r;
				setTimeout(function(){
					let scroll=document.querySelector(".container-comments");
					if(scroll){
						//scroll.scrollTop=scroll.scrollHeight;
					}
					t.f.seller_name = t.profile.name +" "+ t.profile.last;
                    t.$store.commit("core/setLoadingModal", false);
				}, 200);
            })
            .catch((error) => {
                console.log(error);
                t.$store.commit("core/setLoadingModal", false);
            });
		},
		saveNoteEstPro(){
			let t = this;
			t.$store.commit("core/setLoadingModal", true);
			window.billing
            .post('setEstProNotes', t.f)
            .then((response) => {
                console.log('setEstProNotes', response.data);
                t.close();
                t.getEstProNotes(1);
            })
            .catch((error) => {
                console.log(error);
                t.$store.commit("core/setLoadingModal", false);
            });
		},
		close(){
            this.est_id = 0;
			this.editId = 0;
			this.f.comments= '';
			this.comments = '';
			// this.$parent.close();
		},
		openEditNote(i){
			this.est_id = i.est_id;
			this.editId = i.id;
			this.comments = i.comments;
		},
		closeEditNote(){
			this.est_id = 0;
			this.editId = 0;
			this.comments = null;
		},
		editNoteEstPro(){
			let t = this;
			for(let i in t.list){
				if(t.list[i]){
					if(t.list[i].id==t.editId){
						t.list[i].comments = t.comments;
					}
				}
			}
			window.billing
            .post('setEstProNotes', {
				user_idd: window.master_client,
                user_id: window.localuserdata,
                org_id: window.localorgdata,
				seller_id: window.localAccountID,
				est_id: t.est_id,
				id: t.editId,
				comments: t.comments,
                socket_id: pusher.connection.socket_id,
			})
            .then((response) => {
                console.log('setEstProNotes', response.data);
				t.closeEditNote();
            })
            .catch((error) => {
                console.log(error);
            });
		},
	},
	watch: {
    },
    computed: {
        ...mapGetters("core", { 
            getPusher: "get_accitonPusher"
        }),
    }
}
</script>
<style lang="scss" scoped>
.table-bordered th, .table-bordered td {
    border: 0px solid #dee2e6;
}
.container-comments {
	position: relative;
    display: block;
    width: 100%;
    height: calc(100vh - 330px);
    overflow: hidden;
	overflow-y: auto;
}
ul.listComments {
list-style: none;
margin-bottom: 0px;
padding-inline-start: 0px;
margin-block-start: 0px;
}

.comments-content {
	position: relative;
    padding: 10px;
    margin-right: 20px;
    border: 1px solid #e4daff;
    border-radius: 10px;
    background: rgb(228 218 255 / 49%);
    margin-bottom: 20px;
}

.date, .by {
    font-size: 10px;
    color: #bebebe;
}
.comment {
    font-size: 14px;
}	
.comment span{
    font-size: 12px;
}	
.thheader{
	border-bottom: 1px solid #bebebe !important;
    background-color: #fff;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    font-weight: bold;
}
table.table.table-bordered {
    box-shadow: -1px -1px 4px 1px rgb(0 0 0 / 15%);
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    border: 0px;
}
.comment {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}
.comment span {margin-left: 10px;}
.comment.note{
	b{display: contents;}
	pre {
		font-size: 14px;
		color: #6a7a8c;
		display: flex;
		font-family: "ProximaNova", sans-serif;
		margin-left: 0px;
		margin-bottom: 0px;
		white-space: break-spaces;
	}
}
.btn-edit-meet{
	position: absolute;
	right: 10px;
	background-color: transparent;
	color:#555;
}
.popEditNote {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
    padding-top: 50px;
	form.formNote{
		width:100%;
		padding: 10px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		.campo_note {
			width: 90%;
			textarea.form-control {
				height: 210px;
				margin-bottom: 10px;
			}
		}
		.btns_note {
			width: 90%;
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			button.btn-info {
				margin-left: 20px;
			}
		}
	}
}
</style>
