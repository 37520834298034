<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card" id="card">
                        <div class="card-header" style="padding-left: 0">
                            <div class="row" style="margin-bottom: 10px">
                                <div class="col-md-6">
                                    <button
                                        class="btn btn-success"
                                        @click="AddSubcontractor"
                                        style="color: white"
                                    >
                                        <i class="fas fa-plus"></i>
                                        Add Subcontractor/Hour
                                    </button>
                                </div>
                                <div class="col-md-6">
                                    <button
                                        class="btn btn-dafaull"
                                        @click="back"
                                        style="
                                            float: right;
                                            color: white;
                                            background: #343a40;
                                        "
                                    >
                                        <i class="fas fa-arrow-left"></i>
                                        Back to Timesheet List
                                    </button>
                                </div>
                            </div>
                            <div class="row" style="margin-bottom: -10px">
                                <div class="col-md-6"></div>
                                <div class="col-md-6">
                                    <table border="0" align="center">
                                        <tbody>
                                            <tr
                                                v-for="(item, index) in TimesheetList"
                                                :key="index"
                                            >
                                                <td>From:</td>
                                                <td>
                                                    <strong>{{ item.wfrom }}</strong>
                                                </td>
                                                <td width="10">&nbsp;</td>
                                                <td>To:</td>
                                                <td>
                                                    <strong>{{ item.wto }}</strong>
                                                </td>
                                                <td width="10">&nbsp;</td>
                                                <td width="10">&nbsp;</td>
                                                <td width="10">&nbsp;</td>
                                                <td><strong>Timesheet:</strong></td>
                                                <td>
                                                    <strong># {{ item.ids }}</strong>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div
                                class="row"
                                v-if="isLoading"
                                style="display: block; margin: 0 auto"
                            >
                                <Loader></Loader>
                            </div>
                            <div class="row" v-else style="padding-bottom: 130px">
                                <div class="col-md-12">
                                    <table
                                        width="90%"
                                        class="table table-bordered table-striped table-hover"
                                    >
                                        <thead>
                                            <tr>
                                                <th scope="col">Subcontractor Name</th>
                                                <th
                                                    scope="col"
                                                    v-for="(item, index) in interval"
                                                    :key="index"
                                                >
                                                    {{ item.wfecha }}
                                                </th>
                                                <th scope="col">Total hrs</th>
                                                <th scope="col">Payment</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="TimesheetSubList.length > 0">
                                            <tr
                                                v-for="(item, index) in TimesheetSubList"
                                                :key="index"
                                            >
                                                <td>
                                                    <strong>{{
                                                        item.subcontractor_name
                                                    }}</strong>
                                                    <div
                                                        style="
                                                            color: #979796;
                                                            font-size: 11px;
                                                        "
                                                    >
                                                        Default Rate: ${{
                                                            item.subcontractor_default_rate
                                                        }}/hrs
                                                    </div>
                                                </td>
                                                <td
                                                    v-for="(
                                                        i, indx
                                                    ) in TimesheetSubListTime[index]"
                                                    :key="indx"
                                                >
                                                    <div
                                                        v-if="
                                                            item.id == i.timesheetsub_id
                                                        "
                                                    >
                                                        <label v-if="i.horas > 0">
                                                            <div align="center">
                                                                {{ i.horas }} hrs
                                                            </div>
                                                            <div
                                                                align="center"
                                                                style="
                                                                    color: #979796;
                                                                    font-size: 11px;
                                                                "
                                                            >
                                                                ${{ i.rate }}/h
                                                            </div>
                                                            <div
                                                                align="center"
                                                                style="
                                                                    color: #2961cb;
                                                                    font-size: 11px;
                                                                "
                                                            >
                                                                {{ i.task_name }}
                                                            </div>
                                                        </label>
                                                        <label v-else></label>
                                                    </div>
                                                </td>
                                                <td>{{ item.totalHoras }} hrs</td>
                                                <td>${{ item.payment }}</td>
                                                <td>
                                                    <dropdown-menu>
                                                        <button
                                                            class="btn btn-light btnDropActions"
                                                        >
                                                            <label class="">
                                                                Actions
                                                                <input
                                                                    type="checkbox"
                                                                    v-model="item.actions"
                                                                    :id="'check' + index"
                                                                    class="checkAction"
                                                                    @click="
                                                                        activateActions(
                                                                            index
                                                                        )
                                                                    "
                                                                />
                                                                <i
                                                                    class="fas fa-chevron-down"
                                                                    style="
                                                                        font-size: 12px;
                                                                    "
                                                                ></i>
                                                            </label>
                                                        </button>
                                                        <div
                                                            slot="dropdown"
                                                            :id="'drop' + index"
                                                            @click="desactivateActions"
                                                        >
                                                            <div
                                                                class="dropdown-item action-item"
                                                                style="width: 190px"
                                                                @click="
                                                                    editTimesheet(item)
                                                                "
                                                            >
                                                                <a
                                                                    class="text-left textAction"
                                                                    ><i
                                                                        class="fas fa-edit"
                                                                    ></i>
                                                                    Edit</a
                                                                >
                                                            </div>
                                                            <div
                                                                class="dropdown-item action-item"
                                                                style="width: 190px"
                                                                @click="actionPrint(item)"
                                                            >
                                                                <a
                                                                    class="text-left textAction"
                                                                    ><i
                                                                        class="fas fa-print"
                                                                    ></i>
                                                                    Print Check</a
                                                                >
                                                            </div>
                                                            <div
                                                                class="dropdown-item action-item"
                                                                style="width: 190px"
                                                                @click="actionPdf(item)"
                                                            >
                                                                <a
                                                                    class="text-left textAction"
                                                                    ><i
                                                                        class="fas fa-file"
                                                                    ></i>
                                                                    Open PDF Check</a
                                                                >
                                                            </div>
                                                            <div
                                                                class="dropdown-item action-item"
                                                                style="width: 190px"
                                                                @click="
                                                                    deleteSubTimesheet(
                                                                        item
                                                                    )
                                                                "
                                                            >
                                                                <a
                                                                    class="text-left textAction"
                                                                    ><i
                                                                        class="fas fa-times"
                                                                    ></i>
                                                                    Delete</a
                                                                >
                                                            </div>
                                                        </div>
                                                    </dropdown-menu>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="8" align="center"></td>
                                                <td>
                                                    <strong>{{ StotalHoras }} hrs</strong>
                                                </td>
                                                <td>
                                                    <strong>${{ StotalPagos }}</strong>
                                                </td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr>
                                                <td colspan="11" align="center">
                                                    No match.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end modal Add Subcontractor/Hours -->
        <!-- start modal PDF check -->
        <modal name="modal-actionPdf" height="auto" :width="600">

        </modal>
        <!-- end modal PDF check -->

        <modalLateral>
            <component v-bind:is="m.component"></component>
        </modalLateral>        
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Loader from '@/core/components/shared/Loader'
import { ModelSelect } from 'vue-search-select'
import DropdownMenu from '@innologica/vue-dropdown-menu'
import modalLateral from '@/core/plugins/themeModalLateral/modalLateral'
import SubFormTimesheet from '@/apps/billing/components/timesheet/SubFormTimesheet'
import SubFormTimesheetPdf from '@/apps/billing/components/timesheet/SubFormTimesheetPdf'
import SubFormTimesheetPrint from '@/apps/billing/components/timesheet/SubFormTimesheetPrint'
export default {
    name: "TimesheetSub",
    components: {
        Loader,
        ModelSelect,
        DropdownMenu,
        modalLateral,
        SubFormTimesheet,
        SubFormTimesheetPdf,
        SubFormTimesheetPrint
    },
    data() {
        return {
            isLoading: false,
            isDisabled: false,
            f: {
                id: 0,
                times_id: 0,
                user_id: window.localorgdata,
                billing: 1,
                nameSub: 0,
                interval: [],
            },
            fs: {
                user_id: window.localorgdata,
                times_id: 0,
                id: 0,
                fecini:
                    (new Date().toISOString() + "").slice(0, 11) +
                    new Date().toTimeString().slice(0, 8),
                fromTo: "",
                subcontractor_name: "",
                payment: 0,
                proyect: "",
            },
            TimesheetList: [],
            TimesheetSubList: [],
            TimesheetSubListTime: [],
            sub: [],
            interval: [],
            amount: 0,
            horas: [],
            subtime: [],
            subtimeAsing: [],
            StotalHoras: 0,
            StotalPagos: 0,
            task: [],
            timecheck: [],
            m: {
                show: false,
                component: '',
                data: []
            }             
        };
    },
    created() {
        this.getTimesheet();
        this.get();
    },
    methods: {
        actionPrint(item) {
            this.timecheck = item;
            this.fs.times_id = item.times_id;
            this.fs.id = item.id;
            this.fs.subcontractor_name = item.subcontractor_name;
            this.fs.payment = item.payment;
            this.fs.fromTo =
                "From: " +
                this.TimesheetList[0].froms +
                " To: " +
                this.TimesheetList[0].tos;
            let t = this
            this.m = {
                show: true,
                component: 'SubFormTimesheetPrint',
                data: { fs:t.fs, timecheck:t.timecheck }
            }   
            this.$store.commit('core/setOpenModal', this.m)
        },
        actionPdf(item) {
            this.timecheck = item;
            this.fs.times_id = item.times_id;
            this.fs.id = item.id;
            this.fs.subcontractor_name = item.subcontractor_name;
            this.fs.payment = item.payment;
            this.fs.fromTo =
                "From: " +
                this.TimesheetList[0].froms +
                " To: " +
                this.TimesheetList[0].tos;
            let t = this
            this.m = {
                show: true,
                component: 'SubFormTimesheetPdf',
                data: { fs:t.fs, timecheck:t.timecheck }
            }   
            this.$store.commit('core/setOpenModal', this.m)
        },
        get() {
            this.isLoading = true;
            this.f.times_id = this.$route.params.id;
            window.billing
                .post("/subtimesheet/get", this.f)
                .then((response) => {
                    console.log(response.data);
                    this.subtime = response.data.result.subtime;
                    this.TimesheetSubListTime = response.data.result.subtimeAsing;
                    this.conevert();
                    let r = response.data.result.employes;
                    this.sub.push({ value: 0, text: "None", default_rate: 0.0 });
                    for (const i in r) {
                        let name = r[i].firstname + " " + r[i].lastname;
                        this.sub.push({
                            value: r[i].id,
                            text: name,
                            default_rate: r[i].default_rate,
                        });
                    }
                    let task = response.data.result.task;
                    this.task.push({ value: 0, text: "None" });
                    for (const i in task) {
                        this.task.push({ value: task[i].id, text: task[i].name });
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        conevert() {
            let g = this.TimesheetSubListTime;
            let h = this.subtime;
            let totalHoras = 0;
            let totalPagos = 0;
            let amount = 0;
            for (const j in h) {
                for (const i in g[j]) {
                    if (h[j].id === g[j][i].timesheetsub_id) {
                        totalHoras += parseInt(g[j][i].horas);
                        totalPagos += parseInt(g[j][i].rate);
                        amount += parseInt(g[j][i].horas) * parseInt(g[j][i].rate);
                    }
                }
                this.TimesheetSubList.push({
                    id: h[j].id,
                    subcontractor_id: h[j].subcontractor_id,
                    subcontractor_name: h[j].subcontractor_name,
                    subcontractor_default_rate: h[j].subcontractor_default_rate,
                    times_id: h[j].times_id,
                    user_id: h[j].user_id,
                    totalHoras: totalHoras,
                    totalPagos: totalPagos,
                    payment: amount,
                    // payment:totalPagos * totalHoras,
                });
                totalHoras = 0;
                totalPagos = 0;
                amount = 0;
            }
            let y = this.TimesheetSubList;
            for (const j in y) {
                this.StotalHoras += parseInt(y[j].totalHoras);
                this.StotalPagos += parseInt(y[j].payment);
            }
        },
        getTimesheet() {
            this.isLoading = true;
            this.f.times_id = this.$route.params.id;
            window.billing
                .post("timesheet/get", this.f)
                .then((response) => {
                    console.log(response.data);
                    this.TimesheetList = response.data.result;
                    this.f.interval = response.data.interval;
                    this.interval = this.f.interval;
                    this.amount = response.data.amount;
                    this.horas = [];
                    for (let i = 1; i <= 16; i++) {
                        this.horas.push({ value: i, text: i });
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        back() {
            this.$router.push({ name: "Timesheet" });
        },
        AddSubcontractor() {
            let t = this
            this.m = {
                show: true,
                component: 'SubFormTimesheet',
                data: { f:t.f, fs:t.fs, sub:t.sub, horas:t.horas, task:t.task, TimesheetSubList:t.TimesheetSubList }
            }   
            this.$store.commit('core/setOpenModal', this.m)
        },
        editTimesheet(item) {
            this.f.id = item.id;
            this.f.nameSub = item.subcontractor_id;
            let rate = item.subcontractor_default_rate;
            let sub = this.TimesheetSubListTime;
            this.f.interval = [];
            let a = this.interval;
            for (let i = 0; i < sub.length; i++) {
                let k = sub[i];
                for (const j in k) {
                    if (item.id === k[j].timesheetsub_id) {
                        this.f.interval.push({
                            fecha: a[j].fecha,
                            horas: k[j].horas,
                            rate: rate,
                            task: k[j].task,
                            wfecha: a[j].wfecha,
                        });
                    }
                }
            }
            this.AddSubcontractor();
        },
        deleteSubTimesheet(item) {
            this.$swal({
                title: "Delete this Sub Contrator?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                this.isLoading = true;
                if (result.value) {
                    window.billing
                        .post("subtimesheet/del", item)
                        .then((response) => {
                            // console.log(response.data);
                            this.isLoading = false;
                            // this.$swal.fire({
                            // 	icon: 'success',
                            // 	title: 'successfully removed!',
                            // 	showConfirmButton: false,
                            // 	timer: 4000
                            // });
                            this.refresTimesheet();
                            this.closeModal();
                            this.get();
                            this.getTimesheet();
                        })
                        .catch((error) => {
                            console.log(error);
                            this.isLoading = false;
                        });
                }
            });
        },
        closeModal() {
            this.fs.times_id = 0;
            this.fs.id = 0;
            this.fs.fecini =
                (new Date().toISOString() + "").slice(0, 11) +
                new Date().toTimeString().slice(0, 8);
            this.fs.fromTo = "";
            this.fs.subcontractor_name = "";
            this.fs.payment = 0;
            this.fs.proyect = "";
            this.f.interval = this.interval;
            this.f.nameSub = 0;
        },
        refresTimesheet() {
            this.f.id = 0;
            this.f.times_id = 0;
            this.f.nameSub = 0;
            this.f.interval = [];
            this.TimesheetSubList = [];
            this.TimesheetSubListTime = [];
            this.interval = [];
            this.task = [];
            this.StotalHoras = 0;
            this.StotalPagos = 0;
        },
        desactivateActions() {
            let allCheck = document.querySelectorAll(".checkAction");
            for (let i in allCheck) {
                if (allCheck.hasOwnProperty(i)) {
                    allCheck[i].parentElement.parentElement.nextSibling.style.display =
                        "none";
                    allCheck[i].parentElement.parentElement.nextSibling.style.left =
                        "0px";
                    allCheck[i].checked = false;
                }
            }
        },
        activateActions(index) {
            let allCheck = document.querySelectorAll(".checkAction");
            for (let i in allCheck) {
                if (allCheck.hasOwnProperty(i)) {
                    allCheck[i].parentElement.parentElement.nextSibling.style.display =
                        "none";
                    allCheck[i].parentElement.parentElement.nextSibling.style.left =
                        "0px";
                }
            }
            let check = document.querySelector("#check" + index);
            if (check.checked) {
                check.parentElement.parentElement.nextSibling.style.display = "block";
                check.parentElement.parentElement.nextSibling.style.left = "-180px";
            } else {
                check.parentElement.parentElement.nextSibling.style.display = "none";
                check.parentElement.parentElement.nextSibling.style.left = "0px";
            }
        },     
    },  
    watch: {
        'getOpenModal': function() {
            // console.log('timesheet getOpenModal', this.getOpenModal.show);
            if (!this.getOpenModal.show) {
                if (Object.keys(this.getOpenModal.data).length > 0) {
                    this.get();
                    this.getTimesheet();
                }
                this.m = {
                    show: false,
                    component: '',
                    data: {}
                }
            }
        }
    },
    computed: {
        ...mapGetters("core", { getOpenModal: "getOpenModal" }),
    }    
}
</script>
<style lang="scss" scoped>
.botonera-filtros {
    padding: 0px 15px;
    display: flex;
    width: 100%;
}
.btn-add-invo,
.btn-sync {
    margin-right: 10px;
}
.btn-add-invo {
    width: 10%;
    max-width: 115px;
    min-width: 115px;
}
.btn-sync {
    width: 4px;
    max-width: 35px;
    min-width: 35px;
}
.filtros {
    width: 70%;
    margin-left: 16%;
}
@media (min-width: 1320px) {
    .mobile_display {
        justify-content: flex-end;
    }
}
.uno {
    width: 550px;
    margin-right: 10px;
}
.dos {
    width: 70px;
    margin-right: 10px;
}
.tres {
    width: 102px;
}
@media (max-width: 1320px) {
    .filtros {
        margin-left: 10%;
        width: 76%;
    }
}
@media (max-width: 1320px) {
    .filtros {
        margin-left: 5%;
        width: 81%;
    }
}
@media (max-width: 1100px) {
    .filtros {
        max-width: 924px;
    }
    .uno {
        width: 400px;
        margin-right: 10px;
    }
}
@media (max-width: 1000px) {
    .filtros {
        margin-left: 2%;
        width: 84%;
    }
    .uno {
        width: 300px;
    }
}
@media (max-width: 875px) {
    .filtros {
        margin-left: 2%;
        width: 84%;
    }
    .uno {
        width: 100%;
        margin-bottom: 10px;
    }
}
@media (max-width: 500px) {
    .botonera-filtros {
        display: block;
    }
    .btn-add-invo,
    .btn-sync {
        display: inline-block;
        margin-bottom: 10px;
    }
    .filtros {
        margin-left: 0%;
        margin-bottom: 10px;
        width: 100%;
        padding-left: 15px;
    }
}
.btn-filter-search:hover {
    cursor: pointer;
}

.tres button.btn,
.cuatro button.btn {
    padding: 7px 10px;
}

.cdropdown ul li {
    padding: 3px 10px;
}
.cdropdown ul li button {
    font-size: 0.76rem;
}
#card-invoice {
    background: transparent;
}
.dropdStatus {
    list-style: none;
}
.dropdStatus {
    position: absolute;
    width: 130px;
    height: auto;
    background-color: #fff;
    z-index: 9;
    margin: 0;
    border: 1px solid #ccc;
    padding: 10px;
    list-style: none;
    @media (max-width: 767px) {
        right: 0;
    }
}
.dropdStatus li {
    text-align: left;
}
.dropdStatus li label {
    text-align: left;
    display: block;
}
.btn-status {
    font-size: 13px;
    letter-spacing: 0.5px;
    color: #5d5b5b !important;
    border-radius: 0;
    border: 1px solid #dcdcdc;
    background-color: #fff;
    padding: 7px;
}
.pagination {
    .page-car {
        &.active {
            .page-link {
                background-color: #8b8b8c;
                color: #fff;
            }
        }
    }
}
.scroll-right {
    display: none;
}

.btnDropActions {
    border-color: #ccc;
    padding: 0;
}
.btnDropActions label input {
    display: none;
}
.btnDropActions label:hover {
    cursor: pointer;
}
.btnDropActions label {
    padding: 0.375rem 0.5rem;
    margin-bottom: 0.1rem;
    font-size: 12px;
}
.textAction {
    font-size: 12px;
}
.dropdown-item.action-item {
    padding: 3px 5px 3px 10px;
}

/* ************************************* */

.invoList tbody tr td {
    font-size: 0.775rem;
}

.invoList thead tr th:nth-child(1),
.invoList tbody tr td:nth-child(1) {
    min-width: 66px;
    width: 5.694564279551337%;
}
.invoList thead tr th:nth-child(2),
.invoList tbody tr td:nth-child(2) {
    min-width: 92px;
    width: 7.937877480586713%;
}
.invoList thead tr th:nth-child(3),
.invoList tbody tr td:nth-child(3) {
    min-width: 103px;
    width: 8.886971527178602%;
}
.invoList thead tr th:nth-child(4),
.invoList tbody tr td:nth-child(4) {
    min-width: 200px;
    width: 17.25625539257981%;
}
.invoList thead tr th:nth-child(5),
.invoList tbody tr td:nth-child(5) {
    min-width: 178px;
    width: 15.35806729939603%;
}
.invoList thead tr th:nth-child(6),
.invoList tbody tr td:nth-child(6) {
    min-width: 80px;
    width: 6.902502157031924%;
}
.invoList thead tr th:nth-child(7),
.invoList tbody tr td:nth-child(7) {
    min-width: 66px;
    width: 5.694564279551337%;
}
.invoList thead tr th:nth-child(8),
.invoList tbody tr td:nth-child(8) {
    min-width: 80px;
    width: 6.902502157031924%;
}
.invoList thead tr th:nth-child(9),
.invoList tbody tr td:nth-child(9) {
    min-width: 73px;
    width: 6.298533218291631%;
}
.invoList thead tr th:nth-child(10),
.invoList tbody tr td:nth-child(10) {
    min-width: 40px;
    width: 3.451251078515962%;
}
.invoList thead tr th:nth-child(11),
.invoList tbody tr td:nth-child(11) {
    min-width: 71px;
    width: 6.125970664365833%;
}
.invoList thead tr th:nth-child(12),
.invoList tbody tr td:nth-child(12) {
    min-width: 110px;
    width: 9.490940465918896%;
}

.invoList thead {
    width: 100%;
    display: block;
    background: #fff;
}
.invoList tbody {
    width: 100%;
    display: block;
    height: calc(100vh - 230px);
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 290px;
}
.invoList thead tr,
.invoList tbody tr {
    display: block;
}
.invoList thead tr th {
    display: inline-block;
    height: 34px;
}
.invoList tbody tr td {
    display: inline-block;
    height: 50px;
}
.table-bordered th,
.table-bordered td {
    border: 0px solid transparent !important;
}

.contenedor-invoice table thead tr th {
    font-weight: bold;
}
.paginacion {
    width: 70px;
}
.invoList thead tr th:nth-child(11),
.invoList tbody tr td:nth-child(11) {
    min-width: 70px;
    width: 70px;
}
table.table.table-striped.table-bordered.table-hover.invoList {
    border: 0px;
}
.contenedor-invoice table {
    width: 100%;
    min-width: 1180px;
}
.contenedor-invoice {
    padding: 0px 10px 0px 10px;
    overflow-x: auto;
    width: calc(100vw - 170px);
}
@media (max-width: 1230px) {
    .scroll-right {
        display: block;
    }
}
@media (max-width: 1100px) {
    .invoList thead tr th:nth-child(1),
    .invoList tbody tr td:nth-child(1) {
        min-width: 50px;
    }
    .invoList thead tr th:nth-child(2),
    .invoList tbody tr td:nth-child(2) {
        min-width: 80px;
        width: 80px;
    }
    .invoList thead tr th:nth-child(3),
    .invoList tbody tr td:nth-child(3) {
        min-width: 80px;
        width: 80px;
    }
    .invoList thead tr th:nth-child(4),
    .invoList tbody tr td:nth-child(4) {
        min-width: 150px;
        width: 150px;
    }
    .invoList thead tr th:nth-child(5),
    .invoList tbody tr td:nth-child(5) {
        min-width: 130px;
        width: 130px;
    }
    .invoList thead tr th:nth-child(6),
    .invoList tbody tr td:nth-child(6) {
        min-width: 90px;
        width: 90px;
    }
    .invoList thead tr th:nth-child(7),
    .invoList tbody tr td:nth-child(7) {
        min-width: 70px;
        width: 70px;
    }
    .invoList thead tr th:nth-child(8),
    .invoList tbody tr td:nth-child(8) {
        min-width: 90px;
        width: 90px;
    }
    .invoList thead tr th:nth-child(9),
    .invoList tbody tr td:nth-child(9) {
        min-width: 80px;
        width: 80px;
    }
    .invoList thead tr th:nth-child(10),
    .invoList tbody tr td:nth-child(10) {
        min-width: 50px;
        width: 50px;
    }
    .invoList thead tr th:nth-child(11),
    .invoList tbody tr td:nth-child(11) {
        min-width: 50px;
        width: 50px;
    }
    .invoList tbody tr td:nth-child(11) > .cdropdown > button {
        padding: 15px;
    }
    .invoList tbody tr td:nth-child(11) > .cdropdown > button:before {
        right: 11px;
    }
    .invoList tbody tr td:nth-child(11) > .cdropdown > button > span {
        display: none;
    }
    .contenedor-invoice {
        max-width: 924px;
    }
}
.invoList tbody tr:nth-of-type(odd) {
    background-color: #e9ecef;
}
.invoList tbody tr:nth-of-type(even) {
    background-color: #fff;
}
a:not([href]):not([tabindex]) {
    color: #212529;
}
</style>
