<template>
	<div class="header-box">
		<div class="countItems">
			<h4 class="page-title">Customer</h4>
		</div>
		<div
			class="btn-group d-flex justify-content-end actionTop"
			role="group"
			aria-label="Button group with nested dropdown "
		>
			<div class="btn-group" role="group">
				<button
					id="btnGroupDrop1"
					type="button"
					class="btn btn-light dropdown-toggle"
					data-toggle="dropdown"
					aria-haspopup="true"
					aria-expanded="false"
					v-click-outside="hide"
					v-on:click="ShowMenuDropPCus"
					v-bind:class="{ show: ShowMenuDropCus }"
					style="margin-right: 10px"
				>
					<i class="fas fa-cog" style="font-size: 16px"></i>
				</button>
				<button
					type="button"
					class="btn btn-success btn-new"
					@click="showformregister"
					:disabled="addDisabled"
				>
					<i class="fas fa-plus"></i>
				</button>
				<div
					class="dropdown-menu dropdown-menu-right"
					style="width: 184px"
					aria-labelledby="btnGroupDrop1"
					v-bind:class="{ show: ShowMenuDropCus }"
					v-show="ShowMenuDropPCus"
				>
					<div
						class="custom-control custom-checkbox mr-sm-2"
						style="margin-left: 0.4rem !important; font-size: 15px;"
					>
						<input
							type="checkbox"
							class="custom-control-input"
							id="customControlAutosizing"
							v-model="show_business"
							@change="checkcus($event)"
						/>
						<label
							class="custom-control-label"
							for="customControlAutosizing"
						>
							Show Business Name
						</label>
					</div>
					<div
						class="custom-control custom-checkbox mr-sm-2"
						style="margin-left: 0.4rem !important;font-size: 15px;"
					>
						<input
							type="checkbox"
							class="custom-control-input"
							id="customControlAutosizing2"
							v-model="show_customer"
							@change="checkcus($event)"
						/>
						<label
							class="custom-control-label"
							for="customControlAutosizing2"
						>
							Show Inactives
						</label>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import ClickOutside from "vue-click-outside";
export default {
	name: 'headList',
	components: {
	},
	data() {
		return {
			isLoading: false,
			show_customer: true,
			ShowMenuDropCus: false,
			show_business: true,
            premium : (window.OrgPlan > 1),
            TotalStandard: 5000,
            TotalPremium: 10000,
            TotalEspacio: 0,
            addDisabled: false,
		};
	},
	created() {
        this.TotalEspacio = (this.premium) ? this.TotalPremium : this.TotalStandard;
		this.$store.commit('ModApps/billing/setFormCustomer', false);
		this.$store.commit('ModApps/billing/setCustomerByID', {});
		this.$store.commit('ModApps/billing/setCustomerID', 0);
	},
	mounted() {
	},
	directives: {
		ClickOutside
	},
	methods: {
		checkcus: function (e) {
			let show_customer_inactives = "false";
			let show_business = "false";
			if (this.show_business) {
				show_business = "true";
			}
			if (this.show_customer) {
				show_customer_inactives = "true";
			}
			window.billing
			.post("customer/setting", {
				show_customer_inactives: show_customer_inactives,
				show_business: show_business,
				user_id: window.localuserdata,
			})
			.then((response) => {
				this.ShowMenuDropCus = false;
				this.$store.commit('ModApps/billing/setCustomerRefresh', true);
				this.$store.commit('ModApps/billing/setShowSetting', {
					show_customer: this.show_customer,
					show_business: this.show_business
				});
			})
			.catch((error) => {
				console.log(error);
				this.isLoading = false;	
			});
		},
		ShowMenuDropPCus() {
			this.ShowMenuDropCus = !this.ShowMenuDropCus;
		},
		hide() {
			this.ShowMenuDropCus = false;
		},
		showformregister() {
			console.log('open form new add');
			this.$store.commit('ModApps/billing/setFormCustomer', true);
			this.$store.commit('ModApps/billing/setCustomerID', 0);
			this.$store.commit('ModApps/billing/setCustomerByID', {});
		},
	},
	computed: {
		...mapGetters("ModApps/billing", { 
			getShowSetting: "getShowSetting",
			CountTotal: "getCustomerCountTotal",
		}),
	},
	watch: {
		getShowSetting: function () {
			this.show_business = this.getShowSetting.show_business;
			this.show_customer = this.getShowSetting.show_customer;
		},
		CountTotal: function () {
			let calEspacio = this.TotalEspacio - this.CountTotal;
			if(calEspacio<=0){
				this.addDisabled=true;
			}
		},
	},
};
</script>
<style lang="css" scoped>
</style>
