let constructorGroupName = function(i, type) {
	let t =this;
	if(type != 'filter')
	{
		t.getGroupName(i).then(r => {
			i.optionsGroupName = r;
			t.asignItemDataT(i);
		});
	} else {
		for (let j in t.optionsCampign) {
			if(t.optionsCampign[j] && t.optionsCampign[j].value == i) {
				let item = {id_campaign : i};
				t.getGroupName(item).then(r => {
					t.filtersOtpsGroupName = r;
				});
			}
		}
	}
};
export default constructorGroupName;
