let actionEmail = function(id, email, numdoc) {
	this.DataMail.idinv = id;
	this.DataMail.IDitem = numdoc;
	this.DataMail.email = email;
	this.DataMail.validEprint = this.validEprint;
	let t = this;
	this.m = {
		show: true,
		bill: true,
		component: 'modalEmail',
		data: {DataMail:t.DataMail}
	}  
	this.$store.commit('core/setOpenModal', this.m);   
};
export default actionEmail;
