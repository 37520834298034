<template>
    <div class="card-header">
        <!-- <span class="numID" > #{{displayBox.id}} </span> -->
        <span class="daysAgo">{{ displayBox.name }}</span>
        <!-- <span class="status"  v-if="displayBox.status == 1">
			<span class="badge badge-pill badge-success">Active</span>
		</span>
		<span class="status"  v-if="displayBox.status == 0">
			<span class="badge badge-pill badge-danger">Inactive</span>
		</span>	 -->
    </div>
</template>

<script>
export default {
    name: "headerBox",
    props: {
        itemDisplay: { type: Object, required: true },
    },
    data() {
        return {
            displayBox: {
                id: 0,
                counter: "0 days",
                id_status: 1,
            },
        };
    },
    created() {
        if (Object.keys(this.itemDisplay).length > 0) {
            this.displayBox = this.itemDisplay;
        }
    },
    watch: {
        itemDisplay: function () {
            if (Object.keys(this.itemDisplay).length > 0) {
                this.displayBox = this.itemDisplay;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.card-header span.numID,
.card-header span.daysAgo,
.card-header span.status {
    display: flex;
    // width: 33.3333%;
    width: 194px;
}

span.numID {
    color: #0097d9;
    cursor: pointer;
}
span.status {
    justify-content: flex-end;
}
span.status span.badge {
    border-radius: 0px;
    font-size: 14px;
}
.badge-success {
    color: #fff;
    background-color: #019720;
}
</style>
