let metodos = function(g) {
    if (g.action === "modal") {
        this.m = {
            show: true,
            component: g.methods,
            data: { g }
        }             
        this.$store.commit('core/setOpenModal', this.m); 
    }
     else {
        if (g.methods === "changeColor") { 
            this.reEscribirLista();
        }
        if (g.methods === "selectedItem") {                  
            this.reEscribirLista();
        } 
        if (g.methods === "del") { 
            this.listTaks.splice(g.idx, 1);
            this.reEscribirLista();
            this.starGrid();
            this.update_JOPending(g.tagsModalId);
        }
        if (g.methods === "galleryBox") {                  
            this.reEscribirLista();
        }                            
    }          
};
export default metodos;
