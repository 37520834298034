let invoOpAditional = function() {
	let t = this;
	let jobs = 0;
	if(t.accessJobsApp){jobs=1;}
	let listinvo = [];
	for (let i in t.InvoicesListResult) {
		if(t.InvoicesListResult[i].id){
			listinvo.push(t.InvoicesListResult[i].id);
		}
	}
	window.billing.post("invoOpAditional", {
		user_id: window.localuserdata,
		jobs: jobs,
		listinvo: listinvo.toString(),
	})
	.then((response) => {
		t.validEprint = (response.data.ePrint && response.data.ePrint.meta_value);
		if(response.data.setting_columnas){
			let cols = response.data.setting_columnas.meta_value;
			if(cols){
				t.showCols(cols);	
			}
		}
		if(response.data.opAddiInvoice){
			let opAddi = response.data.opAddiInvoice.meta_value;
			if(opAddi){
				t.valOpAddi = opAddi.split(',');
			}
		}
		let p = t.InvoicesList
		for (let i in p) {
			t.InvoicesList[i].accountSMS = window.accountSMS
		}
	})
	.catch((error) => {
		console.log(error);
	});
};
export default invoOpAditional;
