<template>
    <div class="edit_food_price" :data-indicador="indicador">
        <div class="food_item_price" v-if="!editActive" @click.prevent="activeEdit()" :data-indicador="indicador"> {{currency}} {{ food.price | formatMoney}}</div>
        <div class="content-editor" v-else>
            {{currency}}
            <input type="number" v-model="food.price"
            placeholder="E.g. 1.00"
            step="0.01"
            min="0"
            max="100000"
            ref="editPrice_food"
            @change="saveFoodPrice()"
            @focusout="inactiveEdit"
            class="price" >
            <span class="tooltip">Error, wrong format, maximum 2 decimals.</span>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    props: ['indicador', 'food'],
    data(){
        return {
            loadSave: false,
            editActive: false,
            apis: null,
            currency: null,
            price_old: null,
        };
    },
    computed: {
        ...mapGetters("ModApps/Marketea/", {
            get_currency: "get_bmenu_currency",
        }),
    },
    watch: {
        get_currency(){
            this.currency = this.get_currency;
            // console.log('watch currency', this.currency)
        },
    },
    created(){
        let t = this;
        let urlApis = this.$store.getters['ModApps/Marketea/urlApis'];
        this.apis = urlApis.bMenu;
        this.currency = this.$store.getters['ModApps/Marketea/get_bmenu_currency'];
        this.price_old = this.food.price
        // console.log('created currency', this.currency)
    },
    methods: {
        activeEdit(){
            let t = this;
            t.editActive = true;
            setTimeout(() => {
                if(t.$refs.editPrice_food){
                    t.$refs.editPrice_food.focus();
                }
            }, 200);
        },
        inactiveEdit(){this.editActive = false;},
        noEnter(e){
            let t = this;
            var code = (e.which) ? e.which : e.keyCode;
            if(code == 13){
                e.preventDefault();
                t.saveFoodPrice()
            }
        },
        saveFoodPrice(){
            let t= this;
            t.inactiveEdit()
            let urlService = t.apis.set(3)// setFood
            if(t.isEmptyNaN(t.food.price)){
                t.$notify({
                    group: "noti",
                    type: "error",
                    title: "Error",
                    text: "Incorrect amount.",
                });
                t.food.price = t.price_old;
            }else{
                if(!t.loadSave){
                    t.loadSave = true;
                    // @ts-ignore
                    window.master.post(urlService, t.food)
                    .then((response) => {
                        console.log(urlService, t.food, response.data);
                        t.loadSave = false;
                    })
                    .catch((error) => {
                        console.log(error);
                        t.loadSave = false;
                        t.$notify({
                            group: "noti",
                            type: "error",
                            title: "Error",
                            text: "Could not be save!",
                        });
                    });
                }
            }
        },
        isEmptyNaN(val){
            if (isNaN(val)){ return true;}
            else if (val==null){ return true;}
            else if (val==''){ return true;}
            else{ return false;}
        },
    }
}
</script>
<style lang="scss" scoped>
    .edit_food_price {
        .food_item_price{
            border: 1px dashed transparent;
            &:hover{
                border: 1px dashed #fcd602;
            }
        }
        .content-editor{
            margin-top: 10px;
            input.price{
                width: 80px;
                text-align: right;
                -moz-appearance: textfield;
                &:focus{outline: none;}
                &::-webkit-inner-spin-button,
                &::-webkit-outer-spin-button{
                    -webkit-appearance: none;
                    margin: 0;
                }
            }
        }
    }
</style>
