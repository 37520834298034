<template>
    <div class="container-fluid container_gallery">
        <nav-tab></nav-tab>
        <grid-gallery @openImg="openPopUp($event)"></grid-gallery>
        <modal name="modal_gallery" class="modal_gallery" draggable=".window-header" height="auto" >
            <pop-modal></pop-modal>
        </modal>
    </div>
</template>
<script>
import "./stylesIndex.scss"
import navTab from "./navTabAlbum.vue"
import gridGallery from "./gridGallery.vue"
import popModal from "./popUpGallery.vue"
export default {
    components: {
        'nav-tab': navTab,
        'grid-gallery': gridGallery,
        'pop-modal': popModal,
    },
    data(){
        return{
        }
    },
    methods: {
        openPopUp(event){
            this.$modal.show("modal_gallery");
        }
    }
}
</script>
<style lang="scss" scoped>
</style>
