let updateGallery = function() {
    let t = this;
    let images = this.accitonPusher.item.images;
    let url = this.accitonPusher.item.url;
    t.JobsIFGallery = 0;
    t.JobsGalleryList = [];
    t.JobsGalleryURL = "";
    for (let i in t.listTaks) {
        if (t.listTaks.hasOwnProperty(i)) {
            if (t.listTaks[i].id_i == this.accitonPusher.item.job) {
                t.listTaks[i].gallery = 1;
                for (let prop in images) {
                    if (images.hasOwnProperty(prop)) {
                        t.listTaks[i].galleryList.push(images[prop]);
                    }
                }
                t.listTaks[i].url = url;
                t.JobsIFGallery = t.listTaks[i].gallery;
                t.JobsGalleryList = t.listTaks[i].galleryList;
                t.JobsGalleryURL = t.listTaks[i].url;
            }
        }
    }
    t.reEscribirLista();
};
export default updateGallery;
