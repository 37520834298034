import Loader from "@/core/components/shared/Loader.vue";
import modalLateral from '@/core/plugins/themeModalLateral/modalLateral'
import tfilters from '@/apps/Marketea/components/btnCallLink/tdynamicFilters'
import btnCallCode from '@/apps/Marketea/components/btnCallLink/btnCallCode'
export default {
	Loader,
	modalLateral,
	tfilters,
	btnCallCode,
};
