<template>
    <div class="project_header_list">
        <ul class="menu_list">
            <li class="item_list">
                <button type="button" class="btn btn-primary btn-sm" @click="$emit('newAdd', 'newProject')">+ </button>
            </li>
            <li class="item_list item_dropdown">
                <dropdown-menu
                        v-model="dropdownStatus.show"
                        :right="dropdownStatus.right"
                        :hover="dropdownStatus.hover"
                        :interactive="dropdownStatus.interactive"
                        :closeOnClickOutside="dropdownStatus.closeOnClick"
                >
                    <button class="link_list">
                        <span class="label">Filter list by:</span>
                        <span class="selected">{{dropdownStatusVal}}</span>
                    </button>
                    <div slot="dropdown">
                        <a class="dropdown-item" href="#" @click.prevent="filter(0)">All</a>
                        <a class="dropdown-item" href="#" @click.prevent="filter(1)">Current</a>
                        <a class="dropdown-item" href="#" @click.prevent="filter(2)">Completed</a>
                        <a class="dropdown-item" href="#" @click.prevent="filter(3)">To Be Built</a>
                    </div>
                </dropdown-menu>
            </li>
            <li class="item_list">
                <dropdown-menu
                        v-model="dropdownSetting.show"
                        :right="dropdownSetting.right"
                        :hover="dropdownSetting.hover"
                        :interactive="dropdownSetting.interactive"
                        :closeOnClickOutside="dropdownSetting.closeOnClick"
                >
                    <button class="link_list">
                        <i class="fas fa-cog"></i>
                    </button>
                    <div slot="dropdown">
                        <a class="dropdown-item" href="#" @click.prevent="openSetting('widgetPage')">Page Widget</a>
                        <a class="dropdown-item" href="#" @click.prevent="openSetting('widgetSlider')">Slider Widget</a>
                    </div>
                </dropdown-menu>
            </li>
        </ul>
    </div>
</template>
<script>
import DropdownMenu from '@innologica/vue-dropdown-menu';
import './menuHeader.scss';
export default {
    components: {
		'dropdown-menu': DropdownMenu,
    },
    data () {
        return {
            dropdownStatus: {
                show : false,
				right: true,
				hover: false,
				interactive: false,
				closeOnClick: true,
            },
            dropdownStatusVal: 'All',
            Opts: [
                "All",
                "Current",
                "Completed",
                "To Be Built",
            ],
            dropdownSetting: {
                show : false,
				right: true,
				hover: false,
				interactive: false,
				closeOnClick: true,
            },
        }
    },
    methods: {
        filter(val) {
            this.dropdownStatus.show=false;
            this.dropdownStatusVal=this.Opts[val];
            this.$emit('setFilter', val);
        },
        openSetting(open) {
            this.dropdownSetting.show=false;
            this.$emit('open', open);
        },
    },
}
</script>
<style lang="scss" scoped>

</style>
