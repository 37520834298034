<template>
	<div class="container-fluid">
		<div class="page-breadcrumb" style="padding-top:0px;">
			<div class="row">
				<div class="col-md-8 align-self-center">
					<h4 class="page-title">Square Up - Account</h4>
				</div>
				<div class="col-md-4 align-self-center">
					<div class="d-flex align-items-right justify-content-end">
						<router-link :to="{ name: 'Setting' }" class="btn btn-info btn-sm mr-2 ctrlbtn" style="margin-bottom: 10px;width: -webkit-fill-available;">
							<i class="fas fa-arrow-left"></i>
							Back
						</router-link>
					</div>
				</div>
			</div>
		</div>
		<div class="card card-body">
			<div class="row">
				<div class="col-md-12">
					<form @submit.prevent="setSquareAccount">
						<div v-if="isLoading"><Loader></Loader></div>
						<table class="table" style="width: 100%" v-else>
							<tr>
								<td colspan="2">
									<!-- <div class="alert alert-info" role="alert">
									  <h4 class="alert-heading">Info!</h4>
									  <p>The future of business is yours to shape. Register in <a target="_blank" href="https://squareup.com/us/en">Square Up</a></p>
									</div> -->
									<div v-if="error" class="alert alert-danger alert-error" role="alert">
									  <h4 class="alert-heading">Error!</h4>
									  <p>Your square up account is not registered. Please Contact us to receive a support: 774-287-3353 or support@marketea.com</p>
									</div>

								</td>
							</tr>
							<!-- <tr>
								<td style="width: 110px">Application ID:</td>
								<td>
									<input type="text" class="form-control" v-model="f.applicationID">
								</td>
							</tr>
							<tr>
								<td style="width: 110px">Access token:</td>
								<td>
									<input type="text" class="form-control" v-model="f.access_token">
								</td>
							</tr>
							<tr>
								<td style="width: 110px">Location ID:</td>
								<td>
									<input type="text" class="form-control" v-model="f.locationID">
								</td>
							</tr>
							<tr>
								<td colspan="2">
									<hr>
								</td>
							</tr> -->
							<tr>
								<td colspan="2">
									<h4>Show payment options in: </h4>
								</td>
							</tr>
							<tr>
								<td style="width: 110px">Option Invoice:</td>
								<td>
									<label class="radio-inline col-6">
										<span class="form-text"> Show</span>
										<input type="radio" :value="1" v-model="f.showInvo">
									</label>
									<label class="radio-inline col-6">
										<span class="form-text"> Hide</span>
										<input type="radio" :value="0" v-model="f.showInvo">
									</label>
								</td>
							</tr>
							<tr>
								<td style="width: 110px">Button Email:</td>
								<td>
									<label class="radio-inline col-6">
										<span class="form-text"> Show</span>
										<input type="radio" :value="1" v-model="f.showEmail">
									</label>
									<label class="radio-inline col-6">
										<span class="form-text"> Hide</span>
										<input type="radio" :value="0" v-model="f.showEmail">
									</label>
								</td>
							</tr>
							<tr>
								<td style="width: 110px">Link PDF:</td>
								<td>
									<label class="radio-inline col-6">
										<span class="form-text"> Show</span>
										<input type="radio" :value="1" v-model="f.showPdf">
									</label>
									<label class="radio-inline col-6">
										<span class="form-text"> Hide</span>
										<input type="radio" :value="0" v-model="f.showPdf">
									</label>
								</td>
							</tr>
							<tr>
								<td colspan="2">
									<button type="submit" class="btn btn-success">Save</button>
								</td>                         
							</tr>
						</table>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Loader from '@/core/components/shared/Loader'
export default {
	name: 'squareAccount',
	components: {
		Loader,
	},
	data () {
		return {
			isLoading: false,
			f: {
				user_id: window.master_client,
				org_id: window.localorgdata,
				applicationID: '',
				access_token: '',
				locationID: '',
				status: 1,
				showInvo: 0,
				showEmail: 0,
				showPdf: 0,
			},
			error: false
		}
	},
	created() {
		this.getSquareAccount();
	},
	methods: {
		getSquareAccount() {
			this.isLoading = true;
			let t = this;
			window.master.post('getDataForLoginSetting', {
				user_id: window.master_client,
				org_id: window.localorgdata,
			}).then((response) => {
				if(response.data.status) {
					let r = response.data.r;
					t.f.applicationID = r.applicationID;
					t.f.access_token = r.access_token;
					t.f.locationID = r.locationID;
					t.f.showInvo = r.showInvo;
					t.f.showEmail = r.showEmail;
					t.f.showPdf = r.showPdf;
				} else {
					console.log('no hay datos de square Up');
					t.error=true;
				}
				t.isLoading = false;
			}).catch((error) => {
				this.isLoading = false;
				console.log(error);
			});
		},
		setSquareAccount() {
			this.isLoading = true;
			window.master.post('setSquareUpForLoginSetting', this.f)
			.then((response) => {
				this.$store.commit('core/setNotiPush', {
					show: true,
					group: 'noti',
					type: 'success',
					title: 'Saved',
					text: 'Successful'
				});
				this.isLoading = false;
			}).catch((error) => {
				this.isLoading = false;
				this.$store.commit('core/setNotiPush', {
					show: true,
					group: 'noti',
					type: 'error',
					title: 'Saved',
					text: 'fail!'
				});
				console.log(error);
			});
		}
	}
};
</script>
<style lang="scss" scoped>
@media (min-width: 1279px) {
    .ctrlbtn {
        width:160px !important;
    }
    .ctrlbtnCustom {
        width:100px !important;
    }    
    .ctrlbtnShow {
        display: none !important;
    }    
}
@media (max-width: 1281px) {
    .ctrlbtnHiden {
        display: none !important;
    }    
}

.contenedor-table table thead tr th {
    font-weight: bold;
}
@media (max-width: 1100px) {
    .contenedor-table {
        max-width: 924px;
        overflow-x: scroll;
    }
}
</style>
