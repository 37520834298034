export default {
    getShowAside(state) { return state.showAside; },
    getHeader(state) {return state.header; },
    get_accitonPusher(state) { return state.accitonPusher;},
    get_chatActive(state) { return state.chatActive;},
    getOpenModal(state) { return state.openModal;},
    getModalActivity(state) { return state.openModal.data;},
    getLoadingModal(state) { return state.loadingModal;},
    getNotiPush(state) { return state.notiPush;},
    getNotiUpdate(state) { return state.notiUpdate;},
    getAlertSimple(state) { return state.alertSimple; },
    getAlertSimpleShow(state) { return state.alertSimple.show; },
    getAlertConfirm(state) { return state.alertConfirm; },
    getAlertConfirmShow(state) { return state.alertConfirm.show; },
    getNotiPushRefresh(state) { return state.notiPushRefresh; },
    getActionRefresh(state) { return state.actionRefresh; },
    get_showSuscription(state) { return state.showSuscription; },
    get_paletaDeColores(state) { return state.paletaDeColores; },
    //userClientID: state => state.userID,
}
