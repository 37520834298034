let get_menuTags = function() {
	let tags = this.get_menuTags;
	let t = this.$route.params.id;
	for (let i in tags) {
		if (parseInt(t) == parseInt(tags[i].id)) {
			this.tagsModalName = tags[i].name;
			this.tagsModalId = tags[i].id;
		}
	}
};
export default get_menuTags;
