var crm = "/crm/";
export default {  
	get(){
		return crm+"getBntList";
	},
	add(){
		return crm+"addBtnCall";
	},
	edit(){
		return crm+"editBntCall";
	},
	del(){
		return crm+"delBntCall";
	},
	editStatus(){
		return crm+"editStatusBntCall";
	},
};
