<template>
	<div class="card">
		<div class="card-header">
			<h5 class="card-title">
				<button
					@click.prevent="addBanking"
					class="btn btn-success"
				>
					<i class="fas fa-plus"></i> Add Banking
				</button>
			</h5>
		</div>
		<div class="card-body">
			<table
				class="table table-striped table-bordered table-hover"
				id="tbanking"
			>
				<thead>
					<tr>
						<th style="width: 85%; padding-left: 15px">
							Account Details
						</th>
						<th class="text-center" style="width: 5%">
							Status
						</th>
						<th style="width: 10%"></th>
					</tr>
				</thead>
				<tbody v-if="isLoading">
					<tr>
						<td colspan="3"><Loader></Loader></td>
					</tr>
				</tbody>
				<tbody v-else>
					<tr
						v-for="(i, index) in bankingList"
						v-bind:key="index"
					>
						<td
							@click="
							selectBankingChild(
								i.id,
								i.bank_name,
								i.account_type,
								i.account_number
							)
							"
						>
							<div style="cursor: pointer">
								<div class="icono-banking">
									<i v-if="i.account_type == 1" class="fa fa-university fa-2x"></i>
									<i v-if="i.account_type == 0" class="fas fa-money-check-alt fa-2x"></i>
								</div>
								<div class="details-banking">
									<strong class="text-info">{{ i.bank_name }} </strong>
									<br />
									<small>{{i.account_number.slice(-4)}}</small>
								</div>
							</div>
						</td>
						<td class="text-center">
							<span v-if="i.activo == 1" class="badge badge-success">Active</span>
							<span v-if="i.activo == 0" class="badge badge-danger">Inactive</span>
						</td>
						<td class="text-center">
							<span class="cdropdown">
								<button>Actions</button>
								<label>
									<input type="checkbox" />
									<ul>
										<li @click="editBanking(i.id)">
											<button>
												<i class="fas fa-edit"></i>
												Edit
											</button>
										</li>
										<li
											v-if="i.activo == 0" @click=" activeBankin(i.id)"
										>
											<button>
												<i class="fas fa-check-square"></i>
												Active
											</button>
										</li>
										<li v-if="i.activo == 1" @click="inactiveBankin(i.id)">
											<button>
												<i class="fas fa-ban"></i>
												Inactive
											</button>
										</li>
										<li v-if="i.inUse == 0" @click="deleteBankin(i.id)">
											<button>
												<i class="fas fa-trash"></i>
												Delete
											</button>
										</li>
									</ul>
								</label>
							</span>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>
<script>
import Loader from "@/core/components/shared/Loader";
export default {
	name: 'listBanking',
	components: {
        Loader
    },
	data () {
		return {
			isLoading: false,
			bankingList: []
		}
	},
	created() {
		this.getBanking();
	},
	methods: {
		getBanking() {
			this.isLoading = true;
			window.billing.get("bankings/" + window.localuserdata)
			.then((response) => {
				this.isLoading = false;
				this.bankingList = response.data.result;
			})
			.catch((error) => {
				this.isLoading = false;
				console.log(error);
			});
		},
		selectBankingChild(id, name, type, num) {
			let selectBank = {}
			selectBank.id = id;
			selectBank.account_type = type;
			selectBank.bank_name = name;
			selectBank.account_number = num;
			selectBank.list = false;
			selectBank.tablas = true;
			selectBank.unaccepted = true;
			selectBank.accepted = false;
			this.$store.commit("ModApps/billing/SetSelectBank", selectBank);
			this.$router.push({ name: "BankingTransac", params: { idb: id } });
		},
		addBanking() {
			this.$router.push({ name: "BankingNew" });
		},
		editBanking(id) {
			this.$router.push({ name: "BankingEdit", params: { idb: id } });
		},
		inactiveBankin(id) {
			this.$swal({
				title: "Disable this Bank?",
				text: "Are you sure?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				confirmButtonText: "Yes, Delete it!",
			}).then((result) => {
				if (result.value) {
					this.isLoading = true;
					window.billing
					.get("banking/void/" + id)
					.then((response) => {
						this.$notify({
							group: "noti",
							title: "Success",
							type: "success",
							text: "Successfull",
						});

						this.getBanking();
					})
					.catch((error) => {
						this.isLoading = false;
						console.log(error);
					});
				}
			});
		},
		activeBankin(id) {
			this.isLoading = true;
			window.billing
			.get("banking/active/" + id)
			.then((response) => {
				this.$notify({
					group: "noti",
					title: "Success",
					type: "success",
					text: "Successfull",
				});
				this.getBanking();
			})
			.catch((error) => {
				this.isLoading = false;
				console.log(error);
			});
		},		
		deleteBankin(id) {
			this.$swal({
				title: "Delete this Bank?",
				text: "Are you sure?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				confirmButtonText: "Yes, Delete it!",
			}).then((result) => {
				if (result.value) {
					this.isLoading = true;
					window.billing
					.post("banking/delete", { id: id })
					.then((response) => {
						this.$notify({
							group: "noti",
							title: "Success",
							type: "success",
							text: "Successfull",
						});
						this.getBanking();
					})
					.catch((error) => {
						this.isLoading = false;
						console.log(error);
					});
				}
			});
		},
    }
};
</script>
<style lang="css" scoped>
#tbanking thead tr th {
    font-weight: bold;
}
.icono-banking {
    float: left;
    width: 100%;
    max-width: 40px;
    height: 40px;
    display: inline-block;
    margin-right: 5px;
}
.details-banking {
    float: left;
    width: 100%;
    max-width: 180px;
    height: 40px;
    display: inline-block;
}
</style>
