<template>
    <div class="filesAttach card">
        <form @submit.prevent="saveContent" enctype="multipart/form-data">
            <div class="card-header">
                <div class="row">
                    <div class="col-6" style="padding-top: 10px">
                        <h5 class="card-title">Task <span class="badge badge-success" v-if="item!=null">#{{item.idx}}</span></h5>
                    </div>
                    <div class="col-5 text-right">
                        <button v-if="!isAttach" type="button" class="btn btn-ligth">
                            PDF
                        </button>
                        <button v-if="!isAttach" type="button" class="btn btn-ligth" @click="isAttach=true">
                            Edit
                        </button>
                        <button v-if="isAttach" type="button" class="btn btn-ligth" @click="saveContent" :disabled="btnSave">
                            Save Content
                        </button>
                        <button v-if="isAttach" type="button" class="btn btn-ligth" @click="isAttach=false">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body" style="padding: 5px 10px;">
                <div class="row">
                    <div class="col-12" >
                        <div v-html="f.dom_html" v-if="!isAttach" class="previewHTML"></div>
                        <vue-editor
                            v-else
                            v-model="f.dom_html"
                            :editor-toolbar="customToolbar"
                            ref="domHtml"
                            useCustomImageHandler
                            @image-added="handleImageAdded"
                        ></vue-editor>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import { VueEditor } from 'vue2-editor';
import './filesAttach.scss';
export default {
    name:"filesAttach",
    components: {
        VueEditor,
    },
    data(){
        return {
            isAttach: false,
            customToolbar: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ["size","bold", "underline"], // toggled buttons
                [
                    { align: "" },
                    { align: "center" },
                ],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ color: [] }],
                ["link", "image"],
                ["clean"] // remove formatting button
            ], 
            item: null,
            f : {
                user_idd: window.master_client,
                user_id: window.localorgdata,
                org_id: window.localorgdata,
                oauth_m: window.oauth_m,
                task_id: 0,
                dom_html: null,
            },
            btnSave: false,
        }
    },
    created(){
        let t= this;
        let dataItem = t.$store.getters['core/getOpenModal'].data;
        console.log('filesAttach', dataItem);
        t.item=dataItem;
        t.f.task_id=dataItem.id;
        t.getContent();
    },
    methods: {
        closeModalLateral() {
            let t = this;
            t.$modal.hide("modal-lateral");
        },
        handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
            let t = this;
            var setImage = new FormData();
            setImage.append("user_id", t.f.user_idd);
            setImage.append("org_id", t.f.org_id);
            setImage.append("folder", 'so_task');
            setImage.append("img1", file, file.name);
            setImage.append("nombreIMG", "img");
            setImage.append("task_id", t.f.task_id);
            setImage.append("contIMG", 1);
            setImage.append("delIMG", "imgOld");
            setImage.append("contDel", 0);
            window.masterGetBilling
            .post("subirIMG", setImage)
            .then(result => {
                let url = window.urlmaster+'images/no-image.png'; // imagen x defaul
                let name_image = '';
                if(result.data.imgs["1"]){
                   url = window.urlmaster + result.data.rooPath.slice(1) + result.data.imgs["1"]; 
                   name_image = result.data.imgs["1"];
                   Editor.insertEmbed(cursorLocation, "image", url);
                   resetUploader();
                }else{
                    t.$notify({
                        group: "noti",
                        type: "error",
                        title: "Error:",
                        text: "Image upload failed, please try again.",
                    });
                }
            })
            .catch(err => {
                console.log(err);
            });
        },
        getContent: function() {
            let t = this;
            t.$store.commit("core/setLoadingModal", true);
            window.billing
            .post("getTaskAttach", t.f)
            .then((response) => {
                console.log('getTaskAttach', response.data);
                if(response.data.status){
                    t.f.dom_html = response.data.r.dom_html;
                    t.f.id = response.data.r.id;
                    t.isAttach=false;
                }else{
                    t.isAttach=true;
                }
                t.$store.commit("core/setLoadingModal", false);
            })
            .catch((error) => {
            console.log(error);
                t.$store.commit("core/setLoadingModal", false);
            });
        },    
        saveContent: function() {
            let t = this;
            t.btnSave = true;
            t.$store.commit("core/setLoadingModal", true);
            window.billing
            .post("setTaskAttach", t.f)
            .then((response) => {
                t.$store.commit("core/setLoadingModal", false);
                console.log('setTaskAttach', response.data);
                t.btnSave = false;
                if(response.data.status){t.isAttach=false;}
                else{
                    t.$notify({
                        group: "noti",
                        type: "error",
                        title: "Error:",
                        text: "Failed to save content, please try again.",
                    });
                }
            })
            .catch((error) => {
            console.log(error);
                t.btnSave = false;
                t.isAttach=false;
                t.$store.commit("core/setLoadingModal", false);
            });
        },
    },
}
</script>
<style lang="scss" scoped>
.btn-ligth{margin-right: 10px;background-color:#fff;border-color:#ccc;}

</style>
