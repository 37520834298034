<template>
	<div class="card">
		<div class="card-header">
			<h5 class="card-title">Jobs/Customer</h5>
		</div>
		<div class="card-body">
			<div class="row justify-content-md-center" v-if="$parent.$parent.$parent.editCusInv == 'form'">
				<div class="col-md-12">
					<div class="form-group">
						<label>Customer: </label>
						<model-select
						:options="$parent.$parent.$parent.customer"
						v-model="$parent.$parent.$parent.customer_id"
						placeholder="Choose a Customer"
						@input="$parent.$parent.$parent.getinvoice"
						class="form-control customerList"
						></model-select>
					</div>
					<div class="form-group invoiceList">
						<label>Invoice: </label>
						<model-select
						v-if="$parent.$parent.$parent.customer_id > 0"
						:options="$parent.$parent.$parent.invoice"
						v-model="$parent.$parent.$parent.invoice_id"
						placeholder="Choose a Invoice"
						class="form-control invoiceList"
						></model-select>
						<select v-else class="form-control" disabled="true">
							<option>None</option>
						</select>
					</div>
					<div class="form-group" style="margin-bottom: 100px">
						<button
							type="button"
							class="btn btn-success"
							@click="$parent.$parent.$parent.AssignCustomer"
						>
							<i class="fas fa-save"></i> Save
						</button>
					</div>
				</div>
			</div>
			<div class="card-body" v-else>
				<showNameCustomer :itemNameCustomers="$parent.$parent.$parent.itemNameCustomers"></showNameCustomer>
			</div>
		</div>
	</div>
</template>
<script>
import { ModelSelect } from 'vue-search-select'
import showNameCustomer from '@/apps/jobs/components/jobslist/nameCustomer'
export default {
	name: 'mCustomer',
	components: {
		ModelSelect,
		showNameCustomer
	},
	data () {
		return {
		}
	},
	created(){
	}
}
</script>
<style lang="css" scoped>
</style>
