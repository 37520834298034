let setTags = function(d) {
	let v = this;
	if(Object.keys(d).length > 0 && d.job > 0){
        let idx = '0';
        for (let i in v.listTaks) {
            if (v.listTaks[i]) {
                if (v.listTaks[i].id_i == d.job) {
                    idx = i;
                    v.listTaks[i].tags = d.tags;
                    v.reEscribirLista(idx);
                    v.starGrid();
                }
                idx++;
            }
        }
    }
};
export default setTags;
