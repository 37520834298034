<template>
    <div>
        <form
            @submit.prevent="UpdateCover"
            enctype="multipart/form-data"
            id="form1"
            class="custominputs"
        >
            <div class="card-body">
                <div class="imgmodalContent2">
                    <div class="img"><img :src="imgLarge" style="margin: 0 auto" /></div>
                    <div class="imgInfo">
                        <div class="row" style="padding-bottom: 10px">
                            <div class="col-12">
                                <label>Name</label>
                                <input type="text" class="form-control" v-model="name" />
                            </div>
                        </div>
                        <span style="margin-top: 15px"><b>Rotate:</b></span>
                        <div class="btnsRotaes">
                            <span>
                                -90°
                                <button type="button" @click="rotateAction('90')">
                                    <i class="fas fa-undo"></i>
                                </button>
                            </span>
                            <span style="margin-left: 7px">
                                +90°
                                <button type="button" @click="rotateAction('-90')">
                                    <i class="fas fa-undo rotate_custom"></i>
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer d-flex justify-content-end">
                <button type="submit" class="btn btn-success btn-sm">UPDATE</button>
            </div>
        </form>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    name: "modalCover",
    props: {
        item: { type: Object, required: true },
        url: { type: String, required: true },
    },
    data() {
        return {
            title: null,
            id: null,
            name: "",
            imgLarge: "",
            type: "",
            urlimg: null,
            cover: null,
        };
    },
    created() {
        this.cargarCover();
    },
    methods: {
        cargarCover() {
            //console.log('url', this.url)
            //console.log('item', this.item)
            this.id = this.item.id;
            this.title = this.item.name;
            this.name = this.item.name_cover;
            this.type = this.item.type;
            this.cover = this.item.cover;
            this.urlimg = this.url;
            this.imgLarge = this.urlimg.replace("small", "large") + this.cover;
        },
        rotateAction(angle) {
            window.SlabsV2.post("rotateimg", {
                org_id: window.localuserdata,
                user_id: window.localAccountID,
                prefijo: "cover",
                angle: angle,
                type: this.type,
                img: this.cover,
                id: this.id,
            })
                .then((response) => {
                    let t = [];
                    t.push({ open: 1 });
                    this.arrays("coverEdit", t);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        UpdateCover() {
            window.SlabsV2.post("coverName", {
                id: this.id,
                name: this.name,
            })
                .then((response) => {
                    let t = [];
                    t.push({ open: 1 });
                    this.arrays("coverEdit", t);
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        arrays(name, data) {
            let k = [];
            k.push({ function: name, data: data });
            this.$store.commit("ModApps/slabs/set_slabsStorage", k);
        },
    },
    computed: {
        ...mapGetters("ModApps/slabs", ["get_slabsStorage"]),
    },
};
</script>

<style lang="scss" scoped>
.btnsRotaes {
    display: flex;
    justify-content: center;
    button {
        background-color: #5ac146;
        color: #fff;
        border: 0;
        border-radius: 3px;
        cursor: pointer;
    }
}
.rotate_custom {
    transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
}

.custominputs {
    input {
        border: 1px solid #ccc;
    }
}
.imgmodalContent2 {
    display: flex;
    .img {
        width: 65%;
        text-align: center;
        margin-right: 10px;
        img {
            width: 100%;
            height: 300px;
            object-fit: contain;
        }
    }
    .imgInfo {
        padding-left: 10px;
        width: 35%;

        span {
            display: block;
        }
    }
}
</style>
