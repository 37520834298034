let getkeyVal = function(id) {
	window.billing
    .post('getKeyValSetting', {
        user_id: window.master_client,
        org_id: window.localorgdata,
        meta_key: id,
    })
    .then((response) => {
        console.log(response.data);
        if(response.data.status){
            if(id == 'showSMS'){
                this.$store.commit('ModApps/billing/setShowSMS', response.data.r.meta_value);
            }
            if(id == 'JobsInt'){
                this.JobsInt =response.data.r.meta_value;
                console.log('JobsInt', this.JobsInt)
            }
        }
    })
    .catch((error) => {
        console.log(error);
    });
};
export default getkeyVal;
