<template>
    <div>
        <div class="card">
            <div class="card-header">
                ADD PAYMENT
                <span
                    style="
                        color: #fff;
                        background-color: #4caf50;
                        padding: 3px;
                        border-radius: 3px;
                    "
                >
                    INV #{{ form.numdoc }}</span
                ><span style="padding-left: 5px; font-size: 16px"
                    >{{ previewcustomername }} {{ previewcustomerlastname }} -
                    {{ previewcustomerbusinessname }}</span
                >
            </div>
            <form
                @submit.prevent="savePayment"
                enctype="multipart/form-data"
                id="form1"
            >
                <div class="card-body">
                    <div style="display: flex; color: #fff; margin-bottom: 10px">
                        <div
                            style="
                                background-color: #87c37b;
                                width: 50%;
                                text-align: right;
                                padding: 10px;
                            "
                        >
                            Total:
                            <span style="font-size: 19px"
                                >${{ previewtotal | formatMoney }}</span
                            >
                        </div>
                        <div
                            style="
                                background-color: #737373;
                                width: 50%;
                                padding: 10px;
                            "
                        >
                            Balance:
                            <span style="font-size: 19px"
                                >${{ previewbalance | formatMoney }}</span
                            >
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label>Pmt Method</label>
                            <model-select
                                required
                                :options="PaymentMethodsList"
                                v-model="form.method_id"
                                class="metodos"
                                placeholder="Choose a Method"
                            ></model-select>
                        </div>
                        <div class="form-group col-md-5">
                            <label for="inputCity">Ref #</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="form.ref"
                            />
                        </div>
                        <div class="form-group col-md-3">
                            <label for="inputZip">Amount</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">$</span>
                                </div>
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="0.00"
                                    required
                                    v-model="form.price"
                                    @keyup="validP"
                                />
                                <span
                                    class="error"
                                    style="color: red"
                                    v-if="form.vatError"
                                    >{{ form.vatErrorMsg }}</span
                                >
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="">Admin Notes</label>
                        <textarea
                            class="form-control"
                            rows="2"
                            v-model="form.memo"
                        ></textarea>
                    </div>
                    <div class="form-group">
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                id="gridCheck"
                                v-model="form.send_invoice"
                            />
                            <label class="form-check-label" for="gridCheck">
                                Send Invoice/Payment notification mail to the client
                            </label>
                        </div>
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                id="gridCheck2"
                                v-model="form.send_img"
                            />
                            <label class="form-check-label" for="gridCheck2">
                                Send Image
                            </label>
                        </div>
                    </div>
                    <div class="form-group">
                        <input
                            type="file"
                            style="display: none"
                            ref="img1"
                            accept="image/*"
                            @change="fileImg1"
                        />
                        <button
                            type="button"
                            class="btn btn-info"
                            @click="$refs.img1.click()"
                        >
                            Attachment 1
                        </button>
                        <div v-if="Showimg1" style="margin-top: 10px">
                            <img
                                v-bind:src="preview1"
                                id="img1"
                                @click="$refs.img1.click()"
                                style="width: 100%; max-width: 600px; width: 50px"
                            />
                        </div>
                    </div>
                    <div class="form-group">
                        <input
                            type="file"
                            style="display: none"
                            ref="img2"
                            accept="image/*"
                            @change="fileImg2"
                        />
                        <button
                            type="button"
                            class="btn btn-info"
                            @click="$refs.img2.click()"
                        >
                            Attachment 2
                        </button>
                        <div v-if="Showimg2" style="margin-top: 10px">
                            <img
                                v-bind:src="preview2"
                                id="img2"
                                @click="$refs.img2.click()"
                                style="width: 100%; max-width: 600px; width: 50px"
                            />
                        </div>
                    </div>
                    <div style="width: 100%; text-align: right; margin-top: 20px">
                        <button
                            type="submit"
                            class="btn btn-success btn-lg"
                            :disabled="isDisabled"
                        >
                            <i class="fas fa-save"></i> SAVE
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import { ModelSelect } from 'vue-search-select'
export default {
    components: { 
        ModelSelect
     },
    name: "formPayment",
    data () {
        return {
            Showimg1: false,
            Showimg2: false,
            preview1: "",
            preview2: "",                     
            isDisabled: true,
            previewcustomername: null,
            previewcustomerlastname: null,
            previewcustomerbusinessname: null,
            PaymentMethodsList: [],           
            form: {
                id: null,
                numdoc: null,
                user_id: window.localuserdata,
                customer_id: null,
                invoice_id: "",
                fecha: new Date().toISOString().slice(0, 10),
                method_id: "",
                ref: null,
                price: null,
                memo: null,
                send_invoice: false,
                send_img: false,
                img1: null,
                img2: null,
                imgOld1: null,
                imgOld2: null,
                status: 1,
                ope: "create",
                vatError: false,
                vatErrorMsg: null,
            },
        };
    },
    created () {
        this.init()
    },
    methods: {
        init() {
            let g = this.$store.getters['core/getOpenModal'];
            let r = g.data;            
            // this.form = r.item;
            // this.form.send_invoice = false;
            // this.form.status = 1;
            // this.form.invoice_id = r.item.id;
            // this.form.numdoc = r.form.numdoc;
            this.form= r.form;
            this.form.user_id= window.localuserdata;
            this.form.method_id = 2;
            this.totalinvoice = r.totalinvoice;
            this.previewcustomername = r.previewcustomername;
            this.previewcustomerlastname = r.previewcustomerlastname;
            this.previewcustomerbusinessname = r.previewcustomerbusinessname;
            this.previewtotal = r.previewtotal;
            this.previewbalance = r.previewbalance;
            this.PaymentMethodsList = r.PaymentMethodsList;
        },
        savePayment() {
            this.isDisabled = true;
            this.subirIMGs();
        },
        subirIMGs() {
            let t = this;
            t.isLoading = true;
            let f = new FormData();
            let contIMG = 0;
            let contDel = 0;
            for (let i in t.form) {
                if (i == "img1" && t.form[i] !== null && t.validimg1 == true) {
                    contIMG++;
                    let nom = "img" + contIMG;
                    f.append(nom, t.form[i], t.form[i].name);
                }
                if (i == "img2" && t.form[i] !== null && t.validimg2 == true) {
                    contIMG++;
                    let nom = "img" + contIMG;
                    f.append(nom, t.form[i], t.form[i].name);
                }
                if (i == "imgOld1" && t.form[i] !== null && t.validimgOld1 == true) {
                    contDel++;
                    let nom = "imgOld" + contDel;
                    f.append(nom, t.form[i]);
                }
                if (i == "imgOld2" && t.form[i] !== null && t.validimgOld2 == true) {
                    contDel++;
                    let nom = "imgOld" + contDel;
                    f.append(nom, t.form[i]);
                }
            }
            f.append("nombreIMG", "img");
            f.append("contIMG", contIMG);
            f.append("delIMG", "imgOld");
            f.append("contDel", contDel);
            f.append("folder", "payments");
            f.append("org_id", window.localorgdata);
            this.$store.commit("core/setLoadingModal", true);
            window.master
                .post("upload", f)
                .then((response) => {
                    let imgs = response.data.imgs;
                    console.log(imgs);
                    this.saveData(imgs);
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.isDisabled = false;
                    this.$store.commit("core/setLoadingModal", false);
                    console.log(error);
                });
        },  
        saveData(imgs) {
            let t = this;
            t.isLoading = true;
            this.isDisabled = true;
            let f = new FormData();
            for (let prop in t.form) {
                if (
                    prop === "img1" &&
                    t.form[prop] !== null &&
                    t.validimg1 === true
                ) {
                    f.append(prop, imgs[1]);
                } else if (
                    prop === "img2" &&
                    t.form[prop] !== null &&
                    t.validimg2 === true &&
                    t.validimg1 === true
                ) {
                    f.append(prop, imgs[2]);
                } else if (
                    prop === "img2" &&
                    t.form[prop] !== null &&
                    t.validimg2 === true &&
                    t.validimg1 === false
                ) {
                    f.append(prop, imgs[1]);
                } else if (prop === "send_invoice") {

                } else {
                    f.append(prop, t.form[prop]);
                }
            }
            if(t.form.send_invoice === false){
                f.append("send_invoice", 0);
            }else{
                f.append("send_invoice", 1);
            }

            if(t.form.send_img === false){
                f.append("send_img", 0);
            }else{
                f.append("send_img", 1);
            } 
            if (t.validimg1 === true) {
                f.append("validimg1", 1);
            } else {
                f.append("validimg1", 0);
            }
            if (t.validimg2 === true) {
                f.append("validimg2", 1);
            } else {
                f.append("validimg2", 0);
            }
            if (t.validimgOld1 === true) {
                f.append("validimgOld1", 1);
            } else {
                f.append("validimgOld1", 0);
            }
            if (t.validimgOld2 === true) {
                f.append("validimgOld2", 1);
            } else {
                f.append("validimgOld2", 0);
            }
            f.append("folder_user", localStorage.getItem("folder_user"));
            f.append("org_id", localStorage.getItem("mark_client_data"));
            f.append("user_idd", localStorage.getItem("mark_client_data_m"));
            f.append("typeUser", localStorage.getItem("user_type"));
            f.append("oauth", localStorage.getItem("oauth_m"));
            f.append("msj", 'add desde invoice make a payment');
                t.$store.commit("core/setLoadingModal", true);
                window.billing
                    .post("payments/create", f)
                    .then((response) => {
                        console.log(response);
                        this.form.ref = null;
                        this.form.numdoc = null;
                        this.form.fecha = new Date(
                            (new Date().toISOString() + "").slice(0, 10) +
                                "T23:31:04.000Z"
                        ).toISOString();
                        this.form.customer_id = null;
                        this.form.id = null;
                        this.form.invoice_id = null;
                        this.form.method_id = 12;
                        this.form.memo = null;
                        this.form.price = null;
                        this.previewtotal = null;
                        this.previewbalance = null;
                        this.previewcustomername = null;
                        this.previewcustomerlastname = null;
                        this.previewcustomerbusinessname = null;
                        this.$notify({
                            group: "noti",
                            title: "Success",
                            type: "success",
                            text: "Successful registration",
                        });
                        t.$store.commit("core/setLoadingModal", false);
                        t.$store.commit("core/setCloseModal", {
                            action: 'saved',
                            methods:'invoPayment'
                        });  
                        t.isLoading = false;
                        t.isDisabled = false;
                    })
                    .catch((error) => {
                        t.isLoading = false;
                        t.isDisabled = false;
                        t.$store.commit("core/setLoadingModal", false);
                        console.log(error);
                    });
        },                   
        validP() {
            let vatIdRegex = new RegExp(/^\d+(\.\d{0,2})?$/);
            //let vatIdRegex =  new RegExp(/^\d*(\.\d{1})?\d{0,1}$/)
            if (
                !vatIdRegex.test(
                    parseFloat(
                        parseFloat(parseFloat(this.form.price).toFixed(2))
                    ).toFixed(2)
                ) &&
                event.key != "."
            ) {
                this.form.vatError = true;
                this.form.vatErrorMsg =
                    "Enter the valid number, with a maximum of two decimal places.";
                this.isDisabled = true;
            } else if (parseFloat(parseFloat(this.form.price).toFixed(2)) == 0) {
                this.form.vatError = true;
                this.form.vatErrorMsg = "Must be greater than zero.";
                this.isDisabled = true;
            } else if (
                this.totalinvoice != undefined &&
                parseFloat(parseFloat(this.form.price).toFixed(2)) >
                    parseFloat(parseFloat(this.totalinvoice).toFixed(2))
            ) {
                this.form.vatError = true;
                this.form.vatErrorMsg = "Amount cannot be greater than the balance.";
                this.isDisabled = true;
            } else {
                this.form.vatError = false;
                this.form.vatErrorMsg = "";
                this.isDisabled = false;
            }
        },
        fileImg1(event) {
            this.form.img1 = event.target.files[0];
            this.validimg1 = true;
            this.Showimg1 = true;
            if (event.target.files && event.target.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = function (e) {
                    document.querySelector("#img1").src = e.target.result;
                };
            }
        },
        fileImg2(event) {
            this.form.img2 = event.target.files[0];
            this.validimg2 = true;
            this.Showimg2 = true;
            if (event.target.files && event.target.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = function (e) {
                    document.querySelector("#img2").src = e.target.result;
                };
            }
        },        
    },
    watch: {
    },
    computed: {},
};
</script>

<style lang="css" scoped>
.avisoCompany {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 0px 80px;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
}
</style>
