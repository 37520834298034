let updateStatusTask = function() {
    var refresh = true;
    if (this.$router.currentRoute.name != "JobsComppleted") {
        var valid = false;
        for (var k in this.listTaks) {
            if (this.listTaks.hasOwnProperty(k)) {
                if (this.listTaks[k].id_i == this.accitonPusher.item.job) {
                    //si jobs era diferente de complete y ahora es complete y esta en la lista de peding, eliminar
                    // de la lista de pending y actualizar contador
                    if (
                        this.accitonPusher.item.job_status_old < 4 &&
                        this.accitonPusher.item.job_status == 4
                    ) {
                        this.listTaks.splice(k, 1);
                        this.listTaks[parseInt(k) + 1].pusher += 1;
                    }
                    //sino es ninguna de las anteriores solo actualizo status desde la lista de pending
                    else {
                        for (var i in this.listTaks[k].taks) {
                            if (this.listTaks[k].taks.hasOwnProperty(i)) {
                                if (
                                    this.listTaks[k].taks[i].id ==
                                    this.accitonPusher.item.tak_id
                                ) {
                                    this.listTaks[k].taks[
                                        i
                                    ].id_status = this.accitonPusher.item.tak_status;
                                }
                            }
                        }
                        refresh = false;
                    }
                    valid = true;
                }
            }
        }
        if (
            !valid &&
            this.accitonPusher.item.job_status_old == 4 &&
            this.accitonPusher.item.job_status < 4
        ) {
            this.newAddRefresh(this.accitonPusher.item.job, 1);
        }
    }
    if (this.$router.currentRoute.name == "JobsComppleted") {
        let valid = false;
        for (let k in this.listTaks) {
            if (this.listTaks.hasOwnProperty(k)) {
                if (this.listTaks[k].id_i == this.accitonPusher.item.job) {
                    //si era completado y ahora no, elimino y actualizo contador
                    if (
                        this.accitonPusher.item.job_status_old == 4 &&
                        this.accitonPusher.item.job_status < 4
                    ) {
                        this.listTaks.splice(k, 1);
                    }
                    valid = true;
                }
            }
        }
        if (
            !valid &&
            this.accitonPusher.item.job_status_old < 4 &&
            this.accitonPusher.item.job_status == 4
        ) {
            this.newAddRefresh(this.accitonPusher.item.job, 1);
        }
    }
    this.reEscribirLista();
    let tag_id = this.id_tags.length > 0 ? this.id_tags[0] : 0;
    this.update_JOPending(tag_id);
    if (refresh) {
        this.starGrid();
    }
};
export default updateStatusTask;
