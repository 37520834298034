<template>
    <div class="container">
        <form @submit.prevent="form">
            <div class="card" style="margin-top: 10px">
                <div class="card-header">
                    <div class="row">
                        <div class="col-5">
                            <h3 class="tituloJobs">Job Order {{ titulo }}</h3>
                        </div>
                        <div class="col-7 text-right">
                            <button
                                type="submit"
                                class="btn btn-success"
                                :disabled="btnSave"
                            >
                                <i class="fas fa-save"></i> SAVE
                            </button>
                            <button
                                type="button"
                                @click="cancel"
                                class="btn btn-light"
                                style="margin-left: 10px"
                            >
                                <i class="fas fa-times"></i> Cancel
                            </button>
                            <button
                                @click="del(f.id)"
                                type="button"
                                class="btn btn-danger"
                                style="margin-left: 10px"
                                v-if="edit"
                            >
                                <i class="fas fa-trash"></i> DELETE
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <Loader v-if="isLoading"></Loader>
                    <div v-else class="row">
                        <div class="col-md-7">
                            <div class="form-group">
                                <label>Job Name:</label>
                                <textarea
                                    v-model="f.name"
                                    class="form-control"
                                    rows="2"
                                    style="overflow: hidden; max-height: 51px"
                                    :maxlength="100"
                                    v-on:keypress.enter="validarEnter"
                                ></textarea>
                            </div>
                            <div class="row" style="margin: 0px; margin-top: 10px">
                                <div class="form-group col-md-5" style="padding: 0px">
                                    <label>Date:</label>
                                    <datetime
                                        required
                                        type="datetime"
                                        v-model="f.fecha"
                                        :format="{
                                            year: 'numeric',
                                            month: 'short',
                                            day: 'numeric',
                                            hour: 'numeric',
                                            minute: '2-digit',
                                        }"
                                        use12-hour
                                        value-zone="America/New_York"
                                        input-class="form-control jobdate"
                                    ></datetime>
                                </div>
                                <div class="form-group col-md-7" style="padding: 0px">
                                    <label>Color:</label><br />
                                    <div style="margin-left: 50px; margin-top: -24px">
                                        <label
                                            class="c-input c-radio"
                                            v-for="(i, index) in color"
                                            :key="index"
                                        >
                                            <input
                                                type="radio"
                                                v-model="f.color_id"
                                                :value="i.id"
                                                :checked="f.color_id == i.id"
                                                style="margin-left: 8px; cursor: pointer"
                                            />
                                            <div
                                                class="jobcolor"
                                                v-bind:style="{ background: i.name }"
                                            ></div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <!--div class="form-group">
                                <label>Tags:</label>
                                <multiselect
                                    v-model="f.tags"
                                    tag-placeholder="Add this as new tag"
                                    placeholder="Search or add a tag"
                                    label="name"
                                    track-by="id"
                                    :options="tagsList"
                                    :multiple="true"
                                    :taggable="true"
                                    @tag="addTag"
                                    class="form-control"
                                ></multiselect>
                            </div-->
                        </div>
                        <div class="col-md-5">
                            <div class="form-group">
                                <label>Subcontractor: </label>
                                <model-select
                                    :options="sub"
                                    v-model="f.sub_id"
                                    placeholder="Choose a Subcontractor"
                                ></model-select>
                            </div>
                            <div class="form-group">
                                <label>Customer: </label>
                                <model-select
                                    :options="customer"
                                    v-model="f.customer_id"
                                    placeholder="Choose a Customer"
                                    @input="getinvoice"
                                ></model-select>
                            </div>
                            <div class="form-group">
                                <label>Invoice: </label>
                                <model-select
                                    v-if="f.customer_id > 0"
                                    :options="invoice"
                                    v-model="f.invoice_id"
                                    placeholder="Choose a Invoice"
                                ></model-select>
                                <select v-else class="form-control" disabled="true">
                                    <option>None</option>
                                </select>
                            </div>
                        </div>
                        <div
                            class="row"
                            style="
                                border-top: 1px solid #ddd;
                                width: 100%;
                                margin-top: 5px;
                                padding-top: 5px;
                            "
                        >
                            <div class="col-12 content-table">
                                <table class="table table-hover">
                                    <thead>
                                        <tr class="bg-secondary">
                                            <th
                                                style="vertical-align: middle"
                                            >
                                                <div class="row">
                                                    <div
                                                        class="col-lg-3"
                                                        style="
                                                            color: #fff;
                                                            font-size: 16px;
                                                            padding-top: 0px;
                                                            margin-bottom: 5px;
                                                        "
                                                    >
                                                        Things to do
                                                        <span
                                                            class="badge badge-pill badge-light"
                                                            v-if="f.taks.length > 0"
                                                            >{{ f.taks.length }}</span
                                                        >
                                                    </div>
                                                    <div
                                                        class="form-group col-lg-6"
                                                        style="margin: 0px"
                                                    >
                                                        <div class="input-group">
                                                            <model-select
                                                                :options="listTemplate"
                                                                v-model="templateValue"
                                                                placeholder="Choose a to do list"
                                                                class="form-control to_do_list"
                                                            ></model-select>
                                                            <span class="input-group-addon">
                                                                <button
                                                                    type="button"
                                                                    class="btn btn-default"
                                                                    @click="addToDoList"
                                                                    style="
                                                                        height: 30px;
                                                                        border-radius: 2px;
                                                                        padding-top: 5px;
                                                                    "
                                                                    :disabled="btnToDoList"
                                                                >
                                                                    <i class="fa fa-plus"></i>
                                                                </button>
                                                            </span>
                                                        </div>
                                                    </div>                                          
                                                </div>
                                            </th>

                                            <th
                                                class="text-center"
                                                style="
                                                    vertical-align: middle;
                                                    color: #fff;
                                                    font-size: 16px;
                                                    width: 90px;
                                                "
                                            >
                                                Status
                                            </th>
                                            <th
                                                class="text-center"
                                                style="
                                                    vertical-align: middle;
                                                    padding: 1px 4px;
                                                    color: #fff;
                                                    width: 43px;
                                                "
                                            >
                                                <i class="fa fa-times fa-lg"></i>
                                            </th>
                                        </tr>
                                    </thead>
                                    <draggable
                                        v-if="f.taks.length > 0"
                                        v-model="f.taks"
                                        tag="tbody"
                                        class="tbody"
                                        v-bind="dragOptions"
                                        @start="isDragging = true"
                                        @end="isDragging = false"
                                        handle=".handle"
                                    >
                                        <tr v-for="(i, index) in f.taks" :key="index">
                                            <td>
                                                <div class="handle">
                                                    <i class="fas fa-ellipsis-v"></i>
                                                </div>
                                                <textarea
                                                    class="form-control"
                                                    v-model="i.name"
                                                    placeholder="text box"
                                                    required
                                                    :ref="'taks' + index"
                                                    :id="'taks' + index"
                                                    style="height: 44px"
                                                >
                                                </textarea>
                                            </td>
                                            <td>
                                                <model-select
                                                    :options="i.statusList"
                                                    v-model="i.status"
                                                    placeholder="Status"
                                                    class="form-control status"
                                                ></model-select>
                                            </td>
                                            <td class="text-center">
                                                <button
                                                    type="button"
                                                    class="btn btn-danger"
                                                    :disabled="f.taks.length == 1"
                                                    @click="delTaks(i.id, index)"
                                                    style="
                                                        border-radius: 2px;
                                                        padding: 2px 4px;
                                                        font-size: 12px;
                                                    "
                                                >
                                                    <i class="fa fa-times"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </draggable>
                                    <tbody v-else>
                                        <tr>
                                            <td colspan="3" class="text-center">
                                                No record
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colspan="3" class="text-left">
                                                <button
                                                    type="button"
                                                    class="btn btn-info"
                                                    @click="addLine"
                                                >
                                                    <i class="fa fa-plus"></i> Add task
                                                </button>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                        <div class="col-12">
                            <hr style="margin: 5px 0px" />
                            <div class="form-group">
                                <label>Notes:</label>
                                <vue-editor v-model="f.notes" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="row">
                        <div class="col-4"></div>
                        <div class="col-8 text-right">
                            <button
                                type="submit"
                                class="btn btn-success"
                                :disabled="btnSave"
                            >
                                <i class="fas fa-save"></i> SAVE
                            </button>
                            <button
                                type="button"
                                @click="cancel"
                                class="btn btn-light"
                                style="margin-left: 10px"
                            >
                                <i class="fas fa-times"></i> Cancel
                            </button>
                            <button
                                @click="del(f.id)"
                                type="button"
                                class="btn btn-danger"
                                style="margin-left: 10px"
                                v-if="edit"
                            >
                                <i class="fas fa-trash"></i> DELETE
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <input type="hidden" v-model="f.user_id_assign" />
        </form>
    </div>
</template>
<script>
import { ModelSelect } from "vue-search-select";
import Loader from "@/core/components/shared/Loader.vue";
//import Multiselect from "vue-multiselect";
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
import { VueEditor } from "vue2-editor";
export default {
    name: "JobOrderForm",
    components: {
        Loader,
        //Multiselect,
        ModelSelect,
        draggable,
        VueEditor,
    },
    data() {
        return {
            titulo: "New ",
            edit: false,
            isLoading: false,
            search: "",
            user_id: window.window.master_client,
            org_id: window.localorgdata,
            sub: [],
            customer: [],
            invoice: [],
            color: [],
            f: {
                id: 0,
                name: "",
                sub_id: 0,
                customer_id: 0,
                invoice_id: 0,
                fecha:
                    (new Date().toISOString() + "").slice(0, 11) +
                    new Date().toTimeString().slice(0, 8),
                tags: [],
                color_id: 1,
                taks: [],
                user_id: window.master_client, // id de user master
                org_id: window.localorgdata,
                user_id_assign: 0,
                notes: "",
            },
            tagsList: [],
            listTemplate: [],
            templateValue: 0,
            statusList: [],
            btnToDoList: false,
            btnSave: false,
            showForm: false,
            billing: 0,
            dataBilling: [],
            privilegies_dash: 0,
            editorTag: false,
            editarTags: [],
            btnAddJobs: false,
            typez: window.user_type,
        };
    },
    created() {
        let urlApis = this.$store.getters['ModApps/jobs/urlApis'];
        this.apis = urlApis.viewDash;        
        console.log("form jobs");
        this.validAdminApp();
        this.validateBilling();
    },
    methods: {
        validAdminApp() {
            let admin = false;
            this.typez = 2;
            if (window.user_type == 0) {
                admin = true;
            } else {
                for (let i in this.appRolUser) {
                    if (this.appIDactual == this.appRolUser[i].app_id) {
                        if (this.appRolUser[i].rol == 1) {
                            admin = true;
                        }
                    }
                }
            }
            if (admin) {
                //permisos de admin
                this.typez = 0;
                this.privilegies_dash = 1;
                console.log("opciones admin");
            }
        },
        iniDataForm() {
            if (window.user_type == 0) {
                this.f.user_id_assign = 0;
            } else {
                // id de cuenta user invitado
                // this.f.user_id_assign=window.localAccountID;
                // let privilegios = this.$store.getters.privilegiesSesion;
                // let modulos = privilegios.modulos.jobs;
                // console.log('modulos a seleccionar =', modulos);
                /* for (let i in modulos) {
						// permiso para ver form
						if(modulos[i] == 15){  this.showForm= true; }
					}
					// si showForm es false salgo de form pues no tiene permiso para agregar
					if ( !this.showForm ) { this.cancel(); }
					*/
            }
            this.getDataBilling().then((response) => {
                this.getDataForm().then((response) => {
                    this.getJobsInfoByID();
                });
            });
        },
        getDataForm() {
            this.isLoading = true;
            let t = this;
            return new Promise((resolve, reject) => {
                let url = this.apis.getDataForm()
                window.master
                    .post(url, {
                        user_id: t.user_id,
                        org_id: t.org_id,
                        user_id_sesion: window.localAccountID,
                        app_id: t.appIDactual,
                    })
                    .then((response) => {
                        //color
                        t.color = response.data.color;
                        //status
                        let status = response.data.status;
                        t.statusList = [];
                        for (let i in status) {
                            if (status[i].id == 1 || status[i].id == 3) {
                                t.statusList.push({
                                    id: status[i].id,
                                    text: status[i].name,
                                });
                            }
                        }
                        // form new task
                        let link = this.$router.currentRoute.name;
                        // console.log(link);
                        if (
                            link == "JobOrderFormNew" ||
                            link == "JobOrderFormEdit" ||
                            link == "JobsRdash"
                        ) {
                            let statusList = [];
                            let list = this.statusList;
                            for (let i in list) {
                                statusList.push({
                                    value: list[i].id,
                                    text: list[i].text,
                                });
                            }
                            let lineNew = {
                                id: 0,
                                name: "",
                                status: 1,
                                statusList: statusList,
                            };
                            this.f.taks.push(lineNew);
                        }
                        //tags
                        let tags = response.data.tags;
                        t.tagsList = [];
                        for (let i in tags) {
                            t.tagsList.push({ id: tags[i].id, name: tags[i].name });
                        }
                        //to do list template
                        let template = response.data.template;
                        this.listTemplate = [];
                        for (let i in template) {
                            if (template[i].taks.length > 0) {
                                this.listTemplate.push({
                                    value: template[i].id,
                                    text: template[i].name,
                                });
                            }
                        }
                        resolve(true);
                    })
                    .catch((error) => {
                        console.log(error);
                        t.isLoading = false;
                        reject(false);
                    });
            });
        },
        validarEnter(event) {
            event.preventDefault();
            let tecla = document.all ? event.keyCode : event.which;
            console.log("anulando enter " + tecla);
        },
        validateBilling() {
            let b = this.$store.getters.applistorgs;
            for (const i in b) {
                if (b[i].name === "Billing" && b[i].privilegies == true) {
                    this.billing = 1;
                }
            }
            console.log("validando billing " + this.billing);
            this.iniDataForm();
        },
        getDataBilling() {
            this.isLoading = true;
            let t = this;
            if (this.privilegies_dash == 1) {
                this.billing = 1;
            }
            return new Promise((resolve, reject) => {
                if (t.billing == 0) {
                    t.sub.push({ value: 0, text: "None" });
                    t.customer.push({ value: 0, text: "None" });
                    t.invoice.push({ value: 0, text: "None" });
                    console.log("getDataBilling", false);
                    resolve(true);
                } else {
                    let url = this.apis.getDataBilling()
                    window.master
                        .post(url, {
                            user_id: t.user_id,
                            org_id: t.org_id,
                            billing: t.billing,
                        })
                        .then((response) => {
                            console.log("getDataBilling", response.data);
                            if (response.data.status) {
                                let result = response.data.result;
                                this.ordenate(response);
                                // this.dataBilling = result;
                                let none = { value: 0, text: "None" };
                                let r = [];
                                let f = [];
                                //subcontractor
                                if (result.sub.length > 0) {
                                    f = [none];
                                    r = result.sub;
                                    for (const i in r) {
                                        f.push({
                                            value: r[i].id,
                                            text: r[i].name,
                                        });
                                    }
                                    t.sub = f;
                                }
                                //customer
                                if (result.cus.length > 0) {
                                    f = [none];
                                    r = result.cus;
                                    for (const i in r) {
                                        f.push({ value: r[i].id, text: r[i].name });
                                    }
                                    t.customer = f;
                                }
                            }
                            resolve(true);
                        })
                        .catch((error) => {
                            console.log(error);
                            reject(false);
                        });
                }
            });
        },
        ordenate(response) {
            if (response.data.arxx.length > 0) {
                let cam = [];
                let r = response.data.result.cus;
                let ar = response.data.arxx;
                for (const i in ar) {
                    let yu = (r[i].invo = []);
                    let k = ar[i];
                    for (const y in k) {
                        yu.push({
                            created_at: k[y].created_at,
                            date: k[y].date,
                            fecdoc: k[y].fecdoc,
                            id: k[y].id,
                            numdoc: k[y].numdoc,
                            ordenDate: k[y].ordenDate,
                        });
                    }
                    cam.push({
                        id: r[i].id,
                        invo: yu,
                        name: r[i].name,
                    });
                }
                // console.log(cam);
                this.dataBilling = cam;
            }
        },
        getinvoice() {
            if (this.billing == 0) {
                this.invoice.push({ value: 0, text: "None" });
            } else if (this.dataBilling.length > 0) {
                let r = [];
                for (let i in this.dataBilling) {
                    if (this.dataBilling[i].id == this.f.customer_id) {
                        r = this.dataBilling[i].invo;
                    }
                }
                let f = [{ value: 0, text: "None" }];
                if (r.length > 0) {
                    for (const i in r) {
                        f.push({
                            value: r[i].id,
                            text: r[i].fecdoc + " - No: " + r[i].numdoc,
                        });
                    }
                }
                this.invoice = f;
            }
        },
        cancel() {
            if (this.$route.params.dash || this.$route.params.edit == "dash") {
                this.$router.push({ name: "Jobs" });
            } else if (this.$route.params.tag || this.$route.params.tag > 0) {
                this.$router.push({
                    name: "JobsTags",
                    params: {
                        id: this.$route.params.tag,
                        tags: "tags",
                    },
                });
            } else {
                this.$router.push({ name: "JobOrderList" });
            }
        },
        del(id) {
            this.$swal({
                title: "Delete Jobs Order?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                if (result.value) {
                    this.isLoading = true;
                    let url = this.apis.del()
                    window.master
                        .delete(
                            "/jobs/delJobs/" +
                                this.user_id +
                                "/" +
                                this.org_id +
                                "/" +
                                id,
                            {
                                user_id: this.user_id,
                                org_id: this.org_id,
                                id: id,
                            }
                        )
                        .then((response) => {
                            let r = response.data.status;
                            this.$swal.fire({
                                icon: "success",
                                title: "successfully removed!",
                                showConfirmButton: false,
                                timer: 4000,
                            });
                            this.isLoading = false;
                            this.cancel();
                        })
                        .catch((error) => {
                            this.isLoading = false;
                            console.log(error);
                            this.$notify({
                                group: "noti",
                                type: "error",
                                title: "Error",
                                text: "failed to delete!",
                            });
                        });
                }
            });
        },
        getJobsInfoByID() {
            if (
                this.$route.params.id &&
                (this.$route.params.edit == "edit" ||
                    this.$route.params.duplicate == "duplicate")
            ) {
                let id = this.$route.params.id;
                console.log("edit", id);
                this.titulo = "Edit #" + id;
                this.edit = true;
                if (this.$route.params.duplicate) {
                    this.titulo = "Duplicate #" + id;
                    this.edit = false;
                }
                let url = this.apis.getJobsInfoByID(id)
                window.master
                    .get(url)
                    .then((response) => {
                        let r = response.data.result;
                        this.f.id = r.id;
                        this.f.name = r.name;
                        this.f.fecha =
                            (new Date(r.date).toISOString() + "").slice(0, 11) +
                            new Date(r.date).toTimeString().slice(0, 8);
                        this.f.color_id = r.id_color;
                        if (this.$route.params.duplicate) {
                            this.f.edit = r.date;
                        }
                        this.f.status = r.status;
                        this.f.notes = r.notes;
                        this.f.sub_id = r.id_subcontractor;
                        this.f.customer_id = r.id_customer;
                        this.f.invoice_id = r.id_invoice;
                        this.f.tags = [];
                        for (let i in r.tags) {
                            for (let j in this.tagsList) {
                                if (this.tagsList[j].id == r.tags[i].id_tags) {
                                    //console.log('comparar ',this.tagsList[j].id+'=='+r.tags[i].id_tags)
                                    this.f.tags.push(this.tagsList[j]);
                                }
                            }
                        }
                        let list = this.statusList;
                        let statusList = [];
                        for (let i in list) {
                            statusList.push({ value: list[i].id, text: list[i].text });
                        }
                        this.f.taks = [];
                        let c = r.taks.length;
                        if (c > 0) {
                            for (let i in r.taks) {
                                let lineNew = {
                                    id: r.taks[i].id,
                                    name: r.taks[i].name,
                                    status: r.taks[i].id_status,
                                    statusList: statusList,
                                };
                                this.f.taks.push(lineNew);
                            }
                        } else {
                            let lineNew = {
                                id: 0,
                                name: "",
                                status: 1,
                                statusList: statusList,
                            };
                            this.f.taks.push(lineNew);
                        }
                        this.getinvoice();
                        //console.log('getJobsInfoByID',this.f);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
            this.isLoading = false;
        },
        form() {
            if (this.f.color_id > 0) {
                this.f.id = this.$route.params.id;
                this.btnSave = true;
                this.isLoading = true;
                console.log(this.f);
                let url = this.apis.addJobs()
                if (this.edit) {
                    url = this.apis.editJobs()
                }
                window.master
                    .post(url, this.f)
                    .then((response) => {
                        // console.log(response.data);
                        this.isLoading = false;
                        this.btnSave = false;
                        this.cancel();
                    })
                    .catch((error) => {
                        this.btnSave = false;
                        this.isLoading = false;
                        this.$notify({
                            group: "noti",
                            type: "error",
                            title: "Error",
                            text: "failed to save, try later!",
                        });
                        console.log(error);
                    });
            } else {
                this.$notify({
                    group: "noti",
                    type: "error",
                    title: "Error",
                    text: "Selected Color!",
                });
            }
        },
        addTag(newTag) {
            // console.log('addTag',newTag)
            let url = this.apis.addJobsTags()
            window.master
                .post(url, {
                    name: newTag,
                    id: this.$route.params.id,
                    user_id: window.window.master_client,
                    org_id: window.localorgdata,
                })
                .then((response) => {
                    // console.log(response.data);
                    let id = response.data.id;
                    this.$store.dispatch("jobs/update_menuTags");
                    this.tagsList.push({ id: id, name: newTag });
                    this.f.tags.push({ id: id, name: newTag });
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        addLine() {
            let list = this.statusList;
            let statusList = [];
            for (let i in list) {
                statusList.push({ value: list[i].id, text: list[i].text });
            }
            let lineNew = {
                id: 0,
                name: null,
                status: 1,
                statusList: statusList,
            };
            this.f.taks.push(lineNew);
            //console.log('taks', this.f.taks)
            let idx = this.f.taks.length - 1;
            setTimeout(function () {
                document.getElementById("taks" + idx).focus();
            }, 200);
        },
        delTaks(id, index) {
            this.f.taks.splice(index, 1);
        },
        addToDoList() {
            if (this.templateValue > 0) {
                this.btnToDoList = true;
                let url = this.apis.addToDoList(this.templateValue)
                window.master
                    .get(url)
                    .then((response) => {
                        let template = response.data.template;
                        let taks = response.data.taks;
                        let list = this.statusList;
                        let statusList = [];
                        for (let i in list) {
                            statusList.push({
                                value: list[i].id,
                                text: list[i].text,
                            });
                        }
                        for (let i in taks) {
                            let lineNew = {
                                id: taks[i].id,
                                name: taks[i].name,
                                status: 1,
                                statusList: statusList,
                            };
                            this.f.taks.push(lineNew);
                            this.templateValue = 0;
                        }
                        this.btnToDoList = false;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.btnToDoList = false;
                    });
            }
        },
    },
    computed: {
        ...mapGetters("core/apps", {
            appRolUser: "get_appRolUser",
            appIDactual: "getAppIDactual",
        }),
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
            };
        },
    },
};
</script>
<style lang="scss" scoped>
label {
    color: #555;
    font-size: 16px;
}
.search {
    border-color: #555;
}
.btn-light {
    background-color: #fff;
    border-color: #555;
}
.form-control {
    border-color: #555;
}
.ui.fluid.search.selection.dropdown {
    border-color: #555;
    border-radius: 2px;
}
.form-group {
    margin-bottom: 5px;
}
.card-header {
    padding: 5px 15px;
    background: #ddd;
    justify-content: center;
}
.card-header h3 {
    color: #555;
    font-weight: bold;
}
.card-body {
    padding-top: 5px;
}
.ui.fluid.search.selection.dropdown.form-control.to_do_list {
    width: 80%;
    height: 30px;
    padding-top: 6px;
}
.jobcolor {
    margin-right: 4px;
    width: 30px;
    height: 30px;
    border: 1px solid #ccc;
    cursor: pointer;
}
.handle {
    position: absolute;
    border: 0px solid #000;
    left: 5px;
    padding: 12px 2px 3px 4px;
    height: 83%;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: rgb(17 177 245 / 38%);
    cursor: move;
}
.tbody t {
    position: relative;
}
.tbody tr td {
    position: relative;
    padding-left: 16px;
}
.multiselect.form-control {
    width: 97% !important;
}
@media (max-width: 600px) {
    .tituloJobs {
        font-size: 16px;
    }
    .ui.fluid.search.selection.dropdown.form-control.status {
        padding: 5px;
        width: 100%;
    }
    .thead tr th,
    .tbody tr td {
        padding: 0.1rem;
    }
    .handle {
        left: -8px;
        height: 95%;
    }
    .ui.fluid.search.selection.dropdown.form-control.to_do_list {
        width: 77%;
        height: 30px;
        padding-top: 8px;
        padding-left: 5px;
        font-size: 11px;
    }
    .multiselect.form-control[data-v-07610164] {
        width: 93% !important;
    }
    .content-table {
        padding-right: 0px;
    }
}
</style>
