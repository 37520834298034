let updateManageTasks = function() {
    if (this.accitonPusher.status_jobs == 4) {
        //si es completed busco y lo quito de la lista de OPEN
        for (let k in this.listTaks) {
            if (this.listTaks.hasOwnProperty(k)) {
                if (this.listTaks[k].id_i == this.accitonPusher.id_jobs) {
                    this.listTaks.splice(k, 1);
                }
            }
        }
        //actualizo contador de pending
        let tag_id = this.id_tags.length > 0 ? this.id_tags[0] : 0;
        this.update_JOPending(tag_id);
    } else {
        for (let k in this.listTaks) {
            if (this.listTaks.hasOwnProperty(k)) {
                if (this.listTaks[k].id_i == this.accitonPusher.id_jobs) {
                    let newTasks = [];
                    for (let i in this.accitonPusher.tasks) {
                        if (this.accitonPusher.tasks.hasOwnProperty(i)) {
                            let fila = {
                                id_jobs: this.accitonPusher.tasks[i].id_jobs,
                                id: this.accitonPusher.tasks[i].id,
                                name: this.accitonPusher.tasks[i].name,
                                id_status: this.accitonPusher.tasks[i].id_status,
                                pusher: "1",
                            };
                            newTasks.push(fila);
                        }
                    }
                    this.listTaks[k].taks = newTasks;
                    if (
                        this.listTaks[k].id_status == 1 &&
                        this.accitonPusher.status_jobs == 2
                    ) {
                        // cambio a in progress
                        this.listTaks[
                            k
                        ].id_status = this.accitonPusher.status_jobs;
                        //actualizo contador de pending
                        let tag_id =
                            this.id_tags.length > 0 ? this.id_tags[0] : 0;
                        this.update_JOPending(tag_id);
                    }
                }
            }
        }
    }
    this.reEscribirLista();
    this.starGrid();
};
export default updateManageTasks;
