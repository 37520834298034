<template>
    <div class="row card-body" style="padding-top: 0px;padding-bottom: 0px;">
        <div class="card-header window-header" style="margin: 0px -10px 0px -10px; width: 105%;">
            <h4 style="margin-bottom: 0px;">
                <h4>Find me at:</h4>
            </h4>
		</div>
        <div class="row window-body">
            <div class="col-12 marginTop">
                <h4>Contact Me:</h4>
                <draggable
                    v-model="find_me_at"
                    tag="div"
                    class="contacts"
                    v-bind="dragOptions"
                    @start="isDragging(true)"
                    @end="isDragging(false)"
                    @change="checkMoveFindMeAt(find_me_at)"
                    handle=".handle"
                >
                    <div class="row" v-for="(i, index) in find_me_at" :key="'contact'+index">
                        <div class="col-1">
                            <div class="btnmove handle" style="line-height: 50px;">
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 512 512" xml:space="preserve" class="icon-drag">
                                    <circle cx="153.6" cy="-105" r="61"></circle>
                                    <circle cx="153.6" cy="61" r="61"></circle>
                                    <circle cx="153.6" cy="256" r="61"></circle>
                                    <circle cx="153.6" cy="451" r="61"></circle>
                                    <circle cx="153.6" cy="646" r="61"></circle>
                                    <circle cx="153.6" cy="851" r="61"></circle>
                                    <circle cx="358.4" cy="-105" r="61"></circle>
                                    <circle cx="358.4" cy="61" r="61"></circle>
                                    <circle cx="358.4" cy="256" r="61"></circle>
                                    <circle cx="358.4" cy="451" r="61"></circle>
                                    <circle cx="358.4" cy="646" r="61"></circle>
                                    <circle cx="358.4" cy="851" r="61"></circle>
                                </svg>
                            </div>
                        </div>
                        <div class="col-8 ">
                            <label class="col-form-label">{{ i.label }}:</label>
                            <div class="input-group">
                                <span class="input-group-text"><i :class="i.icon"></i></span>
                                <input type="text" class="form-control"
                                    v-model="i.val"
                                    :maxlength="(i.slug=='form-pop-up') ? '30' : '100'"
                                    :placeholder="(i.slug=='form-pop-up') ? 'Title form' : ''"
                                    @change="setContact()"
                                    :disabled="i.label=='Contact'"
                                />
                            </div>
                        </div>
                        <div class="col-2 text-center">
                            <label class="col-form-label">Show: </label>
                            <div class="onoffswitch" v-bind:class="(listPremium.includes(i.label) && !premiumProfile) ? 'disabled' : ''">
                                <div v-if="(index==0)"
                                class="switchDisabled"></div>
                                <input
                                    type="checkbox"
                                    class="onoffswitch-checkbox"
                                    :id="'status-'+i.tipo+'-'+index"
                                    v-model="i.status"
                                    :value="1"
                                    @change="setContact()"
                                    :disabled="((listPremium.includes(i.label) && !premiumProfile) || (index==0))"
                                />
                                <label class="onoffswitch-label" :for="'status-'+i.tipo+'-'+index" >
                                    <span class="onoffswitch-inner"></span>
                                    <span class="onoffswitch-switch"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </draggable>
            </div>
            <!-- <div class="col-12 marginTop">
                <h4>Direct Contact:</h4>
                <draggable
                    v-model="contact"
                    tag="div"
                    class="contacts"
                    v-bind="dragOptions"
                    @start="isDragging(true)"
                    @end="isDragging(false)"
                    @change="checkMoveContact(contact)"
                    handle=".handle"
                >
                    <div class="row" v-for="(i, index) in contact" :key="'contact'+index">
                        <div class="col-1">
                            <div class="btnmove handle" style="line-height: 50px;">
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 512 512" xml:space="preserve" class="icon-drag">
                                    <circle cx="153.6" cy="-105" r="61"></circle>
                                    <circle cx="153.6" cy="61" r="61"></circle>
                                    <circle cx="153.6" cy="256" r="61"></circle>
                                    <circle cx="153.6" cy="451" r="61"></circle>
                                    <circle cx="153.6" cy="646" r="61"></circle>
                                    <circle cx="153.6" cy="851" r="61"></circle>
                                    <circle cx="358.4" cy="-105" r="61"></circle>
                                    <circle cx="358.4" cy="61" r="61"></circle>
                                    <circle cx="358.4" cy="256" r="61"></circle>
                                    <circle cx="358.4" cy="451" r="61"></circle>
                                    <circle cx="358.4" cy="646" r="61"></circle>
                                    <circle cx="358.4" cy="851" r="61"></circle>
                                </svg>
                            </div>
                        </div>
                        <div class="col-8 ">
                            <label class="col-form-label">{{ i.label }}:</label>
                            <div class="input-group">
                                <span class="input-group-text"><i :class="i.icon"></i></span>
                                <input type="text" class="form-control"
                                    v-model="i.val"
                                    maxlength="100"
                                    @change="setContact('c')"
                                />
                            </div>
                        </div>
                        <div class="col-2 text-center">
                            <label class="col-form-label">Show:</label>
                            <div class="onoffswitch" v-bind:class="(listPremium.includes(i.label) && !premiumProfile) ? 'disabled' : ''">
                                <div v-if="(listPremium.includes(i.label) && !premiumProfile)"
                                class="switchDisabled" @click="showPremium()"></div>
                                <input
                                    type="checkbox"
                                    class="onoffswitch-checkbox"
                                    :id="'status-'+i.tipo+'-'+index"
                                    v-model="i.status"
                                    :value="1"
                                    @change="setContact('c')"
                                    :disabled="(listPremium.includes(i.label) && !premiumProfile)"
                                />
                                <label class="onoffswitch-label" :for="'status-'+i.tipo+'-'+index" >
                                    <span class="onoffswitch-inner"></span>
                                    <span class="onoffswitch-switch"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </draggable>
            </div>
            <div class="col-12 marginTop">
                <h4>My Networks:</h4>
                <draggable
                    v-model="social"
                    tag="div"
                    class="contacts"
                    v-bind="dragOptions"
                    @start="isDragging(true)"
                    @end="isDragging(false)"
                    @change="checkMoveSocial(social)"
                    handle=".handle"
                >
                    <div class="row" v-for="(i, index) in social" :key="'social'+index">
                        <div class="col-1">
                            <div class="btnmove handle" style="line-height: 50px;">
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 512 512" xml:space="preserve" class="icon-drag"><circle cx="153.6" cy="451" r="61"></circle><circle cx="153.6" cy="256" r="61"></circle><circle cx="153.6" cy="61" r="61"></circle><circle cx="358.4" cy="256" r="61"></circle><circle cx="358.4" cy="61" r="61"></circle><circle cx="358.4" cy="451" r="61"></circle></svg>
                            </div>
                        </div>
                        <div class="col-8 ">
                            <label class="col-form-label">{{ i.label }}:</label>
                            <div class="input-group">
                                <span class="input-group-text"><i :class="i.icon"></i></span>
                                <input type="text" class="form-control"
                                    v-model="i.val"
                                    maxlength="200"
                                    @change="setContact('s')"
                                />
                            </div>
                        </div>
                        <div class="col-2 text-center">
                            <label class="col-form-label">Show:</label>
                            <div class="onoffswitch" v-bind:class="(listPremium.includes(i.label) && !premiumProfile) ? 'disabled' : ''">
                                <div v-if="(listPremium.includes(i.label) && !premiumProfile)"
                                class="switchDisabled" @click="showPremium()"></div>
                                <input
                                    type="checkbox"
                                    class="onoffswitch-checkbox"
                                    :id="'status-'+i.tipo+'-'+index"
                                    v-model="i.status"
                                    :value="1"
                                    @change="setContact('s')"
                                    :disabled="(listPremium.includes(i.label) && !premiumProfile)"
                                />
                                <label class="onoffswitch-label" :for="'status-'+i.tipo+'-'+index" >
                                    <span class="onoffswitch-inner"></span>
                                    <span class="onoffswitch-switch"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </draggable>
            </div> -->
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import draggable from 'vuedraggable';
export default {
    name:"editContact",
    components:{
        draggable
    },
    data(){
        return {
            contact: [{
                user_id : window.master_client,
                org_id : window.localorgdata,
                tipo: 1,
                icon: 'fas fa-envelope',
                label: 'Email',
                slug: 'email',
                val: '',
                status: 1,
                orden: 0,
                }, {
                user_id : window.master_client,
                org_id : window.localorgdata,
                tipo: 1,
                icon: 'fas fa-phone-alt',
                label: 'Call',
                slug: 'call',
                val: '',
                status: 1,
                orden: 0,
                }, {
                user_id : window.master_client,
                org_id : window.localorgdata,
                tipo: 1,
                icon: 'fas fa-sms',
                label: 'SMS',
                slug: 'sms',
                val: '',
                status: 1,
                orden: 0,
                }, {
                user_id : window.master_client,
                org_id : window.localorgdata,
                tipo: 1,
                icon: 'fab fa-whatsapp',
                label: 'WhatsApp',
                slug: 'whatsapp',
                val: '+1',
                status: 0,
                orden: 0,
                }, {
                user_id : window.master_client,
                org_id : window.localorgdata,
                tipo: 1,
                icon: 'fas fa-globe',
                label: 'Website',
                slug: 'website',
                val: '',
                status: 0,
                orden: 0,
                }, {
                user_id : window.master_client,
                org_id : window.localorgdata,
                tipo: 1,
                icon: 'fas fa-store',
                label: 'Shop',
                slug: 'shop',
                val: '',
                status: 0,
                orden: 0,
                }, {
                user_id : window.master_client,
                org_id : window.localorgdata,
                tipo: 1,
                icon: 'fas fa-paper-plane',
                label: 'Form Pop-up',
                slug: 'form-pop-up',
                val: 'Quote',
                status: 0,
                orden: 0,
            }],
            social: [{
                user_id : window.master_client,
                org_id : window.localorgdata,
                tipo: 2,
                icon: 'fab fa-instagram',
                label: 'Instagram',
                slug: 'instagram',
                val: 'https://www.instagram.com/',
                status: 0,
                orden: 0,
                }, {
                user_id : window.master_client,
                org_id : window.localorgdata,
                tipo: 2,
                icon: 'fab fa-tiktok',
                label: 'TikTok',
                slug: 'tiktok',
                val: 'https://www.tiktok.com/',
                status: 0,
                orden: 0,
                }, {
                user_id : window.master_client,
                org_id : window.localorgdata,
                tipo: 2,
                icon: 'fab fa-facebook',
                label: 'Facebook',
                slug: 'facebook',
                val: 'https://www.facebook.com/',
                status: 0,
                orden: 0,
            }, {
                user_id : window.master_client,
                org_id : window.localorgdata,
                tipo: 2,
                icon: 'fab fa-youtube',
                label: 'Youtube',
                slug: 'youtube',
                val: 'https://www.youtube.com/',
                status: 0,
                orden: 0,
            }, {
                user_id : window.master_client,
                org_id : window.localorgdata,
                tipo: 2,
                icon: 'fas fa-map-marked-alt',
                label: 'Map',
                slug: 'map',
                val: '',
                status: 0,
                orden: 0,
            }, {
                user_id : window.master_client,
                org_id : window.localorgdata,
                tipo: 2,
                icon: 'fas fa-user-plus',
                label: 'Contact',
                slug: 'contact',
                val: '',
                status: 0,
                orden: 0,
            }, {
                user_id : window.master_client,
                org_id : window.localorgdata,
                tipo: 2,
                icon: 'fab fa-waze',
                label: 'Wase',
                slug: 'waze',
                val: 'https://waze.com/',
                status: 0,
                orden: 0,
            }, {
                user_id : window.master_client,
                org_id : window.localorgdata,
                tipo: 2,
                icon: 'fab fa-google',
                label: 'Reviews',
                slug: 'reviews',
                val: '#',
                status: 0,
                orden: 0,
            }],
            find_me_at: [],
            premiumProfile: false,
            planActual: 1,
            listPremium: ['Shop', 'Map', 'Form Pop-up'],
        };
    },
    created(){
        this.item = this.$store.getters['ModApps/Marketea/get_bsite_cardItem'];
        // this.readConctact(this.item);
        // this.readSocial(this.item);
        this.read_find_me_at(this.item);
        // valid premium planID
        this.premiumProfile = (this.$store.getters['core/apps/getPlanIdApp']!=8);
        console.log('premiumProfile', this.premiumProfile);
        this.planActual = this.$store.getters['core/apps/getPlanIdApp'];
        if(this.planActual==7){
            this.premiumProfile = true;
            // console.log('Plan sponsored, dando permiso de premium', this.premiumProfile);
        }
    },
    methods:{
        showPremium(){let t = this; t.$store.commit('core/session/setShowPopPremium', {show: true, data: []});},
        isDragging(t){
            console.log('move')
            return t;
        },
        checkMoveContact: function(list){
            list = list.filter((r, idx) => {
                r.orden = idx;
                return r;
            });
            console.log('checkMove', list);
            this.setContact('c');
        },
        checkMoveSocial: function(list){
            list = list.filter((r, idx) => {
                r.orden = idx;
                return r;
            });
            console.log('checkMove', list);
            this.setContact('s');
        },
        checkMoveFindMeAt: function(list){
            list = list.filter((r, idx) => {
                r.orden = idx;
                return r;
            });
            console.log('checkMove', list);
            this.setContact('s');
        },
        readConctact(r){
            let t = this;
            console.log('contact', r.contact);
            if(r.contact!=null && r.contact.length>0){
                let contact = t.contact.filter(item => {
                    r.contact.filter(i => {
                    if(i.icon == item.icon){
                        if(i.label.toLowerCase()=='whatsapp'){
                            // console.log('label', i.label.toLowerCase())
                            item.val = (i.val==null || i.val=='null' || i.val=='') ? '+1' : i.val;
                        }else{
                            item.val = (i.val==null || i.val=='null') ? '' : i.val;
                        }
                        item.status = i.status;
                    }
                    });
                    return item;
                });
            }
        },
        readSocial(r){
            let t = this;
            console.log('social', r.social);
            if(r.social!=null && r.social.length>0){
                let social = t.social.filter(item => {
                    r.social.filter(i => {
                    if(i.icon == item.icon){
                        item.val = (i.val==null || i.val=='null') ? '' : i.val;
                        item.status = i.status;
                    }
                    });
                    return item;
                });
            }
        },
        read_find_me_at(r){
            let t = this;
            if(r.find_me_at!=null && r.find_me_at.length>0){
                localStorage.setItem('find_me_at', JSON.stringify(r.find_me_at));
                t.find_me_at = JSON.parse(localStorage.getItem('find_me_at'));
                console.log('read_find_me_at', t.find_me_at);
                t.find_me_at.filter(i => {
                    t.social.filter(item => {
                    if(i.icon == item.icon){
                        i.val = (i.val==null || i.val=='null') ? '' : i.val;
                    }
                        return i;
                    });
                });
                t.find_me_at.filter(i => {
                        t.contact.filter(item => {
                    if(i.icon == item.icon){
                        if(i.label.toLowerCase()=='whatsapp'){
                            // console.log('label', i.label.toLowerCase())
                            i.val = (i.val==null || i.val=='null' || i.val=='') ? '+1' : i.val;
                        }else{
                            i.val = (i.val==null || i.val=='null') ? '' : i.val;
                        }
                    }
                        return i;
                    });
                });
                // validar campos, sino existen mostrar como inactivos
                t.contact.filter(item => {
                    let valid=true;
                    t.find_me_at.filter(i => {
                        if(i.icon == item.icon){
                            valid=false;
                        }
                    });
                    if(valid){
                        t.find_me_at.push(item);
                    }
                });
                t.social.filter(item => {
                    let valid=true;
                    t.find_me_at.filter(i => {
                        if(i.icon == item.icon){
                            valid=false;
                        }
                    });
                    if(valid){
                        t.find_me_at.push(item);
                    }
                });
            }
        },
        setContact(type_list){

            let t = this;
            if(type_list=='c'){this.item.contact=t.contact;}
            else{this.item.social=t.social;}
            if(t.find_me_at[0]['status']==0){
                t.find_me_at[0]['status']=1;
            }
            this.item.find_me_at=t.find_me_at;
            let payload = {
                key: 'bsite_cardItem',
                val: t.item,
            };
            console.log('change contact', t.item);
            t.$store.commit('ModApps/Marketea/set_bSiteValStorage', payload);
            t.$store.dispatch('ModApps/Marketea/setContact')
            .then(r => {
                console.log('setContact', r);
                t.$notify({
                    group: "noti",
                    type: 'success',
                    title: "Success",
                    text: "Saved!",
                    duration: 2000
                });
            })
            .catch(error => {
                console.log(error);
                t.$notify({
                    group: "noti",
                    type: 'Error',
                    title: "Fail",
                    text: "Failed to save",
                    duration: 2000
                });
            });
        }
    },
    watch: {
        getCardItem: function(){
            if(this.getCardItem!=null){
                this.item=this.getCardItem;
            }
        },
        item: function(){
            if(this.item!=null){
                let t = this;
                // t.readConctact(t.item);
                // t.readSocial(t.item);
                t.read_find_me_at(t.item);
            }
        },
    },
    computed: {
        ...mapGetters("ModApps/Marketea", {
            getCardItem: "get_bsite_cardItem",
        }),
        item: {
            get: function () {
                let t = this;
                return t.getCardItem;
            },
            set: function (v) {
                return v;
            }
        },
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
            };
        },
    }
}
</script>
<style lang="scss" scoped>
.window-header{cursor: move;}
.marginTop{margin-top: 20px;}
.card-header h4{font-size:24px;margin-bottom: 0px;}
.onoffswitch{
    &.disabled{
        .onoffswitch-label{
            .onoffswitch-inner{
                &:before, &:after {
                    background-color: #c3c3c4 !important;
                    color: #555;
                }
            }
        }
    }
    .onoffswitch-label{
        .onoffswitch-inner{
            &:before{
                padding-left: 10px;
            }
        }
    }
}
.btnmove {
    position: relative;
    left: 0px;
    top: 0px;
    width: 18px;
    height: 100%;
    background-color: #fff;
    z-index: 10;
    font-size: 12px;
    line-height: 75px;
    text-align: center;
    vertical-align: middle;
    font-weight: normal;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    display: flex;
    .icon-drag{
        fill: #ddd;
    }
    &:hover {
        /*background-color: #e0e0e0;*/
        background-color: #e1f2fb;
        cursor: move;
        .icon-drag{
            fill: #000;
        }
    }
}
.contacts{
    position: relative;
    width: 100%;
}
.switchDisabled {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(221 221 221 / 50%);
    z-index: 2;
    border-radius: 20px;
}
.window-body{
    position: relative;
    width: 100%;
    margin: auto;
    height: 500px;
    border: 0px solid #000;
    margin-bottom: 2em;
    overflow: hidden;
    overflow-y: auto;
}
@media (max-width:767px){
    .window-body{
        height: 100%;
        max-height: 800px;
        padding-bottom: 20em;
    }
}
</style>
