<template>
    <div class="container organizaciones">
        <div class="row">
            <div class="col-8 offset-md-2">
                <div class="prof_title">
                    <h4>Setting Organizations</h4>
                    <p></p>
                </div>
                <div class="card" style="border-top: 4px solid #a1e0ae">
                    <div class="icontri"></div>
                    <div class="card-body">
                        <div
                            style="
                                display: flex;
                                justify-content: flex-end;
                                margin-bottom: 10px;
                            "
                        >
                            <button
                                type="button"
                                class="btn btn-success btn-sm"
                                @click="showmodal"
                            >
                                + Add Organization
                            </button>
                        </div>
                        <ul class="list-group">
                            <li
                                class="list-group-item"
                                style="display: flex; justify-content: space-between"
                                v-for="(i, index) in organizationslist"
                                :key="i.id"
                            >
                                <span>
                                    #{{i.id}} {{ i.bill_company_name }}
                                    <button
                                        class="btn badge badge-success"
                                        @click="showChangeModal(i.id)"
                                        v-if="i.principal == 1"
                                        title="Change"
                                        style="margin: 0px 5px 5px 10px; font-size: 11px; float: right; position: relative;cursor: pointer;"
                                        >Primary
                                        <span
                                            class="change-org"
                                            v-if="i.principal == 1"
                                        >
                                            <i class="fa fa-reply"></i>
                                        </span>
                                    </button>
                                </span>
                                <button
                                    type="button"
                                    class="btn btn-secondary btn-sm"
                                    @click="Edit(i, index)"
                                >
                                    Edit
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <modalLateral v-show="m.component=='changeOrg' || m.component=='addEditOrg'">
            <component v-bind:is="m.component"></component>
        </modalLateral>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import addEditOrg from "@/core/components/Account/Org/addEditOrg";
import changeOrg from "@/core/components/Account/Org/changeOrg";
import modalLateral from "@/core/plugins/themeModalLateral/modalLateral";
export default {
    name: "Organizations",
    components: {
        addEditOrg,
        changeOrg,
        modalLateral,
    },
    data() {
        return {
            isloaing: false,
            organizationslist: [],
            FormInfo: {
                user_id: window.master_client,
                id: null,
                title: "NEW",
                action: null,
                companyname: null,
                firstname: null,
                lastname: null,
                address: null,
                city: null,
                state: null,
                zipcode: null,
                telephone: null,
                email: null,
                website: null,
                timezone_id: 1,
            },
            org_id: 0,
            org_id_old: 0,
            editZone: false,
            m: {
                show: false,
                component: "",
                data: [],
            },
            OrgPlan : (window.OrgPlan > 1),
        };
    },
    created() {
        this.$store.dispatch("core/session/validTypeUser").then((r) => {
            if (r) {
                this.getOrganizations();
            } else {
                this.$router.push({ name: "Home" });
            }
        });
    },
    methods: {
        showChangeModal(id) {
            let valOrg = {
                org_id: id,
                org_id_old: id,
            };
            this.m = {
                show: true,
                component: "changeOrg",
                data: {
                    title: "Change Org",
                },
            };
            this.$store.commit("core/setOpenModal", this.m);
            this.$store.commit("core/Org/set_varChangeOrg", valOrg);
        },
        getOrganizations() {
            this.isloaing = true;
            this.$store
                .dispatch("core/Org/OrgAccountAll")
                .then((response) => {
                    this.organizationslist = response.data.result;
                    this.isloaing = false;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        showmodal() {
            console.log('validacion de plan', this.OrgPlan)
            if(this.OrgPlan){
                this.m = {
                    show: true,
                    component: "addEditOrg",
                    data: {
                        title: this.FormInfo.title,
                    },
                };
                this.$store.commit("core/setOpenModal", this.m);
                this.$store.commit("core/Org/reset_formInfo");
            }else{
                this.$notify({group: "notiPushPremium", duration: 100000});
            }
        },
        Edit(i, index) {
            this.FormInfo.title = "UPDATE";
            this.FormInfo.action = "update";
            this.FormInfo.id = i.id;
            this.FormInfo.companyname = i.bill_company_name;
            this.FormInfo.firstname = i.com_contact_name;
            this.FormInfo.lastname = i.com_lastname;
            this.FormInfo.address = i.com_address;
            this.FormInfo.city = i.com_city;
            this.FormInfo.state = i.com_state;
            this.FormInfo.zipcode = i.com_zip_code;
            this.FormInfo.telephone = i.com_telephone;
            this.FormInfo.email = i.com_email;
            this.FormInfo.website = i.com_website;
            this.FormInfo.timezone_id = i.time_zone_id;
            this.m = {
                show: true,
                component: "addEditOrg",
                data: this.FormInfo,
            };
            this.$store.commit("core/setOpenModal", this.m);
            this.$store.commit("core/Org/set_formInfo", this.FormInfo);
        },
    },
    watch: {
        orgAll: function () {
            this.organizationslist = this.orgAll;
        },
    },
    computed: {
        ...mapGetters("core/Org", { orgAll: "orgAll" }),
    },
};
</script>
<style lang="scss" scoped>
    @import '../../assets/css/orgStyles.scss';
    span.premium.min {
        display: inline-block;
        width: 20px;
    }
    span.premium img {
        width: 100%;
    }
</style>
