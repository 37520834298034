let calcAutoHeigthTask = function(){
    let t = this;
    let taskName = document.querySelectorAll('.autoHeigth');
    if(taskName!= null && taskName.length>0){
        taskName.forEach(e => {
            //console.log('taskName', e.value)
            t.autoHeigth(e);
        })
    }
    t.grid_taks_ini(); // formato grid
};
export default calcAutoHeigthTask;
