<template>
    <div class="col-12">
        <div v-if="isLoading == false">
            <div v-if="customer == false">
                <div class="row" style="margin-bottom: 0px">
                    <div class="col-md-12">
                    <table>
                        <tr>
                            <td>
                                <table>
                                    <tr>
                                        <td><b style="color: #9e9e9e;">Name: </b></td>
                                    </tr>
                                    <tr>
                                        <td><b style="color: #9e9e9e;">Business: </b></td>
                                    </tr>
                                    <tr>
                                        <td><b style="color: #9e9e9e;">Office Phone: </b></td>
                                    </tr>
                                    <tr>
                                        <td><b style="color: #9e9e9e;">Email: </b></td>
                                    </tr>
                                    <tr>
                                        <td><b style="color: #9e9e9e;">Address: </b></td>
                                    </tr>
                                    <tr>
                                        <td v-if="show_customer_notes == 1"><b style="color: #9e9e9e;">Notes: </b></td>
                                    </tr>
                                </table>
                            </td>
                            <td>
                                <table>
                                    <tr>
                                        <td>{{ f.fname }} {{ f.lname }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ f.bname }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ f.ophone }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ f.email }}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ f.baddress}}
                                            <span v-if="f.city != ''">, {{ f.city }}</span>
                                            <span v-if="f.state != ''"> {{ f.state }}</span>
                                            <span v-if="f.zcode != ''">, {{ f.zcode }}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td v-if="show_customer_notes == 1">{{f.notes}}</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                    </div>
                </div>
            </div>
            <div v-else>Assign a Customer</div>
        </div>
        <div v-if="isLoading">
            <Loader></Loader>
        </div>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader.vue";
export default {
    name: "nameCustomer",
    components: {
        Loader,
    },
    props: {
        itemNameCustomers: { type: Object, required: true },
    },
    data() {
        return {
            isLoading: false,
            user_id: window.localAccountID,
            org_id: window.localorgdata,
            nameCustomer: [],
            f: {
                fname: "",
                lname: "",
                email: "",
                bname: "",
                ophone: "",
                mphone: "",
                active: "",
                referal: "",
                website: "",
                baddress: "",
                city: "",
                state: "",
                zcode: "",
                notes: "",
                user_id: window.localAccountID,
                org_id: window.localorgdata,
                meta_key: "show_customer_notes",
                meta_value: 0,
            },
            customer: false,
            show_customer_notes: 0,
        };
    },
    created() {
        let urlApis = this.$store.getters['ModApps/jobs/urlApis'];
        this.apis = urlApis.jobList;         
        this.nameCustomer = this.itemNameCustomers.cus;
        this.iniCustomer();
        //this.getJobsSetting();
    },
    methods: {
        iniCustomer() {
            let t = this.nameCustomer;
            if (Object.keys(t).length > 0) {
                if (t.firstname != null) {
                    this.f.fname = t.firstname;
                } else {
                    this.f.fname = "None";
                }
                if (t.lastname != null) {
                    this.f.lname = t.lastname;
                } else {
                    this.f.lname = "None";
                }
                if (t.email != null) {
                    this.f.email = t.email;
                } else {
                    this.f.email = "None";
                }
                if (t.bussinesname != null) {
                    this.f.bname = t.bussinesname;
                } else {
                    this.f.bname = "None";
                }
                if (t.officephone != null) {
                    this.f.ophone = t.officephone;
                } else {
                    this.f.ophone = "None";
                }
                if (t.mobilephone != null) {
                    this.f.mphone = t.mobilephone;
                } else {
                    this.f.mphone = "None";
                }
                if (t.active == 1) {
                    this.f.active = "Active";
                } else {
                    this.f.active = "Inactive";
                }
                if (t.referal != null) {
                    switch (t.referal) {
                        case 1:
                            this.f.referal = "Google Organic";
                            break;
                        case 2:
                            this.f.referal = "Referal from existing client";
                            break;
                        case 3:
                            this.f.referal = "Ads";
                            break;
                        case 4:
                            this.f.referal = "Signs";
                            break;
                        case 5:
                            this.f.referal = "Direct mail";
                            break;
                        case 6:
                            this.f.referal = "Other";
                            break;
                    }
                } else {
                    this.f.referal = "None";
                }
                if (t.website != null) {
                    this.f.website = t.website;
                } else {
                    this.f.website = "None";
                }

                if (t.billingaddress != null) {
                    this.f.baddress = t.billingaddress;
                } else {
                    this.f.baddress = "None";
                }
                if (t.bcity != null) {
                    this.f.city = t.bcity;
                } else {
                    this.f.city = "";
                }
                if (t.bstate != null) {
                    this.f.state = t.bstate;
                } else {
                    this.f.state = "";
                }
                if (t.bpostcode != null) {
                    this.f.zcode = t.bpostcode;
                } else {
                    this.f.zcode = "";
                }
                if (t.notes != null) {
                    this.f.notes = t.notes;
                } else {
                    this.f.notes = "None";
                }
                this.show_customer_notes = this.$parent.$parent.$parent.$parent.show_customer_notes;
            }
        },
        getJobsSetting() {
            let url = this.apis.getSetting()
            window.master
                .post(url, this.f)
                .then((response) => {
                    let r = response.data.result;
                    this.f.meta_value = 0;
                    if (r.meta_value == 1) {
                        this.f.meta_value = 1;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
</script>
<style lang="scss" scoped>
.form-control {
    border: none !important;
}
</style>
