<template>
    <div class="card-body" v-if="IsLoder"><Loader></Loader></div>
    <div class="card-body" v-else>
        <div class="card-body contenedor-total d-flex0">
            <div
                role="alert"
                class="c-ol alert alert-success alert_totales_customer"
            >
                Active Customers:
                <h1>
                    <span class="badge badge-success">{{activos}}</span>
                </h1>
            </div>
            <div
                role="alert"
                class="c-ol alert alert-danger alert_totales_customer"
            >
                Inactive Customers:
                <h1>
                    <span class="badge badge-danger">{{inactivos}}</span>
                </h1>
            </div>
        </div>
        <div class="card-body contenedor-total d-flex0" v-if="(TotalEspacio-TotalRecord) <=10">
            <div class="alert alert-info" role="alert">
              <h4 class="alert-heading">Information!</h4>
              <p>With your current plan you are only allowed a maximum of <strong>{{TotalEspacio}} records</strong>, for more space please contact your system administrator.</p>
              <hr>
              <p class="mb-0">Current space available <strong>{{TotalRecord | formatMileage}} / {{TotalEspacio | formatMileage}}</strong></p>
            </div>
        </div>
    </div>
</template>
<script>
import Loader from '@/core/components/shared/Loader.vue'
export default {
    name: "counterCustomer",
    components: { 
        Loader
    },    
    data () {
        return { 
            IsLoder:false,
            customers: [], 
            activos: 0,
            inactivos: 0,
            TotalRecord: 0,
            TotalStandard: 5000,
            TotalPremium: 10000,
            TotalEspacio: 0,
            premium : (window.OrgPlan > 1),
        }
    },
    created () {
        this.TotalEspacio = (this.premium) ? this.TotalPremium : this.TotalStandard;
        this.customcounts()
    },
    methods: {
        customcounts() {
            this.IsLoder = true
            window.billing
                .get("report/customcounts/" + window.localuserdata)
                .then((response) => {
                    console.log(response.data);
                    let total = 0;
                    if (response.data.status) {
                        this.activos = response.data.actives;
                        this.inactivos = response.data.inactives;
                        total = this.activos + this.inactivos;
                    }
                    this.$store.commit('ModApps/billing/setCustomerCountTotal', total);
                    this.IsLoder = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.IsLoder = false
                    this.$store.commit('ModApps/billing/setCustomerCountTotal', 0);
                });
        },                  
    },
    watch: {
        activos: function(){
            this.TotalRecord = this.activos + this.inactivos;
        },    
        inactivos: function(){
            this.TotalRecord = this.activos + this.inactivos;
        }, 
    },
    computed: {   
    },
};
</script>
<style lang="scss" scoped>

.contenedor-total {
    padding: 0rem;
}
.d-flex0 {
    flex-wrap: wrap !important;
    justify-content: space-between !important;
}
.alert_totales_customer {
    display: inline-block;
    padding: 0.2rem 0.5rem;
    width: 50%;
    text-align: center;
}
.alert_totales_customer h1 span {
    font-size: 20px;
}
@media (min-width: 767px) {
    .d-flex0 .c-ol {
        width: 48%;
    }
}
@media (max-width: 767px) {
    .d-flex0 .c-ol {
        width: 100%;
    }
}
@media (max-width: 1190px) {
    .alert_totales_customer {
        display: inline-block;
        width: 50%;
        text-align: right;
    }
    .alert_totales_customer h1 span {
        font-size: 14px;
    }
}
@media (max-width: 991px) {
    .alert_totales_customer {
        display: inline-block;
        width: 100%;
        text-align: right;
    }
    .alert_totales_customer h1 span {
        font-size: 18px;
    }
}
</style>
