<template>
    <div>
        <div class="card-header">Show Second Address on Profile</div>
        <div class="card-body">
            <div class="bgloading" v-show="isLoading"><Loader></Loader></div>
            <form @submit.prevent="saveOption">
                <div class="form-row">
                    <div class="col-12">
                        <label class="radio-inline col-6">
                            <span id="email" class="form-text"> Show Address2</span>
                            <input
                                type="radio"
                                value="1"
                                v-model="showAddress2"
                            />&nbsp;&nbsp;
                        </label>
                        <label class="radio-inline col-6">
                            <span id="email" class="form-text"> Hide Address2</span>
                            <input type="radio" value="0" v-model="showAddress2" />
                        </label>
                    </div>
                    <div class="col-12">
                        <br />
                        <button type="submit" class="btn btn-success">UPDATE</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import Loader from '@/core/components/shared/Loader';
// import { mapGetters } from 'vuex'
export default {
    name: 'ShowSecondAddressCustomerProfile',
    components: {
        Loader,
    },
    data () {
        return {
            isLoading: false,
            showAddress2: 0,
        };
    },
    created () {
        this.getStart();
    },
    methods: {
        getStart () {
            this.isLoading = true;
            window.billing
                .get('getCustomerAddress2/' + window.localuserdata)
                .then((response) => {
                    console.log(response.data);
                    this.showAddress2 = response.data.r.meta_value;
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        saveOption () {
            window.billing
                .post('customerAddress2', {
                    org_id: window.localuserdata,
                    status: this.showAddress2,
                })
                .then((response) => {
                    this.$swal.fire({
                        icon: 'success',
                        title: 'Updated',
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    this.$store.commit("core/setLoadingModal", false);
                    this.$store.commit("core/setCloseModal", {action: 'saved'}); 
                })
                .catch((error) => {
                    this.$store.commit("core/setLoadingModal", false);
                    console.log(error, (this.isLoading = false))
                });
        },
    },
    computed: { },
};
</script>
