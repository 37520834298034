<template>
    <div :class="['previewCard', (item!=null && item.id) ? 'showPreview' : 'hidePreview']">
        <div :class="['mainBase', templateName]" v-if="item!=null">
            <div class="nav-browser">
                <label class="edit" @click="$emit('setModal', 'orgUrl')" style="position:relative" v-if="item.card_primary==1">
                    <i class="fas fa-pen"></i>
                </label>
                <label v-else>Url</label>
                <a href="#" @click.prevent="external_link(item.slug_url)">
                    <i class="fas fa-lock"></i>
                    {{urlweb}}{{ item.slug_url }}
                </a>
            </div>
            <div class="closePreview" @click="closePreview()">
                <span>Close</span>
                <i class="fas fa-times"></i></div>
            <div class="bodyMe">
                <!-- item.template_id==1 -->
                <component :is="nameTemplate"></component>
                <mini-site :itemPreview="item" v-if="item.template_id!=5"></mini-site>
                <div v-show="isReloadPreview" class="reloadPreview"><Loader></Loader></div>
                <button
                    :class="['cogProfile']"
                    title="Setup"
                    @click="cogPreview()"
                >
                    <i class="fa fa-cog"></i>
                </button>
                <!-- <p>template_id={{ getCardItem.template_id }}</p> -->
                <!-- <p>card_id={{ getCardItem.id }}</p> -->
                <!-- {{ item }} -->
            </div>
        </div>
        <!-- <div class=" mainBase" v-else >
            <div class="cover_picture" style="background-color: rgb(79, 164, 255);"></div>
            <div class="profile_picture">
                <i class="fas fa-user"></i>
            </div>
            <div class="author-info">
                <h1 class="name">Select a Card</h1>
                <p class="rol">or add a one</p>
            </div>
            <div class="add_contact_share_qr">
                <a href="#" @click.prevent="linkVoid()" class="btn-blue btn-action hvr-shrink" aria-label="ADD TO CONTACT">
                    <i class="fas fa-user-plus" style=" margin-right: 10px;"></i>
                    Add New Card
                </a>
            </div>
        </div>-->
    </div>
</template>
<script>
// @ts-ignore
import { mapGetters } from "vuex";
// @ts-ignore
import Loader from "@/core/components/shared/Loader.vue";
// @ts-ignore
import htmlTemplate1 from "@/apps/Marketea/components/bSite/themes/htmlTemplate1.vue";
// @ts-ignore
import htmlTemplate2 from "@/apps/Marketea/components/bSite/themes/htmlTemplate2.vue";
// @ts-ignore
import htmlTemplate3 from "@/apps/Marketea/components/bSite/themes/htmlTemplate3.vue";
// @ts-ignore
import htmlTemplate4 from "@/apps/Marketea/components/bSite/themes/htmlTemplate4.vue";
// @ts-ignore
import htmlTemplate5 from "@/apps/Marketea/components/bSite/themes/htmlTemplate5.vue";
// @ts-ignore
import htmlTemplate6 from "@/apps/Marketea/components/bSite/themes/htmlTemplate6.vue";
// @ts-ignore
import minisitePreview from "@/apps/Marketea/components/bSite/minisitePreview.vue";
export default {
    name:"previewCard",
    components:{
        Loader,
        htmlTemplate1,
        htmlTemplate2,
        htmlTemplate3,
        htmlTemplate4,
        htmlTemplate5,
        htmlTemplate6,
        'mini-site': minisitePreview,
    },
    data(){
        return {
            listTemplate: ['default', 'classic_grid', 'nightly', 'jaglow', 'postorg', 'bmenu', 'events'],
            // @ts-ignore
            urlweb : window.urlbSite,
            profileLoad: false,
            isReloadPreview: false,
            logoLoad: false,
            PlanPremium: false,
            premiumProfile: false,
            planActual: 1,
            nameTemplate: 'htmlTemplate2',
            ifExistSchedule: null,
            hours24Open: false,
            mapLocation: null,
            planesPro: [],
            style_default: {bgColor: "#53626a", txtColor: "#ffffff"}
        };
    },
    created(){
        // valid premium planID
        // this.PlanPremium = (this.$store.getters['core/apps/getPlanIdApp']==6);
        this.planesPro = this.$store.getters['ModApps/Marketea/get_planesPro'];
        this.planActual = this.$store.getters['core/apps/getPlanIdApp'];
        // this.premiumProfile = (this.planesPro.indexOf(this.$store.getters['core/apps/getPlanIdApp'])>-1);
        this.premiumProfile = (this.$store.getters['core/apps/getPlanIdApp']!=8);
        this.PlanPremium = (this.planesPro.indexOf(this.$store.getters['core/apps/getPlanIdApp'])>-1);
        if(this.planActual==7){
            this.PlanPremium = true;
            console.log('Plan sponsored, dando permiso de premium', this.PlanPremium);
        }
        console.log('PlanPremium', this.PlanPremium);
    },
    methods:{
        showPremium(){
            let t = this;
            t.$store.commit('core/setNotiPush', {
                show: true,
                group: "notiPush",
                type: '5', // 1=success|2=info|3=error|4=loading|5=warning
                title: 'This feature is available for PREMIUM USERS.',
                text: 'Upgrade your App to Premium and advanced features, Please contact your administrator at support@marketea.com or Call us at 774-287-3353.',
                duration: 5000
            });
            let appData = {
                app_id: 8,
                show: true,
                infoApp: null,
                planes: [],
            }
            t.$store.commit("core/set_showSuscription", appData);
        },
        closePreview(){
            this.$emit('hidePreview', null);
        },
        edit_btnsActions(){
            if(this.premiumProfile){
                this.$emit('setModal', 'editBtnsActions');
            }
            else{
                this.showPremium();
            }
        },
        editContact(){this.$emit('setModal', 'editContact');},
        cogPreview(){this.$emit('setModal', 'editTemplate');},
        editPicProfile(){this.$emit('setModal', 'editPicProfile');},
        isBackgroundCover(i){
            let t= this;
            let bg = '';
            if(i.pictures !=null && i.pictures.length>0){
                if(i.pictures[0].cover!=null && i.pictures[0].cover_active){
                    bg='background-image: url('+t.getUrlPic+'large/'+i.pictures[0].cover+');';
                    bg+='background-color:'+i.pictures[0].cover_color+';';
                    bg+='background-position-y: '+i.pictures[0].cover_positionY+'%;';
                    bg+='background-position-x: '+i.pictures[0].cover_positionX+'%;';
                    bg+='background-size: '+i.pictures[0].cover_zoom+'%;';
                }else{
                    bg='background-color:'+i.pictures[0].cover_color+';';
                }
            }
            return bg;
        },
        srcCoverVideo(i){
            let t = this;
            let vd =null;
            let p = (i.pictures!=null) ? i.pictures[0] : null;
            // console.log('bg video', p);
            if(p !=null){
                if(p.cover_video!=null && p.cover_v_active){
                    if(p.cover_video!=null && p.cover_video!='' && p.cover_video!='null'){
                        vd =t.getUrlPic+'video/'+p.cover_video;
                        // console.log('bg video', vd);
                    }
                }
            }
            return vd;
        },
        positionVideo(i){
            let t= this;
            let s = '';
            if(i.pictures !=null && i.pictures.length>0){
                s+='top: '+((i.pictures[0].cover_video_y)*-1)+'%;';
                s+='width: '+i.pictures[0].cover_video_z+'%;';
                s+='height:420px;'
                s+='object-fit:cover;'
                if(i.pictures[0].cover_video_z>100){
                    // s+='left: '+(((i.pictures[0].cover_video_z-100)/2)*-1)+'%;';
                }
            }
            return s;
        },
        isLogo(i){
            let t= this;
            let logo = '';
            if(i.pictures !=null && i.pictures.length>0){
                if(i.pictures[0].logo!=null){
                    logo='background-image: url('+t.getUrlPic+'small/'+i.pictures[0].logo+');';
                    logo+='opacity: '+i.pictures[0].logo_opacity+';';
                }
            }
            return logo;
        },
        isProfile(i){
            let t= this;
            let profile = '';
            if(i.pictures!=null && i.pictures.length>0){
                if(i.pictures[0].picture!=null){
                    profile='background-image: url('+t.getUrlPic+'thumbs/'+i.pictures[0].picture+');';
                    profile+='background-position-y: '+i.pictures[0].pic_positionY+'%;';
                    profile+='background-position-x: '+i.pictures[0].pic_positionX+'%;';
                    profile+='background-size: '+i.pictures[0].pic_zoom+'%;';
                    localStorage.setItem('picture_old', i.pictures[0].picture)
                }
            }
            return profile;
        },
        isLogoProfile(i){
            let t= this;
            let src = '';
            let style = '';
            if(i.pictures!=null && i.pictures.length>0){
                if(i.pictures[0].picture!=null){
                    src=''+t.getUrlPic+'thumbs/'+i.pictures[0].picture+'';
                    style+='top: '+i.pictures[0].pic_positionY+'%;';
                    style+='left: '+i.pictures[0].pic_positionX+'%;';
                    style+='object-cover: '+i.pictures[0].pic_zoom+'%;';
                    localStorage.setItem('picture_old', i.pictures[0].picture)
                }
            }
            return [src, style];
        },
        linkVoid(){
            console.log('disabled');
        },
        external_link(slug_url){
            if (slug_url!=null && slug_url.length > 0) {
            window.open(this.urlweb + slug_url, "_blank");
            }
        },
        helperValue(i){
            console.log('helperValue', i)
            let label = (i.label+'').toLowerCase();
            let value = i.val;
            let forUrl = ['website', 'shop']
            let numerPhone = ['call', 'sms'];
            if(value!=null && i.status==1){
                if(forUrl.indexOf(label)>-1){
                    let url = value;
                    if(url.indexOf('http://')==-1 || url.indexOf('https://')==-1){
                        if(url.indexOf('http://')>-1){url = url.replaceAll('http://', ''); }
                        url = 'https://'+url;
                        return url;
                    }
                }
                else if(label=='whatsapp'){
                    return '//wa.me:'+value;
                }
                else if(numerPhone.indexOf(label)>-1){
                    return this.modelarNumeroTel(value);
                }
                else if(i.tipo==2){
                    if(value.indexOf('/')>-1 && label!='map'){
                        let userSocial= value.split('/');
                        return '@'+userSocial[userSocial.length-1];
                    }
                    else if(label=='map'){
                        return '>> come visit us';
                    }
                }
            }
            return value;
        },
        helperLabel(i){
            let label = (i.label+'').toLowerCase();
            let value = i.val;
            let slug = i.slug;
            if(label=='form pop-up'){
                return value;
            }
            return label;
        },
        modelarNumeroTel(value){
            let pais = '';
            let area = '';
            let phone = '';
            let val = value;
            let len = (val!=null) ? val.length : 0;
            // limpiar numero
            if(val.indexOf('tel:')>-1){val = val.replaceAll('tel:', ''); }
            if(val.indexOf('(')>-1){val = val.replaceAll('(', ''); }
            if(val.indexOf(')')>-1){val = val.replaceAll(')', ''); }
            if(val.indexOf('-')>-1){val = val.replaceAll('-', ''); }
            if(val.indexOf('.')>-1){val = val.replaceAll('.', ''); }
            if(val.indexOf('/')>-1){val = val.replaceAll('/', ''); }
            if(val.indexOf(' ')>-1){val = val.replaceAll(' ', ''); }
            let lon=0;
            if(len>=7){
                phone = val.slice(-7, val.length-4)+'-'+val.slice(-4, val.length);
                lon+=7;
            }
            if(len>=10){
                area = '('+val.slice(-10, val.length-lon)+') ';
                lon+=3;
            }
            if(len>=11){
                pais = val.slice(0, val.length-lon)+' ';
            }
            return pais+area+phone;
        },
        btns_action(btns){
            return btns.filter(r => {
                if(r.status==1){
                    return r;
                }
            });
        },
        loadPicProfile(event){
            let t = this;
            let file = event.target.files[0];
            console.log('loadPicProfile', file);
            if (file) {
                t.profileLoad=true;
                var reader = new FileReader();
                reader.readAsDataURL(file);
                // @ts-ignore
                reader.onload = function (e) {
                // t.profilePreview = e.target.result;
                t.item.pictures[0].picture = file;
                t.setPicProfile()
                };
            } else {
                // @ts-ignore
                t.$refs.profileImg.value = null;
            }
        },
        setPicProfile(){
            let t = this;
            let formData = new FormData();
            // @ts-ignore
            formData.append("user_id", window.master_client);
            // @ts-ignore
            formData.append("org_id", window.localorgdata);
            formData.append("id", t.item.id);
            formData.append("picture_old", localStorage.getItem('picture_old'));
            formData.append("picture", t.item.pictures[0].picture);
            t.$store.dispatch('ModApps/Marketea/editPicProfile', formData)
            .then(r => {
                console.log('setPicProfile', r)
                if(r.status){
                    if(r.upload){
                        t.item.pictures[0].picture=r.filename;
                    }
                }
                // @ts-ignore
                t.$refs.profileImg.value = null;
                t.profileLoad=false;
            })
            .catch(error => {
                console.log(error);
                // @ts-ignore
                t.$refs.profileImg.value = null;
                t.profileLoad=false;
                t.$notify({
                    group: "noti",
                    type: 'Error',
                    title: "Fail",
                    text: "Failed to save",
                    duration: 2000
                });
            });
        },
        getMapa(item){
                let mapa = null;
                let mapDetaful= 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1517337.2149921788!2d-73.00297119894064!3d42.03140120496237!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e3656de973bffd%3A0x45c6d03655830d1c!2sMassachusetts%2C%20EE.%20UU.!5e0!3m2!1ses-419!2sco!4v1702055332819!5m2!1sen-419!2sus';
                if(item.temp_opts && item.temp_opts.length>0){
                    for (const key in item.temp_opts) {
                        if (Object.hasOwnProperty.call(item.temp_opts, key)) {
                            if(item.template_id==item.temp_opts[key].template_id){
                                if(item.temp_opts[key].meta_key=='map' && item.temp_opts[key].meta_value!=null && item.temp_opts[key].meta_value.length>0){
                                    mapa = item.temp_opts[key].meta_value;
                                }
                            }
                        }
                    }
                }
                if(mapa==null){mapa = mapDetaful;}
                console.log('getMapa', mapa)
                this.mapLocation = mapa;
        },
        isSchedule(item){
            console.log('isSchedule')
                let t= this;
                let hours = null;
                let d = new Date();
                let dia_actual=null;
                let schedule=null;
                let hoursDefault= [
                    {day_id:1, day_name:'Monday', closed:0, opens_at:'08:00', opens_me:1, closes_at:'17:00', closes_me:2},
                    {day_id:2, day_name:'Tuesday', closed:0, opens_at:'08:00', opens_me:1, closes_at:'17:00', closes_me:2},
                    {day_id:3, day_name:'Wednesday', closed:0, opens_at:'08:00', opens_me:1, closes_at:'17:00', closes_me:2},
                    {day_id:4, day_name:'Thursday', closed:0, opens_at:'08:00', opens_me:1, closes_at:'17:00', closes_me:2},
                    {day_id:5, day_name:'Friday', closed:0, opens_at:'08:00', opens_me:1, closes_at:'17:00', closes_me:2},
                    {day_id:6, day_name:'Saturday', closed:1, opens_at:'08:00', opens_me:1, closes_at:'17:00', closes_me:2},
                    {day_id:0, day_name:'Sunday', closed:1, opens_at:'08:00', opens_me:1, closes_at:'17:00', closes_me:2},
                ];
                if(item.temp_opts && item.temp_opts.length>0){
                    for (const key in item.temp_opts) {
                        if (Object.hasOwnProperty.call(item.temp_opts, key)) {
                            if(item.template_id==item.temp_opts[key].template_id){
                                // console.log('temp_opts template_id', item.temp_opts[key].template_id)
                                if(item.temp_opts[key].meta_key=='schedule' && item.temp_opts[key].meta_value!=null && item.temp_opts[key].meta_value.length>0){
                                    hours = JSON.parse(item.temp_opts[key].meta_value);
                                    // console.log('temp_opts ', hours)
                                }
                            }
                        }
                    }
                }
                if(hours==null){
                    hours = hoursDefault;
                    console.log('hours is null asigndefault')
                }
                let validHours24 = true;
                for (const key in hours) {
                    if (Object.hasOwnProperty.call(hours, key)) {
                        const element = hours[key];
                        // console.log('hours', key, element)
                        if(element.day_name=='24 hours'){
                            t.hours24Open = element.active;
                            validHours24 = false;
                            // console.log('is exist fields 24 hours', t.hours24Open)
                        }
                    }
                }
                if(validHours24){
                    hours.push({day_id:24, day_name:'24 hours', active: 0},)
                    // console.log('new fields 24 hours');
                }
                dia_actual=hours.filter(r => {return r.day_id ==d.getDay() });
                console.log('dia_actual ', dia_actual)
                if(dia_actual.length>0){
                    schedule = dia_actual[0];
                    let hora24 = d.getHours();
                    let min_actual = d.getMinutes();
                    let AmOrPm = hora24 >= 12 ? 1 : 2;
                    let hora12 = AmOrPm ? hora24-12 : hora24;
                    console.log('hora y min actual ', hora24, min_actual)
                    schedule.open_status = 0;
                    let opens_hour = (schedule.opens_at.indexOf(':')) ? parseInt(schedule.opens_at.split(':')[0]) : parseInt(schedule.opens_at);
                    let opens_min = (schedule.opens_at.indexOf(':')) ? parseInt(schedule.opens_at.split(':')[1]) : 0;
                    let closes_hour = (schedule.closes_at.indexOf(':')) ? parseInt(schedule.closes_at.split(':')[0]) : parseInt(schedule.closes_at);
                    let closes_min = (schedule.opens_at.indexOf(':')) ? parseInt(schedule.opens_at.split(':')[1]) : 0;
                    if(schedule.closed==0){
                        if(closes_hour>opens_hour){
                            if(hora24 < closes_hour){
                                if(hora24 > opens_hour){schedule.open_status = 1;}
                                else if(hora24 == opens_hour && min_actual>=opens_min){schedule.open_status = 1;}
                            }
                            else if(hora24==closes_hour && min_actual<closes_min){
                                schedule.open_status = 1;
                            }
                        }
                    }
                }
                console.log('isSchedule', schedule)
                this.ifExistSchedule = schedule;
        },
    },
    watch: {
        getCardItem: function(){
            let t = this;
            t.$store.commit('ModApps/Marketea/set_reloadPreview', true);
            if(t.getCardItem!=null){
                t.templateName=t.getCardItem.template_id;
                t.nameTemplate ='htmlTemplate'+t.getCardItem.template_id;
                if(t.getCardItem.template_id==4){
                    t.getMapa(t.getCardItem);
                    t.isSchedule(t.getCardItem);
                }
                setTimeout(() => {
                    t.$store.commit('ModApps/Marketea/set_reloadPreview', false);
                }, 800);
            }
        },
        getCardUrlPic: function(){
            this.getUrlPic=this.getCardUrlPic;
        },
        reloadPreview: function(){
            this.isReloadPreview = this.reloadPreview;
        },
    },
    computed: {
        ...mapGetters("ModApps/Marketea", {
            getListcard: "get_bsite_listcard",
            getCardItem: "get_bsite_cardItem",
            getCardUrlPic: "get_bsite_cardUrlPic",
            reloadPreview: "get_reloadPreview",
        }),
        ...mapGetters("core/Org", {
            orginf: "orginf",
        }),
        listFilter(){
            let t = this;
            return t.listData;
        },
        item: {
            get: function () {
                let t = this;
                return t.getCardItem;
            },
            set: function (v) {
                return v;
            }
        },
        templateName: {
            get: function () {
                let t = this;
                return t.listTemplate[t.getCardItem.template_id];
            },
            set: function (v) {
                let t = this;
                return t.listTemplate[v];
            }
        },
        getUrlPic: {
            get: function () {
                let t = this;
                return t.getCardUrlPic;
            },
            set: function (v) {
                return v;
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap");
@import url('./scss/previewCard.scss');
// @import url('./scss/efectsThemes.scss');
// @import url('./scss/baseTemplate.scss');
// @import url('./scss/classic_grid.scss');
// @import url('./scss/nightly.scss');
// @import url('./scss/jaglow.scss');
</style>
