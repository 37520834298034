<template>
	<div>
		<table style="float: right;min-width: 75%;">
			<tr style="border-bottom: 1px solid #dedede;">
				<td align="left">
					<div style="padding-right: 10px;font-size: 15px;">
						<b> SubTotal </b>
					</div>
				</td>
				<td align="right">
					<div style="font-size: 18px;">
						{{ subTotal | formatMoney }}
						<input type="hidden" class="form-control" v-model="form.sub_Total" />
					</div>
				</td>
			</tr>
			<tr v-for="(iva, index) in $parent.taxesResumen" :key="index">
				<td colspan="2">
					<table width="100%">
						<tr v-if="iva.total > 0">
							<td align="left">
								<div style="padding-right: 10px;">
									<b>
										<span>{{ iva.name }}</span>
										({{ iva.porcentaje }}%)
									</b>
								</div>
							</td>
							<td align="right">
								{{ Number(iva.total) | formatMoney }}
							</td>
						</tr>
					</table>
				</td>
			</tr>
			<tr style="border-top: 0px solid #dedede;">
				<td align="left">
					<div style="font-size: 16px; padding-right: 10px;display: inline-block;">
						<b> Discount</b>
					</div>
					<div class="input-group" style="display: inline-block;max-width: 60%;">
						<input 
						type="text" 
						class="form-control" 
						v-model="form.discount_mount"
						@change="vDiscount"
						style="display: inline-block;width: 80%; text-align: right;"
                        :disabled="$parent.freePlan"
						/>
						<span class="input-group-addon" style="display: inline-block;width: 20%;">
							<span
							class="cdropdown selec_discount"
							v-click-outside="hideBtnDiscount"
							>
							<button
							class="discount"
							type="button"
							@click="btnDiscount()"
							style="border: 0px"
							>
							<span v-if="form.discount_type==1">%</span>
							<span v-else>$</span>
						</button>
						<label>
							<input
							type="checkbox"
							v-model="DiscountDropd"
							/>
							<ul>
								<li>
									<button type="button" @click="typeDiscount(1)">
										%
									</button>
								</li>
								<li>
									<button type="button" @click=" typeDiscount(2)">
										$
									</button>
								</li>
							</ul>
						</label>
					</span>
				</span>
			</div>
		</td>
		<td align="right">
			<div style="font-size: 18px;" v-if="discount > 0">
				{{ (discount * -1) | formatMoney }}
			</div>
			<div style="font-size: 18px;" v-else>
				0.00
			</div>
		</td>
	</tr>
    <tr v-if="discount > 0">
        <td align="left" colspan="2" style="position: relative;">
            <textarea placeholder="Discount name or description (optional)" class="form-control" style="height: 70px; display: inline-block;width: 280px;" v-model="form.discount_name" @keyup="validMaxLen(100)"></textarea>
            <span class="maxSize" v-if="form.discount_name">{{form.discount_name.length}}/100</span>
            <span class="maxSize" v-else>0/100</span>
        </td>
    </tr>
    <tr v-if="discount > 0">
        <td align="left" colspan="2">
            <br>
        </td>
    </tr>
	<tr style="border-top: 1px solid #dedede;">
		<td align="left">
			<div style="font-size: 16px; padding-right: 10px;">
				<b> Total</b>
			</div>
		</td>
		<td align="right">
			<div style="font-size: 18px;">
				{{ total | formatMoney }}
				<input type="hidden" class="form-control" v-model="form.total" />
			</div>
		</td>
	</tr>
</table>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
	name: 'tableResumenTotales',
    props: {
        form: { type: Object, required: true },
    },
	data () {
		return {
            DiscountDropd: false,
            PlanPremium : (window.OrgPlan > 1),
		}
	},
	created(){
		this.PlanPremium = (this.$store.getters['core/Org/getPlanId'] > 1);
	},
	methods : {		
        vDiscount(e){
            let val = this.quitarComas(e.target.value);
            let valResult = val;
            let msj = '';
            let validInt = new RegExp(/^[0-9.]+$/); 
            // si es diferente de numero , retornar cero
            console.log('vDiscount', val)
            if(!validInt.test(val)){
                if(val != null && val!=''){
                    msj="Discount, only numbers allowed.";
                }
                valResult = 0;
            }
            // si es NaN
            else if(isNaN(val)){
                msj="Incorrect amount, you may be adding disallowed symbology.";
                valResult = 0;
            }
            // si es menor de cero, retorna cero
            else if(val < 0){
                msj="Discount cannot be less than zero";
                valResult = 0;
            }
            //validaciones donde descuento es porcentaje y es mayor de 99.99, retornar cero
            else if(this.form.discount_type==1 && val > 0 && val >99.99){
                msj="Discount cannot be greater than 99.99%";
                valResult = 0;
            }
            // validaciones siendo un monto y si es mayor a subtotal, retornar cero
            else if(this.form.discount_type==2 && val > 0 && val >= this.form.subtotal){
                msj="Discount cannot be greater than or equal to the subtotal.";
                valResult = 0;
            }
            if(msj.length > 0){this.msjAlert(msj);}
            e.target.value = Number(valResult).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        },
        btnDiscount() {
            this.DiscountDropd = !this.DiscountDropd;
        },
        hideBtnDiscount() {
            this.DiscountDropd = false;
        },
        typeDiscount(i){
            if(this.form.discount_type != i){this.form.discount_mount = 0;}
            this.form.discount_type = i;
            this.hideBtnDiscount();
        }, 
        quitarComas(special){
            let vatIdComas = new RegExp(/,/);       
            if (vatIdComas.test(special)) {
                special = special.replace(",", "");
                special = this.quitarComas(special)
            }
            return special           
        },  
        ValItemTax(obj, valDiscount, subtotal){
            //reiniciar totales
            if (this.$parent.taxesResumen.length > 0) {
                for (let e in this.$parent.taxesResumen) {
                    this.$parent.taxesResumen[e].total = 0;
                }
            }
            // calcular totales 
            for (let i in obj) {
                if (obj[i].model.taxOpc != 0) {
                    if (this.$parent.taxesResumen.length > 0) {
                        for (let e in this.$parent.taxesResumen) {
                            if (this.$parent.taxesResumen[e].id == obj[i].model.taxOpc.id) {
                                let unitcost = obj[i].model.unitcost;
                                if ((unitcost+'').indexOf(",") > -1) {
                                    unitcost = Number(this.quitarComas(unitcost));
                                }   
                                let qty = obj[i].model.qty
                                if ((qty+'').indexOf(",") > -1) {
                                    qty = Number(this.quitarComas(qty));
                                }   
                                let valTax = Number(obj[i].model.taxOpc.name);
                                if(valDiscount > 0){
                                    //calculamos el valor en % equivalente al subtotal
                                    let defragDiscount= (unitcost / subtotal) * 100;
                                    let defragDiscVal = valDiscount * defragDiscount / 100;
                                    // console.log('descuento en producto', defragDiscVal)
                                    unitcost = unitcost - defragDiscVal
                                    // console.log('new unitcost', unitcost)
                                }
                                let precioTotal = (unitcost * qty);
                                let total = (precioTotal * valTax) / 100;
                                if (!isNaN(total)) {
                                    this.$parent.taxesResumen[e].total = Number(this.$parent.taxesResumen[e].total) + Number(total);
                                }
                            }
                        }
                    }
                }
            }
        },
        quitarNegativosX(special){
            if (special == 0) {
                special = '0.00'
            }
            let countNegativeUnitcost = (special.match(/-/g) || []).length;            
            if (countNegativeUnitcost == 2) {
                if (special.slice(-1) == '-') {
                    special = special.slice(0, -1)
                }                    
                special = special.replaceAll("-", "");
                special = '-'+special           
                special = this.quitarNegativosX(special)
                this.msjAlert("Incorrect amount, you may be adding disallowed symbology.")
            }
            return special
        },         
        msjAlert(msj){
            this.$notify({
                group: "noti",
                title: "Error",
                type: "error",
                text: msj
            });
        },
        validMaxLen(len) { 
            let t = event.target;
            if(t.value.length > len) { 
                this.msjAlert('Maximum length exceeded: ' + len)
                t.value = t.value.substr(0, len); 
                this.form.discount_name = t.value
                return false; 
            }
        }
	},
	watch : {
        getPlanId() {    
            this.PlanPremium = (this.$store.getters['core/Org/getPlanId'] > 1);
            console.log('watch PlanPremium', this.PlanPremium);
        },
	},
	computed: { 
		...mapGetters("core/Org", { getPlanId: "getPlanId"}),		
        subTotal() {
            return this.$parent.formList.items.reduce((carry, item) => {
                let unitcost 
                if (item.model.unitcost == '' || item.model.unitcost == 'null' || item.model.unitcost == undefined) {
                    unitcost = '0.00'
                } else {
                    unitcost = item.model.unitcost
                    if (unitcost.indexOf(",") > -1) {
                        unitcost = this.quitarComas(unitcost)
                    }                     
                }
                let countPunto = unitcost.split('.').length - 1;           
                if (countPunto == 2) {
                    unitcost = unitcost.replace(".", "");
                }

                let qty 
                if (item.model.qty == '' || item.model.qty == 'null' || item.model.qty== undefined) {
                    qty = '0.00'
                } else {
                    qty = item.model.qty
                    if (qty.indexOf(",") > -1) {
                        qty = this.quitarComas(qty)
                    }                      
                }
                let countPuntoQty = qty.split('.').length - 1;           
                if (countPuntoQty == 2) {
                    qty = qty.replace(".", "");
                }  

                let stt = carry + Number(unitcost) * Number(qty)
                if (isNaN(stt)) {
                    stt = '0.00'
                }
                this.form.subtotal = stt
                console.log('subtotal', this.form.subtotal)
                return stt                
            }, 0);
        },
        discount() {
            // inicializo variables a utilizar 
            let t = this; 
            let subtotal = Number(t.subTotal);
            let discount_value = 0;
            let mount = this.quitarComas(t.form.discount_mount);
            // obtener el valor del descuento para ello
            // primero valido si el descuento es en % o $ 
            if(mount > 0){ 
                // si descuento es mayor a cero, calculo
                if(t.form.discount_type==1){ // porcentaje
                   discount_value = (subtotal * mount) / 100;
                }
                else if(t.form.discount_type==2){ // monto 
                   discount_value = mount; 
                }
            }
            // si el calculo de descuento es igual al subtotal retorno cero o error
            if(discount_value == subtotal){discount_value = 0;}
            else{
                // sino calculo descuneto para cada item 
                this.ValItemTax(t.$parent.formList.items, discount_value, subtotal);
            }
            t.form.discount_total = discount_value;
            return discount_value;
        },
        TotalTax() {
            let totaltaxes = 0;
            if (this.$parent.taxesResumen.length > 0) {
                for (let e in this.$parent.taxesResumen) {
                    totaltaxes+=this.$parent.taxesResumen[e].total;
                }
            }
            return totaltaxes;
        },
        total() {
            let t = this;
            // this.valueOptionstax(t.$parent.formList.items);
            let stt=(Number(t.subTotal)+Number(t.TotalTax))-t.form.discount_total;
            if (isNaN(stt)) {stt = '0.00';}
            t.form.total = stt;
            return stt;
        },
	}
}
</script>
<style lang="css" scoped>
span.cdropdown.selec_discount {
    width: 100%;
    font-size: 0.8rem;
    line-height: 1.5;
    color: #6a7a8c;
    border: 1px solid #dedede;
    border-radius: 2px;
}
.cdropdown ul li {
    padding: 0px;
}
span.cdropdown.selec_discount button {
    color: #6a7a8c;
    width: 100%;
    text-align: left;
    padding: 6px 10px;
}
span.maxSize {position: absolute;right: 77px;bottom: 1px;background-color: #fff;padding: 0px 3px;border: 1px solid #e9ecef;font-size: 10px;}
</style>
