var getQueryVarsUrl = function () {
   var query = window.location.search.substring(1);
   var vars = query.split("&");
   var _Gets = [];
   for (var i=0; i < vars.length; i++) {
       var pair = vars[i].split("=");
       if(pair[0]) {
           _Gets.push({'getKey': pair[0], 'getVal': pair[1]});
       }
   }
   return _Gets;
}
export default getQueryVarsUrl;
