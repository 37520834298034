<template>
    <div class="box">
        <div class="content-form">
            <i class="fas fa-exclamation-triangle"></i>
            <p>
                You want to change the start of the numeration of your Estimates?.<br>
                Do it now or click Cancel.
            </p>
            <form @submit.prevent="saveStartEstimate">
                <div class="form-row">
                    <div class="col-10">
                        <span id="emailHelp" class="form-text" >
                            Start Number: Example <b>001</b>
                        </span>
                        <input
                            type="text"
                            class="form-control"
                            v-model="inputStartnumber"
                            :disabled="isLoadingstart"
                            required
                        />
                    </div>
                    <div class="col-2">
                        <span id="emailHelp" class="form-text text-muted">.</span>
                        <button
                            type="submit"
                            class="btn btn-success"
                            :disabled="isLoadingstart"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </form>
            <Loader v-show="isLoadingstart"></Loader>
        </div>
    </div>
</template>

<script>
import Loader from '@/core/components/shared/Loader';
export default {
    name: "startNumber",
    components: {
        Loader,
    },
    props: {
        updateNum: {
            type: String,
            require: false,
            default: null
        }
    },
    data () {
        return {
            isLoadingstart: false,
            inputStartnumber: '001',
        };
    },
    created(){ this.valueUpdate(); },
    watch: { updateNum: function(){this.valueUpdate();}},
    methods: {
        valueUpdate(){
            let t = this;
            if(t.updateNum!=null){
                t.inputStartnumber=t.updateNum;
            }
        },
        saveStartEstimate() {
            let t = this;
            t.isLoadingstart = true;
            window.billing
                .post("startestimate/add", {
                    user_id: window.localuserdata,
                    startnumber: t.inputStartnumber,
                })
                .then((response) => {
                    // quitar bloqueo startNumber
                    t.isLoadingstart = false;
                    t.$emit('setChildEmitStartNumber', false);
                })
                .catch((error) => {
                    console.log(error)
                    t.isLoadingstart = false;
                });
        },
    }
}
</script>

<style lang="scss" scoped>
.box {
    display: flex;
    justify-content: center;
    height: 100vh;
    padding-top: 100px;
    .content-form {
        max-width: 350px;
        width: 100%;
        text-align: center;
        i {
            font-size: 40px;
            margin-bottom: 20px;
        }
        p {
            display: block;
            margin-bottom: 10px;
            font-size: 18px;
            color: #6a7a8c;
        }
    }
}

</style>