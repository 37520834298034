const urlServers = {
	urlmaster: {
		produccion: 'https://marketeaaccounts.com/',
		test: 'https://m.marketea.app/',
		dev: 'http://127.0.0.1:8000/'
	},
	urlproject: {
		produccion: 'https://api.marketeaprojects2.com/',
		test: 'https://api.marketeaprojects2.com/',
		dev: 'https://api.marketeaprojects2.com/'
	},
	urlbilling: {
		produccion: 'https://marketeabilling.com/',
		//test: 'https://billiingtest.marketeabeta.com/',
		test: 'https://marketeabilling2.com/',
		dev: 'http://127.0.0.1:8050/'
	},
	urldashboard: {
		produccion: 'https://accounts.marketea.com/',
		test: 'https://accounts.marketea.app/',
		dev: 'http://localhost:8080/'
	},
	urlbaselogin: {
		produccion: 'https://marketea.com/login',
		test: 'https://accounts.marketea.app/',
		dev: 'http://localhost/app_marketea/billing/app_marketea_views/login_marketea_app.php'
	},
	urlbase: {
		produccion: 'https://marketeabilling.com/',
		//test: 'https://billiingtest.marketeabeta.com/',
		test: 'https://marketeabilling2.com/',
		dev: 'http://127.0.0.1:8050/'
	},
	urlweb: {
		produccion: 'https://marketea.com/',
		test: 'https://marketea.com/',
		dev: 'https://marketea.com/'
	},
	urlSlabs: {
		produccion: 'https://marketeaslabs.com/',
		test: 'https://test.marketeaslabs.com/',
		dev: 'https://test.marketeaslabs.com/'
	},
	urlDisplay: {
		produccion: 'https://mdisplay.net/',
		test: 'https://mdisplay.marketeabeta.com/',
		dev: 'http://mdisplay.local/'
	},
	urlbSite: {
		produccion: 'https://bsite.me/',
		test: 'https://bsite.marketea.app/',
		dev: 'http://bsite.local/'
	}
};
export default urlServers;
