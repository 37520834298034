<template>
    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col-6" style="padding-top: 10px">
                    <h5 class="card-title">{{title}}</h5>
                </div>
            </div>
        </div>
        <div class="card-body" style="padding-top: 2px;">
                <div class="content-table task-list">
                    <form @submit.prevent="saveVendor" v-if="modal.vendor">
                        <div class="form-group">
                            <label for="inputCity">Company Name</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="addVendor.company_name"
                                required
                            />
                        </div>
                        <div class="row">
                            <div class="col-12 text-right">
                                <button type="button" class="btn btn-secundary" style="margin-right: 10px;background-color:#fff;border-color:#ccc;" @click="cancleVendor">
                                    <i class="fas fa-times"></i> Cancel
                                </button><button type="submit" class="btn btn-success">
                                    <i class="fas fa-save"></i> Save
                                </button>
                            </div>
                        </div>
                    </form>
                    <form @submit.prevent="formSave" class="row" v-else>
                        <div class="form-group col-sm-12">
                            <label for="name">Name:</label>
                            <div class="input-group">
                                <input type="text" class="form-control" v-model="f.task_name" required>
                            </div>
                        </div>
                        <div class="form-group col-sm-12" v-if="showVendor">
                            <label for="vendor">Vendor <small>(Optional)</small>:</label>
                            <div class="input-group">
                                <span style="position: absolute; right: 5px; top: -21px">
                                    <button
                                        type="button"
                                        class="btnlink"
                                        @click="addVendorNew"
                                    >
                                        <i class="fas fa-plus"></i> New Vendor
                                    </button>
                                </span>
                                <model-select
                                    :options="VendorList"
                                    v-model="f.vendor_id"
                                    placeholder="Choose a Vendor"
                                >
                                    <template #search="{attributes, events}">
                                        <input
                                          class="search"
                                          :required="!f.vendor_id"
                                          v-bind="attributes"
                                          v-on="events"
                                          />
                                    </template>
                                </model-select>
                            </div>
                        </div>
                        <div class="form-group col-sm-5">
                            <label for="due_date">Due Date:</label>
                            <div class="input-group">
                                <div class="label-date" style="width: 200px;height: 30px;">
                                    <span class="view-date" style="display: block;padding: 0.33rem 0.75rem;">{{f.due_date | moment("MMM DD, YYYY - hh:mm A")}}</span>
                                    <date-picker
                                        v-model="f.due_date"
                                        :default-value="dateSelected"
                                        value-type="format" 
                                        format="YYYY-MM-DD HH:mm:ss"
                                        type="datetime"
                                        :input-attr="{'data-value': f.due_date}"
                                        :show-time-panel="showTimePanel"
                                        :minute-step="5"
                                        input-class="mx-input input-form"
                                        @open="dueDateOpen()"
                                        @close="closeDatePicker()"
                                        @input="changeDate()"
                                        :clearable="true"
                                        :show-second="false"
                                        use12h
                                        :open.sync="openCal"
                                        :disabled-date="notBeforeToday"
                                    >
                                        <template v-slot:header="{ emit }" class="header-date-picker">
                                            <button class="mx-btn mx-btn-text btn-today" @click="emit(new Date())">Today</button>
                                        </template>
                                        <template v-slot:footer>
                                            <button class="mx-btn mx-btn-text" @click="toggleTimePanel" v-if="!showTimePanel">
                                                Hours
                                            </button>
                                            <button class="mx-btn mx-btn-text" @click="toggleTimePanel" v-else>
                                                Date
                                            </button>
                                            <button class="mx-btn mx-btn-text" @click="closeDatePicker" >
                                                Ok
                                            </button>
                                            <!-- <button class="mx-btn mx-btn-text" @click="onResetCal" :disabled="resetCal">
                                                Cancel
                                            </button> -->
                                            <button class="mx-btn mx-btn-text" @click="closeDatePicker" >
                                                Close
                                            </button>
                                        </template>
                                    </date-picker>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-sm-7">
                            <label for="schudule">Schedule: </label>
                            <div class="input-group">
                                <select class="form-control" v-model="f.date_schedule">
                                    <option v-for="(i, index) in daysBefore" :key="index" :value="i">
                                        {{i}} 
                                        <span v-if="i==0">days</span>
                                        <span v-else-if="i<2">day before</span>
                                        <span v-else>days before</span>
                                    </option>
                                </select>
                                <span class="input-group-addon" style="height: 34px;display: block;padding-top: 10px;padding-left: 5px;">
                                    {{date_prev | moment("MMM DD, hh:mm A")}}
                                </span>
                            </div>
                        </div>
                        <div class="form-group col-sm-5">
                            <label for="rec">Recurring:</label>
                            <div class="input-group" style="display: inline-block;width: 30%;top: 8px;">
                                <div class="btn-switch" >
                                    <div class="onoffswitch" @click="activeRec()">
                                        <input type="checkbox" id="rec" :checked="f.rec>0" class="onoffswitch-checkbox" >
                                        <label class="onoffswitch-label" >
                                            <span class="onoffswitch-inner"></span>
                                            <span class="onoffswitch-switch"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-sm-6" style="padding-top: 10px;">
                            <label for="interval">Every:</label>
                            <div class="input-group" style="display: inline-block;margin-left:15px;width: 50%;top: 2px;">
                                <label class="radio-inline" >
                                    <input type="radio" v-model="f.interval" value="1"> Year
                                </label>
                                <label class="radio-inline" style="padding-left: 15px;">
                                    <input type="radio" v-model="f.interval" value="2"> Month
                                </label>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div v-for="(a, idx) in f.actions" :key="idx" :class="['row', (f.actions.length>0 && f.actions.length>(idx+1)) ? 'row_hr' : '']">
                                <div class="form-group col-md-12" style="margin-top:10px;">
                                    <!-- <label v-if="idx==0">A minimum of 1 Action is required!. Please add.</label> -->
                                    <input type="text" class="form-control" v-model="a.accion_name" placeholder="Action name:" required>
                                </div>
                                <div class="form-group col-md-12 descrip_action">
                                    <!-- <label>Description or instructions:</label> -->
                                    <vue-editor
                                    v-model="a.accion_descrip"
                                    :editor-toolbar="customToolbar"
                                    class="descripcion"
                                    placeholder="Action Details:"
                                    />
                                    <button type="button" class="btn btn-danger delAction" @click="deleteAction(idx)" v-if="f.actions.length>1">
                                        <i class="fas fa-trash"></i>
                                        <span class="tooltip">Delete</span>
                                    </button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <button type="button" class="btn btn-info" @click="newAction">
                                        <i class="fas fa-plus"></i> Add Action
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 text-right" style="margin-top:20px;">
                            <button type="button" class="btn btn-secundary" style="margin-right: 10px;background-color:#fff;border-color:#ccc;" @click="cancle">
                                <i class="fas fa-times"></i> Cancel
                            </button>
                            <button type="submit" class="btn btn-success">
                                <i class="fas fa-save"></i> Save
                            </button>
                        </div>
                    </form>
                </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import { ModelSelect } from "vue-search-select";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import '@/apps/billing/components/smartOffice/datepicker.scss';
import '@/apps/billing/components/smartOffice/form_task.scss';
import { VueEditor } from 'vue2-editor'
export default {
    name: "form_Task",
    components: {
        ModelSelect,
        DatePicker,
        VueEditor,
    },
    data() {
        return {
            isLoading: false,
            title: 'New Task',
            f: {
                id: 0,
                user_idd: window.master_client,
                user_id: window.localorgdata,
                org_id: window.localorgdata,
				oauth_m: window.oauth_m,
                task_name: '',
                vendor_id: '0',
                due_date: new Date().setHours(16, 0, 0, 0),
                date_schedule: 0,
                interval: 1,
                rec: 1,
                actions: [{
                    accion_name: '',
                    accion_descrip: '',
                }],
            },
            addVendor: { company_name: null }, 
            VendorList: [],
            modal: {
                title: "",
                vendor: false,
                isLoading: false,
                action: 'add',
            },  
            showTimePanel: false,
            resetCal: true,
            date_old: null,
            dateSelected: new Date(),
            openCal: false,
            daysBefore: [],
            customToolbar: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ["bold", "underline"], // toggled buttons
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                ],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ color: [] }],
                ["link"],
                ["clean"] // remove formatting button
            ], 
            showVendor: false,
        };
    },
    created() {
        let t= this;
        t.init().then(r=>{
            t.getVendors(0);
            t.generarSchelude();
            localStorage.removeItem('duplicateItem');
        });
    },
    methods: {
        init(){
            let t= this;
            return new Promise((resolve, reject) => { 
                let data = t.$store.getters['core/getOpenModal'].data;
                t.modal.action = data.action;
                t.showVendor = (data.cellVendor) ? data.cellVendor.visible : false;
                if(data.action=='duplicate')
                {
                    localStorage.setItem('duplicateItem', JSON.stringify(data.item));
                    t.$store.commit("core/setLoadingModal", true);
                    if(t.modal.action=='add'){
                        t.title = "New Task";
                    }else{
                        t.title = "Duplicate Task";
                    }
                    window.billing
                    .post("getActionsTask", {
                        task_id: data.item,
                        user_idd: window.master_client,
                        user_id: window.localorgdata,
                        org_id: window.localorgdata,
                        oauth_m: window.oauth_m,
                    })
                    .then((response) => {
                        let item = JSON.parse(localStorage.getItem('duplicateItem'));
                        t.f=item;
                        t.f.actions = response.data.r;
                        t.f.user_idd= window.master_client;
                        t.f.user_id= window.localorgdata;
                        t.f.org_id= window.localorgdata;
                        t.f.oauth_m= window.oauth_m;
                        t.f.due_date= new Date().setHours(16, 0, 0, 0);    
                        console.log('duplicate', t.f);
                        t.$store.commit("core/setLoadingModal", false);
                        resolve(true);
                    })
                    .catch((error) => {
                        console.log(error);
                        t.$store.commit("core/setLoadingModal", false);
                        resolve(false);
                    });
                }else{
                    resolve(true);
                }
            });
        },
        formSave() {  
            let t = this;
            t.$store.commit("core/setLoadingModal", true);
            t.f.due_date = new Date(t.f.due_date).toISOString();
            window.billing
            .post("addTask", t.f)
            .then((response) => {
                console.log('addTask', response.data);
                t.$store.commit("core/setLoadingModal", false);
                t.$parent.btnAdd = false;
                t.cancle();
            })
            .catch((error) => {
            console.log(error);
                t.$parent.btnAdd = false;
                t.$store.commit("core/setLoadingModal", false);
            });
        },
        newAction(){
            let t= this;
            let newItem = {accion_name: '',accion_descrip: ''};
            t.f.actions.push(newItem);
        },
        deleteAction(idx){            
            let t= this;
            t.f.actions.splice(idx, 1);
        },
        cancle() {
            let t = this;
            t.title= 'New Task';
            t.f= {
                id: 0,
                user_idd: window.master_client,
                user_id: window.localorgdata,
                org_id: window.localorgdata,
				oauth_m: window.oauth_m,
                task_name: '',
                vendor_id: '0',
                due_date: new Date().setHours(16, 0, 0, 0),
                date_schedule: 0,
                interval: 1,
                rec: 1,
                actions: [{
                    name: '',
                    descrip: '',
                }],
            };
            t.$parent.close()  
        },
        getVendors(load) {
            var t = this;
            this.isLoading = true;
            window.billing
            .get("vendors/" + window.localuserdata)
            .then((response) => {
                if (response.data.success) {
                    let Vendor = [];
                    for (let item in response.data.result) {
                        let t = response.data.result[item].company_name;
                        let n = response.data.result[item].firstname;
                        if (n !== null && n !== "") {
                            t = t + " - " + n;
                        }
                        Vendor.push({
                            value: response.data.result[item].id,
                            text: t,
                        });
                    }
                    t.VendorList = Vendor;
                }
                t.isLoading = false;
                if(load==1){
                    this.$store.commit("core/setLoadingModal", false);
                }
            })
            .catch((error) => console.log(error));
        },        
        addVendorNew() {
            this.title = "Add Vendor";
            this.modal.vendor = true;
            this.addVendor.company_name = null;
        },
        cancleVendor() {
            if(this.modal.action=='add'){
                this.title = "New Task";
            }else{
                this.title = "Duplicate Task";
            }
            this.modal.vendor = false;
            this.addVendor.company_name = null;
        },
        saveVendor() {
            let t = this;
            t.$store.commit("core/setLoadingModal", true);
            window.billing
            .post("expenses/vendor/add", {
                user_id: window.localuserdata,
                company_name: t.addVendor.company_name,
            })
            .then((response) => {
                t.f.vendor_id = response.data.id;
                t.getVendors(1);
                t.cancleVendor();
            })
            .catch((error) => {
                t.$store.commit("core/setLoadingModal", false);
                console.log(error)
            });
        },
        dueDateOpen(){
            this.dateSelected=this.f.due_date;
            this.date_old =this.f.due_date;
            this.openCal=true;
        }, 
        notBeforeToday(date, currentValue) {
            if(currentValue==null || currentValue.length==0){currentValue=[event.currentTarget.getAttribute('data-value')]}
            //console.log('notBeforeToday', currentValue[0])
            return date < new Date().setHours(0, 0, 0, 0);
        },
        toggleTimePanel() {
            this.showTimePanel = !this.showTimePanel;
        },
        changeDate(date) {
            this.resetCal = false;
            this.generarSchelude();
            //this.showTimePanel = true;
        },
        handleOpenChange() {
            this.showTimePanel = false;
        },
        onResetCal(){
            this.f.due_date = this.date_old;
            this.resetCal = true;
            this.closeDatePicker();
        },
        closeDatePicker() {
            this.openCal = false;
            this.showTimePanel = false;
        },
        generarSchelude(){
            let t = this; 
            let diff = t.calcDiasDiff(new Date().toISOString(), t.f.due_date);
            t.daysBefore = [0];
            if(diff>0){
                for (var i = 1; i <= diff; i++) {
                    t.daysBefore.push(i);
                }
            }
            console.log('daysBefore', t.daysBefore);
        },
        calcDiasDiff(date1, date2){
            let dias = 0;
            //opcion nativa 
            var fechaInicio = new Date(date1).getTime();
            var fechaFin = new Date(date2).getTime();
            var diff = fechaFin - fechaInicio;
            //opcion con moment
            var fecha1 = moment(date1);
            var fecha2 = moment(fechaFin);
            dias = fecha2.diff(fecha1, 'days');
            if(Math.round(diff/(1000*60*60))>23){dias++;}
            console.log('diff horas nativo ', diff/(1000*60*60) +'=>'+ Math.round(diff/(1000*60*60)))
            console.log(fecha2.diff(fecha1, 'days'), ' dias de diferencia');
            console.log(fecha2.diff(fecha1, 'hours'), ' hours de diferencia');
            return dias;
            // Math.round(diff/(1000*60*60*24)) (1000*60*60*24) --> milisegundos -> segundos -> minutos -> horas -> días
        },
        masOmenosDias(fecha, dias, op){
            fecha = new Date(fecha);
            if(op=='-'){
                fecha.setDate(fecha.getDate() - dias);
            }else{
                fecha.setDate(fecha.getDate() + dias);
            }
            return fecha;
        },
        activeRec(){
            this.f.rec = (this.f.rec==1) ? 0 : 1;
            console.log('rec', this.f.rec)
        },
    },
    watch:{
        "f.due_date": function(){
            if(this.f.due_date==null){
                this.f.due_date = new Date().setHours(16, 0, 0, 0);
            }
        },
    },
    computed: {
        //...mapGetters("ModApps/jobs", ["get_changeAssignUser"]),
        //...mapGetters("core/apps", { getAppIDactual: "getAppIDactual" }),
        date_prev: function(){
            return this.masOmenosDias(this.f.due_date, this.f.date_schedule, '-')
        }
    },
};
</script>
<style lang="scss" scoped>
    /*INICIO DEL SWICHT*/
    .btn-switch{
        display: inline-block;
        float: right;
    }
    .onoffswitch {
    position: relative; 
    width: 60px;
    -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
    }
    .onoffswitch-checkbox {
        display: none;
    }
    .onoffswitch-label {
        display: block; 
        overflow: hidden; 
        cursor: pointer;
        border: 1px solid #585858; 
        border-radius: 20px;
    }
    .onoffswitch-inner {
        display: block; 
        width: 200%; 
        margin-left: -100%;
        transition: margin 0.1s ease-in 0s;
    }
    .onoffswitch-inner:before, 
    .onoffswitch-inner:after {
        display: block; 
        float: left; 
        width: 50%; 
        height: 20px; 
        padding: 0; 
        line-height: 20px;
        font-size: 12px; 
        color: white; 
        font-family: Trebuchet, Arial, sans-serif; 
        font-weight: bold;
        box-sizing: border-box;
    }
    .onoffswitch-inner:before {
        content: "Yes";
        padding-left: 10px;
        background-color: #489a38;
        color: #FFFFFF;
    }
    .onoffswitch-inner:after {
        content: "No";
        padding-right: 10px;
        background-color: #d65353; 
        color: #fff;
        text-align: right;
    }
    .onoffswitch-switch {
        display: block; 
        width: 15px; 
        height: 15px;
        margin: 6px;
        background: #fff;
        position: absolute; 
        top: -3px; 
        bottom: 0;
        right: 33px;
        border: 1px solid #585858; 
        border-radius: 20px;
        transition: all 0.1s ease-in 0s; 
    }
    .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
        margin-left: 0;
    }
    .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
        right: 0px; 
    }
    /*FIN DEL SWICHT*/
    .form-group{
        position: relative;
        margin-bottom: 5px;
    }    
    .tooltip {
        background-color: #333;
        font-size: 12px;
        padding: 5px 10px;
        color: #fff;
        position: absolute;
        left: 50%;
        top: 100%;
        margin-top: 5px;
        transform: translateX(-50%);
        width: auto;
        z-index: 15;
        border-radius: 2px;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease-in-out;
        &:before {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 7.5px 6px 7.5px;
            border-color: transparent transparent #333 transparent;
            position: absolute;
            left: 50%;
            margin-left: -7.5px;
            top: -6px;
        }
    }
    .delAction{
        position: absolute;
        right: 10px;
        bottom: 0px;
        &.btn-danger{
            padding: 5px 10px;
            &:hover {
                .tooltip {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
    .row_hr{
        border-bottom:1px dashed #ddd;
        margin-bottom: 5px;
        padding-bottom: 10px;
    }
    </style>
