<template>
    <div>
        <slot>
            <div class="page-breadcrumb">
                <div class="row">
                    <div class="col-md-8 align-self-center">
                        <h4 class="page-title">Setting Invoice Void</h4>
                    </div>
                    <div class="col-md-4 align-self-center">
                        <div class="d-flex align-items-right justify-content-end">
                            <router-link :to="{ name: 'Setting' }" class="btn btn-info btn-sm mr-2 ctrlbtn" style="margin-bottom: 10px;width: -webkit-fill-available;">
                                <i class="fas fa-arrow-left"></i>
                                Back
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-8">
                        <form @submit.prevent="setInvoMsgVoid">
                            <div class="card">
                                <div class="card-header">Email Message Void</div>
                                <div class="card-body">
                                    <div class="bgloading" v-if="form.isLoading">
                                        <Loader></Loader>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 ctrlbtnShow">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <router-link
                                                        :to="{ name: 'Setting' }"
                                                        class="btn btn-primary btn-sm mr-2 float-right ctrlbtn"
                                                        style="margin-bottom: 10px;width: -webkit-fill-available;"
                                                    >
                                                        CANCEL</router-link
                                                    >
                                                </div>
                                                <div class="col-md-6">
                                                    <button
                                                        class="btn btn-success btn-sm mr-2 float-right ctrlbtn"
                                                        style="margin-bottom: 10px;width: -webkit-fill-available;"
                                                        type="submit"
                                                    >
                                                        <i class="fas fa-save"></i> SAVE
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 ctrlbtnHiden">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <router-link
                                                        :to="{ name: 'Setting' }"
                                                        class="btn btn-primary btn-sm mr-2 float-right"
                                                    >
                                                        CANCEL</router-link
                                                    >
                                                    <button
                                                        class="btn btn-success btn-sm mr-2 float-right"
                                                        type="submit"
                                                    >
                                                        <i class="fas fa-save"></i> SAVE
                                                    </button>
                                                </div>
                                            </div>
                                        </div>                                        
                                    </div>                                    
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="row">
                                                <label
                                                    for=""
                                                    class="col-md-2 col-form-label"
                                                    >Message</label
                                                >
                                                <div class="col-md-10">
                                                    <span class="control-label">
                                                        <b>INVOICE VOID</b>
                                                        <button
                                                            type="button"
                                                            class="btn btn-secondary btn-sm"
                                                            @click="DefaultMessage"
                                                            style="
                                                                margin-left: 10px;
                                                                border-radius: 5px;
                                                            "
                                                        >
                                                            Use Standard Message
                                                        </button>
                                                    </span>
                                                </div>
                                                <label
                                                    for=""
                                                    class="col-md-2 col-form-label"
                                                    >Subject:</label
                                                >
                                                <div class="col-md-10">
                                                    <input
                                                        type="text"
                                                        class="form-control"
                                                        v-model="form.messagesubjet"
                                                    />
                                                </div>
                                                <label
                                                    for=""
                                                    class="col-md-2 col-form-label"
                                                    >Message:</label
                                                >
                                                <div class="col-md-10">
                                                    <textarea
                                                        class="form-control"
                                                        id=""
                                                        rows="10"
                                                        v-model="form.messagetext"
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-md-2"></div>
                </div>
            </div>
        </slot>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader";
export default {
    name: "EmailMessageInvVoid",
    components: {
        Loader,
    },
    data() {
        return {
            form: {
                isLoading: false,
                user_id: window.localuserdata,
                action: "Add",
                messagesubjet: null,
                messagetext: null,
            },
            com_company_name: "",
            Default: {
                Subject: null,
                Message: null,
            },
        };
    },
    created() {
        this.getCompanyInfo();
        this.getInvoMsgVoid();
    },

    methods: {
        getCompanyInfo() {
            this.form.isLoading = true;
            window.billing
                .get("companyinfo/" + window.localuserdata)
                .then((response) => {
                    this.com_company_name = response.data.com_company_name;
                })
                .catch((error) => console.log(error));
        },
        DefaultMessage() {
            this.Default.Subject = "Voided Invoice from %CUSTOMER%";
            this.Default.Message = "Your invoice was void. \n\n";
            this.Default.Message +=
                "Thank you for your business - we appreciate it very much.\n\n\n";
            this.Default.Message += "Sincerely,\n%COMPANY_NAME%";
            this.form.messagesubjet = this.Default.Subject;
            this.form.messagetext = this.Default.Message;
        },
        getInvoMsgVoid() {
            this.form.isLoading = true;
            window.billing
                .get("msgvoid/" + window.localuserdata)
                .then((response) => {
                    console.log(response.data);
                    if (!response.data.status) {
                        this.DefaultMessage();
                    } else {
                        this.form.messagesubjet = response.data.msg.messagesubjet;
                        this.form.messagetext = response.data.msg.messagetext;
                        this.form.action = "Update";
                    }
                    this.form.isLoading = false;
                })
                .catch((error) => console.log(error));
        },
        setInvoMsgVoid() {
            this.form.isLoading = true;
            let url = "addmsgvoid";
            if (this.form.action == "Update") {
                url = "updmsgvoid";
            }
            window.billing
                .post(url, {
                    user_id: this.form.user_id,
                    messagesubjet: this.form.messagesubjet,
                    messagetext: this.form.messagetext,
                })
                .then((response) => {
                    console.log(response.data);
                    if (this.form.action == "Add") {
                        this.$notify({
                            group: "noti",
                            title: "Created",
                            type: "success",
                            text: "Created Message Successful",
                        });
                        if (response.data.id !== null) {
                            this.form.action = "Update";
                        }
                    } else {
                        this.form.action = "Update";
                        this.$notify({
                            group: "noti",
                            title: "Updated",
                            type: "success",
                            text: "Updated Setting Successful",
                        });
                    }
                    this.form.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.form.isLoading = false;
                });
        },
    },
};
</script>
<style lang="scss" scoped>
@media (min-width: 1279px) {
    .ctrlbtn {
        width:100px !important;
    }
    .ctrlbtnShow {
        display: none !important;
    }    
}
@media (max-width: 1281px) {
    .ctrlbtnHiden {
        display: none !important;
    }    
}
</style>
