<template>
	<div class="form-search">
		<form @submit.prevent="searchCustomer" class="app-search position-absolute">
			<input
				type="text"
				class="form-control"
				placeholder="Search Client or Business"
				autofocus="autofocus"
				v-model="TextSearch"
				:disabled="countTotal"
			/>
				<a class="srh-btn">
				<i class="fas fa-search"></i>
			</a>
		</form>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
	name: 'searchList',
	components: {
	},
	data() {
		return {
			TextSearch: '',
			countTotal: true,
		};
	},
	created() {
	},
	mounted() {
	},
	directives: {
	},
	methods: {
		searchCustomer(){
			let t = this;
			let searchOld = t.$store.getters['ModApps/billing/getCustomerSearch'];
			if(searchOld.length > 0 && searchOld == t.TextSearch){
				setTimeout(function(){
					console.log('misma busqueda, reinicar valor', searchOld);
					t.$store.commit('ModApps/billing/setCustomerSearchActive', false);
					t.$store.commit('ModApps/billing/setCustomerSearch', '');
					setTimeout(function(){
						console.log('enviando busqueda', t.TextSearch);
						t.$store.commit('ModApps/billing/setCustomerSearchActive', true);
						t.$store.commit('ModApps/billing/setCustomerSearch', t.TextSearch);
					}, 200);
				}, 200);
			}
			else{
				console.log('nueva busqueda', t.TextSearch);
				t.$store.commit('ModApps/billing/setCustomerSearchActive', true);
				t.$store.commit('ModApps/billing/setCustomerSearch', t.TextSearch);
			}			
		}
	},
	computed: {
		...mapGetters("ModApps/billing", { 
			getCustomerCountTotal: "getCustomerCountTotal",
		}),
	},
	watch: {
		TextSearch: function () {
			// this.$store.commit('ModApps/billing/setCustomerSearch', this.TextSearch);
		},
		getCustomerCountTotal: function () {
			if(this.getCustomerCountTotal != null){
				this.countTotal = false;
			}
		},
	},
};
</script>
<style lang="css" scoped>
	.search-box .app-search .form-control:disabled {
		background-color: #e9ecef;
    	opacity: 1;
    	box-shadow: none !important;
	}
</style>
