<template>
	<div>
		<div class="card-header text-center" style="font-size: 20px;">
            Plan
        </div>
		<form @submit.prevent="form" class="card-body text-center">
			<div class="row">
				<div class="col-md-12">
					<label>Select an plan</label>
					<select class="form-control" v-model="f.planes">
						<option value="0" >--Select Plan--</option>
						<option 
							v-for="(i, index) in plan" 
							:key="index"
							:value="i.id"
						>{{i.name}}</option>
					</select>
				</div>
				<div class="col-md-3">
					<br>
					<button type="submit" class="btn btn-success" style="width: 100%;" :disabled="submit">
						Save
					</button>
				</div>
			</div>
		</form>
	</div>
</template>
<script>
export default {
	name: 'Plan',
	data () {
		return {
            f:{
                planes:0,
			    user_id: 0,
			    org_id: 0,
            },
			plan: [],
			submit: false,
		}
	},
	created() {
		let dataOrg = this.$store.getters['core/getOpenModal'].data;
		this.f.user_id = dataOrg.user_id;
		this.f.org_id = dataOrg.id;
		this.f.planes = dataOrg.info.plan_id;
		this.getPlan();
	},
	methods: {
		getPlan(){
			let t = this;
			t.$store.commit("core/setLoadingModal", true);
			let url = '/getPlan';
			window.reselleR2.get(url)
            .then((response) => {
                t.plan = response.data.r;
                console.log(t.plan);
                t.$store.commit("core/setLoadingModal", false);
            })
            .catch((error) => {
                console.log(error);
				t.$store.commit("core/setLoadingModal", false);
            });
		},
		form(){
			if (this.f.planes == 0) {
				this.$store.commit("core/setNotiPush", {
					group: "notiPush",
					type: '3', // 1=success|2=info|3=error|4=loading|5=warning
					title: "Error",
					text: "Select an plan.",
					show: true,
					duration: 10000
				});				
				return false	
			}
			let t = this;
			t.$store.commit("core/setLoadingModal", true);
			let url = '/formPlan';
			window.reselleR2.post(url, t.f)
            .then((response) => {
                t.plan = response.data.r;
                console.log(t.plan);
				this.$store.commit("core/setNotiPush", {
					group: "notiPush",
					type: '1', // 1=success|2=info|3=error|4=loading|5=warning
					title: "Saved",
					text: "Successful.",
					show: true,
					duration: 5000
				});				
                t.$store.commit("core/setLoadingModal", false);
				t.$store.commit("core/setCloseModal", {action: 'saved', method: 'refresh-editOrg'}); 
            })
            .catch((error) => {
                console.log(error);
				this.$store.commit("core/setNotiPush", {
					group: "notiPush",
					type: '3', // 1=success|2=info|3=error|4=loading|5=warning
					title: "Error",
					text: "updating data.",
					show: true,
					duration: 10000
				});				
				t.$store.commit("core/setLoadingModal", false);
            });			
		},
	}
};
</script>
<style lang="css" scoped>
h5.titulo_install{
	margin-top: 10px;
	font-weight:bold;
	font-size: 24px;
}
.media-body p {
	font-size: 19px;
	padding: 10px 60px;
}
.iconapp{
	width: 150px;
	height:auto;
}
</style>
