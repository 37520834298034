export default {
	appAll : [],
	appIDactual: localStorage.getItem('appIDactual') || 0,   
	modulos: [],
	listAvailableApps: [],
	appRolUser: [],
	adminApp: false,
	planIdApp: localStorage.getItem('planIdApp') || 1, // standard
	modulesMenu: (localStorage.getItem('modulesMenu')!=null) ? JSON.parse(localStorage.getItem('modulesMenu')) : [], // menu de plan
}
