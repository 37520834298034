let setCustomInvo = function(d) {
	let t = this;
	for (let i in t.listTaks) {
		if (t.listTaks[i]) {
			if (t.listTaks[i].id_i == d.job) {
				t.listTaks[i].id_customer = d.id_customer;
				t.listTaks[i].id_invoice = d.id_invoice;
				for (let e in t.listCustomer) {
					if (t.listCustomer[e].id == d.id_customer) {
						let businessname = t.listCustomer[e].bussinesname
						let nameCustom = t.listCustomer[e].firstname; 
						nameCustom+=" " + t.listCustomer[e].lastname;
						t.listTaks[i].customer_name = nameCustom;
						t.listTaks[i].businessname = businessname;
						console.log('customer', nameCustom, businessname)
						if (businessname==null || businessname=="") {
							t.listTaks[i].businessname = t.listTaks[i].customer_name;
						}
						t.listTaks[i].cus = t.listCustomer[e];
						let listInvo = t.listCustomer[e].invo;
						if (d.id_invoice > 0) {
							t.listTaks[i].showInvo = 1;
							for (let j = 0; j <= listInvo.length; j++) {
								if (listInvo[j] && listInvo[j].id == d.id_invoice) {
									t.listTaks[i].invoice_name = listInvo[j].numdoc;
									console.log('invo', t.listTaks[i].invoice_name)
								}
							}
						} else {
							t.listTaks[i].showInvo = 0;
							t.listTaks[i].invoice_name = "";
						}
					}
				}
			}
		}
	}
	t.reEscribirLista();
};
export default setCustomInvo;
