<template>
    <div>
        <div class="card-header window-header row">
            <div class="col-12 d-flex justify-content-start">
                <h3>Create Mini Site</h3>
            </div>
        </div>
        <form @submit.prevent="saveForm" id="form1" class="window-body">
            <div class="card-body">
                <div class="row" >
                    <div class="col-12" v-if="isLoading"><Loader></Loader></div>
                    <div class="col-12" v-else>
                        <div class="input-group">
                            <input type="text" class="form-control" maxlength="30" v-model="name" required placeholder="Name mini site" style="border-top-right-radius: 0px; border-bottom-right-radius: 0px;"/>
                            <span class="input-group-text" style="padding:0px;">
                                <button type="submit"
                                class="btn btn-success"
                                style="border-top-left-radius: 0px; border-bottom-left-radius: 0px;"
                                :disabled="isExistSlug">Save</button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader.vue";
export default {
    name: "formAddMiniSite",
    components: {
        Loader,
    },
    data() {
        return {
            isLoading: false,
            isExistSlug: false,
            apis: null,
            name: null,
            defaultMiniSite: [
                {
                    columns: [
                        {
                            elemts: [
                                {
                                    widget: 'text', // or image, link
                                    text: "<h2>Lorem ipsum dolor sit amet.</h2><p>Laudantium esse voluptate possimus libero placeat. Quae facilis ex totam doloremque eligendi qui, quaerat sequi. Accusantium quisquam hic iusto quo id quaerat.</p>",
                                    url:'',
                                }
                            ]
                        },
                    ]
                },
            ],
        };
    },
    created() {
        let urlApis = this.$store.getters['ModApps/Marketea/urlApis'];
        this.apis = urlApis.bSite;
    },
    methods: {
        saveForm(){
            let t = this;
            let url = t.apis.set(12); //setMiniSite
            if(!t.isExistSlug){
                t.isLoading = true;
                window.master.post(url, {
                    user_id: window.master_client,
                    org_id: window.localorgdata,
                    name: t.name,
                    html_value: JSON.stringify(t.defaultMiniSite),
                    pusher_id: pusher.connection.socket_id
                })
                .then((response) => {
                    console.log(url, response.data);
                    if(response.data.status){
                        t.$emit('refreshList', 1)
                        t.$parent.close();
                    }
                    t.isLoading = false;
                }).catch((error) => {
                    console.log(error);
                    t.isLoading = false;
                });
            }
        },
    },
    computed: {
    },
};
</script>

<style lang="scss" scoped>
.window-header{cursor: move;}
</style>
