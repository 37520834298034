let getExtrasYSettingAll = function() {
    let t = this;
    return new Promise((resolve, reject) => {
        let url = this.apis.getExtrasYSettingAll()
        window.master
            .post(url, t.f)
            .then((response) => {
                let colors = response.data.color;
                t.colorList = colors;
                let r = response.data.setting;
                for (const i in r) {
                    switch (r[i].meta_key) {
                        case "show_customer_name":
                            t.show_customer_name = r[i].meta_value;
                            break;
                        case "show_customer_notes":
                            t.show_customer_notes = r[i].meta_value;
                            break;
                        case "show_invoice":
                            t.show_invoice = r[i].meta_value;
                            break;
                        case "privilegio_account":
                            t.typez = r[i].meta_value;
                            if (t.typez > 0) {
                                t.privilegies_dash = t.typez;
                            }
                            break;
                        case "tagsDefault":
                            if(!t.$store.getters["ModApps/jobs/getSeeTagsDefault"]){
                                t.tagsDefaulttagsID = [];
                                if(r[i].meta_value){
                                    t.tagsDefaulttagsID = r[i].meta_value.split(',');
                                }
                            }
                            break;
                    }
                }
                resolve(true);
            })
            .catch((error) => {
                console.log(error);
                reject(false);
            });
    });
};
export default getExtrasYSettingAll;
