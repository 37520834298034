<template>
	<div class="card">
        <form @submit.prevent="salesSetContract">
            <div class="card-header">
				<div class="row">
					<div class="col-5 text-left" v-if="contract_status==null">
						<button type="submit" class="btn btn-success btn-lg">
							<i class="fas fa-save fa-lg" style="margin-right: 10px;"></i> Create Contract
						</button>
					</div>
					<div class="col-7 text-left">
                        <button type="button" class="btn btn-success btn-lg" @click="openPdf()">
                            <i class="fas fa-expand-arrows-alt fa-lg" style="margin-right: 10px;"></i> Open Full Screen
						</button>
					</div>
				</div>
            </div>
            <div class="card-body scopeOfWork">
				<div class="row">
                    <div class="col-12" v-if="contract_status!=null">
                        <nav>
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item" v-if="contract_status>=1"><span class="badge badge-secondary">Created</span></li>
                                <li class="breadcrumb-item" v-if="count_sent_sms==0"><span class="badge badge-primary">Pending</span></li>
                                <li class="breadcrumb-item" v-else><span class="badge badge-info">Sent <span class="bg-count">{{ count_sent_sms }}</span></span></li>
                                <li class="breadcrumb-item" v-if="count_sent_sms>0 && contract_status==1"><span class="badge badge-warning">Unsigned</span></li>
                                <li class="breadcrumb-item" v-if="count_sent_sms>0 && contract_status==2"><span class="badge badge-success">Singed</span></li>
                                <li class="breadcrumb-item" v-if="count_sent_sms>0 && contract_status==3"><span class="badge badge-danger">Reject</span></li>
                                <li class="breadcrumb-item" v-if="count_sent_sms>0 && contract_status==4"><span class="badge badge-danger">Void</span></li>
                                <li class="breadcrumb-item" v-if="count_sent_sms>0 && contract_status==5"><span class="badge badge-danger">removed</span></li>
                                <li class="breadcrumb-item" v-if="count_sent_sms>0 && contract_status==0"><span class="badge badge-danger">Deleted</span></li>
                                <!-- 1=peding, 2 singature, 3 reject, 4 void, 5 remove, 0 delete, -->
                            </ol>
                        </nav>
                    </div>
                    <!-- <div class="col-12">
                        <div class="input-group mb-3">
                            <label style="margin-right:10px; padding-top: 10px;">Estimated <b>Cost</b> of the Work:</label>
                            <input type="text" class="form-control" placeholder="Amount: 0000,00 (Required)" required v-model="f.cost_work"/>
                        </div>
                    </div> -->
                    <div class="col-12" v-if="pdfsrc">
                    <iframe :src="pdfsrc" style="width: 100%;height: 64vh; border: none;">
                        Oops! an error has occurred.
                    </iframe>
                </div>
				</div>
            </div>
        </form>
	</div>
</template>
<script>
export default {
	name: 'SalesContract',
	components: {
	},
	data () {
		return {
			f: {
				user_id: window.master_client,
                org_id: window.localorgdata,
				id: 0,
				sales_id: 0,
				cost_work: '1',
                tab: 2,
			},
            pdfsrc: 'https://m.marketea.app/api/sales/demoPdf',
            urlSale: 'salesGenerateContract',
            contract_status: null,
            count_sent_sms: null,
		}
	},
	created(){
		this.init();
	},
	methods:{
		init(){
			let t = this;
            t.$store.commit("core/setLoadingModal", false);
			let d = t.$store.getters['core/getOpenModal'].data;
			t.f.sales_id= d.id;
			if(d.contract>0){
                t.f.id= d.contract_id;
                t.contract_status= d.contract_status;
                t.count_sent_sms= d.count_sent_sms;
                t.salesGetContract();
                t.url = 'salesSetContract';
            }
		},
		salesGetContract(){
			let t = this;
			t.$store.commit("core/setLoadingModal", true);
			window.master
            .post('salesGetContract', t.f)
            .then((response) => {
                console.log('salesGetContract', response.data)
                if(response.data.status && response.data.r){
                    t.f.cost_work=response.data.r.cost_work;
                }
                t.salesPreviewContract();
            })
            .catch((error) => {
                console.log(error);
                t.$store.commit("core/setLoadingModal", false);
            });
		},
		salesPreviewContract(){
			let t = this;
			window.master
            .get('salesPreviewContract/'+ t.f.user_id +'/'+ t.f.org_id +'/'+ t.f.sales_id)
            .then((response) => {
                console.log('salesPreviewContract', true)
                t.$store.commit("core/setLoadingModal", false);
            })
            .catch((error) => {
                console.log(error);
                t.$store.commit("core/setLoadingModal", false);
            });
		},
		salesSetContract(){
			let t = this;
			t.$store.commit("core/setLoadingModal", true);
            console.log(t.urlSale, t.f)
			window.master.post(t.urlSale, t.f)
            .then((response) => {
                console.log('response', response.data);
                t.$store.commit("core/setLoadingModal", false);
                t.$notify({
                    group: "noti",
                    type: "success",
                    title: "Successfully saved",
                    // text: "Scope of Work",
                });
                t.$parent.close();
            })
            .catch((error) => {
                console.log(error);
                t.$store.commit("core/setLoadingModal", false);
            });
		},
        openPdf() {
			let t = this;
            let urlPdf = window.urlmaster + 'api/salesPreviewContract/'+ t.f.user_id +'/'+ t.f.org_id +'/'+ t.f.sales_id;
            console.log('open', urlPdf);
            window.open(urlPdf, "_blank");
        },
	}
}
</script>
<style lang="css" scoped>
.breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: ">";
}
li.breadcrumb-item span.badge {
    font-size: 1rem;
}

li.breadcrumb-item span.badge span.bg-count {
    margin-left: 5px;
    background: #fff;
    color: #000;
    padding: 1px 5px;
    border-radius: 4px;
    font-size: 12px;
}
</style>
