<template>
	<div>
		<div class="card">
			<div class="card-header">Add Vendor</div>
			<div class="card-body">
				<form @submit.prevent="saveVendor">
					<div class="form-group">
						<label for="inputCity">Company Name</label>
						<input
							type="text"
							class="form-control"
							v-model="addVendor.company_name"
							required
						/>
					</div>
					<div class="row">
						<div class="col-12 text-right">
							<button type="submit" class="btn btn-success">
								<i class="fas fa-save"></i> Save
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	components: {},
	name: "formAddVendor",
	data () {
		return {
            addVendor: {
                company_name: null,
            },					
		}
	},
	created() {
		this.init()
	},
	methods: {
        init() {
            let g = this.$store.getters['core/getOpenModal'];
            let r = g.data;			     
        },
        saveVendor() {
            this.isLoading = true;
            window.billing
                .post("expenses/vendor/add", {
                    user_id: window.localuserdata,
                    company_name: this.addVendor.company_name,
                })
                .then((response) => {
                    this.isLoading = false;
                    this.$notify({
                        group: "noti",
                        title: "Created",
                        type: "success",
                        text: "Vendor Created successfully",
                    });
					let t = this
					this.$store.commit("core/setLoadingModal", false);
					this.$store.commit("core/setCloseModal", {action: 'saved'});
					this.$store.commit("core/setActionRefresh", {
						action: 'saved',
						data: {
							value: response.data.id,
							text: t.addVendor.company_name,
							methods:'vendor'
						}
					});	
					this.addVendor.company_name = ""	
                })
                .catch((error) => console.log(error));
        },				                       
    },
    computed: {

    },	
}
</script>
<style lang="css" scoped></style>
