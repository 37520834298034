//Slabs
import SlabsLayout from '@/apps/slabs/Layouts/SlabsLayout.vue'
import SlabsDashboard from '@/apps/slabs/views/Dashboard.vue'
import SlabsLeads from '@/apps/slabs/views/Leads.vue'
import SlabsSlabs from '@/apps/slabs/views/Slabs.vue'
import SlabsSlabsNew from '@/apps/slabs/views/SlabsNew.vue'
import SlabsGallery from '@/apps/slabs/views/Gallery.vue'
import SlabsRemanents from '@/apps/slabs/views/remanents/list.vue'
import SlabsRemanentsNew from '@/apps/slabs/views/remanents/add.vue'
import SlabsRemanentsGallery from '@/apps/slabs/views/remanents/gallery.vue'
import SlabsSetting from '@/apps/slabs/views/Setting.vue'
export default [
    {
        path: '/slabs',
        folder: SlabsLayout,
        meta: {requiresAuth: true},
        children: [
            {
                path: '/',
                name:'slabs',
                folder: SlabsDashboard
            },
            {
                path: 'leads',
                name:'SlabsLeads',
                folder: SlabsLeads
            },
            {
                path: 'slabs',
                name:'SlabsSlabs',
                folder: SlabsSlabs
            },
            {
                path: 'slabs/new',
                name:'SlabsSlabsNew',
                folder: SlabsSlabsNew
            },
            {
                path: 'slabs/:edit/:id',
                name:'SlabsSlabsEdit',
                folder: SlabsSlabsNew
            },
            {
                path: 'slabs/gallery/list/:material_id',
                name:'SlabsGallery',
                folder: SlabsGallery
            },
            {
                path: 'remanents',
                name:'SlabsRemanents',
                folder: SlabsRemanents
            },
            {
                path: 'remanents/new',
                name:'SlabsRemanentsNew',
                folder: SlabsRemanentsNew
            },
            {
                path: 'remanents/:edit/:id',
                name:'SlabsRemanentsEdit',
                folder: SlabsRemanentsNew
            },
            {
                path: 'remanents/gallery/list/:material_id',
                name:'SlabsRemanentsGallery',
                folder: SlabsRemanentsGallery
            },
            {
                path: 'setting',
                name:'SlabsSetting',
                folder: SlabsSetting
            },
        ]
    },
]
