<template>
    <div>
        <form @submit.prevent="setZip" id="form1" class="" v-if="formZip">
            <div class="card-body">
                <div class="bgloading" v-show="isLoading"><Loader></Loader></div>
                <div class="form-group">
                    <label for="exampleFormControlInput1">Zip Code: </label>
                    <input
                        type="text"
                        class="form-control"
                        v-model="f.zipcode"
                        required="true"
                        maxlength="5"
                    />
                </div>
                <div class="form-group">
                    <label for="exampleFormControlInput1">City: </label>
                    <input
                        type="text"
                        class="form-control"
                        v-model="f.name"
                    />
                </div>
                <div class="form-group">
                    <label for="exampleFormControlInput1">State: </label>
                    <input
                        type="text"
                        class="form-control"
                        v-model="f.initial"
                    />
                </div>
            </div>
            <div class="card-footer d-flex justify-content-end">
                <button type="button" class="btn btn-secondary btn-sm mr-2"  @click="cancel()">Cancel</button>
                <button type="submit" class="btn btn-success btn-sm">SAVE</button>
            </div>
        </form>
        <div class="card-body" v-else>
          <div class="row">
            <div class="col-md-8">
              <button type="button" class="btn btn-primary" @click="add()">Add</button>
            </div>
            <div class="col-md-4 text-right" style="display: flex;
    align-content: center;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center">    
              <span> Allow any Zip code</span>          
                <div class="onoffswitch">
                  <input
                    id="all"
                    type="checkbox"
                    v-model="allZip"
                    :checked="(allZip==1)"
                    class="onoffswitch-checkbox"
                    @input="setAllZip($event, allZip)"
                  />
                  <label class="onoffswitch-label" for="all">
                    <span class="onoffswitch-inner"></span>
                    <span class="onoffswitch-switch"></span>
                  </label>
                </div>
            </div>
          </div>
          <br><br>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th style="background-color: #fff; width: 100px;">Zip Code</th>
                  <th style="background-color: #fff;">City</th>
                  <th style="background-color: #fff;" width="90px">Actions</th>
                </tr>
              </thead>
              <tbody v-if="isLoading">
                <tr>
                  <td colspan="3" align="center"><Loader></Loader></td>
                </tr>
              </tbody>
              <tbody v-else-if="listZip.length == 0">
                <tr>
                  <td colspan="3" align="center">No record</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr v-for="(i, index) in listZip" :key="index">
                  <td>{{i.zipcode}}</td>
                  <td>{{i.name}} ({{i.initial}})</td>
                  <td width="90px">
                    <button
                      class="btn btn-info btn-sm mr-2"
                      @click="editZip(i)"
                    >
                      <i class="fas fa-edit"></i>
                    </button>
                    <button
                      class="btn btn-danger btn-sm mr-2"
                      @click="DelZip(i)"
                    >
                      <i class="fas fa-times"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import Loader from "@/core/components/shared/Loader.vue";
export default {
  name: 'RoofCalZipCode',
  components: {
    Loader,
  },
  data() {
    return {
      user_id: window.master_client,
      org_id: window.localorgdata,
      formZip: false,
      isLoading: true,
      f: { 
        user_id: window.master_client,
        org_id: window.localorgdata,
        zipcode: '',
        name: '',
        initial: ''
      },
      oldEdit: {
        user_id: window.master_client,
        org_id: window.localorgdata,
        zipcode: '',
        name: '',
        initial: ''
      },
      listZip: [],
      apis: {},
      allZip: 0,
    };
  },
  created() {
    let urlApis = this.$store.getters['ModApps/RoofCal/urlApis'];
    this.apis = urlApis.setup;
    this.getZip();
  },
  methods: {
    setAllZip(e, allZip){
      if (e.target.checked == true) {
        allZip = 1;
      } else {
        allZip = 0;
      }
      window.master.post(this.apis.setAllZip(), {
        user_id: window.master_client,
        org_id: window.localorgdata,
        allZip: allZip,
      })
      .then((response) => {
        console.log('setAllZip', response.data)
      })
      .catch((error) => {
        console.log(error);
      });
    },
    getZip(){
      let t = this;
      t.isLoading = true;
      window.master.post(this.apis.getZip(), {
        user_id: window.master_client,
        org_id: window.localorgdata,
      })
      .then((response) => {
        t.listZip = response.data.r;
        t.allZip = response.data.allZip;
        t.isLoading = false;
        t.formZip = false;
        if(t.listZip.length == 0){
          t.formZip = true;
        }
      })
      .catch((error) => {
        console.log(error);
        t.isLoading = false;
      });
    },
    editZip(i){
      this.resetForm();
      this.formZip =true;
      this.f.id = i.id;
      this.f.name = i.name;
      this.f.initial = i.initial;
      this.f.zipcode = i.zipcode;

      this.oldEdit.id = i.id;
      this.oldEdit.name = i.name;
      this.oldEdit.initial = i.initial;
      this.oldEdit.zipcode = i.zipcode;
    },
    setZip(){
      let t = this;
      t.isLoading = true;
      window.master.post(this.apis.setZip(), t.f)
      .then((response) => {
        t.getZip();
      })
      .catch((error) => {
        console.log(error);
        t.isLoading = false;
      });
    },
    DelZip(i){      
      let t = this;
      t.$swal({
        title: "Delete Item?",
        text: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      }).then((result) => {
        if (result.value) {
          t.isLoading = true;
          window.master.post(this.apis.delZip(), i)
          .then((response) => {
            t.getZip();
          })
          .catch((error) => {
            console.log(error);
            t.isLoading = false;
          });
        }
      });
    },
    add(){
      this.formZip = true;
      this.resetForm();
    },
    resetForm(){
      this.f.id = 0;
      this.f.zipcode = '';
      this.f.name = '';
      this.f.initial = '';

      this.oldEdit.id = 0;
      this.oldEdit.zipcode = '';
      this.oldEdit.name = '';
      this.oldEdit.initial = '';
    },
    cancel(){      
      this.formZip = false;
    },
  }

};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.onoffswitch-inner:before {
    content: "On";
    padding-right: 34px;
    background-color: #5ac146;
    color: #ffffff;
}
</style>
