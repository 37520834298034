let showCols = function(cols) {	
	this.cols =cols.split(',');
	// console.log('showCols', this.cols);
	this.colFoot = this.cols.length;
	let t = this;
	setTimeout(function(){
		if(document.querySelector('.invoList thead tr')){
			t.colFoot = document.querySelector('.invoList thead tr').cells.length;
			console.log('colFoot', t.colFoot)
		}
	}, 1000);
};
export default showCols;
