<template>
	<div class="row">
		<draggable 
			v-model="QuestList"
			tag="div" 
			class="TemplatesList"
			v-bind="dragOptions"
			@change="checkMove"
			@start="isDragging = true"
			@end="isDragging = false"
			handle=".handle" 
			v-if="QuestList.length>0"
        >
			<div 
				class="card border-primary mb-3 items" 
				v-for="(i, index) in QuestList" 
				:key="index"
			>
				<div class="card-header btnmove handle">
					<h4>
						<span 
						class="badge badge-secondary" 
						style="border-radius: 50%;width: 23px;margin-right: 5px;"
						>
							{{index+1}}
						</span> 
						{{i.name}}
					</h4>
					<button 
						type="button" 
						class="btn_edit_box" 
						@click="$parent.openLateral(i)"
					>
						<i class="fas fa-pen"></i>
					</button>
					<button type="button" class="btn_edit_box2" @click="$parent.hideQuest(i.id)"><i class="fas fa-trash-alt"></i></button>
				</div>
				<div class="card-body text-primary" style="padding-top: 5px;">
					<div v-if="i.descrip">
						<p v-if="i.descrip.length>0">{{i.descrip}}</p>
					</div>
					<ul class="list-group list-group-flush">
						<li 
							class="list-group-item" 
							v-for="(a, idx) in i.answers" 
							:key="idx"
						>
						<span v-if="i.multiple==1">
							<i class="far fa-check-square" v-if="(a.check==1)"></i> 
							<i class="far fa-square" v-else></i> 
						</span>
						<span v-else>
							<i class="far fa-dot-circle" v-if="(a.check==1)"></i>
							<i class="far fa-circle" v-else></i>
						</span>
							{{a.name}}
						</li>
					</ul>
				</div>
			</div>
    	</draggable>
    	<div v-else-if="isLoading" class="TemplatesList">
    		<div class="card border-primary mb-3 items" >
				<div class="card-header btnmove handle">
					<h4>
						<span 
						class="badge badge-secondary" 
						style="border-radius: 50%;width: 23px;margin-right: 5px;"
						>0</span> 
						<span class="loadH"><span class="loadingBar"></span></span>
					</h4>
				</div>
				<div class="card-body text-primary" style="padding-top: 5px;">					
					<p><span class="loadH1"><span class="loadingBar"></span></span></p>
					<ul class="list-group list-group-flush">
						<li 
							class="list-group-item" 
							style="padding: 0; color:#000;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;" 
						>
						<span class="loadH2"><span class="loadingBar"></span></span>
						</li>
						<li 
							class="list-group-item" 
							style="padding: 0; color:#000;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;" 
						>
						<span class="loadH2"><span class="loadingBar"></span></span>
						</li>
					</ul>
				</div>
			</div>
    	</div>
    	<div v-else class="text-center"> <h3>No record</h3>	</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'
export default {
	name: 'RoofCalListQuest',
	components: {
		draggable,
	},
	props: {
		list: {
			type: Array, // String, Number, Boolean, Function, Object, Array
			required: true,
			default: () => [],
		},
		load: {
			type: Boolean, // String, Number, Boolean, Function, Object, Array
			required: true,
			default: true,
		},
	},
	data () {
		return {
			QuestList: [],
			isLoading: true,
		}
	},
	created(){
		let t = this;
		t.QuestList=t.$parent.listMain;
	},
	computed: {
		dragOptions() {
			return {
				animation: 200,
				group: "description",
				disabled: false,
				ghostClass: "ghost"
			};
		}
	},
	watch:{
		"$parent.listMain": function(){
			console.log('list', this.$parent.listMain)
			if(this.$parent.listMain.length>0){
				this.QuestList = this.$parent.listMain;
			}
		},
		"load": function(){
			this.isLoading = this.load;
		},
	},
	methods: {
		checkMove(){
			let t = this;
			t.QuestList.map((item, index) => {
				item.orderItem = index + 1
			});
			let compPadre = t.$parent;
			compPadre.checkMoveQuest(t.QuestList);
		},
	},
}
</script>

<style lang="scss" scoped>
.TemplatesList{
	display:flex;
	flex-wrap: wrap;
	.card{
		margin-right:10px;
	}
	.items{
		min-width: 300px;
		max-width: 450px;
		border: 1px solid #C0BBBB !important;
		&:hover{
			.btn_edit_box,.btn_edit_box2{
				display:block;
			}
		}
	}
}
.btnmove{
	&:hover{
		background-color: #e0e0e0;
		cursor: move;
	}
}
.btn_edit_box{
	display:none;
	position:absolute;
	right:0;
	bottom: 0;
	z-index: 10;
	border:1px solid #ccc;
	cursor:pointer;
}
.btn_edit_box2{
	display:none;
	position:absolute;
	right:35px;
	bottom: 0;
	z-index: 10;
	background-color:#fff;
	color:red;
	border:1px solid #ccc;
	cursor:pointer;
}
.onoffswitch {
	position: relative; 
	width: 60px;
	-webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
}
.onoffswitch-checkbox {
	display: none;
}
.onoffswitch-label {
	display: block; 
	overflow: hidden; 
	cursor: pointer;
	border: 1px solid #057121; 
	border-radius: 20px;
}
.onoffswitch-inner {
	display: block; 
	width: 200%; 
	margin-left: -100%;
	transition: margin 0.1s ease-in 0s;
}
.onoffswitch-inner:before, 
.onoffswitch-inner:after {
	display: block; 
	float: left; 
	width: 50%; 
	height: 20px; 
	padding: 0; 
	line-height: 20px;
	font-size: 12px; 
	color: white; 
	font-family: Trebuchet, Arial, sans-serif; 
	font-weight: bold;
	box-sizing: border-box;
}
.onoffswitch-inner:before {
	content: "%";
	padding-right: 10px;
	padding-left:5px;
	background-color: #04b653; 
	color: #FFFFFF;
}
.onoffswitch-inner:after {
	content: "$";
	padding-right: 10px;
	background-color: #EEEEEE; 
	color: #222;
	text-align: right;
}
.onoffswitch-switch {
	display: block; 
	width: 15px; 
	height: 15px;
	margin: 6px;
	background: #FFFFFF;
	position: absolute; 
	top: -3px; 
	bottom: 0;
	right: 33px;
	border: 1px solid #057121; 
	border-radius: 20px;
	transition: all 0.1s ease-in 0s; 
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
	margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
	right: 0px; 
}
.deletei{
	position: absolute;
	right: -9px;
	top: 19px;
	background-color: #F44336;
	color: #fff;
	padding: 3px;
	cursor: pointer;
}
@media (max-width: 992px) {
	.TemplatesList .items{ width: 48%;}
}
@media (max-width: 767px) {
	.TemplatesList .items{ width: 100%;}
}
.loadH{
	display: inline-block;
	width: 100%;
	max-width: 140px;
	height: 15px;
	padding-left: 5px;
	padding-right: 5px;
}
.loadH1{
	display: inline-block;
	width: 100%;
	max-width: 100px;
	height: 15px;
	padding-left: 5px;
	padding-right: 5px;
}
.loadH2{
	display: inline-block;
	width: 100%;
	max-width: 60px;
	height: 15px;
	padding-left: 5px;
	padding-right: 5px;
}
.loadingBar{	
	display: block;
	width: 100%;
	opacity: 1;
	height: 100%;
	background-color: #ccc;
	transition: all;
	-webkit-animation: loadBar 2s infinite step-start both;
	animation: loadBar 2s infinite step-start both;
}
@-webkit-keyframes loadBar {
	0% {width: 100%;opacity: 1;}
	2% {width: 2%;opacity: 0.02;}
	4% {width: 4%;opacity: 0.04;}
	6% {width: 6%;opacity: 0.06;}
	8% {width: 8%;opacity: 0.08;}
	10% {width: 10%;opacity: 0.10;}
	12% {width: 12%;opacity: 0.12;}
	14% {width: 14%;opacity: 0.14;}
	16% {width: 16%;opacity: 0.16;}
	18% {width: 18%;opacity: 0.18;}
	20% {width: 20%;opacity: 0.20;}
	22% {width: 22%;opacity: 0.22;}
	24% {width: 24%;opacity: 0.24;}
	26% {width: 26%;opacity: 0.26;}
	28% {width: 28%;opacity: 0.28;}
	30% {width: 30%;opacity: 0.30;}
	32% {width: 32%;opacity: 0.32;}
	34% {width: 34%;opacity: 0.34;}
	36% {width: 36%;opacity: 0.36;}
	38% {width: 38%;opacity: 0.38;}
	40% {width: 40%;opacity: 0.40;}
	42% {width: 42%;opacity: 0.42;}
	44% {width: 44%;opacity: 0.44;}
	46% {width: 46%;opacity: 0.46;}
	48% {width: 48%;opacity: 0.48;}
	50% {width: 50%;opacity: 0.50;}
	52% {width: 52%;opacity: 0.52;}
	54% {width: 54%;opacity: 0.54;}
	56% {width: 56%;opacity: 0.56;}
	58% {width: 58%;opacity: 0.58;}
	60% {width: 60%;opacity: 0.60;}
	62% {width: 62%;opacity: 0.62;}
	64% {width: 64%;opacity: 0.64;}
	66% {width: 66%;opacity: 0.66;}
	68% {width: 68%;opacity: 0.68;}
	70% {width: 70%;opacity: 0.70;}
	72% {width: 72%;opacity: 0.72;}
	74% {width: 74%;opacity: 0.74;}
	76% {width: 76%;opacity: 0.76;}
	78% {width: 78%;opacity: 0.78;}
	80% {width: 80%;opacity: 0.80;}
	82% {width: 82%;opacity: 0.82;}
	84% {width: 84%;opacity: 0.84;}
	86% {width: 86%;opacity: 0.86;}
	88% {width: 88%;opacity: 0.88;}
	90% {width: 90%;opacity: 0.90;}
	92% {width: 92%;opacity: 0.92;}
	94% {width: 94%;opacity: 0.94;}
	96% {width: 96%;opacity: 0.96;}
	98% {width: 98%;opacity: 0.98;}
	100% {width: 100%;opacity: 1;}
}
@keyframes loadBar {
	0% {width: 100%;opacity: 1;}
	2% {width: 2%;opacity: 0.02;}
	4% {width: 4%;opacity: 0.04;}
	6% {width: 6%;opacity: 0.06;}
	8% {width: 8%;opacity: 0.08;}
	10% {width: 10%;opacity: 0.10;}
	12% {width: 12%;opacity: 0.12;}
	14% {width: 14%;opacity: 0.14;}
	16% {width: 16%;opacity: 0.16;}
	18% {width: 18%;opacity: 0.18;}
	20% {width: 20%;opacity: 0.20;}
	22% {width: 22%;opacity: 0.22;}
	24% {width: 24%;opacity: 0.24;}
	26% {width: 26%;opacity: 0.26;}
	28% {width: 28%;opacity: 0.28;}
	30% {width: 30%;opacity: 0.30;}
	32% {width: 32%;opacity: 0.32;}
	34% {width: 34%;opacity: 0.34;}
	36% {width: 36%;opacity: 0.36;}
	38% {width: 38%;opacity: 0.38;}
	40% {width: 40%;opacity: 0.40;}
	42% {width: 42%;opacity: 0.42;}
	44% {width: 44%;opacity: 0.44;}
	46% {width: 46%;opacity: 0.46;}
	48% {width: 48%;opacity: 0.48;}
	50% {width: 50%;opacity: 0.50;}
	52% {width: 52%;opacity: 0.52;}
	54% {width: 54%;opacity: 0.54;}
	56% {width: 56%;opacity: 0.56;}
	58% {width: 58%;opacity: 0.58;}
	60% {width: 60%;opacity: 0.60;}
	62% {width: 62%;opacity: 0.62;}
	64% {width: 64%;opacity: 0.64;}
	66% {width: 66%;opacity: 0.66;}
	68% {width: 68%;opacity: 0.68;}
	70% {width: 70%;opacity: 0.70;}
	72% {width: 72%;opacity: 0.72;}
	74% {width: 74%;opacity: 0.74;}
	76% {width: 76%;opacity: 0.76;}
	78% {width: 78%;opacity: 0.78;}
	80% {width: 80%;opacity: 0.80;}
	82% {width: 82%;opacity: 0.82;}
	84% {width: 84%;opacity: 0.84;}
	86% {width: 86%;opacity: 0.86;}
	88% {width: 88%;opacity: 0.88;}
	90% {width: 90%;opacity: 0.90;}
	92% {width: 92%;opacity: 0.92;}
	94% {width: 94%;opacity: 0.94;}
	96% {width: 96%;opacity: 0.96;}
	98% {width: 98%;opacity: 0.98;}
	100% {width: 100%;opacity: 1;}
}
.list-group-flush:first-child .list-group-item:first-child,
.list-group-item:last-child{
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item{
	padding: 0; 
	color:#000;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
</style>
