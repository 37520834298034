<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-header" style="padding-top: 0; padding-bottom: 0;">
						<h3>Auto Responder</h3>
					</div>
					<div class="bgloading" v-show="isLoading">
						<Loader></Loader>
					</div>
					<form @submit.prevent="setNotiForm" id="form1" class="">
						<div class="card-body">
							<div class="d-flex aling-items-center">
								<label for="active">Active Responder:
									<input type="checkbox" id="active" v-model="f.active_responder" :value="1" :ckeched="(f.active_responder==1)"/>
								</label>
							</div>
							<div class="form-group">
								<label for="title">title: </label>
								<input type="text" class="form-control" id="title" v-model="f.title" />
							</div>
							<div class="form-group">
								<label for="subject">Subject:</label>
								<input type="text" class="form-control" id="subject" v-model="f.subject" />
							</div>
							<div class="form-group">
								<label for="autoresponder">Auto Responder: </label>
								<vue-editor
                                    id="autoresponder"
                                    v-model="f.autoresponder"
                                    style="width: 100%"
                                    :editor-toolbar="customToolbar"
                                />
							</div>
							<div class="d-flex justify-content-end">
								<button type="submit" class="btn btn-success btn-sm" :disabled="btnDisabled">UPDATE</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Loader from "@/core/components/shared/Loader.vue";
import { mapGetters } from "vuex";
import { VueEditor } from 'vue2-editor'
export default {
	components: {
		Loader,
		VueEditor,
	},
	data() {
		return {
			isLoading: false,
			f: {
				org_id: window.localuserdata,
				user_id: window.master_client,
				noti_id: 0,
				alert_success: null,
				title: null,
				subject: null,
				autoresponder: null,
				active_responder: 0,
			},
			org_email: null,
			apis: null,
			btnDisabled: false,
            customToolbar: [
                ["bold", "italic", "underline", "strike"], // toggled buttons
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" }
                ],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                // ["link", "image", "video"],
                ["link"],
                ["clean"] // remove formatting button
            ],
		};
	},
	created() {
		this.init();
	},
	mounted() {
	},
	methods: {
		init() {
			let urlApis = this.$store.getters['ModApps/Marketea/urlApis'];
			this.apis = urlApis.bSite;
			this.getNotiForm();
		},
		getNotiForm() {
			let t = this;
			t.isLoading = true;
			let url = t.apis.pull(10); // getNotiForm
			window.master.post(url, {
				org_id: window.localorgdata,
				form_type: 1,
			})
				.then((response) => {
					let get = response.data;
					console.log(url, get);
					if (get.r) {
						t.f.noti_id = get.r.id;
						t.f.alert_success = get.r.alert_success;
						t.f.title = get.r.title;
						t.f.autoresponder = get.r.autoresponder;
						t.f.subject = get.r.subject;
						t.f.active_responder = get.r.active_responder;
						t.btnDisabled = false;
					} else {
						t.btnDisabled = true;
					}
					t.isLoading = false;
				})
				.catch((error) => {
					console.log(error);
					t.isLoading = false;
					t.btnDisabled = true;
				});
		},
		setNotiForm() {
			let t = this;
			if (t.f.noti_id > 0) {
				t.isLoading = true;
				let url = t.apis.set(16); // setAutoResponder
				window.master
					.post(url, t.f)
					.then((response) => {
						t.$parent.close();
						t.isLoading = false;
					})
					.catch((error) => {
						console.log(error);
						t.isLoading = false;
					});
			}
		},
	},
	computed: {
	},
};
</script>

<style lang="scss" scoped></style>
