import axios from 'axios';
export default {
    PermisionUserDash(context, status){ 
      //console.log('SET_DASHBOARD', status);
      context.commit('SET_DASHBOARD', status); 
    },
    validarSession(){
      //debugger;
      return new Promise((resolve, reject) => {
        window.billing.post('validarSession', 
          {
            user_id: localStorage.getItem('mark_client_data'),
            oauth_id: localStorage.getItem('oauth'),
          }
        ).then(response => {
          resolve(response);   
        })
        .catch(error => {
          console.log(error);
          reject(error)
        });
      });
    },
    validTokenBilling(){
      //debugger;
      return new Promise((resolve, reject) => {
        window.apiv2.post('validarToken', 
          {
            user_id: localStorage.getItem('mark_client_data'),
            oauth_id: localStorage.getItem('oauth'),
          }
        ).then(response => {
            localStorage.setItem('validTokenBilling', response.data.tokenValid);
          resolve(response);   
        })
        .catch(error => {
          console.log(error);
          resolve(false);
          //reject(error)
        });
      });
    },
    destroyTokenBilling(context) {
      context.commit('destroyTokenBilling')
        console.log('logout_billing');
         return new Promise((resolve, reject) => {
          window.billing.post('logout')
            .then(response => {
              console.log('salir');
              localStorage.removeItem('access_token');
              localStorage.removeItem('mark_client_data');
              localStorage.removeItem('oauth');
              localStorage.removeItem('isMoneyIn');
              localStorage.removeItem('isMoneyOut');
              localStorage.removeItem('nomCompany');
              localStorage.removeItem('billing');
              context.commit('destroyTokenBilling')
              resolve(response)
            })
            .catch(error => {
              console.log(error);
              localStorage.removeItem('access_token');
              localStorage.removeItem('mark_client_data');
              localStorage.removeItem('oauth');
              localStorage.removeItem('isMoneyIn');
              localStorage.removeItem('isMoneyOut');
              localStorage.removeItem('nomCompany');
              localStorage.removeItem('billing');
              context.commit('destroyTokenBilling');
              reject(error)
            })
        })      
    },
    retrieveTokenBilling(context, credentials) {
      //login billing
      return new Promise((resolve, reject) => 
      {
        if(credentials.loginBIlling == true)
        {
          window.apiv2.post('accessmaster', {
            user_id: localStorage.getItem('mark_client_data_m'),
            org_id: localStorage.getItem('mark_client_data'),
            oauth_m: localStorage.getItem('oauth_m'),
          })
          .then(response => {
              if(response.data.login)
              {
                  const token = response.data.access_token.token;
                  localStorage.setItem('access_token', token);
                  localStorage.setItem('oauth', response.data.oauth);
                  // localStorage.setItem('folder_user', response.data.f);
                  response.data.f = localStorage.getItem('folder_user')
                  response.data.nomCompany = localStorage.getItem('nomCompany')
                  response.data.org_id = localStorage.getItem('mark_client_data');
                  // localStorage.setItem('nomCompany', response.data.nomCompany);
                  localStorage.setItem('updatedatacompany', JSON.stringify(response.data));
                  // console.log('iniciando sesion = ', response.data.nomCompany)
                  localStorage.setItem('isMoneyIn', 1);
                  localStorage.setItem('isMoneyOut', 0);
                  localStorage.setItem('billing', 1);
                  window.localuserdata = localStorage.getItem('mark_client_data');
                  window.localtoken = token;
                  window.billing = axios.create({
                    baseURL: window.urlbilling + 'api/v3/',
                    headers: {
                      common: {
                        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
                        oauthB: localStorage.getItem('oauth'),
                        orgID: localStorage.getItem('mark_client_data')
                      },
                      post: { 'Content-Type': 'application/json' }
                    }
                  });
                  const accessBilling = {
                    token: response.data.access_token.token,
                    login: response.data.login,
                  };
                  context.commit('retrieveTokenBilling', accessBilling);
                  resolve(response);
              }
              else
              {
                  console.log(response);
                  reject(response);
              } 
          })
          .catch(error => {
              console.log(error);
              reject(error);
          });
        }
        else{reject(false);}
      });
    },    
    access_billing(context, accessBilling){
      context.commit('retrieveTokenBilling', accessBilling);
    },
    updateDataCompany(context, idorg){
      return new Promise((resolve, reject) => 
      {
        if(idorg > 0)
        {
          let dataCompany ={};
          if(localStorage.getItem('updatedatacompany')!=null)
          {
            dataCompany = JSON.parse(localStorage.getItem('updatedatacompany'));
          }
          if(Object.keys(dataCompany).length > 0 && dataCompany.org_id==idorg)
          {
              localStorage.setItem('folder_user', dataCompany.f);
              localStorage.setItem('nomCompany', dataCompany.nomCompany);
              resolve(dataCompany);
          }
          else
          {
            window.billing.post('updatedatacompany', { org_id : idorg})
            .then(response => {
              localStorage.setItem('folder_user', response.data.f);
              localStorage.setItem('nomCompany', response.data.nomCompany);
              localStorage.setItem('updatedatacompany', JSON.stringify(response.data));
              resolve(response);
            })
            .catch(error => {
              console.log(error);
              reject(error);
            });
          }
        }
        else{reject(false);}
      });
    },
    verifyToken(context) {
      return new Promise((resolve, reject) => {
        window.billing.get('verifytoken')
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error);
          reject(error)
          context.commit('redirectBilling');
        })
      })
    },
    pushActionTask(context, payload) {
      return new Promise((resolve, reject) => {
        window.billing
        .post("pushActionTask", )
        .then((response) => {
          console.log('pushActionTask', response);
          resolve(response)
        })
        .catch((error) => {
          console.log(error);
          reject(error)
        });
      })
    },
};
