let getIndex = function() {
	// this.InvoicesList[i].accountSMS = window.accountSMS
	let t = this;
	t.validJobsApp();
	t.isLoading = true;
	let hasta = "";
	let desde = "";
	let duehasta = "";
	let duedesde = "";
	if (t.searchDate) {
		desde = t.dateFrom;
		hasta = t.dateTo;
		duedesde = t.duedateFrom;
		duehasta = t.duedateTo;
		t.searchDate = false;
	}
	let jobs = 0;
	if(t.accessJobsApp){jobs=1;}
	window.billing.post("filterinvoice" + t.query, {
		user_id: window.localuserdata,
		filter: t.inputFilter.trim(),
		fieldsFilter: t.fieldsFilter,
		status: t.statuslist,
		pag: t.paginador,
		rowNum: t.rowNum,
		desde: desde,
		hasta: hasta,
		duedesde: duedesde,
		duehasta: duehasta,
		jobs: jobs,
	})
	.then((response) => {
		t.cancelSearchDate();
		t.pagination = response.data.lista;
		let listFilter = Object.values(response.data.lista.data);
		let e = listFilter;
		let listOverdue = [];
		let listStatus = [];
		let soloFilter = [];
		t.Actionsdropd = [];
		for (var i = 0; i < e.length; i++) {
			t.Actionsdropd[e[i].id] = false;
			let fecdoc = new Date(e[i].fecdoc).toDateString();
			let fecdue = new Date(e[i].fecdue).toDateString();
			let f_a = new Date();
			fecdue = new Date(e[i].fecdue);
			listFilter[i].actions = false;
			if (f_a > fecdue && e[i].idxstatus < 2) {
				listFilter[i].idxstatus = 4;
				listOverdue.push(listFilter[i]);
			} else {
				listStatus.push(listFilter[i]);
			}
			if (t.statuslist.includes("" + listFilter[i].idxstatus + "")) {
				soloFilter.push(listFilter[i]);
			}
		}
		if (t.statuslist.length == 1 && t.statuslist.includes("4")) {
			t.InvoicesList = listOverdue;
		} else if (
			t.statuslist.length > 0 &&
			t.statuslist.length < 5 &&
			t.statuslist.includes("4")
			) {
			t.InvoicesList = soloFilter;
		} else if (
			t.statuslist.length > 0 &&
			t.statuslist.length < 5 &&
			!t.statuslist.includes("4")
			) {
			t.InvoicesList = listStatus;
		} else {
			t.InvoicesList = listFilter;
		}
		if (
			t.inputFilter.length > 0 ||
			t.statuslist.length != 5 ||
			desde.length > 0 ||
			hasta.length > 0 ||
			duedesde.length > 0 ||
			duehasta.length > 0
		) {
			t.isFilter = true;
		}
		if(response.data.setting_columnas){
			let cols = response.data.setting_columnas.meta_value;
			if(cols){
				t.showCols(cols);	
			}
		}
		t.InvoicesListResult = t.InvoicesList;
		t.isLoading = false;
		t.numPagList();	
		if(t.accessJobsApp){
			t.jobsinvo();
		}	
	})
	.catch((error) => {
		t.isLoading = false;
		console.log(error);
	});
};
export default getIndex;
