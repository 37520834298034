<template>
    <div class="container segurity">
        <div class="row">
            <div class="col-md-6 offset-md-3">
                <div class="prof_title">
                    <h4>Password</h4>
                    <p>
                        Update your account password. Always set a strong password, which
                        helps to prevent unauthorized access to your account.
                    </p>
                </div>
                <div class="card" style="border-top: 4px solid #a1e0ae">
                    <div class="icontri"></div>
                    <div class="card-body">
                        <form @submit.prevent="UpdatePassword">
                            <div class="form-row">
                                <div class="form-group col-md-12">
                                    <label for="">Current Password</label>
                                    <input
                                        type="password"
                                        class="form-control"
                                        v-model="f.CurrentPassword"
                                        placeholder=""
                                    />
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="">New Password</label>
                                    <div class="passsecurity">
                                        <password
                                            v-model="f.newPassword"
                                            placeholder=""
                                        />
                                    </div>
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="">Re-enter Password</label>
                                    <input
                                        type="password"
                                        class="form-control"
                                        v-model="f.rePassword"
                                        placeholder=""
                                    />
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary float-right">
                                Update
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Password from 'vue-password-strength-meter'
export default {
    name: 'SecurityInvite',
    components: {
        Password
    },
    data () {
        return {
            isloaing: false,
            user_id: window.master_client,
            org_id: window.localorgdata,
            f: {
                newPassword: '',
                rePassword: '',
                CurrentPassword: ''
            }
        }
    },
    created () {},
    methods: {
        UpdatePassword () {
            if (this.f.newPassword !== this.f.rePassword) {
                this.$store.commit("core/setNotiPush", {
                    group: "notiPush",
                    type: '5', // 1=success|2=info|3=error|4=loading|5=warning
                    title: "Warning",
                    text: "Password and Confirm, they must be the same.",
                    show: true,
                    duration: 10000
                });
            } else if (this.f.newPassword.length < 6) {
                this.$store.commit("core/setNotiPush", {
                    group: "notiPush",
                    type: '5', // 1=success|2=info|3=error|4=loading|5=warning
                    title: "Warning",
                    text: "the password must have more than 6 characters.",
                    show: true,
                    duration: 10000
                });
            } else {
                window.master
                    .post('UpdatePasswordInvite', {
                        id: window.localAccountID,
                        current_password: this.f.CurrentPassword,
                        new_password: this.f.newPassword,
                        new_confirm_password: this.f.rePassword
                    })
                    .then((response) => {
                        this.f.newPassword = ''
                        this.f.rePassword = ''
                        this.f.CurrentPassword = ''
                        console.log(response.data)
                        this.$store.commit("core/setNotiPush", {
                            group: "notiPush",
                            type: '1', // 1=success|2=info|3=error|4=loading|5=warning
                            title: "Update",
                            text: "Password Successful.",
                            show: true,
                            duration: 5000
                        });
                    })
                    .catch((error) => {
                        console.log(error)
                        this.$store.commit("core/setNotiPush", {
                            group: "notiPush",
                            type: '3', // 1=success|2=info|3=error|4=loading|5=warning
                            title: "Error",
                            text: "Current password incorrect.",
                            show: true,
                            duration: 10000
                        });
                    })
            }
        }
    }
};
</script>
<style lang="css" scoped>
.passsecurity {
    position: relative;
}
.Password {
    max-width: 100% !important;
}
.Password__field {
    background-color: #fff !important;
    border: 1px solid #d8d8d8 !important;
    border-radius: 2px !important;
    box-sizing: border-box !important;
    font-size: 14px !important;
    padding: 9px !important;
    width: 100% !important;
}
.Password__strength-meter {
    margin: 0px auto 0px !important;
}
.icontri {
    position: absolute;
    top: -21px;
    left: 50%;
    display: block;
    width: 0;
    height: 0;
    border-right: 10px solid transparent;
    border-top: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 10px solid #a1e0ae;
    transform: translateX(-50%);
}
.prof_title {
    display: block;
    font-size: 14px;
}
.prof_title h4 {
    font-weight: 100;
    font-size: 24px;
    text-align: center;
}
.segurity {
    margin-top: 60px;
}
.segurity label {
    display: block;
    margin-bottom: 0.1rem;
}
</style>
