let convertToJobs = function(id, numdoc, total, customer_id, item) {
	let t = this;
	if(t.PlanPremium){
		let addNewJob = {
			id_status: 1,
			name: 'Invoice Nro #'+ numdoc,
			sub_id: 0,
			invoice_id: id,
			customer_id: customer_id,
			color_id: 1,
			user_id: window.master_client,
			org_id: window.localorgdata,
			fecha: '',
			notes: '',
			user_id_assign: 0,
			tags: [],
			taks : [],
			invo : 1,
		};
		// t.isLoading = true;
		t.$store.commit("core/setNotiPush", {
			group: "notiPush",
			type: '4', // 1=success | 2=info | 3=error | 4=loading | 5=warning
			title: "Loading...",
			text: "Creating job.",
			show: true,
			duration: 3000
		});
		window.billing.get("invoice/" + id)
		.then((response) => {
			let prodServ = response.data.itemsprod;
			let validDescrip = true;
			addNewJob.fecha = response.data.result.fecdoc
			for (let i in prodServ) {
				if(prodServ[i]) {
					if(prodServ[i].description == null || prodServ[i].description ==''){
						validDescrip = false;
					}
					addNewJob.taks.push({
						id: 0,
						name: prodServ[i].description,
						status: 1,
					});
				}
			}
			console.log('convertToJobs', addNewJob)	
			if(!validDescrip){
				t.$notify({
					group: "noti",
					type: "error",
					title: "Error",
					text: "Products/Services description must NOT be empty.",
				});
				// t.isLoading = false;
			} else {
				t.lastFnActionJobs = id; // id invo
				console.log('lastFnActionJobs', t.lastFnActionJobs)
				let url = "/jobs/addJobs";
				window.master
				.post(url, addNewJob)
				.then((response) => {
					t.$notify({
						group: "noti",
						type: "success",
						title: "Job Add",
						text: "successfully",
					});
					// t.isLoading = false;
				})
				.catch((error) => {
					t.$notify({
						group: "noti",
						type: "error",
						title: "Error",
						text: "failed to save, try later!",
					});
					console.log(error);
				});
			}
		})
		.catch((error) => {
			console.log(error);
			// t.isLoading = false;
		});
	}else{
		t.llamarPopPremium();
	}
};
export default convertToJobs;
