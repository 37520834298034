<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-6">
                <OauthClientForm></OauthClientForm>
            </div>
        </div>
    </div>    
</template>
<script>
import OauthClientForm from '@/apps/Marketea/components/google/OauthClientForm'
export default {
    name: "OauthClient",
    components: {
        OauthClientForm
    },
    data() {
        return {
            isLoading: false,
        };
    },
}
</script>
