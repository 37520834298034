let numPagList = function() {
	if(this.pagination.total == 1) {
		this.numPag = [1];
	} else{
		this.numPag = [];
		for (var i = 1; i <= this.pagination.last_page; i++) {
			this.numPag.push(i);
		}
	}
};
export default numPagList;
