var suf = "bMenu/";
export default {
	get(idx){
        let urls = [
            suf+'getCats',
            suf+'getFoods',
        ]
		return urls[idx];
	},
	set(idx){
        let urls = [
            suf+'setCat',
            suf+'setCatOrden',
            suf+'deleteCat',
            suf+'setFood',
            suf+'setFoodOrden',
            suf+'setFoodGalleries',
            suf+'setGalleryOrden',
            suf+'deleteGallery',
            suf+'deleteFood',
            suf+'setCurrency',
        ]
		return urls[idx];
	},
};
