<template>
    <span class="badge badge-pill badge-light tagsName">
        <span v-if="id_tags.length > 0" class="nameTag">
            <span style="margin-right: 5px;">{{numPending}} Open</span> - 
            <span style="margin-left: 5px;">{{ tagsModalName }}</span>
        </span>
        <span v-if="id_tags.length > 0" class="cdropdown btnFilterTags">
            <button >
                <i class="fas fa-ellipsis-v"></i>
            </button>
            <label>
                <input type="checkbox" />
                <ul class="text-left">
                    <li @click="addUsersToTags()">
                        <i class="fas fa-cog"></i> Add editor to tag
                    </li>
                    <li @click="editTags()">
                        <i class="fas fa-edit"></i> Edit
                    </li>
                    <li @click="delTsgs()">
                        <i class="fas fa-times"></i> Delete
                    </li>
                </ul>
            </label>
        </span>
        <router-link v-if="id_tags.length > 0" :to="{ name: 'Jobs' }" class="closex" id="closext" >
            <i class="fa fa-times"></i>
        </router-link>
        <span v-if="id_tags.length == 0">Loading...</span>
    </span>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    name: "filterTagsUrl",
    data () {
        return {
            items: {},
            m: {
                show: false,
                component: '',
                data: []
            },             
            user_id: window.master_client,
            org_id: window.localorgdata,   
            tagsModalName:'',       
            typez: window.user_type,  
            user_id_assign: window.localAccountID,
            id_tags:[],
            listTaks:[],  
            tagsModalId: 0,   
            f: {
                id_jobs: 0,
                id: 0,
                id_status: 0,
                user_id: window.master_client,
                org_id: window.localorgdata,
                name: "",
                taks: [],
            }, 
            numPending: 0,    
        }
    },
    created () {   
        let urlApis = this.$store.getters['ModApps/jobs/urlApis'];
        this.apis = urlApis.jobFilter;         
        this.items = this.$store.getters['ModApps/jobs/getDataFilterTagsUrl'];
        this.numPending = this.$store.getters['ModApps/jobs/get_JOPending'];
        this.init();
    },
    methods: {   
        init() {
            if(Object.keys(this.items).length > 0) {
                this.tagsModalName = this.items.tagsModalName
                this.id_tags = this.items.id_tags
                this.listTaks = this.items.listTaks
                this.tagsModalId = this.items.id
                this.f.id = this.items.id
                this.f.name = this.items.tagsModalName
            }
        },
        addUsersToTags () {
            this.titleModalTaks = "Jobs";
            let t = this;
            this.m = {
                show: true,
                modalTag: true,
                component: 'addUserToTags',
                data: {
                    tagsModalId: this.tagsModalId,
                    f: this.f,
                    open: true,
                }
            }             
            this.$store.commit('core/setOpenModal', this.m);             
        },
        editTags () {
            let t = this;
            t.m = {
                show: true,
                modalTag: true,
                component: 'editTags',
                data: {
                    tag : this.items
                }
            }             
            console.log('editTags', t.m)
            t.$store.commit('core/setOpenModal', t.m);              
        },
        delTsgs () {
            let id = this.tagsModalId;
            this.$swal({
                title: "Delete Tags?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                if (result.value) {
                    let url = this.apis.del(id)
                    window.master
                        .delete(url, {
                                user_id: this.user_id,
                                org_id: this.org_id,
                                id: id,
                            }
                        )
                        .then((response) => {
                            let r = response.data.status;
                            if (r == false) {
                                this.$swal.fire({
                                    icon: "error",
                                    title: "cannot be deleted because it is in use",
                                    showConfirmButton: false,
                                    timer: 4000,
                                });
                            } else {
                                this.update_menuTags();
                                this.$store.commit("core/setLoadingModal", false);
                                this.$store.commit("core/setCloseModal", {
                                    action: 'saved',  
                                    methods:'delTags'                    
                                });                                 
                            }
                        })
                        .catch((error) => console.log(error));
                }
            });
        },                                          
    },
	watch: {  
        get_JOPending: function() {
            this.numPending = this.get_JOPending;
        },
        "getDataFilterTagsUrl.id_tags": function() {
            this.items = this.getDataFilterTagsUrl;
            this.init();  
        }     
    },
	computed: { 
        ...mapGetters("ModApps/jobs", ["getDataFilterTagsUrl", "get_JOPending"]),
	},
};
</script>
<style lang="scss" scoped>
    .text-center {
        text-align: center !important;
    }  
    span.badge.badge-pill.badge-light.tagsName {
        font-size: 14px;
        color: #fff;
        box-shadow: none;
        position: relative;    
        padding: 6px 15px;
    }
    .nameTag {
        padding-right: 5px;
    }
    span.status span.badge {
        border-radius: 0px;
        font-size: 14px;
    }
    li.list-inline-item .row .col4 .status .badge {
        float: right;
        width: 100%;
        padding: 2px 0px;
        cursor: pointer;
    }
    ul.list-options li .cdropdown > button {
        padding: 0;
        background-color: transparent;
        border: 0;
        color: #555;
    }
    ul.list-options li .cdropdown > button::before {
        border-top: 0px solid transparent;
    } 
    span.cdropdown.btnFilterTags {
        position: relative;
    }
    span.cdropdown.btnFilterTags button {
        background-color: transparent;
        border: 0px;
        color: #fff;
        padding: 0px 18px 0px 10px;
        font-size: 14px;
    }
    span.cdropdown.btnFilterTags button:before {
        border-top: 5px solid transparent;
        content: initial;
    }  
    .closex:not(:disabled):not(.disabled) {
        cursor: pointer;
    }
    .closex {
        border: none;
        background: none;
        color: #fff;
        margin-left: 0px;
    }
    span.cdropdown ul li {
        color: #555;
    }
</style>
