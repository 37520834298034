export default {
    Active(state, val){state.access = val},
    set_premiumProfile(state, val){state.premiumProfile = val},
    set_planAppId(state, val){ 
        localStorage.setItem('bSitePlanId', val);
        state.plan_id = val;
    },
    set_itemSalesAppointmente(state, val){ 
        localStorage.setItem('itemSalesAppointmente', JSON.stringify(val));
        state.itemSalesAppointmente = val;
    },
    set_builtForm(state, val){ state.builtForm = val; },
    set_formBuilt(state, val){ state.formBuilt = val; console.log('set_formBuilt', val)},
    set_customCssStorage(state, val){ state.customCssStorage = val; },
    set_reloadPreview(state, val){ state.reloadPreview = val; },
    set_desingStorage(state, val){ state.desingStorage = val; },
    set_editorStorage(state, val){ state.editorStorage = val; },
    set_asideStorage(state, val){ state.asideStorage = val; },
    set_ploadProgress(state, val){ state.ploadProgress = val;},
    set_settupStorage(state, val){ state.settupStorage = val;},
    set_bSiteValStorage(state, payload){
        localStorage.setItem(payload.key, JSON.stringify(payload.val))
        state[payload.key] = payload.val;
    },
    refreshCard(state, payload){
            let oldItemIdx=null;
            state.bsite_listcard.forEach((item, idx) => {
                if(item.id==payload.id){
                    oldItemIdx=idx;
                }
            });
            if(oldItemIdx!=null){
                state.bsite_listcard[oldItemIdx]= payload;
                console.log('refreshCard bsite_listcard', state.bsite_listcard[oldItemIdx].status)
                localStorage.setItem('bsite_listcard', JSON.stringify(state.bsite_listcard))
                if(state.bsite_cardItem!= null && payload.id==state.bsite_cardItem.id){
                    state.bsite_cardItem=payload;
                    console.log('refreshCard', state.bsite_cardItem);
                    localStorage.setItem('bsite_cardItem', JSON.stringify(state.bsite_cardItem));
                    setTimeout(() => {
                        state.bsite_cardItem=null;
                        setTimeout(() => {
                            state.bsite_cardItem=JSON.parse(localStorage.getItem('bsite_cardItem'));
                        }, 100);
                    }, 200);
                }
            }
    },
    setAbout(state, payload){
        let item = state.bsite_cardItem;
        let keysForm = Object.keys(payload);
        Object.keys(item).forEach((key, idx) => {
			if(keysForm.indexOf(key)>-1){
				item[key] = payload[key];
			}
		});
        state.bsite_cardItem=item;
        console.log('refreshCard', state.bsite_cardItem);
        localStorage.setItem('bsite_cardItem', JSON.stringify(state.bsite_cardItem));
        setTimeout(() => {
            state.bsite_cardItem=null;
            setTimeout(() => {
                state.bsite_cardItem=JSON.parse(localStorage.getItem('bsite_cardItem'));
            }, 100);
        }, 200);
    },
    refreshPusherList(state, payload){
        let oldItemIdx=null;
        state.bsite_listcard.forEach((item, idx) => {
            if(item.id==payload.id){
                oldItemIdx=idx;
            }
        });
        if(oldItemIdx!=null){
            state.bsite_listcard[oldItemIdx]= payload;
            localStorage.setItem('bsite_listcard', JSON.stringify(state.bsite_listcard))
            console.log('refreshPusherList', state.bsite_listcard)
        }
    },
    refreshPusherItem(state, payload){
        if(state.bsite_cardItem!=null && state.bsite_cardItem.id==payload.id){
            console.log('refreshPusher bsite_cardItem', state.bsite_cardItem)
            state.bsite_cardItem=payload;
            console.log('refreshCard', state.bsite_cardItem);
            localStorage.setItem('bsite_cardItem', JSON.stringify(state.bsite_cardItem));
            setTimeout(() => {
                state.bsite_cardItem=null;
                setTimeout(() => {
                    state.bsite_cardItem=JSON.parse(localStorage.getItem('bsite_cardItem'));
                }, 100);
            }, 200);
        }
    },
    addNewCard(state, payload){
        state.bsite_listcard.push(payload);
        localStorage.setItem('bsite_listcard', JSON.stringify(state.bsite_listcard))
        console.log('addNewCard', state.bsite_listcard)
    },
    set_bsite_minisite(state, payload){
        localStorage.setItem('siteContent', JSON.stringify(payload));
        state.siteContent = payload;
    },
    set_pluginList(state, val){ state.pluginList = val;},
    set_bMenuValStorage(state, payload){
        // localStorage.setItem(payload.key, JSON.stringify(payload.val))
        state[payload.key] = payload.val;
    },
    set_bMenuNewItem(state, payload){
        // localStorage.setItem(payload.key, JSON.stringify(payload.val))
        state[payload.key].push(payload.val);
    },
    set_bMenuDelCatItem(state, index){
        // localStorage.setItem(payload.key, JSON.stringify(payload.val))
        state.bmenu_listCat.splice(index, 1);
    },
    set_bMenuDelGalleryItem(state, payload){
        console.log('delete', payload)
        state.bmenu_listCat.find((c, cIdx) => {
            if(c.id==payload.cat_id){
                return c.foods.find((f, fIdx) => {
                    if(c.foods[fIdx].id==payload.food_id){
                        return f.gallery.find((g, gIdx) => {
                            if(f.gallery[gIdx].id==payload.id){
                                console.log('encontrado y eliminado', payload)
                                return state.bmenu_listCat[cIdx].foods[fIdx].gallery.splice(gIdx, 1);
                            }
                        });
                    }
                });
            }
        });
    },
    set_bMenuDelFoodItem(state, payload){
        console.log('delete food', payload)
        state.bmenu_listCat.find((c, cIdx) => {
            if(c.id==payload.cat_id){
                return c.foods.find((f, fIdx) => {
                    if(c.foods[fIdx].id==payload.id){
                        console.log('encontrado y eliminado', payload)
                        return state.bmenu_listCat[cIdx].foods.splice(fIdx, 1);
                    }
                });
            }
        });
    },
    set_bMenuAddGalleryItem(state, payload){
        // localStorage.setItem(payload.key, JSON.stringify(payload.val))
        state.bmenu_listCat.find((c, cIdx) => {
            if(c.id==payload.cat_id){
                return c.foods.find((f, fIdx) => {
                    if(c.foods[fIdx].id==payload.food_id){
                        return state.bmenu_listCat[cIdx].foods[fIdx].gallery.push(payload);
                    }
                });
            }
        });
    },
    set_bMenuAddFood(state, payload){
        state.bmenu_listCat.find((c, cIdx) => {
            if(c.id==payload.cat_id){
                console.log('add food', payload)
                return state.bmenu_listCat[cIdx].foods.push(payload);
            }
        });
    },
    set_widget_listCat(state, payload){ state.widget_listCat = payload;},
    set_widget_categoryMain(state, payload){ state.widget_categoryMain = payload;},
    set_widget_urlMedia(state, payload){ state.widget_urlMedia = payload;},
    set_widget_path_base(state, payload){ state.widget_path_base = payload;},
    set_bmenu_currency(state, payload){
        // console.log('set_bmenu_currency', payload)
        state.bmenu_currency = payload;
    },
}
