<template>
    <div>
        <div class="container-fluid">
            <div class="">
                <div class="bgloading op-loading-pricipal" v-if="isLoading">
                    <Loader :getProgress="progress"></Loader>
                </div>
                <div class="">
                    <div class="taks-columns" v-if="display.length > 0">
                        <div
                            v-for="(i, index) in display"
                            :key="index"
                            v-bind:class="['item-taks', i.selected ? 'active' : '']"
                        >
                            <div class="card">
                                <headerBox :itemDisplay="i"></headerBox>
                                <div :class="'card-body bg-1'">
                                    <sectionFiles :itemDisplay="i"></sectionFiles>
                                </div>
                                <div :class="'card-footer bg-1'">
                                    <footerBox :itemDisplay="i" :inde="index"></footerBox>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="add-first-item">
                        <h3>Add your first Display</h3>
                        <button class="btn btn-success btn-lg" @click="openForm">
                            <i class="fa fa-plus"></i> Add Display
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <modal
            name="DisplayFileH"
            :height="448"
            :width="700"
            :adaptive="true"
            @before-close="beforeCloseModal"
            v-bind:click-to-close="false"
        >
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">Display File</h5>
                    <button @click="closeModal" class="closeIcon"><span></span></button>
                </div>
                <div class="card-bodyx">
                    <div class="row" v-if="template == 'imagen'">
                        <img
                            :src="urls_imagenLg"
                            v-bind:class="[
                                'img-responsive',
                                orientacion == 1 ? 'horizontal' : 'vertical',
                            ]"
                            @click="closeModal()"
                        />
                    </div>
                    <div class="row" v-else>
                        <video
                            :src="urls_imagenLg"
                            id="video-container"
                            width="700"
                            height="406"
                            style="margin: 0 auto"
                            controls
                        ></video>
                    </div>
                </div>
            </div>
        </modal>
        <modal
            name="DisplayFileV"
            height="auto"
            width="295"
            :adaptive="true"
            @before-close="beforeCloseModal"
            v-bind:click-to-close="false"
        >
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title">Display File</h5>
                    <button @click="closeModal" class="closeIcon"><span></span></button>
                </div>
                <div class="card-bodyx">
                    <div class="row" v-if="template == 'imagen'">
                        <img
                            :src="urls_imagenLg"
                            v-bind:class="[
                                'img-responsive',
                                orientacion == 1 ? 'horizontal' : 'vertical',
                            ]"
                            @click="closeModal()"
                        />
                    </div>
                    <div class="row" v-else>
                        <video
                            :src="urls_imagenLg"
                            id="video-container"
                            width="295px"
                            height="auto"
                            controls
                        ></video>
                    </div>
                </div>
            </div>
        </modal>
        <button class="btn btn-dark" id="addDisplay" @click="openForm">
            <i class="fa fa-plus"></i>
        </button>
        <button class="btn btn-secondary" id="setupDisplay" @click="openSetting">
            <i class="fa fa-cog"></i>
        </button>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader.vue";
import headerBox from "@/apps/display/components/displayBox/headerBox.vue";
import sectionFiles from "@/apps/display/components/displayBox/sectionFiles.vue";
import footerBox from "@/apps/display/components/displayBox/footerBox.vue";
import { mapGetters } from "vuex";
export default {
    name: "DisplayDash",
    components: {
        Loader,
        headerBox,
        sectionFiles,
        footerBox,
    },
    data() {
        return {
            isLoading: false,
            user_id: window.master_client,
            org_id: window.localorgdata,
            type: window.user_type,
            user_id_assign: window.localAccountID,
            accountID: window.localAccountID,
            display: [],
            next_page_url: "",
            nextPage: false,
            heightPage: window.innerHeight,
            topMax: 0,
            active: 0,
            privilegies_dash: 0,
            urls_imagenLg: "",
            orientacion: 0,
            template: "",
            progress: 0,
        };
    },
    created() {
        localStorage.setItem("eje_pagination", 0);
        localStorage.setItem("maxScrollY", 0);
        this.$store.commit("ModApps/jobs/set_typeVisor", 1);
        this.getDisplayDash();
    },
    mounted() {
        let t = this;
        window.onscroll = () => {
            if (
                window.pageYOffset >= t.heightPage &&
                localStorage.getItem("eje_pagination") == 0
            ) {
                //console.log('traer datos' , window.pageYOffset+' >= '+window.innerHeight)
                if (t.next_page_url != null && t.next_page_url.length) {
                    t.nextPage = true;
                    localStorage.setItem("eje_pagination", 1);
                    localStorage.setItem("maxScrollY", window.pageYOffset);
                    t.getJobsDash();
                }
            }
        };
    },
    directives: {
        focus: {
            inserted: function (el) {
                el.focus();
            },
        },
    },
    methods: {
        openFile() {
            if (this.orientacion == 1) {
                this.$modal.show("DisplayFileH");
            } else {
                document.styleSheets[1].addRule(
                    ".v--modal-overlay .v--modal-box",
                    "top: 100px !important;"
                );
                this.$modal.show("DisplayFileV");
            }
        },
        closeModal() {
            if (this.orientacion == 1) {
                this.$modal.hide("DisplayFileH");
            } else {
                document.styleSheets[1].addRule(
                    ".v--modal-overlay .v--modal-box",
                    "top: 0px !important;"
                );
                this.$modal.hide("DisplayFileV");
            }
            this.template = "";
        },
        beforeCloseModal() {},
        openForm(id) {
            if (id > 0) {
                this.$router.push({
                    name: "DisplayEditdash",
                    params: { id: id, edit: "edit", dash: "dash" },
                });
            } else {
                this.$router.push({ name: "DisplayForm" });
            }
        },
        openSetting() {
            this.$router.push({ name: "DisplaySetting" });
        },
        selectedItem(idx) {
            for (const i in this.display) {
                this.display[i].selected = false;
            }
            this.display[idx].selected = true;
        },
        getDisplayDash() {
            this.isLoading = true;
            let url = "/display/getdisplaydash";
            window.master
                .post(url, {
                    user_id_assign: this.user_id_assign,
                    org_id: this.org_id,
                    user_id: this.user_id,
                    type: this.type,
                }, 
                {
                    onUploadProgress: (progressEvent) => {
                        console.log("progressEvent", progressEvent);
                        this.progress = parseInt(
                            (progressEvent.loaded / progressEvent.total) * 100
                        );
                    },
                })
                .then((response) => {
                    // console.log(response.data);
                    let e = response.data.list;
                    let urls = response.data.urls;
                    this.display = this.dataDisplayDash(e, urls);
                    this.isLoading = false;
                    this.starGrid();
                })
                .catch((error) => {
                    this.isLoading = false;
                    console.log(error);
                });
        },
        dataDisplayDash(e, urls) {
            let list = [];
            for (const i in e) {
                let imagenPortada = (e[i].name_archivos.length > 0) ? e[i].name_archivos[0].name_archivos : urls.poster;
                let imagenThumbs = (e[i].name_archivos.length > 0) ? urls.thumbs + imagenPortada : null;
                let imagenLarge = (e[i].name_archivos.length > 0) ? urls.large + imagenPortada : null;
                let imagenVideo = (e[i].name_archivos.length > 0) ? urls.video + imagenPortada : urls.poster;
                let newLine = {
                    id: e[i].id,
                    org_id: e[i].org_id,
                    user_id: e[i].user_id,
                    template_id: e[i].template_id,
                    name: e[i].name,
                    pin: e[i].pin,
                    name_archivos: imagenPortada,
                    name_templates: e[i].name_templates,
                    prefijo: e[i].prefijo,
                    orientacion: e[i].orientacion,
                    selected: false,
                    edicion: true,
                    url: e[i].url,
                    urls_imagenLg: imagenLarge,
                    urls_imagenTh: imagenThumbs,
                    urls_video: imagenVideo,
                    urls_poster: urls.poster,
                    f_p: e[i].f_p,
                    f_t: e[i].f_t,
                    status: e[i].status,
                    urlsLarge: urls.large,
                    urlsThumbs: urls.thumbs,
                };
                let slider = [];
                let slider2 = [];
                for (let j in e[i].name_archivos) {
                    if(e[i].name_archivos[j]){
                        slider.push(urls.largeSlider+e[i].name_archivos[j].name_archivos)
                        slider2.push(urls.thumbsSlider+e[i].name_archivos[j].name_archivos)
                    }
                }
                newLine.archivosLG = slider;
                newLine.archivosTH = slider2;
                list.push(newLine);
            }
            return list;
        },
        //start grilla
        starGrid() {
            let t = this;
            setTimeout(function () {
                t.grid_taks_ini();
            }, 100);
        },
        grid_taks_ini() {
            let divisor = this.grip_taks_divisor(window.innerWidth);
            this.grid_taks_generator(divisor);
            this.grid_taks_resize();
        },
        grid_taks_resize() {
            let t = this;
            window.addEventListener("resize", function (e) {
                //console.log('width ', window.innerWidth )
                let divisor = t.grip_taks_divisor(window.innerWidth);
                t.grid_taks_generator(divisor);
            });
        },
        grip_taks_divisor(n) {
            let columns = 1;
            if (n >= 1500) {
                columns = 5;
            } else if (n >= 1100) {
                columns = 4;
            } else if (n >= 850) {
                columns = 3;
            } else if (n >= 601) {
                columns = 2;
            } else {
                columns = 1;
            }
            return columns;
        },
        grid_taks_generator(divisor) {
            var i = document.querySelectorAll(".item-taks");
            let cont = 0;
            let columns = 0;
            let row = 1;
            let v = this;
            v.topMax = 0;
            i.forEach(function (item, index) {
                var w = item.offsetWidth;
                var h = item.offsetHeight;
                if (columns >= divisor) {
                    columns = 0;
                    row++;
                }
                let l = w * columns;
                let t = 0;
                if (row > 1) {
                    t = i[index - divisor].offsetHeight;
                }
                if (row > 2) {
                    t =
                        parseInt(i[index - divisor].style.top.replace("px", "")) +
                        parseInt(i[index - divisor].offsetHeight);
                }
                if (v.topMax < t) {
                    v.topMax = t;
                    item.classList.add("maxTop");
                }
                /*item.style.top = t + "px"; 
	          		item.style.left = l + "px"; 
	          		item.animate([
					  // keyframes
					  { top: t+'px' },
					  { let: l+'px' }
					], {
					  // timing options
					  duration: 1000
					});*/

                cont++;
                columns++;
            });
            if (localStorage.getItem("eje_pagination") == 1) {
                localStorage.setItem("eje_pagination", 0);
                this.heightPage =
                    document.body.scrollHeight -
                    parseInt(localStorage.getItem("maxScrollY"));
            }
            if (v.nextPage) {
                v.nextPage = false;
            }
        },
        //end grilla
    },
    computed: {
        ...mapGetters("ModApps/display", ["get_displayEdit"]),
    },
    watch: {
        get_displayEdit: function () {
            if (this.get_displayEdit) {
                let g = this.$store.getters["ModApps/display/get_displayEdit"];
                let fun = g[0].function;
                let r = g[0].data[0];
                if (fun == "DisplayUploadRefresh") {
                    this.isLoading = true;
                }
                if (fun == "DisplayUpload") {
                    this.display = [];
                    let t = this;
                    setTimeout(function () {
                        t.getDisplayDash();
                    }, 500);
                    this.starGrid();
                }
                if (fun == "DisplayFile") {
                    this.urls_imagenLg = r.img;
                    this.orientacion = r.orientacion;
                    this.template = r.template;
                }
                if (fun == "DisplayDelete") {
                    let list = [];
                    let e = this.display;
                    for (const i in e) {
                        if (r.id != e[i].id) {
                            let newLine = {
                                id: e[i].id,
                                org_id: e[i].org_id,
                                user_id: e[i].user_id,
                                template_id: e[i].template_id,
                                name: e[i].name,
                                name_archivos: e[i].name_archivos,
                                name_templates: e[i].name_templates,
                                prefijo: e[i].prefijo,
                                orientacion: e[i].orientacion,
                                selected: e[i].selected,
                                edicion: e[i].edicion,
                                url: e[i].url,
                                urls_imagenTh: e[i].urls_imagenTh,
                                urls_imagenLg: e[i].urls_imagenLg,
                                urls_video: e[i].urls_video,
                                urls_poster: e[i].urls_poster,
                                f_p: e[i].f_p,
                                f_t: e[i].f_t,
                                status: e[i].status,
                                urlsLarge: e[i].urlsLarge,
                                urlsThumbs: e[i].urlsThumbs,
                                archivosLG: e[i].archivosLG,
                                archivosTH: e[i].archivosTH,
                            };
                            list.push(newLine);
                        }
                    }
                    this.display = [];
                    let t = this;
                    setTimeout(function () {
                        t.display = list;
                    }, 500);
                    this.starGrid();
                }
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.v--modal-overlay .v--modal-box {
    position: absolute !important;
    top: 20px !important;
    left: 0.1px !important;
    margin: 0 auto;
    right: 0;
    transform: translateY(-50%) !important;
}
.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
}

.vertical {
    cursor: zoom-out;
}

.horizontal {
    cursor: zoom-out;
}

#addDisplay {
    position: fixed;
    top: 80px;
    right: 15px;
    border-radius: 40px;
    padding: 10px 15px;
}
#setupDisplay {
    position: fixed;
    top: 140px;
    right: 15px;
    border-radius: 40px;
    padding: 10px 15px;
}
.bg-1 {
    background-color: #fff;
}
.bg-2 {
    background-color: #ff8a80;
}
.bg-3 {
    background-color: #ffd180;
}
.bg-4 {
    background-color: #ffff8d;
}
.bg-5 {
    background-color: #cfd8dc;
}
.bg-6 {
    background-color: #80d8ff;
}
.bg-7 {
    background-color: #ccff90;
}
.taks-columns {
    position: relative;
}
.item-taks {
    /*width: calc(100%/4);*/
    display: inline-block;
    max-width: 406.22px;
    padding: 10px;
    margin-bottom: 20px;
    position: absolute;
    position: relative;
    transition-property: left;
    transition-duration: 5s;
}
/*
@media (min-width:1100px) {
	.item-taks{width: calc(100%/4);}	
}
@media (min-width:1500px) {
	.item-taks{width: calc(100%/5);}	
}
@media (max-width:1099px) {
	.item-taks{width: calc(100%/3);}	
}
@media (max-width:849px) {
	.item-taks{width: calc(100%/2);}	
}
@media (max-width:600px) {
	.item-taks{width: calc(100%/1);}	
}*/
.item-taks .card .card-header {
    background: #000;
    color: #fff;
    display: flex;
    flex-direction: row;
    border: 1px solid #000;
    padding: 10px 10px 10px 10px;
}

.item-taks .card {
    border: 1px solid #c4c5c6;
}
.item-taks .card .card-body {
    padding: 10px;
    padding-bottom: 0px;
}

ul.list-inline li {
    display: block;
    position: relative;
    border-bottom: 1px dashed #c4c5c6;
    padding: 10px 0;
    color: #555;
    margin-right: 0px;
}
.card-body.bg-1 .taks ul.list-inline li.list-inline-item,
.card-body.bg-4 .taks ul.list-inline li.list-inline-item,
.card-body.bg-5 .taks ul.list-inline li.list-inline-item,
.card-body.bg-6 .taks ul.list-inline li.list-inline-item,
.card-body.bg-7 .taks ul.list-inline li.list-inline-item {
    border-color: #c4c5c6;
}
.card-body.bg-2 .taks ul.list-inline li.list-inline-item,
.card-body.bg-3 .taks ul.list-inline li.list-inline-item {
    border-color: #fff;
    color: #000;
}
.tags span button.badge.badge-pill.badge-light {
    border-radius: 0px;
    margin-right: 5px;
    margin-bottom: 5px;
    border: 1px solid #a2a1a1;
    font-size: 12px;
    color: #0a0a0a;
    padding: 5px 10px;
    box-shadow: none;
}
li.list-inline-item .row .col4 .status {
    display: block;
    width: 100%;
}
li.list-inline-item .row .col4 .status .badge {
    float: right;
    width: 100%;
    padding: 2px 0px;
    cursor: pointer;
}
li.list-inline-item .row .col4 {
    padding-right: 5px;
}
li.list-inline-item .row {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
}
li.list-inline-item .row .col8 {
    width: 78% !important;
}

li.list-inline-item .row .col4 {
    width: 22% !important;
    padding: 0px;
}

li.list-inline-item .row .col4 .status .op-loading {
    height: 20px;
}
li.list-inline-item .row .col4 .status .op-loading:after {
    width: 20px;
    height: 20px;
    border: 2px solid #ccc;
    border-top-color: #37344f;
}
.card-footer {
    text-align: right;
    padding: 6px 10px;
}
.add-first-item {
    text-align: center;
    padding-top: 100px;
    padding-bottom: 100px;
    max-width: 800px;
    margin: auto;
    background: #fff;
    margin-top: 80px;
    border: 6px solid #ddd;
    border-radius: 10px;
}
</style>
