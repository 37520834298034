<template>
    <div>
        <aside
            class="left-sidebar"
            data-sidebarbg="skin5"
            v-bind:class="{ min: activarLateral }"
        >
            <div class="scroll-sidebar">
                <nav class="sidebar-nav">
                    <ul id="sidebarnav">
                        <li class="sidebar-item">
                            <router-link
                                :to="{ name: 'RestoreCustomer' }"
                                exact-active-class="active"
                                class="sidebar-link waves-effect waves-dark"
                            >
                                <i class="fas fa-angle-right"></i>
                                <span class="hide-menu">Customer</span>
                            </router-link>
                        </li>
                        <li class="sidebar-item">
                            <router-link
                                :to="{ name: 'RestoreEstimate' }"
                                exact-active-class="active"
                                class="sidebar-link waves-effect waves-dark"
                            >
                                <i class="fas fa-angle-right"></i>
                                <span class="hide-menu">Estimate</span>
                            </router-link>
                        </li>
                        <li class="sidebar-item">
                            <router-link
                                :to="{ name: 'RestoreInvoice' }"
                                exact-active-class="active"
                                class="sidebar-link waves-effect waves-dark"
                            >
                                <i class="fas fa-angle-right"></i>
                                <span class="hide-menu">Invoice</span>
                            </router-link>
                        </li>
                        <li class="sidebar-item">
                            <router-link
                                :to="{ name: 'RestoreExpenses' }"
                                exact-active-class="active"
                                class="sidebar-link waves-effect waves-dark"
                            >
                                <i class="fas fa-angle-right"></i>
                                <span class="hide-menu">Expenses</span>
                            </router-link>
                        </li>
                    </ul>
                </nav>
                <div class="btnAsideAction" @click="ShowHiddenAside">
                    <i
                        class="fas fa-chevron-left"
                        v-bind:class="{ min: activarLateral }"
                    ></i>
                </div>
            </div>
        </aside>
    </div>
</template>
<script>
export default {
    name: "AsideRestore",
    data() {
        return {
            ShowToggleAside: true,
            isActiveMenu: false,
            activarLateral: false,
            winnerWidth: window.innerWidth,
        };
    },
    methods: {
        MenuactiveAside() {
            this.isActiveMenu = !this.isActiveMenu;
            this.ShowToggleAside = false;
        },
        MetActivemenuAside() {
            this.ShowToggleAside = !this.ShowToggleAside;
            this.isActiveMenu = false;
        },
        ShowHiddenAside() {
            this.activarLateral = !this.activarLateral;
            this.$emit("activarLateral", this.activarLateral);
        },
    },
    mounted: function () {
        if (this.winnerWidth < 1050) {
            this.ShowHiddenAside();
        }
    },
};
</script>
<style lang="css" scoped>
.left-sidebar {
    position: fixed;
}
.btnAsideAction {
    position: absolute;
    bottom: -18px;
    top: initial;
    left: 0;
    width: 100%;
    height: 40px;
    background-color: #343435;
    cursor: pointer;
    padding: 10px 4px 4px 4px;
    line-height: 21px;
    text-align: center;
    color: #fff;
}
aside.left-sidebar.min {
    width: 60px;
    z-index: 100;
}
aside.left-sidebar.min .scroll-sidebar {
    height: 100%;
}
aside.left-sidebar.min .scroll-sidebar .btnAsideAction {
    bottom: 0%;
}
aside.left-sidebar.min .scroll-sidebar .btnAsideAction i:before {
    content: "\f054";
}
aside.left-sidebar.min .scroll-sidebar nav.sidebar-nav ul#sidebarnav li.sidebar-item,
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item
    ul.collapse.first-level
    li.sidebar-item {
    width: 60px;
    transition: all 0.5s ease-out;
    position: relative;
}

aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    ul.collapse.first-level,
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    ul.collapse.first-level.in {
    position: absolute;
    top: 0px;
    left: 40px;
    width: 170px;
}

aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item
    a.sidebar-link {
}
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item
    a.sidebar-link
    span.hide-menu,
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item
    div.sidebar-link
    span.hide-menu,
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item
    ul.collapse.first-level,
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item
    ul.collapse.first-level.in {
    display: none;
}
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    ul.collapse.first-level,
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    ul.collapse.first-level.in,
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    ul.collapse.first-level
    li.sidebar-item
    a.sidebar-link
    span.hide-menu {
    display: block;
}
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    ul.collapse.first-level
    li.sidebar-item
    a.sidebar-link {
    border-left: 2px solid transparent;
}
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    a.sidebar-link,
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    div.sidebar-link.has-arrow.waves-effect.waves-dark,
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    div.sidebar-link.has-arrow.waves-effect.waves-dark.active {
    border-left: 2px solid #137eff;
    opacity: 1;
}
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    div.sidebar-link,
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item:hover
    ul.collapse.first-level {
    background: #191919;
}
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item
    > .has-arrow::after {
    transition: all 0.5s ease-out;
}
aside.left-sidebar.min
    .scroll-sidebar
    nav.sidebar-nav
    ul#sidebarnav
    li.sidebar-item
    > .has-arrow::after {
    -webkit-transform: rotate(135deg) translate(0, -50%) !important;
    transform: rotate(135deg) translate(0, -50%) !important;
}
.sidebar-nav ul .sidebar-item .sidebar-link i.fas.fa-angle-right {
    font-size: 14px;
    width: 20px;
}
</style>
