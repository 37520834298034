<template>
	<div class="card card-body">
		<Loader v-if="isLoading"></Loader>
		<div class="list-group list-group-flush" v-else>
			<div
				v-for="item in filteredCustomer"
				:key="item.id"
				@click="detailCustomer(item.id)"
				:class="[
					'list-group-item list-group-item-action d-flex justify-content-between align-items-left filteredCustomer',
					(item.active === 0) ? 'customerInactive' : '',
				]"
			>
				<div class="d-block">
					<span>{{ item.firstname }} {{ item.lastname }}</span>
					<small
						class="d-block"
						style="color: rgb(160, 160, 160)"
						v-if="show_business == true"
					>{{ item.bussinesname }}</small>
				</div>
				<span
					class="badge badge-default"
					v-bind:class="{ balance: item.balance > 0 }"
				>
					<h6>$ {{ item.balance | formatMoney }}</h6>
				</span>
			</div>
		</div>
		<div
			v-if="isLoading"
			class="msj-no-customer"
		>
			<h3 style="text-align: center">
				Loading...
			</h3>
		</div>
		<div
			v-else-if="!isLoading && filteredCustomer.length == 0 && totalCustom == 0"
			class="msj-no-customer"
		>
			<h3 style="text-align: center">
				It's time to create your first Customer! 
			</h3>
			<button
				type="button"
				class="btn btn-success"
				@click="showformregister"
				style="margin-top: 7px"
			>
				Create New Customer
			</button>
		</div>
		<div
			v-else-if="!isLoading && noRecord == 0 && TextSearch.length > 0"
			class="msj-no-customer"
		>
			<h3 style="text-align: center">
				No record.
			</h3>
		</div>
		<div
			v-else-if="!isLoading && filteredCustomer.length == 0 && TextSearch.length > 0"
			class="msj-no-customer"
		>
			<h3 style="text-align: center">
				[Loading]...
			</h3>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import Loader from "@/core/components/shared/Loader";
export default {
	name: 'bodyList',
	components: {
		Loader
	},
	data() {
		return {
			isLoading: true,
			CustomersList: [],
			validList: [],
			morososList: [],
			TextSearch: '',
            show_business: true,
            show_customer: true,
            address2: false,
			paginador: 0,
			offset: 4,
			page: 1,
			pagination: {
				current_page: 1,
			},
			last_page: 1,
			current_page: 0,
			urlQuery: "",
			nextPage: false,
			totalCustom: 0,
			noRecord: 0,
		};
	},
	created() {
		// this.last_page = localStorage.getItem('cutomerlast_page') || 0;
		// this.current_page = localStorage.getItem('cutomercurrent_page') || 0;
		//this.getCustomers();
		localStorage.setItem('searchCustomer', 'Off');
	},
	mounted() {
	},
	directives: {
	},
	methods: {
		showformregister() {
			this.$store.commit('ModApps/billing/setFormCustomer', true);
			this.$store.commit('ModApps/billing/setCustomerID', 0);
			this.$store.commit('ModApps/billing/setCustomerByID', {});
		},
		getCustomers() {
			let t = this;
			// solo la primera ves cargar loading
			if(t.current_page == 0 || t.current_page == 1){
				if(t.CustomersList.length == 0){
					t.isLoading = false;
				}
			}else {
				console.log('current_page', t.current_page)
			}
			if(t.TextSearch.length>0){
				t.urlQuery = "";
				t.isLoading = true;
				t.noRecord = 0;
				console.log('buscando TextSearch', t.TextSearch)
			}
			let url = "customerspaginado";
			if (t.urlQuery != null && t.urlQuery.length > 0 && t.nextPage) {
				url = t.urlQuery;
			}
			window.billing
			.post(url, {
				user_id: window.localuserdata,
				filter: t.TextSearch,
				current_page: t.current_page,
			})
			.then((response) => {
				// console.log(response.data.result);
				if(t.TextSearch.length>0){
					t.CustomersList = response.data.result.data
					t.noRecord = response.data.result.data.length;
					console.log('resp TextSearch', t.TextSearch)
					t.isLoading = false;
				}
				else{
					console.log('resp paginado', t.current_page, t.last_page);
					t.last_page = response.data.result.last_page;
					t.current_page = response.data.result.current_page;
					let next_url = response.data.result.next_page_url;
					let total = response.data.result.total;
					// debugger;
					console.log('current_page='+t.current_page, 'last_page='+t.last_page)
					if (next_url != null && next_url.length > 0) {
						t.urlQuery = next_url;
						t.nextPage = true;
					} else{
						t.nextPage = false;
						t.urlQuery = null;
					}
					t.pagination = response.data.result;
					let list = response.data.result.data;
					if (t.current_page > 1 && t.current_page <= t.last_page) {
						console.log('current_page', t.current_page)
						for (let j in list) {
							if(list[j]){
								let valid = true;
								// console.log('buscando en list', list[j].firstname);
								for (let k in t.morososList) {
									if (t.morososList[k] && t.morososList[k].id == list[j].id) {
										valid = false;
										// console.log('ya existe en list', list[j].firstname);
									}
								}
								if (valid) {
									// console.log('agregando a la lista', list[j].firstname);
									t.CustomersList.push(list[j]);
								}
							}
						}
						t.validList = t.CustomersList;
					} else {
						// console.log('iniciando la lista', list);
						t.CustomersList = list;
						t.validList = list;
						let morosos = response.data.morosos;
						t.morososList = morosos;
						for (let j in morosos) {
							if(morosos[j]){
								// console.log('buscando morosos', morosos[j].firstname);
								let valid = true;
								let idxC = null;
								for (let k in t.validList) {
									if (t.validList[k] && t.validList[k].id == morosos[j].id) {
										valid = false;
										idxC = k;
									}
								}
								if (valid) {
									// console.log('no existe, agregando morosos a la lista', morosos[j].firstname);
									t.CustomersList.push(morosos[j]);
								}else{
									// console.log('ya existe moroso en la lista', t.CustomersList[idxC])
								}
							}
						}
						t.show_business = response.data.show_business;
						t.show_customer = response.data.show_customer;
						t.address2 = response.data.a2;
						t.$store.commit('ModApps/billing/setShowSetting', {
							show_business: t.show_business,
							show_customer: t.show_customer
						});
						t.$store.commit('ModApps/billing/setCustomerRefresh', false);
						t.$store.commit('ModApps/billing/setShowAddress2', t.address2);
					}
					if(t.CustomersList.length > 0){
						t.isLoading = false;
					}
				}
				// t.CustomersList = response.data.result;
				// console.log('lista de customer', response.data.result)
			})
			.catch((error) => {
				console.log(error);
				t.isLoading = false;
			});
		},
		detailCustomer(idc) {
			this.$store.commit('ModApps/billing/setCustomerID', idc);
			this.$store.commit('ModApps/billing/setFormCustomer', false); 
			// siempre cerrar form para cargar data primero
			console.log('seleccionando ID', idc);
		},
	},
	computed: {
		...mapGetters("ModApps/billing", { 
			getCustomerRefresh: "getCustomerRefresh",
			getCustomerSearch: "getCustomerSearch",
			getShowSetting: "getShowSetting",
			getCustomerCountTotal: "getCustomerCountTotal",
		}),
		filteredCustomer: function () {
			var t = this;
			return t.CustomersList;
			/*return t.CustomersList.filter(function (item) {
				return (
					(item.firstname &&
						item.firstname
						.toLowerCase()
						.indexOf(t.TextSearch.trim().toLowerCase()) >= 0) ||
					(item.bussinesname &&
						item.bussinesname
						.toLowerCase()
						.indexOf(t.TextSearch.trim().toLowerCase()) >= 0) ||
					(item.lastname &&
						item.lastname
						.toLowerCase()
						.indexOf(t.TextSearch.trim().toLowerCase()) >= 0) ||
					(item.fullname &&
						item.fullname
						.toLowerCase()
						.indexOf(t.TextSearch.trim().toLowerCase()) >= 0) ||
					(item.nameBussines &&
						item.nameBussines
						.toLowerCase()
						.indexOf(t.TextSearch.trim().toLowerCase()) >= 0) ||
					(item.lastnameBussines &&
						item.lastnameBussines
						.toLowerCase()
						.indexOf(t.TextSearch.trim().toLowerCase()) >= 0) ||
					(item.bussfirstname &&
						item.bussfirstname
						.toLowerCase()
						.indexOf(t.TextSearch.trim().toLowerCase()) >= 0) ||
					(item.busslastname &&
						item.busslastname
						.toLowerCase()
						.indexOf(t.TextSearch.trim().toLowerCase()) >= 0) ||
					(item.lastAndFirstname &&
						item.lastAndFirstname
						.toLowerCase()
						.indexOf(t.TextSearch.trim().toLowerCase()) >= 0) ||
					(item.lastFirstBuss &&
						item.lastFirstBuss
						.toLowerCase()
						.indexOf(t.TextSearch.trim().toLowerCase()) >= 0) ||
					(item.fulldata &&
						item.fulldata
						.toLowerCase()
						.indexOf(t.TextSearch.trim().toLowerCase()) >= 0)
					);
			});*/
		},
	},
	watch: {
		totalCustom: function(){
			console.log('totalCustom', this.totalCustom)
		},
		getCustomerCountTotal: function(){
			if(this.getCustomerCountTotal != null){
				this.getCustomers();
			}
			if(this.getCustomerCountTotal > 0){
				this.totalCustom = this.getCustomerCountTotal;
			}
		},
		current_page: function(){
			let t = this;
			if(t.TextSearch.length==0){
				console.log('current_page='+t.current_page, 'total='+t.last_page)
				if(t.current_page >0 && t.last_page >0 && t.current_page < t.last_page){
					setTimeout(function(){
						if(localStorage.getItem('searchCustomer') =='Off'){
							console.log('sigue paginando')
							t.getCustomers();
						}else{
							console.log('cancela la paginacion');
						}
					}, 200)
				}else{
					console.log('se rompio la paginacion', 'current_page=', t.current_page, 'last_page', t.last_page)
				} 
			}else{
				console.log('current_page hay TextSearch', t.TextSearch.length, t.TextSearch)
			}
		},
		getShowSetting: function () {
			this.show_business = this.getShowSetting.show_business;
			this.show_customer = this.getShowSetting.show_customer;
		},
		getCustomerRefresh: function () {
			if(this.getCustomerRefresh) {
				console.log('getCustomerRefresh list');
				this.getCustomers();
			}
		},
		getCustomerSearch: function () {
			this.TextSearch = (this.getCustomerSearch.length>0) ? this.getCustomerSearch : '';
			console.log('getCustomerSearch', this.TextSearch);
			let activeSearch = this.$store.getters['ModApps/billing/getCustomerSearchActive'];
			console.log('activeSearch', activeSearch);
			if(activeSearch){
				localStorage.setItem('searchCustomer', 'On');
				this.$store.commit('ModApps/billing/setCustomerSearchActive', false);
				this.current_page =0; // reiniciar paginacion
				if(this.TextSearch.length==0){
					localStorage.setItem('searchCustomer', 'Off');
					console.log('volver a paginando')
				}
				this.getCustomers();
			}else{
				localStorage.setItem('searchCustomer', 'Off');
			}
		}
	},
};
</script>
<style lang="css" scoped>
.msj-no-customer {
	position: relative;
	height: 50vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 30px;
}
.customerInactive {
    color: #d61b1b;
}
.list-group-item.list-group-item-action.d-flex.justify-content-between.align-items-left.filteredCustomer {
    padding-top: 5px;
    padding-bottom: 5px;
}
@media (max-width: 767px) {
	.filteredCustomer {
		padding: 12px 20px !important;
	}
}
.balance {
    color: #ff0000;
}
</style>
