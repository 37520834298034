<template>
    <div class="container">
        <div class="card-body">
			<div class="prof_title text-center">
				<h4>Reseller Information</h4>
			</div>
			<div class="card" style="border-top: 4px solid #a1e0ae">
				<div class="icontri"></div>
				<div class="card-body">
					<form @submit.prevent="editProfileReseller">
						<div class="form-row">
							<div class="form-group col-md-12">
								<label for="">Telephone</label>
								<input
									type="text"
									class="form-control"
									v-model="p.phone"
								/>
							</div>
						</div>
						<div class="form-row">
							<div class="form-group col-md-12">
								<label for="">Email</label>
								<input
									type="text"
									class="form-control"
									v-model="p.email"
								/>
							</div>
						</div>	
						<hr>										
						<div class="form-row">
							<div class="form-group col-md-12">
								<label for="">Company name</label>
								<input
									type="text"
									class="form-control"
									v-model="p.company_name"
								/>
							</div>
						</div>
						<div class="form-row">
							<div class="form-group col-md-6">
								<label for="">First name</label>
								<input
									type="text"
									class="form-control"
									v-model="p.name"
								/>
							</div>
							<div class="form-group col-md-6">
								<label for="">Last name</label>
								<input
									type="text"
									class="form-control"
									v-model="p.lastname"
								/>
							</div>
						</div>
						<div class="form-group">
							<label for="">Address</label>
							<input
								type="text"
								class="form-control"
								v-model="p.address"
							/>
						</div>
						<div class="form-row">
							<div class="form-group col-md-4">
								<label for="inputCity">City</label>
								<input
									type="text"
									class="form-control"
									v-model="p.city"
								/>
							</div>
							<div class="form-group col-md-4">
								<label for="inputCity">State</label>
								<input
									type="text"
									class="form-control"
									v-model="p.state"
								/>
							</div>
							<div class="form-group col-md-4">
								<label for="">Zip code</label>
								<input
									type="text"
									class="form-control"
									v-model="p.zip"
								/>
							</div>
						</div>
						<button type="submit" class="btn btn-primary float-right">
							Update
						</button>
					</form>
				</div>
			</div>				
        </div>		
	</div>
</template>
<script>
export default {
	name: 'profilerReseller',
	data () {
		return {
            p: {
                id : window.resellerId,
                company_name: '',
                name: '',
                lastname: '',
                address: '',
                city: '',
                state: '',
                zip: '',
                phone: '',
				email:'',			
            },						
		}
	},
	created(){
		console.log('profilerReseller')
		this.getProfileReseller()
	},
    methods: {
        getProfileReseller () {
			this.$store.commit('core/setLoadingModal', true);		
            let url = '/getProfileReseller/' + window.resellerId;
            window.reselleR2
                .get(url)
                .then((response) => {
                    let e = response.data.result					
					console.log(e);
                    if (e != null) {
						this.restore = e
                        this.p = e
                    }
					this.$store.commit('core/setLoadingModal', false);
                })
                .catch((error) => {
                    console.log(error)
					this.$store.commit('core/setLoadingModal', false);
                })
        },
        editProfileReseller () {
			this.$store.commit('core/setLoadingModal', true);
			if (this.p.phone.length == 0) {
				this.$store.commit("core/setNotiPush", {
					group: "notiPush",
					type: '3', // 1=success|2=info|3=error|4=loading|5=warning
					title: "Error",
					text: "phone empty.",
					show: true,
					duration: 5000
				});
				this.getrestor()				
				this.$store.commit('core/setLoadingModal', false);
				return false
			}
			if (this.p.email.length == 0) {
				this.$store.commit("core/setNotiPush", {
					group: "notiPush",
					type: '3', // 1=success|2=info|3=error|4=loading|5=warning
					title: "Error",
					text: "email empty.",
					show: true,
					duration: 5000
				});
				this.getrestor()
				this.$store.commit('core/setLoadingModal', false);
				return false
			}				
            let url = '/editProfileReseller';
            window.reselleR2
                .post(url, this.p)
                .then((response) => {
                    console.log(response.data)
                    this.$store.commit("core/setNotiPush", {
                        group: "notiPush",
                        type: '1', // 1=success|2=info|3=error|4=loading|5=warning
                        title: "Update",
                        text: "Profile Successful.",
                        show: true,
                        duration: 5000
                    });
					this.$store.commit('core/setLoadingModal', false);
					this.$store.commit("core/setCloseModal", true);
                })
                .catch((error) => {
                    console.log(error)
                    this.$store.commit("core/setNotiPush", {
						group: "notiPush",
						type: '3', // 1=success|2=info|3=error|4=loading|5=warning
						title: "Error",
						text: "updating data.",
						show: true,
						duration: 10000
					});
					this.$store.commit('core/setLoadingModal', false);
					this.$store.commit("core/setCloseModal", true);
                })
        },
        getrestor () {	
            let url = '/getProfileReseller/' + window.resellerId;
            window.reselleR2
                .get(url)
                .then((response) => {
                    let e = response.data.result					
                    if (e != null) {					
                        this.p = e
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        },				
    }
}
</script>
<style lang="css" scoped>
</style>
