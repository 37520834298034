<template>
    <div>
        <div class="page-breadcrumb">
            <div class="row">
                <div class="col-md-8 align-self-center">
                    <h4 class="page-title"><i class="fas fa-chart-line"></i> Customers</h4>
                </div>
                <div class="col-md-4 align-self-center">
                    <div class="d-flex align-items-center justify-content-end">
                        <router-link :to="{ name: 'BillingReport' }" class="btn btn-info btn-sm mr-2 ctrlbtn" style="margin-bottom: 10px;width: -webkit-fill-available;">
                            <i class="fas fa-arrow-left"></i>
                            Back
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-header"><h4>Export</h4></div>
                        <div class="bgloading" v-if="isLoading">
                            <Loader></Loader>
                        </div>
                        <div v-else class="card-body contenedor-table">
                            <div class="row">
                                <div class="col-md-6">
                                    <div role="alert" class="alert alert-success alert_totales" style="margin: 0 auto; text-align: center;margin: 10px;">
                                        Customer Actives:
                                        <h1>
                                            <span class="badge badge-success">{{activos}}</span>
                                        </h1>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div role="alert" class="alert alert-danger alert_totales" style="margin: 0 auto; text-align: center;margin: 10px;">
                                        Customer Inactives:
                                        <h1>
                                            <span class="badge badge-danger">{{inactivos}}</span>
                                        </h1>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div style="width: 250px; margin: 0 auto; text-align: center; margin-top:10px">
                                        <button type="button" class="btn btn-info" style="margin-right: 10px" @click="ExportCustomer()">
                                            <i class="fas fa-arrow-alt-circle-down"></i> Export
                                            Customers
                                        </button>
                                    </div>
                                </div>
                                <div class="col-md-12 " style="margin-top: 20px;"  v-if="(TotalEspacio-TotalRecord) <=10">
                                    <div class="alert alert-info" role="alert">
                                      <h4 class="alert-heading">Information!</h4>
                                      <p>With your current plan you are only allowed a maximum of <strong>{{TotalEspacio}} records</strong>, for more space please contact your system administrator.</p>
                                      <hr>
                                      <p class="mb-0">Current space available <strong>{{TotalRecord | formatMileage}} / {{TotalEspacio | formatMileage}}</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <button type="file" class="btn btn-success" @change="loadCSV($event)"><i class="fas fa-upload"></i> Upload Customers</button> -->
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-header"><h4>Import</h4></div>
                        <div class="bgloading" v-if="Loading">
                            <Loader></Loader>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div style="width: 250px; margin: 0 auto; text-align: center; padding: 10px;">
                                    <span>Document format Template <b>.xlsx</b></span>
                                    <button
                                        type="button"
                                        class="btn btn-secondary"
                                        style="margin-right: 10px"
                                        @click="MDownloadTemplate()"
                                    >
                                        <i class="fas fa-download"></i> Download
                                    </button>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div style="width: 250px; margin: 0 auto; text-align: center; padding: 10px;">
                                    <span>Document format Template <b>.csv</b></span>
                                    <button
                                        type="button"
                                        class="btn btn-secondary"
                                        style="margin-right: 10px"
                                        @click="MDownloadTemplateCSV()"
                                    >
                                        <i class="fas fa-download"></i> Download
                                    </button>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div class="row" style="padding: 10px;">
                            <Loader v-if="Loading1"></Loader>
                            <div class="col-md-10">
                                <div>
                                    <h4
                                        style="
                                            padding-bottom: 8px;
                                            margin-top: 20px;
                                            text-align: left;
                                        "
                                    >
                                        Upload Customers <b>XLSX</b>
                                    </h4>
                                    <div
                                        class=""
                                        style="
                                            margin-top: 0px;
                                            width: 330px;
                                            margin-bottom: 20px;
                                        "
                                    >
                                        <input
                                            type="file"
                                            id="file"
                                            ref="file"
                                            class="form-control-file"
                                            @change="onChangeFileUpload"
                                        />
                                        <!-- <input type="file" class="custom-file-input" id="customFileLang"  @change="loadCSV($event)" lang="es"> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <button :disabled="addDisabled" v-on:click="submitForm()" class="btn btn-success ajusBtn">
                                    <i class="fas fa-upload"></i> Start
                                </button>
                            </div>
                        </div>
                        <div class="row" style="padding: 10px;">
                            <Loader v-if="Loading2"></Loader>
                            <div class="col-md-10">
                                <div>
                                    <h4 style="padding-bottom: 8px; margin-top: 20px;">
                                        Upload Customers <b>CSV</b>
                                    </h4>
                                    <div class="" style="margin-top: 0px;width: 330px;margin-bottom: 20px;">
                                        <input type="file" id="file" ref="file" class="form-control-file" @change="onChangeFileUpload2"/>
                                        <!-- <input type="file" class="custom-file-input" id="customFileLang"  @change="loadCSV($event)" lang="es"> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <button :disabled="addDisabled" v-on:click="submitFormCSV()" class="btn btn-success ajusBtn">
                                    <i class="fas fa-upload"></i> Start
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader";
export default {
    name: "Report",
    components: {
        Loader,
    },
    data() {
        return {
            Loading: false,
            isLoading: false,
            Loading1: false,
            Loading2: false,
            attachment: { name: null, file: null },
            customers: [],
            activos: 0,
            inactivos: 0,
            premium : (window.OrgPlan > 1),
            TotalStandard: 5000,
            TotalPremium: 10000,
            TotalEspacio: 0,
            TotalRecord: 0,
            addDisabled: false,
        };
    },
    created() {
        this.TotalEspacio = (this.premium) ? this.TotalPremium : this.TotalStandard;
        this.customcounts();
    },
    methods: {
        submitForm() {
            this.Loading1 = true;
            let formData = new FormData();
            formData.append("name", this.attachment.name);
            formData.append("file", this.attachment.file);
            window.billing
                .post("import/customers/" + window.localuserdata, formData)
                .then((response) => {
                    this.Loading1 = false;
                    if (response.data.status) {
                        this.$swal.fire({
                            icon: "success",
                            title: "Imported",
                            text: "Successful import to verify enter the customers",
                            showConfirmButton: false,
                            timer: 7000,
                        });
                        this.customcounts();
                    }
                })
                .catch((error) => {
                    this.Loading2 = false;
                    console.log(error);
                });
        },
        submitFormCSV() {
            this.Loading2 = true;
            let formData = new FormData();
            formData.append("name", this.attachment.name);
            formData.append("file", this.attachment.file);
            window.billing
                .post("import/customerscsv/" + window.localuserdata, formData)
                .then((response) => {
                    this.Loading2 = false;
                    if (response.data.status) {
                        this.$swal.fire({
                            icon: "success",
                            title: "Imported",
                            text: "Successful import to verify enter the customers",
                            showConfirmButton: false,
                            timer: 7000,
                        });
                        this.customcounts();
                    }
                })
                .catch((error) => {
                    this.Loading2 = false;
                    console.log(error);
                });
        },
        onChangeFileUpload(event) {
            this.attachment.file = event.target.files[0];
        },
        onChangeFileUpload2(event) {
            this.attachment.file = event.target.files[0];
        },
        ExportCustomer() {
            //this.isLoading = true
            console.log("export");
            window.open(
                window.urlbase + "api/v2/report/export/customers/" + window.localuserdata,
                "_blank"
            );
            /*     window.billing.get('report/export/customers/' + window.localuserdata)
                .then((response) => {
                    this.isLoading = false
					 console.log(response.data)
                    window.open(window.urlbase+'api/v2/report/export/customers/'+window.localuserdata , "_blank");
                }).catch((error) => {
                    console.log(error);
                    this.isLoading = false
                }); */
        },
        customcounts() {
            this.isLoading = true;
            window.billing
                // .get("report/customers/" + window.localuserdata)
                .get("report/customcounts/" + window.localuserdata)
                .then((response) => {
                    this.isLoading = false;
                    console.log(response.data);
                    if (response.data.status) {
                        // this.customers = response.data.result;
                        this.activos = response.data.actives;
                        this.inactivos = response.data.inactives;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        MDownloadTemplate() {
            window.open(window.urlbase + "files/customers-format.xlsx", "_blank");
        },
        MDownloadTemplateCSV() {
            window.open(window.urlbase + "files/customers-format.csv", "_blank");
        },
    },
    watch: {
        activos: function(){
            this.TotalRecord = this.activos + this.inactivos;
        },    
        inactivos: function(){
            this.TotalRecord = this.activos + this.inactivos;
        },
        TotalRecord: function () {
            let calEspacio = this.TotalEspacio - this.TotalRecord;
            if(calEspacio<=0){
                this.addDisabled=true;
            }
        },
    },
    computed: {
        // Totalactivos: function () {
        //     return this.customers.filter((r) => r.active === 1 && r.activorestore === 0);
        // },
        // inactivos: function () {
        //     return this.customers.filter((r) => r.active === 0);
        // },
    },
};
</script>
<style lang="scss" scoped>
i.fas.fa-arrow-left {
    font-size: 13px;
}
.ajusBtn{
    margin: 0 auto; 
    text-align: center;
    display: block;
    margin-bottom: 10px;
}
.contenedor-table table thead tr th {
    font-weight: bold;
}
@media (max-width: 1100px) {
    .contenedor-table {
        max-width: 924px;
        overflow-x: scroll;
    }
}
@media (min-width: 1280px) {
    .ctrlbtn {
        width:100px !important;
    }
}
</style>
