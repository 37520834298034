<template>
	<div class="container-fluid">
		<visualizador></visualizador>
	</div>
</template>
<script>
import visualizador from "@/apps/Marketea/components/LandingPage/maquetador/visualizador";
export default {
	name: 'maquetador',
	components: {
		visualizador
	},
	data () {
		return {
		}
	}
}
</script>
<style lang="css" scoped>
</style>
