<template>
    <div>
        <div class="card">
            <div class="card-header">
                TAXES            
            </div>
            <div class="card-body">
                <form @submit.prevent="MetSAddTaxes">
                    <div class="form-row">
                        <div class="col-5">
                            <small id="emailHelp" class="form-text text-muted">
                                State:</small
                            >
                            <select class="form-control" v-model="FormTax.state_id">
                                <option value="0">--State--</option>
                                <option
                                    v-for="state in statesList"
                                    :key="state.id"
                                    v-bind:value="state.id"
                                >
                                    {{ state.name }}
                                </option>
                            </select>
                        </div>
                        <div class="col-2">
                            <small id="emailHelp" class="form-text text-muted">
                                Tax (%)</small
                            >
                            <input
                                type="text"
                                class="form-control"
                                placeholder="0.01"
                                v-model="FormTax.nametaxes"
                            />
                            <input type="hidden" v-model="FormTax.idtax" />
                        </div>
                        <div class="col-3">
                            <small id="emailHelp" class="form-text text-muted">
                                Status:</small
                            >
                            <select class="form-control" v-model="FormTax.status">
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                            </select>
                        </div>
                        <div class="col-2">
                            <small id="emailHelp" class="form-text text-muted"
                                >.</small
                            >
                            <button type="submit" class="btn btn-success">
                                {{ FormTax.btnNameTax }}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: { },
    name: "formTaxes",
    data () {
        return {
            m: {
                show: false,
                component: '',
                data: []
            },
            statesList: [],
            FormTax: {
                btnNameTax: "ADD",
                ActionTaxe: "AddTaxe",
                idtax: null,
                nametaxes: null,
                state_id: 0,
                status: 1,
            },                                    
        }
    },
    created () {
        this.getStates();
        this.init();
    },
    methods: {
        init() {
            let g = this.$store.getters['core/getOpenModal'];
            let r = g.data;            
            this.FormTax.btnNameTax = r.FormTax.btnNameTax;
            this.FormTax.ActionTaxe = r.FormTax.ActionTaxe;
            this.FormTax.idtax = r.FormTax.idtax;
            this.FormTax.nametaxes = r.FormTax.nametaxes;
            this.FormTax.state_id = r.FormTax.state_id;
            this.FormTax.status = r.FormTax.status;
        },        
        getStates() {
            this.isLoadingStates = true;
            window.billing
                .get("states/" + window.localuserdata)
                .then((response) => {
                    this.statesList = response.data.resultStates;
                })
                .catch((error) => console.log(error));
        },        
        MetSAddTaxes() {
            this.$store.commit("core/setLoadingModal", true);
            if (this.FormTax.ActionTaxe === "AddTaxe") {
                window.billing
                    .post("taxes/add", {
                        user_id: window.localuserdata,
                        name: this.FormTax.nametaxes,
                        state_id: this.FormTax.state_id,
                        status: this.FormTax.status,
                    })
                    .then((response) => {
                        console.log(response.data);
                        this.FormTax.nametaxes = null;
                        this.FormTax.state_id = 0;
                        this.FormTax.status = 1;
                        this.NoRecordTaxe = false;
                        this.$notify({
                            group: "noti",
                            title: "Add",
                            type: "success",
                            text: "Add Taxes Successful",
                        });
                        this.$store.commit("core/setLoadingModal", false);
                        this.$store.commit("core/setCloseModal", {action: 'saved'});                         
                    })
                    .catch((error) => {
                        this.$store.commit("core/setLoadingModal", false);
                        console.log(error, (this.isLoading = false))
                    });
            }
            if (this.FormTax.ActionTaxe === "UpdateTaxe") {
                this.$store.commit("core/setLoadingModal", true);
                window.billing
                    .put("taxes/edit/" + this.FormTax.idtax, {
                        user_id: window.localuserdata,
                        idtax: this.FormTax.idtax,
                        name: this.FormTax.nametaxes,
                        state_id: this.FormTax.state_id,
                        status: this.FormTax.status,
                    })
                    .then((response) => {
                        console.log(response.data);
                        this.FormTax.nametaxes = null;
                        this.FormTax.state_id = 0;
                        this.FormTax.btnNameTax = "ADD";
                        this.FormTax.ActionTaxe = "AddTaxe";
                        this.NoRecordTaxe = null;
                        this.isLoadingStates = false;
                        this.$notify({
                            group: "noti",
                            title: "Updated",
                            type: "success",
                            text: "Updated Taxes Successful",
                        });
                        this.$store.commit("core/setLoadingModal", false);
                        this.$store.commit("core/setCloseModal", {action: 'saved'});                        
                    })
                    .catch((error) => {
                        console.log(error, (this.isLoading = false))
                        this.$store.commit("core/setLoadingModal", false);
                    });
            }
        }, 
    },
    watch: { },
    computed: {},
};
</script>

<style lang="css" scoped></style>
