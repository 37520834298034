<template>
    <div id="main-wrapper" data-navbarbg="skin6" data-theme="light" data-layout="vertical" data-sidebartype="full" data-boxed-layout="full">
        <div class="page-wrapper homepage">
            <slot>
                <div class="bgloading" v-if="isLoading"><Loader></Loader></div>
                <div class="container">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 hidden-xs hidden-sm">
                            <infoApp></infoApp>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 formloginhome">
                            <formForgot></formForgot>
                        </div>
                    </div>
                </div>
            </slot> 
        </div>
        <notifications group="noti" position="top center"></notifications>
    </div>
</template>
<script>
import Loader from '@/core/components/shared/Loader'
import formForgot from '@/core/components/Login/formForgot'
import infoApp from '@/core/components/Login/infoApp'
import { mapGetters } from 'vuex'
export default {
        name: 'Forgot',
        components: {
            Loader,
            formForgot,
            infoApp
        },
        data () {
            return {
                isLoading: false,
                urlPage: window.urlweb
            }
    },
    created () {
    },
    methods: {
    },
    watch: {
        'get_isLoading': function() {
            this.isLoading = this.get_isLoading;
            if(this.get_isLoading) {
                this.$notify({
                    group: 'noti',
                    type: 'success',
                    title: 'Accessing',
                    text: 'redirecting...'
                });
            }
        }
    },
    computed: {
        ...mapGetters({get_isLoading: 'get_isLoading'}),
    }
};
</script>
<style lang="css" scoped>
    #main-wrapper{
        overflow-y: hidden;
        overflow-x: hidden;
    }
    .bgloading{
        background-color: #fbfbfb;
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999;
    }
    .container {
        max-width: 1000px;
    }
    .footer{
        position: relative;
        width: 100%;
        margin-top: 10px;
    }
    .page-wrapper.homepage{
        background-color: #f0f2f5;
    }
    .formloginhome{
        max-width: 400px;
        margin: 0 auto;
        margin-top: 50px;
    }
    @media (max-width: 999px) {
        .container {
            max-width: 500px;
        }   
    }
</style>
