var jobs = "/jobs/";
export default {  
	getListByID(id){
		return jobs+"getTemplateByID/" + id;
	}, 
	addTemplate(){
		return jobs+"addTemplate";
	},  
	editTemplate(){
		return jobs+"editTemplate";
	}, 
	getCounte(){
		return jobs+"getJobsCounte/" + window.master_client + "/" + window.localorgdata;
	}, 
	add(){
		return jobs+"editJobsCounte";
	},   
	getSetting(){
		return jobs+"getjobssetting";
	}, 
	editSetting(){
		return jobs+"editjobssetting";
	}, 
	addTask(){
		return jobs+"addJobsTaskAssingUser";
	}, 
	getTask(){
		return jobs+"getJobsTaskAssingUser";
	},
	getJobsTags(){
		return jobs+"getJobsTags/" + window.master_client + "/" + window.localorgdata;
	}, 
	del(id){
		return jobs+"getJobsDel/" + window.master_client + "/" + window.localorgdata + "/" + id;
	},
	addTags(){
		return jobs+"addJobsTags";
	}, 
	editTags(){
		return jobs+"editJobsTags";
	},
	checkMove(){
		return jobs+"ordertags";
	},	
	ordertolist(){
		return jobs+"ordertolist";
	},
	delTemplate(id){
		return jobs+"delTemplate/" + id;
	},	
	getTemplate(){
		return jobs+"getJobsTemplate/" + window.master_client + "/" + window.localorgdata;
	},		 	                            
};
