<template>
    <div>
        <nav class="navbar-expand-md navbar-dark bg-dark" id="headerJobs">
            <div style="display: block; margin: 0 auto; text-align: center">
                <div>
                    <div>
                        <span
                            v-bind:class="[
                                'badge badge-pill badge-light numID desktop',
                                open ? 'active' : '',
                            ]"
                            @click="timesheet()"
                        >
                            <span class="titulo-cont">Timesheet</span>
                        </span>
                        <span
                            v-bind:class="[
                                'badge badge-pill badge-light completed desktop',
                                completed ? 'active' : '',
                            ]"
                            @click="task()"
                        >
                            <span class="titulo-cont">Task</span>
                        </span>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</template>
<script>
export default {
    components: {},
    name: "TimesheetHeader",
    data() {
        return {
            isLoading: false,
            active: true,
            typeU: window.user_type,
            open: true,
            completed: false,
            typez: window.user_type,
        };
    },
    created() {
        this.validAdminApp();
        this.url = this.$router.currentRoute.name;
    },
    methods: {
        validAdminApp() {
            let admin = false;
            this.typez = 2;
            if (window.user_type == 0) {
                admin = true;
            } else {
                for (let i in this.appRolUser) {
                    if (this.appIDactual == this.appRolUser[i].app_id) {
                        if (this.appRolUser[i].rol == 1) {
                            admin = true;
                        }
                    }
                }
            }
            if (admin) {
                this.typez = 0;
                this.privilegies_dash = 1;
            }
        },
        timesheet() {
            this.$router.push({ name: "Timesheet" });
            // if(this.$router.currentRoute.name!='Jobs')
            // {
            //     this.$router.push({name:'Jobs'});
            //     this.open=true;
            //     this.completed=false;
            // }
        },
        task() {
            this.$router.push({ name: "TimesheetTask" });
            // if(this.$router.currentRoute.name!='JobsComppleted')
            // {
            //     this.$router.push({name:'JobsComppleted'});
            //     this.open=false;
            //     this.completed=true;
            // }
        },
    },
    computed: {},
    watch: {},
};
</script>

<style lang="scss" scoped>
span.badge.badge-pill.badge-info.check-done {
    width: 120px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

div.scroll_vertical {
    right: 124px;
    position: fixed;
    margin-top: 31px;
    height: 300px;
    width: 350px;
    overflow: auto;
    border: 1px solid #666;
    background-color: #ccc;
    padding: 8px;
}
div.scroll_vertical ul {
    list-style: none;
}
div.scroll_vertical ul li {
    background-color: #eee;
    color: black;
    display: block;
    padding: 12px;
    text-decoration: none;
    cursor: pointer;
}
div.scroll_vertical ul li:hover {
    background-color: #ccc;
}
.vertical-menus {
    width: 350px;
    height: 300px;
    position: fixed;
    margin-top: 31px;
    right: 124px;
    overflow-x: hidden;
    overflow-y: scroll;
}
.vertical-menus ul li {
    background-color: #eee;
    color: black;
    display: block;
    padding: 12px;
    text-decoration: none;
    cursor: pointer;
}
.vertical-menus .an {
    background-color: #eee;
    color: black;
    display: initial;
    padding: 12px;
    text-decoration: none;
    cursor: pointer;
}
.vertical-menus ul li:hover {
    background-color: #ccc;
}
.vertical-menu {
    width: 350px;
    height: auto;
    position: fixed;
    margin-top: 31px;
    right: 124px;
}
.vertical-menu ul li {
    background-color: #eee;
    color: black;
    display: block;
    padding: 12px;
    text-decoration: none;
    cursor: pointer;
    list-style: none;
}
.vertical-menu .an {
    background-color: #eee;
    color: black;
    display: initial;
    padding: 12px;
    text-decoration: none;
    cursor: pointer;
}
.vertical-menu ul li:hover {
    background-color: #ccc;
}
.vertical-menu ul li.active {
    background-color: #4caf50;
    color: white;
}
#headerJobs {
    top: 50px;
    background-color: #343435 !important;
    padding: 2px 0px 2px 10px;
}
#activeMenuLateral {
    display: block;
    margin-right: 10px;
    border: 0px;
    padding: 0;
}
.titulo-cont {
    font-size: 16px;
}
.num-cont {
    font-size: 16px;
    padding: 0px 10px;
    margin-right: 10px;
    font-family: monospace;
}
span.numID {
    cursor: pointer;
}
.searchbox-open {
    width: 100%;
}
.searchbox {
    position: relative;
    min-width: 35px;
    margin-top: 0px;
    height: 31px;
    width: 0%;
    float: right;
    overflow: hidden;
    -webkit-transition: width 0.3s;
    -moz-transition: width 0.3s;
    -ms-transition: width 0.3s;
    -o-transition: width 0.3s;
    transition: width 0.3s;
}
.form-group {
    margin-bottom: 15px;
}
.searchbox-input {
    border-radius: 5px;
    top: 0;
    right: 0;
    border: 0;
    outline: 0;
    background: #fff;
    width: 100%;
    height: 30px;
    margin: 0;
    padding: 0px 55px 0px 20px;
    font-size: 13px;
    color: black;
    margin-top: 2px;
}
.searchbox-icon,
.searchbox-submit {
    width: 35px;
    height: 35px;
    display: block;
    position: absolute;
    top: 0;
    font-family: verdana;
    font-size: 22px;
    right: 0;
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
    line-height: 35px;
    text-align: center;
    cursor: pointer;
    color: #dcddd8;
    background: #343435;
}
.open.searchbox {
    width: 100% !important;
}
.desktop {
    display: inline-block;
}
.mobile {
    display: none;
}
span.badge.badge-pill.badge-light.completed {
    margin-left: 10px;
}
span.badge.badge-pill.badge-light {
    background-color: transparent;
    border: 1px solid #989898;
    color: #989898;
    padding: 0.5em 2em;
    border-radius: 10px;
    cursor: pointer;
}

span.badge.badge-pill.badge-light.active {
    border: 1px solid #ddd;
    color: #ddd;
}
@media (max-width: 800px) {
    .titulo-cont {
        font-size: 14px;
    }
    span.badge.badge-pill.badge-light {
        padding: 0.5em 1em;
    }
}

@media (max-width: 767px) {
    .desktop {
        display: none;
    }
    .mobile {
        display: initial;
    }
    li.nav-item .nav-link span.mobile {
        margin-left: 5px;
    }
    .searchbox {
        float: left;
        min-width: 84px;
    }
    .searchbox-submit {
        width: 90px;
        left: 0;
        right: initial;
        text-align: left;
    }
    .searchbox-icon {
        right: initial;
        left: -5px;
    }
    .searchbox-submit span.mobile {
        color: #d2d2d2;
        position: absolute;
        margin-left: 30px;
        font-size: 0.875rem;
    }
    .searchbox-input {
        border: 0px;
        box-shadow: none;
        margin-left: 80px;
        width: 74%;
        padding-right: 10px;
    }
}
</style>
