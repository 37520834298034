<template>
    <div>
        <div
            id="main-wrapper"
            data-navbarbg="skin6"
            data-theme="light"
            data-layout="vertical"
            data-sidebartype="full"
            data-boxed-layout="full"
        >
            <Header-Partial></Header-Partial>
            <AsideRestore @activarLateral="showAside = $event"></AsideRestore>
            <div class="page-wrapper" v-bind:class="{ show: showAside }">
                <slot>
                    <div class="page-breadcrumb">
                        <div class="row">
                            <div class="col-5 align-self-center">
                                <h4 class="page-title">Default</h4>
                            </div>
                            <div class="col-7 align-self-center">
                                <div
                                    class="d-flex align-items-center justify-content-end"
                                >
                                    <nav aria-label="breadcrumb">
                                        <ol class="breadcrumb">
                                            <li class="breadcrumb-item"></li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12">
                                <div class="card card-body">
                                    <p>Default content</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </slot>
                <footer-partial></footer-partial>
            </div>
        </div>
    </div>
</template>
<script>
import HeaderPartial from "@/core/views/Header";
import FooterPartial from "@/core/views/Footer";
import AsideRestore from "@/apps/billing/views/setting/AsideRestore";
export default {
    name: "BillingRestore",
    components: {
        HeaderPartial,
        FooterPartial,
        AsideRestore,
    },
    data() {
        return {
            showAside: false,
        };
    },
    created() {
        const accessBilling = { login: true, token: window.localtoken };
        this.$store
            .dispatch("ModApps/billing/updateDataCompany", window.localorgdata)
            .then((response) => {
                this.$store.dispatch("ModApps/billing/access_billing", accessBilling);
            })
            .catch((error) => {
                console.log(error);
                console.log("error al actualizar data Org");
                this.$router.push({ name: "Home" });
            });
    },
};
</script>
