<template>
    <div class="container">
        <div class="row" style="margin-top: 60px">
            <div class="col-12">
                <form @submit.prevent="saveUsers" enctype="multipart/form-data">
                    <div class="card">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-md-6">
                                    <h3 class="card-title">{{ titleFrom }} Employee</h3>
                                </div>
                                <div class="col-md-6 text-right">
                                    <button
                                        type="button"
                                        class="btn btn-light"
                                        @click="clearForm"
                                        style="margin-right: 5px; border: 1px solid #ccc"
                                    >
                                        CANCEL
                                    </button>
                                    <button
                                        type="submit"
                                        class="btn btn-success"
                                        style="margin-right: 5px"
                                        disabeld="disabled"
                                    >
                                        SAVE
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div v-if="isLoading"><Loader></Loader></div>
                            <div v-else class="form-row">
                                <div class="form-group col-md-5 text-center">
                                    <div class="ficha-user">
                                        <div v-if="viewAvatar">
                                            <div v-if="imgAvatar">
                                                <div class="imgAvatar">
                                                    <img
                                                        v-bind:src="avatar"
                                                        id="avatar"
                                                        @click="$refs.avatar.click()"
                                                    />
                                                    <button
                                                        type="button"
                                                        class="btn btn-danger del-avatar"
                                                        style="padding: 2px 5px"
                                                        @click="delAvatar"
                                                        v-if="btnDeleteImg"
                                                    >
                                                        <i class="fas fa-times"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <span
                                                    class="avatarDefault"
                                                    @click="$refs.avatar.click()"
                                                    >{{ avatarDefault }}</span
                                                >
                                            </div>
                                        </div>
                                        <div v-else class="icon-avatar">
                                            <i
                                                class="fas fa-user-circle fa-5x"
                                                @click="$refs.avatar.click()"
                                            ></i>
                                        </div>
                                        <input
                                            type="file"
                                            style="display: none"
                                            ref="avatar"
                                            accept="image/*"
                                            @change="fileImg"
                                        />
                                        <h2
                                            style="margin-top: 10px"
                                            v-if="name || lastname"
                                        >
                                            {{ name }} {{ lastname }}
                                        </h2>
                                        <h2 style="margin-top: 10px" v-else>
                                            Lorem ipsum dolor
                                        </h2>
                                        <p v-if="address || city || state || zip_code">
                                            <span v-if="address != null"
                                                >{{ address }}<br />
                                            </span>
                                            <span v-if="city != null">{{ city }} </span>
                                            <span v-if="state != null">{{ state }} </span>
                                            <span v-if="zip_code != null">{{
                                                zip_code
                                            }}</span>
                                        </p>
                                        <p v-else>
                                            Lorem ipsum dolor sit amet<br />
                                            consectetur adipisicing elit.
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-7">
                                    <div class="form-row">
                                        <div class="form-group col-md-12">
                                            <label for="">Username</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="username"
                                                required
                                            />
                                        </div>
                                        <div class="form-group col-md-12">
                                            <label for="">Name</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="name"
                                                required
                                            />
                                        </div>
                                        <div class="form-group col-md-12">
                                            <label for="">Last name</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="lastname"
                                                required
                                            />
                                        </div>
                                        <div class="form-group col-md-12">
                                            <label for="">Email</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="email"
                                                required
                                            />
                                        </div>
                                        <div
                                            class="form-group col-md-12"
                                            v-if="action === 'create'"
                                        >
                                            <label
                                                for=""
                                                style="
                                                    display: flex;
                                                    justify-content: space-between;
                                                "
                                            >
                                                <span>Password</span>
                                            </label>
                                            <input
                                                type="password"
                                                class="form-control"
                                                minlength="6"
                                                v-model="password"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-md-12">
                                            <label for="">Cell Phone</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="telephone"
                                            />
                                        </div>
                                        <div class="form-group col-md-12">
                                            <label for="inputCity">Role
                                                <span class="badge badge-light addRole" @click="openModal('RolesSetting')">Add+</span>
                                            </label>
                                            <select class="form-control" v-model="rol">
                                                <option value="0">Uncategorized</option>
                                                <option value="9991">Sales Rep</option>
                                                <option value="9992">Inspector</option>
                                                <option value="9993">Supervisor</option>
                                                <option value="9994">Estimator</option>
                                                <option value="9995">Admin</option>
                                                <option value="9996">Project Manager</option>
                                                <option value="9997">KTD</option>
                                                <option value="9997">KTD-Inspector</option>
                                                <option v-for="(i, index) in listRoles" :key="index" :value="i.id">{{i.name}}</option>
                                            </select>
                                        </div>
                                        <div class="form-group col-md-12">
                                            <label for="inputCity">Status</label>
                                            <select class="form-control" v-model="status">
                                                <option value="1">Active</option>
                                                <option value="0">Inactive</option>
                                            </select>
                                        </div>
                                        <!-- <div class="form-group col-md-6">
                                            <label for="inputCity">Address</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="address"
                                            />
                                        </div>
                                        <div class="form-group col-md-4">
                                            <label for="inputCity">City</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="city"
                                            />
                                        </div>
                                        <div class="form-group col-md-4">
                                            <label for="inputCity">State</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="state"
                                            />
                                        </div>
                                        <div class="form-group col-md-4">
                                            <label for="">Zip code</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="zip_code"
                                            />
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <modalLateral>
            <component v-bind:is="m.component"></component>
        </modalLateral>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Loader from '@/core/components/shared/Loader.vue'
import RolesSetting from '@/core/components/Account/Users/RolesSetting.vue'
import modalLateral from "@/core/plugins/themeModalLateral/modalLateral";
export default {
    name: 'Form',
    components: {
        Loader,
        RolesSetting,
        modalLateral
    },
    data () {
        return {
            isLoading: false,
            viewAvatar: false,
            imgAvatar: false,
            validimg: false,
            btnDeleteImg: false,
            avatarDefault: false,
            borrarAvatar: false,
            avatar_old: '',
            user_id: window.localAccountID,
            action: 'create',
            titleFrom: 'New',
            id: null,
            account_id: null,
            name: '',
            lastname: '',
            username: '',
            email: '',
            password: '',
            address: '',
            city: '',
            state: '',
            rol: 0,
            zip_code: '',
            telephone: '',
            avatar: '',
            status: 1,
            disabled: false,
            changepassword: false,
            listRoles: [],
            m: {
                show: false,
                component: "",
                data: [],
            },
        }
    },
    created () {
        if (this.$store.getters.getUserType === 2 && !this.$route.params.profile) {
            this.$router.push({ name: 'Home' })
        }
        if (this.$route.params.id && this.$route.params.edit) {
            this.getUsersById()
        }else if(this.action =='create'){
            this.getRoles(0);
        }
    },
    methods: { 
        openModal (nom) {
            this.m = {
                show: true,
                component: nom,
                data: {},
            };
            this.$store.commit("core/setOpenModal", this.m);
        },      
        getRoles (load) {
            let t = this;
            if(load==0){t.isLoading = true;}
            t.listRoles=[];
            window.master.post('user/getroles', {
                user_id: t.user_id
            })
            .then((response) => {
                t.listRoles = response.data.roles;
                t.isLoading = false;
            })
            .catch((error) => {
                console.log(error);
                t.isLoading = false
            })
        },
        getUsersById () {
            let t = this;
            t.isLoading = true;
            t.listRoles=[];
            window.master
                .get('user/' + t.user_id + '/' + t.$route.params.id)
                .then((response) => {
                    t.listRoles = response.data.roles;
                    const url = response.data.url;
                    const i = response.data.user_detail;
                    const access = response.data.access;
                    t.action = 'update';
                    t.titleFrom = 'Edit';
                    t.id = i.id;
                    t.account_id = i.account_id;
                    t.name = i.firstname;
                    t.lastname = i.lastname;
                    t.username = access.username;
                    t.email = i.email;
                    t.address = i.address;
                    t.city = i.city;
                    t.state = i.state;
                    t.zip_code = i.zip_code;
                    t.telephone = i.telephone;
                    t.status = i.status;
                    t.avatar = url + i.avatar;
                    t.avatar_old = i.avatar;
                    t.imgAvatar = i.imgAvatar;
                    t.btnDeleteImg = i.imgAvatar;
                    t.rol = i.rol;
                    t.isLoading = false;
                })
                .catch((error) => {
                    console.log(error)
                    t.isLoading = false
                })
        },
        saveUsers () {
            this.isLoading = true
            this.disabled = true
            console.log('save ', this.action)
            var f = new FormData()
            f.append('user_id', window.master_client) // master
            f.append('id', this.id)
            f.append('account_id', this.account_id)
            f.append('name', this.name)
            f.append('lastname', this.lastname)
            f.append('username', this.username)
            f.append('email', this.email)
            f.append('password', this.password)
            f.append('changepassword', this.changepassword)
            f.append('address', this.address)
            f.append('city', this.city)
            f.append('state', this.state)
            f.append('zip_code', this.zip_code)
            f.append('telephone', this.telephone)
            f.append('status', this.status)
            f.append('rol', this.rol)
            f.append('avatar_old', this.avatar_old)
            f.append('borrarAvatar', this.borrarAvatar)
            f.append('validimg', this.validimg)
            if (this.validimg === true) {
                f.append(
                    'avatar',
                    event.target.elements[3].files[0],
                    event.target.elements[3].files[0].name
                )
            }
            let ruta = '/user/add'
            if (this.action === 'update') {
                ruta = 'user/update'
            }
            window.master
                .post(ruta, f)
                .then((response) => {
                    if (response.data.status) {
                        this.$store.commit('core/setNotiPush', {
                            show: true,
                            group: "notiPush",
                            type: '1', // 1=success|2=info|3=error|4=loading|5=warning
                            title: 'Success',
                            text: 'Saved Successful.',
                            duration: 3000
                        });
                        this.clearForm(response.data.account_id)
                    } else {
                        this.$store.commit('core/setNotiPush', {
                            show: true,
                            group: "notiPush",
                            type: '3', // 1=success|2=info|3=error|4=loading|5=warning
                            title: 'Error',
                            text: 'An account with that email already exists.',
                            duration: 10000
                        });
                    }
                    this.isLoading = false
                    this.disabled = false
                })
                .catch((error) => {
                    console.log(error)
                    this.isLoading = false
                    this.disabled = false
                })
        },
        clearForm (account_id) {
            if (account_id > 0) {
                this.$router.push({ name: 'Usersave', params: { 'user_id': account_id }})
            } else {
                this.$router.push({ name: 'Users' })
            }
        },
        fileImg (event) {
            this.validimg = true
            this.viewAvatar = true
            this.imgAvatar = true
            this.btnDeleteImg = true
            if (event.target.files.length > 0) {
                var reader = new FileReader()
                reader.readAsDataURL(event.target.files[0])
                reader.onload = function (e) {
                    document.querySelector('#avatar').src = e.target.result
                }
            }
            this.validAvatarDefault()
        },
        delAvatar () {
            this.imgAvatar = false
            this.validimg = false
            this.btnDeleteImg = false
            this.borrarAvatar = true
            this.avatar = ''
            this.$refs.avatar.value = ''
            this.validAvatarDefault()
        },
        validAvatarDefault () {
            if (!this.validimg) {
                this.avatarDefault = ''
                if (this.name != null && this.name.length > 0 && !this.imgAvatar) {
                    this.viewAvatar = true
                    this.avatarDefault = this.name.charAt().toUpperCase()
                } else if (this.imgAvatar) {
                    this.viewAvatar = true
                } else {
                    this.viewAvatar = false
                }
                if (
                    this.lastname != null &&
                    this.lastname.length > 0 &&
                    !this.imgAvatar
                ) {
                    this.avatarDefault += this.lastname.charAt().toUpperCase()
                    this.viewAvatar = true
                }
            }
        }
    },
    watch: {
        name () {
            this.validAvatarDefault()
        },
        lastname () {
            this.validAvatarDefault()
        },
        getPusher: function(){
            let t = this;
            if (t.getPusher != undefined) {
                if(t.getPusher.action == "actuUsersRoles" && t.getPusher.data.user_id == window.master_client){
                    console.log('getPusher', t.getPusher);
                    t.getRoles(1);
                }
            }
        },
    },
    computed: {
        ...mapGetters("core", { 
            getPusher: "get_accitonPusher"
        }),
    }
};
</script>
<style lang="css" scoped>
span.avatarDefault {
    width: 100px;
    height: 100px;
    display: flex;
    margin: 0px auto;
    border: 1px solid #ccc;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 60px;
    font-weight: 900;
    background-color: #5ac146;
    color: #fff;
}
img#avatar {
    width: 100px;
}
.icon-avatar {
    font-size: 20px;
}
.imgAvatar {
    height: 110px;
    width: 110px;
    border: 6px double #ccc;
    border-radius: 50%;
    margin: auto;
    overflow: hidden;
}
img#avatar:hover {
    cursor: pointer;
}
.imgAvatar:hover button.btn.btn-danger.del-avatar {
    display: initial;
}
button.btn.btn-danger.del-avatar {
    position: absolute;
    top: 90px;
    font-size: 10px;
    border-radius: 50%;
    margin-left: -8px;
    display: none;
}
.ficha-user {
    width: 100%;
    max-width: 300px;
    margin: auto;
    border: 1px solid #ddd;
    padding: 10px;
    position: relative;
    margin-top: 60px;
    background: #fcfcfc;
    position: relative;
}
.ficha-user::before {
    content: "";
    position: absolute;
    top: -80px;
    left: 0;
    width: 0;
    height: 0;
    border-left: 150px solid transparent;
    border-right: 150px solid transparent;
    border-bottom: 80px solid #ddd;
}
.ficha-user:after {
    content: "";
    position: absolute;
    bottom: -81px;
    left: 0;
    width: 0;
    height: 0;
    border-left: 150px solid transparent;
    border-right: 150px solid transparent;
    border-top: 80px solid #ddd;
}
.form-control {
    border: 1px solid #bbb;
}
.addRole{
    color: #42b1ff;
}
.addRole:hover{
    cursor: pointer;
}
</style>
