let refreshListByJobs = function(pusher) {
	let t=this;
	if(t.accessJobsApp) {
		//crear copia de listado
		localStorage.setItem('InvoicesList', JSON.stringify(t.InvoicesList));
		let list = JSON.parse(localStorage.getItem('InvoicesList'));
		let d = pusher.item;
		let idJobs =0;
		let updateList = false;
		if (pusher.action == "jobs-assign-invo"){idJobs = pusher.item.id;}
		else if (pusher.action == "update-status"){idJobs = pusher.item.job;}
		else {idJobs = pusher.item;}
		for (let i in list) {
			if (pusher.action == "jobs-assign-invo") {
				if (pusher.pusher_action == "assign"){
					if(list[i] && list[i].id == pusher.invo) {
						console.log('pusher_action', pusher.pusher_action);
						list[i].job.id = idJobs;
						list[i].job.id_status = d.id_status;
						if(d.id_status == 1){ list[i].job.statusName='Pending'; }
						if(d.id_status == 2){ list[i].job.statusName='In progress'; }
						if(d.id_status == 4){ list[i].job.statusName='Completed'; }
						updateList=true;
					}
				}else{
					if(list[i] && list[i].job.id == idJobs) {
						console.log('delete jobs: inv='+list[i].id);
						list[i].job.id = 0;
						updateList=true;
					}
				}
			}
			if (pusher.action == "update-status" || pusher.action == "delete-job") {
				if(list[i] && list[i].job.id == idJobs) {
					if (pusher.action == "update-status"){
						list[i].job.id_status = d.job_status;
						if(d.job_status == 1){ list[i].job.statusName='Pending'; }
						if(d.job_status == 2){ list[i].job.statusName='In progress'; }
						if(d.job_status == 4){ list[i].job.statusName='Completed'; }
						updateList=true;
						console.log('item refresh by jobs: inv='+list[i].id+' job='+idJobs);
					}
					if (pusher.action == "delete-job"){
						console.log('delete jobs: inv='+list[i].id);
						list[i].job.id = 0;
						updateList=true;
					}
				}
			}
		}
		console.log('updateList', updateList);
		if(updateList){
			t.InvoicesList = list;
		}
		localStorage.removeItem('InvoicesList');
	}
};
export default refreshListByJobs;
