let checkSubmenu = function(e) {		
	//console.log('checkSubmenu', e)
	let submenus = document.querySelectorAll('.' + e.target.classList[0]);
	submenus.forEach(function(i){
		if(i != e.target){
			i.checked = false;
		}else{
			console.log('checkSubmenu', i.classList[1], i.checked)
		}
	});
};
export default checkSubmenu;
