<template>
    <div class="card formvendor">
        <div class="bgloading" v-if="isLoading"><Loader></Loader></div>
        <form class="form-horizontal form-material" @submit.prevent="MetNewVendorsAdd">
            <div class="card-header">
                <div class="row">
                    <div class="col-md-6">
                        <b style="font-size: 21px; font-weight: 100">
                            {{ NewCustomer.titleactionForm }} Vendor
                        </b>
                    </div>
                    <div class="col-md-6">
                        <div class="d-block text-right" style="width: 100%">
                            <input
                                type="hidden"
                                class="form-control"
                                v-model="NewCustomer.id"
                                name="idcustomer"
                            />
                            <button
                                type="submit"
                                class="btn btn-success btn-lg b-block BtnSave"
                            >
                                <i class="fas fa-save"></i> Save
                            </button>
                            <button
                                type="button"
                                class="btn btn-light btn-lg b-block Btncancel"
                                @click="MetCancelFormC"
                                v-if="ShowBtncancel"
                            >
                                <i class="fas fa-window-close"></i> Cancel
                            </button>
                            <button
                                type="button"
                                class="btn btn-light btn-lg b-block Btncancel"
                                @click="MetCancelFormC"
                                v-else
                            >
                                <i class="fas fa-window-close"></i> Cancel
                            </button>
                            <button
                                type="button"
                                @click="MetDeleteVendor(NewCustomer.id)"
                                v-if="ShowBtnDel"
                                class="btn btn-danger btn-lg b-block BtnDel"
                            >
                                <i class="fas fa-trash-alt"></i> Delete
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12 col-lg-6">
                        <div class="row">
                            <div class="col-md-12 col-lg-12 col-xl-6">
                                <div class="form-group">
                                    <label class="">Company Name</label>
                                    <input
                                        type="text"
                                        v-model="NewCustomer.company_name"
                                        class="form-control"
                                        required
                                    />
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-12 col-xl-6">
                                <div class="form-group">
                                    <label class="">Title</label>
                                    <select
                                        class="form-control"
                                        name="referal"
                                        v-model="NewCustomer.title"
                                    >
                                        <option value="0" selected>-- Select --</option>
                                        <option value="Dr.">Dr.</option>
                                        <option value="Miss.">Miss.</option>
                                        <option value="Mr.">Mr.</option>
                                        <option value="Mrs.">Mrs.</option>
                                        <option value="Ms.">Ms.</option>
                                        <option value="Prof.">Prof.</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6">
                                <div class="form-group">
                                    <label class="">First Name</label>
                                    <input
                                        type="text"
                                        v-model="NewCustomer.firstname"
                                        class="form-control"
                                    />
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-12 col-xl-6">
                                <div class="form-group">
                                    <label class="">Middle Name</label>
                                    <input
                                        type="text"
                                        name="bussinesname"
                                        v-model="NewCustomer.middletname"
                                        class="form-control"
                                    />
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-12 col-xl-12">
                                <div class="form-group">
                                    <label class="">Last Name</label>
                                    <input
                                        type="text"
                                        name="bussinesname"
                                        v-model="NewCustomer.lastname"
                                        class="form-control"
                                    />
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-12 col-xl-12">
                                <div class="form-group">
                                    <label class="">Display Name As</label>
                                    <select
                                        class="form-control"
                                        name="referal"
                                        v-model="NewCustomer.displayname"
                                    >
                                        <option
                                            v-for="listValue in valuesListDisplayname"
                                            :key="listValue.id"
                                            :selected="
                                                NewCustomer.displayname == listValue.id
                                            "
                                            :value="listValue.id"
                                        >
                                            {{ listValue.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-12 col-xl-6">
                            <div class="form-group">
                                <label class="">Print on Check As</label>
                                <input
                                    type="text"
                                    name="bussinesname"
                                    v-model="NewCustomer.printoncheck"
                                    class="form-control"
                                />
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-12 col-xl-6">
                            <div class="form-group">
                                <label class="">Status</label>
                                <select class="form-control" v-model="NewCustomer.status">
                                    <option value="1" selected>Active</option>
                                    <option value="0">Inactive</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-6 col-xl-6">
                        <div class="row">
                            <div class="col-md-12 col-lg-12">
                                <div class="form-group">
                                    <label class="">Email</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="NewCustomer.email"
                                        name="email"
                                    />
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-12 col-xl-6">
                                <div class="form-group">
                                    <label class=""> Phone</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="NewCustomer.phone"
                                        name="officephone"
                                    />
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-12 col-xl-6">
                                <div class="form-group">
                                    <label class="">Mobile Phone</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="NewCustomer.mobilephone"
                                        name="mobilephone"
                                    />
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="">Fax</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="NewCustomer.fax"
                                        name=""
                                    />
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="">Other</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="NewCustomer.other"
                                        name=""
                                    />
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="">Website</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="NewCustomer.website"
                                        name="website"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div style="display: block; margin-bottom: 0px"></div>
                <div class="row">
                    <div
                        class="col-md-12"
                        style="background-color: #f1f1f1; padding-top: 10px"
                    >
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="">Address</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="NewCustomer.adressinfo"
                                    />
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-12 col-xl-4">
                                <div class="form-group">
                                    <label class="">City</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        name=""
                                        v-model="NewCustomer.cityinfo"
                                    />
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-12 col-xl-4">
                                <div class="form-group">
                                    <label class="">State</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        name=""
                                        v-model="NewCustomer.stateinfo"
                                    />
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-12 col-xl-4">
                                <div class="form-group">
                                    <label class="">Zip code</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        name=""
                                        v-model="NewCustomer.zipcodeinfo"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader";
import { mapGetters } from "vuex";
export default {
    name: "vendorForm",
    components: {
        Loader,
    },
    data() {
        return {
            isLoading: false,
            valuesListDisplayname: [
                { name: null, id: 1 },
                { name: null, id: 2 },
                { name: null, id: 3 },
                { name: null, id: 4 },
            ],
            NewCustomer: {
                id: 0,
                titleactionForm: "",
                user_id: "",
                company_name: "",
                title: "",
                firstname: "",
                middletname: "",
                lastname: "",
                displayname: 1,
                printoncheck: "",
                status: 1,
                email: "",
                phone: "",
                mobilephone: "",
                fax: "",
                other: "",
                website: "",
                adressinfo: "",
                cityinfo: "",
                stateinfo: "",
                zipcodeinfo: "",
            },
            ActionType: null,
            ShowBtnDel: false,
            ShowBtncancel: false,
            vendorSelect: null,
            vendorOP: null,
            vendorID: 0,
        };
    },
    created() {
        this.vendorOP = this.$store.getters['ModApps/billing/vendorOP'];
        // this.vendorOP = this.$store.state.billing.vendorOP;
        this.vendorID = this.$store.getters['ModApps/billing/vendorSelect'];
        // this.vendorID = this.$store.state.billing.vendorSelect;
    },
    methods: {
        showformregister() {
            this.ActionType = "new";
            this.NewCustomer.titleactionForm = "New";
            this.ShowBtnDel = false;
            this.ShowBtncancel = false;
            this.clearForms();
            this.isLoading = false;
        },
        MetNewVendorsAdd() {
            this.isLoading = true;
            if (this.ActionType == "new") {
                window.billing
                    .post("vendors/add", {
                        user_id: window.localuserdata,
                        company_name: this.NewCustomer.company_name,
                        title: this.NewCustomer.title,
                        firstname: this.NewCustomer.firstname,
                        middletname: this.NewCustomer.middletname,
                        lastname: this.NewCustomer.lastname,
                        displayname: this.NewCustomer.displayname,
                        printoncheck: this.NewCustomer.printoncheck,
                        status: this.NewCustomer.status,
                        email: this.NewCustomer.email,
                        phone: this.NewCustomer.phone,
                        mobilephone: this.NewCustomer.mobilephone,
                        fax: this.NewCustomer.fax,
                        other: this.NewCustomer.other,
                        website: this.NewCustomer.website,
                        adressinfo: this.NewCustomer.adressinfo,
                        cityinfo: this.NewCustomer.cityinfo,
                        stateinfo: this.NewCustomer.stateinfo,
                        zipcodeinfo: this.NewCustomer.zipcodeinfo,
                    })
                    .then((response) => {
                        this.isLoading = false;
                        this.$notify({
                            group: "noti",
                            title: "Created",
                            type: "success",
                            text: "Created successfull",
                        });
                        this.MetCancelFormC();
                    })
                    .catch((error) => console.log(error, (this.isLoading = false)));
            }
            if (this.ActionType == "update") {
                window.billing
                    .put("vendors/edit/" + this.NewCustomer.id, {
                        user_id: window.localuserdata,
                        company_name: this.NewCustomer.company_name,
                        title: this.NewCustomer.title,
                        firstname: this.NewCustomer.firstname,
                        middletname: this.NewCustomer.middletname,
                        lastname: this.NewCustomer.lastname,
                        displayname: this.NewCustomer.displayname,
                        printoncheck: this.NewCustomer.printoncheck,
                        status: this.NewCustomer.status,
                        email: this.NewCustomer.email,
                        phone: this.NewCustomer.phone,
                        mobilephone: this.NewCustomer.mobilephone,
                        fax: this.NewCustomer.fax,
                        other: this.NewCustomer.other,
                        website: this.NewCustomer.website,
                        adressinfo: this.NewCustomer.adressinfo,
                        cityinfo: this.NewCustomer.cityinfo,
                        stateinfo: this.NewCustomer.stateinfo,
                        zipcodeinfo: this.NewCustomer.zipcodeinfo,
                    })
                    .then((response) => {
                        this.isLoading = false;
                        this.$notify({
                            group: "noti",
                            title: "Updated Ok",
                            type: "success",
                            text: "Updated successfull",
                        });
                        this.MetCancelFormC();
                    })
                    .catch((error) => console.log(error, (this.isLoading = false)));
            }
        },
        MetCancelFormC() {
            this.clearForms();
            this.$store.commit("ModApps/billing/set_vendorOP", 1);
        },
        MetDeleteVendor(id) {
            console.log(id);
            window.billing
                .post("vendors/delete", { id: id })
                .then((response) => {
                    console.log(response.data.success);
                    if (response.data.success) {
                        console.log("deeted");
                        this.$notify({
                            group: "noti",
                            title: "Deleted",
                            type: "success",
                            text: "Deleted successfull",
                        });
                        this.MetCancelFormC();
                    } else {
                        this.$notify({
                            group: "noti",
                            title: "Error",
                            type: "error",
                            text:
                                "This vendor have attached documents and can not be deleted",
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        MetEditVendors() {
            console.log("edit form");
            this.ActionType = "update";
            this.NewCustomer.titleactionForm = "Edit";
            this.NewCustomer.id = this.vendorSelect.id;
            this.NewCustomer.user_id = this.vendorSelect.user_id;
            this.NewCustomer.company_name = this.vendorSelect.company_name;
            this.NewCustomer.title = this.vendorSelect.title;
            this.NewCustomer.firstname = this.vendorSelect.firstname;
            this.NewCustomer.middletname = this.vendorSelect.middletname;
            this.NewCustomer.lastname = this.vendorSelect.lastname;
            this.NewCustomer.displayname = this.vendorSelect.displayname;
            this.NewCustomer.printoncheck = this.vendorSelect.printoncheck;
            this.NewCustomer.status = this.vendorSelect.status;
            this.NewCustomer.email = this.vendorSelect.email;
            this.NewCustomer.phone = this.vendorSelect.phone;
            this.NewCustomer.mobilephone = this.vendorSelect.mobilephone;
            this.NewCustomer.fax = this.vendorSelect.fax;
            this.NewCustomer.other = this.vendorSelect.other;
            this.NewCustomer.website = this.vendorSelect.website;
            this.NewCustomer.adressinfo = this.vendorSelect.adressinfo;
            this.NewCustomer.cityinfo = this.vendorSelect.cityinfo;
            this.NewCustomer.stateinfo = this.vendorSelect.stateinfo;
            this.NewCustomer.zipcodeinfo = this.vendorSelect.zipcodeinfo;
            this.isLoading = false;
            this.ShowBtnDel = true;
            this.ShowBtncancel = true;
        },
        getVendorbyID(id) {
            window.billing
                .get("vendors/" + id + "/" + window.localuserdata)
                .then((response) => {
                    this.isLoading = false;
                    if (response.data.success) {
                        this.vendorSelect = response.data.result;
                        console.log(this.vendorSelect);
                        this.MetEditVendors();
                    }
                })
                .catch((error) => console.log(error));
        },
        clearForms() {
            this.NewCustomer = {
                id: 0,
                titleactionForm: "",
                user_id: "",
                company_name: "",
                title: "",
                firstname: "",
                middletname: "",
                lastname: "",
                displayname: 1,
                printoncheck: "",
                status: 1,
                email: "",
                phone: "",
                mobilephone: "",
                fax: "",
                other: "",
                website: "",
                adressinfo: "",
                cityinfo: "",
                stateinfo: "",
                zipcodeinfo: "",
            };
        },
    },
    watch: {
        vendorOP: function (val) {
            console.log("op = ", val);
            this.isLoading = true;
            if (this.vendorOP == 2) {
                this.showformregister();
            } else if (this.vendorOP == 3 && this.vendorID != null && this.vendorID > 0) {
                console.log("vendorSelect = ", this.vendorID);
                this.getVendorbyID(this.vendorID);
            } else {
                this.MetCancelFormC();
            }
        },
        "NewCustomer.company_name": function (val) {
            this.NewCustomer.printoncheck = val;
            this.valuesListDisplayname[0].name = val;
        },
        "NewCustomer.title": function (val) {
            this.valuesListDisplayname[0].name =
                this.NewCustomer.company_name + " " + val;
        },
        "NewCustomer.firstname": function (val) {
            this.valuesListDisplayname[0].name =
                this.NewCustomer.company_name + " " + this.NewCustomer.title + " " + val;
        },
        "NewCustomer.middletname": function (val) {
            this.valuesListDisplayname[0].name =
                this.NewCustomer.company_name +
                " " +
                this.NewCustomer.title +
                " " +
                this.NewCustomer.firstname +
                " " +
                val;
        },
        "NewCustomer.lastname": function (val) {
            this.valuesListDisplayname[0].name =
                this.NewCustomer.company_name +
                " " +
                this.NewCustomer.title +
                " " +
                this.NewCustomer.firstname +
                " " +
                this.NewCustomer.middletname +
                " " +
                val;
            this.valuesListDisplayname[1].name =
                this.NewCustomer.firstname + " " + this.NewCustomer.lastname;
            this.valuesListDisplayname[2].name =
                this.NewCustomer.lastname + " " + this.NewCustomer.firstname;
            this.valuesListDisplayname[3].name = this.NewCustomer.company_name;
        },
    },
};
</script>

<style lang="scss" scoped>
.formvendor {
    margin-bottom: 50px;
}
</style>
