<template>
    <div>
        <div class="page-breadcrumb">
            <div class="row">
                <div class="col-5 align-self-center">
                    <h4 class="page-title">{{ titleName }} Subcontractor</h4>
                </div>
                <div class="col-7 align-self-center">
                    <div class="d-flex align-items-center justify-content-end">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link
                                        :to="{ name: 'Employees' }"
                                        class="btn btn-info btn-sm mr-2"
                                    >
                                        Back</router-link
                                    >
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-8 offset-sm-2">
                    <div class="card">
                        <div class="card-body">
                            <form
                                class="form-horizontal form-material"
                                @submit.prevent="MetNewEmployeesAdd"
                                enctype="multipart/form-data"
                                id="form1"
                            >
                                <div class="card-header">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div
                                                class="custom-control custom-radio custom-control-inline"
                                            >
                                                <input
                                                    type="radio"
                                                    id="custom2"
                                                    checked
                                                    name=""
                                                    class="custom-control-input"
                                                    value="1"
                                                    v-model="NewCustomer.type"
                                                />
                                                <label
                                                    class="custom-control-label"
                                                    for="custom2"
                                                    >Individual</label
                                                >
                                            </div>
                                            <div
                                                class="custom-control custom-radio custom-control-inline"
                                            >
                                                <input
                                                    type="radio"
                                                    id="custom"
                                                    name=""
                                                    class="custom-control-input"
                                                    value="2"
                                                    v-model="NewCustomer.type"
                                                />
                                                <label
                                                    class="custom-control-label"
                                                    for="custom"
                                                >
                                                    Corporation</label
                                                >
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div
                                                class="d-block text-right"
                                                style="width: 100%"
                                            >
                                                <input
                                                    type="hidden"
                                                    class="form-control"
                                                    v-model="NewCustomer.id"
                                                    name="idcustomer"
                                                />
                                                <button
                                                    type="submit"
                                                    class="btn btn-success btn-lg b-block BtnSave"
                                                >
                                                    <i class="fas fa-save"></i> Save
                                                </button>
                                                <!--       <button type="button" class="btn btn-light btn-lg b-block Btncancel" @click="MetCancelFormC" v-if="ShowBtncancel"><i class="fas fa-window-close"></i> Cancel</button>
                                                    <button type="button" class="btn btn-light btn-lg b-block Btncancel" @click="MetCancelFormC" v-else><i class="fas fa-window-close"></i> Cancel</button> -->
                                                <router-link
                                                    :to="{ name: 'Employees' }"
                                                    class="btn btn-light btn-lg b-block Btncancel"
                                                    ><i class="fas fa-window-close"></i>
                                                    Cancel</router-link
                                                >
                                                <!-- <button type="button" @click="MetDeleteCustomer(DetailVendorsItem.id)" v-if="ShowBtnDel" class="btn btn-danger btn-lg b-block BtnDel"><i class="fas fa-trash-alt"></i> Delete</button> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="bgloading" v-if="isLoading">
                                        <Loader></Loader>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 col-lg-6">
                                            <div class="row">
                                                <div
                                                    class="col-md-12 col-lg-12"
                                                    v-if="NewCustomer.type == 2"
                                                >
                                                    <div class="form-group">
                                                        <label class=""
                                                            >Business Name:</label
                                                        >
                                                        <input
                                                            type="text"
                                                            v-model="
                                                                NewCustomer.business_name
                                                            "
                                                            class="form-control"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-lg-6 col-xl-6">
                                                    <div class="form-group">
                                                        <label class="">Title:</label>
                                                        <select
                                                            class="form-control"
                                                            name="referal"
                                                            v-model="NewCustomer.title"
                                                        >
                                                            <option value="" selected>
                                                                -- Select --
                                                            </option>
                                                            <option value="Dr.">
                                                                Dr.
                                                            </option>
                                                            <option value="Miss.">
                                                                Miss.
                                                            </option>
                                                            <option value="Mr.">
                                                                Mr.
                                                            </option>
                                                            <option value="Mrs.">
                                                                Mrs.
                                                            </option>
                                                            <option value="Ms.">
                                                                Ms.
                                                            </option>
                                                            <option value="Prof.">
                                                                Prof.
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-lg-6">
                                                    <div class="form-group">
                                                        <label class=""
                                                            >First Name:</label
                                                        >
                                                        <input
                                                            type="text"
                                                            v-model="
                                                                NewCustomer.firstname
                                                            "
                                                            class="form-control"
                                                            :required="isrequired"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-lg-6 col-xl-6">
                                                    <div class="form-group">
                                                        <label class=""
                                                            >Middle Name:</label
                                                        >
                                                        <input
                                                            type="text"
                                                            name="bussinesname"
                                                            v-model="
                                                                NewCustomer.middletname
                                                            "
                                                            class="form-control"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-lg-6 col-xl-6">
                                                    <div class="form-group">
                                                        <label class="">Last Name:</label>
                                                        <input
                                                            type="text"
                                                            name="bussinesname"
                                                            v-model="NewCustomer.lastname"
                                                            class="form-control"
                                                            :required="isrequired"
                                                        />
                                                    </div>
                                                </div>
                                                required
                                                <div
                                                    class="col-md-6 col-lg-12 col-xl-12"
                                                    v-if="NewCustomer.type == 1"
                                                >
                                                    <div class="form-group">
                                                        <label class=""
                                                            >Display Name As:</label
                                                        >
                                                        <!-- :selected="NewCustomer.displayname == listValue.id" -->
                                                        <select
                                                            class="form-control"
                                                            v-model="
                                                                NewCustomer.displayname
                                                            "
                                                        >
                                                            <option
                                                                v-for="listValue in valuesListDisplayname"
                                                                :key="listValue.id"
                                                                :selected="
                                                                    NewCustomer.displayname ==
                                                                    listValue.id
                                                                "
                                                                :value="listValue.id"
                                                            >
                                                                {{ listValue.name }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div
                                                    class="col-md-12"
                                                    v-if="NewCustomer.type == 1"
                                                >
                                                    <div class="form-group">
                                                        <label class=""
                                                            >Default Rate:</label
                                                        >
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            v-model="
                                                                NewCustomer.default_rate
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    class="col-md-12 col-lg-12 col-xl-12"
                                                >
                                                    <div class="form-group">
                                                        <label class=""
                                                            >Print on Check As:</label
                                                        >
                                                        <input
                                                            type="text"
                                                            name="bussinesname"
                                                            v-model="
                                                                NewCustomer.printoncheck
                                                            "
                                                            class="form-control"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 col-lg-6 col-xl-6">
                                            <div class="row">
                                                <div
                                                    class="col-md-12 col-lg-12"
                                                    v-if="NewCustomer.type == 2"
                                                >
                                                    <div class="form-group">
                                                        <label class="">EIN #:</label>
                                                        <input
                                                            type="text"
                                                            v-model="NewCustomer.ein"
                                                            class="form-control"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-md-12 col-lg-12">
                                                    <div class="form-group">
                                                        <label class="">Email:</label>
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            v-model="NewCustomer.email"
                                                            name="email"
                                                        />
                                                    </div>
                                                </div>

                                                <div
                                                    class="col-md-12 col-lg-12 col-xl-12"
                                                >
                                                    <div class="form-group">
                                                        <label class=""> Phone:</label>
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            v-model="NewCustomer.phone"
                                                            name="officephone"
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    class="col-md-12 col-lg-12 col-xl-12"
                                                    v-if="NewCustomer.type == 1"
                                                >
                                                    <div class="form-group">
                                                        <label class=""> DOB:</label>
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            v-model="NewCustomer.dob"
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    class="col-md-12 col-lg-12 col-xl-12"
                                                >
                                                    <div
                                                        class="form-group"
                                                        v-if="displayUploadingBtn"
                                                    >
                                                        <label class="">
                                                            W-9 Browse File:</label
                                                        >
                                                        <input
                                                            type="file"
                                                            class="form-control-file"
                                                            ref="img1"
                                                            accept="image/*"
                                                            @change="fileImg1"
                                                        />
                                                    </div>
                                                    <div style="position: relative">
                                                        <span
                                                            style="
                                                                position: absolute;
                                                                top: 0;
                                                                left: 0;
                                                                cursor: pointer;
                                                                background: #444;
                                                                color: #fff;
                                                                width: 83px;
                                                                padding: 3px;
                                                                height: auto;
                                                                font-size: 13px;
                                                            "
                                                            v-if="ActionType == 'update'"
                                                            @click="delImg1"
                                                            >Change IMG</span
                                                        >
                                                        <img
                                                            v-bind:src="preview1"
                                                            id="img1"
                                                            style="
                                                                width: 100%;
                                                                max-width: 200px;
                                                            "
                                                            v-if="existimage"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div style="display: block; margin-bottom: 0px"></div>
                                    <div class="row">
                                        <div
                                            class="col-md-12"
                                            style="
                                                background-color: #f1f1f1;
                                                padding-top: 10px;
                                            "
                                        >
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="">Address</label>
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            v-model="
                                                                NewCustomer.adressinfo
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-md-12 col-lg-12 col-xl-4">
                                                    <div class="form-group">
                                                        <label class="">City</label>
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            name=""
                                                            v-model="NewCustomer.cityinfo"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-md-12 col-lg-12 col-xl-4">
                                                    <div class="form-group">
                                                        <label class="">State</label>
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            name=""
                                                            v-model="
                                                                NewCustomer.stateinfo
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-md-12 col-lg-12 col-xl-4">
                                                    <div class="form-group">
                                                        <label class="">Zip code</label>
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            name=""
                                                            v-model="
                                                                NewCustomer.zipcodeinfo
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ClickOutside from "vue-click-outside";
import Loader from "@/core/components/shared/Loader";
export default {
    name: "EmployeesNew",
    components: {
        Loader,
    },
    data() {
        return {
            isLoading: false,
            titleName: "New",
            valuesListDisplayname: [
                { id: 1, name: "" },
                { id: 2, name: "" },
                { id: 3, name: "" },
            ],
            isLoadingCustomers: false,
            ActionType: "new",
            NewCustomer: {
                id: null,
                titleactionForm: null,
                user_id: null,
                company_name: null,
                business_name: null,
                ein: null,
                type: 1, // 1=Individual 2= corporation
                title: "",
                firstname: "",
                middletname: "",
                lastname: "",
                displayname: 1,
                printoncheck: "",
                dob: "",
                email: "",
                phone: "",
                fax: "",
                other: "",
                default_rate: 0,
                adressinfo: "",
                cityinfo: "",
                stateinfo: "",
                zipcodeinfo: "",
                img1: null,
                imgOld1: null,
            },
            validimg1: false,
            validimgOld1: false,
            Showimg1: false,
            preview1: "",
            existimage: false,
            folder_user: "",
            files: {
                img1: null,
                img2: null,
            },
            isrequired: true,
            displayUploadingBtn: true,
        };
    },
    created() {
        if (this.$route.params.subcontractor_id) {
            this.titleName = "Edit";
            this.ActionType = "update";
            this.displayUploadingBtn = false;
            this.editSubcontractor(this.$route.params.subcontractor_id);
        }
    },
    mounted() {},
    directives: {
        ClickOutside,
    },
    methods: {
        /* onChangeFileUpload(event){
              this.attachment.file = event.target.files[0]
        }, */
        /*   fileImg1(event){
        this.NewCustomer.img1 = event.target.files[0];
        this.validimg1 = true;
        this.Showimg1=false;
        if(event.target.files && event.target.files[0])
        {
          var reader = new FileReader();
          reader.readAsDataURL(event.target.files[0]);
         /*  reader.onload = function (e) {
            document.querySelector("#img1").src=e.target.result;
          } 
        }
      }, */

        delImg1() {
            this.files.img1 = null;
            this.validimg1 = false;
            this.validimgOld1 = true;
            this.Showimg1 = false;
            this.existimage = false;
            this.preview1 = "";
            this.NewCustomer.img1 = null;
            this.displayUploadingBtn = true;
        },
        hide() {
            this.ShowMenuDropCus = false;
        },
        editSubcontractor(subcontractor_id) {
            window.billing
                .get("employees/" + subcontractor_id + "/" + window.localuserdata)
                .then((response) => {
                    console.log("sucontractor detail");
                    console.log(response.data);
                    if (response.data.success) {
                        this.NewCustomer.id = response.data.result.id;
                        this.NewCustomer.user_id = response.data.result.user_id;
                        this.NewCustomer.title =
                            response.data.result.title == "null"
                                ? ""
                                : response.data.result.title;
                        this.NewCustomer.type = response.data.result.type;
                        this.NewCustomer.default_rate = response.data.result.default_rate;
                        this.NewCustomer.firstname =
                            response.data.result.firstname == "null"
                                ? ""
                                : response.data.result.firstname;
                        this.NewCustomer.middletname =
                            response.data.result.middletname == "null"
                                ? ""
                                : response.data.result.middletname;
                        this.NewCustomer.lastname =
                            response.data.result.lastname == "null"
                                ? ""
                                : response.data.result.lastname;
                        this.NewCustomer.printoncheck =
                            response.data.result.printoncheck == "null"
                                ? ""
                                : response.data.result.printoncheck;
                        this.NewCustomer.email =
                            response.data.result.email == "null"
                                ? ""
                                : response.data.result.email;
                        this.NewCustomer.phone =
                            response.data.result.phone == "null"
                                ? ""
                                : response.data.result.phone;
                        this.NewCustomer.ein =
                            response.data.result.ein == "null"
                                ? ""
                                : response.data.result.ein;
                        this.NewCustomer.business_name =
                            response.data.result.business_name == "null"
                                ? ""
                                : response.data.result.business_name;
                        this.NewCustomer.displayname = Number.parseInt(
                            response.data.result.displayname
                        );
                        this.NewCustomer.adressinfo =
                            response.data.result.adressinfo == "null"
                                ? ""
                                : response.data.result.adressinfo;
                        this.NewCustomer.cityinfo =
                            response.data.result.cityinfo == "null"
                                ? ""
                                : response.data.result.cityinfo;
                        this.NewCustomer.stateinfo =
                            response.data.result.stateinfo == "null"
                                ? ""
                                : response.data.result.stateinfo;
                        this.NewCustomer.zipcodeinfo =
                            response.data.result.zipcodeinfo == "null"
                                ? ""
                                : response.data.result.zipcodeinfo;
                        this.NewCustomer.dob = response.data.result.dob;
                        if (
                            response.data.result.image_url !== null &&
                            response.data.result.image_url !== ""
                        ) {
                            this.Showimg1 = true;
                            this.existimage = true;
                            this.NewCustomer.imgOld1 = response.data.result.image_url;
                            this.preview1 =
                                response.data.folder_user +
                                response.data.result.image_url;
                        }
                    }
                })
                .catch((error) => console.log(error));
        },
        fileImg1(event) {
            let file = "";
            if (this.files.img1 != null) {
                file = this.files.img1;
            } else {
                file = event.target.files[0];
            }
            this.NewCustomer.img1 = file;
            this.validimg1 = true;
            this.Showimg1 = true;
            this.existimage = true;
            if (file) {
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function (e) {
                    document.querySelector("#img1").src = e.target.result;
                };
            }
        },
        MetNewEmployeesAdd() {
            let t = this;
            t.isLoading = true;
            let f = new FormData();
            let contIMG = 0;
            let contDel = 0;
            for (let i in t.NewCustomer) {
                if (i == "img1" && t.NewCustomer[i] !== null && t.validimg1 == true) {
                    contIMG++;
                    let nom = "img" + contIMG;
                    f.append(nom, t.NewCustomer[i], t.NewCustomer[i].name);
                }

                if (
                    i == "imgOld1" &&
                    t.NewCustomer[i] !== null &&
                    t.validimgOld1 == true
                ) {
                    contDel++;
                    let nom = "imgOld" + contDel;
                    f.append(nom, t.NewCustomer[i]);
                }
            }
            f.append("nombreIMG", "img");
            f.append("contIMG", contIMG);
            f.append("delIMG", "imgOld");
            f.append("contDel", contDel);
            f.append("folder", "w-9");
            f.append("org_id", window.localorgdata);
            window.master
                .post("upload", f)
                .then((response) => {
                    let imgs = response.data.imgs;
                    this.MetNewEmployeesAdd2(imgs);
                    //this.saveData(imgs)
                })
                .catch((error) => {
                    this.isLoading = false;
                    console.log(error);
                });
        },
        MetNewEmployeesAdd2(imgs) {
            this.isLoading = true;
            var self = this;
            self.isLoading = true;
            var f = new FormData();
            for (let prop in self.NewCustomer) {
                console.log(prop, self.NewCustomer[prop]);
                if (
                    prop == "img1" &&
                    self.NewCustomer[prop] !== null &&
                    self.validimg1 == true
                ) {
                    f.append(prop, imgs[1]);
                } else {
                    f.append(prop, self.NewCustomer[prop]);
                }
            }
            if (self.validimg1 == true) {
                f.append("validimg1", 1);
            } else {
                f.append("validimg1", 0);
            }
            if (self.validimgOld1 == true) {
                f.append("validimgOld1", 1);
            } else {
                f.append("validimgOld1", 0);
            }
            f.append("user_id", window.localuserdata);
            f.append("folder_user", localStorage.getItem("folder_user"));
            if (this.ActionType == "new") {
                window.billing
                    .post("employees/add", f)
                    .then((response) => {
                        this.isLoading = false;
                        this.$router.push({ name: "Employees" });
                        this.$notify({
                            group: "noti",
                            title: "Created",
                            type: "success",
                            text: "Created successfull",
                        });

                        //this.clearForms();
                    })
                    .catch((error) => console.log(error, (this.isLoading = false)));
            }
            if (this.ActionType == "update") {
                console.log("edit action");
                window.billing
                    .post("employees/edit", f)
                    .then((response) => {
                        this.isLoading = false;
                        this.$router.push({ name: "Employees" });
                        this.$notify({
                            group: "noti",
                            title: "Updated",
                            type: "success",
                            text: "Updated successfull",
                        });
                    })
                    .catch((error) => console.log(error, (this.isLoading = false)));
            }
        },
    },
    watch: {
        "NewCustomer.title": function (val) {
            if (this.NewCustomer.type == 1) {
                this.NewCustomer.printoncheck =
                    val +
                    " " +
                    this.NewCustomer.firstname +
                    " " +
                    this.NewCustomer.lastname;
            }
            this.valuesListDisplayname[0].name =
                val + " " + this.NewCustomer.firstname + " " + this.NewCustomer.lastname;
        },
        "NewCustomer.firstname": function (val) {
            if (this.NewCustomer.title !== null && this.NewCustomer.type == 1) {
                this.NewCustomer.printoncheck = this.NewCustomer.title + " " + val;
            }
            this.valuesListDisplayname[0].name = this.NewCustomer.title + " " + val;
        },
        "NewCustomer.middletname": function (val) {
            if (this.NewCustomer.title !== null && this.NewCustomer.type == 1) {
                this.NewCustomer.printoncheck =
                    this.NewCustomer.title + " " + this.NewCustomer.firstname + " " + val;
            }
            if (this.NewCustomer.middletname !== null) {
                this.valuesListDisplayname[0].name =
                    this.NewCustomer.title + " " + this.NewCustomer.firstname + " " + val;
            }
        },
        "NewCustomer.lastname": function (val) {
            if (
                this.NewCustomer.title !== null &&
                this.NewCustomer.middletname !== null &&
                this.NewCustomer.type == 1
            ) {
                this.NewCustomer.printoncheck =
                    this.NewCustomer.title +
                    " " +
                    this.NewCustomer.firstname +
                    " " +
                    this.NewCustomer.middletname +
                    " " +
                    val;
            } else if (this.NewCustomer.title == null && this.NewCustomer.type == 1) {
                this.NewCustomer.printoncheck = this.NewCustomer.firstname + " " + val;
            } else if (
                this.NewCustomer.middletname == null &&
                this.NewCustomer.title !== null &&
                this.NewCustomer.type == 1
            ) {
                this.NewCustomer.printoncheck =
                    this.NewCustomer.title + " " + this.NewCustomer.firstname + " " + val;
            }
            if (
                this.NewCustomer.title !== null &&
                this.NewCustomer.middletname !== null
            ) {
                this.valuesListDisplayname[0].name =
                    this.NewCustomer.title +
                    " " +
                    this.NewCustomer.lastname +
                    " " +
                    this.NewCustomer.firstname +
                    " " +
                    this.NewCustomer.middletname +
                    " " +
                    val;
            } else if (
                this.NewCustomer.title == null &&
                this.NewCustomer.middletname == null
            ) {
                this.valuesListDisplayname[0].name =
                    this.NewCustomer.firstname + " " + this.NewCustomer.lastname;
            }
            this.valuesListDisplayname[0].name =
                this.NewCustomer.firstname + " " + this.NewCustomer.lastname;
            this.valuesListDisplayname[1].name =
                this.NewCustomer.firstname + " " + this.NewCustomer.lastname;
            this.valuesListDisplayname[2].name =
                this.NewCustomer.lastname + " " + this.NewCustomer.firstname;
        },
        "NewCustomer.type": function () {
            if (this.NewCustomer.type == 2) {
                this.isrequired = false;
                this.NewCustomer.printoncheck = this.NewCustomer.business_name;
            } else {
                this.isrequired = true;
                this.NewCustomer.printoncheck = this.valuesListDisplayname[0].name;
            }
        },
        "NewCustomer.business_name": function () {
            if (this.NewCustomer.type == 2) {
                this.NewCustomer.printoncheck = this.NewCustomer.business_name;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.eboton i {
    font-size: 12px;
}
.eboton {
    padding: 5px 10px;
    font-size: 14px;
    margin-left: 10px;
    color: #fff;
    background-color: #137eff !important;
    border-color: #137eff !important;
}
#btnGroupDrop1 {
    height: 26px;
    padding-top: 0px;
}
button.btn.btn-success.btn-new {
    padding: 0px;
    width: 26px;
    height: 26px;
}
.Btncancel {
    background-color: #fff;
    border-color: #000;
}
.Btncancel,
.BtnSave,
.BtnDel {
    border-radius: 2px;
}
.Btncancel,
.BtnSave,
.BtnDel {
    margin-left: 5px;
    padding: 5px 10px;
    font-size: 14px;
}
.contenedor-total {
    padding: 0rem;
}
.alert_totales {
    display: inline-block;
    padding: 0.2rem 0.5rem;
    width: 50%;
    text-align: center;
}
.alert_totales h1 span {
    font-size: 20px;
}
.tabla-transaction {
    width: 100%;
}
@media (max-width: 1190px) {
    .tabla-transaction {
        max-width: 800px;
        overflow-x: scroll;
    }
    .tabla-transaction table {
        min-width: 800px;
    }
    .alert_totales {
        display: block;
        width: 100%;
        text-align: right;
    }
}
@media (max-width: 767px) {
    .list-group.list-group-flush {
        height: 240px;
    }
}
.balance {
    color: #ff0000;
}
.btn-transaction {
    color: #fff !important;
    background-color: #137eff !important;
    border-color: #137eff !important;
    border-radius: 2px !important;
}
</style>
