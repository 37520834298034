<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-3">
				<h3 class="card-title">
					Organizations 
				</h3>
				<p>
					<span v-if="contadorOrgsActives >= 1 && contadorOrgsSuspended >= 1" class="filter">
						<span v-if="statusA == false" class="filter" @click="filterStatusC(1, 'a')">
							Actives: <span class="badge badge-success" style="margin-right: 10px">{{contadorOrgsActives}}</span>
						</span>
						<span v-else class="filter filtery" @click="filterStatusC(9999)">
							Actives: <span class="badge badge-success filterz">{{contadorOrgsActives}}</span>
							<span class="badge filterx">
								X
							</span>	
						</span>
					</span>
					<span v-else>
						<span>
							Actives: <span class="badge badge-success" style="margin-right: 10px">{{contadorOrgsActives}}</span>
						</span>
					</span>					
					<span v-if="contadorOrgsActives >= 1 && contadorOrgsSuspended >= 1" class="filter">
						<span v-if="statusB == false" class="filter" @click="filterStatusC(0, 'b')">
							Deleted:  <span class="badge badge-danger">{{contadorOrgsSuspended}}</span> 
						</span>
						<span v-else class="filter filtery" @click="filterStatusC(9999)">
							Deleted: <span class="badge badge-danger filterz">{{contadorOrgsSuspended}}</span>
							<span class="badge filterx">
								X
							</span>	
						</span>	
					</span>
					<span v-else>
						<span>
							Deleted: <span class="badge badge-danger filterz">{{contadorOrgsSuspended}}</span>
						</span>
					</span>
				</p>
			</div>
			<div class="col-md-3">			
				<h3 class="card-title">
					Plans:
				</h3>
				<p>
					<span v-if="contadorStandar >= 1 && contadorPremium >= 1" class="filter">
						<span v-if="planA == false" class="filter" @click="filterPlanStatus(1, 'a')">
							Standard: <span class="badge badge-secondary" style="margin-right: 10px">{{contadorStandar}}</span>
						</span>
						<span v-else class="filter filtery" @click="filterPlanStatus(9999)">
							Standard: <span class="badge badge-secondary filterz">{{contadorStandar}}</span>
							<span class="badge filterx">
								X
							</span>	
						</span>
					</span>	
					<span v-else>
						<span>
							Standard: <span class="badge badge-secondary filterz">{{contadorStandar}}</span>
						</span>
					</span>	
					<span v-if="contadorStandar >= 1 && contadorPremium >= 1" class="filter">								
						<span v-if="planB == false" class="filter" @click="filterPlanStatus(2, 'b')">
							Premium:  <span class="badge badge-warning badgeOro">{{contadorPremium}}</span> 
							<img src="@/core/assets/img/premium.png" style="height: 20px;"/>
						</span>
						<span v-else class="filter filtery" @click="filterPlanStatus(9999)">
							Premium: <span class="badge badge-warning badgeOro filterz">{{contadorPremium}}</span>
							<img src="@/core/assets/img/premium.png" style="height: 20px;"/>
							<span class="badge filterx">
								X
							</span>	
						</span>
					</span>	
					<span v-else>
						<span>
							Premium: <span class="badge badge-warning badgeOro filterz">{{contadorPremium}}</span>
							<img src="@/core/assets/img/premium.png" style="height: 20px;"/>
						</span>
					</span>										
				</p>
			</div>
			<!--<div class="col-md-3">			
				<h3 class="card-title">
					Billing:
				</h3>
				<p>
					<span v-if="contadorPaid >= 1 && contadorUnpaid >= 1" class="filter">
						<span v-if="billingA == false" class="filter" @click="filterBillingStatus(1, 'a')">
							Paid: <span class="badge badge-success" style="margin-right: 10px">{{contadorPaid}}</span>
						</span>	
						<span v-else class="filter filtery" @click="filterBillingStatus(9999)">
							Paid: <span class="badge badge-success filterz">{{contadorPaid}}</span>
							<span class="badge filterx">
								X
							</span>	
						</span>	
					</span>
					<span v-else>
						Paid: <span class="badge badge-success filterz">{{contadorPaid}}</span>
					</span>	
					<span v-if="contadorPaid >= 1 && contadorUnpaid >= 1" class="filter">								
						<span v-if="billingB == false" class="filter" @click="filterBillingStatus(0, 'b')">
							Unpaid: <span class="badge badge-secondary" style="margin-right: 10px">{{contadorUnpaid}}</span>	
						</span>
						<span v-else class="filter filtery" @click="filterBillingStatus(9999)">
							Unpaid: <span class="badge badge-secondary filterz">{{contadorUnpaid}}</span>
							<span class="badge filterx">
								X
							</span>	
						</span>
					</span>
					<span v-else>
						Unpaid: <span class="badge badge-secondary filterz">{{contadorUnpaid}}</span>
					</span>
				</p>
			</div>-->
			<div class="col-md-6">
				<h3 class="card-title">
					Search:
				</h3>
				<div class="row">			
					<input
						type="text"
						class="form-control"
						v-model="TextSearch"
						placeholder="Name organizations"
						style="border: 1px solid #c9c9ca;"
					/>	
				</div>				
			</div>
		</div>
        <div class="row">
			<div class="card-body tdynamic">
				<div class="content-table-tdynamic" >				
					<table class="table-tdynamic" id="tableID">
						<thead>
							<tr>
								<th scope="col" class="colMore"><i class="far fa-plus-square"></i></th>
								<th scope="col" class="colId">#</th>
								<th scope="col">Organization</th>
								<th scope="col">Account</th>
								<th scope="col" class="colDates">Creation</th>
								<th scope="col" class="colDates">Last Activity Login</th>
								<th scope="col" class="colPlan"								
									v-if="contadorStandar >= 1 && contadorPremium >= 1"
									@click="filterPlan()"
									v-bind:class="[
										'filter',
										(resetplanFilter && planFilter==0) ? 'filterOff' : 
										(!resetplanFilter && planFilter==1) ? 'filterOn bg-secondary unpaid' : 
										(!resetplanFilter && (planFilter==2 || planFilter==3)) ? 'filterOn bg-warning primium' : '',
									]"									
								>Plan</th>
								<th v-else scope="col" class="colPlan">Plan</th>								
								<th scope="col" class="colPlan text-right">Amount</th>
								<!--<th scope="col" class="colStatus"								
									v-if="contadorPaid >= 1 && contadorUnpaid >= 1"
									@click="filterBilling()"
									v-bind:class="[
										'filter',
										(resetbillingFilter && billingFilter==0) ? 'filterOff' : 
										(!resetbillingFilter && billingFilter==0) ? 'filterOn bg-secondary unpaid' : 
										(!resetbillingFilter && billingFilter==1) ? 'filterOn bg-success paid' : '',
									]"								
								>								
									Billing
								</th>
								<th v-else scope="col" class="colStatus">								
									Billing
								</th>-->								
								<th scope="col" class="text-center">Renewal Date</th>
								<!-- <th scope="col" class="text-center">Interval</th> -->
								<th scope="col" class="colApps">Apps</th>
								<th scope="col" class="colStatus"
									v-if="contadorOrgsActives >= 1 && contadorOrgsSuspended >= 1"
									@click="filterStatus()"
									v-bind:class="[
										'filter',
										(resetstatusFilter && statusFilter==0) ? 'filterOff' : 
										(!resetstatusFilter && statusFilter==1) ? 'filterOn bg-success' : 
										(!resetstatusFilter && statusFilter==0) ? 'filterOn bg-danger' : '',
									]"								
								>							
									Status
								</th>
								<th v-else scope="col" class="colStatus">							
									Status
								</th>								
								<th scope="col" class="colActions">Actions</th>
							</tr>
						</thead>
						<tbody v-if="isLoading === true">
							<tr>
								<th colspan="12" style="text-align: center">
									<loader></loader>
								</th>
							</tr>
						</tbody>
						<tbody v-else-if="result.length === 0">
							<tr>
								<th colspan="12" style="text-align: center">
									No record
								</th>
							</tr>
						</tbody>
						<tbody v-else>
							<template v-for="(i, index) in filtered"  >
								<tr :key="index" 
									v-bind:class="['row-tr-'+index, (i.status==2) ? 'hide' : '']"
								>
									<td class="colMore" @click="tMore(i, index)">
										<i class="far fa-plus-square" v-if="i.moreOpen=='hide'"></i>
										<i class="far fa-minus-square" v-else></i>
									</td>
									<td class="colId">{{i.id}}</td>
									<td>
										<b>{{i.bill_company_name}}</b><br>
										{{i.bill_firstname}} {{ i.bill_lastname }} 
										<br v-if="i.bill_firstname || i.bill_lastname">
										<br v-if="i.status == 0">
										<span class="badge badge-danger" v-if="i.status==0 || i.status==2">Deleted</span> 
									</td>	
									<td>
										<span v-if="Object.keys(i.info).length>0" >	
											<b  v-if="i.com_company_name !='' && i.com_company_name !='null'">
												{{i.com_company_name}}
											</b> 
											<br v-if="i.com_company_name !='' && i.com_company_name !='null'">
											<span v-if="i.info.verifyEmail==1"><i class="fa fa-lg fa-check-circle" style="color:#5ac146;font-size: 10px;"></i></span>
											<span v-else><i class="fa fa-lg fa-question-circle" style="color:#f92e06;font-size: 10px;"></i></span>										
											#{{ i.user_id }}
											<span v-if="i.info.billing.bill_firstname!='' && i.info.billing.bill_firstname!='null'">{{ i.info.billing.bill_firstname+' '}}</span>
											<span v-if="i.info.billing.bill_lastname!='' && i.info.billing.bill_lastname!='null'">{{ i.info.billing.bill_lastname }}</span>                                
											<br v-if="(i.info.billing.bill_firstname!='' && i.info.billing.bill_firstname!='null') || (i.info.billing.bill_lastname!='' && i.info.billing.bill_lastname!='null') ">	
											{{i.info.userEmail}} <br>
											{{i.info.userPhone}}					
											<span class="badge badge-danger" v-if="i.info.billing.active==0">Blocked</span> 										
										</span>							
									</td>								
									<td class="colDates">
										<span>
											{{ i.created_date }}<br>{{ i.created_time }}
										</span>							
									</td>	
									<td class="colDates">
										<span v-if="Object.keys(i.info).length>0" >
											{{ i.info.lastActivity_date }}<br>{{ i.info.lastActivity_time }}
										</span>							
									</td>								
									<td class="colPlan" @click="modalaResOrgs('Plan', i)" style="cursor:pointer">
										<span v-if="i.info.plan_id == 1" class="badge badge-secondary no-veri">
											Standard
										</span>	
										<span v-else>
											<span class="badge badge-warning badgeOro">
												Premium
											</span>	
											<img src="@/core/assets/img/premium.png" style="height: 20px;"/>
										</span>	
										<!--<span v-else-if="i.info.plan_id == 3">
											<span class="badge badge-info">
												Sponsored
											</span>	
										</span>-->																																
									</td>		
									<td class="text-right" > 
										<span v-if="i.statusPay != 2">
											${{i.info.plan_amount}}
										</span>
										<span v-else>
											$0.00
										</span>
									</td>		
									<!--<td class="colStatus"> 
										<span v-if="i.statusPay == 2" v-bind:class="['badge',
												(i.statusPay==0) ? 'badge badge-secondary' : '',
												(i.statusPay==1) ? 'badge badge-success' : '',
												(i.statusPay==2) ? 'badge badge-danger' : '',	
											]" style="padding: 4px; border-radius: 3px;">
											{{i.statusPayName}}
										</span>
										<span v-else class="cdropdown" v-bind:class="['badge',
											(i.statusPay==0) ? 'badge badge-secondary' : '',
											(i.statusPay==1) ? 'badge badge-success' : '',
											(i.statusPay==2) ? 'badge badge-danger' : '',	
										]">
											<button style="border-top: 0px solid black !important;" v-bind:class="['dropx', 'badge',
												(i.statusPay==0) ? 'badge badge-secondary' : '',
												(i.statusPay==1) ? 'badge badge-success' : '',	
												(i.statusPay==2) ? 'badge badge-danger' : '',											
											]">
													{{i.statusPayName}}
											</button>
											<label>
												<input 
													type="checkbox" 
													class="submenu" 
													@click="checkSubmenu($event)" 
												/>
												<ul style="top:25px !important; width: 70px !important;">
													<li v-for="(s, sindex) in listPay" 
														:key="sindex"
														@click="changeStatusPay(i.user_id, i.id, s.statusID, i)"
														v-if="s.statusID==2"
														style="padding: 0px !important; min-width: 0px !important;"
														>
														<span
															v-bind:class="[
																'badge',
																(s.statusID==0) ? 'badge badge-secondary' : '', 
																(s.statusID==1) ? 'badge badge-success' : '',
																(s.statusID==2) ? 'badge badge-danger' : '',
															]"
															>{{s.nameStatus}}</span
														>
													</li>																							
												</ul>								
											</label>
										</span>	
									</td>	-->													
									<td class="text-center">
										<span v-if="i.renewal_interval == 1" style="cursor:pointer">
											{{i.renewal_mensual}}th
										</span>	
										<span v-if="i.renewal_interval == 2" style="cursor:pointer">
											{{i.renewal_anual}}
										</span>	
										<br>
										<span  v-if="i.renewal_interval == 1" style="cursor:pointer">
											Monthly
										</span>	
										<span  v-if="i.renewal_interval == 2" style="cursor:pointer">
											Yearly
										</span>																											
									</td>
									<!-- <td class="text-center">
										<span  v-if="i.renewal_interval == 1" style="cursor:pointer">
											Monthly
										</span>	
										<span  v-if="i.renewal_interval == 2" style="cursor:pointer">
											Yearly
										</span>																
									</td>								 -->
									<td class="colApps" >
										<span v-if="Object.keys(i.apps).length>0" >
											<span v-for="(j, index) in i.apps" :key="index" class="badge badge-info" style="margin-right: 5px;cursor:pointer;" @click="modalaResAssignApp(i, j.id)">
												{{ j.pref }}
											</span>
										</span>         
										<span v-else class="badge badge-danger" @click="modalaResAssignApp(i, 0)" style="cursor:pointer;">None</span>
									</td>
									<td class="colStatus">
										<div class="action_btn_status">
		                                    <span @click="activeStatus(index)"
												v-bind:class="[
													'statusSelected badge',
													(i.status==1) ? 'badge-success active' : '', 
													(i.status==0) ? 'badge-danger inactive' : '',
												]"
		                                        style="cursor: pointer"
											>
												<span v-if="i.status==1">Active</span>
												<span v-else>Deleted</span>
												<!-- <i class="fas fa-angle-down"></i> -->
											</span>
		                                    <!-- <span
		                                        class="status_list"
		                                        v-if="statusActive == index"
		                                    >
		                                        <ul class="reset" style="padding-left: 0; width: 70px;">
		                                            <li style="padding: 0px; min-width:0px;"													
		                                            	v-for="(s, sindex) in statuslist" 
		                                            	:key="sindex"
		                                            	@click="changeStatus(s.statusID, i.user_id, i.id)"
														v-if="s.statusID != i.status"
		                                            	>											
		                                                <span
		                                                    v-bind:class="[
																'badge',
																(s.statusID==1) ? 'badge-success active' : '', 
																(s.statusID==0) ? 'badge-danger inactive' : '',
															]"
		                                                    >{{s.nameStatus}}</span
		                                                >
		                                            </li>
		                                        </ul>
		                                    </span> -->
		                                </div>
									</td>
									<td class="colActions">
										<span class="cdropdown">
											<button><i class="fas fa-cog"></i></button>
											<label>
												<input 
													type="checkbox" 
													class="submenu" 
													@click="checkSubmenu($event)" 
												/>
												<ul>
													<li  @click="modalaResOrgs('addEditOrg', i)">
														<i class="fa fa-lg fa-edit"></i> Edit
													</li>
													<li  @click="modalaResAssignApp(i, 0)">
														<i class="fas fa-lg fa-th"></i> Assing App
													</li>
													<li  @click="modalaResOrgs('Plan', i)">
														<i class="fas fa-gifts"></i> Plan
													</li>												
												</ul>								
											</label>
										</span>								
									</td>
								</tr>
								<tr v-if="i.moreOpen=='show'" class="moreDetails" :key="index+'show'">
									<td colspan="14" class="text-center">
										<div class="row content-tr-extension">
											<div class="col-md-6">
												<h5 style="font-weight: bold;">List Users:</h5>
												<table class="usersbyorg">
													<thead>
														<tr>
															<th class="text-center">ID</th>
															<th>Username</th>
															<th>Name</th>
															<th>Lastname</th>
															<th class="text-center">Status</th>
														</tr>
													</thead>
													<tbody v-if="i.userbyOrgs.length>0">
														<template v-for="(usec, ukey) in i.userbyOrgs">
															<tr :key="ukey">
																<td class="text-center">{{usec.userSecID}}</td>
																<td>{{usec.userSecEmail}}</td>
																<td>{{usec.userSecFirstname}}</td>
																<td>{{usec.userSecLastnamename}}</td>
																<td class="text-center">
																	<span v-if="usec.userSecStatus==0" class="badge badge-danger">Inactive</span>
																	<span v-if="usec.userSecStatus==1" class="badge badge-success">Sctive</span>				
																</td>
															</tr>
														</template>
													</tbody>
													<tbody v-else>
														<tr>
															<td colspan="5" class="text-center">No record.</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</td>
								</tr>
							</template>
						</tbody>
					</table>
				</div>
			</div>
		</div>
        <modalLateral v-show="activeModalOrgs">
            <component v-bind:is="m.component"></component>
        </modalLateral>		
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import renewal from "@/core/components/Reseller/Orgs/renewal";
import Plan from "@/core/components/Reseller/Orgs/Plan";
import addEditOrg from "@/core/components/Reseller/Orgs/addEditOrgs";
import AssignAppsR from "@/core/components/Reseller/Orgs/AssignAppsR";
import modalLateral from "@/core/plugins/themeModalLateral/modalLateral";
import Loader from '@/core/components/shared/Loader'
export default {
	name: 'OrgbyReseller',
	components: {
		Loader,
		modalLateral,
		addEditOrg,
		AssignAppsR,
		Plan,
		renewal,
	},		
	data () {
		return {
			isLoading: false,
			result:[],
			OrgLists:[],
			f:{
				resellerId:window.resellerId,
			},
			statusActive: null,
			statuslist: [],
			activeModalOrgs: false,
			listPay:[],
			statusPayActive:null,
			contadorOrgsActives: 0,
			contadorOrgsSuspended: 0,
			contadorStandar: 0,
			contadorPremium: 0,
			contadorPaid: 0,
			contadorUnpaid: 0,
			contadorCancelled: 0,
            m: {
                show: false,
                component: '',
                data: []
            },
			TextSearch:'',
			oldList: [],
			filterList: [],	
			planA: false,
			planB: false,
			billingA: false,	
			billingB: false,	
			statusA: false,
			statusB: false,							
			planFilter: null,
			statusFilter: null,
			billingFilter: null,
			resetplanFilter: true,
			resetstatusFilter: true,
			resetbillingFilter: true,		
			listCancelled: false,
		}
	},
	created(){
		console.log('OrgbyReseller')
		this.getOrg();
	},
    methods: {	
		filterAlpha(type){
			let t = this;
			let listNew = [];						
			for (let e in t.filterList) {
				if(t.filterList[e]) {
					let insert = false;	
					// se filtra por status --principal--					
					if (type == 'status') {
						// start ==== status contador
						if (t.statusFilter == null && t.planFilter == null && t.billingFilter == null) {
							insert = true;
						}							
						if (t.statusFilter == null && t.planFilter == null && parseInt(t.billingFilter) >= 0) {
							if(parseInt(t.billingFilter) == parseInt(t.filterList[e].statusPay)) { insert = true; }	
						}
						if (t.statusFilter == null && parseInt(t.planFilter) >= 0 && t.billingFilter == null) {													
							if(parseInt(t.planFilter) == 1) { 
								if(parseInt(t.planFilter) == parseInt(t.filterList[e].info.plan_id)) { insert = true; }
							}
							if(parseInt(t.planFilter) == 2) { 													
								if(parseInt(t.filterList[e].info.plan_id) == 2 || parseInt(t.filterList[e].info.plan_id) == 3) { insert = true; }	
							}								
						}
						if (t.statusFilter == null && parseInt(t.planFilter) >= 0 && parseInt(t.billingFilter) >= 0) {							
							if(parseInt(t.planFilter) == 1) { 
								if(parseInt(t.billingFilter) == parseInt(t.filterList[e].statusPay) && parseInt(t.filterList[e].info.plan_id) == parseInt(t.planFilter)) { insert = true; }	
							}
							if(parseInt(t.planFilter) == 2) { 		
								if(parseInt(t.billingFilter) == parseInt(t.filterList[e].statusPay) && (parseInt(t.filterList[e].info.plan_id) == 2 || parseInt(t.filterList[e].info.plan_id) == 3)) { insert = true; }																				
							}							
						}						
						// end ==== status contador

						// start ==== status
						if (t.billingFilter == null && t.planFilter == null) {
							if(parseInt(t.statusFilter) == parseInt(t.filterList[e].status)) { insert = true; }
						}
						// end ==== status	
						// start ==== billing > status
						if(parseInt(t.billingFilter) == parseInt(t.filterList[e].statusPay)) {
							// billing > status	
							if (parseInt(t.billingFilter) >= 0 && parseInt(t.statusFilter) >= 0 && t.planFilter == null) {
								if(parseInt(t.filterList[e].status) == parseInt(t.statusFilter)){ insert = true; }	
							}
							// billing < status
							if (parseInt(t.billingFilter) >= 0 && parseInt(t.statusFilter) == 2 && t.planFilter == null) {							
								if(parseInt(t.filterList[e].statusPay) == parseInt(t.billingFilter)){ insert = true; }
							}

							// billing > plan > status				
							if (parseInt(t.billingFilter) >= 0 && parseInt(t.planFilter) >= 0 && parseInt(t.statusFilter) <= 1) {
								if(parseInt(t.filterList[e].info.plan_id) == parseInt(t.planFilter) && parseInt(t.filterList[e].status) == parseInt(t.statusFilter)){ insert = true; }		
							}
							// billing > plan < status
							if (parseInt(t.billingFilter) >= 0 && parseInt(t.planFilter) >= 0 && parseInt(t.statusFilter) == 2) {							
								if(parseInt(t.filterList[e].info.plan_id) == parseInt(t.planFilter)){ insert = true; }	
							}																																					
						}
						// end ==== status > status			
						
						// start ==== plan > status
						if(parseInt(t.planFilter) == 1) { 
							if(parseInt(t.planFilter) == parseInt(t.filterList[e].info.plan_id)) { 
								// plan > status
								if (parseInt(t.planFilter) >= 0 && parseInt(t.statusFilter) <= 2 && t.billingFilter == null) {
									if(parseInt(t.filterList[e].status) == parseInt(t.statusFilter)){ insert = true; }		
								}
								// plan < status
								if (parseInt(t.planFilter) >= 0 && parseInt(t.statusFilter) == 2 && t.billingFilter == null) {
									if(parseInt(t.planFilter) == parseInt(t.filterList[e].info.plan_id)) { insert = true; }
								}
								// plan > billing > status 				
								if (parseInt(t.planFilter) >= 0 && parseInt(t.billingFilter) >= 0 && parseInt(t.statusFilter) >= 0) {
									if(parseInt(t.filterList[e].status) == parseInt(t.statusFilter) && parseInt(t.filterList[e].statusPay) == parseInt(t.billingFilter)){ insert = true; }		
								}						
							}
						}
						if(parseInt(t.planFilter) == 2) { 						
							if(parseInt(t.filterList[e].info.plan_id) == 2 || parseInt(t.filterList[e].info.plan_id) == 3) { 								
								// plan > status
								if (parseInt(t.statusFilter) <= 2 && t.billingFilter == null) {
									if(parseInt(t.filterList[e].status) == parseInt(t.statusFilter)){ insert = true; }		
								}
								// plan < status
								if (parseInt(t.statusFilter) == 2 && t.billingFilter == null) {
									if(parseInt(t.filterList[e].info.plan_id) == 2 || parseInt(t.filterList[e].info.plan_id) == 3) { insert = true; }
								}
								// plan > billing > status 				
								if (parseInt(t.billingFilter) >= 0 && parseInt(t.statusFilter) >= 0) {
									if(parseInt(t.filterList[e].status) == parseInt(t.statusFilter) && parseInt(t.filterList[e].statusPay) == parseInt(t.billingFilter)){ insert = true; }		
								}													
							}
						}							
						// end ==== plan > billing						
					}
					if (type == 'billing') {				
						// start ==== billing contador
						if (t.billingFilter == null && t.statusFilter == null && t.planFilter == null) {
							insert = true;
						}						
						if (t.billingFilter == null && t.statusFilter == null && parseInt(t.planFilter) >= 0) {							
							if(parseInt(t.planFilter) == 1) { 
								if(parseInt(t.planFilter) == parseInt(t.filterList[e].info.plan_id)) { insert = true; }						
							}
							if(parseInt(t.planFilter) == 2) { 
								if(parseInt(t.filterList[e].info.plan_id) == 2 || parseInt(t.filterList[e].info.plan_id) == 3) { insert = true; }	
							}							
						}
						if (t.billingFilter == null && parseInt(t.statusFilter) >= 0 && t.planFilter == null) {
							if(parseInt(t.statusFilter) == parseInt(t.filterList[e].status)) { insert = true; }	
						}						
						if (t.billingFilter == null && parseInt(t.planFilter) >= 0 && parseInt(t.statusFilter) >= 0) {					
							if(parseInt(t.statusFilter) == parseInt(t.filterList[e].status) && (parseInt(t.filterList[e].info.plan_id) == 2 || parseInt(t.filterList[e].info.plan_id) == 3)) { insert = true; }	
						}						
						// end ==== billing contador

						// start ==== billing
						if (t.planFilter == null && t.statusFilter == null) {
							if(parseInt(t.billingFilter) == parseInt(t.filterList[e].statusPay)) { insert = true; }	
						}
						// end ==== billing

						// start ==== status > billing
						if(parseInt(t.statusFilter) == parseInt(t.filterList[e].status)) {	
							// status > billing
							if (parseInt(t.billingFilter) >= 0 && t.planFilter == null) {
								if(parseInt(t.filterList[e].statusPay) == parseInt(t.billingFilter)){ insert = true; }		
							}
							// status > billing > plan			
							if (parseInt(t.billingFilter) >= 0 && parseInt(t.planFilter) >= 0) {																	
								if(parseInt(t.planFilter) == 1) { 
									if(parseInt(t.filterList[e].info.plan_id) == parseInt(t.planFilter) && parseInt(t.filterList[e].statusPay) == parseInt(t.billingFilter)){ insert = true; }			
								}
								if(parseInt(t.planFilter) == 2) { 
									if((parseInt(t.filterList[e].info.plan_id) == 2 || parseInt(t.filterList[e].info.plan_id) == 3) && parseInt(t.filterList[e].statusPay) == parseInt(t.billingFilter)){ insert = true; }
								}								
							}	
							// status > plan < billing
							if (parseInt(t.billingFilter) == 2 && parseInt(t.planFilter) >= 0) {															
								if(parseInt(t.planFilter) == 1) { 
									if(parseInt(t.filterList[e].info.plan_id) == parseInt(t.planFilter)){ insert = true; }
								}
								if(parseInt(t.planFilter) == 2) { 
									if((parseInt(t.filterList[e].info.plan_id) == 2 || parseInt(t.filterList[e].info.plan_id) == 3)){ insert = true; }
								}											
							}																			
						}
						// se filtra primero por status para restablecer, punto clave [null || 2, 3] --terciario--
						if (parseInt(t.statusFilter) >= 0 && (t.planFilter == null || parseInt(t.planFilter) == 3) && (t.billingFilter == null || parseInt(t.billingFilter) == 2)) {
							if(parseInt(t.statusFilter) == parseInt(t.filterList[e].status)) { insert = true; }			
						}
						// end ==== status > billing

						// start ==== plan > billing	
						if(parseInt(t.planFilter) == 1) { 
							if(parseInt(t.planFilter) == parseInt(t.filterList[e].info.plan_id)) { 
								// status > billing
								if (parseInt(t.planFilter) >= 0 && parseInt(t.billingFilter) <= 2 && t.statusFilter == null) {
									if(parseInt(t.filterList[e].statusPay) == parseInt(t.billingFilter)){ insert = true; }		
								}
								// status < billing
								if (parseInt(t.planFilter) >= 0 && parseInt(t.billingFilter) == 2 && t.statusFilter == null) {
									if(parseInt(t.planFilter) == parseInt(t.filterList[e].info.plan_id)) { insert = true; }
								}

								// plan > status > billing 				
								if (parseInt(t.planFilter) >= 0 && parseInt(t.billingFilter) >= 0 && parseInt(t.statusFilter) >= 0) {
									if(parseInt(t.filterList[e].status) == parseInt(t.statusFilter) && parseInt(t.filterList[e].statusPay) == parseInt(t.billingFilter)){ insert = true; }		
								}							
							}
						}
						if(parseInt(t.planFilter) == 2) { 						
							if(parseInt(t.filterList[e].info.plan_id) == 2 || parseInt(t.filterList[e].info.plan_id) == 3) { 
								// status > billing
								if (parseInt(t.billingFilter) <= 2 && t.statusFilter == null) {
									if(parseInt(t.filterList[e].statusPay) == parseInt(t.billingFilter)){ insert = true; }		
								}
								// status < billing
								if (parseInt(t.billingFilter) == 2 && t.statusFilter == null) {
									if(parseInt(t.planFilter) == parseInt(t.filterList[e].info.plan_id)) { insert = true; }
								}

								// plan > status > billing 				
								if (parseInt(t.billingFilter) >= 0 && parseInt(t.statusFilter) >= 0) {
									if(parseInt(t.filterList[e].status) == parseInt(t.statusFilter) && parseInt(t.filterList[e].statusPay) == parseInt(t.billingFilter)){ insert = true; }		
								}								
							}
						}
						// end ==== plan > billing
					}	
					if (type == 'plan') {							
						// start ==== plan contador
						if (t.planFilter == null && t.statusFilter == null && t.billingFilter == null) {
							insert = true;
						}
						if (t.planFilter == null && t.statusFilter == null && parseInt(t.billingFilter) >= 0) {							
							if(parseInt(t.billingFilter) == parseInt(t.filterList[e].statusPay)) { insert = true; }	
						}
						if (t.planFilter == null && parseInt(t.statusFilter) >= 0 && t.billingFilter == null) {
							if(parseInt(t.statusFilter) == parseInt(t.filterList[e].status)) { insert = true; }	
						}						
						if (t.planFilter == null && parseInt(t.statusFilter) >= 0 && parseInt(t.billingFilter) >= 0) {					
							if(parseInt(t.statusFilter) == parseInt(t.filterList[e].status) && parseInt(t.filterList[e].statusPay) == parseInt(t.billingFilter)) { insert = true; }	
						}					
						// end ==== plan contador

						// end ==== plan
						if (t.billingFilter == null && t.statusFilter == null) {
							// if(parseInt(t.planFilter) == parseInt(t.filterList[e].info.plan_id)) { insert = true; }
							if(parseInt(t.planFilter) == 1) { 
								if(parseInt(t.planFilter) == parseInt(t.filterList[e].info.plan_id)) { insert = true; }
							}
							if(parseInt(t.planFilter) == 2) { 
								if(parseInt(t.filterList[e].info.plan_id) == 2 || parseInt(t.filterList[e].info.plan_id) == 3) { insert = true; }
							}							
						}
						// end ==== plan

						// start ==== status < plan
						// se filtra primero por status para restablecer, punto clave [null || 2, 3] --terciario--
						if (parseInt(t.statusFilter) >= 0 && (t.planFilter == null || parseInt(t.planFilter) == 3) && (t.billingFilter == null || parseInt(t.billingFilter) == 2)) {
							if(parseInt(t.statusFilter) == parseInt(t.filterList[e].status)) { insert = true; }			
						}						
						// end ==== status < plan
						
						// start ==== status > billing > plan
						if(parseInt(t.statusFilter) == parseInt(t.filterList[e].status)) {
							// status > plan
							if (t.billingFilter == null && parseInt(t.planFilter) >= 0) {
								if(parseInt(t.planFilter) == 1) { 
									if(parseInt(t.filterList[e].info.plan_id) == parseInt(t.planFilter)){ insert = true; }
								}
								if(parseInt(t.planFilter) == 2) { 
									if((parseInt(t.filterList[e].info.plan_id) == 2 || parseInt(t.filterList[e].info.plan_id) == 3)){ insert = true; }
								}								
							}			
							// status > billing > plan				
							if (parseInt(t.billingFilter) >= 0 && parseInt(t.planFilter) >= 0) {								
								if(parseInt(t.planFilter) == 1) { 
									if(parseInt(t.filterList[e].info.plan_id) == parseInt(t.planFilter) && parseInt(t.filterList[e].statusPay) == parseInt(t.billingFilter)){ insert = true; }
								}
								if(parseInt(t.planFilter) == 2) { 
									if((parseInt(t.filterList[e].info.plan_id) == 2 || parseInt(t.filterList[e].info.plan_id) == 3) && parseInt(t.filterList[e].statusPay) == parseInt(t.billingFilter)){ insert = true; }
								}								
							}
							// status > billing < plan
							if (parseInt(t.billingFilter) >= 0 && parseInt(t.planFilter) == 3) {
								if(parseInt(t.filterList[e].statusPay) == parseInt(t.billingFilter)){ insert = true; }		
							}							
						}
						// end ==== status > billing > plan		
						
						// start ==== billing > plan
						if(parseInt(t.billingFilter) == parseInt(t.filterList[e].statusPay)) {
							// billing > status	
							if (parseInt(t.billingFilter) >= 0 && parseInt(t.planFilter) <= 2 && t.statusFilter == null) {
								if(parseInt(t.filterList[e].info.plan_id) == parseInt(t.planFilter)){ insert = true; }	
							}
							// billing < plan
							if (parseInt(t.billingFilter) >= 0 && parseInt(t.planFilter) == 3 && t.statusFilter == null) {							
								if(parseInt(t.filterList[e].statusPay) == parseInt(t.billingFilter)){ insert = true; }
							}																		
						}
						// end ==== status > plan						
					}													
					if(insert) {
						listNew.push(t.filterList[e]);// insertamos fila a la nueva lista 
					}				
				}
			}		
			return listNew
		},
		filterStatusC(filter, type){
			if (filter < 9999) {
				this.statusFilter = filter;
				this.resetstatusFilter = false;
				this.filterStatus(filter);	
				if (type == 'a') {
					this.statusA = true
					this.statusB = false
				} else {
					this.statusB = true
					this.statusA = false
				}					
			} else {
				this.statusA = false
				this.statusB = false				
				this.statusFilter = null
				this.filterStatus(4);
			}
		},			
		filterStatus(filter){				
			let t = this;
			// guardamos copia de la lista original
			if(filter == undefined){
				// sino existe filter
				if(t.resetstatusFilter) { 
					t.statusFilter = 0;
				} else {
					t.statusFilter++; // incrementamos valor para proximo filtro
				}
			}
			let listNew = this.filterAlpha('status')
			if(listNew.length > 0) {
				t.result = listNew; // retornamos nueva lista con item filtrados
				t.resetstatusFilter = false;
			} 
			let ctl
			if(parseInt(t.statusFilter) >= 2 && t.billingFilter == null && t.planFilter == null) { ctl = true; } 
			if(parseInt(t.statusFilter) >= 2 && (parseInt(t.billingFilter) >= 0 || parseInt(t.planFilter) >= 0)) { ctl = false; }	
			if(ctl == false) {
				t.statusFilter = null; // reiniciamos al llegar al maximo
				t.resetstatusFilter = true; // activamos reset	
			}
			if(ctl) {
				t.statusFilter = null; // reiniciamos al llegar al maximo
				t.resetstatusFilter = true; // activamos reset		
				t.result = t.filterList; // restauramos lista sin filter
			}
			if(t.statusFilter == 1){
				t.statusA = true
				t.statusB = false
			} else if (t.statusFilter == 0) {
				t.statusA = false
				t.statusB = true
			} else {
				t.statusA = false
				t.statusB = false				
			}														
		},		
		filterPlanStatus(filter, type){
			if (filter < 9999) {
				this.planFilter = filter;
				this.resetplanFilter = false;
				this.filterPlan(filter);	
				if (type == 'a') {
					this.planA = true
					this.planB = false
				} else {
					this.planB = true
					this.planA = false
				}					
			} else {
				this.planA = false
				this.planB = false				
				this.planFilter = null
				this.filterPlan(4);
			}				
		},		
		filterPlan(filter){
			let t = this;
			// guardamos copia de la lista original
			if(filter == undefined){
				// sino existe filter
				if(t.resetplanFilter) { 
					t.planFilter = 1;
				} else {
					t.planFilter++; // incrementamos valor para proximo filtro
				}
			}
			let listNew = this.filterAlpha('plan')
			if(listNew.length > 0) {
				t.result = listNew; // retornamos nueva lista con item filtrados
				t.resetplanFilter = false;
			}
			let ctl
			if(parseInt(t.planFilter) >= 3 && t.billingFilter == null && t.statusFilter == null) { ctl = true; } 
			if(parseInt(t.planFilter) >= 3 && parseInt(t.billingFilter) >= 0 && parseInt(t.statusFilter) >= 0) { ctl = false; }
			if (parseInt(t.planFilter) >= 3 && t.billingFilter == null && parseInt(t.statusFilter) >= 0) { ctl = false;	}
			if (parseInt(t.planFilter) >= 3 && parseInt(t.billingFilter) >= 0 && t.statusFilter == null) { ctl = false; }
			if(ctl == false) {
				t.planFilter = null; // reiniciamos al llegar al maximo
				t.resetplanFilter = true; // activamos reset	
			}
			if(ctl) {
				t.planFilter = null; // reiniciamos al llegar al maximo
				t.resetplanFilter = true; // activamos reset		
				t.result = t.filterList; // restauramos lista sin filter
			}	
			if(t.planFilter == 1){
				t.planA = true
				t.planB = false
			} else if (t.planFilter == 2) {
				t.planA = false
				t.planB = true
			} else {
				t.planA = false
				t.planB = false				
			}									
		},
		filterBillingStatus(filter, type){
			if (filter < 9999) {
				this.billingFilter = filter;
				this.resetbillingFilter = false;
				this.filterBilling(filter);
				if (type == 'a') {
					this.billingA = true
					this.billingB = false
				} else {
					this.billingB = true
					this.billingA = false
				}				
			} else {
				this.billingA = false
				this.billingB = false
				this.billingFilter = null
				this.filterBilling(3);
			}				
		},
		filterBilling(filter){			
			let t = this;
			// guardamos copia de la lista original
			if(filter == undefined){
				// sino existe filter
				if(t.resetbillingFilter) { 
					t.billingFilter = 0;
				} else {
					t.billingFilter++; // incrementamos valor para proximo filtro
				}
			}
			let listNew = this.filterAlpha('billing')
			if(listNew.length > 0) {
				t.result = listNew; // retornamos nueva lista con item filtrados
				t.resetbillingFilter = false;
			}	
			let ctl	
			if(parseInt(t.billingFilter) >= 2 && t.planFilter == null && t.statusFilter == null) { ctl = true }
			if(parseInt(t.billingFilter) >= 2 && parseInt(t.planFilter) >= 0 && parseInt(t.statusFilter) >= 0) { ctl = false	}
			if (parseInt(t.billingFilter) >= 2 && t.planFilter == null && parseInt(t.statusFilter) >= 0) { ctl = false }
			if (parseInt(t.billingFilter) >= 2 && parseInt(t.planFilter) >= 0 && t.statusFilter == null) { ctl = false }
			if(ctl == false) {
				t.billingFilter = null; // reiniciamos al llegar al maximo
				t.resetbillingFilter = true; // activamos reset	
			}
			if(ctl) {
				t.billingFilter = null; // reiniciamos al llegar al maximo
				t.resetbillingFilter = true; // activamos reset
				t.result = t.filterList; // restauramos lista sin filter
			}
			if(t.billingFilter == 1){
				t.billingA = true
				t.billingB = false
			} else if (t.billingFilter == 0) {
				t.billingA = false
				t.billingB = true
			} else {
				t.billingA = false
				t.billingB = false				
			}							
		},
				
		activeStatusPay(index){
            if (this.statusPayActive == index) {
                this.statusPayActive = null;
            } else {
                this.statusPayActive = index;
            }			
		},
		changeStatusPay(user_id, org_id, statusID, i){	
			console.log(i);
			let dataMail = [{
				id:i.id,
				company:i.bill_company_name,
				email:i.bill_email,
				firstname:i.bill_firstname,
				lastname:i.bill_lastname,
				telephone:i.bill_telephone
			}]
        	let t = this;
    		t.statusPayActive = null;
    		let send = true;
            this.$swal({
                title: "cancel this plan",
                text: "Are you sure you want to cancel this plan?, the account will be removed from the list",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, i'm sure",
            }).then((result) => {	
				if(statusID==2){				
					t.isLoading = true;
					let url = "/changeStatusPay";
					window.reselleR2.post(url, {
						user_id: user_id,
						org_id: org_id,
						statusID: statusID,
						dataMail:dataMail,
					})
					.then((response) => {
						console.log('changeStatusPay', response.data)
						if(response.data.status){
							t.getOrg();
						}
						else{
							t.isLoading = false;	
						}
					})
					.catch((error) => {
						t.isLoading = false;
						console.log(error)
					});		
				}
			});				
		},				
    	tMore(i, index){
    		let t = this;
    		t.oldList = t.result;
    		let listNew = [];
    		for (let e in t.oldList) {
    			if(t.oldList[e]){
    				if(i.id == t.oldList[e].id)
    				{
	    				if(t.oldList[e].moreOpen == 'show'){
	    					t.oldList[e].moreOpen = 'hide';
	    				}
	    				else{
	    					t.oldList[e].moreOpen = 'show';
	    				}
	    				console.log(i.moreOpen)
	    			}
    			}
    			listNew.push(t.oldList[e]);
    		}
    		t.result = listNew;
    	},
        activeStatus(index) {
            if (this.statusActive == index) {
                this.statusActive = null;
            } else {
                this.statusActive = index;
            }
        },
    	changeStatus(status, user_id, org_id){
        	let t = this;
    		if(status==2){
	            t.$swal({
	                title: "Delete Organization",
	                text: "Are you sure that you want to delete permanently this organization?",
	                icon: "warning",
	                showCancelButton: true,
	                confirmButtonColor: "#3085d6",
	                confirmButtonText: "Yes, i'm sure",
	            }).then((result) => {
	            	console.log('result', result)
	            	if(result.value){
	        			t.sendStatus(status, user_id, org_id);
	            	}
	            });	
	        }
	        else{
	        	t.sendStatus(status, user_id, org_id);
	        }
    	},
    	sendStatus(status, user_id, org_id){
        	let t = this;
    		let url = "/changeStatusOrgs";
    		t.statusActive = null;
    		t.isLoading = true;
			window.reselleR2.post(url, {
				org_id: org_id,
				idStatus: status,
				active: status,
				id: org_id,
				user_id: user_id,
			})		
			.then((response) => {
				console.log('changeStatusOrgs', response.data)
				t.getOrg();
			})
			.catch((error) => {
				t.isLoading = false;
				console.log(error)
			});
    	},
		checkSubmenu(e) {		
			let submenus = document.querySelectorAll('.' + e.target.classList[0]);
			submenus.forEach(function(i){
				if(i != e.target){
					i.checked = false;
				}
			});
		},		
        modalaResOrgs(component, i) {
            this.m = {
                show: true,
                component: component,
                data: i
            };
			this.activeModalOrgs = true;
            this.$store.commit('core/setOpenModal', this.m);          
        },		
        modalaResAssignApp(i, app_id) {
            this.m = {
                show: true,
                component: 'AssignAppsR',
                data: {i:i, app_id: app_id}
            };
			this.activeModalOrgs = true;
            this.$store.commit('core/setOpenModal', this.m);          
        },
        getOrg() {
        	let t = this;
			t.isLoading = true
            let url = "/getOrg";
			t.contadorOrgsActives=0;
			t.contadorOrgsSuspended=0;
			t.contadorStandar=0;
			t.contadorPremium=0;
			t.contadorPaid=0;
			t.contadorUnpaid=0;
			t.contadorCancelled=0;
            window.reselleR2
            .post(url, t.f)
            .then((response) => {
                t.statuslist = response.data.statusList;
                let u = response.data.r;
				t.contadorPremium = response.data.premium;
				t.contadorStandar = response.data.standard;
				t.listPay = response.data.statusPayList;
                t.result = u;
				let o = []
                for (let i in u) {
					if(u[i].statusPay != 2){
						t.result[i].moreOpen='hide';
						t.result[i].rowNormal=true;
						if(u[i]){
							if(u[i].status == 1){t.contadorOrgsActives += 1; }
							if(u[i].status == 0){t.contadorOrgsSuspended += 1; }
							if(u[i].statusPay == 0){t.contadorUnpaid += 1; }
							if(u[i].statusPay == 1){t.contadorPaid += 1; }
							// if(u[i].statusPay == 2){t.contadorCancelled += 1; }
						}

						let apps = u[i].apps
						let p = [{app_id: 1}, {app_id: 8}, {app_id: 4}, {app_id: 10},
						{app_id: 11}, {app_id: 2}, {app_id: 7}, {app_id: 3}, {app_id: 9}]
						let g = []					
						for (let l in p) {
							for (let j in apps) {
								if (apps[j].app_id === p[l].app_id) {
									g.push(apps[j])
								}						
							}
						}
						u[i].apps = g;
						o.push(u[i])
					}
                }
				console.log(t.contadorUnpaid);
				t.result = o;
				let k = t.result
				let p = t.sortedKeyValue(k, 'id')
				t.result = []
				t.result = p
				t.filterList = p
				t.isLoading = false
				//let objIternos= ['info', 'apps', 'userbyOrgs'];
				//let resulCombos= []
				//t.combinaciones(t.result, 3, objIternos, resulCombos);
            })
            .catch((error) => {
                console.log(error);
				t.isLoading = false
            });
        },
		sortedKeyValue(obj, key) {
			return obj.sort((a, b) => {
				let x = a[key];
				let y = b[key];
				return ((y < x) ? -1 : ((y > x) ? 1 : 0)); // ordenar de mayor a menor
				// return ((x < y) ? -1 : ((x > y) ? 1 : 0)); // ordenar de menor a mayor
			});
		},
		combinaciones(arr, n, objIternos, resultados, combinados) {
			if(!combinados) {
				combinados = [];
			}
			let combos = '';
			console.log('correr combinaciones')
			arr.filter(function (obj) {
				console.log('item combo', obj)
				// buscar y separar objetos internos 
				for (let i in obj) {
					if(objIternos.indexOf(i) > -1){
						console.log('obj interno encontrado', i, obj[i])
						let iterno = obj[i];
						for (let j in iterno) {
							obj[i+'_'+j]=iterno[j];
						}
						obj.splice(i);
					}
				}
				/*for(let i=0; i<item.length; ++i) {
					let newResultado = combinados.slice();
					let newCombo = item.slice();
					newResultado.push(newCombo[i]);
					//combos+=arr[i];
					console.log('combos', newResultado)
					newCombo.splice(i, 1);
					if(n>1) {
					comb(newCombo, n-1, resultados, newResultado);
					} else {
					resultados.push(newResultado);
					}
				}*/
			});
		},
    },
    computed: {
		filtered: function () {
			var t = this;
            if(t.TextSearch.length == 0) {
                return t.result;    
            } else{			
				return t.result.filter(function (item) {
					let fullname = item.bill_firstname+' '+item.bill_lastname
					let fullnameA = item.bill_firstname+' '+item.bill_company_name
					let fullnameB = item.bill_lastname+' '+item.bill_firstname
					let fullnameC = item.bill_lastname+' '+item.bill_company_name
					let fullnameD = item.bill_company_name+' '+item.bill_firstname
					let fullnameE = item.bill_company_name+' '+item.bill_lastname

					let fullnameF = item.bill_firstname+' '+item.com_company_name
					let fullnameG = item.bill_lastname+' '+item.com_company_name
					let fullnameH = item.com_company_name+' '+item.bill_firstname
					let fullnameI = item.com_company_name+' '+item.bill_lastname				

					let fullnameJ = item.bill_firstname+' '+item.bill_lastname+' '+item.bill_company_name
					let fullnameK = item.bill_lastname+' '+item.bill_firstname+' '+item.bill_company_name
					let fullnameM = item.bill_company_name+' '+item.bill_lastname+' '+item.bill_firstname
					let fullnameN = item.bill_company_name+' '+item.bill_firstname+' '+item.bill_lastname
					let fullnameO = item.bill_firstname+' '+item.bill_lastname+' '+item.com_company_name
					let fullnameP = item.bill_lastname+' '+item.bill_firstname+' '+item.com_company_name
					let fullnameQ = item.com_company_name+' '+item.bill_lastname+' '+item.bill_firstname
					let fullnameR = item.com_company_name+' '+item.bill_firstname+' '+item.bill_lastname

					let fullnamecom = item.info.billing.bill_firstname+' '+item.info.billing.bill_lastname
					let fullnamecomA = item.info.billing.bill_firstname+' '+item.bill_company_name
					let fullnamecomB = item.info.billing.bill_lastname+' '+item.info.billing.bill_firstname
					let fullnamecomC = item.info.billing.bill_lastname+' '+item.bill_company_name
					let fullnamecomD = item.bill_company_name+' '+item.info.billing.bill_firstname
					let fullnamecomE = item.bill_company_name+' '+item.info.billing.bill_lastname

					let fullnamecomF = item.info.billing.bill_firstname+' '+item.com_company_name
					let fullnamecomG = item.info.billing.bill_lastname+' '+item.com_company_name
					let fullnamecomH = item.com_company_name+' '+item.info.billing.bill_firstname
					let fullnamecomI = item.com_company_name+' '+item.info.billing.bill_lastname				

					let fullnamecomJ = item.info.billing.bill_firstname+' '+item.info.billing.bill_lastname+' '+item.bill_company_name
					let fullnamecomK = item.info.billing.bill_lastname+' '+item.info.billing.bill_firstname+' '+item.bill_company_name
					let fullnamecomM = item.bill_company_name+' '+item.info.billing.bill_lastname+' '+item.info.billing.bill_firstname
					let fullnamecomN = item.bill_company_name+' '+item.info.billing.bill_firstname+' '+item.info.billing.bill_lastname
					let fullnamecomO = item.info.billing.bill_firstname+' '+item.info.billing.bill_lastname+' '+item.com_company_name
					let fullnamecomP = item.info.billing.bill_lastname+' '+item.info.billing.bill_firstname+' '+item.com_company_name
					let fullnamecomQ = item.com_company_name+' '+item.info.billing.bill_lastname+' '+item.info.billing.bill_firstname
					let fullnamecomR = item.com_company_name+' '+item.info.billing.bill_firstname+' '+item.info.billing.bill_lastname				
					return (
						(item.bill_lastname &&
							item.bill_lastname
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
						(item.bill_firstname &&
							item.bill_firstname
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
						(item.info.billing.bill_firstname &&
							item.info.billing.bill_firstname
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||	
						(item.info.billing.bill_lastname &&
							item.info.billing.bill_lastname
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||	
						(item.bill_company_name &&
							item.bill_company_name
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
						(item.com_company_name &&
							item.com_company_name
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||	
						(fullname &&
							fullname
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||	
						(fullnameA &&
							fullnameA
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||								
						(fullnameB &&
							fullnameB
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||	
						(fullnameC &&
							fullnameC
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||	
						(fullnameD &&
							fullnameD
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||	
						(fullnameE &&
							fullnameE
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||	
						(fullnameF &&
							fullnameF
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||	
						(fullnameG &&
							fullnameG
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||	
						(fullnameH &&
							fullnameH
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||	
						(fullnameI &&
							fullnameI
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||	
						(fullnameJ &&
							fullnameJ
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||								
						(fullnameK &&
							fullnameK
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||	
						(fullnameM &&
							fullnameM
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||	
						(fullnameN &&
							fullnameN
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||	
						(fullnameO &&
							fullnameO
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||	
						(fullnameP &&
							fullnameP
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||	
						(fullnameQ &&
							fullnameQ
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||	
						(fullnameR &&
							fullnameR
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||	

						(fullnamecom &&
							fullnamecom
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||	
						(fullnamecomA &&
							fullnamecomA
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
						(fullnamecomB &&
							fullnamecomB
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||	
						(fullnamecomC &&
							fullnamecomC
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
						(fullnamecomD &&
							fullnamecomD
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||	
						(fullnamecomE &&
							fullnamecomE
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||	
						(fullnamecomF &&
							fullnamecomF
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
						(fullnamecomG &&
							fullnamecomG
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||	
						(fullnamecomH &&
							fullnamecomH
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||	
						(fullnamecomI &&
							fullnamecomI
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
						(fullnamecomJ &&
							fullnamecomJ
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||	
						(fullnamecomK &&
							fullnamecomK
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
						(fullnamecomM &&
							fullnamecomM
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||	
						(fullnamecomN &&
							fullnamecomN
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||								
						(fullnamecomO &&
							fullnamecomO
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||	
						(fullnamecomP &&
							fullnamecomP
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||	
						(fullnamecomQ &&
							fullnamecomQ
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||
						(fullnamecomR &&
							fullnamecomR
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||						
						(item.info.userPhone &&
							item.info.userPhone
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0) ||							
						(item.info.userEmail &&
							item.info.userEmail
								.toLowerCase()
								.indexOf(t.TextSearch.toLowerCase().trim()) >= 0)							
					);
				});
			}
		},					
        ...mapGetters("core", { getOpenModal: "getOpenModal" }),
    },
    watch: {
        'getOpenModal': function() {            
			let t = this.getOpenModal.data;
			// console.log(t);
			if (t.method === 'refresh-editOrg') {
				this.getOrg()
			}
			if(!this.getOpenModal.show){
				this.activeModalOrgs= false;
			}
        },          
    },		
}
</script>
<style lang="css" scoped>
	@import '../../plugins/tdynamic/css/tdynamic.css';
	.tdynamic {
    	height: calc(100vh - 180px);
	}
	.tdynamic .content-table-tdynamic{
		top: 0px;
	}
	.table-tdynamic thead tr th{
		font-weight: bold;
	}
	.table-tdynamic thead tr th,
	.table-tdynamic tbody tr td{
		padding: 1px 8px;
	}
	.table-tdynamic thead tr th.colMore,
	.table-tdynamic tbody tr td.colMore{
		width: 30px;
	}
	.table-tdynamic tbody tr td.colMore:hover{cursor: pointer;}
	.table-tdynamic thead tr th.colId,
	.table-tdynamic tbody tr td.colId{
		width: 40px;
	}
	.table-tdynamic thead tr th.colPlan,
	.table-tdynamic tbody tr td.colPlan{
		width: 90px;
	}	
	.table-tdynamic thead tr th.colApps,
	.table-tdynamic tbody tr td.colApps{
		width: 200px;
	}
	.table-tdynamic thead tr th.colActions,
	.table-tdynamic tbody tr td.colActions{
		width: 80px;
	}
	.table-tdynamic thead tr th.colStatus,
	.table-tdynamic tbody tr td.colStatus{
		width: 80px;
	}
	.table-tdynamic thead tr th.colDates,
	.table-tdynamic tbody tr td.colDates{
		width: 130px;
	}	
	.status_list {
	    padding: 0px;
	    position: absolute;
	    left: 0;
	    top: 25px;
	    background-color: #fff;
	    /* width: 120px; */
	    font-size: 14px;
	    z-index: 9;
	}
	.status_list > ul {
	    margin-bottom: 0px;
	    border: 1px solid #ccc;
	}
    .status_list > ul > li {
	    display: block;
	    position: relative;
	    margin: 1px 0;
	    padding: 5px;
    }
	.status_list > ul > li:hover {
		background-color: #efefef;
		cursor: pointer;
	}
	.action_btn_status {
    	position: relative;
    	display: flex;
    	justify-content: flex-end;
    	align-items: center;
    	justify-content: center;
	}
	span.statusSelected {
	    font-size: 12px;
	}

	span.statusSelected i {
	    color: #000;
	}
	.badgeOro{
		background-color: #f1c94c;
    	color: #606060;
	}
	.tableRow{
		width: 100%; 
		border: 0px solid;
		border-collapse: initial;
		border-bottom: 0px solid #eee;
	    table-layout: fixed;
	    border-collapse: collapse;
	    box-sizing: border-box;
	    display: table;
	    text-indent: initial;
	    border-spacing: 0px;
	    border-color: grey;
	}
.cdropdownx > a:before, .cdropdownx > button:before {
    position: absolute;
    right: 7px;
    top: 15px;
    content: " ";
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black;
}

.dropx {
	background-color: transparent !important; 
	border: 0px solid #ccc !important; 
	color: white !important;
	padding: 0px 15px 0px 0px !important;
}
.usersbyorg{
	width: 100%;
	border: 1px solid #ccc;
	margin: 10px 0px;
}
.usersbyorg thead tr th{border-top: 1px solid #ccc;}
.content-tr-extension{
	background-color: #fff !important; 
	margin-left: 0px;
	margin-right: 0px;
}
.filter:hover{ cursor: pointer;}
.filterOff{}
.filterOn::after{
	display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}
.filterOn.bg-danger.unpaid,
.filterOn.bg-success.paid,
.filterOn.bg-danger.cancelled{color: #fff;}
.filterOn.bg-warning.declined{color: #000;}
.filterOn.bg-secondary.unpaid{
	color: #fff;
    background-color: #6c757d;
}
.filterOn.bg-warning.primium{
    background-color: #f1c94c;
    color: #606060;
}
.filterx{
	cursor: pointer;
	border-left:1px solid rgb(211, 217, 217);
	border-radius: 0px !important; 
	padding: 1px !important; 
	font-size: inherit !important;
}
.filtery{
	border:1px solid rgb(211, 217, 217); 
	padding: 0.1px; 
	background-color:#fff;
} 
.filterz{
    border-radius: 0px !important;
    padding: 0.4px 3px !important;
    font-size: inherit !important;
}
</style>
