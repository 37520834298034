<template>
	<div class="container">
		<div class="row">
      <div class="col-md-12">
          <div class="card" style="margin-bottom: 78px">
              <div class="card-body">
                  <div class="row">
                      <div class="col-7 text-left"><h2 class="page-title"><i class="fas fa-users"></i> Leads</h2> </div>
                      <div class="col-5">
                          <!-- <input
                              type="text"
                              class="form-control search"
                              v-model="search"
                              placeholder="Search"
                          /> -->
                          <br />
                      </div>
                  </div>
                  <table class="table table-striped">
                      <thead>
                          <tr>
                              <th >Date</th>
                              <th style="min-width: 100px; ">Customer</th>
                              <th >Email</th>
                              <th >Phone</th>
                              <th >Address</th>
                              <th style="width: 100px; text-align: center">
                                Status
                              </th>
                              <th  style="width: 100px">Quest</th>
                          </tr>
                      </thead>
                      <tbody v-if="isLoading == false">
                          <tr v-for="(i, index) in filterItems" :key="i.id">
                              <th>{{ i.date_create }} <br />{{ i.hora }}</th>
                              <td>
                                <div v-if="i.quest.firsname">
                                  {{ i.quest.firsname }}
                                </div>
                                <div v-if="i.quest.lastname">
                                  {{ i.quest.lastname }}
                                </div>
                              </td>
                              <td>
                                <div v-if="i.quest.email">
                                  {{ i.quest.email }}
                                </div>
                              </td>
                              <td>
                                <div v-if="i.quest.phone">
                                  {{ i.quest.phone }}
                                </div>
                              </td>
                              <td style="width: 220px;">
                                <div>
                                  {{ i.address }}
                                </div>
                              </td>
                              <td>
                                  <div
                                      class="action_order_btn"
                                      style="width: 100px"
                                  >
                                      <span
                                          @click="activeStatus(index)"
                                          style="cursor: pointer"
                                      >
                                          <span
                                              v-if="i.status == 0"
                                              class="badge badge-pill badge-warning"
                                              >Pending
                                              <i class="fas fa-angle-down"></i
                                          ></span>
                                          <span
                                              v-else-if="i.status == 1"
                                              class="badge badge-pill badge-info"
                                              >Verified Phone
                                              <i class="fas fa-angle-down"></i
                                          ></span>
                                          <span
                                              v-else-if="i.status == 2"
                                              class="badge badge-pill badge-primary"
                                              >Estimate Send
                                              <i class="fas fa-angle-down"></i
                                          ></span>
                                          <span
                                              v-else-if="i.status == 3"
                                              class="badge badge-pill badge-success"
                                              >Acepted
                                              <i class="fas fa-angle-down"></i
                                          ></span>
                                          <span
                                              v-else-if="i.status == 4"
                                              class="badge badge-pill badge-danger"
                                              >Rejected
                                              <i class="fas fa-angle-down"></i
                                          ></span>
                                          <span
                                              v-else-if="i.status == 5"
                                              class="badge badge-pill badge-danger"
                                              >Spam
                                              <i class="fas fa-angle-down"></i
                                          ></span>
                                      </span>
                                      <span
                                          class="status_list"
                                          v-if="statusActive == index"
                                      >
                                          <ul class="reset" style="padding-left: 0">
                                              <li @click="statusChange(0, i.id)">
                                                  <span
                                                      class="badge badge-pill badge-warning"
                                                      >Pending</span
                                                  >
                                              </li>
                                              <li @click="statusChange(1, i.id)">
                                                  <span
                                                      class="badge badge-pill badge-info"
                                                      >Verified Phone</span
                                                  >
                                              </li>
                                              <li @click="statusChange(2, i.id)">
                                                  <span
                                                      class="badge badge-pill badge-primary"
                                                      >Estimate Send</span
                                                  >
                                              </li>
                                              <li @click="statusChange(3, i.id)">
                                                  <span
                                                      class="badge badge-pill badge-success"
                                                      >Acepted</span
                                                  >
                                              </li>
                                              <li @click="statusChange(4, i.id)">
                                                  <span
                                                      class="badge badge-pill badge-danger"
                                                      >Rejected</span
                                                  >
                                              </li>
                                              <li @click="statusChange(5, i.id)">
                                                  <span
                                                      class="badge badge-pill badge-danger"
                                                      >Spam</span
                                                  >
                                              </li>
                                          </ul>
                                      </span>
                                  </div>
                              </td>
                              <td>
                                  <button
                                      type="button"
                                      class="btn btn-secondary btn-sm"
                                      @click="EstPreview(i)"
                                  >
                                      <i class="fas fa-eye"></i> View
                                  </button>
                              </td>
                          </tr>
                      </tbody>
                      <tbody v-if="isLoading">
                          <tr>
                              <th colspan="7"><Loader></Loader></th>
                          </tr>
                      </tbody>
                  </table>
                  <!--<ul
                      v-if="
                          pagination.total > pagination.per_page && content == false
                      "
                      class="pagination justify-content-center"
                      style="borde: 1px solid blue"
                  >
                      <li v-if="pagination.current_page !== 1" class="page-car">
                          <button
                              class="page-link"
                              aria-label="Previous"
                              v-on:click.prevent="
                                  changePage(pagination.current_page - 1)
                              "
                          >
                              <span aria-hidden="true">«</span>
                          </button>
                      </li>
                      <li
                          v-for="(page, index) in pagesNumber"
                          :key="index"
                          class="page-car"
                          :class="{ active: page == pagination.current_page }"
                      >
                          <button
                              class="page-link"
                              v-on:click.prevent="changePage(page)"
                          >
                              {{ page }}
                          </button>
                      </li>
                      <li
                          v-if="pagination.current_page !== pagination.last_page"
                          class="page-car"
                      >
                          <button
                              class="page-link"
                              aria-label="Next"
                              v-on:click.prevent="
                                  changePage(pagination.current_page + 1)
                              "
                          >
                              <span aria-hidden="true">»</span>
                          </button>
                      </li>
                  </ul> -->
              </div>
          </div>
      </div>
    </div>
    <modal
	    name="modal-estimate"
	    height="auto"
	    :resizable="true"
	    :adaptive="true"
	    :scrollable="true"
    >
    <div class="card-header" style="position: relative">
    	<span style="font-size: 18px">Estimate</span>
    	<i
	    	class="fas fa-times"
	    	style="
	    	position: absolute;
	    	top: 9px;
	    	right: 11px;
	    	font-size: 24px;
	    	cursor: pointer;
    		"
    		@click="CloseModal"
    	></i>
    </div>
    <div class="card-body">
  		<div style="display: flex">
  			<b style="width: 300px">{{q.zipcode}}:</b>
  			<span style="width: 200px"> {{ quest.zipcode }}</span>
  		</div>
  		<div style="display: flex">
  			<b style="width: 300px">{{q.customer}}:</b>
  			<span style="width: 200px"> {{ quest.firsname }} {{ quest.lastname }}</span>
  		</div>
  		<div style="display: flex">
  			<b style="width: 300px">{{q.phone}}:</b>
  			<span style="width: 200px"> {{ quest.phone }}</span>
  		</div>
  		<div style="display: flex">
  			<b style="width: 300px">{{q.email}}:</b>
  			<span style="width: 200px"> {{ quest.email }}</span>
  		</div>
  		<div style="display: flex">
  			<b style="width: 300px">{{q.address}}:</b>
  			<span style="width: 200px"> {{ quest.address }}</span>
  		</div>
    	<div style="display: flex">
    		<b style="width: 300px">Location Ip:</b>
    		<span style="width: 200px" v-if="ip_info!=null"> {{ ip }} / {{ ip_info.countryCode}}. {{ ip_info.regionName}}, {{ ip_info.city}} {{ ip_info.zip }}</span>
        <span style="width: 200px" v-else> {{ ip }} / <a :href="ipurl+'#block-geolocation'" target="_blank">View Geo-Ip</a></span>
    	</div>
      <div style="display: flex">
        <h4><b>Project related questions:</b></h4>
      </div>
      <div style="display: flex;flex-direction: column;" v-if="questAnswers.length==0">
        <div style="display: flex">
          <b style="width: 300px">{{q.project}}:</b>
          <span style="width: 200px"> {{ quest.project }}</span>
        </div>
        <div style="display: flex">
          <b style="width: 300px">{{q.material}}:</b>
          <span style="width: 200px"> {{ quest.material }}</span>
        </div>
      </div>
      <div style="display: flex;flex-direction: column;" v-else>
        <div style="display: flex" v-for="(i, index) in questAnswers" :key="index">
          <b style="width: 300px">{{i.questName}}:</b>
          <span style="width: 200px">
            <span v-for="(a,idx) in i.answers" :key="idx">
              {{a}}<span v-if="idx<(i.answers.length-1)">,</span>
            </span>
          </span>
        </div>
      </div>
      <div style="display: flex">
        <b style="width: 300px">{{q.otherdetails}}:</b>
        <span style="width: 200px"> {{ quest.otherdetails }}</span>
      </div>
      <div style="display: flex">
        <b style="width: 300px">Url referred:</b>
        <span style="width: 200px"> {{urlOrigin}}</span>
      </div>
    </div>
  </modal>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import Loader from "@/core/components/shared/Loader.vue";
export default {
	name: 'RoofCalLeads',
	components: {
    Loader,
	},
  data() {
      return {
          isLoading: false,
          statusActive: null,
          leadsList: [],
          quest: [],
          questAnswers: [],
          search: "",
          offset: 4,
          page: 1,
          pagination: {
              current_page: 1,
          },
          query: "",
          result: "",
          content: false,
          ip: '',
          ip_info: '',
          ipurl: '',
          urlOrigin: '',
          datecreate: '',
          type: 1,
          apis: {},
          q: []
      };
  },
  created() {
      this.init()
  },
  methods: {
    init() {
      let urlApis = this.$store.getters['ModApps/RoofCal/urlApis'];
      this.apis = urlApis.leads;
      this.getLeads(1);
    },
    getLeads(sinLoad) {
    	if(sinLoad==1){this.isLoading = true;}
    	this.statusActive = null;
    	window.master.post(this.apis.get(), {
    		org_id: window.localorgdata,
    		user_id: window.localAccountID,
    	})
    	.then((response) => {
    		console.log('data leads', response.data);
    		this.leadsList = response.data.r;
    		this.q = response.data.q;
    		this.isLoading = false;
    	})
    	.catch((error) => console.log(error));
    },
    activeStatus(index) {
    	if (this.statusActive == index) {
    		this.statusActive = null;
    	} else {
    		this.statusActive = index;
    	}
    },
    statusChange(status, id) {
    	console.log('statusChange', status, id);
    	this.statusActive = null;
    	window.master.post(this.apis.status(), {
    		id: id,
    		status: status,
    	})
    	.then((response) => {
    		this.getLeads();
    	})
    	.catch((error) => {console.log(error);});
    },
    EstPreview(i) {
        this.quest = i.quest;
        if(i.quest.questAnswers != undefined){
          this.questAnswers = JSON.parse(i.quest.questAnswers);
        }else{
          this.questAnswers =[];
        }
        this.ip = i.ip;
        this.ip_info = i.ip_info;
        this.ipurl = i.ipurl;
				this.urlOrigin = i.webiste;
				this.datecreate = i.date_create +' - '+i.hora;
        this.$modal.show("modal-estimate");
    },
    CloseModal() {
        this.$modal.hide("modal-estimate");
    },
  },
  watch: {
    accitonPusher: function () {
      let t = this;
      if (t.accitonPusher != undefined) {
        let data = t.accitonPusher;
        if(window.localorgdata == data.org_id && window.master_client == data.user_id){
          if(data.action === "RoofCalLeads"){
            this.getLeads(0);
          }
          else if(data.action === "RoofCalLeads validPhone"){
            this.getLeads(0);
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters("core", { 
        accitonPusher: "get_accitonPusher"
    }),
  	filterItems: function () {
  		let self = this;
  		if (this.search !== "") {
  			self.content = true;
  			return this.result;
  		} else {
  			self.content = false;
  			return this.leadsList
  		}
  	},
  },
};
</script>
<style scoped lang="scss">
.action_order_btn {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    justify-content: center;
}
.status_list {
    padding: 8px 7px;
    position: absolute;
    left: 0;
    top: 25px;
    background-color: #fff;
    width: 120px;
    font-size: 14px;
    z-index: 9;
    ul {
        li {
            display: block;
            position: relative;
            margin: 1px 0;
            padding: 5px;
            span {
                cursor: pointer;
            }
            &:hover {
            }
        }
    }
}
.pagination {
    .page-car {
        &.active {
            .page-link {
                background-color: #8b8b8c;
                color: #fff;
            }
        }
    }
}
.search {
    border-color: #555;
}
</style>
