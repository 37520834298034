let fillInData = function(data){
	let output =[];
	let prop = ['id', 'name', 'id_campaign'];
	for (let i in data) {
		if(data[i]) {
			let n = {
				id : data[i].id,
				user_id : data[i].user_id,
				org_id : data[i].org_id,
				date : data[i].date,
				dateFormat : data[i].dateFormat.slice(0, 16),
				id_marksource : data[i].id_marksource,
				id_ctachannel : data[i].id_ctachannel,
				id_campaign : data[i].id_campaign,
				id_groupname : data[i].id_groupname,
				leadstatus : data[i].leadstatus,
				leadscore : data[i].leadscore,
				optionsGroupName: this.contruirSelect(data[i].group, prop),
				new : false,
			}
			output.push(n);
		}
	}
	return output;
};
export default fillInData;
