<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-header">
						<h3>Email Notificacions</h3>
					</div>
					<div class="bgloading" v-show="isLoading">
						<Loader></Loader>
					</div>
					<form @submit.prevent="setNotiForm" id="form1" class="">
						<div class="card-body">
							<div class="form-group">
								<label for="email1">Email 1: </label>
								<input type="email" class="form-control" id="email1" v-model="f.email1" />
							</div>
							<div class="form-group">
								<label for="email2">Email 2:</label>
								<input type="email" class="form-control" id="email2" v-model="f.email2" />
							</div>
							<div class="form-group">
								<label for="email3">Email 3: </label>
								<input type="email" class="form-control" id="email3" v-model="f.email3" />
							</div>
						</div>
						<div class="card-footer d-flex justify-content-end">
							<button type="submit" class="btn btn-success btn-sm" :disabled="btnDisabled">UPDATE</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Loader from "@/core/components/shared/Loader.vue";
import { mapGetters } from "vuex";
export default {
	components: {
		Loader,
	},
	data() {
		return {
			isLoading: false,
			f: {
				org_id: window.localuserdata,
				user_id: window.master_client,
				noti_id: 0,
				email1: null,
				email2: null,
				email3: null,
			},
			org_email: null,
			apis: null,
			btnDisabled: false,
		};
	},
	created() {
		this.init();
	},
	mounted() {
	},
	methods: {
		init() {
			let urlApis = this.$store.getters['ModApps/Marketea/urlApis'];
			this.apis = urlApis.bSite;
			this.getNotiForm();
		},
		getNotiForm() {
			let t = this;
			t.isLoading = true;
			let url = t.apis.pull(10); // getNotiForm
			window.master.post(url, {
				org_id: window.localorgdata,
				form_type: 1,
			})
				.then((response) => {
					let get = response.data;
					console.log(url, get);
					if (get.r) {
						t.f.noti_id = get.r.id;
						t.f.email1 = get.r.email1;
						t.f.email2 = (get.r.email2) ? get.r.email2 : '';
						t.f.email3 = (get.r.email3) ? get.r.email3 : '';
						t.btnDisabled = false;
					} else {
						t.f.email1 = t.org_email;
						t.btnDisabled = true;
					}
					t.isLoading = false;
				})
				.catch((error) => {
					console.log(error);
					t.isLoading = false;
					t.btnDisabled = true;
				});
		},
		setNotiForm() {
			let t = this;
			if (t.f.noti_id > 0) {
				t.isLoading = true;
				let url = t.apis.set(15); // getNotiForm
				window.master
					.post(url, t.f)
					.then((response) => {
						t.$parent.close();
						t.isLoading = false;
					})
					.catch((error) => {
						console.log(error);
						t.isLoading = false;
					});
			}
		},
	},
	computed: {
	},
};
</script>

<style lang="scss" scoped></style>
