<template>
	<div>
		<div class="card">
			<div class="card-header">
				Add Bank
			</div>
			<div class="card-body">
				<form
					@submit.prevent="saveBanking"
					class="form-horizontal"
					role="form"
					style="margin-top: 25px"
				>
					<div class="row">
						<div class="col-md-12">
							<div class="form-group row">
								<label class="control-label required col-md-5"
									>Select Account Type</label
								>
								<div class="col-md-7">
									<label class="radio-inline">
										<input
											name="bankorcard"
											type="radio"
											value="true"
											v-model="formB.account_type"
										/>Bank &nbsp;&nbsp;
									</label>
									<label class="radio-inline">
										<input
											name="bankorcard"
											type="radio"
											value="false"
											v-model="formB.account_type"
										/>Credit Card
									</label>
								</div>
							</div>
							<div class="form-group row">
								<label class="control-label required col-md-4">
									<span class="text-dashed-underline"
										>Account Name
									</span>
								</label>
								<div class="col-md-8">
									<input
										class="form-control"
										type="text"
										required
										v-model="formB.account_name"
									/>
								</div>
							</div>
							<div class="form-group row">
								<label class="control-label col-md-4"
									>Account Number</label
								>
								<div class="col-md-8">
									<input
										class="form-control"
										type="text"
										required
										v-model="formB.account_number"
									/>
								</div>
							</div>
							<div class="form-group row">
								<label class="control-label col-md-4">
									<span class="text-dashed-underline">
										Bank Name
									</span>
								</label>
								<div class="col-md-8">
									<input
										class="form-control"
										type="text"
										required
										v-model="formB.bank_name"
									/>
								</div>
							</div>
							<div class="form-group row">
								<label class="control-label col-md-4">
									<span class="text-dashed-underline">
										Routing Number
									</span>
								</label>
								<div class="col-md-8">
									<input
										class="form-control"
										type="text"
										required
										v-model="formB.routing_number"
									/>
								</div>
							</div>
							<div class="form-group row">
								<label class="control-label col-md-4">
									<span class="text-dashed-underline">
										Transaction Type:
									</span>
								</label>
								<div class="col-md-8">
									<select
										class="form-control"
										v-model="
											formB.account_type_transac
										"
									>
										<option value="1">
											Checking Account
										</option>
										<option value="2">
											Saving Account
										</option>
									</select>
								</div>
							</div>                                
						</div>
					</div>
					<div class="form-group row">
						<div class="col-md-12">
							<button class="btn btn-success float-right" type="submit">
								Save</button
							>&nbsp;&nbsp;
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	components: {},
	name: "formAddVendor",
	data () {
		return {
            addVendor: {
                company_name: null,
            },
            formB: {
                user_id: window.localuserdata,
                title: "Add Bank or Credit Card",
                action: "create",
                account_type: true,
                account_name: "",
                account_code: "",
                account_number: "",
                bank_name: "",
                routing_number: "",
                description: "",
                activo: 1,
                primary: false,
                AccountSeleted: "",
                bankingSelected: "",
                send_print: 0,
                account_type_transac:1
            },							
		}
	},
	created() {
		this.init()
	},
	methods: {
        init() {
            let g = this.$store.getters['core/getOpenModal'];
            let r = g.data;
			this.formB = r.formB	     
        },
        saveBanking() {
            this.$store.commit("core/setLoadingModal", true);
            let urlAxios = "";
            let title = "Created";
            let text = "Successful registration";
            if (this.formB.action == "create") {
                urlAxios = "banking/add";
            }
            window.billing
                .post(urlAxios, this.formB)
                .then((response) => {
                    this.$notify({
                        group: "noti",
                        title: title,
                        type: "success",
                        text: text,
                    });
					let t = this
					this.$store.commit("core/setLoadingModal", false);
					this.$store.commit("core/setCloseModal", {action: 'saved'});
					this.$store.commit("core/setActionRefresh", {
						action: 'saved', 
						data:{
							bank_id: response.data.id,
							methods:'bankin'
						}
					});
                })
                .catch((error) => {
                    this.$store.commit("core/setLoadingModal", false);
                    console.log(error);
                });
        },
    },
    computed: {

    },	
}
</script>
<style lang="css" scoped></style>
