<template>
    <div class="template_container">
        <div class="cover_container">
            <div class="cover_picture" :style="$parent.isBackgroundCover($parent.item)">
                <div class="cover_video" v-if="$parent.item.pictures[0].cover_v_active">
                    <video id="cover_video_player" loop muted="muted" playsinline="true" autoplay :key="'video-'+$parent.item.id"
                    :style="$parent.positionVideo($parent.item)">
                        <source :src="$parent.srcCoverVideo($parent.item)" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
                    </video>
                </div>
                <div class="cover_picture_before"
                    v-if="$parent.item.pictures[0].cover_active"
                    :style="'background-color: rgba(0 0 0 / '+$parent.item.pictures[0].cover_overlay+'%);'"
                ></div>
                <div class="edit edit-cover" @click="$parent.$emit('setModal', 'editCover')"><i class="fas fa-pen"></i> Cover</div>
                <!-- <div class="logo_picture" :style="isLogo(item)"></div> -->
                <!-- <div class="edit edit-logo" @click="$emit('setModal', 'editLogo')"><i class="fas fa-pen"></i> Edit Logo</div> -->
            </div>
        </div>
        <div class="profile">
            <div class="profile_picture" :style="$parent.isProfile($parent.item)">
                <i class="fas fa-user" v-if="$parent.isProfile($parent.item)==''"></i>
                <Loader v-if="$parent.profileLoad" class="profileLoad"></Loader>
                <div class="edit edit-profile" @click="$parent.editPicProfile()"><i class="fas fa-pen"></i></div>
            </div>
            <div class="author-info">
                <div class="edit edit-info" @click="$parent.$emit('setModal', 'editInfo')"><i class="fas fa-pen"></i></div>
                <h1 class="name">{{$parent.item.name}} {{$parent.item.lastname}}</h1>
                <p class="rol">{{$parent.item.rol}}</p>
                <h2 class="company">{{$parent.orginf.com_company_name}}</h2>
            </div>
        </div>
        <div class="add_contact_share_qr">
            <a href="#Show_Qr" @click.prevent="$parent.linkVoid()" class="btn show_Qr hvr-float" aria-label="show or Hide code Qr">
                <i class="fas fa-qrcode"></i>
            </a>
            <div class="section-btnsActions">
                <div class="edit edit-btnsActions" @click="$parent.edit_btnsActions()"><i class="fas fa-pen"></i></div>
                <div class="list-btnsActions" v-if="$parent.premiumProfile && $parent.item.btns_action!=null && $parent.item.btns_action.length>0">
                    <template v-for="(b, idxBtns) in $parent.item.btns_action">
                        <a :key="idxBtns"
                            v-if="b.status==1 && idxBtns==0"
                            href="#"
                            @click.prevent="$parent.edit_btnsActions()"
                            class="btn-blue btn-action hvr-shrink"
                            :aria-label="b.label"
                            :style="customBgBtn(b.style)"
                        >
                            {{b.label}}
                        </a>
                    </template>
                </div>
                <a v-else href="#" @click.prevent="$parent.edit_btnsActions()" class="btn-blue btn-action hvr-shrink" aria-label="ADD TO CONTACT">
                    ADD TO CONTACT
                </a>
            </div>
            <a href="#Share_link" @click.prevent="$parent.linkVoid()" class="btn share_link hvr-float" aria-label="Share" >
                <img :src="$parent.urlweb+'img/share.svg'" style="width: 25px;" alt="icon_share" width="25" height="32"/>
            </a>
        </div>
        <div class="contact-iconos2 text-center edit-contact">
            <div class="box-icon" v-for="c2 in $parent.item.find_me_at" :key="c2.id" v-show="c2.status==1">
                <div class="button-icon hvr-sweep-to-right">
                    <a href="#" @click.prevent="$parent.editContact()" :class="'btn btn-sbg '+c2.icon+' hvr-icon-forward'">
                        <span class="iconSVG"><i :class="c2.icon"></i></span>
                        <p>
                            <small class="label" v-if="(c2.label+'').toLowerCase()=='sms'"  style="text-transform: uppercase;">{{$parent.helperLabel(c2)}}</small>
                            <small class="label" v-else>{{$parent.helperLabel(c2)}}</small>
                            <span class="value" v-if="(c2.label+'').toLowerCase()!='form pop-up'" >{{$parent.helperValue(c2)}}</span>
                            <span class="value" v-else style="text-transform: capitalize;">Contact Form</span>
                            <span class="icon_arrow">
                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" class="hvr-icon"><path d="M512 199.652c0 23.625-20.65 43.826-44.8 43.826h-99.851c16.34 17.048 18.346 49.766-6.299 70.944 14.288 22.829 2.147 53.017-16.45 62.315C353.574 425.878 322.654 448 272 448c-2.746 0-13.276-.203-16-.195-61.971.168-76.894-31.065-123.731-38.315C120.596 407.683 112 397.599 112 385.786V214.261l.002-.001c.011-18.366 10.607-35.889 28.464-43.845 28.886-12.994 95.413-49.038 107.534-77.323 7.797-18.194 21.384-29.084 40-29.092 34.222-.014 57.752 35.098 44.119 66.908-3.583 8.359-8.312 16.67-14.153 24.918H467.2c23.45 0 44.8 20.543 44.8 43.826zM96 200v192c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V200c0-13.255 10.745-24 24-24h48c13.255 0 24 10.745 24 24zM68 368c0-11.046-8.954-20-20-20s-20 8.954-20 20 8.954 20 20 20 20-8.954 20-20z"/></svg>
                            </span>
                        </p>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:"htmlTemplate2",
    data(){
        return {
            style: {bgColor: "#4fa4ff", txtColor: "#ffffff"}
        }
    },
    methods: {
        customBgBtn(style){
            let t = this;
            let d = t.style;
            let s = '';
            let css = (style==null || style=='null' || style=='' || style==='[object Object]') ? d : ((typeof style === 'object') ? style : JSON.parse(style));
            if(css!=''){
                s+='background: '+css.bgColor+';';
                s+='border-color: '+css.bgColor+';';
                s+='color: '+css.txtColor+';'
            }
            // console.log('style', s);
            return s;
        },
    }
}
</script>
<style lang="scss" scoped>
@import url('../scss/efectsThemes.scss');
@import url('../scss/baseTemplate.scss');
@import url('../scss/nightly.scss');
</style>
