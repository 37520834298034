<template>
    <div class="contenedor-home">
        <div class="container">
            <div :class="(appStart) ? 'hide' : ''">
                <Applications></Applications>
            </div>
            <div v-if="!emailVerify" class="text-center" style="padding-top: 60px">
                <div
                    class="alert alert-emailverify"
                    style="
                        width: 100%;
                        margin: auto;
                        max-width: 300px;
                        margin-bottom: 30px;
                        padding: 0.4rem 1rem;
                    "
                >
                    <span style="font-size: 11px;">
                        <i class="fa fa-exclamation-triangle"></i>
                        Please verify your email!.
                        <a href="#"
                            style="
                                color: #0a0a0a;
                            "
                            @click="resendEmailToken"
                        >
                            Resend email verification
                        </a>
                    </span>
                </div>
            </div>
            <hr class="separator" />
            <div v-if="user_type == 0">
                <AppsAvailable></AppsAvailable>
            </div>
        </div>
        <div id="loadingHome" class="alert" v-if="isLoading">
            <i class="fas fa-spinner fa-spin fa-3x"></i>
        </div>
    </div>
</template>
<script>
import Applications from '@/core/components/Home/Applications'
import AppsAvailable from '@/core/components/Home/AppsAvailable'
import { mapGetters } from 'vuex'
export default {
    name: 'Home',
    components: {
        Applications,
        AppsAvailable
    },
    data () {
        return {
            isLoading: false,
            email_Verify: true,
            user_type: window.user_type,
            appStart: (localStorage.getItem('appStart'))
        }
    },
    created () {
        this.user_type = this.$store.getters['core/session/getUserType']
        this.email_Verify = this.$store.getters['core/session/getEmailVerify']
        localStorage.setItem('appIDactual', 0);
        if(localStorage.getItem('appStart')){
            this.$store.commit("set_isLoading", true);
        }
    },
    methods: {
        resendEmailToken () {
            this.isLoading = true
            window.master
                .post('pushtetokenemail', {
                    user_id: window.localAccountID
                })
                .then((response) => {
                    if (response.data.status) {
                        this.$swal.fire({
                            icon: 'success',
                            title: 'Verification email sent.',
                            showConfirmButton: false,
                            timer: 2000
                        })
                    } else {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'error sending email, try again later.',
                            showConfirmButton: false,
                            timer: 2000
                        })
                    }
                    this.isLoading = false
                })
                .catch((error) => {
                    console.log(error)
                    this.isLoading = false
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Internal error, try again later.',
                        showConfirmButton: false,
                        timer: 2000
                    })
                })
        }
    },
    computed: {
        ...mapGetters('core/session', { emailVerify: 'getEmailVerify' })
    }
};
</script>
<style lang="css" scoped>
    @import '../assets/css/homeStyles.css';
</style>
