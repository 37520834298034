let getInvoices = function() {
	this.inputFilter = "";
	this.isLoading = true;
	window.billing
	.get("invoices/" + window.localuserdata + this.query)
	.then((response) => {
		this.InvoicesListResult = response.data.result;
		this.pagination = response.data.lista;
		this.InvoicesList = response.data.lista.data;
		let e = this.InvoicesList;
		this.Actionsdropd = [];
		for (var i = 0; i < e.length; i++) {
			this.Actionsdropd[e[i].id] = false;
			let fecdoc = new Date(e[i].fecdoc).toDateString();
			let fecdue = new Date(e[i].fecdue).toDateString();
			let f_a = new Date();
			fecdue = new Date(e[i].fecdue);
			if (f_a > fecdue && e[i].idxstatus < 2) {
				this.InvoicesList[i].idxstatus = 4;
			}
		}
		console.log(this.InvoicesList);
		this.OldInvoicesList = this.InvoicesList;
		this.isLoading = false;
	})
	.catch((error) => console.log(error));
};
export default getInvoices;
