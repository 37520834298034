<template>
    <div class="card">
        <div class="card-header"><h3 class="card-title">Edit Profile</h3></div>
        <div class="card-body">
            <div class="row">
                <div v-if="isLoading" class="col-12 text-center"><Loader></Loader></div>
                <form class="col-12" v-else @submit.prevent="editProfile">
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <label for="">Name</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="f.firstname"
                                required
                            />
                        </div>
                        <div class="form-group col-md-12">
                            <label for="">Last name</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="f.lastname"
                                required
                            />
                        </div>
                        <div class="form-group col-md-12">
                            <label for="">Email</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="f.email"
                                required
                            />
                        </div>
                        <div class="form-group col-md-12">
                            <label for="">Cell Phone</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="f.telephone"
                            />
                        </div>
                        <div class="form-group col-md-12">
                            <label for="inputCity">Role or Position
                            </label>
                            <select class="form-control" v-model="f.rol">
                                <option value="0">Uncategorized</option>
                                <option v-for="(i, index) in listRoles" :key="index" :value="i.id">{{i.name}}</option>
                            </select>
                        </div>
                        <div class="form-group col-md-12">
                            <button
                                type="submit"
                                class="btn btn-success"
                                style="display: block; width: 100%; max-width: 200px; border-radius: 10px; margin: auto; padding: 10px 40px; font-size: 1.1rem;"
                            >
                                SAVE
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Loader from '@/core/components/shared/Loader.vue'
    export default {
        components: {
            Loader,
        },
        props: {
            account_id: {
                type: Number,
                require: true,
            }
        },
        data(){
            return {
                isLoading: false,
                user_id: window.master_client,
                f: {
                    account_id: 0,
                    id:0,
                    name: null,
                    lastname: null,
                    email: null,
                    telephone: null,
                    rol: 0,
                    avatar: null,
                    avatar_old: null,
                    imgAvatar: null,
                    btnDeleteImg: null,
                },
                listRoles: [],
            }
        },
        created(){
            this.getRoles();
            this.getUsersById()
        },
        methods: {
            getRoles () {
                let t = this;
                t.listRoles=[];
                window.master.post('user/getroles', {
                    user_id: t.user_id
                })
                .then((response) => {
                    t.listRoles = response.data.roles;
                })
                .catch((error) => {
                    console.log(error);
                })
            },
            getUsersById () {
                let t = this;
                t.isLoading = true;
                t.listRoles=[];
                window.master
                .get('user/' + t.user_id + '/' + t.account_id)
                .then((response) => {
                    t.listRoles = response.data.roles;
                    const url = response.data.url;
                    const i = response.data.user_detail;
                    // const access = response.data.access;
                    t.f.id = i.id;
                    t.f.account_id = i.account_id;
                    t.f.firstname = i.firstname;
                    t.f.lastname = i.lastname;
                    t.f.email = i.email;
                    t.f.telephone = i.telephone;
                    t.f.rol = i.rol;
                    t.f.avatar = url + i.avatar;
                    t.f.avatar_old = i.avatar;
                    t.f.imgAvatar = i.imgAvatar;
                    t.f.btnDeleteImg = i.imgAvatar;
                    t.isLoading = false;
                })
                .catch((error) => {
                    console.log(error)
                    t.isLoading = false
                })
            },
            editProfile(){
                let t = this;
                t.isLoading = true;
                // @ts-ignore
                window.master.post('/user/editProfile', t.f)
                .then((response) => {
                    t.isLoading = false;
                    console.log(response.data);
                    if (response.data.status) {
                        t.$emit('setUpdate', response.data)
                        t.$store.commit('core/setNotiPush', {
                            show: true,
                            group: "notiPush",
                            type: '1', // 1=success|2=info|3=error|4=loading|5=warning
                            title: 'Success',
                            text: 'Saved Successful.',
                            duration: 3000
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    t.isLoading = false;
                })
            }
        },
        watch: {
            getPusher: function(){
                let t = this;
                if (t.getPusher != undefined) {
                    if(t.getPusher.action == "actuUsersRoles" && t.getPusher.data.user_id == window.master_client){
                        console.log('getPusher', t.getPusher);
                        t.getRoles();
                    }
                }
            },
        },
        computed: {
            ...mapGetters("core", {
                getPusher: "get_accitonPusher"
            }),
        }
    }
</script>

<style lang="scss" scoped>

</style>
