let filterFechas = function() {
	let t = this;
	let jobs = 0;
	if(t.accessJobsApp){jobs=1;}
	this.m = {
		show: true,
		bill: true,
		component: 'formFilterFechas',
		data: {
			query:t.query,
			inputFilter: t.inputFilter.trim(),
			fieldsFilter: t.fieldsFilter,
			rowNum: t.rowNum,
			statuslist: t.statuslist,
			paginador:t.paginador,
			jobs: jobs,
		}
	};
	this.$store.commit('core/setOpenModal', this.m);
};
export default filterFechas;
