let changeOpAddi = function() {		
	let t = this;
	window.billing.post("/opAddiInvoice", {
		user_idd: window.master_client,
		org_id: window.localorgdata,
		typeUser: window.user_type,
		user_id: window.localorgdata,
		oauth: window.oauth_m,
		opAddi: this.valOpAddi.toString()
	})
	.then((response) => {
		console.log('opAddi', response.data.r);
		if(response.data.r){
			let opAddi = response.data.r.meta_value;
		}
	})
	.catch((error) => {
		console.log(error);
	});
};
export default changeOpAddi;
