<template>
    <div style="position: relative; margin-top: 20px">
        <div class="container-fluid">
            <div class="row">
                <div
                    class="col-sm-12 col-md-12 col-lg-12 col-xl-12"
                    v-if="actionVendor == 1"
                >
                    <div class="card">
                        <div class="card card-body">
                            <div class="d-flex" style="justify-content: space-between">
                                <button
                                    class="btn btn-success btn-sm mr-2 float-left"
                                    style="width: 200px; margin-bottom: 10px"
                                    @click="newVendor"
                                >
                                    <i class="fas fa-plus"></i> Add Vendor
                                </button>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="TextSearchVendor"
                                    placeholder="Filter Vendor Name"
                                    style="width: 200px; border: 1px solid #c9c9ca"
                                />
                            </div>
                            <div class="">
                                <div v-if="isLoading"><Loader></Loader></div>
                                <table
                                    class="table table-striped table-vendor"
                                    v-if="showVendors"
                                >
                                    <thead>
                                        <tr>
                                            <th scope="col" class="text-left">
                                                Vendor Name
                                            </th>
                                            <th scope="col" class="text-left">Address</th>
                                            <th scope="col" class="text-right">
                                                Total Expense
                                            </th>
                                            <th
                                                scope="col"
                                                class="text-right"
                                                style="width: 120px"
                                            >
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(i, index) in filteredCustomer"
                                            :key="index"
                                        >
                                            <td class="text-left">
                                                {{ i.company_name }}
                                            </td>
                                            <td class="text-left">
                                                <span v-if="i.adressinfo != null"
                                                    >{{ i.adressinfo }}
                                                </span>
                                                <span v-if="i.cityinfo != null"
                                                    >{{ i.cityinfo }},
                                                </span>
                                                <span v-if="i.stateinfo != null"
                                                    >{{ i.stateinfo }}
                                                </span>
                                                <span v-if="i.zipcodeinfo != null">{{
                                                    i.zipcodeinfo
                                                }}</span>
                                            </td>
                                            <td class="text-right">
                                                ${{ i.totalExpenses | formatMoney }}
                                            </td>
                                            <td class="text-right" style="width: 120px">
                                                <span class="cdropdown">
                                                    <button>Actions</button>
                                                    <label>
                                                        <input type="checkbox" />
                                                        <ul class="text-left">
                                                            <li @click="edit(i.id)">
                                                                <button>
                                                                    <i
                                                                        class="fas fa-edit"
                                                                    ></i>
                                                                    Edit
                                                                </button>
                                                            </li>
                                                            <li @click="del(i.id)">
                                                                <button>
                                                                    <i
                                                                        class="fas fa-times"
                                                                    ></i>
                                                                    Delete
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </label>
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div v-if="NoRecord == true" class="text-center">
                                    no record
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="col-sm-12 col-md-12 col-lg-1 col-xl-1"
                    v-if="actionVendor > 1"
                ></div>
                <div
                    class="col-sm-12 col-md-12 col-lg-10 col-xl-10"
                    v-if="actionVendor > 1"
                >
                    <vendorform></vendorform>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import Loader from "@/core/components/shared/Loader";
import vendorform from "@/apps/billing/components/vendors/vendorForm";
export default {
    name: "Vendors",
    components: {
        Loader,
        vendorform,
    },
    data() {
        return {
            isLoading: false,
            showVendors: false,
            VendorsList: [],
            TextSearchVendor: "",
            NoRecord: false,
            actionVendor: 0,
        };
    },
    created() {
        this.getVendors();
    },
    methods: {
        getVendors() {
            this.isLoading = true;
            this.showVendors = false;
            this.NoRecord = false;
            this.actionVendor = 1; //list
            window.billing
                .get("vendors/" + window.localuserdata)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.success) {
                        this.VendorsList = response.data.result;
                        this.showVendors = true;
                    }
                    this.NoRecord = false;
                    this.isLoading = false;
                })
                .catch((error) => console.log(error));
        },
        newVendor() {
            this.actionVendor = 2; // new
            this.$store.commit("ModApps/billing/set_vendorOP", 2);
        },
        edit(id) {
            console.log(id);
            this.isLoading = true;
            this.$store.commit("ModApps/billing/set_vendorSelect", id);
            this.$store.commit("ModApps/billing/set_vendorOP", 3);
            this.actionVendor = 3; //edit
            this.isLoading = false;
        },
        del(id) {
            console.log(id);
            this.isLoading = true;
            this.showVendors = false;
            window.billing
                .post("vendors/delete", { id: id })
                .then((response) => {
                    console.log(response.data.success);
                    if (response.data.success) {
                        console.log("deeted");
                        this.$notify({
                            group: "noti",
                            title: "Deleted",
                            type: "success",
                            text: "Deleted successfull",
                        });
                        this.getVendors();
                    } else {
                        this.$notify({
                            group: "noti",
                            title: "Error",
                            type: "error",
                            text:
                                "This vendor have attached documents and can not be deleted",
                        });
                        this.isLoading = false;
                        this.showVendors = true;
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    this.isLoading = false;
                    this.showVendors = true;
                });
        },
    },
    watch: {
        vendorOP: function (val) {
            console.log("op1 = ", val);
            this.actionVendor = this.vendorOP;
            if (this.actionVendor == 1) {
                this.getVendors();
            }
        },
    },
    computed: {
        filteredCustomer: function () {
            var self = this;
            return this.VendorsList.filter(function (item) {
                return (
                    (item.firstname &&
                        item.firstname
                            .toLowerCase()
                            .indexOf(self.TextSearchVendor.toLowerCase()) >= 0) ||
                    (item.company_name &&
                        item.company_name
                            .toLowerCase()
                            .indexOf(self.TextSearchVendor.toLowerCase()) >= 0) ||
                    (item.lastname &&
                        item.lastname
                            .toLowerCase()
                            .indexOf(self.TextSearchVendor.toLowerCase()) >= 0)
                );
            });
        },
        ...mapGetters("ModApps/billing", { vendorOP: "vendorOP" }),
    },
};
</script>
<style lang="scss" scoped>
table.table.table-striped.table-vendor {
    margin-bottom: 100px;
}
</style>
