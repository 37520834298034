let editCamp = function(y, idx, i, index){            
    let t = this;
    let url = this.apis.editCamp();
    setTimeout(function(){
        // console.log('editCamp', new Date());
        var elem = document.querySelector(".heigth" + idx + "-" + y.id);
        var alto = elem.getAttribute("data-heigth");
        //console.log('data-heigth', alto);
        window.master.post(url, {
            user_id: window.master_client,
            org_id: window.localorgdata,
            id: y.id,
            id_jobs: y.id_jobs,
            name: y.name,
            alto: alto,
        })
        .then((response) => {
            // console.log('editItemTask', response.data);
            t.$store.commit("ModApps/jobs/set_refresh_grid", true);
        })
        .catch((error) => {
            console.log(error);
        });
    }, 300);
};                
export default editCamp;
