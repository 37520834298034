<template>
    <div class="edit_food_details" :data-indicador="indicador">
        <div class="food_item_details" v-html="food.desc_food" :data-indicador="indicador" v-if="!editActive" @click.prevent="activeEdit()"></div>
        <div class="content-editor" v-else>
            <textarea
                v-model="food.desc_food"
                class="widget_edit_food_details"
                placeholder="Food details"
                maxlength="500"
                ref="editDetails_food"
                @change="saveFoodDetails()"
                @focusout="inactiveEdit"
            ></textarea>
        </div>
    </div>
</template>
<script>
export default {
    props: ['indicador', 'food'],
    data(){
        return {
            loadSave: false,
            editActive: false,
            apis: null,
        };
    },
    created(){
        let t = this;
        let urlApis = this.$store.getters['ModApps/Marketea/urlApis'];
        this.apis = urlApis.bMenu;
    },
    methods: {
        activeEdit(){
            let t = this;
            t.editActive = true;
            setTimeout(() => {
                if(t.$refs.editDetails_food){
                    t.$refs.editDetails_food.focus();
                }
            }, 200);
        },
        inactiveEdit(){this.editActive = false;},
        noEnter(e){
            let t = this;
            var code = (e.which) ? e.which : e.keyCode;
            if(code == 13){
                e.preventDefault();
                t.saveFoodDetails()
            }
        },
        saveFoodDetails(){
            let t= this;
            t.inactiveEdit()
            let urlService = t.apis.set(3)// setFood
            if(!t.loadSave){
                t.loadSave = true;
                // @ts-ignore
                window.master.post(urlService, t.food)
                .then((response) => {
                    console.log(urlService, t.food, response.data);
                    t.loadSave = false;
                })
                .catch((error) => {
                    console.log(error);
                    t.loadSave = false;
                    t.$notify({
                        group: "noti",
                        type: "error",
                        title: "Error",
                        text: "Could not be save!",
                    });
                });
            }
        },
    }
}
</script>
<style lang="scss" scoped>
    .edit_food_details {
        .food_item_details{
            border: 1px dashed transparent;
            &:hover{
                border: 1px dashed #fcd602;
            }
        }
        textarea.widget_edit_food_details{
            width: 100%;
            min-height: 80px;
            font-size: 0.8rem;
            font-weight: normal;
            color: #8e8e8e;
            font-family: Arial, Helvetica, sans-serif;
            color: #000;
            text-align: left;
            padding: 0px;
            resize: none;
            box-shadow: none;
            outline: none;
            margin-top: 10px;
        }
    }
</style>
