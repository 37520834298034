<template>
    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col-6" style="padding-top: 10px">
                    <h5 class="card-title">Assign User</h5>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="col-12">
                <div class="content-table">
                    <form @submit.prevent="form">
                        <table style="width: 100%" border="0">
                            <tbody v-if="isLoading == false">
                                <tr>
                                    <td colspan="2" width="99%">
                                        <div
                                            class="option__desc"
                                            style="margin: 0.5px; height: 50px"
                                        >
                                            <div
                                                style="width: 10%; float: left; height: 50px"
                                                v-if="ownerImg"
                                            >
                                                <img
                                                    class="option__image"
                                                    style="border-radius: 50px"
                                                    width="50px"
                                                    height="50px"
                                                />
                                            </div>
                                            <div
                                                style="width: 10%; float: left; height: 50px"
                                                v-else
                                            >
                                                <span class="avatarDefault">{{ ownerL }}</span>
                                            </div>
                                            <div
                                                style="
                                                    width: 90%;
                                                    float: right;
                                                    height: 50px;
                                                    padding: 0px;
                                                "
                                            >
                                                <span
                                                    class="option__title"
                                                    style="
                                                        font-size: 18px;
                                                        color: #4f5467;
                                                        margin-left: 10px;
                                                        padding: 0px;
                                                    "
                                                    >{{ ownerName }} (Owner)</span
                                                ><br />
                                                <span
                                                    class="option__small"
                                                    style="
                                                        font-size: 14px;
                                                        color: #6a7a8c;
                                                        margin-left: 10px;
                                                        padding: 0px;
                                                    "
                                                    >{{ ownerEmail }}</span
                                                >
                                            </div>
                                        </div>
                                        <br />
                                    </td>
                                </tr>
                                <tr v-bind:class="{ noneRow: show_privileges }">
                                    <td style="width: 100%;">
                                        <multiselect                                            
                                            v-model="f.userIdAssign"
                                            placeholder="Search"
                                            label="title"
                                            track-by="id"
                                            :options="options"
                                            :custom-label="customLabel"
                                            :multiple="true"
                                            :taggable="true"
                                            style="border: 1px solid #343a402e;"
                                        >
                                            <template slot="option" slot-scope="props">
                                                <div
                                                    class="option__desc"
                                                    style="margin: 0.5px; height: 50px"
                                                >
                                                    <div
                                                        style="
                                                            width: 10%;
                                                            float: left;
                                                            height: 50px;
                                                        "
                                                        v-if="props.option.img"
                                                    >
                                                        <img
                                                            class="option__image"
                                                            :src="props.option.img"
                                                            style="border-radius: 50px"
                                                            width="50px"
                                                            height="50px"
                                                        />
                                                    </div>
                                                    <div
                                                        style="
                                                            width: 10%;
                                                            float: left;
                                                            height: 50px;
                                                        "
                                                        v-else
                                                    >
                                                        <span class="avatarDefault">{{
                                                            props.option.default
                                                        }}</span>
                                                    </div>
                                                    <div
                                                        style="
                                                            width: 90%;
                                                            float: right;
                                                            height: 50px;
                                                            padding: 10px;
                                                        "
                                                    >
                                                        <span
                                                            class="option__title"
                                                            style="
                                                                font-size: 18px;
                                                                color: #4f5467;
                                                            "
                                                            >{{ props.option.title }}</span
                                                        ><br />
                                                        <span
                                                            class="option__small"
                                                            style="
                                                                font-size: 14px;
                                                                color: #6a7a8c;
                                                            "
                                                            >{{ props.option.desc }}</span
                                                        >
                                                    </div>
                                                </div>
                                            </template>
                                        </multiselect>
                                    </td>
                                    <td>
                                        <button type="submit" class="btn btn-success" style="margin-left:30px">
                                            Save
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-if="isLoading">
                                <tr>
                                    <td colspan="3" class="text-center"><Loader></Loader></td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader.vue";
import Multiselect from "vue-multiselect";
import { mapGetters } from "vuex";
export default {
    name: "assignUser",
    components: {
        Loader,
        Multiselect,
    },
    data() {
        return {
            isLoading: false,
            user_id: window.localAccountID,
            org_id: window.localorgdata,
            user_id_assign: 0,
            userlist: [],
            search: "",
            f: {
                userIdAssign: [],
                idJobz: 0,
            },
            userIdAssign: [],
            asing: [],
            idJobz: [],
            options: [],
            value: [],
            ownerL: "",
            ownerName: "",
            ownerEmail: "",
            ownerImg: "",
            show_privileges: false,
            user_privilege: [],
        };
    },
    created() {
        this.init()
    },
    methods: {
        init() {
            let urlApis = this.$store.getters['ModApps/jobs/urlApis'];
            this.apis = urlApis.jobList;             
            let g = this.$store.getters['core/getOpenModal'];
            let r = g.data.g;
            let t = this    
            t.user_id_assign = r.userID
            t.f.idJobz = r.idJobs
            t.idJobz = r.idJobs
            t.titleModalTaks = r.titleModalTaks
            t.closeModals = r.closeModals
            this.iniJobsList();
        },        
        okPrivileges() {
            let o = this.f.userIdAssign;
            for (let i in o) {
                if (o[i].id == this.user_privilege.id) {
                    this.f.userIdAssign[i].privilegio = this.user_privilege.privilegio;
                }
            }
            this.closeP();
            console.log(this.f.userIdAssign);
        },
        closeP() {
            this.user_privilege = [];
            this.show_privileges = false;
        },
        privilegiosUser(e) {
            this.user_privilege = e;
            this.show_privileges = true;
            console.log("seleccionado", this.user_privilege);
        },
        customLabel({ title, desc, img }) {
            return `${title} – ${desc}`;
        },
        iniJobsList() {
            this.getForm().then((response) => {
                let r = this.asing;
                let o = r.length;
                if (o > 0) {
                    this.getUsersList();
                } else {
                    this.getUsersList();
                }
            });
        },
        getForm() {
            return new Promise((resolve, reject) => {
                this.isLoading = true;
                let url = this.apis.getTaks()
                window.master
                    .post(url, {
                        idJobz: this.f.idJobz,
                        user_id: window.localAccountID,
                    })
                    .then((response) => {
                        let r = response.data.res;
                        this.asing = r;
                        this.isLoading = false;
                        resolve(true);
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        console.log(error);
                        reject(false);
                    });
            });
        },
        getUsersList() {
            this.isLoading = true;
            let url = this.apis.getUsers()
            window.master
                .get(url)
                .then((response) => {
                    let e = response.data.rs;
                    let q = response.data.r;
                    console.log(q);
                    let a = q[0].name;
                    this.ownerL = a.charAt().toUpperCase();
                    this.ownerName = q[0].name;
                    this.ownerEmail = q[0].email;
                    this.ownerImg = "";
                    this.userlist = [];
                    if (this.user_id_assign > 0) {
                        if (e.length > 0) {
                            for (let i in e) {
                                if (this.user_id_assign == e[i].account_id) {
                                    let first = e[i].firstname;
                                    let last = e[i].lastname;
                                    let w =
                                        first.charAt().toUpperCase() +
                                        last.charAt().toUpperCase();
                                    this.userlist.push({
                                        id: e[i].account_id,
                                        title: e[i].name,
                                        desc: e[i].email,
                                        img: e[i].avatar,
                                        default: w,
                                        privilegio: e[i].privilegio,
                                    });
                                }
                            }
                        }
                    }
                    let check = 0;
                    if (this.user_id_assign == 0) {
                        check = 1;
                    }
                    if (e.length > 0) {
                        for (let i in e) {
                            if (this.user_id_assign != e[i].account_id) {
                                let first = e[i].firstname;
                                let last = e[i].lastname;
                                let w =
                                    first.charAt().toUpperCase() +
                                    last.charAt().toUpperCase();
                                this.userlist.push({
                                    id: e[i].account_id,
                                    title: e[i].name,
                                    desc: e[i].email,
                                    img: e[i].avatar,
                                    default: w,
                                    privilegio: e[i].privilegio,
                                });
                            }
                        }
                    }
                    this.userIdAssign = this.userlist;
                    let object = this.userIdAssign;
                    let f = this.asing;
                    let t = 0;
                    for (const key in object) {
                        for (const i in f) {
                            if (f[i].user_id_assign == object[key].id) {
                                let tx = object[key].title;
                                let w = tx.charAt().toUpperCase();
                                this.f.userIdAssign.push({
                                    id: f[i].user_id_assign,
                                    title: object[key].title,
                                    desc: object[key].desc,
                                    img: object[key].avatar,
                                    default: w,
                                    privilegio: f[i].privilegio,
                                });
                            }
                        }
                    }
                    console.log("userIdAssign", this.f.userIdAssign);
                    this.value = this.f.userIdAssign;
                    this.options = this.userIdAssign;
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        form() {
            this.isLoading = true;
            let p = this.f.idJobz;
            var y = [];
            let z = this.f.userIdAssign;
            for (const i in p) {
                let t = [];
                for (const q in z) {
                    t.push({
                        userIdAssign: z[q].id,
                        privilegio: z[q].privilegio,
                    });
                }
                y.push({
                    assign_to: t,
                    idJobz: p[i].ids,
                });
            }
            let url = this.apis.addJobs()
            window.master
                .post(url, {
                    user_id: window.master_client,
                    org_id: this.org_id,
                    app_id: this.getAppIDactual,
                    data: y,
                })
                .then((response) => {
                    console.log(response.data);
                    this.isLoading = false;
                    let len = y[0].assign_to.length;
                    let o = [];
                    for (const r in y) {
                        if (len > 0) {
                            o.push({
                                idJobz: y[r].idJobz,
                                assign_to: y[r].assign_to,
                                status: true,
                            });
                        } else {
                            o.push({
                                idJobz: y[r].idJobz,
                                assign_to: "",
                                status: false,
                            });
                        }
                    }
                    this.$store.commit("ModApps/jobs/set_jobsListAssignUser", o);
                    let t = this
                    this.$store.commit("core/setLoadingModal", false);
                    this.$store.commit("core/setCloseModal", {
                        action: 'saved',
                    });
                    this.$parent.close();
                })
                .catch((error) => {
                    this.btnSave = false;
                    this.isLoading = false;
                    this.$notify({
                        group: "noti",
                        type: "error",
                        title: "Error",
                        text: "failed to save, try later!",
                    });
                    console.log(error);
                });
        },
    },
    computed: {
        ...mapGetters("ModApps/jobs", ["get_changeAssignUser"]),
        ...mapGetters("core/apps", { getAppIDactual: "getAppIDactual" }),
    },
};
</script>
<style lang="scss" scoped>
span.avatarDefault {
    width: 50px;
    height: 50px;
    display: flex;
    margin: 0px auto;
    border: 1px solid #ccc;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 25px;
    font-weight: 900;
    background-color: #5ac146;
    color: #fff;
}
.noneRow {
    opacity: 0;
}
.privilegios {
    position: absolute;
    z-index: 1;
    width: 100%;
}
.privilegios label.c-input.c-radio {
    margin-right: 20px;
}
</style>
