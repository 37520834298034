<template>
	<div class="container-principal">
		<navtop></navtop>
		<barmenu></barmenu>
		<div class="row page-section">
			<div class="container-fluid">
				<div class="row" v-if="selectTheme">
					<div v-for="(i, index) in listThemes" :key="index" class="col-4 item-theme">
						<div class="card bg-light">
							  <img :src="i.image" class="card-img-top" :alt="i.name">
							  <div class="card-body">
							    <p class="card-text">{{i.name}}</p>
							    <div class="botones">
							    	<button type="button" class="btn btn-primary" @click="installTheme(i)">Install</button>
							    	<button type="button" class="btn btn-light" @click="viewPrevTheme(i)">Preview</button>
							    </div>
							  </div>
							</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import navtop from "../components/sitebuilder/navTop.vue";
import barmenu from "../components/sitebuilder/barMenu.vue";
export default {
  components: {
		navtop,
		barmenu,
  },
  name: 'SitesThemes',
  data() {
    return {
      selectTheme: true,
      webid: 0,
      listThemes: [
      	{
	      	id: 1,
	      	name: 'Template 1',
	      	image: 'https://marketeabeta.com/files/themes/preview1.webp',
	      	preview: 'https://maximusbuilders.com/',
	      },
	      {
	      	id: 2,
	      	name: 'Template 2',
	      	image: 'https://marketeabeta.com/files/themes/preview1.webp',
	      	preview: 'https://maximusbuilders.com/',
	      },
	      {
	      	id: 3,
	      	name: 'Template 3',
	      	image: 'https://marketeabeta.com/files/themes/preview1.webp',
	      	preview: 'https://maximusbuilders.com/',
	      },
      ],
    }
  },
  created(){
  	let t = this;
    t.listWebs = t.$store.getters['ModApps/Sites/get_webs'];
    if(t.$route.params.webid){
    	t.selectTheme=true;
    	t.webid = t.$route.params.webid;
    }else{
    	t.selectTheme=false;
    	t.$router.push({ name: "Sites"});
    }
  },
  methods:{
		viewPrevTheme(i) {
			window.open(i.preview, "_blank");
		},
		installTheme(i) {
      let t = this;
  		t.$store.commit('ModApps/Sites/set_loadSites', true);     
      window.master
      .post('sites/installTheme', {
          user_id: window.master_client,
          org_id: window.localorgdata,
          token: window.localtoken_M,
          web_id: t.webid,
          theme_id: i.id,
      })
      .then((response) => {
        console.log('installTheme', response.data)
        if(response.data.status){
      		t.$router.push({ name: "editor", params: { webid: response.data.webid } });
        }
        t.$store.commit('ModApps/Sites/set_loadSites', false);
      })
      .catch((error) => {
        console.log(error);
        t.$store.commit('ModApps/Sites/set_loadSites', false);
      });
		},
  }
};
</script>
<style scoped lang="scss">
.container-principal{
	margin-top: 0px;
}
.page-section{
   margin-top: 10px;
   margin-left: 100px;
}
.img-preview{
	width: 100%;
}
.item-theme{
	margin: auto;
	margin-bottom: 20px;
	padding: 5px 15px;
	max-width: 650px;
	.card-body{
		padding: 5px 10px;
		.card-text{
			text-align: center;
			font-weight: bold;
			text-transform: uppercase;
			margin-bottom: 5px;
		}
		.botones{
			text-align: center;
			.btn-primary{
				margin-right: 5px;
				margin-bottom: 10px;
				padding: 0.275rem 2rem;
				font-size: 16px;
			}
			.btn-light{
				border-color: #ccc;
				margin-left: 5px;
				margin-bottom: 10px;
			}
		}
	}
}
</style>
