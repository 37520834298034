let get_editTags = function() {
	if (this.get_editTags.active) {
		this.tagsList = this.get_editTags.option;
		this.tags = this.get_editTags.values;		
		this.m = {
			show: true,
			component: 'assignTags',
			data: {}
		};
		this.$store.commit('core/setOpenModal', this.m);
	}
};
export default get_editTags;
