export default {
	getAppIDactual(state) {return state.appIDactual; },
	modulos(state) { return state.modulos; },
	applistorgs(state){return state.appAll; },
	getListAvailableApps(state){return state.listAvailableApps; },
	get_appRolUser(state){ return state.appRolUser;},
	get_adminApp(state){ return state.adminApp;},
	getPlanIdApp(state){ return state.planIdApp;},
	getModulesMenu(state){ return state.modulesMenu; },
	getAppStatus(state){
		let status= {
			paymentStatus: null, // statusPay=0 es unpaid, statusPay=1 es  paid, statusPay=2 es cancelled (ya no se usa), statusPay=3 es  declined
			appStatus: null, //assingStatus=0 app inactive, statusApp=3 app cancelled, statusApp=2 app suspended, statusApp=1 app active
		};
		state.appAll.filter(a => {
			if(a.id == state.appIDactual){
				status.paymentStatus = a.statusPay
				status.appStatus = (a.assingStatus==0 || a.statusApp==3) ? -1 : ((a.statusApp==2) ? 0 : 1);
			}
		});
		// status.appStatus== -1 es app inactiva
		// status.appStatus== 0 es app bloqueo
		// status.appStatus== 1 es app activa
		// status.paymentStatus== statusPay
		return status
	},
	getMsgAccessDeneid(state){
		return {
			title: 'Access denied!',
			msg: "You do not have permission to enter this application. Please contact your administrator at support@marketea.com or Call us at 774-287-3353",
		}
	},
	getMsgPaymentAlert(state){
		let nameApp=null;
		state.appAll.filter(a => {if(a.id == state.appIDactual){nameApp = a.name}});
		return {
			title: '¡'+nameApp+', UnPaid!',
			msg: "Make the payment corresponding to your plan, avoid suspension. Please contact your administrator at support@marketea.com or Call us at 774-287-3353",
		}
	},
	getMsgAccessSuspended(state){
		let nameApp = null;
		return {
			title: 'Access suspended',
			msg: "Your App <b>[nameApp]</b> was suspended.<br> Please contact your administrator at <br>support@marketea.com <br>or Call us at 774-287-3353",
		}
	},
}
