<template>
    <div>
        <div class="header">
            <div class="block block1" style="height: 40px">
                <menuApps></menuApps>
            </div>
            <div class="block block2">
                <ul class="menuTop">
                    <li>
                        <button class="btn-refresh" @click="refreshApp">
                            <i class="fas fa-sync-alt"></i>
                            <span class="tooltip">Refresh</span>
                        </button>
                    </li>
                    <!-- <li>menuLang </li> -->
                    <li><menuOrg @setTriggerAddOrg="getTriggerAddOrg($event)"></menuOrg></li>
                    <!-- <li><menuChat /></li> -->
                    <!-- <li> <menuNoti /></li> -->
                    <li><menuAccount :triggerAddOrg="triggerAddOrg"></menuAccount></li>
                </ul>
            </div>
        </div>
        <div id="loading" v-if="isLoading"><div class="op-loading"></div></div>
        <notiPusher></notiPusher>
        <alertsConfirm></alertsConfirm>
        <bsite-sus></bsite-sus>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import menuApps from "@/core/components/Header/menuApps";
import menuOrg from "@/core/components/Header/menuOrg";
import menuAccount from "@/core/components/Header/menuAccount";
//import menuNoti from "@/core/components/Header/menuNoti";
//import menuChat from "@/core/components/Header/menuChat";
import notiPusher from "@/core/plugins/notiAlerts/notiPusher";
import alertsConfirm from "@/core/plugins/notiAlerts/alertsConfirm";
import bsite_sus from "@/core/components/suscriptionApp/bsite_sus";
export default {
    name: "Header",
    components: {
        menuApps,
        menuOrg,
        menuAccount,
        //menuNoti,
        //menuChat,
        notiPusher,
        alertsConfirm,
        'bsite-sus': bsite_sus,
    },
    data() {
        return {
            isLoading: false,
            app: null,
            activeIndex: null,
            isDimenu: false,
            validViews: [
                "Home",
                "Profile",
                "Organizations",
                "Security",
                "Users",
                "Privilegies",
                "PrivilegiesList",
                "AddOrganization",
            ],
            AppIDactual: 0,
            triggerAddOrg: 0,
            nameCompSus: null,
        };
    },
    created() {
        let t = this;
        t.$store.commit("set_isLoading", true);
        // console.log('store appsorg = ', t.$store.getters['core/apps/appsorg'])
        t.AppIDactual=t.$store.getters['core/apps/getAppIDactual']
        // console.log('header created getAppIDactual', t.AppIDactual)
        t.activeIndex = Number(localStorage.getItem("menu_top_active"));
        if (t.validViews.indexOf(t.$router.currentRoute.name) > -1) {
            t.app = "Home";
            t.activeIndex = null;
        } else {
            t.app = null;
        }
        // console.log('$router= ', t.$router.currentRoute.name)
        let validSession = t.$store.getters["core/session/loggedIn"];
        if (!validSession) {
            console.log("validando validToken master");
            t.$store.dispatch("core/session/validToken").then((response) => {
                // console.log('validando token master desde header ', response);
                if (!response.data.tokenValid) {
                    //console.log('fallo validacion de token, cerrando sesion');
                    t.$router.push({ name: "Logout" });
                } else {
                    t.getDataSession();
                    t.$store.dispatch(
                        "core/session/SET_EMAIL_VERIFY",
                        response.data.emailVerify
                    );
                }
            });
        }else {
            t.getDataSession();
            let emailVerify = (localStorage.getItem('emailVerify') > 0);
            //debugger;
            t.$store.dispatch("core/session/SET_EMAIL_VERIFY", emailVerify);
        }
    },
    mounted() {
        this.getMessagesExt();
        this.$postMessage('webExterna', '*', window.parent);
    },
    methods: {
        getMessagesExt() {
            this.$receiveMessage((e) => {
                if (e.data.ext) {
                    console.log('getMessagesExt', e);
                    window.top.location.replace(window.self.location.href);
                }
            }, false);
        },
        getDataSession() {
            let t = this;
            t.$store
                .dispatch("core/session/getDataSessionLogin")
                .then((response) => {
                    // console.log('getDataSession', response);
                        t.$store.commit("core/SET_HEADER", true);
                    if(!localStorage.getItem('appStart')){
                        t.$store.commit("set_isLoading", false);
                    }else{
                        t.$store.commit("set_isLoading", true);
                    }
                })
                .catch((error) => {
                    console.log("getDataSession", error);
                    t.$store.commit("set_isLoading", false);
                    t.$router.push({ name: "Logout" });
                });
        },
        refreshApp() {
            let d = new Date();
            let t = d.getTime();
            window.location = window.location.href + "?cache=false&" + t;
            // window.location.reload(true);
        },
        getTriggerAddOrg(event){
            // console.log('triggerAddOrg', event);
            this.triggerAddOrg++;
        },
    },
    watch: {
        isLoading: function () {
            console.log('isLoading', this.isLoading)
        },
        get_isLoading: function () {
            //console.log('get_isLoading', this.get_isLoading);
            this.isLoading = this.get_isLoading;
            if (!this.get_isLoading) {
                this.orgSelect = localStorage.getItem("nomCompany");
            }
        },
        accitonPusher: function () {
            let t = this;
            if (t.accitonPusher != undefined) {
                console.log("accitonPusher", t.accitonPusher);
                let token = "Bearer " + localStorage.getItem('access_token_m');
                let oauth_m = localStorage.getItem('oauth_m');
                if (t.accitonPusher.token === token || t.accitonPusher.oauth === oauth_m) {
                    if (t.accitonPusher.action === "disconnected") { 
                        console.log("desconectado");
                        if(localStorage.getItem('disconnected') == null) {
                            t.$notify({
                                group: 'noti',
                                type: 'error',
                                title: 'Disconnected',
                                text: 'session expired!',
                            });
                            localStorage.setItem('disconnected', 1);
                            t.$store.dispatch("core/session/logoutV2").then((response) => {
                                t.$router.push({ name: "Login" });
                            });
                        }
                    }
                }
                else if(t.accitonPusher.action === "actAppRol" || t.accitonPusher.action=="statusPay-crm"){
                    let data = t.accitonPusher.data;
                    if(window.localorgdata == data.org_id && window.localAccountID == data.user_id){
                        t.$store.commit("set_isLoading", true);
                        t.$store.dispatch("core/session/actAppRol", data).then((r) => {
                            let getAppIDactual = t.$store.getters['core/apps/getAppIDactual'];
                            if(getAppIDactual == 0){t.$store.commit("set_isLoading", false);}
                            else
                            {
                                console.log('validando access app', getAppIDactual)
                                t.$store.dispatch('core/apps/validAccess').then((res) => {
                                    t.$store.commit("set_isLoading", false);
                                    if(!res){
                                        console.log('validAccess', res);
                                        /*setTimeout(function(){
                                            t.$store.commit('core/setNotiPush', {
                                                show: true,
                                                group: "notiPush",
                                                type: '5', // 1=success|2=info|3=error|4=loading|5=warning
                                                title: 'Warning',
                                                text: 'The privileges of this application have been suspended, contact your administrator, redirect home',
                                                duration: 10000
                                            });
                                        }, 400);*/
                                        t.$router.push({ name: "Home" });
                                    }
                                });
                            }
                        });
                    }
                }
                else if(t.accitonPusher.action === "statusBilling"){
                    let data = t.accitonPusher.data;
                    if(window.localorgdata == data.org_id && window.localAccountID == data.user_id){
                        t.$store.dispatch("core/session/actAppRol", data).then((r) => {
                            if(localStorage.setItem('statusBilling', data.statusBilling)){
                                console.log('set statusBilling', localStorage.setItem('statusBilling'))
                            }
                            let getAppIDactual = t.$store.getters['core/apps/getAppIDactual'];
                            if(getAppIDactual == 0){t.$store.commit("set_isLoading", false);}
                            else if(getAppIDactual == 1)
                            {
                                console.log('validando access app', getAppIDactual)
                                t.$store.commit("set_isLoading", false);
                                if(data.statusBilling == 2 || data.statusBilling == 3){
                                    let msj = 'Your App was suspended, please contact our support department support@marketea.com.';
                                    setTimeout(function(){
                                        t.$store.commit('core/setNotiPush', {
                                            show: true,
                                            group: "notiPush",
                                            type: '5', // 1=success|2=info|3=error|4=loading|5=warning
                                            title: 'Warning',
                                            text: msj,
                                            duration: 15000
                                        });
                                    }, 400);
                                    t.$router.push({ name: "Home" });
                                }
                            }
                        });
                    }
                }
                else if(t.accitonPusher.action === "verifyemail"){
                    let data = t.accitonPusher.data;
                    if(window.localAccountID == data.user_id){
                        localStorage.setItem('emailVerify', 1);
                        localStorage.removeItem('getDataSession');
                        t.$store.dispatch("core/session/actAppRol", data).then((r) => {
                            window.location.reload(true);
                        });
                    }
                }
                else if(t.accitonPusher.action == "refreshApp_bsite"){
                    console.log('refreshApp')
                    if(t.accitonPusher.data.org_id == window.localorgdata){
                        setTimeout(function(){
                            location.reload(true);
                        }, 1000);
                    }
                }
            }
        },
        getAppIDactual: function () {
            this.AppIDactual = this.getAppIDactual;
            // console.log('switchingApp watch getAppIDactual', this.getAppIDactual)
        },
    },
    computed: {
        ...mapGetters({
            get_isLoading: "get_isLoading",
        }),
        ...mapGetters("core", {
            notiPush: "getNotiPush",
            accitonPusher: "get_accitonPusher",
        }),
        ...mapGetters("core/session", {
            access_account: "access_account",
            loggedIn: "loggedIn"
        }),
        ...mapGetters("core/apps", {
            appRolUser: "get_appRolUser",
            appIDactual: "getAppIDactual",
        }),
    },
};
</script>
<style lang="css" scoped>
    @import '../assets/css/headerStyles.scss';
</style>
