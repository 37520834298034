let mostrarBtnAddFinal = function(e, id_i, taks, indey) {
    let btn = '.btnAddTask-'+id_i;
    let elem = e.target
    let her = elem.nextElementSibling;
    /*if(indey=='no'){
        her.classList.remove('hide');
        elem.classList.add('hide'); 
        setTimeout(function(){
            elem.style.height = elem.getAttribute("data-heigth") + 'px';
            console.log('nueva altura', elem.style.height)
        }, 500)      
    }else{
        elem.style.height = '84px';
    }*/
    if(document.querySelector(btn)){
        console.log(btn);
        if(taks.length == (indey+1)){
            document.querySelector(btn).classList.add("showBtnAdd");
        }
        else{
            setTimeout(function(){
                console.log('remover showBtnAdd');
                document.querySelector(btn).classList.remove("showBtnAdd");
            }, 2000);
        }
    }
};
export default mostrarBtnAddFinal;
