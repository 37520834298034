<template>
    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col-6" style="padding-top: 10px">
                    <h5 class="card-title">Jobs #{{ jobsNoteNum }}</h5>
                </div>
            </div>
        </div>
        <div class="card-body" style="padding: 0.5em !important;height: auto;min-height: 200px;overflow: hidden;overflow-y: auto;">
            <div class="row">
                <div class="col-md-12">
                    <input type="file"
                        id="imgNotes"
                        accept=".jpg,.jpeg,.png"
                        multiple
                        style="display: none"
                        @change="subirIMG($event)"
                        ref="addGalley"
                        v-if="jobsEdicion"
                    />
                    <div class="btn-group" v-if="jobsEdicion">
                        <button type="button" class="btn btn-secondary" @click="displayRef" v-if="JobsGalleryList.length <10">
                            <i class="fas fa-paperclip"></i> Upload Images {{ JobsGalleryList.length }}
                        </button>
                        <button type="button" class="btn btn-danger" @click="deleteAllIMG" v-if="JobsGalleryList.length > 0" style="margin-left: 10px">
                            <i class="fas fa-trash"></i> Delete All
                        </button>
                    </div>
                    <div class="col-12" v-if="isLoadingImgNotes">
                        <Loader></Loader>
                    </div>
                    <galleryjobs css="h-300"
                        :items="JobsGalleryList"
                        :url="JobsGalleryURL"
                        :typez="typez"
                        :cells="5"
                        v-if="JobsIFGallery == 1"
                    ></galleryjobs>
                </div>
            </div>
        </div>
    </div>    
</template>
<script>
import { mapGetters } from 'vuex'
import Loader from '@/core/components/shared/Loader'
import galleryjobs from '@/apps/jobs/plugins/gallery/galleryJobsCover'
import '@/apps/jobs/plugins/gallery/galleryJobs.css'
export default {
    name: "galleryBox",
    components: {
        Loader,
        galleryjobs,
    },
    data () {
        return {
            typez: 0,
            jobsNoteNum: 0,
            jobsNoteID: 0,
            jobsNote: "",
            jobsNoteBtn: false,
            jobsEdicion: false,
            jobsLinkDrive: "",
            jobsLinkDriveEdit: false,
            JobsIFGallery: 0,
            JobsGalleryList: [],
            JobsGalleryURL: "",
            JobsGallerySHOW: false,      
            isLoadingImgNotes: false,
            listTaks: [],    
        }
    },
    created () {
        this.init()
    },
    methods: {
        init() {
            let urlApis = this.$store.getters['ModApps/jobs/urlApis'];
            this.apis = urlApis.jobBox;            
            let g = this.$store.getters['core/getOpenModal'];
            let r = g.data.g;
            let t = this;
            t.jobsNoteNum = r.jobsNoteNum
            t.jobsNoteID = r.jobsNoteID
            t.jobsNote = r.jobsNote
            t.jobsEdicion = r.jobsEdicion
            t.jobsLinkDrive = r.jobsLinkDrive
            t.JobsIFGallery = r.JobsIFGallery
            t.JobsGalleryList = r.JobsGalleryList
            t.JobsGalleryURL = r.JobsGalleryURL
            t.JobsGallerySHOW = r.JobsGallerySHOW
            t.typez = r.typez
            t.listTaks = r.listTaks
        },
        actualizarList() {
            let t = this;
            let g = {
                jobsNoteNum: t.jobsNoteNum,
                jobsNoteID: t.jobsNoteID,
                jobsNote: t.jobsNote,
                jobsEdicion: t.jobsEdicion,
                jobsLinkDrive: t.jobsLinkDrive,
                JobsIFGallery: t.JobsIFGallery,
                JobsGalleryList: t.JobsGalleryList,
                JobsGalleryURL: t.JobsGalleryURL,
                JobsGallerySHOW: t.JobsGallerySHOW,
                typez: t.typez,
                listTaks: t.listTaks,
                methods:"galleryBox",
                action:"modal",
            }
            t.$store.commit("ModApps/jobs/set_jobsDash", g);
        },
        subirIMG(event) {
            this.isLoadingImgNotes = true;
            let t = this;
            let fileSendHeader = { headers: { "Content-Type": "multipart/form-data" } };
            let formData = new FormData();
            let cont = [];
            let maxFiles = 10 - this.JobsGalleryList.length;
            console.log('maxFiles', maxFiles)
            for (let i in event.target.files) {
                if (event.target.files.hasOwnProperty(i)) {
                    cont++;
                    formData.append("image" + cont, event.target.files[i]);
                    maxFiles--;
                    console.log('maxFiles', maxFiles)
                }
            }
            formData.append("nombreIMG", "image");
            formData.append("cont", cont);
            formData.append("jobs_id", this.jobsNoteID);
            formData.append("org_id", window.localuserdata);
            formData.append("user_id", window.master_client);
            let url = this.apis.uploadImg()
            if(maxFiles<0){
                this.$notify({
                    group: "noti",
                    type: "error",
                    title: "Error",
                    text: "Max files 10",
                });
                event.target.value=null;
                this.isLoadingImgNotes = false;
            }else{
                window.master
                .post(url, formData, fileSendHeader)
                .then((response) => {
                    if (response.data.status) {
                        for (let i in t.listTaks) {
                            if (t.listTaks.hasOwnProperty(i)) {
                                if (t.listTaks[i].id_i == this.jobsNoteID) {
                                    t.listTaks[i].gallery = 1;
                                    for (let prop in response.data.insert) {
                                        if (response.data.insert.hasOwnProperty(prop)) {
                                            t.listTaks[i].galleryList.push(
                                                response.data.insert[prop]
                                            );
                                        }
                                    }
                                    t.listTaks[i].url = response.data.url;
                                    console.log("galleryList", t.listTaks[i].galleryList);
                                    t.JobsIFGallery = t.listTaks[i].gallery;
                                    t.JobsGalleryList = t.listTaks[i].galleryList;
                                    t.JobsGalleryURL = t.listTaks[i].url;
                                }
                            }
                        }
                        t.actualizarList();
                    }
                    t.isLoadingImgNotes = false;
                })
                .catch((error) => {
                    console.log(error);
                    t.isLoadingImgNotes = false;
                });
            }
        }, 
        displayRef() {
            this.isLoadingImgNotes = true
            this.$refs.addGalley.click();
        },    
        deleteAllIMG() {
            let t = this;
            this.$swal({
                title: "Delete all image?",
                text: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
                if (result.value) {
                    t.isLoadingImgNotes = true;
                    let url = this.apis.elImg()
                    window.master
                        .post(url, {
                            user_id: window.master_client,
                            org_id: window.localorgdata,
                            job_id: t.jobsNoteID,
                        })
                        .then((response) => {
                            if (response.data.status) {
                                for (let j in t.listTaks) {
                                    if (t.listTaks.hasOwnProperty(j)) {
                                        if (t.listTaks[j].id_i == t.jobsNoteID) {
                                            t.listTaks[j].gallery = 0;
                                            t.listTaks[j].galleryList = [];
                                            t.JobsIFGallery = t.listTaks[j].gallery;
                                            t.JobsGalleryList = t.listTaks[j].galleryList;
                                            console.log(
                                                "delImgAllGallery",
                                                t.listTaks[j].galleryList
                                            );
                                        }
                                    }
                                }
                                t.actualizarList();
                            }
                            t.isLoadingImgNotes = false;
                        })
                        .catch((error) => {
                            console.log(error);
                            t.isLoadingImgNotes = false;
                        });
                }
            });
        },
        editLinkDrive() {
            this.jobsLinkDriveEdit = !this.jobsLinkDriveEdit;
        },  
        saveNotes() {
            this.jobsNoteBtn = true;
            window.master
                .post("jobs/editNotes", {
                    user_id: this.user_id,
                    org_id: this.org_id,
                    id: this.jobsNoteID,
                    notes: this.jobsNote,
                    link_drive: this.jobsLinkDrive,
                })
                .then((response) => {
                    this.$notify({
                        group: "noti",
                        type: "success",
                        title: "Success",
                        text: "Updated note",
                    });
                    this.jobsNoteBtn = false;
                    this.getJobsDash();
                })
                .catch((error) => {
                    console.log(error);
                    this.jobsNoteBtn = false;
                });
        },                            
    },
    watch: {
        getOpenModal: function() {
            if (this.getOpenModal.show) {
                if (Object.keys(this.getOpenModal.data).length > 0) {
                    let t = this.getOpenModal.data;
                    if (t.methods === 'galleryBox') {
                        this.init();
                    }                                                        
                }
            }
        },
    },
    computed: {
        ...mapGetters("core", {getOpenModal: "getOpenModal"})
    }
};
</script>
<style lang="scss" scoped>
.jobsNote img {
    width: 50%;
}
</style>
