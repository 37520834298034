<template>
	<div class="container-visualizer">
		<div class="header_visualizer">
			<div class="box-head"></div>
			<div class="box-head">
				<button type="button" :class="['btn btn-open', (!activeCompleted) ? 'active' : '']" @click="viewOpen">
					<span>{{ openCounter }}</span>Open
				</button>
				<button type="button" :class="['btn btn-completed', (activeCompleted) ? 'active' : '']" @click="viewCompleted">
					<span>{{ countCompleted }}</span>Completed
				</button>
			</div>
			<div class="box-head">
				<button type="button" class="btn submenu-head"><i class="fas fa-ellipsis-v"></i></button>
			</div>
		</div>
		<div class="content-box">
			<Loader v-if="isLoading"></Loader>
				<div class="taks-columns task-list" v-else>
					<div
						v-for="(i, index) in dataList"
						:key="index"
						v-bind:class="['item-taks', i.selected ? 'active' : '']"
					>
						<div class="card">
							<div class="card-header">
								<!-- <span class="numID" >#{{ i.idx }}</span> -->
								<span :class="['date', 'status'+i.status_id]">
									<span class="label-date">
										{{ i.due_date | moment("MMM DD, YYYY")}}<br />
										{{ new Date(i.due_date).toLocaleTimeString("en-us", optionsTime)}}
									</span>
									<date-picker 
										v-model="i.due_date"
										value-type="format" 
                                        format="YYYY-MM-DD HH:mm:ss"
                                        type="datetime"
										input-class="mx-input"
										:minute-step="5"
                                        :show-second="false"
										:confirm="true"
                                        use12h
										@input="changeDate(i)"
									>
                                                <template v-slot:header="{ emit }" class="header-date-picker">
                                                    <button class="mx-btn mx-btn-text btn-today" @click="emit(new Date())">Today</button>
                                                </template>
                                    </date-picker>
								</span>
								<span class="daysAgo">
									<span v-if="i.status_id==2">Closed</span>
									<span v-else-if="i.days>0 && i.days > i.daysBefore" class="on_time"> {{ i.days }} days left</span>
									<span v-else-if="i.days>0 && i.days <= i.daysBefore" class="time_running_out"> {{ i.days }} days left</span>
									<span v-else-if="i.days==0" class="today"> Due Today</span>
									<span v-else class="overdue"> Overdue </span>
								</span>
								<span :class="['status', 'task_status']" >
									<span :class="[
										'badge badge-pill', 
										'status_'+i.status_id, 
										(i.status_id<=1 && i.days<0) ? 'uncompleted' : 
											(i.status_id == 0) ? 'badge-warning' : 
												(i.status_id == 1) ? 'badge-success' : 
													(i.status_id == 2) ? 'badge-info' : '',
										]"
									>
										<span v-if="i.status_id<=1 && i.days<0">Uncompleted</span>
										<span v-else-if="i.status_id==0">Pending</span>
										<span v-else-if="i.status_id==1">In Progress</span>
										<span v-else-if="i.status_id==2">Completed</span>
										<span v-else-if="i.status_id==3">Suspended</span>
										<span v-else>None</span>
									</span>
                                </span>
							</div>
							<div :class="['card-body',
									(i.status_id<=1 && i.days<0) ? 'bg1' : 
										(i.status_id<=1 && i.days==0) ? 'bg2' : 
											(i.status_id<=1 && i.days>0 && i.days <= i.daysBefore) ? 'bg3' : 'bg4' 
							]">
								<div :class="['client', 
									(i.status_id<=1 && i.days<0) ? 'bg1' : 
										(i.status_id<=1 && i.days==0) ? 'bg2' : 
											(i.status_id<=1 && i.days>0 && i.days <= i.daysBefore) ? 'bg3' : 'bg4' 
								]">
									<textarea     
										:class="['input-edit-name', 'task_name-'+i.id]" 
										v-model="i.task_name" 
										@change="changeInput('task_name', i)"
                                        @keydown="prevenirSaltosDeLinea"
										maxlength="200"
										:placeholder="(i.task_name!=null && i.task_name.length>0) ? 'Unnamed task' :'Task name'"
									></textarea>
								</div>   
								<div class="taks">
									<draggable
										v-model="i.actions"
										tag="ul"
										class="list-inline"
										v-bind="dragOptions"
										@start="isDragging(true)"
										@end="isDragging(false)"
										@change="checkMove(i, i.actions)"
										handle=".handle"
									>
										<template  v-for="(y, indey) in i.actions">
											<li class="list-inline-item" :key="indey" v-if="y.action_status_id <3">
												<div class="row">
													<div class="col8">
														<button
															type="button"
															class="moveAction handle"
														>
															<i class="bar-vertical"></i>	
														</button>
														<div >
															<textarea
																v-model="y.accion_name"
																:class="['input-action-name', 'action_name-'+i.id+'-'+y.id]" 
																@keydown="prevenirSaltosDeLinea"
																@change="editNameAction(i, y)"
																class=""
															></textarea>
														</div>
													</div>
													<div class="col4">
														<!-- <div class="status" v-if="y.isLoading"><Loader></Loader></div> -->
														<span class="status" >
															<span class="badge badge-pill badge-warning check-pedding"
																v-if="y.action_status_id == 0"
																@click="changeStatusAction(i, y, indey, 2)"
															>
																Pending
															</span>
															<span class="badge badge-pill badge-info check-done"
																v-if="y.action_status_id == 2"
																@click="changeStatusAction(i, y, indey, 0)"
															>
																Done
															</span>
														</span>
													</div>
												</div>
											</li>
										</template>
									</draggable>
									<ul class="list-inline">
										<li :class="'list-inline-item btnAddTask btnAddTask-'+i.id">
											<button
												class="btn btn btn-sm "
												style="margin-left: 10px"
											>
												<i class="fas fa-plus"></i> Add Tasks
											</button>										
										</li>								
									</ul>
								</div>
								<div class="tags">									
									<span v-for="(c, catIdx) in i.category" :key="catIdx">
										<button class="badge badge-pill badge-light" @click="delTags(i, catIdx)">
											<span class="delTags" >
												<i class="fa fa-times"></i>
											</span>
											{{ c.category_name }}
										</button>
									</span>
									<span>
										<button class="badge badge-pill badge-light addTags"  @click="selectTags(i)">
											<i class="fa fa-plus"></i>
										</button>
									</span>							
								</div>
							</div>
							<div :class="['card-footer', 
									(i.status_id<=1 && i.days<0) ? 'bg1' : 
										(i.status_id<=1 && i.days==0) ? 'bg2' : 
											(i.status_id<=1 && i.days>0 && i.days <= i.daysBefore) ? 'bg3' : 'bg4' 
							]">
								<ul class="list-options">
									<li class="users" @click="assignUserPopover(i)"> 
										<div class="label-assign">
											<span :class="(i.assing.length>0) ? 'avatarUser join-avatar' : ''" v-if="i.assing.length==0">
												<i class="fas fa-user-plus"></i>
											</span>
                                            <template v-for="(a, aIdx) in i.assing">
                                                <span :key="aIdx" 
                                                    :class="['avatarUser join-avatar', (a.id==org_id)? 'bg-avatar-admin': 'bg-avatar'+aIdx]" 
                                                >
                                                    <span class="labelAvatar" >
                                                        {{a.pref_name}}
                                                    </span>
                                                    <!-- <i v-if="aIdx>0" class="fas fa-times" @click="assingUserRemove(i, a.id)"></i> -->
                                                </span>
                                            </template>
											<span class="tooltip">Assign User</span>
                                        </div>
									</li>
									<li class="edit" @click="open_task(i)">
										<i class="fas fa-pen"></i>
										<span class="tooltip">Edit Task</span>
									</li>
									<!-- <li class="images" >
										<i class="fas fa-paperclip"></i>
										<span class="tooltip">Images</span>
									</li> -->
									<li class="note" @click="actionNotes(i.id, i.isNote, i.note_id)">
										<i :class="[(i.isNote) ? 'fas fa-sticky-note active' : 'far fa-sticky-note']"></i>
										<span class="tooltip">Notes</span>
									</li>
									<!-- <li class="colores" >
										<button>
											<i class="fas fa-palette"></i>
										</button>
										<span class="paletaDeColores">
											<label
												class="c-input c-radio"
												v-for="(ic, indexc) in colorList"
												:key="indexc"
												@change="changeColor(i, ic.id, ic.name)"
											>
												<span 
													class="icon-check" 
													v-if="i.color_id == ic.id"
												>
													<i class="fas fa-check"></i>
												</span>
												<input type="radio"
													:name="'color-' + i.id"
													:value="ic.id"
													:checked="i.color_id == ic.id"
													style="margin-left: 8px;cursor: pointer;"
													required
												/>
												<div class="jobcolor" v-bind:style="{ background: ic.name, }"></div>
											</label>
										</span>
									</li> -->
									<li class="submenuFooter">
										<span class="cdropdown" v-click-outside="hideSubMenuFooter">
											<button>
												<i class="fas fa-ellipsis-v"></i>
											</button>
											<label>
												<input type="checkbox" :class="'open open-' + i.id" v-model="i.open"/>
												<ul class="list-optiones-extras text-left" >
													<li >
														<button>
															Duplicate
														</button>
													</li>
													<li @click="deleteTask(i)">
														<button>
															<i class="fas fa-times"></i>
															Delete
														</button>
													</li>
												</ul>
											</label>
										</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
		</div>	
		<!--btn add  -->
		<button
			@click="addTaksPop()"
			class="btn btn-info"
			id="addJobs"
		>
			<i class="fa fa-plus"></i>
		</button>
		<!-- modal-lateral -->
		<modalLateral class="modal-lateral">
			<component v-bind:is="m.component" ></component>
		</modalLateral> 
	</div>
</template>
<script>
import moment from 'moment';
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable';
import ClickOutside from 'vue-click-outside'
import Loader from '@/core/components/shared/Loader';
import NotesSO from '@/apps/billing/components/smartOffice/notesSO';
import modalLateral from '@/core/plugins/themeModalLateral/modalLateral';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import '@/apps/billing/components/smartOffice/datepicker.scss';
import formTask from '@/apps/billing/components/smartOffice/form_Task';
import selectTags from '@/apps/billing/components/smartOffice/selectTags';
import openTask from '@/apps/billing/components/smartOffice/openTask';
import assign_user from '@/apps/billing/components/smartOffice/assign_user';
export default {
	name: 'smartO_visualizer',
    components: {
        Loader,
		NotesSO,
        draggable,
		modalLateral,
		DatePicker,
		formTask,
		selectTags,
		openTask,
		assign_user,
    },
	data () {
		return {
			user_id: window.master_client,
			org_id: window.localorgdata,
			CategoryList: [],
			userlist: [],
			VendorList: [],
			dataList: [],
			activeCompleted: false,
			openCounter: 0,
			countCompleted: 0,
			isLoading: false,
			inputFilter: '',
			statuslist: [0, 1],
			filterTimer: 1, //1=all, 2=today, 3=this week, 4= this month
			yearFilter: new Date().getFullYear(),
			paginador: 0,
			offset: 4,
			page: 1,
			pagination: {
				current_page: 1,
			},
			rowNum: 100,
			rowList: [5, 10, 20, 50, 100, 200],
			numPag: [4],
			currSeconds: 0,
			timerRefresh: null,
			m: {
				show: false,
				component: '',
				data: []
			},
			colorList: [],
			optionsTime: {hour12: true, hour: "2-digit", minute: "2-digit"},
			showSubMenuFooter: false,
		}
	},
	created(){
		this.init();
	},	  
	mounted(){
		// detectar inactividad y consultar refresh
		let t = this;
		window.onload = t.refreshState;
		window.onmousemove = t.refreshState;
		window.onmousedown = t.refreshState;
		window.ontouchstart = t.refreshState;
		window.onclick = t.refreshState;
		window.onkeypress = t.refreshState;
		window.onresize = function(){
			t.grid_taks_ini();
		}
		//setInterval(() => {t.rectifyData();}, 60e3);
	},
    updated(){
        //console.log('update');
        this.calcHeigthTaskName();
    },
	directives: {
		ClickOutside
	},
	methods: {
		hideSubMenuFooter(e) {
			this.showSubMenuFooter= false;
			//console.log(e.target)
			/*let submenus = document.querySelectorAll('.' + e.target.classList[0]);
			submenus.forEach(function(i){
				if(i != e.target){
					i.checked = false;
				}else{
					console.log('checkSubmenu', i.classList[1], i.checked)
				}
			});*/
		},
		assignUserPopover(item){
			let t = this;
			t.m = {
				show: true,
				component: 'assign_user',
				data: {
					item: item, 
					userlist: t.userlist
				}
			}
			t.$store.commit('core/setOpenModal', this.m);  
		},
		viewCompleted(){
			this.activeCompleted = !this.activeCompleted;
			let t= this;
			t.statuslist= [2];
			t.getListTask();
		},
		viewOpen(){
			let t= this;
			t.statuslist= [0, 1];
			t.getListTask();
		},
		init(){
			let t = this;
			t.$store.commit("ModApps/billing/setVisibleAside", {'vista': 'SO_visualizer', 'aside': false});
			t.getcategory();
			t.getUsersList();
			//t.getVendors().then((r) => {
				t.getJobsColor().then((c) => {
					t.getListTask();
					t.$store.commit("ModApps/billing/setVisibleAside", null);
				});
			//});
		},		
		getcategory(){
			let t = this;
			return new Promise((resolve, reject) => { 
				window.billing
				.post("getTagsAll", {
					org_id: window.localorgdata,
				})
				.then((response) => {
					//console.log('getTagsAll', response.data);
					let r = response.data;
					t.CategoryList=[];
					if(r.list.length>0){
					t.CategoryList=r.list;
					}
					resolve(true);
				})
				.catch((error) => {
					console.log(error);
					resolve(false);
				});
			});
		}, 		
		getUsersList() {
			let t = this;
			let url = "/users/" + window.master_client;
			window.master.post('usersListSec', {
			user_id: window.master_client,
			org_id: window.localorgdata,
			})
			.then((response) => {
				let r = response.data;
				t.userlist=[{
				id: r.org.id,
				owner: r.org.bill_company_name,
				firstname: r.org.bill_firstname,
				lastname: r.org.bill_lastname,
				email: r.org.bill_email,
				pref_name: r.org.pref_name+' '+r.org.pref_lastname,
				pref_email: r.org.pref_email,
				}];
				for (let i in r.users) {
				if(r.users[i]){
					t.userlist.push({
					id: r.users[i].id,
					owner: r.org.bill_email,
					firstname: r.users[i].firstname,
					lastname: r.users[i].lastname,
					email: r.users[i].email,
					pref_name: r.users[i].pref_name+' '+r.users[i].pref_lastname,
					pref_email: r.users[i].pref_email,
					});
				}
				}
				//console.log('usersListSec', t.userlist)
			})
			.catch((error) => {
				console.log(error);
			});
		},
		getVendors() {
			var t = this;
			return new Promise((resolve, reject) => {
				window.billing.get("vendors/" + window.localuserdata)
				.then((response) => {
					if (response.data.success) {
						let Vendor = [{value: 0, text: 'Vendor name'}];
						for (let item in response.data.result) {
						let t = response.data.result[item].company_name;
						let n = response.data.result[item].firstname;
						if (n !== null && n !== "") {
							t = t + " - " + n;
						}
						Vendor.push({
							value: response.data.result[item].id,
							text: t,
						});
						}
						t.VendorList = Vendor;
						resolve(true);
					}
				})
				.catch((error) => {console.log(error); resolve(false);});
			});
		},
		getListTask(refresh){
			let t = this;
			if(refresh==undefined){t.isLoading = true;}     
			return new Promise((resolve, reject) => { 
				window.billing
				.post("getListTask", {
				user_id: window.localorgdata,
				filter: this.inputFilter,
				status_id: this.statuslist,
				filterTimer: this.filterTimer,
				yearFilter: this.yearFilter,
				pag: this.paginador,
				getActions: 1,
				})
				.then((response) => {
					//console.log('getListTask', response.data);
					let r = response.data;
					t.dataList=[];
					t.countCompleted = r.completed || 0; 
					if(t.statuslist.length>1){
						t.openCounter = 0;
					}
					if(r.result.length>0){
						let e =r.result;
						t.fillData(e);
					}
					t.isLoading = false;
					resolve(true);
				})
				.catch((error) => {
				console.log(error);
				t.isLoading = false;
				resolve(false);
				});
			});
		},		
		fillData(arr, idItem){
			let t = this;
			let a = arr;
			for (let i in a) {
				if(a[i]){
					let e = a[i];
					let id = ((e.id+'').length==1) ? '000'+e.id : (((e.id+'').length==2) ? '00'+e.id : (((e.id+'').length==3) ? '0'+e.id : e.id));
					let item = e;
					let daysLeft= t.calcDiasDiff(new Date(), e.due_date, '-');
					let daysBefore= t.masOmenosDias(e.due_date, e.date_schedule, '-');
					let category_ids = [];
					let tagsIds = [];
					if(e.category_ids!=null && e.category_ids.length>0){
						category_ids = e.category_ids.split(',');
						tagsIds = t.CategoryList.filter(r => {
							for (let c in category_ids) {
								if(category_ids[c] == r.id){
									return r;
								}
							}
						});
					}
					let usersAssign_id = [];
					if(item.assing_users!=null){
						let assing_users = item.assing_users.split(',');
						for (let a in assing_users) {
							let addItem = null;
							if(assing_users[a]){
								addItem = t.userlist.find(r => r.id==assing_users[a]);
							}
							if(addItem!=null){
								usersAssign_id.push(addItem);
							}
						}
					}
					let bgColor ="#fff";
					if(e.color_id != null && t.colorList.length>0){
						let color = t.colorList.filter(c => {
							if(c.id==e.color_id){
								return c;
							}
						});
						if(color.length>0){
							bgColor = color[0].name;
						}
					}
					item.id= e.id;
					item.idx= id;
					item.isNote= (e.note_id>0);
					item.isRec= (e.rec>0);
					item.interval= (e.interval==null) ? 0 : e.interval;
					item.days= daysLeft.d;
					item.due_date= e.due_date;
					let day_time = (e.due_date!=null) ? e.due_date.split(' ') : [];
					item.due_date_day= (day_time.length>0) ? day_time[0] : null;
					item.due_date_time= (day_time.length>0) ? day_time[1] : null;
					item.task_name= e.task_name;
					item.editName= false;
					let vendor = t.VendorList.filter((r) => { if(r.value==e.vendor_id){ return r;}})
					item.vendor= (vendor.length>0) ? vendor[0].text : 'Vendor Name';
					item.vendor_id= (vendor.length>0) ? vendor[0].value : 0;
					item.assing=usersAssign_id;
					item.category= tagsIds;
					item.open= 0; //(idItem!=undefined && idItem==e.id) ? 1 : 0;
					item.openCal= 0;
					item.menuRow= false;
					item.actions=(e.actions != undefined && (e.actions.length>0)) ? e.actions : [];
					item.isActions= e.isActions;
					item.schedule= daysBefore;
					item.daysBefore= e.date_schedule;
					item.editSchedule= false;
					item.status= e.status_id;
					item.exp_id= e.exp_id;
					item.exp_amount= (!isNaN(e.exp_amount)) ? ((e.exp_amount>0) ? t.formatUSD(t.validDecimal(e.exp_amount)) : null) : e.exp_amount;
					item.expValid= true;
					item.expMsg= '';
					item.bgColor= bgColor;
					if(t.statuslist.length>1){
						if(item.status_id<2){t.openCounter++;}
					}
					t.dataList.push(item);
				}
			}
    	}, 
		validDecimal(num) {
			let t = this;
			let vatIdRegex = new RegExp(
				/^(-{1}?(?:([0-9]{0,10}))|([0-9]{1})?(?:([0-9]{0,9})))?(?:.([0-9]{0,3}))?$/
			);
			num = num.replace('$', '');
			num = t.quitarComas(num);
			var value = 0.0;
			if (vatIdRegex.test(num)) {
			value = num;
			}
			return Number(value)
			.toFixed(2)
			.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1");
		},    
		formatUSD(mnto) {
		let ops = {
			style: 'currency', 
			currency: 'USD',
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		};                                                  
		let amount = new Intl.NumberFormat('en-US', ops).format(mnto);
		return amount;
		},    
		quitarComas(special){
			let vatIdComas = new RegExp(/,/);       
			if (vatIdComas.test(special)) {
				special = special.replace(",", "");
				special = this.quitarComas(special)
			}
			return special           
		},
		calcDiasDiff(date1, date2, op){
		if(date2==undefined || date2==null){date2= new Date();}
			let dias = 0;
	        //opcion con moment
	        var fecha1 = moment(date1);
	        var fecha2 = moment(date2);
	        dias = fecha2.diff(fecha1, 'days');
	        let hours = fecha2.diff(fecha1, 'hours');
	        let minutes = fecha2.diff(fecha1, 'minutes');
	        //if(Math.round(diff/(1000*60*60))>23){dias++;}
	        let fecha = new Date(date1);
	        let today = new Date(date1);
	        let dueDate = new Date(date2);
	        if(dias==0 || dias==-1){
	          if(today.getDay() < dueDate.getDay()){
	            dias = 1;
	          }
	          else if(hours > 0){
	            dias = 0;
	          }
	          else if(minutes>0){
	            dias = 0;
	          }
	          // console.log('dias == 0 ', date2, dias, hours, minutes);
	        }
	        // else{
	        //   console.log('dias diff 0 ', date2, dias, hours, minutes);
	        // }
			if(op=='-'){
				fecha.setDate(fecha.getDate() - dias);
			}else{
				fecha.setDate(fecha.getDate() + dias);
			}
			return {d: dias, f: fecha};
		},
		masOmenosDias(fecha, dias, op){
			fecha = new Date(fecha);
			if(op=='-'){
				fecha.setDate(fecha.getDate() - dias);
			}else{
				fecha.setDate(fecha.getDate() + dias);
			}
			return fecha;
		},
        prevenirSaltosDeLinea(evento) {
            if (evento.keyCode === 13) {
                evento.preventDefault();
            }
        },
		calcHeigthTaskName(){    
            let t = this;
            let taskName = document.querySelectorAll('.input-edit-name');
            if(taskName!= null && taskName.length>0){
                taskName.forEach(e => {
                    //console.log('taskName', e.value)
                    t.autoHeigthTaskName(e);
                })
            }
            let actionName = document.querySelectorAll('.input-action-name');
            if(actionName!= null && actionName.length>0){
                actionName.forEach(e => {
                    //console.log('actionName', e.value)
                    t.autoHeigthTaskName(e);
                })
            }
			t.grid_taks_ini(); // formato grid
        },
        autoHeigthTaskName(elem){
            let calcHeight = elem;
            if(calcHeight){
                let numberOfLineBreaks = (calcHeight.value.match(/\n/g) || [1]).length;
                let logitud = Math.round(calcHeight.clientWidth) / 14 + 10; //elemWidth + font-size + line-height
                let caracteres = (calcHeight.value.length>logitud) ? (calcHeight.value.length/logitud) : 1;
                // numberOfLineBreaks = (numberOfLineBreaks==1) ? caracteres : numberOfLineBreaks;
                // console.log('item', calcHeight, 'numberOfLineBreaks', caracteres)
                // min-height + lines x line-height + padding + border
                let newHeight = Math.round(14 + caracteres * 16 + 4 + 4);
                calcHeight.style.height = newHeight + "px";
                // if(numberOfLineBreaks>=3){
                //     calcHeight.style.height = calcHeight.scrollHeight + "px";
                // }
            }
        },
        changeInput(campo, i){
            let t = this;
            if(campo=='task_name'){
                t.saveChangeInput(campo, i);
                let elem = document.querySelector('.task_name-'+i.id);
                t.autoHeigthTaskName(elem);
            }
            else if(campo=="exp_amount"){
                i[campo] = t.validDecimal(i[campo]);
                t.saveChangeInput(campo, i);    
            }
            else{
                //console.log('change', campo, i[campo])
                t.saveChangeInput(campo, i);    
            }
        },
        saveChangeInput(campo, i){
            let t = this;
            console.log('change', campo, i[campo])
            window.billing
            .post("updateTask", {
                id: i.id,
                user_idd: window.master_client,
                user_id: window.localorgdata,
                org_id: window.localorgdata,
                oauth_m: window.oauth_m,
                campo: campo,
                valor: i[campo],
            })
            .then((response) => {
                console.log('updateTask');
                localStorage.removeItem('task_name');
            })
            .catch((error) => {
                console.log(error)
            });
        },		
        isDragging(t){ console.log('move'); return t; },
        checkMove: function(item, actions){
            actions = actions.filter((r, idx) => {
                r.item_order = idx;
                return r;
            });
            console.log('checkMove', actions);
            window.billing
            .put('orderActionsTask', {
                user_idd: window.master_client,
                user_id: window.localorgdata,
                org_id: window.localorgdata,
                oauth_m: window.oauth_m,
                task_id: item.id,
                itemActions: actions,                		
            })
            .then((response) => {
                console.log('orderActionsTask');
            })
            .catch((error) => {
                console.log(error);
            });
        },
		editNameAction(task, action){
            let t = this;
            if(action.accion_name!=null && action.accion_name.length > 0){
                t.changeItemAction(task, action);
            }            
        },
        changeStatusAction(itemTask, i, idx, status){
            let t = this;
            if(i.action_status_id != status){
                itemTask.actions[idx].action_status_id = status;
                i.action_status_id = status;
                t.changeItemAction(itemTask, i);
            }
        },
        changeItemAction(itemTask, itemAction){
            let t = this;  
            window.billing
            .put("updateActionTask", {
                user_idd: window.master_client,
                user_id: window.localorgdata,
                org_id: window.localorgdata,
                oauth_m: window.oauth_m,
                task_id: itemTask.id,
                id: itemAction.id,
                accion_name: itemAction.accion_name,
                accion_descrip: itemAction.accion_descrip,
                date_action: itemAction.date_action,
                action_status_id: itemAction.action_status_id
            })
            .then((response) => {
                console.log('updateActionTask', response.data);
            })
            .catch((error) => {
                console.log(error);
            });
        },
		changeColor(itemTask, idColor, valueColor){
			let t = this;
			let campo = 'color_id';
			itemTask.color_id = idColor;
			itemTask.bgColor = valueColor;
			t.saveChangeInput(campo, itemTask);
		},
		grid_taks_ini() {
			let t = this;
			let contentTask = document.querySelector('.container-visualizer').clientWidth;
			let divisor = t.grip_taks_divisor(contentTask);
			t.grid_taks_generator(divisor);
		},
		grip_taks_divisor(n) {
			console.log('desktop', n)
			let columns = 1;
			if (n >= 1599) {
				columns = 5;
			} else if (n >= 1100) {
				columns = 4;
			} else if (n >= 850) {
				columns = 3;
			} else if (n >= 601) {
				columns = 2;
			} else {
				columns = 1;
			}
			return columns;
		},
		grid_taks_generator(divisor) {
			let i = document.querySelectorAll(".item-taks");
			let cont = 0;
			let columns = 0;
			let row = 1;
			let v = this;
			v.topMax = 0;
			i.forEach(function (item, index) {
				let w = item.offsetWidth;
				let h = item.offsetHeight;
				if (columns >= divisor) {
					columns = 0;
					row++;
				}
				let l = w * columns;
				let t = 0;
				if (row > 1) {
					t = i[index - divisor].offsetHeight;
				}
				if (row > 2) {
					t =
						parseInt(i[index - divisor].style.top.replace("px", "")) +
						parseInt(i[index - divisor].offsetHeight);
				}
				//debugger;
				if (v.topMax < t) {
					v.topMax = t;
					item.classList.add("maxTop");
				}
				item.style.position ="absolute";
				item.style.top = t + "px";
				item.style.left = l + "px";
				// console.log('row', row, 'columns',columns)
				cont++;
				columns++;
			});
			if (localStorage.getItem("eje_pagination") == 1) {
				localStorage.setItem("eje_pagination", 0);
				this.heightPage =
					document.body.scrollHeight -
					parseInt(localStorage.getItem("maxScrollY"));
			}
			if (v.nextPage) {
				v.nextPage = false;
				console.log('nextPage', v.nextPage);
			}
		},
		validPusher(){
			let t = this;
			//consultar si hay pusher pendiente  
			if(localStorage.getItem('refreshStateV2')=='pending'){
				let p = (localStorage.getItem('pushRefreshV2')!=null) ? JSON.parse(localStorage.getItem('pushRefreshV2')) : null;
				if(p != null){
					console.log('ejecutarPushRefresh', p)
					localStorage.setItem('refreshStateV2', 'in progress');
					t.ejecutarPushRefresh(p);
				}else{
					console.log('fail pushRefresh', p)
				}
			}
		},
		duplicateData(arr1){
			let arr2 = [];
			for (let i in arr1) {
				if (arr1[i]) {
					arr2.push(arr1[i]);
				}
			}
			localStorage.setItem('duplicateDataV2', JSON.stringify(arr2))
		},
		refreshAction(item, task_status){
			let t = this;
			window.billing
			.post("getActionsTask", {
				task_id: item.id,
				user_idd: window.master_client,
				user_id: window.localorgdata,
				org_id: window.localorgdata,
				oauth_m: window.oauth_m,
			})
			.then((response) => {
				console.log('getActionsTask', response.data);
				let newList = t.dataList.filter((r) => { 
					if(r.id==item.id){
						r.open=item.open;
						r.actions = response.data.r;
						r.isActions = response.data.r.length;
						console.log('item editado', r);
						if(r.status != task_status){
						r.status_id = task_status;
						console.log('change task status', task_status);
						}
					}
					else{
						r.open=0;
					} 
					return r; 
				});
				t.dataList=[];
				t.fillData(newList, item.id);
			})
			.catch((error) => {
				console.log(error);
				t.btndisabled=false;
			});
		},		
		ejecutarPushRefresh(p){
			let t = this;
			if(p.org_id==window.localorgdata){
			if(p.data.action=='newTask-refresh'){
				t.getListTask(1);
			}
			if(p.data.action=='taskUpdate-refresh'){
				t.duplicateData(t.dataList);
				t.dataList=[];
				let newItem = p.data.result;
				let newList = [];
				let oldArr = JSON.parse(localStorage.getItem('duplicateDataV2'));
				oldArr.filter((r) => {
					if(r.id == newItem.id){
						newItem.actions= r.actions;
						r = newItem;
					}
					newList.push(r);
				});
				console.log('taskUpdate', newItem)
				t.fillData(newList, newItem.id);
				if(p.data.campo=='due_date' || (p.data.campo=="status_id" && (p.data.result.status_id==2 || p.data.result.status_id==3))){
					t.getListTask(1);
				}
			}
			if(p.data.action=='task-tag-refresh'){
				let listTag = p.data.result;
				t.CategoryList=listTag;
			}
			if(p.data.action=='actionTask-refresh'){
				let itemID = p.data.task_id;
				let task_status = p.data.task_status;
				let itemUpdate = null;
				if(task_status==2){
					//si ya esta en done refrescar listado
					t.getListTask(1);
				}else{
					// sino solo actualizar item
					t.dataList.filter((r) => {
						if (r.id == itemID) {
							itemUpdate = r;
						}
					});
					if (itemUpdate != null) {
						task_status = (task_status == undefined || task_status == null) ? itemUpdate.status_id : task_status;
						console.log('actionTask-refresh', itemUpdate.open)
						t.refreshAction(itemUpdate, task_status);
					}
				}
			}
				// limpiar pusher
				t.$store.commit('core/set_accitonPusher', null); 
				localStorage.removeItem('refreshStateV2');
				localStorage.removeItem('pushRefreshV2');
				t.refreshState();
			}
		},
		refreshState(){
			let t = this;
			// Borrar el intervalo anterior
			clearInterval(t.timerRefresh);
			// Restablecer los segundos del temporizador
			t.currSeconds = 0;
			// Establecer un nuevo intervalo
			t.timerRefresh = setInterval(t.startIdleTimer, 1000);
			},
		startIdleTimer() {
			let t = this;
			// Incrementar segundos sin activida
			t.currSeconds++;
			if((t.currSeconds % 5)==0){
				//console.log('tiempo sin actividad', t.currSeconds);
				t.validPusher();
			}
		},		
		actionNotes(task_id, isNote, note_id) {
			let t = this;
			let edit = false
			if (isNote) { edit = true }
			this.m = {
				show: true,
				bill: true,
				component: 'NotesSO',
				data: {
					id: task_id,
					type: "SO",
					edit: edit,
					idNote: note_id
				}
			};
			this.$store.commit('core/setOpenModal', this.m);
		},
		getJobsColor() {
			let t = this;
			return new Promise((resolve, reject) => {
				window.master
					.get("/jobs/getJobsColor/" + t.user_id + "/" + t.org_id)
					.then((response) => {
						let r = response.data.result;
						t.colorList = r;
						resolve(true);
					})
					.catch((error) => {
						console.log(error);
						reject(false);
					});
			});
		},
		changeDate(i) {
            console.log('changeDate', i.due_date)
            this.resetCal = true;
            this.changeInput('due_date', i);
            //this.showTimePanel = true;
        },
		open_task(i){
			let t = this;
			t.m = {
				show: true,
				component: 'openTask',
				data: i
			}
			t.$store.commit('core/setOpenModal', this.m);  
		},
		selectTags(i){
			let t = this;
			t.m = {
				show: true,
				component: 'selectTags',
				data: i
			}
			t.$store.commit('core/setOpenModal', this.m);  
		},	
		delTags(i, catIdx){
			let t = this;
			let category_ids = [];
			i.category.splice(catIdx, 1)
            i.category.filter(r => {
				category_ids.push(r.id);
            });
            i.category_ids = (category_ids.length>0) ? category_ids.toString().replace('1,', '') : '';
			t.saveChangeInput('category_ids', i);
		},
		deleteTask(i){
            let t= this;
            i.status_id=3;
            t.saveChangeInput('status_id', i);
		},
		addTaksPop(){
			let t = this;
			t.m = {
				show: true,
				component: 'formTask',
				data: {
					action: 'add'
				}
			}
			t.$store.commit('core/setOpenModal', this.m);  
      	},
		rectifyData(){
			let t = this;
			console.log('rectificar datos')
			t.duplicateData(t.dataList);
			let list = JSON.parse(localStorage.getItem('duplicateDataV2'));
			t.fillData(list);
			let detectChange = [];
			/*t.dataList.filter(r=>{
				let itemData = Object.values(r);
				let validList = list.filter(e=>{
					if(e.id == r.id){
						let itemFill = Object.values(e);
						let valid = false;
						for (const keyR in itemData) {
							if (itemData[keyR]) {
								for (const keyE in itemFill) {
									if (itemFill[keyE]) {
										if(keyR == keyE && itemData[keyR] != itemFill[keyE]) {
											valid= true;
											//console.log('detect change ', itemData[keyR]+' != '+itemFill[keyE])
										}
									}
								}
							}
						}
						if(valid){
							return e;
						}
					}
				});
				if(validList.length > 0){
					detectChange.push(r);
				}
			});*/
			//console.log('item change', detectChange);
			//t.fillData(t.dataList);
		},
	},
	watch: {
		"accitonPusher": function(){
			let t = this;
			let p = t.accitonPusher;
			if (p!=undefined && p!=null && p.action=='onlySO'){
				console.log('pusher', p);
				localStorage.setItem('refreshStateV2', 'pending');
				localStorage.setItem('pushRefreshV2', JSON.stringify(p));
				if(window.oauth_m==p.oauth){
					t.validPusher();
				}
			}
		},
		getVisibleAside: function(){
			let t = this;
			if(t.getVisibleAside!=null){
				console.log('getVisibleAside', t.getVisibleAside.vista)
				if(t.getVisibleAside.vista=='aside'){
					t.getListTask(1);
				}
			}
		},
	},
	computed: {   
		...mapGetters("core", {
			getOpenModal: "getOpenModal",
			getActionRefresh: "getActionRefresh",
			accitonPusher: "get_accitonPusher",
		}),
		...mapGetters("ModApps/billing", {getVisibleAside: "getVisibleAside"}),
        dragOptions() {
            return {
                animation: 200,
                group: "actions",
                disabled: false,
                ghostClass: "ghost",
            };
        },
	},
	
}
</script>
<style lang="scss" scoped>
	.container-visualizer{
		border:0px dashed #000;
		margin-top: -10px;
		background-color: #fff;
		height: calc(100vh - 45px);
		overflow-y: scroll;
		overflow-x: hidden;
		scrollbar-color:rgba(255, 255, 255, 1) rgba(255, 255, 255, 1);
		scrollbar-width: thin;
		&::-webkit-scrollbar:vertical { 
			width: 1px; 
			background-color: transparent;
			margin-left: 0px;
			padding-left: 0px;
		}
		&::-webkit-scrollbar-thumb {
			background-color: #797979;
			border-radius: 20px;
			border: 1px solid #f1f2f3;
			height: 50px;
		}
	}
	.no-border{border:0px!important;}
	.header_visualizer {
		display: flex;
		justify-content: space-between;
		padding: 5px 10px;
		background-color: #343435;
		color:#fff;
		box-sizing: border-box;
		.box-head{
			text-align: center;
			border: 0px solid #fff;
			padding: 0px 10px;
			min-width: 100px;
			.btn{
				margin: 0px 10px;
				padding: 5px 15px;
				background-color: transparent;
				border: 1px solid #fff;
				color: #fff;
				border-radius: 10px;
				width:120px;
			}
			.btn-open{ 
				margin: 0px 10px;
				opacity:0.5;
				span{ margin-right: 5px;}
				&.active{
					opacity:1;
				}
			}
			.btn-completed{ 
				margin: 0px 10px;
				opacity:0.5;
				span{ margin-right: 5px;}
				&.active{
					opacity:1;
				}
			}
			.submenu-head{
				border:0px;
				min-width:50px;
				width:50px;
			}
		}
	}
	.content-box{
		position:relative;
	}
	/*JOBS BOX*/
	.taks-columns {
		position: relative;
	}
	.item-taks {
		width: calc(100% / 4);
		display: inline-block;
		padding: 10px;
		margin-bottom: 20px;
		position: absolute;
		position: relative;
	}
	@media (min-width: 1921px){
		.item-taks {
			width: calc(100% / 5);
		}
	}
	@media (max-width: 1920px){
		.item-taks {
			width: calc(100% / 5);
		}
	}
	@media (max-width: 1599px) {
		.item-taks {
			width: calc(100% / 4);
		}
	}
	@media (max-width: 1200px) {
		.item-taks {
			width: calc(100% / 3);
		}
	}
	@media (max-width: 1099px) {
		.item-taks {
			width: calc(100% / 2);
		}
	}
	@media (max-width: 849px) {
		.item-taks {
			width: calc(100% / 2);
		}
	}
	@media (max-width: 600px) {
		.item-taks {
			width: calc(100% / 1);
		}
	}
	.item-taks .card .card-header {
		background: #343435;
		color: #fff;
		display: flex;
		flex-direction: row;
		align-items: center;
		border: 1px solid #000;
		padding: 10px 10px 10px 10px;

	}
	.item-taks .card {
		border: 1px solid #c4c5c6;
	}
	.item-taks .card .card-body {
		padding: 10px;
		padding-top: 0px;
	}
	.client {
		margin: 0px -10px;
		background: #fff;
		padding: 8px 10px;
		color: #8e8e8e;
		font-size: 14px;
		overflow: hidden;
		min-height: 35px;
		border-bottom: 1px solid #c4c5c6;
	}
	ul.list-inline li {
		display: block;
		position: relative;
		border-bottom: 1px dashed #c4c5c6;
		padding: 5px 0;
		color: #555;
		margin-right: 0px;
	}
	.card-body.bg-1 .taks ul.list-inline li.list-inline-item,
	.card-body.bg-4 .taks ul.list-inline li.list-inline-item,
	.card-body.bg-5 .taks ul.list-inline li.list-inline-item,
	.card-body.bg-6 .taks ul.list-inline li.list-inline-item,
	.card-body.bg-7 .taks ul.list-inline li.list-inline-item {
		border-color: #c4c5c6;
	}
	.card-body.bg-2 .taks ul.list-inline li.list-inline-item,
	.card-body.bg-3 .taks ul.list-inline li.list-inline-item {
		border-color: #fff;
		color: #000;
	}

	li.list-inline-item .row .col4 .status {
		display: block;
		width: 100%;
	}
	li.list-inline-item .row .col4 .status .badge {
		float: right;
		width: 100%;
		padding: 2px 0px;
		cursor: pointer;
	}
	li.list-inline-item .row .col4 {
		padding-right: 5px;
	}
	li.list-inline-item .row {
		margin-left: 0px;
		margin-right: 0px;
		width: 100%;
	}
	li.list-inline-item .row .col4 {
		width: 22% !important;
		padding: 0px;
	}
	#addJobs {
		position: fixed;
		bottom: 20px;
		right: 15px;
		border-radius: 20px;
		padding: 10px 15px;
		background-color: #000;
		border-color: #000;
	}
	li.list-inline-item .row .col4 .status .op-loading {
		height: 20px;
	}
	li.list-inline-item .row .col4 .status .op-loading:after {
		width: 20px;
		height: 20px;
		border: 2px solid #ccc;
		border-top-color: #37344f;
	}
	.card-footer {
		text-align: right;
		padding: 3px 10px;
	}
	ul.list-options {
		list-style: none;
		width: auto;
		display: inline-block;
		margin: 0px;
		padding: 0px;
		opacity: 1;
	}
	ul.list-options li {
		display: inline-block;
		padding: 0px 10px;
		color: #555;
	}
	ul.list-options li:hover {
		cursor: pointer;
	}
	.item-taks:hover ul.list-options,
	.item-taks.active ul.list-options {
		opacity: 1;
	}
	ul.list-options li .cdropdown > button {
		padding: 0;
		background-color: transparent;
		border: 0;
		color: #555;
	}
	ul.list-options li .cdropdown > button::before {
		border-top: 0px solid transparent;
	}
	ul.list-options li button {
		background: transparent;
		border: 0;
		color: #555;
		box-shadow: none;
	}
	ul.list-options li button:hover,
	ul.list-options li button:focus,
	ul.list-options li button:active {
		border: 0px solid transparent;
		outline: none;
	}
	.paletaColor:hover {
		cursor: pointer;
	}
	.paletaDeColores {
		display: none;
		position: absolute;
		right: 10px;
		bottom: 30px;
		background: #fff;
		height: 80px;
		width: 130px;
		padding-top: 8px;
		padding-left: 6px;
		border: 1px solid #ddd;
		border-radius: 8px;
		text-align: left;
	}
	.jobcolor {
		display: block;
		border: 1px solid #ddd;
		width: 25px;
		height: 25px;
		margin-right: 3px;
		border-radius: 560%;
	}
	.paletaDeColores label.c-input.c-radio input[type="radio"] {
		position: absolute;
		left: 0;
		opacity: 0;
	}
	.paletaDeColores label.c-input.c-radio {
		position: relative;
		width: 28px;
		height: 25px;
	}
	.paletaDeColores label.c-input.c-radio:hover {
		cursor: pointer;
	}
	.colores:hover button {
		cursor: pointer;
	}
	.colores:hover .paletaDeColores {
		display: block;
	}
	.colores:hover:before {
		content: "";
		position: absolute;
		bottom: 20px;
		z-index: 1;
		border-top: 10px solid #ddd;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-bottom: 0px solid transparent;
	}
	.paletaDeColores label .icon-check {
		position: absolute;
		left: 5px;
		top: 3px;
	}
	.custom-template {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		margin: 5px;
		margin-bottom: 0;
		align-items: center;
		justify-content: center;
		&,
		& > div {
			box-sizing: border-box;
		}
		background: #3028de;
		border: 2px solid #3028de;
		border-radius: 4px;
		.custom-template-content {
			padding: 10px;
			flex: 1 0 auto;
			.custom-template-title {
				color: #fff;
				.icon {
					font-size: 14px;
					margin-right: 5px;
				}
				.title {
					letter-spacing: 1px;
					text-transform: uppercase;
					font-size: 12px;
					font-weight: 600;
				}
			}
		}
	}
	.v-fade-left-enter-active,
	.v-fade-left-leave-active,
	.v-fade-left-move {
		transition: all 0.5s;
	}
	.v-fade-left-enter,
	.v-fade-left-leave-to {
		opacity: 0;
		transform: translateX(-500px) scale(0.2);
	}
	.alertx {
		position: relative;
		padding: 0.75rem 1.25rem;
		margin-bottom: 1rem;
		margin-top: 1rem;
		border: 1px solid transparent;
		border-radius: 0.25rem;
	}
	.alert-successx {
		color: #155724;
		background-color: #d4edda;
		border-color: #c3e6cb;
	}
	.alert-dangerx {
		color: #721c24;
		background-color: #f8d7da;
		border-color: #f5c6cb;
	}
	.closex:not(:disabled):not(.disabled) {
		cursor: pointer;
	}
	.closex {
		border: none;
		background: none;
		color: #000;
		margin-left: 10px;
	}
	button,
	select {
		text-transform: none;
	}
	button,
	input,
	optgroup,
	select,
	textarea {
		margin: 0;
		font-family: inherit;
		font-size: inherit;
		line-height: inherit;
	}
	ul.list-options li,
	ul.list-options li.edit,
	ul.list-options li.note,
	ul.list-options li.images,
	ul.list-options li.users,
	ul.list-options li.printer {
		position: relative;
		&:hover {
			.tooltip {
				opacity: 1;
				visibility: visible;
			}
		}
		i.fa-sticky-note{
			color:#555555;
			&:hover{cursor: pointer;}
			&.active{
				color:#ffbc34;
			}
		}
		.label-assign{
			position: relative;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			align-content: center;
			justify-content: center;
			align-items: center;
			i.fa-user-circle{
				font-size: 20px;
				padding: 0px 1px;
				margin: auto;
				color:#ddd;
			}
			i.fa-user-circle.active{
				color:#555;
			}
			.avatarUser{
				width: 28px;
				min-width: 28px;
				max-width: 28px;
				height: 28px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 0px;
				border: 1px solid rgba(0, 0, 0, 0.10);
				position: relative;
				z-index: 1;
				border-radius: 50%;
				font-size: 12px;
				font-weight: 900;
				background-color: #000;
				color: #fff;
				text-transform: uppercase;
				&.join-avatar{margin-left: -10px;}
				&.bg-avatar-admin{background-color: #031099;}
				&.bg-avatar0{background-color: #2b6af1;}
				&.bg-avatar1{background-color: #fdab3d;}
				&.bg-avatar2{background-color: #00c875;}
				&.bg-avatar3{background-color: #e2445c;}
				&.bg-avatar4{background-color: #b00af1;}
				&.bg-avatar5{background-color: #2ab8df;}
				&.bg-avatar6{background-color: #df752a;}
				&.bg-avatar-choise{
					background-color: #1d1d1d;
					.plus-user{
						font-size: 11px;
						position: relative;
						right: -1px;
					}
					i.fa-user{
						font-size: 12px;
						position: relative;
						top: 2px;
					}
				}
				i.fa-times{
					display: none;
					justify-content: center;
					align-items: center;
					position: absolute;
					z-index: 2;
					top: -2px;
					right: -3px;
					font-size: 80%;
					border: 1px solid #ddd;
					color: #555;
					background-color: #ddd;
					border-radius: 50%;
					width: 10px;
					height: 10px;
					padding: 5px;
				}
				span.labelAvatar{
					position: absolute;
					z-index: 1;
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					letter-spacing: -1px;                        
				}
				&:hover{
					z-index: 2;
					i.fa-times{
						display: flex;
						cursor: pointer;
						&:hover{
							color: #e2445c;
							box-shadow: 2px -2px 5px rgba(0,0,0,.5)
						}
					}
				}
			}
		}
		.tooltip {
			background-color: #333;
			font-size: 12px;
			padding: 5px 10px;
			color: #fff;
			position: absolute;
			left: 50%;
			top: 70%;
			margin-top: 5px;
			transform: translateX(-50%);
			width: auto;
			z-index: 15;
			border-radius: 2px;
			opacity: 0;
			visibility: hidden;
			transition: all 0.3s ease-in-out;
			&:before {
				content: "";
				display: block;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 7.5px 6px 7.5px;
				border-color: transparent transparent #333 transparent;
				position: absolute;
				left: 50%;
				margin-left: -7.5px;
				top: -6px;
			}
		}
		&.submenuFooter{
			padding:0px;
			.cdropdown button{
				width:25px;
			}
		}
	}
	.taks pre {
		font-family: "ProximaNova", sans-serif;
		font-size: 0.875rem;
		font-weight: 400;
		line-height: 1.5;
		color: #555;
		overflow: hidden;
		display: block;
		white-space: break-spaces;
		margin-bottom: 0px;
	}
	.card-body.bg-2 .taks pre {
		color: #000;
	}
	ul.pagination.justify-content-center {
		margin-bottom: 0px;
	}
	li button.page-link {
		display: block;
		width: 100%;
	}
	.dropdown.pagination.justify-content-center a.dropbtn:before {
		right: initial;
		margin-left: 84px;
		top: 14px;
	}
	.dropdown.pagination.justify-content-center a.dropbtn {
		padding-top: 5px;
		padding-bottom: 0px;
	}
	ul.list-optiones-extras li button {
		padding: 5px 10px;
	}
	span.cdropdown.btnRinvoExp {
		position: relative;
	}
	span.cdropdown.btnRinvoExp button {
		border: 0px;
		color: #000;
		padding-right: 20px;
		padding-left: 5px;
		font-size: 14px;
	}
	span.cdropdown.btnRinvoExp button:before {
		border-top: 5px solid transparent;
		content: initial;
	}
	.jobsNote img {
		width: 50%;
	}
	.form-group.invoiceList {
		margin-bottom: 50px;
	}
	.bgloading {
		position: fixed;
	}
	.op-loading {
		position: absolute;
		top: 30%;
		left: 45%;
	}
	.op-loading-pricipal > .op-loading:after {
		width: 100px;
		height: 100px;
	}
	/*
	HEADER
	*/
	.card-header span.numID,
	.card-header span.date,
	.card-header span.daysAgo,
	.card-header span.status {
		position: relative;
		display: flex;
		width: 33.3333%;
		&.task_status{
			.uncompleted{
				background-color: #e2445c;
				font-size: 13.5px;
				padding: 0.2em 0.1em;
				width: 100%;
			}
		}
		span.label-date{			
			font-size: 12px;
			text-align: center;
		}
	}
	span.numID {
		color: #fafafa;
		cursor: pointer;
	}
	span.status {
		justify-content: flex-end;
	}
	span.status span.badge {
		border-radius: 0px;
		font-size: 14px;
		width: 86px;
	}
	.badge-success {
		color: #fff;
		background-color: #019720;
	}
	/*
	*customer 
	*/
	label.badge.badge-pill.badge-light.assign-custom {
		border-radius: 0px;
		margin-right: 5px;
		margin-bottom: 5px;
		border: 1px solid #a2a1a1;
		font-size: 12px;
		color: #0a0a0a;
		padding: 5px 10px;
		-webkit-box-shadow: none;
		box-shadow: none;
	}
	.assign-custom {
		position: relative;
		cursor: pointer;
		&:hover {
			.tooltip {
				opacity: 1;
				visibility: visible;
			}
		}
	}
	.tooltip {
		background-color: #333;
		font-size: 12px;
		padding: 5px 10px;
		color: #fff;
		position: absolute;
		top: -7px;
		left: 108px;
		margin-top: 5px;
		transform: translateX(-50%);
		width: 120px;
		z-index: 15;
		border-radius: 2px;
		opacity: 0;
		visibility: hidden;
		transition: all 0.3s ease-in-out;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		&:before {
			content: "";
			display: block;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 8px 12px 8px 0px;
			border-color: transparent #333 transparent transparent;
			position: absolute;
			left: -3px;
			margin-left: -10px;
			top: 5px;
		}
	}
	.client label a.btn.btn-link {
		color: #555;
	}
	/* seccion taks*/
	span.status {
		justify-content: flex-end;
	}
	span.status span.badge {
		border-radius: 0px;
		font-size: 14px;
	}
	ul.list-inline li {
		display: block;
		position: relative;
		border-bottom: 1px dashed #c4c5c6;
		padding: 5px 0;
		color: #555;
		margin-right: 0px;
	}
	li.list-inline-item .row .col4 {
		display: flex;
		align-items: flex-start;
	}
	li.list-inline-item .row .col4 .status {
		display: block;
		width: 100%;
	}
	li.list-inline-item .row .col4 .status .badge {
		float: right;
		width: 100%;
		padding: 2px 0px;
		cursor: pointer;
	}
	li.list-inline-item .row .col4 {
		padding-right: 5px;
	}
	li.list-inline-item .row {
		margin-left: 0px;
		margin-right: 0px;
		width: 100%;
	}
	li.list-inline-item .row .col8 {
		width: 72% !important;
		.moveAction{
			padding: 1px 10px 1px 5px;
			font-size: 14px;
			border-radius: 2px;
    		border-top-left-radius: 2px;
    		border-bottom-left-radius: 2px;
			position: absolute;
			left: -8px;
			top: 2px;
			height: 40px;
			z-index: 1;
			margin-right: 15px;
			box-shadow: none;
			opacity: 0.2;
			color: #555;
			border:0px;
			box-shadow: none;
			background-color: transparent;
			i.bar-vertical{
				display: block;
				position: relative;
				border: 2px solid #555;
				border-top: 0px solid #555;
				border-bottom: 0px solid #555;
				width: 100%;
				height: 80%;
				padding: 0px 1.3px;
				&:after{	
					content: "";
					position: absolute;
					z-index: 1;
					top:0px;
					right: -6px;
					width: 100%;
					height: 100%;
					display: block;
					border-left: 2px solid #555;
					border-right: 0px;
					border-top: 0px;
					border-bottom: 0px;
				}
			}
		}
		&:hover {
			.moveAction{
				opacity: 0.8;
				cursor: move;
				background-color: #b4d6ff;
				border-color: #137eff;
			}
		}
	}
	li.list-inline-item .row .col4 {
		width: 28% !important;
		padding: 0px;
	}
	li.list-inline-item .row .col4 .status .op-loading {
		height: 20px;
	}
	li.list-inline-item .row .col4 .status .op-loading:after {
		width: 20px;
		height: 20px;
		border: 2px solid #ccc;
		border-top-color: #37344f;
	}
	.taks pre {
		font-family: "ProximaNova", sans-serif;
		font-size: 0.875rem;
		font-weight: 400;
		line-height: 1.5;
		color: #555;
		overflow: hidden;
		display: block;
		white-space: break-spaces;
		margin-bottom: 0px;
	}
	.edicionTaks {
		overflow: hidden;
		resize: initial;
		border: 0px solid transparent;
		background: transparent;
		box-shadow: none;
		padding: 0px;
		padding-left: 10px;
	}
	/*seccion tags*/
	.tags span button.badge.badge-pill.badge-light {
		border-radius: 0px;
		margin-right: 5px;
		margin-top: 5px;
		margin-bottom: 5px;
		border: 1px solid #a2a1a1;
		font-size: 12px;
		color: #0a0a0a;
		padding: 2px 5px;
		box-shadow: none;
	}
	span.delTags {
		background-color: #cac9c9;
		color: #424242;
		padding: 2px 4px;
		font-size: 10px;
		border-radius: 50%;
		margin-right: 5px;
	}
	span.delTags:hover {
		cursor: pointer;
	}
	.tags span button.badge.badge-pill.badge-light.addTags.hover {
		cursor: pointer;
	}
	.tags span button.badge.badge-pill.badge-light.addTags {
		padding: 3px 6px;
		font-size: 10px;
		background-color: #fff;
	}
	.hidden-tag {
		display: none;
	}
	.page-wrapper > .container-fluid{    
		padding-top: 0px;
		margin-bottom: 100px;
		min-height: calc(100vh - 100px);
	}
	.cortina {
		background-color: rgb(0 0 0 / 63%);
		position:fixed;
		top:0;
		right:0;
		bottom:0;
		left:0;
		z-index: 1;
		.op-loading {
			position: inherit !important;
		}
	}
	.btnAddTask {
		display: none !important;
		text-align: end;
		height: 0px;
		transition: all 3s ease-out;
	}
	.showBtnAdd{
		display: block !important;
		height: 52px;
		transition: all 3s ease-out;
	}
	.previewTask {
		padding-left: 12px;
	}
	.ellipsis {
		display: block;
		display: -webkit-box;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.previewTask.hide {
		display: none;
	}
	/*Smart office*/
	/*days*/
	.on_time{color:#fafafa;}
	.time_running_out{color: #d38019;}
	.today, .overdue{color:#e2445c; }
	.completed{color:#1010d8;}
	.label-time{
		color: #d38019;
		margin-left: 5px;
		&.expired{color: #e2445c; }
		&.closed{color: #1010d8;}
	}
	/*task name*/
	.input-edit-name{
		border: 0px;
		background-color: transparent;
		width: 100%;
		height: 18px;
		color:#1010d8;
		font-weight: bold;
		resize: none;
		overflow: hidden;
		line-height: 1.2;
		&:hover{border: 0px solid #ccc;border-radius: 4px;}
		&:focus{
			border: 1px solid #ccc;
			border-radius: 4px;
			width: 100%;
			color:#555;
			font-weight: normal;
		}
	}
	/*action name */
	.input-action-name{
		border: 0px;
		background-color: transparent;
		width: 100%;
		height: 18px;
		color:#555;
		font-weight: normal;
		resize: none;
		overflow: hidden;
		padding-left: 15px;
		line-height: 1.2;
		&:hover{border: 0px solid #ccc;border-radius: 4px;}
		&:focus{
			border: 1px solid #ccc;
			border-radius: 4px;
			width: 100%;
			color:#555;
			font-weight: normal;
		}
	}
	.date{
		display: flex;
		align-items: center;
		width: 25%;
		margin-top: 0px;
		margin-right: 8.3333%;
		font-size: 12px;
		color: #fff;
		border-radius: 4px;
		padding: 0px 5px;
		span{margin-left: 6px;font-weight: normal;font-size: 14px;}
	}
	.mx-datepicker{
		position: absolute;
		width: 100%;
	}
	.date.status1{
		position: relative;
	}
	#addJobs {
		position: fixed;
		bottom: 20px;
		right: 15px;
		border-radius: 20px;
		padding: 10px 15px;
		background-color: #000;
		border-color: #000;
	}
.bg1{background-color: #ff8a80;}
.bg2{background-color: #ffff8d;}
.bg3{background-color: #FFD180;}
.bg4{ background-color:#FFFFFF;}
</style>
