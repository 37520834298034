export default {  
	gets(query){
		return "leads/form" + query
	},
	status(id){
		return "leads/status/" + id + "/" + window.localorgdata
	},
	setStatusLeads(){
		return "leads/setstatusleads"
	},	
};
