export default {
  SET_DASHBOARD(state, status) {
    if(status){
      var visible = true
    }else{
      visible = false
    }
    state.dashboard = visible
  },
    retrieveTokenBilling(state, accessBilling) {
      state.token = accessBilling.token;
      state.billing = accessBilling.login;
      console.log('state.billing', state.billing)
    },
    destroyTokenBilling(state) {
      state.token = null;
      state.billing =false;
    },
    redirectBilling() {
      let NewCache = localStorage.getItem('start')+'=';
      NewCache +=localStorage.getItem('oauth_m')+'&eraseCache=true';
      window.location.href = window.location.href+'?'+NewCache;
    },
    set_vendorSelect(state, item){
      state.vendorSelect = item;
    },
    set_vendorOP(state, item){
      state.vendorOP = item;
    },
    set_setupBilling(state, val){
      state.setupBilling = val;
    },
    SetResponseMail(state, val){
      state.responseMail = val;
    },
    SetSelectBank(state, val){
      state.selectBank = val;
      localStorage.setItem('selectBank', JSON.stringify(val))
    },
    SetFilterBank(state, val){
      state.filterBank = val;
    },
    setTextfilter(state, val){
      state.textfilter = val;
    },
    setDelItemSelected(state, val){
      state.delItemSelected = val;
    },
    setTemplateBanking(state, val){
      state.templateBanking = val;
    },
    setAssignExpense(state, val){
      state.assignExpense = val;
    },
    setCustomerRefresh(state, val){state.customerRefresh = val;},
    setCustomerSearch(state, val){state.customerSearch = val;},
    setCustomerSearchActive(state, val){state.customerSearchActive = val;},
    setCustomerID(state, val){state.customerID = val; },
    setFormCustomer(state, val){state.formCustomer = val;},
    setShowAddress2(state, val){state.showAddress2 = val;},
    setCustomerByID(state, val){state.customerByID = val;},
    setCustomerData(state, val){state.customerData = val;},
    setShowSetting(state, val){state.showSetting = val;},
    setCustomerCountTotal(state, val){state.customerCountTotal = val;},
    setAccountListBacking(state, val){state.AccountList = val;},
    setShowSMS(state, val){state.showSMS = val;},
    settemplateContract(state, val){state.templateContract = val;},
    setVisibleAside(state, val){state.asideMenu = val;},
};
