<template>
    <aside
        class="left-sidebar"
        data-sidebarbg="skin5"
        v-bind:class="{ min: lateral }"
    >
        <div class="scroll-sidebar">
            <div class="btnAsideAction" @click="ShowHiddenAside">
                <i class="fas fa-chevron-left" v-bind:class="{ min: lateral }"></i>
                Close Menu
            </div>
            <nav :class="'sidebar-nav ' + heightMenu">
                <ul id="sidebarnav" @click="ShowHiddenAside">
                    <li
                        class="sidebar-item"
                        v-for="(i, index) in filterMenu"
                        :key="index"
                        v-bind:class="{ sub: i.type === 2 }"
                    >
                        <router-link
                            :to="i.ruta"
                            exact-active-class="active"
                            class="sidebar-link waves-effect waves-dark"
                            v-if="i.type == 1"
                        >
                            <i
                                :class="i.icon"
                                style="color: #555; font-size: 20px"
                            ></i>
                            <span class="hide-menu">{{ i.name }}</span>
                        </router-link>
                    </li>
                </ul>
            </nav>
        </div>
    </aside>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    name: "AsideReseller",
    components: {},
    data() {
        return {
            activar: true,
            menuDefaul: [
                {
                    name: "Dashboard",
                    ruta: { name: "HomeReseller" },
                    icon: "fas fa-lg fa-home",
                    m_id: 0,
                    type: 1,
                },
                {
                    name: "Accounts",
                    ruta: { name: "UsersbyReseller" },
                    icon: "fas fa-lg fa-users",
                    m_id: 1,
                    type: 1,
                },
                {
                    name: "Organizations",
                    ruta: { name: "OrgbyReseller" },
                    icon: "fas fa-lg fa-building",
                    m_id: 2,
                    type: 1,
                },
            ],
            menuLateral: [],
            search: "",
            countMenu: 1,
            heightMenu: "full",
        };
    },
    created() {
        this.estructuraMenu(this.menuDefaul);
    },
    methods: {
        ShowHiddenAside() {
            this.activar = !this.activar;
            this.$store.commit("core/reseller/ActiveLateral", this.activar);
        },
        estructuraMenu(items) {
            for (let i in items) {
                let newitems = {
                    name: items[i].name,
                    ruta: items[i].ruta,
                    icon: items[i].icon,
                    m_id: items[i].id,
                    type: 1,
                };
                this.menuLateral.push(newitems);
            }
            // console.log('menu lateral ', this.menuLateral)
        },
    },
    mounted: function () {},
    computed: {
        ...mapGetters("core/reseller", ["lateral"]),
        filterMenu: function () {
            var self = this;
            return this.menuLateral.filter(function (item) {
                return (
                    item.name &&
                    item.name.toLowerCase().indexOf(self.search.toLowerCase()) >= 0
                );
            });
        },
    },
    watch: {
        lateral: function () {
            this.activar = this.lateral;
        },
    },
};
</script>
<style lang="scss" scoped>
.scroll-sidebar {
    height: calc(100% - 5px);
    position: relative;
}
aside.left-sidebar {
    position: relative;
    height: 100vh;
    float: left;
    width: 180px;
    background: #fff !important;
}
.btnAsideAction {
    position: absolute;
    bottom: initial;
    top: -14px;
    left: 0;
    width: 100%;
    height: 40px;
    background-color: #343435;
    cursor: pointer;
    padding: 10px 4px 4px 4px;
    line-height: 21px;
    text-align: center;
    color: #fff;
}
aside.left-sidebar.min {
    width: 0px;
}
aside.left-sidebar.min .scroll-sidebar {
    display: none;
}
.sidebar-nav ul .sidebar-item {
    width: 100%;
    border-top: 1px solid#dee2e6;
}
nav.sidebar-nav {
    overflow-y: hidden;
    padding-top: 25px;
}
nav.sidebar-nav.uno {
    height: 91.5%;
}
nav.sidebar-nav.dos {
    height: 85%;
}
nav.sidebar-nav.tres {
    height: 77.5%;
}
nav.sidebar-nav.full {
    height: 70%;
}
nav.sidebar-nav.uno,
nav.sidebar-nav.dos,
nav.sidebar-nav.tres,
nav.sidebar-nav.full {
    height: 99% !important;
}
.menu-foot.uno {
    height: 7.5%;
}
.menu-foot.dos {
    height: 15%;
}
.menu-foot.tres {
    height: 21.5%;
}
.menu-foot.full {
    height: 30%;
}
.menu-foot {
    display: none;
}
ul#sidebarnav {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 30px;
}
.menu-foot a {
    color: #343434;
    font-weight: bold;
}
.menu-foot a i {
    color: #000;
}
.sidebar-nav > #sidebarnav > .sidebar-item > .sidebar-link:hover {
    background: #e2e1e1;
    color: #555;
}
.sidebar-nav > #sidebarnav > .sidebar-item > .sidebar-link:hover i,
.sidebar-nav ul .sidebar-item .sidebar-link i {
    color: #000;
}
.sidebar-nav ul .sidebar-item .sidebar-link {
    color: #555;
    opacity: 1;
}
@media (max-width: 1024px) {
    .sidebar-nav ul .sidebar-item .sidebar-link {
        padding: 8px 8px;
    }
}
@media (max-width: 767px) {
    aside.left-sidebar {
        position: fixed;
        z-index: 1031;
        width: 180px;
    }
}
</style>
