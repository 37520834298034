<template>
    <div>
        <div class="page-breadcrumb">
            <div class="row">
                <div class="col-5 align-self-center">
                    <h4 class="page-title">Reports</h4>
                </div>
                <div class="col-7 align-self-center">
                    <div class="d-flex align-items-center justify-content-end">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="card card-body">
                                    <div class="list-group list-group-flush">
                                        <h4>MONEY IN</h4>
                                        <router-link
                                            :to="{ name: 'Reportinvoices' }"
                                            class="list-group-item list-group-item-action"
                                            >Invoices</router-link
                                        >
                                        <router-link
                                            :to="{ name: 'ReportEstimates' }"
                                            class="list-group-item list-group-item-action"
                                            >Estimates</router-link
                                        >
                                        <router-link
                                            :to="{ name: 'ReportRecurringInvoices' }"
                                            class="list-group-item list-group-item-action"
                                            >Recurring Invoices</router-link
                                        >
                                        <router-link
                                            :to="{ name: 'ReportPaymentsReceived' }"
                                            class="list-group-item list-group-item-action"
                                            >Paymens Received</router-link
                                        >
                                        <router-link
                                            :to="{ name: 'accountTypeInvoice' }"
                                            class="list-group-item list-group-item-action"
                                            >Accounts Type Invoice</router-link
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="card card-body">
                                    <div class="list-group list-group-flush">
                                        <h4>CUSTOMERS</h4>
                                        <router-link
                                            :to="{ name: 'ReportCustomer' }"
                                            class="list-group-item list-group-item-action"
                                            >Customers Export/Import</router-link
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="card card-body">
                                    <div class="list-group list-group-flush">
                                        <h4>PROFIT & LOSS</h4>
                                        <router-link
                                            :to="{ name: 'ProfitandLoss' }"
                                            class="list-group-item list-group-item-action"
                                            >Profit & Loss</router-link
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="card card-body">
                                    <div class="list-group list-group-flush">
                                        <h4>EXPENSES & ACCOUNTS</h4>
                                        <router-link
                                            :to="{ name: 'ExpensesbyAccount' }"
                                            class="list-group-item list-group-item-action"
                                            >Expenses Accounts</router-link
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="card card-body">
                                    <div class="list-group list-group-flush">
                                        <h4>EXPENSES</h4>
                                        <router-link
                                            :to="{ name: 'ExpensesReport' }"
                                            class="list-group-item list-group-item-action"
                                            >Expenses</router-link
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="card card-body">
                                    <div class="list-group list-group-flush">
                                        <h4>TAXES</h4>
                                        <router-link
                                            :to="{ name: 'ReportTaxes' }"
                                            class="list-group-item list-group-item-action"
                                            >Sales Tax Report</router-link
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="card card-body">
                                    <div class="list-group list-group-flush">
                                        <h4>MONEY OUT</h4>
                                        <router-link
                                            :to="{ name: 'SubcontractorReport' }"
                                            class="list-group-item list-group-item-action"
                                            >Subcontractor</router-link
                                        >
                                        <router-link
                                            :to="{ name: 'VendorsReport' }"
                                            class="list-group-item list-group-item-action"
                                            >Vendor</router-link
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal name="modal-setting" height="auto" @before-close="ClosedModal">
            <div class="card">
                <component :is="SettingBox"></component>
            </div>
        </modal>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader";
import Taxes from "@/apps/billing/components/setting/Taxes.vue";
import Terms from "@/apps/billing/components/setting/Terms.vue";
import StartInvoice from "@/apps/billing/components/setting/StartInvoice.vue";
import StartEstimate from "@/apps/billing/components/setting/StartEstimate.vue";
import { mapGetters } from "vuex";
export default {
    name: "Report",
    components: {
        Loader,
        Terms,
        Taxes,
        StartInvoice,
        StartEstimate,
    },
    data() {
        return {
            showModal: false,
            SettingBox: "",
        };
    },
    methods: {
        getStates() {
            this.isLoadingCustomers = true;
            window.billing
                .get("states/")
                .then((response) => {
                    console.log(response.data.resultStates);
                    this.CustomersList = response.data.resultStates;
                    this.isLoadingCustomers = false;
                })
                .catch((error) => console.log(error));
        },
        closeModal() {
            this.$modal.hide("modal-setting");
        },
        ClosedModal() {
            this.SettingBox = "";
        },
        resfresh(res) {
            if (res.open == 1) {
                this.ClosedModal();
                this.closeModal();
            }
        },
    },
    computed: {
        ...mapGetters("ModApps/billing", ["get_setupBilling"]),
    },
    watch: {
        get_setupBilling: function () {
            if (this.get_setupBilling) {
                let g = this.$store.getters["ModApps/billing/get_setupBilling"];
                let fun = g[0].function;
                let r = g[0].data[0];
                if (fun == "UpdateStartInvoice") {
                    this.resfresh(r);
                }
                if (fun == "UpdateStartEstimate") {
                    this.resfresh(r);
                }
            }
        },
        SettingBox: function () {
            if (!this.SettingBox == "") {
                this.$modal.show("modal-setting");
            }
        },
    },
};
</script>
<style lang="scss" scoped>
i.fas.fa-arrow-left {
    font-size: 13px;
}
</style>
